import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    storeName: string
    location: string
    mid: string
    venueId: string
    save: string
    cancel: string
    errorMessage: string
}>()