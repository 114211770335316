import { message } from 'antd'
import { parseTextResult } from 'back-connector'
import { saveAs } from 'file-saver'
import { postWithAuth, getWithAuth } from '~/code/services'
import { getFilename, getPdfFilename } from './getters'
import translations from '../translations'
import { DownloadStatementFileParamsType } from '../types'

const createDownloadStatementFile =
  (url: string) => async (merchantName: string, params: DownloadStatementFileParamsType) => {
    const response = await postWithAuth(url, params, [], {
      parseResponse: async res => {
        if (res.status !== 200) {
          const error = await parseTextResult(res)
          throw new Error(error.message)
        }
        const blobFile = await res.blob()
        return blobFile
      }
    })
    if (response.error) {
      // TODO: previously we had response.error as a param, need to test it.
      message.error(response.error?.message)
    }
    saveAs.saveAs(await response.result, getFilename(merchantName, params.from, params.to, params.format))
  }

const createDownloadStatementPdfFile =
  (url: string) =>
  async (merchantName: string, { period, format }: DownloadStatementFileParamsType) => {
    const response = await getWithAuth(url, { period }, [], {
      parseResponse: async res => {
        if (res.status !== 200) {
          const error = await parseTextResult(res)
          throw new Error(error.message)
        }
        const blobFile = await res.blob()
        return blobFile
      }
    })
    if (response.error) {
      // TODO: previously we had response.error as a param, need to test it.
      message.error(response.error?.message)
    }
    saveAs.saveAs(await response.result, getPdfFilename(merchantName, period, format))
  }

export const downloadStatementFile = (id: string) =>
  createDownloadStatementPdfFile(`/api/v1/merchants/${id}/pdf-statement`)

export const downloadStatementFileTest = (id: string) =>
  createDownloadStatementPdfFile(`/api/temp/merchants/${id}/pdf-statement`)

export const downloadStatementFileOld = createDownloadStatementFile(`/api/v2/merchants/statement`)

export const downloadStatementFileTestOld = createDownloadStatementFile(`/api/temp/merchants/statement`)

const createDownloadStatementFileAsync =
  (url: string) => async (merchantName: string, params: DownloadStatementFileParamsType) => {
    const { error, result } = await postWithAuth<{ jobId: string; jobName: string; fileName: string }>(url, {
      ...params,
      fileName: getFilename(merchantName, params.from, params.to, params.format)
    })
    if (error || !result) {
      message.error(error.message || translations().errDownloadingStatement)
    } else {
      message.success(translations().successDownloadingStatement, 10)
    }
  }

export const downloadStatementFileTestAsync = createDownloadStatementFileAsync('/api/temp/merchants/statement-async')

export const downloadStatementFileAsync = createDownloadStatementFileAsync('/api/v1/merchants/statement-async')
