import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { message } from 'antd'
import { injectable } from 'inversify'
import {
  NotificationBehaviourEnum,
  NotificationSetupFormType,
  NotificationTemplateEnum,
  TemplateType
} from '~/code/stores/NotificationManagementStore/models'
import {
  computeContentAreas, formatSubmitData,
  formatTemplateForm,
  getPreviewTemplateOption, handleFulfilled
} from '~/code/stores/NotificationManagementStore/services'
import { SelectItem } from '~/code/models'
import {
  createTemplate,
  fetchContentAreas,
  fetchDisplayTypes,
  fetchLevels,
  fetchSystems, fetchTemplate, updateTemplate
} from '~/code/stores/NotificationManagementStore/services/fetchers'
import { IManageNotificationTemplateStore } from '~/code/pages/Notifications'
import translations from './translations'

@injectable()
export class ManageNotificationTemplateStore implements IManageNotificationTemplateStore {
  isTemplateLoading = false
  isTemplateCreating = false
  isOptionsDataLoading = false
  isContentAreasLoading = false
  selectedTemplate = null
  templateType: NotificationTemplateEnum = null
  notificationSetupForm: NotificationSetupFormType = null
  step: number = 0
  systemOptions: SelectItem[] = []
  displayTypeOptions: SelectItem[] = []
  levelsOptions: SelectItem[] = []
  mpContentAreas:SelectItem[] = []
  dashboardContentAreas: SelectItem[] = []
  notificationTypesOptions: SelectItem[] = [
    {label: 'Maintenance / Incident', value: NotificationTemplateEnum.MAINTENANCE},
    {label: 'Push', value: NotificationTemplateEnum.PUSH},
    {label: 'Marketing', value: NotificationTemplateEnum.MARKETING}
  ]
  constructor() {
    makeObservable(this, {
      selectedTemplate: observable,
      notificationSetupForm: observable,
      step: observable,
      isContentAreasLoading: observable,
      isTemplateLoading: observable,
      isOptionsDataLoading: observable,
      isTemplateCreating: observable,
      previewTemplate: computed,
      contentAreaOptions: computed,
      isDashboard: computed,
      setSelectedTemplate: action.bound,
      setNotificationSetupForm: action.bound,
      setStep: action.bound,
      setTemplateType: action.bound
    })

    this.loadOptionsData()
    this.loadContentAreas()
  }
  get previewTemplate() {
    return getPreviewTemplateOption(this.notificationSetupForm)
  }

  get contentAreaOptions() {
    if (this.notificationSetupForm && this.mpContentAreas.length && this.dashboardContentAreas.length) {
      const {systemId, notificationDisplayTypeId} = this.notificationSetupForm
      let sections = systemId && systemId === 'dashboard'
        ? this.dashboardContentAreas
        : this.mpContentAreas

      return computeContentAreas(notificationDisplayTypeId, sections)
    } else return null
  }

  get isDashboard() {
    return this.previewTemplate === NotificationBehaviourEnum.DASHBOARD_LOGIN_BANNER ||
      this.previewTemplate === NotificationBehaviourEnum.DASHBOARD_SECTION_BANNER ||
      this.previewTemplate === NotificationBehaviourEnum.DASHBOARD_POPUP ||
      this.previewTemplate === NotificationBehaviourEnum.DASHBOARD_SECTION_BLOCK ||
      this.previewTemplate === NotificationBehaviourEnum.DASHBOARD_BLOCK
  }

  public setSelectedTemplate = (selectedTemplate: TemplateType) => {
    this.selectedTemplate = selectedTemplate
  }

  public setNotificationSetupForm = (values: any) => {
    this.notificationSetupForm = values
  }

  public setStep = (step: number) => {
    this.step = step
  }

  public setTemplateType = (templateType: NotificationTemplateEnum) => {
    this.templateType = templateType
    this.setStep(1)
  }

  loadTemplate = async (id: string) => {
    runInAction(() => this.isTemplateLoading = true)
    try {
      const { result } = await fetchTemplate(id)

      if (result) runInAction(() => {
        this.setNotificationSetupForm(formatTemplateForm(result))
        this.setSelectedTemplate(result)
      })
    } catch (error) {
      message.error(error.message)
    } finally {
      runInAction(() => this.isTemplateLoading = false)
    }
  }

  loadContentAreas = async () => {
    runInAction(() => this.isContentAreasLoading = true)
    try {
      const mpContentAreasRes = await fetchContentAreas('mp')
      const dashboardContentAreasRes = await fetchContentAreas('dashboard')

      if (mpContentAreasRes.result) {
        const {result} = mpContentAreasRes
        this.mpContentAreas = result.map((contentArea) => ({label: contentArea.description, value: contentArea.id}))
      }

      if (dashboardContentAreasRes.result) {
        const {result} = dashboardContentAreasRes
        this.dashboardContentAreas = result.map((contentArea) => ({label: contentArea.description, value: contentArea.id}))
      }

    } catch(error) {
      message.error(error.message)
    } finally {
      runInAction(() => this.isContentAreasLoading = false)
    }
  }

  loadOptionsData = async () => {
    runInAction(() => {
      this.isOptionsDataLoading = true
    })

    try {
      const [systems, displayTypes, levels] = await Promise.allSettled([
        fetchSystems(),
        fetchDisplayTypes(),
        fetchLevels(),
      ])

      runInAction(() => {
        this.systemOptions = handleFulfilled(systems).map(({ id, name }: any) => ({
          value: id,
          label: name,
        }))

        this.displayTypeOptions = handleFulfilled(displayTypes).map(({ id, name }: any) => ({
          value: id,
          label: name,
        }))

        this.levelsOptions = handleFulfilled(levels).map(({ id, name }: any) => ({
          value: id,
          label: name,
        }))
      })
    } catch (error) {
      message.error(error.message || translations().unexpectedError)
    } finally {
      runInAction(() => {
        this.isOptionsDataLoading = false
      })
    }
  }


  submitTemplateForm = async ()=> {
    runInAction(() => {
      this.isTemplateCreating = true
    })

    try {
      const data = formatSubmitData(this.notificationSetupForm)

      const { result } = this.selectedTemplate && this.selectedTemplate.id.length
        ? await updateTemplate(data, this.selectedTemplate.id)
        : await createTemplate(data)


      if (result) message.success(`${result.title} ${translations().notificationSaved}`)

    } catch (error) {
      message.error(error.message)
    } finally {
      runInAction(() => {
        this.setSelectedTemplate(null)
        this.notificationSetupForm = null
        this.isTemplateCreating = false
      })
    }
  }
}