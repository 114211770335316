import React from 'react'
import { Resizable } from 'react-resizable'
import { ResizableColumnTitleProps } from './props'
import styles from './ResizableColumnTitle.scss'

export const ResizableColumnTitle: React.FC<ResizableColumnTitleProps> = (props) => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return <Resizable
    width={width}
    height={0}
    className={styles.reactResizable}
    handle={
      <span
        className={styles.reactResizableHandle}
        onClick={e => {
          e.stopPropagation()
        }}
      />
    }
    onResize={onResize}
    draggableOpts={{ enableUserSelectHack: false }}
  >
    <th {...restProps} />
  </Resizable>
}