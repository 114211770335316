import React from 'react'
import translations from './translations'

translations.add('en', {
  filename: 'File name',
  period: 'Month',
  subsidiary: 'Subsidiary',
  download: 'Download NetSuite Invoice Upload File',
  downloadToolip: 'Download',
  reset: 'Reset',
})
