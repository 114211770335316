import React, { useEffect } from 'react'
import { Typography } from 'antd'
import { observer } from 'mobx-react'
import { SendVerificationCode } from '~/code/components'
import { AuthForm } from '~/code/components/auth/AuthForm'
import { TwoFAConfirmProps } from './props'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'

import translations from './translations'
import styles from './styles.scss'

const { Link } = Typography

const TwoFAConfirm = observer(({ store }: TwoFAConfirmProps) => {
  const { password, confirmTwoFA } = store

  useEffect(() => {
    if (!Boolean(password)) {
      goToRoute(Routes.SET_PASSWORD)
    }
  }, [password])

  const onClick = e => {
    e.preventDefault()
    goToRoute(`${Routes.RECOVERY_CODE}?source=resetPassword`)
  }

  return (
    <AuthForm title={translations().title}>
      <SendVerificationCode
        fullWidthButtons={true}
        onSubmit={async v => await confirmTwoFA(v.verificationCode, 'authorize_code')}
      />

      <div className={styles.link}>
        <Link href={'#'} onClick={onClick}>
          {translations().sendAnotherWay}
        </Link>
      </div>
    </AuthForm>
  )
})

export default TwoFAConfirm
