import React from 'react'
import { Button } from 'antd'
import page from 'page'
import { Routes } from '~/code/startup/Router/Routes'
import styles from '~/code/pages/settlements/SettlementsStatement/components/SettlementsFilters/SettlementsFilters.scss'
import translations from './translations'

translations.add('en', {
  title: 'Export POS payments',
  columns: 'Columns',
  cancel: 'Cancel',
  ok: 'Export',
  all: value => `All (${value})`,
  custom: 'Custom',
  errDownloadingStatement: 'Error downloading statement',
  successDownloadingStatement: (
    <span className={styles.downloadAsyncMsg}>
      Your download request has been queued for processing. Once the document is ready, it can be downloaded from the{' '}
      <Button type='link' onClick={() => page(Routes.EXPORTS)}>
        Exports
      </Button>{' '}
      section.
    </span>
  ),
  error: 'Error fetching transactions'
})
