import React from 'react'
import classNames from 'classnames'
import { SectionBlockMobileProps } from './props'
import styles from './styles.scss'

export const SectionBlockMobile: React.FC<SectionBlockMobileProps> = ({ className, notificationSetupForm }) => {
  const { title, description } = notificationSetupForm

  const renderHeader = () => (
    <div className={classNames(styles.header, styles[className])}>
      <div className={styles.logo}>
        <div className={classNames(styles.circle, styles.yellow, styles.mrFive)}></div>
        <div className={styles.bar}></div>
      </div>
      <div className={styles.userInfo}>
        <div className={styles.bar}></div>
        <div className={classNames(styles.circle, styles.mlFive)}></div>
      </div>
    </div>
  )

  const renderNotification = () => (
    <div className={styles.notificationWrapper}>
      <div>
        <span className={styles.title}>{title}</span>
      </div>
      <div>{description}</div>
    </div>
  )

  return (
    <div className={styles.previewWrapper}>
      {renderHeader()}
      <div className={styles.content}>
        <div className={styles.tableContainer}></div>
      </div>
      {renderNotification()}
    </div>
  )
}