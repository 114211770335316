export const MIN_DAILY_AMOUNT = 100
export const MAX_DAILY_AMOUNT_LENGTH = 6

export const MIN_DAILY_COUNT = 0
export const MAX_DAILY_COUNT_LENGTH = 5

export const MIN_DAILY_MAX_REFUND_AMOUNT = 10
export const MAX_DAILY_MAX_REFUND_AMOUNT_LENGTH = 5

export const MIN_MONTHLY_AMOUNT = 1000
export const MAX_MONTHLY_AMOUNT_LENGTH = 8

export const MIN_MONTHLY_COUNT = 0
export const MAX_MONTHLY_COUNT_LENGTH = 6

export const MIN_PER_REFUND = 10
export const MAX_PER_REFUND_LENGTH = 5

export const MIN_PER_TRANSACTION = 1
export const MAX_PER_TRANSACTION_LENGTH = 5

export const MIN_COMMENT_LENGTH = 2
export const MAX_COMMENT_LENGTH = 150

export const MIN_PER_DEVICE = 0
export const MAX_PER_DEVICE_LENGTH = 10