import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import { inject, injectable } from 'inversify'
import { message } from 'antd'
import { fetchChargebackDetails, fetchChargebacks } from '~/code/stores/ChargebacksStore/services/fetchers'
import { ChargeBacksRangePickerStoreSymbol, MerchantSelectStoreChargebacksSymbol } from '~/code/pages/Chargebacks'
import { IMerchantSelectStore } from '~/code/components/MerchantSelect/models'
import { IRangePickerContainerStore } from '~/code/pages/settlements/SettlementsPayments/components/RangePickerContainer/IRangePickerContainerStore'
import { StoreAndTerminalFilterStore } from '~/code/stores/StoreAndTerminalFilterStore'
import {
  ChargebackDetailsDataType,
  ChargebackDetailsType
} from '~/code/stores/ChargebacksStore/types/ChargebackDetailsType'
import { ChargebacksStoreType } from '~/code/pages/Chargebacks/types/ChargebacksStoreType'
import { ChargebacksType } from '~/code/stores/ChargebacksStore/types'
import { PeriodType } from '~/code/models'
import translations from './translations'

@injectable()
export class ChargebacksStore implements ChargebacksStoreType {
  merchantSelectStoreInjectable: IMerchantSelectStore
  rangePickerStoreInjectable: IRangePickerContainerStore
  storeAndTerminalFilterStore: StoreAndTerminalFilterStore

  isChargebacksLoading: boolean = false
  isChargebackDetailsLoading: boolean = false
  showDetailsModal: boolean = false
  chargebacks: ChargebacksType[] = []
  chargebackDetails: ChargebackDetailsType[] = []

  modalTitle: string = ''
  recordId: string = ''
  currentPage: number = 1
  total: number = 0
  size: number = 20

  constructor(
    @inject(MerchantSelectStoreChargebacksSymbol) merchantSelectStoreInjectable: IMerchantSelectStore,
    @inject(ChargeBacksRangePickerStoreSymbol) rangePickerStoreInjectable: IRangePickerContainerStore
  ) {
    this.merchantSelectStoreInjectable = merchantSelectStoreInjectable
    this.rangePickerStoreInjectable = rangePickerStoreInjectable
    this.storeAndTerminalFilterStore = new StoreAndTerminalFilterStore(this, 'all')

    makeObservable(this, {
      isChargebacksLoading: observable,
      isChargebackDetailsLoading: observable,
      showDetailsModal: observable,
      storeAndTerminalFilterStore: observable,
      chargebackDetails: observable,
      recordId: observable,

      merchantId: computed,
      acquisitionChannelId: computed,
      chargebacks: observable,
      currentPage: observable,
      total: observable,
      size: observable,
      modalTitle: observable,

      viewDetails: action.bound,
      loadChargebackDetails: action.bound,
      openDetailsModal: action.bound,
      refreshFilter: action.bound,
      setModalTitle: action.bound,
      loadChargebacks: action.bound
    })

    if (!this.rangePickerStoreInjectable.period) {
      this.rangePickerStoreInjectable.setPeriod(PeriodType.Day)
    }

    reaction(
      () => ({
        startDate: this.rangePickerStoreInjectable.startDate,
        endDate: this.rangePickerStoreInjectable.endDate,
        merchant: this.merchantSelectStoreInjectable.merchant,
        terminal: this.storeAndTerminalFilterStore.terminal
      }),
      () => {
        this.loadChargebacks({})
      }
    )
  }

  get merchantId() {
    return this.merchantSelectStoreInjectable.merchant?.id
  }

  get acquisitionChannelId() {
    return this.merchantSelectStoreInjectable.acquisitionChannel?.id
  }

  setModalTitle(value: string) {
    this.modalTitle = value
  }

  openDetailsModal(value: boolean) {
    this.showDetailsModal = value
  }

  viewDetails(data: ChargebackDetailsDataType) {
    this.loadChargebackDetails(data.id)
    this.setModalTitle(`${data.merchantName}`)
    this.openDetailsModal(true)
  }

  refreshFilter() {
    this.loadChargebacks({})
  }

  async loadChargebackDetails(id: string) {
    runInAction(() => {
      this.isChargebackDetailsLoading = true
      this.recordId = id
    })

    try {
      const { status, result, error } = await fetchChargebackDetails(id)

      if (status === 200) {
        runInAction(() => {
          this.chargebackDetails = result
        })
      } else {
        throw new Error(error.message || translations().fetchChargebackDetailsError)
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      runInAction(() => (this.isChargebackDetailsLoading = false))
    }
  }

  async loadChargebacks({ page, size }: { page?: number; size?: number }) {
    const { startDate, endDate } = this.rangePickerStoreInjectable
    const { terminalsForFilter } = this.storeAndTerminalFilterStore

    runInAction(() => {
      this.isChargebacksLoading = true
      this.currentPage = page ? page : 1
      this.size = size ? size : this.size
    })

    const params = {
      from: startDate.format('YYYY-MM-DD'),
      to: endDate.format('YYYY-MM-DD'),
      merchantId: this.merchantId === 'all' ? undefined : this.merchantId,
      acquisitionChannel: this.acquisitionChannelId === 'all' ? undefined : this.acquisitionChannelId,
      terminalId: terminalsForFilter.length > 0 ? terminalsForFilter.join(',') : undefined,
      page: this.currentPage,
      size: this.size
    }

    try {
      const { status, result, error } = await fetchChargebacks(params)

      if (status === 200) {
        runInAction(() => {
          this.chargebacks = result.data
          this.total = result.totalCount
        })
      } else {
        throw new Error(error.message || translations().fetchChargebacksError)
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      runInAction(() => (this.isChargebacksLoading = false))
    }
  }
}
