import translations from './translations'

translations.add('en', {
    status: 'Status',
    viewDetails: 'View Details',
    paymentStatuses: {
        all: 'All',
        new: 'New',
        waiting_approval: 'Waiting approval',
        processing: 'Processing',
        failed: 'Failed',
        settled: 'Settled',
        rejected: 'Rejected'
    },
    tableHeader: {
        totalSumTitle: 'Total sum of successful transactions',
        totalSumShortTitle: 'Total sum'
    },
    tableActionButtons: {
        sendForApproval: 'Send for approval',
        approve: 'Approve',
        sendForApprovalSelected: (count: number) => `Send for approval ${count} ${count > 1 ? 'items' : 'item'}`,
        approveSelected: (count: number) => `Approve ${count} ${count > 1 ? 'items' : 'item'}`,
        pleaseConfirmYourAction: 'Please confirm your action',
        areYouSureToSendForApproval: 'Are you sure that you want to send the selected payments to approval?',
        areYouSureToApprove: 'Are you sure that you want to approve the selected?'
    },
    tableColumns: {
        postingDate: 'Posting Date',
        merchant: 'Merchant',
        amountToMerchant: 'Amount to Merchant',
        currency: 'Currency',
        status: 'Status',
        message: 'Message',
        sortCode: 'Sort Code',
        accountNumber: 'Account Number',
        documentId: 'Document ID',
        reference: 'Reference',
        details: 'Details',
        action: 'Action'
    },
    paymentHistory: {
        paymentStatusHistory: 'Payment Status History',
        sentForApprovalBy: (email) => `Sent for approval by ${email}`,
        initiatedBy: (email) => `Approved by ${email}`,
        paymentHistoryNotAvailable: 'The payment history is not available',
        sentForApproval: 'Sent for approval',
        paymentSentToClearBank: 'Payment sent to ClearBank'
    }
})
