import React from 'react'
import { Dot, StatusDetails, ChartCard } from '..'
import { RatesCardProps } from './props'
import translations from './translations'
import styles from './RatesCard.scss'

export const RatesCard: React.FC<RatesCardProps> = ({ data, isLoading, title }) => (
    <ChartCard
        title={title}
        loading={isLoading}
        footer={<div>{translations().total} {data?.total}</div>}
    >
        {data && (
            <div>
                <div className={styles.successfulContainer}>
                    <div className={styles.successfulLabel}>
                        <Dot status='successful' />
                        <div>{translations().successful}</div>
                    </div>
                    <div className={styles.successfulValues}>
                        <div className={styles.percentage}>{data.successful.percentage}</div>
                        <div>{data.successful.value}</div>
                    </div>
                </div>
                <div className={styles.failedContainer}>
                    <div className={styles.failedLabel}>
                        <Dot status='failed' />
                        <div>{translations().failedTransactionsReasons}</div>
                    </div>
                </div>
                <div>
                    {data.failed.map((failed, index) => (
                        <StatusDetails
                            // title = Failed other
                            key={index}
                            title={translations()[failed.reason] || failed.reason}
                            percent={failed.percentage}
                            value={failed.value}
                        />
                    ))}
                </div>
                {data.other && (
                    <div>
                        <StatusDetails
                            status='other'
                            percent={data.other.percentage}
                            value={data.other.value}
                        />
                    </div>
                )}
            </div>
        )}
    </ChartCard>
)
