import React from 'react'
import { Modal, ModalProps } from 'antd'
import styles from './CustomModal.scss'

export const CustomModal = (props: ModalProps) => {
  const { children, ...rest } = props
  return (
    <Modal className={styles.styledModal} {...rest}>
      {children}
    </Modal>
  )
}
