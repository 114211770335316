import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  tids: string
  icPlus: string
  blended: string
  transactionFees: string
  diners: string
  baseLabel: string
  percentLabel: string
  percentPlaceholder: string
  disableFee: (disabled: boolean) => string
  discoverGlobalDescription: string
  amex: string
  amexDescription: string
  chinaUnionPay: string
  payByBank: string
  payByBankDescription: string
  chinaUnionPayDescription: string
  authorisationOkFee: string
  authorisationFee: string
  zeroPlaceholder: string
  authorisationOkFeeDescription: string
  nonTransactionFees: string
  refundFee: string
  refundFeeDescription: string
  chargebackFee: string
  chargebackFeeDescription: string
  pciFee: string
  pciFeeDescription: string
  zashTitle: string
  zashSolution: string
  zAndPayByBankApp: string
  zashApp: string
  zashAppDescription: string
  paymentFacilitatingServices: string
  chargedAtOnce: string
  terminalPurchase: string
  regularCharges: string
  airtimeServiceDescription: string
  airtimeService: string
  paymentTerminalSupportAndTransactionFeeDescription: string
  paymentTerminalSupportAndTransactionFee: string
  gatewayServices: string
  other: string
  gatewaySetUpFee: string
  gatewaySetUpFeePerTransaction: string
  feeForEachTransaction: string
  monthlyGatewayFee: string
  monthlyGatewayFeeDescription: string
  minMonthlyServiceCharge: string
  minMonthlyServiceChargeDescription: string
  headlineRate: string
  dnapConsumerCredit: string
  dnapConsumerDebit: string
  dnapCorporateCredit: string
  dnapCorporateDebit: string
  transactionType: string
  cardScheme: string
  location: string
  corporateOrConsumer: string
  cardType: string
  baseFee: string
  percentFee: string
  save: string
  mcUkConsumerDebit: string
  mcUkConsumerCredit: string
  mcUkCorporateDebit: string
  mcUkCorporateCredit: string
  visaUkConsumerDebit: string
  visaUkConsumerCredit: string
  visaUkCorporateDebit: string
  visaUkCorporateCredit: string
  mcEuConsumerDebit: string
  mcEuConsumerCredit: string
  mcEuCorporateDebit: string
  mcEuCorporateCredit: string
  visaEuConsumerDebit: string
  visaEuConsumerCredit: string
  visaEuCorporateDebit: string
  visaEuCorporateCredit: string
  mcIntConsumerDebit: string
  mcIntConsumerCredit: string
  mcIntCorporateDebit: string
  mcIntCorporateCredit: string
  visaIntConsumerDebit: string
  visaIntConsumerCredit: string
  visaIntCorporateDebit: string
  visaIntCorporateCredit: string
  confirmationMessage: string
  comment: string
  settlementPeriod: string
  settlementFee: string
  fasterFundingFee: string
  fasterFundingMonthly: string
  settlementPeriodDescription: string
  settlementFeeDescription: string
  fasterFundingFeeDescription: string
  gatewayFee: string
  openBanking: string
  klarna: string
  chargebackFeeVisaGBP: string
  chargebackFeeMCGBP: string
  chargebackFeeVisaEUR: string
  chargebackFeeMCEUR: string
  declineAuthenticationFeeVisaGBP: string
  declineAuthenticationFeeMCGBP: string
  declineAuthenticationFeeVisaEUR: string
  declineAuthenticationFeeMCEUR: string
  monthlyFeeVisaEUR: string
  monthlyFeeMCEUR: string
  refundFeeVisaGBP: string
  refundFeeMCGBP: string
  refundFeeVisaEUR: string
  refundFeeMCEUR: string
  retrievalRequestFeeVisaEUR: string
  retrievalRequestFeeMCEUR: string
  transactionFeeVisaGBP: string
  transactionFeeMCGBP: string
  transactionFeeVisaEUR: string
  transactionFeeMCEUR: string
  wireFeeVisaEUR: string
  wireFeeMCEUR: string
  contractRates: string
  cardNotPresentFee: string
  effectiveFrom: string
  aliPay: string
  weeChat: string
}>()
