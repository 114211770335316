import translations from './translations'

translations.add('en', {
  yes: 'Yes',
  no: 'No',
  isSeasonal: 'is the business seasonal',
  qLabel: 'How would you split your trade by quarter?',
  q1Label: 'Q1 (%)',
  q2Label: 'Q2 (%)',
  q3Label: 'Q3 (%)',
  q4Label: 'Q4 (%)',
  annualCardTurnover: 'Expected annual card turnover (£)',
  averageTransaction: 'Average transaction value (£)',
  numberTransactionPerMonth: 'Number of transactions per month',
  highestTransaction: 'Highest transaction value (£)',
  isSeasonalBusiness: 'Is the business seasonal?'
})
