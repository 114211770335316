import { inject, injectable } from 'inversify'
import { makeObservable, observable, action, reaction } from 'mobx'
import { IStoresDossierV2Store } from '~/code/pages/MerchantsManagement/components/Stores/models'
import { StoresDossierV2StoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores'
import { IFinancialInformationStore } from '~/code/pages/MerchantsManagement/components/FinancialInformation/IFinancialInformationStore'
import { StoreType, PhysicalStoreAttributesType } from './models/merchant-dossier-v2'

@injectable()
export class FinancialInformationStore implements IFinancialInformationStore {
  storesDossierV2Store: IStoresDossierV2Store
  selectedStore: StoreType | null = null
  constructor(@inject(StoresDossierV2StoreSymbol) storesDossierV2Store: IStoresDossierV2Store) {
    this.storesDossierV2Store = storesDossierV2Store

    makeObservable(this, {
      selectedStore: observable,
      setSelectedStore: action,
      getMids: action,
      getProduct: action
    })
  }

  setSelectedStore(store: StoreType | null) {
    this.selectedStore = store
  }

  getMids(contractItem: PhysicalStoreAttributesType) {
    return this.storesDossierV2Store.getMidsFromContractInfo(contractItem)
  }

  getProduct(contractItem: PhysicalStoreAttributesType) {
    return this.storesDossierV2Store.defineProductType(
      contractItem.terminals?.map(terminal => terminal?.terminal?.terminalId)
    )
  }
}
