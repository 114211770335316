import { observer } from 'mobx-react'
import React from 'react'
import { Grid } from 'antd'

import { PageHeader, MobileRangePicker, DesktopRangePicker } from '~/code/components'
import { goToRoute } from '~/code/startup/Router/utils'
import { TabType } from '~/code/models'

import { ChangeRiskLimitsTabEnum } from './models'
import { ChangeRiskLimitsProps } from './props'
import translations from './translations'

const { useBreakpoint } = Grid

const tabList: TabType[] = [
    {
        key: ChangeRiskLimitsTabEnum.submittedRequests,
        label: translations().submittedRequests
    }
]

export const Requests = observer((props: ChangeRiskLimitsProps) => {
    const { activeTab, children, store } = props
    const screens = useBreakpoint()

    let tabBarExtraContent = <div></div>

    if (store) {
        tabBarExtraContent = screens.xs ? <MobileRangePicker store={store.dateStore} /> : <DesktopRangePicker store={store.dateStore} />
    }

    return <div>
        <PageHeader
            tabActiveKey={activeTab}
            tabList={tabList}
            onTabChange={(key) => goToRoute(`/bpm-processes/${key}`)}
            tabBarExtraContent={tabBarExtraContent}
        />
        {children}
    </div>
})
