import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from '~/code/services'
import {
  RoleType,
  PermissionsSystemIdType,
  TeammatePostRequestType,
  TeammatePutRequestType,
  TeammateRequestType,
  TeammateResponseType
} from './models'

import { EnforceTwoFAStatusParamsType } from '~/code/stores/Profile/models'

const API = 'auth/oauth2/teammates'

export const fetchTeammates = (params: TeammateRequestType = {}) => {
  return getWithAuth<TeammateResponseType>(API, params)
}

export const fetchRolesWithPermissions = (systemId?: PermissionsSystemIdType) => {
  const params = systemId ? { systemId } : undefined
  return getWithAuth<RoleType[]>('auth/oauth2/permissions', params)
}

export const fetchSpecificPermissions = () => {
  return getWithAuth<string[]>('auth/oauth2/permissions/exclusive ')
}

export const inviteTeammate = (data: TeammatePostRequestType) => postWithAuth<any>(API, data)

export const updateTeammate = (id: string, data: TeammatePutRequestType) => putWithAuth<any>(`${API}/${id}`, data)

export const deleteTeammate = (id: string) => deleteWithAuth<any>(`${API}/${id}`)

export const resendTeammate = (id: string) => postWithAuth<any>(`${API}/${id}/resend`, {})

export const updateTeammateTwoFASettings = (id: string, params: EnforceTwoFAStatusParamsType) =>
  putWithAuth(`/api/v1/teammates/${id}/2fa/settings`, params)