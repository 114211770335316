import { Checkbox } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { MultiSelectItemProps } from './props'
import styles from './MultiSelectItem.scss'

export const MultiSelectItem: React.FC<MultiSelectItemProps> = ({ selected, onClick, title, children, style }) => <div
    className={classNames(styles.MultiSelectItem, { selected })}
    onClick={onClick}
    style={style}
>
    <Checkbox checked={selected} />
    <span className={styles.text} title={title}>{children}</span>
</div> 