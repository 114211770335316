import { Col, Row, Typography } from 'antd'
import React from 'react'
import { ThreeDs } from '~/assets/icons'
import { SwitchTumbler } from '~/code/pages/TransactionChannels/components'
import { TransactionChannelToggleType } from '../../models'
import { TransactionChannel } from '../TransactionChannel'
import translations from '../../translations'
import { EcomTransactionChannelProps } from './props'
import styles from './styles.scss'

export const EcomTransactionChannel: React.FC<EcomTransactionChannelProps> = (props) => {
    const { transactionChannel, isLoading, isOnlyOneTransactionChannelEnabled, updateTransactionChannel } = props

    const makeUpdateTransactionChannel = (toggleType: TransactionChannelToggleType) => {
        return (toggleValue: boolean, comment?: string) => {
            return updateTransactionChannel(transactionChannel.transactionChannelId, toggleType, toggleValue, comment)
        }
    }

    return <TransactionChannel
        isLoading={isLoading}
        isOnlyOneTransactionChannelEnabled={isOnlyOneTransactionChannelEnabled}
        updateTransactionChannel={makeUpdateTransactionChannel('isDisabled')}
        transactionChannel={transactionChannel}
    >
        <Row justify={'space-between'} className={styles.tumblerWrapper}>
            <Col md={12}>
                <Typography.Title level={5}>{translations().isAuthorisationDisabledLabel}:</Typography.Title></Col>
            <Col md={12}>
                <SwitchTumbler
                    isEnabled={!transactionChannel.isTdsOneDisabled}
                    toggleText={`${translations().isAuthorisationDisabledLabel} ${translations().onLowercased} ${transactionChannel.channelName}`}
                    isLoading={isLoading}
                    updateTransactionChannel={makeUpdateTransactionChannel('isTdsOneDisabled')}
                    isOnlyOneTransactionChannelEnabled={isOnlyOneTransactionChannelEnabled}
                    switchType={'Authorisation'}
                    isDisabled={false}
                />
            </Col>
        </Row>

        <Row justify={'space-between'}>
            <Col md={12}><ThreeDs/></Col>
            <Col md={12}>
                <SwitchTumbler
                    isEnabled={!transactionChannel.isTdsTwoDisabled}
                    toggleText={`${translations().isTdsTwoDisabledLabel} ${translations().onLowercased} ${transactionChannel.channelName}`}
                    isLoading={isLoading}
                    updateTransactionChannel={makeUpdateTransactionChannel('isTdsTwoDisabled')}
                    isOnlyOneTransactionChannelEnabled={isOnlyOneTransactionChannelEnabled}
                    switchType={'3DS v2.x'}
                    isDisabled={false}
                />
            </Col>
        </Row>
    </TransactionChannel>
}
