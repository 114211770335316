import translations from './translations'
import React from 'react'
import { SubLabel } from '~/code/pages/StartApplication/components'
import styles from '../styles.scss'

translations.add('en', {
    info: 'Info',
    merchant: 'Merchant',
    email: 'Email address',
    companyWebsite: 'Company website',
    firstName: 'First name',
    surname: 'Surname',
    phoneNumber: 'Phone number',
    businessStructure: 'Business structure',
    acquisitionChannel: 'Application source',
    status: 'Status',
    paymentSolution: {
        label: 'Payment solution',
        value: (paymentSolution) => {
            switch (paymentSolution) {
                case 'pay-by-link':
                    return <>Pay by Link only</>
                case 'pos':
                    return <><span className={styles.paymentSolutionSpan}>POS</span><SubLabel>(In store POS terminals)</SubLabel></>
                case 'website-payments':
                    return <><span className={styles.paymentSolutionSpan}>Website Payments</span><SubLabel>(Pay by Link included)</SubLabel></>
                default:
                    return <></>
            }
        }
    },
    source: 'Source',
    applicant: 'Applicant',
    productCompany: 'Product & Company'
})
