import React from 'react'
import { Form, Row, Col, Input, Radio } from 'antd'
import { FormSubgroupTitle } from 'startapp/components'
import { StoreType } from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v2'
import { quarterTotal } from './services'
import translations from './translations'
import styles from './FinancialInformationForm.scss'

export const FinancialInformationForm = (props: { store: StoreType }) => {
  const [form] = Form.useForm()
  const { storeName } = props.store?.storeInfo || {}

  const {
    seasonalBusiness,
    quarter1,
    quarter2,
    quarter3,
    quarter4,
    expAnnualCardTurnover,
    averageTransactionValue,
    numberTransactionPerMonth,
    highestTransactionValue
  } = props.store.natureAccountTransaction?.declaredTradeCharacteristic || {}

  const initValues = {
    seasonalBusiness: !!seasonalBusiness,
    quarter1: quarter1 || 0,
    quarter2: quarter2 || 0,
    quarter3: quarter3 || 0,
    quarter4: quarter4 || 0,
    expAnnualCardTurnover: expAnnualCardTurnover || 0,
    averageTransactionValue: averageTransactionValue || 0,
    numberTransactionPerMonth: numberTransactionPerMonth || 0,
    highestTransactionValue: highestTransactionValue || 0
  }

  return (
    <Form
      form={form}
      autoComplete='off'
      layout='vertical'
      requiredMark={'optional'}
      className={styles.form}
      initialValues={initValues}
      disabled
    >
      <FormSubgroupTitle className={styles.header}>{storeName}</FormSubgroupTitle>
      <Row gutter={[64, 0]}>
        <Col xs={24}>
          <Form.Item name='seasonalBusiness' label={translations().isSeasonalBusiness} required>
            <Radio.Group>
              <Radio value={true}>{translations().yes}</Radio>
              <Radio value={false}>{translations().no}</Radio>
            </Radio.Group>
          </Form.Item>
          {seasonalBusiness && (
            <Form.Item noStyle shouldUpdate>
              {() => {
                return (
                  <Form.Item name='quarter' label={translations().qLabel} trigger='onBlur' required>
                    <Row gutter={16} className={styles.quarterFieldsContainer}>
                      <Col xs={24} md={5}>
                        <Form.Item name='quarter1' label={translations().q1Label} required>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={5}>
                        <Form.Item name='quarter2' label={translations().q2Label} required>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={5}>
                        <Form.Item name='quarter3' label={translations().q3Label} required>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={5}>
                        <Form.Item name='quarter4' label={translations().q4Label} required>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={4}>
                        <Form.Item label=' ' required>
                          ={' '}
                          {quarterTotal(
                            initValues.quarter1,
                            initValues.quarter2,
                            initValues.quarter3,
                            initValues.quarter4
                          )}
                          %
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                )
              }}
            </Form.Item>
          )}
          <Form.Item name='expAnnualCardTurnover' label={translations().annualCardTurnover} required>
            <Input />
          </Form.Item>
          <Form.Item name='averageTransactionValue' label={translations().averageTransaction} required>
            <Input />
          </Form.Item>
          <Form.Item name='numberTransactionPerMonth' label={translations().numberTransactionPerMonth} required>
            <Input />
          </Form.Item>
          <Form.Item name='highestTransactionValue' label={translations().highestTransaction} required>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
