import React from 'react'
import { Typography } from 'antd'
import { observer } from 'mobx-react'
import { SendVerificationCode } from '~/code/components'
import { AuthForm } from '~/code/components/auth/AuthForm'

import { TwoFAProps } from './props'
import translations from './translations'
import styles from './styles.scss'
import { Routes } from '~/code/startup/Router/Routes'

const { Link } = Typography

export const TwoFASymbol = Symbol('TwoFASymbol')

const TwoFA = observer(({ store }: TwoFAProps) => {
  const { updateTokenTwoFA, backToLogin } = store

  return (
    <AuthForm title={translations().title}>
      <div className={styles.back}>
        <Link onClick={backToLogin}>{translations().back}</Link>
      </div>
      <SendVerificationCode
        fullWidthButtons={true}
        onSubmit={async v => {
          await updateTokenTwoFA(v.verificationCode, 'authorize_code')
        }}
      />

      <div className={styles.link}>
        <Link href={Routes.RECOVERY_CODE} onClick={() => {}}>
          {translations().sendAnotherWay}
        </Link>
      </div>
    </AuthForm>
  )
})

export default TwoFA
