import { observer } from 'mobx-react'
import React from 'react'
import { ContactInfoForm, StartAppContainer } from '..'
import { ContactInfoProps } from './props'

export const ContactInfo = observer((props: ContactInfoProps) => {
    const { store } = props

    return <StartAppContainer>
        <ContactInfoForm
            initialValues={store.contactInfoData}
            isMoreFormFieldsVisible={store.isMoreFormFieldsVisible}
            isApplicationWaitingForSubmission={store.isApplicationWaitingForSubmission}
            isNextButtonLoading={store.isLoading || store.isCheckSalesforceOpportunityIDLoading}
            isAcquisitionChannelsLoading={store.isAcquisitionChannelsLoading}
            acquisitionChannels={store.acquisitionChannels}
            onEmailChange={store.onEmailChange}
            onFinish={store.setContactInfoData}
            onContinue={store.loadApplicationById}
            checkSalesforceOpportunityID={store.checkSalesforceOpportunityID}
        />
    </StartAppContainer>
})