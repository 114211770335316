import React from 'react'
import Helmet from 'react-helmet'
import { observer } from 'mobx-react'
import translations from './translations'
import { PageTitleProps } from './props'

const PageTitle: React.FC<PageTitleProps> = observer(({ store }) => {
    return (
        <Helmet>
            <title>{store?.currentPageTitle ? store?.currentPageTitle : translations().defaultTitle}</title>
        </Helmet>
    )
})

export { PageTitle }
