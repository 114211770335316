import translations from './translations'

translations.add('en', {
  startDate: 'Start Date',
  merchant: 'Merchant',
  status: 'Status',
  assignedGroup: 'Assigned Group',
  assignedUser: 'Assigned User',
  reviewTime: 'Review Time',
  endDate: 'End Date',
  mid: 'Mid',
  contractNumber: 'Contract Number',
  drawerTitle: 'Change of bank details',
  bankStatement: 'Bank Statement',
  other: 'Other',
  initiator: 'Initiator',
  comment: 'Comment'
})
