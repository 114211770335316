import React from 'react'
import { observer } from 'mobx-react'
import { Card, Col, Form, Row, Select } from 'antd'
import { ChangeOfSettlementsPeriodRequestsRangePickerStoreSymbol } from '~/code/stores/ChangeOfSettlementPeriodRequestStore/ChangeOfSettlementPeriodRequestStore'
import { RangePickerContainer } from '~/code/pages/settlements/SettlementsPayments/components'
import translations from '../../translations'
import { IChangeOfSettlementPeriodRequestStore } from '~/code/pages/BPMProcesses/Requests/components/ChangeOfSettlementPeriodRequests/models/IChangeOfSettlementPeriodRequestStore'
import { MerchantSelect } from '../../../shared/MerchantSelect'
import styles from './SettlementPeriodRequestFilter.scss'

interface SettlementPeriodRequestsFilterProps {
  store: IChangeOfSettlementPeriodRequestStore
}

export const SettlementPeriodRequestsMerchantSelectStoreSymbol = Symbol('SettlementPeriodRequestsMerchantSelectStore')

export const SettlementPeriodRequestsFilter = observer((props: SettlementPeriodRequestsFilterProps) => {
  const { store } = props

  return (
    <Card>
      <Row justify={'space-between'}>
        <Col xs={24} sm={12}>
          <Form wrapperCol={{ span: 12 }} labelAlign='left' layout='vertical'>
            <MerchantSelect merchantSelectStoreSymbol={SettlementPeriodRequestsMerchantSelectStoreSymbol} />
            <Form.Item label={translations().status}>
              <Select
                className={styles.merchantSelect}
                placeholder={translations().selectStatus}
                options={store.statuses}
                disabled={store.statuses.length <= 1}
                loading={store.isStatusesLoading}
                value={store.status}
                onChange={v => store.setStatus(v)}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} sm={12}>
          <RangePickerContainer injectableStoreIdentifier={ChangeOfSettlementsPeriodRequestsRangePickerStoreSymbol} />
        </Col>
      </Row>
    </Card>
  )
})
