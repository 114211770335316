import React from 'react'
import { Carousel as AntdCarousel } from 'antd'
import { CarouselProps, CarouselRef } from 'antd/lib/carousel'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import styles from './Carousel.scss'

interface Props extends CarouselProps{
    slides: React.ReactNode[]
}

export const Carousel = React.forwardRef<CarouselRef, Props>((props, ref) => {
    const { slides, ...rest } = props
    return <AntdCarousel
        {...rest}
        arrows={true}
        infinite={false}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow/>}
        className={styles.Carousel}
        ref={ref}
    >
        {slides.map((slide, i) => <div className={styles.slide} key={i}>{slide}</div>)}
    </AntdCarousel>
})

const withArrow = (svgIcon: React.ReactNode) => (props: any) => {
    const { className, style, onClick } = props
    return <div className={className} style={style} onClick={onClick}>{svgIcon}</div>
}

const PrevArrow = withArrow(<LeftOutlined />)
const NextArrow = withArrow(<RightOutlined />)
