import React from 'react'
import { observer } from 'mobx-react'
import { Card, Col, Row, Select } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { Page } from '../../Page'
import { ISettlementsPaymentsStore } from '~/code/pages/settlements/SettlementsPayments/ISettlementsPaymentsStore'
import { RangePickerContainer } from '~/code/pages/settlements/SettlementsPayments/components/RangePickerContainer/RangePickerContainer'
import { SettlementsPaymentsTableContainer } from '~/code/pages/settlements/SettlementsPayments/components/SettlementsPaymentsTableContainer/SettlementsPaymentsTableContainer'
import { MerchantSelect } from '~/code/components'
import { PaymentStatuses } from './constants/PaymentStatuses'
import translations from './translations'
import styles from './SettlementsPayments.scss'

const Option = Select.Option

export const MerchantSelectStoreSettlementsPaymentsSymbol = Symbol('MerchantSelectStoreSettlementsPayments')

/**
 * A constant symbol that indicates what store needs to be injected into the current page
 */
export const SettlementsPaymentsStoreSymbol = Symbol('SettlementsPaymentsStore')
export const SettlementsPaymentsRangePickerStoreSymbol = Symbol('SettlementsPaymentsRangePickerStore')

export const SettlementsPayments = observer(() => {
  const store = useInjection<ISettlementsPaymentsStore>(SettlementsPaymentsStoreSymbol)

  const renderStatusSelectOptions = () => {
    return Object.keys(PaymentStatuses).map(status => {
      return (
        <Option value={PaymentStatuses[status]} key={status}>
          {translations().paymentStatuses[PaymentStatuses[status]]}
        </Option>
      )
    })
  }

  return (
    <Page>
      <Card key={'filterContainer'} className={styles.SettlementsPayments}>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={7}>
            <MerchantSelect storeSymbol={MerchantSelectStoreSettlementsPaymentsSymbol} />
          </Col>
          <Col xs={24} md={7}>
            <div>
              <span className={styles.statusLabel}>{translations().status}</span>
              <Select
                value={store.selectedPaymentStatus}
                className={styles.statusSelect}
                onChange={store.handlePaymentStatusChange}
              >
                {renderStatusSelectOptions()}
              </Select>
            </div>
          </Col>
          <Col xs={24} md={10}>
            <div className={styles.rangePickerContainer}>
              <RangePickerContainer injectableStoreIdentifier={SettlementsPaymentsRangePickerStoreSymbol} />
            </div>
          </Col>
        </Row>
      </Card>

      <Card key={'tableContainer'}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <SettlementsPaymentsTableContainer />
          </Col>
        </Row>
      </Card>
    </Page>
  )
})
