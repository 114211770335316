import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  filename: string
  period: string
  subsidiary: string
  download: string
  downloadToolip: string
  reset: string
}>()
