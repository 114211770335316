export enum ProcessingMethodsTypes {
    POS = 'pos',
    MOTO = 'moto',
    ECOM = 'ecom',
    PAYBYLINK = 'payByLink',
    PAYBYAPP = 'payByApp',
    VIRTUALTERMINAL = 'virtualTerminal'
}

export interface ProcessingMethodsModel {
    description: string,
    name: string,
    percent: number | string
}

export type ProcessingMethodsFormStateModel = Partial<Record<ProcessingMethodsTypes, ProcessingMethodsModel>>

export const processingMethodsInitialValues: ProcessingMethodsFormStateModel = {
    pos: {
        description: '',
        name: '',
        percent: null
    },
    moto: {
        description: '',
        name: '',
        percent: null
    },
    ecom: {
        description: '',
        name: '',
        percent: null
    },
    payByApp: {
        description: '',
        name: '',
        percent: null
    },
    payByLink: {
        description: '',
        name: '',
        percent: null
    },
    virtualTerminal: {
        description: '',
        name: '',
        percent: null
    }
}