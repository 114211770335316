import { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  title: string
  titleRequired: string
  dateBirth: string
  dateBirthRequired: string
  dateBirthInvalid: string
  dateBirthAgeLimit: string
  gender: string
  genderRequired: string
  name: string
  nameRequired: string
  middleName: string
  middleNameRequired: string
  surname: string
  surnameRequired: string
  houseName: string
  houseNumber: string
  addressTitle: string
  street: string
  streetRequired: string
  street2: string
  city: string
  cityRequired: string
  cityInvalid: string
  region: string
  country: string
  countryRequired: string
  countryResidence: string
  countryResidenceRequired: string
  postCode: string
  postCodeRequired: string
  postCodeInvalid: string
  nationality: string
  nationalityRequired: string
  emailAddress: string
  emailAddressRequired: string
  emailFormatIncorrect: string
  telephoneNumber: string
  telephoneNumberRequired: string
  telephoneNumberWrongFormat: string
  occupation: string
  occupationRequired: string
  placeBirth: string
  placeBirthRequired: string
  personalInfo: string
  contactInfo: string
  submit: string
  back: string
  ownershipPercentage: string
  ownershipPercentageRequired: string
  titles: {
    mr: string
    mrs: string
    miss: string
    ms: string
  }
  genders: {
    male: string
    female: string
    other: string
  }
  checkPassed: string
  checkFailed: string
  verify: string
  checkResult: (result: string) => string
  modalTitle: string
  save: string
  complete: string
  welcomeText1: string
  welcomeText2: string
  backToForm: string
  pleaseDoubleCheck: string
  detailsInfo: (firstName: string, lastName: string, dob: string, address: string, postalCode: string) => ReactNode
  detailAreCorrect: string
  verificationResult: string
  idVerifResult: (result: boolean) => ReactNode
  addressVerifResult: (result: boolean) => ReactNode
  idVerifResult1: (result: boolean) => ReactNode
  addressVerifResult1: (result: boolean) => ReactNode
  verificationWarn: () => ReactNode
  cancel: string
  agreementSignatory: string
  agreementSignatoryInfo: string
  automatedIdentityVerification: string
  whatsNext: string
  twoOptions: string
  optionOne: string
  optionTwo: string
  jumioRequest: string
  sendJumioRequest: string
  close: string
  editDetails: string
  startJumioRequestInfo: string
  startJumioRequestInfo2: ReactNode
  jumioVerificationSent: string
  sendJumioLink: string
  uploadDoc: string
  saveForLater: string
  updateDetails: string
  resetChecks: string
  refreshStatus: string
  jumioStatus: string
  detailsEdit: string
  verifResult: (status: string) => ReactNode
  hasComplete: (email: string) => ReactNode
  docsNotRequired: string
  jumioVerifResult: (idVerif: boolean, addressVerif: boolean) => ReactNode
  recepientEmail: (email: string) => string
  userExists: string
  areYouSure: string
  docModalText: string
  uploadDocsChoosen: string
  resendText: string
  resend: string
  idNumber: string
  idExpDate: string
  uploadUtilityBill: string
  vulnerableStatus: string
  visualDisability: string
  audioDisability: string
  lifeEvent: string
  resilience: string
  capability: string
  vulnerabilityInfo: string
  addDetails: string
  vulnerabilityError: string
  chooseAnother: string
}>()
