import translations from './translations'

translations.add('en', {
    terminalIssueTitle: 'Additional Terminal Issue',
    mid: 'MID',
    newMid: 'Existing MID',
    posModel: 'POS Model',
    productType: 'Product Type',
    terminalsQuantity: 'Terminals Quantity',
    businessKey: 'Unique process number',
    newTariff: 'New Tariffs',
    moto: 'MOTO',
    required: 'Required',
    qntError: 'Terminals quantity must be in 1 - 100 range',
    descriptor: 'Descriptor:',
    contractNumber: 'Contract Number:',
    address: 'Address:',
    submit: 'Submit',
    clear: 'Clear',
    contractType: 'Contract Type',
    byDNA: 'Chargeable by DNA',
    byPaytek: 'Chargeable by Paytek',
    subscriptionServicePeriod: 'Subscription Service Period (in months)',
    freeSubscriptionPeriod: 'Free Subscription Period (in months)',
    feeSubscriptionPerMonth: 'Subscription Fee Per Month',
    terminalCost: 'Terminal Cost',
    setUpConfigurationDeliveryFee: 'Set-Up, Configuration and Delivery (£)',
    subscription: 'Subscription',
    terminalPurchase: 'Terminal purchase',
    ownTerminal: 'TID ONLY - Existing Optomany Terminal',
    notZero: 'Value cannot be negative or zero',
    posBundles: 'Bundles',
    posDeliveryAddress: 'POS delivery address',
    sameAsCompany: 'Same As Company Address',
    sameAsStore: 'Same As Store Address',
    noMoreThan: (value: number) => `Value cannot be 0 or more than ${value}`,
    selectedDeliveryAddress: 'Selected POS delivery address',
    manualAddress: {
        title: 'Manual',
        countryTooltip: 'We are currently only able to work with UK registered businesses',
        countryLabel: 'Country',
        countryRequired: 'Country is required',
        postalCodeLabel: 'Postal Code',
        postalCodeRequired: 'Postcode is required',
        addressLine1Label: 'Address line 1',
        addressLine1Required: 'Address line 1 is required',
        addressLine2Label: 'Address line 2 ',
        addressLine2Required: 'address line 2 is required',
        cityLabel: 'Town/City',
        cityRequired: 'Town/city is required',
        cityInvalid: 'Only alphanumeric characters are allowed',
        postalCodeInvalid: 'Invalid postcode'
    },
    UK: 'United Kingdom',
    deliveryInstructions: 'Delivery Instructions',
    deliveryInstructionsPlaceholder: 'Enter pos delivery instructions',
    deliveryContactNumber: 'Contact number for delivery'
})
