import React from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  acquiringServicesFee: string
  icPlusFeeStructure: string
  transactionFees: string 
  processingFee: string 
  description: string
  debitConsumer: string
  creditConsumer: string
  debitCorporate: string
  creditCorporate: string
  debitCreditDescription: string
  interchangeFee: string
  schemeFee: string
  dnaStandardFee: string
  headlineRate: string
  totalFee: string
}>()
