import React from 'react'
import { TeammateStatus } from '~/code/stores/TeammatesStore/constants/TeammateStatus'
import { Status } from '~/code/components'
import { TagType } from '~/code/models'

import translations from '~/code/stores/TeammatesStore/translations'

export const getTeammateStatusTag = (status: TeammateStatus) => {
  switch (status) {
    case TeammateStatus.block:
      return <Status iconType={'close'} status={getTeammateStatusLabel(status)} type={TagType.Error} />
    case TeammateStatus.invited:
      return <Status iconType={'clock'} status={getTeammateStatusLabel(status)} type={TagType.Processing} />
    case TeammateStatus.ok:
      return <Status iconType={'tick'} status={getTeammateStatusLabel(status)} type={TagType.Success} />
    case TeammateStatus.changePassword:
      return <Status iconType={'lock'} status={getTeammateStatusLabel(status)} type={TagType.Default} />
    case TeammateStatus.validateEmail:
      return <Status iconType={'clock'} status={getTeammateStatusLabel(status)} type={TagType.Processing} />
    default:
      return ''
  }
}

export const getTeammateStatusLabel = (status: TeammateStatus) => {
  switch (status) {
    case TeammateStatus.block:
      return translations().statuses.block
    case TeammateStatus.invited:
      return translations().statuses.invited
    case TeammateStatus.ok:
      return translations().statuses.ok
    case TeammateStatus.changePassword:
      return translations().statuses.changePassword
    case TeammateStatus.validateEmail:
      return translations().statuses.validateEmail
    default:
      return ''
  }
}
