import { TransactionsAcquisitionChannelModel } from '~/code/models'
import translations from '../translations'

export const getButtonText = (acquisitionChannel: TransactionsAcquisitionChannelModel, merchant: string) => {
    if (!acquisitionChannel || acquisitionChannel?.id === 'all') {
        if (merchant === 'ALL') {
            return translations().all
        }

        return merchant
    }

    return `${acquisitionChannel?.name} -> ${merchant}`
}