import translations from './translations'

translations.add('en', {
  customVerificationResult: 'Custom Verification Result',
  sortCode: 'Sort code',
  accountName: 'Account Name',
  accountNumber: 'Account Number',
  overallScore: 'Overall Score',
  account: 'Account',
  dnaAlgorithm: 'Open Banking Additional Verification (new algorithm)',
  parties: 'Parties'
})
