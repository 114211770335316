import { log } from 'dna-common'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { UploadFile } from 'antd/lib/upload/interface'
import { mandatoryDocuments } from '../components/SupportingDocuments/components/SupportingDocument/constants/constants'
import { DocumentItem } from '../components/SupportingDocuments/components/SupportingDocument/models/models'
import { DocumentsRequiredModel, CheckedDocumentModel, CheckedDocumentType } from '../models/DocumentsRequiredModel'
import {
  camelToKebabCase,
  checkDocumentRequirements,
  parseCheckedAccountVerification,
  parseCheckedDocumentVerification,
  parseOnboardingRequestData
} from '../services'
import translations from '../components/SupportingDocuments/translations'
import { EuroClientDocumentsType, SupportingDocumentsType } from '../models'
import {
  CreatePreviewRequestType,
  CreatePreviewResponseType,
  UpdateDocumentRequestType,
  UploadDocumentRes
} from '../components/SupportingDocuments/models'
import { message } from 'antd'
import {
  deleteDocument,
  getDocuSignDocumentById,
  createDocuSignPreview,
  uploadDocument,
  sendToSignMSA,
  updateDocuSignPreview,
  docusignStatus,
  docusignCancel,
  resendDocusignEmail
} from '../components/SupportingDocuments/services'
import { StartProcessStore } from '../StartProcessStore'
import { SendToSignRequestType } from '../components/SupportingDocuments/models/SendToSignRequestType'
import { PROCESS_VERSIONS } from '../services/constants'

type FileKey = keyof SupportingDocumentsType

export class SupportingDocumentsStore {
  isCheckDocumentsRequiredLoading: boolean = false
  documentsRequired: DocumentsRequiredModel = {
    bankStatement: true
  }

  idDocuments: CheckedDocumentModel[] = []
  residentialUtilityBills: CheckedDocumentModel[] = []
  bankDocuments: CheckedDocumentModel[] = []
  euroDocuments: CheckedDocumentModel[] = []
  manualMSA: CheckedDocumentModel[] = []

  supportingDocumentsMap: Record<FileKey, UploadFile[]> = {
    bankStatement: [],

    idDrivingLicence: [],
    idPassport: [],
    idOther: [],
    poaPersonalBankStatement: [],
    poaCouncilTax: [],
    poaUtilityBill: [],
    poaOther: [],
    pobBankStatementBusiness: [],
    pobChequeBook: [],
    pobBankLetter: [],
    pobOther: [],
    kybLeaseAgreement: [],
    kybAlcoholLicense: [],
    kybCertificateOfAuthenticity: [],
    domainOwnership: [],
    licences: [],
    processingStatement: [],
    premisesPhotoOutside: [],
    premisesPhotoInside: [],
    signedMsa: [],
    additionalTidsRequestForm: [],
    other: []
  }

  isDocumentLoading = {
    bankStatement: false,

    idDrivingLicence: false,
    idPassport: false,
    idOther: false,
    poaPersonalBankStatement: false,
    poaCouncilTax: false,
    poaUtilityBill: false,
    poaOther: false,
    pobBankStatementBusiness: false,
    pobChequeBook: false,
    pobBankLetter: false,
    pobOther: false,
    kybLeaseAgreement: false,
    kybAlcoholLicense: false,
    kybCertificateOfAuthenticity: false,
    domainOwnership: false,
    licences: false,
    processingStatement: false,
    premisesPhotoOutside: false,
    premisesPhotoInside: false,
    signedMsa: false,
    other: false,

    uploadAdditionalDocument: false
  }

  supportingDocumentsData: UploadDocumentRes[] = []
  additionalDocumentTypes: FileKey[] = []
  activeTab: string = 'sign'
  isSignDocumentLoading: boolean = false
  isUpdateDocLoading: boolean = false
  signDocumentResult: CreatePreviewResponseType = { envelopeId: '', uri: '', statusDateTime: '', status: '' }
  isSaveLoading: boolean = false
  updateDocumentResult: CreatePreviewResponseType = { envelopeId: '', uri: '', statusDateTime: '', status: '' }
  showModal: boolean = false
  isDocsSectionCompleted: boolean = false
  isCheckStatusLoading: boolean = false
  showCancellationModal: boolean = false
  isCancelLoading: boolean = false
  isResendEmailLoading: boolean = false
  isResendTimeoutDone: boolean = true

  constructor(private parentStore: StartProcessStore) {
    makeObservable(this, {
      documentsRequired: observable,
      isCheckDocumentsRequiredLoading: observable,
      supportingDocumentsMap: observable,
      isDocumentLoading: observable,
      supportingDocumentsData: observable,
      additionalDocumentTypes: observable,
      idDocuments: observable,
      residentialUtilityBills: observable,
      bankDocuments: observable,
      activeTab: observable,
      isSignDocumentLoading: observable,
      signDocumentResult: observable,
      isSaveLoading: observable,
      isUpdateDocLoading: observable,
      updateDocumentResult: observable,
      euroDocuments: observable,
      showModal: observable,
      isDocsSectionCompleted: observable,
      isCheckStatusLoading: observable,
      showCancellationModal: observable,
      isCancelLoading: observable,
      manualMSA: observable,
      isResendEmailLoading: observable,
      isResendTimeoutDone: observable,

      mandatoryDocuments: computed,
      isSupportingDocumentsValid: computed,
      isIdDocumentsValid: computed,
      isResidentialUtilityBillsValid: computed,
      isBankDocumentsValid: computed,
      isEuroDocumentsValid: computed,
      isManualMSA: computed,
      isManualMSAValid: computed,

      checkDocumentsRequired: action,
      addAdditionalDocument: action,
      removeAdditionalDocument: action,
      setSupportingDocumentsMap: action.bound,
      setIdDocument: action,
      setResidentialUtilityBillDocument: action,
      uploadFile: action,
      deleteFile: action,
      reset: action,
      setBankDocument: action,
      setActiveTab: action,
      signDocument: action.bound,
      passDocuSignToBackend: action.bound,
      updateDocument: action.bound,
      setEuroDocument: action,
      handleEuroDocumentChange: action,
      setShowModal: action.bound,
      setSupportingDocs: action.bound,
      setDocsCompleted: action.bound,
      docusignCancellation: action.bound,
      setShowCancellationModal: action.bound,
      handleCancelClick: action.bound,
      onDocusignSave: action.bound,
      handleManualMSAChange: action.bound,
      setManualDocusignDocument: action.bound,
      handleMSADocumentChange: action.bound,
      resendDocusignEmailToClient: action.bound,

      showDocusign: computed
    })
  }

  handleManualMSAChange() {
    this.dataStore.setIsManualMSA(true)
    this.onDocusignSave()
    this.parentStore.setActiveTabKey('supportingDocuments')
  }

  setShowCancellationModal(value: boolean) {
    this.showCancellationModal = value
  }

  setSupportingDocs(docs: UploadDocumentRes[]) {
    this.supportingDocumentsData = docs
  }

  setShowModal(val: boolean) {
    this.showModal = val
  }

  setActiveTab(val: string) {
    this.activeTab = val
  }

  get dataStore() {
    return this.parentStore.dataStore
  }

  get showDocusign() {
    return (
      this.dataStore.version !== PROCESS_VERSIONS.ADDITIONAL_STORE_ISSUE &&
      this.parentStore.onboardingSettings?.automatedMSA === 'docusignUK'
    )
  }

  get isManualMSA() {
    return this.dataStore.isManualMSA
  }

  get mandatoryDocuments(): (DocumentItem & { notRequiredText?: string })[] {
    return mandatoryDocuments.map(d => ({
      ...d,
      notRequiredText: this.documentsRequired[d.key] ? null : translations().notRequired[d.key]
    }))
  }

  get isSupportingDocumentsValid() {
    return Boolean(
      this.isBankDocumentsValid &&
        this.isManualMSAValid &&
        (this.parentStore.onboardingSettings?.documentsSettings?.length > 0 ? this.isEuroDocumentsValid : true) &&
        this.isIdDocumentsValid &&
        this.isResidentialUtilityBillsValid
    )
  }

  get isBankDocumentsValid() {
    if (this.bankDocuments.length === 0) return true
    let isValid = true
    for (const doc of this.bankDocuments) {
      if (doc.isRequired) {
        isValid = doc.fileList.length > 0
      }
    }
    return isValid
  }

  get isManualMSAValid() {
    if (this.manualMSA.length === 0) return true
    let isValid = true
    for (const doc of this.manualMSA) {
      if (doc.isRequired) {
        isValid = doc.fileList.length > 0
      }
    }
    return isValid
  }

  get isEuroDocumentsValid() {
    if (this.euroDocuments.length === 0) return true
    let isValid = true
    for (const doc of this.euroDocuments) {
      if (doc.isRequired) {
        isValid = doc.fileList.length > 0
      }
    }
    return isValid
  }

  get isIdDocumentsValid() {
    if (this.idDocuments.length === 0) return true
    let isValid = true
    for (const doc of this.idDocuments) {
      if (doc.isRequired) {
        isValid = doc.fileList.length > 0
      }
    }
    return isValid
  }

  get isResidentialUtilityBillsValid() {
    if (this.residentialUtilityBills.length === 0) return true
    let isValid = true
    for (const bill of this.residentialUtilityBills) {
      if (bill.isRequired) {
        isValid = bill.fileList.length > 0
      }
    }
    return isValid
  }

  openCompleteApplicationPage() {
    this.parentStore.openPricingPage(true)
  }

  openResultPage() {
    this.parentStore.openResultPage()
  }

  reset() {
    this.supportingDocumentsMap = {
      bankStatement: [],

      idDrivingLicence: [],
      idPassport: [],
      idOther: [],
      poaPersonalBankStatement: [],
      poaCouncilTax: [],
      poaUtilityBill: [],
      poaOther: [],
      pobBankStatementBusiness: [],
      pobChequeBook: [],
      pobBankLetter: [],
      pobOther: [],
      kybLeaseAgreement: [],
      kybAlcoholLicense: [],
      kybCertificateOfAuthenticity: [],
      domainOwnership: [],
      licences: [],
      processingStatement: [],
      premisesPhotoOutside: [],
      premisesPhotoInside: [],
      signedMsa: [],
      additionalTidsRequestForm: [],
      other: []
    }
    this.supportingDocumentsData = []
    this.additionalDocumentTypes = []
    this.idDocuments = []
    this.residentialUtilityBills = []
    this.bankDocuments = []
    this.isDocsSectionCompleted = false
    this.manualMSA = []
  }

  addAdditionalDocument(fileKey: FileKey, fileList: UploadFile[]) {
    this.setSupportingDocumentsMap(fileKey, [...this.supportingDocumentsMap[fileKey], ...fileList])
    this.addAdditionalDocumentType(fileKey)
  }

  removeAdditionalDocument(fileKey: FileKey, fileList: UploadFile[]) {
    this.setSupportingDocumentsMap(fileKey, fileList)
    if (fileList.length === 0) this.removeAdditionalDocumentType(fileKey)
  }

  addAdditionalDocumentType(value: FileKey) {
    if (this.additionalDocumentTypes.includes(value)) return
    this.additionalDocumentTypes.push(value)
  }

  removeAdditionalDocumentType(value: FileKey) {
    this.additionalDocumentTypes = this.additionalDocumentTypes.filter(doc => doc !== value)
  }

  setSupportingDocumentsMap(fileKey: FileKey, fileList: UploadFile[]) {
    this.supportingDocumentsMap[fileKey] = fileList
  }

  setIdDocument(officer: string, fileList: UploadFile[]) {
    this.idDocuments = this.idDocuments.map(b => {
      if (b.officer === officer) {
        return { ...b, fileList }
      }
      return b
    })
  }

  setBankDocument(officer: string, fileList: UploadFile[]) {
    this.bankDocuments = this.bankDocuments.map(b => {
      if (b.officer === officer) {
        return { ...b, fileList }
      }
      return b
    })
  }

  setEuroDocument(officer: string, fileList: UploadFile[]) {
    this.euroDocuments = this.euroDocuments.map(b => {
      if (b.officer === officer) {
        return { ...b, fileList }
      }
      return b
    })
  }

  setResidentialUtilityBillDocument(officer: string, fileList: UploadFile[]) {
    this.residentialUtilityBills = this.residentialUtilityBills.map(b => {
      if (b.officer === officer) {
        return { ...b, fileList }
      }
      return b
    })
  }

  setManualDocusignDocument(officer: string, fileList: UploadFile[]) {
    this.manualMSA = this.manualMSA.map(b => {
      if (b.officer === officer) {
        return { ...b, fileList }
      }
      return b
    })
  }

  setCheckedDocumentLoading(checkedDocumentType: CheckedDocumentType, officer: string, isLoading: boolean) {
    if (checkedDocumentType === 'idDocument') {
      this.idDocuments = this.idDocuments.map(b => {
        if (b.officer === officer) {
          return { ...b, isLoading }
        }
        return b
      })
    }

    if (checkedDocumentType === 'bankStatement') {
      this.bankDocuments = this.bankDocuments.map(b => {
        if (b.officer === officer) {
          return { ...b, isLoading }
        }
        return b
      })
    }

    if (checkedDocumentType === 'residentialUtilityBill') {
      this.residentialUtilityBills = this.residentialUtilityBills.map(b => {
        if (b.officer === officer) {
          return { ...b, isLoading }
        }
        return b
      })
    }

    if (checkedDocumentType === 'manual-merchant-agreement') {
      this.manualMSA = this.manualMSA.map(b => {
        if (b.officer === officer) {
          return { ...b, isLoading }
        }
        return b
      })
    }
  }

  async checkDocumentsRequired() {
    const { application, tariffs, applicationId, stores, version, contactInfoData, envelopeId } = this.dataStore

    this.idDocuments = []
    this.bankDocuments = []
    this.residentialUtilityBills = []
    this.euroDocuments = []

    const data = parseOnboardingRequestData(
      application,
      this.parentStore.pricingStore.productTypes.length === 0 ? [] : tariffs,
      stores.filter(s => s.removable === true),
      '',
      version,
      contactInfoData,
      envelopeId,
      this.parentStore.onboardingSettings,
      this.dataStore.bankAccounts,
      this.dataStore.sentToDocusign,
      this.dataStore.isManualMSA,
      this.dataStore.productVersion
    )

    try {
      runInAction(() => {
        this.isCheckDocumentsRequiredLoading = true
      })

      const { status, error, result } = await checkDocumentRequirements(applicationId, data)
      if (status === 200 && !error) {
        runInAction(() => {
          result.bankAccountVerifications
            ?.filter(t => t.verified === false)
            ?.forEach((bank, idx) => {
              const isFromApi = this.dataStore.bankAccounts?.find(acc => acc.sortCode === bank.sortCode)?.fromApi
              if (bank.sortCode && !isFromApi)
                this.bankDocuments[idx] = parseCheckedAccountVerification(bank, this.idDocuments?.[idx]?.fileList, idx)
            })
          if (this.dataStore.version !== PROCESS_VERSIONS.ADDITIONAL_STORE_ISSUE) {
            result.idVerifications
              ?.filter(t => t.verified === false)
              ?.forEach((officer, idx) => {
                this.idDocuments[idx] = parseCheckedDocumentVerification(officer, this.idDocuments?.[idx]?.fileList)
              })
            result.addressVerifications
              ?.filter(t => t.verified === false)
              ?.forEach((officer, idx) => {
                this.residentialUtilityBills[idx] = parseCheckedDocumentVerification(
                  officer,
                  this.residentialUtilityBills?.[idx]?.fileList
                )
              })
          }

          if (this.isManualMSA) {
            this.manualMSA[0] = {
              isRequired: true,
              officer: translations().manualDocusignTitle,
              fileList: [],
              isLoading: false,
              code: 'manual-merchant-agreement'
            }
          }

          this.parentStore.onboardingSettings?.documentsSettings?.forEach((itm, idx) => {
            this.euroDocuments[idx] = {
              isRequired: itm.required,
              officer: itm.value,
              fileList: [],
              isLoading: false,
              code: itm.code
            }
          })
        })
      }
    } catch (error) {
      log(error)
    }

    runInAction(() => {
      this.isCheckDocumentsRequiredLoading = false
    })
  }

  async uploadFile(
    documentType: FileKey | CheckedDocumentType | EuroClientDocumentsType,
    file: File,
    officer?: string
  ): Promise<boolean> {
    if (!file) return false

    let isUploaded: boolean

    try {
      runInAction(() => {
        if (officer) {
          this.setCheckedDocumentLoading(documentType as CheckedDocumentType, officer, true)
        } else {
          this.isDocumentLoading[documentType] = true
        }
      })
      const { status, error, result } = await uploadDocument({
        companyName: this.dataStore.application?.companyProfile?.companyName,
        companyNumber: this.dataStore.companyNumber,
        acquisitionChannel: this.dataStore.contactInfoData?.acquisitionChannel,
        documentType: camelToKebabCase(documentType),
        file
      })
      if (status !== 200 || error || !result || result.length <= 0) {
        message.error(error.message || translations().errorUploadingDocument)
        isUploaded = false
      } else {
        runInAction(() => {
          this.supportingDocumentsData.push({
            ...result[0],
            internalFileName: file.name,
            type: documentType
          })
        })
        isUploaded = true
      }
    } catch (error) {
      message.error(translations().errorUploadingDocument)
      isUploaded = false
    }

    runInAction(() => {
      if (officer) {
        this.setCheckedDocumentLoading(documentType as CheckedDocumentType, officer, false)
      } else {
        this.isDocumentLoading[documentType] = false
      }
    })

    return isUploaded
  }

  async deleteFile(
    documentType: FileKey | CheckedDocumentType | EuroClientDocumentsType,
    fileName: string,
    officer?: string
  ): Promise<boolean> {
    let isDeleted: boolean
    try {
      runInAction(() => {
        if (officer) {
          this.setCheckedDocumentLoading(documentType as CheckedDocumentType, officer, true)
        } else {
          this.isDocumentLoading[documentType] = true
        }
      })
      const path = this.supportingDocumentsData.find(
        d => d?.internalFileName === fileName && d?.type === documentType
      )?.path
      if (path) {
        const { status, error } = await deleteDocument(path)
        if (status !== 200 || error) {
          message.error(error.message || translations().errorDeletingDocument)
          isDeleted = false
        } else {
          isDeleted = true
        }
      }
    } catch (error) {
      message.error(translations().errorDeletingDocument)
      isDeleted = false
    }

    runInAction(() => {
      if (officer) {
        this.setCheckedDocumentLoading(documentType as CheckedDocumentType, officer, false)
      } else {
        this.isDocumentLoading[documentType] = false
      }
    })
    return isDeleted
  }

  async signDocument() {
    try {
      runInAction(() => {
        this.isSignDocumentLoading = true
      })

      this.dataStore.setIsManualMSA(false)

      const dt: CreatePreviewRequestType = {
        templateId: '3152f0dc-2b91-4df1-af12-b49ad00b904a',
        email: this.dataStore.agreementOfficer?.emailAddress,
        name: `${this.dataStore.agreementOfficer?.nameElements?.forename} ${this.dataStore.agreementOfficer?.nameElements?.surname}`,
        roleName: 'signer'
      }

      const { status, error, result } = await createDocuSignPreview(this.dataStore.applicationId, dt)
      if (status !== 200 || error || !result) {
        message.error(error.message || translations().signError)
        return
      }

      await this.fetchDocument(result)
      if (result.envelopeId) {
        this.dataStore.setEnvelopeId(result.envelopeId)
        this.dataStore.saveApplication('isSaveLoading')
      }

      this.signDocumentResult = result
      this.setShowModal(false)
    } catch (error) {
      log(error)
    }

    runInAction(() => {
      this.isSignDocumentLoading = false
    })
  }

  async updateDocument() {
    try {
      runInAction(() => {
        this.isUpdateDocLoading = true
      })

      const dt: UpdateDocumentRequestType = {
        envelopeId: this.dataStore.envelopeId,
        documentId: '1',
        recipientId: '1'
      }

      const { status, error, result } = await updateDocuSignPreview(this.dataStore.applicationId, dt)
      if (status !== 200 || error || !result) {
        message.error(error.message || translations().updateError)
        return
      }

      await this.fetchDocument(result)

      this.dataStore.setSentToDocusign(false)
      this.dataStore.saveApplication('isSaveLoading')

      this.signDocumentResult = result
    } catch (error) {
      log(error)
    }

    runInAction(() => {
      this.isUpdateDocLoading = false
    })
  }

  async fetchDocument(data: CreatePreviewResponseType) {
    try {
      const { status, error, result } = await getDocuSignDocumentById(data.envelopeId || this.dataStore.envelopeId, '1')
      if (status !== 200 || error) {
        message.error(error.message || translations().signError)
        return
      }

      const linkSource = `data:application/pdf;base64,${result}`
      const downloadLink = document.createElement('a')
      const fileName = `Preview Only MSA ${
        this.dataStore.application?.companyProfile?.companyName
      } ${new Date().toJSON()}.pdf`
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    } catch (error) {
      log(error)
    }
  }

  async passDocuSignToBackend() {
    try {
      runInAction(() => {
        this.isSaveLoading = true
      })

      const dt: SendToSignRequestType = {
        envelopeId: this.dataStore.envelopeId
      }

      const { status, error } = await sendToSignMSA(this.dataStore.applicationId, dt)
      if (status !== 200 || error) {
        message.error(error?.message || translations().signError)
        return
      }
      this.dataStore.setSentToDocusign(true)
    } catch (error) {
      log(error)
    }

    runInAction(() => {
      this.isSaveLoading = false
    })
  }

  async resendDocusignEmailToClient() {
    try {
      runInAction(() => {
        this.isResendEmailLoading = true
      })

      runInAction(() => {
        this.isResendTimeoutDone = false
      })

      const { status, error } = await resendDocusignEmail(this.dataStore.applicationId)
      if (status !== 200 || error) {
        message.error(error?.message || translations().resendError)
      } else {
        message.success(translations().resendSuccess, 11)
      }

      runInAction(() => {
        setTimeout(() => {
          this.isResendTimeoutDone = true
        }, 10000)
      })
    } catch (error) {
      log(error)
    }

    runInAction(() => {
      this.isResendEmailLoading = false
    })
  }

  async handleEuroDocumentChange(fileList: UploadFile[], file?: UploadFile, doc?: CheckedDocumentModel) {
    const fileToUpload = fileList[0]
    if (fileToUpload) {
      const isUploaded = await this.uploadFile(
        doc.code as EuroClientDocumentsType,
        fileToUpload?.originFileObj as File,
        doc.officer
      )
      if (!isUploaded) return
      this.setEuroDocument(doc.officer, fileList)
    } else {
      const isDeleted = await this.deleteFile(doc.code as EuroClientDocumentsType, file.name, doc.officer)
      if (!isDeleted) return
      this.setEuroDocument(doc.officer, fileList)
    }
  }

  async handleMSADocumentChange(fileList: UploadFile[], file?: UploadFile, doc?: CheckedDocumentModel) {
    const fileToUpload = fileList[0]
    if (fileToUpload) {
      const isUploaded = await this.uploadFile(
        'manual-merchant-agreement',
        fileToUpload?.originFileObj as File,
        doc.officer
      )
      if (!isUploaded) return
      this.setManualDocusignDocument(doc.officer, fileList)
    } else {
      const isDeleted = await this.deleteFile('manual-merchant-agreement', file.name, doc.officer)
      if (!isDeleted) return
      this.setManualDocusignDocument(doc.officer, fileList)
    }
  }

  async checkDocusignStatus() {
    runInAction(() => {
      this.isCheckStatusLoading = true
    })
    const { status, error, result } = await docusignStatus(this.dataStore.applicationId)
    if (status !== 200 || error) {
      message.error(error?.message || translations().signError)
      return
    }
    this.dataStore.setDocuSignStatus(result?.status)

    runInAction(() => {
      this.isCheckStatusLoading = false
    })
  }

  setDocsCompleted() {
    this.isDocsSectionCompleted = true
    if (this.dataStore.sentToDocusign && this.dataStore.docuSignStatus !== 'success') {
      message.error(translations().completeDocusign, 10)
      this.parentStore.setActiveTabKey('contract')
      return
    }
    this.parentStore.setActiveTabKey('result')
  }

  async docusignCancellation() {
    runInAction(() => {
      this.isCancelLoading = true
    })
    try {
      const { status, error } = await docusignCancel(this.dataStore.applicationId, this.dataStore.envelopeId)
      if (status !== 200 || error) {
        message.error(error?.message || translations().signError)
        return
      }
      this.dataStore.setEnvelopeId('')
      this.dataStore.setSentToDocusign(false)
      this.dataStore.setDocuSignStatus('')
    } catch (e) {
      message.error(translations().signError)
    } finally {
      runInAction(() => {
        this.isCancelLoading = false
      })
    }
  }

  async handleCancelClick() {
    await this.docusignCancellation()
    this.setShowCancellationModal(false)
  }

  onDocusignSave() {
    this.dataStore.saveApplication('isSaveLoading')
  }
}
