import React, { useState } from 'react'
import { Form, InputProps } from 'antd'
import { Input } from '~/code/components/Input'
import { NumberFormItemProps } from './props'
import translations from './translations'
import { DIGIT_EXCLUSION_REGEX } from './services/pattern'
import { isNull } from '~/code/services'

export const NumberFormItem: React.FC<NumberFormItemProps> = props => {
  const {
    isPositive = true,
    min,
    max,
    disabled = false,
    validate = true,
    readonly = false,
    placeholder,
    decimalLength,
    initialValue,
    showValueChangedMsg = false,
    ...rest
  } = props

  return (
    <Form.Item
      {...rest}
      rules={
        validate
          ? [
              {
                validator: async (_, rawValue) => {
                  const value = Number(rawValue)
                  if (!showValueChangedMsg && !rawValue && String(rawValue) !== '0') {
                    throw new Error(translations().requiredMessage)
                  }
                  if (isPositive && value < 0) {
                    throw new Error(translations().positiveMessage)
                  }
                  if (!isNull(min) && !isNull(max) && (value < min || value > max)) {
                    throw new Error(translations().betweenMessage(min, max))
                  }
                  if (isNull(min) && !isNull(max) && value > max) {
                    throw new Error(translations().lessMessage(max))
                  }
                  if (!isNull(min) && isNull(max) && value <= min) {
                    if (value === 0) {
                      throw new Error(translations().cannotBeZero)
                    } else {
                      throw new Error(translations().greaterMessage(min))
                    }
                  }
                }
              }
            ]
          : []
      }
      validateTrigger={['onBlur', 'onChange']}
      getValueFromEvent={v => {
        const str = String(v || '')
        const dotIndex = str.indexOf('.')
        let cleanedStr = str

        if (dotIndex < 0) {
          cleanedStr = str.replace(DIGIT_EXCLUSION_REGEX, '')
        } else {
          const part1 = str.substring(0, dotIndex).replace(DIGIT_EXCLUSION_REGEX, '') + '.'
          const part2 = str.substring(dotIndex + 1).replace(DIGIT_EXCLUSION_REGEX, '')
          if (decimalLength && decimalLength > 0) {
            cleanedStr = part1 + part2.substring(0, decimalLength)
          } else {
            cleanedStr = part1 + part2
          }
        }

        return cleanedStr
      }}
    >
      <NumberInput
        disabled={disabled}
        readOnly={readonly}
        placeholder={placeholder}
        initialValue={initialValue}
        showValueChangedMsg={showValueChangedMsg}
      />
    </Form.Item>
  )
}

const NumberInput: React.FC<InputProps & { initialValue?: string | number } &
  { showValueChangedMsg?: boolean } & { onChange?(v: string): void }> = ({ onChange, onBlur, initialValue, showValueChangedMsg, ...rest }) => {
  const [showWarning, setShowWarning] = useState(false)
  return (
    <>
      <Input
        {...rest}
        onChange={e => {
          onChange && onChange(e.target.value)
          if (String(e.target.value) !== String(initialValue)) {
            setShowWarning(true)
          } else {
            setShowWarning(false)
          }
        }}
        onBlur={e => {
          const v = e.target.value || ''
          const n = v && String(Number(v))
          if (v !== n) {
            onChange && onChange(n)
          }
          onBlur && onBlur(e)
        }}
      />
      {showValueChangedMsg && showWarning && <div>{translations().changedText} {initialValue}</div>}
    </>
  )
}

