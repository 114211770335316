import { makeObservable, observable, reaction } from 'mobx'
import { inject, injectable } from 'inversify'
import { RealTimeTransactionMapStoreInterface } from '~/code/pages/RealTimeTransaction/components/RealTimeTransactionMap/RealTimeTransactionMapStoreInterface'
import { RealTimeTransactionDataStoreSymbol, RealTimeTransactionDataStoreInterface } from '~/code/stores/RealTimeTransactionStore/RealTimeTransactionDataStore'
import { RealTimeTransactionModel } from '~/code/stores/RealTimeTransactionStore/models'

@injectable()
export class RealTimeTransactionMapStore implements RealTimeTransactionMapStoreInterface {
  public realTimeTransactionDataStore: RealTimeTransactionDataStoreInterface
  public data: Pick<RealTimeTransactionModel, 'latitude' | 'longitude' | 'source' | 'terminalId'> = null

  constructor(
    @inject(RealTimeTransactionDataStoreSymbol) realTimeTransactionDataStore: RealTimeTransactionDataStoreInterface
  ) {
    this.realTimeTransactionDataStore = realTimeTransactionDataStore

    makeObservable(this, {
      data: observable
    })

    reaction(
      () => this.realTimeTransactionDataStore.data,
      ({ latitude, longitude, source, terminalId }) => {
        this.data = {
          latitude,
          longitude,
          source,
          terminalId
        }
      }
    )
  }
}
