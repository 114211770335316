import translations from './translations'

translations.add('en', {
    customer: 'Customer',
    issuer: 'Issuer',
    other: 'Failed other',
    total: 'Total',
    failedTransactionsReasons: 'Failed transactions reasons',
    successful: 'Successful'
})
