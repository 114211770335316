import { StatusTabType } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/chart-related/SumCountCard/models'
import translations from '../translations'

export const yearsComparisonStatusTabs: StatusTabType[] = [
    {
        value: 'all',
        label: translations().all
    },
    {
        value: 'successful',
        label: translations().successful
    },
    {
        value: 'failed',
        label: translations().failed
    }
]
