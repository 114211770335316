import { object, string, array, boolean, number } from 'yup'
import { BusinessModelScheme } from 'startapp/models/schemes/BusinessModelScheme'

const PMSchema = object({
    percent: number().required()
})

const ProcessingMethodsScheme = object({
    pos: PMSchema.required(),
    moto: PMSchema.required(),
    ecom: PMSchema.required(),
    payByLink: PMSchema.required(),
    payByApp: PMSchema.required(),
    virtualTerminal: PMSchema.required()
})

const APScheme = object({
    percent: number().required(),
    days: number().required()
})

const AdvancePaymentsScheme = object({
    nbsp: APScheme.required(),
    deposits: APScheme.required(),
    balances: APScheme.required(),
    fullPayment: APScheme.required(),
    other: APScheme.required()
})

export const FinancialInfoScheme = object({
    businessModelQuestions: BusinessModelScheme.nullable(),
    expectedAnnualTurnover: string().required(),
    expectedMonthlyTransactionValues: string().required(),
    expectedAnnualCardTurnover: string().required(),
    highestTransactionValue: string().required(),
    averageTransactionValue: string().required(),
    tradeOutsideEea: boolean().required(),
    tradingCountriesOutsideEea: array(string()).nullable().when('tradeOutsideEea', {
        is: true, then: array(string()).required()
    }),
    bankAccountNumber: string().required().matches(/^(\d){8}$/),
    sortCode: string().required().matches(/^(\d){2}-(\d){2}-(\d){2}$/),
    bankAccountType: string().required(),
    companyNumber: string().nullable().when('companyType', { is: 'company', then: string().required() }),
    processingMethods: ProcessingMethodsScheme.required(),
    advancePayments: AdvancePaymentsScheme.required()
}).required()
