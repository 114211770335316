import { string, object } from 'yup'
import { isDateOfCreationValid } from 'startapp/services/validations'
import { AddressScheme } from './AddressScheme'

export const CompanyProfileScheme = object({
    relationshipType: string().required(),
    companyType: string().oneOf(['company', 'sole-trader']),
    companyNumber: string().nullable().when('companyType', { is: 'company', then: string().required() }),
    companyName: string().required(),
    companyTradeName: string().nullable().when('companyType', { is: 'company', then: string().required() }),
    storeName: string().nullable().when('companyType', { is: 'company', then: string().required() }).max(24),
    emailAddress: string().required().email(),
    dateOfCreation: string()
        .required()
        .test('date-validator', '', isDateOfCreationValid),
    natureOfBusiness: string().required(),
    natureOfBusinessDetails: string().required(),
    employeesNumber: string().required(),
    tradingAddress: AddressScheme.required()
})
.required()
