import { message } from 'antd'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { apiCheck, ApiResponseError, noThrow } from 'back-connector'
import {
  parseApplicationCompanyInformation,
  loadCompanyInformation,
  completeApplication,
  parseOnboardingRequestData,
  camelToKebabCase,
  checkPerson,
  verifyOfficers,
  jumioRequestStart,
  jumioVerificationCheckResults,
  resendJumioLinkEmail
} from '../services'
import {
  OfficerProfile,
  PCSProfile,
  CompleteAppTabKey,
  CompleteApplicationParentStore,
  ResultPageType,
  SupportingDocumentsType,
  CompanyProfile,
  GeneralInformationType,
  LoadingPropertyType,
  JumioCheckDataType,
  JumioShortResultsDataType
} from '../models'
import translations from '~/code/pages/StartProcess/translations'
import { RESPONSE_TIMEOUT_IN_MS } from '~/code/constants/Configurations'
import { availableAdditionalDocuments } from '../components/SupportingDocuments/components/SupportingDocument/constants/constants'
import { VerifyOfficerRequestType, VerifyOfficerResponseType } from '../models/verification'
import { isEmpty } from 'dna-common'
import { PROCESS_VERSIONS } from '../services/constants'
export class CompleteApplicationStore {
  currentTab: CompleteAppTabKey = 'financialInformation'
  resultPage: ResultPageType = 'check'
  resultApplicationId: string
  selectedOfficer: OfficerProfile | PCSProfile = null
  isSubmitting: boolean = false
  officerVerificationResult: VerifyOfficerResponseType = null
  officerVerificationLoading: boolean = false
  isCheckTimeoutDone: boolean = true
  showIndivVerificationModal: boolean = false
  isJumioRequestLoading: boolean = false
  jumioRequestSent: boolean = false
  showJumioVerificationModal: boolean = false
  uploadDocManually: boolean = false
  jumioResults: JumioShortResultsDataType = null
  isJumioResultsLoading: boolean = false
  isResendEmailLoading: boolean = false
  isResendTimeoutDone: boolean = true

  errorSubmitApplication: ApiResponseError = { code: '', message: '' }

  constructor(private parentStore: CompleteApplicationParentStore) {
    makeObservable(this, {
      currentTab: observable,
      selectedOfficer: observable,
      isSubmitting: observable,
      resultPage: observable,
      errorSubmitApplication: observable,
      officerVerificationResult: observable,
      officerVerificationLoading: observable,
      isCheckTimeoutDone: observable,
      showIndivVerificationModal: observable,
      isJumioRequestLoading: observable,
      jumioRequestSent: observable,
      showJumioVerificationModal: observable,
      uploadDocManually: observable,
      jumioResults: observable,
      isJumioResultsLoading: observable,
      isResendEmailLoading: observable,
      isResendTimeoutDone: observable,

      isNextLoading: computed,
      isSaveLoading: computed,
      isCheckDocumentsRequiredLoading: computed,
      isCreateMode: computed,
      isEuroClient: computed,

      saveOnGeneralInformation: action.bound,
      saveOnOfficer: action.bound,
      submit: action.bound,
      selectTab: action.bound,
      selectOfficer: action.bound,
      submitFinancialInfo: action.bound,
      loadCompanyInformation: action.bound,
      clearResultPage: action.bound,
      onClose: action.bound,
      checkCount: action.bound,
      checkOwner: action.bound,
      submitOfficer: action.bound,
      submitShareholder: action.bound,
      verifyCompanyOfficer: action.bound,
      setShowIndivVerifModal: action.bound,
      setOfficerVerificationResult: action.bound,
      setShowJumioVerificationModal: action.bound,
      setUploadDocManually: action.bound,
      sendJumioRequest: action.bound,
      checkJumioResults: action.bound,
      setJumioRequestSent: action.bound,
      resetChecks: action.bound,
      findAndUpdateAgreementSignee: action.bound,
      handleAgreementSignatoryChange: action.bound,
      checkForDuplicates: action.bound,
      setJumioResults: action.bound,
      handleSaveOfficer: action.bound,
      handleSaveShareholder: action.bound,
      handleSubmitOfficer: action.bound,
      handleSubmitShareholder: action.bound,
      handleSubmitBusinessOwner: action.bound,
      handleSaveBusinessOwner: action.bound,
      resendJumioEmail: action.bound
    })
  }

  setJumioResults(res: JumioShortResultsDataType) {
    this.jumioResults = res
  }

  setJumioRequestSent(value: boolean) {
    this.jumioRequestSent = value
  }

  setUploadDocManually(value: boolean) {
    this.uploadDocManually = value
  }

  setShowJumioVerificationModal(value: boolean) {
    this.showJumioVerificationModal = value
  }

  setOfficerVerificationResult(result: VerifyOfficerResponseType) {
    this.officerVerificationResult = result
  }

  setShowIndivVerifModal(val: boolean) {
    this.showIndivVerificationModal = val
  }

  reset() {
    this.currentTab = 'financialInformation'
    this.resultPage = 'check'
    this.selectedOfficer = null
    this.resultApplicationId = ''
    this.dataStore.resetApplication()
  }

  resetChecks() {
    this.officerVerificationResult = null
    this.jumioResults = null
    this.jumioRequestSent = false
  }

  get isEuroClient() {
    return this.parentStore.onboardingSettings?.bankDetailScheme === 'EU'
  }

  get dataStore() {
    return this.parentStore.dataStore
  }

  get supportingDocumentsStore() {
    return this.parentStore.supportingDocumentsStore
  }

  get isCreateMode() {
    return !this.selectedOfficer?.id
  }

  get isNextLoading() {
    return this.dataStore.isNextLoading
  }

  get isSaveLoading() {
    return this.dataStore.isSaveLoading
  }

  get isCheckDocumentsRequiredLoading() {
    return this.parentStore.isCheckDocumentsRequiredLoading
  }

  get isAdditionalStoreProcess() {
    return this.dataStore.version === PROCESS_VERSIONS.ADDITIONAL_STORE_ISSUE
  }

  updateCompanyProfile(companyProfile: CompanyProfile) {
    this.dataStore.savePhoneNumberAndEmail(companyProfile.telephoneNumber, companyProfile.emailAddress)
    this.dataStore.updateCompanyProfile(companyProfile)
  }

  async submitFinancialInfo() {
    if (this.dataStore.version !== PROCESS_VERSIONS.ADDITIONAL_STORE_ISSUE) {
      this.dataStore.saveApplication('isSaveLoading')
      this.goToShareholderOrBusinessOwnerTab()
    } else {
      this.parentStore.openPricingPage()
    }
  }

  goToFinancialInfoTab() {
    this.selectTab('financialInformation')
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  goToShareholderOrBusinessOwnerTab() {
    if (this.dataStore.companyType === 'company') {
      this.selectTab('directors')
    } else {
      this.selectTab('businessOwner')
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  selectShareholder = (shareholder: PCSProfile) => {
    this.selectOfficer(shareholder)
  }

  goToBack = () => {
    this.parentStore.openGeneralInformationPage()
  }

  async loadCompanyInformation(companyNumber: string) {
    const result = await noThrow(apiCheck(loadCompanyInformation(companyNumber)))
    if (result.error) {
      message.error(result.error.message)
      return false
    } else if (!result.value) {
      message.error(translations().errorLoadingApplication)
      return false
    }

    const companyInformation = parseApplicationCompanyInformation(result.value)
    this.updateCompanyProfile(companyInformation)

    return true
  }

  async init(values: GeneralInformationType) {
    const { companyType, companyNumber } = values
    const { application } = this.dataStore
    const oldCompanyNumber = application?.companyProfile?.companyNumber
    if (companyType === 'company' && oldCompanyNumber !== companyNumber) {
      return await this.loadCompanyInformation(companyNumber)
    }
    return true
  }

  selectTab(page: CompleteAppTabKey) {
    this.currentTab = page
    this.selectOfficer(null)
  }

  selectOfficer(officer: OfficerProfile | PCSProfile) {
    if (officer === null) {
      this.officerVerificationResult = null
      this.jumioResults = null
      this.jumioRequestSent = false
      this.uploadDocManually = false
    }
    this.selectedOfficer = officer
  }

  openCompleteApplicationPage() {
    this.parentStore.openCompleteApplicationPage()
  }

  async openSupportingDocumentsPage() {
    this.parentStore.openSupportingDocumentsPage()
  }

  openResultPage = () => {
    this.parentStore.openResultPage()
  }

  onClose() {
    this.currentTab = 'financialInformation'
  }

  clearResultPage() {
    this.resultPage = 'check'
  }

  async saveOnGeneralInformation() {
    this.dataStore.saveApplication('isSaveLoading')
  }

  async saveOnOfficer() {
    this.dataStore.saveApplication('isSaveLoading')
    this.selectOfficer(null)
  }

  async submit() {
    this.isSubmitting = true
    const { application, applicationId, tariffs, stores, version, contactInfoData, envelopeId } = this.dataStore
    const { supportingDocumentsData, supportingDocumentsMap } = this.supportingDocumentsStore
    const data = parseOnboardingRequestData(
      application,
      tariffs,
      stores,
      '',
      version,
      contactInfoData,
      envelopeId,
      this.parentStore.onboardingSettings,
      this.dataStore.bankAccounts,
      this.dataStore.sentToDocusign,
      this.dataStore.isManualMSA,
      this.dataStore.productVersion
    )

    // to be removed soon - START
    const obj = {} as SupportingDocumentsType
    const additionalDocuments = availableAdditionalDocuments.map(d => d.value)
    for (const [fileKey, fileList] of Object.entries(supportingDocumentsMap)) {
      const file = fileList[0]
      if (!file || additionalDocuments.includes(fileKey as any)) continue
      const base64 = await getBase64(file.originFileObj)

      obj[fileKey] = {
        fileName: file.name,
        value: base64.replace(/^data:([\w\/\+]+);(charset=[\w-]+|base64),/, '')
      }
    }
    data.supportingDocuments = obj
    // to be removed soon - END

    data.documents = supportingDocumentsData.map(d => ({
      type: camelToKebabCase(d.type),
      name: d.fileName,
      path: d.path
    }))

    let isTimeoutFired = false
    const timeoutId = setTimeout(() => {
      this.resultPage = 'timeout'
      this.isSubmitting = false
      isTimeoutFired = true
    }, RESPONSE_TIMEOUT_IN_MS)

    try {
      const { error } = await completeApplication(applicationId, data)

      this.errorSubmitApplication = error

      if (isTimeoutFired) {
        return
      }

      if (error) {
        if (error.code.toString() === '259') {
          message.error(translations().msaSignError)
          this.errorSubmitApplication.message = translations().msaSignError
        } else {
          message.error(error.message)
        }

        this.resultPage = 'error'
      } else {
        this.resultApplicationId = applicationId
        this.resultPage = 'success'
      }
    } catch (err) {
      message.error(err?.message)
    }

    clearTimeout(timeoutId)

    this.isSubmitting = false
  }

  checkCount(type: 'add' | 'next') {
    const companyMode =
      this.dataStore.application?.companyProfile?.companyType === 'company' ||
      this.dataStore.application?.companyProfile?.companyType === 'llp'
    const directorsCount = this.dataStore.application.companyOfficerList.filter(
      item => item.officerRole === 'director'
    ).length
    const businessOwnersCount = this.dataStore.application.companyOfficerList.filter(
      item => item.officerRole === 'business-owner'
    ).length

    const needCount = companyMode ? directorsCount : businessOwnersCount

    const reqCount = this.dataStore.application.companyProfile.directorsPartnersCount || 0

    if (reqCount > 0) {
      if (type === 'add' && needCount + 1 > reqCount) {
        message.error(translations().mustBe(reqCount, companyMode))
        return false
      }
      if (type === 'next' && needCount !== reqCount) {
        message.error(translations().mustBe(reqCount, companyMode))
        return false
      }
    }

    return true
  }

  async checkOwner(data: OfficerProfile) {
    let checkResult = true
    await checkPerson(
      data.nameElements.forename,
      data.nameElements.surname,
      `${data.dateOfBirth.year}-${data.dateOfBirth.month}-${data.dateOfBirth.day}`,
      '',
      data.address.postalCode
    ).then(res => {
      if (res.result?.length > 0) {
        checkResult = res.result.every(item => item.status === 'deactivated')
      }
    })

    !checkResult && message.error(translations().canNotProceed)

    return checkResult
  }

  checkForDuplicates(surname: string, forename: string, shareholder?: boolean): boolean {
    let isDuplicate = false
    if (shareholder) {
      isDuplicate = this.dataStore.application?.companyPCSList?.find(
        item => item.nameElements?.surname === surname && item.nameElements?.forename === forename
      )
        ? true
        : false
    } else {
      isDuplicate = this.dataStore.application?.companyOfficerList?.find(
        item => item.nameElements?.surname === surname && item.nameElements?.forename
      )
        ? true
        : false
    }

    return isDuplicate
  }

  async submitOfficer(data: OfficerProfile, loadingProperty: LoadingPropertyType, showOverview: boolean = true) {
    if (data.agreementSignatory === true) {
      this.findAndUpdateAgreementSignee()
    }

    this.dataStore.savePhoneNumberAndEmail(data.telephoneNumber, data.emailAddress)
    const { officerRole, id, deletionAllowed } = this.selectedOfficer as OfficerProfile
    const commonData = {
      officerVerificationResult: this.officerVerificationResult,
      officerRole,
      jumioRequestSent: this.jumioRequestSent,
      jumioCheckResults: this.jumioResults,
      uploadDocManually: this.uploadDocManually,
      deletionAllowed: isEmpty(deletionAllowed) ? true : deletionAllowed
    }

    if (this.isCreateMode) {
      const newOfficer = this.dataStore.createOfficer({
        ...data,
        ...commonData
      })
      this.selectOfficer(newOfficer)
    } else {
      this.dataStore.updateOfficer({
        ...data,
        ...commonData,
        id
      })
    }

    const isOk = await this.dataStore.saveApplication(loadingProperty)

    if (showOverview && isOk) {
      this.selectOfficer(null)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    if (loadingProperty !== 'isSaveLoading') {
      this.officerVerificationResult = null
      this.jumioResults = null
    }
  }

  async submitShareholder(data: PCSProfile, loadingProperty: LoadingPropertyType, showOverview: boolean = true) {
    if (data.agreementSignatory === true) {
      this.findAndUpdateAgreementSignee()
    }

    this.dataStore.savePhoneNumberAndEmail(data.telephoneNumber, data.emailAddress)
    const { id, deletionAllowed } = this.selectedOfficer as PCSProfile
    const commonData = {
      officerVerificationResult: this.officerVerificationResult,
      jumioRequestSent: this.jumioRequestSent,
      jumioCheckResults: this.jumioResults,
      uploadDocManually: this.uploadDocManually,
      deletionAllowed: isEmpty(deletionAllowed) ? true : deletionAllowed
    }

    if (this.isCreateMode) {
      const newOfficer = this.dataStore.createShareholder({
        ...data,
        ...commonData
      })
      this.selectOfficer(newOfficer)
    } else {
      this.dataStore.updateShareholder({
        ...data,
        ...commonData,
        id
      })
    }

    const isOk = await this.dataStore.saveApplication(loadingProperty)

    if (showOverview && isOk) {
      this.selectOfficer(null)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  async handleAgreementSignatoryChange(value: boolean, id: string, shareholder?: boolean) {
    if (value === true) {
      await this.findAndUpdateAgreementSignee()
    }

    if (shareholder) {
      this.dataStore.updateShareholder({ id, agreementSignatory: value })
    } else {
      this.dataStore.updateOfficer({
        id,
        agreementSignatory: value
      })
    }
  }

  async findAndUpdateAgreementSignee() {
    const agreementSignatoryOfficer = this.dataStore.application?.companyOfficerList?.find(
      o => o.agreementSignatory === true
    )
    agreementSignatoryOfficer &&
      (await this.dataStore.updateOfficer({
        ...agreementSignatoryOfficer,
        agreementSignatory: false
      }))

    const agreementSignatoryPcs = this.dataStore.application?.companyPCSList?.find(o => o.agreementSignatory === true)
    agreementSignatoryPcs &&
      (await this.dataStore.updateShareholder({
        ...agreementSignatoryPcs,
        agreementSignatory: false
      }))
  }

  async verifyCompanyOfficer(data: OfficerProfile) {
    runInAction(() => {
      this.officerVerificationLoading = true
    })

    runInAction(() => {
      this.isCheckTimeoutDone = false
    })

    const { officerRole } = this.selectedOfficer as OfficerProfile

    const requestData: VerifyOfficerRequestType = {
      role: officerRole || 'individual-shareholder',
      person: {
        firstName: data.nameElements?.forename,
        surname: data.nameElements?.surname,
        middleName: data.nameElements?.middleName,
        dateOfBirth: data.dateOfBirth,
        gender: data.gender,
        residentialAddress: {
          addressLine1: data.address.addressLine1,
          addressLine2: data.address?.addressLine2,
          country: data.address.country,
          postalCode: data.address.postalCode,
          countyOrState: data.address.county,
          townOrCity: data.address.locality,
          houseName: data.address.houseName,
          houseNumber: data.address.houseNumber
        }
      }
    }

    await verifyOfficers(requestData)
      .then(res => {
        this.officerVerificationResult = res.result
        officerRole
          ? this.submitOfficer(data, 'isSaveLoading', false)
          : this.submitShareholder(data, 'isSaveLoading', false)
      })
      .catch(er => message.error(translations().errorVerifyOfficer))

    runInAction(() => {
      this.officerVerificationLoading = false
    })

    runInAction(() => {
      setTimeout(() => {
        this.isCheckTimeoutDone = true
      }, 20000)
    })
  }

  async sendJumioRequest(officer: OfficerProfile) {
    try {
      runInAction(() => {
        this.isJumioRequestLoading = true
      })

      this.uploadDocManually = false

      const { officerRole } = this.selectedOfficer as OfficerProfile

      const dt: JumioCheckDataType = {
        userId: officer.emailAddress,
        userCheckRequest: {
          firstName: officer?.nameElements?.forename,
          lastName: officer?.nameElements?.surname,
          address: {
            line1: `${officer?.address?.houseNumber ? `${officer?.address?.houseNumber} ` : ''}${
              officer?.address?.addressLine1
            }`,
            line2: `${officer?.address?.houseName ? `${officer?.address?.houseName} ` : ''} ${
              officer?.address?.addressLine2
            }`,
            postalCode: officer?.address?.postalCode,
            city: officer?.address?.locality,
            country: officer?.address?.country,
            subdivision: officer?.address?.region
          }
        }
      }

      const { status } = await jumioRequestStart(this.dataStore.applicationId, dt)

      if (status === 200) {
        this.jumioRequestSent = true

        officerRole
          ? this.submitOfficer(officer, 'isSaveLoading', false)
          : this.submitShareholder(officer, 'isSaveLoading', false)
      } else {
        message.error(translations().jumioError, 10)
      }
    } catch (error) {
      message.error(translations().jumioError, 10)
    } finally {
      runInAction(() => {
        this.isJumioRequestLoading = false
      })
    }
  }

  async checkJumioResults(firstName: string, lastName: string) {
    try {
      runInAction(() => {
        this.isJumioResultsLoading = true
      })
      const { status, result } = await jumioVerificationCheckResults(
        this.parentStore.dataStore.applicationId,
        firstName,
        lastName
      )
      if (status !== 200) {
        message.error(translations().ecoSpendError, 10)
      }

      this.jumioResults = { identityVerified: result?.identityVerified, addressVerified: result?.addressVerified }
    } finally {
      runInAction(() => {
        this.isJumioResultsLoading = false
      })
    }
  }

  async resendJumioEmail(firstName: string, lastName: string) {
    try {
      runInAction(() => {
        this.isResendEmailLoading = true
      })

      runInAction(() => {
        this.isResendTimeoutDone = false
      })

      const { status } = await resendJumioLinkEmail(this.dataStore.applicationId, firstName, lastName)
      if (status === 200) {
        message.success(translations().resendSuccess, 11)
      }

      runInAction(() => {
        setTimeout(() => {
          this.isResendTimeoutDone = true
        }, 10000)
      })
    } catch (e) {
      message.error(translations().resendError, 10)
    } finally {
      runInAction(() => {
        this.isResendEmailLoading = false
      })
    }
  }

  handleSaveOfficer(data: OfficerProfile) {
    this.submitOfficer(data, 'isSaveLoading', false)
    this.selectOfficer(null)
  }

  handleSaveShareholder(data: OfficerProfile) {
    this.submitShareholder(data, 'isSaveLoading', false)
    this.selectOfficer(null)
  }

  handleSubmitOfficer(data: OfficerProfile) {
    this.submitOfficer(data, 'isNextLoading')
  }

  handleSubmitShareholder(data: OfficerProfile) {
    this.submitShareholder(data, 'isNextLoading')
  }

  async handleSubmitBusinessOwner(data: OfficerProfile) {
    if (await this.checkOwner(data)) this.submitOfficer(data, 'isNextLoading')
  }
  async handleSaveBusinessOwner(data: OfficerProfile) {
    await this.submitOfficer(data, 'isSaveLoading', false)
    this.selectOfficer(null)
  }
}

function getBase64(file: File | Blob) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })
}
