import { log } from 'dna-common'
import { inject, injectable } from 'inversify'
import { message, FormInstance } from 'antd'
import { makeObservable, computed, runInAction, observable, action } from 'mobx'
import { AppStore } from '../AppStore'
import { MerchantsManagementStoreSymbol } from '~/code/pages'
import { MerchantsManagementStore } from './MerchantsManagementStore'
import { IBalancesStore } from '~/code/pages/MerchantsManagement/components/Balances/IBalancesStore'
import { getBalances, loadSalesForceData, startAdjustmentProcess } from './services/fetchers'
import { Routes } from '~/code/startup/Router/Routes'
import { goToRoute } from '~/code/startup/Router/utils'
import {
  BalancesType,
  SalesForceDataResType,
  AdjustmentProcessReqType,
  CompanyFullDossierV2Type
} from './models/merchant-dossier-v2'
import { stringType, jsonType } from './constants'
import translations from './translations'

@injectable()
export class BalancesStore implements IBalancesStore {
  isBalancesLoading: boolean = false
  isSFDataLoading: boolean = false
  merchantsManagementStore: MerchantsManagementStore
  balancesResult: BalancesType[] = []
  isAdjustmentModalOpen: boolean = false
  sfDataResult: SalesForceDataResType = {} as SalesForceDataResType
  midsAreEqual: boolean = true
  selectedMid: string = ''
  isAdjustmentStartLoading: boolean = false
  noSFDataFound: boolean = false
  constructor(@inject(MerchantsManagementStoreSymbol) merchantsManagementStore: MerchantsManagementStore) {
    this.merchantsManagementStore = merchantsManagementStore
    makeObservable(this, {
      isBalancesLoading: observable,
      balancesResult: observable,
      isAdjustmentModalOpen: observable,
      isSFDataLoading: observable,
      selectedMid: observable,
      isAdjustmentStartLoading: observable,
      noSFDataFound: observable,
      companyFullDossierV2: computed,
      isCompanyFullDossierV2Exists: computed,
      isSubsidiary: computed,
      handleAdjustmentModalStatus: action.bound
    })
  }
  get companyFullDossierV2(): CompanyFullDossierV2Type {
    return this.merchantsManagementStore.companyFullDossierV2
  }

  get isSubsidiary(): boolean {
    return this.merchantsManagementStore.isSubsidiary
  }

  get isCompanyFullDossierV2Exists(): boolean {
    return this.merchantsManagementStore.isCompanyFullDossierV2Exists
  }

  handleBalancesResult(result: BalancesType[]): BalancesType[] {
    return result?.map((balance, idx) => ({ ...balance, key: idx })) || []
  }

  reset() {
    this.balancesResult = []
    this.selectedMid = ''
  }

  async loadBalances() {
    try {
      runInAction(() => {
        this.isBalancesLoading = true
      })
      const { topLevelSubjectId } = this.merchantsManagementStore.companyFullDossierV2?.mainInfo
      if (!topLevelSubjectId) {
        message.error(translations().topLevelSubjectIdIsEmpty)
        return
      }
      const { status, error, result } = await getBalances(topLevelSubjectId)
      if (status !== 200 || error) {
        message.error(error?.message || translations().errLoadingBalances)
      } else {
        this.balancesResult = this.handleBalancesResult(result)
      }
    } catch (error) {
      message.error(error?.message || translations().errorChangeRoutings)
      log(error)
    } finally {
      runInAction(() => {
        this.isBalancesLoading = false
      })
    }
  }

  handleAdjustmentModalStatus(status: boolean, mid: string): void {
    this.isAdjustmentModalOpen = status
    this.midsAreEqual = true
    this.selectedMid = mid
    this.noSFDataFound = false
  }

  handleSalesForceResult(result: SalesForceDataResType[], form: FormInstance) {
    if (result?.length > 0) {
      this.sfDataResult = result[0]
      this.noSFDataFound = false
      this.validateMids(form)
    } else {
      this.clearSalesForceData(form)
      this.noSFDataFound = true
    }
  }

  clearSalesForceData(form: FormInstance) {
    this.sfDataResult = {} as SalesForceDataResType
    this.midsAreEqual = true
    this.noSFDataFound = false
    form.resetFields(['adjustmentType', 'amount', 'currency'])
  }

  setSalesForceFields(form: FormInstance) {
    const { adjustmentType, adjustmentAmount, currency } = this.sfDataResult
    form.setFieldsValue({ adjustmentType, amount: adjustmentAmount, currency })
  }

  validateMids(form: FormInstance) {
    const mid = this.sfDataResult.mid
    const selectedMidList = this.selectedMid.split(',').map(mid => mid.trim())
    this.midsAreEqual = selectedMidList.includes(mid)
    if (this.midsAreEqual) this.setSalesForceFields(form)
  }

  async loadSalesForceData(sfId: string, form: FormInstance) {
    try {
      runInAction(() => {
        this.isSFDataLoading = true
      })
      if (!sfId) return
      const { status, error, result } = await loadSalesForceData(sfId)
      if (status !== 200 || error) {
        message.error(error?.message || translations().errorLoadingSalesForceData)
        this.clearSalesForceData(form)
      } else {
        this.handleSalesForceResult(result, form)
      }
    } catch (error) {
      message.error(error?.message || translations().errorLoadingSalesForceData)
      log(error)
    } finally {
      runInAction(() => {
        this.isSFDataLoading = false
      })
    }
  }

  createAdjustmentData(
    dsrId: number,
    companyName: string,
    companyNumber: string,
    description: string
  ): AdjustmentProcessReqType {
    return {
      variables: {
        dossierId: {
          value: dsrId.toString(),
          type: stringType
        },
        initiator: {
          value: AppStore.authStore.email,
          type: stringType
        },
        companyName: {
          value: companyName,
          type: stringType
        },
        companyNumber: {
          value: companyNumber,
          type: stringType
        },
        description: {
          value: description,
          type: stringType
        },
        application: {
          value: JSON.stringify(this.sfDataResult),
          type: jsonType
        },
        mid: {
          value: this.sfDataResult.mid,
          type: stringType
        }
      }
    }
  }
  async startAdjustementProcess(form: FormInstance) {
    try {
      runInAction(() => {
        this.isAdjustmentStartLoading = true
      })
      const { description } = form.getFieldsValue()
      const { dsrId, companyName, companyNumber } = this.merchantsManagementStore.companyFullDossierV2?.mainInfo
      if (!dsrId) {
        message.error(translations().dsrIdIsEmpty)
        return
      }
      const adjustmentData = this.createAdjustmentData(dsrId, companyName, companyNumber, description)
      const { status, error, result } = await startAdjustmentProcess(adjustmentData)
      if (status !== 200 || error) {
        message.error(error?.message || translations().errStartingAdjustment)
      } else {
        message.success(result.message || translations().requestSuccess)
        this.clearSalesForceData(form)
        this.handleAdjustmentModalStatus(false, '')
        goToRoute(Routes.BPM_PROCESSES_ADJUSTMENT_REQUESTS)
      }
    } catch (error) {
      message.error(error?.message || translations().errStartingAdjustment)
      log(error)
    } finally {
      runInAction(() => {
        this.isAdjustmentStartLoading = false
      })
    }
  }
}
