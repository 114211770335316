import Chart from 'chart.js'
import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'
import { isSet } from '~/code/services/utils'
import { GaugeChartProps } from './props'
import styles from './GaugeChart.scss'

const options = {
    layout: {
        padding: {
            bottom: 3
        }
    },
    rotation: 1 * Math.PI,
    circumference: 1 * Math.PI,
    legend: {
        display: false
    },
    tooltips: {
        enabled: false
    },
    plugins: {
        labels: {
          render: 'label',
          arc: true,
          fontColor: '#000',
          outsidePadding: 0,
          textMargin: 0,
          position: 'outside'
        }
    },
    cutoutPercentage: 70
}

export const GaugeChart: React.FC<GaugeChartProps> = (props) => {
    const { data: [item1, item2], needleValue, unit, className } = props
    const maxNeedleValue =  3 * item2 / 2

    const data = {
        labels: [item1 > 1 ? `0-${item1}` : 1, item2 > item1 + 1 ? `${item1 + 1}-${item2}` : item2, `${item2 + 1}+`],
        datasets: [
            {
                data: [item1, item2 - item1, item2 / 2],
                needleValue: needleValue > maxNeedleValue ? maxNeedleValue : needleValue - 0.02,
                backgroundColor: ['rgba(82, 196, 26, 0.8)', 'rgba(250, 140, 22, 0.8)', 'rgba(245, 34, 45, 0.8)'],
                hoverBorderWidth: [0, 0, 0],
                hoverBackgroundColor: ['rgba(82, 196, 26, 0.8)', 'rgba(250, 140, 22, 0.8)', 'rgba(245, 34, 45, 0.8)']
            }
        ]
    }

    const chartRef = useRef(null)

    useEffect(() => {
        createChart()
    }, [])

    const createChart = () => {
        const ctx = chartRef.current.getContext('2d')

        const chart = new Chart(ctx, {
            type: 'doughnut',
            data,
            options,
            plugins: [
                {
                    afterDraw: (chartInstance) => {
                        const chartNeedleValue = chartInstance.chart.config.data.datasets[0].needleValue
                        const dataTotal = chartInstance.chart.config.data.datasets[0].data.reduce(
                            (a, b) => a + b,
                            0
                        )
                        const angle = Math.PI + (1 / dataTotal) * chartNeedleValue * Math.PI
                        const chartInstanceContext = chartInstance.chart.ctx
                        const cw = chartInstance.chart.canvas.offsetWidth
                        const ch = chartInstance.chart.canvas.offsetHeight
                        const cx = cw / 2
                        const cy = ch - 23

                        chartInstanceContext.translate(cx, cy)
                        chartInstanceContext.rotate(angle)
                        chartInstanceContext.beginPath()
                        chartInstanceContext.moveTo(0, -3)
                        chartInstanceContext.lineTo(ch - 45, 0)
                        chartInstanceContext.lineTo(0, 3)
                        chartInstanceContext.fillStyle = 'rgb(0, 0, 0)'
                        chartInstanceContext.fill()
                        chartInstanceContext.rotate(-angle)
                        chartInstanceContext.translate(-cx, -cy)
                        chartInstanceContext.beginPath()
                        chartInstanceContext.arc(cx, cy, 5, 0, Math.PI * 2)
                        chartInstanceContext.fill()
                    }
                }
            ]
        })
    }

    return <div className={classNames(styles.GaugeChart, className)}>
        <canvas
            id={`gauge-${styles.GaugeChart}`}
            ref={chartRef}
        />
        <div className={styles.needleText}>
            { isSet(needleValue) ? needleValue :  '-'} <span className={styles.unit}>{unit}</span>
        </div>
    </div>
}
