import { message } from 'antd'
import { apiCheck, noThrow } from 'back-connector'
import { action, makeObservable, observable, runInAction } from 'mobx'
import React from 'react'
import { SelectItem } from '~/code/models'
import { CompanyResponseItem } from '../models'
import {
  fetchCompaniesByName,
  convertResponseToCompanies,
  filterCompanyByCompanyNumber,
  checkCompany
} from '../services'
import { StartProcessStore } from '../StartProcessStore'
import translations from '../translations'
import { isEmpty } from 'dna-common'

export class CompanyNameFormStore {
  public company: CompanyResponseItem = undefined
  public companies: SelectItem[] = []
  public isCompanyInfoLoading: boolean = false
  activeProcessCheckPassed: number = 0
  isActiveProcessCheckLoading: boolean = false

  constructor(private parentStore: StartProcessStore) {
    makeObservable(this, {
      company: observable,
      companies: observable,
      isCompanyInfoLoading: observable,
      activeProcessCheckPassed: observable,
      isActiveProcessCheckLoading: observable,

      reset: action,
      selectCompany: action,
      searchCompanies: action,
      getCompanyInformation: action,
      checkExistingCompany: action.bound
    })
  }

  async getCompanyInformation(companyNumber: string) {
    if (companyNumber) {
      runInAction(() => {
        this.isCompanyInfoLoading = true
      })
      await this.parentStore.completeApplicationStore.loadCompanyInformation(companyNumber)
      await this.parentStore.contactInfoStore.loadCompanyOfficersAndShareholdersFromCS(companyNumber, 0, 0)

      runInAction(() => {
        this.isCompanyInfoLoading = false
      })
    }
  }

  get dataStore() {
    return this.parentStore.dataStore
  }

  public async selectCompany(companyNumber: string) {
    if (!companyNumber) {
      this.companies = []
      this.company = null
      return
    }

    const company = filterCompanyByCompanyNumber(this.companies.slice(), companyNumber)

    const compChecked: CompanyResponseItem = company && company.params ? { ...company.params } : null

    await this.checkExistingCompany(companyNumber, company.params?.email)

    await this.getCompanyInformation(companyNumber)

    this.company = compChecked
    this.dataStore.setCompany(compChecked)
  }

  async checkExistingCompany(companyNumber: string, email: string) {
    const config = {
      content: translations().applicationAlreadySubmitted,
      duration: 10
    }
    try {
      runInAction(() => {
        this.isActiveProcessCheckLoading = true
      })
      const { result, status } = await checkCompany(companyNumber, email)

      if (status !== 200) {
        this.activeProcessCheckPassed = 2
        config.content = translations().companyCheckErr
        message.error(config)
      }

      if (!isEmpty(result)) {
        this.activeProcessCheckPassed = 0

        config.content = translations().applicationAlreadySubmitted
        message.error(config)
      } else {
        this.activeProcessCheckPassed = 1
      }
    } catch (err) {
      this.activeProcessCheckPassed = 2
      config.content = translations().companyCheckErr
      message.error(config)
    } finally {
      runInAction(() => {
        this.isActiveProcessCheckLoading = false
      })
    }
  }

  public async searchCompanies(value: string) {
    if (!!value && value.length && value.length >= 3) {
      const result = await noThrow(apiCheck(fetchCompaniesByName(value)))
      if (!result.error) {
        this.companies = convertResponseToCompanies(result.value)
        return
      } else {
        this.companies = []
        message.error(result.error ? result.error.message : translations().couldNotLoadCompanies)
      }
    } else {
      this.companies = []
    }
  }

  public reset() {
    this.company = null
    this.companies = []
  }
}
