import { getWithAuth, postWithAuth } from '~/code/services'
import { camelCaseObject } from 'dna-common'
import {
  Application,
  CheckDocumentRequirementsRes,
  DocssierCompanyResponseType,
  DocusignMemberResponseType,
  JumioCheckDataType,
  JumioResultsType,
  OnboardingRequest,
  OpenBankingCheckAccDataType,
  OpenBankingResultsType,
  OpportunityOwnerInfoResponseType,
  SortCodeCheckInBaseResponseType
} from '../models'
import {
  VerifyBankAccountReq,
  VerifyBankAccountRes,
  VerifyOfficerRequestType,
  VerifyOfficerResponseType
} from '../models/verification'
import { FetchApplicationStatusRes } from '../models/onboarding/FetchApplicationStatusRes'
import { OnboardingStartData } from '../models/onboarding/OnboardingStartData'
import { CheckBankAccountDataType, CheckBankAccountResultType } from '../models'
import { MCCByIndustryResponseType } from '../models/MCCByIndustryResponseType'
import { OnboardingSettingsResponseType } from '../models/onboarding/OnboardingSettingsResponseType'
import { OnboardingCountriesResponseType } from '../models/onboarding/OnboardingCountriesResponseType'

export const fetchCompaniesByName = async (name: string) => {
  return getWithAuth<any>(`/api/merchant`, {
    name,
    size: 20,
    page: 0
  }).then(camelCaseObject)
}

export const saveCompleteApplication = (email: string, data: Application) =>
  postWithAuth<any>(`/api/process/onboarding/key/${email}/application`, data).then(camelCaseObject)

export const loadCompanyInformation = (companyNumber: string) =>
  getWithAuth<any>(`/api/merchant/${companyNumber}/full?officerRole=director`).then(camelCaseObject)

export const loadCompanyMainInfoFromCreditSafe = (companyNumber: string) =>
  getWithAuth<any>(`/api/processes-api/v1/companies?companyNumber=${companyNumber}`).then(camelCaseObject)

export const loadCompanyAdditionalInfoFromCreditSafe = (companyId: string) =>
  getWithAuth<any>(`/api/processes-api/v1/companies/${companyId}`).then(camelCaseObject)

export const verifyBankAccount = (data: VerifyBankAccountReq) =>
  postWithAuth<VerifyBankAccountRes>('/api/v1/sort-codes/validation', data)

export const fetchApplicationStatus = (email: string) =>
  getWithAuth<FetchApplicationStatusRes>(`/api/v2/sales-onboarding/logins/${email}/st`)

export const startApplication = (data: OnboardingStartData) =>
  postWithAuth<{ id: string }>(`/api/processes-api/v2.1/sales-onboarding/applications`, data)

export const saveApplication = (id: string, data: OnboardingRequest) =>
  postWithAuth<{ id: string }>(`/api/processes-api/v2.1/sales-onboarding/applications/${id}/save`, data).then(
    camelCaseObject
  )

export const completeApplication = (id: string, data: OnboardingRequest) =>
  postWithAuth(`/api/processes-api/v2.1/sales-onboarding/applications/${id}/complete`, data).then(camelCaseObject)

export const checkSalesforceOpportunityID = (id: string) =>
  getWithAuth<{ code: number; message: string }>(`/bpm/v2/sales-onboarding/opportunities/${id}`)

export const checkDocumentRequirements = (id: string, data: OnboardingRequest) =>
  postWithAuth<CheckDocumentRequirementsRes>(
    `/api/processes-api/v2.1/sales-onboarding/applications/${id}/document-requirements`,
    data
  )

export const checkCompany = (companyNumber: string, companyEmail?: string) =>
  getWithAuth<any>(
    `/api/processes-api/v2/dossier/legal?company_number=${companyNumber}${
      companyEmail ? `&email_address=${companyEmail}` : ''
    }&is_active=true`
  )

export const checkPerson = (
  firstName: string,
  surName?: string,
  birthDay?: string,
  email?: string,
  postalCode?: string
) =>
  getWithAuth<any>(
    `/api/processes-api/v1/dossier/person?first_name=${firstName}${surName ? `&surname=${surName}` : ''}${
      birthDay ? `&birthday=${birthDay}` : ''
    }${email ? `&email_address=${email}` : ''}${postalCode ? `&postal_code=${postalCode}` : ''}`
  )

export const checkBankAccount = (id: string, data: CheckBankAccountDataType) =>
  postWithAuth<CheckBankAccountResultType>(
    `/api/processes-api/v2.1/sales-onboarding/applications/${id}/verifications/bank-account`,
    data
  )

export const verifyOfficers = (data: VerifyOfficerRequestType) =>
  postWithAuth<VerifyOfficerResponseType>(
    `/api/processes-api/v2.1/sales-onboarding/verifications/address-and-identity`,
    data
  )

export const checkEmailAddress = (email: string) =>
  getWithAuth<DocssierCompanyResponseType>(`/api/processes-api/v2/dossier/legal?email_address=${email}`)

export const fetchMccOptionsByIndustry = (industry: string) =>
  getWithAuth<MCCByIndustryResponseType[]>(`/api/processes-api/dictionary/mcc/industry/${industry}`)

export const onboardingSettings = (partner: string, country?: string) => {
  return getWithAuth<OnboardingSettingsResponseType>(
    `/bpm/onboarding/settings?partner=${partner}${country ? `&country=${country}` : ''}`
  )
}

export const fetchOnboardingCountriesInfo = () =>
  getWithAuth<OnboardingCountriesResponseType[]>(`/api/processes-api/v2/dossier/dictionary/d_countries`)

export const fetchDocusignSettings = () => getWithAuth<DocusignMemberResponseType>(`/bpm/pilot/docusign/member`)

export const openBankingVerificationCheck = (id: string, data: OpenBankingCheckAccDataType) =>
  postWithAuth(`/api/processes-api/v2.1/sales-onboarding/applications/${id}/verifications/ecospend`, data)

export const openBankingResultsCheck = (id: string, accNum: string, sortCode: string) =>
  getWithAuth<OpenBankingResultsType>(
    `/api/processes-api/v2.1/sales-onboarding/applications/${id}/verifications/ecospend?bankAccount=${accNum}&sortCode=${sortCode}`
  )

export const jumioRequestStart = (id: string, data: JumioCheckDataType) =>
  postWithAuth(`/api/processes-api/v2.1/sales-onboarding/applications/${id}/verifications/jumio`, data)

export const jumioVerificationCheckResults = (id: string, firstName: string, lastName: string) =>
  getWithAuth<JumioResultsType>(
    `/api/processes-api//v2.1/sales-onboarding/applications/${id}/verifications/jumio?firstName=${firstName?.toUpperCase()}&lastName=${lastName?.toUpperCase()}`
  )

export const opportunityOwnerInfo = (opportunityId: string) => {
  return getWithAuth<OpportunityOwnerInfoResponseType>(`/bpm/v1/salesforce/opportunity/${opportunityId}/owner`)
}

export const resendJumioLinkEmail = (processId: string, firstName: string, lastName: string) =>
  postWithAuth(
    `/api/processes-api/v2.1/sales-onboarding/applications/${processId}/notifications/jumio/applicant?firstName=${firstName}&lastName=${lastName}`
  )

export const resendOBEmail = (processId: string) =>
  postWithAuth(`/api/processes-api/v2.1/sales-onboarding/applications/${processId}/notifications/ecospend/applicant`)

export const fetchOBQuestionnaireSettings = () =>
  getWithAuth<DocusignMemberResponseType>(`/bpm/pilot/ecospend-questionnaire/member`)

export const sortCodeCheckInBase = (sortCode: string) => {
  return getWithAuth<SortCodeCheckInBaseResponseType>(`/bpm/adapter-ecospend/v1/sortcodes/${sortCode}`)
}
