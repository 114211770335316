import { inject, injectable } from 'inversify'
import { makeObservable, action } from 'mobx'
import { StoresDossierV2Store } from './StoresDossierV2Store'
import { StoresDossierV2StoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores'
import { TariffsTableStoreType } from '~/code/pages/MerchantsManagement/components/Stores/components/TariffsTable/TariffsTableStoreType'
import {
  GroupedTerminalsDataType,
  TariffType,
  BlendedTariffTabTableType,
  IcPlusTariffTabTableType
} from './models/merchant-dossier-v2'

@injectable()
export class TariffsTableStore implements TariffsTableStoreType {
  storesDossierV2Store: StoresDossierV2Store
  constructor(@inject(StoresDossierV2StoreSymbol) storesDossierV2Store: StoresDossierV2Store) {
    this.storesDossierV2Store = storesDossierV2Store
    makeObservable(this, {
      getBlendedTariffs: action
    })
  }

  getIcPlusTariffs = (terminal: GroupedTerminalsDataType): IcPlusTariffTabTableType[] => {
    const terminalTariffsList = terminal.tariffs.map((tariff, idx) => {
      return {
        key: idx.toString(),
        cardScheme: tariff.cardScheme,
        cardType: tariff.cardType,
        corporateOrConsumer: tariff.corporateOrConsumer,
        product: tariff.product,
        serviceArea: tariff.serviceArea,
        tariffType: tariff.tariffType,
        interchangeFee: tariff.interchangeFee?.percentFee,
        schemeBaseFee: tariff.schemeFee?.baseFee,
        schemePercentFee: tariff.schemeFee?.percentFee,
        totalBaseFee: tariff.totalFee?.baseFee,
        totalPercentFee: tariff.totalFee?.percentFee,
        standardBaseFee: tariff.standardFee?.baseFee,
        standardPercentFee: tariff.standardFee?.percentFee
      }
    })
    return terminalTariffsList
  }

  getBlendedTariffs = (
    terminal: GroupedTerminalsDataType,
    contractTariffs: TariffType[]
  ): {
    terminalTariffs: BlendedTariffTabTableType[] | IcPlusTariffTabTableType[]
    generalTariffs: BlendedTariffTabTableType[]
    tariffType: 'icPlus' | 'blended'
  } => {
    const icPlus = terminal.tariffs.filter(terminal => terminal.tariffType === 'ic+')

    const generalTariffsList = terminal.generalTariffs.map((tariff, idx) => {
      return {
        key: `1${idx}`,
        name: tariff.tariffName,
        feeBase: tariff.feeBase,
        feePercent: tariff.feePercent,
        settlement: 0
      }
    })
    const contractTariffsList = contractTariffs.map((tariff, idx) => {
      return {
        key: `2${idx}`,
        name: tariff.tariffName,
        feeBase: tariff.feeBase,
        feePercent: tariff.feePercent,
        settlement: tariff.settlementPeriod
      }
    })
    if (icPlus.length > 0) {
      const terminalTariffsList = this.getIcPlusTariffs(terminal)
      return {
        terminalTariffs: terminalTariffsList,
        generalTariffs: contractTariffsList.concat(generalTariffsList),
        tariffType: 'icPlus'
      }
    }
    const blended = terminal.tariffs.map((tariff: TariffType, idx) => {
      return {
        key: `3${idx}`,
        name: `${tariff.cardScheme} ${tariff.serviceArea} ${tariff.corporateOrConsumer} ${tariff.cardType}`,
        feeBase: tariff.interchangeFee?.baseFee,
        feePercent: tariff.interchangeFee?.percentFee,
        settlement: tariff.settlementPeriod
      }
    })
    return {
      terminalTariffs: blended.concat(contractTariffsList).concat(generalTariffsList),
      generalTariffs: [],
      tariffType: 'blended'
    }
  }
}
