import { Descriptions } from 'antd'
import React from 'react'
import { OfficerInfoPropsType } from './props'
import translations from './translations'

export const OfficerInfo = ({ data }: OfficerInfoPropsType) => {
  return (
    <Descriptions bordered column={1}>
      <Descriptions.Item label={<b>{translations().title}</b>}>{data.title}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().firstName}</b>}>{data.firstName}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().middleName}</b>}>{data.middleName}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().surname}</b>}>{data.surname}</Descriptions.Item>
      <Descriptions.Item
        label={<b>{translations().dateOfBirth}</b>}
      >{`${data.dateOfBirth.day}.${data.dateOfBirth.month}.${data.dateOfBirth.year}`}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().gender}</b>}>{data.gender}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().nationality}</b>}>{data.nationality}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().occupation}</b>}>{data.occupation}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().emailAddress}</b>}>{data.emailAddress}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().telephoneNumber}</b>}>{data.telephoneNumber}</Descriptions.Item>
      {data?.ownershipPercentage && (
        <Descriptions.Item label={<b>{translations().ownershipPercentage}</b>}>
          {data.ownershipPercentage}
        </Descriptions.Item>
      )}

      <Descriptions.Item label={<b>{translations().postalCode}</b>}>
        {data.residentialAddress?.postalCode}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().country}</b>}>{data.residentialAddress?.country}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().addressLine1}</b>}>
        {data.residentialAddress?.addressLine1}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().addressLine2}</b>}>
        {data.residentialAddress?.addressLine2}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().townOrCity}</b>}>
        {data.residentialAddress?.townOrCity}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().countyOrState}</b>}>
        {data.residentialAddress?.countyOrState}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().county}</b>}>{data.residentialAddress?.county}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().houseName}</b>}>
        {data.residentialAddress?.houseName}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().houseNumber}</b>}>
        {data.residentialAddress?.houseNumber}
      </Descriptions.Item>
    </Descriptions>
  )
}
