import React from 'react'
import {Divider, Typography} from 'antd'
import { TwoFA} from './components'

import translations from './translations'
import styles from './styles.scss'

export const ProfileStoreSymbol = Symbol('ProfileStore')

const Profile = () => {
    return (
        <div className={styles.root}>
            <Typography.Title level={3}>{translations().title}</Typography.Title>

            <Divider />

            <TwoFA />
        </div>
    )
}

export default Profile
