import { message } from 'antd'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { inject, injectable } from 'inversify'
import { FileKey } from '~/code/pages/MerchantsManagement/components/ChangeOfSettlementPeriod/ChangeOfSettlementPeriodStoreType'
import { CheckedDocumentType } from 'startapp/models/DocumentsRequiredModel'
import { deleteDocument, uploadDocument } from 'startapp/components/SupportingDocuments/services'
import { camelToKebabCase } from 'startapp/services'
import { UploadFile } from 'antd/lib/upload/interface'
import { MerchantsManagementStoreSymbol } from '~/code/pages'
import { MerchantsManagementStore } from '~/code/stores/MerchantsManagementStore/MerchantsManagementStore'
import { UploadDocumentRes } from 'startapp/components/SupportingDocuments/models'
import { uploadAdditionalDoc } from '~/code/stores/MotoVtRequestsStore/services/fetchers'
import translations from './translations'
import { availableDocumentTypes } from '~/code/pages/MerchantsManagement/components/ChangeOfSettlementPeriod/constants'
import { MotoVtRequestsStoreSymbol } from '~/code/pages'
import { IMotoVtRequestsStore } from '~/code/pages/BPMProcesses/Requests/components/MotoVtRequests/IMotoVtRequestsStore'
import { IMotoVtRequestsDrawerTitleStore } from '~/code/pages/BPMProcesses/Requests/components/MotoVtRequests/components/MotoVtRequestsDrawerTitle/IMotoVtRequestsDrawerTitleStore'

@injectable()
export class MotoVtRequestsDrawerTitleStore implements IMotoVtRequestsDrawerTitleStore {
    motoVtStore: IMotoVtRequestsStore
    merchantsManagementStore: MerchantsManagementStore
    isDocumentLoading: boolean
    isLoading: boolean
    showUploadModal: boolean
    documentsMap: Record<FileKey, UploadFile[]> = {
        bankStatement: [],
        processingStatement: [],
        other: []
    }
    documentTypes: FileKey[] = []
    documentsData: UploadDocumentRes[] = []
    isNoDocumentsError: boolean


    constructor(
        @inject(MotoVtRequestsStoreSymbol) motoVtRequestsStore : IMotoVtRequestsStore,
        @inject(MerchantsManagementStoreSymbol) merchantsManagementStore: MerchantsManagementStore
    ) {
        this.motoVtStore = motoVtRequestsStore
        this.merchantsManagementStore = merchantsManagementStore
        this.isDocumentLoading = false
        this.isLoading = false
        this.documentTypes = []
        this.showUploadModal = false
        this.isNoDocumentsError = false


        makeObservable(this, {
            isDocumentLoading: observable,
            documentsMap: observable,
            documentTypes: observable,
            isLoading: observable,
            showUploadModal: observable,
            isNoDocumentsError: observable,

            availableDocumentTypeOptions: computed,

            setNoDocumentsError: action.bound,
            addDocumentType: action.bound,
            setDocumentsMap: action.bound,
            setUploadModal: action.bound,
            clearData: action.bound
        })
    }
    
    get availableDocumentTypeOptions() {
        return availableDocumentTypes.filter((type) => 
            this.motoVtStore.processDetails?.requiredDocuments[type.value]
        )
    }

    public setUploadModal(value: boolean) {
        this.showUploadModal = value
    }

    public setNoDocumentsError(value: boolean) {
        this.isNoDocumentsError = value
    }

    public addDocumentTypesArray(value: FileKey) {
        if (this.documentTypes.includes(value)) return
        this.documentTypes.push(value)
    }

    public setDocumentsMap(fileKey: FileKey, fileList: UploadFile[]) {
        this.documentsMap[fileKey] = fileList
    }

    addDocumentType(fileKey: FileKey, fileList: UploadFile[]) {
        this.setDocumentsMap(fileKey, [...this.documentsMap[fileKey], ...fileList])
        this.addDocumentTypesArray(fileKey)
    }

    public removeDocument(fileKey: FileKey, fileList: UploadFile[]) {
        this.setDocumentsMap(fileKey, fileList)
        if (fileList.length === 0) this.removeDocumentType(fileKey)
    }

    public removeDocumentType(value: FileKey) {
        this.documentTypes = this.documentTypes.filter(doc => doc !== value)
    }

    public clearData() {
        this.documentsData = []
        this.documentTypes = []
        this.documentsMap = {
            bankStatement: [],
            processingStatement: [],
            other: []
        }
    }

    public onFormFinish = async(values) => {

        if (!this.documentsData.length && this.availableDocumentTypeOptions.length) {
            this.setNoDocumentsError(true)
            return
        }

        const data = {
            comment: values.comment,
            documents: this.documentsData.map((document) => ({
                path: document.path,
                name: document.fileName,
                type: camelToKebabCase(document.type)
            }))
        }

        try {
            runInAction(() => {
                this.isLoading = true
            })
            const { status, error, result } = await uploadAdditionalDoc(this.motoVtStore.selectedProcessId, data)

            if (status === 200 && result.code === 0) {
                message.success(result.message || translations().requestSuccess)
                this.setUploadModal(false)
                this.motoVtStore.closeInfoDrawer()
                this.motoVtStore.loadProcesses({page: 1})
            } else {
                throw new Error(error.message || translations().requestFail)
            }

        } catch (error) {
            message.error(error.message || translations().requestFail)
        } finally {
            runInAction(() => {
                this.isLoading = false
            })
        }
    }

    async uploadFile(documentType: FileKey | CheckedDocumentType, file: File): Promise<boolean> {
        if (!file) return false

        let isUploaded: boolean

        runInAction(() => {
            this.isDocumentLoading = true
        })

        try {
            const { status, error, result } = await uploadDocument({
                companyName: this.motoVtStore.processDetails?.companyName,
                companyNumber: this.motoVtStore.processDetails?.companyNumber,
                acquisitionChannel: this.motoVtStore.processDetails?.acquisitionChannel,
                documentType: camelToKebabCase(documentType),
                file
            })
            if (status !== 200 || error || !result || result.length <= 0) {
                message.error(error.message || translations().errorUploadingDocument)
                isUploaded = false
            } else {
                runInAction(() => {
                    this.documentsData.push({ ...result[0], internalFileName: file.name, type: documentType })
                })
                this.setNoDocumentsError(false)
                isUploaded = true
            }
        } catch (error) {
            message.error(translations().errorUploadingDocument)
            isUploaded = false
        }

        runInAction(() => {
            this.isDocumentLoading = false
        })

        return isUploaded
    }

    async deleteFile(documentType: FileKey | CheckedDocumentType, fileName: string): Promise<boolean> {
        let isDeleted: boolean
        try {

            const path = this.documentsData.find(d => d?.internalFileName === fileName && d?.type === documentType)?.path
            if (path) {
                const { status, error } = await deleteDocument(path)
                if (status !== 200 || error) {
                    message.error(error.message || translations().errorDeletingDocument)
                    isDeleted = false
                } else {
                    isDeleted = true
                }
            }
        } catch (error) {
            message.error(translations().errorDeletingDocument)
            isDeleted = false
        }
        return isDeleted
    }
}
