import { UploadOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Form, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { SupportingDocumentsType } from 'startapp/models'
import { FormTitle } from 'startapp/components'
import { StartAppContainer } from '../StartAppContainer'
import { FormSubgroupTitle } from '../shared'
import translations from './translations'
import styles from './SupportingDocuments.scss'
import { CustomButton, DNALoader, FilterSelect } from '~/code/components'
import { SupportingDocument, Upload } from './components'
import { availableAdditionalDocuments } from './components/SupportingDocument/constants/constants'
import { SupportingDocumentsStore } from '../../stores/SupportingDocumentsStore'
import classNames from 'classnames'

type Props = { store: SupportingDocumentsStore }

export const SupportingDocuments = observer(({ store }: Props) => {
  const [documentType, setDocumentType] = useState<keyof SupportingDocumentsType>('' as keyof SupportingDocumentsType)

  useEffect(() => {
    !store.isDocsSectionCompleted && store.checkDocumentsRequired()
  }, [])

  const renderAdditionalDocumentUpload = (_documentType: keyof SupportingDocumentsType) => {
    const acceptedExtensions = _documentType === 'additionalTidsRequestForm' ? '.xls, .xlsx' : undefined
    return (
      <Upload
        acceptedExtensions={acceptedExtensions}
        disabled={!documentType}
        loading={store.isDocumentLoading.uploadAdditionalDocument}
        fileList={[]}
        onChange={async fileList => {
          const file = fileList[0]
          if (!file) return
          const isUploaded = await store.uploadFile(documentType, file?.originFileObj as File)
          if (!isUploaded) return
          store.addAdditionalDocument(documentType, fileList)
          setDocumentType('' as keyof SupportingDocumentsType)
        }}
      >
        <Button icon={<UploadOutlined />}>{translations().upload}</Button>
      </Upload>
    )
  }

  return (
    <div>
      <>
        <StartAppContainer>
          {store.isCheckDocumentsRequiredLoading && <DNALoader />}
          <FormTitle>{translations().title}</FormTitle>
          {translations().uploadInfo}

          {store.manualMSA.length > 0 && (
            <>
              <div className={styles.checkedDocument}>{translations().signedByPaytek}</div>
              {store.manualMSA.map((b, idx, arr) => (
                <SupportingDocument
                  lessMarginBottom={idx !== arr.length - 1}
                  key={b.officer as any}
                  title={b.officer}
                  loading={b.isLoading}
                  icon={<UserOutlined className={styles.userIcon} />}
                  fileList={b.fileList}
                  onChange={(fileList, file) => store.handleMSADocumentChange(fileList, file, b)}
                />
              ))}
            </>
          )}

          {store.bankDocuments.length > 0 && (
            <div className={styles.checkedDocument}>{translations().bankStatement}</div>
          )}

          {store.bankDocuments.map((b, idx, arr) => (
            <SupportingDocument
              lessMarginBottom={idx !== arr.length - 1}
              key={b.officer as any}
              title={b.officer}
              loading={b.isLoading}
              icon={<UserOutlined className={styles.userIcon} />}
              fileList={b.fileList}
              onChange={async (fileList, file) => {
                const fileToUpload = fileList[0]
                if (fileToUpload) {
                  const isUploaded = await store.uploadFile(
                    'bankStatement',
                    fileToUpload?.originFileObj as File,
                    b.officer
                  )
                  if (!isUploaded) return
                  store.setBankDocument(b.officer, fileList)
                } else {
                  const isDeleted = await store.deleteFile('bankStatement', file.name, b.officer)
                  if (!isDeleted) return
                  store.setBankDocument(b.officer, fileList)
                }
              }}
            />
          ))}

          {store.idDocuments.length > 0 && (
            <div className={styles.checkedDocument}>{translations().passportOrDriving}</div>
          )}
          {store.idDocuments.map((b, idx, arr) => (
            <SupportingDocument
              lessMarginBottom={idx !== arr.length - 1}
              key={b.officer as any}
              title={b.officer}
              loading={b.isLoading}
              notRequiredText={b.isRequired ? null : translations().notRequired.passportOrDriving}
              icon={<UserOutlined className={styles.userIcon} />}
              fileList={b.fileList}
              onChange={async (fileList, file) => {
                const fileToUpload = fileList[0]
                if (fileToUpload) {
                  const isUploaded = await store.uploadFile(
                    'idDocument',
                    fileToUpload?.originFileObj as File,
                    b.officer
                  )
                  if (!isUploaded) return
                  store.setIdDocument(b.officer, fileList)
                } else {
                  const isDeleted = await store.deleteFile('idDocument', file.name, b.officer)
                  if (!isDeleted) return
                  store.setIdDocument(b.officer, fileList)
                }
              }}
            />
          ))}
          {store.residentialUtilityBills.length > 0 && (
            <div className={styles.checkedDocument}>{translations().residentialUtilityBill}</div>
          )}
          {store.residentialUtilityBills.map((b, idx, arr) => (
            <SupportingDocument
              lessMarginBottom={idx !== arr.length - 1}
              key={b.officer as any}
              title={b.officer}
              loading={b.isLoading}
              notRequiredText={b.isRequired ? null : translations().notRequired.residentialUtilityBill}
              icon={<UserOutlined className={styles.userIcon} />}
              fileList={b.fileList}
              onChange={async (fileList, file) => {
                const fileToUpload = fileList[0]
                if (fileToUpload) {
                  const isUploaded = await store.uploadFile(
                    'residentialUtilityBill',
                    fileToUpload?.originFileObj as File,
                    b.officer
                  )
                  if (!isUploaded) return
                  store.setResidentialUtilityBillDocument(b.officer, fileList)
                } else {
                  const isDeleted = await store.deleteFile('residentialUtilityBill', file.name, b.officer)
                  if (!isDeleted) return
                  store.setResidentialUtilityBillDocument(b.officer, fileList)
                }
              }}
            />
          ))}
          <div className={styles.additionalDocumentsContainer}>
            <FormSubgroupTitle>{translations().additionalDocuments}</FormSubgroupTitle>
            {store.additionalDocumentTypes.map(d => (
              <SupportingDocument
                key={d}
                title={translations()[d]}
                fileList={store.supportingDocumentsMap[d]}
                onChange={async (fileList, file) => {
                  const isDeleted = await store.deleteFile(d, file?.name)
                  if (!isDeleted) return
                  store.removeAdditionalDocument(d, fileList)
                }}
                loading={store.isDocumentLoading[d]}
              />
            ))}

            {store.euroDocuments.map((b, idx, arr) => (
              <SupportingDocument
                lessMarginBottom={idx !== arr.length - 1}
                key={b.officer as any}
                title={b.officer}
                loading={b.isLoading}
                icon={<UserOutlined className={styles.userIcon} />}
                fileList={b.fileList}
                onChange={(fileList, file) => store.handleEuroDocumentChange(fileList, file, b)}
              />
            ))}

            <div className={styles.additionalDocumentsActions}>
              <Form layout='vertical'>
                <FilterSelect
                  formItemProps={{ label: translations().documentType }}
                  selectProps={{
                    className: styles.documentTypeSelect,
                    value: documentType,
                    onChange: setDocumentType,
                    options: availableAdditionalDocuments
                  }}
                />
              </Form>
              {renderAdditionalDocumentUpload(documentType)}
            </div>
            {documentType === 'additionalTidsRequestForm' && (
              <div className={classNames(styles.uploadFileText)}>{translations().onlyXlsFilesAreSupported}</div>
            )}
          </div>

          <Divider />

          <Row gutter={10}>
            <Col>
              <CustomButton
                disabled={!store.dataStore.uploadedDocumentsValid}
                type='primary'
                loading={store.dataStore.isNextLoading}
                onClick={() => store.setDocsCompleted()}
              >
                {translations().complete}
              </CustomButton>
            </Col>
          </Row>
        </StartAppContainer>
      </>
    </div>
  )
})
