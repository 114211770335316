import React from 'react'
import { injectable } from 'inversify'
import { IAnalyticsOverviewStore } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview'
import { AnalyticsOverviewStore } from '~/code/stores/AnalyticsStore/AnalyticsOverviewStore'
import { IAcquiringFilterStore } from '~/code/pages/Acquiring/components/AcquiringFilter'
import { IAnalyticsDetailedViewStore } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsDetailedView/models/IAnalyticsDetailedViewStore'
import { AnalyticsDetailedViewStore } from '~/code/stores/AnalyticsStore/AnalyticsDetailedViewStore'
import { AcquiringType } from '~/code/pages/Acquiring'
import { GlobalConfigStore } from '../GlobalConfigStore'
import { IAnalyticsStore } from '~/code/pages/Acquiring/pages/Analytics/models'

@injectable()
export class AnalyticsStore implements IAnalyticsStore {
  isInitiated = false

  constructor(filterStore: IAcquiringFilterStore, globalConfigStore: GlobalConfigStore, acquiringType: AcquiringType) {
    this.analyticsOverviewStore = new AnalyticsOverviewStore(filterStore, globalConfigStore, acquiringType)
    this.analyticsDetailedViewStore = new AnalyticsDetailedViewStore(filterStore, globalConfigStore, acquiringType)
  }

  // child stores
  analyticsOverviewStore: IAnalyticsOverviewStore
  analyticsDetailedViewStore: IAnalyticsDetailedViewStore

  public init = () => {
    this.isInitiated = true
    ;(this.analyticsOverviewStore as AnalyticsOverviewStore).init()
    ;(this.analyticsDetailedViewStore as AnalyticsDetailedViewStore).init()
  }
}
