import { Card, Row, Col, Typography, Divider, Space, Tooltip } from 'antd'
import React, { PropsWithChildren } from 'react'
import moment from 'moment'
import { CommentOutlined, HistoryOutlined } from '@ant-design/icons'
import { Bambora, MC, DnaVisa, Paynetics, DNALogo } from '~/assets/icons'
import { TransactionChannelNameEnum } from '~/code/pages/TransactionChannels/models/TransactionChannelNameEnum'
import { SwitchTumbler } from '~/code/pages/TransactionChannels/components'
import { TransactionChannelProps } from './props'
import translations from '../../translations'
import styles from './styles.scss'

export const TransactionChannel: React.FC<PropsWithChildren<TransactionChannelProps>> = props => {
  const { transactionChannel, children, ...rest } = props
  const renderLogo = (channel: TransactionChannelNameEnum) => {
    switch (channel) {
      case TransactionChannelNameEnum.Bambora:
        return <Bambora />
      case TransactionChannelNameEnum.Paynetics:
        return <Paynetics />
      default:
        return (
          <div className={styles.doubleLogoContainer}>
            <div>
              <DnaVisa />
            </div>
            <div>
              <MC />
            </div>
            <div>
              <DNALogo />
            </div>
          </div>
        )
    }
  }

  return (
    <>
      <Card className={styles.transactionCard}>
        <>
          <Row className={styles.logoWrapper}>
            {renderLogo(transactionChannel.channelName as TransactionChannelNameEnum)}
          </Row>

          <Row justify={'space-between'} className={styles.tumblerWrapper}>
            <Col md={12}>
              <Typography.Title level={5}>{translations().traffic}</Typography.Title>
            </Col>

            <Col md={12}>
              <SwitchTumbler
                isEnabled={!transactionChannel.isDisabled}
                toggleText={transactionChannel.channelName}
                switchType={'channel'}
                isDisabled={false}
                {...rest}
              />
            </Col>
          </Row>
        </>

        {children}

        <Divider />

        <Space size={'small'} direction={'vertical'}>
          <Row>
            <Col xs={2}>
              <HistoryOutlined />
            </Col>
            <Col xs={22}>{translations().lastChangedDate}</Col>
            <Col offset={2}>{moment(transactionChannel.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</Col>
          </Row>
          <Row>
            <Col xs={2}>
              <CommentOutlined />
            </Col>
            <Col xs={22}>{translations().userComment}</Col>
            <Col className={styles.userComment} offset={2}>
              <Tooltip placement={'topLeft'} title={transactionChannel.comment}>
                {transactionChannel.comment}
              </Tooltip>
            </Col>
          </Row>
        </Space>
      </Card>
    </>
  )
}
