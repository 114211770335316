import { Card, Select, Divider, Form, Space } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'

import { isPartner } from '~/code/services/auth'
import { DesktopRangePicker, Input } from '~/code/components'
import translations from './translations'
import { SavedApplicationsFiltersProps } from './props'
import styles from './SavedApplicationsFilters.scss'

export const SavedApplicationsFilters = observer((props: SavedApplicationsFiltersProps) => {
    const { store } = props

    const emailInput = (
        <Form.Item
            className={styles.emailInput}
            label={translations().emailLabel}
        >
            <Input
                value={store.email}
                onChange={e => store.setEmail(e.target.value.trim())}
                onBlur={() => store.loadData()}
            />
        </Form.Item>
    )

    return <Card>
        <div className={styles.rangePickerContainer}>
            {isPartner() && emailInput}
            <DesktopRangePicker store={store.dateStore} />
        </div>
        { !isPartner() && (<>
            <Divider />
            <Form layout={'inline'}>
                <Space>
                    <Form.Item
                        label={translations().acquisitionChannelLabel}
                    >
                        <Select
                            className={styles.select}
                            value={store.acquisitionChannel}
                            onChange={store.setAcquisitionChannel}
                            loading={store.isAcquisitionChannelsLoading}
                        >
                            {store.acquisitionChannels?.map(a => (
                                <Select.Option key={a.name} value={a.name}>
                                    {a.description}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {emailInput}
                </Space>
            </Form>
        </>) }

    </Card>
})
