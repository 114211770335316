import { loginTableColumns, merchantTableColumns } from '../constants'
import { PortalAccessTabKeyType } from '../types/PortalAccessTabType'
import { convertTableItemToFormModel } from '~/code/stores/PortalAccessStore/services/processors'
import { PortalAccessMenuType } from '~/code/pages/PortalAccess/types/PortalAccessMenuType'
import { PortalAccessStoreType } from '~/code/pages/PortalAccess/types/PortalAccessStoreType'

import translations from '../translations'

const columns: Record<PortalAccessTabKeyType, any> = {
  [PortalAccessTabKeyType.merchants]: loginTableColumns,
  [PortalAccessTabKeyType.logins]: merchantTableColumns
}

export const getColumns = (tab: PortalAccessTabKeyType, renderActions, onSelect) => [
  ...columns[tab],
  ...[
    {
      title: translations().actions,
      key: 'actions',
      width: 90,
      fixed: 'right',
      render: (_, item) => renderActions(tab, key => onSelect(key, item))
    }
  ]
]

export const onSelectMenu = async (key: PortalAccessMenuType, item: any, store: PortalAccessStoreType) => {
  const { setSelectedTableItem, setIsUserEditDrawerOpen, setIsSecurityDrawerOpen } = store

  try {
    switch (key) {
      case 'edit':
        await handleEdit(item, store)
        break
      case 'edit-user':
        handleEditUser(item, setSelectedTableItem, setIsUserEditDrawerOpen)
        break
      case 'edit-security':
        handleEditSecurity(item, setSelectedTableItem, setIsSecurityDrawerOpen)
        break
      case 'delete':
        await handleDelete(item, store)
        break
      default:
        console.warn(`Unhandled menu key: ${key}`)
    }
  } catch (error) {
    console.error(error)
  }
}

const handleEdit = async (item, store: PortalAccessStoreType) => {
  const { setSelectedTableItem, setIsUserAccessDrawerOpen, isMerchantsTabActive, stores } = store
  const shops = item?.shops

  if (isMerchantsTabActive) {
    setSelectedTableItem(convertTableItemToFormModel({ ...item, shops: shops || stores?.map(store => store.id) }))
    setIsUserAccessDrawerOpen(true)
  } else {
    await store.loadStoresByMerchant(item.merchantId, loadedStores => {
      setSelectedTableItem(
        convertTableItemToFormModel({ ...item, shops: shops || loadedStores?.map(store => store.id) })
      )
      setIsUserAccessDrawerOpen(true)
    })
  }
}

const handleEditUser = (item, setSelectedTableItem, setIsUserEditDrawerOpen) => {
  setSelectedTableItem(convertTableItemToFormModel(item))
  setIsUserEditDrawerOpen(true)
}

const handleEditSecurity = (item, setSelectedTableItem, setIsSecurityDrawerOpen) => {
  setSelectedTableItem(convertTableItemToFormModel(item))
  setIsSecurityDrawerOpen(true)
}

const handleDelete = async (item, store: PortalAccessStoreType) => {
  await store.deleteLogin(item.publicId, item.merchantId)
}
