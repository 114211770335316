import React from 'react'
import {EntireSystemBlockProps} from './props'
import styles from './styles.scss'

export const EntireSystemBlockMobile: React.FC<EntireSystemBlockProps> = ({notificationSetupForm}) => {
  const {title, description} = notificationSetupForm

  return (
    <div className={styles.previewWrapper}>
      <div className={styles.previewImg}>
        <div className={styles.notificationWrapper}>
          <div><span className={styles.title}>{title}</span></div>
          <div className={styles.description}>{description}</div>
        </div>
      </div>
    </div>
  )
}