import React from 'react'
import { DnaLogo } from '~/assets/icons'
import styles from './DNALoader.scss'

export const DNALoader: React.FC = () => {
  return (
    <div className={styles.loader}>
      <DnaLogo />
    </div>
  )
}
