import { Card, Table, Button, Tooltip, Space } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import moment from 'moment'
import page from 'page'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'

import { isPartner } from '~/code/services/auth'
import { ReloadButton } from '~/code/components'
import { PaginationContainer, DefaultPagination } from '~/code/components/table'
import { StartedApplication } from '../../models'
import translations from '../../translations'
import { SavedApplicationsStore } from '../../SavedApplicationsStore'
import { DeleteModal } from './components'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'

export const SavedApplicationsTable = observer((props: { store: SavedApplicationsStore }) => {
    const { store } = props
    const { tableStore } = store

    const closeModal = () => {
        store.setSelectedApplication(null)
    }

    const deleteApplication = async (deleteReason: string) => {
        await store.deleteApplication(deleteReason, store.selectedApplication.id)
        closeModal()
        store.loadData()
    }

    const columns: (ColumnType<StartedApplication> & { hiddenForPartner?: boolean })[] = [
        {
            title: translations().acquisitionChannelLabel,
            dataIndex: 'acquisitionChannel',
            key: 'acquisitionChannel',
            hiddenForPartner: true,
            render: value => store.getAcquisitionChannelDescription(value)
        },
        {
            title: translations().initiatorEmail,
            dataIndex: 'initiatorEmail',
            key: 'initiatorEmail'
        },
        {
            title: translations().companyName,
            dataIndex: 'companyName',
            key: 'companyName'
        },
        {
            title: translations().applicantName,
            dataIndex: 'applicantName',
            key: 'applicantName'
        },
        {
            title: translations().email,
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: translations().phoneNumber,
            dataIndex: 'phoneNumber',
            key: 'phoneNumber'
        },
        {
            title: translations().startDate,
            dataIndex: 'startDate',
            key: 'startDate',
            render: (value) => value ? moment(value).format('DD.MM.YYYY HH:mm:ss') : ''
        },
        {
            title: translations().action,
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (value, record) => <Space>
                <Tooltip title={translations().continue}>
                    <Button
                        type={'text'}
                        icon={<EditOutlined />}
                        onClick={() => {
                            goToRoute(Routes.APPLICATIONS_START_PROCESS, {id: record.id})
                        }}
                    />
                </Tooltip>
                <Tooltip title={translations().delete}>
                    <Button
                        type={'text'}
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            store.setSelectedApplication(record)
                        }}
                    />
                </Tooltip>
            </Space>
        }
    ]

    const filteredColumns = columns.filter((c) => !c.hiddenForPartner || !isPartner())    

    return <Card>
        <PaginationContainer position='top'>
            <ReloadButton onClick={() => tableStore.loadData()} />
            <DefaultPagination store={tableStore} />
        </PaginationContainer>
        <Table
            size='small'
            rowKey='id'
            columns={filteredColumns}
            dataSource={tableStore.data ? tableStore.data.map(d => ({ key: d.id, ...d })) : null}
            scroll={{ x: 900 }}
            loading={tableStore.isLoading}
            className={classNames('dashboard-table', 'tableWithBottomPadding')}
            pagination={false}
        />
        <PaginationContainer position='bottom'>
            <DefaultPagination store={tableStore} />
        </PaginationContainer>
        <DeleteModal
            isVisible={Boolean(store.selectedApplication)}
            handleDelete={deleteApplication}
            handleCancel={closeModal}
            title={`${store.selectedApplication?.applicantName} (${store.selectedApplication?.email})`}
        />
    </Card>
})
