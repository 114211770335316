import translations from './translations'

translations.add('en', {
    errLoadingAcquisitionChannels: 'Error loading acquisition channels',
    errLoadingAccountNumbers: 'Error loading account numbers',
    selectMerchant: 'Select merchant',
    selectMerchantOrChannelFirst: 'Please select an acquisition channel or a merchant first',
    selectDatesFirst: 'Please select dates first',
    selectMerchantFirst: 'Please select a merchant first',
    noData: 'No Data',
    all: 'ALL',
    errorUploadingDocument: 'Error uploading document',
    errorDeletingDocument: 'Error deleting document',
    requestFail: 'Request has not been submitted!',
    requestSuccess: 'Request has been submitted!'
})
