import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  partnerSuccessfulAdded: string
  partnerUpdateFailure: string
  partnerSuccessfulUpdated: string
  securitySettingsSuccessfulUpdated: string
  securitySettingsUpdateFailure: string
  partnerAddFailure: string
  loginSuccessfulAdded: string
  loginSuccessfulUpdated: string
  loginAddFailure: string
  loginUpdateFailure: string
  dna: string
  dna_eu: string
  paynetics_eu: string
}>()
