import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  review: string
  processName: string
  assignedUser: string
  assignedGroup: string
  reviewTime: string
  lessThanOneMinute: string
  selectMerchant: string
  selectStatus: string
  startDate: string
  merchant: string
  midsAndTids: string
  initiator: string
  status: string
  endDate: string
  contractNumber: string
  tids: string
}>()
