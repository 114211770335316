import React, { useState } from 'react'
import { Button, Card, Row, Col } from 'antd'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { EditOutlined } from '@ant-design/icons'
import { IContractStore } from './IContractStore'
import { ContractProps } from './props'
import { RowElement } from '../RowElement'
import translations from './translations'
import styles from './Contract.scss'

export const ContractStoreSymbol = Symbol('ContractStore')
export const Contract = observer((props: ContractProps) => {
  const { contract, mid, midText } = props
  const store = useInjection<IContractStore>(ContractStoreSymbol)
  const [isEditTariffsModalLoading, setIsEditTariffsModalLoading] = useState(false)

  const getHoldInfoElement = (contractNumber: string) => {
    const holdInfo = store.isHoldEnabled(contractNumber)
    if (!holdInfo.enabled) {
      return <div className={styles.redText}>{translations().settlemetTeam(holdInfo.team)}</div>
    }
    return null
  }

  const renderEditTariffsButton = () => {
    return (
      <div>
        <Button
          disabled={store.isSubsidiary}
          icon={<EditOutlined />}
          loading={isEditTariffsModalLoading}
          onClick={async () => {
            setIsEditTariffsModalLoading(true)
            await store.getEditTariffsInformation(contract, midText)
            setIsEditTariffsModalLoading(false)
          }}
        >
          {translations().editTariffs}
        </Button>
      </div>
    )
  }

  const payneticsMid = store.getPayneticsMid(mid)
  const tipJarVenueId = store.defineTipJarVenueByMid(mid)

  return (
    <>
      <Card>
        <>
          <Row justify={'space-between'}>
            <Col xs={24} sm={16}>
              <RowElement label={translations().contractNumber} value={contract.contractNumber} />
              <RowElement
                label={translations().mid}
                value={
                  <>
                    <RowElement label={translations().dnaMid} value={midText} />
                    {payneticsMid ? <RowElement label={translations().payneticsMid} value={payneticsMid} /> : ''}
                  </>
                }
              />
              {getHoldInfoElement(contract.contractNumber)}
              {!contract.isActive && (
                <div className={styles.redText}>
                  <RowElement label={translations().constractStatus} value={translations().closedContract} />
                </div>
              )}
              {tipJarVenueId && <RowElement label={translations().tipJar} value={tipJarVenueId} />}
            </Col>
            <Col xs={24} sm={8}>
              <div className={styles.groupContainer}>{renderEditTariffsButton()}</div>
            </Col>
          </Row>
        </>
      </Card>
    </>
  )
})
