import React from 'react'
import translations from './translations'

translations.add('en', {
  sign: 'Send for signature',
  docuSign: 'Acquiring contract',
  docuSignInfo: (
    <>
      <p>
        Note, Merchant will receive a contract via DocuSign.
        <br /> Once signed, the status will change automatically.
      </p>
      <p>
        <i>The contract reflects the data from the onboarding form and cannot be edited separately.</i>
      </p>
      <p>
        <i>If it requires amendments, edit the data of the application form.</i>
      </p>
      <p>
        <i>
          If contract is already sent and requires amendments, withdraw it first, update the form data and resend the
          new contract.
        </i>
      </p>
    </>
  ),
  signError: 'Something went wrong on backend side',
  preview: 'Generate the contract',
  alreadySentToSign: 'Current status: Send, pending signature',
  update: 'Update to the latest details',
  updateError: 'Something went wrong while update',
  signContract: 'I agree to form and send MSA automatically',
  docuSignInfo2: (
    <>
      <p>Is it required to generate an MSA? </p>
      <p>
        If YES then the document with MSA is automatically generated in PDF format based on the completed data from
        application and then sent to the merchant in DocuSign to sign
      </p>
      <p>After MSA document is signed by merchant, it will be saved in Salesforce in the appropriate Opportunity.</p>
    </>
  ),
  signedSuccessfully: 'Your document successfully signed',
  currentStatus: (status: string) => {
    if (status === 'wait') {
      return 'Current status: Sent, pending signature'
    } else if (status === 'success') {
      return 'Current status: Signed'
    } else {
      return 'Current status: Ready for sending'
    }
  },
  signatoryEmail: 'Signatory email:',
  review: 'Review',
  detailsInfo: (firstName: string, lastName: string, dob: string, address: string) => (
    <>
      <p>{`First name: ${firstName}`}</p>
      <p>{`Last name: ${lastName}`}</p>
      <p>{`Date of birth: ${dob}`}</p>
      <p>{`Residental address: ${address}`}</p>
    </>
  ),
  detailsAreCorrect: 'Details are correct',
  backToForm: 'Back to form',
  doubleCheck: 'Please double-check these details',
  refreshStatus: 'Refresh status',
  needToChangeEmail: 'Changing the email of a Signee',
  alreadySent: 'Amending the contract that is already sent',
  alreadySentCase: 'In case you need to update the contract that is already sent:',
  amendData: 'Amend the required data using the Application`s sections',
  clickTheButton: '‘Send for signature’. After that the new updated MSA will be sent to the Merchant',
  detailsWillBeUpd: 'Contract details will be updated to the latest within the same DocuSign link.',
  wontBeRequire: 'This won`t require additional DocuSign link sending.',
  signeeNotFound: 'The contract signee is not selected. Please assign the individual as Signee first',
  note: 'Please note, in some cases, it takes 10-15 minutes for the email with the link to reach the recipient inbox.',
  cancellation: 'Cancellation',
  cancellationText: (
    <>
      <p>MSA is already sent, and we are waiting for the merchant to sign it.</p>
      <p>Pressing the "Cancel" button will revoke the DocuSign MSA that was already sent.</p>
    </>
  ),
  note2:
    'You can now inform your merchant that they need to open the email and follow instructions to sign the DocuSign MSA',
  updateLogic: 'DocuSign status update logic (?)',
  docusignStatusCheck: (
    <>
      <p>
        <b>DocuSign status check frequency</b>
      </p>
      <p>Once the DocuSign email is sent system starts the Status monitoring process following this schedule: </p>
      <ul>
        <li>first 30 minutes after sending: every minute</li>
        <li>31 minutes until the next day: every 15 minutes</li>
        <li>From the next day: every hour</li>
      </ul>
      <p>For example: </p>
      <p>If the DocuSign is signed within 30 minutes from when it's sent - the status will be updated in 1 minute.</p>
    </>
  ),
  attention: 'Attention!',
  back: 'Back',
  revoke: 'Revoke the MSA',
  save: 'Save',
  willUploadManually: 'I will upload MSA manually',
  resend: 'Resend',
  resendText: 'If a client does not receive the email, please click the "Resend" button.',
  changeEmailText:
    'If you want to change Signee`s email, proceed to the “Individuals details” section and amend Signee`s email accordingly.',
  clickCancellation: 'Click the "Cancellation" button. Contract will be revoked and withdrawn',
  clickGenerate: 'Click "Generate the contract" (check the generated pdf)'
})
