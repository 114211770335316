import translations from './translations'
import { PaymentSolutionView } from '../../../models'

translations.add('en', {
  title: 'Add products to the store',
  required: 'Field is required',
  posModel: 'POS Model',
  posModelRequired: 'pos model is required',
  posCountRequired: 'pos count is required',
  paymentSolutions: {
    [PaymentSolutionView.WEBSITE_PAYMENTS]: {
      title: 'Website Payments',
      comment: 'Pay by Link included'
    },
    [PaymentSolutionView.PAY_BY_LINK]: {
      title: 'Pay by Link only',
      comment: 'No website needed'
    },
    [PaymentSolutionView.POS]: {
      title: 'POS',
      comment: 'In store POS terminals'
    },
    [PaymentSolutionView.WEBSITE_PAYMENTS_MOTO]: {
      title: 'Website Payments + VT',
      comment: 'Pay by Link included'
    },
    [PaymentSolutionView.PAY_BY_LINK_MOTO]: {
      title: 'Pay by Link only + VT',
      comment: 'No website needed'
    },
    [PaymentSolutionView.POS_MOTO]: {
      title: 'POS with MOTO enabled',
      comment: 'In store POS terminals'
    }
  },
  ecom: 'ECOM',
  pos: 'POS',
  webSiteRequired: 'Store WebSite required',
  posDeliveryInstructionsRequired: 'Pos Delivery Instructions Required',
  posDeliveryAddressRequired: 'Pos Delivery Address Required',
  productPlaceholder: 'Select product',
  deviceAmountPlaceholder: 'Enter amount of devices',
  posQuantity: 'Pos quantity',
  posDeliveryAddress: 'Pos Delivery Address',
  posDeliveryAddressPlaceholder: 'Enter Pos Delivery Address',
  manual: 'Manual',
  sameAsCompany: 'Same As Company Address',
  sameAsStore: 'Same As Store Address',
  deliveryInstructions: 'Delivery Instructions',
  deliveryInstructionsPlaceholder: 'Enter pos delivery instructions',
  deliveryContactNumber: 'Contact number for delivery',
  validateBankAccount: 'Please validate bank account',
  addProduct: 'Add this product for the store',
  posBundles: 'Bundles',
  cityLabel: 'Town/City',
  cityRequired: 'town/city is required',
  cityInvalid: 'only alphanumeric characters are allowed',
  countryLabel: 'Country',
  countryRequired: 'country is required',
  regionLabel: 'County',
  postalCodeLabel: 'Postcode',
  postalCodeRequired: 'postcode is required',
  postalCodeInvalid: 'invalid postcode',
  addressLine1Label: 'Address line 1',
  addressLine1Required: 'address line 1 is required',
  addressLine2Label: 'Address line 2 ',
  addressLine2Required: 'address line 2 is required',
  storeWebSite: 'Store website',

  subscriptionServicePeriod: 'Subscription Service Period (in months)',
  freeSubscriptionPeriod: 'Free Subscription Period (in months)',
  contractType: 'Contract type',
  byDNA: 'Chargeable by DNA',
  byPaytek: 'Chargeable by Paytek',
  posCountError: 'Pos terminals quantity must be in 1 - 100 range',
  zashEpos: 'Zash ePOS',
  zashDigital: 'Zash Digital Ordering',
  byOptomany: 'Chargeable by Optomany',
  confirm: 'Confirm',
  byOptomanyDetails:
    'By selecting Chargeable by Optomany, you`re taking full responsibility for checking billing procedures are in place on the Optomany side. In case billing on the Optomany side is not set and "Chargeable by Optomany" is selected, the merchant won`t be billed at all. ',
    attention: 'Attention'
})
