import React, { useEffect, useRef, useState } from 'react'
import { Card, Col, Radio, Row, Tabs } from 'antd'
import classNames from 'classnames'

import { TabType } from '~/code/models'
import { StatusType } from '~/code/stores/AnalyticsStore/models/StatusType'
import { TabKey } from './models'
import { SumCountCardProps } from './props'
import translations from './translations'
import styles from './SumCountCard.scss'

export const SumCountCard = ({
  loading,
  loadingSum,
  loadingCount,
  title,
  renderSumHeader,
  renderCountHeader,
  renderSumTitle,
  renderSumContent,
  renderCountTitle,
  renderCountContent,
  currencySymbol,
  className,
  onSumStatusChange,
  onCountStatusChange,
  headerExtraContent,
  onTabChange,
  statusTabs
}: SumCountCardProps) => {
  const [sumStatus, setSumStatus] = useState<StatusType>(statusTabs?.[0]?.value)
  const [countStatus, setCountStatus] = useState<StatusType>(statusTabs?.[0]?.value)
  const [_loadingSum, setLoadingSum] = useState(loadingSum)
  const [_loadingCount, setLoadingCount] = useState(loadingCount)

  // the height state is used to set the min height value, it prevents the view from jumping when changing status
  const [height, setHeight] = useState(0)
  const heightRef = useRef(null)

  useEffect(() => {
    setLoadingSum(loadingSum)
    setTimeout(() => {
      setHeight(heightRef.current?.clientHeight)
    }, 1)
  }, [loadingSum])

  useEffect(() => {
    setLoadingCount(loadingCount)
    setTimeout(() => {
      setHeight(heightRef.current?.clientHeight)
    }, 1)
  }, [loadingCount])

  const handleSumStatusChange = e => {
    setLoadingSum(true)
    const status = e.target.value
    setSumStatus(status)
    onSumStatusChange && onSumStatusChange(status)
    setTimeout(() => setLoadingSum(false), 1)
  }

  const handleCountStatusChange = e => {
    setLoadingCount(true)
    const status = e.target.value
    setCountStatus(status)
    onCountStatusChange && onCountStatusChange(status)
    setTimeout(() => setLoadingCount(false), 1)
  }

  const defaultRenderSumSubTitle = status => {
    return translations().sumTitle(status, currencySymbol)
  }

  const defaultRenderCountSubTitle = status => {
    return translations().countTitle(status, currencySymbol)
  }

  const handleTabChange = (tabKey: TabKey) => {
    onTabChange(tabKey)
  }

  const tabContent = (
    _loading: boolean,
    tabTile: string,
    tabKey: TabKey,
    renderHeader,
    renderSubTitle: (status) => string | JSX.Element,
    status: string,
    handleStatusChange: (e) => void,
    renderContent: (status?) => JSX.Element
  ): TabType => {
    return {
      label: tabTile,
      key: tabKey,
      children: (
        <Card bordered={false} styles={{ body: { padding: 0 } }} loading={_loading}>
          <div className={styles.header}>
            {renderHeader ? (
              renderHeader(status, handleStatusChange)
            ) : (
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <div className={styles.subTitle}>{renderSubTitle(statusTabs ? status : null)}</div>
                </Col>
                {statusTabs && (
                  <Col xs={24} sm={12}>
                    <div className={styles.statusWrapper}>
                      <Radio.Group value={status} onChange={handleStatusChange}>
                        {statusTabs.map(tab => (
                          <Radio.Button key={tab.value} value={tab.value}>
                            {tab.label}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </div>
                  </Col>
                )}
              </Row>
            )}
          </div>
          <div className={styles.content}>{!_loading && renderContent(status)}</div>
        </Card>
      )
    }
  }

  return (
    <Card bordered={false} className={classNames(styles.SumCountCard, { [className]: !loading })}>
      <div className={styles.cardBody} ref={heightRef} style={{ minHeight: `${height}px` }}>
        <Tabs
          size='large'
          tabBarStyle={{ marginBottom: 0 }}
          tabBarExtraContent={
            <>
              <div className={styles.title}>{title}</div>
              {headerExtraContent && headerExtraContent}
            </>
          }
          className={styles.tabs}
          onChange={onTabChange ? handleTabChange : null}
          items={[
            tabContent(
              _loadingSum,
              translations().sum,
              'amount',
              renderSumHeader,
              renderSumTitle || (status => defaultRenderSumSubTitle(status)),
              sumStatus,
              handleSumStatusChange,
              renderSumContent
            ),
            tabContent(
              _loadingCount,
              translations().count,
              'count',
              renderCountHeader,
              renderCountTitle || (status => defaultRenderCountSubTitle(status)),
              countStatus,
              handleCountStatusChange,
              renderCountContent
            )
          ]}
        />
      </div>
    </Card>
  )
}
