import React from 'react'

const CompleteGreen = props => (
  <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.3568 8.18848H16.4206C16.2894 8.18848 16.1648 8.24874 16.0845 8.35187L10.5626 15.347L7.91484 11.992C7.87478 11.9412 7.82373 11.9001 7.76551 11.8718C7.70729 11.8435 7.64341 11.8287 7.57868 11.8287H6.64252C6.55278 11.8287 6.50323 11.9318 6.55814 12.0014L10.2264 16.6487C10.3979 16.8657 10.7273 16.8657 10.9001 16.6487L17.4412 8.35991C17.4961 8.2916 17.4465 8.18848 17.3568 8.18848Z'
      fill='#038C4C'
    />
    <path
      d='M12 24C5.64873 24 0.5 18.8513 0.5 12.5C0.5 6.14873 5.64873 1 12 1C18.3513 1 23.5 6.14873 23.5 12.5C23.5 18.8513 18.3513 24 12 24Z'
      stroke='#038C4C'
    />
  </svg>
)

export default CompleteGreen
