import { MerchantProductType } from '~/code/models'
import { StatusItem } from '../../AnalyticsDetailedView/models/StatusItem'
import translations from '../translations'

export const getStatuses = (merchantProductType: MerchantProductType): StatusItem[] => {
    let statuses: StatusItem[] = []

    switch (merchantProductType) {
        case 'ecom':
            statuses = [
                { value: 'all', label: translations().all },
                { value: 'successful', label: translations().successful },
                { value: 'failed', label: translations().failed },
                { value: 'other', label: translations().other }
            ]
            break
        case 'pos':
            statuses = [
                { value: 'all', label: translations().all },
                { value: 'success', label: translations().success },
                { value: 'failed', label: translations().failed }
            ]
            break
    }

    return statuses
}