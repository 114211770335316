import React from 'react'
import { Form, Input } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { TipJarTableColumnsType } from '../models'
import translations from '../translations'
import styles from '../TipJarModal.scss'

export const TipJarTableColumns: ColumnsType<TipJarTableColumnsType> = [
  {
    children: [
      {
        key: 'storeName',
        dataIndex: 'storeName',
        title: translations().storeName,
        render: (v) => <span>{v}</span>
      },
      {
        key: 'location',
        dataIndex: 'location',
        title: translations().location
      },
      {
        key: 'mid',
        dataIndex: 'mid',
        title: translations().mid,
        render: (v, row) => {
          return (
            <Form.Item className={styles.formItem}
                name={`mid_${row.storeIdx}${row.contractIdx}`}
                initialValue={v}
            >
                <Input disabled></Input>
            </Form.Item>
          )
        }

      },
      {
        key: 'venueId',
        dataIndex: 'venueId',
        title: translations().venueId,
        render: (v, row) => {
          return (
            <Form.Item 
                className={styles.formItem}
                name={`venueId_${row.storeIdx}${row.contractIdx}`}
                initialValue={v}
            >
                <Input disabled={!!v}/>
            </Form.Item>
          )
        }
      }
    ]
  }
]
