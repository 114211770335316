import { Descriptions, Divider } from 'antd'
import React from 'react'
import { AdvancedPayments } from '../AdvancedPayments'
import { CompanyProfilePropsType } from './props'
import translations from './translations'
import styles from './CompanyProfile.scss'
import { ProcessingMethods } from '../ProcessingMethods'
import { FinancialInfoType } from '../../types'
import { FinancialInfo } from '../FinancialInfo/FinancialInfo'
import { BusinessModelQuestions } from '../BusinessModelQuestions'
import { isEmpty } from 'dna-common'

export const CompanyProfile = ({ data, businessStructure }: CompanyProfilePropsType) => {
  return (
    <>
      <Descriptions bordered column={1}>
        <Descriptions.Item label={<b>{translations().companyType}</b>}>
          {data.companyType || businessStructure}
        </Descriptions.Item>
        <Descriptions.Item label={<b>{translations().companyName}</b>}>{data.companyName}</Descriptions.Item>
        {data.companyTradeName && (
          <Descriptions.Item label={<b>{translations().companyTradeName}</b>}>
            {data.companyTradeName}
          </Descriptions.Item>
        )}
        <Descriptions.Item label={<b>{translations().companyNumber}</b>}>{data.companyNumber}</Descriptions.Item>
        <Descriptions.Item label={<b>{translations().dateOfCreation}</b>}>{data.dateOfCreation}</Descriptions.Item>
        <Descriptions.Item label={<b>{translations().numberOfEmployees}</b>}>
          {data.numberOfEmployees}
        </Descriptions.Item>
        <Descriptions.Item label={<b>{translations().companyWebsite}</b>}>{data.companyWebsite}</Descriptions.Item>
        <Descriptions.Item label={<b>{translations().emailAddress}</b>}>{data.emailAddress}</Descriptions.Item>
        <Descriptions.Item label={<b>{translations().telephoneNumber}</b>}>{data.telephoneNumber}</Descriptions.Item>
        <Descriptions.Item label={<b>{translations().bankAccountName}</b>}>{data.bankAccountName}</Descriptions.Item>
        <Descriptions.Item label={<b>{translations().bankAccountNumber}</b>}>
          {data.bankAccountNumber}
        </Descriptions.Item>
        <Descriptions.Item label={<b>{translations().bankAccountType}</b>}>{data.bankAccountType}</Descriptions.Item>
        <Descriptions.Item label={<b>{translations().sortCode}</b>}>{data.sortCode}</Descriptions.Item>
        <Descriptions.Item label={<b>{translations().bankName}</b>}>{data.bankName}</Descriptions.Item>
        <Descriptions.Item label={<b>{translations().directorsPartnersCount}</b>}>
          {data.directorsPartnersCount}
        </Descriptions.Item>
        <Descriptions.Item label={<b>{translations().charityNumber}</b>}>{data.charityNumber}</Descriptions.Item>
        <Descriptions.Item label={<b>{translations().charityComission}</b>}>{data.charityComission}</Descriptions.Item>
        <Descriptions.Item label={<b>{translations().merchantCategoryCode}</b>}>
          {data.merchantCategoryCode}
        </Descriptions.Item>
        <Descriptions.Item label={<b>{translations().merchantCategoryCodeDescription}</b>}>
          {data.merchantCategoryCodeDescription}
        </Descriptions.Item>
        <Descriptions.Item
          label={<b>{translations().companyAddress}</b>}
        >{`${data?.companyAddress?.postalCode} ${data?.companyAddress?.addressLine1} ${data?.companyAddress?.townOrCity}`}</Descriptions.Item>
      </Descriptions>

      {!isEmpty(data.advancePayments) && (
        <>
          <Divider />
          <div className={styles.sectionHeader}>
            <b>{translations().advancedPayments}</b>
          </div>
          <AdvancedPayments data={data.advancePayments} />
        </>
      )}

      {!isEmpty(data.processingMethods) && (
        <>
          <Divider />
          <div className={styles.sectionHeader}>
            <b>{translations().processingMethods}</b>
          </div>
          <ProcessingMethods data={data.processingMethods} />
        </>
      )}

      {data.expectedAnnualCardTurnover && (
        <>
          <Divider />
          <FinancialInfo data={data as FinancialInfoType} />
        </>
      )}

      {data.businessModelQuestions && (
        <>
          <Divider />
          <BusinessModelQuestions data={data.businessModelQuestions} />
        </>
      )}
    </>
  )
}
