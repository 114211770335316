import { observer } from 'mobx-react'
import React from 'react'
import { Empty, Spin } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { STORE_TYPES } from '~/code/constants/StoreTypes'
import { SubmittedRequestsStore } from '~/code/stores/SubmittedRequestsStore/SubmittedRequestsStore'
import { RiskLimits } from '~/code/pages/BPMProcesses/Requests/components/RiskLimits'
import { Details } from './components'
import translations from './translations'
import styles from './ProcessDetails.scss'


export const ProcessDetails = observer(() => {
    const store = useInjection<SubmittedRequestsStore>(STORE_TYPES.SubmittedRequestsStore)

    if (store.hasProcessDetailsLoadingError) {
        return (
            <div className={ styles.empty }>
                <Empty />
            </div>
        )

    }

    if (store.isProcessDetailsLoading) {
        return (
            <div className={ styles.loader }>
                <Spin size='large' />
            </div>
        )

    }

    return <>
        <Details processDetails={store.processDetails} />
        <RiskLimits
            riskLimits={store.processDetails.currentLimits}
            title={translations().currentLimits}
            insideDrawer
        />
        <RiskLimits
            riskLimits={store.processDetails.requestedLimits}
            title={translations().requestedLimits}
            insideDrawer
        />
        {store.processDetails.approvedLimits && (
            <RiskLimits
                riskLimits={store.processDetails.approvedLimits}
                title={translations().approvedLimits}
                insideDrawer
            />
        )}
    </>
})
