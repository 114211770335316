export const STORE_TYPES = {
  RouterStore: Symbol('RouterStore'),
  GlobalConfigStore: Symbol('GlobalConfigStore'),
  SettlementStore: Symbol('SettlementStore'),
  HandbooksStore: Symbol('HandbooksStore'),
  RangePickerStore: Symbol('RangePickerStore'),
  DrawerTitleStore: Symbol('DrawerTitleStore'),
  SubmittedRequestsStore: Symbol('SubmittedRequestsStore'),
  ChangeOfSettlementPeriodStore: Symbol('ChangeOfSettlementPeriodStore'),
  SettlementPeriodDrawerTitleStoreSymbol: Symbol('SettlementPeriodDrawerTitleStore')
}
