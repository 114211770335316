import { Badge, Pagination, Popover, Table, Typography } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { observer } from 'mobx-react'
import moment from 'moment'
import React from 'react'
import { GroupableItem } from '~/code/models'
import { getBpmStatusColor, onCell } from '~/code/services/utils'
import { ReloadButton } from '~/code/components'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { Routes } from '~/code/startup/Router/Routes'
import { goToRoute } from '~/code/startup/Router/utils'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'
import { PROCESS_STATUSES_WITH_NO_DETAILS } from '../../../../services/constants'
import { BPMApplicationModel } from '../../../../models'
import translations from './translations'
import { ProcessesTableProps } from './props'
import styles from './ProcessesTable.scss'

const { Link } = Typography

const render = (value: string) => (
  <div className={styles.fixedWidth}>
    <span className='nowrap'>{value?.length > 20 ? <Popover content={value}>{value}</Popover> : value}</span>
  </div>
)

const toTime = (value: number, unit: string) => (value ? `${value} ${unit}${value > 1 ? 's' : ''}` : '')

export const ProcessesTable = observer((props: ProcessesTableProps) => {
  const {
    store,
    actions = null,
    rowSelection = null,
    excludedColumns = [],
    canGoToDetailsPage = false,
    additionalStore = false
  } = props
  const { tableStore } = store

  const hasPermission = hasPermissions([PermissionMap.onboarding.processes.details.read])

  const hasProcessADetailsPage = (processStatus: string): boolean => {
    if (hasPermission && canGoToDetailsPage && !PROCESS_STATUSES_WITH_NO_DETAILS.includes(processStatus)) return true
    return false
  }

  const onCellClick = (record: GroupableItem<BPMApplicationModel>) => ({
    onClick: () => {
      if (hasProcessADetailsPage(record.processStatus)) {
        goToRoute(Routes.BPM_REPORTS_ONBOARDING_PROCESS, {
          id: record.id,
          type: additionalStore ? 'additional' : 'onboarding'
        })
      }
    },
    ...onCell
  })

  const restColumns: ColumnsType<GroupableItem<BPMApplicationModel>> = [
    {
      title: <span className='nowrap'>{translations().merchant}</span>,
      dataIndex: 'companyName',
      key: 'companyName',
      onCell: onCellClick,
      render
    },
    {
      title: <span className='nowrap'>{translations().productType}</span>,
      dataIndex: 'productType',
      key: 'productType',
      width: 20,
      onCell: onCellClick,
      render
    },
    {
      title: <span className='nowrap'>{translations().approvalType}</span>,
      dataIndex: 'approvalType',
      key: 'approvalType',
      width: 20,
      onCell: onCellClick,
      render
    },
    {
      title: <span className='nowrap'>{translations().applicantName}</span>,
      dataIndex: 'applicantName',
      key: 'applicantName',
      onCell: onCellClick,
      render
    },
    {
      title: translations().email,
      dataIndex: 'email',
      key: 'email',
      width: 20,
      onCell: onCellClick,
      render
    },
    {
      title: translations().initiatorEmail,
      dataIndex: 'initiatorEmail',
      key: 'initiatorEmail',
      width: 20,
      onCell: onCellClick,
      render
    },
    {
      title: translations().opportunityOwner,
      dataIndex: 'opportunityOwner',
      key: 'opportunityOwner',
      width: 20,
      onCell: onCellClick,
      render
    },
    {
      title: translations().segmentCategory,
      dataIndex: 'segmentCategory',
      key: 'segmentCategory',
      width: 20,
      onCell: onCellClick,
      render
    },
    {
      title: translations().segmentSubCategory,
      dataIndex: 'segmentSubCategory',
      key: 'segmentSubCategory',
      width: 20,
      onCell: onCellClick,
      render
    },
    {
      title: translations().acquisitionChannel,
      dataIndex: 'acquisitionChannel',
      key: 'acquisitionChannel',
      width: 20,
      onCell: onCellClick,
      render
    },
    {
      title: translations().currentStatus,
      dataIndex: 'processStatus',
      key: 'processStatus',
      width: 20,
      onCell: onCellClick,
      render: (processStatus: string, row) => (
        <Badge status={getBpmStatusColor(row.status)} text={processStatus} className='nowrap' />
      )
    },
    {
      title: translations().assignedGroup,
      dataIndex: 'assignedGroup',
      key: 'assignedGroup',
      width: 20,
      onCell: onCellClick,
      render
    },
    {
      title: translations().assignedUser,
      dataIndex: 'assignedUser',
      key: 'assignedUser',
      width: 20,
      onCell: onCellClick,
      render
    },
    {
      title: translations().timeAtCurrentStatus,
      dataIndex: 'reviewTime',
      key: 'reviewTime',
      width: 20,
      onCell: onCellClick,
      render: (reviewMinutes: number, row) => {
        if (!reviewMinutes) return ''
        const obj = moment.duration(reviewMinutes, 'minutes')
        const days = Math.floor(obj.asDays())
        const hours = obj.hours()
        const minutes = obj.minutes()

        const result = [toTime(days, 'day'), toTime(hours, 'hour'), toTime(minutes, 'minute')]
          .filter(v => Boolean(v))
          .map((v, i) => (
            <span key={i} className='nowrap'>
              {v}&nbsp;
            </span>
          ))

        return <div className='nowrap'>{result}</div>
      }
    },
    {
      title: translations().mid,
      dataIndex: 'mid',
      key: 'mid',
      width: 20,
      onCell: onCellClick,
      render
    },
    {
      title: translations().terminalIssueType,
      dataIndex: 'terminalIssueTime',
      key: 'terminalIssueTime',
      width: 20,
      onCell: onCellClick,
      render
    },
    {
      title: <span className='nowrap'>{translations().endDate}</span>,
      dataIndex: 'endDate',
      key: 'endDate',
      onCell: onCellClick,
      width: 150,
      render: (date: string) => (
        <div className={styles.fixedWidth}>{date ? moment(date).format('DD.MM.YYYY HH:mm:ss') : null}</div>
      )
    },
    {
      title: translations().opportunityId,
      dataIndex: 'opportunityId',
      key: 'opportunityId',
      width: 30,
      onCell,
      render: (opportunityId: string, row) => (
        <div className={styles.fixedWidth}>
          <Link target='_blank' href={`${__LIGHTNING_FORCE_URL__}/lightning/r/Opportunity/${opportunityId}/view`}>
            {opportunityId}
          </Link>
        </div>
      )
    },
    {
      title: translations().phoneNumber,
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 20,
      onCell: onCellClick,
      render
    }
  ]

  const allColumns: ColumnsType<GroupableItem<BPMApplicationModel>> = [
    {
      title: <span className='nowrap'>{translations().startDate}</span>,
      dataIndex: 'startDate',
      key: 'startDate',
      width: 100,
      render: (date: string, row) => {
        if (row.groupTitle) {
          return row.groupTitle
        }
        return date ? <span className={styles.grey}>{moment(date).format('HH:mm:ss')}</span> : null
      }
    },
    ...restColumns
  ]

  const columns = !excludedColumns ? allColumns : allColumns.filter(c => excludedColumns.indexOf(c.key as string) < 0)

  const paginationProps: TablePaginationConfig = generateTablePaginationProps(
    tableStore.currentPage,
    tableStore.pageSize,
    tableStore.totalCount,
    pageNumber => {
      tableStore.loadData({ pageNumber })
    },
    tableStore.setPageSize
  )

  return (
    <>
      <div className={styles.tableFunctionalitiesContainer}>
        {actions}
        <div className={styles.paginationContainer}>
          <ReloadButton onClick={() => tableStore.loadData()} className={styles.reloadButton} />
          <Pagination {...paginationProps} />
        </div>
      </div>
      <Table
        size='small'
        bordered={false}
        columns={columns}
        scroll={{ x: true }}
        className='dashboard-table tableWithBottomPadding'
        dataSource={tableStore.data}
        loading={tableStore.isLoading}
        pagination={false}
        rowSelection={rowSelection}
        rowClassName={record => {
          if (record.groupTitle) return styles.rowGroup
          if (hasProcessADetailsPage(record.processStatus)) return styles.tableRowWithPointer
          return ''
        }}
        rowKey={row => {
          return row.rowId || ''
        }}
      />
      <div className={styles.paginationContainer} style={{ marginTop: 15 }}>
        <Pagination {...paginationProps} />
      </div>
    </>
  )
})
