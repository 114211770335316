import React from 'react'
import moment from 'moment'
import { ColumnType } from 'antd/lib/table'
import { GroupableItem, PosTransactionChannelType } from '~/code/models'
import { formatAmount, getPosTransactionChannelText, onCell } from '~/code/services/utils'
import { PosTransactionModel } from '~/code/models/PosTransactionModel'
import translations from './translations'
import styles from './MonitoringPosTransactions.scss'

export type PosTransactionColumnType = ColumnType<GroupableItem<PosTransactionModel>>

export const TRANSACTION_DATE_COLUMN_KEY = 'transactionDate'
export const TRANSACTION_DATE_COLUMN_WIDTH = 140

export const columnsOnlyForFullAccess = [
  'transactionChannel', 'transactionMcc'
]

export const getTransactionDateColumn = (columnCount: number): PosTransactionColumnType => ({
  title: translations().transactionDate,
  dataIndex: 'transactionDate',
  colSpan: 1,
  key: TRANSACTION_DATE_COLUMN_KEY,
  width: TRANSACTION_DATE_COLUMN_WIDTH,
  onCell: (row) => Boolean(row.groupTitle) && ({ colSpan: columnCount, className: styles.rowGroup }),
  render: (date: string, row) => {
    if (row.groupTitle) {
      return row.groupTitle
    }

    return date ? <span className={styles.grey}>{moment(date).format('HH:mm:ss')}</span> : null
  }
})

export const columns: (PosTransactionColumnType[]) = [
  {
    title: translations().merchant,
    dataIndex: 'merchantName',
    key: 'merchantName',
    width: 216,
    onCell
  },
  {
    title: translations().amount,
    dataIndex: 'amount',
    key: 'amount',
    width: 100,
    align: 'right',
    onCell,
    render: (amount: number) => formatAmount(amount)
  },
  {
    title: translations().currency,
    dataIndex: 'currency',
    key: 'currency',
    width: 80,
    onCell
  },
  {
    title: translations().status,
    dataIndex: 'status',
    key: 'status',
    width: 80,
    onCell
  },
  {
    title: translations().returnCode,
    dataIndex: 'returnCode',
    key: 'returnCode',
    width: 115,
    onCell
  },
  {
    title: translations().message,
    dataIndex: 'returnCodeDescription',
    key: 'returnCodeDescription',
    width: 300,
    onCell
  },
  {
    title: translations().transactionId,
    dataIndex: 'transactionId',
    key: 'transactionId',
    width: 145,
    onCell
  },
  {
    title: translations().mid,
    dataIndex: 'mid',
    key: 'mid',
    width: 140,
    onCell
  },
  {
    title: translations().terminalId,
    dataIndex: 'terminalId',
    key: 'terminalId',
    width: 130,
    onCell
  },
  {
    title: translations().operation,
    dataIndex: 'operation',
    key: 'operation',
    width: 120,
    onCell
  },
  {
    title: translations().transactionType,
    dataIndex: 'transactionType',
    key: 'transactionType',
    width: 140,
    onCell,
    render: (value: string) => value ? (value === 'PAYBYLINK' ? 'Pay By Link' : value) : ''
  },
  {
    title: translations().transactionChannel,
    dataIndex: 'transactionChannel',
    key: 'transactionChannel',
    width: 160,
    onCell,
    render: (channel: PosTransactionChannelType) => getPosTransactionChannelText(channel)
  },
  {
    title: translations().transactionDetails,
    dataIndex: 'transactionDetails',
    key: 'transactionDetails',
    width: 216,
    onCell
  },
  {
    title: translations().transactionCountry,
    dataIndex: 'transactionCountry',
    key: 'transactionCountry',
    width: 170,
    onCell
  },
  {
    title: translations().transactionCity,
    dataIndex: 'transactionCity',
    key: 'transactionCity',
    width: 160,
    onCell
  },
  {
    title: translations().cardScheme,
    dataIndex: 'cardScheme',
    key: 'cardScheme',
    width: 120,
    onCell,
    render: (value) => translations()[value] || value
  },
  {
    title: translations().cardMask,
    dataIndex: 'cardMask',
    key: 'cardMask',
    width: 120,
    onCell
  },
  {
    title: translations().captureMethod,
    dataIndex: 'captureMethod',
    key: 'captureMethod',
    width: 130,
    onCell,
    render: (value) => translations()[value] || value
  },
  {
    title: translations().transactionMcc,
    dataIndex: 'transactionMcc',
    key: 'transactionMcc',
    width: 170,
    onCell
  }
]
