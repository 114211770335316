import translations from './translations'
import { FileType } from '~/code/models/FileType'

translations.add('en', {
    download: 'Download',
    selectMerchantOrAcquisitionChannel: 'Please select a merchant or an acquisition channel first',
    fileTypes: {
        [FileType.PDF]: 'PDF',
        [FileType.CSV]: 'CSV',
        [FileType.EXCEL]: 'EXCEL'
    },
    selectSpecificMerchantFirst: 'Please select a specific merchant first'
})
