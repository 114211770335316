import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    title: string
    emailRequired: string
    emailInvalid: string
    passwordRequired: string
    login: string
}>()

