import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    acquisitionChannel: string
    productType: string
    approvalType: string
    segmentCategory: string
    segmentSubCategory: string
}>()

