import React from 'react'
import { Button, Form, Modal } from 'antd'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { STORE_TYPES } from '~/code/constants/StoreTypes'
import { RiskLimitsFormModel } from '~/code/pages/BPMProcesses/Requests/models'
import {
    convertLimitFields,
    getChangedItemsList
} from '~/code/pages/MerchantsManagement/components/RiskLimits/components/ChangeRiskLimitsModal/services'
import { ChangeRiskLimits } from '~/code/pages/MerchantsManagement/components/RiskLimits/components/ChangeRiskLimitsModal/ChangeRiskLimitsModal'
import { DrawerTitleStore } from '~/code/stores/SubmittedRequestsStore/DrawerTitleStore'
import translations from './translations'
import { SubmittedRequestsStatus } from '../../../SubmittedRequests/models'
import styles from './DrawerTitle.scss'

const { confirm } = Modal


interface DrawerTitleProps {
    closeDrawer: () => void
}

export const DrawerTitle = observer((props: DrawerTitleProps) => {
    const { closeDrawer } = props

    const store = useInjection<DrawerTitleStore>(STORE_TYPES.DrawerTitleStore)

    const { changeRiskLimitsStore, decision } = store
    const [form] = Form.useForm()

    const onFinish = (values: RiskLimitsFormModel ) => {
        confirm({
            title: store.isApproveDecision ? translations().sureToApproveRequest : translations().sureToRejectRequest ,
            content: store.isApproveDecision ? getChangedItemsList(changeRiskLimitsStore.initialValues, changeRiskLimitsStore.currentValues) : null,
            okType: 'danger',
            okText: store.isApproveDecision ? translations().approve : translations().reject,
            cancelText: translations().cancel,
            okButtonProps: {
                type: 'primary',
                danger: store.isRejectDecision
            },
            onOk: async () => {
                const isComplete = await store.finishRiskReview({
                    comment: values.comment,
                    decision,
                    ...(decision === SubmittedRequestsStatus.APPROVED && { limits: convertLimitFields(values) })
                })

                if (isComplete) {
                    store.changeRiskLimitsStore.closeModal()
                    form.resetFields()
                    store.reset()
                    closeDrawer()
                }
            }
        })
    }

    return <div className={ styles.DrawerTitle }>
        <div className={styles.drawerTitleLabel}>{ store.processDetails.processStatus }</div>
        {store.processDetails.processStatus === 'STARTED' && (
            <>

                <div className={ styles.actionList }>
                    <Button
                        disabled={ store.isLoading || store.hasError }
                        type='primary'
                        className={styles.acceptButton}
                        onClick={() => {
                            store.setDecision(SubmittedRequestsStatus.APPROVED)
                            changeRiskLimitsStore.showModal(store.processDetails?.requestedLimits)
                        }}
                    >
                        {translations().approve}
                    </Button>
                    <Button
                        disabled={ store.isLoading || store.hasError }
                        danger={ true }
                        type={ 'primary' }
                        onClick={() => {
                            store.setDecision(SubmittedRequestsStatus.REJECTED)
                            changeRiskLimitsStore.showModal({})
                        }}
                    >
                        {translations().reject}
                    </Button>
                </div>

                <ChangeRiskLimits
                    form={ form }
                    onFinish={ onFinish }
                    title={ translations().review }
                    okButtonText={ store.isApproveDecision ? translations().approve : translations().reject }
                    isShowLimits={ store.isApproveDecision }
                    okButtonProps={ {
                        disabled: !store.isActiveConfirm,
                        danger: store.isRejectDecision
                    } }
                    isFormLayoutVertical={store.isRejectDecision}
                />
            </>
        ) }
    </div>
})
