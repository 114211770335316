import React from 'react'
import { Button, Card, Col, Pagination, Row, Table, TablePaginationConfig, Tooltip } from 'antd'
import { FileSearchOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table/interface'
import { MerchantSelect, ReloadButton } from '~/code/components'
import { RangePickerContainer } from '~/code/pages/settlements/SettlementsPayments/components/RangePickerContainer/RangePickerContainer'
import { useInjection } from 'dna-react-ioc'
import { observer } from 'mobx-react-lite'
import { StoreAndTerminalFilter } from '~/code/components/StoreAndTerminalFilter'
import { ChargebacksStoreType } from '~/code/pages/Chargebacks/types/ChargebacksStoreType'
import { chargebacksColumns } from '~/code/pages/Chargebacks/constants/chargebacks-constants'
import { ChargebacksDetailsModal } from '~/code/pages/Chargebacks/components/ChargebacksDetailsModal'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'
import translations from './translations'
import styles from './Chargebacks.scss'

export const MerchantSelectStoreChargebacksSymbol = Symbol('MerchantSelectStoreChargebacksStore')
export const ChargeBacksRangePickerStoreSymbol = Symbol('ChargeBacksRangePickerStore')
export const ChargebacksStoreSymbol = Symbol('ChargebacksStore')

export const Chargebacks = observer(() => {
  const store = useInjection<ChargebacksStoreType>(ChargebacksStoreSymbol)

  const {
    currentPage,
    size,
    total,
    chargebacks,
    loadChargebacks,
    storeAndTerminalFilterStore,
    refreshFilter,
    openDetailsModal,
    isChargebacksLoading,
    chargebackDetails,
    showDetailsModal,
    modalTitle,
    isChargebackDetailsLoading,
    recordId,
    viewDetails
  } = store

  const columns = [
    ...chargebacksColumns,
    {
      key: 'operation',
      fixed: 'right',
      width: 50,
      render: data => (
        <Tooltip title={translations().viewDetails}>
          <Button type={'text'} onClick={() => viewDetails(data)} icon={<FileSearchOutlined />} />
        </Tooltip>
      )
    }
  ]

  const paginationProps: TablePaginationConfig = generateTablePaginationProps(
    currentPage,
    size,
    total,
    (page, pageSize) => loadChargebacks({ page, size: pageSize })
  )

  const dataSource = chargebacks.map((item, index) => ({
    key: index,
    ...item
  }))

  return (
    <>
      <Card>
        <Row justify={'space-between'}>
          <Col>
            <MerchantSelect storeSymbol={MerchantSelectStoreChargebacksSymbol} />
          </Col>
          <Col>
            <RangePickerContainer injectableStoreIdentifier={ChargeBacksRangePickerStoreSymbol} />
          </Col>
        </Row>
        <Row>
          <StoreAndTerminalFilter className={styles.storeAndTerminalFilter} store={storeAndTerminalFilterStore} />
        </Row>
      </Card>

      <Card style={{ marginTop: 40 }}>
        <Row justify={'end'} className={styles.topPagination}>
          <Col>
            <ReloadButton onClick={refreshFilter} disabled={false} className={styles.reloadButton} />
          </Col>
          <Col>
            <Pagination {...paginationProps} disabled={false} />
          </Col>
        </Row>
        <Table
          size={'small'}
          bordered={true}
          dataSource={dataSource}
          columns={columns as ColumnsType}
          pagination={false}
          scroll={{ x: 1000, y: 1000 }}
          loading={isChargebacksLoading}
          rowKey={(row: { transactionId: string }, index) => `${row.transactionId} + ${index}`}
        />
        <Row justify={'end'} className={styles.bottomPagination}>
          <Col>
            <ReloadButton onClick={refreshFilter} disabled={false} className={styles.reloadButton} />
          </Col>
          <Col>
            <Pagination {...paginationProps} disabled={false} />
          </Col>
        </Row>
      </Card>
      <ChargebacksDetailsModal
        id={recordId}
        details={chargebackDetails}
        open={showDetailsModal}
        onOk={openDetailsModal}
        title={modalTitle}
        isLoading={isChargebackDetailsLoading}
      />
    </>
  )
})
