import React from 'react'
import { Col, Divider, List, Row, Tabs, Typography } from 'antd'
import { TabsProps } from 'antd/lib/tabs'
import {
  WebNotificationPreview
} from '~/code/pages/Notifications/ManageNotificationTemplates/components/WebNotificationPreview'
import {
  MobileNotificationPreview
} from '~/code/pages/Notifications/ManageNotificationTemplates/components/MobileNotificationPreview'
import { TemplateDetailsPropsType } from './props'
import translations from './translations'
import styles from './styles.scss'

export const TemplateDetails = ({
  isDashboard,
  previewTemplate,
  notificationSetupForm,
  data
}: TemplateDetailsPropsType) => {

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: translations().web,
      children: <WebNotificationPreview isDashboard={isDashboard} previewTemplate={previewTemplate} notificationSetupForm={notificationSetupForm}/>,
    },
    {
      key: '2',
      label: translations().mobile,
      children: <MobileNotificationPreview isDashboard={isDashboard} previewTemplate={previewTemplate} notificationSetupForm={notificationSetupForm}/>,
    },
  ]



  return (
    <>
      <Typography.Title level={5}>{translations().fields}</Typography.Title>
      <Divider className={styles.detailsDivider}/>
      <List
        className={styles.detailsList}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 2
        }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={<span className={styles.listTitle}>{item.title}</span>}
              description={<span className={styles.listDescription}>{item.description}</span>}
            />
          </List.Item>
        )}
      />
      <Typography.Title level={5}>{translations().preview}</Typography.Title>
      <Divider className={styles.detailsDivider}/>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey="1" items={items} />
        </Col>
      </Row>
    </>
  )
}