import { Form, Row, Col } from 'antd'
import React from 'react'
import { Input } from '~/code/components'
import { FormSubgroupTitle, PhoneNumberFormItem, SubLabel } from 'startapp/components'
import translations from './translations'
import { PersonalInfoFormProps } from './props'
import styles from './PersonalInfoForm.scss'

export const PersonalInfoForm: React.FC<PersonalInfoFormProps> = (props) => {
    const { form } = props

    return <div className={styles.container}>
        <FormSubgroupTitle>{translations().title}</FormSubgroupTitle>

        <Row gutter={[64, 0]}>
            <Col xs={24}>
                <Form.Item
                    name='firstName'
                    label={<div>
                        {translations().firstName} <SubLabel>{translations().firstNameSubLabel}</SubLabel>
                    </div>}
                    rules={[{ required: true, message: translations().firstNameRequired }]}
                    validateTrigger='onBlur'
                >
                    <Input placeholder={'John'}/>
                </Form.Item>
            </Col>

            <Col xs={24}>
                <Form.Item
                    name='surname'
                    label={translations().surname}
                    rules={[{ required: true, message: translations().surnameRequired }]}
                    validateTrigger='onBlur'
                >
                    <Input placeholder={'Doe'}/>
                </Form.Item>
            </Col>
            
            <Col xs={24}>
                <Form.Item
                    name='email'
                    label={<div>
                        {translations().emailLabel} <SubLabel>{translations().emailSubLabel}</SubLabel>
                    </div>}
                    rules={[
                        { type: 'email', message: translations().emailFormatIncorrect },
                        { required: true, message: translations().emailRequired }
                    ]}
                    validateTrigger='onBlur'
                >
                    <Input 
                        placeholder={'me@company.co.uk'} 
                        textTransform={'lowercase'} 
                    />
                </Form.Item>
            </Col>

            <Col xs={24}>
                <PhoneNumberFormItem />
            </Col>
        </Row>
    </div>
}