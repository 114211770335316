import React, { useEffect, useState } from 'react'
import { Table, Form } from 'antd'
import { observer } from 'mobx-react'
import { blendedTableColumns } from './services/generator'
import { BlendedTableProps } from './props'
import styles from './styles.scss'

export const BlendedTable = observer((props: BlendedTableProps) => {
  const { store, form, initialValues, type } = props
  const [mergedInitialValues, setMergedInitialValues] = useState(store.mergeInitialvaluesBlended(type, initialValues))

  useEffect(() => {
    store.onInitialValuesChangedBlended(initialValues, type, form, setMergedInitialValues)
    setTimeout(() => form.setFieldsValue(store.mergeInitialvaluesBlended(type, initialValues)), 1000)
  }, [initialValues])

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={mergedInitialValues}
      scrollToFirstError
    >
      <Table
        loading={store.isTariffsLoading}
        columns={blendedTableColumns(store.isPartnerWithoutLimits)}
        dataSource={store.getFilteredTariffsList(type, 'blended')?.map((p, idx) => ({ key: idx, ...p }))}
        pagination={false}
        size='small'
        bordered
        className={styles.blendedTable}
      />
    </Form>
  )
})
