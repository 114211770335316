import React from 'react'
import { observer } from 'mobx-react'
import { Skeleton } from 'antd'
import { withAppStore } from '~/code/hocs/withAppStore'
import { MainForm } from './components'
import { StartProcessStore, StartProcessStoreContext } from './StartProcessStore'
import './StartProcess.scss'

const StartProcessView = observer((props: { store: StartProcessStore }) => {
  const { store } = props

  return (
    <StartProcessStoreContext.Provider value={store}>
      <>{store.isLoadApplicationByIdLoading ? <Skeleton /> : <MainForm store={store} />}</>
    </StartProcessStoreContext.Provider>
  )
})

export const StartProcess = withAppStore(({ appStore }) => <StartProcessView store={appStore.startProcessStore} />)
