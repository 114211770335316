import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    infoText: string
    ok: string
    new: string
    existing: string
    required: string
    onboardNewMerchant: string
    newMerchantText: string
    newMerchantDetails: string
    existingMerchantDetails: string
    existingInfo: string
    existingPopupHeader: string
    dontNeedSubmit: string
    applicationType: string
    note: string
    smeAndCorporate: string
    smeAndCorporateNote: string
    partnersAndISO: string
    partnersAndISONote: string

}>()

