import translations from './translations'

translations.add('en', {
    newApplication: 'New Application',
    locationAddress: 'Location address',
    addressError: 'Total length of Address and Town/City must not exceed 40 characters',
    address: 'Address',
    postalCode: 'Postal Code',
    country: 'Country',
    townOrCity: 'Town or City',
    incompleteData: 'Incomplete data. Please enter manually',
    fastTrack: 'FastTrack',
    msaSigned: 'MSA signed'
})
