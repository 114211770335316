import translations from './translations'

translations.add('en', {
  emailAddress: 'Email',
  searchByEmail: 'Search by email',

  formErrors: {
    wrongEmailFormat: 'wrong email format'
  },
})
