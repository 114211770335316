import L from 'leaflet'
import { RealTimeTransactionSourceType } from '~/code/stores/RealTimeTransactionStore/models'

const divIcon: L.DivIconOptions = {
  className: 'pulsation-icon',
  iconSize: [10, 10],
  iconAnchor: [5, 5]
}

const ecomIcon: L.DivIcon = L.divIcon({
  ...divIcon,
  className: `${divIcon.className} ecom`
})

const posIcon: L.DivIcon = L.divIcon({
  ...divIcon,
  className: `${divIcon.className} pos`
})

const icons: Record<RealTimeTransactionSourceType, L.DivIcon> = {
  ecom: ecomIcon,
  pos: posIcon
}

export const getIcon = (type: RealTimeTransactionSourceType = 'ecom') => {
  return icons[type]
}
