import translations from './translations'

translations.add('en', {
    merchantButtonLabel: (isLoading, isThereMerchant) => {
        if (isLoading || isThereMerchant) return 'Merchant'
        return 'No merchants'
    },
    merchant: 'Merchant',
    merchantSubLabel: 'Search by trade name, company name or MID',
    noMerchants: 'No merchants',
    acquisitionChannel: 'Acquisition Channel',
    noAcquisitionChannels: 'No acquisition channels',
    all: 'ALL',
    modalTitle: (hasAcquisitionChannels) => hasAcquisitionChannels ? 'Filters' : 'Select a merchant'
})
