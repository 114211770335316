import { StatusTabType } from '../components/AnalyticsOverview/components/chart-related/SumCountCard/models'
import translations from '../translations'

export const posStatusTabs: StatusTabType[] = [
    {
        value: 'all',
        label: translations().statusTabs.all
    },
    {
        value: 'successful',
        label: translations().statusTabs.successful
    },
    {
        value: 'failed',
        label: translations().statusTabs.failed
    }
]

export const ecomStatusTabs: StatusTabType[] = [
    ...posStatusTabs,
    {
        value: 'other',
        label: translations().statusTabs.other
    }
]

export const ecomErrorCodesStatusTabs: StatusTabType[] = [
    {
        value: 'responseCodes',
        label: translations().statusTabs.responseCodes
    },
    {
        value: 'cscErrors',
        label: translations().statusTabs.cscErrors
    },
    {
        value: 'threeDsecureErrors',
        label: translations().statusTabs.threeDsecureErrors
    }
]