import React, { forwardRef, useState } from 'react'
import Select, { SelectProps, SelectValue } from 'antd/lib/select'

import styles from './withSearchSelect.scss'

const withSearchSelect = <VT extends SelectValue = SelectValue>(getList: () => string[]) =>
  forwardRef<any, SelectProps<VT>>((props, ref) => {
    const { onSelect, onBlur, onSearch, ...rest } = props
    const allList = getList()
    const [list, setList] = useState(allList)

    return (
      <Select
        className={styles.withSearchSelect}
        {...rest}
        ref={ref}
        allowClear
        showArrow
        showSearch
        onSelect={(value, option) => {
          setList(allList)
          onSelect && onSelect(value, option)
        }}
        onBlur={value => {
          setList(allList)
          onBlur && onBlur(value)
        }}
        onSearch={text => {
          setList(allList.filter(c => c.toLocaleLowerCase().includes(text.toLocaleLowerCase())))
          onSearch && onSearch(text)
        }}
      >
        {list.map(c => (
          <Select.Option key={c} value={c}>
            {c}
          </Select.Option>
        ))}
      </Select>
    )
  })

export { withSearchSelect }
