import { makeObservable, runInAction, observable, action } from 'mobx'
import { message, FormInstance } from 'antd'
import { injectable } from 'inversify'
import { log } from 'dna-common'
import { loadSalesForceData } from './services/fetchers'
import { SalesForceDataResType } from './models/merchant-dossier-v2'
import { ISalesForceSearchStore } from '~/code/pages/MerchantsManagement/components/SalesForceSearch/ISalesForceSearchStore'
import translations from './translations'

@injectable()
export class SalesForceSearchStore implements ISalesForceSearchStore {
  isSalesForceDataLoading: boolean = false
  salesForceDataResult: SalesForceDataResType = {} as SalesForceDataResType
  midsAreEqual: boolean = true
  selectedMid: string = ''
  noSalesForceDataFound: boolean = false
  salesForceSuccessfulValidation: boolean = false
  constructor() {
    makeObservable(this, {
      isSalesForceDataLoading: observable,
      selectedMid: observable,
      noSalesForceDataFound: observable,
      salesForceSuccessfulValidation: observable,
      salesForceDataResult: observable,
      midsAreEqual: observable,
      handleSalesForceResult: action,
      clearSalesForceData: action,
      validateMids: action,
      loadSalesForceData: action
    })
  }

  handleSalesForceResult(result: SalesForceDataResType[], form: FormInstance, midsList: string[]) {
    if (result?.length > 0) {
      this.salesForceDataResult = result[0]
      this.noSalesForceDataFound = false
      this.validateMids(form, midsList)
    } else {
      this.clearSalesForceData(form)
      this.noSalesForceDataFound = true
      this.salesForceSuccessfulValidation = false
    }
  }

  clearSalesForceData(form: FormInstance) {
    this.salesForceDataResult = {} as SalesForceDataResType
    this.midsAreEqual = true
    this.noSalesForceDataFound = false
    this.salesForceSuccessfulValidation = false
    form.resetFields(['salesForceCaseId', 'salesForceCaseIdSearch'])
  }

  validateMids(form: FormInstance, midsList: string[]) {
    const mid = this.salesForceDataResult.mid
    const salesForceId = this.salesForceDataResult.id
    const selectedMidList = midsList.map(mid => mid)
    this.midsAreEqual = selectedMidList.includes(mid)
    if (this.midsAreEqual) {
      this.salesForceSuccessfulValidation = true
      form.setFieldValue('salesForceCaseId', salesForceId)
    } else {
      this.salesForceSuccessfulValidation = false
    }
  }

  async loadSalesForceData(salesForceId: string, form: FormInstance, midsList: string[]) {
    try {
      runInAction(() => {
        this.isSalesForceDataLoading = true
      })
      if (!salesForceId) return
      const { status, error, result } = await loadSalesForceData(salesForceId)
      if (status !== 200 || error) {
        message.error(error?.message || translations().errorLoadingSalesForceData)
        this.clearSalesForceData(form)
      } else {
        this.handleSalesForceResult(result, form, midsList)
      }
    } catch (error) {
      message.error(error?.message || translations().errorLoadingSalesForceData)
      log(error)
    } finally {
      runInAction(() => {
        this.isSalesForceDataLoading = false
      })
    }
  }
}
