import translations from './translations'

translations.add('en', {
    contract: 'Contract',
    mids: 'Mids',
    status: 'Status',
    team: 'Team',
    reason: 'Reason',
    balance: 'Balance',
    commentPlaceHolder: 'Write your comment...',
    allSettlementsOn: 'All settlements on',
    allSettlementsOff: 'All settlements off',
    comment: 'Reason/Comment',
    required: 'Required'
})