import { injectable, inject } from 'inversify'
import { notification } from 'antd'
import { action, makeObservable, observable, computed, autorun } from 'mobx'
import {
  addPartnerLogins,
  addPartner,
  fetchPermissionsByPartner,
  fetchPermissions,
  updatePartner
} from '~/code/stores/PartnerAccessStore/services/fetchers'
import { updateTeammate } from '~/code/stores/TeammatesStore/services'
import { updateTeammateTwoFASettings } from '~/code/stores/TeammatesStore/services'
import { ItemModel } from '~/code/models/handbooks'

import {
  PartnerLoginType,
  AddPartnerLoginsRequestType,
  FinancialInstitutionOptionType,
  PartnerRequestType,
  UpdatePartnerRequestType
} from '~/code/stores/PartnerAccessStore/types'
import { EnforceTwoFAStatusParamsType } from '~/code/stores/Profile/models'
import { IPartnerAccessFormStore } from '~/code/pages/PartnerAccess/components/PartnerAccessDrawer'
import { IPartnerAccessStore } from '~/code/pages/PartnerAccess'
import {
  IPartnerAccessTableStore,
  PartnerAccessTableStoreSymbol
} from '~/code/pages/PartnerAccess/components/PartnerAccessTable'
import {
  IPartnerAccessFilterStore,
  PartnerAccessFilterStoreSymbol
} from '~/code/pages/PartnerAccess/components/PartnerAccessFilter'
import {
  getSpecificPermission,
  getHasSpecificPermission
} from '~/code/pages/PartnerAccess/components/PermissionsTable/services'

import translations from './translations'

@injectable()
export class PartnerAccessFormStore implements IPartnerAccessFormStore {
  partnerAccessStore: IPartnerAccessStore
  partnerAccessTableStore: IPartnerAccessTableStore
  partnerAccessFilterStore: IPartnerAccessFilterStore
  allPermissions: string[] = []
  partnerPermissions: string[] = []
  partnerSpecificPermissions: string[] = []
  hasPartnerSpecificPermissions: boolean = false
  isLoading: boolean = false
  financialInstitutionOptions: FinancialInstitutionOptionType[] = [
    { label: translations().dna, value: 'dna' },
    { label: translations().dna_eu, value: 'dna_eu' },
    { label: translations().paynetics_eu, value: 'paynetics_eu' }
  ]

  constructor(
    @inject(PartnerAccessTableStoreSymbol) partnerAccessTableStore: IPartnerAccessTableStore,
    @inject(PartnerAccessFilterStoreSymbol) partnerAccessFilterStore: IPartnerAccessFilterStore
  ) {
    this.partnerAccessTableStore = partnerAccessTableStore
    this.partnerAccessStore = partnerAccessTableStore.partnerAccessStore
    this.partnerAccessFilterStore = partnerAccessFilterStore

    makeObservable(this, {
      isLoading: observable,
      allPermissions: observable,
      partnerSpecificPermissions: observable,
      partnerPermissions: observable,
      hasPartnerSpecificPermissions: observable,

      setIsLoading: action.bound,
      setAllPermissions: action.bound,
      setPartnerPermission: action.bound,
      setPartnerSpecificPermissions: action.bound,
      setHasPartnerSpecificPermissions: action.bound,

      partners: computed,
      loginFormInitialValues: computed,
      partnerFormInitialValues: computed
    })

    autorun(() => this.getPermissions())
  }

  get partners(): ItemModel[] {
    return this.partnerAccessFilterStore.partners.map(({ id, value }) => ({ id, value, label: value }))
  }

  get loginFormInitialValues(): Partial<PartnerLoginType> {
    const s = this.partnerAccessStore.selectedTableItem

    return {
      login: s?.login || '',
      firstName: s?.firstName || '',
      lastName: s?.lastName || '',
      sendEmail: this.partnerAccessStore.isEditMode ? undefined : true
    }
  }

  get partnerFormInitialValues(): PartnerRequestType {
    const p = this.partnerAccessStore.selectedPartner

    return this.partnerAccessStore.isPartnerDrawerEdit
      ? {
          financialInstitution: p?.financialInstitution || null,
          partnerName: p?.value || '',
          parentCompany: p?.parentID ? this.getParentPartner(p?.parentID)?.value : null,
          permissions: []
        }
      : ({} as PartnerRequestType)
  }

  get isSpecificPermissionsVisible(): boolean {
    return this.hasPartnerSpecificPermissions || !!this.partnerSpecificPermissions.length
  }

  getParentPartner = (parentID: string) => {
    return this.partners.find(i => i.id == +parentID)
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  setAllPermissions = (permissions: string[]) => {
    this.allPermissions = permissions
  }

  setPartnerPermission = (permissions: string[]) => {
    this.partnerPermissions = permissions
  }

  setPartnerSpecificPermissions = (permissions: string[]) => {
    this.partnerSpecificPermissions = permissions
  }

  setHasPartnerSpecificPermissions = (hasPartnerSpecificPermissions: boolean) => {
    this.hasPartnerSpecificPermissions = hasPartnerSpecificPermissions
  }

  onLoginFormSubmit = async (
    user: Partial<PartnerLoginType> & AddPartnerLoginsRequestType & { twoFAEnabled: boolean }
  ) => {
    const asyncCallback = this.partnerAccessStore.isEditMode ? this.updatePartnerLogin : this.addPartnerLogin

    await asyncCallback(user)
  }

  updatePartnerLogin = async (user: Partial<PartnerLoginType>) => {
    this.setIsLoading(true)

    try {
      const { firstName, lastName } = user

      const { status, error } = await updateTeammate(this.partnerAccessStore.selectedTableItem.id, {
        firstName,
        lastName
      })

      if (error) {
        notification.error({ message: error.message || translations().loginUpdateFailure })
        return
      }

      if (status === 200) {
        notification.success({ message: translations().loginSuccessfulUpdated })
      }

      this.partnerAccessStore.onLoginDrawerClose()
      this.partnerAccessTableStore.reload()
    } catch (error) {
      notification.error({ message: error.message || translations().loginUpdateFailure })
    } finally {
      this.setIsLoading(false)
    }
  }

  addPartnerLogin = async ({ twoFAEnabled, ...user }: AddPartnerLoginsRequestType & { twoFAEnabled: boolean }) => {
    this.setIsLoading(true)

    try {
      const twoFaStatus = twoFAEnabled ? 'ENFORCED' : 'DISABLED'

      const { status, error } = await addPartnerLogins({
        ...user,
        twoFaStatus,
        partnerKey: this.partnerAccessStore?.selectedPartner?.clientId
      })

      if (error) {
        notification.error({ message: error.message || translations().loginAddFailure })
        return
      }

      if (status === 200) {
        notification.success({ message: translations().loginSuccessfulAdded })
      }

      this.partnerAccessStore.onLoginDrawerClose()
      this.partnerAccessTableStore.reload()
    } catch (error) {
      notification.error({ message: error.message || translations().loginAddFailure })
    } finally {
      this.setIsLoading(false)
    }
  }

  onPartnerFormSubmit = async (partner: PartnerRequestType) => {
    const asyncCallback = this.partnerAccessStore.isPartnerDrawerEdit ? this.updatePartner : this.addPartner

    await asyncCallback(partner)
  }

  addPartner = async (partner: PartnerRequestType) => {
    this.setIsLoading(true)

    try {
      const { status, error } = await addPartner(partner)

      if (error) {
        notification.error({ message: error.message || translations().partnerAddFailure })
        return
      }

      if (status === 200) {
        notification.success({ message: translations().partnerSuccessfulAdded })
      }

      this.partnerAccessStore.onPartnerDrawerClose()
      this.partnerAccessFilterStore.fetchPartners()
    } catch (error) {
      notification.error({ message: error.message || translations().partnerAddFailure })
    } finally {
      this.setIsLoading(false)
    }
  }

  updatePartner = async (partner: PartnerRequestType) => {
    this.setIsLoading(true)

    try {
      const { financialInstitution, parentCompany, ...updatedPartner } = partner

      const request: UpdatePartnerRequestType = {
        ...updatedPartner,
        newFinancialInstitution: financialInstitution,
        ...(!!parentCompany ? { updatedParentCompany: parentCompany } : {}),
        ...(partner.partnerName === this.partnerAccessStore.selectedPartner?.value
          ? {}
          : {
              updatedPartnerName: partner.partnerName,
              partnerName: this.partnerAccessStore.selectedPartner?.value
            })
      }

      const { status, error } = await updatePartner(request)

      if (error) {
        notification.error({ message: error.message || translations().partnerUpdateFailure })
        return
      }

      if (status === 200) {
        notification.success({ message: translations().partnerSuccessfulUpdated })
      }

      this.partnerAccessStore.onPartnerDrawerClose()

      await this.partnerAccessFilterStore.fetchPartners()

      const newPartnerName = request.updatedPartnerName || request.partnerName
      const newSelectedPartner = this.partnerAccessFilterStore.partners.find(
        ({ value }) => value?.toLowerCase() === newPartnerName?.toLowerCase()
      )

      this.partnerAccessStore.setSelectedPartner(newSelectedPartner)
    } catch (error) {
      notification.error({ message: error.message || translations().partnerUpdateFailure })
    } finally {
      this.setIsLoading(false)
    }
  }

  getPermissions = async () => {
    try {
      const { status, result, error } = await fetchPermissions()

      if (error) {
        notification.error({ message: error.message })
        return
      }

      if (status === 200) {
        const allPermissions: string[] = result?.permissions || []

        this.setAllPermissions(allPermissions)

        this.setPartnerSpecificPermissions(getSpecificPermission(allPermissions))
      }
    } catch (error) {
      notification.error({ message: error.message })
    }
  }

  getPartnerPermissions = async () => {
    this.setPartnerPermission([])

    try {
      const { status, result, error } = await fetchPermissionsByPartner(
        this.partnerAccessStore?.selectedPartner?.clientId
      )

      if (error) {
        notification.error({ message: error.message })
        return
      }

      if (status === 200 && result) {
        this.setPartnerPermission(result.permissions || [])

        const hasSpecificPermission = getHasSpecificPermission(result.permissions)

        this.setHasPartnerSpecificPermissions(hasSpecificPermission)

        if (!Boolean(this.partnerSpecificPermissions.length) && hasSpecificPermission) {
          this.setPartnerSpecificPermissions(getSpecificPermission(result.permissions))
        }
      }
    } catch (error) {
      notification.error({ message: error.message })
    }
  }

  updateSecurity = async ({
    twoFAEnabled,
    reason,
    publicId
  }: {
    publicId: string
    twoFAEnabled: boolean
    reason?: string
  }) => {
    this.setIsLoading(true)

    try {
      const twoFASettingsRequest: EnforceTwoFAStatusParamsType = {
        twoFAStatus: twoFAEnabled ? 'ENFORCED' : 'DISABLED',
        ...(!!reason ? { reason } : {})
      }

      const { status, error } = await updateTeammateTwoFASettings(publicId, twoFASettingsRequest)

      if (status === 200) {
        this.partnerAccessTableStore.reload()
        this.partnerAccessStore.onSecurityDrawerClose()
        notification.success({ message: translations().securitySettingsSuccessfulUpdated })
      } else {
        throw new Error(error.message || translations().securitySettingsUpdateFailure)
      }
    } catch (error) {
      notification.error({ message: error.message || translations().securitySettingsUpdateFailure })
    } finally {
      this.setIsLoading(false)
    }
  }
}
