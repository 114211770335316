import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    selectType: string
    fillInDetails: string
    manageTemplates: string
    createNotification: string
    createNotificationTemplate: string
    editNotificationTemplate: string
}>()

