import React from 'react'
import { observer } from 'mobx-react'
import { LockOutlined } from '@ant-design/icons'
import { Badge, Button, Form, Input } from 'antd'
import { PASSWORD_CRITERIEA } from 'dna-common'

import { PASSWORD_REQUIRED_PATTERN } from '~/code/constants/Patterns'
import { AuthForm } from '~/code/components/auth/AuthForm'
import { AuthFormSubmitButton } from '~/code/components/auth/AuthFormSubmitButton'

import translations from './translations'
import { SetNewPasswordProps } from './props'
import styles from './SetNewPassword.scss'

export const SetNewPassword = observer((props: SetNewPasswordProps) => {
  const { store } = props
  const [ form] = Form.useForm()
  const { getFieldValue } = form

  return <AuthForm
    title={translations().title}
  >
    <Form
      form={form}
      layout={'vertical'}
      validateTrigger={['onBlur']}
      onFinish={({ password }) => {
        store.setPassword(password)
      }}
    >
      <Form.Item
        name='password'
        rules={[
          { required: true, message: translations().passwordRequired },
          {
            pattern: new RegExp(PASSWORD_REQUIRED_PATTERN),
            message: translations().passwordWrongFormat
          }
        ]}
      >
        <Input.Password
          size='large'
          prefix={<LockOutlined />}
          placeholder={translations().password}
        />
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {() => {
          const password = getFieldValue('password') || ''
          return <div className={styles.criteria}>
            {PASSWORD_CRITERIEA.map((item) => <div key={item.key}>
              <Badge status={item.validator(password) ? 'success' : 'default'} /> {item.title}
            </div>)}
          </div>
        }}
      </Form.Item>
      <div>

      </div>

      <Form.Item
        name='confirmPassword'
        rules={[
          { required: true, message: translations().confirmPasswordRequired },
          {
            validator: async (rule, value) => {
              if ((value && value !== getFieldValue('password'))) {
                  throw new Error(translations().passwordsDoNotMatch)
              }
            }
          }
        ]}
      >
        <Input.Password
          size='large'
          prefix={<LockOutlined />}
          placeholder={translations().confirmPassword}
        />
      </Form.Item>

      <AuthFormSubmitButton
        loading={store.isLoading}
      >
        {translations().set}
      </AuthFormSubmitButton>

      <Button size='large' className={styles.cancel} onClick={store.cancelResetPassword}>
        {translations().cancel}
      </Button>
    </Form>
  </AuthForm>
})
