import { isYesterdayPeriodDisabled, isLastWeekPeriodDisabled, isLastMonthPeriodDisabled } from './index'
import { isTodaySaturdayOrSunday } from '../../../services'
import translations from '../translations'

const getYesterdayTooltipTitle = () => {
    if (isTodaySaturdayOrSunday()) return translations().willBeAvailableOnMondayAfter6AM

    if (isYesterdayPeriodDisabled()) return translations().willBeAvailableAfter6AM

    return null
}

export const getPeriods = (isRangePickerDisabled?: boolean) => {
    if (isRangePickerDisabled) {
        return [
            { value: 'yesterday', text: translations().yesterday, isDisabled: true, tooltipTitle: translations().selectMerchantOrAcquisitionChannelFirst },
            { value: 'lastWeek', text: translations().lastWeek, isDisabled: true, tooltipTitle: translations().selectMerchantOrAcquisitionChannelFirst },
            { value: 'lastMonth', text: translations().lastMonth, isDisabled: true, tooltipTitle: translations().selectMerchantOrAcquisitionChannelFirst }
        ]
    }

    return [
        { value: 'yesterday', text: translations().yesterday, isDisabled: isYesterdayPeriodDisabled(), tooltipTitle: getYesterdayTooltipTitle() },
        { value: 'lastWeek', text: translations().lastWeek, isDisabled: isLastWeekPeriodDisabled(), tooltipTitle: isLastWeekPeriodDisabled() ? translations().willBeAvailableAfter6AM : null },
        { value: 'lastMonth', text: translations().lastMonth, isDisabled: isLastMonthPeriodDisabled(), tooltipTitle: isLastMonthPeriodDisabled() ? translations().willBeAvailableAfter6AM : null }
    ]
}
