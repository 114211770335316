import React from 'react'
import translations from './translations'
import styles from '../DetailsForm.scss'
import { Typography } from 'antd'

const { Link } = Typography

translations.add('en', {
  title: 'Title',
  titleRequired: 'title is required',
  name: 'First name',
  nameRequired: 'first name is required',
  dateBirth: 'Date of birth',
  dateBirthRequired: 'date of birth is required',
  dateBirthInvalid: 'invalid date of birth',
  dateBirthAgeLimit: 'age must be between 18 and 100',
  gender: 'Gender',
  genderRequired: 'gender is required',
  middleName: 'Middle name',
  middleNameRequired: 'middle name is required',
  surname: 'Surname',
  surnameRequired: 'surname is required',
  houseName: 'House name',
  houseNumber: 'House number',
  addressTitle: 'Residential address',
  street: 'Address line 1',
  streetRequired: 'address line 1 is required',
  street2: 'Address line 2',
  city: 'Town/City',
  cityRequired: 'town/city is required',
  cityInvalid: 'only alphanumeric characters are allowed',
  region: 'County',
  country: 'Country',
  countryRequired: 'country is required',
  countryResidence: 'Country of residence',
  countryResidenceRequired: 'country of residence is required',
  postCode: 'Postcode',
  postCodeRequired: 'postcode is required',
  postCodeInvalid: 'invalid postcode',
  nationality: 'Nationality',
  nationalityRequired: 'nationality is required',
  emailAddress: 'Email address',
  emailAddressRequired: 'email address is required',
  emailFormatIncorrect: 'invalid email',
  telephoneNumber: 'Phone number',
  telephoneNumberRequired: 'phone number is required',
  telephoneNumberWrongFormat: 'invalid phone number',
  occupation: 'Occupation',
  occupationRequired: 'occupation is required',
  placeBirth: 'Place of birth',
  placeBirthRequired: 'place of birth is required',
  personalInfo: 'Personal info',
  contactInfo: 'Contact info',
  submit: 'Submit',
  back: 'Back',
  ownershipPercentage: 'Ownership Percentage',
  ownershipPercentageRequired: 'ownership percentage is required',
  titles: {
    mr: 'Mr',
    mrs: 'Mrs',
    miss: 'Miss',
    ms: 'Ms'
  },
  genders: {
    male: 'Male',
    female: 'Female',
    other: 'Other'
  },
  checkPassed: 'Verification of officer successfully passed, confirming document is not required',
  checkFailed: 'Officer verification is not passed, in the next steps you will need to attach a confirming document',
  verify: 'Verify',
  checkResult: (result: string) => {
    switch (result) {
      case 'text1':
        return `The officer's address verification and officer's ID verification and  is not passed, in the next steps you will need to attach a confirming documents - Proof of address and Proof of ID.`
      case 'text2':
        return `The officer's ID verification is not passed, in the next steps you will need to attach a confirming document - Proof of ID.
        The officer's address verification is passed.`
      case 'text3':
        return `The officer's address verification is not passed, in the next steps you will need to attach a confirming document - Proof of address.
        The officer's ID verification is  passed. `
      case 'text4':
        return `The officer's address verification and officer's ID verification is passed.
        In the next steps you will not need to attach a confirming documents for this officer - Proof of address and Proof of ID.`
      default:
        return ''
    }
  },
  modalTitle: 'Check individuals details',
  save: 'Save for later',
  complete: 'Complete',
  welcomeText1: 'Dear user,',
  welcomeText2: 'You`re about to start verification of the personal data.',
  backToForm: 'Back to form',
  pleaseDoubleCheck: 'Please double-check these details',
  detailsInfo: (firstName: string, lastName: string, dob: string, address: string, postalCode: string) => (
    <>
      <p>{`First name: ${firstName}`}</p>
      <p>{`Last name: ${lastName}`}</p>
      <p>{`Date of birth: ${dob}`}</p>
      <p>{`Residental address: ${address}`}</p>
      <p>{`Postal code: ${postalCode}`}</p>
    </>
  ),
  detailAreCorrect: 'Details are correct',
  verificationResult: 'Verification results:',
  idVerifResult: (result: boolean) => (
    <>
      <b>ID Verification:</b>
      {result && (
        <>
          <p className={styles.verified}>VERIFIED</p>
          <p>ID document is NOT required</p>
        </>
      )}
      {!result && (
        <>
          <p className={styles.nonVerified}>NON-VERIFIED</p>
          <p>Please obtain ID document and upload it at the final step of this application</p>
        </>
      )}
    </>
  ),
  addressVerifResult: (result: boolean) => (
    <>
      <b>Residential address verification:</b>
      {result && (
        <>
          <p className={styles.verified}>VERIFIED</p>
          <p>POA document is NOT required</p>
        </>
      )}
      {!result && (
        <>
          <p className={styles.nonVerified}>NON-VERIFIED</p>
        </>
      )}
    </>
  ),
  idVerifResult1: (result: boolean) => (
    <>
      {result && (
        <>
          <div className={styles.successTxt}>
            <p>
              <b>ID Verification - </b>
            </p>
            <p className={styles.verified}>SUCCESSFUL</p>
          </div>
          ID verification is successfully completed.
          <br />
          ID document IS NOT required
        </>
      )}
      {!result && (
        <>
          <div className={styles.successTxt}>
            <p>
              <b>ID Verification - </b>
            </p>
            <p className={styles.nonVerified}>UNSUCCESSFUL</p>
          </div>
          ID verification has failed. <br />
          Please obtain a valid ID document and upload it at the ”Documents” stage.
        </>
      )}
    </>
  ),
  addressVerifResult1: (result: boolean) => (
    <>
      {result && (
        <>
          <div className={styles.successTxt}>
            <p>
              <b>Residential address verification - </b>
            </p>
            <p className={styles.verified}>SUCCESSFUL</p>
          </div>
          Residential address verification is successfully completed.
          <br />
          Proof of address document IS NOT required
        </>
      )}
      {!result && (
        <>
          <div className={styles.successTxt}>
            <p>
              <b>Residential address verification - </b>
            </p>
            <p className={styles.nonVerified}>UNSUCCESSFUL</p>
          </div>
          Residential address verification has failed. <br />
          Please obtain a valid Proof of Address document and upload it at the ”Documents” stage.
        </>
      )}
    </>
  ),
  verificationWarn: () => (
    <>
      <b>Verifications</b>
      <br />
      Individual's address and ID verification must be submitted first.
    </>
  ),
  cancel: 'Cancel',
  agreementSignatory: 'Agreement signatory',
  agreementSignatoryInfo: 'If this person will be acting as a signatory of DNA Payments Merchant Service Agreement',
  automatedIdentityVerification: 'Automated identity verification results',
  whatsNext: 'WHAT`S NEXT',
  twoOptions: 'You now have two options:',
  optionOne:
    'Send the Jumio authorisation link. Merchant will receive request to verify and confirm their id and address details',
  optionTwo:
    'Upload the bank statementWhen proceeding with this option, you will have to upload a recent bank statement on the ”Documents” section',
  jumioRequest: 'Jumio verification',
  sendJumioRequest: 'Send the Jumio Request',
  close: 'Close',
  editDetails: 'Edit details',
  startJumioRequestInfo: 'Dear user, you`re about to send the Jumio verification request',
  startJumioRequestInfo2: (
    <>
      <p>Merchant will receive this request via their email </p>
      <p>
        Please inform them that they need to open the link and follow instructions to upload their ID document and
        complete ID verification{' '}
      </p>
      <p>
        What is Jumio Verification: We're using this tool to send ID & Address verification requests. Once received
        Individual will have to follow the link and instructions to take a selfie and a photo of their documents
      </p>
    </>
  ),
  jumioVerificationSent:
    'Verification request is now sent, we`re waiting for the merchant to complete the verification form.',
  sendJumioLink: 'Send Jumio Link',
  uploadDoc: 'I will upload documents',
  saveForLater: 'Save for later',
  updateDetails: 'Update the ID details',
  resetChecks: 'Verification results will be lost, are you sure want to edit details?',
  refreshStatus: 'Refresh status',
  jumioStatus: 'Jumio identity verification',
  detailsEdit: 'Edit details',
  verifResult: (status: string) => (
    <>
      <div className={styles.flex}>
        <p>
          <b>{`Verification results -  `}</b>
        </p>
        {status?.toUpperCase() === 'VERIFIED' && (
          <p className={styles.statusWithMargin}>
            <b className={styles.green}>COMPLETE, VERIFIED</b>
          </p>
        )}
        {status?.toUpperCase() === 'COMPLETED' && (
          <p className={styles.statusWithMargin}>
            <b className={styles.green}>COMPLETE</b>
          </p>
        )}
        {status?.toUpperCase() === 'SENT' && (
          <p className={styles.statusWithMargin}>
            <b>SENT, Waiting for the completion</b>
          </p>
        )}
      </div>
    </>
  ),
  hasComplete: (email: string) => (
    <>
      <p>
        Individual has to complete Jumio verification process following the link they`ve received on this email:{' '}
        <Link href={`mailto:${email}`}>{email}</Link>
      </p>
      <i>Note: you can proceed with the rest of the application in the meantime</i>
      <p>
        <i>
          Please note, in some cases, it takes 5-10 minutes for the email with the link to reach the recipient inbox.
        </i>
      </p>
      <p>
        You can now inform your merchant that they need to open the email and follow instructions to complete the Jumio
        Identity verification
      </p>
    </>
  ),
  docsNotRequired: 'POID and POA documents are NOT required.',
  jumioVerifResult: (idVerif: boolean, addressVerif: boolean) => (
    <>
      <div className={styles.flex}>
        <p>
          <b>{`ID Verification -  `}</b>
        </p>
        <p className={styles.statusWithMargin}>
          {idVerif === true && <b className={styles.green}>PASS</b>}
          {idVerif === false && <b className={styles.yellow}>FAIL</b>}
        </p>
      </div>

      <div className={styles.flex}>
        <p>
          <b>{`Residential address verification -  `}</b>
        </p>
        <p className={styles.statusWithMargin}>
          {addressVerif === true && <b className={styles.green}>PASS</b>}
          {addressVerif === false && <b className={styles.yellow}>FAIL</b>}
        </p>
      </div>
    </>
  ),
  recepientEmail: (email: string) => `Recepient email: ${email}`,
  userExists: 'Officer with this name elements already exists',
  areYouSure: 'Are you sure?',
  docModalText:
    'We`re waiting for the merchant to complete the Jumio ID verification, selecting  "I will upload documents" will cancel the Jumio verification request, and you will have to upload Individual`s documents in the Documents section',
  uploadDocsChoosen: 'You`ve already selected "I will upload documents" for this person. Press the "Complete" button',
  resendText: 'If a client does not receive the email, please click the "Resend" button.',
  resend: 'Resend',
  idNumber: 'Document number',
  idExpDate: 'Document expire date',
  uploadUtilityBill:
    'Upload the utility bill. To proceed with this option, you will need to upload a recent bank statement to the ”Documents” section',
  vulnerableStatus: 'Vulnerable status',
  visualDisability: 'Visual disability',
  audioDisability: 'Audio disability',
  lifeEvent: 'Life event (bereavement, relationship breakdown, job loss)',
  resilience: 'Resilience (low confidence / capability in managing financial affairs)',
  capability: 'Capability (low income / unmanaged debt)',
  vulnerabilityInfo: 'Vulnerability info:',
  addDetails: 'Please add details',
  vulnerabilityError: 'Error, choose one of these options',
  chooseAnother: 'You chose the wrong person; the surname and forename do not match. Please choose another person'
})
