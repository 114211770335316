import translations from './translations'
import React from 'react'
import styles from '../AdditionalStoreIssue.scss'

translations.add('en', {
  additionalStoreIssueConfirm: 'Additional Store Issue Confirm',
  confirmText: 'Are you sure want to start additional store issue process? ',
  confirmText2: 'If you click OK, process will start immediately',
  forbiddenMessage: (
    <>
      <b>At this time, the ability to submit a request for an additional store is only allowed for channels :</b>
      <br />
      <div className={styles.centered}>
        <i>DNA Onboarding</i> <br />
        <i>Active Payments</i> <br />
        <i>123send Sales</i> <br />
        <i>FPMS</i> <br />
        <i>Kwalitas</i> <br />
        <i>Cardcutters</i> <br />
        <i>Optomany</i>
      </div>
      <br />
    </>
  )
})
