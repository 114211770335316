import translations from '../translations'

export const TerminalTypes = [
    {
        label: translations().terminalTypes.all,
        value: 'all'
    },
    {
        label: translations().terminalTypes.ecom,
        value: 'ecom'
    },
    {
        label: translations().terminalTypes.pos,
        value: 'pos'
    }
]