
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Form, Switch, Modal, Input, Select } from 'antd'
import styles from './SettlementsStatusSwitch.scss'
import { SettlementsStatusSwitchProps } from './SettlementsStatusSwitchProps'
import { ISettlementsStatusSwitchStore } from './ISettlementsStatusSwitchStore'
import translations from './translations'

export const SettlementsStatusSwitchStoreSymbol = Symbol('SettlementsStatusSwitchStore')
export const SettlementsStatusSwitch = observer((props: SettlementsStatusSwitchProps) => {
    const store = useInjection<ISettlementsStatusSwitchStore>(SettlementsStatusSwitchStoreSymbol)
    const [currentSwitchValue, setCurrentSwithchValue] = useState<boolean>(false)
    const [showModal, setShowModal] = useState(false)
    const [form] = Form.useForm()

    useEffect(() => {
        setCurrentSwithchValue(props.settlemetValue)
    }, [props.settlemetValue, props.contract])

    const onFinish = async ({ settlementsReason, holdReason }) => {
        const holdReasonValue = currentSwitchValue ? settlementsReason : holdReason
        store.changeHoldStatus(holdReasonValue, form)
        setShowModal(false)
    }

    return (<>
        <Form.Item name={'settlementStatus'} className={styles.switchStyle}>
            <Switch
                checked={currentSwitchValue}
                checkedChildren={translations().on}
                unCheckedChildren={translations().off}
                onChange={(value) => {
                    setCurrentSwithchValue(value)
                    setShowModal(true)
                    store.changeSwitchStatus(props.contract, value)
                }}
            />
        </Form.Item>
        <Modal    
            title={translations().reason}
            open={showModal}
            onCancel={() => {
                form.resetFields()
                setShowModal(false)
                setCurrentSwithchValue(!currentSwitchValue)
            }}
            onOk={() => form.submit()}
        >
                <Form
                    form={form}
                    onFinish={(values) => onFinish(values)}
                >
                        {!currentSwitchValue &&
                        <Form.Item
                            name='holdReason'
                            rules={[{ required: true, message: translations().required }]}
                            validateTrigger={['onChange', 'onBlur']}
                        >
                            <Select options={store.settlementsReasonsDict} />
                        </Form.Item>}

                        {currentSwitchValue &&
                        <Form.Item 
                            name='settlementsReason'
                            rules={[{ required: true, message: translations().required }]}
                        >
                            <Input.TextArea />
                        </Form.Item>}

                </Form>
        </Modal>
        </>
    )
})