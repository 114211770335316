import React from 'react'
import { Card } from 'antd'
import { TransactionContainerPropsType } from './TransactionContainerPropsType'
import styles from './TransactionContainer.scss'

export const TransactionContainer: React.FC<TransactionContainerPropsType> = ({
  header,
  subHeader
}: TransactionContainerPropsType) => {
  return (
    <Card className={styles.styledContainer}>
      <p className={styles.styledHeader}>{header}</p>
      <p className={styles.styledSubHeader}>{subHeader}</p>
    </Card>
  )
}
