import { getStorageUserData, setStorageUserData } from '~/code/services/storage'
import { AcquiringType, EcomPosType, TransactionTabType } from '../../../models'

type AcquiringStorageKeyType = 'filters' | 'columns'

export const getAcquiringStorageData = (type: AcquiringType, ecomPos: EcomPosType, tab: TransactionTabType, key: AcquiringStorageKeyType) => {
    return getStorageUserData(`${type}.${ecomPos}.${tab}.${key}`)
}

export const setAcquiringStorageData = (type: AcquiringType, ecomPos: EcomPosType, tab: TransactionTabType, key: AcquiringStorageKeyType, newData: Record<string, any>) => {
    return setStorageUserData(`${type}.${ecomPos}.${tab}.${key}`, newData)
}
