export const posGenerateDetailsObject = () => {
    return {
        amountAllData: [],
        amountSuccessData: [],
        amountFailedData: [],
        amountAllCurrentPage: 1,
        amountAllPageSize: 20,
        amountAllTotal: 0,
        amountSuccessCurrentPage: 1,
        amountSuccessPageSize: 20,
        amountSuccessTotal: 0,
        amountFailedCurrentPage: 1,
        amountFailedPageSize: 20,
        amountFailedTotal: 0,
        isLoadingAmountAllData: false,
        hasLoadedAmountAllData: false,
        isLoadingAmountSuccessData: false,
        hasLoadedAmountSuccessData: false,
        isLoadingAmountFailedData: false,
        hasLoadedAmountFailedData: false,

        countAllData: [],
        countSuccessData: [],
        countFailedData: [],
        countAllCurrentPage: 1,
        countAllPageSize: 20,
        countAllTotal: 0,
        countSuccessCurrentPage: 1,
        countSuccessPageSize: 20,
        countSuccessTotal: 0,
        countFailedCurrentPage: 1,
        countFailedPageSize: 20,
        countFailedTotal: 0,
        isLoadingCountAllData: false,
        hasLoadedCountAllData: false,
        isLoadingCountSuccessData: false,
        hasLoadedCountSuccessData: false,
        isLoadingCountFailedData: false,
        hasLoadedCountFailedData: false
    }
}

export const posResetCurrentPageInDetailsData = (detailsData) => {
    return {
        ...detailsData,
        amountAllCurrentPage: 1,
        amountSuccessCurrentPage: 1,
        amountFailedCurrentPage: 1,

        countAllCurrentPage: 1,
        countSuccessCurrentPage: 1,
        countFailedCurrentPage: 1
    }
}

export const posResetDataHasBeenLoadedFlags = (detailsData) => {
    return {
        ...detailsData,
        hasLoadedAmountAllData: false,
        hasLoadedAmountSuccessData: false,
        hasLoadedAmountFailedData: false,

        hasLoadedCountAllData: false,
        hasLoadedCountSuccessData: false,
        hasLoadedCountFailedData: false
    }
}

export const posDetailsDataToTabData = (key, detailsData) => {
    return {
        key,
        amountTableData: {
            all: {
                loading: detailsData.isLoadingAmountAllData,
                data: detailsData.amountAllData,
                currentPage: detailsData.amountAllCurrentPage,
                pageSize: detailsData.amountAllPageSize,
                total: detailsData.amountAllTotal
            },
            success: {
                loading: detailsData.isLoadingAmountSuccessData,
                data: detailsData.amountSuccessData,
                currentPage: detailsData.amountSuccessCurrentPage,
                pageSize: detailsData.amountSuccessPageSize,
                total: detailsData.amountSuccessTotal
            },
            failed: {
                loading: detailsData.isLoadingAmountFailedData,
                data: detailsData.amountFailedData,
                currentPage: detailsData.amountFailedCurrentPage,
                pageSize: detailsData.amountFailedPageSize,
                total: detailsData.amountFailedTotal
            }
        },
        countTableData: {
            all: {
                loading: detailsData.isLoadingCountAllData,
                data: detailsData.countAllData,
                currentPage: detailsData.countAllCurrentPage,
                pageSize: detailsData.countAllPageSize,
                total: detailsData.countAllTotal
            },
            success: {
                loading: detailsData.isLoadingCountSuccessData,
                data: detailsData.countSuccessData,
                currentPage: detailsData.countSuccessCurrentPage,
                pageSize: detailsData.countSuccessPageSize,
                total: detailsData.countSuccessTotal
            },
            failed: {
                loading: detailsData.isLoadingCountFailedData,
                data: detailsData.countFailedData,
                currentPage: detailsData.countFailedCurrentPage,
                pageSize: detailsData.countFailedPageSize,
                total: detailsData.countFailedTotal
            }
        }
    }
}