import React from 'react'
import debounce from 'lodash.debounce'
import { observer } from 'mobx-react-lite'
import { Form, Input } from 'antd'
import { PartnerAccessFilterProps } from './props'

import translations from './translations'
import styles from './styles.scss'

export const PartnerAccessEmailInput = observer(({ onChange }: PartnerAccessFilterProps) => {
  const [form] = Form.useForm()

  const handleChange = debounce(e => {
    if (form.getFieldError('email').length) return

    onChange(e.target.value.trim())
  }, 500)

  return (
    <Form form={form} requiredMark={false} layout='vertical'>
      <Form.Item
        label={`${translations().emailAddress}:`}
        name='email'
        className={styles.item}
        rules={[{ type: 'email', message: translations().formErrors.wrongEmailFormat }]}
      >
        <Input allowClear placeholder={translations().searchByEmail} onChange={handleChange} />
      </Form.Item>
    </Form>
  )
})
