import { Form } from 'antd'
import React from 'react'
import { PHONE_NUMBER_PATTERN } from '~/code/constants/Patterns'
import { PhoneNumberInput } from '../PhoneNumberInput'
import { PhoneNumberFormItemProps } from './props'
import translations from './translations'

export const PhoneNumberFormItem: React.FC<PhoneNumberFormItemProps> = (props) => {
    const {
        name = 'telephoneNumber',
        label = translations().phoneNumberLabel,
        requiredErrorMessage = translations().phoneNumberRequired,
        invalidErrorMessage = translations().phoneNumberWrongFormat,
        shouldCheckByData8,
        options,
        required,
        ...rest
    } = props

    return (
        <Form.Item
            {...rest}
            name={name}
            label={label}
            rules={[
                { required: required, message: requiredErrorMessage },
                { validator: async (_, value) => {
                    if (value) {
                        if (!PHONE_NUMBER_PATTERN.test(value)) {
                            throw new Error(invalidErrorMessage)
                        }
                    }
                }}
            ]}
            validateTrigger={'onBlur'}
        >
            <PhoneNumberInput options={options} />
        </Form.Item>
    )
}