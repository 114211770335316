import { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  start: string
  generalInfo: string
  bankDetailsVerif: string
  storesProducts: string
  financialInfo: string
  merchantBankAccount: string
  shareholders: string
  directors: string
  newDirector: string
  signatories: string
  newSignatory: string
  businessOwner: string
  optional: string
  completed: string
  notCompleted: string
  pricing: string
  contract: string
  sign: string
  docuSign: string
  docuSignInfo: ReactNode
  signError: string
  preview: string
  alreadySentToSign: string
  update: string
  updateError: string
  title: string
  submit: string
  back: string
  individialDetails: string
  companyName: string
  pendingSignature: string
  docusignStatus: (status: string) => string
  beta: string
  contactEmail: ReactNode
  closeProcess: string
  closeProcessDescription: string
  manualMSA: string
}>()
