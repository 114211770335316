import { object, string, number } from 'yup'
import { convertDateObjectToString, isDateOfBirthValid } from 'startapp/services'
import { AddressScheme } from './AddressScheme'

export const OfficerProfileScheme = object({
  nameElements: object({
    title: string().required(),
    forename: string().required(),
    middleName: string().nullable(),
    surname: string().required()
  }).required(),
  dateOfBirth: object({
    day: number().required(),
    month: number().required(),
    year: number().required()
  })
    .required()
    .test('date-validator', '', value => isDateOfBirthValid(convertDateObjectToString(value))),
  gender: string().required(),
  birthplace: string().required(),
  nationality: string().required(),
  emailAddress: string().required().email(),
  address: AddressScheme.shape({
    houseName: string(),
    houseNumber: string()
  }).required()
}).required()
