import React, { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { Menu, Grid } from 'antd'
import { getMatchMenu } from '@umijs/route-utils'

import { context } from '~/code/startup'
import { MenuDataItemType, MenuItemType } from '~/code/models'

import styles from './styles.scss'

type Props = {
  menuData: MenuDataItemType[]
  onSelect(key: string): void
}

const { useBreakpoint } = Grid

export const SiderMenu = observer((props: Props) => {
  const screens = useBreakpoint()
  const isMobile = screens.xs || !screens.md
  const { menuData, onSelect } = props
  const [openKeys, setOpenKeys] = useState([])

  const [openedKeys, selectedKeys] = useMemo(() => {
    const menuItems = convertMenuItems(menuData)
    const matchedMenu = getMatchMenu(context.pathname, menuItems)
    return [
      matchedMenu.reduce((prev: string[], item) => [...prev, ...item.parentKeys], []),
      matchedMenu.map(item => item.key)
    ]
  }, [menuData, context.pathname])

  useEffect(() => setOpenKeys(openedKeys), [openedKeys])

  return (
    <Menu
      className={classNames({ [styles.mobile]: isMobile })}
      theme='dark'
      mode='inline'
      openKeys={openKeys}
      selectedKeys={selectedKeys}
      items={menuData}
      onSelect={item => onSelect(item.key)}
      onOpenChange={_keys => {
        setOpenKeys(_keys)
      }}
    />
  )
})

const convertMenuItems = (items: MenuDataItemType[], parent?: MenuDataItemType): MenuItemType[] => {
  return items.map(item => {
    const { permissions, children, key, ...rest } = item
    const parentKeys = parent ? [parent.key, ...(parent.parentKeys || [])] : []
    return {
      ...rest,
      key,
      parentKeys,
      children: children ? convertMenuItems(children, { ...item, parentKeys }) : []
    }
  })
}
