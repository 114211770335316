import { Upload as AntUpload, message, Spin } from 'antd'
import React, { PropsWithChildren } from 'react'
import translations from '../../translations'
import { UploadProps } from './props'

export const Upload: React.FC<PropsWithChildren<UploadProps>> = props => {
  const {
    fileList,
    children,
    disabled = false,
    loading = false,
    acceptedExtensions = '.pdf, .png, .jpg, .jpeg',
    onChange
  } = props

  if (loading) return <Spin />

  return (
    <AntUpload
      disabled={disabled}
      accept={acceptedExtensions}
      listType='picture'
      beforeUpload={_ => false}
      onChange={({ fileList: fs, file }) => {
        const uploadedFile = fs && fs.length > 0 ? fs[fs.length - 1] : null

        if (!uploadedFile) {
          onChange(fs, file)
          return
        }

        const isLessThan10Mb = uploadedFile.size / 1024 / 1024 < 10
        if (!isLessThan10Mb) {
          message.error(translations().errors.moreThan10Mb)
          return
        }

        onChange(fs, file)
      }}
      fileList={fileList}
      onPreview={() => false}
    >
      {children}
    </AntUpload>
  )
}
