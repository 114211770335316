import React, { useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Form, Typography, Switch, Input } from 'antd'
import { UserSecurityProps } from './props'

import translations from './translations'
import styles from './styles.scss'

export const UserSecurity = observer(({ form, status = 'DISABLED' }: UserSecurityProps) => {
  const isTwoFADisabled = status === 'DISABLED'
  const isTwoFAEnforced = status === 'ENFORCED'
  const isTwoFAEnabled = status === 'ENABLED'

  const currentTwoFAEnabled = Form.useWatch('twoFAEnabled', form)

  useEffect(() => {
    form?.setFieldsValue({ twoFAEnabled: !isTwoFADisabled })
  }, [])

  const switchLabel = useMemo(() => {
    if (isTwoFAEnabled) return translations().enabled
    if (isTwoFAEnforced || isTwoFADisabled) return translations().enforced
    return translations().enabled
  }, [status])

  const shouldShowReason = !isTwoFADisabled && !currentTwoFAEnabled

  return (
    <>
      <Typography.Title className={styles.title}>{translations().security}</Typography.Title>

      <Typography.Title className={styles.subTitle}>{translations().twoFA}</Typography.Title>

      {isTwoFADisabled && <div className={styles.enforceTitle}>{translations().enforceTitle}</div>}

      <Form.Item
        name='twoFAEnabled'
        valuePropName='checked'
        className={classNames({
          [styles.formItem]: shouldShowReason
        })}
      >
        <Switch className={styles.switch} checkedChildren={switchLabel} unCheckedChildren={translations().disabled} />
      </Form.Item>

      {shouldShowReason && (
        <>
          <Typography.Text className={styles.disableTwoFAMessage}>{translations().disableWarning}</Typography.Text>

          <Form.Item name='reason' rules={[{ required: true, message: translations().formErrors.reasonRequired }]}>
            <Input placeholder={translations().reasonPlaceholder} />
          </Form.Item>
        </>
      )}
    </>
  )
})
