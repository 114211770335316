import React from 'react'
import classNames from 'classnames'
import { LoginBannerWebProps } from '~/code/pages/Notifications/ManageNotificationTemplates/components/WebNotificationPreview/components/LoginBannerWeb/props'
import styles from './styles.scss'

export const LoginBannerWeb: React.FC<LoginBannerWebProps> = ({ className, notificationSetupForm }) => {
  const { notificationLevelId, title, description } = notificationSetupForm

  const renderNotification = () => {
    if (!notificationLevelId) return null

    return (
      <div className={classNames(styles.notificationContainer, styles[notificationLevelId])}>
        <span className={styles.title}>{title}</span>
        <div className={styles.description}>{description}</div>
      </div>
    )
  }

  return (
    <div className={styles.previewWrapper}>
      <div className={styles.header}>
        <div className={classNames(styles.bar, styles[className])}></div>
        <div className={classNames(styles.circle, styles.yellow)}></div>
      </div>
      <div className={styles.loginFormWrapper}>
        {Array.from({ length: 3 }).map((_, index) => (
          <div key={index} className={classNames(styles.bar, styles[className])}></div>
        ))}
        <div className={styles.input}></div>
        <div className={styles.loginBtnWrapper}>
          <div className={classNames(styles.bar, styles[className])}></div>
          <div className={classNames(styles.input, styles.yellow)}></div>
        </div>
        {renderNotification()}
      </div>
    </div>
  )
}