import React from 'react'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Menu, MenuProps } from 'antd'
import { Routes } from '~/code/startup/Router/Routes'
import { goToRoute } from '~/code/startup/Router/utils'
import { WithAppStoreProps } from '~/code/models'
import { withAppStore } from '~/code/hocs/withAppStore'
import translations from './translations'
import styles from './styles.scss'

const HeaderAvatarView: React.FC<WithAppStoreProps> = ({ appStore }) => {
  const email = appStore.authStore.email
  const handleMenuSelect = (object: { key: string }) => {
    switch (object.key) {
      case 'logout':
        appStore.authStore.logout()
        break
      case 'profile':
        goToRoute(Routes.PROFILE)
        break
    }
  }

  const items: MenuProps['items'] = [
    {
      key: 'email',
      label: <span className={styles.email}>{email}</span>
    },
    {
      type: 'divider'
    },
    {
      key: 'profile',
      icon: <UserOutlined />,
      label: translations().profile
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: translations().logout
    }
  ]

  return (
    <Dropdown menu={{ items, onClick: handleMenuSelect }} trigger={['click']}>
      <Avatar size='small' icon={<UserOutlined />} className={styles.avatar} />
    </Dropdown>
  )
}

export const HeaderAvatar = withAppStore(HeaderAvatarView)
