import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  startDate: string
  merchant: string
  status: string
  assignedGroup: string
  assignedUser: string
  reviewTime: string
  endDate: string
  mid: string
  contractNumber: string
  drawerTitle: string
  bankStatement: string
  other: string
  initiator: string
  comment: string
}>()
