import React from 'react'
import translations from './translations'


translations.add('en', {
    title: 'Title',
    titleRequired: 'title is required',
    name: 'First name',
    nameRequired: 'first name is required',
    dateBirth: 'Date of birth',
    dateBirthRequired: 'date of birth is required',
    dateBirthInvalid: 'invalid date of birth',
    dateBirthAgeLimit: 'age must be between 18 and 100',
    gender: 'Gender',
    genderRequired: 'gender is required',
    middleName: 'Middle name',
    middleNameRequired: 'middle name is required',
    surname: 'Surname',
    surnameRequired: 'surname is required',
    houseName: 'House name',
    houseNumber: 'House number',
    addressTitle: 'Residential address',
    street: 'Address line 1',
    streetRequired: 'address line 1 is required',
    street2: 'Address line 2',
    city: 'Town/City',
    cityRequired: 'town/city is required',
    cityInvalid: 'only alphanumeric characters are allowed',
    region: 'County',
    country: 'Country',
    countryRequired: 'country is required',
    countryResidence: 'Country of residence',
    countryResidenceRequired: 'country of residence is required',
    postCode: 'Postcode',
    postCodeRequired: 'postcode is required',
    postCodeInvalid: 'invalid postcode',
    nationality: 'Nationality',
    nationalityRequired: 'nationality is required',
    emailAddress: 'Email address',
    emailAddressRequired: 'email address is required',
    emailFormatIncorrect: 'invalid email',
    telephoneNumber: 'Phone number',
    telephoneNumberRequired: 'phone number is required',
    telephoneNumberWrongFormat: 'invalid phone number',
    occupation: 'Occupation',
    occupationRequired: 'occupation is required',
    placeBirth : 'Place of birth',
    placeBirthRequired: 'place of birth is required',
    personalInfo: 'Personal info',
    contactInfo: 'Contact info',
    submit: 'Submit',
    back: 'Back'
})
