import React from 'react'
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import numeral from 'numeral'
import { IAnalyticsDetailedViewStore } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsDetailedView/models/IAnalyticsDetailedViewStore'
import { TableFilterStore } from '~/code/stores/TableFilterStore'
import { TabData } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsDetailedView/models/TabData'
import { IAcquiringFilterStore } from '~/code/pages/Acquiring/components/AcquiringFilter'
import {
  fetchPosAcquisitionChannelsSummary,
  fetchPosCardTypesSummary,
  fetchPosIssuingBanksSummary,
  fetchPosMccSummary,
  fetchPosMerchantsSummary
} from '~/code/services/fetchers'
import { error as errorLog } from 'dna-common'
import {
  capitalize,
  generateAmountTableColumns,
  generateCountTableColumns,
  generateMCCAmountTableColumns,
  generateMCCCountTableColumns,
  getRequestUniqueFieldName
} from '~/code/stores/AnalyticsStore/services/utils'
import {
  posDetailsDataToTabData,
  posGenerateDetailsObject,
  posResetCurrentPageInDetailsData,
  posResetDataHasBeenLoadedFlags
} from '~/code/stores/AnalyticsStore/services/posUtils'
import translations from './translations'
import { PosDetailsType } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsDetailedView/models/PosDetailsType'
import { AnalyticsPosDetailedSummaryRequest } from '~/code/models/analytics/AnalyticsPosDetailedSummaryRequest'
import {
  AnalyticsPosDetailedSummaryResponse,
  StatusSummaryPos
} from '~/code/models/analytics/AnalyticsPosSummaryResponse'
import { isPartner } from '~/code/services/auth'
import { GlobalConfigStore } from '../GlobalConfigStore'
import { Routes } from '~/code/startup/Router/Routes'

export class AnalyticsPosDetailedViewStore implements IAnalyticsDetailedViewStore {
  tableFilterStore: TableFilterStore<any> = null
  filterStore: IAcquiringFilterStore = null
  configStore: GlobalConfigStore = null

  constructor(filterStore: IAcquiringFilterStore, configStore: GlobalConfigStore) {
    this.filterStore = filterStore
    this.configStore = configStore
    this.tableFilterStore = new TableFilterStore(null) // TODO add fetch function

    makeObservable(this, {
      currentTab: observable,

      currentMerchantsCATab: observable,
      currentMccCATab: observable,
      currentIssuingBanksCATab: observable,
      currentCardTypesCATab: observable,
      forceRefresh: observable,

      tabsData: computed,
      amountTableColumns: computed,
      countTableColumns: computed,
      merchantName: computed,
      merchantId: computed,
      acquisitionChannelId: computed,
      currency: computed,

      merchantsDetails: observable,
      mccDetails: observable,
      issuingBanksDetails: observable,
      cardTypesDetails: observable,
      acquisitionChannelsDetails: observable,

      refreshTable: action.bound,

      loadData: action.bound,
      loadBreakdown: action,
      setShouldLoadData: action,
      onTabChange: action,
      onCountAmountTabChange: action,
      onStatusTabChange: action,
      onPageSizeChange: action,
      setDetailsLoadingField: action,
      setDetailsDataField: action
    })
  }

  merchantsDetails = posGenerateDetailsObject()
  mccDetails = posGenerateDetailsObject()
  issuingBanksDetails = posGenerateDetailsObject()
  cardTypesDetails = posGenerateDetailsObject()
  acquisitionChannelsDetails = posGenerateDetailsObject()

  shouldLoadData: boolean = false
  forceRefresh: boolean = false
  currentTab: PosDetailsType = 'merchants'

  currentMerchantsCATab: 'amount' | 'count' = 'amount'
  currentMerchantsStatusTab: 'all' | 'success' | 'failed' = 'all'

  currentMccCATab: 'amount' | 'count' = 'amount'
  currentMccStatusTab: 'all' | 'success' | 'failed' = 'all'

  currentIssuingBanksCATab: 'amount' | 'count' = 'amount'
  currentIssuingBanksStatusTab: 'all' | 'success' | 'failed' = 'all'

  currentCardTypesCATab: 'amount' | 'count' = 'amount'
  currentCardTypesStatusTab: 'all' | 'success' | 'failed' = 'all'

  currentAcquisitionChannelsCATab: 'amount' | 'count' = 'amount'
  currentAcquisitionChannelsStatusTab: 'all' | 'successful' | 'failed' = 'all'

  get amountTableColumns() {
    switch (this.currentTab) {
      case 'mcc':
        return generateMCCAmountTableColumns()
      default:
        return generateAmountTableColumns()
    }
  }
  get countTableColumns() {
    switch (this.currentTab) {
      case 'mcc':
        return generateMCCCountTableColumns()
      default:
        return generateCountTableColumns()
    }
  }

  public init() {
    reaction(
      () => {
        const {
          dateStore: { startDate, endDate },
          merchantTradeName,
          acquisitionChannels,
          acquisitionChannelId,
          storeAndTerminalFilterStore: { store, terminal }
        } = this.filterStore
        return {
          startDate,
          endDate,
          merchantTradeName,
          acquisitionChannels,
          acquisitionChannelId,
          store,
          terminal,
          currency: this.currency
        }
      },
      (curr, prev) => {
        // before store and terminals loaded, do not make requests to backend
        if (this.filterStore.storeAndTerminalFilterStore.terminalLoadingStatus === 'loading') return

        // if user is not on current page, do not make requests to backend on currency change
        if (window.location.pathname !== Routes.TRANSACTIONS_DNA_ACQUIRING_ANALYTICS_POS) return

        const {
          startDate,
          endDate,
          merchantTradeName,
          acquisitionChannels,
          acquisitionChannelId,
          store,
          terminal,
          currency
        } = curr
        if (
          startDate &&
          endDate &&
          merchantTradeName &&
          (!acquisitionChannels?.length || acquisitionChannelId) &&
          store &&
          terminal &&
          this.shouldLoadData &&
          currency
        ) {
          // reset data has been loaded flags
          this.merchantsDetails = posResetDataHasBeenLoadedFlags(this.merchantsDetails)
          this.mccDetails = posResetDataHasBeenLoadedFlags(this.mccDetails)
          this.issuingBanksDetails = posResetDataHasBeenLoadedFlags(this.issuingBanksDetails)
          this.cardTypesDetails = posResetDataHasBeenLoadedFlags(this.cardTypesDetails)
          this.acquisitionChannelsDetails = posResetDataHasBeenLoadedFlags(this.acquisitionChannelsDetails)

          // reset all table current page props
          this.merchantsDetails = posResetCurrentPageInDetailsData(this.merchantsDetails)
          this.mccDetails = posResetCurrentPageInDetailsData(this.mccDetails)
          this.issuingBanksDetails = posResetCurrentPageInDetailsData(this.issuingBanksDetails)
          this.cardTypesDetails = posResetCurrentPageInDetailsData(this.cardTypesDetails)
          this.acquisitionChannelsDetails = posResetCurrentPageInDetailsData(this.acquisitionChannelsDetails)
          this.loadData()
        }
      },
      { delay: 5, fireImmediately: true }
    )
  }

  public refreshTable() {
    this.forceRefresh = true
    this.setShouldLoadData(true)
  }

  // TODO find an elegant solution for loading data on radio button change or RENAME
  setShouldLoadData = (value: boolean) => {
    this.shouldLoadData = value
    if (value) {
      this.loadData()
    }
  }

  get currency() {
    return this.configStore.currency.type
  }

  get summaryRequestParams(): AnalyticsPosDetailedSummaryRequest {
    const {
      dateStore: { startDate, endDate },
      storeAndTerminalFilterStore: { terminalsForFilter }
    } = this.filterStore
    return {
      from: startDate.format(),
      to: endDate.format(),
      merchantId: this.merchantId,
      currency: this.currency,
      acquisitionChannel: this.acquisitionChannelId,
      terminalId: terminalsForFilter.length > 0 ? terminalsForFilter : undefined
    }
  }

  get merchantName() {
    return this.filterStore.merchantTradeName
  }

  get merchantId() {
    return this.filterStore.merchantId
  }

  get acquisitionChannelId() {
    if (isPartner() || this.filterStore.acquisitionChannelId === 'all') return undefined
    return this.filterStore.acquisitionChannelId
  }

  get fetchDataRequest() {
    switch (this.currentTab) {
      case 'merchants': {
        return fetchPosMerchantsSummary
      }
      case 'mcc': {
        return fetchPosMccSummary
      }
      case 'issuingBanks': {
        return fetchPosIssuingBanksSummary
      }
      case 'cardTypes': {
        return fetchPosCardTypesSummary
      }
      case 'acquisitionChannels': {
        return fetchPosAcquisitionChannelsSummary
      }
    }
  }

  /**
   * The main data fetcher function. The function first checks what tab is currently active, then it checks which
   * tab of the amount/count tab pane is active. Then if the new data has not been fetched for the given
   * tabs combination, the function fetches the data
   */
  loadData = async () => {
    if (this.merchantName !== 'ALL' && !this.merchantId) return
    const detailsType = this.currentTab
    const upperCaseDetailsType = capitalize(detailsType)
    const orderBy = this['current' + upperCaseDetailsType + 'CATab']
    const orderByUpperCase = capitalize(orderBy)
    const status = this['current' + upperCaseDetailsType + 'StatusTab']
    const statusUpperCase = capitalize(status)
    const dataFieldName = orderBy + statusUpperCase + 'Data'
    const totalFieldName = orderBy + statusUpperCase + 'Total'
    const loadingFieldName = 'isLoading' + orderByUpperCase + statusUpperCase + 'Data'
    const page = this[this.currentTab + 'Details'][orderBy + statusUpperCase + 'CurrentPage']
    const size = this[this.currentTab + 'Details'][orderBy + statusUpperCase + 'PageSize']
    const requestUniqueFieldName = getRequestUniqueFieldName(this.currentTab)

    const hasLoadedData = this[detailsType + 'Details']['hasLoaded' + orderByUpperCase + statusUpperCase + 'Data']
    const setDataFunction = this.setDetailsDataField

    if (this.forceRefresh || !hasLoadedData) {
      this.loadBreakdown(
        { ...this.summaryRequestParams, orderBy, page, size, status },
        requestUniqueFieldName,
        data => {
          setDataFunction(data, detailsType, dataFieldName, totalFieldName)
        },
        loading => {
          this.setDetailsLoadingField(loading, detailsType, loadingFieldName)
        },
        this.fetchDataRequest
      )
    }

    return
  }

  ////////// COMMON LOAD BREAKDOWN DATA ////////////
  loadBreakdown = async (
    params: AnalyticsPosDetailedSummaryRequest,
    uniqueFieldName,
    setData,
    setLoadingData,
    fetchFunction
  ) => {
    try {
      setLoadingData(true)
      const { err, result } = await fetchFunction(params)
      if (err) {
        errorLog(`Error loading ${uniqueFieldName} ${params.orderBy} data:`, err)
        runInAction(() => {
          setLoadingData(false)
        })
        return
      }

      runInAction(() => {
        const data = this.processDataMethod(
          uniqueFieldName === 'acquisitionChannel' ? this.modifyResult(result) : result,
          params.orderBy,
          params.page,
          params.size
        )

        setData(data)
        setLoadingData(false)
      })
    } catch (err) {
      errorLog(`Error loading ${uniqueFieldName} ${params.orderBy} data`, err)
      runInAction(() => {
        setLoadingData(false)
      })
      return
    } finally {
      runInAction(() => (this.forceRefresh = false))
    }
  }

  onTabChange = tab => {
    this.currentTab = tab
    this.loadData()
  }

  /**
   * A callback that is triggered when the amount/count tab is changed
   * We need to fetch data if any of the filter items is changed
   * @param tab - amount/count tab
   */
  onCountAmountTabChange = (tab: 'amount' | 'count') => {
    switch (this.currentTab) {
      case 'merchants': {
        this.currentMerchantsCATab = tab
        break
      }
      case 'mcc': {
        this.currentMccCATab = tab
        break
      }
      case 'cardTypes': {
        this.currentCardTypesCATab = tab
        break
      }
      case 'issuingBanks': {
        this.currentIssuingBanksCATab = tab
        break
      }
      case 'acquisitionChannels': {
        this.currentAcquisitionChannelsCATab = tab
        break
      }
    }
    this.loadData()
  }

  /**
   * A callback that is triggered when the status tab is changed
   * We need to fetch data if any of the filter items is changed
   * @param tab - selected status tab
   */
  onStatusTabChange = (tab: 'all' | 'success' | 'failed') => {
    const detailsTypeUpperCase = capitalize(this.currentTab)

    this['current' + detailsTypeUpperCase + 'StatusTab'] = tab
    this.loadData()
  }

  onPageSizeChange = (page: number, size: number) => {
    const detailsTypeUpperCase = capitalize(this.currentTab)
    const orderBy = this['current' + detailsTypeUpperCase + 'CATab']
    const orderByUpperCase = capitalize(orderBy)
    const status = this['current' + detailsTypeUpperCase + 'StatusTab']
    const statusUpperCase = capitalize(status)

    const previousPageSize = this[this.currentTab + 'Details'][orderBy + statusUpperCase + 'PageSize']
    let _page = page
    if (previousPageSize !== size) {
      _page = page
    }
    this[this.currentTab + 'Details'] = {
      ...this[this.currentTab + 'Details'],
      [orderBy + statusUpperCase + 'CurrentPage']: _page,
      [orderBy + statusUpperCase + 'PageSize']: size,
      ['hasLoaded' + orderByUpperCase + statusUpperCase + 'Data']: false
    }
    this.loadData()
  }

  setDetailsLoadingField = (loading, detailsType: DetailsType, fieldName) => {
    this[detailsType + 'Details'] = {
      ...this[detailsType + 'Details'], // ex: merchantsDetails
      [fieldName]: loading // ex: isLoadingAmountAllData
    }
  }

  setDetailsDataField = (data, detailsType: DetailsType, dataFieldName, totalFieldName) => {
    this[detailsType + 'Details'] = {
      ...this[detailsType + 'Details'], // ex: merchantsDetails
      [dataFieldName]: data.data, // ex: amountAllData
      [totalFieldName]: data.total, // ex: amountAllTotal
      ['hasLoaded' + capitalize(dataFieldName)]: true // ex: hasLoadedAmountAllData
    }
  }

  processData = (
    result: AnalyticsPosDetailedSummaryResponse,
    countAmount: string,
    currentPage?: number,
    pageSize?: number
  ) => {
    return {
      data: result.data.map((item, index) => {
        return {
          order: index + 1 + (currentPage - 1) * pageSize,
          key: index,
          average: item.count ? numeral(item.amount / item.count).format('0,0.00') : 0,
          name: item.value || translations().noData,
          [countAmount]: countAmount === 'amount' ? numeral(item[countAmount]).format('0,0.00') : item[countAmount]
        }
      }),
      total: result.totalCount
    }
  }

  processMCCData = (
    result: AnalyticsPosDetailedSummaryResponse,
    countAmount: string,
    currentPage?: number,
    pageSize?: number
  ) => {
    return {
      data: result.data.map((item, index) => {
        return {
          order: index + 1 + (currentPage - 1) * pageSize,
          key: index,
          average: item.count ? numeral(item.amount / item.count).format('0,0.00') : 0,
          mcc: item.value || translations().noData,
          name: item.description || translations().noData,
          [countAmount]: countAmount === 'amount' ? numeral(item[countAmount]).format('0,0.00') : item[countAmount]
        }
      }),
      total: result.totalCount
    }
  }

  get processDataMethod() {
    switch (this.currentTab) {
      case 'mcc':
        return this.processMCCData
      default:
        return this.processData
    }
  }

  modifyResult(result: AnalyticsPosDetailedSummaryResponse): AnalyticsPosDetailedSummaryResponse {
    return {
      totalCount: result.totalCount,
      data: result.data.map(item => this.processItem(item))
    }
  }

  processItem(item: StatusSummaryPos) {
    const acquisitionChannelId = item.value
    const acquisitionChannel = this.filterStore.acquisitionChannels.find(a => a.id === acquisitionChannelId)
    if (acquisitionChannel) {
      return {
        ...item,
        value: acquisitionChannel.name
      }
    }

    return item
  }

  generateTabsData = () => {
    const tabsData = [
      posDetailsDataToTabData('merchants', this.merchantsDetails),
      posDetailsDataToTabData('mcc', this.mccDetails),
      posDetailsDataToTabData('issuingBanks', this.issuingBanksDetails),
      posDetailsDataToTabData('cardTypes', this.cardTypesDetails)
    ]
    if (!isPartner()) tabsData.push(posDetailsDataToTabData('acquisitionChannels', this.acquisitionChannelsDetails))
    return tabsData
  }

  get tabsData(): TabData[] {
    return this.generateTabsData()
  }
}
