import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { isEmpty } from 'dna-common'
import { CustomButton } from '~/code/components'
import { DeleteRedOutlined, EditOutlinedBlue } from '~/assets/icons'
import { PosFieldsForm } from '../PosFieldsForm'
import { PosProductsListPropsType } from './props'
import translations from './translations'
import styles from './PosProductsList.scss'

export const PosProductsList: React.FC<PosProductsListPropsType> = props => {
  const [form] = Form.useForm()
  const { product, onRemove, key, store } = props

  const [editEnabled, setEditEnabled] = useState(false)
  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <b>{translations().configurePos}</b>
        </Col>
        <Col className={styles.rightAlign} span={12}>
          <Button type='text' icon={<DeleteRedOutlined />} onClick={() => onRemove(product.id)} />
          <Button type='text' icon={<EditOutlinedBlue />} onClick={() => setEditEnabled(true)} />
        </Col>
      </Row>
      <Form form={form} disabled={!editEnabled} layout='vertical' initialValues={product}>
        <PosFieldsForm
          form={form}
          isPaytek={store.productStore.isPaytek}
          posModels={store.posModels}
          isPosModelsLoading={store.isPosModelsLoading}
          getBundlesForSelectedPos={store.getBundlesForSelectedPos}
          bundleOptions={store.bundleOptions}
          isBundlesLoading={store.isPosBundlesLoading}
          handleChargeTypeChange={store.productStore.handleChargeTypeChange}
          companyProfile={store.dataStore.application?.companyProfile}
          onboardingSettings={store.onboardingSettings}
          posDeliveryAddress={store.productStore.productDeliveryAddress}
          posDeliveryAddressType={store.productStore.posDeliveryType}
          showDeliveryInfo={isEmpty(store.productStore.productDeliveryAddress)}
          posDeliveryInstructions={store.productStore.productDeliveryInstructions}
          productItem={store.productStore.products?.find(p => p.type === 'pos')}
          subscriptionFee={store.subscriptionPriceFeeForSelectedPos}
          aliPayEnabled={store.aliPayEnabled}
          editMode
          editEnabled={editEnabled}
          isISO={store.isISO}
          isSME={store.isSME}
        />
        {editEnabled && (
          <CustomButton
            className={styles.mt15}
            type='primary'
            onClick={async () => {
              await form.validateFields()
              await store.productStore.saveEditingProduct(form.getFieldsValue(), product.id)
              setEditEnabled(false)
            }}
          >
            {translations().save}
          </CustomButton>
        )}
      </Form>
    </>
  )
}
