import { Collapse, Descriptions, Divider, Table } from 'antd'
import React from 'react'
import translations from './translations'
import { JumioCheckResultsProps } from './props'
import { jumioDocsColumns } from './services/generators'
import styles from './JumioCheckResults.scss'
import { isEmpty } from 'dna-common'
import { jumioResultColumns } from './constants'

export const JumioCheckResults = ({ data, loadDocumentsData }: JumioCheckResultsProps) => {
  return (
    <Collapse>
      {data?.map((item, indx) => (
        <Collapse.Panel header={`${translations().id}: ${item.processId}`} key={indx}>
          <div className={styles.centeredDiv}>
            <Descriptions>
              <Descriptions.Item label={translations().personName}>{`${
                item?.requestData?.userCheckRequest?.firstName || ''
              } ${item?.requestData?.userCheckRequest?.lastName || ''} ${
                item?.requestData?.userCheckRequest?.middleName || ''
              }`}</Descriptions.Item>
            </Descriptions>
          </div>
          {!isEmpty(item?.verification) && (
            <Table
              pagination={false}
              columns={jumioResultColumns}
              dataSource={Object.entries(item?.verification).map(v => ({
                name: v[0],
                result: v[1] ? translations().pass : translations().fail
              }))}
            />
          )}
          <Divider />
          <div className={styles.centeredDiv}>
            <p>{translations().files}</p>
          </div>
          <Table
            pagination={false}
            columns={jumioDocsColumns(loadDocumentsData)}
            dataSource={item?.responseData?.saveWorkflowImagesResponse}
          />
        </Collapse.Panel>
      ))}
    </Collapse>
  )
}
