import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    title: string
    moneyBlockTitle: string
    accountBlockTitle: string
    companyNumberLabel: string
    expectedAnnualTurnoverLabel: string
    expectedAnnualTurnoverRequired: string
    expectedAnnualTurnoverCustomLabel: string
    expectedAnnualTurnoverCustomRequired: string
    natureOfAccountTransactionsSubLabel: string
    natureOfAccountTransactionsRequired: string
    natureOfAccountTransactionsPlaceholder: string
    expectedMonthlyTransactionValuesLabel: string
    expectedMonthlyTransactionValuesRequired: string
    expectedMonthlyTransactionValuesExceed: string
    averageTransactionValueLabel: string
    averageTransactionValueRequired: string
    tradeOutsideEeaLabel: string
    tradeOutsideEeaRequired: string
    tradeOutsideEeaOptions: {
        yes: string
        no: string
    }
    tradeOutsideEeaTooltip: string
    tradingCountriesOutsideEeaLabel: string
    tradingCountriesOutsideEeaRequired: string
    tradingCountriesOutsideEeaTooltip: string
    emailAddressLabel: string
    emailAddressRequired: string
    emailFormatIncorrect: string
    telephoneNumberLabel: string
    telephoneNumberRequired: string
    telephoneNumberWrongFormat: string
    accountType: string,
    accountTypes: {
        business: string
        personal: string
    },
    accountTypeRequired: string,
    bankAccountNumberLabel: string
    bankAccountNumberRequired: string
    bankAccountNumberInvalid: string
    sortCodeLabel: string
    sortCodeRequired: string
    sortCodeInvalid: string
    accountNameLabel: string
    accountNamePlaceholder: string
    accountNameTooltip: string
    accountNameRequired: string
    bankNameLabel: string
    tradingAddressTitle: string
    sameAsCompanyAddress: string
    tradingAddress: {
        cityLabel: string
        cityRequired: string
        cityInvalid: string
        countryLabel: string
        countryRequired: string
        regionLabel: string
        postalCodeLabel: string
        postalCodeRequired: string
        postalCodeInvalid: string
        addressLine1Label: string
        addressLine1Required: string
        addressLine2Label: string
        countryTooltip: string
    }
    face2faceSelectOptions: {
        withinHours: string
        withinOneDay: string
        withinOneWeek: string
        withinOneMonth: string
    }
    natureOfAccountTransactionsOptions: {
        cash: string
        cheque: string
        directDebit: string
        international: string
        onlinePayments: string
        standingOrder: string
        faceToFaceCardPayments: string
    }
    validate: string
    seasonalBusiness: {
        isTrueLabel: string
        isTrueRequired: string
        yes: string
        no: string
        qLabel: string
        q1Label: string
        q1Required: string
        q2Label: string
        q2Required: string
        q3Label: string
        q3Required: string
        q4Label: string
        q4Required: string
        totalShouldBe100: string
    }
    businessModelQuestions: {
        yes: string
        no: string
        isTrueLabel: string
        deliveryTimeLabel: string
        deliveryTimeRequired: string
        courierServiceUsedLabel: string
    }
    validateBankAccount: string

    annualTurnover: string
    annualCardTurnover: string
    monthTransactions: string
    highestTransaction: string
    averageTransaction: string
    required: string
    pos: string
    moto: string
    ecom: string
    payByLink: string
    payByApp: string
    virtualTerminal: string
    nbsp: string
    deposits: string
    balances: string
    fullPayment: string
    other: string
    totalSum: string
    digitsOnly: string
    range: string
    enterDetails: string
    onlyPositive: string
    selectTerminal: string
    turnoverMethod: string
    processingMethod: string
    cardTurnoverPercent: string
    ifMerchantNew: string
    howFar: string
    cardTurnover: string
    days: string
    haveComments: string
    comment: string
    requestMoto: string
    requestVt: string
    notAvailable: string
    clear: string
    questionnaire: string
    incorrectValue: string
    greaterThanZero: string
}>()

