import React from 'react'
import { Button, List, Popover, Tooltip } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { EntityItemProps } from './props'
import translations from './translations'
import styles from './EntityItem.scss'

export const EntityItem = (props: EntityItemProps) => {
  const { onClick, additionalInfo } = props

  const renderName = () => {
    const { name } = props
    const alias = name ? name.charAt(0) : ''
    return (
      <>
        <div className={styles.alias}>{alias}</div>
        <div className={styles.name}>{name}</div>
      </>
    )
  }

  return (
    <>
      <List.Item
        actions={[
          <Tooltip title={translations().view}>
            <Button type='text' icon={<EyeOutlined />} onClick={onClick} />
          </Tooltip>
        ]}
      >
        <div className={styles.contentContainer}>{renderName()}</div>
      </List.Item>
      <div className={styles.container}>{additionalInfo}</div>
    </>
  )
}
