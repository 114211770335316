export const PaymentMethods = {
    paypal: 'PayPal',
    card: 'Card',
    paybybankapp: 'Pay by Bank app',
    ecospend: 'Open Banking',
    applepay: 'Apple Pay',
    googlepay: 'Google Pay',
    klarna: 'Klarna',
    astropay:  'AstroPay',
    clicktopay: 'Click to Pay'
}
