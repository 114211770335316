import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Typography, Button, Input } from 'antd'
import { AuthForm } from '~/code/components/auth/AuthForm'
import { RecoveryCodeProps } from './props'

import translations from './translations'
import styles from './styles.scss'

const { Link } = Typography

const RECOVERY_CODE_LENGTH = 12

const RecoveryCode = observer(({ store }: RecoveryCodeProps) => {
  const { updateTokenTwoFA, confirmTwoFA, backToLogin } = store
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const source = new URLSearchParams(window.location.search).get('source')
  const isResetPassword = source === 'resetPassword'

  const onFinish = async ({ recoveryCode }) => {
    setIsLoading(true)

    if (isResetPassword) {
      await confirmTwoFA(recoveryCode, 'recovery_code')
    } else {
      await updateTokenTwoFA(recoveryCode, 'recovery_code')
    }
    setIsLoading(false)
  }

  return (
    <>
      <AuthForm title={translations().title}>
        <div className={styles.back}>
          <Link onClick={backToLogin}>{translations().back}</Link>
        </div>
        <Typography.Text>{translations().text(RECOVERY_CODE_LENGTH)}</Typography.Text>

        <Form layout='vertical' requiredMark={false} className={styles.form} onFinish={onFinish}>
          <Form.Item
            name='recoveryCode'
            rules={[
              {
                required: true,
                message: translations().recoveryCodeRequired
              },

              {
                validator: async (_, value) => {
                  if (value && value.length !== RECOVERY_CODE_LENGTH)
                    throw new Error(translations().recoveryCodeInvalid)
                }
              }
            ]}
          >
            <Input placeholder={translations().recoveryCodePlaceholder} maxLength={RECOVERY_CODE_LENGTH} />
          </Form.Item>
          <Form.Item className={styles.submitHolder}>
            <Button type={'primary'} htmlType={'submit'} className={styles.submit} disabled={isLoading}>
              {translations().submit}
            </Button>
          </Form.Item>
        </Form>

        <div className={styles.linkHolder}>
          <Link href='mailto:support@dnapaymentsgroup.com'>{translations().dontHaveRecoveryCode}</Link>
        </div>
      </AuthForm>
    </>
  )
})

export default RecoveryCode
