import React from 'react'
import page from 'page'
import { action, makeObservable, observable } from 'mobx'
import { error, IdleTimer } from 'dna-common'
import { AppStore } from '~/code/stores/AppStore'
import { MainLayout, AuthLayout } from '~/code/layouts'
import {
  NotFound,
  NoPermission,
  Processes as OnboardingProcesses,
  Process as OnboardingProcess,
  PartnerProcesses as OnboardingPartnerProcesses,
  BpmAnalytics as OnboardingAnalytics,
  SubmittedRequests as RiskLimitsSubmittedRequests,
  MotoVtRequests,
  StartApplication,
  SavedApplications,
  MerchantsManagement,
  ChangeOfSettlementsPeriodRequests,
  SettlementsStatement,
  Statistics,
  StartProcess,
  TerminalIssueRequests,
  ProcessesAdditionalStore,
  AdjustmentRequests,
  EditTariffsRequests,
  Exports,
  Login
} from '~/code/pages'
import { IRouterParentStore, IRouterStore } from '~/code/startup'
import { MainLayoutStore } from '~/code/stores/MainLayoutStore'
import { LoginRouteQueryModel } from '~/code/models/route'
import translations from '~/code/translations'
import { TotalAnalytics } from '~/code/pages/Acquiring/pages/TotalAnalytics'
import { Analytics } from '~/code/pages/Acquiring/pages/Analytics'
import { MonitoringStoreSymbol, Statement } from '~/code/pages/Acquiring/pages/Statement'
import { ResetPassword } from '~/code/pages/auth/ResetPassword'
import { SetNewPassword } from '~/code/pages/auth/SetNewPassword'
import { ConfirmEmail } from '~/code/pages/auth/ConfirmEmail'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { getHomePageRoute, hasPermissions } from '~/code/services/auth'
import { GlobalSettings, MerchantSettings } from '../pages/TransactionChannels/pages'
import { resetPassword } from '~/code/services/auth-requests'
import { ResetPasswordFailure } from '~/code/pages/auth/ResetPasswordFailure'
import { Routes } from '~/code/startup/Router/Routes'
import { goToRoute, replaceRoute } from '~/code/startup/Router/utils'
import { SettlementsPayments } from '~/code/pages/settlements/SettlementsPayments'
import { ChangeBankDetailsDossierV2 } from '~/code/pages/BPMProcesses/Requests/components/ChangeBankDetailsDossierV2'
import { ClosureOfMerchant } from '~/code/pages/BPMProcesses/Requests/components/ClosureOfMerchant'
import { ClosureOfTerminal } from '~/code/pages/BPMProcesses/Requests/components/ClosureOfTerminal'
import { PortalAccess } from '~/code/pages/PortalAccess'
import { PartnerAccess } from '~/code/pages/PartnerAccess'
import Profile from '~/code/pages/Profile'
import RecoveryCode from '~/code/pages/RecoveryCode'
import TwoFA from '~/code/pages/TwoFA'
import TwoFAEnforced from '~/code/pages/TwoFAEnforced'
import TwoFAConfirm from '~/code/pages/TwoFAConfirm'

import { Chargebacks } from '~/code/pages/Chargebacks'
import { ChangeOfSettlementKind } from '~/code/pages/BPMProcesses/Requests/components/ChangeOfSettlementKind'
import {
  SETTLEMENT_STATEMENTS_STORE_TYPES,
  SETTLEMENT_STATEMENTS_TEST_STORE_TYPES
} from '~/code/pages/settlements/SettlementsStatement/symbols'
import { Teammates } from '~/code/pages/Teammates'
import { ReportType } from '../pages/BPM/Onboarding/components/Processes/models'
import { Invoices, NetSuiteInvoices } from '~/code/pages/Billing'
import { MonitoringPos, MonitoringPosStoreSymbol } from '~/code/pages/Acquiring/pages/MonitoringPos'
import { ChangeOfSettlementsStatus } from '../pages/BPMProcesses/Requests/components/ChangeOfSettlementsStatus'
import { RealTimeTransaction } from '~/code/pages/RealTimeTransaction/RealTimeTransaction'
import { MonitoringPosAmex, MonitoringPosAmexStoreSymbol } from '~/code/pages/Acquiring/pages/MonitoringPosAmex'
import { MonitoringPosTest, MonitoringPosTestStoreSymbol } from '~/code/pages/Acquiring/pages/MonitoringPosTest'
import { PartnerCommissions } from '../pages/PartnerCommissions'
import {
  ManageNotifications,
  ManageNotificationTemplates,
  CreateTemplates, PublishNotification
} from '~/code/pages/Notifications'


// there are more merchant select store symbols in settlements
export const MerchantSelectStoreSymbol = {
  analytics: {
    dnaTotal: Symbol('MerchantSelectStoreAnalyticsDnaTotal'),
    dnaEcom: Symbol('MerchantSelectStoreAnalyticsDnaEcom'),
    dnaPos: Symbol('MerchantSelectStoreAnalyticsDnaPos'),
    optomanyEcom: Symbol('MerchantSelectStoreAnalyticsOptomanyEcom')
  },
  monitoring: {
    dnaEcom: Symbol('MerchantSelectStoreMonitoringDnaEcom'),
    dnaPos: Symbol('MerchantSelectStoreMonitoringDnaPos'),
    dnaPosTest: Symbol('MerchantSelectStoreMonitoringDnaPosTest'),
    dnaPosAmex: Symbol('MerchantSelectStoreMonitoringDnaPosAmex'),
    optomanyEcom: Symbol('MerchantSelectStoreMonitoringOptomanyEcom')
  }
}

export const AcquiringFilterStoreSymbol = {
  analytics: {
    dnaEcom: Symbol('AcquiringFilterStoreAnalyticsDnaEcom'),
    dnaPos: Symbol('AcquiringFilterStoreAnalyticsDnaPos'),
    optomanyEcom: Symbol('AcquiringFilterStoreAnalyticsOptomanyEcom')
  },
  monitoring: {
    dnaEcom: Symbol('AcquiringFilterStoreMonitoringDnaEcom'),
    dnaPos: Symbol('AcquiringFilterStoreMonitoringDnaPos'),
    dnaPosTest: Symbol('AcquiringFilterStoreMonitoringDnaPosTest'),
    dnaPosAmex: Symbol('AcquiringFilterStoreMonitoringDnaPosAmex'),
    optomanyEcom: Symbol('AcquiringFilterStoreMonitoringOptomanyEcom')
  }
}

export const AnalyticsStoreSymbol = {
  dna: {
    total: Symbol('AnalyticsStoreDnaTotal'),
    ecom: Symbol('AnalyticsStoreDnaEcom'),
    pos: Symbol('AnalyticsStoreDnaPos')
  },
  optomany: {
    ecom: Symbol('AnalyticsStoreOptomanyEcom')
  }
}

export class RouterStore implements IRouterStore {
  private _loginRedirectRoute: string

  constructor(private parentStore: IRouterParentStore) {
    makeObservable(this, {
      currentRoute: observable,
      showDNATransactionsAnalyticsPage: action,
      showDNATransactionsStatementPage: action,
      showDNATransactionsMonitoringPosPage: action,
      showDNATransactionsMonitoringPosTestPage: action,
      showDNATransactionsMonitoringPosAmexPage: action,
      showOptomanyCheckoutV3AnalyticsPage: action,
      showOptomanyCheckoutV3StatementPage: action,
      showNotFoundPage: action,
      showResetPassword: action,
      showResetPasswordFailure: action,
      showSettlementsStatementPage: action,
      showSettlementsPaymentsPage: action,
      showChargebacks: action,
      showInvoicesPage: action,
      showNetSuiteInvoicesPage: action,
      showPartnerCommissions: action,
      showManageNotificationTemplatesPage: action,
      showManageNotificationTemplatesCreatePage: action,
      showManageNotificationTemplatesEditPage: action,
      showManageNotificationPage: action,
      showPublishNotificationPage: action
    })
  }

  currentRoute: string = ''
  // layout & page stores
  mainLayoutStore = new MainLayoutStore(this)

  get authStore() {
    return this.parentStore.authStore
  }

  get isAuthenticated() {
    return this.authStore.isAuthenticated
  }

  mainLayout = ({ children }: { children?: any }) => {
    return (
      <MainLayout key={'MainLayout'} store={this.mainLayoutStore} currentRoute={this.currentRoute}>
        <IdleTimer onCancelSession={() => this.authStore.logout()} />
        {children}
      </MainLayout>
    )
  }

  onLogin() {
    if (this._loginRedirectRoute) {
      goToRoute(this._loginRedirectRoute)
    } else {
      this.showHomePage()
    }
  }

  onLogout() {
    this.openLoginPage()
    // reload to clear all store caches
    window.location.reload()
  }

  openLoginPage = (redirectRoute?: string) => {
    try {
      const hasRedirect = redirectRoute && ['', '/'].indexOf(redirectRoute) < 0
      goToRoute(`${Routes.LOGIN}${hasRedirect ? `?redirect=${redirectRoute}` : ''}`)
    } catch (e) {
      error(e)
      // TODO: error cannot read property 'document' of undefined occured. Need to find out the reason.
    }
  }

  showHomePage() {
    this.authorize(() => {
      const homeRoute = getHomePageRoute()
      if (homeRoute) {
        goToRoute(homeRoute)
      } else {
        this.showNotFoundPage()
      }
    })
  }

  showDashboardPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(() => <RealTimeTransaction />, this.mainLayout, translations().home)
    }, [PermissionMap.transactions.dna_ecom_realtime.read, PermissionMap.transactions.dna_pos_realtime.read])
  }

  showLoginPage(query?: LoginRouteQueryModel) {
    this._loginRedirectRoute = query?.redirect
    if (!this.isAuthenticated) {
      AppStore.setCurrentPage(
        () => <Login store={this.parentStore.authStore} />,
        AuthLayout,
        translations().loginPageTitle
      )
    } else {
      this.showHomePage()
    }
  }

  showResetPassword = async (email?: string) => {
    if (!this.isAuthenticated) {
      if (email) {
        this.parentStore.authStore.email = email
        const { result, error: err } = await resetPassword(email)

        if (err || !result) {
          replaceRoute(`${Routes.RESET_PASSWORD_FAILURE}?m=${err?.message}`)
          return
        }
        goToRoute(Routes.CONFIRM_EMAIL)
        return
      }

      AppStore.setCurrentPage(
        () => <ResetPassword store={this.parentStore.authStore} />,
        AuthLayout,
        translations().resetPasswordPageTitle
      )
    } else {
      this.showHomePage()
    }
  }

  showResetPasswordFailure = (message?: string) => {
    AppStore.setCurrentPage(
      () => <ResetPasswordFailure errorMessage={message} onTryAgainClick={() => page.redirect('/reset-password')} />,
      AuthLayout,
      translations().resetPasswordFailurePageTitle
    )
  }

  showConfirmEmail() {
    if (!this.isAuthenticated) {
      AppStore.setCurrentPage(
        () => <ConfirmEmail store={this.parentStore.authStore} />,
        AuthLayout,
        translations().confirmCodePageTitle
      )
    } else {
      this.showHomePage()
    }
  }

  showSetNewPassword() {
    if (!this.isAuthenticated) {
      AppStore.setCurrentPage(
        () => <SetNewPassword store={this.parentStore.authStore} />,
        AuthLayout,
        translations().setNewPasswordPageTitle
      )
    } else {
      this.showHomePage()
    }
  }

  showDNATransactionsAnalyticsTotalPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(
        () => <TotalAnalytics key={'dnaTotalAnalytics'} />,
        this.mainLayout,
        translations().dnaAcquiringAnalyticsPageTitle
      )
    }, [PermissionMap.transactions.dna_total_analytics.read])
  }

  showDNATransactionsAnalyticsPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(
        () => (
          <Analytics
            key={'dnaAnalytics'}
            analyticsStoreSymbol={AnalyticsStoreSymbol.dna.ecom}
            acquiringFilterStoreSymbol={AcquiringFilterStoreSymbol.analytics.dnaEcom}
            merchantSelectStoreSymbol={MerchantSelectStoreSymbol.analytics.dnaEcom}
          />
        ),
        this.mainLayout,
        translations().dnaAcquiringAnalyticsPageTitle
      )
    }, [PermissionMap.transactions.dna_ecom_analytics.read])
  }

  showDNATransactionsAnalyticsPosPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(
        () => (
          <Analytics
            key={'dnaAnalyticsPos'}
            analyticsStoreSymbol={AnalyticsStoreSymbol.dna.pos}
            acquiringFilterStoreSymbol={AcquiringFilterStoreSymbol.analytics.dnaPos}
            merchantSelectStoreSymbol={MerchantSelectStoreSymbol.analytics.dnaPos}
          />
        ),
        this.mainLayout,
        translations().dnaAcquiringAnalyticsPosPageTitle
      )
    }, [PermissionMap.transactions.dna_pos_analytics.read])
  }

  showDNATransactionsStatementPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(
        () => (
          <Statement
            key={'dnaStatement'}
            transactionsStatementStoreSymbol={MonitoringStoreSymbol.dna}
            acquiringFilterStoreSymbol={AcquiringFilterStoreSymbol.monitoring.dnaEcom}
            merchantSelectStoreSymbol={MerchantSelectStoreSymbol.monitoring.dnaEcom}
          />
        ),
        this.mainLayout,
        translations().dnaAcquiringStatementPageTitle
      )
    }, [PermissionMap.transactions.dna_ecom_statement.read])
  }

  showDNATransactionsMonitoringPosPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(
        () => (
          <MonitoringPos
            key={'dnaStatement'}
            transactionsStatementStoreSymbol={MonitoringPosStoreSymbol}
            acquiringFilterStoreSymbol={AcquiringFilterStoreSymbol.monitoring.dnaPos}
            merchantSelectStoreSymbol={MerchantSelectStoreSymbol.monitoring.dnaPos}
          />
        ),
        this.mainLayout,
        translations().dnaAcquiringMonitoringPosPageTitle
      )
    }, [PermissionMap.transactions.dna_pos_statement.v2.read])
  }

  showDNATransactionsMonitoringPosTestPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(
        () => (
          <MonitoringPosTest
            key={'dnaStatementTest'}
            transactionsStatementStoreSymbol={MonitoringPosTestStoreSymbol}
            acquiringFilterStoreSymbol={AcquiringFilterStoreSymbol.monitoring.dnaPosTest}
            merchantSelectStoreSymbol={MerchantSelectStoreSymbol.monitoring.dnaPosTest}
          />
        ),
        this.mainLayout,
        translations().dnaAcquiringMonitoringPosPageTitle
      )
    }, [PermissionMap.transactions.dna_pos_statement.test.read])
  }

  showDNATransactionsMonitoringPosAmexPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(
        () => (
          <MonitoringPosAmex
            key={'amexDnaStatement'}
            transactionsStatementStoreSymbol={MonitoringPosAmexStoreSymbol}
            acquiringFilterStoreSymbol={AcquiringFilterStoreSymbol.monitoring.dnaPosAmex}
            merchantSelectStoreSymbol={MerchantSelectStoreSymbol.monitoring.dnaPosAmex}
          />
        ),
        this.mainLayout,
        translations().dnaAcquiringMonitoringPosPageTitle
      )
    }, [PermissionMap.transactions.dna_pos_statement.v2.read])
  }

  showTransactionChannelsGlobalSettingsPage() {
    this.authorize(() => {
      const { transactionChannelsStore } = AppStore
      if (!transactionChannelsStore.isInitiated) {
        transactionChannelsStore.init()
      }
      AppStore.setCurrentPage(
        () => <GlobalSettings store={transactionChannelsStore} />,
        this.mainLayout,
        translations().transactionChannelsGeneralSettingsPageTitle
      )
    }, [PermissionMap.transactions.channels.read])
  }

  showTransactionChannelsMerchantSettingsPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(
        () => <MerchantSettings />,
        this.mainLayout,
        translations().transactionChannelsMerchantSettingsPageTitle
      )
    }, [PermissionMap.transactions.channels.read])
  }

  showOptomanyCheckoutV3AnalyticsPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(
        () => (
          <Analytics
            key={'optomanyAnalytics'}
            analyticsStoreSymbol={AnalyticsStoreSymbol.optomany.ecom}
            acquiringFilterStoreSymbol={AcquiringFilterStoreSymbol.analytics.optomanyEcom}
            merchantSelectStoreSymbol={MerchantSelectStoreSymbol.analytics.optomanyEcom}
          />
        ),
        this.mainLayout,
        translations().optomanyCheckoutV3AnalyticsPageTitle
      )
    }, [PermissionMap.transactions.optomany_ecom_analytics.read])
  }

  showOptomanyCheckoutV3StatementPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(
        () => (
          <Statement
            key={'optomanyStatement'}
            transactionsStatementStoreSymbol={MonitoringStoreSymbol.optomany}
            acquiringFilterStoreSymbol={AcquiringFilterStoreSymbol.monitoring.optomanyEcom}
            merchantSelectStoreSymbol={MerchantSelectStoreSymbol.monitoring.optomanyEcom}
          />
        ),
        this.mainLayout,
        translations().optomanyCheckoutV3StatementPageTitle
      )
    }, [PermissionMap.transactions.optomany_ecom_statement.read])
  }

  showOnboardingProcesses() {
    const component = this.authStore.isPartner ? <OnboardingPartnerProcesses /> : <OnboardingProcesses />
    this.authorize(
      () => AppStore.setCurrentPage(() => component, this.mainLayout, translations().onboardingProcessesPageTitle),
      [PermissionMap.onboarding.processes.read]
    ) // TODO page title
  }

  showAdditionalStoreProcesses() {
    const component = <ProcessesAdditionalStore />
    this.authorize(
      () => AppStore.setCurrentPage(() => component, this.mainLayout, translations().onboardingProcessesPageTitle),
      [PermissionMap.onboarding.processes.read]
    )
  }

  showOnboardingProcess(id: string, type: ReportType = 'onboarding') {
    const { onboardingProcessStore } = AppStore
    onboardingProcessStore.setProcessId(id)
    onboardingProcessStore.init()
    const component = this.authStore.isPartner ? (
      <></>
    ) : (
      <OnboardingProcess store={onboardingProcessStore} type={type} />
    )
    this.authorize(
      () => AppStore.setCurrentPage(() => component, this.mainLayout, translations().onboardingProcessPageTitle),
      [PermissionMap.onboarding.processes.details.read]
    )
  }

  showOnboardingAnalytics() {
    this.authorize(
      () =>
        AppStore.setCurrentPage(
          () => <OnboardingAnalytics />,
          this.mainLayout,
          translations().onboardingProcessesAnalytics
        ),
      [PermissionMap.onboarding.analytics.read]
    )
  }

  showStatisticsPage() {
    this.authorize(
      () =>
        AppStore.setCurrentPage(() => <Statistics />, this.mainLayout, translations().onboardingStatisticsPageTitle),
      [PermissionMap.onboarding.processes.create, PermissionMap.onboarding.processesV2.create]
    )
  }

  showMerchantsPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(() => <MerchantsManagement />, this.mainLayout, translations().merchantsManagementProfile)
    }, [PermissionMap.merchants.read])
  }

  showInvoicesPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(() => <Invoices />, this.mainLayout, translations().invoicesPageTitle)
    }, [PermissionMap.invoices.read])
  }

  showNetSuiteInvoicesPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(() => <NetSuiteInvoices />, this.mainLayout, translations().netSuiteInvoicesPageTitle)
    }, [PermissionMap.netSuiteInvoices.download])
  }

  showRiskLimitsSubmittedRequests() {
    this.authorize(
      () =>
        AppStore.setCurrentPage(
          () => <RiskLimitsSubmittedRequests />,
          this.mainLayout,
          translations().riskLimitsPageTitle
        ),
      [PermissionMap.merchants.processes.read]
    ) // TODO page title
  }

  showMotoVtRequests() {
    this.authorize(
      () => AppStore.setCurrentPage(() => <MotoVtRequests />, this.mainLayout, translations().motoVtRequestPageTitle),
      [PermissionMap.merchants.processes.read]
    )
  }

  showChangeBankDetailsDossierV2() {
    this.authorize(
      () =>
        AppStore.setCurrentPage(
          () => <ChangeBankDetailsDossierV2 />,
          this.mainLayout,
          translations().changeBankDetailsPageTitle
        ),
      [PermissionMap.merchants.processes.read]
    )
  }

  showClosureOfMerchant() {
    this.authorize(
      () =>
        AppStore.setCurrentPage(
          () => <ClosureOfMerchant />,
          this.mainLayout,
          translations().closureOfMerchantPageTitle
        ),
      [PermissionMap.merchants.processes.read]
    )
  }

  showClosureOfTerminal() {
    this.authorize(
      () =>
        AppStore.setCurrentPage(
          () => <ClosureOfTerminal />,
          this.mainLayout,
          translations().closureOfTerminalPageTitle
        ),
      [PermissionMap.merchants.processes.read]
    )
  }

  showChangeOfSettlementPeriod() {
    this.authorize(
      () =>
        AppStore.setCurrentPage(
          () => <ChangeOfSettlementsPeriodRequests />,
          this.mainLayout,
          translations().terminalIssueTitle
        ),
      [PermissionMap.merchants.processes.read]
    )
  }

  showChangeOfSettlementKind() {
    this.authorize(
      () =>
        AppStore.setCurrentPage(
          () => <ChangeOfSettlementKind />,
          this.mainLayout,
          translations().changeOfSettlementType
        ),
      [PermissionMap.merchants.processes.read]
    )
  }

  showChangeOfSettlementStatus() {
    this.authorize(
      () =>
        AppStore.setCurrentPage(
          () => <ChangeOfSettlementsStatus />,
          this.mainLayout,
          translations().changeOfSettlementStatus
        ),
      [PermissionMap.onboarding.processes.details.read]
    )
  }

  showTerminalIssue() {
    this.authorize(
      () =>
        AppStore.setCurrentPage(
          () => <TerminalIssueRequests />,
          this.mainLayout,
          translations().changeofSettlementPeriodTitle
        ),
      [PermissionMap.merchants.processes.read]
    )
  }

  showAdjustmentRequests() {
    this.authorize(
      () =>
        AppStore.setCurrentPage(() => <AdjustmentRequests />, this.mainLayout, translations().adjustmentRequestsTitle),
      [PermissionMap.merchants.processes.read]
    )
  }

  showEditTariffsRequests() {
    this.authorize(
      () =>
        AppStore.setCurrentPage(
          () => <EditTariffsRequests />,
          this.mainLayout,
          translations().editTariffsRequestsTitle
        ),
      [PermissionMap.merchants.processes.read]
    )
  }

  showStartApplicationPage(id: string) {
    this.authorize(async () => {
      const { startApplicationStore } = AppStore
      if (!startApplicationStore.isInitiated) {
        await startApplicationStore.init()
      }
      if (id) {
        startApplicationStore.dataStore.setApplicationId(id)
        startApplicationStore.contactInfoStore.loadApplicationById()
      }
      AppStore.setCurrentPage(() => <StartApplication />, this.mainLayout, translations().onboardingPageTitle)
    }, [PermissionMap.onboarding.processes.create])
  }

  showSavedApplicationsPage() {
    this.authorize(() => {
      const { savedApplicationsStore } = AppStore
      if (!savedApplicationsStore.isInitiated) {
        savedApplicationsStore.init()
      } else {
        savedApplicationsStore.loadData()
      }
      AppStore.setCurrentPage(
        () => <SavedApplications store={savedApplicationsStore} />,
        this.mainLayout,
        translations().onboardingPageTitle
      )
    }, [PermissionMap.onboarding.processes.create, PermissionMap.onboarding.processesV2.create])
  }

  showSettlementsStatementPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(
        () => (
          <SettlementsStatement
            key='statement'
            showHeader={hasPermissions([PermissionMap.settlements.per_banking_date.read])}
            canDownload={hasPermissions([PermissionMap.settlements.download])}
            tableStoreSymbol={SETTLEMENT_STATEMENTS_STORE_TYPES.table}
            filtersStoreSymbol={SETTLEMENT_STATEMENTS_STORE_TYPES.filter}
            merchantSelectStoreSymbol={SETTLEMENT_STATEMENTS_STORE_TYPES.merchantSelect}
          />
        ),
        this.mainLayout,
        translations().settlementsStatementPageTitle
      )
    }, [PermissionMap.settlements.read])
  }

  showSettlementsStatementTestPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(
        () => (
          <SettlementsStatement
            key='statement-test'
            showHeader={hasPermissions([PermissionMap.settlements.test.per_banking_date.read])}
            canDownload={hasPermissions([PermissionMap.settlements.test.download])}
            tableStoreSymbol={SETTLEMENT_STATEMENTS_TEST_STORE_TYPES.table}
            filtersStoreSymbol={SETTLEMENT_STATEMENTS_TEST_STORE_TYPES.filter}
            merchantSelectStoreSymbol={SETTLEMENT_STATEMENTS_TEST_STORE_TYPES.merchantSelect}
          />
        ),
        this.mainLayout,
        translations().settlementsStatementPageTitle
      )
    }, [PermissionMap.settlements.test.read])
  }

  showSettlementsPaymentsPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(
        () => <SettlementsPayments />,
        this.mainLayout,
        translations().settlementsPaymentsPageTitle
      )
    }, [PermissionMap.settlements.payments.read])
  }

  showChargebacks() {
    this.authorize(() => {
      AppStore.setCurrentPage(() => <Chargebacks />, this.mainLayout, translations().chargebacksPageTitle)
    }, [PermissionMap.chargebacks.read])
  }

  showPartnerCommissions() {
    this.authorize(() => {
      AppStore.setCurrentPage(() => <PartnerCommissions />, this.mainLayout, translations().partnerCommissionsTitle)
    }, [PermissionMap.chargebacks.read])
  }

  showPortalAccessPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(() => <PortalAccess />, this.mainLayout, translations().portalAccessPageTitle)
    }, [PermissionMap.portalAccess.read])
  }

  showPartnerAccessPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(() => <PartnerAccess />, this.mainLayout, translations().partnerAccessPageTitle)
    }, [PermissionMap.partners.read])
  }

  showTeammatesPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(() => <Teammates />, this.mainLayout, translations().teammatesPageTitle)
    }, [PermissionMap.teammates.read])
  }

  showExportsPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(() => <Exports />, this.mainLayout, translations().exportsPageTitle)
    }, [
      PermissionMap.settlements.test.download,
      PermissionMap.settlements.download,
      PermissionMap.transactions.dna_pos_statement.v2.read
    ])
  }

  showProfilePage() {
    this.authorize(() => {
      AppStore.setCurrentPage(() => <Profile />, this.mainLayout, translations().portalAccessPageTitle)
    })
  }

  showTwoFAPage() {
    if (!this.isAuthenticated) {
      AppStore.setCurrentPage(() => <TwoFA store={this.parentStore.authStore} />, AuthLayout, translations().twoFA)
    } else {
      this.showHomePage()
    }
  }

  showTwoFAEnforcedPage() {
    if (!this.isAuthenticated) {
      AppStore.setCurrentPage(() => <TwoFAEnforced store={this.parentStore.authStore} />, AuthLayout, translations().enforceTwoFA)
    } else {
      this.showHomePage()
    }
  }

  showTwoFAConfirmPage() {
    if (!this.isAuthenticated) {
      AppStore.setCurrentPage(
        () => <TwoFAConfirm store={this.parentStore.authStore} />,
        AuthLayout,
        translations().twoFA
      )
    } else {
      this.showHomePage()
    }
  }

  showRecoveryCodePage() {
    if (!this.isAuthenticated) {
      AppStore.setCurrentPage(
        () => <RecoveryCode store={this.parentStore.authStore} />,
        AuthLayout,
        translations().recoveryCode
      )
    } else {
      this.showHomePage()
    }
  }

  showNotFoundPage() {
    this.authorize(() => AppStore.setCurrentPage(() => <NotFound />, this.mainLayout, translations().notFoundPageTitle))
  }

  showNoPermissionPage() {
    this.authorize(() =>
      AppStore.setCurrentPage(() => <NoPermission />, this.mainLayout, translations().noPermissionPageTitle)
    )
  }

  showStartProcessPage(id: string) {
    this.authorize(async () => {
      const { startProcessStore } = AppStore
      if (!startProcessStore.isInitiated) {
        await startProcessStore.init()
      }
      if (id) {
        startProcessStore.dataStore.setApplicationId(id)
        await startProcessStore.contactInfoStore.loadApplicationById(id)
      }
      AppStore.setCurrentPage(() => <StartProcess />, this.mainLayout, translations().onboardingPageTitle)
    }, [PermissionMap.onboarding.processes.create, PermissionMap.onboarding.processesV2.create])
  }

  showAdditionalStoreProcessPage(id: string, uberID: string) {
    this.authorize(async () => {
      const { startProcessStore } = AppStore
      if (!startProcessStore.isInitiated) {
        await startProcessStore.init()
      }
      if (id) {
        startProcessStore.contactInfoStore.startAdditionalStore(id, uberID)
      }
      AppStore.setCurrentPage(() => <StartProcess />, this.mainLayout, translations().onboardingPageTitle)
    }, [PermissionMap.onboarding.processes.create, PermissionMap.onboarding.processesV2.create])
  }

  showManageNotificationTemplatesPage() {
    this.authorize(() => {
        AppStore.setCurrentPage(() => <ManageNotificationTemplates />, this.mainLayout, translations().notifications.manageTemplates)
      }, [PermissionMap.notifications.templates.read])
  }

  showManageNotificationTemplatesCreatePage() {
    this.authorize(() => {
      AppStore.setCurrentPage(() => <CreateTemplates />, this.mainLayout, translations().notifications.manageTemplates)
    }, [PermissionMap.notifications.templates.create])
  }

  showManageNotificationTemplatesEditPage(id: string) {
    this.authorize(() => {
      AppStore.setCurrentPage(() => <CreateTemplates id={id} />, this.mainLayout, translations().notifications.manageTemplates)
    }, [PermissionMap.notifications.templates.update])
  }

  showManageNotificationPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(() => <ManageNotifications />, this.mainLayout, translations().notifications.manageNotifications)
    }, [PermissionMap.notifications.read])
 }

  showPublishNotificationPage() {
    this.authorize(() => {
      AppStore.setCurrentPage(() => <PublishNotification />, this.mainLayout, translations().notifications.publishNotifications)
    }, [PermissionMap.notifications.create])
  }

  private authorize(route: () => void | Promise<void>, permissions?: string[]) {
    const hasPermissions = this.parentStore.authStore.hasPermissions(permissions)
    if (!this.isAuthenticated) {
      this.openLoginPage(this.currentRoute)
    } else if (!hasPermissions) {
      this.showNoPermissionPage()
    } else {
      route()
    }
  }
}
