import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  formFields: {
    email: string
    firstName: string
    lastName: string
    sendWelcomeEmail: string
  }
  formErrors: {
    emailRequired: string
    enterValidEmail: string
  }
}>()
