import { getWithAuth, postWithAuth } from '~/code/services'
import { SettlementPeriodProcessDetails } from '~/code/stores/ChangeOfSettlementPeriodRequestStore/models/SettlementPeriodProcessDetails'
import { UploadAdditionalDocRes } from '~/code/stores/ChangeOfSettlementPeriodRequestStore/models/UploadAdditionalDocRes'
import { BpmFilterStatusModel } from '~/code/models/BpmFilterStatusModel'

export const getSettlementPeriodStatuses = () =>
    getWithAuth<BpmFilterStatusModel[]>('/api/bpm/processes/statuses?processName=change-settlement-period')

export const getProcessDetails = (processId: string) =>
    getWithAuth<SettlementPeriodProcessDetails>(`/api/bpm/processes/change-settlement-period/${processId}/details`)

export const uploadAdditionalDoc = (processId: string, params) =>
    postWithAuth<UploadAdditionalDocRes>(`/api/bpm/processes/change-settlement-period/${processId}/documents`, params)
