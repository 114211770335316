import {Grid} from 'antd'

export const useScreenType = () => {
    const screens = Grid.useBreakpoint()
    return {
        isDesktop: screens.xl || screens.xxl,
        isLaptop: screens.lg && !screens.xl,
        isTablet: (screens.sm || screens.md) && !screens.lg,
        isMobile: screens.xs && !screens.sm
    }
}
