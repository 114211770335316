import { Grid, Select } from 'antd'
import classNames from 'classnames'
import { Moment } from 'moment'
import React, { useMemo, useRef, useCallback } from 'react'
import { PeriodType } from '~/code/models'
import { LinkButtonGroup } from '~/code/components/LinkButtonGroup'
import { withTooltip } from '~/code/hocs/withTooltip'
import DatePicker from '~/code/components/DatePicker'
import translations from './translations'
import { RangePickerProps } from './props'
import styles from './RangePicker.scss'

const { RangePicker: AntRangePicker } = DatePicker
const { useBreakpoint } = Grid

export const RangePicker: React.FC<RangePickerProps> = props => {
  const {
    startDate,
    endDate,
    period,
    className,
    onSetPeriod,
    onSetDates,
    periods = [
      { value: 'day', text: translations().day },
      { value: 'yesterday', text: translations().yesterday },
      { value: 'week', text: translations().week },
      { value: 'last7Days', text: translations().last7Days },
      { value: 'month', text: translations().month },
      { value: 'last30Days', text: translations().last30Days },
      { value: 'last60Days', text: translations().last60Days },
      { value: 'last90Days', text: translations().last90Days },
      { value: 'year', text: translations().year },
      { value: 'last12Months', text: translations().last12Months }
    ],
    disabledDate: _disabledDate,
    disabled,
    tooltipTitle,
    includeHoursAndMins = true,
    allowClear = true,
    onCalendarChange
  } = props
  const screens = useBreakpoint()
  const rangePickerRef = useRef<any>()

  const isPeriodTypeCustom = useMemo(() => {
    for (const p of periods) {
      if (p.value === period) return false
    }
    return true
  }, [period])

  const renderPeriodSelect = () => {
    return (
      <Select
        value={period}
        onChange={v => onSetPeriod(v)}
        disabled={disabled || false}
        className={styles.periodSelect}
      >
        {isPeriodTypeCustom && <Select.Option value={period}>{translations().custom}</Select.Option>}
        {periods.map(p => (
          <Select.Option value={p.value} key={p.value} disabled={p.isDisabled}>
            {withTooltip(p.text, tooltipTitle ? tooltipTitle : p.tooltipTitle)}
          </Select.Option>
        ))}
      </Select>
    )
  }

  const getFormat = (): string => {
    if (includeHoursAndMins) return 'YYYY-MM-DD HH:mm'

    return 'YYYY-MM-DD'
  }

  const getShowTime = () => {
    if (includeHoursAndMins) return { format: 'HH:mm' }

    return false
  }

  const disabledDate = useCallback(
    (date: Moment) => {
      if (!_disabledDate) return false

      return _disabledDate(date, [startDate, endDate])
    },
    [_disabledDate, startDate, endDate]
  )

  return (
    <div className={classNames(styles.RangePicker, className)}>
      {screens.xl && renderPeriodSelect()}
      {screens.xs ? (
        <>
          {renderPeriodSelect()}
          {withTooltip(
            <div className={styles.mobileRangePicker}>
              <DatePicker
                value={startDate}
                allowClear={allowClear}
                placement={'bottomRight'}
                onChange={value => {
                  const [_startDate, _endDate] = onCalendarChange
                    ? onCalendarChange([value, endDate], 'start')
                    : [value, endDate]

                  onSetDates(_startDate, _endDate, 'start')
                }}
                disabledDate={_disabledDate ? disabledDate : null}
                disabled={disabled || false}
                showTime={getShowTime()}
                format={getFormat()}
              />
              <DatePicker
                value={endDate}
                allowClear={allowClear}
                placement={'bottomRight'}
                onChange={value => onSetDates(startDate, value, 'end')}
                disabledDate={_disabledDate ? disabledDate : null}
                disabled={disabled || false}
                showTime={getShowTime()}
                format={getFormat()}
              />
            </div>,
            tooltipTitle
          )}
        </>
      ) : (
        withTooltip(
          <AntRangePicker
            allowClear={allowClear}
            ref={rangePickerRef}
            value={[startDate, endDate]}
            placement={'bottomRight'}
            renderExtraFooter={
              screens.xl
                ? null
                : () => (
                    <div className={styles.rangePickerFooter}>
                      <LinkButtonGroup
                        selectedValue={period}
                        onSelect={link => {
                          rangePickerRef.current?.blur()
                          onSetPeriod(link.value as PeriodType)
                        }}
                        links={periods}
                      />
                    </div>
                  )
            }
            disabledDate={_disabledDate ? disabledDate : null}
            disabled={disabled || false}
            showTime={getShowTime()}
            format={getFormat()}
            onCalendarChange={(dates, _, info) => { 
              const _dates = onCalendarChange ? onCalendarChange(dates, info.range) : dates

              if (_dates[0] && _dates[1]) {
                onSetDates(_dates[0], _dates[1])
              }
            }}
          />,
          tooltipTitle
        )
      )}
    </div>
  )
}
