import { TranslationBuilder } from '~/code/components/Translation'
import { PaymentSolutionView } from '../../../models'

interface PaymentSolutionTranslate {
    title: string,
    comment: string
}

export default TranslationBuilder.create<{
    title: string
    required: string
    posModel: string
    posModelRequired: string
    paymentSolutions: Record<PaymentSolutionView, PaymentSolutionTranslate>,
    ecom: string
    pos: string
}>()

