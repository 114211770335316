import { inject, injectable } from 'inversify'
import { message, notification } from 'antd'
import { action, autorun, makeObservable, observable, computed } from 'mobx'
import { ItemModel } from '~/code/models/handbooks'
import { IPartnerAccessFilterStore } from '~/code/pages/PartnerAccess/components/PartnerAccessFilter'
import { PartnerType } from '~/code/stores/PartnerAccessStore/types'
import { IPartnerAccessStore, PartnerAccessStoreSymbol } from '~/code/pages/PartnerAccess'
import { IPartnerAccessTableStore, PartnerAccessTableStoreSymbol } from '~/code/pages/PartnerAccess/components/PartnerAccessTable'

import { fetchPartners } from '~/code/stores/PartnerAccessStore/services/fetchers'

@injectable()
export class PartnerAccessFilterStore implements IPartnerAccessFilterStore {
  isPartnersLoading: boolean = false
  partnerAccessStore: IPartnerAccessStore
  partnerAccessTableStore: IPartnerAccessTableStore
  partners: PartnerType[] = []

  constructor(
    @inject(PartnerAccessStoreSymbol) partnerAccessStore: IPartnerAccessStore,
    @inject(PartnerAccessTableStoreSymbol) partnerAccessTableStore: IPartnerAccessTableStore
  ) {
    this.partnerAccessStore = partnerAccessStore
    this.partnerAccessTableStore = partnerAccessTableStore

    makeObservable(this, {
      partners: observable,
      isPartnersLoading: observable,

      fetchPartners: action.bound,

      partnerSelectOptions: computed,
      isEditPartnerButtonVisible: computed
    })

    autorun(() => this.fetchPartners())
  }

  get isEditPartnerButtonVisible(): boolean {
    return !!this.partnerAccessStore.selectedPartner && !!this.partnerAccessStore.selectedPartner.synced
  }

  get partnerSelectOptions(): ItemModel[] {
    return this.partners.map(({ clientId, value, clientValue, id }, i) => ({
      id: id,
      value: clientId,
      label: !!value ? value : clientValue,
      key: `${clientId}${i}`
    }))
  }

  onPartnerSelect = (partnerId: string) => {
    const selectedPartner = this.partners.find(i => i.clientId === partnerId)
    this.partnerAccessStore.setSelectedPartner(selectedPartner)
    this.loadTableData()
  }

  onPartnerClear = () => {
    this.partnerAccessStore.setSelectedPartner(null)
    this.loadTableData()
  }

  onEmailChange = (email: string) => {
    this.partnerAccessStore.setSelectedEmail(email)
    this.loadTableData()
  }

  loadTableData = () => {
    this.partnerAccessTableStore.tableStore.loadData({ pageNumber: 1 })
  }

  fetchPartners = async () => {
    this.isPartnersLoading = true

    try {
      const { status, result, error } = await fetchPartners()

      if (error) {
        notification.error({ message: error.message })
        return
      }

      if (status === 200) {
        this.partners = result.filter(i => i.isActive)
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      this.isPartnersLoading = false
    }
  }
}
