import translations from './translations'

translations.add('en', {
    avgDurationTime: 'Avg. duration',
    avgReviewTime: 'Avg. review',
    target: 'Target KPI',
    performance: 'Performance',
    min: 'min',    
    applications: 'applications',
    hour: 'hour',
    hours: 'hours',
    hourAbbr: 'hr',
    hoursAbbr: 'hrs'
})
