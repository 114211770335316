import React from 'react'
import { BPMProcessDossierV2 } from '../shared'
import { columns } from './constants'

export const ChangeOfSettlementKindStoreSymbol = Symbol('ChangeOfSettlementKindStore')
export const ChangeOfSettlementKindRangePickerStoreSymbol = Symbol('ClosureOfMerchantRangePickerStore')
export const ChangeOfSettlementKindMerchantSelectStoreSymbol = Symbol('ClosureOfMerchantMerchantSelectStore')

export const ChangeOfSettlementKind = () => (
  <BPMProcessDossierV2
    BPMProcessesStoreSymbol={ChangeOfSettlementKindStoreSymbol}
    BPMProcessesRangePickerStoreSymbol={ChangeOfSettlementKindRangePickerStoreSymbol}
    BPMProcessesMerchantSelectStoreSymbol={ChangeOfSettlementKindMerchantSelectStoreSymbol}
    columns={columns}
  />
)
