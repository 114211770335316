import React from 'react'
import moment from 'moment'
import { Badge } from 'antd'
import { SubmittedRequestsStatus } from '../../SubmittedRequests/models'
import { reviewTime } from '~/code/pages/BPMProcesses/Requests/utils'
import { Nowrap } from '~/code/components'
import { DATE_TIME_FORMAT } from '~/code/constants/date-time'
import translations from '../translations'

export const columns = [
  {
    title: translations().startDate,
    dataIndex: 'startDate',
    key: 'startDate',
    render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : '')
  },
  {
    title: translations().initiator,
    dataIndex: 'initiator',
    key: 'initiator'
  },
  {
    title: translations().merchant,
    dataIndex: 'companyName',
    key: 'companyName'
  },
  {
    title: translations().mid,
    dataIndex: 'mid',
    key: 'mid'
  },
  {
    title: translations().sfCaseId,
    dataIndex: 'sfCaseId',
    key: 'sfCaseId'
  },
  {
    title: translations().adjustmentType,
    dataIndex: 'adjustmentType',
    key: 'adjustmentType'
  },
  {
    title: translations().status,
    dataIndex: 'processStatus',
    key: 'processStatus',
    render: (status: SubmittedRequestsStatus) =>
      status ? (
        <Nowrap width={94} title={status}>
          {renderStatus(status)}
        </Nowrap>
      ) : (
        ''
      )
  },
  {
    title: translations().reviewTime,
    dataIndex: 'reviewTime',
    key: 'reviewTime',
    render: (reviewMinutes: number) => reviewTime(reviewMinutes)
  },
  {
    title: translations().assignedGroup,
    dataIndex: 'assignedGroup',
    key: 'assignedGroup'
  },
  {
    title: translations().assignedUser,
    dataIndex: 'assignedUser',
    key: 'assignedUser'
  },
  {
    title: translations().endDate,
    dataIndex: 'endDate',
    key: 'endDate',
    render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : '')
  }
]

export const renderStatus = (status: SubmittedRequestsStatus) => {
  switch (status) {
    case SubmittedRequestsStatus.APPROVED:
      return <Badge status={'success'} text={status} />
    case SubmittedRequestsStatus.REJECTED:
      return <Badge status={'error'} text={status} />
    default:
      return <Badge text={status} color={'yellow'} />
  }
}
