import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    checkTheData: string
    pleaseCheckBeforeSubmit: string
    backToForm: string
    submit: string
    applicationHasFollowingErrors: string
    generalInformationNotCompleted: string
    shareholdersNotCompleted: string
    directorsNotCompleted: string
    authorizedSignatoriesNotCompleted: string
    businessOwnerNotCompleted: string
    supportingDocumentsNotCompleted: string
    submissionFailed: string
    applicationSubmittedSuccessfully: string
    applicationID: string
    inOrderToTrackApplication: string
    newApplication: string
    onceYouClickSubmit: string
    pleaseWait2Mins: string
}>()

