import React, { useMemo } from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { Typography, Card, Form, Button, Tooltip, Modal, Checkbox, Select, Input, List, Row, Col, Collapse } from 'antd'
import { EditOutlined, SettingOutlined, EyeOutlined, CloseOutlined } from '@ant-design/icons'
import { EditOutlinedBlue } from '~/assets/icons'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { AddressType } from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v2'
import { Info } from '../Info'
import { ChangeBankDetailsModal } from './components/ChangeBankDetailsModal'
import { Contract } from './components/Contract'
import { RowElement } from './components/RowElement'
import { SettlementsStatusModal } from './components/SettlementsStatusModal'
import { EditTariffsModal } from './components/EditTariffsModal'
import { TipJarModal } from './components/TipJarModal'
import { ViewAttributesModal } from './components/ViewAttributesModal'
import { CloseStoreModal } from './components/CloseStoreModal'
import { IStoresDossierV2Store } from './models'
import { StoresProps } from './props'
import translations from './translations'
import styles from './Stores.scss'

export const StoresDossierV2StoreSymbol = Symbol('StoresDossierV2Store')
export const Stores: React.FC<StoresProps> = observer(({ storesInfo }) => {
  const [settlementForm] = Form.useForm()
  const store = useInjection<IStoresDossierV2Store>(StoresDossierV2StoreSymbol)

  const getStoreAddress = (address: AddressType | null) => {
    let addressLine: string = ''
    if (address) {
      addressLine = `${address.townOrCity ? address.townOrCity : ''} ${
        address.addressLine1 ? address.addressLine1 : ''
      } ${address.postalCode ? address.postalCode : ''}`
    }
    return addressLine
  }

  const closeSettlementModal = () => {
    store.closeGrossSettlementModal()
  }

  const midsList = useMemo(() => store.getMidsByStore(), [store.selectedStoreId])

  const renderHoldReleaseSettlementsButton = () => {
    if (!hasPermissions([PermissionMap.merchants.processes.hold_settlement.read])) {
      return null
    }
    return (
      <div>
        <Button
          disabled={store.isSubsidiary}
          onClick={() => store.openSettlementsStatusModal()}
          loading={store.isHoldSettlementLoading}
        >
          {translations().settlementsStatus}
        </Button>
      </div>
    )
  }

  const renderChangeSettlementTypeBtn = () => {
    if (!hasPermissions([PermissionMap.merchants.processes.settlement_type.read])) {
      return null
    }
    return (
      <div>
        <Button
          disabled={store.isSubsidiary}
          icon={<EditOutlined />}
          onClick={async () => {
            await store.openGrossSettlementModal()
          }}
        >
          {translations().changeSettlement}
        </Button>
      </div>
    )
  }

  const renderTipJarButton = () => {
    if (!hasPermissions([PermissionMap.merchants.processes.tipjar.read])) {
      return null
    }

    return (
      <div>
        <Button
          disabled={store.isSubsidiary}
          icon={<SettingOutlined />}
          loading={store.isTipJarLoading}
          onClick={() => store.loadTipJarInfo()}
        >
          {translations().tipJar}
        </Button>
      </div>
    )
  }

  const renderCloseStoreButton = (originId: string, id: number) => {
    if (!hasPermissions([PermissionMap.merchants.processes.merchant_close.read])) {
      return null
    }
    return (
      <Button icon={<CloseOutlined />} onClick={() => store.openCloseStoreModal(originId, id)}>
        {translations().closeStore}
      </Button>
    )
  }

  const renderName = (accName: string, sortCode: string, isDirectDebit: boolean) => {
    return (
      <>
        <div className={styles.alias}>
          {isDirectDebit ? translations().directDebitAlias : translations().settlementAlias}
        </div>
        <div className={styles.infoItem}>
          <Info label={translations().accountName} value={accName} />
        </div>
        <div className={styles.infoItem}>
          <Info label={translations().sortCode} value={sortCode} />
        </div>
      </>
    )
  }

  return (
    <>
      <Row>
        <Col xs={24} sm={8}>
          <Typography.Title level={3}>
            {translations().companyName}: {storesInfo.mainInfo?.companyName}
          </Typography.Title>
        </Col>

        <Col xs={24} sm={16}>
          <div className={styles.groupContainer}>
            {renderTipJarButton()}
            {renderChangeSettlementTypeBtn()}
            {renderHoldReleaseSettlementsButton()}
          </div>
        </Col>
      </Row>
      {storesInfo.processing?.stores?.map((stores, idx) => {
        const bankDetailsData = store.getBankDetailsData(stores)
        return (
          <Card
            key={idx}
            title={
              <div className={styles.flexCard}>
                <div>
                  <RowElement label={translations().storeName} value={stores.storeInfo.storeName} />
                  <RowElement label={translations().address} value={getStoreAddress(stores.storeAddress)} />
                  {!stores.storeInfo?.isActive && (
                    <div className={styles.redText}>
                      <RowElement label={translations().storeStatus} value={translations().closedStore} />
                    </div>
                  )}
                </div>
                <div className={styles.centeredBtn}>
                  {stores?.storeInfo?.isActive &&
                    renderCloseStoreButton(stores?.storeInfo?.originId, stores?.storeInfo?.id)}
                  <Tooltip title={translations().viewAttributes}>
                    <Button icon={<EyeOutlined />} onClick={() => store.loadStoreAttributes(stores?.storeInfo?.id)}>
                      {translations().viewAttributesBtnLabel}
                    </Button>
                  </Tooltip>
                </div>
              </div>
            }
            className={styles.storeCard}
          >
            {bankDetailsData?.length > 0 && (
              <Collapse>
                <Collapse.Panel header={translations().changeBankDetails} key='1'>
                  <Row>
                    <Col xs={16}>
                      <List
                        itemLayout='horizontal'
                        split={false}
                        dataSource={bankDetailsData?.map(detail => {
                          return {
                            title: renderName(detail.bankAccountNumber, detail.sortCode, detail.isDirectDebit),
                            bankDetails: detail,
                            originStoreId: detail.originStoreId
                          }
                        })}
                        renderItem={item => {
                          return (
                            <List.Item
                              actions={[
                                <Tooltip title={translations().edit}>
                                  <Button
                                    type='text'
                                    icon={<EditOutlinedBlue />}
                                    onClick={async () => {
                                      await store.openChangeBankDetailsModal(item.bankDetails, stores.storeInfo?.id)
                                    }}
                                  />
                                </Tooltip>
                              ]}
                            >
                              {item.title}
                            </List.Item>
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            )}
            {stores.contracts.map((contractItem, idx) => {
              return (
                <Contract
                  key={idx}
                  contract={contractItem.contract}
                  mid={contractItem.terminals[0]?.terminal?.merchantId}
                  midText={`${store.getMidsFromContractInfo(contractItem)} (${store.defineProductType(
                    contractItem.terminals.map(terminal => terminal.terminal.terminalId)
                  )})`}
                />
              )
            })}
          </Card>
        )
      })}
      {store.isChangeBankDetailsModalOpen && (
        <ChangeBankDetailsModal
          width={'50%'}
          open={true}
          title={translations().changeBankDetails}
          midsList={midsList}
        />
      )}
      <Modal
        open={store.isGrossSettlementModalOpen}
        onOk={settlementForm.submit}
        okButtonProps={{
          loading: store.isChangeSettlementLoading,
          disabled:
            (!store.isChangeOfGrossSettlement && !store.isChangeOfDirectDebit) ||
            (store.isChangeOfDirectDebit && !store.mandate) ||
            !store.isMerchantActive
        }}
        onCancel={closeSettlementModal}
        title={translations().grossSettlementChange}
      >
        <Form form={settlementForm} onFinish={store.handleSubmitSettlement}>
          <Typography.Title level={5}>
            {store.getSettlementType() === null
              ? translations().errorDefiningSettlementType
              : `${translations().settlementType} ${translations()[store.getSettlementType()]}`}
          </Typography.Title>
          <div className={styles.checkboxGrid}>
            {store.getSettlementType() === 'dailyNet' && (
              <Form.Item>
                <Checkbox
                  checked={store.isChangeOfGrossSettlement}
                  onChange={() => store.setIsChangeOfGrossSettlement()}
                >
                  {translations().changeGrossSettlementConfirmation}
                </Checkbox>
              </Form.Item>
            )}
            {(store.getSettlementType() === 'dailyNet' || store.getSettlementType() === 'monthlyNet') && (
              <Form.Item>
                <Checkbox checked={store.isChangeOfDirectDebit} onChange={() => store.setIsChangeOfDirectDebit()}>
                  {translations().changeDirectDebitConfirmation}
                </Checkbox>
              </Form.Item>
            )}
            {store.isChangeOfDirectDebit && (
              <>
                <Form.Item label={translations().chargeMerchant}>
                  <Select defaultValue='MID' onChange={store.handleChargeMethod} options={store.changeMethodsOptions} />
                </Form.Item>
                <Form.Item label={translations().mandate} required={store.isChangeOfDirectDebit}>
                  <Input onChange={e => store.handleMandate(e.target.value)} value={store.mandate} />
                </Form.Item>
              </>
            )}
          </div>
        </Form>
      </Modal>
      <TipJarModal open={store.isTipJarModalOpen} />
      <SettlementsStatusModal open={store.isSettlementsStatusModalOpen} />
      <EditTariffsModal open={store.isEditTariffsModalOpen} data={store.editContractTariffs} />
      <ViewAttributesModal storeId={store.selectedAttributesStoreId} open={store.isViewAttributesModalOpen} />
      {store.isCloseStoreModalOpen && (
        <CloseStoreModal storeId={store.selectedOriginStoreId} open={true} midsList={midsList} />
      )}
    </>
  )
})
