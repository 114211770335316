import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    terminalIssueTitle: string
    mid: string
    newMid: string
    posModel: string
    productType: string
    terminalsQuantity: string
    businessKey: string
    newTariff: string
    moto: string
    required: string
    qntError: string
    descriptor: string
    contractNumber: string
    address: string
    submit: string
    clear: string
    contractType: string
    byDNA: string
    byPaytek: string
    subscriptionServicePeriod: string
    freeSubscriptionPeriod: string
    feeSubscriptionPerMonth: string
    terminalCost: string
    setUpConfigurationDeliveryFee: string
    subscription: string
    terminalPurchase: string
    ownTerminal: string
    notZero: string
    posBundles: string
    posDeliveryAddress: string
    sameAsCompany: string
    sameAsStore: string
    noMoreThan: (value: number) => string
    selectedDeliveryAddress: string
    manualAddress: {
        title: string
        countryTooltip: string
        countryLabel: string
        countryRequired: string
        postalCodeLabel: string
        postalCodeRequired: string
        addressLine1Label: string
        addressLine1Required: string
        addressLine2Label: string
        addressLine2Required: string
        cityLabel: string
        cityRequired: string
        cityInvalid: string
        postalCodeInvalid: string
    },
    UK: string,
    deliveryInstructions: string
    deliveryInstructionsPlaceholder: string
    deliveryContactNumber: string
}>()
