import { TranslationBuilder } from '~/code/components/Translation'
import countriesEn from '~/assets/jsons/countries.en.json'

const translations = TranslationBuilder.create<string[]>()

translations.add('en', countriesEn)

export function getCountries() {
    return translations()
}

const ukTranslation = TranslationBuilder.create<string>()
ukTranslation.add('en', 'United Kingdom')

export function getSortedCountriesByUK() {
    const uk = ukTranslation()
    return [
        ...[uk],
        ...translations().filter((c) => c !== uk)
    ]
}
