import { Collapse, Descriptions, Table } from 'antd'
import React from 'react'
import { TarriffsPropsType } from './props'
import translations from './translations'
import styles from './Stores.scss'
import { TarriffsTable } from '../TarriffsTable'

export const Tarriffs = ({ data }: TarriffsPropsType) => {
  return (
    <Collapse ghost>
      {data?.map((item, id) => (
        <Collapse.Panel header={item.type} key={id}>
          <TarriffsTable data={item.tariffs} />
        </Collapse.Panel>
      ))}
    </Collapse>
  )
}
