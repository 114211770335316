import React from 'react'
import { Layout } from 'antd'
import { CopyrightOutlined } from '@ant-design/icons'
import { isDeveloperMode } from '~/code/services/auth'
import styles from './styles.scss'

export const Footer = () => {
    const versionStr = isDeveloperMode() ? __LAST_COMMIT_HASH__ : ''
    return <Layout.Footer className={styles.root}>
        <CopyrightOutlined /> {new Date().getFullYear()} DNA Payments {versionStr}
    </Layout.Footer>
}