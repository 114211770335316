import React from 'react'
import { Table, Row, Col } from 'antd'
import {
  MidAttributeType,
  ContractAttributeType,
  TerminalAttributeType,
  StoreAttributeType
} from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v2'
import translations from '../translations'

export function attributesTable(
  data: (MidAttributeType | ContractAttributeType | TerminalAttributeType)[],
  columnKey: string
) {
  const columns = [
    {
      title: translations()[columnKey],
      dataIndex: columnKey,
      key: columnKey,
      onCell: () => ({
        style: {
          verticalAlign: 'top',
          fontWeight: 'bold'
        }
      }),
      width: '35%'
    },
    {
      title: translations().attributes,
      dataIndex: 'attributes',
      key: 'attributes',
      render: (attributes: StoreAttributeType[]) => (
        <>
          {attributes?.map(attr => (
            <Row gutter={[16, 16]} key={attr.id}>
              <Col md={12}>
                <strong>{attr.name}:</strong>
              </Col>
              <Col md={12}>{attr.value}</Col>
            </Row>
          ))}
        </>
      ),
      width: '65%'
    }
  ]

  const tableData = data
    ?.filter(item => item.attributes !== null)
    ?.map((item, index) => ({
      key: index,
      [columnKey]: item[columnKey],
      attributes: item.attributes
    }))

  if (!tableData || tableData.length === 0) return null
  return <Table sticky columns={columns} dataSource={tableData} pagination={false} />
}
