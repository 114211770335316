import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  terminalId: string
  tariffCode: string
  tariffName: string
  feeBase: string
  feePercent: string
  settlement: string
  tids: string
  blended: string
  icPlus: string
  transactionType: string
  backText: string
  cardScheme: string
  serviceArea: string
  corporateOrConsumer: string
  cardType: string
  baseFee: string
  percentFee: string
  totalFee: string
  dnaStandardFee: string
  schemeFee: string
  interchangeFee: string
}>()
