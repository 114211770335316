import translations from './translations'
import React from 'react'

translations.add('en', {
    baseLabel: 'Base fee (p.)',
    percentLabel: 'Percent fee (%)',

    settlementFee: 'Settlement fee (per settlement) (£) ****',	
        
    authorisationFee: 'Authorisation fee (per transaction) (£) ***',
    gatewayFee: 'Gateway fee (per transaction) (£) **',	
    refundFee: 'Refund fee (per instance) (£) *****',
    chargebackFee: 'Chargeback fee (per instance) (£) ******',	
        
    dinersCardsFee: 'Diners/Discover Cards fee',	
    unionPayCardsFee: 'UnionPay Cards fee',	
    amexCardsFee: 'American Express Cards fee',	
        
    openBankingFee: 'Open Banking',	
    payByBankFee: 'Pay By Bank',
    klarnaFee: 'Klarna fee',	
        
    settlementPeriod: 'Settlement Period (business days)',
        
    monthlyRental: 'Monthly rental per TID GBP',

    definitions: <>
        * As per Definitions <br />
        ** Fee applied in sending an authorisation request to a Payment Scheme. This fee applies regardless of the next action (void or charge) <br />
        *** Fee applied on successful authorisation request to a Payment Scheme. <br />
        **** Fee applied for processing of each Settlement <br />
        ***** Fee applied for processing of each instance of the Refund request <br />
        ****** Fee applied as soon as the Chargeback process has been initiated (does not apply to reported fraud) <br />
        ******* Fee applied monthly for ongoing PCI compliance certification <br />
    </>
})
