import { Alert, Col, Divider, Popover, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { FormTitle } from 'startapp/components'
import translations from './translations'
import styles from './DocuSignForm.scss'
import { DocuSignFormPropsType } from './props'
import { CustomButton, CustomModal, DNALoader, ReloadButton } from '~/code/components'
import { SUCCESS_STATUS } from '../../services/constants'

const { Link } = Typography

export const DocuSignForm = observer(({ store }: DocuSignFormPropsType) => {
  useEffect(() => {
    if (!store.dataStore.isDocusignSignedSuccessfully && store.dataStore.sentToDocusign) {
      store.checkDocusignStatus()
    }
  }, [])

  useEffect(() => {
    if (!store.dataStore.isDocusignSignedSuccessfully && store.dataStore.sentToDocusign) {
      store.checkDocusignStatus()
    }
  }, [store.dataStore.sentToDocusign])

  const signee = store.dataStore.application?.companyOfficerList?.find(o => o.agreementSignatory === true) || false

  return (
    <>
      {store.isCheckStatusLoading && <DNALoader />}
      <FormTitle>{translations().docuSign}</FormTitle>
      <div className={styles.additionalDocumentsContainer}>
        {translations().docuSignInfo}
        {store.dataStore.sentToDocusign && (
          <Row>
            <div className={styles.flex}>
              <Link onClick={() => store.checkDocusignStatus()}>{translations().refreshStatus}</Link>
              <ReloadButton onClick={() => store.checkDocusignStatus()} />
            </div>
          </Row>
        )}
        <Alert
          className={styles.styledAlert}
          message={
            <>
              <p>{translations().currentStatus(store.dataStore.docuSignStatus)}</p>
              {store.dataStore.docuSignStatus === 'wait' && (
                <>
                  <p>
                    <i>{translations().note}</i>
                  </p>
                  <p>
                    <i>{translations().note2}</i>
                  </p>
                  <p>
                    <Popover content={<Alert type='warning' message={translations().docusignStatusCheck} />}>
                      <b>
                        <i>{translations().updateLogic}</i>
                      </b>
                    </Popover>
                  </p>
                </>
              )}
            </>
          }
          type={store.dataStore.docuSignStatus === 'success' ? 'success' : 'warning'}
        />

        <Row className={styles.emailSection}>
          {signee && (
            <b>
              {`${translations().signatoryEmail} 
            ${signee?.emailAddress} `}
            </b>
          )}
          {!signee && <Alert className={styles.styledAlert} message={translations().signeeNotFound} type='warning' />}
        </Row>
        <b>{translations().needToChangeEmail}</b>
        <div>{translations().changeEmailText}</div>

        <div className={styles.note}>
          <b>{translations().alreadySent}</b>
          <div>{translations().alreadySentCase}</div>
          <ul>
            <li>{translations().clickCancellation}</li>
            <li>{translations().amendData}</li>
            <li>{translations().clickGenerate}</li>
            <li>{translations().clickTheButton}</li>
          </ul>
        </div>

        {store.dataStore.sentToDocusign && store.dataStore.docuSignStatus !== SUCCESS_STATUS && (
          <div className={styles.note}>
            <Alert
              message={
                <Row gutter={10}>
                  <Col span={18}>{translations().resendText}</Col>
                  <Col span={6}>
                    <CustomButton
                      disabled={!store.isResendTimeoutDone}
                      loading={store.isResendEmailLoading}
                      onClick={() => store.resendDocusignEmailToClient()}
                    >
                      {translations().resend}
                    </CustomButton>
                  </Col>
                </Row>
              }
            />
          </div>
        )}

        {signee && (
          <Row gutter={10}>
            <Col span={8}>
              <CustomButton
                disabled={store.dataStore.sentToDocusign || store.dataStore.isDocusignSignedSuccessfully}
                className={styles.styledButton}
                onClick={() => store.setShowModal(true)}
              >
                {translations().preview}
              </CustomButton>
            </Col>
            <Col span={8}>
              <CustomButton
                disabled={
                  store.dataStore?.envelopeId === '' ||
                  store.dataStore.sentToDocusign ||
                  store.dataStore.isDocusignSignedSuccessfully
                }
                type='primary'
                className={styles.styledButton}
                loading={store.isSaveLoading}
                onClick={() => store.passDocuSignToBackend()}
              >
                {translations().sign}
              </CustomButton>
            </Col>

            <Col span={8}>
              <CustomButton
                className={styles.styledButton}
                onClick={() => store.setShowCancellationModal(true)}
                disabled={!store.dataStore.sentToDocusign}
              >
                {translations().cancellation}
              </CustomButton>
            </Col>

            <Col span={8}>
              <CustomButton className={styles.styledButton} onClick={() => store.handleManualMSAChange()}>
                {translations().willUploadManually}
              </CustomButton>
            </Col>

            <Col span={8}>
              <CustomButton
                loading={store.dataStore.isSaveLoading}
                type='primary'
                className={styles.styledButton}
                onClick={() => store.onDocusignSave()}
              >
                {translations().save}
              </CustomButton>
            </Col>
          </Row>
        )}
      </div>
      <Divider />

      <CustomModal
        title={translations().attention}
        open={store.showCancellationModal}
        footer={
          <Row gutter={10}>
            <Col>
              <CustomButton onClick={() => store.setShowCancellationModal(false)}>{translations().back}</CustomButton>
            </Col>
            <Col>
              <CustomButton loading={store.isCancelLoading} onClick={() => store.handleCancelClick()} type='primary'>
                {translations().revoke}
              </CustomButton>
            </Col>
          </Row>
        }
      >
        {translations().cancellationText}
      </CustomModal>

      <CustomModal
        title={translations().review}
        open={store.showModal}
        onCancel={() => store.setShowModal(false)}
        footer={
          <Row gutter={10}>
            <Col>
              <CustomButton
                type='primary'
                onClick={async () => await store.signDocument()}
                loading={store.isSignDocumentLoading}
              >
                {translations().detailsAreCorrect}
              </CustomButton>
            </Col>
            <Col>
              <CustomButton
                type={store.dataStore.envelopeId ? 'primary' : 'default'}
                onClick={() => store.setShowModal(false)}
              >
                {translations().backToForm}
              </CustomButton>
            </Col>
          </Row>
        }
      >
        <b>{translations().doubleCheck}</b>
        <div className={styles.details}>
          {translations().detailsInfo(
            store.dataStore.agreementOfficer?.nameElements?.forename,
            store.dataStore.agreementOfficer?.nameElements?.surname,
            `${store.dataStore.agreementOfficer?.dateOfBirth?.day}.${store.dataStore.agreementOfficer?.dateOfBirth?.month}.${store.dataStore.agreementOfficer?.dateOfBirth?.year}`,
            `${store.dataStore.agreementOfficer?.address?.postalCode}, ${store.dataStore.agreementOfficer?.address?.addressLine1}`
          )}
        </div>

        {store.isSignDocumentLoading && <DNALoader />}
      </CustomModal>
    </>
  )
})
