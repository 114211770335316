import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    id: string
    date: string
    period: string
    merchantName: string
    amount: string
    currency: string
    status: string
    reset: string
    mid: string
    download: string
}>()

