import { message } from 'antd'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import {
  tabKey,
  IProcessStore,
  NotesModel,
  CheckingResultsModel
} from '../pages/BPM/Onboarding/components/Processes/components/Process/types'
import { BPMApplicationModel } from '../pages/BPM/Onboarding/models'
import {
  fetchCheckingResults,
  fetchProcessApplicationData,
  fetchProcessInfo,
  fetchProcessNotes
} from '../services/fetchers'
import translations from '../pages/BPM/Onboarding/components/Processes/components/Process/translations'
import { fetchDocument } from '../pages/BPM/Onboarding/services/fetchers'

export class OnboardingProcessStore implements IProcessStore {
  processID: string = ''

  allProcessInfo = {} // { processID: BPMApplicationModel }
  isProcessInfoLoading: boolean = false

  allApplicationData = {} // { processID: applicationData }
  isApplicationDataLoading: boolean = false

  allProcessNotes = {} // { processID: NotesModel }
  isNotesLoading: boolean = false

  allCheckingResults = {} // { processID: CheckingResultsModel }
  isCheckingResultsLoading: boolean = false
  isDocumentsLoading: boolean = false

  constructor() {
    makeObservable(this, {
      processID: observable,
      allProcessInfo: observable,
      isProcessInfoLoading: observable,
      allApplicationData: observable,
      isApplicationDataLoading: observable,
      allProcessNotes: observable,
      isNotesLoading: observable,
      allCheckingResults: observable,
      isCheckingResultsLoading: observable,
      isDocumentsLoading: observable,

      processInfo: computed,
      applicationData: computed,
      notes: computed,
      checkingResults: computed,

      setProcessId: action,
      loadProcessInfo: action,
      loadApplicationData: action,
      loadNotes: action,
      loadCheckingResults: action,
      loadDocumentsData: action.bound
    })
  }

  init() {
    this.loadProcessInfo()
    this.loadApplicationData()
  }

  setProcessId(id: string) {
    this.processID = id
  }

  get processInfo(): BPMApplicationModel {
    return this.allProcessInfo[this.processID]
  }

  get applicationData() {
    return this.allApplicationData[this.processID]
  }

  get notes(): NotesModel {
    return this.allProcessNotes[this.processID]
  }

  get checkingResults(): CheckingResultsModel {
    return this.allCheckingResults[this.processID]
  }

  onTabChange(activeTabKey: tabKey): void {
    switch (activeTabKey) {
      case 'applicationData':
        this.loadApplicationData()
        break
      case 'attachedDocuments':
        break
      case 'checkingResults':
        this.loadCheckingResults()
        break
      case 'notes':
        this.loadNotes()
        break
      default:
        break
    }
  }

  async loadProcessInfo() {
    if (this.allProcessInfo[this.processID]) return
    try {
      runInAction(() => {
        this.isProcessInfoLoading = true
      })

      const { status, error, result } = await fetchProcessInfo(this.processID)
      if (status !== 200 || error) {
        message.error(error.message || translations().errLoadingProcessInfo)
      } else {
        runInAction(() => {
          this.allProcessInfo[this.processID] = result
        })
      }
    } catch (error) {
      message.error(translations().errLoadingProcessInfo)
    } finally {
      runInAction(() => {
        this.isProcessInfoLoading = false
      })
    }
  }

  async loadApplicationData() {
    if (this.allApplicationData[this.processID]) return
    try {
      runInAction(() => {
        this.isApplicationDataLoading = true
      })

      const { status, error, result } = await fetchProcessApplicationData(this.processID)
      if (status !== 200 || error) {
        message.error(error.message || translations().errLoadingApplicationData)
      } else {
        runInAction(() => {
          this.allApplicationData[this.processID] = result
        })
      }
    } catch (error) {
      message.error(translations().errLoadingApplicationData)
    } finally {
      runInAction(() => {
        this.isApplicationDataLoading = false
      })
    }
  }

  async loadNotes() {
    if (this.allProcessNotes[this.processID]) return
    try {
      runInAction(() => {
        this.isNotesLoading = true
      })

      const { status, error, result } = await fetchProcessNotes(this.processID)
      if (status !== 200 || error) {
        message.error(error.message || translations().errLoadingNotes)
      } else {
        runInAction(() => {
          this.allProcessNotes[this.processID] = result
        })
      }
    } catch (error) {
      message.error(translations().errLoadingNotes)
    } finally {
      runInAction(() => {
        this.isNotesLoading = false
      })
    }
  }

  async loadCheckingResults() {
    if (this.allCheckingResults[this.processID]) return
    try {
      runInAction(() => {
        this.isCheckingResultsLoading = true
      })

      const { error, status, result } = await fetchCheckingResults(this.processID)
      if (status !== 200 || error) {
        message.error(error.message || translations().errLoadingCheckingResults)
      } else {
        runInAction(() => {
          this.allCheckingResults[this.processID] = result
        })
      }
    } catch (error) {
      message.error(translations().errLoadingCheckingResults)
    } finally {
      runInAction(() => {
        this.isCheckingResultsLoading = false
      })
    }
  }

  async loadDocumentsData(path: string, name: string, location?: string) {
    try {
      runInAction(() => {
        this.isDocumentsLoading = true
      })
      await fetchDocument(this.processID, path, name, location)
    } catch (e) {
      message.error(translations().errorLoadingDocs, 5)
    } finally {
      runInAction(() => {
        this.isDocumentsLoading = false
      })
    }
  }
}
