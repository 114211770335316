import { injectable } from 'inversify'
import { action, computed, makeObservable, observable } from 'mobx'
import { Moment } from 'moment-timezone'
import { PeriodType } from '~/code/models'
import { getDates, getPeriod, getPrevDates } from '~/code/services/utils'

@injectable()
export class RangePickerStore {
  period: PeriodType = null
  startDate: Moment = null
  endDate: Moment = null

  constructor() {

    makeObservable(this, {
      period: observable,
      startDate: observable,
      endDate: observable,
      prevDates: computed,
      setDates: action.bound,
      setPeriod: action.bound
    })
  }

  get prevDates() {
    return getPrevDates(this.startDate, this.endDate, this.period)
  }

  setDates(startDate: Moment, endDate: Moment) {
    this.startDate = startDate
    this.endDate = endDate
    this.period = getPeriod(startDate, endDate)
  }

  setPeriod(period: PeriodType) {
    const { startDate, endDate } = getDates(period)
    this.startDate = startDate
    this.endDate = endDate
    this.period = period
  }
}
