import React from 'react'
import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { PopupMobileProps } from './props'
import translations from './translations'
import styles from './styles.scss'

export const PopupMobile: React.FC<PopupMobileProps> = ({ className, notificationSetupForm }) => {
  const { title, description, actionButtonUrl, actionButtonName } = notificationSetupForm

  const renderNotification = () => (
    <div className={styles.previewModalWrapper}>
      <div className={styles.previewModalTitle}>
        <div>{title}</div>
        <Button size='small' type='text' icon={<CloseOutlined />} />
      </div>
      <div className={styles.previewModalContent}>
        <div className={styles.previewModalDescription}>
          {description}
        </div>
        <div className={styles.previewButtonsWrapper}>
          <div className={styles.previewButtons}>
            <Button size='small'>{translations().close}</Button>
            <Button
              type='primary'
              size='small'
              onClick={() => actionButtonUrl && window.open(actionButtonUrl, '_blank')}
            >
              {actionButtonName || translations().ok}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )

  const renderTableItems = (rows: number) => (
    Array.from({ length: rows }, (_, index) => (
      <div className={styles.tableRow} key={index}>
        <div className={styles.tableItem}></div>
        <div className={classNames(styles.tableItem, styles.long)}></div>
        <div className={classNames(styles.tableItem)}></div>
        <div className={styles.tableItem}></div>
      </div>
    ))
  )

  return (
    <div className={styles.previewWrapper}>
      <div className={styles.shadowLayout}></div>
      <div className={classNames(styles.header, styles[className])}>
        <div className={styles.logo}>
          <div className={classNames(styles.circle, styles.yellow, styles.mrFive)}></div>
          <div className={styles.bar}></div>
        </div>
        <div className={styles.userInfo}>
          <div className={styles.bar}></div>
          <div className={classNames(styles.circle, styles.grey, styles.mlFive)}></div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.tableContainer}>
          <div className={styles.table}>
            <div className={styles.tableHead}>
              <div className={styles.headItem}></div>
            </div>
            {renderTableItems(5)}
            <div className={styles.tableHead}>
              <div className={styles.headItem}></div>
            </div>
            {renderTableItems(7)}
          </div>
        </div>
      </div>
      {renderNotification()}
    </div>
  )
}