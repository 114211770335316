import { log, error as errorLog } from 'dna-common'
import { action, autorun, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import { AnalyticsPosOverviewSummaryRequest } from '~/code/models/analytics/AnalyticsPosOverviewSummaryRequest'
import { StatusSummaryGroupPos, StatusSummaryPos } from '~/code/models/analytics/AnalyticsPosSummaryResponse'
import {
  IAnalyticsOverviewStore,
  MerchantsBreakdownData,
  SummaryRowData,
  SummaryRowDataPercentage
} from '~/code/pages/Acquiring/pages/Analytics/components'
import {
  fetchAnalyticsPosStatusSummary,
  fetchPosTopCardSchemesSummary,
  fetchPosTopMCCSummary,
  fetchPosTopIssuingBanksSummary,
  fetchPosTopMerchantsSummary,
  fetchPosErrorCodes,
  fetchAnalyticsYearsComparison,
  fetchPosTransactionsSummary,
  fetchPosTopAcquisitionChannelsSummary,
  fetchAnalyticsPosApprovalRateSummary
} from '~/code/services/fetchers'
import numeral from 'numeral'
import { IAcquiringFilterStore } from '~/code/pages/Acquiring/components/AcquiringFilter'
import {
  PCSBreakdownData,
  PCSDataTypeBreakdown
} from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/breakdown/PCSBreakdown/models'
import { backgroundColors, cardTypeIcons } from './constants/colors-icons'
import {
  filterIntervalOptions,
  generateBackgroundColorsForCardTypes,
  getYearsComparisonData,
  processApprovalRates
} from './services/utils'
import { EntitySummary, EntitySummaryGroup } from '~/code/models/analytics/AnalyticsSummaryResponse'
import translations from '~/code/translations/translations'
import { MCCBreakdownData } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/breakdown/MCCBreakdown/models'
import { IssuingBanksBreakdownData } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/breakdown/IBBreakdown/models'
import { ErrorCodesPosBreakdownData } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/breakdown/ErrorCodesBreakdown/models'
import { UniqueFieldNameType } from './models/UniqueFieldNameType'
import { AnalyticsYearsComparisonRequest } from '~/code/models/analytics/AnalyticsYearsComparisonRequest'
import { YearsComparisonData } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/YearsComparison/models'
import moment from 'moment'
import { StatusType } from './models/StatusType'
import { TabKey } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/chart-related/SumCountCard/models'
import { IntervalType } from './models/IntervalType'
import {
  generateTransactionsLineChartData,
  TransactionsLineChartData
} from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/breakdown/TransactionsLineChart/models'
import { message } from 'antd'
import { isPartner } from '~/code/services/auth'
import { AcquisitionChannelsBreakdownData } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/breakdown/AcquisitionChannelsBreakdown/models'
import { GlobalConfigStore } from '../GlobalConfigStore'
import { Routes } from '~/code/startup/Router/Routes'
import { ApprovalRateData } from '~/code/models/analytics/AnalyticsApprovalRateSummary'

export class AnalyticsPosOverviewStore implements IAnalyticsOverviewStore {
  shouldLoadData: boolean = true // by default the selected tab is analytics overview
  observablesHaveChanged: boolean = true // by default the selected tab is analytics overview

  isLoadingSummary: boolean = false
  summaryRowData: SummaryRowData = null
  summaryRowDataPercentage: SummaryRowDataPercentage = null

  isLoadingApprovalRate: boolean = false
  approvalRateData: ApprovalRateData = null

  transactionsSumStatus: StatusType = 'all'
  transactionsCountStatus: StatusType = 'all'
  transactionsCurrentTab: TabKey = 'amount'
  _transactionsInterval: IntervalType = null
  transactionsLineChartData: TransactionsLineChartData = generateTransactionsLineChartData()
  legendPosition: string = 'bottom'

  isLoadingPCSAmountBreakdown: boolean = false
  isLoadingPCSCountBreakdown: boolean = false
  pcsBreakdownData: PCSBreakdownData = null

  isLoadingMCCAmountBreakdown: boolean = false
  isLoadingMCCCountBreakdown: boolean = false
  mccBreakdownData: MCCBreakdownData = null

  isLoadingIssuingBanksAmountBreakdown: boolean = false
  isLoadingIssuingBanksCountBreakdown: boolean = false
  issuingBanksBreakdownData: IssuingBanksBreakdownData = null

  isLoadingMerchantsAmountBreakdown: boolean = false
  isLoadingMerchantsCountBreakdown: boolean = false
  merchantsBreakdownData: MerchantsBreakdownData = null

  isLoadingErrorCodesAmountBreakdown: boolean = false
  isLoadingErrorCodesCountBreakdown: boolean = false
  errorCodesBreakdownData: ErrorCodesPosBreakdownData = null

  isLoadingAcquisitionChannelsAmountBreakdown: boolean = false
  isLoadingAcquisitionChannelsCountBreakdown: boolean = false
  acquisitionChannelsBreakdownData: AcquisitionChannelsBreakdownData = null

  isLoadingYearsComparison: boolean = false
  yearsComparisonData: YearsComparisonData = null
  yearsComparisonYear1: moment.Moment = moment().subtract(1, 'years')
  yearsComparisonYear2: moment.Moment = moment()

  constructor(filterStore: IAcquiringFilterStore, configStore: GlobalConfigStore) {
    this.filterStore = filterStore
    this.configStore = configStore

    makeObservable(this, {
      isLoadingSummary: observable,
      summaryRowData: observable,
      summaryRowDataPercentage: observable,
      loadStatusSummaryWrapper: action,

      isLoadingApprovalRate: observable,
      approvalRateData: observable,

      transactionsSumStatus: observable,
      transactionsCountStatus: observable,
      transactionsCurrentTab: observable,
      _transactionsInterval: observable,
      transactionsLineChartData: observable,
      legendPosition: observable,
      transactionsCurrentStatus: computed,
      transactionsInterval: computed,
      transactionsIntervalOptions: computed,
      setTransactionsSumStatus: action.bound,
      setTransactionsCountStatus: action.bound,
      setTransactionsCurrentTab: action.bound,
      setTransactionsInterval: action.bound,
      loadTransactionsBreakdown: action,
      clearTransactionsLineChartData: action,
      setLegendPosition: action,

      isLoadingPCSAmountBreakdown: observable,
      isLoadingPCSCountBreakdown: observable,
      pcsBreakdownData: observable,

      isLoadingMCCAmountBreakdown: observable,
      isLoadingMCCCountBreakdown: observable,
      mccBreakdownData: observable,

      isLoadingIssuingBanksAmountBreakdown: observable,
      isLoadingIssuingBanksCountBreakdown: observable,
      issuingBanksBreakdownData: observable,

      isLoadingMerchantsAmountBreakdown: observable,
      isLoadingMerchantsCountBreakdown: observable,
      merchantsBreakdownData: observable,

      isLoadingErrorCodesAmountBreakdown: observable,
      isLoadingErrorCodesCountBreakdown: observable,
      errorCodesBreakdownData: observable,

      isLoadingAcquisitionChannelsAmountBreakdown: observable,
      isLoadingAcquisitionChannelsCountBreakdown: observable,
      acquisitionChannelsBreakdownData: observable,

      isLoadingYearsComparison: observable,
      yearsComparisonData: observable,
      yearsComparisonYear1: observable,
      yearsComparisonYear2: observable,
      loadYearsComparison: action,
      changeYearsComparisonYears: action,

      refreshTable: action.bound,

      loadBreakdown: action,
      loadApprovalRateSummary: action,
      merchantId: computed,
      merchantName: computed,
      acquisitionChannelId: computed,
      statusSummaryPercentageChange: computed,
      currency: computed,
      currencySymbol: computed
    })
  }

  filterStore: IAcquiringFilterStore
  configStore: GlobalConfigStore

  // TODO find an elegant solution for loading data on radio button change
  setShouldLoadData = (value: boolean) => {
    this.shouldLoadData = value
    if (value) {
      this.loadData(this.loadDataParams)
      this.observablesHaveChanged = true
    } else {
      this.observablesHaveChanged = false
    }
  }

  public async init() {
    reaction(() => this.loadDataParams, this.loadData)

    reaction(() => this.transactionsInterval, this.loadTransactionsBreakdown.bind(this))

    autorun(
      () => {
        const { isMerchantListLoading, merchants } = this.filterStore.merchantSelectStore
        if (!isMerchantListLoading && merchants.length === 0) {
          this.isLoadingSummary = false
        }
      },
      { delay: 1 }
    )

    autorun(
      () => {
        // if user is not on current page, do not make requests to backend on currency change
        if (window.location.pathname !== Routes.TRANSACTIONS_DNA_ACQUIRING_ANALYTICS_POS) return
        const currency = this.currency
        if (
          this.yearsComparisonYear1 &&
          this.yearsComparisonYear2 &&
          this.shouldLoadData &&
          this.merchantName &&
          currency
        ) {
          this.loadYearsComparison({
            year1: this.yearsComparisonYear1.get('year').toString(),
            year2: this.yearsComparisonYear2.get('year').toString(),
            merchantId: this.merchantId,
            currency,
            acquisitionChannel: this.acquisitionChannelId
          })
        }
      },
      { delay: 5 }
    )

    autorun(() => {
      const {
        dateStore: { startDate, endDate }
      } = this.filterStore

      if (startDate && endDate) {
        this.setTransactionsInterval(this.transactionsIntervalOptions[1] || this.transactionsIntervalOptions[0])
      }
    })
  }

  public refreshTable() {
    this.setShouldLoadData(true)
  }

  loadData = ({
    startDate,
    endDate,
    store,
    terminal,
    merchantName,
    currency,
    shouldLoadData,
    observablesHaveChanged
  }) => {
    if (window.location.pathname !== Routes.TRANSACTIONS_DNA_ACQUIRING_ANALYTICS_POS) return
    if (merchantName !== 'ALL' && !this.merchantId) return

    if (startDate && endDate && store && terminal && shouldLoadData && observablesHaveChanged && currency) {
      this.loadStatusSummaryWrapper()
      this.clearTransactionsLineChartData()
      this.loadTransactionsBreakdown()
      this.loadBreakdown(
        { ...this.summaryRequestPosParams, orderBy: 'amount' },
        'merchant',
        this.setMerchantsBreakdownData,
        this.setLoadingMerchantsAmountBreakdown,
        fetchPosTopMerchantsSummary
      )
      this.loadBreakdown(
        { ...this.summaryRequestPosParams, orderBy: 'count' },
        'merchant',
        this.setMerchantsBreakdownData,
        this.setLoadingMerchantsCountBreakdown,
        fetchPosTopMerchantsSummary
      )
      this.loadBreakdown(
        { ...this.summaryRequestPosParams, orderBy: 'amount' },
        'issuer',
        this.setIssuingBanksBreakdownData,
        this.setLoadingIssuingBanksAmountBreakdown,
        fetchPosTopIssuingBanksSummary,
        true
      )
      this.loadBreakdown(
        { ...this.summaryRequestPosParams, orderBy: 'count' },
        'issuer',
        this.setIssuingBanksBreakdownData,
        this.setLoadingIssuingBanksCountBreakdown,
        fetchPosTopIssuingBanksSummary,
        true
      )
      this.loadBreakdown(
        { ...this.summaryRequestPosParams, orderBy: 'amount' },
        'mcc',
        this.setMCCBreakdownData,
        this.setLoadingMCCAmountBreakdown,
        fetchPosTopMCCSummary,
        true
      )
      this.loadBreakdown(
        { ...this.summaryRequestPosParams, orderBy: 'count' },
        'mcc',
        this.setMCCBreakdownData,
        this.setLoadingMCCCountBreakdown,
        fetchPosTopMCCSummary,
        true
      )
      this.loadBreakdown(
        { ...this.summaryRequestPosParams, orderBy: 'amount' },
        'cardScheme',
        this.setPCSBreakdownData,
        this.setLoadingPCSAmountBreakdown,
        fetchPosTopCardSchemesSummary,
        true,
        cardTypeIcons
      )
      this.loadBreakdown(
        { ...this.summaryRequestPosParams, orderBy: 'count' },
        'cardScheme',
        this.setPCSBreakdownData,
        this.setLoadingPCSCountBreakdown,
        fetchPosTopCardSchemesSummary,
        true,
        cardTypeIcons
      )
      this.loadBreakdown(
        { ...this.summaryRequestPosParams, orderBy: 'amount' },
        'errorCodes',
        this.setErrorCodesBreakdownData,
        this.setLoadingErrorCodesAmountBreakdown,
        fetchPosErrorCodes,
        true
      )
      this.loadBreakdown(
        { ...this.summaryRequestPosParams, orderBy: 'count' },
        'errorCodes',
        this.setErrorCodesBreakdownData,
        this.setLoadingErrorCodesCountBreakdown,
        fetchPosErrorCodes,
        true
      )

      if (!isPartner()) {
        this.loadApprovalRateSummary()
        this.loadBreakdown(
          { ...this.summaryRequestPosParams, orderBy: 'amount' },
          'acquisitionChannel',
          this.setAcquisitionChannelsBreakdownData,
          this.setLoadingAcquisitionChannelsAmountBreakdown,
          fetchPosTopAcquisitionChannelsSummary,
          true
        )
        this.loadBreakdown(
          { ...this.summaryRequestPosParams, orderBy: 'count' },
          'acquisitionChannel',
          this.setAcquisitionChannelsBreakdownData,
          this.setLoadingAcquisitionChannelsCountBreakdown,
          fetchPosTopAcquisitionChannelsSummary,
          true
        )
      }
    } else {
      this.observablesHaveChanged = true
    }
  }

  get currency() {
    return this.configStore.currency.type
  }

  get currencySymbol() {
    return this.configStore.currency.symbol
  }

  get summaryRequestPosParams(): AnalyticsPosOverviewSummaryRequest {
    const {
      dateStore: { startDate, endDate },
      storeAndTerminalFilterStore: { terminalsForFilter }
    } = this.filterStore
    return {
      from: startDate.format(),
      to: endDate.format(),
      merchantId: this.merchantId ? this.merchantId : undefined,
      currency: this.currency,
      top: 5,
      acquisitionChannel: this.acquisitionChannelId,
      terminalId: terminalsForFilter.length > 0 ? terminalsForFilter : undefined
    }
  }

  get prevStatusSummaryParams(): AnalyticsPosOverviewSummaryRequest {
    const {
      dateStore: {
        prevDates: { prevStartDate, prevEndDate }
      },
      storeAndTerminalFilterStore: { terminalsForFilter }
    } = this.filterStore
    return {
      from: prevStartDate.format(),
      to: prevEndDate.format(),
      merchantId: this.merchantId ? this.merchantId : undefined,
      currency: this.currency,
      top: 5,
      acquisitionChannel: this.acquisitionChannelId,
      terminalId: terminalsForFilter.length > 0 ? terminalsForFilter : undefined
    }
  }

  get statusSummaryPercentageChange() {
    const totalSum = this.summaryRowDataPercentage?.current?.totalSum
    const totalCount = this.summaryRowDataPercentage?.current?.totalCount
    const prevTotalSum = this.summaryRowDataPercentage?.prev?.totalSum
    const prevTotalCount = this.summaryRowDataPercentage?.prev?.totalCount
    const amount = prevTotalSum && prevTotalSum > 0 ? ((totalSum - prevTotalSum) / prevTotalSum) * 100 : 0
    const count = prevTotalCount && prevTotalCount > 0 ? ((totalCount - prevTotalCount) / prevTotalCount) * 100 : 0
    return { amount, count }
  }

  get prevDates() {
    return this.filterStore.dateStore.prevDates
  }

  get merchantId() {
    return this.filterStore.merchantId
  }

  get merchantName() {
    return this.filterStore.merchantTradeName
  }

  get acquisitionChannelId() {
    if (isPartner() || this.filterStore.acquisitionChannelId === 'all') return undefined
    return this.filterStore.acquisitionChannelId
  }

  get transactionsInterval(): IntervalType {
    return this._transactionsInterval
      ? this._transactionsInterval
      : this.transactionsIntervalOptions[1] || this.transactionsIntervalOptions[0]
  }

  get transactionsIntervalOptions(): IntervalType[] {
    return filterIntervalOptions(this.filterStore.dateStore.startDate, this.filterStore.dateStore.endDate)
  }

  get transactionsCurrentStatus(): StatusType {
    return this.transactionsCurrentTab === 'amount' ? this.transactionsSumStatus : this.transactionsCountStatus
  }

  setTransactionsSumStatus(status: StatusType) {
    this.transactionsSumStatus = status
  }

  setTransactionsCountStatus(status: StatusType) {
    this.transactionsCountStatus = status
  }

  setTransactionsCurrentTab(tab: TabKey) {
    this.setLegendPosition(tab)
    this.transactionsCurrentTab = tab
  }

  setTransactionsInterval(interval: IntervalType) {
    this._transactionsInterval = interval
  }

  setLegendPosition = (tabKey: TabKey) => {
    if (tabKey === 'amount') {
      this.legendPosition = 'bottom'
    } else {
      this.legendPosition = ''
    }
  }

  setLoadingPCSAmountBreakdown = (value: boolean) => {
    this.isLoadingPCSAmountBreakdown = value
  }

  setLoadingPCSCountBreakdown = (value: boolean) => {
    this.isLoadingPCSCountBreakdown = value
  }

  setPCSBreakdownData = (data: PCSDataTypeBreakdown, fieldName) => {
    const dataToSet = { ...data }
    dataToSet.all.datasets[0].backgroundColor = generateBackgroundColorsForCardTypes(dataToSet.all.labels)
    dataToSet.successful.datasets[0].backgroundColor = generateBackgroundColorsForCardTypes(dataToSet.successful.labels)
    dataToSet.failed.datasets[0].backgroundColor = generateBackgroundColorsForCardTypes(dataToSet.failed.labels)
    this.pcsBreakdownData = { ...this.pcsBreakdownData, [fieldName]: data }
  }

  setLoadingMCCAmountBreakdown = (value: boolean) => {
    this.isLoadingMCCAmountBreakdown = value
  }

  setLoadingMCCCountBreakdown = (value: boolean) => {
    this.isLoadingMCCCountBreakdown = value
  }

  setMCCBreakdownData = (data, fieldName) => {
    this.mccBreakdownData = { ...this.mccBreakdownData, [fieldName]: data }
  }

  setLoadingIssuingBanksAmountBreakdown = (value: boolean) => {
    this.isLoadingIssuingBanksAmountBreakdown = value
  }

  setLoadingIssuingBanksCountBreakdown = (value: boolean) => {
    this.isLoadingIssuingBanksCountBreakdown = value
  }

  setIssuingBanksBreakdownData = (data, fieldName) => {
    this.issuingBanksBreakdownData = { ...this.issuingBanksBreakdownData, [fieldName]: data }
  }

  setLoadingMerchantsAmountBreakdown = (value: boolean) => {
    this.isLoadingMerchantsAmountBreakdown = value
  }

  setLoadingMerchantsCountBreakdown = (value: boolean) => {
    this.isLoadingMerchantsCountBreakdown = value
  }

  setMerchantsBreakdownData = (data, fieldName) => {
    this.merchantsBreakdownData = { ...this.merchantsBreakdownData, [fieldName]: data }
  }

  setLoadingErrorCodesAmountBreakdown = (value: boolean) => {
    this.isLoadingErrorCodesAmountBreakdown = value
  }

  setLoadingErrorCodesCountBreakdown = (value: boolean) => {
    this.isLoadingErrorCodesCountBreakdown = value
  }

  setErrorCodesBreakdownData = (data, fieldName) => {
    this.errorCodesBreakdownData = { ...this.errorCodesBreakdownData, [fieldName]: data }
  }

  setLoadingAcquisitionChannelsAmountBreakdown = (value: boolean) => {
    this.isLoadingAcquisitionChannelsAmountBreakdown = value
  }

  setLoadingAcquisitionChannelsCountBreakdown = (value: boolean) => {
    this.isLoadingAcquisitionChannelsCountBreakdown = value
  }

  setAcquisitionChannelsBreakdownData = (data, fieldName) => {
    this.acquisitionChannelsBreakdownData = { ...this.acquisitionChannelsBreakdownData, [fieldName]: data }
  }

  loadStatusSummaryWrapper = async () => {
    this.isLoadingSummary = true
    await Promise.all([
      this.loadStatusSummary(this.summaryRequestPosParams, 'current'),
      this.loadStatusSummary(this.prevStatusSummaryParams, 'prev')
    ])
    this.isLoadingSummary = false
  }

  loadStatusSummary = async (params: AnalyticsPosOverviewSummaryRequest, statusSummaryType: 'current' | 'prev') => {
    delete params.top
    const { error, result } = await fetchAnalyticsPosStatusSummary(params)
    if (error) {
      log('Error loading status summary:', error)
      runInAction(() => {
        this.isLoadingSummary = false
      })
      return
    }

    runInAction(() => {
      if (statusSummaryType === 'current') {
        this.processStatusSummaryRowData(result)
      } else {
        this.processPrevStatusSummaryRowData(result)
      }
    })
  }

  private processStatusSummaryRowData = async (statusSummary: StatusSummaryGroupPos) => {
    const successfulData = statusSummary.successful
    const failedData = statusSummary.failed
    const allData = statusSummary.all

    // as we use only GBP the current implementation uses only GBP
    const successfulCurrencyData = successfulData.find(item => item.currency === this.currency) || {
      amount: 0,
      count: 0,
      currency: this.currency
    }
    const failedCurrencyData = failedData.find(item => item.currency === this.currency) || {
      amount: 0,
      count: 0,
      currency: this.currency
    }
    const allCurrencyData = allData.find(item => item.currency === this.currency) || {
      amount: 0,
      count: 0,
      currency: this.currency
    }

    const totalSum = allCurrencyData.amount
    const totalCount = allCurrencyData.count
    const numberOfDays = this.filterStore.dateStore.endDate.diff(this.filterStore.dateStore.startDate, 'days') + 1

    this.summaryRowDataPercentage = { ...this.summaryRowDataPercentage, current: { totalSum, totalCount } }

    this.summaryRowData = {
      sum: {
        total: `${this.currencySymbol}${numeral(totalSum).format('0,0.00')}`,
        successful: `${this.currencySymbol}${numeral(successfulCurrencyData.amount).format('0,0.00')}`,
        successfulPercent: `${numeral((successfulCurrencyData.amount * 100) / totalSum).format('0,0.00')}%`,
        failed: `${this.currencySymbol}${numeral(failedCurrencyData.amount).format('0,0.00')}`,
        failedPercent: `${numeral((failedCurrencyData.amount * 100) / totalSum).format('0,0.00')}%`,
        average: {
          successful: `${this.currencySymbol}${numeral(
            (successfulCurrencyData.amount / numberOfDays).toFixed(2)
          ).format('0,0.00')}`,
          failed: `${this.currencySymbol}${numeral((failedCurrencyData.amount / numberOfDays).toFixed(2)).format(
            '0,0.00'
          )}`
        }
      },
      count: {
        total: `${numeral(totalCount).format('0,0')}`,
        successful: `${numeral(successfulCurrencyData.count).format('0,0')}`,
        successfulPercent: `${numeral((successfulCurrencyData.count * 100) / totalCount).format('0,0.00')}%`,
        failed: `${numeral(failedCurrencyData.count).format('0,0')}`,
        failedPercent: `${numeral((failedCurrencyData.count * 100) / totalCount).format('0,0.00')}%`,
        average: {
          successful: `${numeral((successfulCurrencyData.count / numberOfDays).toFixed(2)).format('0,0.00')}`,
          failed: `${numeral((failedCurrencyData.count / numberOfDays).toFixed(2)).format('0,0.00')}`
        }
      }
    }
  }

  private processPrevStatusSummaryRowData = async (statusSummary: StatusSummaryGroupPos) => {
    const allData = statusSummary.all

    // as we use only GBP the current implementation uses only GBP
    const allCurrencyData = allData.find(item => item.currency === this.currency) || {
      amount: 0,
      count: 0,
      currency: this.currency
    }

    const totalSum = allCurrencyData.amount
    const totalCount = allCurrencyData.count

    this.summaryRowDataPercentage = { ...this.summaryRowDataPercentage, prev: { totalSum, totalCount } }
  }

  loadApprovalRateSummary = async () => {
    runInAction(() => {
      this.isLoadingApprovalRate = true
    })
    const { error, result } = await fetchAnalyticsPosApprovalRateSummary({
      ...this.summaryRequestPosParams,
      top: undefined
    })
    if (error) {
      log('Error loading approval rate summary:', error)
      runInAction(() => {
        this.isLoadingApprovalRate = false
      })
      return
    }

    runInAction(() => {
      this.approvalRateData = processApprovalRates(result, this.currencySymbol)
      this.isLoadingApprovalRate = false
    })
  }

  ////////// COMMON LOAD BREAKDOWN DATA ////////////
  loadBreakdown = async (
    params: AnalyticsPosOverviewSummaryRequest,
    uniqueFieldName: UniqueFieldNameType,
    setData,
    setLoadingData,
    fetchFunction,
    shouldAddBackgroundColor?,
    icons?
  ) => {
    try {
      setLoadingData(true)
      const { err, result } = await fetchFunction(params)

      if (err) {
        errorLog(`Error loading ${uniqueFieldName} ${params.orderBy} summary:`, err)
        runInAction(() => {
          setLoadingData(false)
        })
        return
      }

      runInAction(() => {
        let data

        if (uniqueFieldName === 'errorCodes') {
          data = this.processErrorCodesData(result, params.orderBy)
        } else if (uniqueFieldName === 'acquisitionChannel') {
          data = this.processData(this.modifyResult(result), uniqueFieldName, params.orderBy, shouldAddBackgroundColor)
        } else {
          data = this.processData(result, uniqueFieldName, params.orderBy, shouldAddBackgroundColor)
        }

        if (icons) {
          data.all.icons = data.all.labels.map(label => icons[label.trim().toLowerCase()] || icons.unknown)
          data.successful.icons = data.successful.labels.map(
            label => icons[label.trim().toLowerCase()] || icons.unknown
          )
          data.failed.icons = data.failed.labels.map(label => icons[label.trim().toLowerCase()] || icons.unknown)
        }

        setData(data, params.orderBy)

        setLoadingData(false)
      })
    } catch (err) {
      errorLog(`Error loading ${uniqueFieldName} ${params.orderBy} summary`, err)
      runInAction(() => {
        setLoadingData(false)
      })
      return
    }
  }

  processData = (result: EntitySummaryGroup, uniqueFieldName, countAmount: string, shouldAddBackgroundColor?) => {
    const allTotal = result?.all?.reduce((prevVal, currVal) => prevVal + currVal[countAmount], 0) || 0
    const successfulTotal = result?.successful?.reduce((prevVal, currVal) => prevVal + currVal[countAmount], 0)
    const failedTotal = result?.failed?.reduce((prevVal, currVal) => prevVal + currVal[countAmount], 0)

    return {
      all: {
        total: allTotal,
        labels: result?.all?.map(item => item[uniqueFieldName] || translations().unknown) || [],
        subLabels: result?.all?.map(item => item[uniqueFieldName + 'Description']) || [],
        datasets: [
          {
            barThickness: 20,
            data: result?.all?.map(item => item[countAmount]) || [],
            percents: result?.all?.map(item => (item[countAmount] * 100) / allTotal) || [],
            backgroundColor: shouldAddBackgroundColor && backgroundColors
          }
        ],
        icons: undefined
      },
      successful: {
        total: successfulTotal,
        labels: result?.successful?.map(item => item[uniqueFieldName] || translations().unknown),
        subLabels: result?.successful?.map(item => item[uniqueFieldName + 'Description']),
        datasets: [
          {
            barThickness: 20,
            data: result?.successful?.map(item => item[countAmount]),
            percents: result?.successful?.map(item => (item[countAmount] * 100) / successfulTotal),
            backgroundColor: shouldAddBackgroundColor && backgroundColors
          }
        ],
        icons: undefined
      },
      failed: {
        total: failedTotal,
        labels: result?.failed?.map(item => item[uniqueFieldName] || translations().unknown),
        subLabels: result?.failed?.map(item => item[uniqueFieldName + 'Description']),
        datasets: [
          {
            barThickness: 20,
            data: result?.failed?.map(item => item[countAmount]),
            percents: result?.failed?.map(item => (item[countAmount] * 100) / failedTotal),
            backgroundColor: shouldAddBackgroundColor && backgroundColors
          }
        ],
        icons: undefined
      }
    }
  }

  processErrorCodesData(result: StatusSummaryPos[], countAmount: string) {
    const total = result?.reduce((prevVal, currVal) => prevVal + currVal[countAmount], 0) || 0

    return {
      total,
      labels: result?.map(item => item.code || translations().unknown) || [],
      subLabels: result?.map(item => item.description || []),
      datasets: [
        {
          barThickness: 20,
          data: result?.map(item => item[countAmount]) || [],
          percents: result?.map(item => (item[countAmount] * 100) / total) || [],
          backgroundColor: backgroundColors
        }
      ],
      icons: undefined
    }
  }

  modifyResult(result: EntitySummaryGroup) {
    return {
      all: result?.all?.map(item => this.processItem(item)),
      successful: result?.successful?.map(item => this.processItem(item)),
      failed: result?.failed?.map(item => this.processItem(item))
    }
  }

  processItem(item: EntitySummary) {
    const acquisitionChannelId = item['acquisitionChannel']
    const acquisitionChannel = this.filterStore.acquisitionChannels.find(a => a.id === acquisitionChannelId)
    if (acquisitionChannel) {
      return {
        ...item,
        acquisitionChannel: acquisitionChannel.name
      }
    }

    return item
  }

  loadYearsComparison = async (params: AnalyticsYearsComparisonRequest) => {
    if (this.merchantName !== 'ALL' && !this.merchantId) return

    this.isLoadingYearsComparison = true
    const { error, result } = await fetchAnalyticsYearsComparison('pos', params)

    if (error) {
      log('Error:', error)
      runInAction(() => {
        this.isLoadingYearsComparison = false
      })
    }

    runInAction(() => {
      const _year1 = this.yearsComparisonYear1.get('year')
      const _year2 = this.yearsComparisonYear2.get('year')

      this.yearsComparisonData = getYearsComparisonData(_year1, _year2, [result])
      this.isLoadingYearsComparison = false
    })
  }

  changeYearsComparisonYears = (year1, year2) => {
    this.yearsComparisonYear1 = year1
    this.yearsComparisonYear2 = year2
  }

  async loadTransactionsBreakdown() {
    if (!this.merchantName) return

    const summaryReqParams = this.summaryRequestPosParams
    delete summaryReqParams.top
    const currentTab = this.transactionsCurrentTab
    const currentStatus = this.transactionsCurrentStatus
    const currentInterval = this.transactionsInterval

    try {
      const hasLoaded: boolean = this.transactionsLineChartData[currentTab][currentStatus][currentInterval]['hasLoaded']
      if (hasLoaded) {
        return
      }

      runInAction(() => {
        this.transactionsLineChartData[currentTab]['isLoading'] = true
      })
      const { error, result } = await fetchPosTransactionsSummary({
        ...summaryReqParams,
        interval: this.transactionsInterval
      })

      if (error) {
        errorLog('Error loading transactions summary:', error)
        message.error('Error loading transactions summary: ' + error.message)
        return
      }

      runInAction(() => {
        this.transactionsLineChartData.amount.all[currentInterval] = this.processDataTransactions(result.all, 'amount')
        this.transactionsLineChartData.count.all[currentInterval] = this.processDataTransactions(result.all, 'count')
        this.transactionsLineChartData.amount.successful[currentInterval] = this.processDataTransactions(
          result.successful,
          'amount'
        )
        this.transactionsLineChartData.count.successful[currentInterval] = this.processDataTransactions(
          result.successful,
          'count'
        )
        this.transactionsLineChartData.amount.failed[currentInterval] = this.processDataTransactions(
          result.failed,
          'amount'
        )
        this.transactionsLineChartData.count.failed[currentInterval] = this.processDataTransactions(
          result.failed,
          'count'
        )

        this.transactionsLineChartData.amount.all[currentInterval].hasLoaded = true
        this.transactionsLineChartData.count.all[currentInterval].hasLoaded = true
        this.transactionsLineChartData.amount.successful[currentInterval].hasLoaded = true
        this.transactionsLineChartData.count.successful[currentInterval].hasLoaded = true
        this.transactionsLineChartData.amount.failed[currentInterval].hasLoaded = true
        this.transactionsLineChartData.count.failed[currentInterval].hasLoaded = true
      })
    } catch (error) {
      errorLog('Error loading transactions summary:', error)
      message.error('Error loading transactions summary')
    } finally {
      runInAction(() => {
        this.transactionsLineChartData[currentTab]['isLoading'] = false
      })
    }
  }

  processDataTransactions = (result: EntitySummary[], amountCount: TabKey) => {
    if (amountCount === 'amount') {
      return {
        labels: result.map(res => res.value),
        datasets: [
          {
            data: result.map(res => res[amountCount]),
            fill: false,
            borderColor: 'rgba(24, 143, 255, 0.7)',
            label: translations().total,
            hidden: false
          },
          {
            data: result.map(res => {
              if (res['count']) {
                return res[amountCount] / res['count']
              } else {
                return res['count']
              }
            }),
            fill: false,
            borderColor: 'rgba(24, 143, 255, 0.35)',
            label: translations().average,
            hidden: false
          }
        ]
      }
    } else {
      return {
        labels: result.map(res => res.value),
        datasets: [
          {
            data: result.map(res => res[amountCount]),
            fill: false,
            borderColor: 'rgba(24, 143, 255, 0.7)'
          }
        ]
      }
    }
  }

  clearTransactionsLineChartData() {
    this.transactionsLineChartData = generateTransactionsLineChartData()
  }

  private get loadDataParams() {
    const {
      dateStore: { startDate, endDate },
      storeAndTerminalFilterStore: { store, terminal }
    } = this.filterStore
    const { merchantName, currency, shouldLoadData, observablesHaveChanged, acquisitionChannelId } = this
    return {
      startDate,
      endDate,
      store,
      terminal,
      merchantName,
      currency,
      shouldLoadData,
      observablesHaveChanged,
      acquisitionChannelId
    }
  }
}
