import { Form, InputNumber } from 'antd'
import React from 'react'
import merge from 'lodash/merge'
import { Input } from '~/code/components'
import translations from './translations'
import { CorporateDetailsFormProps } from './props'
import styles from './CorporateDetailsForm.scss'
import { BelowActionsWithSave, FormTitle } from '../shared'

export const CorporateDetailsForm: React.FC<CorporateDetailsFormProps> = ({
  data,
  onSubmit,
  isSaveLoading,
  onSave
}) => {
  const [form] = Form.useForm()

  return (
    <Form
      form={form}
      autoComplete='off'
      onFinish={values => onSubmit(merge({}, data, values))}
      layout='vertical'
      requiredMark='optional'
      initialValues={data}
    >
      <FormTitle>{data.name}</FormTitle>

      <Form.Item
        name={['identification', 'registrationNumber']}
        label={translations().registrationNumber}
        rules={[
          {
            required: true,
            message: translations().registrationNumberRequired
          }
        ]}
        validateTrigger='onBlur'
      >
        <Input readOnly={(data?.identification?.registrationNumber && data?.fromApi) || false} />
      </Form.Item>

      <Form.Item
        name={'ownershipPercentage'}
        label={translations().ownershipPercentage}
        rules={[{ required: true, message: translations().ownershipPercentageRequired }]}
        validateTrigger='onBlur'
      >
        <InputNumber className={styles.styledInput} min={25} />
      </Form.Item>

      <BelowActionsWithSave
        isLoading={isSaveLoading}
        onSave={() => {
          onSave(form.getFieldsValue())
        }}
      ></BelowActionsWithSave>
    </Form>
  )
}
