import React from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { Modal, Tabs, Button } from 'antd'
import { IEditTariffsModalStore } from './IEditTariffsModalStore'
import { EditTariffsTable } from './components/EditTariffsTable'
import { GroupedTerminalsDataType, TariffType } from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v2'
import { posProductIds } from './constants'
import { EditTariffsModalProps } from './props'
import translations from './translations'

export const EditTariffsModalStoreSymbol = Symbol('EditTariffsModalStore')
export const EditTariffsModal = observer((props: EditTariffsModalProps) => {
  const store = useInjection<IEditTariffsModalStore>(EditTariffsModalStoreSymbol)

  const renderTabs = (
    terminal: GroupedTerminalsDataType,
    contractTariffs: TariffType[],
    terminalList: string[],
    terminalGroup: string
  ): JSX.Element => {
    const product = store.defineProduct(terminalList)
    if (product === 'POS/ECOM') {
      const posList = terminalList.filter(terminalId => posProductIds.includes(terminalId.substring(0, 2)))
      const ecomList = terminalList.filter(terminalId => !posList.includes(terminalId))
      return (
        <Tabs
          centered
          defaultActiveKey='0'
          items={[
            {
              label: translations().pos,
              key: '0',
              children: (
                <EditTariffsTable
                  terminal={terminal}
                  contractTariffs={contractTariffs}
                  product={'POS'}
                  terminalList={posList}
                  terminalGroup={terminalGroup}
                />
              )
            },
            {
              label: translations().ecom,
              key: '1',
              children: (
                <EditTariffsTable
                  terminal={terminal}
                  contractTariffs={contractTariffs}
                  product={'ECOM'}
                  terminalList={ecomList}
                  terminalGroup={terminalGroup}
                />
              )
            }
          ]}
        ></Tabs>
      )
    }
    return (
      <EditTariffsTable
        terminal={terminal}
        contractTariffs={contractTariffs}
        product={product}
        terminalList={terminalList}
        terminalGroup={terminalGroup}
      />
    )
  }

  const prepareData = () => {
    const tabItems = props.data.terminals.map((terminal, idx) => {
      return {
        label: translations().getTabLabel((idx + 1).toString()),
        key: idx.toString(),
        children: renderTabs(
          terminal,
          props.data.tariffs,
          terminal.terminal[0]?.terminalId,
          terminal.terminal[0]?.terminalGroup
        )
      }
    })
    return tabItems
  }

  return (
    <Modal
      width={'90%'}
      title={translations().editTariffs}
      open={store.isEditTariffsModalOpen}
      footer={[
        <Button key='cancel' onClick={() => store.closeEditTariffstModal()}>
          {translations().cancel}
        </Button>
      ]}
      onCancel={() => store.closeEditTariffstModal()}
    >
      {<Tabs defaultActiveKey='0' items={prepareData()}></Tabs>}
    </Modal>
  )
})
