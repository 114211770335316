import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js'
import { observer } from 'mobx-react'
import numeral from 'numeral'
import styles from './HorizontalBar.scss'
import { Grid } from 'antd'
const { useBreakpoint } = Grid

export const HorizontalBar = observer(({ data, renderTooltipLabel, valueNumberFormat }) => {
    const chartRef = useRef(null)
    const chartObjectRef = useRef({chart: null} )
    const screens = useBreakpoint()
    const isMobile = !screens.md

    useEffect(() => {
        if (chartObjectRef.current.chart) {
            updateChart(chartObjectRef.current.chart)
        } else {
            createChart()
        }
    }, [isMobile])

    const createChart = () => {
        const ctx = chartRef.current.getContext('2d')
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            elements: {
                rectangle: {
                    backgroundColor: 'rgba(16, 136, 255, 0.7)'
                }
            },
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    ticks: {
                        min: 0,
                        callback(value, index, values) {
                            return numeral(value).format(valueNumberFormat || '0.00a')
                        }
                    },
                    gridLines: {drawBorder: false}
                }],
                yAxes: [{
                    gridLines: {display: false, drawBorder: false, offsetGridLines: true},
                    ticks: {
                        callback(label, index, labels) {
                            if (!label) {
                                return 'Unknown'
                            }
                            const _label = label.trim()
                            if (isMobile && _label.length > 15) {
                                return _label.substring(0, 12) + '...'
                            } else{
                                return _label
                            }
                        }
                    }
                }]
            },
            tooltips: {
                callbacks: {
                    label: ({datasetIndex, index, label, value}, _data) => {
                        return renderTooltipLabel(label, value)
                    }
                }
            }
        }

        if (!renderTooltipLabel) {
           delete options.tooltips
        }

        chartObjectRef.current.chart = new Chart(ctx, {
            type: 'horizontalBar',
            data,
            options
        })
    }

    const updateChart = (chart) => {
        chart.options.scales.yAxes = [{
            gridLines: {display: false, drawBorder: false, offsetGridLines: true},
            ticks: {
                callback(label, index, labels) {
                    if (!label) {
                        return 'Unknown'
                    }
                    const _label = label.trim()
                    if (isMobile && _label.length > 15) {
                        return _label.substring(0, 12) + '...'
                    } else{
                        return _label
                    }
                }
            }
        }]

        chart.update()
    }

    return <div className={styles.BarChart}>
        <canvas
            id={`bar-${styles.BarChart}`}
            ref={chartRef}
        />
    </div>
})
