import React, { useRef, forwardRef } from 'react'
import { AutoComplete } from 'antd'
import { Input } from '~/code/components'
import { PhoneNumberInputProps } from './props'

const cleanValue = (value: any): string => value?.toString().replace(/[^\+\d]/g, '') || ''
const removePrefix = (value: any, prefix: string): string => {
  const reg = new RegExp(`\^0|^\\${prefix}`, 'g')
  return value?.toString().replace(reg, '') || ''
}
const maxPhoneLength = 10

export const PhoneNumberInput = forwardRef<any, PhoneNumberInputProps>(
  ({ options, value, onChange, onBlur, prefix, ...rest }, ref) => {
    const stateRef = useRef({ isPasted: false })

    const onCustomChange = (inputValue: string) => {
      let v = cleanValue(inputValue)
      if (stateRef.current.isPasted) {
        v = removePrefix(v, prefix)
        stateRef.current.isPasted = false
      }
      if (v.length > maxPhoneLength) {
        v = v.substring(0, maxPhoneLength)
      }
      onChange && onChange(v && prefix + v)
    }

    return options && options.length > 0 ? (
      <AutoComplete
        options={options?.map(o => ({ value: o, label: o }))}
        ref={ref}
        value={removePrefix(value, prefix)}
        onChange={(v, option: any) => {
          const newValue = option.value ? removePrefix(option.value, prefix) : v
          onCustomChange(newValue)
        }}
        onBlur={onBlur}
      >
        <Input
          addonBefore={prefix}
          placeholder={'1122334455'}
          onPasteCapture={e => {
            stateRef.current.isPasted = true
          }}
          {...rest}
        />
      </AutoComplete>
    ) : (
      <Input
        ref={ref}
        addonBefore={prefix}
        placeholder={'1122334455'}
        value={removePrefix(value, prefix)}
        onChange={e => onCustomChange(e.target.value)}
        onPasteCapture={e => {
          stateRef.current.isPasted = true
        }}
        onBlur={onBlur}
        {...rest}
      />
    )
  }
)
