import React, { useEffect, useRef, useContext, useState } from 'react'
import { Form, Divider, Typography, Row, Col, Radio, Input, CheckboxRef } from 'antd'
import { observer } from 'mobx-react-lite'
import { FormSubgroupTitle } from '../shared/FormSubgroupTitle'
import translations from './translations'
import {
  addCommasForNumbersInput,
  editQuarterInput,
  isQuarterTotalNot100,
  isStoreFinancialInfoValid,
  quarterTotal,
  wereQuarterFieldsFilledIn
} from '../../services'
import { StartProcessStoreContext } from '../../StartProcessStore'
import { scrollToFirstError } from '~/code/services/helpers'
import styles from './FinancialInfo.scss'

import { renderAdvancePaymentsBlock, renderProcessingMethodBlock } from './services/generator'
import { FinancialInfoProps } from './props'
import { CustomButton } from '~/code/components'
import { advancePaymentsData, processingMethodsData } from './constants/data'
import { getInitialValues, setMonthTransactionNumber } from './services/utils'

export const FinancialInfo = observer(({ financialStore }: FinancialInfoProps) => {
  const store = useContext(StartProcessStoreContext)
  const [form] = Form.useForm()
  const { getFieldValue, setFields } = form
  const face2faceIsTrueRef = useRef<CheckboxRef>(null)
  const [advancePaymentsPercentSum, setAdvancePaymentsPercentSum] = useState<number>(0)
  const [processingMethodsPercentSum, setProcessingMethodsPercentSum] = useState<number>(0)
  const [completeEnabled, setCompleteEnabled] = useState(false)

  useEffect(() => {
    getTotal('processingMethods')
    getTotal('advancePayments')
  }, [])

  const seasonalBusinessIsTrueRef = useRef<CheckboxRef>(null)

  const getTotal = (field: string) => {
    const fieldValues = getFieldValue(field)
    switch (field) {
      case 'processingMethods':
        setProcessingMethodsPercentSum(
          processingMethodsData.reduce((sum: number, method) => {
            return fieldValues[method.key]?.percent && !isNaN(fieldValues[method.key]?.percent)
              ? sum + +fieldValues[method.key]?.percent
              : sum + 0
          }, 0)
        )
        return 0
      case 'advancePayments':
        setAdvancePaymentsPercentSum(
          advancePaymentsData.reduce((sum: number, payment) => {
            return fieldValues[payment.key]?.percent && !isNaN(fieldValues[payment.key]?.percent)
              ? sum + +fieldValues[payment.key]?.percent
              : sum + 0
          }, 0)
        )
        return 0
      default:
        return 0
    }
  }

  return (
    <Form
      form={form}
      autoComplete='off'
      layout='vertical'
      className={styles.form}
      scrollToFirstError={scrollToFirstError}
      initialValues={getInitialValues(financialStore)}
      onValuesChange={() => {
        setCompleteEnabled(isStoreFinancialInfoValid(store.productStore.mapDataToFinancialInfo(form.getFieldsValue())))
      }}
    >
      <Row gutter={[64, 0]}>
        <Col xs={24}>
          <FormSubgroupTitle className={styles.header}>{financialStore.storeName}</FormSubgroupTitle>
          <Form.Item
            name={['financialInfo', 'businessModelQuestions', 'seasonalBusiness', 'isTrue']}
            label={translations().seasonalBusiness.isTrueLabel}
            rules={[{ required: true, message: translations().seasonalBusiness.isTrueRequired }]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <Radio.Group>
              <Radio value={true}>{translations().seasonalBusiness.yes}</Radio>
              <Radio value={false} ref={seasonalBusinessIsTrueRef}>
                {translations().seasonalBusiness.no}
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {() => {
              if (getFieldValue(['financialInfo', 'businessModelQuestions', 'seasonalBusiness', 'isTrue']) !== true)
                return null
              const q1 = getFieldValue(['financialInfo', 'businessModelQuestions', 'seasonalBusiness', 'quarter1'])
              const q2 = getFieldValue(['financialInfo', 'businessModelQuestions', 'seasonalBusiness', 'quarter2'])
              const q3 = getFieldValue(['financialInfo', 'businessModelQuestions', 'seasonalBusiness', 'quarter3'])
              const q4 = getFieldValue(['financialInfo', 'businessModelQuestions', 'seasonalBusiness', 'quarter4'])

              const wereFieldsFilledIn = wereQuarterFieldsFilledIn(q1, q2, q3, q4)

              const isTotalNot100 = wereFieldsFilledIn && isQuarterTotalNot100(q1, q2, q3, q4)

              return (
                <Form.Item
                  name={['financialInfo', 'businessModelQuestions', 'seasonalBusiness', 'quarter']}
                  label={translations().seasonalBusiness.qLabel}
                  required
                  rules={[
                    {
                      validator: async () => {
                        if (isTotalNot100) {
                          throw new Error(translations().seasonalBusiness.totalShouldBe100)
                        }
                      }
                    }
                  ]}
                  trigger='onBlur'
                >
                  <Row gutter={16} className={styles.quarterFieldsContainer}>
                    <Col xs={24} md={5}>
                      <Form.Item
                        name={['financialInfo', 'businessModelQuestions', 'seasonalBusiness', 'quarter1']}
                        label={translations().seasonalBusiness.q1Label}
                        rules={[{ required: true, message: translations().seasonalBusiness.q1Required }]}
                        validateTrigger={['onChange', 'onBlur']}
                        getValueFromEvent={editQuarterInput}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={5}>
                      <Form.Item
                        name={['financialInfo', 'businessModelQuestions', 'seasonalBusiness', 'quarter2']}
                        label={translations().seasonalBusiness.q2Label}
                        rules={[{ required: true, message: translations().seasonalBusiness.q2Required }]}
                        validateTrigger={['onChange', 'onBlur']}
                        getValueFromEvent={editQuarterInput}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={5}>
                      <Form.Item
                        name={['financialInfo', 'businessModelQuestions', 'seasonalBusiness', 'quarter3']}
                        label={translations().seasonalBusiness.q3Label}
                        rules={[{ required: true, message: translations().seasonalBusiness.q3Required }]}
                        validateTrigger={['onChange', 'onBlur']}
                        getValueFromEvent={editQuarterInput}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={5}>
                      <Form.Item
                        name={['financialInfo', 'businessModelQuestions', 'seasonalBusiness', 'quarter4']}
                        label={translations().seasonalBusiness.q4Label}
                        rules={[{ required: true, message: translations().seasonalBusiness.q4Required }]}
                        validateTrigger={['onChange', 'onBlur']}
                        getValueFromEvent={editQuarterInput}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                      <Form.Item label=' ' required>
                        = {quarterTotal(q1, q2, q3, q4)}%
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              )
            }}
          </Form.Item>

          <Form.Item
            name={['financialInfo', 'expectedAnnualCardTurnover']}
            label={translations().annualCardTurnover}
            rules={[{ required: true, message: translations().required }]}
            getValueFromEvent={addCommasForNumbersInput}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={['financialInfo', 'averageTransactionValue']}
            label={<>{translations().averageTransactionValueLabel}</>}
            rules={[{ required: true, message: translations().averageTransactionValueRequired }]}
            validateTrigger='onBlur'
            getValueFromEvent={addCommasForNumbersInput}
          >
            <Input
              onChange={() => {
                setMonthTransactionNumber(form)
              }}
            />
          </Form.Item>

          <Form.Item
            name={['financialInfo', 'expectedMonthlyTransactionValues']}
            label={<>{translations().expectedMonthlyTransactionValuesLabel}</>}
            dependencies={[['financialInfo', 'expectedAnnualTurnover']]}
            rules={[
              { required: true, message: translations().expectedMonthlyTransactionValuesRequired },
              {
                validator: async (rule, value) => {
                  if (isNaN(+value)) {
                    return Promise.reject(new Error(translations().incorrectValue))
                  } else if (+value <= 0) {
                    return Promise.reject(new Error(translations().greaterThanZero))
                  } else if (!isFinite(+value)) {
                    return Promise.reject(new Error(translations().numberIsInfinity))
                  } else {
                    return Promise.resolve()
                  }
                }
              }
            ]}
            validateTrigger={['onBlur', 'onChange']}
            getValueFromEvent={addCommasForNumbersInput}
          >
            <Input disabled={true} />
          </Form.Item>

          <Form.Item
            name={['financialInfo', 'highestTransactionValue']}
            label={translations().highestTransaction}
            rules={[{ required: true, message: translations().required }]}
            validateTrigger='onBlur'
            getValueFromEvent={addCommasForNumbersInput}
          >
            <Input />
          </Form.Item>

          <div className={styles.formSectionTitle}>
            <Typography.Title level={5}>{translations().turnoverMethod}</Typography.Title>
          </div>

          {renderProcessingMethodBlock(form, getTotal)}

          <Row className={styles.processingMethodsSumCounter}>
            <Col>{`${translations().totalPercentage}: ${processingMethodsPercentSum} %`}</Col>
          </Row>
          <Divider />

          <Form.Item
            name={'paymentsInAdvance'}
            label={translations().paymentsInAdvance}
            rules={[{ required: true, message: translations().required }]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <Radio.Group>
              <Radio value={true}>{translations().businessModelQuestions.yes}</Radio>
              <Radio value={false} ref={face2faceIsTrueRef}>
                {translations().businessModelQuestions.no}
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {() => {
              if (getFieldValue('paymentsInAdvance') !== false) return null

              return (
                <>
                  <div className={styles.formSectionTitle}>
                    <Typography.Title level={5}>{translations().howFar}</Typography.Title>
                  </div>

                  {renderAdvancePaymentsBlock(form, getTotal)}
                  <Row className={styles.sumCounter}>
                    <Col>{`${translations().totalPercentage}: ${advancePaymentsPercentSum} %`}</Col>
                  </Row>
                  <Divider />
                </>
              )
            }}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col>
          <CustomButton
            type='primary'
            loading={store.dataStore.isNextLoading}
            onClick={() => store.productStore.onSubmitStore(form, true, 'isNextLoading')}
            disabled={!completeEnabled}
          >
            {translations().complete}
          </CustomButton>
        </Col>
        <Col>
          <CustomButton
            loading={store.dataStore.isSaveLoading}
            onClick={() => store.productStore.onSubmitStore(form, false, 'isSaveLoading')}
          >
            {translations().saveForLater}
          </CustomButton>
        </Col>
      </Row>
    </Form>
  )
})
