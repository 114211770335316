import React from 'react'
import { Button, Result } from 'antd'
import { observer } from 'mobx-react'
import { AuthForm } from '~/code/components/auth/AuthForm'
import translations from './translations'
import { ResetPasswordFailureProps } from './props'
import styles from './ResetPasswordFailure.scss'

export const ResetPasswordFailure = observer((props: ResetPasswordFailureProps) => {
  return <AuthForm
    title={translations().title}
  >
    <Result
        status={'error'}
        title={translations().somethingWentWrong}
        subTitle={props.errorMessage || translations().unexpectedError}
    />
    <Button size='large' className={styles.tryAgain} onClick={props.onTryAgainClick}>
      {translations().tryAgain}
    </Button>
  </AuthForm>
})
