import { message } from 'antd'
import { inject, injectable } from 'inversify'
import { makeObservable, computed, observable, action, runInAction } from 'mobx'
import { log } from 'dna-common'
import { IClosureOfTerminalDossierV2Store } from '~/code/pages/MerchantsManagement/components/ClosureOfTerminal/ClosureOfTerminalDossierV2StoreType'
import { activeTerminalStatus } from '~/code/pages/MerchantsManagement/components/ChangeOfSettlementPeriod/constants'
import { MerchantsManagementStoreSymbol } from '~/code/pages'
import { MerchantsManagementStore } from './MerchantsManagementStore'
import { startProcessCloseTerminalDossierV2 } from './services/fetchers'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import { AppStore } from '~/code/stores/AppStore'
import { jsonType, stringType, doubleType, booleanType } from './constants'
import translations from './translations'

@injectable()
export class ClosureOfTerminalDossierV2Store implements IClosureOfTerminalDossierV2Store {
  merchantsManagementStore: MerchantsManagementStore
  isCloseMerchantTerminalLoading: boolean = false
  terminalIds: string[] = []
  allTerminalsSelected: boolean = false

  constructor(@inject(MerchantsManagementStoreSymbol) merchantsManagementStore: MerchantsManagementStore) {
    this.merchantsManagementStore = merchantsManagementStore
    makeObservable(this, {
      isCloseMerchantTerminalLoading: observable,
      terminalIds: observable,
      selectedMerchant: computed,
      allTerminalIds: computed,
      isMerchantActive: computed,
      allTerminalsSelected: observable,
      setTerminalIds: action
    })
  }

  get selectedMerchant() {
    return this.merchantsManagementStore.selectedMerchant
  }

  get isMerchantActive() {
    return this.merchantsManagementStore.isMerchantActive
  }

  get isSubsidiaryForFee() {
    return this.merchantsManagementStore.isSubsidiaryForFee
  }

  get isSubsidiaryForProcess() {
    return this.merchantsManagementStore.isSubsidiaryForProcess
  }

  get allTerminalIds() {
    const terminalsList = []
    const stores = this.merchantsManagementStore.companyFullDossierV2?.processing?.stores || []

    stores.forEach(store => {
      store.contracts.forEach(contract => {
        const filteredTerminals = contract.terminals.filter(
          terminal => terminal.terminal.terminalStatus === activeTerminalStatus
        )
        filteredTerminals.forEach(terminal => {
          terminalsList.push(terminal.terminal.terminalId)
        })
      })
    })

    return terminalsList
  }

  setTerminalIds(terminalIds: string[]) {
    this.terminalIds = terminalIds
    if (this.allTerminalIds.length === this.terminalIds.length) {
      this.allTerminalsSelected = true
    } else {
      this.allTerminalsSelected = false
    }
  }

  createTerminalClosureData(reason: string, terminationFee: number, dsrId: number, topLevelSubjectId: number) {
    return {
      variables: {
        initiatorEmail: {
          value: AppStore.authStore.email,
          type: stringType
        },
        reason: {
          value: reason,
          type: stringType
        },
        terminals: {
          value: JSON.stringify(this.allTerminalIds.filter(t => this.terminalIds.includes(t))),
          type: jsonType
        },
        topLevelSubjectId: {
          value: topLevelSubjectId.toString(),
          type: stringType
        },
        dossierId: {
          value: dsrId.toString(),
          type: stringType
        },
        terminationFee: {
          value: terminationFee,
          type: doubleType
        },
        subsidiariesMerchant: {
          value: this.isSubsidiaryForProcess,
          type: booleanType
        }
      }
    }
  }

  async closeTerminal(reason: string, terminationFee: number): Promise<void> {
    try {
      runInAction(() => {
        this.isCloseMerchantTerminalLoading = true
      })
      const { topLevelSubjectId, dsrId } = this.merchantsManagementStore.companyFullDossierV2?.mainInfo

      if (!topLevelSubjectId) {
        message.error(translations().topLevelSubjectIdIsEmpty)
        return
      }

      if (!dsrId) {
        message.error(translations().dsrIdIsEmpty)
        return
      }

      const closureTerminalRequestData = this.createTerminalClosureData(
        reason,
        terminationFee,
        dsrId,
        topLevelSubjectId
      )
      const { status, error, result } = await startProcessCloseTerminalDossierV2(closureTerminalRequestData)
      if (status !== 200 || error) {
        message.error(error.message || translations().errClosingTerminal)
      } else {
        message.success(result.message || translations().applicationSuccessful)
        goToRoute(Routes.BPM_PROCESSES_CLOSURE_OF_TERMINAL)
      }
    } catch (error) {
      message.error(error?.message || translations().errClosingTerminal)
      log(error)
    } finally {
      runInAction(() => {
        this.isCloseMerchantTerminalLoading = false
      })
    }
  }
}
