import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import _ from 'lodash'
import { Form, Input, Select } from 'antd'
import { PermissionsTable, SpecificPermissions } from '~/code/pages/PartnerAccess/components'
import { PartnerAccessAddEditFormProps } from './props'

import translations from './translations'
import styles from './styles.scss'

export const PartnerAccessAddEditForm = observer(({ form, store }: PartnerAccessAddEditFormProps) => {
  const {
    partners,
    allPermissions,
    partnerPermissions,
    partnerAccessStore,
    partnerFormInitialValues,
    partnerSpecificPermissions,
    financialInstitutionOptions,
    isSpecificPermissionsVisible,
    onPartnerFormSubmit,
    getPartnerPermissions
  } = store

  const { selectedPartner, onPartnerDrawerClose } = partnerAccessStore

  const [permissions, setPermissions] = useState<string[]>([])
  const [specificPermissions, setSpecificPermissions] = useState<string[]>([])

  useEffect(() => {
    if (!!selectedPartner) {
      getPartnerPermissions()
    }
  }, [])

  return (
    <Form
      form={form}
      initialValues={partnerFormInitialValues}
      requiredMark={false}
      labelAlign='left'
      labelCol={{ span: 8 }}
      onFinish={v => onPartnerFormSubmit({ ...v, permissions: [...permissions, ...specificPermissions] })}
    >
      <Form.Item
        label={translations().formFields.partnerName}
        name='partnerName'
        rules={[{ required: true, message: translations().formErrors.partnerNameRequired }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label={translations().formFields.parentPartner} name='parentCompany'>
        <Select options={partners} allowClear />
      </Form.Item>

      <Form.Item
        label={translations().formFields.financialInstitution}
        name='financialInstitution'
        rules={[{ required: true, message: translations().formErrors.financialInstitutionRequired }]}
      >
        <Select options={financialInstitutionOptions} />
      </Form.Item>

      <Form.Item>
        <PermissionsTable
          permissions={allPermissions}
          initialPermissions={partnerPermissions}
          onPermissionsChange={setPermissions}
        />
      </Form.Item>

      {isSpecificPermissionsVisible && (
        <Form.Item>
          <SpecificPermissions
            permissions={partnerSpecificPermissions}
            initialPermissions={partnerPermissions}
            onPermissionsChange={setSpecificPermissions}
          />
        </Form.Item>
      )}
    </Form>
  )
})