import { action, computed, makeObservable, observable } from 'mobx'
import React from 'react'

import { CompleteApplicationStore } from './stores/CompleteApplicationStore'
import { CompanyNameFormStore } from './stores/CompanyNameFormStore'
import { StartApplicationDataStore } from './stores/StartApplicationDataStore'
import { ContactInfoStore } from './stores/ContactInfoStore'
import { SupportingDocumentsStore } from './stores/SupportingDocumentsStore'
import { PricingStore } from './stores/PricingStore'
import { GeneralInformationViewType, LoadingPropertyType, ParentStartApplicationStore, TabKey } from './models'
import { hasPermissions, isPartner } from '~/code/services/auth'
import { clearMobx } from '~/code/services'
import { getPaymentSolution, getTerminalOrders, isEquivalent } from './services'

export class StartApplicationStore {
    isInitiated: boolean = false

    companyNameFormStore = new CompanyNameFormStore()
    supportingDocumentsStore = new SupportingDocumentsStore(this)
    completeApplicationStore = new CompleteApplicationStore(this)
    pricingStore = new PricingStore(this)
    dataStore: StartApplicationDataStore = new StartApplicationDataStore(this.completeApplicationStore, this.supportingDocumentsStore)
    contactInfoStore = new ContactInfoStore(this)

    activeTabKey: TabKey = 'contactInfo'

    constructor(private parentStore: ParentStartApplicationStore) {
        makeObservable(this, {
            activeTabKey: observable,

            onFinishGeneralInformation: action.bound,
            reset: action.bound,  
            setActiveTabKey: action,

            currentStep: computed,
            acquisitionChannels: computed,
            posModels: computed,

            isAcquisitionChannelsLoading: computed,
            isFastTrackAvailable: computed,
            acquisitionChannelsWithAll: computed,
            isPosModelsLoading: computed,
            isLoadApplicationByIdLoading: computed,
            isCheckDocumentsRequiredLoading: computed
        })
    }

    async init() {
        this.isInitiated = true
        await Promise.all([
            this.parentStore.handbooksStore.loadPosModels(),
            this.parentStore.handbooksStore.loadStartApplicationAcquisitionChannels()
        ])
    }

    get isAcquisitionChannelsLoading() {
        return this.parentStore.handbooksStore.isStartApplicationAcquisitionChannelsLoading
    }

    get isFastTrackAvailable() {
        return this.acquisitionChannels.find(a => a.name === this.dataStore.contactInfoData.acquisitionChannel)?.fastTrackAllowed &&
            hasPermissions(['onboarding.processes.fast_track.create'])
    }

    get isPosModelsLoading() {
        return this.parentStore.handbooksStore.isPosModelsLoading
    }

    get posModels() {
        return this.parentStore.handbooksStore.posModels
    }

    get acquisitionChannels() {
        return this.parentStore.handbooksStore.startApplicationAcquisitionChannels
    }

    get acquisitionChannelsWithAll() {
        return this.parentStore.handbooksStore.startApplicationAcquisitionChannelsWithAll
    }

    get currentStep() {
        switch (this.activeTabKey) {
            case 'contactInfo': return 0
            case 'generalInformation': return 1
            case 'pricing': return 2
            case 'completeApplication': return 3
            case 'supportingDocuments': return 4
            case 'result': return 5
            default: return 0
        }
    }

    get isLoadApplicationByIdLoading(): boolean {
        return this.contactInfoStore.isLoadApplicationByIdLoading
    }

    get isCheckDocumentsRequiredLoading() {
        return this.supportingDocumentsStore.isCheckDocumentsRequiredLoading
    }

    setActiveTabKey(key: TabKey) {
        this.activeTabKey = key
    }

    openGeneralInformationPage() {
        this.setActiveTabKey('generalInformation')
    }

    openCompleteApplicationPage() {
        this.completeApplicationStore.selectTab('generalInformation')
        this.setActiveTabKey('completeApplication')
    }

    openPricingPage(shouldReset = false) {
        if (shouldReset) {
            this.pricingStore.reset()
        }
        this.setActiveTabKey('pricing')
    }

    openSupportingDocumentsPage() {
        this.setActiveTabKey('supportingDocuments')
    }

    openResultPage() {
        this.setActiveTabKey('result')
    }

    reset() {
        this.setActiveTabKey('contactInfo')
        this.companyNameFormStore.reset()
        this.completeApplicationStore.reset()
        this.contactInfoStore.reset()
        this.dataStore.reset()
        this.pricingStore.reset()
    }

    async onFinishGeneralInformation(values: GeneralInformationViewType, loadingProperty: LoadingPropertyType) {
        const { paymentSolutionView, locationAddress, ...rest } = values
        const paymentSolution = getPaymentSolution(paymentSolutionView)
        const generalInformation = { paymentSolution, ...rest }
        const isCompanyTypeChanged = values?.companyType !== this.dataStore.companyType

        if (isCompanyTypeChanged) {
            this.completeApplicationStore.reset()
        }

        if (await this.completeApplicationStore.init(generalInformation)) {
            this.dataStore.setTerminalOrders(getTerminalOrders(paymentSolutionView, locationAddress))

            let isOk = true
            const isValuesChanged = !isEquivalent(JSON.parse(JSON.stringify(this.dataStore.generalInformationData)), JSON.parse(JSON.stringify(values)))

            if (isValuesChanged) {
                isOk = await this.dataStore.saveOnPaymentSolution(generalInformation, loadingProperty)
            }
            if (loadingProperty === 'isNextLoading' && isOk) {
                this.openPricingPage(isValuesChanged)
            }
        }
    }

    async onFinishPricing(values: any, loadingProperty: LoadingPropertyType) {
        let isOk = true
        if (!isEquivalent(clearMobx(this.dataStore.tariffs), clearMobx(values))) {
            isOk = await this.dataStore.saveOnTariffs(values, loadingProperty)
        }
        if (loadingProperty === 'isNextLoading' && isOk) {
            this.openCompleteApplicationPage()
        }
    }

    checkDocumentsRequired() {
        return this.supportingDocumentsStore.checkDocumentsRequired()
    }
}

export const StartApplicationStoreContext = React.createContext<StartApplicationStore>(null)