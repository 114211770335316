export enum PaymentSolution {
    WEBSITE_PAYMENTS = 'website-payments',
    PAY_BY_LINK = 'pay-by-link',
    POS = 'pos'
}

export enum PaymentSolutionView {
    WEBSITE_PAYMENTS = 'website-payments',
    PAY_BY_LINK = 'pay-by-link',
    POS = 'pos',
    WEBSITE_PAYMENTS_MOTO = 'website-payments_moto',
    PAY_BY_LINK_MOTO = 'pay-by-link_moto',
    POS_MOTO = 'pos_moto'
}