import translations from './translations'

translations.add('en', {
  formFields: {
    email: 'Email',
    firstName: 'First name',
    lastName: 'Last name',
    sendWelcomeEmail: 'Send Welcome Email?'
  },
  formErrors: {
    emailRequired: 'email is required',
    enterValidEmail: 'please enter valid email'
  }
})
