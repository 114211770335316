import React from 'react'
import translations from './translations'
import styles from '../ChangeBankDetailsModal.scss'

translations.add('en', {
  accountBlockTitle: (dd: boolean) => (dd ? 'Direct Debit Bank account' : 'Settlement Bank account'),
  bankAccountNumberLabel: 'Bank account number',
  bankAccountNumberRequired: 'bank account number is required',
  bankAccountNumberInvalid: 'invalid bank account number',
  sortCodeLabel: 'Sort code',
  sortCodeRequired: 'sort code is required',
  sortCodeInvalid: 'invalid sort code',
  accountNameLabel: 'Account name',
  accountNamePlaceholder: 'Bank account name',
  accountNameTooltip: 'Name on the bank statement',
  accountNameRequired: 'account name is required',
  bankNameLabel: 'Bank name',
  validate: 'Validate',
  validateBankAccount: 'Please validate bank account',
  bankStatementTitle: 'A copy of a bank statement for your business account (dated within the last 3 months)',
  bankStatement: 'Bank Statement',
  other: 'Other',
  upload: 'Upload',
  documentType: 'Document type',
  changeSettlement: 'Same Bank account for Settlements',
  changeDirectDebit: 'Same Bank account for Direct Debit',
  openBankingVerification: 'Open Banking Verification',
  uploadBankStatement: 'I will upload a bank statement',
  detailEdit: 'Edit bank detials',
  startOBRequestInfo: 'Dear user, you are about to send the Open Banking verification request',
  startOBRequestInfo2:
    'Merchant will receive this request via their Banking App.Please inform them that they need to authorise the request for us to verify that the bank details provided belong to them',
  info3: `Make sure you've entered correct details:`,
  yes: 'Yes',
  no: 'No',
  accountDetailsInfo: (companyName: string, accNum: string, sortCode: string) => (
    <>
      <p>{`Merchant legal name: ${companyName}`}</p>
      <p>{`Bank account number: ${accNum}`}</p>
      <p>{`Sort code: ${sortCode}`}</p>
    </>
  ),
  accountOwnerEmail: 'Account owner email is: ',
  sendOpenBankingRequest: 'Send the Open Banking Request',
  openBankingVerificationSentText:
    'The verification request is now sent via email. Please inform the merchant to follow the link from the email request and complete the Bank Account verification process. Once completed the status here will change automatically',
  openBankingVerificationRequest: 'Open Banking Verification Request',
  cancel: 'Cancel',
  change: 'Change',
  changeSortCodeAccountNumber: 'Please change sort code or bank account number',
  bankSupportEcoSpend: `We couldn’t automatically verify this account belongs to the company. Please follow one of two options below: send the Open Banking link to the account holder OR obtain and upload a physical copy of the valid bank statement.`,
  bankNotSupportEcoSpend: `We couldn’t automatically verify this account belongs to the company. Please obtain and upload a physical copy of the valid bank statement`,
  toReport: 'View report',
  changeBankDetailsSuccessText:
    'Your request has been submitted. To track the status of your request, you can use the "Change of Bank Details" report on the Dashboard.',
  processingStatement: 'Processing statement',
  automatedVerification: 'AUTOMATED BANK VERIFICATION ',
  verifResult: (status: string) => (
    <>
      <div className={styles.flex}>
        <p>
          <b>{`Verification results -  `}</b>
        </p>
        {status?.toUpperCase() === 'FAILED' && <p className={styles.notVerified}>FAILED</p>}
        {status?.toUpperCase() === 'VERIFIED' && <p className={styles.verified}>VERIFIED</p>}
      </div>
    </>
  ),
  whatsNext: 'WHATS NEXT:',
  twoOptions: 'Now, you have the following option:',
  optionTwo:
    'Upload the bank statement. When proceeding with this option, you will have to upload a recent bank statement on the ”Documents” section',
  optionOnePartOne:
    'Send the Open Banking authorisation request to the Merchant. They will receive an email with the Open Banking link.',
  optionOnePartTwo:
    'Once clicked, they will be forwarded to the Open Banking verification page, where they can select their bank, enter banking details and authorise the request via the mobile banking App or banking portal.',
  doesntSupportOB:
    'The sort-code provided is not the list of Banks supporting Open Banking. If you think this is wrong, please contact processes@dnapayments.com',
  accountType: 'Account type',
  accountTypeRequired: 'Account type is required',
  business: 'Business',
  personal: 'Personal'
})
