import { inject, injectable } from 'inversify'
import { action, autorun, makeObservable, observable, runInAction } from 'mobx'
import { message } from 'antd'
import moment from 'moment-timezone'
import { ITransactionStatementStore } from '~/code/pages/Acquiring/pages/Statement/models'
import { PosTransactionModel } from '~/code/models/PosTransactionModel'
import { PosTransactionsParams } from '~/code/models'
import { postExportsTest } from '~/code/services/fetchers'
import { isPartner } from '~/code/services/auth'
import translations from './translations'
import { TMonitoringPosExportsModalStore } from '~/code/pages/Acquiring/pages/MonitoringPos/components'
import {
  columns,
  columnsOnlyForFullAccess,
  getTransactionDateColumn
} from '~/code/pages/Acquiring/pages/MonitoringPos/components/MonitoringPosTransactions/columns'
import { MonitoringPosTestStoreSymbol } from '~/code/pages/Acquiring/pages/MonitoringPosTest'

export type ExportOptionsType = Record<keyof PosTransactionModel, { value: string; label: string }>

@injectable()
export class MonitoringPosTestExportsModalStore implements TMonitoringPosExportsModalStore {
  constructor(@inject(MonitoringPosTestStoreSymbol) monitoringPosTransactionStore: ITransactionStatementStore) {
    this.monitoringPosTransactionStore = monitoringPosTransactionStore
    makeObservable(this, {
      fields: observable,
      isUploading: observable,
      selectedExportType: observable,
      selectedOptions: observable,
      selectExportType: action.bound,
      selectOption: action.bound,
      handleCancel: action.bound
    })
    autorun(() => this.init())
  }

  monitoringPosTransactionStore: ITransactionStatementStore
  selectedOptions: any[] = []
  fields: string[] = []
  isUploading = false
  exportTypeOptions = []
  selectedExportType: 'all' | 'custom' = 'all'

  get isButtonDisabled() {
    return this.isUploading || !this.fields.length
  }

  get hasFullAccess() {
    return this.monitoringPosTransactionStore?.acquiringType === 'dna' && !isPartner()
  }

  get filteredColumns() {
    return columns.filter(c => columnsOnlyForFullAccess.indexOf(c.key as string) < 0 || this.hasFullAccess)
  }

  get transactionDateColumn() {
    return getTransactionDateColumn(this.filteredColumns.length + 1)
  }

  get options() {
    return [this.transactionDateColumn, ...this.filteredColumns].reduce((acc, curr) => {
      return {
        ...acc,
        [curr.key as number]: { value: curr.key, label: curr.title }
      }
    }, {})
  }

  public init() {
    this.fields = Object.keys(this.options)
    this.exportTypeOptions = [
      { value: 'all', label: translations().all(Object.keys(this.options).length) },
      { value: 'custom', label: translations().custom }
    ]
  }

  public selectExportType = (value: 'all' | 'custom') => {
    this.selectedExportType = value
    if (value === 'all') this.fields = Object.keys(this.options)
    else this.fields = []
  }

  public selectOption = (options: (keyof ExportOptionsType)[]) => {
    this.fields = [...options]
  }

  public handleCancel = () => {
    this.monitoringPosTransactionStore.openExportModal(false)
    this.fields = Object.keys(this.options)
    this.selectExportType('all')
  }

  public onExportsFormSubmit = async () => {
    const { request } = this.monitoringPosTransactionStore
    const { merchant } = this.monitoringPosTransactionStore.acquiringFilterStore.merchantSelectStore
    const { from, to } = request as PosTransactionsParams
    const { tradeName } = merchant

    const fileName = `${tradeName} ${moment(from).format('DD MMM YYYY')} - ${moment(to).format('DD MMM YYYY')}.csv`

    try {
      runInAction(() => (this.isUploading = true))

      const { result, error } = await postExportsTest({
        ...(request as PosTransactionsParams),
        fields: this.fields,
        fileName,
        format: 'csv'
      })

      if (error) throw new Error(error.message)
      else message.success(translations().successDownloadingStatement, 10)
    } catch (error) {
      message.error(translations().errDownloadingStatement || error.message, 10)
    } finally {
      runInAction(() => {
        this.isUploading = false
        this.handleCancel()
      })
    }
  }
}
