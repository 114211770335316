import { Select } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import { SelectItem } from '~/code/models'
import { FilterSelect, TwoLine } from '~/code/components'
import { TerminalTypes } from './constants'
import { StoreAndTerminalFilterProps } from './props'
import { midsToOptions, terminalsToOptions } from './services'
import styles from './styles.scss'
import translations from './translations'

export const StoreAndTerminalFilter = observer((props: StoreAndTerminalFilterProps) => {
  const { store, className, withMidsFilter = false } = props

  const terminals: SelectItem[] = useMemo<SelectItem[]>(() => terminalsToOptions(store.terminals), [store.terminals])
  const mids: SelectItem[] = useMemo<SelectItem[]>(() => midsToOptions(store.mids), [store.mids])
  const drawTerminalTypeSelection = useMemo(
    () => store.availableTerminalTypes?.length > 1 && store.availableTerminalTypes?.length > 0,
    [store.availableTerminalTypes]
  )
  const drawStoreSelection = useMemo(
    () => store.terminalType === 'pos' && store.stores?.length > 0,
    [store.terminalType, store.stores]
  )
  const drawTerminalSelection = useMemo(() => store.terminals?.length > 0, [store.terminals])

  const isLoading = useMemo(() => store.terminalLoadingStatus === 'loading', [store.terminalLoadingStatus])
  const hideSelections = useMemo(() => !store.merchantId, [store.merchantId])

  if (hideSelections) return <></>

  return (
    <div
      className={classNames(styles.storeAndTerminalFilter, {
        [className]: className && (drawTerminalTypeSelection || drawStoreSelection || drawTerminalSelection)
      })}
    >
      {drawTerminalTypeSelection && (
        <div className={styles.selectContainer}>
          <FilterSelect
            formItemProps={{ label: translations().terminalType }}
            selectProps={{
              className: styles.terminalTypeSelect,
              loading: isLoading,
              value: store.terminalType,
              onChange: store.setTerminalType,
              options: TerminalTypes
            }}
          />
        </div>
      )}
      {drawStoreSelection && (
        <div className={styles.selectContainer}>
          <FilterSelect
            formItemProps={{ label: translations().store }}
            selectProps={{
              className: styles.storeSelect,
              loading: isLoading,
              value: store.store.nameLocation,
              showSearch: true,
              onChange: store.setStore,
              optionLabelProp: 'prettiesLabel',
              options: [
                ...(store.stores?.map(s => {
                  const isAll = s.location === 'all'
                  return {
                    value: s.nameLocation,
                    label: <TwoLine primary={isAll ? translations().all : s.name} secondary={!isAll && s.location} />,
                    prettiesLabel: isAll ? translations().all : s.name
                  }
                }) || [])
              ]
            }}
          />
        </div>
      )}
      {drawTerminalSelection && withMidsFilter && (
        <div className={styles.selectContainer}>
          <FilterSelect
            formItemProps={{ label: translations().mid, shouldUpdate: true }}
            selectProps={{
              className: styles.terminalSelect,
              loading: isLoading,
              value: store.mid === 'all' ? translations().all : store.mid,
              onChange: store.setMid,
              options: mids
            }}
          />
        </div>
      )}
      {drawTerminalSelection && (
        <div className={styles.selectContainer}>
          <FilterSelect
            formItemProps={{ label: translations().terminal, shouldUpdate: true }}
            selectProps={{
              className: styles.terminalSelect,
              loading: isLoading,
              value: store.terminal.number === 'all' ? translations().all : store.terminal.number,
              onChange: store.setTerminal,
              options: terminals
            }}
          />
        </div>
      )}
    </div>
  )
})
