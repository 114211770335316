import { Form, FormInstance } from 'antd'
import classNames from 'classnames'
import React from 'react'

import { Input } from '~/code/components'
import { UserSecurity } from '~/code/components'
import { TeammateType } from '../../models'

import translations from '../../translations'
import styles from './styles.scss'

type Props = {
  form: FormInstance
  teammate: Partial<TeammateType>
  isEditMode?: boolean
  className?: string
}

export const TeammateForm: React.FC<Props> = props => {
  const { className, form, isEditMode, teammate } = props

  return (
    <Form form={form} className={classNames(className, styles.root)} layout='vertical' requiredMark='optional'>
      {!isEditMode && (
        <Form.Item
          name='email'
          label={translations().labels.email}
          rules={[
            { required: true, message: translations().errors.email },
            { type: 'email', message: translations().errors.emailInvalid }
          ]}
        >
          <Input placeholder={translations().labels.email} />
        </Form.Item>
      )}

      <Form.Item
        name='firstName'
        label={translations().labels.firstName}
        rules={[{ required: true, message: translations().errors.firstName }]}
      >
        <Input placeholder={translations().labels.firstName} />
      </Form.Item>

      <Form.Item
        name='lastName'
        label={translations().labels.lastName}
        rules={[{ required: true, message: translations().errors.lastName }]}
      >
        <Input placeholder={translations().labels.lastName} />
      </Form.Item>

      {!isEditMode && <UserSecurity form={form} status={teammate?.twoFAStatus} />}
    </Form>
  )
}