import translations from './translations'

translations.add('en', {
  notCompleted: 'Not completed',
  deleteFormTitle: (name: string) => `Are you sure you want to delete ${name}?`,
  deleteFormContent: 'By clicking Ok you confirm your action',
  edit: 'Edit',
  delete: 'Delete',
  productsModal: 'Products',
  agreementSignatory: 'SIGNEE',
  verified: 'Account successfully verified',
  notVerified: 'Account not verified',
  manuallyDocs: 'Documents will be uploaded',
  outletInfo: 'Outlet information',
  posProduct: 'POS product',
  ecomProduct: 'Ecommerce product',
  aliPay: 'AliPay',
  cards: 'Cards (Visa, Mastercard, UnionPay, Diners & Discover)',
  payByBank: 'Pay By Bank',
  zashEpos: 'Zash ePos',
  moto: 'MOTO Payments',
  openBanking: 'Open Banking',
  paymentLinks: 'Payment links',
  virtualTerminal: 'Virtual terminal',
  websitePayments: 'Website payments',
  configure: 'Configure POS product',
  noPosProds: 'There are no POS products here',
  noEcomProds: 'There are no ECOM products here'
})
