import { reaction } from 'mobx'
import { log } from 'dna-common'
import { inject, injectable } from 'inversify'
import ReconnectingWebSocket from 'reconnecting-websocket'
import {
  RealTimeTransactionDataStoreSymbol,
  RealTimeTransactionDataStoreInterface
} from '~/code/stores/RealTimeTransactionStore/RealTimeTransactionDataStore'
import { AppStore } from '~/code/stores/AppStore'
import { RealTimeTransactionWebsocketStoreInterface } from './RealTimeTransactionWebsocketStoreInterface'
import { RTT_PROD_URL, RTT_TEST_URL, RTT_PONG_TEXT, RTT_PING_TEXT } from './constants'

export const RealTimeTransactionWebsocketStoreSymbol = Symbol('RealTimeTransactionWebsocketStoreSymbol')

const SEND_MESSAGE_INTERVAL_TIME = 30 * 1000
const RECONNECT_TIMEOUT = 5 * 1000

@injectable()
export class RealTimeTransactionWebsocketStore implements RealTimeTransactionWebsocketStoreInterface {
  public realTimeTransactionDataStore: RealTimeTransactionDataStoreInterface
  public rws: ReconnectingWebSocket
  private intervalId: NodeJS.Timeout
  private pongTimeoutId: NodeJS.Timeout

  constructor(
    @inject(RealTimeTransactionDataStoreSymbol) realTimeTransactionDataStore: RealTimeTransactionDataStoreInterface
  ) {
    this.realTimeTransactionDataStore = realTimeTransactionDataStore

    reaction(
      () => AppStore.authStore.isAuthenticated,
      isAuthenticated => {
        if (!isAuthenticated) {
          this.close()
        }
      }
    )
  }

  connect = () => {
    const rwsUrl = __IS_PROD__ ? RTT_PROD_URL : RTT_TEST_URL

    this.rws = new ReconnectingWebSocket(rwsUrl)

    log('connecting websocket')

    this.rws.addEventListener('message', this.handleMessage)
    this.rws.addEventListener('close', this.handleClose)
    this.rws.addEventListener('error', this.handleError)
    this.rws.addEventListener('open', this.handleOpen)
  }

  handleOpen = (event: Event) => {
    log('WebSocket opened:', event)
    this.setInterval()
  }

  handleMessage = (event: MessageEvent) => {
    if (event.data === RTT_PONG_TEXT) {
      clearTimeout(this.pongTimeoutId)
      return
    }

    if (event.data === RTT_PONG_TEXT) return

    const data = JSON.parse(event.data)

    this.realTimeTransactionDataStore.updateData(data)
  }

  handleClose = (event: CloseEvent) => {
    this.clearInterval()
  }

  handleError = (error: ErrorEvent) => {
    console.error('WebSocket error:', error)
  }

  reconnect = () => {
    log('Pong not received, refreshing websocket connection')
    this.rws.reconnect()
  }

  sendMessage = () => {
    if (this.rws.readyState === ReconnectingWebSocket.OPEN) {
      this.rws.send(RTT_PING_TEXT)
      this.pongTimeoutId = setTimeout(() => this.reconnect(), RECONNECT_TIMEOUT)
    }
  }

  setInterval = () => {
    this.intervalId = setInterval(this.sendMessage, SEND_MESSAGE_INTERVAL_TIME)
  }

  close = () => {
    if (!this.rws) return

    this.rws.close()
    this.clearInterval()
  }

  clearInterval = () => {
    this.intervalId && clearInterval(this.intervalId)
  }
}
