import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  companyProfile: string
  yes: string
  no: string

  companyName: string
  companyTradeName: string
  dateOfCreation: string
  merchantCategoryCode: string
  numberOfEmployees: string
  companyWebsite: string
  natureOfAccountTransactions: string
  tradingCountriesOutsideEea: string
  tradeOutsideEea: string
  emailAddress: string
  telephoneNumber: string
  averageTransactionValue: string
  expectedAnnualTurnover: string
  expectedMonthlyTransactionValues: string
  natureOfBusiness: string
  natureOfBusinessDetails: string
  tradingAddress: string
  businessModelQuestions: {
    seasonalBusiness: {
      isTrue: string
      quarter1: string
      quarter2: string
      quarter3: string
      quarter4: string
    }
    face2face: {
      isTrue: string
      deliveryTime: string
      courierServiceUsed: string
    }
  }
  companyType: string
  companyNumber: string
  companyAddress: string
  addressLine1: string
  addressLine2: string
  townCity: string
  countyOrState: string
  country: string
  postCode: string
  company: (dsrId: number | string, isActive: boolean) => string
  financialInformation: string
  partner: string
  segmentCategory: string
  segmentSubCategory: string
  acquisitionMgr: string
  segmentInformation: string
  dnaAcquiring: string
  tenantValue: string
  marketCode: string
  attributes: string
}>()
