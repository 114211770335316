import translations from './translations'

translations.add('en', {
  applicationDeclaration: 'Application Declaration',
  averageTransactionValue: 'Average Transaction Value',
  bankAccountDetails: 'Bank Account Details',
  businessType: 'BusinessType',
  companyAddress: 'Company Address',
  companyNumber: 'Company Number',
  descriptor: 'Descriptor',
  dnaAcquirer: 'Dna Acquirer',
  emailAddress: 'Email Address',
  expectedAnnualCardTurnover: 'Expected Annual Card Turnover',
  expectedAnnualTurnover: 'Expected Annual Turnover',
  expectedMonthlyTransactionValues: 'Expected Monthly Transaction Values',
  formerAcquirer: 'Former Acquirer',
  fullLegalName: 'Full Legal Name',
  highestTransactionValue: 'Highest Transaction Value',
  incorporationDate: 'Incorporation Date',
  merchantCategoryCode: 'Merchant Category Code',
  privacyPolicy: 'Privacy Policy',
  resellerName: 'Reseller Name',
  storeDetails: 'Store Details',
  taxInfo: 'Tax Info',
  telephoneNumber: 'Telephone Number',
  tradingAddress: 'Trading Address',
  tradingName: 'Trading Name',
  website: 'Website',

  deviceId: 'Device Id',
  isFastTrack: 'Is Fast Track',
  isMsaSigned: 'Is Msa Signed',
  motoRequested: 'Moto Requested',
  needUploadKycDocuments: 'Need Upload Kyc Documents',
  parentCompanies: 'Parent Companies',
  partnerMerchantId: 'Partner Merchant Id',
  productCount: 'Product Count',
  productType: 'Product Type',
  acquisitionChannel: 'Acquisition Channel',
  yes: 'Yes',
  no: 'No'
})
