import React from 'react'
import translations from './translations'


translations.add('en', {
    registrationNumber: 'Company number',
    registrationNumberRequired: 'company number is required',
    ownershipPercentage: 'Ownership Percentage',
    ownershipPercentageRequired: 'ownership percentage is required'
})
