export enum PaymentSolution {
  WEBSITE_PAYMENTS = 'website-payments',
  PAY_BY_LINK = 'pay-by-link',
  POS = 'pos'
}

export enum PaymentSolutionView {
  POS = 'pos',
  POS_MOTO = 'pos_moto',
  WEBSITE_PAYMENTS = 'website-payments',
  PAY_BY_LINK = 'pay-by-link',
  WEBSITE_PAYMENTS_MOTO = 'website-payments_moto',
  PAY_BY_LINK_MOTO = 'pay-by-link_moto'
}
