import { ArrowLeftOutlined } from '@ant-design/icons'
import React, { PropsWithChildren } from 'react'
import translations from './translations'
import { BackButtonProps } from './props'
import styles from './BackButton.scss'

export const BackButton: React.FC<PropsWithChildren<BackButtonProps>> = props => {
  const { onClick, children } = props

  return (
    <span onClick={onClick} className={styles.button}>
      <ArrowLeftOutlined /> {children || translations().back}
    </span>
  )
}
