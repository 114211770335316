import { message } from 'antd'
import { inject, injectable } from 'inversify'
import { makeObservable, computed, action, runInAction, observable, reaction } from 'mobx'
import { FormInstance } from 'antd/es/form/Form'
import { AppStore } from '~/code/stores/AppStore'
import { IStoresDossierV2Store } from '~/code/pages/MerchantsManagement/components/Stores/models'
import { StoresDossierV2StoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores'
import { ITipJarModalStore } from '~/code/pages/MerchantsManagement/components/Stores/components/TipJarModal/ITipJarModalStore'
import { PhysicalStoreAttributesType,
        ProductType,
        DictionaryMappingValueType,
        StoreType,
        TipJarVenueDataType
    } from './models/merchant-dossier-v2'
import { TipJarTableColumnsType } from '~/code/pages/MerchantsManagement/components/Stores/components/TipJarModal/models'
import { stringType, jsonType } from './constants'
import { setTipJarConfiguration } from './services/fetchers'
import translations from './translations'

@injectable()
export class TipJarModalStore implements ITipJarModalStore {
    storesDossierV2Store: IStoresDossierV2Store
    isSetTipJarConfigurationLoading: boolean = false
    tipJartableData: TipJarTableColumnsType[] = []
    displayErrorMessage: boolean = false
    constructor(@inject(StoresDossierV2StoreSymbol) storesDossierV2Store : IStoresDossierV2Store) {
        this.storesDossierV2Store = storesDossierV2Store
        makeObservable(this, {
            stores: computed,
            tipJarInfo: computed,
            isSetTipJarConfigurationLoading: observable,
            tipJartableData: observable,
            displayErrorMessage: observable,
            closeModal: action,
            defineProductType: action,
            setTipJarConfiguration: action
        })

        reaction(() => this.tipJarInfo, () => {
            this.loadTipJarTableData()
        })
    }

    get stores(): StoreType[] {
        return this.storesDossierV2Store.companyFullDossierV2.processing.stores
    }

    get tipJarInfo(): DictionaryMappingValueType[] {
        return this.storesDossierV2Store.tipJarInfo
    }

    defineProductType = (contract: PhysicalStoreAttributesType): ProductType => {
        return this.storesDossierV2Store.defineProductType(contract.terminals.map((terminal) => terminal.terminal.terminalId))
    }

    defineMid = (contract: PhysicalStoreAttributesType): string => {
        const filteredTerminal = contract.terminals.find((terminal) => terminal.terminal.contractId === contract.contract.id)
        return filteredTerminal ? filteredTerminal.terminal.merchantId : ''
    }

    defineVenueIdByMid = (mid: string): string => {
        return this.storesDossierV2Store.defineTipJarVenueByMid(mid)
    }
    
    closeModal = (form: FormInstance) => {
        this.displayErrorMessage = false
        form.resetFields()
        this.storesDossierV2Store.closeTipJarModal()
    }

    loadTipJarTableData() {
        const tableData = []
        this.storesDossierV2Store.companyFullDossierV2.processing.stores
        .forEach((storeInfo, storeIdx) => {
            storeInfo.contracts.map((contract, contractIdx) => {
                if (this.defineProductType(contract) === 'POS') {
                    const mid = this.defineMid(contract)
                    const venue = this.defineVenueIdByMid(mid)
                    tableData.push({ 
                        key: `${storeIdx}${contractIdx}`,
                        storeIdx: storeIdx,
                        contractIdx: contractIdx,
                        storeName: storeInfo.storeInfo?.storeName,
                        location: `${storeInfo.storeAddress?.townOrCity} ${storeInfo.storeAddress?.addressLine1} ${storeInfo.storeAddress?.postalCode}`,
                        mid: mid,
                        venueId: venue
                    })
                }
            })
        })
        this.tipJartableData = tableData
    }

    prepareVenueData(data: FormData): TipJarVenueDataType {
        const venueData = {}
        for (const [key, value] of Object.entries(data)) {
            const keyParts = key.split('_');
            if (key.includes('venue') && value && keyParts.length >= 2) {
                const midKey = `mid_${keyParts[1]}`
                venueData[data[midKey]] = value
            }
        }
        return venueData
    }
    
    checkTheFieldsFilling(data: FormData, initialValues: TipJarTableColumnsType[]): boolean {
        let initialCountOfVenues = 0
        let finalCountOfVenues = 0
        for (const [key, value] of Object.entries(data)) {
            if (key.includes('venue') && value) finalCountOfVenues++
        }

        initialValues.forEach((item) => {
            if (item.venueId) initialCountOfVenues++
        })

        return finalCountOfVenues > initialCountOfVenues
    }

    async setTipJarConfiguration(form: FormInstance) {
        const initialValues = this.tipJartableData
        const formValues = form.getFieldsValue()
        const isFormValid = this.checkTheFieldsFilling(formValues, initialValues)
        if (isFormValid) {
            this.displayErrorMessage = false
            const venueData = this.prepareVenueData(formValues)
            try {
                runInAction(() => {
                    this.isSetTipJarConfigurationLoading = true
                })
                const { status, error } = await setTipJarConfiguration({
                    variables: {
                        dossierId: {
                            value: this.storesDossierV2Store.companyFullDossierV2.mainInfo.topLevelSubjectId.toString(),
                            type: stringType
                        },
                        initiator: {
                            value: AppStore.authStore.email,
                            type: stringType
                        },
                        venues: {
                            value: JSON.stringify(venueData),
                            type: jsonType
                        }
                    }
                })

                if (status !== 200 || error) {
                    message.error(error.message)
                } else {
                    form.resetFields()
                    message.success(translations().applicationSuccessful)
                    this.storesDossierV2Store.closeTipJarModal()
                }
            } catch (error) {
                message.error(error.message)
            } finally {
                runInAction(() => {
                    this.isSetTipJarConfigurationLoading = false
                })
            }
        } else {
            this.displayErrorMessage = true
        }
    }
}