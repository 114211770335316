import { ColumnTitle } from 'antd/lib/table/interface'
import { ColumnsType, ColumnType } from 'antd/lib/table'
import { TeamSummaryType } from '../models'
import translations from '../translations'
import { render, totalSummaryColumns } from './totalSummaryColumns'

export const teamSummaryColumns: ColumnsType<TeamSummaryType> = totalSummaryColumns.map(c => {
  if ((c as any).dataIndex === 'terminalType') {
    return {
      dataIndex: 'user',
      title: translations().columnTitles.user as ColumnTitle<TeamSummaryType>,
      render
    }
  }
  return c as ColumnType<TeamSummaryType>
})
