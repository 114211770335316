import React from 'react'
import { Col, Row } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { RatesCard } from '../chart-related'
import translations from './translations'
import { RatesSummaryRowProps } from './props'

const colResponsiveProps = {
    xs: 24,
    sm: 12
}

export const RatesSummaryRow = observer(({
    isLoading,
    data,
    className
}: RatesSummaryRowProps) => {
    return (
        <Row gutter={[24, 24]} className={classNames(className)}>
            <Col {...colResponsiveProps}>
                <RatesCard
                    data={data?.amount}
                    title={translations().approvalRateTitleByAmount}
                    isLoading={isLoading}
                />
            </Col>
            <Col {...colResponsiveProps}>
                <RatesCard
                    data={data?.count}
                    title={translations().approvalRateTitleByCount}
                    isLoading={isLoading}
                />
            </Col>
        </Row>
    )
})
