import { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  accountBlockTitle: (dd: boolean) => string
  bankAccountNumberLabel: string
  bankAccountNumberRequired: string
  bankAccountNumberInvalid: string
  sortCodeLabel: string
  sortCodeRequired: string
  sortCodeInvalid: string
  accountNameLabel: string
  accountNamePlaceholder: string
  accountNameTooltip: string
  accountNameRequired: string
  bankNameLabel: string
  validate: string
  validateBankAccount: string
  bankStatementTitle: string
  bankStatement: string
  other: string
  upload: string
  documentType: string
  changeSettlement: string
  changeDirectDebit: string
  openBankingVerification: string
  uploadBankStatement: string
  detailEdit: string
  startOBRequestInfo: string
  startOBRequestInfo2: string
  info3: string
  yes: string
  no: string
  accountDetailsInfo: (companyName: string, accNum: string, sortCode: string) => ReactNode
  accountOwnerEmail: string
  sendOpenBankingRequest: string
  openBankingVerificationSentText: string
  openBankingVerificationRequest: string
  cancel: string
  change: string
  changeSortCodeAccountNumber: string
  bankSupportEcoSpend: string
  bankNotSupportEcoSpend: string
  toReport: string
  changeBankDetailsSuccessText: string
  processingStatement: string
  automatedVerification: string
  verifResult: (status: string) => ReactNode
  whatsNext: string
  twoOptions: string
  optionTwo: string
  optionOnePartOne: string
  optionOnePartTwo: string
  doesntSupportOB: string
  accountType: string
  accountTypeRequired: string
  business: string
  personal: string
}>()
