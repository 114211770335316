import React from 'react'
import { useInjection } from 'dna-react-ioc'
import { observer } from 'mobx-react-lite'
import { Card, Col, Pagination, Row, Table, TablePaginationConfig } from 'antd'
import { InvoicesFilter } from '~/code/pages/Billing/pages/Invoices/components'
import { ReloadButton } from '~/code/components'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'
import { IInvoicesStore } from '~/code/pages/Billing/pages/Invoices/IInvoicesStore'
import { columns } from '~/code/pages/Billing/pages/Invoices/constants/constants'
import styles from './styles.scss'

export const InvoicesStoreSymbol = Symbol('InvoicesStore')

export const Invoices = observer(() => {
  const { refresh, invoicesDataSource, isInvoicesLoading, invoiceFilterStore } =
    useInjection<IInvoicesStore>(InvoicesStoreSymbol)

  const { currentPage, size, total, setPagination } = invoiceFilterStore

  const paginationProps: TablePaginationConfig = generateTablePaginationProps(
    currentPage,
    size,
    total,
    (page, pageSize) => setPagination(page, pageSize)
  )

  return (
    <>
      <InvoicesFilter />
      <Card style={{ marginTop: 40 }}>
        <Row justify={'end'} className={styles.topFilterWrapper}>
          <Col>
            <ReloadButton className={styles.reloadBtn} onClick={refresh} disabled={false} />
          </Col>
          <Col>
            <Pagination {...paginationProps} disabled={false} />
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={invoicesDataSource}
          pagination={false}
          loading={isInvoicesLoading}
          rowKey={record => record.rowKey}
          scroll={{ x: 1000 }}
        />
        <Row justify={'end'} className={styles.bottomFilterWrapper}>
          <Col>
            <ReloadButton className={styles.reloadBtn} onClick={refresh} disabled={false} />
          </Col>
          <Col>
            <Pagination {...paginationProps} disabled={false} />
          </Col>
        </Row>
      </Card>
    </>
  )
})
