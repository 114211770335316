import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    contract: string
    mids: string
    status: string
    team: string
    reason: string
    balance: string
    commentPlaceHolder: string
    allSettlementsOn: string
    allSettlementsOff: string
    comment: string
    required: string
}>()