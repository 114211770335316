import React, { useState } from 'react'
import { observer } from 'mobx-react'
import {
  Badge,
  Button,
  Drawer,
  Empty,
  Grid,
  Modal,
  Pagination,
  Spin,
  Table,
  TablePaginationConfig,
  Tooltip
} from 'antd'
import { FileSearchOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table/interface'
import { ReloadButton, ResizableColumnTitle } from '~/code/components'
import { PaymentsTableColumns } from '~/code/pages/settlements/SettlementsPayments/components/SettlementsPaymentsTableContainer/constants/PaymentsTableColumns'
import { useInjection } from 'dna-react-ioc'
import { ISettlementsPaymentsTableContainerStore } from '~/code/pages/settlements/SettlementsPayments/components/SettlementsPaymentsTableContainer/ISettlementsPaymentsTableContainerStore'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'
import { PaymentStatuses } from '~/code/pages/settlements/SettlementsPayments/constants/PaymentStatuses'
import translations from '~/code/pages/settlements/SettlementsPayments/translations/translations'
import { MAX_NUMBER_OF_SELECTABLE_ROWS } from '~/code/pages/settlements/SettlementsPayments/components/SettlementsPaymentsTableContainer/constants/configs'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import {
  generateBadgeStatus,
  renderTimeline
} from '~/code/pages/settlements/SettlementsPayments/components/SettlementsPaymentsTableContainer/services/ui-helpers'
import { formatAmount } from '~/code/services'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'
import styles from './SettlementPaymentsTableContainer.scss'

export const SettlementsPaymentsTableContainerStoreSymbol = Symbol('SettlementsPaymentsTableContainerStore')

export const SettlementsPaymentsTableContainer = observer(() => {
  const store = useInjection<ISettlementsPaymentsTableContainerStore>(SettlementsPaymentsTableContainerStoreSymbol)
  const breakpoint = useBreakpoint()

  const [isSendToApprovalModalVisible, setSendToApprovalModalVisible] = useState(false)
  const [isApproveModalVisible, setApproveModalVisible] = useState(false)
  const [isPaymentDetailsDrawerVisible, setPaymentDetailsDrawerVisible] = useState(false)
  const [selectedPayment, setSelectedPayment] = useState(null)
  const screens = Grid.useBreakpoint()

  const isCheckboxDisabled = record => {
    return (
      (record.status === PaymentStatuses.new &&
        !hasPermissions([PermissionMap.settlements.payments.send_for_approval])) ||
      (record.status === PaymentStatuses.waitingApproval &&
        !hasPermissions([PermissionMap.settlements.payments.approve])) ||
      (record.status !== PaymentStatuses.new && record.status !== PaymentStatuses.waitingApproval) ||
      (store.selectedPayments.length >= MAX_NUMBER_OF_SELECTABLE_ROWS &&
        !store.selectedRows.find(row => {
          return Number(row) === record.index
        }))
    )
  }

  const rowSelection = hasPermissions([
    PermissionMap.settlements.payments.send_for_approval,
    PermissionMap.settlements.payments.approve
  ])
    ? {
        selectedRowKeys: store.selectedRows,
        onSelect: store.setSelectedRows,
        onSelectAll: store.selectAllRows,
        getCheckboxProps: record => ({
          disabled: isCheckboxDisabled(record)
        })
      }
    : undefined

  const tableHeight = 980
  const columns = [
    ...PaymentsTableColumns,
    {
      key: 'action',
      fixed: 'right',
      width: 49,
      render: record => {
        return (
          <Tooltip title={translations().viewDetails}>
            <Button type={'text'} onClick={() => onRow(record)} icon={<FileSearchOutlined />} />
          </Tooltip>
        )
      }
    }
  ]

  const tablePaginationProps: TablePaginationConfig = generateTablePaginationProps(
    store.currentPage,
    store.pageSize,
    store.totalCount?.all,
    store.onPageChange
  )

  const isLoadingData = store.dataLoadingStatus === 'loading'
  const isLoadingPaymentHistory = store.paymentHistoryLoadingStatus === 'loading'

  const renderTotalSumLabel = () => {
    return (
      <>
        <Badge
          status={generateBadgeStatus(store.selectedStatus)}
          text={
            <>
              <Tooltip title={translations().tableHeader.totalSumTitle}>
                {translations().tableHeader.totalSumShortTitle}
              </Tooltip>
              : <strong>&pound; {formatAmount(store.totalAmount?.all || 0)}</strong>
            </>
          }
          className={styles.totalSumLabel}
        />
      </>
    )
  }

  const renderSendToApprovalButton = (selectedPayments, onClick) => {
    if (hasPermissions([PermissionMap.settlements.payments.send_for_approval]) && selectedPayments?.length > 0) {
      const newPayments = selectedPayments.filter(payment => payment.status === PaymentStatuses.new)

      if (newPayments?.length > 0) {
        return (
          <Button
            type={'primary'}
            className={styles.sendOrApproveButton}
            onClick={onClick}
            disabled={isLoadingData}
            loading={isLoadingData}
          >
            {translations().tableActionButtons.sendForApprovalSelected(newPayments.length)}
          </Button>
        )
      }
    }

    return null
  }

  const renderApproveButton = (selectedPayments, onClick) => {
    if (hasPermissions([PermissionMap.settlements.payments.approve]) && selectedPayments?.length > 0) {
      const waitingApprovalPayments = selectedPayments.filter(
        payment => payment.status === PaymentStatuses.waitingApproval
      )

      if (waitingApprovalPayments?.length > 0) {
        return (
          <Button
            type={'primary'}
            className={styles.sendOrApproveButton}
            onClick={onClick}
            disabled={isLoadingData}
            loading={isLoadingData}
          >
            {translations().tableActionButtons.approveSelected(waitingApprovalPayments.length)}
          </Button>
        )
      }
    }

    return null
  }

  const onSendToApprovalButtonClick = () => {
    setSendToApprovalModalVisible(false)
    store.onSendToApprovalSelectedButtonClick()
  }

  const onApproveButtonClick = () => {
    setApproveModalVisible(false)
    store.onApproveSelectedButtonClick()
  }

  const onRow = record => {
    store.onRowClick(record)
    setSelectedPayment(record)
    setPaymentDetailsDrawerVisible(true)
  }

  const renderDrawerTitle = () => {
    return (
      <>
        {selectedPayment ? `${selectedPayment.documentId} - ` : ''}
        {translations().paymentHistory.paymentStatusHistory}
      </>
    )
  }

  const renderTimelineContent = () => {
    if (isLoadingPaymentHistory) {
      return null
    }
    if (store.paymentHistory?.length > 0) {
      return renderTimeline(store.paymentHistory)
    } else {
      return <Empty description={translations().paymentHistory.paymentHistoryNotAvailable} />
    }
  }

  return (
    <>
      <div className={styles.tableHeaderContainer}>
        {!breakpoint.xl && (
          <>
            <div className={styles.actionButtonsContainer}>
              {renderSendToApprovalButton(store.selectedPayments, () => setSendToApprovalModalVisible(true))}
              {renderApproveButton(store.selectedPayments, () => setApproveModalVisible(true))}
            </div>
            <div className={styles.tableHeaderInfoWrapper}>
              <div>
                <div className={styles.tableHeaderActionsContainer}>{renderTotalSumLabel()}</div>
              </div>
              <div>
                <div className={styles.tableHeaderPaginationContainer}>
                  <ReloadButton
                    className={styles.reloadButton}
                    onClick={store.onReloadButtonClick}
                    disabled={store.isPaginationDisabled}
                  />
                  <Pagination {...tablePaginationProps} disabled={store.isPaginationDisabled} />
                </div>
              </div>
            </div>
          </>
        )}
        {breakpoint.xl && (
          <>
            <div className={styles.actionButtonsContainer}>
              {renderSendToApprovalButton(store.selectedPayments, () => setSendToApprovalModalVisible(true))}
              {renderApproveButton(store.selectedPayments, () => setApproveModalVisible(true))}
            </div>
            <div className={styles.tableHeaderInfoWrapper}>
              <div>
                <div className={styles.tableHeaderActionsContainer}>{renderTotalSumLabel()}</div>
              </div>
              <div>
                <div className={styles.tableHeaderPaginationContainer}>
                  <ReloadButton
                    className={styles.reloadButton}
                    onClick={store.onReloadButtonClick}
                    disabled={store.isPaginationDisabled}
                  />
                  <Pagination {...tablePaginationProps} disabled={store.isPaginationDisabled} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Table
        size='small'
        bordered
        virtual
        rowSelection={rowSelection}
        columns={columns as ColumnsType}
        scroll={{ x: 2000, y: tableHeight }}
        components={{ header: { cell: ResizableColumnTitle } }}
        dataSource={store.data && !isLoadingData ? store.data.map((item, i) => ({ ...item, key: `${i}` })) : null}
        pagination={false}
        loading={isLoadingData}
        className={styles.table}
        rowKey={record => record.key}
        onChange={store.onTableChange}
      />
      <div className={styles.tableFooterPaginationContainer}>
        <Pagination {...tablePaginationProps} disabled={store.isPaginationDisabled} />
      </div>
      <Modal
        title={translations().tableActionButtons.pleaseConfirmYourAction}
        open={isSendToApprovalModalVisible}
        onOk={onSendToApprovalButtonClick}
        onCancel={() => setSendToApprovalModalVisible(false)}
      >
        <p>{translations().tableActionButtons.areYouSureToSendForApproval}</p>
      </Modal>
      <Modal
        title={translations().tableActionButtons.pleaseConfirmYourAction}
        open={isApproveModalVisible}
        onOk={onApproveButtonClick}
        onCancel={() => setApproveModalVisible(false)}
      >
        <p>{translations().tableActionButtons.areYouSureToApprove}</p>
      </Modal>
      <Drawer
        title={renderDrawerTitle()}
        width={screens.xs ? 340 : 520}
        placement={'right'}
        onClose={() => setPaymentDetailsDrawerVisible(false)}
        open={isPaymentDetailsDrawerVisible}
      >
        <Spin spinning={isLoadingPaymentHistory}>
          <div className={styles.drawerContent}>{renderTimelineContent()}</div>
        </Spin>
      </Drawer>
    </>
  )
})
