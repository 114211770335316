import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  shareholders: string
  directors: string
  authorisedSignatories: string
  businessOwner: string
  corporateEntityShareholders: string
  companyName: string
  companyNumber: string
  companyProfile: string
  info: string
  stores: string
  balances: string
  routing: string
  financialInformation: string
}>()
