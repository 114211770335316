import translations from './translations'
import React from 'react'

translations.add('en', {
  requiredMessage: 'Required',
  positiveMessage: 'Should be at least zero',
  betweenMessage: (min, max) => `Must be between ${min} and ${max}`,
  lessMessage: max => `Value should not exceed ${max}`,
  greaterMessage: min => `Must be greater than ${min}`,
  cannotBeZero: 'Cannot be Zero',
  changedText: 'Changed from: '
})
