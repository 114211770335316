import React from 'react'
import { List, Row, Col } from 'antd'
import { FormTitle } from 'startapp/components'
import { EntityItem } from '../EntityItem'
import { EntityItemListProps } from './props'
import styles from './EntityItemList.scss'

export const EntityItemList: React.FC<EntityItemListProps> = props => {
  const { title, text, list } = props

  return (
    <>
      <FormTitle>{title}</FormTitle>
      <p className={styles.text}>{text}</p>
      <Row className={styles.styledRow}>
        <Col xs={24}>
          <List
            className={styles.listStyle}
            split={false}
            itemLayout='horizontal'
            dataSource={list}
            renderItem={item => (
              <>
                <EntityItem name={item.name} onClick={() => item.onClick()} additionalInfo={item.additionalInfo} />
              </>
            )}
          />
        </Col>
      </Row>
    </>
  )
}
