import React from 'react'
import translations from './translations'

translations.add('en', {
    title: 'Documents',
    mandatoryDocuments: 'Mandatory Documents',
    additionalDocuments: 'Additional Documents',
    upload: 'Upload',
    bankStatement: 'A copy of a bank statement for your business account (dated within the last 3 months)',
    passportOrDriving: 'A copy of your Passport or Driving Licence',
    residentialUtilityBill: 'A residential utility bill in your name (for your home address, dated within the last 3 months)',
    uploadFileText: <>Maximum upload file size: 10 MB <br /> Supported file extensions: .pdf, .png, .jpg, .jpeg</>,
    errors: {
        moreThan10Mb: 'File must be less than 10 MB'
    },
    backToThirdStep: 'Back to Application',
    documentType: 'Document Type',
    
    idDrivingLicence: 'ID. Driving licence',
    idPassport: 'ID. Passport',
    idOther: 'ID. Other',
    poaPersonalBankStatement: 'POA. Personal bank statement',
    poaCouncilTax: 'POA. Council tax',
    poaUtilityBill: 'POA. Utility bill',
    poaOther: 'POA. Other',
    pobBankStatementBusiness: 'POB. Bank statement business',
    pobChequeBook: 'POB. Cheque book',
    pobBankLetter: 'POB. Bank letter',
    pobOther: 'POB. Other',
    kybLeaseAgreement: 'KYB. Lease agreement',
    kybAlcoholLicense: 'KYB. Alcohol license',
    kybCertificateOfAuthenticity: 'KYB. Certificate of authenticity',
    domainOwnership: 'Domain ownership',
    licences: 'Licences',
    processingStatement: 'Processing statement',
    premisesPhotoOutside: 'Premises photo outside',
    premisesPhotoInside: 'Premises photo inside',
    signedMsa: 'Signed MSA',
    additionalTidsRequestForm: 'Additional TIDs request form',
    onlyXlsFilesAreSupported: 'Only following extensions are supported: .xls, .xlsx',
    other: 'Other',
    notRequired: {
        bankStatement: 'Bank account details are verified. The bank statement is NOT required.',
        passportOrDriving: 'ID is verified. A copy of your passport or driving licence is NOT required.',
        residentialUtilityBill: 'Address is verified. The residential utility bill is NOT required.'
    },
    errorUploadingDocument: 'Error uploading the document',
    errorDeletingDocument: 'Error deleting the document'
})
