import React from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Select } from 'antd'
import { PartnerAccessSelectProps } from './props'

import translations from './translations'
import styles from './styles.scss'

export const PartnerAccessSelect = observer((props: PartnerAccessSelectProps) => {
  const { onSearch, className, loading, ...rest } = props

  const filterOption = (input, option) => {
    return String(option?.label).toLowerCase().includes(input.toLowerCase())
  }

  return (
    <Select
      className={classNames(styles.root, className)}
      showSearch
      allowClear
      loading={loading}
      placeholder={translations().partnerPlaceholder}
      notFoundContent={translations().noPartners}
      filterOption={filterOption}
      {...rest}
    />
  )
})
