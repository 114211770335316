import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    changeRiskLimit: string,
    sureToChangeFields: string,
    confirm: string,
    cancel: string,
    change: string
}>()

