import translations from './translations'

translations.add('en', {
    bankingDate: 'Banking Date',
    settlementDate: 'Settlement Date',
    notSettledYet: 'Not settled yet',
    merchant: 'Merchant',
    address: 'Address',
    postcode: 'Postcode',
    mid: 'MID',
    terminal: 'Terminal',
    amount: 'Amount',
    currency: 'Currency',
    settlementType: 'Settlement Type',
    acquirerFee: 'Acquirer Fee',
    totalFee: 'Total Fee',
    interchangeFee: 'Interchange Fee',
    schemeFee: 'Scheme Fee',
    processingFee: 'Processing Fee',
    gatewayFee: 'Gateway Fee',
    authorisationFee: 'Authorisation Fee',
    blendedFee: 'Blended Fee',
    refundFee: 'Refund Fee',
    amountToMerchant: 'Amount to Merchant',
    amountToSettle: 'Amount to Settle',
    accountNumber: 'Account Number',
    sortCode: 'Sort Code',
    operation: 'Operation',
    transactionId: 'Transaction ID',
    merchantReference: 'Merchant Reference',
    transactionDate: 'Transaction Date',
    transactionType: 'Transaction Type',
    cardScheme: 'Card Scheme',
    cardMask: 'Card Mask',
    cardholderName: 'Cardholder Name',
    issuerCountry: 'Issuer Country',
    cardType: 'Card Type',
    issuerRegion: 'Issuer Region',
    isCorporateCard: 'Is Corporate Card',
    captureMethod: 'Capture Method',
    corporate: 'Corporate',
    personal: 'Personal',
    pos: 'POS',
    posContactless: 'POS Contactless',
    stored: 'Stored',
    visa: 'VISA',
    mastercard: 'MasterCard',
    noData: 'No Data',
    count: 'Count'
})
