import { Card, Tabs, Table as AntTable, TableProps, Typography } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { ReloadButton } from '~/code/components'
import { RangePickerContainer } from '~/code/pages/settlements/SettlementsPayments/components/RangePickerContainer/RangePickerContainer'
import { teamSummaryColumns, terminalIssueReportColumns, totalSummaryColumns } from './columns'
import { StatisticsFilters } from './components'
import { IStatisticsStore } from './models'
import styles from './Statistics.scss'
import translations from './translations'

export const StatisticsStoreSymbol = Symbol('StatisticsStore')
export const StatisticsFiltersStoreSymbol = Symbol('StatisticsFiltersStore')
export const StatisticsRangePickerStoreSymbol = Symbol('StatisticsRangePickerStore')

type TabKey = 'totalSummary' | 'teamSummary' | 'terminalIssueReport'

const Table = (props: TableProps<any>) => {
  return (
    <AntTable
      {...props}
      pagination={false}
      bordered
      scroll={{ x: 200 }}
      dataSource={props.dataSource?.map((d, idx) => ({ key: idx, ...d }))}
    />
  )
}

export const Statistics = observer(() => {
  const store = useInjection<IStatisticsStore>(StatisticsStoreSymbol)

  const [tabKey, setTabKey] = useState<TabKey>('totalSummary')

  return (
    <>
      <Card className={styles.headerContainer}>
        <div className={styles.header}>
          <Tabs
            activeKey={tabKey}
            onChange={key => setTabKey(key as TabKey)}
            items={[
              { key: 'totalSummary', label: translations().tabTitles.totalSummary },
              { key: 'teamSummary', label: translations().tabTitles.teamSummary },
              { key: 'terminalIssueReport', label: translations().tabTitles.terminalIssueReport }
            ]}
          />
          <div className={styles.rightHeader}>
            <div className={styles.reloadButtonContainer}>
              <ReloadButton onClick={store.clear} />
            </div>
            <RangePickerContainer injectableStoreIdentifier={StatisticsRangePickerStoreSymbol} includeHoursAndMins />
          </div>
        </div>
      </Card>
      <Card>
        {tabKey === 'totalSummary' && (
          <Table
            columns={totalSummaryColumns}
            dataSource={store.totalSummary}
            loading={
              store.totalSummaryLoadingStatus.ecom === 'loading' || store.totalSummaryLoadingStatus.pos === 'loading'
            }
          />
        )}
        {tabKey === 'teamSummary' && (
          <>
            <Table
              columns={teamSummaryColumns}
              dataSource={store.ecomTeamSummary}
              loading={store.teamSummaryLoadingStatus.ecom === 'loading'}
              title={() => <div className={styles.tableTitle}>{translations().ecom}</div>}
              className={styles.ecomTeamSummaryTable}
            />
            <Table
              columns={teamSummaryColumns}
              dataSource={store.posTeamSummary}
              loading={store.teamSummaryLoadingStatus.pos === 'loading'}
              title={() => <div className={styles.tableTitle}>{translations().pos}</div>}
            />
          </>
        )}
        {tabKey === 'terminalIssueReport' && (
          <>
            <StatisticsFilters />
            <Table
              columns={terminalIssueReportColumns}
              dataSource={store.terminalIssueReport}
              loading={store.terminalIssueReportLoadingStatus === 'loading'}
            />
            <div className={styles.totalTerminalIssueReportContainer}>
              <div className={styles.totalTerminalIssueReport}>
                <div>
                  <Typography.Title level={5}>
                    {translations().total} {translations().columnTitles.terminalQuantity}
                  </Typography.Title>
                  <div>
                    {translations().ecom}: {store.totalTerminalIssueReport.terminalQuantity.ecom}
                  </div>
                  <div>
                    {translations().pos}: {store.totalTerminalIssueReport.terminalQuantity.pos}
                  </div>
                </div>

                <div>
                  <Typography.Title level={5}>
                    {translations().total} {translations().columnTitles.merchantQuantity}
                  </Typography.Title>
                  <div>
                    {translations().ecom}: {store.totalTerminalIssueReport.merchantQuantity.ecom}
                  </div>
                  <div>
                    {translations().pos}: {store.totalTerminalIssueReport.merchantQuantity.pos}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Card>
    </>
  )
})
