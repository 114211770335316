import React from 'react'
import translations from './translations'

translations.add('en', {
  title: 'Documents Upload',
  mandatoryDocuments: 'Mandatory Documents',
  additionalDocuments: 'Additional Documents',
  upload: 'Upload',
  bankStatement: 'A copy of a bank statement for your business account (dated within the last 3 months)',
  passportOrDriving: 'A copy of your Passport or Driving Licence',
  residentialUtilityBill:
    'A residential utility bill in your name (for your home address, dated within the last 3 months)',
  uploadFileText: (
    <>
      Maximum upload file size: 10 MB <br /> Supported file extensions: .pdf, .png, .jpg, .jpeg
    </>
  ),
  errors: {
    moreThan10Mb: 'File must be less than 10 MB'
  },
  backToThirdStep: 'Back',
  documentType: 'Document Type',

  idDrivingLicence: 'ID. Driving licence',
  idPassport: 'ID. Passport',
  idOther: 'ID. Other',
  poaPersonalBankStatement: 'POA. Personal bank statement',
  poaCouncilTax: 'POA. Council tax',
  poaUtilityBill: 'POA. Utility bill',
  poaOther: 'POA. Other',
  pobBankStatementBusiness: 'POB. Bank statement business',
  pobChequeBook: 'POB. Cheque book',
  pobBankLetter: 'POB. Bank letter',
  pobOther: 'POB. Other',
  kybLeaseAgreement: 'KYB. Lease agreement',
  kybAlcoholLicense: 'KYB. Alcohol license',
  kybCertificateOfAuthenticity: 'KYB. Certificate of authenticity',
  domainOwnership: 'Domain ownership',
  licences: 'Licences',
  processingStatement: 'Processing statement',
  premisesPhotoOutside: 'Premises photo outside',
  premisesPhotoInside: 'Premises photo inside',
  signedMsa: 'Signed MSA',
  additionalTidsRequestForm: 'Additional TIDs request form',
  onlyXlsFilesAreSupported: 'Only following extensions are supported: .xls, .xlsx',
  other: 'Other',
  notRequired: {
    bankStatement: 'Bank account details are verified. The bank statement is NOT required.',
    passportOrDriving: 'ID is verified. A copy of your passport or driving licence is NOT required.',
    residentialUtilityBill: 'Address is verified. The residential utility bill is NOT required.'
  },
  errorUploadingDocument: 'Error uploading the document',
  errorDeletingDocument: 'Error deleting the document',
  bankStatementWithAcc:
    'A copy of a bank statement for your business account (dated within the last 3 months), for account: ',
  sign: 'Sign',
  docuSign: 'DocuSign',
  docuSignInfo: (
    <>
      <p>Click Preview to form the draft of the contract MSA. </p>
      <p>A pdf document will be downloaded, </p>
      <p>check it and assure the data is correct,</p>
      <p> then click Sign to send it for merchant signature in DocuSign</p>
    </>
  ),
  signError: 'Something went wrong on backend side',
  preview: 'Preview',
  alreadySentToSign: 'Document already sent to sign',
  update: 'Update',
  updateError: 'Something went wrong while update',
  signContract: 'I agree to form and send MSA automatically',
  docuSignInfo2: (
    <>
      <p>Is it required to generate an MSA? </p>
      <p>
        If YES then the document with MSA is automatically generated in PDF format based on the completed data from
        application and then sent to the merchant in DocuSign to sign
      </p>
      <p>After MSA document is signed by merchant, it will be saved in Salesforce in the appropriate Opportunity.</p>
    </>
  ),
  signedSuccessfully: 'Your document successfully signed',
  uploadInfo: (
    <>
      <p>
        Please upload documents as per the list below.
        <br />
        These documents are required to submit the full application due to failed automated verifications
      </p>

      <p>
        <i>
          Maximum upload file size: 10 MB <br /> Supported file extensions: .pdf, .png, .jpg, .jpeg
        </i>
      </p>
    </>
  ),
  complete: 'Complete',
  saveForLater: 'Save for later',
  completeDocusign:
    'The "Contract" section is not yet completed. Please finish it before proceeding to the "Submit" section.',
  manualDocusignTitle: 'Generate the MSA manually and get it signed by the Merchant -> Upload the signed MSA here.',
  signedByPaytek: 'Signed MSA',
  resendError: 'Error while resend email',
  resendSuccess: 'Email successfully resent. Your next attempt can be made after 10 seconds.'
})
