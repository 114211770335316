import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import Title from 'antd/es/typography/Title'
import { Button, Checkbox, Col, Form, Input, Row, Select, Radio, InputNumber, Space } from 'antd'
import { productTypeOptions, contractType } from '~/code/pages/MerchantsManagement/components/TerminalIssue/constants'
import { allowOnlyNumber } from '~/code/pages/MerchantsManagement/components/TerminalIssue/services'
import { CITY_TYPING_PATTERN } from '~/code/constants/Patterns'
import { ITerminalIssueStore } from './ITerminalIssueStore'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { PostalCode } from '~/code/components'
import { PhoneNumberFormItem } from './components'
import { subscriptionServicePeriodOptions } from './constants'
import { DeliveryAddressType } from './models'
import translations from './translations'
import styles from './TerminalIssue.scss'

export const TerminalIssueStoreSymbol = Symbol('TerminallIssueStore')

export const TerminalIssue = observer(() => {

    const store = useInjection<ITerminalIssueStore>(TerminalIssueStoreSymbol)
    const [form] = Form.useForm()
    const uk = translations().UK

    useEffect(() => {
        form.resetFields()
    }, [store.selectedMerchant])

    const renderMIDsOptions = () => {
        return store.mids.map((midItem) => ({label: midItem.mid, value: midItem.mid}))
    }

    const permissionCheck = () => {
        return hasPermissions(
            [PermissionMap.onboarding.processes.create, PermissionMap.onboarding.processesV2.create ]
        )
    }

    const renderMIDInfo = () => (
        <div className={styles.midInfo}>
            <Row>
                <Col sm={12} xl={8}>{translations().descriptor}</Col>
                <Col sm={12} xl={8}>{store.selectedMid?.descriptor}</Col>
            </Row>
            <Row>
                <Col sm={12} xl={8}>{translations().contractNumber}</Col>
                <Col sm={12} xl={8}>{store.selectedMid?.contractNumber}</Col>
            </Row>
            <Row>
                <Col sm={12} xl={8}>{translations().address}</Col>
                <Col sm={12} xl={8}>{store.selectedMid?.address}</Col>
            </Row>
        </div>
    )

    const renderMIDsSelect = () => {
        return (
            <Form.Item
                key={'existingMerchantID'}
                name={'existingMerchantID'}
                label={translations().mid}
                rules={[
                    {required: true, message: translations().required}
                ]}
            >
                <Select
                    options={renderMIDsOptions()}
                    loading={store.isMIDsLoading}
                    onSelect={v => store.setMid(String(v))}
                />
            </Form.Item>
        )
    }

    return (
        <>
            <Title level={3}>{translations().terminalIssueTitle}</Title>
            <Form
                form={form}
                onFinish={store.onFormFinish}
                wrapperCol={{sm: 12, xl: 8}}
                labelCol={{sm: 12, xl: 8}}
                labelAlign={'left'}
                initialValues={{
                    needNewMID: true,
                    address: {
                        country: uk
                    }
                }}
            >
                <Form.Item
                    key={'needNewMID'}
                    name={'needNewMID'}
                    valuePropName={'checked'}
                >
                    <Checkbox disabled={true}>{translations().newMid}</Checkbox>
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                    {(formInstance) => {
                        return formInstance.getFieldValue('needNewMID') ? renderMIDsSelect() : null
                    }}
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                    {() => {
                        return form.getFieldValue('existingMerchantID') ? renderMIDInfo() : null
                    }}
                </Form.Item>
                <Form.Item
                    key={'paymentSolution'}
                    name={'paymentSolution'}
                    label={translations().productType}
                    rules={[
                        {required: true, message: translations().required}
                    ]}
                >
                    <Select options={productTypeOptions}/>
                </Form.Item>
                <Form.Item
                    key={'contractType'}
                    name='contractType'
                    label={translations().contractType}
                    rules={[{ required: true, message: translations().required }]}
                    validateTrigger={['onChange', 'onBlur']}
                  >
                    <Select
                        onSelect={(val) => {
                            val === 'subscription' && form.setFieldsValue({ chargeType: 'byDNA' })
                            store.setContractType(val)
                        }}
                        onChange={() => {
                            form.resetFields([
                                'chargeType',
                                'subscriptionServicePeriod', 
                                'freeSubscriptionPeriod', 
                                'terminalCost', 
                                'setUpConfigurationDeliveryFee', 
                                'feeSubscriptionPerMonth',
                                'posBundles',
                                'POSModel',
                                'posDeliveryAddressType',
                                'posDeliveryInstructions'
                            ])
                        }}
                        options={contractType}
                    />
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                    {t =>
                      t.getFieldValue('contractType') === 'terminalPurchase' && (
                        <>
                          <Form.Item
                            label={translations().terminalCost}
                            name='terminalCost'
                            rules={[
                                { required: true, message: translations().required }, 
                                { type: 'number', min: 1, message: translations().notZero }
                            ]}
                            validateTrigger={['onChange', 'onBlur']}
                          >
                            <InputNumber />
                          </Form.Item>
                        </>
                      )
                    }
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                    {t =>
                      t.getFieldValue('contractType') === 'subscription' && (
                        <>
                            <Form.Item
                                name='chargeType'
                                label={' '}
                            >
                            <Radio.Group
                                onChange={() => {
                                    form.resetFields(['feeSubscriptionPerMonth', 'setUpConfigurationDeliveryFee'])
                                    form.setFieldsValue({freeSubscriptionPeriod: 0})
                                }}
                            >
                              <Radio value='byDNA'>{translations().byDNA}</Radio>
                              <Radio value='byPaytek'>{translations().byPaytek}</Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item
                            label={translations().subscriptionServicePeriod}
                            name='subscriptionServicePeriod'
                            rules={[{ required: true, message: translations().required }]}
                            validateTrigger={['onChange', 'onBlur']}
                          >
                            <Select options={subscriptionServicePeriodOptions} />
                          </Form.Item>
                          <Form.Item
                            label={translations().freeSubscriptionPeriod}
                            name='freeSubscriptionPeriod'
                            rules={[
                                { required: true, message: translations().required },
                                { type: 'number', min: 0, max: 200, message: translations().required }
                            ]}
                            validateTrigger={['onChange', 'onBlur']}
                          >
                            <InputNumber disabled={t.getFieldValue('chargeType') === 'byPaytek'} min={0} />
                          </Form.Item>
                        </>
                      )
                    }
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                    {t =>
                        ((t.getFieldValue('contractType') === 'terminalPurchase' || t.getFieldValue('contractType') === 'subscription') && t.getFieldValue('chargeType') === 'byDNA')  && (
                        <>
                        <Form.Item
                            label={translations().setUpConfigurationDeliveryFee}
                            name='setUpConfigurationDeliveryFee'
                            rules={[
                                { required: true, message: translations().required },
                                { type: 'number', min: 0, max: 1000, message: translations().required }
                            ]}
                            validateTrigger={['onChange', 'onBlur']}
                        >
                            <InputNumber min={0} />
                        </Form.Item>
                        </>
                    )
                    }
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                    {t =>
                      t.getFieldValue('chargeType') === 'byDNA' && (
                        <>
                          <Form.Item
                            label={translations().feeSubscriptionPerMonth}
                            name='feeSubscriptionPerMonth'
                            rules={[
                                { required: true, message: translations().required },
                                { type: 'number', min: 0, message: translations().required }
                            ]}
                            validateTrigger={['onChange', 'onBlur']}
                          >
                            <InputNumber />
                          </Form.Item>
                        </>
                      )
                    }
                </Form.Item>
                <Form.Item
                    key={'POSModel'}
                    name={'POSModel'}
                    label={translations().posModel}
                    rules={[
                        {required: true, message: translations().required}
                    ]}
                >
                    <Select
                        loading={store.isPOSModelsLoading}
                        options={store.posModels}
                        onSelect={(e) => store.getBundlesForSelectedPos(e)}
                        onChange={() => form.resetFields(['posBundles'])}
                    />
                </Form.Item>
                {(store.bundleOptions.length > 0 && store.contractType === 'subscription') && 
                    <Form.Item
                        key={'posBundles'}
                        name={'posBundles'}
                        label={translations().posBundles}
                        rules={[{ required: true, message: translations().required }]}
                        validateTrigger={['onChange', 'onBlur']}
                    >
                        <Select
                            mode='multiple'
                            disabled={store.isPosBundlesLoading}
                            loading={store.isPosBundlesLoading}
                            options={store.bundleOptions}
                        />
                    </Form.Item>
                }
                <Form.Item
                    key={'newTerminalQnt'}
                    name={'newTerminalQnt'}
                    label={translations().terminalsQuantity}
                    rules={[
                        { required: true, message: translations().required },
                        { type: 'number', min: 1, max: 100, message: translations().qntError }
                    ]}
                    getValueFromEvent={ (e: React.FormEvent<HTMLInputElement>) => allowOnlyNumber(form, 'newTerminalQnt', e.currentTarget.value, 100) }
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Checkbox key={'needMoto'} name={'needMoto'} checked={store.isMoto} disabled>{translations().moto}</Checkbox>
                </Form.Item>
                <Form.Item
                    label={translations().posDeliveryAddress}
                    name='posDeliveryAddressType'
                    rules={[ {required: true} ]}
                    validateTrigger={['onChange', 'onBlur']}
                >
                    <Radio.Group
                        onChange={(e) => {
                                store.setDeliveryAddress(e.target.value)
                                form.resetFields(['selectedDeliveryAddress', 'address', 'posDeliveryInstructions'])
                            }
                        }
                    >
                        <Space direction="vertical">
                            <Radio value={DeliveryAddressType.sameAsCompanyAddress}>{translations().sameAsCompany}</Radio>
                            <Radio value={DeliveryAddressType.sameAsStoreAddress}>{translations().sameAsStore}</Radio>
                            <Radio value={DeliveryAddressType.manual}>{translations().manualAddress.title}</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                    {t =>
                      ['sameAsCompanyAddress','sameAsStoreAddress'].includes(t.getFieldValue('posDeliveryAddressType')) && (
                        <Form.Item
                            name='selectedDeliveryAddress'
                            label={translations().selectedDeliveryAddress}
                        >
                            <div>{store.selectedDeliveryAddress}</div>
                        </Form.Item>
                      )
                    }
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                    {t =>
                        t.getFieldValue('posDeliveryAddressType') === 'manual' &&
                            <>
                                <Form.Item
                                    name={['address', 'country']}
                                    label={translations().manualAddress.countryLabel}
                                    tooltip={translations().manualAddress.countryTooltip}
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                        name={['address', 'postalCode']}
                                        label={translations().manualAddress.postalCodeLabel}
                                        getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                            return e.currentTarget.value?.toLocaleUpperCase()
                                        }}
                                        rules={[
                                            {
                                                required: true,
                                                message: translations().manualAddress.postalCodeRequired
                                            },
                                            {
                                                validator: async (rule, value) => {
                                                    if (!store.validatePostalCodeOfUK(value)) {
                                                        throw new Error(translations().manualAddress.postalCodeInvalid)
                                                    }
                                                }
                                            }
                                        ]}
                                        validateTrigger={['onChange', 'onBlur']}
                                    >
                                    <PostalCode
                                        isUK={true}
                                        onAddressSelect={a => {
                                            form.setFieldsValue({
                                                address: {
                                                    addressLine1: a.line_1,
                                                    addressLine2: `${a.line_2} ${a.line_3 ? ', ' + a.line_3 + ',' : ''} ${a.line_4}`,
                                                    townOrCity: a.town_or_city
                                                }
                                            })
                                        }}
                                        onChange={() =>
                                            !store.isCountryUK(form.getFieldValue(['address', 'country'])) &&
                                            form.setFieldsValue({
                                                systemStoreAddress: {
                                                    postalCode: form.getFieldValue(['address', 'postalCode'])
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={['address', 'addressLine1']}
                                    label={translations().manualAddress.addressLine1Label}
                                    rules={[
                                        {
                                            required: true,
                                            message: translations().manualAddress.addressLine1Required
                                        }
                                    ]}
                                    validateTrigger={['onChange', 'onBlur']}
                                >
                                    <Input
                                        onChange={() =>
                                            form.setFieldsValue({
                                                systemStoreAddress: {
                                                    addressLine1: form.getFieldValue(['address', 'addressLine1'])
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                                <Form.Item name={['address', 'addressLine2']} label={translations().manualAddress.addressLine2Label}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={['address', 'townOrCity']}
                                    label={translations().manualAddress.cityLabel}
                                    rules={[
                                        {
                                            required: true,
                                            message: translations().manualAddress.cityRequired
                                        },
                                        {
                                            pattern: CITY_TYPING_PATTERN,
                                            message: translations().manualAddress.cityInvalid
                                        }
                                    ]}
                                    validateTrigger={['onChange', 'onBlur']}
                                >
                                    <Input onChange={e => form.setFieldsValue({ systemStoreAddress: { townOrCity: e.target.value } })} />
                                </Form.Item>
                            </>
                    }
                </Form.Item>
                <Form.Item
                    name='posDeliveryInstructions'
                    label={translations().deliveryInstructions}
                >
                    <Input.TextArea placeholder={translations().deliveryInstructionsPlaceholder} />
                </Form.Item>
                <PhoneNumberFormItem
                    required={false}
                    name='posDeliveryNumber'
                    label={translations().deliveryContactNumber}
                />
                <Row>
                    <Col sm={12} xl={8}>
                        <Form.Item>
                            <Button
                                onClick={() => {
                                    form.resetFields()
                                    store.setIsMoto(false)
                                }}
                            >
                                {translations().clear}
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col sm={12} xl={8}>
                        <Form.Item>
                            <Button
                                disabled={
                                    !permissionCheck()
                                    || store.isLoading
                                    || !store.isMerchantActive
                            }
                                loading={store.isLoading}
                                type={'primary'}
                                htmlType={'submit'}
                            >
                                {translations().submit}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
})
