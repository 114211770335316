import React from 'react'
import { observer } from 'mobx-react'
import { isCompanyPCSListValid, isCompanyPCSValid, isPCSCorporate } from 'startapp/services'
import { OfficerListPage } from '../OfficerListPage'
import translations from './translations'
import { ShareholdersProps } from './props'

export const Shareholders = observer((props: ShareholdersProps) => {
    const { shareholders, deleteShareholder, selectShareholder, onContinue, isSaveLoading, onSave } = props

    return <OfficerListPage
        title={translations().title}
        text={translations().text}
        list={shareholders && shareholders.map((d) => (
            isPCSCorporate(d) ? {
                corporateName: d.name,
                isCompleted: isCompanyPCSValid(d),
                onRemove: () => deleteShareholder(d),
                onClick: () => selectShareholder(d),
                isRemoveDisabled: shareholders.length === 1 || !d.deletionAllowed
            } : {
                name: d.nameElements?.forename,
                surname: d.nameElements?.surname,
                isCompleted: isCompanyPCSValid(d),
                onClick: () => selectShareholder(d),
                onRemove: () => deleteShareholder(d),
                isRemoveDisabled: shareholders.length  === 1 || !d.deletionAllowed
            }
        ))}
        isValid={isCompanyPCSListValid(shareholders)}
        onContinue={onContinue}
        isSaveLoading={isSaveLoading}
        onSave={onSave}
    />
})
