import { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'
import { JobNameType } from '~/code/stores/ExportsStore/models'

export default TranslationBuilder.create<{
    id: string
    createdDate: string
    lastUpdateDate: string
    fileName: string
    jobName: string
    status: string
    jobNameTypes: Record<JobNameType, string>
    jobNameLabel: string
    download: string
    tableTitle: ReactNode
    errorDownloadingFile: string
}>()

