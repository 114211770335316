import React from 'react'
import { observer } from 'mobx-react-lite'
import { Dropdown } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { PortalAccessMenuType } from '~/code/pages/PortalAccess/types/PortalAccessMenuType'
import { onSelectMenu } from '~/code/pages/PortalAccess/utils'
import { PortalAccessMenuDropdownProps } from './props'

import translations from './translations'
import styles from './styles.scss'

export const PortalAccessMenuDropdown = observer(({ email, store }: PortalAccessMenuDropdownProps) => {
  const {
    merchantsTableStore: { data }
  } = store

  const onClick = item => {
    onSelectMenu(
      item.key as PortalAccessMenuType,
      {
        ...data[0],
        email
      },
      store
    )
  }

  const menu = {
    items: [
      {
        key: 'edit-user',
        label: translations().menu.editUser
      },
      {
        key: 'edit-security',
        label: translations().menu.editSecurity
      }
    ],
    onClick
  }

  return (
    <Dropdown menu={menu} className={styles.root}>
      <SettingOutlined />
    </Dropdown>
  )
})
