import React from 'react'
import { observer } from 'mobx-react-lite'
import { Card, Col, Row, Button } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { hasPermissions } from '~/code/services/auth'
import { WarningIconWithTooltip } from '~/code/components'
import {
  PartnerAccessSelect,
  PartnerAccessEmailInput,
  PartnerAccessMenuDropdown
} from '~/code/pages/PartnerAccess/components'
import { IPartnerAccessFilterStore } from '~/code/pages/PartnerAccess/components/PartnerAccessFilter'

import translations from './translations'
import styles from './styles.scss'

export const PartnerAccessFilterStoreSymbol = Symbol('PartnerAccessFilterStore')

export const PartnerAccessFilter = observer(() => {
  const {
    partnerAccessStore,
    partnerAccessTableStore,
    isPartnersLoading,
    partnerSelectOptions,
    isEditPartnerButtonVisible,
    onPartnerSelect,
    onPartnerClear,
    onEmailChange
  } = useInjection<IPartnerAccessFilterStore>(PartnerAccessFilterStoreSymbol)

  const { isPartnerTabActive, selectedPartner, selectedEmail, onPartnerDrawerOpen } = partnerAccessStore

  const tableData = partnerAccessTableStore.tableStore?.data

  const isActionsVisible = !!tableData?.length && hasPermissions([PermissionMap.partners.teammates.update])

  return (
    <Card className={styles.root}>
      {isPartnerTabActive ? (
        <Row>
          <Col sm={24} md={12}>
            <label>{translations().partner}:</label>

            <PartnerAccessSelect
              loading={isPartnersLoading}
              options={partnerSelectOptions}
              className={styles.select}
              onSelect={onPartnerSelect}
              onClear={onPartnerClear}
              value={selectedPartner?.value}
            />

            {!!selectedPartner &&
              selectedPartner.synced === false &&
              hasPermissions([PermissionMap.partners.update]) && (
                <WarningIconWithTooltip className={styles.warningIcon} text={translations().warningText} />
              )}
          </Col>

          {isEditPartnerButtonVisible && hasPermissions([PermissionMap.partners.update]) && (
            <Col sm={24} md={12}>
              <div className={styles.editButtonHolder}>
                <Button type='primary' onClick={() => onPartnerDrawerOpen(true)}>
                  {translations().editPartner}
                </Button>
              </div>
            </Col>
          )}
        </Row>
      ) : (
        <Row>
          <Col sm={24} md={12}>
            <PartnerAccessEmailInput value={selectedEmail} onChange={onEmailChange} />
          </Col>

          <Col sm={24} md={12} xs={24}>
            {isActionsVisible && (
              <PartnerAccessMenuDropdown
                onSelect={key => partnerAccessTableStore.onActionSelect(key, tableData?.[0])}
              />
            )}
          </Col>
        </Row>
      )}
    </Card>
  )
})
