import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    bankingDate: string
    settlementDate: string
    notSettledYet: string
    merchant: string
    address: string
    postcode: string
    mid: string
    terminal: string
    amount: string
    currency: string
    settlementType: string
    acquirerFee: string
    totalFee: string
    interchangeFee: string
    schemeFee: string
    processingFee: string
    gatewayFee: string
    authorisationFee: string
    blendedFee: string
    refundFee: string
    amountToMerchant: string
    amountToSettle: string
    accountNumber: string
    sortCode: string
    operation: string
    transactionId: string
    merchantReference: string
    transactionDate: string
    transactionType: string
    cardScheme: string
    cardMask: string
    cardholderName: string
    issuerCountry: string
    cardType: string
    issuerRegion: string
    isCorporateCard: string
    captureMethod: string
    corporate: string
    personal: string
    pos: string
    posContactless: string
    stored: string
    visa: string
    mastercard: string
    noData: string
    count: string
}>()

