import React from 'react'
import { observer } from 'mobx-react'
import { Button, Form, Input } from 'antd'
import { useTimer } from 'dna-common'

import { AuthForm } from '~/code/components/auth/AuthForm'
import { AuthFormSubmitButton } from '~/code/components/auth/AuthFormSubmitButton'
import translations from './translations'
import { ConfirmEmailProps } from './props'
import styles from './ConfirmEmail.scss'
import { Routes } from '~/code/startup/Router/Routes'

export const ConfirmEmail = observer((props: ConfirmEmailProps) => {
  const { store } = props
  const { start, count, isRunning } = useTimer(60)

  const resend = async () => {
    if (await store.sendCodeToEmailForResetPassword()) {
      start()
    }
  }

  return <AuthForm
    title={translations().title}
  >
    <Form
      validateTrigger={[]}
      onFinish={({ code }) => {
        store.confirmEmail(code)
      }}
    >
      <p className={styles.text}>
        {translations().emailWasSentTo(store.email) }
      </p>
      <Form.Item
        name='code'
        rules={[
          { required: true, message: translations().codeRequired }
        ]}
      >
        <Input
          size='large'
          placeholder={translations().code}
        />
      </Form.Item>

      <p className={styles.text}>{translations().text(store.isLoading || isRunning, count, resend)}</p>

      <AuthFormSubmitButton loading={store.isLoading}>
        {translations().confirm}
      </AuthFormSubmitButton>

      <Button href={Routes.RESET_PASSWORD} size='large' className={styles.back}>
        {translations().back}
      </Button>
    </Form>
  </AuthForm>
})
