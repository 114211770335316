import React from 'react'
import { Button, Tooltip } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { fetchInvoice } from '~/code/stores/invoicesStore/services/fetchers'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import translations from '../translations'
import { StatusesEnum } from '~/code/stores/invoicesStore/models/StatusesEnum'

export const columns = [
    {
        key: 'period',
        dataIndex: 'period',
        width: 160,
        title: translations().period
    },
    {
        key: 'date',
        dataIndex: 'date',
        width: 120,
        title: translations().date,
    },
    {
        key: 'merchantName',
        dataIndex: 'merchantName',
        title: translations().merchantName,
        width: 200,

    },
    {
        key: 'id',
        dataIndex: 'id',
        title: translations().id,
        width: 480

    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: translations().amount
    },
    {
        key: 'currency',
        dataIndex: 'currency',
        title: translations().currency,
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: translations().status,
        width: 130,
        render: (item, object) => {
            return hasPermissions([PermissionMap.invoices.download]) ? (
                <div>
                    {StatusesEnum[item]}
                    <Tooltip title={translations().download}>
                        <Button
                            style={{marginLeft: 10}}
                            icon={<DownloadOutlined />}
                            download
                            onClick={() => fetchInvoice(object.id, `${object.id}.pdf`)}
                        />
                    </Tooltip>
                </div>
            ) : StatusesEnum[item]
        }
    }
]
