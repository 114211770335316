import React, { useRef, forwardRef } from 'react'
import { AutoComplete } from 'antd'
import { Input } from '~/code/components'
import { cleanValue, removePrefix } from '../../services'
import { maxPhoneLength, UKPhonePrefix, phonePlaceHolder } from '../../constants'
import { PhoneNumberInputProps } from './props'

export const PhoneNumberInput = forwardRef<any, PhoneNumberInputProps>(({ options, value, onChange, onBlur, ...rest }, ref) => {
    const stateRef = useRef({ isPasted: false })

    const onCustomChange = (inputValue: string) => {
        let v = cleanValue(inputValue)
        if (stateRef.current.isPasted) {
            v = removePrefix(v)
            stateRef.current.isPasted = false
        }
        if (v.length > maxPhoneLength) {
            v = v.substring(0, maxPhoneLength)
        }
        onChange && onChange(v && UKPhonePrefix + v)
    }

    return options && options.length > 0 ? (
        <AutoComplete
            options={options?.map((o) => ({ value: o, label: o }))}
            ref={ref}
            value={removePrefix(value)}
            onChange={(v, option: any) => {
                const newValue = option.value ? removePrefix(option.value) : v
                onCustomChange(newValue)
            }}
            onBlur={onBlur}
        >
            <Input
                addonBefore={UKPhonePrefix}
                placeholder={phonePlaceHolder}
                onPasteCapture={(e) => {
                    stateRef.current.isPasted = true
                }}
                {...rest}
            />
        </AutoComplete>
    ) : <Input
        ref={ref}
        addonBefore={UKPhonePrefix}
        placeholder={phonePlaceHolder}
        value={removePrefix(value)}
        onChange={(e) => onCustomChange(e.target.value)}
        onPasteCapture={(e) => {
            stateRef.current.isPasted = true
        }}
        onBlur={onBlur}
        {...rest}
    />
})
