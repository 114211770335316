import React from 'react'
import { Card, Col, Divider, Row, Space, Tooltip, Typography } from 'antd'
import moment from 'moment-timezone'
import { CommentOutlined, HistoryOutlined, UserOutlined } from '@ant-design/icons'
import { Bambora, DangerLight, DNALogo, MC, DnaVisa, Paynetics, SuccessLight, WarningLight } from '~/assets/icons'
import { SwitchTumbler } from '~/code/pages/TransactionChannels/components'
import { TransactionChannelNameEnum } from '~/code/pages/TransactionChannels/models/TransactionChannelNameEnum'
import { ChannelConnectionEnum } from '~/code/pages/TransactionChannels/models/ChannelConnectionEnum'
import { TransactionChannelProps } from './props'
import translations from '../../translations'
import styles from './styles.scss'

export const PosTransactionChannel: React.FC<TransactionChannelProps> = (props) => {
    const { transactionChannel, updateTransactionChannel } = props
    const renderLogo = (channel: TransactionChannelNameEnum) => {
        switch (channel) {
            case TransactionChannelNameEnum.BM_AVL:
                return <Bambora/>
            case TransactionChannelNameEnum.PN_AVL:
                return <Paynetics/>
            case TransactionChannelNameEnum.MC_AVL:
                return (
                    <div className={styles.doubleLogoContainer}>
                        <div><MC/></div>
                        <div><DNALogo/></div>
                    </div>
                )
            default: return (
                <div className={styles.doubleLogoContainer}>
                    <div>
                        <DnaVisa/>
                    </div>
                    <div><DNALogo/></div>
                </div>
            )
        }
    }

    const renderConnection = (connection: ChannelConnectionEnum) => {
        switch (connection) {
            case ChannelConnectionEnum.GOOD:
                return <SuccessLight/>
            case ChannelConnectionEnum.UNSTABLE:
                return <WarningLight/>
            case ChannelConnectionEnum.BAD:
                return <DangerLight/>
            default: return <DangerLight/>
        }
    }

    return (
        <>
            <Card className={styles.transactionCard}>
                {!transactionChannel.isActive ? (<div className={styles.mask}>
                    <div className={styles.disabledFlagWrapper}>
                        <div className={styles.disabledFlag}>
                            {translations().comingSoon}
                        </div>
                    </div>
                </div>) : null}
                <>
                    <Row className={styles.logoWrapper}>
                        {renderLogo(transactionChannel.channel as TransactionChannelNameEnum)}
                    </Row>

                    <Row justify={'space-between'}>
                        <Col md={12}><Typography.Title level={5}>{translations().connection}</Typography.Title></Col>
                        <Col md={12}>
                            <div className={styles.connectionStatus}>{renderConnection(transactionChannel.connectionStatus as ChannelConnectionEnum)}</div>
                        </Col>
                    </Row>

                    <Row justify={'space-between'} className={styles.tumblerWrapper}>
                        <Col md={12}><Typography.Title level={5}>{translations().traffic}</Typography.Title></Col>
                        <Col md={12}>
                            <SwitchTumbler
                                isLoading={props.isLoading}
                                isEnabled={transactionChannel.value === 'YES'}
                                isDisabled={!transactionChannel.isActive}
                                updateTransactionChannel={updateTransactionChannel}
                                isOnlyOneTransactionChannelEnabled={false}
                                toggleText={transactionChannel.name}
                                switchType='channel'
                            />
                        </Col>
                    </Row>
                </>

                <Divider/>

                <Space size={'small'} direction={'vertical'}>
                    <Row>
                        <Col xs={2}><UserOutlined/></Col>
                        <Col xs={22}>{translations().lastChangedBy}</Col>
                        <Col className={styles.userComment} offset={2}>
                            <Tooltip placement={'topLeft'} title={transactionChannel.user}>
                                {transactionChannel.user}
                            </Tooltip>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={2}><HistoryOutlined/></Col>
                        <Col xs={22}>{translations().lastChangedDate}</Col>
                        <Col offset={2}>{moment(transactionChannel.lastUpdatedDate).format('DD.MM.YYYY HH:mm:ss')}</Col>
                    </Row>
                    <Row>
                        <Col xs={2}><CommentOutlined/></Col>
                        <Col xs={22}>{translations().userComment}</Col>
                        <Col className={styles.userComment} offset={2}>
                            <Tooltip placement={'topLeft'} title={transactionChannel.comment}>
                                {transactionChannel.comment}
                            </Tooltip>
                        </Col>
                    </Row>
                </Space>
            </Card>
        </>
    )
}

