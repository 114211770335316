import React from 'react'
import { observer } from 'mobx-react'
import translations from './translations'
import { ShareholdersProps } from './props'
import { ListPage } from '../../../ListPage'
import {
  isCompanyPCSValid,
  isCompanyPCSValidWithoutChecks,
  isOfficerVerificationResultPassed,
  isPCSCorporate
} from '~/code/pages/StartProcess/services'

export const Shareholders = observer((props: ShareholdersProps) => {
  const { shareholders, deleteShareholder, selectShareholder, isSaveLoading, onSave, settings } = props

  return (
    <ListPage
      title={translations().title}
      text={translations().text}
      list={
        shareholders &&
        shareholders.map(d =>
          isPCSCorporate(d)
            ? {
                name: d.name,
                isCompleted: isCompanyPCSValid(d),
                onRemove: () => deleteShareholder(d),
                onClick: () => selectShareholder(d),
                isRemoveDisabled: !d.deletionAllowed
              }
            : {
                name: d.nameElements?.forename,
                surname: d.nameElements?.surname,
                isCompleted:
                  settings?.addressAndIDCheck === 'none' ? isCompanyPCSValidWithoutChecks(d) : isCompanyPCSValid(d),
                onClick: () => selectShareholder(d),
                onRemove: () => deleteShareholder(d),
                isRemoveDisabled: !d.deletionAllowed,
                agreementSignatory: d.agreementSignatory,
                officerVerificationStatus:
                  settings?.addressAndIDCheck === 'none'
                    ? null
                    : isOfficerVerificationResultPassed(d.officerVerificationResult, d.jumioCheckResults)
                    ? translations().verified
                    : translations().nonVerified,
                manuallyDocs: d.uploadDocManually
              }
        )
      }
      isSaveLoading={isSaveLoading}
      onSave={onSave}
      onAdd={() => selectShareholder({})}
    />
  )
})
