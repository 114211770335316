import React, { useEffect } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { Typography, Table, Pagination, TablePaginationConfig } from 'antd'
import { TwoFADevicesStoreInterface } from '~/code/stores/Profile/TwoFADevicesStore/TwoFADevicesStoreInterface'
import { getColumns } from './services'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'

import translations from './translations'
import styles from './styles.scss'

type TwoFADevicesProps = {
  className?: string
  onChange?: (v: string) => void
}

export const TwoFADevicesStoreSymbol = Symbol('TwoFADevicesStoreSymbol')
export const TwoFADevicesPaginationContainerStoreSymbol = Symbol('TwoFADevicesPaginationContainerStoreSymbol')

export const TwoFADevices: React.FC<TwoFADevicesProps> = observer(({ className }) => {
  const { isLoading, isVisible, tableStore, twoFAModalStore, deleteDevice } =
    useInjection<TwoFADevicesStoreInterface>(TwoFADevicesStoreSymbol)

  const { isTrustedDeviceSelected } = twoFAModalStore

  const { currentPage, pageSize, totalCount, setPageSize } = tableStore

  const paginationProps: TablePaginationConfig = generateTablePaginationProps(
    currentPage,
    pageSize,
    totalCount,
    pageNumber => tableStore.loadData({ pageNumber }),
    setPageSize
  )

  useEffect(() => {
    if (isTrustedDeviceSelected) {
      tableStore.loadData({ pageNumber: 1 })
    }
  }, [isTrustedDeviceSelected])

  if (!isVisible || totalCount < 1) return null

  return (
    <div className={classNames(className)}>
      <Typography.Title level={5}>{translations().title}</Typography.Title>

      <Table
        scroll={{ x: 500 }}
        columns={getColumns(deleteDevice)}
        dataSource={tableStore.viewData}
        loading={isLoading}
        pagination={false}
        rowKey={'id'}
      />

      <div className={styles.pagination}>
        <Pagination {...paginationProps} />
      </div>
    </div>
  )
})
