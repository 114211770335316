import { ColumnsType } from 'antd/lib/table'
import React from 'react'
import { TotalSummaryType } from '../models'
import translations from '../translations'

export const render = (value, record) => {
    const bold = record.terminalType === 'Total' || record.user === 'Total' || record.acquisitionChannelName === 'Total'
    return <div style={{ fontWeight: bold ? 'bold' : 'normal' }}>{value}</div>
}

export const totalSummaryColumns: ColumnsType<TotalSummaryType> = [
    {
        dataIndex: 'terminalType',
        title: translations().columnTitles.terminalType,
        render
    },
    {
        dataIndex: 'start',
        title: translations().columnTitles.start,
        render
    },
    {
        title: translations().columnTitles.submitted,
        children: [
            {
                dataIndex: ['submitted', 'new'],
                title: translations().columnTitles.new,
                render
            },
            {
                dataIndex: ['submitted', 'addInfoProvided'],
                title: translations().columnTitles.addInfoProvided,
                render
            }
        ]
    },
    {
        title: translations().columnTitles.approved,
        children: [
            {
                dataIndex: ['approved', 'new'],
                title: translations().columnTitles.new,
                render
            },
            {
                dataIndex: ['approved', 'addInfoProvided'],
                title: translations().columnTitles.addInfoProvided,
                render
            }
        ]
    },
    {
        dataIndex: 'referred',
        title: translations().columnTitles.referred,
        render
    },
    {
        dataIndex: 'declined',
        title: translations().columnTitles.declined,
        render
    },
    {
        dataIndex: 'end',
        title: translations().columnTitles.end,
        render
    }
]