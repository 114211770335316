import {
  AdvancedPaymentsModel,
  ProcessingMethodsModel
} from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v1'
import { BankInfo } from '../BankInfo'
import { onboardingBusinessModelQuestionsValues } from '../onboarding/OnboardingBusinessModelQuestions'
import { FinancialInfoItem } from './FinancialInfoItem'
import { ProductItem } from './ProductItem'
import { StoreAddressType } from './StoreAddressType'
import { ContactPerson } from './ContactPerson'

export interface StoreItem {
  id?: number
  storeName?: string
  systemDescriptor?: string
  storeAddress?: StoreAddressType
  systemStoreAddress?: StoreAddressType
  products?: ProductItem[]
  bankInfo?: BankInfo
  mcc?: string
  mccDescription?: string
  industry?: string
  natureOfBusiness?: string
  financialInfo?: FinancialInfoItem
  processingMethods?: ProcessingMethodsModel[]
  advancePayments?: AdvancedPaymentsModel[]
  deliveryAddress?: string
  fullDeliveryAddress?: StoreAddressType
  deliveryInstructions?: string
  deliveryPhoneNumber?: string
  removable?: boolean
  deliveryType?: string
  directDebitBankInfo?: BankInfo
  paymentsInAdvance?: boolean
  contactPerson?: ContactPerson
}

export const defaultFinancialInfo = {
  expectedAnnualTurnover: '',
  expectedAnnualTurnoverCustomValue: '',
  expectedMonthlyTransactionValues: '',
  averageTransactionValue: '',
  tradeOutsideEea: false,
  tradingCountriesOutsideEea: [''],
  businessModelQuestions: onboardingBusinessModelQuestionsValues,
  expectedAnnualCardTurnover: '',
  highestTransactionValue: ''
}
