import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    dailyAmount: string
    dailyCount: string
    monthlyAmount: string
    monthlyCount: string
    perRefund: string
    perTransaction: string
    merchant: string
    selectMerchant: string
    status: string
    selectStatus: string
}>()

