import React from 'react'
import { action, computed, makeObservable } from 'mobx'
import {
  AppstoreAddOutlined, BellOutlined,
  DownloadOutlined,
  ExportOutlined,
  FileTextOutlined,
  HomeOutlined,
  PercentageOutlined,
  ReloadOutlined
} from '@ant-design/icons'
import {
  DashboardOutlined,
  ProfileOutlined,
  UserOutlined,
  FormOutlined,
  SwapOutlined,
  TeamOutlined
} from '@ant-design/icons/lib'

import { SvgSkull } from '~/assets/icons'
import { IMainLayoutStore, MainLayoutParentStore } from '~/code/layouts/MainLayout'
import { MenuDataItemType } from '~/code/models'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { Routes } from '~/code/startup/Router/Routes'
import { filterMenuItems, isPartner } from '~/code/services/auth'
import { goToRoute } from '~/code/startup/Router/utils'
import translations from './translations'

export class MainLayoutStore implements IMainLayoutStore {
  constructor(private parentStore: MainLayoutParentStore) {
    makeObservable(this, {
      menuData: computed,
      onMenuItemClick: action,
      openTasklist: action
    })
  }

  hasPermissions = (permissions: string[]) => this.parentStore.authStore.hasPermissions(permissions)

  get menuData(): MenuDataItemType[] {
    const authStore = this.parentStore.authStore

    const getOnboardingMenu = (): MenuDataItemType => {
      const isBpmOnboardingAnalyticsAvailable = authStore.hasPermissions([PermissionMap.onboarding.analytics.read])

      if (!isBpmOnboardingAnalyticsAvailable) {
        return {
          key: Routes.BPM_REPORTS, // '/bpm-reports/onboarding/processes',
          label: translations().partnerOnboarding,
          icon: <ProfileOutlined />,
          permissions: [PermissionMap.onboarding.processes.read],
          children: [
            {
              key: Routes.BPM_REPORTS_ONBOARDING_PROCESSES, // '/bpm-reports/onboarding',
              label: translations().onboarding,
              permissions: [PermissionMap.onboarding.analytics.read, PermissionMap.onboarding.processes.read]
            },
            {
              key: Routes.BPM_REPORTS_ONBOARDING_PROCESSES_ADDITIONAL_STORE, // '/bpm-reports/onboarding/additional-store',
              label: translations().additionalStoreProcesses,
              permissions: [PermissionMap.onboarding.analytics.read, PermissionMap.onboarding.processes.read]
            }
          ]
        }
      }

      const getChildren = () => {
        const children = [
          {
            key: Routes.BPM_REPORTS_ONBOARDING, // '/bpm-reports/onboarding',
            label: translations().onboarding,
            permissions: [PermissionMap.onboarding.analytics.read, PermissionMap.onboarding.processes.read]
          },
          {
            key: Routes.BPM_REPORTS_ONBOARDING_PROCESSES_ADDITIONAL_STORE, // '/bpm-reports/onboarding/additional-store',
            label: translations().additionalStoreProcesses,
            permissions: [PermissionMap.onboarding.analytics.read, PermissionMap.onboarding.processes.read]
          }
        ]
        if (!isPartner()) {
          children.push({
            key: Routes.BPM_REPORTS_STATISTICS, // '/bpm-reports/statistics',
            label: translations().statistics,
            permissions: [PermissionMap.onboarding.analytics.read]
          })
        }
        return children
      }

      return {
        key: Routes.BPM_REPORTS, // '/bpm-reports',
        label: translations().bmpReports,
        icon: <ProfileOutlined />,
        children: getChildren()
      }
    }

    const getTransactionsMenu = () => {
      const dnaTransactionsMenu = filterMenuItems(
        [
          {
            key: Routes.TRANSACTIONS_DNA_ACQUIRING_TOTAL_ANALYTICS, // '/transactions/dna-acquiring/analytics-total',
            label: translations().analyticsTotal,
            permissions: [PermissionMap.transactions.dna_total_analytics.read]
          },
          {
            key: Routes.TRANSACTIONS_DNA_ACQUIRING_ANALYTICS, // '/transactions/dna-acquiring/analytics',
            label: translations().analytics,
            permissions: [PermissionMap.transactions.dna_ecom_analytics.read]
          },
          {
            key: Routes.TRANSACTIONS_DNA_ACQUIRING_STATEMENT, // '/transactions/dna-acquiring/statement',
            label: translations().ecomMonitoring,
            permissions: [PermissionMap.transactions.dna_ecom_statement.read]
          },
          {
            key: Routes.TRANSACTIONS_DNA_ACQUIRING_ANALYTICS_POS, // '/transactions/dna-acquiring/analytics-pos',
            label: translations().analyticsPos,
            permissions: [PermissionMap.transactions.dna_pos_analytics.read]
          },
          {
            key: Routes.TRANSACTIONS_DNA_ACQUIRING_MONITORING_POS, // '/transactions/dna-acquiring/monitoring-pos-v2',
            label: translations().monitoringPos,
            permissions: [PermissionMap.transactions.dna_pos_statement.v2.read]
          },
          {
            key: Routes.TRANSACTIONS_DNA_ACQUIRING_MONITORING_POS_TEST,
            label: translations().monitoringPosTest,
            permissions: [PermissionMap.transactions.dna_pos_statement.test.read]
          },
          {
            key: Routes.TRANSACTIONS_DNA_ACQUIRING_MONITORING_POS_AMEX,
            label: translations().monitoringPosAmex,
            permissions: [PermissionMap.transactions.dna_pos_amex_statement.read]
          }
        ],
        this.hasPermissions
      )

      const optomanyTransactionsMenu = filterMenuItems(
        [
          {
            key: Routes.TRANSACTIONS_OPTOMANY_CHECKOUT_ANALYTICS, // '/transactions/optomany-checkout-v3/analytics',
            label: translations().analytics,
            permissions: [PermissionMap.transactions.optomany_ecom_analytics.read]
          },
          {
            key: Routes.TRANSACTIONS_OPTOMANY_CHECKOUT_STATEMENT, // '/transactions/optomany-checkout-v3/statement',
            label: translations().ecomMonitoring,
            permissions: [PermissionMap.transactions.optomany_ecom_statement.read]
          }
        ],
        this.hasPermissions
      )

      if (dnaTransactionsMenu.length > 0 && optomanyTransactionsMenu.length > 0) {
        return {
          key: Routes.TRANSACTIONS, // '/transactions',
          label: translations().transactions,
          icon: <DashboardOutlined />,
          children: [
            {
              key: Routes.TRANSACTIONS_DNA_ACQUIRING, // '/transactions/dna-acquiring',
              label: translations().dnaAcquiring,
              children: dnaTransactionsMenu
            },
            {
              key: Routes.TRANSACTIONS_OPTOMANY_CHECKOUT, // '/transactions/optomany-checkout-v3',
              label: translations().optomanyCheckoutV3,
              children: optomanyTransactionsMenu
            }
          ]
        }
      }

      if (optomanyTransactionsMenu.length === 0) {
        return {
          key: Routes.TRANSACTIONS_DNA_ACQUIRING, // '/transactions/dna-acquiring',
          label: translations().transactions,
          icon: <DashboardOutlined />,
          children: dnaTransactionsMenu
        }
      }

      return {
        key: Routes.TRANSACTIONS_OPTOMANY_CHECKOUT, // '/transactions/optomany-checkout-v3',
        label: translations().transactions,
        icon: <DashboardOutlined />,
        children: optomanyTransactionsMenu
      }
    }

    const menuData: MenuDataItemType[] = [
      {
        key: Routes.DASHBOARD,
        label: translations().home,
        icon: (
          <span role='img' className='anticon'>
            <HomeOutlined />
          </span>
        ),
        permissions: [
          PermissionMap.transactions.dna_ecom_realtime.read,
          PermissionMap.transactions.dna_pos_realtime.read
        ]
      },
      getTransactionsMenu(),
      {
        key: Routes.TRANSACTION_CHANNELS, // '/transaction-channels',
        label: translations().transactionChannels,
        icon: (
          <span role='img' className='anticon'>
            <SvgSkull />
          </span>
        ),
        permissions: [PermissionMap.transactions.channels.read]
      },
      {
        key: Routes.SETTLEMENTS,
        label: translations().settlements,
        icon: <SwapOutlined />,
        permissions: [PermissionMap.settlements.read],
        children: [
          {
            key: Routes.SETTLEMENTS_STATEMENT, // '/settlements/statement',
            label: translations().statement,
            permissions: [PermissionMap.settlements.read]
          },
          {
            key: Routes.SETTLEMENTS_STATEMENT_TEST, // '/settlements/statement-test',
            label: translations().statementTest,
            permissions: [PermissionMap.settlements.test.read]
          },
          {
            key: Routes.SETTLEMENTS_PAYMENTS, // '/settlements/payments',
            label: translations().payments,
            permissions: [PermissionMap.settlements.payments.read] // PermissionMap.settlements.payments.read
          }
        ]
      },
      {
        key: Routes.BILLING, // '/billing',
        label: translations().billing,
        icon: <FileTextOutlined />,
        permissions: [PermissionMap.invoices.read, PermissionMap.netSuiteInvoices.download],
        children: [
          {
            key: Routes.INVOICES, // '/billing/invoices',
            label: translations().invoices,
            permissions: [PermissionMap.invoices.read]
          },

          {
            key: Routes.NETSUITE_INVOICES, // '/billing/netsuite-invoices',
            label: translations().netSuiteInvoices,
            permissions: [PermissionMap.netSuiteInvoices.download]
          }
        ]
      },
      {
        key: Routes.CHARGEBACKS, // '/chargebacks',
        label: translations().chargebacks,
        icon: <ReloadOutlined />,
        permissions: [PermissionMap.chargebacks.read]
      },
      {
        key: Routes.MERCHANTS, // '/merchants',
        label: translations().merchantManagement,
        icon: <UserOutlined />,
        permissions: [PermissionMap.merchants.read]
      },
      {
        key: Routes.PARTNER_COMMISSIONS, // '/partner-commissions',
        label: translations().partnerCommissions,
        icon: <PercentageOutlined />,
        permissions: [PermissionMap.partner_commissions.read]
      },
      getOnboardingMenu(),
      {
        key: Routes.BPM_PROCESSES, // '/bpm-processes',
        label: translations().bpmProcesses,
        icon: <ProfileOutlined />,
        children: [
          {
            key: Routes.BPM_PROCESSES_SUBMITTED_REQUESTS, // '/bpm-processes',
            label: translations().riskLimits,
            permissions: [PermissionMap.merchants.processes.read]
          },
          {
            key: Routes.BPM_PROCESSES_MOTO_VT, // '/bpm-processes/moto-vt',
            label: translations().motoVt,
            permissions: [PermissionMap.merchants.processes.read]
          },
          {
            key: Routes.BPM_PROCESSES_CHANGE_BANK_DETAILS_DOSSIER_V2, // '/bpm-processes/dossierbank',
            label: translations().changeBankDetailsDossierV2,
            permissions: [PermissionMap.merchants.processes.read]
          },
          {
            key: Routes.BPM_PROCESSES_CLOSURE_OF_MERCHANT, // '/bpm-processes/closure-of-merchant',
            label: translations().closureOfMerchant,
            permissions: [PermissionMap.merchants.processes.read]
          },
          {
            key: Routes.BPM_PROCESSES_CLOSURE_OF_TERMINAL, // '/bpm-processes/closure-of-terminal',
            label: translations().closureOfTerminal,
            permissions: [PermissionMap.merchants.processes.read]
          },
          {
            key: Routes.BPM_CHANGE_OF_SETTLEMENT_PERIOD, // '/bpm-processes/change-of-settlement-period',
            label: translations().settlementPeriod,
            permissions: [PermissionMap.merchants.processes.read]
          },
          {
            key: Routes.BPM_CHANGE_OF_SETTLEMENT_KIND, // '/bpm-processes/change-of-settlement-type',
            label: translations().settlementType,
            permissions: [PermissionMap.merchants.processes.read]
          },
          {
            key: Routes.BPM_PROCESSES_TERMINAL_ISSUE, // '/bpm-processes/terminal-issue',
            label: translations().terminalIssue,
            permissions: [PermissionMap.merchants.processes.read]
          },
          {
            key: Routes.BPM_CHANGE_OF_SETTLEMENT_STATUS, // '/bpm-processes/change-of-settlement-type',
            label: translations().settlementStatus,
            permissions: [PermissionMap.merchants.processes.read]
          },
          {
            key: Routes.BPM_PROCESSES_ADJUSTMENT_REQUESTS, // '/bpm-processes/adjustment-requests',
            label: translations().adjustmentRequests,
            permissions: [PermissionMap.merchants.processes.read]
          },
          {
            key: Routes.BPM_PROCESSES_EDIT_TARIFFS, // '/bpm-processes/change-of-fees',
            label: translations().editTariffsRequests,
            permissions: [PermissionMap.merchants.processes.read]
          }
        ]
      },
      {
        key: Routes.APPLICATIONS, // '/application',
        label: translations().applications,
        icon: <FormOutlined />,
        children: [
          {
            key: Routes.APPLICATIONS_START_NEW_APPLICATION, // '/start-application',
            label: translations().newApplication,
            permissions: [PermissionMap.onboarding.processes.create]
          },
          {
            key: Routes.APPLICATIONS_START_NEW_PROCESS, // '/start-process',
            label: translations().newProcess,
            permissions: [PermissionMap.onboarding.processes.create, PermissionMap.onboarding.processesV2.create]
          },
          {
            key: Routes.APPLICATIONS_SAVED_APPLICATION, // '/saved-applications',
            label: translations().incompleteApplications,
            permissions: [PermissionMap.onboarding.processes.create, PermissionMap.onboarding.processesV2.create]
          }
        ]
      },
      {
        key: Routes.NOTIFICATIONS_MANAGEMENT, // '/notifications',
        label: translations().notificationsManagement,
        icon: <BellOutlined />,
        permissions: [PermissionMap.notifications.read, PermissionMap.notifications.templates.read],
        children: [
          {
            key: Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_TEMPLATES, // '/start-application',
            label: translations().manageTemplates,
            permissions: [PermissionMap.notifications.templates.read]
          },
          {
            key: Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_NOTIFICATIONS, // '/start-application',
            label: translations().manageNotifications,
            permissions: [PermissionMap.notifications.read]
          },
        ]
      },
      {
        key: Routes.USER_PORTAL,
        label: translations().taskList,
        icon: <ExportOutlined />,
        permissions: [PermissionMap.processes.tasklist.read]
      },
      {
        key: Routes.PORTAL_ACCESS, // '/portal-access',
        label: translations().portalAccess,
        icon: <AppstoreAddOutlined />,
        permissions: [PermissionMap.portalAccess.read]
      },
      {
        key: Routes.PARTNER_ACCESS, // '/partner-access'
        label: translations().partnerDashboardAccess,
        icon: <AppstoreAddOutlined />,
        permissions: [PermissionMap.partners.read]
      },
      {
        key: Routes.TEAMMATES,
        label: translations().teammates,
        icon: <TeamOutlined />,
        permissions: [PermissionMap.teammates.read]
      },
      {
        key: Routes.EXPORTS,
        label: translations().exports,
        icon: <DownloadOutlined />,
        permissions: [
          PermissionMap.settlements.test.download,
          PermissionMap.settlements.download,
          PermissionMap.transactions.dna_pos_statement.v2.read
        ]
      }
    ]

    return filterMenuItems(menuData, this.parentStore.authStore.hasPermissions)
  }

  openTasklist() {
    window.open('/tasklist/', '_blank');
  }

  onMenuItemClick = (path: string) => {
    if (path === Routes.USER_PORTAL) {
      this.openTasklist()
    } else {
      goToRoute(path)
    }
  }
}
