import translations from './translations'
import React from 'react'

translations.add('en', {
  infoText:
    'Choose what type of application you want to start: start a new merchant or issue an additional store or an additional terminal for an existing merchant.',
  ok: 'OK',
  new: 'New',
  existing: 'Additional product for EXISTING merchant',
  required: 'Required',
  onboardNewMerchant: 'Onboard a NEW Merchant',
  newMerchantText: 'Are you onboarding a new merchant?',
  newMerchantDetails:
    'If an onboarding application is submitted for an existing Merchant, it will get stuck and will not be processed.',
  existingMerchantDetails: 'Additional Terminal; Additional MID; Additional Outlet',
  existingInfo: 'You will now be redirected to the "Merchants" section.',
  existingPopupHeader:
    'Additional products for the Existing merchant (the one that is already onboarded) can be requested from the Merchants Section.',
  dontNeedSubmit: 'You don`t need to submit the onboarding application twice.',
  applicationType: 'Select application type:',
  note: '(!) Make sure you select the correct type of the application. This will directly affect the result of the onboarding and related reporting',
  smeAndCorporate: 'SME & Corporate Onboarding Application. NEW MERCHANT',
  smeAndCorporateNote: 'Salesforce ID is mandatory. Can only be used by SME & Corporate Sales Managers',
  partnersAndISO: 'Partners & ISOs. NEW MERCHANT',
  partnersAndISONote: 'Can only be used to submit applications from Partners and ISOs'
})
