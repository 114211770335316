import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    description: string
    title: string
    type: string
    all: string
    maintenance: string
    system: string
    dashboard: string
    merchantPortal: string
    push: string,
    marketing: string,
    block: string
    banner: string
    popup: string
    displayType: string
}>()

