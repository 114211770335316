import { inject, injectable } from 'inversify'
import { message } from 'antd'
import { MerchantsManagementStore } from '~/code/stores/MerchantsManagementStore/MerchantsManagementStore'
import { makeObservable, observable, computed, action, runInAction } from 'mobx'
import { log } from 'dna-common'
import {
  AdvancePaymentsFormStateModel,
  advancePaymentsInitialValues,
  AdvancePaymentsTypes,
  AdvancedPaymentsModel,
  ProcessingMethodsFormStateModel,
  processingMethodsInitialValues,
  ProcessingMethodsModel,
  ProcessingMethodsTypes
} from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v1'
import { RequestMotoVtDossierV2ReqType, StoreType } from './models/merchant-dossier-v2'
import { IMotoVtStore } from '~/code/pages/MerchantsManagement/components/MotoVT/IMotoVtStore'
import { startProcessMotoVtDossierV2 } from '~/code/stores/MerchantsManagementStore/services/fetchers'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import { MerchantsManagementStoreSymbol } from '~/code/pages'
import { AppStore } from '~/code/stores/AppStore'
import { jsonType, stringType, posProductPrefixes, ecomProductPrefixes, terminalActiveStatus } from './constants'
import { ecomMotoPrefix, ProductType } from '~/code/pages/MerchantsManagement/components/MotoVT/constants'
import translations from './translations'
import { FormInstance } from 'antd/es/form'

@injectable()
export class MotoVtStore implements IMotoVtStore {
  merchantsManagementStore: MerchantsManagementStore
  processingMethods: ProcessingMethodsModel[] = []
  advancePayments: AdvancedPaymentsModel[] = []
  processingMethodsFormState: ProcessingMethodsFormStateModel
  advancePaymentsFormState: AdvancePaymentsFormStateModel
  productType: string
  selectedProducts: string[]
  selectedMids: string[]
  isLoading: boolean
  isStatusModalShown: boolean

  constructor(@inject(MerchantsManagementStoreSymbol) merchantsManagementStore: MerchantsManagementStore) {
    this.merchantsManagementStore = merchantsManagementStore
    this.processingMethodsFormState = processingMethodsInitialValues
    this.advancePaymentsFormState = advancePaymentsInitialValues
    this.productType = ProductType.pos
    this.selectedProducts = []
    this.selectedMids = []
    this.isLoading = false
    this.isStatusModalShown = false

    makeObservable(this, {
      processingMethods: observable,
      processingMethodsFormState: observable,
      advancePaymentsFormState: observable,
      productType: observable,
      selectedProducts: observable,
      selectedMids: observable,
      isLoading: observable,
      isStatusModalShown: observable,

      poses: computed,
      ecoms: computed,
      hasSumError: computed,
      isMerchantActive: computed,
      ecomMidList: computed,
      hasEcomTerminals: computed,
      hasPosTerminals: computed,
      stores: computed,
      selectedStoreOriginIds: computed,

      setProcessingMethodsFormState: action.bound,
      setAdvancePaymentsFormState: action.bound,
      setDays: action.bound,
      setProductType: action.bound,
      setSelectedProducts: action.bound,
      onFormFinish: action.bound,
      setSelectedMids: action.bound
    })
  }

  reset() {
    this.selectedProducts = []
    this.selectedMids = []
    this.setProductType(ProductType.pos)
  }

  getTerminals(stores: StoreType[], productPrefixes: string[], isMid = false) {
    const list = []
    stores.forEach(store => {
      store?.contracts?.forEach(contract => {
        const filteredTerminals = contract?.terminals?.filter(terminal => {
          const terminalIdPrefix = terminal?.terminal?.terminalId?.substring(0, 2)
          return (
            terminal?.terminal?.isMoto === false &&
            productPrefixes.includes(terminalIdPrefix) &&
            terminal?.terminal?.terminalStatus === terminalActiveStatus
          )
        })

        filteredTerminals.forEach(terminal => {
          list.push(isMid ? terminal?.terminal?.merchantId : terminal?.terminal)
        })
      })
    })
    return Array.from(new Set(list))
  }

  get stores() {
    return this.merchantsManagementStore.companyFullDossierV2?.processing?.stores || []
  }

  get poses() {
    return this.getTerminals(this.stores, posProductPrefixes)
  }

  get ecoms() {
    return this.getTerminals(this.stores, ecomProductPrefixes)
  }

  get ecomMidList() {
    return this.getTerminals(this.stores, ecomProductPrefixes, true)
  }

  get isMerchantActive() {
    return this.merchantsManagementStore.isMerchantActive
  }

  get hasEcomTerminals() {
    const motoIdentifier = this.ecoms?.find(t => ecomMotoPrefix.includes(t.terminalId?.substring(0, 2)))
    return motoIdentifier?.isMoto === false
  }

  get hasPosTerminals() {
    return this.poses?.length > 0
  }

  get selectedStoreOriginIds() {
    const selectedStores = this.ecoms?.filter(ecom => {
      return this.selectedMids.includes(ecom?.merchantId)
    })
    const selectedStoreIds = selectedStores.map(store => store?.storeId)
    const stores = this.stores?.filter(
      store => selectedStoreIds.includes(store?.storeInfo?.id) && store?.storeInfo?.isActive
    )
    const originIds = stores.map(store => store?.storeInfo?.originId)
    return originIds || []
  }

  setProcessingMethodsFormState(processingKey: ProcessingMethodsTypes, value: ProcessingMethodsModel) {
    this.processingMethodsFormState[processingKey] = value
  }

  setAdvancePaymentsFormState(paymentKey: AdvancePaymentsTypes, value: AdvancedPaymentsModel) {
    this.advancePaymentsFormState[paymentKey] = {
      ...this.advancePaymentsFormState[paymentKey],
      ...value
    }
  }

  setDays(paymentKey: AdvancePaymentsTypes, value: number | string) {
    this.advancePaymentsFormState[paymentKey].days = value
  }

  setProductType = (value: string) => {
    this.productType = value
  }

  setSelectedProducts = (value: string[]) => {
    this.selectedProducts = value
  }

  setSelectedMids = (value: string[]) => {
    this.selectedMids = value
  }

  createMotoVtDossierV2Data(
    form: FormInstance,
    dsrId: number,
    topLevelSubjectId: number
  ): RequestMotoVtDossierV2ReqType {
    const {
      expectedAnnualCardTurnover,
      expectedAnnualTurnover,
      expectedMonthlyTransactionValues,
      averageTransactionValue,
      highestTransactionValue,
      comment
    } = form.getFieldsValue()

    const natureOfTransactions = {
      expectedAnnualCardTurnover,
      expectedAnnualTurnover,
      expectedMonthlyTransactionValues,
      averageTransactionValue,
      highestTransactionValue
    }

    const processingDescription = {
      advancePayments: Object.values(this.advancePaymentsFormState),
      processingMethods: Object.values(this.processingMethodsFormState)
    }
    const posTids = this.poses
      ?.filter(pos => this.selectedProducts.includes(pos?.terminalId))
      .map(pos => pos?.terminalId)
    const ecomTids = this.ecoms.map(ecom => ecom?.terminalId)

    return {
      variables: {
        productType: {
          value: this.productType.toUpperCase(),
          type: stringType
        },
        processingDescription: {
          value: JSON.stringify(processingDescription),
          type: jsonType
        },
        natureAccountTransaction: {
          value: JSON.stringify(natureOfTransactions),
          type: jsonType
        },
        terminals: {
          value: JSON.stringify(this.productType === ProductType.pos ? posTids : ecomTids),
          type: jsonType
        },
        topLevelSubjectId: {
          value: topLevelSubjectId.toString(),
          type: stringType
        },
        dossierId: {
          value: dsrId.toString(),
          type: stringType
        },
        initiatorEmail: {
          value: AppStore.authStore.email,
          type: stringType
        },
        comment: {
          value: comment,
          type: stringType
        },
        storesOriginId: {
          value: JSON.stringify(this.productType === ProductType.ecom ? this.selectedStoreOriginIds : []),
          type: jsonType
        }
      }
    }
  }

  public onFormFinish = async (form: FormInstance) => {
    try {
      runInAction(() => {
        this.isLoading = true
      })
      const { topLevelSubjectId, dsrId } = this.merchantsManagementStore.companyFullDossierV2?.mainInfo

      if (!topLevelSubjectId) {
        message.error(translations().topLevelSubjectIdIsEmpty)
        return
      }

      if (!dsrId) {
        message.error(translations().dsrIdIsEmpty)
        return
      }

      const motoVtRequest = this.createMotoVtDossierV2Data(form, dsrId, topLevelSubjectId)
      const { status, error, result } = await startProcessMotoVtDossierV2(motoVtRequest)

      if (status !== 200 || error) {
        throw new Error(result.message || translations().requestFail)
      } else {
        message.success(result.message || translations().requestSuccess)
        return goToRoute(Routes.BPM_PROCESSES_MOTO_VT)
      }
    } catch (error) {
      message.error(error.message || translations().requestFail)
      log(error)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  onFormFinishFailed = () => {
    message.error(translations().requestFail)
  }

  get hasSumError() {
    const { pos, moto, payByLink, payByApp, ecom, virtualTerminal } = this.processingMethodsFormState
    const sum =
      +pos.percent + +moto.percent + +payByLink.percent + +payByApp.percent + +ecom.percent + +virtualTerminal.percent
    return sum !== 100
  }
}
