import moment, { Moment } from 'moment-timezone'

export const momentUK: Moment = moment.tz('Europe/London')

export const isNowAfter6am = () => momentUK.clone().format('HH') >= '06'
export const getTodayWeekday = () => momentUK.clone().format('dddd')
export const isTodayCurrentMonthFirstBusinessday = () => {
    const monthFirstDay = momentUK.clone().date(1)
    const weekDay = monthFirstDay.day()
    
    if (weekDay === 0) {
      monthFirstDay.add(1, 'days')
    } else if (weekDay === 6) {
      monthFirstDay.add(2, 'days')
    }
    
    return momentUK.isSame(monthFirstDay, 'day')
}

export const isTodaySaturdayOrSunday = () => getTodayWeekday() === 'Saturday' || getTodayWeekday() === 'Sunday'