import moment, { Moment } from 'moment'
import { IntervalType } from '~/code/stores/AnalyticsStore/models/IntervalType'

export const formatXAxis = (date: string, interval: IntervalType, dates: string[], index: number): string => {
    let formattedDate: string | Moment = moment(date)

    switch (interval) {
        case 'hour':
            formattedDate = formattedDate.format('HH:mm')
            break
        case 'day':
            if (dates.length > 20) {
                const day = formattedDate.format('ddd')
                if (day === 'Mon' || index === 0 || index === dates.length - 1) {
                    formattedDate = formattedDate.format('DD ddd')
                } else {
                    formattedDate = formattedDate.format('DD')
                }
            } else {
                formattedDate = formattedDate.format('DD ddd')
            }
            break
        case 'week':
            const fromWeek = formattedDate.format('DD')
            const toWeek = formattedDate.add(6, 'days').format('DD')
            formattedDate = `${fromWeek} - ${toWeek}`
            break
        case 'month':
            formattedDate = formattedDate.format('MMM')
            break
        case 'year':
            formattedDate = formattedDate.format('YYYY')
            break
    }

    return formattedDate
}

export const formatXAxisSecond = (date: string, interval: IntervalType, dates: string[]): string => {
    let formattedDate: string | Moment = moment(date)
    let filteredDates: string[]
    let index: number

    switch (interval) {
        case 'hour':
            formattedDate = formattedDate.format('ddd')
            filteredDates = dates.filter(d => moment(d).format('ddd') === formattedDate)
            index = filteredDates.indexOf(date)
            if (index > 0) {
                formattedDate = ''
            }
            break
        case 'day':
            formattedDate = formattedDate.format('MMM')
            filteredDates = dates.filter(d => moment(d).format('MMM') === formattedDate)
            index = filteredDates.indexOf(date)
            if (index > 0) {
                formattedDate = ''
            }
            break
        case 'week':
            formattedDate = formattedDate.format('MMM')
            filteredDates = dates.filter(d => moment(d).format('MMM') === formattedDate)
            index = filteredDates.indexOf(date)
            if (index > 0) {
                formattedDate = ''
            }
            break
        case 'month':
            formattedDate = formattedDate.format('YYYY')
            filteredDates = dates.filter(d => moment(d).format('YYYY') === formattedDate)
            index = filteredDates.indexOf(date)
            if (index > 0) {
                formattedDate = ''
            }
            break
        case 'year':
            formattedDate = ''
            break
    }

    return formattedDate
}

export const getTooltipTitle = (date: Moment, interval: IntervalType) => {
    let formattedDate: string | Moment = moment(date)

    switch (interval) {
        case 'hour':
            formattedDate = formattedDate.format('ddd HH:mm')
            break
        case 'day':
            formattedDate = formattedDate.format('DD MMM, ddd')
            break
        case 'week':
            const month = formattedDate.format('MMM')
            const fromWeek = formattedDate.format('DD')
            const toWeek = formattedDate.add(6, 'days').format('DD')
            formattedDate = `${fromWeek} - ${toWeek} ${month}`
            break
        case 'month':
            formattedDate = formattedDate.format('MMM YYYY')
            break
        case 'year':
            formattedDate = formattedDate.format('YYYY')
            break
    }

    return formattedDate
}