import { Select } from 'antd'
import React from 'react'
import translations from '../translations'

export const titleGenerator = () => {
  return (
    <>
      {Object.values(translations().titles).map(txt => (
        <Select.Option value={txt} key={txt}>
          {txt}
        </Select.Option>
      ))}
    </>
  )
}

export const genderGenerator = () => {
  return (
    <>
      {Object.values(translations().genders).map(txt => (
        <Select.Option value={txt} key={txt}>
          {txt}
        </Select.Option>
      ))}
    </>
  )
}
