import { SvgAmex, SvgCard, SvgMasterCard, SvgUnknownCard, SvgVisa, UnionPay } from '~/assets/icons'
import React from 'react'

export const cardTypeBackgroundColors = {
    visa: 'rgba(24, 143, 255, 0.8)',
    mastercard: 'rgba(250, 173, 20, 0.8)',
    amex: 'rgba(19, 194, 194, 0.8)',
    americanexpress: 'rgba(19, 194, 194, 0.8)',
    unionpay: 'rgba(240, 72, 100, 0.8)',
    other: 'rgba(114, 46, 209, 0.8)'
}

export const restCardTypeBackgroundColors = [
    'rgba(47, 194, 91, 0.8)',
    'rgba(250, 219, 20, 0.8)',
    'rgba(240, 72, 100, 0.8)',
    'rgba(47, 84, 235, 0.8)',
    'rgba(235, 47, 150, 0.8)',
    'rgba(160, 217, 17, 0.8)'
]

export const backgroundColors = [
    'rgba(24, 143, 255, 0.8)',
    'rgba(47, 194, 91, 0.8)',
    'rgba(250, 219, 20, 0.8)',
    'rgba(240, 72, 100, 0.8)',
    'rgba(114, 46, 209, 0.8)',
    'rgba(19, 194, 194, 0.8)',
    'rgba(235, 47, 150, 0.8)',
    'rgba(250, 173, 20, 0.8)',
    'rgba(47, 84, 235, 0.8)',
    'rgba(160, 217, 17, 0.8)'
]


export const cardTypeIcons = {
    visa: () => <SvgVisa />,
    mastercard: () => <SvgMasterCard />,
    amex: () => <SvgAmex />,
    americanexpress: () => <SvgAmex />,
    unionpay: () => <UnionPay />,
    upi: () => <UnionPay />,
    other: () => <SvgCard />,
    unknown: () => <SvgUnknownCard />
}
