import React from 'react'
import translations from './translations'

translations.add('en', {
    cardTypesCardPaymentsOnly: 'Card Types (card payments only)',
    cardTypes: 'Card Types',
    issuingBanksCardPaymentsOnly: 'Issuing Banks (card payments only)',
    issuingBanks: 'Issuing Banks',
    acquisitionChannels: 'Acquisition Channels',
    all: 'All',
    successful: 'Successful',
    success: 'Success',
    failed: 'Failed',
    other: 'Other',
    percentageChangeTooltipText: (from, to) => (
        `Compared to ${from?.format('YYYY-MM-DD HH:mm')} - ${to?.format('YYYY-MM-DD HH:mm')}`
    ),
    ecomErrorCodes: {
        status: {
            responseCodes: 'response codes',
            cscErrors: 'CSC errors',
            threeDsecureErrors: '3DS errors'
        },
        sumTitle: (status, currencySymbol) => {
            return  <>Total sum of {status} in {currencySymbol || '£'}</>
        },
        countTitle: (status, currencySymbol) => {
            return  <>Total count of {status} in {currencySymbol || '£'}</>
        }
    }
})
