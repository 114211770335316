import React from 'react'
import { AutoComplete, Col, Form, Row, Select } from 'antd'
import merge from 'lodash/merge'
import globalTranslations from '~/code/translations'
import { CITY_TYPING_PATTERN, HOUSE_NAME_NUMBER_TYPING_PATTERN } from '~/code/constants/Patterns'
import { PostalCode, Input } from '~/code/components'
import {
  SortedNationalitySelect,
  SortedCountrySelect,
  NextButton,
  FormSubgroupTitle,
  FormTitle,
  StartAppContainer,
  PhoneNumberFormItem,
  OfficersPopover,
  BelowActionsWithSave
} from 'startapp/components'
import { OfficerProfile } from 'startapp/models'
import {
  convertDateObjectToString,
  convertDateStringToObject,
  isCountryUK,
  validatePostalCodeOfUK,
  validateDateOfBirth
} from 'startapp/services'
import translations from './translations'
import { DetailsFormProps } from './props'
import styles from './DetailsForm.scss'
import { scrollToFirstError } from '~/code/services/helpers'

const convertDataToFormValue = (data: OfficerProfile) =>
  merge(new OfficerProfile(), data, { dateOfBirth: convertDateObjectToString(data?.dateOfBirth) })

export const DetailsForm: React.FC<DetailsFormProps> = (props: DetailsFormProps) => {
  const { title, data, onSubmit, isNextLoading, cachedPhoneNumbers, cachedEmails, isSaveLoading, onSave } = props
  // const refFormSumbitted = useRef<boolean>(false)
  const [form] = Form.useForm()
  const { validateFields, getFieldValue, setFieldsValue, getFieldsValue, getFieldsError } = form

  const forename = data?.nameElements?.forename || ''
  const surname = data?.nameElements?.surname || ''
  const formTitle = forename + (surname ? ' ' + surname : '')

  // convert form data to officer profile data
  const getFormData = () => {
    const values = getFieldsValue()
    const dateOfBirth = convertDateStringToObject(values.dateOfBirth)
    const emailAddress = (values.emailAddress?.toLocaleLowerCase() as string) || ''

    return {
      ...values,
      dateOfBirth,
      emailAddress
    }
  }

  // save form data when unmounting
  // useEffect(() => {
  //     return () => {
  //         if (!refFormSumbitted.current) {
  //             onSubmit(getFormData())
  //         }
  //     }
  // }, [])

  return (
    <StartAppContainer>
      <Form
        form={form}
        autoComplete='off'
        onFinish={() => {
          // refFormSumbitted.current = true
          onSubmit(getFormData())
        }}
        layout='vertical'
        requiredMark='optional'
        scrollToFirstError={scrollToFirstError}
        initialValues={convertDataToFormValue(data)}
      >
        <div className={styles.header}>
          <FormTitle>{title || formTitle}</FormTitle>
          <OfficersPopover onSelect={officer => form.setFieldsValue(convertDataToFormValue(officer))} />
        </div>

        <Row gutter={[64, 0]}>
          <Col xs={24}>
            <FormSubgroupTitle>{translations().personalInfo}</FormSubgroupTitle>

            <Row gutter={16}>
              <Col xs={24} md={6}>
                <Form.Item
                  name={['nameElements', 'title']}
                  label={translations().title}
                  rules={[{ required: true, message: translations().titleRequired }]}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Select allowClear>
                    {['Mr', 'Mrs', 'Miss', 'Ms'].map(txt => (
                      <Select.Option value={txt} key={txt}>
                        {txt}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={18}>
                <Form.Item
                  name={['nameElements', 'forename']}
                  label={translations().name}
                  rules={[{ required: true, message: translations().nameRequired }]}
                  validateTrigger='onBlur'
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name={['nameElements', 'middleName']} label={<div>{translations().middleName}</div>}>
              <Input />
            </Form.Item>

            <Form.Item
              name={['nameElements', 'surname']}
              label={translations().surname}
              rules={[{ required: true, message: translations().surnameRequired }]}
              validateTrigger='onBlur'
            >
              <Input />
            </Form.Item>

            <Row gutter={16}>
              <Col xs={24} md={10}>
                <Form.Item
                  name='dateOfBirth'
                  label={translations().dateBirth}
                  rules={[
                    { required: true, message: translations().dateBirthRequired },
                    {
                      validator: async (rule, value) => {
                        const message = validateDateOfBirth(value)
                        if (message) {
                          throw new Error(message)
                        }
                      }
                    }
                  ]}
                  validateTrigger='onBlur'
                >
                  <Input type={'masked'} mask={'99-99-9999'} placeholder={globalTranslations().datePlaceholder} />
                </Form.Item>
              </Col>

              <Col xs={24} md={10}>
                <Form.Item
                  name='gender'
                  label={translations().gender}
                  rules={[{ required: true, message: translations().genderRequired }]}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Select allowClear>
                    {['Female', 'Male'].map(txt => (
                      <Select.Option value={txt} key={txt}>
                        {txt}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name='birthplace'
              label={translations().placeBirth}
              rules={[{ required: true, message: translations().placeBirthRequired }]}
              validateTrigger={['onChange', 'onBlur']}
            >
              <SortedCountrySelect />
            </Form.Item>

            <Form.Item
              name='nationality'
              label={translations().nationality}
              rules={[{ required: true, message: translations().nationalityRequired }]}
              validateTrigger={['onChange', 'onBlur']}
            >
              <SortedNationalitySelect />
            </Form.Item>

            <Form.Item
              name='occupation'
              label={translations().occupation}
              rules={[{ required: true, message: translations().occupationRequired }]}
              validateTrigger='onBlur'
            >
              <Input />
            </Form.Item>

            <FormSubgroupTitle>{translations().contactInfo}</FormSubgroupTitle>

            <Form.Item
              name='emailAddress'
              label={translations().emailAddress}
              rules={[
                { type: 'email', message: translations().emailFormatIncorrect },
                { required: true, message: translations().emailAddressRequired }
              ]}
              validateTrigger='onBlur'
            >
              <AutoComplete options={cachedEmails?.map(o => ({ value: o, label: o })) || []}>
                <Input placeholder={'me@company.co.uk'} textTransform={'lowercase'} />
              </AutoComplete>
            </Form.Item>

            <PhoneNumberFormItem
              options={cachedPhoneNumbers}
              label={translations().telephoneNumber}
              requiredErrorMessage={translations().telephoneNumberRequired}
              invalidErrorMessage={translations().telephoneNumberWrongFormat}
            />
          </Col>

          <Col xs={24}>
            <FormSubgroupTitle>{translations().addressTitle}</FormSubgroupTitle>

            <Form.Item
              name={['address', 'country']}
              label={translations().country}
              rules={[{ required: true, message: translations().countryRequired }]}
              validateTrigger={['onChange', 'onBlur']}
            >
              <SortedCountrySelect
                onChange={() => {
                  if (getFieldValue(['address', 'postalCode'])) {
                    validateFields([['address', 'postalCode']])
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => prevValues.address?.country !== curValues.address?.country}
            >
              {() => (
                <Form.Item
                  name={['address', 'postalCode']}
                  label={translations().postCode}
                  getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                    return e.currentTarget.value?.toLocaleUpperCase()
                  }}
                  rules={[
                    { required: true, message: translations().postCodeRequired },
                    {
                      validator: async (rule, value) => {
                        const isUK = isCountryUK(getFieldValue(['address', 'country']))
                        if (value && isUK && !validatePostalCodeOfUK(value)) {
                          throw new Error(translations().postCodeInvalid)
                        }
                      }
                    }
                  ]}
                  validateTrigger='onBlur'
                >
                  <PostalCode
                    isUK={isCountryUK(getFieldValue(['address', 'country']))}
                    onAddressSelect={a => {
                      setFieldsValue({
                        address: {
                          houseName: a.building_name || a.sub_building_name,
                          houseNumber: a.building_number || a.sub_building_number,
                          addressLine1: a.thoroughfare,
                          locality: a.town_or_city,
                          region: a.county
                        }
                      })
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item
              name={['address', 'houseName']}
              label={translations().houseName}
              getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                const value = e.currentTarget.value
                if (value && !HOUSE_NAME_NUMBER_TYPING_PATTERN.test(value)) {
                  return getFieldValue(['address', 'houseName']) || ''
                }
                return value
              }}
              validateTrigger='onBlur'
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={['address', 'houseNumber']}
              label={translations().houseNumber}
              getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                const value = e.currentTarget.value
                if (value && !HOUSE_NAME_NUMBER_TYPING_PATTERN.test(value)) {
                  return getFieldValue(['address', 'houseNumber']) || ''
                }
                return value
              }}
              validateTrigger='onBlur'
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={['address', 'addressLine1']}
              label={translations().street}
              rules={[{ required: true, message: translations().streetRequired }]}
              validateTrigger='onBlur'
            >
              <Input />
            </Form.Item>

            <Form.Item name={['address', 'addressLine2']} label={<div>{translations().street2}</div>}>
              <Input />
            </Form.Item>

            <Form.Item
              name={['address', 'locality']}
              label={translations().city}
              rules={[
                { required: true, message: translations().cityRequired },
                { pattern: CITY_TYPING_PATTERN, message: translations().cityInvalid }
              ]}
              validateTrigger='onBlur'
            >
              <Input />
            </Form.Item>

            <Form.Item name={['address', 'region']} label={<div>{translations().region}</div>}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <BelowActionsWithSave
          large
          isLoading={isSaveLoading}
          onSave={() => {
            onSave(getFormData())
          }}
        >
          <NextButton loading={isNextLoading} />
        </BelowActionsWithSave>
      </Form>
    </StartAppContainer>
  )
}
