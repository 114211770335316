import { FormInstance } from 'antd'
import { advancePaymentsData, processingMethodsData } from '../constants/data'
import translations from '../translations'
import { StoreItem } from '../../../models'

export const checkValues = (field: string, tForm: FormInstance) => {
  const fieldValues = tForm.getFieldValue(field)

  switch (field) {
    case 'processingMethods':
      return processingMethodsData.every(method => typeof fieldValues[method.key]?.percent !== 'undefined')
    case 'advancePayments':
      return advancePaymentsData.every(payment => typeof fieldValues[payment.key]?.percent !== 'undefined')
    default:
      return null
  }
}

export const getSum = (field: string, tForm: FormInstance) => {
  const fieldValues = tForm.getFieldValue(field)
  if (fieldValues) {
    switch (field) {
      case 'processingMethods':
        return processingMethodsData.reduce((sum, method) => {
          return fieldValues[method.key] ? sum + +Number(fieldValues[method.key]?.percent || 0) : sum + 0
        }, 0)

      case 'advancePayments':
        return advancePaymentsData.reduce((sum, payment) => {
          return fieldValues[payment.key] ? sum + +Number(fieldValues[payment.key]?.percent || 0) : sum + 0
        }, 0)
      default:
        return 0
    }
  } else return 0
}

export const setProcessingMethodsError = (tForm: FormInstance) => {
  tForm.setFields([
    {
      name: ['processingMethods', 'pos', 'percent'],
      errors: [translations().totalSum],
      validating: false,
      touched: true,
      value: tForm.getFieldValue(['processingMethods', 'pos', 'percent'])
    }
  ])
}

export const resetProcessingMethodsError = (tForm: FormInstance) => {
  tForm.setFields([
    {
      name: ['processingMethods', 'pos', 'percent'],
      errors: [],
      value: tForm.getFieldValue(['processingMethods', 'pos', 'percent'])
    }
  ])
}

export const setAdvancePaymentsError = (tForm: FormInstance) => {
  tForm.setFields([
    {
      name: ['advancePayments', 'nbsp', 'percent'],
      errors: [translations().totalSum],
      validating: false,
      touched: true,
      value: tForm.getFieldValue(['advancePayments', 'nbsp', 'percent'])
    }
  ])
}

export const resetAdvancePaymentsError = (tForm: FormInstance) => {
  tForm.setFields([
    {
      name: ['advancePayments', 'nbsp', 'percent'],
      errors: [],
      value: tForm.getFieldValue(['advancePayments', 'nbsp', 'percent'])
    }
  ])
}

export const setMonthTransactionNumber = (tForm: FormInstance) => {
  const expectedAnnualCardTurnover = tForm
    .getFieldValue(['financialInfo', 'expectedAnnualCardTurnover'])
    .split(',')
    .join('')
  const averageTransactionValue = tForm.getFieldValue(['financialInfo', 'averageTransactionValue']).split(',').join('')

  if (expectedAnnualCardTurnover.length && averageTransactionValue.length) {
    const result = +expectedAnnualCardTurnover / +averageTransactionValue / 12
    tForm.setFieldsValue({
      financialInfo: { expectedMonthlyTransactionValues: Math.floor(result).toString() }
    })
  }
  tForm.validateFields([['financialInfo', 'expectedMonthlyTransactionValues']])
}

export const editQuarterInput = (e: React.FormEvent<HTMLInputElement>) => {
  let value = e.currentTarget.value

  if (value.length > 1) {
    value = value?.replace(/^0+/g, '')
  }

  return value?.replace(/-|\+|\D/, '')
}

export const getInitialValues = (selectedStore: StoreItem) => {
  const processingMethods = {}
  const advancePayments = {}
  selectedStore.processingMethods?.forEach(st => {
    processingMethods[st.name] = { percent: st.percent }
  })

  selectedStore.advancePayments?.forEach(st => {
    advancePayments[st.name] = { percent: st.percent, days: st.days }
  })
  return { ...selectedStore, processingMethods, advancePayments }
}
