import React from 'react'
import moment from 'moment'
import { Col, Row, Input, Form } from 'antd'
import translations from './translations'
import { CompanyInformationFormProps } from './props'

export const CompanyInformationForm: React.FC<CompanyInformationFormProps> = (props) => {
    return (
        <Row gutter={16} className={ props.className }>
            <Col xs={ 24 } sm={14}>
                <Form.Item label={ translations().companyNumber }>
                    <Input
                        readOnly={ true }
                        value={ props.companyNumber }
                    />
                </Form.Item>
            </Col>
            <Col xs={ 24 } sm={ { order: 2 } }>
                <Form.Item label={ translations().address }>
                    <Input.TextArea
                        rows={2}
                        readOnly={ true }
                        style={{ resize: 'none' }}
                        value={ props.address }
                    />
                </Form.Item>
            </Col>
            <Col xs={ 10 } sm={ { order: 1} }>
                <Form.Item label={ translations().registrationDate }>
                    <Input
                        readOnly={ true }
                        value={ moment(props.registrationDate).format('DD / MM / YYYY') }
                    />
                </Form.Item>
            </Col>
        </Row>
    )
}
