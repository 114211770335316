import translations from './translations'

translations.add('en', {
    manageTemplates: 'Manage Templates',
    newTemplate: 'New Template',
    templateDetails: 'Template Details',
    edit: 'Edit',
    delete: 'Delete',
    title: 'Title',
    description: 'Description',
    system: 'System',
    type: 'Type',
    actions: 'Actions',
    createDate: 'Create Date',
    refresh: 'Refresh',
    deleteTemplateTitle: 'You are about to delete the template',
    deleteTemplateContent: 'Are you certain you wish to proceed?',
    viewDetails: 'View Details',
    displayType: 'Display Type',
    contentArea: 'Content Area'
})
