import React, { useEffect, useRef, useState } from 'react'
import { Checkbox, Col, Form, Row, Select, Input as AntInput, AutoComplete, Radio } from 'antd'
import moment from 'moment'
import merge from 'lodash/merge'
import { convertBooleanToString } from 'dna-common'
import { Input, PostalCode } from '~/code/components'
import { URL_PATTERN, CITY_TYPING_PATTERN } from '~/code/constants/Patterns'
import { scrollToFirstError } from '~/code/services/helpers'
import globalTranslations from '~/code/translations'
import { CompanyProfile } from 'startapp/models'
import { isDateOfCreationValid, isCountryUK, validatePostalCodeOfUK } from 'startapp/services'
import {
  FormTitle,
  NextButton,
  FormSubgroupTitle,
  PhoneNumberFormItem,
  BelowActionsWithSave,
  StartAppContainer
} from 'startapp/components'
import translations from './translations'
import { GeneralInformationFormProps } from './props'
import styles from './GeneralInformationForm.scss'

export const GeneralInformationForm: React.FC<GeneralInformationFormProps> = props => {
  const {
    companyType,
    data,
    onSubmit,
    isNextLoading,
    cachedPhoneNumbers,
    cachedEmails,
    setVerifyBankAccountResult,
    isSaveLoading,
    onSave,
    isCompanyWebsiteRequired
  } = props

  const getInitialValues = () =>
    convertDataToFormData(
      merge(new CompanyProfile({}, companyType), data, {
        storeName: data?.companyTradeName?.substring(0, 24),
        registeredOfficeAddress: { country: 'United Kingdom' },
        tradingAddress: { country: 'United Kingdom' }
      })
    )

  useEffect(() => {
    form.setFieldsValue(getInitialValues())
    companyProfileDataRef.current = data
    if (data.bankName) {
      setVerifyBankAccountResult({ type: 'valid', message: data.bankName })
    } else {
      setVerifyBankAccountResult({ type: 'invalid', message: '' })
    }
  }, [data])

  const companyProfileDataRef = useRef<CompanyProfile>(data)

  useEffect(() => {
    return () => {
      onSave(companyProfileDataRef.current)
    }
  }, [])

  const [showTradingAddressBlock, setShowTradingAddressBlock] = useState(true)
  // const refFormSumbitted = useRef<boolean>(false)
  const [form] = Form.useForm()
  const { validateFields, getFieldsValue, getFieldValue, setFieldsValue, setFields } = form

  const convertDataToFormData = (initialData: CompanyProfile) => {
    return {
      ...initialData,
      tradeOutsideEea: convertBooleanToString(initialData?.tradeOutsideEea) || 'false',
      tradingCountriesOutsideEea: initialData?.tradingCountriesOutsideEea?.slice() || [],
      dateOfCreation:
        initialData && initialData.dateOfCreation
          ? moment(initialData.dateOfCreation, 'DD-MM-YYYY').format('DD / MM / YYYY')
          : '',
      bankAccountNumber: initialData?.bankAccountNumber?.replace(/(\d{4})(\d{4})/, '$1 $2'),
      sortCode: initialData?.sortCode?.replace(/-/g, ' - '),
      businessModelQuestions: {
        face2face: {
          ...initialData?.businessModelQuestions?.face2face,
          isTrue: convertBooleanToString(initialData?.businessModelQuestions?.face2face?.isTrue),
          courierServiceUsed: convertBooleanToString(initialData?.businessModelQuestions?.face2face?.courierServiceUsed)
        },
        seasonalBusiness: {
          ...initialData?.businessModelQuestions?.seasonalBusiness,
          isTrue: convertBooleanToString(initialData?.businessModelQuestions?.seasonalBusiness?.isTrue)
        }
      }
    }
  }

  // convert form data to company profile data
  const getFormData = () => {
    const values = getFieldsValue()
    const tradingAddress = getFieldValue(['tradingAddress'])
    return { tradingAddress, ...values } as CompanyProfile
  }

  const handleSubmit = () => {
    onSubmit(getFormData())
  }

  const validateAndTouchFields = (names: string[][]) => {
    setFields(names.map(name => ({ name, touched: true })))
    validateFields(names)
  }

  return (
    <StartAppContainer>
      <Form
        form={form}
        onFinish={handleSubmit}
        autoComplete='off'
        layout='vertical'
        requiredMark={'optional'}
        className={styles.form}
        scrollToFirstError={scrollToFirstError}
        initialValues={getInitialValues()}
        onValuesChange={() => (companyProfileDataRef.current = getFormData())}
      >
        <FormTitle>{translations().title}</FormTitle>

        <Row gutter={[64, 10]}>
          <Col xs={24}>
            <FormSubgroupTitle>{translations().newToDNA}</FormSubgroupTitle>

            <Form.Item
              name={'relationshipType'}
              rules={[{ required: true, message: translations().relationshipTypeRequired }]}
            >
              <Radio.Group>
                <Radio value={'new'}>{translations().new}</Radio>
                <Radio value={'renewal'}>{translations().renewal}</Radio>
              </Radio.Group>
            </Form.Item>

            <FormSubgroupTitle>{translations().companyBlockTitle}</FormSubgroupTitle>

            <Form.Item
              name='companyName'
              label={translations().companyNameLabel}
              rules={[{ required: true, message: translations().companyNameRequired }]}
              validateTrigger='onBlur'
            >
              <Input disabled={companyType === 'company'} />
            </Form.Item>

            <Form.Item
              name='companyTradeName'
              className={styles.companyTradeName}
              label={
                <div className={styles.companyTradeNameLabelContainer}>
                  <span>{translations().companyTradeNameLabel}</span>
                  <Checkbox
                    onChange={e => {
                      const companyTradeName = e.target.checked ? getFieldValue('companyName') : ''
                      setFieldsValue({ companyTradeName, storeName: companyTradeName?.substring(0, 24) })
                    }}
                  >
                    {translations().sameAsCompanyName}
                  </Checkbox>
                </div>
              }
              rules={[{ required: true, message: translations().companyTradeNameRequired }]}
              validateTrigger='onBlur'
            >
              <Input
                onChange={e => {
                  const companyTradeName = e.target.value?.substring(0, 24)
                  setFieldsValue({ storeName: companyTradeName })
                }}
              />
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {() => (
                <Form.Item
                  name='storeName'
                  label={translations().storeNameLabel}
                  tooltip={translations().storeNameInfo}
                  rules={[
                    { required: true, message: translations().storeNameRequired },
                    { max: 24, message: translations().storeNameMax }
                  ]}
                  validateTrigger='onBlur'
                >
                  <Input />
                </Form.Item>
              )}
            </Form.Item>

            {companyType === 'company' && (
              <Form.Item
                name='companyNumber'
                label={translations().companyNumberLabel}
                rules={[{ required: true, message: translations().companyNumberRequired }]}
                validateTrigger='onBlur'
              >
                <Input disabled={true} />
              </Form.Item>
            )}

            <Form.Item
              name='companyWebsite'
              label={translations().companyWebsiteLabel}
              rules={[
                { required: isCompanyWebsiteRequired, message: translations().companyWebsiteRequired },
                { pattern: URL_PATTERN, message: translations().companyWebsiteInvalid }
              ]}
              validateTrigger='onBlur'
            >
              <Input placeholder={'www.mywebsite.com'} textTransform={'lowercase'} />
            </Form.Item>

            <Form.Item
              name='emailAddress'
              label={translations().emailAddressLabel}
              rules={[
                { type: 'email', message: translations().emailFormatIncorrect },
                { required: true, message: translations().emailAddressRequired }
              ]}
              validateTrigger='onBlur'
            >
              <AutoComplete options={cachedEmails?.map(o => ({ value: o, label: o })) || []}>
                <Input placeholder={'me@company.co.uk'} textTransform={'lowercase'} />
              </AutoComplete>
            </Form.Item>

            <PhoneNumberFormItem
              options={cachedPhoneNumbers}
              label={translations().telephoneNumberLabel}
              requiredErrorMessage={translations().telephoneNumberRequired}
              invalidErrorMessage={translations().telephoneNumberWrongFormat}
            />

            <Form.Item
              name='dateOfCreation'
              label={translations().dateOfCreationLabel}
              rules={[
                { required: true, message: translations().dateOfCreationRequired },
                {
                  validator: async (rule, value) => {
                    if (!value) return

                    const formattedValue = moment(value, 'DD - MM - YYYY').format('DD-MM-YYYY')
                    if (!isDateOfCreationValid(formattedValue)) {
                      throw new Error(translations().dateOfCreationInvalid)
                    }
                  }
                }
              ]}
              validateTrigger='onBlur'
              wrapperCol={{ xs: 24, md: 10 }}
            >
              <Input
                type={'masked'}
                className={'ant-input'}
                mask={'99 / 99 / 9999'}
                placeholder={globalTranslations().datePlaceholder}
                disabled={companyType === 'company'}
              />
            </Form.Item>

            <Form.Item
              name='natureOfBusiness'
              label={<div>{translations().natureOfBusinessLabel}</div>}
              rules={[{ required: true, message: translations().natureOfBusinessRequired }]}
              validateTrigger={['onChange', 'onBlur']}
            >
              <Input placeholder={translations().natureOfBusinessPlaceholder} />
            </Form.Item>

            <Form.Item
              name='natureOfBusinessDetails'
              label={translations().natureOfBusinessDetailsLabel}
              rules={[{ required: true, message: translations().natureOfBusinessDetailsRequired }]}
              validateTrigger='onBlur'
            >
              <AntInput.TextArea rows={2} placeholder={translations().natureOfBusinessDetailsPlaceholder} />
            </Form.Item>

            <Form.Item
              name='employeesNumber'
              label={translations().employeesNumberLabel}
              rules={[{ required: true, message: translations().employeesNumberRequired }]}
              validateTrigger={['onChange', 'onBlur']}
            >
              <Select allowClear>
                {Object.keys(translations().employeesNumberOptions).map(key => (
                  <Select.Option key={key} value={translations().employeesNumberOptions[key] || ''}>
                    {translations().employeesNumberOptions[key]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[32, 0]}>
          {companyType === 'company' && (
            <Col xs={24}>
              <FormSubgroupTitle>{translations().companyAddressTitle}</FormSubgroupTitle>

              <Form.Item
                name={['registeredOfficeAddress', 'country']}
                label={translations().companyAddress.countryLabel}
                tooltip={translations().companyAddress.countryTooltip}
                rules={[{ required: true, message: translations().companyAddress.countryRequired }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.registeredOfficeAddress?.country !== curValues.registeredOfficeAddress?.country
                }
              >
                {() => (
                  <Form.Item
                    name={['registeredOfficeAddress', 'postalCode']}
                    label={translations().companyAddress.postalCodeLabel}
                    getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                      return e.currentTarget.value?.toLocaleUpperCase()
                    }}
                    rules={[
                      { required: true, message: translations().companyAddress.postalCodeRequired },
                      {
                        validator: async (rule, value) => {
                          const isUK = isCountryUK(getFieldValue(['registeredOfficeAddress', 'country']))
                          if (value && isUK && !validatePostalCodeOfUK(value)) {
                            throw new Error(translations().companyAddress.postalCodeInvalid)
                          }
                        }
                      }
                    ]}
                    validateTrigger='onBlur'
                  >
                    <PostalCode
                      isUK={isCountryUK(getFieldValue(['registeredOfficeAddress', 'country']))}
                      onAddressSelect={a => {
                        const address = [a.line_2, a.line_3, a.line_4].filter(item => !!item).join(', ')
                        setFieldsValue({
                          registeredOfficeAddress: {
                            addressLine1: a.line_1,
                            addressLine2: address,
                            locality: a.town_or_city,
                            region: a.county
                          }
                        })
                      }}
                    />
                  </Form.Item>
                )}
              </Form.Item>

              <Form.Item
                name={['registeredOfficeAddress', 'addressLine1']}
                label={translations().companyAddress.addressLine1Label}
                rules={[{ required: true, message: translations().companyAddress.addressLine1Required }]}
                validateTrigger='onBlur'
              >
                <Input />
              </Form.Item>

              <Form.Item
                name={['registeredOfficeAddress', 'addressLine2']}
                label={<div>{translations().companyAddress.addressLine2Label}</div>}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name={['registeredOfficeAddress', 'locality']}
                label={translations().companyAddress.cityLabel}
                rules={[
                  { required: true, message: translations().companyAddress.cityRequired },
                  { pattern: CITY_TYPING_PATTERN, message: translations().companyAddress.cityInvalid }
                ]}
                validateTrigger='onBlur'
              >
                <Input />
              </Form.Item>

              <Form.Item
                name={['registeredOfficeAddress', 'region']}
                label={<div>{translations().companyAddress.regionLabel}</div>}
              >
                <Input />
              </Form.Item>
            </Col>
          )}

          <Col xs={24}>
            <div className={styles.tradingBlock}>
              <FormSubgroupTitle className={styles.tradingTitle}>
                {translations().tradingAddressTitle}
              </FormSubgroupTitle>

              {companyType === 'company' && (
                <Checkbox
                  onChange={e => {
                    const checked = e.target.checked
                    setShowTradingAddressBlock(!checked)
                    if (checked) {
                      setFieldsValue({
                        tradingAddress: {
                          country: 'United Kingdom',
                          postalCode: getFieldValue(['companyAddress', 'postalCode']),
                          addressLine1: getFieldValue(['companyAddress', 'addressLine1']),
                          addressLine2: getFieldValue(['companyAddress', 'addressLine2']),
                          locality: getFieldValue(['companyAddress', 'townOrCity']),
                          region: getFieldValue(['companyAddress', 'region']),
                          countyOrState: getFieldValue(['companyAddress', 'countyOrState'])
                        }
                      })
                    } else {
                      setFieldsValue({
                        tradingAddress: {
                          country: 'United Kingdom',
                          postalCode: '',
                          addressLine1: '',
                          addressLine2: '',
                          townOrCity: '',
                          region: '',
                          locality: '',
                          countyOrState: ''
                        }
                      })
                      setTimeout(() => {
                        validateAndTouchFields([
                          ['tradingAddress', 'country'],
                          ['tradingAddress', 'postalCode'],
                          ['tradingAddress', 'addressLine1'],
                          ['tradingAddress', 'addressLine2'],
                          ['tradingAddress', 'locality'],
                          ['tradingAddress', 'region']
                        ])
                      }, 0)
                    }
                  }}
                >
                  {translations().sameAsCompanyAddress}
                </Checkbox>
              )}
            </div>

            {showTradingAddressBlock ? (
              <>
                <Form.Item
                  name={['tradingAddress', 'country']}
                  label={translations().tradingAddress.countryLabel}
                  tooltip={translations().tradingAddress.countryTooltip}
                  rules={[{ required: true, message: translations().tradingAddress.countryRequired }]}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Input disabled />
                </Form.Item>

                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.tradingAddress?.country !== curValues.tradingAddress?.country
                  }
                >
                  {() => (
                    <Form.Item
                      name={['tradingAddress', 'postalCode']}
                      label={translations().tradingAddress.postalCodeLabel}
                      getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                        return e.currentTarget.value?.toLocaleUpperCase()
                      }}
                      rules={[
                        { required: true, message: translations().tradingAddress.postalCodeRequired },
                        {
                          validator: async (rule, value) => {
                            const isUK = isCountryUK(getFieldValue(['tradingAddress', 'country']))
                            if (value && isUK && !validatePostalCodeOfUK(value)) {
                              throw new Error(translations().tradingAddress.postalCodeInvalid)
                            }
                          }
                        }
                      ]}
                      validateTrigger='onBlur'
                    >
                      <PostalCode
                        isUK={isCountryUK(getFieldValue(['tradingAddress', 'country']))}
                        onAddressSelect={a => {
                          const address = [a.line_2, a.line_3, a.line_4].filter(item => !!item).join(', ')
                          setFieldsValue({
                            tradingAddress: {
                              addressLine1: a.line_1,
                              addressLine2: address,
                              locality: a.town_or_city,
                              region: a.county
                            }
                          })
                        }}
                      />
                    </Form.Item>
                  )}
                </Form.Item>

                <Form.Item
                  name={['tradingAddress', 'addressLine1']}
                  label={translations().tradingAddress.addressLine1Label}
                  rules={[{ required: true, message: translations().tradingAddress.addressLine1Required }]}
                  validateTrigger='onBlur'
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name={['tradingAddress', 'addressLine2']}
                  label={<div>{translations().tradingAddress.addressLine2Label}</div>}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name={['tradingAddress', 'locality']}
                  label={translations().tradingAddress.cityLabel}
                  rules={[
                    { required: true, message: translations().tradingAddress.cityRequired },
                    { pattern: CITY_TYPING_PATTERN, message: translations().companyAddress.cityInvalid }
                  ]}
                  validateTrigger='onBlur'
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={['tradingAddress', 'region']}
                  label={<div>{translations().tradingAddress.regionLabel}</div>}
                >
                  <Input />
                </Form.Item>
              </>
            ) : null}
          </Col>
        </Row>

        <BelowActionsWithSave large isLoading={isSaveLoading} onSave={() => onSave(getFormData(), true)}>
          <NextButton loading={isNextLoading} />
        </BelowActionsWithSave>
      </Form>
    </StartAppContainer>
  )
}
