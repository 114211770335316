const prefix = 'STATEMENTS_STORE_TYPES_'
export const SETTLEMENT_STATEMENTS_STORE_TYPES = {
    filter: Symbol(`${prefix}FiltersStore`),
    merchantSelect: Symbol(`${prefix}MerchantSelectStore`),
    rangePicker: Symbol(`${prefix}RangePickerStore`),
    table: Symbol(`${prefix}TableStore`)
}

const prefix_test = 'STATEMENTS_TEST_STORE_TYPES_'
export const SETTLEMENT_STATEMENTS_TEST_STORE_TYPES = {
    filter: Symbol(`${prefix_test}FiltersStore`),
    merchantSelect: Symbol(`${prefix_test}MerchantSelectStore`),
    rangePicker: Symbol(`${prefix_test}RangePickerStore`),
    table: Symbol(`${prefix_test}TableStore`)
}
