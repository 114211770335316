export const Routes = {
  // AUTH
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_FAILURE: '/reset-password-failure',
  CONFIRM_EMAIL: '/confirm-email',
  SET_PASSWORD: '/set-password',

  // MAIN
  MAIN: '/',
  DASHBOARD: '/dashboard',

  // TRANSACTIONS
  TRANSACTIONS: '/transactions',

  // DNA ACQUIRING
  TRANSACTIONS_DNA_ACQUIRING: '/transactions/dna-acquiring',
  TRANSACTIONS_DNA_ACQUIRING_TOTAL_ANALYTICS: '/transactions/dna-acquiring/analytics-total',
  TRANSACTIONS_DNA_ACQUIRING_ANALYTICS: '/transactions/dna-acquiring/analytics',
  TRANSACTIONS_DNA_ACQUIRING_ANALYTICS_POS: '/transactions/dna-acquiring/analytics-pos',
  TRANSACTIONS_DNA_ACQUIRING_STATEMENT: '/transactions/dna-acquiring/statement',
  TRANSACTIONS_DNA_ACQUIRING_MONITORING_POS: '/transactions/dna-acquiring/monitoring-pos',
  TRANSACTIONS_DNA_ACQUIRING_MONITORING_POS_TEST: '/transactions/dna-acquiring/monitoring-pos-test',
  TRANSACTIONS_DNA_ACQUIRING_MONITORING_POS_AMEX: '/transactions/dna-acquiring/monitoring-pos-amex',

  // OPTOMANY
  TRANSACTIONS_OPTOMANY_CHECKOUT: '/transactions/optomany-checkout-v3',
  TRANSACTIONS_OPTOMANY_CHECKOUT_ANALYTICS: '/transactions/optomany-checkout-v3/analytics',
  TRANSACTIONS_OPTOMANY_CHECKOUT_STATEMENT: '/transactions/optomany-checkout-v3/statement',

  // BPM-REPORTS
  BPM_REPORTS: '/bpm-reports',
  BPM_REPORTS_ONBOARDING: '/bpm-reports/onboarding',
  BPM_REPORTS_ONBOARDING_PROCESSES: '/bpm-reports/onboarding/processes',
  BPM_REPORTS_ONBOARDING_PROCESS: '/bpm-reports/onboarding/processes/:id/:type',
  BPM_REPORTS_ONBOARDING_ANALYTICS: '/bpm-reports/onboarding/analytics',
  BPM_REPORTS_STATISTICS: '/bpm-reports/statistics',
  BPM_REPORTS_ONBOARDING_PROCESSES_ADDITIONAL_STORE: '/bpm-reports/onboarding/additional-store',

  // BPM-PROCESSES
  BPM_PROCESSES: '/bpm-processes',
  BPM_PROCESSES_SUBMITTED_REQUESTS: '/bpm-processes/submitted-requests',
  BPM_PROCESSES_MOTO_VT: '/bpm-processes/moto-vt',
  BPM_PROCESSES_CHANGE_BANK_DETAILS_DOSSIER_V2: '/bpm-processes/dossierbank',
  BPM_PROCESSES_CLOSURE_OF_MERCHANT: '/bpm-processes/closure-of-merchant',
  BPM_PROCESSES_CLOSURE_OF_TERMINAL: '/bpm-processes/closure-of-terminal',
  BPM_CHANGE_OF_SETTLEMENT_PERIOD: '/bpm-processes/change-of-settlement-period',
  BPM_PROCESSES_TERMINAL_ISSUE: '/bpm-processes/terminal-issue',
  BPM_PROCESSES_ADJUSTMENT_REQUESTS: '/bpm-processes/adjustment-requests',
  BPM_CHANGE_OF_SETTLEMENT_KIND: '/bpm-processes/change-of-settlement-type',
  BPM_CHANGE_OF_SETTLEMENT_STATUS: '/bpm-processes/change-of-settlement-status',
  BPM_PROCESSES_EDIT_TARIFFS: '/bpm-processes/change-of-fees',

  // APPLICATIONS
  APPLICATIONS: '/applications',
  APPLICATIONS_START_NEW_APPLICATION: '/applications/start-application',
  APPLICATIONS_START_APPLICATION: '/applications/start-application/:id',
  APPLICATIONS_SAVED_APPLICATION: '/applications/saved-applications',
  APPLICATIONS_START_NEW_PROCESS: '/applications/start-process',
  APPLICATIONS_START_PROCESS: '/applications/start-process/:id',
  APPLICATIONS_START_PROCESS_ADDITIONAL: '/applications/start-process/additional-store/:id/:uberID',

  // MERCHANTS
  MERCHANTS: '/merchants',

  // PARTNER-COMMISSIONS
  PARTNER_COMMISSIONS: '/partner-commissions',

  // SETTLEMENTS
  SETTLEMENTS: '/settlements',
  SETTLEMENTS_STATEMENT: '/settlements/statement',
  SETTLEMENTS_STATEMENT_TEST: '/settlements/statement-test',
  SETTLEMENTS_PAYMENTS: '/settlements/payments',

  // CHARGEBACKS
  CHARGEBACKS: '/chargebacks',

  // TRANSACTION CHANNELS
  TRANSACTION_CHANNELS: '/transaction-channels',
  TRANSACTION_CHANNELS_GLOBAL_SETTINGS: '/transaction-channels/global-settings',
  TRANSACTION_CHANNELS_MERCHANT_SETTINGS: '/transaction-channels/merchant-settings',

  // NOTIFICATION MANAGEMENT
  NOTIFICATIONS_MANAGEMENT: '/notifications-management',
  NOTIFICATIONS_MANAGEMENT_MANAGE_TEMPLATES: '/notifications-management/manage-templates',
  NOTIFICATIONS_MANAGEMENT_MANAGE_TEMPLATES_CREATE: '/notifications-management/manage-templates/create',
  NOTIFICATIONS_MANAGEMENT_MANAGE_TEMPLATES_EDIT: '/notifications-management/manage-templates/edit/:id',
  NOTIFICATIONS_MANAGEMENT_MANAGE_NOTIFICATIONS: '/notifications-management/manage-notifications',
  NOTIFICATIONS_MANAGEMENT_PUBLISH_NOTIFICATIONS: '/notifications-management/manage-notifications/publish-notification',

  // BILLING
  BILLING: '/billing',

  // BILLING INVOICES
  INVOICES: '/billing/invoices',

  NETSUITE_INVOICES: '/billing/netsuite-invoices',

  // PORTAL ACCESS
  PORTAL_ACCESS: '/portal-access',

  // PARTNER ACCESS
  PARTNER_ACCESS: '/partner-access',

  // TEAM MANAGEMENT
  TEAMMATES: '/teammates',

  // EXPORTS
  EXPORTS: '/exports',

  // PROFILE
  PROFILE: '/profile',

  // 2FA
  TWO_FA: '/two-step-authentication',

  // 2FA ENFORCED
  TWO_FA_ENFORCED: '/enforce-2fa',

  // 2FA CONFIRM
  TWO_FA_CONFIRM: '/confirm-2fa',

  // RECOVERY CODE
  RECOVERY_CODE: '/recovery-code',

  // WILDCARD
  WILDCARD: '*',
  USER_PORTAL: '/user-portal'
}
