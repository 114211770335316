import React, { useEffect } from 'react'
import { Col, Form, Input, Radio, Row, Typography, Button, Divider, Empty, Checkbox } from 'antd'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { ONLY_DIGITS_PATTERN } from '~/code/models/Patterns'
import { IMotoVtStore } from './IMotoVtStore'
import {
  ProcessingMethodsTypes,
  AdvancePaymentsTypes
} from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v1'
import { ProductType } from './constants'
import { processingMethodsData, advancePaymentsData, DECIMAL_NUMBER_PATTERN, PERCENTAGE_PATTERN } from './constants'
import translations from './translations'
import styles from './MotoVT.scss'

export const MotoVtStoreSymbol = Symbol('MotoVtStore')
export const MotoVT = observer(() => {
  const [form] = Form.useForm()

  const store = useInjection<IMotoVtStore>(MotoVtStoreSymbol)

  const {
    setAdvancePaymentsFormState,
    setProcessingMethodsFormState,
    setDays,
    setProductType,
    setSelectedProducts,
    setSelectedMids
  } = store

  useEffect(() => {
    form.resetFields()
    store.reset()
  }, [store.merchantsManagementStore.selectedMerchant])

  useEffect(() => {
    if (store.productType === ProductType.ecom) {
      store.setSelectedMids(store.ecomMidList)
      form.setFieldsValue({ mids: store.selectedMids })
    }
  }, [store.productType])

  const refreshForm = () => {
    form.resetFields()
  }

  const setError = () => {
    form.setFields(
      processingMethodsData.map(field => ({
        name: field.key,
        errors: [translations().totalSum],
        validating: false,
        touched: true,
        value: form.getFieldValue(field.key)
      }))
    )
  }

  const resetError = () => {
    form.setFields(
      processingMethodsData.map(field => ({ name: field.key, errors: [], value: form.getFieldValue(field.key) }))
    )
  }

  const renderProcessingMethodBlock = () => {
    return processingMethodsData.map(method => (
      <Form.Item
        key={method.key}
        name={method.key}
        label={method.description}
        rules={[
          {
            validator: async (_, rawValue) => {
              if (!rawValue) return Promise.reject(new Error(translations().required))
              else if (rawValue < 0 || rawValue > 100) {
                return Promise.reject(new Error(translations().range))
              } else if (!ONLY_DIGITS_PATTERN.test(rawValue)) {
                return Promise.reject(new Error(translations().digitsOnly))
              } else if (
                form.isFieldsTouched(
                  processingMethodsData.map(data => data.key),
                  true
                ) &&
                store.hasSumError
              ) {
                setError()
              } else {
                resetError()
                return Promise.resolve()
              }
            }
          }
        ]}
        wrapperCol={{ span: 10 }}
      >
        <Input
          onChange={event =>
            setProcessingMethodsFormState(method.key as ProcessingMethodsTypes, {
              name: method.key,
              description: method.description,
              percent: Number(event.target.value)
            })
          }
          addonAfter='%'
        />
      </Form.Item>
    ))
  }

  const renderAdvancePaymentsBlock = () => {
    const renderDescriptionField = (payment, isRequired) => (
      <Form.Item
        name={`${payment.key}${translations().description}`}
        rules={[{ required: isRequired, message: translations().enterDetails }]}
      >
        <Input
          placeholder={translations().enterDetails}
          onChange={event =>
            setAdvancePaymentsFormState(payment.key as AdvancePaymentsTypes, {
              name: payment.key,
              description: event.target.value
            })
          }
        />
      </Form.Item>
    )

    return advancePaymentsData.map(payment => (
      <Form.Item key={payment.key} wrapperCol={{ span: 10 }} label={payment.description}>
        <Input.Group compact className={styles.inputGroup}>
          <Form.Item
            className={styles.inputItem}
            name={payment.key}
            rules={[
              { required: true, message: translations().required },
              {
                pattern: DECIMAL_NUMBER_PATTERN,
                message: translations().onlyPositive
              },
              {
                pattern: PERCENTAGE_PATTERN,
                message: translations().range
              }
            ]}
          >
            <Input
              onChange={event =>
                setAdvancePaymentsFormState(payment.key as AdvancePaymentsTypes, {
                  name: payment.key,
                  description: payment.description,
                  percent: Number(event.target.value)
                })
              }
              addonAfter='%'
            />
          </Form.Item>
          <Form.Item
            className={styles.inputItem}
            name={`${payment.key}${translations().days}`}
            rules={[
              { required: true, message: translations().required },
              {
                pattern: DECIMAL_NUMBER_PATTERN,
                message: translations().onlyPositive
              }
            ]}
          >
            <Input onChange={event => setDays(payment.key as AdvancePaymentsTypes, Number(event.target.value))} />
          </Form.Item>
        </Input.Group>

        {payment.hasInput && (
          <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.other !== currentValues.other}>
            {({ getFieldValue }) =>
              getFieldValue('other') !== '0'
                ? renderDescriptionField(payment, true)
                : renderDescriptionField(payment, false)
            }
          </Form.Item>
        )}
      </Form.Item>
    ))
  }

  const onFormFinishProcess = () => {
    store.onFormFinish(form)
  }

  const posTab = store.productType === ProductType.pos
  const ecomTab = store.productType === ProductType.ecom

  const renderForm = () => {
    return (
      <Form
        name='moto/vt'
        form={form}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 9 }}
        labelAlign='left'
        labelWrap={true}
        onFinish={onFormFinishProcess}
        onFinishFailed={store.onFormFinishFailed}
      >
        <Row gutter={24}>
          {store.productType === ProductType.pos ? (
            <Col span={4}>
              <Form.Item
                name='tids'
                className={styles.tidList}
                wrapperCol={{ span: 10 }}
                rules={[
                  {
                    required: true,
                    message: translations().required
                  }
                ]}
              >
                <Checkbox.Group
                  options={store.poses.map(item => ({
                    value: item.terminalId,
                    label: `${item.terminalId}`
                  }))}
                  value={store.selectedProducts}
                  onChange={(value: string[]) => setSelectedProducts(value)}
                ></Checkbox.Group>
              </Form.Item>
            </Col>
          ) : null}
          {store.productType === ProductType.ecom ? (
            <Col span={4}>
              <Form.Item
                name='mids'
                className={styles.tidList}
                wrapperCol={{ span: 10 }}
                rules={[
                  {
                    required: true,
                    message: translations().required
                  }
                ]}
              >
                <Checkbox.Group
                  options={store.ecomMidList.map(item => ({
                    value: item,
                    label: `${item}`
                  }))}
                  value={store.selectedMids}
                  onChange={(value: string[]) => setSelectedMids(value)}
                ></Checkbox.Group>
              </Form.Item>
            </Col>
          ) : null}
          <Col span={20}>
            <Typography.Title level={5} className={styles.titleStyle}>
              {translations().financialInformation}
            </Typography.Title>

            <Form.Item
              name={'expectedAnnualTurnover'}
              label={translations().annualTurnover}
              rules={[{ required: true, message: translations().required }]}
              wrapperCol={{ span: 10 }}
            >
              <Input addonAfter='£' />
            </Form.Item>

            <Form.Item
              name={'expectedAnnualCardTurnover'}
              label={translations().annualCardTurnover}
              rules={[{ required: true, message: translations().isRequired }]}
              wrapperCol={{ span: 10 }}
            >
              <Input addonAfter='£' />
            </Form.Item>

            <Form.Item
              name={'expectedMonthlyTransactionValues'}
              label={translations().monthTransactions}
              rules={[{ required: true, message: translations().required }]}
              wrapperCol={{ span: 10 }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={'highestTransactionValue'}
              label={translations().highestTransaction}
              rules={[{ required: true, message: translations().required }]}
              wrapperCol={{ span: 10 }}
            >
              <Input addonAfter='£' />
            </Form.Item>

            <Form.Item
              name={'averageTransactionValue'}
              label={translations().averageTransaction}
              rules={[{ required: true, message: translations().required }]}
              wrapperCol={{ span: 10 }}
            >
              <Input addonAfter='£' />
            </Form.Item>

            <Divider />

            <Typography.Title level={5} className={styles.titleStyle}>
              {translations().turnoverMethod}
            </Typography.Title>

            <Row gutter={16}>
              <Col span={9}>
                <Typography.Text strong>{translations().processingMethod}</Typography.Text>
              </Col>
              <Col span={7}>
                <Typography.Text strong>{translations().cartTurnoverPercent}</Typography.Text>
              </Col>
            </Row>

            {renderProcessingMethodBlock()}

            <Typography.Text style={{ fontStyle: 'italic' }}>{translations().ifMerchantNew}</Typography.Text>
            <Divider />
            <Typography.Title level={5} className={styles.titleStyle}>
              {translations().howFar}
            </Typography.Title>

            <Row gutter={16}>
              <Col span={9}></Col>
              <Col span={5}>
                <Typography.Text strong>{translations().cardTurnover}</Typography.Text>
              </Col>
              <Col span={3} offset={1}>
                <Typography.Text strong>{translations().days}</Typography.Text>
              </Col>
            </Row>

            {renderAdvancePaymentsBlock()}
            <Typography.Text style={{ fontStyle: 'italic' }}>{translations().ifMerchantNew}</Typography.Text>

            <Form.Item
              name={'comment'}
              className={styles.commentMargin}
              label={translations().haveComments}
              wrapperCol={{ span: 10 }}
            >
              <Input.TextArea placeholder={translations().comment} allowClear={true} />
            </Form.Item>
            <Row className={styles.btnsStyle}>
              <Col>
                <Form.Item>
                  <Button size='large' onClick={refreshForm}>
                    {translations().clear}
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    loading={store.isLoading}
                    disabled={!store.isMerchantActive}
                    htmlType='submit'
                    size='large'
                    type='primary'
                  >
                    {translations().submit}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    )
  }

  const renderNoTerminals = () => {
    return (
      <Row className={styles.noTerminals}>
        <Empty
          description={
            <Typography.Title level={5} className={styles.notAvailableMargin}>
              {translations().notAvailable}
            </Typography.Title>
          }
        />
      </Row>
    )
  }

  return (
    <>
      <Typography.Title level={3}>
        {store.productType === ProductType.pos ? translations().requestMoto : translations().requestVt}
      </Typography.Title>

      <Radio.Group
        defaultValue={ProductType.pos}
        size='large'
        buttonStyle='solid'
        onChange={event => setProductType(event.target.value)}
      >
        <Radio.Button value={ProductType.pos}>{translations().posLabel}</Radio.Button>
        <Radio.Button value={ProductType.ecom}>{translations().ecomLabel}</Radio.Button>
      </Radio.Group>

      {ecomTab && (!store.hasEcomTerminals ? renderNoTerminals() : renderForm())}

      {posTab && (!store.hasPosTerminals ? renderNoTerminals() : renderForm())}
    </>
  )
})
