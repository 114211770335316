import { TranslationBuilder } from '~/code/components/Translation'
import countriesEn from '~/assets/jsons/eea-countries.en.json'

const translations = TranslationBuilder.create<string[]>()

translations.add('en', countriesEn)

export function getEEACountries() {
    return translations()
}
