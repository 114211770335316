import React, { useEffect } from 'react'
import { useInjection } from 'dna-react-ioc'
import { observer } from 'mobx-react'
import { Button, Card, Col, Row, Space, Steps, Typography } from 'antd'
import { PageHeaderRaw } from '~/code/components'
import { Routes } from '~/code/startup/Router/Routes'
import { goToRoute } from '~/code/startup/Router/utils'
import {
  MaintenanceSetupStep,
  ManageNotificationTemplateStoreSymbol,
  SelectTemplateStep
} from '~/code/pages/Notifications/ManageNotificationTemplates/components'
import { IManageNotificationTemplateStore } from '~/code/pages/Notifications'
import translations from './translations'
import { LeftOutlined } from '@ant-design/icons'

export const CreateTemplates = observer(({id} : {id?: string}) => {
  const store = useInjection<IManageNotificationTemplateStore>(ManageNotificationTemplateStoreSymbol)
  const {step, loadTemplate, setStep, selectedTemplate, setSelectedTemplate, setNotificationSetupForm } = store


  useEffect(() => {
    if(id) loadTemplate(id).then(() => setStep(1))

    return () => {
      clearForm()
    }
  }, [id])

  const steps = [
    {
      title: translations().selectType,
      content:<SelectTemplateStep />,
    },
    {
      title: translations().fillInDetails,
      content: <MaintenanceSetupStep />,
    }
  ]

  const items = steps.map((item) => ({ key: item.title, title: item.title }))

  const onBackBtnClick = () => {
    step === 0 || selectedTemplate ? goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_TEMPLATES) : stepBack()
  }

  const stepBack = () => {
    setStep(step - 1)
    clearForm()
  }

  const clearForm = () => {
    setSelectedTemplate(null)
    setNotificationSetupForm(null)
  }

  return (
    <>
      <Space direction={'vertical'} size={'large'} style={{display: 'flex'}}>
        <PageHeaderRaw>
         <Space direction={'vertical'} size={'small'} style={{display: 'flex'}}>
           <Row>
             <Button
               onClick={onBackBtnClick}
               icon={<LeftOutlined />}
               type={'link'}
               size={'small'}
               style={{paddingLeft: 0}}
             >
               Back
             </Button>
           </Row>
           <Row>
             <Col>
               <Typography.Title level={5}>
                 {id ? translations().editNotificationTemplate : translations().createNotificationTemplate}
               </Typography.Title>
             </Col>
           </Row>
         </Space>
        </PageHeaderRaw>

        {selectedTemplate && selectedTemplate.id ? null : (
          <Card>
            <Row justify={'center'}>
              <Col sm={24}>
                <Steps current={step} items={items} />
              </Col>
            </Row>
          </Card>
        )}

        <Row>
          <Col sm={24}>
            {steps[step].content}
          </Col>
        </Row>
      </Space>
    </>
  )
})