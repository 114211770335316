import { log } from 'dna-common'
import { inject, injectable } from 'inversify'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { message } from 'antd'
import { AppStore } from '../AppStore'
import { MerchantsManagementStoreSymbol } from '~/code/pages'
import { MerchantsManagementStore } from './MerchantsManagementStore'
import { IRoutingDataStore } from '~/code/pages/MerchantsManagement/components/MerchantApplication/components/RoutingTable/IRoutingDataStore'
import { loadRoutingData, startChangeRouteProcess } from './services/fetchers'
import { RoutingDataType } from './models/merchant-dossier-v2'
import { jsonType, stringType, posProductPrefixes } from './constants'
import translations from './translations'

@injectable()
export class RoutingDataStore implements IRoutingDataStore {
  merchantsManagementStore: MerchantsManagementStore
  routingData: RoutingDataType[] = []
  isRoutingDataLoading: boolean = false
  selectedTIDs: string[] = []
  isModalOpen: boolean = false
  isSubmitButtonLoading: boolean = false
  constructor(@inject(MerchantsManagementStoreSymbol) merchantsManagementStore: MerchantsManagementStore) {
    this.merchantsManagementStore = merchantsManagementStore

    makeObservable(this, {
      isRoutingDataLoading: observable,
      routingData: observable,
      selectedTIDs: observable,
      isModalOpen: observable,
      isSubmitButtonLoading: observable,
      companyFullDossierV2: computed,
      setSelectedRows: action.bound,
      openModal: action.bound,
      closeModal: action.bound
    })
  }

  get companyFullDossierV2(): number {
    return this.merchantsManagementStore.companyFullDossierV2?.mainInfo?.dsrId
  }

  setSelectedRows = (
    record: RoutingDataType,
    selected: boolean,
    selectedRows: RoutingDataType[],
    nativeEvent: Event
  ) => {
    this.selectedTIDs = selectedRows.map(row => row.terminalId)
  }

  setSelectAllRows = (selected: boolean, selectedRows: RoutingDataType[], changeRows: RoutingDataType[]) => {
    this.selectedTIDs = selectedRows.map(row => row.terminalId)
  }

  openModal() {
    this.isModalOpen = true
  }

  closeModal() {
    this.isModalOpen = false
  }

  reset() {
    this.selectedTIDs = []
    this.routingData = []
  }

  async loadRoutingData() {
    try {
      runInAction(() => {
        this.isRoutingDataLoading = true
      })
      const dsrId = this.merchantsManagementStore.companyFullDossierV2?.mainInfo?.dsrId
      if (dsrId) {
        let { status, error, result } = await loadRoutingData(dsrId)
        if (status === 200 && !error) {
          if (result?.length > 0) {
            result = result.map((routingItem, idx) => {
              const currentRoutingItem = { ...routingItem }
              const isPos = posProductPrefixes.includes(currentRoutingItem.terminalId?.substring(0, 2))
              currentRoutingItem.key = idx
              currentRoutingItem.isPosProduct = isPos
              currentRoutingItem.product = isPos ? translations().pos : translations().ecom
              return currentRoutingItem
            })
          }
          this.routingData = result
        } else {
          this.routingData = []
          message.error(error?.message)
        }
      } else {
        message.error(translations().dsrIdIsEmpty)
      }
    } catch (error) {
      message.error(error)
    } finally {
      runInAction(() => {
        this.isRoutingDataLoading = false
      })
    }
  }

  createRoutingData(dsrId: number, companyName: string, companyNumber: string, route: string) {
    return {
      variables: {
        dossierId: {
          value: dsrId.toString(),
          type: stringType
        },
        initiator: {
          value: AppStore.authStore.email,
          type: stringType
        },
        companyNumber: {
          value: companyNumber,
          type: stringType
        },
        companyName: {
          value: companyName,
          type: stringType
        },
        channel: {
          value: route,
          type: stringType
        },
        terminals: {
          value: JSON.stringify(this.selectedTIDs),
          type: jsonType
        }
      }
    }
  }

  async updateRoutingData(route: string) {
    try {
      runInAction(() => {
        this.isSubmitButtonLoading = true
      })
      const { companyName, companyNumber, dsrId } = this.merchantsManagementStore.companyFullDossierV2?.mainInfo
      if (!dsrId) {
        message.error(translations().dsrIdIsEmpty)
        return
      }
      const routingData = this.createRoutingData(dsrId, companyName, companyNumber, route)
      const { status, error, result } = await startChangeRouteProcess(routingData)
      if (status !== 200 || error) {
        message.error(error.message || translations().errorChangeRoutings)
      } else {
        message.success(result.message || translations().applicationSuccessful)
        this.closeModal()
      }
    } catch (error) {
      message.error(error?.message || translations().errorChangeRoutings)
      log(error)
    } finally {
      runInAction(() => {
        this.isSubmitButtonLoading = false
      })
    }
  }
}
