import { action, computed, makeObservable, observable } from 'mobx'
import { PeriodType } from '~/code/models'
import { getAcquisitionChannel, isPartner } from '~/code/services/auth'
import { RangePickerStore } from '~/code/stores/RangePickerStore'
import { SavedApplicationsStore } from '../../SavedApplicationsStore'

export class SavedApplicationsFiltersStore {
    email: string = ''
    acquisitionChannel: string = null

    dateStore: RangePickerStore

    constructor(
        private parentStore: SavedApplicationsStore
    ) {
        this.dateStore = new RangePickerStore()

        makeObservable(this, {
            email: observable,
            acquisitionChannel: observable,

            acquisitionChannels: computed,
            isAcquisitionChannelsLoading: computed,

            setEmail: action.bound,
            setAcquisitionChannel: action.bound
        })
    }

    init() {
        this.dateStore.setPeriod(PeriodType.Last30Days)
    }

    get acquisitionChannels() {
        return this.parentStore.handbookStore.startApplicationAcquisitionChannelsWithAll
    }

    get isAcquisitionChannelsLoading() {
        return this.parentStore.handbookStore.isStartApplicationAcquisitionChannelsLoading
    }

    setEmail(email: string) {
        this.email = email
    }

    setAcquisitionChannel(acquisitionChannel: string) {
        this.acquisitionChannel = acquisitionChannel
        this.loadData()
    }

    loadData() {
        this.parentStore.loadData()
    }
}