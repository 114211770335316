import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  title: string
  soleTrader: string
  company: string
  companyNameFormLabel: string
  companyNameFormRequired: string
  companyNameFormSubLabel: string
  companyIsNotActive: (status: string) => string
  companyNameFormDescription: string
  typeSomethingToSearch: string
  next: string
  changePaymentSolution: string
  couldNotLoadCompanies: string
  companyTypeRequired: string
  charityNumber: string
  directorsNumber: string
  partnersNumber: string
  charityComission: string
  processAlreadyStarted: (compNum: string) => string
  legalEntityPlaceholder: string
  limitedCompany: string
  partnerShip: string
  charity: string
  llp: string
  other: string
  description: string
  required: string
  otherDescription: string
  errorCompanyCheck: string
  tryAgain: string
}>()
