import React from 'react'
import { useInjection } from 'dna-react-ioc'
import { observer } from 'mobx-react'
import { Card, Table, Drawer, Grid } from 'antd'
import { BPMProcessFilterDossierV2 } from '../shared/BPMProcessDossierV2/components'
import { IChangeBankDetailsDossierV2Store } from './IChangeBankDetailsDossierV2Store'
import { ChangeBankDetailsInfo } from './components/ChangeBankDetailsInfo/ChangeBankDetailsInfo'
import { ChangeBankDetailsDrawer } from './components/ChangeBankDetailsDrawer'
import { columns } from './constants'
import styles from './ChangeBankDetailsDossierV2.scss'

export const ChangeBankDetailsDossierV2StoreSymbol = Symbol('ChangeBankDetailsDossierV2Store')
export const ChangeBankDetailsDossierV2RangePickerStoreSymbol = Symbol('ChangeBankDetailsDossierV2RangePickerStore')
export const ChangeBankDetailsDossierV2MerchantSelectStoreSymbol = Symbol(
  'ChangeBankDetailsDossierV2MerchantSelectStore'
)

export const ChangeBankDetailsDossierV2 = observer(() => {
  const screens = Grid.useBreakpoint()
  const store = useInjection<IChangeBankDetailsDossierV2Store>(ChangeBankDetailsDossierV2StoreSymbol)

  React.useEffect(() => {
    store.init()
  }, [])

  const dataSource = store.processes && store.processes.map((application, idx) => ({ key: idx, ...application }))
  return (
    <>
      <BPMProcessFilterDossierV2
        BPMProcessesStoreSymbol={ChangeBankDetailsDossierV2StoreSymbol}
        BPMProcessesRangePickerStoreSymbol={ChangeBankDetailsDossierV2RangePickerStoreSymbol}
        BPMProcessesMerchantSelectStoreSymbol={ChangeBankDetailsDossierV2MerchantSelectStoreSymbol}
      />
      <Card>
        <Table
          loading={store.isProcessesLoading}
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: 200 }}
          rowClassName={styles.row}
          onRow={record => {
            return {
              onClick: () => {
                if (!record.endDate) {
                  store.loadProcessDetails(record.id, record.processStatus, record.initiator)
                  store.showInfoDrawer()
                }
              }
            }
          }}
          pagination={{
            total: store.total,
            pageSize: store.pageSize,
            current: store.currentPage,
            onChange: (page, pageSize) => store.loadProcesses(page, pageSize),
            size: 'small'
          }}
        />
      </Card>
      <Drawer
        title={<ChangeBankDetailsDrawer />}
        placement='right'
        closable={false}
        onClose={() => store.closeInfoDrawer()}
        open={store.isShowInfoDrawer}
        width={screens.xs ? 256 : 512}
      >
        <ChangeBankDetailsInfo open={store.isShowInfoDrawer} initiatorEmail={store.selectedEmail} />
      </Drawer>
    </>
  )
})
