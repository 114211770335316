import React from 'react'
import { Row, Col, Input } from 'antd'
import moment, { Moment } from 'moment-timezone'
import { NumberFormItem } from '~/code/components/NumberFormItem'
import DatePicker from '~/code/components/DatePicker'
import { TariffTypeBase } from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v2/TariffTypeBase'
import translations from '../translations'

export const childrenColumns = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    width: 160
  },
  {
    title: '',
    dataIndex: 'value',
    key: 'value',
    width: 150,
    align: 'center' as 'center'
  },

  {
    title: '',
    dataIndex: 'description',
    key: 'description',
    width: 150
  }
]

export const generateInput = (name: string, value: number | string | TariffTypeBase | Moment) => {
  if (typeof value === 'number' || typeof value === 'string' || value === undefined || value === null) {
    return <Input name={name} disabled value={value === null || value === undefined ? '' : String(value)} />
  }
  if (moment.isMoment(value)) {
    return <DatePicker placement={'bottomRight'} disabled value={value} />
  }
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={12}>
        <div>{translations().baseLabel}</div>
        <Input name={`${name}_base`} disabled value={value?.base} />
      </Col>
      <Col xs={24} sm={12}>
        <div>{translations().percentLabel}</div>
        <Input name={`${name}_percent`} disabled value={value?.percent} />
      </Col>
    </Row>
  )
}

export const columnsBlended = (isDisabled: boolean) => {
  return [
    {
      title: translations().transactionType,
      children: [
        {
          key: 'cardScheme',
          dataIndex: 'cardScheme',
          title: translations().cardScheme
        },
        {
          key: 'serviceArea',
          dataIndex: 'serviceArea',
          title: translations().location,
          width: 200
        },
        {
          key: 'corporateOrConsumer',
          dataIndex: 'corporateOrConsumer',
          title: translations().corporateOrConsumer,
          width: 110
        },
        {
          key: 'cardType',
          dataIndex: 'cardType',
          title: translations().cardType
        }
      ]
    },
    {
      title: translations().baseFee,
      render: (_, row) => {
        return (
          <NumberFormItem
            name={[row.interchangeFee?.key, 'base']}
            initialValue={row.interchangeFee?.baseFee}
            showValueChangedMsg={true}
            max={row.interchangeFee?.maxBaseFee}
            min={row.interchangeFee?.minBaseFee}
            disabled={isDisabled}
          />
        )
      }
    },
    {
      title: translations().percentFee,
      render: (_, row) => {
        return (
          <NumberFormItem
            name={[row.interchangeFee?.key, 'percent']}
            initialValue={row.interchangeFee?.percentFee}
            showValueChangedMsg={true}
            max={row.interchangeFee?.maxPercentFee}
            min={row.interchangeFee?.minPercentFee}
            disabled={isDisabled}
          />
        )
      }
    }
  ]
}

export const confirmationTableColumns = [
  {
    title: 'Tariff Name',
    dataIndex: 'tariffName',
    key: 'tariffName'
  },
  {
    title: 'Old Values',
    dataIndex: 'oldValues',
    key: 'oldValues'
  },
  {
    title: 'New Values',
    dataIndex: 'newValues',
    key: 'newValues'
  }
]
