import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    notCompleted: string
    deleteFormTitle: (name: string) => string
    deleteFormContent: string
    edit: string
    delete: string
}>()

