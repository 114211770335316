import translations from '../translations'

export const timeToString = (minutes: number) => {
    const { value, unit } = getTime(minutes)
    return `${round(value)} ${unit}`
}

export const getTime = (minutes: number, isAbbr = false) => {
    const value = minutes >= 60 ? minutes / 60 : minutes
    const unit = minutes >= 60 ? translations()[`${(value > 1 ? 'hours' : 'hour')}${isAbbr ? 'Abbr' : ''}`] : translations().min
    return { value, unit }
}

export const round = (num: number, precision = 2) => Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision)
