import { Descriptions } from 'antd'
import React from 'react'
import { BusinessModelQuestionsPropsType } from './props'
import translations from './translations'
import styles from './BusinessModelQuestions.scss'

export const BusinessModelQuestions = ({ data }: BusinessModelQuestionsPropsType) => {
  return (
    <>
      <Descriptions title={<div className={styles.sectionHeader}>{translations().face2face}</div>} bordered column={1}>
        <Descriptions.Item label={<b>{translations().courierServiceUsed}</b>}>
          {data?.face2face?.courierServiceUsed ? translations().yes : translations().no}
        </Descriptions.Item>
        <Descriptions.Item label={<b>{translations().deliveryTime}</b>}>
          {data?.face2face?.deliveryTime}
        </Descriptions.Item>
        <Descriptions.Item label={<b>{translations().isTrue}</b>}>
          {data?.face2face?.isTrue ? translations().yes : translations().no}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        title={<div className={styles.sectionHeader}>{translations().seasonalBusiness}</div>}
        bordered
        column={1}
      >
        <Descriptions.Item label={<b>{translations().isTrue}</b>}>
          {data?.seasonalBusiness?.isTrue ? translations().yes : translations().no}
        </Descriptions.Item>
        <Descriptions.Item label={<b>{translations().quarter1}</b>}>
          {data?.seasonalBusiness?.quarter1}
        </Descriptions.Item>
        <Descriptions.Item label={<b>{translations().quarter2}</b>}>
          {data?.seasonalBusiness?.quarter2}
        </Descriptions.Item>
        <Descriptions.Item label={<b>{translations().quarter3}</b>}>
          {data?.seasonalBusiness?.quarter3}
        </Descriptions.Item>
        <Descriptions.Item label={<b>{translations().quarter4}</b>}>
          {data?.seasonalBusiness?.quarter4}
        </Descriptions.Item>
      </Descriptions>
    </>
  )
}
