import React from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Drawer, DrawerProps, Form, Grid, Button } from 'antd'
import { PortalAccessStoreType } from '~/code/pages/PortalAccess/types/PortalAccessStoreType'
import { PortalAccessStoreSymbol } from '~/code/pages/PortalAccess/'
import { UserSecurity } from '~/code/components'

import translations from './translations'
import styles from './styles.scss'

const { useBreakpoint } = Grid

export const PortalAccessEditSecurityDrawer = observer(({ open }: DrawerProps) => {
  const [form] = Form.useForm()
  const screens = useBreakpoint()

  const store = useInjection<PortalAccessStoreType>(PortalAccessStoreSymbol)
  const { selectedTableItem, isLoginsSending, closeSecurityDrawer, setSelectedTableItem } = store

  const twoFAEnabled = selectedTableItem?.twoFAStatus !== 'DISABLED'
  const currentTwoFAEnabled = Form.useWatch('twoFAEnabled', form)
  const currentReason = Form.useWatch('reason', form)
  const isSubmitDisabled = twoFAEnabled === currentTwoFAEnabled || (twoFAEnabled && !currentTwoFAEnabled && !currentReason)

  const renderFooter = () => (
    <div className={styles.footer}>
      <Button onClick={closeSecurityDrawer}>{translations().cancel}</Button>
      <Button
        htmlType='button'
        type='primary'
        loading={isLoginsSending}
        disabled={isSubmitDisabled} 
        onClick={() => form.submit()}
      >
        {translations().submit}
      </Button>
    </div>
  )

  const handleAfterOpenChange = (visible: boolean) => {
    if (!visible) {
      form.resetFields()
      setSelectedTableItem(null)
    }
  }

  return (
    <Drawer
      title={translations().edit}
      open={open}
      width={screens.xs ? window.innerWidth : 512}
      footer={renderFooter()}
      afterOpenChange={handleAfterOpenChange}
      onClose={closeSecurityDrawer}
      destroyOnClose
    >
      <Form
        form={form}
        requiredMark={false}
        labelAlign='left'
        labelCol={{ span: 6 }}
        initialValues={{
          twoFAEnabled
        }}
        onFinish={v => store.updateSecurity({ ...v, publicId: selectedTableItem.publicId })}
      >
        <UserSecurity form={form} status={selectedTableItem?.twoFAStatus} />
      </Form>
    </Drawer>
  )
})
