import { message } from 'antd'
import { action, autorun, computed, makeObservable, observable } from 'mobx'
import { PeriodType } from '~/code/models'
import { HandbooksStore } from '~/code/stores/HandbooksStore'
import { RangePickerStore } from '~/code/stores/RangePickerStore'
import { deleteProcesses } from '~/code/pages/BPM/Onboarding/services/fetchers'
import { BPMApplicationModel } from '../../models'
import { ProcessesFilterModel } from './models'
import { ProcessesDataStore } from './stores/ProcessesDataStore'
import translations from './translations'

export class ProcessesStore {
  dataStore: ProcessesDataStore
  isInitiated: boolean = false
  selectedProcesses: BPMApplicationModel[] = []
  isTerminateModalVisible: boolean = false
  isTerminatingProcesses: boolean = false

  constructor(public handbooksStore: HandbooksStore, rangePickerStore: RangePickerStore) {
    this.dataStore = new ProcessesDataStore(rangePickerStore)

    makeObservable(this, {
      isTerminateModalVisible: observable,
      isTerminatingProcesses: observable,
      selectedProcesses: observable,
      filter: computed,
      setSelectedProcesses: action,
      terminateProcesses: action,
      setTerminateModalVisible: action
    })

    autorun(() => {
      if (this.dateStore?.startDate && this.dateStore?.endDate) {
        this.loadData(1)
      }
    })
  }

  get dateStore() {
    return this.dataStore.dateStore
  }

  get tableStore() {
    return this.dataStore.tableStore
  }

  get filter() {
    return this.dataStore.filter
  }

  async init() {
    this.isInitiated = true
    if (this.dateStore.period === null) this.dateStore.setPeriod(PeriodType.Yesterday)
    this.handbooksStore.loadBpmStatuses()
    this.handbooksStore.loadBpmUsers()
    this.handbooksStore.loadBpmUserGroups()
    this.handbooksStore.loadApprovalTypes()
    this.handbooksStore.loadProductTypes()
    this.handbooksStore.loadStartApplicationAcquisitionChannels()
    this.handbooksStore.loadBusinessCategories()
    this.handbooksStore.loadBusinessSubCategories()
  }

  setFilter(filter: Partial<ProcessesFilterModel>, shouldLoad: boolean = true) {
    this.dataStore.setFilter(filter, shouldLoad)
  }

  resetFilter() {
    this.dataStore.resetFilter()
  }

  loadData(page?: number) {
    this.dataStore.loadData(page)
  }

  setSelectedProcesses(processes: BPMApplicationModel[]) {
    this.selectedProcesses = processes
  }

  setTerminateModalVisible(isVisible: boolean) {
    this.isTerminateModalVisible = isVisible
  }

  terminateProcesses = async (deleteReason: string) => {
    this.isTerminatingProcesses = true
    const processIds = this.selectedProcesses.map(process => process.id)
    const result = await deleteProcesses({
      processIds,
      deleteReason
    })
    this.isTerminatingProcesses = false
    this.isTerminateModalVisible = false
    if (result.status === 200) {
      this.selectedProcesses = []
      this.loadData()
      message.success(translations().terminatedProcessesSuccessfully)
    } else {
      message.error(result.error.message)
    }
  }
}
