import translations from '../translations'

export const columns = [
    {
        title: translations().terminalId,
        dataIndex: 'terminalId',
        key: 'terminalId',
        width: '15%'
    },
    {
        title: translations().product,
        dataIndex: 'product',
        key: 'product',
        width: '15%'
    },
    {
        title: translations().contractNumber,
        dataIndex: 'contractNumber',
        key: 'contractNumber',
        width: '25%'
    },
    {
        title: translations().merchantId,
        dataIndex: 'merchantId',
        key: 'merchantId',
        width: '15%'
    },
    {
        title: translations().routeToMasterCard,
        dataIndex: 'routeToMasterCard',
        key: 'routeToMasterCard',
        render: (value: string) => translations().getRouteLabel(value) || '-',
        width: '15%'
    },
    {
        title: translations().routeToVisa,
        dataIndex: 'routeToVisa',
        key: 'routeToVisa',
        render: (value: string) => translations().getRouteLabel(value) || '-',
        width: '15%'
    }
]

export enum routeTypeEnum {
    PH2H = 'Paynetics',
    MC = 'DNA Direct',
    VISA = 'DNA Direct'
}

export const paymentMethods = [
    {
        value: 'DNA',
        label: translations().dna
    },
    {
        value: 'PH2H',
        label: translations().paynetics
    }
]