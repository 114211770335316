import React from 'react'
import { Typography } from 'antd'
import { observer } from 'mobx-react'
import { DocumentsProps } from './props'

const { Link } = Typography

export const AttachedDocuments = observer((props: DocumentsProps) => {
  const { docsList, store } = props

  return (
    <ol>
      {docsList.map((item, id) => {
        return (
          <li key={id}>
            <Link onClick={() => store.loadDocumentsData(item.path, item.name)}>{item.type}</Link>
          </li>
        )
      })}
    </ol>
  )
})
