import { observer } from 'mobx-react'
import moment from 'moment-timezone'
import React from 'react'
import { Card, Col, Empty, Row, Spin } from 'antd'
import { Comment } from '@ant-design/compatible'
import { useInjection } from 'dna-react-ioc'

import { IMotoVtRequestsStore } from '../../IMotoVtRequestsStore'
import { MotoVtRequestsStoreSymbol } from '../../MotoVtRequests'

import translations from './translations'
import styles from './MotoVtProcessDetails.scss'

export const MotoVtProcessDetails = observer(() => {
  const store = useInjection<IMotoVtRequestsStore>(MotoVtRequestsStoreSymbol)

  if (store.dataLoadingStatus === 'failed') {
    return (
      <div className={styles.empty}>
        <Empty />
      </div>
    )
  }

  if (store.dataLoadingStatus === 'loading') {
    return (
      <div className={styles.loader}>
        <Spin size='large' />
      </div>
    )
  }

  return (
    <Card>
      <div className={styles.blockTitle}>{translations().commonInfo}</div>
      <Row gutter={[0, 12]}>
        <Col xs={12} className={styles.propLabel}>
          {translations().companyName}:{' '}
        </Col>
        <Col xs={12}>{store.processDetails?.companyName}</Col>
      </Row>
      <Row gutter={[0, 12]}>
        <Col xs={12} className={styles.propLabel}>
          {translations().companyNumber}:{' '}
        </Col>
        <Col xs={12}>{store.processDetails?.companyNumber}</Col>
      </Row>
      <Row gutter={[0, 12]}>
        <Col xs={12} className={styles.propLabel}>
          {translations().product}:{' '}
        </Col>
        <Col xs={12}>{store.processDetails?.product}</Col>
      </Row>
      {store.processDetails?.comments?.length ? (
        <>
          <div className={styles.blockTitle}>{translations().comments}</div>
          {store.processDetails.comments.map((comment, index) => (
            <Comment
              key={index}
              content={comment.comment}
              author={comment.author}
              datetime={moment(comment.date).format('YYYY-MM-DD HH:mm:ss')}
            />
          ))}
        </>
      ) : null}
    </Card>
  )
})
