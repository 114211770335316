import React from 'react'
import { observer } from 'mobx-react'
import { Card, Form, Steps, Grid, Skeleton } from 'antd'
import { withAppStore } from '~/code/hocs/withAppStore'
import { CompleteApplication, StartAppResult, ContactInfo, GeneralInformation, SupportingDocuments, Pricing } from './components'
import { StartApplicationStore, StartApplicationStoreContext } from './StartApplicationStore'
import translations from './translations'
import styles from './StartApplication.scss'

const { Step } = Steps
const { useBreakpoint } = Grid

const StartApplicationView = observer((props: { store: StartApplicationStore }) => {
    const { store } = props
    const screen = useBreakpoint()

    const [form] = Form.useForm()

    const renderContent = () => {
        switch (store.activeTabKey) {
            case 'contactInfo': return <ContactInfo store={store.contactInfoStore} />
            case 'generalInformation': return <GeneralInformation form={form} store={store} />
            case 'pricing': return <Pricing store={store.pricingStore} />
            case 'completeApplication': return <CompleteApplication store={store.completeApplicationStore} />
            case 'supportingDocuments': return <SupportingDocuments store={store.supportingDocumentsStore} />
            case 'result': return (
                <StartAppResult
                    store={store.completeApplicationStore}
                    onBack={() => {
                        store.openCompleteApplicationPage()
                        store.completeApplicationStore.clearResultPage()
                    }}
                    onBackToStart={() => {
                        store.reset()
                        form.resetFields()
                    }}
                />
            )
            default: return null
        }
    }

    return <StartApplicationStoreContext.Provider value={store}>
        <Card>
            <Steps
                current={store.currentStep}
                className={styles.steps}
                direction={screen.sm ? 'horizontal' : 'vertical'}
                size={screen.sm ? 'default' : 'small'}
            >
                <Step title={translations().contactInfoTitle} />
                <Step title={translations().generalInformationTitle} />
                <Step title={translations().pricingTitle} />
                <Step title={translations().completeApplicationTitle} />
                <Step title={translations().supportingDocumentsTitle} />
                <Step title={translations().applicationSubmissionTitle}  />
            </Steps>
            {store.isLoadApplicationByIdLoading ? (
                <Skeleton />
            ) : (
                renderContent()
            )}
        </Card>
    </StartApplicationStoreContext.Provider>
})

export const StartApplication = withAppStore(({ appStore }) => <StartApplicationView store={appStore.startApplicationStore} />)