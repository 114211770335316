import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Modal } from 'antd'
import { ISettlementsStatusModalStore } from './ISettlementsStatusModalStore'
import { SettlementsStatusTable } from './components/SettlementsStatusTable'
import { SettlementsStatusModalProps } from './props'
import translations from './translations'

export const SettlementsStatusModalStoreSymbol = Symbol('SettlementsStatusModalStore')
export const SettlementsStatusModal = observer((props: SettlementsStatusModalProps) => {
    const store = useInjection<ISettlementsStatusModalStore>(SettlementsStatusModalStoreSymbol)

    useEffect(() => {
        props.open ? store.setInitialSettlementsStatusTableData() : store.clearSettlementsStatusTableData()
    }, [props.open])

    return (
        <Modal
            width={'90%'}
            title={translations().settlementsStatus}
            open={props.open}
            onCancel={store.closeSettlementsStatusModal}
            onOk={store.updateHoldStatus}
            okButtonProps={{
                loading: store.isSubmitButtonLoading
            }}
        >
            <SettlementsStatusTable tableData={store.holdTableData}/>
        </Modal>
    )
})