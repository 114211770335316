import React, { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  title: string
  mandatoryDocuments: string
  additionalDocuments: string
  upload: string
  bankStatement: string
  passportOrDriving: string
  residentialUtilityBill: string
  uploadFileText: React.ReactNode
  errors: {
    moreThan10Mb: string
  }
  backToThirdStep: string
  documentType: string

  idDrivingLicence: string
  idPassport: string
  idOther: string
  poaPersonalBankStatement: string
  poaCouncilTax: string
  poaUtilityBill: string
  poaOther: string
  pobBankStatementBusiness: string
  pobChequeBook: string
  pobBankLetter: string
  pobOther: string
  kybLeaseAgreement: string
  kybAlcoholLicense: string
  kybCertificateOfAuthenticity: string
  domainOwnership: string
  licences: string
  processingStatement: string
  premisesPhotoOutside: string
  premisesPhotoInside: string
  signedMsa: string
  additionalTidsRequestForm: string
  onlyXlsFilesAreSupported: string
  other: string

  notRequired: {
    bankStatement: string
    passportOrDriving: string
    residentialUtilityBill: string
  }

    errorUploadingDocument: string
    errorDeletingDocument: string
    bankStatementWithAcc: string
    sign: string
    docuSign: string
    docuSignInfo: ReactNode
    signError: string,
    preview: string
    alreadySentToSign: string
    update: string
    updateError: string
    signContract: string
    docuSignInfo2: ReactNode
    signedSuccessfully: string

    uploadInfo: ReactNode
    complete: string
    saveForLater: string
    completeDocusign: string
    manualDocusignTitle: string
    signedByPaytek: string
    resendError: string
    resendSuccess: string
}>()
