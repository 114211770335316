import { getWithAuth } from '~/code/services'
import { PartnerCommissionsResponseType } from '../models'
import { FetchPartnersCommissionDataType } from '../models/FetchPartnersCommissionDataType'
import { AcquisitionChannelType } from '~/code/models'

export const fetchGroupedData = (data: FetchPartnersCommissionDataType) => {
  return getWithAuth<PartnerCommissionsResponseType[]>(
    `bpm/adw/partner/comission/grouped?year1=${data.year1}${data.month1 ? `&month1=${data.month1}` : ''}${
      data.year2 ? `&year2=${data.year2}` : ''
    }${data.month2 ? `&month2=${data.month2}` : ''}${data.partner ? `&partner_client_id=${data.partner}` : ''}`
  )
}

export const fetchUngroupedData = (data: FetchPartnersCommissionDataType) => {
  return getWithAuth<PartnerCommissionsResponseType[]>(
    `bpm/adw/partner/comission?year1=${data.year1}${data.month1 ? `&month1=${data.month1}` : ''}${
      data.year2 ? `&year2=${data.year2}` : ''
    }${data.month2 ? `&month2=${data.month2}` : ''}${data.partner ? `&partner_client_id=${data.partner}` : ''}`
  )
}

export const fetchDataFileExcell = (data: FetchPartnersCommissionDataType) => {
  return getWithAuth<any>(
    `bpm/adw/partner/comission/file?year1=${data.year1}${data.month1 ? `&month1=${data.month1}` : ''}${
      data.year2 ? `&year2=${data.year2}` : ''
    }${data.month2 ? `&month2=${data.month2}` : ''}${data.partner ? `&partner_client_id=${data.partner}` : ''}`
  )
}

export const fetchDetailedDataFileExcell = (data: FetchPartnersCommissionDataType) => {
  return getWithAuth<any>(
    `bpm/adw/partner/comission/file/detailed?year1=${data.year1}${data.month1 ? `&month1=${data.month1}` : ''}${
      data.year2 ? `&year2=${data.year2}` : ''
    }${data.month2 ? `&month2=${data.month2}` : ''}${data.partner ? `&partner_client_id=${data.partner}` : ''}`
  )
}

export const fetchPartnersCommissionAcqChannels = () => getWithAuth<AcquisitionChannelType[]>(`/bpm/adw/partner/comission/partner-list`)
