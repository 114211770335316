import translations from '../translations'

export const ecospenResultColumns = [
  {
    title: translations().sortCode,
    dataIndex: 'sortCode',
    key: 'sortCode'
  },
  {
    title: translations().bankAccount,
    dataIndex: 'bankAccount',
    key: 'bankAccount'
  },
  {
    title: translations().accountName,
    dataIndex: 'accountName',
    key: 'accountName'
  },
  {
    title: translations().bankName,
    dataIndex: 'bankName',
    key: 'bankName'
  },
  {
    title: translations().overallScore,
    dataIndex: 'overallScore',
    key: 'overallScore'
  },
  {
    title: translations().result,
    dataIndex: 'result',
    key: 'result'
  }
]
