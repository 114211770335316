import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    web: string
    mobile: string
    recipientsPreview: string
    notificationsPreview: (system: string) => string
    publishTo: string
    publish: string
    fieldRequired: string
    partner: string
    employee: string
    all: string
    confirmation: string
    confirmationTextMp: string
    confirmationTextDashboard: string
    aboutToPublish: string
    dashboard: string
    mp: string
}>()

