import { TranslationBuilder } from '~/code/components/Translation'
import { TerminalType } from '../models'

export default TranslationBuilder.create<{
    tabTitles: {
        totalSummary: string
        teamSummary: string
        terminalIssueReport: string
    }
    ecom: string
    pos: string
    errorLoading: {
        totalSummary: (terminalType: TerminalType) => string
        teamSummary: (terminalType: TerminalType) => string
        terminalIssueReport: string
    }
    total: string
    columnTitles: {
        start: string
        new: string
        submitted: string
        approved: string
        addInfoProvided: string
        referred: string
        declined: string
        end: string
        terminalType: string
        user: string
        acquisitionChannelName: string
        productType: string
        approvalType: string
        terminalQuantity: string
        merchantQuantity: string
        segmentCategory: string
        segmentSubCategory: string
    }
}>()

