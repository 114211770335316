import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Form, Radio, Space} from 'antd'
import {TwoFAModalStoreInterface} from '~/code/stores/Profile/TwoFAModalStore/TwoFAModalStoreInterface'
import {TwoFAModalStoreSymbol} from '~/code/pages/Profile/components/TwoFA/components/TwoFAModal'

import translations from './translations'
import styles from './styles.scss'

const {useForm} = Form

type TwoFAFrequencyProps = {
    onChange?: (v: string) => void
}

export const TwoFAFrequency: React.FC<TwoFAFrequencyProps> = observer(({onChange}) => {
    const {selectedFrequency, settings} =
        useInjection<TwoFAModalStoreInterface>(TwoFAModalStoreSymbol)

    const frequencies = settings?.frequencies

    const [form] = useForm()

    useEffect(() => {
        form.setFieldsValue({frequency: selectedFrequency})
    }, [selectedFrequency])

    return (
        <Form form={form}>
            <Form.Item name={'frequency'} className={styles.formItem}>
                <Radio.Group>
                    <Space direction={'vertical'}>
                        {frequencies &&
                            frequencies.map(({id, value}) => (
                                <Radio key={id} value={id} onChange={v => onChange(v.target.value)}>
                                    {translations().name[value]}
                                </Radio>
                            ))}
                    </Space>
                </Radio.Group>
            </Form.Item>
        </Form>
    )
})
