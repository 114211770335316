import translations from './translations'

export const skipReasons = [
  {
    key: 'alreadyHaveProof',
    value: 'alreadyHaveProof',
    label: translations().alreadyHaveProof
  },
  {
    key: 'doesntTrustTool',
    label: translations().doesntTrustTool,
    value: 'doesntTrustTool'
  },
  {
    key: 'couldntOpenLink',
    label: translations().couldntOpenLink,
    value: 'couldntOpenLink'
  },

  {
    key: 'couldntUseApp',
    label: translations().couldntUseApp,
    value: 'couldntUseApp'
  },
  {
    key: 'bankNotSupported',
    label: translations().bankNotSupported,
    value: 'bankNotSupported'
  },
  {
    key: 'other',
    label: translations().other,
    value: 'other'
  }
]
