import { inject, injectable } from 'inversify'
import { action, autorun, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import { message } from 'antd'
import moment, { Moment } from 'moment-timezone'
import { STORE_TYPES } from '~/code/constants/StoreTypes'
import { fetchAcquisitionChannelsV2 } from '~/code/services/fetchers'
import { PartnerCommissionsStoreType } from '~/code/pages/PartnerCommissions/types/PartnerCommissionsStoreType'
import { YearsCompareDataType } from '~/code/pages/PartnerCommissions/components/YearsCompare/models'
import { PartnerCommissionsResponseType } from './models'
import { TransactionsAcquisitionChannelModel } from '~/code/models'
import { FetchPartnersCommissionDataType } from './models/FetchPartnersCommissionDataType'
import { getChartDataCommissions, getChartDataNetRev, getChartDataTransactionVol } from './services/utils'
import {
  fetchDataFileExcell,
  fetchDetailedDataFileExcell,
  fetchGroupedData,
  fetchPartnersCommissionAcqChannels,
  fetchUngroupedData
} from './services/fetchers'
import { GlobalConfigStore } from '../GlobalConfigStore'
import translations from './translations'

@injectable()
export class PartnerCommissionsStore implements PartnerCommissionsStoreType {
  configStore: GlobalConfigStore

  _yearsComparisonDataNetRev: YearsCompareDataType = null
  yearsComparisonYear1NetRev: Moment = moment().subtract(1, 'years')
  yearsComparisonYear2NetRev: Moment = moment()

  _yearsComparisonDataCommissions: YearsCompareDataType = null
  yearsComparisonYear1Commissions: Moment = moment().subtract(1, 'years')
  yearsComparisonYear2Commissions: Moment = moment()

  _yearsComparisonDataTransactionsVol: YearsCompareDataType = null
  yearsComparisonYear1TransactionsVol: Moment = moment().subtract(1, 'years')
  yearsComparisonYear2TransactionsVol: Moment = moment()

  mainYearAndMonth: Moment = moment().subtract(1, 'months')

  isGroupedDataLoading: boolean = false
  groupedDataResult: PartnerCommissionsResponseType = null
  isUngroupedDataLoading: boolean = false
  ungroupedDataResult: PartnerCommissionsResponseType[] = null
  isPartnerSelectLoading: boolean = false
  showModal: boolean = false
  partners: TransactionsAcquisitionChannelModel[] = null
  selectedPartner: TransactionsAcquisitionChannelModel = {
    id: null,
    name: translations().all,
    isActive: true
  }
  isGroupedDataYearNetRevLoading: boolean = false
  isGroupedDataYearCommissionsLoading: boolean = false
  isExcelFileLoading: boolean = false
  isInitiated: boolean = false
  isGroupedDataYearTransactionVolLoading: boolean = false
  isExcelFileDetailedLoading: boolean = false

  constructor(@inject(STORE_TYPES.GlobalConfigStore) configStore: GlobalConfigStore) {
    this.configStore = configStore

    makeObservable(this, {
      _yearsComparisonDataNetRev: observable,
      yearsComparisonYear1NetRev: observable,
      yearsComparisonYear2NetRev: observable,

      _yearsComparisonDataCommissions: observable,
      yearsComparisonYear1Commissions: observable,
      yearsComparisonYear2Commissions: observable,

      _yearsComparisonDataTransactionsVol: observable,
      yearsComparisonYear1TransactionsVol: observable,
      yearsComparisonYear2TransactionsVol: observable,

      mainYearAndMonth: observable,

      isGroupedDataLoading: observable,
      groupedDataResult: observable,
      isUngroupedDataLoading: observable,
      ungroupedDataResult: observable,
      isPartnerSelectLoading: observable,
      showModal: observable,
      partners: observable,
      selectedPartner: observable,
      isGroupedDataYearNetRevLoading: observable,
      isGroupedDataYearCommissionsLoading: observable,
      isInitiated: observable,
      isGroupedDataYearTransactionVolLoading: observable,
      isExcelFileLoading: observable,
      isExcelFileDetailedLoading: observable,

      currencySymbol: computed,
      dataNetRevParams: computed,
      selectedPartnerId: computed,

      loadGroupedData: action.bound,
      loadUngroupedData: action.bound,
      setShowModal: action.bound,
      loadPartners: action.bound,
      onSelectPartner: action.bound,
      loadGroupedDataByYear: action.bound,
      loadGroupedDataByYearCommissions: action.bound,
      changeMainYearAndMonth: action.bound,
      changeYearsCompareNetRev: action.bound,
      loadExcellFile: action.bound,
      changeYearsCompareCommissions: action.bound,
      changeYearsCompareTransactionVol: action.bound,
      loadGroupedDataByYearTransactionVol: action.bound,
      loadDetailedExcellFile: action
    })

    autorun(() => {
      if (this.isInitiated === false) {
        this.isInitiated = true
        this.loadPartners()
        this.loadGroupedData()
        this.loadUngroupedData()
        this.loadGroupedDataByYear()
        this.loadGroupedDataByYearCommissions()
        this.loadGroupedDataByYearTransactionVol()
      }
    })

    reaction(() => this.mainData, this.loadGroupedData, { delay: 5 })
    reaction(() => this.mainData, this.loadUngroupedData, { delay: 5 })
    reaction(() => this.dataNetRevParams, this.loadGroupedDataByYear)
    reaction(() => this.dataCommissionsParams, this.loadGroupedDataByYearCommissions)
    reaction(() => this.dataTransactionVolParams, this.loadGroupedDataByYearTransactionVol)
  }

  setShowModal(val: boolean) {
    this.showModal = val
  }

  onSelectPartner(val: string) {
    this.selectedPartner = this.partners.find(p => p.id === val)
  }

  get selectedPartnerId() {
    return this.selectedPartner?.id
  }

  get dataNetRevParams() {
    return {
      year1: this.yearsComparisonYear1NetRev?.year(),
      year2: this.yearsComparisonYear2NetRev?.year(),
      partner: this.selectedPartnerId
    }
  }

  get dataCommissionsParams() {
    return {
      year1: this.yearsComparisonYear1Commissions?.year(),
      year2: this.yearsComparisonYear2Commissions?.year(),
      partner: this.selectedPartnerId
    }
  }

  get dataTransactionVolParams() {
    return {
      year1: this.yearsComparisonYear1TransactionsVol?.year(),
      year2: this.yearsComparisonYear2TransactionsVol?.year(),
      partner: this.selectedPartnerId
    }
  }

  get currencySymbol() {
    return this.configStore.currency.symbol
  }

  get mainData() {
    return {
      year: String(this.mainYearAndMonth.year()),
      month: String(this.mainYearAndMonth.month() + 1),
      partner: this.selectedPartnerId
    }
  }

  changeYearsCompareNetRev(year1: Moment, year2: Moment) {
    this.yearsComparisonYear1NetRev = year1
    this.yearsComparisonYear2NetRev = year2
  }

  changeYearsCompareCommissions(year1: Moment, year2: Moment) {
    this.yearsComparisonYear1Commissions = year1
    this.yearsComparisonYear2Commissions = year2
  }

  changeYearsCompareTransactionVol(year1: Moment, year2: Moment) {
    this.yearsComparisonYear1TransactionsVol = year1
    this.yearsComparisonYear2TransactionsVol = year2
  }

  changeMainYearAndMonth(date: Moment) {
    this.mainYearAndMonth = date
  }

  async loadGroupedData() {
    try {
      runInAction(() => {
        this.isGroupedDataLoading = true
      })
      const data: FetchPartnersCommissionDataType = {
        year1: this.mainData.year,
        month1: this.mainData.month,
        partner: this.mainData.partner
      }
      const { status, result, error } = await fetchGroupedData(data)
      if (error) {
        message.error(error.message)
      }

      if (status === 200) {
        this.groupedDataResult = result?.[0]
      }
    } catch (error) {
      message.error(error)
    } finally {
      runInAction(() => {
        this.isGroupedDataLoading = false
      })
    }
  }

  async loadGroupedDataByYear() {
    try {
      runInAction(() => {
        this.isGroupedDataYearNetRevLoading = true
      })
      const data: FetchPartnersCommissionDataType = {
        year1: String(this.dataNetRevParams.year1),
        year2: String(this.dataNetRevParams.year2),
        partner: this.dataNetRevParams.partner
      }

      const { status, result, error } = await fetchGroupedData(data)
      if (error) {
        message.error(error.message)
      }

      if (status === 200) {
        this._yearsComparisonDataNetRev = getChartDataNetRev(data.year1, data.year2, result)
      }
    } catch (error) {
      message.error(error)
    } finally {
      runInAction(() => {
        this.isGroupedDataYearNetRevLoading = false
      })
    }
  }

  async loadGroupedDataByYearCommissions() {
    try {
      runInAction(() => {
        this.isGroupedDataYearCommissionsLoading = true
      })
      const data: FetchPartnersCommissionDataType = {
        year1: String(this.dataCommissionsParams.year1),
        year2: String(this.dataCommissionsParams.year2),
        partner: this.dataCommissionsParams.partner
      }

      const { status, result, error } = await fetchGroupedData(data)

      if (error) {
        message.error(error.message)
      }

      if (status === 200) {
        this._yearsComparisonDataCommissions = getChartDataCommissions(data.year1, data.year2, result)
      }
    } catch (error) {
      message.error(error)
    } finally {
      runInAction(() => {
        this.isGroupedDataYearCommissionsLoading = false
      })
    }
  }

  async loadGroupedDataByYearTransactionVol() {
    try {
      runInAction(() => {
        this.isGroupedDataYearTransactionVolLoading = true
      })
      const data: FetchPartnersCommissionDataType = {
        year1: String(this.dataTransactionVolParams.year1),
        year2: String(this.dataTransactionVolParams.year2),
        partner: this.dataTransactionVolParams.partner
      }

      const { status, result, error } = await fetchGroupedData(data)

      if (error) {
        message.error(error.message)
      }

      if (status === 200) {
        this._yearsComparisonDataTransactionsVol = getChartDataTransactionVol(data.year1, data.year2, result)
      }
    } catch (error) {
      message.error(error)
    } finally {
      runInAction(() => {
        this.isGroupedDataYearTransactionVolLoading = false
      })
    }
  }

  async loadUngroupedData() {
    try {
      runInAction(() => {
        this.isUngroupedDataLoading = true
      })

      const data: FetchPartnersCommissionDataType = {
        year1: this.mainData.year,
        month1: this.mainData.month,
        partner: this.mainData.partner
      }

      const { status, result } = await fetchUngroupedData(data)

      if (status === 200) {
        this.ungroupedDataResult = result
      }
    } catch (error) {
      message.error(error)
    } finally {
      runInAction(() => {
        this.isUngroupedDataLoading = false
      })
    }
  }

  async loadPartners() {
    try {
      runInAction(() => {
        this.isPartnerSelectLoading = true
      })

      const { status, result } = await fetchPartnersCommissionAcqChannels()

      if (status === 200) {
        const withAll = [
          {
            id: null,
            name: translations().all,
            isActive: true
          },
          ...result?.map(r => ({ id: r.code, name: r.value, isActive: r.isActive }))
        ]
        this.partners = withAll
      }
    } catch (error) {
      message.error(error)
    } finally {
      runInAction(() => {
        this.isPartnerSelectLoading = false
      })
    }
  }

  async loadExcellFile() {
    try {
      runInAction(() => {
        this.isExcelFileLoading = true
      })

      const data: FetchPartnersCommissionDataType = {
        year1: this.mainData.year,
        month1: this.mainData.month,
        partner: this.mainData.partner
      }

      const { status, result } = await fetchDataFileExcell(data)
      if (status === 200) {
        const linkSource = `data:application/vnd.ms-excel;base64,${result}`
        const downloadLink = document.createElement('a')
        const fileName = `Partner commission report ${new Date().toJSON()}.xlsx`
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      }
    } catch (error) {
      message.error(error)
    } finally {
      runInAction(() => {
        this.isExcelFileLoading = false
      })
    }
  }

  async loadDetailedExcellFile() {
    try {
      runInAction(() => {
        this.isExcelFileDetailedLoading = true
      })

      const data: FetchPartnersCommissionDataType = {
        year1: this.mainData.year,
        month1: this.mainData.month,
        partner: this.mainData.partner
      }

      const { status, result } = await fetchDetailedDataFileExcell(data)
      if (status === 200) {
        const linkSource = `data:application/vnd.ms-excel;base64,${result}`
        const downloadLink = document.createElement('a')
        const fileName = `Detailed partner commission report ${this.selectedPartner.name} ${new Date().toJSON()}.xlsx`
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      }
    } catch (error) {
      message.error(error)
    } finally {
      runInAction(() => {
        this.isExcelFileDetailedLoading = false
      })
    }
  }
}
