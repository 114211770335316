import React, { useMemo, useEffect } from 'react'
import { useInjection } from 'dna-react-ioc'
import { observer } from 'mobx-react-lite'
import { Table, Pagination, TablePaginationConfig, Row, Col, Card } from 'antd'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'
import { getColumns, renderActions } from '~/code/pages/PartnerAccess/utils'
import { PartnerAccessTabKeyType } from '~/code/pages/PartnerAccess/types'
import { IPartnerAccessTableStore } from '~/code/pages/PartnerAccess/components/PartnerAccessTable'
import { PartnerAccessTableProps } from './props'

import styles from './styles.scss'

export const PartnerAccessTableStoreSymbol = Symbol('PartnerAccessTableStore')

export const PartnerAccessTable: React.FC<PartnerAccessTableProps> = observer(props => {
  const { ...rest } = props
  const store = useInjection<IPartnerAccessTableStore>(PartnerAccessTableStoreSymbol)
  const { isLoading, tableStore, partnerAccessStore, onActionSelect } = store

  const { currentPage, pageSize, totalCount, data, viewData, setPageSize, loadData } = tableStore

  const type = partnerAccessStore.activeTab

  const paginationProps: TablePaginationConfig = generateTablePaginationProps(
    currentPage,
    pageSize,
    totalCount,
    pageNumber => loadData({ pageNumber }),
    setPageSize
  )

  const columns = useMemo(() => getColumns(type, renderActions, onActionSelect), [type])

  useEffect(() => () => store.reload(), [])

  return (
    <Card className={styles.root}>
      <Table
        loading={isLoading}
        rowKey={({ rowKey }) => rowKey}
        columns={columns}
        pagination={false}
        dataSource={viewData}
        scroll={{ x: type === PartnerAccessTabKeyType.partners ? 1500 : 1000 }} // To ensure that the column headers fit without wrapping
        {...rest}
      />

      <Row className={styles.pagination}>
        <Col>
          <Pagination {...paginationProps} disabled={!data?.length} />
        </Col>
      </Row>
    </Card>
  )
})
