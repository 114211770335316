import { Row, Col, Radio, Space, RadioChangeEvent, Divider } from 'antd'
import React, { useState } from 'react'
import translations from './translations'
import styles from './PreStartPage.scss'
import { PreStartPageProps } from './props'
import { CustomButton, CustomModal } from '~/code/components'

export const PreStartPage: React.FC<PreStartPageProps> = ({ handlePreStart }) => {
  const [value, setValue] = useState('')
  const [openModal, setOpenModal] = useState(false)

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }
  return (
    <>
      <div className={styles.wrapper}>
        <Row>
          <Col span={24}>
            <p className={styles.styledFont}>{translations().applicationType}</p>
            <p className={styles.red}>{translations().note}</p>
          </Col>
        </Row>
        <Divider />

        <Radio.Group onChange={onChange} value={value}>
          <Space direction='vertical'>
            <Radio className={styles.styledRadio} value='sme'>
              <div className={styles.radioDetails}>
                <div className={styles.radioTitle}>{translations().smeAndCorporate}</div>
                <div className={styles.radioSubtitle}>{translations().smeAndCorporateNote}</div>
              </div>
            </Radio>
            <Radio className={styles.styledRadio} value='partners'>
              <div className={styles.radioDetails}>
                <div className={styles.radioTitle}>{translations().partnersAndISO}</div>
                <div className={styles.radioSubtitle}>{translations().partnersAndISONote}</div>
              </div>
            </Radio>
            <Radio value='existing'>
              <div className={styles.radioDetails}>
                <div className={styles.radioTitle}>{translations().existing}</div>
                <div className={styles.radioSubtitle}>{translations().existingMerchantDetails}</div>
              </div>
            </Radio>
          </Space>
        </Radio.Group>

        <Row className={styles.rowWrapper}>
          <CustomButton
            className={styles.styledOkBtn}
            type='primary'
            onClick={() => {
              if (value === 'existing') {
                setOpenModal(true)
              } else {
                value && handlePreStart(value)
              }
            }}
          >
            {translations().ok}
          </CustomButton>
        </Row>
      </div>
      <CustomModal
        title={translations().existingPopupHeader}
        open={openModal}
        onOk={() => handlePreStart(value)}
        onCancel={() => setOpenModal(false)}
      >
        <p>{translations().dontNeedSubmit}</p>
        <p>{translations().existingInfo}</p>
      </CustomModal>
    </>
  )
}
