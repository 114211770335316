import translations from './translations'

translations.add('en', {
  review: 'Review',
  processName: 'Process Name',
  assignedUser: 'Assigned User',
  assignedGroup: 'Assigned Group',
  reviewTime: 'Review Time',
  lessThanOneMinute: 'less than 1 minute',
  selectMerchant: 'Select Merchant',
  selectStatus: 'Select Status',
  merchant: 'Merchant',
  startDate: 'Start Date',
  midsAndTids: 'Issued TID',
  initiator: 'Initiator',
  status: 'Status',
  endDate: 'End Date',
  contractNumber: 'Contract Number',
  tids: 'TID(s)'
})
