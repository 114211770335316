import React from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Card, Col, Pagination, Row, Table, TablePaginationConfig } from 'antd'
import { ReloadButton } from '~/code/components'
import { INetSuiteInvoicesStore } from '~/code/pages/Billing/pages/NetSuiteInvoices/INetSuiteInvoicesStore'
import { FetchNetSuiteInvoicesResponseType } from '~/code/stores/NetSuiteInvoicesStore/models'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'
import { getColumns } from './constants/columns'
import { NetSuiteInvoicesFilter } from '~/code/pages/Billing/pages/NetSuiteInvoices/components'

import styles from './styles.scss'

export const NetSuiteInvoicesStoreSymbol = Symbol('NetSuiteInvoicesStoreSymbol')

export const NetSuiteInvoices = observer(() => {
  const { data, isLoading, filterStore, refresh, loadInvoiceCSV } =
    useInjection<INetSuiteInvoicesStore>(NetSuiteInvoicesStoreSymbol)

  const { currentPage, size, total, selectedSubsidiary, setPagination } = filterStore

  const paginationProps: TablePaginationConfig = generateTablePaginationProps(
    currentPage,
    size,
    total,
    (page, pageSize) => setPagination(page, pageSize)
  )

  const onDownloadClick = async ({ period, filename }: FetchNetSuiteInvoicesResponseType) => {
    await loadInvoiceCSV({
      period,
      filename,
      system: selectedSubsidiary
    })
  }

  return (
    <>
      <NetSuiteInvoicesFilter />

      <Card style={{ marginTop: 40 }}>
        <Table
          columns={getColumns(onDownloadClick)}
          dataSource={data}
          pagination={false}
          loading={isLoading}
          rowKey={record => record.rowKey}
          scroll={{ x: 1000 }}
        />
        <Row justify={'end'} className={styles.bottomFilterWrapper}>
          <Col>
            <ReloadButton className={styles.reloadBtn} onClick={refresh} disabled={false} />
          </Col>
          <Col>
            <Pagination {...paginationProps} disabled={false} />
          </Col>
        </Row>
      </Card>
    </>
  )
})
