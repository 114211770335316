import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    all: string
    maintenanceIncident: string
    push: string
    marketing: string
    type: string,
    description: string
    merchantPortal: string
    dashboard: string
    bpm: string
    system: string
    title: string
    status: string
    active: string
    inactive: string
    scheduled: string
    block: string
    banner: string
    popup: string
    displayType: string
}>()

