import ReactDOM from 'react-dom'
import React, { forwardRef, useState, useEffect, useRef } from 'react'
import { Menu, Dropdown, InputRef } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import MenuItem from 'antd/lib/menu/MenuItem'
import classNames from 'classnames'
import { validatePostalCodeOfUK } from '~/code/pages/StartApplication/services'
import { useRefCurrent } from '~/code/hooks/useRefCurrent'
import { Input } from '~/code/components/Input'
import { PostCodeAddress } from './models'
import { getPostCodeAddresses } from './services'
import { PostalCodeProps } from './props'
import styles from './PostalCode.scss'

type DropDownPlacement = 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight'

const PostalCode = forwardRef<InputRef, PostalCodeProps>((props, forwardedRef) => {
  const { isUK, parentRef, onAddressSelect, onInput, onFocus, ...rest } = props
  const [placement, setPlacement] = useState<DropDownPlacement>('bottomLeft')
  const [width, setWidth] = useState<number>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [addresses, setAddresses] = useState<PostCodeAddress[]>([])
  const { current, ref } = useRefCurrent(forwardedRef)
  const pastRef = useRef(false)
  const menuRef = useRef()
  const isMenuVisible = isUK && addresses.length >= 1 && isOpen

  useEffect(() => {
    if (current?.input) {
      const el = ReactDOM.findDOMNode(current.input) as Element
      const { width: elWidth } = el.getBoundingClientRect()
      setWidth(elWidth)
    }
  }, [current])

  useEffect(() => {
    const documentClick = (e: MouseEvent) => {
      if (menuRef.current && current?.input) {
        const target = e.target as Element
        const el = ReactDOM.findDOMNode(current.input) as Element
        const menuEl = ReactDOM.findDOMNode(menuRef.current) as Element
        if (!menuEl.contains(target) && !el.contains(target)) {
          setIsOpen(false)
        }
      }
    }
    document.addEventListener('click', documentClick)
    return () => document.removeEventListener('click', documentClick)
  }, [current])

  const menu = (
    <Menu
      ref={menuRef}
      onClick={param => {
        const idx = Number(param.key)
        onAddressSelect && onAddressSelect(addresses[idx])
        setIsOpen(false)
      }}
    >
      {addresses.length > 0 &&
        addresses.map((a, i) => (
          <MenuItem key={i}>
            {[a.line_1, a.line_2, a.line_3, a.line_4, a.town_or_city].filter(s => Boolean(s)).join(', ')}
          </MenuItem>
        ))}
    </Menu>
  )

  const calculatePlacement = () => {
    if (!isMenuVisible || !current?.input) {
      return
    }
    const el = ReactDOM.findDOMNode(current?.input) as Element
    const menuEl = ReactDOM.findDOMNode(menuRef.current) as Element
    const { bottom } = el.getBoundingClientRect()
    const { height } = menuEl.getBoundingClientRect()
    const isBottom = bottom + height < window.innerHeight
    setPlacement(isBottom ? 'bottomLeft' : 'topLeft')
  }

  const search = async (txt: string) => {
    if (isUK && validatePostalCodeOfUK(txt)) {
      setIsLoading(true)
      try {
        const data = await getPostCodeAddresses(txt)
        setAddresses((data && data.addresses) || [])
      } catch {
        setAddresses([])
      }
      setIsLoading(false)
      setIsOpen(true)
      calculatePlacement()
    } else if (addresses.length > 0) {
      setAddresses([])
    }
  }

  return (
    <Dropdown
      overlay={menu}
      placement={placement}
      overlayClassName={classNames('ant-select-dropdown', styles.PostalCodeDropDown)}
      overlayStyle={{ width }}
      trigger={[]}
      open={isMenuVisible}
      getPopupContainer={() => (parentRef ? parentRef.current : document.body)}
      disabled={props?.disabled}
    >
      <Input
        {...rest}
        ref={ref}
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (pastRef.current) {
            e.target.value = e.target.value?.trim()
            pastRef.current = false
          }
          search(e.target.value)
          onInput && onInput(e)
        }}
        onFocus={e => {
          if (addresses.length > 0) {
            setIsOpen(true)
            calculatePlacement()
          }
          onFocus && onFocus(e)
        }}
        suffix={isLoading ? <LoadingOutlined /> : <span />}
        onPaste={_ => {
          pastRef.current = true
        }}
      />
    </Dropdown>
  )
})

export { PostalCode }
