import React, { PropsWithChildren } from 'react'
import { Footer } from '~/code/components/layout/Footer'
import styles from './styles.scss'

export const AuthLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles.root}>
      <div className={styles.content}>{children}</div>
      <Footer />
    </div>
  )
}
