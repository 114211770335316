import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    shareholders: string
    directors: string
    authorisedSignatories: string
    fillInDataFrom: string
    businessOwners: string
}>()

