import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Form, FormInstance} from 'antd'
import {FormItemVerificationCode} from '~/code/components'
import {TwoFAModalStoreInterface} from '~/code/stores/Profile/TwoFAModalStore/TwoFAModalStoreInterface'
import {TwoFAModalStoreSymbol} from '~/code/pages/Profile/components/TwoFA/components/TwoFAModal'

import translations from './translations'
import styles from './styles.scss'

export type TwoFAConfigureAuthAppProps = {
    form: FormInstance
}

export const TwoFAConfigureAuthApp: React.FC<TwoFAConfigureAuthAppProps> = observer(({form}) => {
    const {authAppInfo, secretKey, qrCode} =
        useInjection<TwoFAModalStoreInterface>(TwoFAModalStoreSymbol)

    const {key, account} = secretKey

    useEffect(() => () => form.resetFields(), [])

    return (
        <div>
            <p>{translations().title}</p>
            <ul className={styles.list}>
                <li>
                    1.{' '}
                    {translations().step1(
                        authAppInfo?.ios?.google?.Url,
                        authAppInfo?.android?.google?.Url
                    )}
                    <br />
                    <i>
                        {translations().step1Note}
                    </i>
                </li>
                <li>
                    2. {translations().step2(key, account)}
                    <div className={styles.qrHolder}>
                        <img src={`data:image/jpeg;base64, ${qrCode}`} alt={'qrCode'} />
                    </div>
                </li>
                <li>3. {translations().step3}</li>
                <li>
                    <Form form={form}>
                        <FormItemVerificationCode className={styles.formItem} />
                    </Form>
                </li>
            </ul>
        </div>
    )
})
