import { message } from 'antd'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { v4 as uuid } from 'uuid'
import { isEmpty } from 'dna-common'

import { OfficerRole, Application, CompanyProfile, OfficerProfile,
    PCSProfile, CompanyType, GeneralInformationType, ContactInfoType, LoadingPropertyType, VerifyBankAccountResult, GeneralInformationViewType } from 'startapp/models'
import { TariffRequestType } from '../components/Pricing/models/TariffRequestType'
import {
    isCompanyProfileValid, isDirectorsValid, isCompanyOfficerValid, isAuthorisedSignatoriesValid,
    isCompanyPCSListValid, parseApplicationWithEmptyValues, parseOnboardingRequestData, saveApplication, isFinancialInfoValid, getPaymentSolutionView
} from 'startapp/services'
import translations from '../translations'

const emptyApplication: Application = {
    companyProfile: null,
    companyPCSList: [],
    companyOfficerList: []
}

const UK = 'United Kingdom'

export class StartApplicationDataStore {
    cachedPhoneNumbers: string[] = []
    cachedEmails: string[] = []

    contactInfoData: ContactInfoType = null
    generalInformationData: GeneralInformationType = null
    applicationId: string = ''
    application: Application = parseApplicationWithEmptyValues(emptyApplication)
    tariffs: TariffRequestType = null

    isSaveLoading: boolean = false
    isNextLoading: boolean = false

    constructor(
        private completeApplicationStore: { verifyBankAccountResult: VerifyBankAccountResult },
        private supportingDocumentsStore: { isSupportingDocumentsValid: boolean, reset: () => void }
    ) {
        makeObservable(this, {
            cachedPhoneNumbers: observable,
            cachedEmails: observable,

            contactInfoData: observable,
            generalInformationData: observable,
            applicationId: observable,
            application: observable,
            tariffs: observable,

            isNextLoading: observable,
            isSaveLoading: observable,

            verifyBankAccountResult: computed,
            companyType: computed,
            companyNumber: computed,
            isApplicationValid: computed,
            isApplicationValidWithoutDocuments: computed,
            isCompanyProfileValid: computed,
            isDirectorsValid: computed,
            isBusinessOwnerValid: computed,
            isAuthorisedSignatoriesValid: computed,
            isShareholdersValid: computed,
            isFinancialInfoValid: computed,
            directors: computed,
            owner: computed,
            signatories: computed,
            shareholders: computed,
            generalInformationViewData: computed,
            savePhoneNumberAndEmail: action.bound,

            setContactInfoData: action.bound,
            setGeneralInformationData: action.bound,
            setApplicationId: action.bound,
            updateCompanyProfile: action.bound,
            createOfficer: action.bound,
            updateOfficer: action.bound,
            deleteOfficer: action.bound,
            updateShareholder: action.bound,
            deleteShareholder: action.bound,
            reset: action.bound,
            resetApplication: action.bound,
            setIsNextLoading: action,
            setTariffs: action.bound,
            setTerminalOrders: action.bound,

            saveOnPaymentSolution: action.bound,
            saveApplication: action.bound
        })
    }

    get generalInformationViewData(): GeneralInformationViewType {
        if (!this.generalInformationData) {
            return {
                companyNumber: null,
                companyType: null,
                paymentSolutionView: null,
                locationAddress: { country: UK },
                isFastTrack: false,
                isMsaSigned: false
            }
        }
        const { paymentSolution, ...rest } = this.generalInformationData
        const locationAddress = this.application.terminalOrders && this.application.terminalOrders[0]?.locationAddress || {}

        return {
            paymentSolutionView: getPaymentSolutionView(paymentSolution, this.application.terminalOrders),
            locationAddress: {
                country: UK,
                ...locationAddress
            },
            ...rest
        }
    }


    get verifyBankAccountResult() {
        return this.completeApplicationStore.verifyBankAccountResult
    }

    get companyType(): CompanyType {
        return this.generalInformationData?.companyType
    }

    get companyNumber(): string {
        return this.generalInformationData?.companyNumber
    }

    get isCompanyProfileValid(): boolean {
        const { companyProfile } = this.application
        return isCompanyProfileValid(companyProfile, this.companyType, this.generalInformationData?.paymentSolution)
    }

    get isFinancialInfoValid(): boolean {
        const { companyProfile } = this.application
        return isFinancialInfoValid(companyProfile, this.companyType, this.generalInformationData?.paymentSolution)
    }

    get isApplicationValid(): boolean {
        return this.isApplicationValidWithoutDocuments && this.supportingDocumentsStore.isSupportingDocumentsValid
    }

    get isApplicationValidWithoutDocuments(): boolean {
        return this.isCompanyProfileValid && (
            (this.companyType !== 'company' && this.isBusinessOwnerValid) ||
            (
                this.companyType === 'company' &&
                this.isDirectorsValid &&
                this.isShareholdersValid &&
                this.isAuthorisedSignatoriesValid
            )
        )
    }

    get isDirectorsValid(): boolean {
        return isDirectorsValid(this.directors)
    }

    get isBusinessOwnerValid(): boolean {
        return !isEmpty(this.owner) && isCompanyOfficerValid(this.owner)
    }

    get isAuthorisedSignatoriesValid(): boolean {
        return isAuthorisedSignatoriesValid(this.signatories)
    }

    get isShareholdersValid(): boolean {
        return isCompanyPCSListValid(this.shareholders)
    }

    get directors(): OfficerProfile[] {
        return this.application?.companyOfficerList?.filter((item) => item.officerRole === OfficerRole.director) || []
    }

    get owner(): OfficerProfile {
        return this.application.companyOfficerList
            ?.find(officer => officer.officerRole === OfficerRole.businessOwner) ||
            {
                officerRole: OfficerRole.businessOwner,
                emailAddress: this.contactInfoData?.email,
                telephoneNumber: this.contactInfoData?.telephoneNumber,
                nameElements: {
                    surname: this.contactInfoData?.surname,
                    forename: this.contactInfoData?.firstName
                }
            }
    }

    get signatories(): OfficerProfile[] {
        return this.application?.companyOfficerList?.filter((item) => item.officerRole === OfficerRole.authorisedSignatory) || []
    }

    get shareholders(): PCSProfile[] {
        return this.application?.companyPCSList || []
    }

    setTariffs(tariffs: any) {
        if (tariffs) {
            const { posModel, ...rest } = tariffs
            this.tariffs = rest
        } else {
            this.tariffs = tariffs
        }
    }

    savePhoneNumberAndEmail(phoneNumber: string, email: string) {
        if (phoneNumber && !this.cachedPhoneNumbers.includes(phoneNumber)) {
            this.cachedPhoneNumbers.push(phoneNumber)
        }

        if (email && !this.cachedEmails.includes(email)) {
            this.cachedEmails.push(email)
        }
    }

    setContactInfoData(data: ContactInfoType) {
        this.contactInfoData = data
    }

    setTerminalOrders(data) {
        this.application.terminalOrders = data
    }

    setGeneralInformationData(data: GeneralInformationType) {
        this.generalInformationData = data
    }

    setApplicationId(id: string) {
        this.applicationId = id
    }

    updateCompanyProfile(companyProfile: CompanyProfile) {
        this.application.companyProfile = {...this.application.companyProfile, ...companyProfile}
    }

    setOfficerList(officerList: OfficerProfile[]) {
        this.application.companyOfficerList = officerList
    }

    createOfficer (officer: OfficerProfile) {
        const newOfficer = { ...officer, id: uuid() }
        this.application.companyOfficerList.push(newOfficer)
        return newOfficer
    }

    updateOfficer (officer: OfficerProfile) {
        const index = this.application.companyOfficerList.findIndex((o) => o.id === officer.id)
        const oldValue = this.application.companyOfficerList[index]

        this.application.companyOfficerList.splice(index, 1, { ...oldValue, ...officer })
    }

    deleteOfficer(officer: OfficerProfile) {
        const index = this.application.companyOfficerList.findIndex((o) => o.id === officer.id)
        this.application.companyOfficerList.splice(index, 1)
    }

    setCompanyPCSList(companyPCSList: PCSProfile[]) {
        this.application.companyPCSList = companyPCSList
    }

    updateShareholder (shareholder: PCSProfile) {
        const index = this.application.companyPCSList.findIndex((o) => o.id === shareholder.id)
        const oldValue = this.application.companyPCSList[index]

        this.application.companyPCSList.splice(index, 1, { ...oldValue, ...shareholder })
    }

    deleteShareholder (shareholder: PCSProfile) {
        const index = this.application.companyPCSList.findIndex((o) => o.id === shareholder.id)
        this.application.companyPCSList.splice(index, 1)
    }

    setIsNextLoading(value: boolean) {
        this.isNextLoading = value
    }

    reset() {
        this.cachedEmails = []
        this.cachedPhoneNumbers = []
        this.generalInformationData = null
        this.contactInfoData = null
        this.resetApplication()
    }

    resetApplication() {
        this.supportingDocumentsStore.reset()
        this.application = parseApplicationWithEmptyValues(emptyApplication)
    }

    saveOnPaymentSolution(values: GeneralInformationType, loadingProperty: LoadingPropertyType): Promise<boolean> {
        this.setGeneralInformationData(values)
        return this.saveApplication(loadingProperty)
    }

    saveOnTariffs(values: any, loadingProperty: LoadingPropertyType): Promise<boolean> {
        this.setTariffs(values)
        return this.saveApplication(loadingProperty)
    }

    async saveApplication(loadingProperty: LoadingPropertyType) {
        const data = parseOnboardingRequestData(this.application, this.generalInformationData, this.tariffs)
        let isOk = false

        try {
            runInAction(() => {
                this[loadingProperty] = true
            })

            const { error, status } = await saveApplication(this.applicationId, data)

            if (error || status !== 200) {
                message.error(error.message || translations().errorSavingData)
            } else {
                isOk = true
                message.success(translations().savedDataSuccessfully)
            }
        } catch (error) {
            message.error(error.message || translations().errorSavingData)
        } finally {
            runInAction(() => {
                this[loadingProperty] = false
            })
        }

        return isOk
    }
}
