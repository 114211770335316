import translations from './translations'

translations.add('en', {
    title: 'Business Owners',
    text: 'Please ensure you have included all your business owners',
    tooltip: 'Business Owners is optional, so you can skip it.',
    optional: 'Optional',
    verified: 'Verified',
    nonVerified: 'Non Verified'
})
