import translations from './translations'

translations.add('en', {
    newNotification: 'New Notification',
    notificationDetails: 'Notification Details',
    viewDetails: 'View Details',
    republish: 'Republish',
    deactivate: 'Deactivate',
    template: 'Template',
    system: 'System',
    type: 'Type',
    status: 'Status',
    endDate: 'End Date',
    startDate: 'Start Date',
    actions: 'Actions',
    maintenanceIncident: 'Maintenance/Incident',
    push: 'Push',
    marketing: 'Marketing',
    description: 'Description',
    title: 'Title',
    view: 'View',
    select: 'Select',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    descriptor: 'Descriptor',
    companyName: 'Company Name',
    publish: 'Publish',
    manageNotifications: 'Manage Notifications',
    refresh: 'Refresh',
    deactivateTitle: 'You are about to deactivate the notification.',
    deactivateContent: 'Are you certain you wish to proceed?',
    displayType: 'Display Type',
    contentArea: 'Content Area'
})
