export class TransactionStatementFilterModel {
    status: string[] = undefined
    transactionChannel: string[] = []
    paymentMethod: string[] = []
    issuerCountry: string[] = []
    ipCountry: string[] = []
    payerIp: string = ''
    payerEmail: string = ''
    cardMask: string = ''
    terminal: string = ''
    reference: string = ''
    mid: string = ''
    merchantReference: string = ''
    threeDSVersion: string = ''
    payerAuthenticationResult: string[] = []
    transactionId: string = ''
    processingType: string[] = []
    cardScheme: string[] = []
    signifydDecision: string[] = []
    amountFrom: string = ''
    amountTo: string = ''
}
