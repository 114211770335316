import translations from './translations'

translations.add('en', {
    buttons: {
        cancel: 'Cancel',
        continue: 'Continue'
    },
    description: n =>
        `Please enter the ${n}-digit verification code generated by your authenticator app`
})
