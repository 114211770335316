import translations from './translations'
import React from 'react'

translations.add('en', {
  headlineRate: 'Visa and Mastercard fees',
  transactionType: 'Transaction Type (Merchant Services Charges (per Transaction) *)',
  backText: 'Back',
  blended: 'Blended',
  icPlus: 'IC++',
  cardScheme: 'Card Scheme',
  serviceArea: 'Location',
  corporateOrConsumer: 'Corporate or Consumer',
  cardType: 'Card Type',
  baseFee: 'Base fee (GBP.)',
  percentFee: 'Percent fee (%)',
  skip: 'Skip',
  pos: 'Set Tariffs For POS Terminals',
  ecom: 'Set Tariffs For ECOM Terminals',
  warningTitle: 'Please be cautious whilst filling out the fees',
  ensureMSA: 'Ensure each rate is filled according to the MSA.',
  wrongFee: 'The wrong fee will cause incorrect charges.',
  doubleCheck: 'It is critical to double-check each field before proceeding forward.',
  consumerCreditLabel: 'Consumer Credit %',
  consumerDebitLabel: 'Consumer Debit %',
  corporateCreditLabel: 'Corporate Credit %',
  corporateDebitLabel: 'Corporate Debit %',
  sameAsPos: 'Same as Pos tariffs?',
  prodName: (prod: string) => (prod === 'pos' ? 'POS Payments' : 'Online Payments'),
  selectFeeType: 'Please select fee type',
  note: 'Note, these exact rates will be reflected in the Merchant Service Agreement. ',
  save: 'Save',
  cancel: 'Cancel',
  saveForLater: 'Save for later',
  back: 'Back'
})
