import { action, computed, makeObservable, observable } from 'mobx'
import { currencies } from '../constants/dictionaries/currencies'
import { CurrencyModel, CurrencyType } from '../models/globalConfig'


export class GlobalConfigStore {
    currency: CurrencyModel = { type: 'GBP', symbol: '£' }
    currencies: CurrencyModel[] = currencies

    constructor() {
        makeObservable(this, {
            currency: observable,
            setCurrency: action
        })
    }

    showCurrencySelect(currentRoute: string) {
        return currentRoute.includes('transactions')
    }

    setCurrency = (currencyType: CurrencyType) => {
        this.currency = this.currencies.find(c => c.type === currencyType)
    }
}