import translations from './translations'

translations.add('en', {
    merchant: 'Merchant',
    applicantName: 'Applicant Name',
    mid: 'MID',
    email: 'Email',
    phoneNumber: 'Phone',
    initiatorEmail: 'Initiator Email',
    startDate: 'Start Date',
    endDate: 'End Date',
    processStatus: 'Status',
    acquisitionChannel: 'Partner',
    assignedGroup: 'Assigned Group',
    assignedUser: 'Assigned User',
    approvalType: 'Approval Type',
    productType: 'Product Type',
    reviewTime: 'Review Time',
    segmentCategory: 'Segment Category',
    segmentSubCategory: 'Segment subcategory',
    opportunityId: 'Opportunity ID'
})
