import React from 'react'
import translations from './translations'


translations.add('en', {
    title: 'General Information',
    description: 'We need to learn more about your business before you can start using our service',
    companyBlockTitle: 'Company',
    companyNameLabel: 'Company name',
    companyNameRequired: 'company name is required',
    companyTradeNameLabel: 'Trading name',
    companyTradeNameRequired: 'trading name is required',
    storeNameLabel: 'Store system descriptor',
    storeNameRequired: 'store system descriptor is required',
    storeNameMax: 'store system descriptor shouldn\'t exceed 24 characters',
    storeNameInfo: 'Store system descriptor will be reflected in the printed receipts and consumer bank statements. It shouldn\'t exceed 24 characters. If the Trading name is too long, it would be a good idea to ask the Merchant how they want to shorten it.',
    sameAsCompanyName: 'same as company name',
    companyNumberLabel: 'Company number',
    companyNumberRequired: 'company number is required',
    dateOfCreationLabel: 'Trading from',
    dateOfCreationRequired: 'trading from date is required',
    dateOfCreationInvalid: 'invalid trading from date',
    natureOfBusinessLabel: 'Industry',
    natureOfBusinessRequired: 'industry is required',
    natureOfBusinessPlaceholder: 'Enter the industry, e.g.: retail, food',
    natureOfBusinessDetailsLabel: 'Product/service description',
    natureOfBusinessDetailsRequired: 'product/service is required',
    natureOfBusinessDetailsPlaceholder: 'Tell us what products or services you provide',
    companyWebsiteLabel: 'Company website',
    companyWebsiteRequired: 'company website is required',
    companyWebsiteInvalid: 'company website is invalid',
    employeesNumberLabel: 'Number of employees',
    employeesNumberRequired: 'number of employees is required',
    emailAddressLabel: 'Company email address',
    emailAddressRequired: 'company email address is required',
    emailFormatIncorrect: 'invalid email',
    telephoneNumberLabel: 'Company phone number',
    telephoneNumberRequired: 'company phone number is required',
    telephoneNumberWrongFormat: 'invalid company phone number',
    companyAddressTitle: 'Company address',
    companyAddress: {
        cityLabel: 'Town/City',
        cityRequired: 'town/city is required',
        cityInvalid: 'only alphanumeric characters are allowed',
        countryLabel: 'Country',
        countryRequired: 'country is required',
        regionLabel: 'County',
        postalCodeLabel: 'Postcode',
        postalCodeRequired: 'postcode is required',
        postalCodeInvalid: 'invalid postcode',
        addressLine1Label: 'Address line 1',
        addressLine1Required: 'address line 1 is required',
        addressLine2Label: 'Address line 2',
        countryTooltip: 'We are currently only able to work with UK registered businesses'
    },
    tradingAddressTitle: 'Trading address',
    sameAsCompanyAddress: 'same as company address',
    tradingAddress: {
        cityLabel: 'Town/City',
        cityRequired: 'town/city is required',
        cityInvalid: 'only alphanumeric characters are allowed',
        countryLabel: 'Country',
        countryRequired: 'country is required',
        regionLabel: 'County',
        postalCodeLabel: 'Postcode',
        postalCodeRequired: 'postcode is required',
        postalCodeInvalid: 'invalid postcode',
        addressLine1Label: 'Address line 1',
        addressLine1Required: 'address line 1 is required',
        addressLine2Label: 'Address line 2',
        countryTooltip: 'We are currently only able to work with UK registered businesses'
    },
    employeesNumberOptions: {
        upTo15: 'Up to 15',
        between15And50: '15-50',
        between50And100: '50-100',
        between100And300: '100-300',
        over300: 'Over 300'
    },
    new: 'New',
    renewal: 'Renewal',
    newToDNA: 'New to DNA Group?',
    relationshipTypeRequired: 'relationship type is required'
})
