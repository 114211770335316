import React, { useEffect, useState } from 'react'
import { PageHeaderRaw, ReloadButton } from '~/code/components'
import {
  Button,
  Card,
  Col, Drawer, Modal,
  Pagination,
  Row,
  Space,
  Table,
  TablePaginationConfig,
  Typography
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { NotificationSetupFormType } from '~/code/stores/NotificationManagementStore'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'
import {
  IManageNotificationTemplatesStore
} from '~/code/pages/Notifications/ManageNotificationTemplates/INotificationTemplatesStore'
import {
  NotificationTemplatesFilter
} from '~/code/pages/Notifications/ManageNotificationTemplates/components/NotificationTemplatesFilter'
import { TemplateDetails } from '~/code/pages/Notifications/ManageNotificationTemplates/components/TemplateDetails'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import {getTemplatesColumns} from './constants'
import translations from './translations'
import styles from './styles.scss'

export const NotificationTemplatesStoreSymbol = Symbol('NotificationTemplatesStoreSymbol')

export const ManageNotificationTemplates = observer(() => {
  const store = useInjection<IManageNotificationTemplatesStore>(NotificationTemplatesStoreSymbol)
  const [deleteTemplateId, setDeleteTemplateId] = useState(null)
  const {
    templates,
    isTemplatesLoading,
    isDeletingModalOpen,
    isDrawerOpened,
    total,
    selectedTemplate,
    notificationSetupForm,
    previewTemplate,
    filter,
    setFilter,
    deleteTemplate,
    editTemplate,
    loadTemplates,
    newTemplate,
    viewDetails,
    setDeletingModal,
    onDrawerClose,
    isDashboard
  } = store

  const screens = useBreakpoint()
  const paginationProps: TablePaginationConfig = generateTablePaginationProps(
    filter.page,
    filter.size,
    total,
    (page: number, pageSize: number) => setFilter({page, size: pageSize}, true)
  )

  const onDeleteActionClick = (id:string) => {
    setDeletingModal(true)
    setDeleteTemplateId(id)
  }

  const onDeleteTemplate = () => {
    deleteTemplate(deleteTemplateId)
    setDeleteTemplateId(null)
  }

  const columns = getTemplatesColumns(onDeleteActionClick, editTemplate, viewDetails)

  const templateDetails = [
    {
      title: translations().title,
      description: selectedTemplate?.title,
    },
    {
      title: translations().system,
      description: selectedTemplate?.system,
    },
    {
      title: translations().description,
      description: (<div className={styles.templateDescription}>{selectedTemplate?.descriptionLine1}</div>),
    },
    {
      title: translations().type,
      description: selectedTemplate?.type,
    },
    {
      title: translations().displayType,
      description: selectedTemplate?.displayType,
    },
    {
      title: translations().contentArea,
      description: selectedTemplate?.contentArea,
    },
  ]

  useEffect(() => {
    loadTemplates()
  }, [])

  return (
    <>
      <Space className={styles.flex} direction="vertical" size="large">
        <PageHeaderRaw>
          <Space direction={'vertical'} size={'small'}>
            <Row>
              <Col>
                <Typography.Title className={styles.pageTitle}  level={5}>{translations().manageTemplates}</Typography.Title>
              </Col>
            </Row>
          </Space>
        </PageHeaderRaw>

        <Card className={styles.filterCard}>
          <NotificationTemplatesFilter/>
        </Card>

        <Card>
          <Space direction="vertical" size={'middle'} style={{ display: 'flex' }}>
            <Row justify={'end'}>
              <Col className={styles.refreshBtn}>
                <ReloadButton onClick={() => setFilter({...filter}, true)}/>
              </Col>
              <Col>
                <Button
                  type={'primary'}
                  icon={<PlusOutlined />}
                  onClick={() => newTemplate(0)}
                  disabled={!hasPermissions([PermissionMap.notifications.templates.create])}
                >
                  {translations().newTemplate}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Table
                  loading={isTemplatesLoading}
                  dataSource={templates}
                  columns={columns}
                  pagination={false}
                  rowKey={(template) =>template.id}
                  scroll={{ x: 1000, y: 1000 }}
                />
              </Col>
            </Row>
            <Row justify={'end'}>
              <Col className={styles.refreshBtn}>
                <ReloadButton onClick={() => setFilter({...filter}, true)}/>
              </Col>
              <Col>
                <Pagination className={styles.bottomPagination} {...paginationProps} disabled={false} />
              </Col>
            </Row>
          </Space>
        </Card>

        <Modal
          width={310}
          title={translations().deleteTemplateTitle}
          open={isDeletingModalOpen}
          onOk={onDeleteTemplate}
          onCancel={() => setDeletingModal(false)}
        >
          {translations().deleteTemplateContent}
        </Modal>

        <Drawer
          closable
          destroyOnClose
          title={translations().templateDetails}
          placement="right"
          open={isDrawerOpened}
          onClose={onDrawerClose}
          width={screens.xs ? 360 : 620}
        >
          <TemplateDetails
            data={templateDetails}
            isDashboard={isDashboard}
            previewTemplate={previewTemplate}
            notificationSetupForm={notificationSetupForm as NotificationSetupFormType}
          />
        </Drawer>
      </Space>
    </>
  )
})