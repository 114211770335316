import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Form, Drawer, Grid, Button } from 'antd'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { hasPermissions } from '~/code/services/auth'
import {
  IPartnerAccessFormStore,
  PartnerAccessFormStoreSymbol
} from '~/code/pages/PartnerAccess/components/PartnerAccessDrawer'
import { PartnerAccessAddEditLoginForm } from '../'
import { PartnerAccessLoginDrawerProps } from './props'

import translations from './translations'
import styles from './styles.scss'

const { useBreakpoint } = Grid

export const PartnerAccessLoginDrawer = observer((props: PartnerAccessLoginDrawerProps) => {
  const { open, ...rest } = props
  const [form] = Form.useForm()
  const { xs } = useBreakpoint()
  const width = xs ? window.innerWidth : 512
  const store = useInjection<IPartnerAccessFormStore>(PartnerAccessFormStoreSymbol)
  const {
    isLoading,
    partnerAccessStore: { isEditMode, selectedPartner, onLoginDrawerClose }
  } = store

  const title = isEditMode ? translations().edit : selectedPartner?.value

  useEffect(() => form.resetFields(), [open])

  const renderFooter = () => (
    <div className={styles.footer}>
      <Button onClick={onLoginDrawerClose}>{translations().cancel}</Button>
      <Button
        htmlType='button'
        type='primary'
        loading={isLoading}
        disabled={!hasPermissions([PermissionMap.partners.teammates.create, PermissionMap.partners.teammates.update])}
        onClick={() => form.submit()}
      >
        {translations().submit}
      </Button>
    </div>
  )

  return (
    <Drawer title={title} open={open} width={width} footer={renderFooter()} onClose={onLoginDrawerClose} {...rest}>
      <PartnerAccessAddEditLoginForm form={form} store={store} />
    </Drawer>
  )
})
