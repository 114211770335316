import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  emailAddress: string
  firstName: string
  lastName: string
  sendWelcomeEmail: string
  submit: string
  partner: string
  partnerPlaceholder: string
  noPartners: string
  roles: string
  createdAt: string
  searchByPartner: string
  searchByEmail: string
  actions: string
  emailSearchPlaceholder: string
  lastLoginDate: string
  status: string
  authMethod: string

  formErrors: {
    wrongEmailFormat: string
  }

  menu: {
    edit: string
    editSecurity: string
    delete: string
  }
  deleteAlert: {
    logins: {
      title: string
      text: string
    }

    partners: {
      title: string
      text: string
    }

    okText: string
  }
  totalPagination: (range: number[], total: number) => string
}>()
