import React from 'react'
import { ReloadOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import translations from './translations'
import styles from './ReloadButton.scss'

interface Props {
  onClick(): void
  className?: string
  disabled?: boolean
}

export const ReloadButton: React.FC<Props> = (props) => {
  const { className, onClick, disabled } = props

  return <Tooltip title={translations().title}>
    <button
      onClick={ () => onClick && onClick() }
      className={classNames(styles.ReloadButton, className, {[styles.disabled]: disabled})}
      disabled={ disabled }
    >
      <ReloadOutlined />
    </button>
  </Tooltip>
}
