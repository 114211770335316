import translations from './translations'
import { PaymentSolutionView } from '../../../models'

translations.add('en', {
    title: 'Payment solution',
    required: 'payment solution is required',
    posModel: 'POS Model',
    posModelRequired: 'pos model is required',
    paymentSolutions: {
        [PaymentSolutionView.WEBSITE_PAYMENTS]: {
            title: 'Website Payments',
            comment: 'Pay by Link included'
        },
        [PaymentSolutionView.PAY_BY_LINK]: {
            title: 'Pay by Link only',
            comment: 'No website needed'
        },
        [PaymentSolutionView.POS]: {
            title: 'POS',
            comment: 'In store POS terminals'
        },
        [PaymentSolutionView.WEBSITE_PAYMENTS_MOTO]: {
            title: 'Website Payments + VT',
            comment: 'Pay by Link included'
        },
        [PaymentSolutionView.PAY_BY_LINK_MOTO]: {
            title: 'Pay by Link only + VT',
            comment: 'No website needed'
        },
        [PaymentSolutionView.POS_MOTO]: {
            title: 'POS with MOTO enabled',
            comment: 'In store POS terminals'
        }
    },
    ecom: 'ECOM',
    pos: 'POS'
})
