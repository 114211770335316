import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Divider, Form, Modal, Radio, Typography } from 'antd'
import { BackButton } from '~/code/components'
import { PricingStore } from '../../stores/PricingStore'
import { convertStringValuesToNumber } from '../../services'
import { NextButton } from '../shared'
import { BlendedTable, FeesFormMSA, ICPlusTable } from './components'
import { TariffRequestType } from './models/TariffRequestType'
import { getBlendedValues, getFeesFormMSAValues } from './services'
import translations from './translations'
import styles from './styles.scss'

export const Pricing = observer((props: { store: PricingStore }) => {
  const { store } = props
  const { tariffType, dataStore } = store
  const [nextClickCounter, setNextClickCounter] = useState<number>(0)

  const [formBlended] = Form.useForm()
  const [formICP] = Form.useForm()
  const [formFees] = Form.useForm()

  const warning = () => {
    Modal.warning({
      title: translations().warningTitle,
      content: (
        <>
          <Typography.Text>{translations().ensureMSA}</Typography.Text>
          <br />
          <Typography.Text>{translations().wrongFee}</Typography.Text>
          <br />
          <Typography.Text>{translations().doubleCheck}</Typography.Text>
        </>
      ),
      width: 600
    })
  }

  const submit = async () => {
    const form = tariffType === 'blended' ? formBlended : formICP
    await Promise.all([form.validateFields(), formFees.validateFields()])

    const newTariffs = { ...form.getFieldsValue(), ...formFees.getFieldsValue(), tariffType }
    convertStringValuesToNumber(newTariffs, ['tariffType'])
    return newTariffs as TariffRequestType
  }

  useEffect(() => {
    if (store.tariffsList.length <= 0) store.loadTariffs()
  }, [])

  const PricingBack = () => (
    <div>
      <BackButton onClick={() => store.goBack()}>{translations().backText}</BackButton>
    </div>
  )

  if (store.shouldBeSkipped) {
    return (
      <div>
        <PricingBack />

        <NextButton
          loading={dataStore.isNextLoading}
          disabled={store.isTariffsLoading}
          onClick={() => {
            store.goNext({
              ...getBlendedValues(store.filteredtariffsList),
              ...getFeesFormMSAValues(store.productType),
              tariffType: 'blended'
            })
          }}
          children={translations().skip}
        />
      </div>
    )
  }

  return (
    <div>
      <PricingBack />

      <Radio.Group
        value={tariffType}
        onChange={e => {
          store.setTariffType(e.target.value)
          setNextClickCounter(0)
        }}
        className={styles.radioGroup}
      >
        <Radio value='blended'>{translations().blended}</Radio>
        <Radio value='ic+'>{translations().icPlus}</Radio>
      </Radio.Group>

      <Form.Provider>
        {tariffType === 'blended' && (
          <BlendedTable store={store} form={formBlended} initialValues={dataStore.tariffs} />
        )}
        {tariffType === 'ic+' && <ICPlusTable store={store} form={formICP} initialValues={dataStore.tariffs} />}
        {tariffType && (
          <>
            <Divider style={{ margin: '12px 0' }} />
            <FeesFormMSA form={formFees} initialValues={dataStore.tariffs} product={store.productType} />
            <NextButton
              loading={dataStore.isNextLoading}
              disabled={store.isTariffsLoading}
              onClick={() => {
                submit().then(newTariffs => {
                  if (nextClickCounter === 0) {
                    warning()
                    setNextClickCounter(prevValue => prevValue + 1)
                    return
                  }

                  store.goNext(newTariffs)
                })
              }}
            />
            {/* <BelowActionsWithSave
                        large
                        isLoading={dataStore.isSaveLoading}
                        onSave={}
                    >
                    </BelowActionsWithSave> */}
          </>
        )}
      </Form.Provider>
    </div>
  )
})
