import React from 'react'
import classNames from 'classnames'
import { Button, ButtonProps } from 'antd'
import styles from './styles.scss'

export const AuthFormSubmitButton: React.FC<ButtonProps> = ({ className, ...rest}) => (
  <Button
    className={classNames(styles.button, className)}
    size='large'
    type='primary'
    htmlType='submit'
    {...rest}
  />
)