import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  payByLink: string
  virtualTerminal: string
  websitePayments: string
  zashDigital: string
  amex: string
  unionPay: string
  discovery: string
  openBanking: string
  payByBank: string
  klarna: string
  options: string
  storeWebSite: string
  webSiteRequired: string
  aliPay: string
  configureEcom: string
  paymentMethods: string
  cards: string
  productConfiguration: string
  minTerm: string
}>()
