import { mixed, object, string } from 'yup'
import { PHONE_NUMBER_PATTERN } from '~/code/constants/Patterns'

export const GeneralInformationScheme = object({
    email: string().email().required(),
    acquisitionChannel: string().required(),
    firstName: string().required(),
    surname: string().required(),
    telephoneNumber: string().required().matches(PHONE_NUMBER_PATTERN),
    paymentSolution: string().required(),
    companyType: string().required().oneOf(['company', 'sole-trader']),
    companyNumber: mixed().when('companyType', { is: 'company', then: string().required() })
}).required()
