import { Button, ButtonProps } from 'antd'
import React, { PropsWithChildren } from 'react'
import translations from './translations'
import styles from './NextButton.scss'

type Props = Pick<ButtonProps, 'disabled' | 'loading' | 'onClick'>

export const NextButton: React.FC<PropsWithChildren<Props>> = props => {
  const { disabled, loading, onClick, children } = props
  return (
    <div className={styles.container}>
      <Button
        type='primary'
        htmlType='submit'
        disabled={disabled}
        className={styles.button}
        loading={loading}
        onClick={onClick}
      >
        {children || translations().next}
      </Button>
    </div>
  )
}
