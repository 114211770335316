import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { AuthFormProps } from './props'
import styles from './AuthForm.scss'

export const AuthForm: React.FC<PropsWithChildren<AuthFormProps>> = props => {
  const { header, title, children, className } = props
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.header}>{header || <h1 className={styles.title}>{title}</h1>}</div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}
