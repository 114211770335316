import React from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'

import { MonthPicker } from '~/code/components'
import { MonthPickerContainerProps } from '~/code/containers/MonthPickerContainer/MonthPickerContainerProps'
import { IRangePickerContainerStore } from '~/code/pages/settlements/SettlementsPayments/components'

export const MonthRangePickerContainerStoreSymbol = Symbol('MonthRangePickerContainerStore')

export const MonthPickerContainer = observer(
  ({ injectableStoreIdentifier = MonthRangePickerContainerStoreSymbol }: MonthPickerContainerProps) => {
    const store = useInjection<IRangePickerContainerStore>(injectableStoreIdentifier)

    return <MonthPicker startDate={store.startDate} endDate={store.endDate} onDatesChange={store.setMonths} />
  }
)
