import contries from '~/assets/jsons/countries.json'
import { ItemModel } from '~/code/models/handbooks'
import translations from '~/code/translations'

export const COUNTRIES = contries

export const STATUSES = [
    'REFUND',
    'TOKENIZED',
    'VERIFIED',
    'REJECT',
    'CANCEL',
    'NEW',
    '3D',
    'AUTH',
    'CHARGE',
    'CREATED',
    'PAYMENT_LINK_VIEWED',
    'PAYMENT_LINK_CANCELLED',
    'CREDITED',
    'PROCESSING',
    'FAILED',
    'ABANDONED'
]

export const TRANSACTION_CHANNELS = Object.entries(translations().transactionChannels)
    .map(([value, label]) => ({ value, label }))

export const POS_TRANSACTION_CHANNELS = Object.entries(translations().posTransactionChannels)
    .map(([value, label]) => ({ value, label }))

export const PAYMENT_METHODS = Object.entries(translations().paymentMethods)
    .map(([ value, label ]) => ({ value, label }))

export const THREE_DS_VERSIONS: ItemModel[] = [
    {
        label: 'All',
        value: null
    },
    {
        label: '1.x',
        value: '1'
    },
    {
        label: '2.x',
        value: '2'
    }
]

export const PAYER_AUTHS: string[] = [
    'Y/Y',
    '-/-',
    'C/Y',
    'Y/A',
    'C/-',
    'Y/R',
    'C/N',
    'U/-',
    'C/U',
    'Y/E',
    'N/-',
    'Y/N',
    'Y/U',
    'C/',
    'Y/-',
    'C/A',
    'E/-',
    'Y/'
]

export const PROCESSING_TYPES: string[] = [
    translations().processingTypes.mailOrder,
    translations().processingTypes.telephoneOrder,
    translations().processingTypes.initialRecurring,
    translations().processingTypes.recurringMit,
    translations().processingTypes.initialUcof,
    translations().processingTypes.ucofMit,
    translations().processingTypes.initialCof,
    translations().processingTypes.cofCit,
    translations().processingTypes.mailOrderInitialCof,
    translations().processingTypes.telephoneOrderInitialCof,
    translations().processingTypes.motoRecurringMit
]

export const CARD_SCHEMES: string[] = [
    translations().cardTypes.visa,
    translations().cardTypes.mastercard,
    translations().cardTypes.amex,
    translations().cardTypes.upi,
    translations().cardTypes.diners,
    translations().cardTypes.other
]

export const SIGNIFYD_DECISIONS: string[] = [
    translations().signifydDecisions.accept,
    translations().signifydDecisions.reject,
    translations().signifydDecisions.hold
]

export const UK = 'United Kingdom'

export const UKPrefix = '+44'