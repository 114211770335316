import React from 'react'
import { observer } from 'mobx-react-lite'
import { Drawer, Form, Grid, Button } from 'antd'
import { UserSecurity } from '~/code/components'
import { TeammatesSecurityDrawerProps } from './props'

import translations from './translations'
import styles from './styles.scss'

const { useBreakpoint } = Grid

export const TeammatesSecurityDrawer = observer(
  ({ teammate, open, onSubmit, onClose, ...rest }: TeammatesSecurityDrawerProps) => {
    const [form] = Form.useForm()
    const screens = useBreakpoint()

    const twoFAEnabled = teammate?.twoFAStatus !== 'DISABLED'
    const currentTwoFAEnabled = Form.useWatch('twoFAEnabled', form)
    const currentReason = Form.useWatch('reason', form)
    const isSubmitDisabled =
      twoFAEnabled === currentTwoFAEnabled || (twoFAEnabled && !currentTwoFAEnabled && !currentReason)

    const onFinish = v => {
      onSubmit({
        id: teammate?.id,
        ...v
      })
    }

    const renderFooter = () => (
      <div className={styles.footer}>
        <Button onClick={onClose}>{translations().cancel}</Button>

        <Button htmlType='button' type='primary' disabled={isSubmitDisabled} onClick={() => form.submit()}>
          {translations().submit}
        </Button>
      </div>
    )

    const handleAfterOpenChange = (visible: boolean) => {
      if (!visible) {
        form.resetFields()
      }
    }

    return (
      <Drawer
        title={translations().edit}
        open={open}
        width={screens.xs ? window.innerWidth : 512}
        afterOpenChange={handleAfterOpenChange}
        onClose={onClose}
        footer={renderFooter()}
        destroyOnClose
        {...rest}
      >
        <Form form={form} requiredMark={false} labelAlign='left' labelCol={{ span: 6 }} onFinish={onFinish}>
          <UserSecurity form={form} status={teammate?.twoFAStatus} />
        </Form>
      </Drawer>
    )
  }
)
