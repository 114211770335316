import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    customer: string
    issuer: string
    other: string
    total: string
    failedTransactionsReasons: string
    successful: string
}>()
