import React from 'react'
import {Form, Input} from 'antd'

import translations from './translations'

type FormItemVerificationCodeProps = {
    length?: number
    className?: string
}

export const FormItemVerificationCode: React.FC<FormItemVerificationCodeProps> = ({
    length = 6,
    className
}) => {
    return (
        <Form.Item
            name='verificationCode'
            className={className}
            rules={[
                {
                    required: true,
                    message: translations().errors.codeRequired
                },

                {
                    validator: async (_, value) => {
                        if (value && value.length !== length)
                            throw new Error(translations().errors.codeInvalid)
                    }
                }
            ]}
        >
            <Input placeholder={translations().codePlaceholder} maxLength={length} />
        </Form.Item>
    )
}
