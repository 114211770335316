import React from 'react'
import { Form, Select, Input, InputNumber, Col, Radio, Divider, Row, Switch } from 'antd'
import { observer } from 'mobx-react-lite'
import { DictionaryInfoType, PaymentSolutionView } from '../../models'
import { FormSubgroupTitle } from '../shared/FormSubgroupTitle'
import translations from './translations'
import styles from './SelectPaymentSolutionForm.scss'
import { PhoneNumberFormItem } from '../shared'
import { SelectPaymentSolutionFormProps } from './props'
import { isCountryUK } from '../../services'
import { isEmpty, validatePostalCodeOfUK } from 'dna-common'
import { CustomButton, CustomModal, PostalCode } from '~/code/components'
import { subscriptionServicePeriodOptionsDNA, subscriptionServicePeriodOptionsPaytek, terminalTypes } from './constants'

const { Option } = Select

const renderProductOptions = (item: DictionaryInfoType, index: number, disabled: boolean) => (
  <Option key={index} value={item.code} disabled={disabled}>
    {`${translations().paymentSolutions[item.code].title} (${translations().paymentSolutions[item.code].comment})`}
  </Option>
)

export const SelectPaymentSolutionForm = observer(
  ({ store, form, storeForm, isBundlesLoading }: SelectPaymentSolutionFormProps) => {
    const { resetFields, getFieldValue, setFieldsValue } = form
    const companyType = store.dataStore.application?.companyProfile.companyType

    return (
      <div className={styles.container}>
        <FormSubgroupTitle>{translations().title}</FormSubgroupTitle>
        <Form.Item
          name='paymentSolutionView'
          rules={[{ required: true, message: translations().required }]}
          validateTrigger={['onChange', 'onBlur']}
        >
          <Select
            placeholder={translations().productPlaceholder}
            onChange={e => {
              resetFields(['posModel', 'storeWebSite', 'posCount'])
              store.productStore.setProductAddMessage('')
              if (['pos'].includes(e)) {
                form.setFieldsValue({
                  zashEpos: false,
                  zashDigital: null
                })
              } else {
                form.setFieldsValue({
                  zashEpos: null,
                  zashDigital: false
                })
              }
            }}
          >
            {store.onboardingSettings?.paymentSolutionSettings?.map((item, indx) =>
              renderProductOptions(item, indx, !item?.value.includes('pos') && store.dataStore.isFastTrack)
            )}
          </Select>
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {t =>
            store.onboardingSettings?.bankDetailScheme !== 'EU' &&
            (t.getFieldValue('paymentSolutionView') === PaymentSolutionView.POS ||
              t.getFieldValue('paymentSolutionView') === PaymentSolutionView.POS_MOTO) && (
              <>
                <Form.Item label={translations().zashEpos} name={'zashEpos'} valuePropName={'checked'}>
                  <Switch />
                </Form.Item>

                <span>{translations().contractType}:</span>
                <Form.Item
                  name='terminalType'
                  rules={[{ required: true, message: translations().required }]}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Select
                    onSelect={val => val === 'subscription' && form.setFieldsValue({ chargeType: 'byDNA' })}
                    onChange={() => {
                      form.resetFields(['chargeType', 'subscriptionServicePeriod', 'freeSubscriptionPeriod'])
                    }}
                    options={terminalTypes}
                  />
                </Form.Item>

                <Form.Item shouldUpdate noStyle>
                  {t =>
                    store.productStore.showChargeType(form) && (
                      <>
                        <Form.Item name='chargeType'>
                          <Radio.Group
                            value='byDNA'
                            onChange={e => store.productStore.handleChargeTypeChange(e.target.value, form)}
                          >
                            <Radio value={'byDNA'}>{translations().byDNA}</Radio>
                            {t.getFieldValue('terminalType') === 'subscription' && (
                              <Radio value={'byPaytek'}>{translations().byPaytek}</Radio>
                            )}
                            <Radio value={'byOptomany'}>{translations().byOptomany}</Radio>
                          </Radio.Group>
                        </Form.Item>
                        {t.getFieldValue('terminalType') === 'subscription' && (
                          <>
                            <span>{translations().subscriptionServicePeriod}:</span>
                            <Form.Item
                              name='subscriptionServicePeriod'
                              rules={[{ required: true, message: translations().required }]}
                              validateTrigger={['onChange', 'onBlur']}
                            >
                              <Select
                                options={
                                  form.getFieldValue('chargeType') === 'byPaytek'
                                    ? subscriptionServicePeriodOptionsPaytek
                                    : subscriptionServicePeriodOptionsDNA
                                }
                              />
                            </Form.Item>

                            <span>{translations().freeSubscriptionPeriod}:</span>
                            <Form.Item
                              name='freeSubscriptionPeriod'
                              rules={[{ required: true, message: translations().required }]}
                              validateTrigger={['onChange', 'onBlur']}
                            >
                              <InputNumber
                                disabled={store.productStore.isPaytek}
                                max={12}
                                min={0}
                                className={styles.styledNumberInput}
                              />
                            </Form.Item>
                          </>
                        )}
                      </>
                    )
                  }
                </Form.Item>
              </>
            )
          }
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {t =>
            (t.getFieldValue('paymentSolutionView') === PaymentSolutionView.POS ||
              t.getFieldValue('paymentSolutionView') === PaymentSolutionView.POS_MOTO) && (
              <div>
                <span>{translations().posModel}:</span>
                <Form.Item
                  name='posModel'
                  rules={[{ required: true, message: translations().posModelRequired }]}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Select
                    loading={store.isPosModelsLoading}
                    options={store.posModels}
                    onSelect={e => store.getBundlesForSelectedPos(e)}
                    onChange={() => resetFields(['posBundles'])}
                  />
                </Form.Item>
              </div>
            )
          }
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {t =>
            (t.getFieldValue('paymentSolutionView') === PaymentSolutionView.POS ||
              t.getFieldValue('paymentSolutionView') === PaymentSolutionView.POS_MOTO) &&
            t.getFieldValue('posModel') &&
            !isEmpty(store.bundleOptions) &&
            t.getFieldValue('terminalType') === 'subscription' && (
              <div>
                <span>{translations().posBundles}:</span>
                <Form.Item
                  name='posBundles'
                  rules={[{ required: true, message: translations().required }]}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Select
                    mode='multiple'
                    disabled={isBundlesLoading}
                    loading={isBundlesLoading}
                    options={store.bundleOptions}
                  />
                </Form.Item>
              </div>
            )
          }
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {t =>
            (t.getFieldValue('paymentSolutionView') === PaymentSolutionView.WEBSITE_PAYMENTS ||
              t.getFieldValue('paymentSolutionView') === PaymentSolutionView.WEBSITE_PAYMENTS_MOTO ||
              t.getFieldValue('paymentSolutionView') === PaymentSolutionView.PAY_BY_LINK ||
              t.getFieldValue('paymentSolutionView') === PaymentSolutionView.PAY_BY_LINK_MOTO) && (
              <>
                <Form.Item label={translations().zashDigital} name={'zashDigital'} valuePropName={'checked'}>
                  <Switch />
                </Form.Item>
              </>
            )
          }
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {t =>
            (t.getFieldValue('paymentSolutionView') === PaymentSolutionView.WEBSITE_PAYMENTS ||
              t.getFieldValue('paymentSolutionView') === PaymentSolutionView.WEBSITE_PAYMENTS_MOTO) && (
              <>
                <Form.Item>
                  <span>{translations().storeWebSite}</span>
                  <Form.Item
                    name='storeWebSite'
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        message: translations().webSiteRequired
                      }
                    ]}
                  >
                    <Input placeholder={'www.storewebsite.com'} />
                  </Form.Item>
                </Form.Item>
              </>
            )
          }
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {t =>
            (t.getFieldValue('paymentSolutionView') === PaymentSolutionView.POS ||
              t.getFieldValue('paymentSolutionView') === PaymentSolutionView.POS_MOTO) && (
              <>
                <Form.Item>
                  <span>{translations().posQuantity}</span>
                  <Form.Item
                    name='posCount'
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        message: translations().posCountRequired
                      },
                      {
                        type: 'number',
                        min: 1,
                        max: 100,
                        message: translations().posCountError
                      }
                    ]}
                  >
                    <InputNumber
                      min={1}
                      className={styles.styledNumberInput}
                      placeholder={translations().deviceAmountPlaceholder}
                    />
                  </Form.Item>
                </Form.Item>

                <Form.Item>
                  <FormSubgroupTitle>{translations().posDeliveryAddress}</FormSubgroupTitle>
                  <Divider />
                  <Form.Item name='posDeliveryAddressType'>
                    <Radio.Group
                      value='manual'
                      onChange={e => {
                        form.resetFields(['posDeliveryAddress'])
                        const val = e.target.value
                        if (val === 'sameAsStoreAddress') {
                          form.setFieldsValue({ posDeliveryAddress: storeForm.getFieldValue('storeAddress') })
                        }

                        if (val === 'sameAsCompanyAddress') {
                          form.setFieldsValue({
                            posDeliveryAddress: {
                              country: store.dataStore.application?.companyProfile?.companyAddress?.country,
                              postalCode: store.dataStore.application?.companyProfile?.companyAddress?.postalCode,
                              addressLine1: store.dataStore.application?.companyProfile?.companyAddress?.addressLine1,
                              addressLine2: store.dataStore.application?.companyProfile?.companyAddress?.addressLine2,
                              townOrCity: store.dataStore.application?.companyProfile?.companyAddress?.locality
                            }
                          })
                        }
                      }}
                    >
                      <Radio value={'manual'}>{translations().manual}</Radio>
                      {(companyType === 'company' || companyType === 'llp') && (
                        <Radio value={'sameAsCompanyAddress'}>{translations().sameAsCompany}</Radio>
                      )}
                      <Radio value={'sameAsStoreAddress'}>{translations().sameAsStore}</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Row gutter={[32, 0]}>
                    <Col xs={24}>
                      <Form.Item
                        name={['posDeliveryAddress', 'country']}
                        label={translations().countryLabel}
                        rules={[
                          {
                            required: true,
                            message: translations().countryRequired
                          }
                        ]}
                        validateTrigger={['onChange', 'onBlur']}
                      >
                        <Input disabled />
                      </Form.Item>

                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.posDeliveryAddress?.country !== curValues.posDeliveryAddress?.country
                        }
                      >
                        {() => (
                          <Form.Item
                            name={['posDeliveryAddress', 'postalCode']}
                            label={translations().postalCodeLabel}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                              return e.currentTarget.value?.toLocaleUpperCase()
                            }}
                            rules={[
                              {
                                required: true,
                                message: translations().postalCodeRequired
                              },
                              {
                                validator: async (rule, value) => {
                                  const isUK = isCountryUK(getFieldValue(['posDeliveryAddress', 'country']))
                                  if (value && isUK && !validatePostalCodeOfUK(value)) {
                                    throw new Error(translations().postalCodeInvalid)
                                  }
                                }
                              }
                            ]}
                            validateTrigger={['onChange', 'onBlur']}
                          >
                            <PostalCode
                              disabled={form.getFieldValue('posDeliveryAddressType') !== 'manual'}
                              isUK={isCountryUK(getFieldValue(['posDeliveryAddress', 'country']))}
                              onAddressSelect={a => {
                                const building = a.building_name || a.building_number
                                setFieldsValue({
                                  posDeliveryAddress: {
                                    addressLine1: a.line_1,
                                    addressLine2: `${a.line_2} ${a.line_3 ? ', ' + a.line_3 + ',' : ''} ${a.line_4}`,
                                    townOrCity: a.town_or_city,
                                    region: a.county
                                  }
                                })
                              }}
                            />
                          </Form.Item>
                        )}
                      </Form.Item>

                      <Form.Item
                        name={['posDeliveryAddress', 'addressLine1']}
                        label={translations().addressLine1Label}
                        rules={[
                          {
                            required: true,
                            message: translations().addressLine1Required
                          }
                        ]}
                        validateTrigger={['onChange', 'onBlur']}
                      >
                        <Input disabled={form.getFieldValue('posDeliveryAddressType') !== 'manual'} />
                      </Form.Item>

                      <Form.Item name={['posDeliveryAddress', 'addressLine2']} label={translations().addressLine2Label}>
                        <Input disabled={form.getFieldValue('posDeliveryAddressType') !== 'manual'} />
                      </Form.Item>

                      <Form.Item name={['posDeliveryAddress', 'townOrCity']} label={translations().cityLabel}>
                        <Input
                          disabled={form.getFieldValue('posDeliveryAddressType') !== 'manual'}
                          onChange={e => form.setFieldsValue({ systemStoreAddress: { townOrCity: e.target.value } })}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider />
                </Form.Item>

                <Form.Item>
                  <span>{translations().deliveryInstructions}</span>
                  <Form.Item name='posDeliveryInstructions'>
                    <Input.TextArea placeholder={translations().deliveryInstructionsPlaceholder} />
                  </Form.Item>
                </Form.Item>
                <PhoneNumberFormItem
                  name='posDeliveryNumber'
                  label={translations().deliveryContactNumber}
                  initialValue={store.dataStore.application?.companyProfile?.telephoneNumber}
                  pattern={store.onboardingSettings?.phonePattern}
                  prefix={store.onboardingSettings?.phoneCode}
                />
              </>
            )
          }
        </Form.Item>

        <Form.Item shouldUpdate>
          {t => (
            <div className={styles.addTerminalBtn}>
              <CustomButton
                disabled={!t.getFieldValue('paymentSolutionView')}
                type='primary'
                onClick={async () => {
                  await form.validateFields()
                  store.productStore.addProduct(form, store.bundlesForSelectedPos)
                }}
              >
                {translations().addProduct}
              </CustomButton>
            </div>
          )}
        </Form.Item>

        <CustomModal
          title={translations().attention}
          open={store.productStore.showPopup}
          onOk={() => {
            store.productStore.setShowPopup(false)
          }}
          footer={
            <CustomButton onClick={() => store.productStore.setShowPopup(false)}>{translations().confirm}</CustomButton>
          }
        >
          {translations().byOptomanyDetails}
        </CustomModal>
      </div>
    )
  }
)
