import React from 'react'
import moment from 'moment'
import translations from '~/code/pages/BPMProcesses/Requests/components/SubmittedRequests/translations'

const toTime = (value: number, unit: string) => (value ? `${value} ${unit}${value > 1 ? 's' : ''}` : '')

export const reviewTime = (reviewMinutes: number) => {
  if (reviewMinutes === 0) {
    return <div className='nowrap'>{translations().lessThanOneMinute} </div>
  }
  if (!reviewMinutes) return ''
  const obj = moment.duration(reviewMinutes, 'minutes')
  const days = Math.floor(obj.asDays())
  const hours = obj.hours()
  const minutes = obj.minutes()

  const result = [toTime(days, 'day'), toTime(hours, 'hour'), toTime(minutes, 'minute')]
    .filter(v => Boolean(v))
    .map((v, i) => (
      <span key={i} className='nowrap'>
        {v}&nbsp;
      </span>
    ))

  return <div className='nowrap'>{result}</div>
}
