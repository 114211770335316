import { Card, List, Avatar, Select } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { withAppStore } from '~/code/hocs/withAppStore'
import { BpmAnalyticsStageModel, OnboardingTabEnum } from '../../models'
import { Onboarding } from '../../Onboarding'
import { getTime, round } from './services/utils'
import { BpmAnalyticsHeader, GaugeChart, Carousel, SubStatuses } from './components'
import { BpmAnalyticsProps } from './props'
import styles from './BpmAnalytics.scss'
import translations from './translations'

const AnalyticsView = observer((props: BpmAnalyticsProps) => {
  const { store, handbooksStore } = props

  useEffect(() => {
    if (!store.isInitiated) {
      store.init()
    }
  }, [])

  return <Onboarding activeTab={OnboardingTabEnum.Analytics} store={store}>
    <BpmAnalyticsHeader store={store} handbooksStore={handbooksStore} />

    <List<BpmAnalyticsStageModel>
      rowKey='name'
      className={styles.cardList}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 2,
        xl: 3,
        xxl: 4
      }}
      dataSource={store.data}
      renderItem={(item, index) => {
        const hasData = item.subStatuses.length > 0
        const percent = round(item.target * 100 / item.avgReviewTime)

        return (
          <List.Item key={item.name}>
            <Card loading={store.isLoading}>
              <Card.Meta
                avatar={<Avatar className={styles.avatar} size='small'>{index + 1}</Avatar>}
                title={item.description}
              />
              <div className={styles.clear} />
              <Carousel
                slides={[
                  <>
                    <div className={styles.list}>
                      <div className={styles.item}>
                        <div className={styles.label}>{index > 0 ? translations().avgReviewTime : translations().avgDurationTime}</div>
                        <div className={styles.value}>{hasData ? <RenderTime minutes={item.avgReviewTime} /> : <Empty />}</div>
                      </div>
                      <div className={styles.item}>
                        <div className={styles.label}>{translations().target}</div>
                        <div className={styles.value}>{hasData ? <RenderTime minutes={item.target} /> : <Empty />}</div>
                      </div>
                      <div className={styles.item}>
                        <div className={styles.label}>{translations().performance}</div>
                        <div className={styles.value}>{item.avgReviewTime ? <>{percent} <span className={styles.unit}>%</span></> : <Empty />}</div>
                      </div>
                    </div>
                    <GaugeChart
                      data={item.gaugeData}
                      needleValue={hasData ? item.count : null}
                      unit={translations().applications}
                    />
                  </>,
                  <SubStatuses list={item.subStatuses} />
                ]}
              />
            </Card>
          </List.Item>
        )
      }}
    />
  </Onboarding>
})

const Empty = () => <div className={styles.empty}>-</div>

const RenderTime: React.FC<{ minutes: number }> = ({ minutes }) => {
  const { value, unit } = getTime(minutes, true)
  return <>{ round(value) } <span className={styles.unit}>{unit}</span></>
}

export const BpmAnalytics = withAppStore(({ appStore: appStore }) => <AnalyticsView store={appStore.onboardingBpmAnalyticsStore} handbooksStore={appStore.handbooksStore} />)
