import translations from './translations'

translations.add('en', {
  ecoSpendError: 'Error while getting ecospend status',
  errorUploadingDocument: 'Error uploading the document',
  errorDeletingDocument: 'Error deleting the document',
  errCompletingApplication: 'Error completing application',
  applicationSuccessful: 'Application successfully completed',
  errTerminatingApplication: 'Error terminating application',
  applicationTerminationSuccess: 'Application successfully terminated',
  requestFail: 'Request has not been submitted!',
  requestSuccess: 'Request has been submitted!'
})
