import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  courierServiceUsed: string
  deliveryTime: string
  isTrue: string
  quarter1: string
  quarter2: string
  quarter3: string
  quarter4: string
  face2face: string
  seasonalBusiness: string
  yes: string
  no: string
}>()
