import { message } from 'antd'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { inject, injectable } from 'inversify'
import { IChangeBankDetailsDossierV2Store } from '~/code/pages/BPMProcesses/Requests/components/ChangeBankDetailsDossierV2/IChangeBankDetailsDossierV2Store'
import { ChangeBankDetailsDossierV2StoreSymbol } from '~/code/pages/BPMProcesses/Requests/components/ChangeBankDetailsDossierV2'
import { FileKey } from '~/code/pages/BPMProcesses/Requests/components/ChangeBankDetailsDossierV2/IChangeBankDetailsDossierV2Store'
import { CheckedDocumentType } from 'startapp/models/DocumentsRequiredModel'
import { deleteDocument, uploadDocument } from 'startapp/components/SupportingDocuments/services'
import { camelToKebabCase } from 'startapp/services'
import { UploadFile } from 'antd/lib/upload/interface'
import { MerchantsManagementStoreSymbol } from '~/code/pages'
import { MerchantsManagementStore } from '~/code/stores/MerchantsManagementStore/MerchantsManagementStore'
import { UploadDocumentRes } from 'startapp/components/SupportingDocuments/models'
import { uploadAdditionalDoc } from './services'
import { availableDocumentTypes } from '~/code/pages/MerchantsManagement/components/Stores/components/ChangeBankDetailsModal/constants'
import { IChangeBankDetailsDrawerTitleStore } from '~/code/pages/BPMProcesses/Requests/components/ChangeBankDetailsDossierV2/components/ChangeBankDetailsDrawer/IChangeBankDetailsDrawerTitleStore'
import { DocumentItem } from '~/code/pages/BPMProcesses/Requests/components/ChangeBankDetailsDossierV2/models'
import translations from './translations'

@injectable()
export class ChangeBankDetailsDrawerTitleStore implements IChangeBankDetailsDrawerTitleStore {
  changeBankDetailsDossierV2Store: IChangeBankDetailsDossierV2Store
  merchantsManagementStore: MerchantsManagementStore
  isDocumentLoading: boolean
  isLoading: boolean
  showUploadModal: boolean
  documentsMap: Record<FileKey, UploadFile[]> = {
    bankStatement: [],
    processingStatement: [],
    other: []
  }
  documentTypes: FileKey[] = []
  documentsData: UploadDocumentRes[] = []
  isNoDocumentsError: boolean

  constructor(
    @inject(ChangeBankDetailsDossierV2StoreSymbol) changeBankDetailsDossierV2Store: IChangeBankDetailsDossierV2Store,
    @inject(MerchantsManagementStoreSymbol) merchantsManagementStore: MerchantsManagementStore
  ) {
    this.changeBankDetailsDossierV2Store = changeBankDetailsDossierV2Store
    this.merchantsManagementStore = merchantsManagementStore
    this.isDocumentLoading = false
    this.isLoading = false
    this.showUploadModal = false
    this.documentTypes = []

    this.isNoDocumentsError = false

    makeObservable(this, {
      isDocumentLoading: observable,
      documentsMap: observable,
      documentTypes: observable,
      isLoading: observable,
      showUploadModal: observable,
      isNoDocumentsError: observable,

      availableDocumentTypeOptions: computed,

      setNoDocumentsError: action,
      addDocumentType: action,
      setDocumentsMap: action,
      setUploadModal: action,
      clearData: action
    })
  }

  get availableDocumentTypeOptions(): DocumentItem[] {
    return availableDocumentTypes.filter(
      type => this.changeBankDetailsDossierV2Store.processDetails?.requiredDocuments[type.value]
    )
  }

  setUploadModal(value: boolean) {
    this.showUploadModal = value
  }

  setNoDocumentsError(value: boolean) {
    this.isNoDocumentsError = value
  }

  addDocumentTypesArray(value: FileKey) {
    if (this.documentTypes.includes(value)) return
    this.documentTypes.push(value)
  }

  setDocumentsMap(fileKey: FileKey, fileList: UploadFile[]) {
    this.documentsMap[fileKey] = fileList
  }

  addDocumentType(fileKey: FileKey, fileList: UploadFile[]) {
    this.setDocumentsMap(fileKey, [...this.documentsMap[fileKey], ...fileList])
    this.addDocumentTypesArray(fileKey)
  }

  removeDocument(fileKey: FileKey, fileList: UploadFile[]) {
    this.setDocumentsMap(fileKey, fileList)
    if (fileList.length === 0) this.removeDocumentType(fileKey)
  }

  removeDocumentType(value: FileKey) {
    this.documentTypes = this.documentTypes.filter(doc => doc !== value)
  }

  clearData() {
    this.documentsData = []
    this.documentTypes = []
    this.documentsMap = {
      bankStatement: [],
      processingStatement: [],
      other: []
    }
  }

  onFormFinish = async values => {
    if (!this.documentsData.length && this.availableDocumentTypeOptions.length) {
      this.setNoDocumentsError(true)
      return
    }

    const data = {
      comment: values.comment,
      documents: this.documentsData.map(document => ({
        path: document.path,
        name: document.fileName,
        type: document.type
      }))
    }

    try {
      runInAction(() => {
        this.isLoading = true
      })
      const { status, error } = await uploadAdditionalDoc(this.changeBankDetailsDossierV2Store.selectedProcessId, data)

      if (status === 200) {
        message.success(translations().requestSuccess)
        this.setUploadModal(false)
        this.changeBankDetailsDossierV2Store.closeInfoDrawer()
        this.changeBankDetailsDossierV2Store.loadProcesses()
      } else {
        throw new Error(error.message || translations().requestFail)
      }
    } catch (error) {
      message.error(error.message || translations().requestFail)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  async uploadFile(documentType: FileKey | CheckedDocumentType, file: File): Promise<boolean> {
    if (!file) return false

    let isUploaded: boolean

    try {
      runInAction(() => {
        this.isDocumentLoading = true
      })

      const { status, error, result } = await uploadDocument({
        companyName: this.changeBankDetailsDossierV2Store.processDetails?.companyName,
        companyNumber: this.changeBankDetailsDossierV2Store.processDetails?.companyNumber,
        acquisitionChannel: this.changeBankDetailsDossierV2Store.processDetails?.acquisitionChannel,
        documentType: camelToKebabCase(documentType),
        file
      })
      if (status !== 200 || error || !result || result.length <= 0) {
        message.error(error.message || translations().errorUploadingDocument)
        isUploaded = false
      } else {
        runInAction(() => {
          this.documentsData.push({ ...result[0], internalFileName: file.name, type: documentType })
        })
        this.setNoDocumentsError(false)
        isUploaded = true
      }
    } catch (error) {
      message.error(translations().errorUploadingDocument)
      isUploaded = false
    }

    runInAction(() => {
      this.isDocumentLoading = false
    })

    return isUploaded
  }

  async deleteFile(documentType: FileKey | CheckedDocumentType, fileName: string): Promise<boolean> {
    let isDeleted: boolean
    try {
      const path = this.documentsData.find(d => d?.internalFileName === fileName && d?.type === documentType)?.path
      if (path) {
        const { status, error } = await deleteDocument(path)
        if (status !== 200 || error) {
          message.error(error.message || translations().errorDeletingDocument)
          isDeleted = false
        } else {
          isDeleted = true
        }
      }
    } catch (error) {
      message.error(translations().errorDeletingDocument)
      isDeleted = false
    }
    return isDeleted
  }
}
