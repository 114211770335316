import React from 'react'
import { Card, Divider } from 'antd'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { Page } from '~/code/pages'
import { isPartner } from '~/code/services/auth'
import { AcquiringFilter, TransactionsStatementFilter } from '../../components'
import { TransactionsStatement } from './components'
import styles from './Statement.scss'
import { ITransactionStatementStore } from './models'
import { StatementExportsModal } from '~/code/pages/Acquiring/pages/Statement/components/StatementExportsModal/StatementExportsModal'

export const MonitoringStoreSymbol = {
    dna: Symbol('MonitoringStoreDnaEcom'),
    optomany: Symbol('MonitoringStoreOptomanyEcom')
}

export const Statement = observer((props: {
    transactionsStatementStoreSymbol: symbol,
    acquiringFilterStoreSymbol: symbol,
    merchantSelectStoreSymbol: symbol
}) => {
    const { transactionsStatementStoreSymbol, acquiringFilterStoreSymbol, merchantSelectStoreSymbol }  = props
    const transactionsStatementStore = useInjection<ITransactionStatementStore>(transactionsStatementStoreSymbol)

    const isFilterVisible = !isPartner()

    return <Page>
        <StatementExportsModal
            transactionsStatementStore={transactionsStatementStore}
        />
        <Card className={styles.DNAStatement}>
            <AcquiringFilter
                storeSymbol={acquiringFilterStoreSymbol}
                merchantSelectStoreSymbol={merchantSelectStoreSymbol}
            />
            { isFilterVisible &&
                <>
                    <Divider/>
                    <div className={styles.transactionStatementFilterWrapper}>
                        <TransactionsStatementFilter
                            withExportsModal={true}
                            store={transactionsStatementStore}
                            handbooksStore={transactionsStatementStore.handbooksStore}
                        />
                    </div>
                </>
            }
        </Card>
        <TransactionsStatement store={transactionsStatementStore} />
    </Page>
})
