import React from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Modal, Form, Table, Button } from 'antd'
import { TipJarModalProps } from './props'
import { ITipJarModalStore } from './ITipJarModalStore'
import { TipJarTableColumns } from './constants'
import translations from './translations'
import styles from './TipJarModal.scss'

export const TipJarModalStoreSymbol = Symbol('TipJarModalStore')
export const TipJarModal = observer((props: TipJarModalProps) => {
    const store = useInjection<ITipJarModalStore>(TipJarModalStoreSymbol)
    const [form] = Form.useForm()

    const onFinish = async () => store.setTipJarConfiguration(form)
    
    return (
        <> 
            <Modal
                width={'70%'}
                open={props.open}
                onCancel={() => store.closeModal(form)}
                footer={[
                    <Button
                        key='cancel'
                        onClick={() => store.closeModal(form)}
                    >
                        {translations().cancel}
                    </Button>,
                    <Button
                        key='submit'
                        type='primary'
                        loading={store.isSetTipJarConfigurationLoading}
                        onClick={() => form.submit()}
                    >
                        {translations().save}
                    </Button>
                ]}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    {
                        store.displayErrorMessage ?
                        <div className={styles.errorMessage}>{translations().errorMessage}</div> : ''
                    }
                    <Table
                        size='small'
                        bordered={false}
                        columns={TipJarTableColumns}
                        dataSource={store.tipJartableData}
                        pagination={false}
                    />
                </Form>
            </Modal>
        </>
    )
})
