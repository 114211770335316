import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Drawer, DrawerProps, Form, Input, Grid, Row, Col, Space, Button } from 'antd'
import { PortalAccessStoreType } from '~/code/pages/PortalAccess/types/PortalAccessStoreType'
import { PortalAccessStoreSymbol } from '~/code/pages/PortalAccess/'

import translations from './translations'
import styles from './styles.scss'

const { useBreakpoint } = Grid

export type PortalAccessUserEditDrawer = DrawerProps

export const PortalAccessUserEditDrawer = observer((props: PortalAccessUserEditDrawer) => {
  const { open, ...rest } = props
  const [form] = Form.useForm()
  const screens = useBreakpoint()

  const store = useInjection<PortalAccessStoreType>(PortalAccessStoreSymbol)
  const { selectedTableItem, isLoginsSending, closeUserEditDrawer } = store

  useEffect(() => form.resetFields(), [open])

  const renderFooter = () => (
    <div className={styles.footer}>
      <Button onClick={() => closeUserEditDrawer()}>{translations().cancel}</Button>
      <Button htmlType='button' type='primary' loading={isLoginsSending} onClick={() => form.submit()}>
        {translations().submit}
      </Button>
    </div>
  )

  return (
    <Drawer
      title={translations().edit}
      open={open}
      width={screens.xs ? window.innerWidth : 512}
      footer={renderFooter()}
      {...rest}
    >
      <Form
        form={form}
        requiredMark={false}
        labelAlign='left'
        labelCol={{ span: 6 }}
        onFinish={v => store.updateUser({ ...v, publicId: selectedTableItem.publicId })}
      >
        <Form.Item
          name='login'
          label={translations().formFields.email}
          initialValue={selectedTableItem?.email}
          rules={[
            { type: 'email', message: translations().formErrors.enterValidEmail },
            { required: true, message: translations().formErrors.emailRequired }
          ]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name='firstName'
          label={translations().formFields.firstName}
          initialValue={selectedTableItem?.firstName}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='lastName'
          label={translations().formFields.lastName}
          initialValue={selectedTableItem?.lastName}
        >
          <Input />
        </Form.Item>
      </Form>
    </Drawer>
  )
})
