import React, { PropsWithChildren } from 'react'
import translations from './translations'
import styles from './NextButton.scss'
import { NextButtonProps } from './props'
import classNames from 'classnames'
import { CustomButton } from '~/code/components'

export const NextButton: React.FC<PropsWithChildren<NextButtonProps>> = props => {
  const { disabled, loading, onClick, children, position = 'end', htmlType } = props
  return (
    <div className={classNames(styles.container, position === 'start' && styles.flexStart)}>
      <CustomButton
        htmlType={htmlType}
        disabled={disabled}
        className={styles.button}
        loading={loading}
        onClick={onClick}
      >
        {children || translations().next}
      </CustomButton>
    </div>
  )
}
