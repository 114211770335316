import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { OfficerRole } from '~/code/pages/StartProcess/models'
import translations from './translations'
import { DirectorsProps } from './props'
import { StartProcessStoreContext } from '~/code/pages/StartProcess/StartProcessStore'
import { ListPage } from '../../../ListPage'
import {
  isCompanyOfficerValid,
  isCompanyOfficerValidWithoutChecks,
  isOfficerVerificationResultPassed
} from '~/code/pages/StartProcess/services'
import { isDirectorsValid } from '~/code/pages/StartApplication/services'

export const Directors = observer((props: DirectorsProps) => {
  const { directors, selectOfficer, deleteOfficer, onContinue, isSaveLoading, onSave, settings } = props
  const store = useContext(StartProcessStoreContext)
  const count = store.dataStore.application.companyProfile?.directorsPartnersCount

  return (
    <ListPage
      title={translations().title}
      text={`${translations().text} ${count ? translations().mustBe(count) : ''} `}
      list={
        directors &&
        directors.map(d => ({
          name: d.nameElements?.forename,
          surname: d.nameElements?.surname,
          isCompleted:
            settings?.addressAndIDCheck === 'none' ? isCompanyOfficerValidWithoutChecks(d) : isCompanyOfficerValid(d),
          onClick: () => selectOfficer(d),
          onRemove: () => deleteOfficer(d),
          isRemoveDisabled: d.fromApi,
          agreementSignatory: d.agreementSignatory,
          officerVerificationStatus:
            settings?.addressAndIDCheck === 'none'
              ? null
              : isOfficerVerificationResultPassed(d.officerVerificationResult, d.jumioCheckResults)
              ? translations().verified
              : translations().nonVerified,
          manuallyDocs: d.uploadDocManually
        }))
      }
      onAdd={() => selectOfficer({ officerRole: OfficerRole.director })}
      isSaveLoading={isSaveLoading}
      onSave={onSave}
    />
  )
})
