import translations from './translations'

translations.add('en', {
  title: 'Specific permissions settings',
  permissions: {
    'settlements.download': 'Settlements Download',
    'settlements.test.download': 'Settlements Download (Test)',
    'settlements.v2.download': 'Settlements V2 Download',
    'invoices.download': 'Invoices Download',
    'netsuite_invoices.download': 'NetSuite Invoices Download'
  }
})
