import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Select, Input, InputNumber, Button, Row, Col } from 'antd'
import { FormSubgroupTitle } from '../shared/FormSubgroupTitle'
import translations from './translations'
import { CompanyNameFormProps } from './props'
import styles from './CompanyNameForm.scss'
import { CompanyProfile } from '../../models'
import { StartProcessStoreContext } from '../../StartProcessStore'
import { CustomButton } from '~/code/components'

export const CompanyNameForm = observer((props: CompanyNameFormProps) => {
  const store = useContext(StartProcessStoreContext)
  const { form } = props
  const { companyNameFormStore, onboardingSettings } = store
  const { company, companies, activeProcessCheckPassed, isActiveProcessCheckLoading } = companyNameFormStore
  const { resetFields } = form
  const [dirCount, setDirCount] = useState(0)
  const isViewMode = Boolean(store.dataStore.applicationId)

  useEffect(() => {
    form.validateFields(['companyNumber'])
  }, [activeProcessCheckPassed, company])

  useEffect(() => {
    const cnt = store.dataStore.application.companyOfficerList.filter(item => item.officerRole === 'director').length
    setDirCount(cnt)

    if (cnt > 0) {
      form.setFieldsValue({
        directorsPartnersCount: cnt
      })
    }
  }, [store.dataStore.application.companyOfficerList])

  const setCompanyNameData = () => {
    const values = form.getFieldsValue()
    values !== undefined && store.completeApplicationStore.updateCompanyProfile(values as CompanyProfile)
  }

  const handleEntityTypeChange = async () => {
    store.reset()
    store.dataStore.resetApplication()
    resetFields(['companyNumber', 'directorsPartnersCount', 'charityNumber', 'charityComission'])
    setCompanyNameData()
  }

  const entityTypeOptions = [
    { value: 'company', label: translations().limitedCompany },
    { value: 'sole-trader', label: translations().soleTrader },
    { value: 'partnership', label: translations().partnerShip },
    { value: 'charity', label: translations().charity },
    { value: 'llp', label: translations().llp },
    { value: 'other', label: translations().other }
  ]

  return (
    <Form form={form} layout='vertical' onValuesChange={() => setCompanyNameData()}>
      <FormSubgroupTitle>{translations().title}</FormSubgroupTitle>

      <Form.Item
        name='companyType'
        rules={[{ required: true, message: translations().companyTypeRequired }]}
        validateTrigger={['onChange', 'onBlur']}
      >
        <Select
          disabled={isViewMode}
          placeholder={translations().legalEntityPlaceholder}
          onChange={handleEntityTypeChange}
          options={entityTypeOptions.map(option => ({
            value: option.value,
            label: <span>{option.label}</span>
          }))}
        />
      </Form.Item>

      <Form.Item noStyle shouldUpdate={(prevVal, curVal) => prevVal.companyType !== curVal.companyType}>
        {formInstance =>
          formInstance.getFieldValue('companyType') === 'other' && (
            <Form.Item
              name='otherDescription'
              label={translations().description}
              rules={[
                {
                  required: true,
                  message: translations().required
                }
              ]}
              validateFirst={true}
              validateTrigger={['onChange', 'onBlur']}
            >
              <Input.TextArea rows={2} placeholder={translations().otherDescription} />
            </Form.Item>
          )
        }
      </Form.Item>

      <div className={styles.companyContainer}>
        <Form.Item noStyle shouldUpdate={(prevVal, curVal) => prevVal.companyType !== curVal.companyType}>
          {formInstance =>
            (formInstance.getFieldValue('companyType') === 'company' ||
              formInstance.getFieldValue('companyType') === 'llp') && (
              <>
                <Form.Item
                  name='companyNumber'
                  label={
                    <>
                      {translations().companyNameFormLabel}
                      <span className={styles.subLabel}>{translations().companyNameFormSubLabel}</span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: translations().companyNameFormRequired
                    },
                    {
                      validator: async (rule, value) => {
                        if (company && company.companyStatus.toLowerCase() !== 'active') {
                          throw new Error(translations().companyIsNotActive(companyNameFormStore.company.companyStatus))
                        }
                        if (company && companyNameFormStore.activeProcessCheckPassed === 0) {
                          throw new Error(
                            translations().processAlreadyStarted(companyNameFormStore.company.companyNumber)
                          )
                        }
                        if (companyNameFormStore.activeProcessCheckPassed === 2) {
                          throw new Error(translations().errorCompanyCheck)
                        }
                      }
                    }
                  ]}
                  validateFirst={true}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  {onboardingSettings.checkCompanyNumberService !== 'companiesHouse' ? (
                    <Input disabled={isViewMode} />
                  ) : (
                    <Select
                      disabled={isViewMode}
                      showSearch
                      allowClear
                      suffixIcon={null}
                      filterOption={false}
                      placeholder={translations().typeSomethingToSearch}
                      onSearch={(text: string) => {
                        if (text) {
                          companyNameFormStore.searchCompanies(text.trim())
                        }
                      }}
                      onSelect={(value: string) => companyNameFormStore.selectCompany(value)}
                      onChange={(value: string) => {
                        if (!value) {
                          store.reset()
                        }
                      }}
                    >
                      {companies &&
                        companies.map(o => (
                          <Select.Option key={o.value} value={o.value}>
                            {o.label}
                          </Select.Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
                {/**TODO: Check required mark here*/}
                {companyNameFormStore.activeProcessCheckPassed === 2 && (
                  <Form.Item>
                    <CustomButton
                      loading={isActiveProcessCheckLoading}
                      onClick={() => companyNameFormStore.checkExistingCompany(company.companyNumber, company.email)}
                    >
                      {translations().tryAgain}
                    </CustomButton>
                  </Form.Item>
                )}
              </>
            )
          }
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {t =>
            (t.getFieldValue('companyType') === 'llp' ||
              t.getFieldValue('companyType') === 'company' ||
              t.getFieldValue('companyType') === 'partnership') && (
              <Form.Item
                shouldUpdate
                name='directorsPartnersCount'
                label={
                  t.getFieldValue('companyType') === 'llp' || t.getFieldValue('companyType') === 'company'
                    ? translations().directorsNumber
                    : translations().partnersNumber
                }
                rules={[{ required: true }]}
                validateTrigger='onBlur'
              >
                {store.companyNameFormStore.isCompanyInfoLoading === true ? (
                  <Button loading className={styles.styledNumberInput} />
                ) : (
                  <InputNumber min={dirCount} max={10} className={styles.styledNumberInput} />
                )}
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevVal, curVal) => prevVal.companyType !== curVal.companyType}>
          {t =>
            t.getFieldValue('companyType') === 'charity' && (
              <>
                <Form.Item
                  name='charityNumber'
                  label={translations().charityNumber}
                  rules={[{ required: true }]}
                  validateTrigger='onBlur'
                >
                  <InputNumber min={1} className={styles.styledNumberInput} />
                </Form.Item>
                <Form.Item
                  name='charityComission'
                  label={translations().charityComission}
                  rules={[{ required: true }]}
                  validateTrigger='onBlur'
                >
                  <InputNumber min={1} className={styles.styledNumberInput} />
                </Form.Item>
              </>
            )
          }
        </Form.Item>
      </div>
    </Form>
  )
})
