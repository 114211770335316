import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    merchants: string
    mcc: string
    cardTypes: string
    paymentMethods: string
    issuingBanks: string
    acquisitionChannels: string
    amount: string
    count: string
}>()
