import { inject, injectable } from 'inversify'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import {
  deleteTemplate,
  fetchTemplate,
  fetchTemplates
} from '~/code/stores/NotificationManagementStore/services/fetchers'
import { message } from 'antd'
import {
  NotificationSetupFormType,
  TemplatesRequestParamsType,
  TemplateType
} from '~/code/stores/NotificationManagementStore/models'
import { NotificationTemplatesFilterType } from '~/code/stores/NotificationManagementStore/models/NotificationTemplatesFilterType'
import { IManageNotificationTemplateStore } from '~/code/pages/Notifications'
import {
  ManageNotificationTemplateStoreSymbol
} from '~/code/pages/Notifications/ManageNotificationTemplates/components'
import { IManageNotificationTemplatesStore } from '~/code/pages/Notifications/ManageNotificationTemplates/INotificationTemplatesStore'
import { formatTemplateForm } from '~/code/stores/NotificationManagementStore/services/services'
import { INITIAL_FILTER_DATA } from '~/code/stores/NotificationManagementStore/constants'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'

@injectable()
export class NotificationTemplatesStore implements IManageNotificationTemplatesStore {
    isTemplatesLoading = false
    isDrawerOpened = false
    isDeletingModalOpen = false
    templates: TemplateType[] = []
    manageNotificationTemplateStore: IManageNotificationTemplateStore = null
    total: number = 0
    filter: NotificationTemplatesFilterType =  INITIAL_FILTER_DATA
    constructor(
      @inject(ManageNotificationTemplateStoreSymbol) manageNotificationTemplateStore : IManageNotificationTemplateStore
    ) {
      makeObservable(this, {
        manageNotificationTemplateStore: observable,
        templates: observable,
        isTemplatesLoading: observable,
        isDrawerOpened: observable,
        isDeletingModalOpen: observable,
        total: observable,
        filter: observable,
        setFilter: action.bound,
        resetFilter: action.bound,
        setNotificationForm: action.bound,
        setDrawer: action.bound,
        setDeletingModal: action.bound,
        newTemplate: action.bound,
        selectedTemplate: computed,
        previewTemplate: computed,
        isDashboard: computed,
        notificationSetupForm: computed
      })
      this.manageNotificationTemplateStore = manageNotificationTemplateStore
      this.loadTemplates()
  }

  get notificationSetupForm() {
    return this.manageNotificationTemplateStore.notificationSetupForm
  }

  get previewTemplate() {
    return this.manageNotificationTemplateStore.previewTemplate
  }

  get selectedTemplate() {
      return this.manageNotificationTemplateStore.selectedTemplate
  }

  get isDashboard() {
      return this.manageNotificationTemplateStore.isDashboard
  }

  public setFilter = (values: any, shouldLoad: boolean = false) => {
    this.filter = {
      ...this.filter,
      ...values
    }

    if (shouldLoad) this.loadTemplates()
  }

  public resetFilter = () => {
      this.filter = INITIAL_FILTER_DATA
      this.loadTemplates()
  }

  public clearSelectedTemplate = () => {
      this.manageNotificationTemplateStore.setNotificationSetupForm(null)
      this.manageNotificationTemplateStore.setSelectedTemplate(null)
  }

  public setNotificationForm = (form: TemplateType) => {
      const formData = formatTemplateForm(form)
      this.manageNotificationTemplateStore.setNotificationSetupForm(formData as NotificationSetupFormType)
  }

  public newTemplate = (step: number) => {
      this.setNotificationForm(null)
      this.manageNotificationTemplateStore.setStep(step)
      this.manageNotificationTemplateStore.setSelectedTemplate(null)
      this.manageNotificationTemplateStore.setNotificationSetupForm(null)
      goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_TEMPLATES_CREATE)
  }

  viewDetails = (id: string) => {
    this.getTemplateDetails(id).then(() => this.setDrawer(true))
  }

  getTemplateDetails = async (id: string) => {
    try {
      const { result } = await fetchTemplate(id)

      if (result) {
        runInAction(() => {
          this.manageNotificationTemplateStore.setSelectedTemplate(result)
          this.setNotificationForm(result)
        })
      }

    } catch (error) {
      message.error(error.message)
    }
  }

  public onDrawerClose = () => {
    this.setDrawer(false)
    this.setNotificationForm(null)
  }

  setDrawer = (value: boolean) => {
      this.isDrawerOpened = value
  }

  setDeletingModal = (value: boolean) => {
      this.isDeletingModalOpen = value
  }

  loadTemplates = async () => {
    const params = {
      ...this.filter,
      notificationTypeId: this.filter.notificationTypeId === 'all' ? undefined : this.filter.notificationTypeId,
      systemId: this.filter.systemId === 'all' ? undefined : this.filter.systemId,
      notificationDisplayTypeId: this.filter.notificationDisplayTypeId === 'all' ? undefined : this.filter.notificationDisplayTypeId,
    }

    runInAction(() => this.isTemplatesLoading = true)

    try {
      const { result } = await fetchTemplates(params as TemplatesRequestParamsType)
      if (result) runInAction(() => {
        this.templates = result.data
        this.total = result.totalCount
      })
    } catch (error) {
      message.error(error.message)
    } finally {
      runInAction(() => this.isTemplatesLoading = false)
    }
  }

  editTemplate = (id: string) => {
    this.manageNotificationTemplateStore.setStep(1)
    this.manageNotificationTemplateStore.loadTemplate(id)
      .then(() => {
        goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_TEMPLATES_EDIT, {id})
      })
  }

  deleteTemplate = async (id: string) => {
    try {
      const { result, error } = await deleteTemplate(id)

      if (result) {
        message.success(result['message'])
        this.loadTemplates()
      }

      if (error) throw new Error(error.message)
    } catch (error) {
      message.error(error.message)
    } finally {
      this.setDeletingModal(false)
    }
  }
}