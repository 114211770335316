import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    transactionVolume: string
    transactionCount: string
    netRevenue: string
    totalCommission: string
    order: string
    partner: string
    trVol: string
    netRev: string
    comVol: string
    selectPartner: string
    all: string
    tableInfo: string
    netRevMonthOnMonth: string
    selectMonthDate: string
    totalCommissions: string
    commissionsVolume: string
    ok: string
    transactionsVolumeMonth: string
    downloadDetailedReport: string
    downloadSummaryReport: string
    totalTransactions: string
}>()

