import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  emailAddress: string 
  searchByEmail: string 

  formErrors: {
    wrongEmailFormat: string 
  }
}>()
