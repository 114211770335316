import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    no: string
    yesTerminateProcess: string
    areYouSureTerminateProcess: string
    areYouSureTerminateProcesses: string
    seeProcess: string
    seeProcesses: string
    reasonForTerminating: string
}>()

