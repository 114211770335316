import React, { useEffect, useRef, useContext } from 'react'
import { Col, Form, Row, Select, Input as AntInput, Popover, Alert, Divider } from 'antd'
import moment from 'moment'
import merge from 'lodash/merge'
import { Input, PostalCode } from '~/code/components'
import { URL_PATTERN, CITY_TYPING_PATTERN } from '~/code/constants/Patterns'
import { scrollToFirstError } from '~/code/services/helpers'
import { CompanyProfile } from '../../models'
import { StartProcessStoreContext } from '../../StartProcessStore'
import { FormSubgroupTitle, PhoneNumberFormItem } from '../shared'
import { isCountryUK, isDateOfCreationValid, validatePostalCodeOfUK } from '../../services'
import { DNA_DOMAINS } from '../../services/constants'
import translations from './translations'
import { GeneralInformationFormProps } from './props'
import styles from './GeneralInformationForm.scss'


export const GeneralInformationForm: React.FC<GeneralInformationFormProps> = props => {
  const { form, obrCountry, companyType, data, onboardingSettings, obrCountries, isObrCountriesLoading } = props
  const store = useContext(StartProcessStoreContext)
  const isViewMode = Boolean(store.dataStore.applicationId)
  const companyMode = companyType === 'company' || companyType === 'llp'
  const isEuroClient = onboardingSettings?.bankDetailScheme === 'EU'

  const getInitialValues = () => {
    return convertDataToFormData(
      merge(new CompanyProfile(), data, {
        companyAddress: { country: obrCountry }
      })
    )
  }

  const companyProfileDataRef = useRef<CompanyProfile>(data)

  useEffect(() => {
    form.setFieldsValue(getInitialValues())
    companyProfileDataRef.current = data
  }, [data])

  const { getFieldsValue, getFieldValue, setFieldsValue } = form

  const convertDataToFormData = (initialData: CompanyProfile) => {
    const dt = initialData

    if (dt.directorsPartnersCount) {
      dt.employeesNumber = initialData?.directorsPartnersCount?.toString()
    }

    if (
      onboardingSettings?.checkCompanyNumberService === 'companiesHouse' &&
      companyMode &&
      initialData &&
      initialData.dateOfCreation
    ) {
      dt.dateOfCreation = moment(initialData.dateOfCreation, 'DD-MM-YYYY', true).isValid()
        ? moment(initialData.dateOfCreation, 'DD-MM-YYYY').format('YYYY / MM / DD')
        : moment(initialData.dateOfCreation, 'YYYY-MM-DD').format('YYYY / MM / DD')
    }
    return dt
  }

  // convert form data to company profile data
  const getFormData = () => {
    const values = getFieldsValue()
    return { ...values } as CompanyProfile
  }

  const emailPopoverContent = () => {
    return (
      <Alert
        type='warning'
        message={
          <>
            <p>{translations().attention}</p>
            <p>{translations().informText1}</p>
            <p>{translations().informText2}</p>
            <p>{translations().informText3}</p>
          </>
        }
      />
    )
  }

  return (
    <>
      <Form
        form={form}
        autoComplete='off'
        layout='vertical'
        className={styles.form}
        scrollToFirstError={scrollToFirstError}
        initialValues={getInitialValues()}
        onValuesChange={() =>
          store.completeApplicationStore.updateCompanyProfile((companyProfileDataRef.current = getFormData()))
        }
      >
        <Row gutter={[64, 10]}>
          <Col xs={24}>
            <FormSubgroupTitle>{translations().companyBlockTitle}</FormSubgroupTitle>

            <Popover content={emailPopoverContent()}>
              <Form.Item
                name='emailAddress'
                label={translations().emailAddressLabel}
                rules={[
                  { type: 'email', message: translations().emailFormatIncorrect },
                  { required: true, message: translations().emailAddressRequired },
                  {
                    validator: async (rule, value) => {
                      if (!value) {
                        return
                      }
                      const isDNADomain = Boolean(DNA_DOMAINS.some(d => value.toUpperCase().includes(d.toUpperCase())))
                      if (value && isDNADomain) {
                        throw new Error(translations().invalidDomain)
                      }
                    }
                  }
                ]}
                validateTrigger={['onBlur']}
                validateFirst={true}
              >
                <Input
                  disabled={isViewMode}
                  placeholder={translations().emailPlaceholder}
                  textTransform={'lowercase'}
                />
              </Form.Item>
            </Popover>

            <Form.Item
              name='companyName'
              label={translations().companyNameLabel}
              rules={[{ required: true, message: translations().companyNameRequired }]}
              validateTrigger='onBlur'
            >
              <Input
                disabled={
                  (onboardingSettings.checkCompanyNumberService === 'companiesHouse' && companyMode) || isViewMode
                }
              />
            </Form.Item>

            <Form.Item>
              <Form.Item
                name='companyTradeName'
                label={translations().companyTradeName}
                rules={[{ required: true, message: translations().required }]}
                validateTrigger='onBlur'
              >
                <Input disabled={isViewMode} />
              </Form.Item>
              <span className={styles.errMsgVerifyBankAccount}>{translations().tradeNameNote}</span>
            </Form.Item>

            {isEuroClient && (
              <Form.Item
                name='countryOfInitialRegistration'
                label={translations().countryOfInitialReg}
                rules={[
                  {
                    required: true,
                    message: translations().required
                  }
                ]}
                validateTrigger='onBlur'
              >
                <Select loading={isObrCountriesLoading} showSearch>
                  {obrCountries.map(option => (
                    <Select.Option key={option.iso} value={option.value}>
                      {option.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {companyMode && (
              <Form.Item
                name='companyNumber'
                label={translations().companyNumberLabel}
                rules={[
                  {
                    required: true,
                    message: translations().companyNumberRequired
                  }
                ]}
                validateTrigger='onBlur'
              >
                <Input disabled={true} />
              </Form.Item>
            )}

            <Form.Item
              name='companyWebsite'
              label={translations().companyWebsiteLabel}
              rules={[
                {
                  pattern: URL_PATTERN,
                  message: translations().companyWebsiteInvalid
                }
              ]}
              validateTrigger='onBlur'
            >
              <Input placeholder={'www.mywebsite.com'} textTransform={'lowercase'} />
            </Form.Item>

            <PhoneNumberFormItem
              label={translations().telephoneNumberLabel}
              requiredErrorMessage={translations().telephoneNumberRequired}
              invalidErrorMessage={translations().telephoneNumberWrongFormat}
              pattern={onboardingSettings?.phonePattern}
              prefix={onboardingSettings?.phoneCode}
            />

            <Form.Item
              name='dateOfCreation'
              label={translations().dateOfCreationLabel}
              rules={[
                {
                  required: true,
                  message: translations().dateOfCreationRequired
                },
                {
                  validator: async (rule, value) => {
                    if (!value) return

                    const formattedValue = moment(value, 'YYYY - MM - DD').format('YYYY-MM-DD')
                    if (!isDateOfCreationValid(formattedValue)) {
                      throw new Error(translations().dateOfCreationInvalid)
                    }
                  }
                }
              ]}
              validateTrigger='onBlur'
              wrapperCol={{ xs: 24, md: 10 }}
            >
              <Input
                type={'masked'}
                className={'ant-input'}
                mask={'9999 / 99 / 99'}
                placeholder={translations().datePlaceholder}
                disabled={onboardingSettings.checkCompanyNumberService === 'companiesHouse' && companyMode}
              />
            </Form.Item>

            <Form.Item
              name='employeesNumber'
              label={translations().employeesNumberLabel}
              rules={[
                {
                  required: true,
                  message: translations().employeesNumberRequired
                }
              ]}
              validateTrigger={['onChange', 'onBlur']}
            >
              <Select allowClear>
                {Object.keys(translations().employeesNumberOptions).map(key => (
                  <Select.Option key={key} value={translations().employeesNumberOptions[key] || ''}>
                    {translations().employeesNumberOptions[key]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {companyType && !['sole-trader', 'partnership'].includes(companyType) && (
          <Row gutter={[32, 0]}>
            <Col xs={24}>
              <FormSubgroupTitle>{translations().companyAddressTitle}</FormSubgroupTitle>

              <Form.Item
                name={['companyAddress', 'country']}
                label={translations().companyAddress.countryLabel}
                tooltip={translations().companyAddress.countryTooltip}
                rules={[
                  {
                    required: true,
                    message: translations().companyAddress.countryRequired
                  }
                ]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.companyAddress?.country !== curValues.companyAddress?.country
                }
              >
                {() => (
                  <Form.Item
                    name={['companyAddress', 'postalCode']}
                    label={translations().companyAddress.postalCodeLabel}
                    getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                      return e.currentTarget.value?.toLocaleUpperCase()
                    }}
                    rules={[
                      {
                        required: true,
                        message: translations().companyAddress.postalCodeRequired
                      },
                      {
                        validator: async (rule, value) => {
                          const isUK = isCountryUK(getFieldValue(['companyAddress', 'country']))
                          if (value && isUK && !validatePostalCodeOfUK(value)) {
                            throw new Error(translations().companyAddress.postalCodeInvalid)
                          }
                        }
                      }
                    ]}
                    validateTrigger='onBlur'
                  >
                    <PostalCode
                      isUK={isCountryUK(getFieldValue(['companyAddress', 'country']))}
                      onAddressSelect={a => {
                        const address = [a.line_2, a.line_3, a.line_4].filter(item => !!item).join(', ')
                        setFieldsValue({
                          companyAddress: {
                            addressLine1: a.line_1,
                            addressLine2: address,
                            townOrCity: a.town_or_city,
                            region: a.county
                          }
                        })
                        store.completeApplicationStore.updateCompanyProfile(
                          (companyProfileDataRef.current = getFormData())
                        )
                      }}
                    />
                  </Form.Item>
                )}
              </Form.Item>

              <Form.Item
                name={['companyAddress', 'addressLine1']}
                label={translations().companyAddress.addressLine1Label}
                rules={[
                  {
                    required: true,
                    message: translations().companyAddress.addressLine1Required
                  }
                ]}
                validateTrigger='onBlur'
              >
                <Input />
              </Form.Item>

              <Form.Item
                name={['companyAddress', 'addressLine2']}
                label={<div>{translations().companyAddress.addressLine2Label}</div>}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name={['companyAddress', 'townOrCity']}
                label={translations().companyAddress.cityLabel}
                rules={[
                  {
                    required: true,
                    message: translations().companyAddress.cityRequired
                  },
                  {
                    pattern: CITY_TYPING_PATTERN,
                    message: translations().companyAddress.cityInvalid
                  }
                ]}
                validateTrigger='onBlur'
              >
                <Input />
              </Form.Item>

              <Form.Item
                name={['companyAddress', 'region']}
                label={<div>{translations().companyAddress.regionLabel}</div>}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Divider />

        <Form.Item name='comments' label={translations().comments}>
          <AntInput.TextArea rows={5} placeholder={translations().commentsPlaceholder} />
        </Form.Item>
      </Form>
    </>
  )
}
