import translations from './translations'

translations.add('en', {
    createdDate: 'Created date (GMT)',
    merchantName: 'Merchant',
    amount: 'Amount',
    currency: 'Currency',
    status: 'Status',
    message: 'Message',
    signifydDecision: 'Signifyd Decision',
    signifydScore: 'Signifyd Score',
    payoutDate: 'Confirmation Date (GMT)',
    payoutAmount: 'Confirmed Amount',
    transactionChannel: 'Transaction Channel',
    transactionType: 'Transaction Type',
    paymentMethod:  'Payment Method',
    processingType: 'Processing Type',
    mid: 'MID',
    terminal: 'Terminal',
    merchantReference: 'Merchant Reference',
    reference: 'RRN',
    sourceClient: 'Source Client (Origin of Transaction)',
    threeDSVersion: '3DS Version',
    avsHouseNumberResult: 'AVS House Number Result',
    avsPostcodeResult: 'AVS Post Code Result',
    cscResult: 'CSC Result',
    payerAuthenticationResult: 'Payer Auth Result (Enrollment/Payer Authentication)',
    responseCode: 'Response Code',
    cardScheme: 'Card Scheme',
    cardMask: 'Card Mask',
    issuer: 'Issuer',
    issuerCountry: 'Issuer Country',
    payerName: 'Payer Name',
    payerEmail: 'Payer Email',
    payerIp: 'Payer IP',
    ipCountry: 'IP Country',
    ipCity: 'IP City',
    id: 'ID',
    title: 'Export ECOM transactions',
    columns: 'Columns',
    cancel: 'Cancel',
    ok: 'Export',
    all: (value) => `All (${value})`,
    custom: 'Custom'
})
