import { ColumnType } from 'antd/lib/table'
import translations from '../translations'

const renderIsCorporateCard = (value: any) => {
    if (typeof value !== 'boolean') return value
    return value ? translations().corporate : translations().personal
}

export const columns: ColumnType<any>[] = [
    {
        title: translations().transactionDate,
        dataIndex: 'transactionDate',
        key: 'transactionDate',
        width: 180
    },
    {
        title: translations().settlementDate,
        dataIndex: 'settlementDate',
        key: 'settlementDate',
        width: 140,
        render: (value) => value ?? translations().notSettledYet
    },
    {
        title: translations().bankingDate,
        dataIndex: 'bankingDate',
        key: 'bankingDate',
        width: 140
    },
    {
        title: translations().merchant,
        dataIndex: 'merchantName',
        key: 'merchantName',
        width: 150
    },
    {
        title: translations().address,
        dataIndex: 'address',
        key: 'address',
        width: 150
    },
    {
        title: translations().postcode,
        dataIndex: 'postcode',
        key: 'postcode',
        width: 120
    },
    {
        title: translations().mid,
        dataIndex: 'mid',
        key: 'mid',
        width: 150
    },
    {
        title: translations().terminal,
        dataIndex: 'terminalNumber',
        key: 'terminalNumber',
        width: 150
    },
    {
        title: translations().amount,
        dataIndex: 'amount',
        key: 'amount',
        width: 100
    },
    {
        title: translations().currency,
        dataIndex: 'currency',
        key: 'currency',
        width: 100
    },
    {
        title: translations().settlementType,
        dataIndex: 'settlementType',
        key: 'settlementType',
        width: 130
    },
    {
        title: translations().totalFee,
        dataIndex: 'totalFee',
        key: 'totalFee',
        width: 120
    },
    {
        title: translations().interchangeFee,
        dataIndex: 'interchangeFee',
        key: 'interchangeFee',
        width: 130
    },
    {
        title: translations().schemeFee,
        dataIndex: 'schemeFee',
        key: 'schemeFee',
        width: 120
    },
    {
        title: translations().processingFee,
        dataIndex: 'processingFee',
        key: 'processingFee',
        width: 120
    },
    {
        title: translations().gatewayFee,
        dataIndex: 'gatewayFee',
        key: 'gatewayFee',
        width: 120
    },
    {
        title: translations().authorisationFee,
        dataIndex: 'authorisationFee',
        key: 'authorisationFee',
        width: 135
    },
    {
        title: translations().blendedFee,
        dataIndex: 'blendedFee',
        key: 'blendedFee',
        width: 120
    },
    {
        title: translations().refundFee,
        dataIndex: 'refundFee',
        key: 'refundFee',
        width: 120
    },
    {
        title: translations().amountToSettle,
        dataIndex: 'amountToSettle',
        key: 'amountToSettle',
        width: 140
    },
    {
        title: translations().sortCode,
        dataIndex: 'sortCode',
        key: 'sortCode',
        width: 120
    },
    {
        title: translations().accountNumber,
        dataIndex: 'accountNumber',
        key: 'accountNumber',
        width: 140
    },
    {
        title: translations().operation,
        dataIndex: 'operation',
        key: 'operation',
        width: 100
    },
    {
        title: translations().transactionId,
        dataIndex: 'transactionId',
        key: 'transactionId',
        width: 340
    },
    {
        title: translations().merchantReference,
        dataIndex: 'merchantReference',
        key: 'merchantReference',
        width: 340
    },
    {
        title: translations().transactionType,
        dataIndex: 'transactionType',
        key: 'transactionType',
        width: 150
    },
    {
        title: translations().cardScheme,
        dataIndex: 'cardScheme',
        key: 'cardScheme',
        width: 120,
        render: (value) => translations()[value] || value
    },
    {
        title: translations().cardMask,
        dataIndex: 'cardMask',
        key: 'cardMask',
        width: 160
    },
    {
        title: translations().cardholderName,
        dataIndex: 'cardholderName',
        key: 'cardholderName',
        width: 160
    },
    {
        title: translations().issuerCountry,
        dataIndex: 'issuerCountry',
        key: 'issuerCountry',
        width: 120
    },
    {
        title: translations().cardType,
        dataIndex: 'cardType',
        key: 'cardType',
        width: 120
    },
    {
        title: translations().issuerRegion,
        dataIndex: 'issuerRegion',
        key: 'issuerRegion',
        width: 165
    },
    {
        title: translations().isCorporateCard,
        dataIndex: 'isCorporateCard',
        key: 'isCorporateCard',
        width: 150,
        render: renderIsCorporateCard
    },
    {
        title: translations().captureMethod,
        dataIndex: 'captureMethod',
        key: 'captureMethod',
        width: 130
    }
]

export const columnsTest: ColumnType<any>[] = [
    {
        title: translations().transactionDate,
        dataIndex: 'transactionDate',
        key: 'transactionDate',
        width: 180
    },
    {
        title: translations().settlementDate,
        dataIndex: 'settlementDate',
        key: 'settlementDate',
        width: 140,
        render: (value) => value ?? translations().notSettledYet
    },
    {
        title: translations().bankingDate,
        dataIndex: 'bankingDate',
        key: 'bankingDate',
        width: 140
    },
    {
        title: translations().merchant,
        dataIndex: 'merchantName',
        key: 'merchantName',
        width: 150
    },
    {
        title: translations().address,
        dataIndex: 'address',
        key: 'address',
        width: 150
    },
    {
        title: translations().postcode,
        dataIndex: 'postcode',
        key: 'postcode',
        width: 120
    },
    {
        title: translations().mid,
        dataIndex: 'mid',
        key: 'mid',
        width: 150
    },
    {
        title: translations().terminal,
        dataIndex: 'terminalNumber',
        key: 'terminalNumber',
        width: 150
    },
    {
        title: translations().amount,
        dataIndex: 'amount',
        key: 'amount',
        width: 100
    },
    {
        title: translations().currency,
        dataIndex: 'currency',
        key: 'currency',
        width: 100
    },
    {
        title: translations().settlementType,
        dataIndex: 'settlementType',
        key: 'settlementType',
        width: 130
    },
    {
        title: translations().totalFee,
        dataIndex: 'totalFee',
        key: 'totalFee',
        width: 120
    },
    {
        title: translations().interchangeFee,
        dataIndex: 'interchangeFee',
        key: 'interchangeFee',
        width: 130
    },
    {
        title: translations().schemeFee,
        dataIndex: 'schemeFee',
        key: 'schemeFee',
        width: 120
    },
    {
        title: translations().processingFee,
        dataIndex: 'processingFee',
        key: 'processingFee',
        width: 120
    },
    {
        title: translations().gatewayFee,
        dataIndex: 'gatewayFee',
        key: 'gatewayFee',
        width: 120
    },
    {
        title: translations().authorisationFee,
        dataIndex: 'authorisationFee',
        key: 'authorisationFee',
        width: 135
    },
    {
        title: translations().blendedFee,
        dataIndex: 'blendedFee',
        key: 'blendedFee',
        width: 120
    },
    {
        title: translations().refundFee,
        dataIndex: 'refundFee',
        key: 'refundFee',
        width: 120
    },
    {
        title: translations().amountToSettle,
        dataIndex: 'amountToSettle',
        key: 'amountToSettle',
        width: 140
    },
    {
        title: translations().sortCode,
        dataIndex: 'sortCode',
        key: 'sortCode',
        width: 120
    },
    {
        title: translations().accountNumber,
        dataIndex: 'accountNumber',
        key: 'accountNumber',
        width: 140
    },
    {
        title: translations().operation,
        dataIndex: 'operation',
        key: 'operation',
        width: 100
    },
    {
        title: translations().transactionId,
        dataIndex: 'transactionId',
        key: 'transactionId',
        width: 340
    },
    {
        title: translations().merchantReference,
        dataIndex: 'merchantReference',
        key: 'merchantReference',
        width: 340
    },
    {
        title: translations().transactionType,
        dataIndex: 'transactionType',
        key: 'transactionType',
        width: 150
    },
    {
        title: translations().cardScheme,
        dataIndex: 'cardScheme',
        key: 'cardScheme',
        width: 120,
        render: (value) => translations()[value] || value
    },
    {
        title: translations().cardMask,
        dataIndex: 'cardMask',
        key: 'cardMask',
        width: 160
    },
    {
        title: translations().cardholderName,
        dataIndex: 'cardholderName',
        key: 'cardholderName',
        width: 160
    },
    {
        title: translations().issuerCountry,
        dataIndex: 'issuerCountry',
        key: 'issuerCountry',
        width: 120
    },
    {
        title: translations().cardType,
        dataIndex: 'cardType',
        key: 'cardType',
        width: 120
    },
    {
        title: translations().issuerRegion,
        dataIndex: 'issuerRegion',
        key: 'issuerRegion',
        width: 165
    },
    {
        title: translations().isCorporateCard,
        dataIndex: 'isCorporateCard',
        key: 'isCorporateCard',
        width: 150,
        render: renderIsCorporateCard
    },
    {
        title: translations().captureMethod,
        dataIndex: 'captureMethod',
        key: 'captureMethod',
        width: 130
    }
]