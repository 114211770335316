import { TablePaginationConfig } from 'antd/lib/table'
import React from 'react'

export const generateTablePaginationProps = (currentPage: number,
                                             pageSize: number,
                                             total: number,
                                             onChange,
                                             onShowSizeChange?,
                                             options?): TablePaginationConfig => {
    return {
        size: 'small',
        position: ['bottomRight'],
        current: currentPage,
        pageSize,
        total,
        showSizeChanger: true,
        pageSizeOptions: options?.pageSizeOptions || [
            '20',
            '50',
            '100',
            '500',
            '1000'
        ],
        responsive: true,
        onChange,
        onShowSizeChange,
        showTotal: (_total, range) => {
            return options?.showTotal ? options.showTotal(_total, range) :
                (<>
                    <span className='nowrap'>{range[0]} – {range[1]}</span> of {_total}
                </>)
        }
    }
}
