import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { Button, Modal, Typography, Space, Grid } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

import DatePicker from '~/code/components/DatePicker'
import { disabledDateForPdf, disabledDateYearForPdf, getLastFullMonth } from '../../../../services'

import { DownloadStatementPdfModalProps } from './DownloadStatementPdfModalProps'
import translations from './translations'
import styles from './styles.scss'

const DATE_NEW = moment({ year: 2023, month: 7 }) // 2023-08
const DATE_FORMAT = 'YYYY-MM-DD'
const DATE_PERIOD_FORMAT = 'YYYY-MM'

export const DownloadStatementPdfModal = observer((props: DownloadStatementPdfModalProps) => {
  const screens = Grid.useBreakpoint()
  const isMobile = !screens.md

  const { isOpen, onCancel, onSubmit } = props

  const [date, setDate] = useState(getLastFullMonth())
  const isOld = date.isBefore(DATE_NEW)

  const changeDate = (value: moment.Moment) => {
    if (disabledDateForPdf(value)) {
      setDate(getLastFullMonth())
    } else {
      setDate(value)
    }
  }

  const download = () => {
    onSubmit(
      isOld
        ? {
            from: moment(date).startOf('month').format(DATE_FORMAT),
            to: moment(date).endOf('month').format(DATE_FORMAT)
          }
        : { period: date.format(DATE_PERIOD_FORMAT) }
    )
  }

  useEffect(() => {
    if (isOpen) {
      setDate(getLastFullMonth())
    }
  }, [isOpen, setDate])

  return (
    <Modal
      title={translations().title}
      width={isMobile ? '90%' : '600px'}
      open={isOpen}
      footer={null}
      onCancel={onCancel}
    >
      <Typography.Text>{translations().text}</Typography.Text>

      <div className={styles.datepickers}>
        <Space>
          <DatePicker
            value={date}
            placement={'bottomRight'}
            picker='year'
            disabledDate={disabledDateYearForPdf}
            className={styles.yearDatePicker}
            onChange={changeDate}
          />
          <DatePicker
            value={date}
            placement={'bottomRight'}
            format='MMMM'
            picker='month'
            disabledDate={disabledDateForPdf}
            className={styles.monthDatePicker}
            popupClassName={styles.monthDatePickerPopup}
            onChange={changeDate}
          />
        </Space>
      </div>

      <div className={styles.buttons}>
        <Space>
          <Button type='primary' onClick={download} icon={<DownloadOutlined />}>
            {translations().download}
          </Button>
          <Button onClick={onCancel}> {translations().cancel}</Button>
        </Space>
      </div>
    </Modal>
  )
})
