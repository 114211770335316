import { inject, injectable } from 'inversify'
import { v4 as uuid } from 'uuid'
import { message } from 'antd'
import { action, makeObservable, observable, reaction } from 'mobx'
import { fetchInvoices, fetchInvoice } from './services/fetchers'
import { NetSuiteInvoicesFilterStoreSymbol } from '~/code/pages/Billing/pages/NetSuiteInvoices/components'
import { INetSuiteInvoicesFilterStore } from '~/code/pages/Billing/pages/NetSuiteInvoices/components/NetSuiteInvoicesFilter/INetSuiteInvoicesFilterStore'
import { INetSuiteInvoicesStore } from '~/code/pages/Billing/pages/NetSuiteInvoices/INetSuiteInvoicesStore'
import {
  NetSuiteInvoiceDataSourceType,
  FetchNetSuiteInvoicesParamsType,
  FetchNetSuiteInvoiceParamsType
} from './models'

import translations from './translations'

@injectable()
export class NetSuiteInvoicesStore implements INetSuiteInvoicesStore {
  isLoading: boolean = false
  filterStore: INetSuiteInvoicesFilterStore
  data: NetSuiteInvoiceDataSourceType[] = []

  constructor(@inject(NetSuiteInvoicesFilterStoreSymbol) filterStore: INetSuiteInvoicesFilterStore) {
    this.filterStore = filterStore

    makeObservable(this, {
      isLoading: observable,
      data: observable,

      refresh: action.bound,
      loadData: action.bound
    })

    this.loadData()

    reaction(
      () => {
        const { rangePickerStore, selectedSubsidiary, currentPage, size } = this.filterStore

        return {
          startDate: rangePickerStore.startDate,
          endDate: rangePickerStore.endDate,
          subsidiariy: selectedSubsidiary,
          currentPage,
          size
        }
      },
      () => this.loadData()
    )
  }

  loadData = async () => {
    this.isLoading = true

    try {
      const { rangePickerStore, currentPage, size, selectedSubsidiary } = this.filterStore
      const { startDate, endDate } = rangePickerStore

      const params: FetchNetSuiteInvoicesParamsType = {
        from: startDate ? startDate.format('YYYY-MM-DD') : undefined,
        to: endDate ? endDate.format('YYYY-MM-DD') : undefined,
        page: currentPage,
        size,
        system: selectedSubsidiary
      }

      const { error, result } = await fetchInvoices(params)

      if (error) throw new Error(error.message)

      this.data = result.data.map(invoice => ({
        ...invoice,
        rowKey: uuid(),
        subsidiary: translations().subsidiaries[invoice.subsidiary] || invoice.subsidiary
      })) as NetSuiteInvoiceDataSourceType[]

      this.filterStore.total = result.totalCount
    } catch (err) {
      message.error(err.message)
    } finally {
      this.isLoading = false
    }
  }

  loadInvoiceCSV = async (params: FetchNetSuiteInvoiceParamsType) => {
    this.isLoading = true

    try {
      await fetchInvoice(params)
    } catch (err) {
      message.error(err.message)
    } finally {
      this.isLoading = false
    }
  }

  public refresh() {
    this.loadData()
  }
}
