import translations from './translations'
import React from 'react'

translations.add('en', {
  salesforceOpportunityIDLabel: 'Salesforce opportunity ID',
  salesforceOpportunityIDRequired: 'salesforce opportunity ID is required',
  salesforceOpportunityIDMin: 'salesforce opportunity ID must be at least 15 characters',
  oppIdNote:
    'Once the Salesforce Opportunity ID is pasted in, Opportunity Owner`s name and email will be pulled out automatically from Salesforce'
})
