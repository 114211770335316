import React, { useEffect, useState } from 'react'
import { Button, Drawer, Grid, Tag, Typography } from 'antd'
import { ToolOutlined, UnlockOutlined } from '@ant-design/icons'
import { useForm } from 'antd/es/form/Form'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'

import { TeammateType, RoleItemModel } from '../../models'
import translations from '../../translations'
import { PermissionCard } from '../PermissionCard'
import { TeammateForm } from '../TeammateForm'
import { PermissionsTable } from '../PermissionsTable'

import styles from './styles.scss'

const { useBreakpoint } = Grid
const ADMIN = 'admin'

type Props = {
    teammate: Partial<TeammateType>
    roles: RoleItemModel[]
    isVisible: boolean
    onSubmit(values: TeammateType): Promise<void>
    onClose(): void
}

export const TeammatesDrawer = observer((props: Props) => {
    const { teammate, roles: allRoles, isVisible, onClose, onSubmit } = props

    const screens = useBreakpoint()
    const [form] = useForm()
    const [selectedRoles, setSelectedRoles] = useState<string[]>()

    const roles = allRoles.filter((item) => item.value !== ADMIN)

    const isEditMode = Boolean(teammate?.id)
    const isAdmin = selectedRoles?.includes('admin')
    const isInvalid = !selectedRoles?.length

    const toggleRole = (role: string) => {
        const _selectedRoles = selectedRoles || []
        if (_selectedRoles.includes(role)) {
            setSelectedRoles(_selectedRoles.filter((r) => r !== role))
        } else {
            setSelectedRoles([ ..._selectedRoles, role ])
        }
    }

    const close = () => {
        form.resetFields()
        setSelectedRoles(undefined)
        onClose()
    }

    const save = async () => {
        await form.validateFields()
        onSubmit({
            ...form.getFieldsValue(),
            id: teammate?.id,
            roles: selectedRoles,
            status: teammate?.status
        })
        close()
    }

    const renderFooter = () => <div className={styles.footer}>
        <Button onClick={close}>{translations().buttons.cancel}</Button>
        <Button
            type='primary'
            disabled={isInvalid}
            onClick={save}
        >
            {isEditMode ? translations().buttons.save : translations().buttons.invite}
        </Button>
    </div>

    const renderTitle = () => {
        if (isEditMode) return <>{translations().titles.edit} <span>({teammate.email})</span></>
        return translations().titles.invite
    }

    useEffect(() => {
        if (isEditMode && isVisible) {
            setSelectedRoles(teammate.roles)
            form.setFieldsValue(teammate)
        }
    }, [teammate, isVisible])

    return <Drawer
        title={renderTitle()}
        width={screens.xs ? 360 : 520}
        closable={false}
        onClose={close}
        className={styles.root}
        open={isVisible}
        footer={renderFooter()}
        destroyOnClose
    >
        <TeammateForm
            form={form}
            isEditMode={isEditMode}
            teammate={teammate}
            className={styles.form}
        />

        <Typography.Title level={5} className={styles.subtitle}>{translations().subtitles.userAccess}</Typography.Title>

        <PermissionCard
            title={translations().permissionSelects.admin}
            content={translations().permissionSelectsText.admin}
            icon={<UnlockOutlined />}
            isActive={isAdmin === true}
            onClick={() => setSelectedRoles([ADMIN])}
        />

        <PermissionCard
            title={translations().permissionSelects.restricted}
            content={translations().permissionSelectsText.restricted}
            icon={<ToolOutlined />}
            isActive={isAdmin === false}
            onClick={() => {
                const hasAdmin = teammate?.roles?.includes(ADMIN)
                setSelectedRoles(hasAdmin ? [] : (teammate?.roles || []))
            }}
        />

        {isAdmin === false && <>
            <h4 className={styles.subtitle}>{translations().subtitles.presets}</h4>

            <div>
                {roles.map((item) => (
                    <Tag
                        key={item.value}
                        className={classNames(styles.tag, selectedRoles.includes(item.value) && styles.active)}
                        onClick={() => toggleRole(item.value)}
                    >
                        {item.label}
                    </Tag>
                ))}
            </div>

            <PermissionsTable
                roles={roles}
                selectedRoles={selectedRoles}
            />
        </>}

    </Drawer>
})

