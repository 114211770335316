import React from 'react'
import translations from './translations'


translations.add('en', {
    title: 'Financial Information',
    moneyBlockTitle: 'Financial information',
    accountBlockTitle: 'Bank account',
    companyNumberLabel: 'Company number',
    expectedAnnualTurnoverLabel: 'Expected annual business turnover (£)',
    expectedAnnualTurnoverRequired: 'expected annual turnover is required',
    expectedAnnualTurnoverCustomLabel: 'Manual Entry',
    expectedAnnualTurnoverCustomRequired: 'manual entry is required',
    natureOfAccountTransactionsSubLabel: 'select all relevant',
    natureOfAccountTransactionsRequired: 'nature of your account transactions is required',
    natureOfAccountTransactionsPlaceholder: 'Please select',
    expectedMonthlyTransactionValuesLabel: 'Number of transactions per month',
    expectedMonthlyTransactionValuesRequired: 'number of transactions per month is required',
    expectedMonthlyTransactionValuesExceed: 'expected monthly transaction values can\'t exceed expected annual turnover',
    averageTransactionValueLabel: 'Average transaction value (£)',
    averageTransactionValueRequired: 'average transaction value is required',
    tradeOutsideEeaLabel: 'Do you trade outside the EEA?',
    tradeOutsideEeaRequired: 'please select an option',
    tradeOutsideEeaOptions: {
        yes: 'Yes',
        no: 'No'
    },
    tradeOutsideEeaTooltip: 'The European Economic Area (EEA) is the EU and European Free Trade Association members combined',
    tradingCountriesOutsideEeaLabel: 'Please select countries',
    tradingCountriesOutsideEeaRequired: 'please select at least one country',
    tradingCountriesOutsideEeaTooltip: 'This is a list of countries that, for security reasons, we need to know if you trade in',
    emailAddressLabel: 'Company email address',
    emailAddressRequired: 'company email address is required',
    emailFormatIncorrect: 'invalid email',
    telephoneNumberLabel: 'Company phone number',
    telephoneNumberRequired: 'company phone number is required',
    telephoneNumberWrongFormat: 'invalid company phone number',
    accountType: 'Account type',
    accountTypes: {
        business: 'Business',
        personal: 'Personal'
    },
    accountTypeRequired: 'Account type is required',
    bankAccountNumberLabel: 'Bank account number',
    bankAccountNumberRequired: 'bank account number is required',
    bankAccountNumberInvalid: 'invalid bank account number',
    sortCodeLabel: 'Sort code',
    sortCodeRequired: 'sort code is required',
    sortCodeInvalid: 'invalid sort code',
    accountNameLabel: 'Account name',
    accountNamePlaceholder: 'Bank account name',
    accountNameTooltip: 'Name on the bank statement',
    accountNameRequired: 'account name is required',
    bankNameLabel: 'Bank name',
    tradingAddressTitle: 'Trading address',
    sameAsCompanyAddress: 'same as company address',
    tradingAddress: {
        cityLabel: 'Town/City',
        cityRequired: 'town/city is required',
        cityInvalid: 'only alphanumeric characters are allowed',
        countryLabel: 'Country',
        countryRequired: 'country is required',
        regionLabel: 'County',
        postalCodeLabel: 'Postcode',
        postalCodeRequired: 'postcode is required',
        postalCodeInvalid: 'invalid postcode',
        addressLine1Label: 'Address line 1',
        addressLine1Required: 'address line 1 is required',
        addressLine2Label: 'Address line 2',
        countryTooltip: 'We are currently only able to work with UK registered businesses'
    },
    face2faceSelectOptions: {
        withinHours: 'Within hours',
        withinOneDay: 'Within one day',
        withinOneWeek: 'Within one week',
        withinOneMonth: 'Within one month'
    },
    natureOfAccountTransactionsOptions: {
        cash: 'Cash',
        cheque: 'Cheque',
        directDebit: 'Direct debit',
        international: 'International',
        onlinePayments: 'Online payments',
        standingOrder: 'Standing order',
        faceToFaceCardPayments: 'Face to face card payments'
    },
    validate: 'Validate',
    seasonalBusiness: {
        isTrueLabel: 'Is the business seasonal?',
        isTrueRequired: 'is seasonal business required',
        yes: 'Yes',
        no: 'No',
        qLabel: 'How would you split your trade by quarter?',
        q1Label: 'Q1 (%)',
        q1Required: 'quarter 1 is required',
        q2Label: 'Q2 (%)',
        q2Required: 'quarter 2 is required',
        q3Label: 'Q3 (%)',
        q3Required: 'quarter 3 is required',
        q4Label: 'Q4 (%)',
        q4Required: 'quarter 4 is required',
        totalShouldBe100: 'Total value should be 100'
    },
    businessModelQuestions: {
        yes: 'Yes',
        no: 'No',
        isTrueLabel: 'Do you take orders F2F and fulfill them in the future (i.e. post the goods to your customers)?',
        deliveryTimeLabel: 'How long can delivery times take?',
        deliveryTimeRequired: 'delivery time is required',
        courierServiceUsedLabel: 'If you post goods do you use a courier?'
    },
    validateBankAccount: 'Please validate bank account',
    annualTurnover: 'Expected annual business turnover',
    annualCardTurnover: 'Expected annual card turnover (£)',
    monthTransactions: 'Number of transactions per month',
    highestTransaction: 'Highest transaction value (£)',
    averageTransaction: 'Average transaction value (£)',
    required: 'Required',
    pos: 'POS (face-to-face)',
    moto: 'MOTO (phone payments)',
    ecom: 'ECOM (Website payments)',
    payByLink: 'ECOM Pay by link only',
    payByApp: 'ECOM Pay by Apps',
    virtualTerminal: 'ECOM Virtual terminal',
    nbsp: 'Same day sales –  product is taken away from stock',
    deposits: 'Deposits',
    balances: 'Balances',
    fullPayment: 'Full payment in advance',
    other: 'Other (please detail)',
    totalSum: 'Total sum must be 100%!',
    digitsOnly: 'Digits only!',
    range: '0 - 100 range!',
    enterDetails: 'Please enter details!',
    onlyPositive: 'Only positive digits!',
    selectTerminal: 'Select Terminal',
    turnoverMethod: 'Card Turnover depending on the processing method',
    processingMethod: 'Method of processing',
    cardTurnoverPercent: 'Card Turnover (100% in total)',
    ifMerchantNew: '*Expected card turnover if the Merchant is new to cards',
    howFar: 'How far in advance payments are taken, before goods/products are received or services are complete',
    cardTurnover: 'Card Turnover',
    days: 'Days',
    haveComments: 'Have comments?',
    comment: 'Please add comment',
    requestMoto: 'Request MOTO',
    requestVt: 'Request Virtual Terminal',
    notAvailable: 'No terminals available for configuration',
    clear: 'Clear Form',
    questionnaire: 'Questionnaire',
    incorrectValue: 'Incorrect value',
    greaterThanZero: 'Value must be greater than 0'
})
