import translations from './translations'
import React from 'react'

translations.add('en', {
  docusignSignedText: (
    <>
      <p>Editing of the data is not allowed because a contract has already been signed with this data in DocuSign. </p>
      <p>If you still need to change the data, go to the Contract page and cancel the signed contract,</p>
      <p> after which you will be able to edit the data in the application.</p>
    </>
  )
})
