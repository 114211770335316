export const validActions = ['read', 'create', 'update', 'delete']

export const extractPermissionBase = (permission: string): string => {
  const parts = permission?.split('.') || []
  parts.pop()
  return parts.join('.')
}

export const filterValidPermissions = (permissions: string[]) =>
  permissions?.filter(permission => validActions.includes(permission?.split('.').pop() || ''))

export const getInitialPermissionsState = (permissions: string[]) =>
  permissions?.reduce<{ [key: string]: string[] }>((acc, permission) => {
    const base = extractPermissionBase(permission)
    const action = permission?.split('.').pop()!
    if (validActions.includes(action)) {
      if (!acc[base]) {
        acc[base] = []
      }
      acc[base].push(action)
    }
    return acc
  }, {})

export const removeDuplicateBases = (permissions: string[]) => {
  const seen = new Set()
  return permissions?.filter(permission => { 
    const base = extractPermissionBase(permission)
    if (seen.has(base)) {
      return false
    }
    seen.add(base)
    return true
  })
}

export const getSpecificPermission = (permissions: string[]): string[] => {
  return permissions?.filter(permission => !validActions.includes(permission.split('.').pop()!))
}

export const getHasSpecificPermission = (permissions: string[]): boolean => {
  return permissions?.some(permission => { 
    const action = permission.split('.').pop()
    return action && !validActions.includes(action)
  })
}
