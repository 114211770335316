import { postWithAuth } from '~/code/services/authorised-requests'
import { SettlementModelByBankingDate, SettlementParams, SettlementResponseModel } from '../components/SettlementsTable/models'

export const fetchSettlement = (data: SettlementParams) =>
    postWithAuth<SettlementResponseModel>(`/api/v2/merchants/settlements`, data)

export const fetchSettlementTest = (data: SettlementParams) =>
    postWithAuth<SettlementResponseModel>(`/api/temp/merchants/settlements`, data)

export const fetchSettlementByBankingDate = (data: SettlementParams) =>
    postWithAuth<SettlementResponseModel<SettlementModelByBankingDate>>('/api/v1/merchants/settlements-by-banking-date', data)
