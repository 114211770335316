import { message } from 'antd'
import { ApiResponse } from 'back-connector'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import {
    ITransactionChannelsStore, EcomTransactionChannelType,
    TransactionChannelToggleType, PosTransactionChannelRequestType, PosTransactionChannelType
} from '~/code/pages/TransactionChannels/models'
import { PosConnectionDetailsType } from '~/code/pages/TransactionChannels/models/PosConnectionDetailsType'
import { fetchEcomTransactionChannels, updateEcomTransactionChannel, updatePosTransactionChannel, fetchPosTransactionChannels, fetchPosConnectionDetails } from '~/code/pages/TransactionChannels/services'
import translations from './translations'

export class TransactionChannelsStore implements ITransactionChannelsStore {
    isInitiated: boolean = false

    ecomTransactionChannels: EcomTransactionChannelType[] = []
    _posTransactionChannels: PosTransactionChannelType[] = []

    posConnectionDetails: PosConnectionDetailsType[] = []

    isTransactionChannelsLoading: boolean = false
    isUpdateTransactionChannelLoading: boolean = false


    constructor() {
        makeObservable(this, {
            ecomTransactionChannels: observable,
            _posTransactionChannels: observable,
            isTransactionChannelsLoading: observable,
            isUpdateTransactionChannelLoading: observable,
            posConnectionDetails: observable,

            loadTransactionChannels: action,

            _updateTransactionChannel: action,

            isOnlyOneEcomTransactionChannelEnabled: computed,
            posAllTransactionChannels: computed,
        })
    }

    get isOnlyOneEcomTransactionChannelEnabled(): boolean {
        const enabledTransactionChannels = this.ecomTransactionChannels.filter(transactionChannel => !transactionChannel.isDisabled)
        return enabledTransactionChannels.length <= 1
    }

    get posAllTransactionChannels() {
        return this._posTransactionChannels.map((posTransaction) => {
            const channelDetails = this.posConnectionDetails.find(({channel})=> channel === posTransaction.channel)
            return {...posTransaction, connectionStatus: channelDetails?.connectionStatus}
        }) || this._posTransactionChannels
    }

    init() {
        this.isInitiated = true
        this.loadPosConnectionDetails()
        this.loadTransactionChannels()
    }

    async loadPosConnectionDetails() {
        try {
            const { status, error, result } = await fetchPosConnectionDetails()

            if (status !== 200 || error) {
                message.error(error.message || translations().errorFetchingConnectionDetails)
                return
            }

            runInAction(() => this.posConnectionDetails = result)


        } catch (error) {
            message.error(translations().errorFetchingConnectionDetails)
        }
    }

    async loadTransactionChannels(type?: 'pos' | 'ecom') {
        runInAction(() => this.isTransactionChannelsLoading = true)

        if (!type || type === 'ecom') {
            try {
                const { status, error, result } = await fetchEcomTransactionChannels()

                if (status !== 200 || error) {
                    message.error(error.message || translations().errorLoadingEcomTransactionChannels)
                    return
                }

                runInAction(() => this.ecomTransactionChannels = result)
            } catch (error) {
                message.error(translations().errorLoadingEcomTransactionChannels)
            }
        }

        if (!type || type === 'pos') {

            try {
                const { status, error, result } = await fetchPosTransactionChannels()

                if (status !== 200 || error) {
                    message.error(error.message || translations().errorLoadingPosTransactionChannels)
                    return
                }

                runInAction(() => this._posTransactionChannels = result)
            } catch (error) {
                message.error(translations().errorLoadingPosTransactionChannels)
            }
        }

        runInAction(() => this.isTransactionChannelsLoading = false)
    }

    updateEcomTransactionChannel = (id: string, toggleType: TransactionChannelToggleType, toggleValue: boolean, comment?: string) => {
        return this._updateTransactionChannel(
            updateEcomTransactionChannel(id, { [toggleType]: toggleValue, comment }),
            (result) => {
                const updatedTransactionChannelIndex = this.ecomTransactionChannels.findIndex(transactionChannel => transactionChannel.transactionChannelId === result.transactionChannelId)
                this.ecomTransactionChannels[updatedTransactionChannelIndex] = result
            }
        )
    }

    updatePosTransactionChannel(payload: PosTransactionChannelRequestType) {
        return this._updateTransactionChannel(
            updatePosTransactionChannel(payload),
            (result) => {
                const updatedTransactionChannelIndex = this._posTransactionChannels.findIndex(transactionChannel => transactionChannel.name === result.name)
                this._posTransactionChannels[updatedTransactionChannelIndex] = result
            }
        )
    }



    async _updateTransactionChannel<T>(promise: Promise<ApiResponse<T>>, onSuccess: (result: T) => void): Promise<void> {
        runInAction(() => this.isUpdateTransactionChannelLoading = true)

        try {
            const { status, error, result } = await promise

            if (status !== 200 || error) {
                message.error(error.message || translations().errorUpdatingTransactionChannel)
                return
            }

            message.success(translations().successUpdatingTransactionChannel)
            runInAction(() => onSuccess(result))
        } catch (error) {
            message.error(translations().errorUpdatingTransactionChannel)
        }

        runInAction(() => this.isUpdateTransactionChannelLoading = false)
    }
}
