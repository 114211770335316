import { message } from 'antd'
import { getWithAuth } from '~/code/services'
import translations from '~/code/pages/Exports/translations'
import { FetchJobsParamsType, JobType } from '../models'

export const fetchJobs = (params: FetchJobsParamsType) =>
    getWithAuth<{ data: JobType[], totalCount: number }>('/api/v1/exports/jobs', params)

export const downloadFile = async (job: JobType) => {
    const fileExtension = job.url.match(/\.[0-9a-z]+$/)[0]
    const fileName = job.fileName.replace(/\.[0-9a-z]+$/, fileExtension)

    try {
        const res = await fetch(job.url)
        const blob = await res.blob()
        const elm = document.createElement('a')
        elm.href = URL.createObjectURL(blob)
        elm.setAttribute('download', fileName)
        elm.click()
    } catch (error) {
        message.error(translations().errorDownloadingFile)
    }
}
