import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    notCompleted: string
    deleteFormTitle: (name: string) => string
    deleteFormContent: string
    edit: string
    delete: string
    productsModal: string
    agreementSignatory: string
    verified: string
    notVerified: string
    manuallyDocs: string
    outletInfo: string
    posProduct: string
    ecomProduct: string
    aliPay: string
    cards: string
    payByBank: string
    zashEpos: string
    moto: string
    openBanking: string
    paymentLinks: string
    virtualTerminal: string
    websitePayments: string
    configure: string
    noPosProds: string
    noEcomProds: string
}>()

