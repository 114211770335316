import translations from './translations'
import React from 'react'
import styles from '../MerchantHighlight.scss'

translations.add('en', {
  closed: 'CLOSED',
  uberId: 'UBER ID:',
  active: (isActive: boolean) =>
    isActive ? <span className={styles.active}>ACTIVE</span> : <span className={styles.closed}>CLOSED</span>
})
