import React from 'react'
import translations from './translations'

translations.add('en', {
  title: 'Bank accounts',
  details: (
    <>
      Start by adding bank accounts merchant will be using for funds settlements and direct debit fees collection.{' '}
      <br />
      You can add multiple accounts if a merchant wills to operate different accounts for different locations
    </>
  ),
  openBankStatus: (status: string) =>
    status === 'SENT' ? 'SENT, Waiting for the authorisation' : status === 'NOMATCH' ? 'Non Verified' : status,
  creditSafeStatus: (status: boolean) => (status ? 'VERIFIED' : 'Account verification failed'),
  notVerified: 'Not verified'
})
