import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Typography } from 'antd'
import translations from '../../translations'
import { BalanceTableMidCellProps } from './props'

const { Link } = Typography

export const BalanceTableMidCell = observer((props: BalanceTableMidCellProps) => {
  const [showAllMids, setShowAllMids] = useState(false)

  const midsArray = props.mids.split(',')
  if (showAllMids && midsArray.length > 0) {
    return (
      <div>
        <div>{props.mids}</div>
        <Link onClick={() => setShowAllMids(false)}>{translations().showLess}</Link>
      </div>
    )
  }

  return (
    <div>
      {midsArray.length > 1 ? (
        <>
          <div>{midsArray[0]}</div>
          <Link onClick={() => setShowAllMids(true)}>{translations().showMore}</Link>
        </>
      ) : (
        <div>{props.mids}</div>
      )}
    </div>
  )
})
