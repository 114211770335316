import { RiskLimitsModel } from './RiskLimitsModel'

export interface ProcessDetailsModel {
    firstName: string
    surname: string
    companyName: string
    companyNumber: string
    terminalId: string
    terminalProduct: string
    acquisitionChannel: string
    phoneNumber: string
    merchantEmail: string
    initiatorName: string
    initiatorEmail: string
    initiatorComment: string
    reviewerName: string
    reviewerEmail: string
    reviewerComment: string
    processStatus: string
    currentLimits: RiskLimitsModel
    requestedLimits: RiskLimitsModel
    approvedLimits: RiskLimitsModel
}

export const createProcessDetailsModel = () => {
    return {
        firstName: '',
        surname: '',
        companyName: '',
        companyNumber: '',
        terminalId: '',
        terminalProduct: '',
        acquisitionChannel: '',
        phoneNumber: '',
        merchantEmail: '',
        initiatorEmail: '',
        initiatorName: '',
        initiatorComment: '',
        reviewerName: '',
        reviewerEmail: '',
        reviewerComment: '',
        processStatus: '',
        currentLimits: {
            dailyAmount: 0,
            dailyCount: 0,
            dailyMaxRefundAmount: 0,
            monthlyAmount: 0,
            monthlyCount: 0,
            perRefund: 0,
            perTransaction: 0,
            currency: ''
        },
        requestedLimits: {
            dailyAmount: 0,
            dailyCount: 0,
            dailyMaxRefundAmount: 0,
            monthlyAmount: 0,
            monthlyCount: 0,
            perRefund: 0,
            perTransaction: 0,
            currency: ''
        },
        approvedLimits: {
            dailyAmount: 0,
            dailyCount: 0,
            dailyMaxRefundAmount: 0,
            monthlyAmount: 0,
            monthlyCount: 0,
            perRefund: 0,
            perTransaction: 0,
            currency: ''
        }
    }
}
