import { getWithAuth } from '~/code/services'
import {
  FetchApplicationByIdRes,
  FetchApplicationByIdResV2,
  FetchStartedApplicationsParams,
  FetchStartedApplicationsRes
} from '../models'

export const fetchStartedApplications = (params: FetchStartedApplicationsParams) =>
  getWithAuth<FetchStartedApplicationsRes>('/api/v2/sales-onboarding/applications', params)

export const fetchApplicationById = (id: string) =>
  getWithAuth<FetchApplicationByIdRes>(`/api/v2/sales-onboarding/applications/${id}`)

export const fetchApplicationByIdV2 = (id: string) =>
  getWithAuth<FetchApplicationByIdResV2>(`/api/processes-api/v2.1/sales-onboarding/applications/${id}`)

export const fetchApplicationByCompanyID = async (id: string) =>
  getWithAuth<FetchApplicationByIdResV2>(`/api/processes-api/v2/dossier/legal/application?company_number=${id}`)
