import { message } from 'antd'
import { inject, injectable } from 'inversify'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { getStoreAttributes } from './services/fetchers'
import { IViewAttributesModalStore } from '~/code/pages/MerchantsManagement/components/Stores/components/ViewAttributesModal/IViewAttributesModal'
import { IStoresDossierV2Store } from '~/code/pages/MerchantsManagement/components/Stores/models'
import { StoresDossierV2StoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores'
import { GetStoreAttributesResultType } from './models/merchant-dossier-v2'
import translations from './translations'

@injectable()
export class ViewAttributesModalStore implements IViewAttributesModalStore {
  storesDossierV2Store: IStoresDossierV2Store
  isStoreAttributesLoading: boolean = false
  storeAttributes: GetStoreAttributesResultType = null

  constructor(@inject(StoresDossierV2StoreSymbol) storesDossierV2Store: IStoresDossierV2Store) {
    this.storesDossierV2Store = storesDossierV2Store
    makeObservable(this, {
      isStoreAttributesLoading: observable,
      storeAttributes: observable,
      loadStoreAttributes: action,
      closeModal: action,
      areAllAttributesNull: action
    })
  }

  closeModal = () => {
    this.storesDossierV2Store.closeViewAttributesModal()
  }

  async loadStoreAttributes(storeId: number) {
    try {
      runInAction(() => {
        this.isStoreAttributesLoading = true
      })
      if (!storeId) {
        return
      }
      const { status, error, result } = await getStoreAttributes(storeId)
      if (status === 200 && !error) {
        this.storeAttributes = result
      } else {
        this.storeAttributes = null
        message.error(error.message || translations().errorLoadingStoreAttributes)
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      runInAction(() => {
        this.isStoreAttributesLoading = false
      })
    }
  }

  areAllAttributesNull(): boolean {
    if (!this.storeAttributes) {
      return true
    }

    const { contractAttributes, midAttributes, teminalAttributes } = this.storeAttributes

    const allContractAttributesNull = !contractAttributes || contractAttributes.every(attr => attr.attributes === null)
    const allMidAttributesNull = !midAttributes || midAttributes.every(attr => attr.attributes === null)
    const allTerminalAttributesNull = !teminalAttributes || teminalAttributes.every(attr => attr.attributes === null)

    return allContractAttributesNull && allMidAttributesNull && allTerminalAttributesNull
  }
}
