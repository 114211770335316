import { Form } from 'antd'
import React from 'react'
import merge from 'lodash/merge'

import { Input } from '~/code/components'
import { BelowActionsWithSave, FormTitle, NextButton, StartAppContainer } from 'startapp/components'
import translations from './translations'
import { CorporateDetailsFormProps } from './props'

export const CorporateDetailsForm: React.FC<CorporateDetailsFormProps> = ({ data, onSubmit, isSaveLoading, onSave }) => {
    const [form] = Form.useForm()

    return <StartAppContainer>
        <Form
            form={form}
            autoComplete='off'
            onFinish={(values) => onSubmit(merge({}, data, values))}
            layout='vertical'
            requiredMark='optional'
            initialValues={data}
        >
            <FormTitle>{data.name}</FormTitle>

            <Form.Item
                name={['identification', 'registrationNumber']}
                label={translations().registrationNumber}
                rules={[{ required: true, message: translations().registrationNumberRequired }]}
                validateTrigger='onBlur'
            >
                <Input />
            </Form.Item>

            <BelowActionsWithSave
                isLoading={isSaveLoading}
                onSave={() => {
                    onSave(form.getFieldsValue())
                }}
            >
                <NextButton />
            </BelowActionsWithSave>
        </Form>
    </StartAppContainer>
}
