import React, { useEffect } from 'react'
import { Typography, Button } from 'antd'
import { observer } from 'mobx-react'
import { AuthForm } from '~/code/components/auth/AuthForm'
import { TwoFAModal } from '~/code/pages/Profile/components/TwoFA/components'
import { TwoFAEnforcedProps } from './props'
import { Routes } from '~/code/startup/Router/Routes'
import { redirectRoute } from '~/code/startup/Router/utils'

import translations from './translations'
import styles from './styles.scss'

const { Link } = Typography

const TwoFAEnforced = observer(({ store }: TwoFAEnforcedProps) => {
  const { onTwoFAModalOpen, initTwoFAModalStore, enforceTokenTwoFA, onTwoFAModalClose, backToLogin, requireAuth } =
    store

  useEffect(() => {
    if (!requireAuth) {
      redirectRoute(Routes.LOGIN)
    }
  }, [requireAuth])

  useEffect(() => {
    initTwoFAModalStore()
  }, [])

  return (
    <AuthForm title={<div className={styles.title}>{translations().title}</div>}>
      <div className={styles.back}>
        <Link onClick={backToLogin}>{translations().back}</Link>
      </div>

      <Typography.Text>{translations().text}</Typography.Text>

      <Button className={styles.button} type='primary' htmlType={'submit'} onClick={() => onTwoFAModalOpen()}>
        {translations().submit}
      </Button>

      <TwoFAModal onSubmit={enforceTokenTwoFA} onCancel={onTwoFAModalClose} centered />
    </AuthForm>
  )
})

export default TwoFAEnforced
