import React from 'react'
import { Col, Row, Typography } from 'antd'
import { Info } from '../../..'
import { AdditionalMerchantInfoType } from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v2'
import translations from './translations'
import styles from './styles.scss'
import { ProfileProps } from './props'

const { Title } = Typography

export const Profile: React.FC<{ profile: ProfileProps }> = ({ profile }) => {
  const {
    companyName,
    companyTradeName,
    companyNumber,
    companyWebsite,
    dateOfCreation,
    merchantCategoryCode,
    numberOfEmployees,
    tradeOutsideEea,
    tradingCountriesOutsideEea,
    tradingAddress,
    emailAddress,
    telephoneNumber,
    averageTransactionValue,
    expectedAnnualTurnover,
    expectedMonthlyTransactionValues,
    natureOfBusiness,
    natureOfBusinessDetails,
    companyAddress,
    dsrId,
    isActive,
    partner,
    segmentCategory,
    segmentSubCategory,
    acquisitionMgr,
    dnaAcquiring,
    tenantCode,
    tenantValue,
    marketCode,
    additionalInfo
  } = profile

  const renderBoolean = (value: boolean): string => (value ? translations().yes : translations().no)

  return (
    <>
      <Row>
        <Col xs={24}>
          <Title level={5} className={isActive ? styles.sectionTitle : styles.sectionTitleRed}>
            {translations().company(dsrId || '', isActive)}
          </Title>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().companyName} value={companyName} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().companyTradeName} value={companyTradeName} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().companyNumber} value={companyNumber} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().companyWebsite} value={companyWebsite} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().emailAddress} value={emailAddress} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().telephoneNumber} value={telephoneNumber} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().dateOfCreation} value={dateOfCreation} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().merchantCategoryCode} value={merchantCategoryCode} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().numberOfEmployees} value={numberOfEmployees} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().natureOfBusiness} value={natureOfBusiness} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().natureOfBusinessDetails} value={natureOfBusinessDetails} />
        </Col>
      </Row>

      <Row className={styles.sectionContainer}>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().marketCode} value={marketCode} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().dnaAcquiring} value={dnaAcquiring ? translations().yes : translations().no} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info
            label={translations().tenantValue}
            value={tenantValue}
            textColor={tenantCode !== 'dna' ? styles.redText : styles.greenText}
          />
        </Col>
      </Row>

      <Row className={styles.sectionContainer}>
        <Col xs={24}>
          <Title level={5} className={styles.sectionTitle}>
            {translations().segmentInformation}
          </Title>
        </Col>

        <Col xs={24} md={12} lg={8}>
          <Info label={translations().segmentCategory} value={segmentCategory} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().segmentSubCategory} value={segmentSubCategory} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().partner} value={partner} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().acquisitionMgr} value={acquisitionMgr} />
        </Col>
      </Row>

      <Row className={styles.sectionContainer}>
        <Col xs={24}>
          <Title level={5} className={styles.sectionTitle}>
            {translations().financialInformation}
          </Title>
        </Col>

        <Col xs={24} md={12} lg={8}>
          <Info label={translations().expectedAnnualTurnover} value={expectedAnnualTurnover} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().expectedMonthlyTransactionValues} value={expectedMonthlyTransactionValues} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().averageTransactionValue} value={averageTransactionValue} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().tradeOutsideEea} value={renderBoolean(tradeOutsideEea)} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Info label={translations().tradingCountriesOutsideEea} value={tradingCountriesOutsideEea} />
        </Col>
      </Row>
      <Row className={styles.sectionContainer}>
        {companyAddress?.length > 0 && (
          <Col xs={24}>
            <Title level={5} className={styles.sectionTitle}>
              {translations().companyAddress}
            </Title>
            <Row>
              <Col xs={24} md={12} lg={8}>
                <Info label={translations().addressLine1} value={companyAddress[0].addressLine1} />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Info label={translations().addressLine2} value={companyAddress[0].addressLine2} />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Info label={translations().townCity} value={profile.companyAddress[0].townOrCity} />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Info label={translations().countyOrState} value={profile.companyAddress[0].countyOrState} />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Info label={translations().postCode} value={profile.companyAddress[0].postalCode} />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Info label={translations().country} value={profile.companyAddress[0].country} />
              </Col>
            </Row>
          </Col>
        )}
        {tradingAddress?.length > 0 && (
          <Col xs={24}>
            <Title level={5} className={styles.sectionTitle}>
              {translations().tradingAddress}
            </Title>
            <Row>
              <Col xs={24} md={12} lg={8}>
                <Info label={translations().addressLine1} value={tradingAddress[0].addressLine1} />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Info label={translations().addressLine2} value={tradingAddress[0].addressLine2} />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Info label={translations().townCity} value={tradingAddress[0].townOrCity} />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Info label={translations().countyOrState} value={tradingAddress[0].countyOrState} />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Info label={translations().postCode} value={tradingAddress[0].postalCode} />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Info label={translations().country} value={tradingAddress[0].country} />
              </Col>
            </Row>
          </Col>
        )}
        {additionalInfo && additionalInfo.length > 0 && (
          <Col xs={24}>
            <Title level={5} className={styles.sectionTitle}>
              {translations().attributes}
            </Title>
            {additionalInfo.map((groupItem: AdditionalMerchantInfoType[], idx: number) => {
              return (
                <Row key={idx}>
                  {groupItem?.map((item: AdditionalMerchantInfoType, idx: number) => {
                    return (
                      <Col key={idx} xs={24} md={12} lg={8}>
                        <Info label={item?.displayValue} value={item?.value} />
                      </Col>
                    )
                  })}
                </Row>
              )
            })}
          </Col>
        )}
      </Row>
    </>
  )
}
