import translations from './translations'

translations.add('en', {
  partnerSuccessfulUpdated: 'Partner has been updated',
  partnerUpdateFailure: 'Partner update error',
  partnerSuccessfulAdded: 'Partner has been created',
  partnerAddFailure: 'Partner creation error',
  securitySettingsSuccessfulUpdated: 'Security settings have been updated',
  securitySettingsUpdateFailure: 'Security settings update error',
  loginSuccessfulAdded: 'Login has been added',
  loginSuccessfulUpdated: 'Login has been updated',
  loginAddFailure: 'Login adding error',
  loginUpdateFailure: 'Login update error',
  dna: 'DNA',
  dna_eu: 'DNA Europe',
  paynetics_eu: 'Paynetics Europe',
  
})
