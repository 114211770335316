import translations from '../translations'

export const jumioResultColumns = [
  {
    title: translations().name,
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: translations().result,
    dataIndex: 'result',
    key: 'result'
  }
]
