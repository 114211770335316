import React from 'react'
import { Button, List, Row, Col } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import sortBy from 'lodash/sortBy'
import { isCorporate } from '~/code/pages/StartApplication/services'
import { OfficerListItem } from '../OfficerListItem'
import { FormTitle, StartAppContainer, BelowActionsWithSave } from 'startapp/components'
import translations from './translations'
import { OfficerListPageProps } from './props'
import styles from './OfficerListPage.scss'

export const OfficerListPage: React.FC<OfficerListPageProps> = (props) => {
  const { title, text, list, isValid, onContinue, onAdd, isSaveLoading, onSave, isContinueLoading = false } = props
  const canSkip = isValid && list.length === 0

  return <StartAppContainer>
      <FormTitle>{title}</FormTitle>
      <p className={styles.text}>{text}</p>
      <Row>
        <Col xs={24}>
          <List
            itemLayout='horizontal'
            dataSource={list && sortBy(list, (item) => {
              if (isCorporate(item)) {
                return item.corporateName?.toLocaleLowerCase()
              }
              return item.name?.toLocaleLowerCase()
            })
            }
            renderItem={(item) => (
                <OfficerListItem
                    { ...item }
                />
            )}
          />
          {onAdd && (
            <div className={styles.addButtonContainer}>
              <Button icon={<PlusOutlined />} onClick={onAdd}>{translations().add}</Button>
            </div>
          )}
          <BelowActionsWithSave isLoading={isSaveLoading} onSave={onSave}>
            <Button
              type='primary'
              disabled={!isValid}
              onClick={onContinue}
              loading={isContinueLoading}
            >
              {canSkip ? translations().skip : translations().continue}
            </Button>
          </BelowActionsWithSave>
        </Col>
      </Row>
    </StartAppContainer>
}
