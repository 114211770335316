import React from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { Modal } from 'antd'
import { TwoFAModalStoreInterface } from '~/code/stores/Profile/TwoFAModalStore/TwoFAModalStoreInterface'
import { TwoFAModalStoreSymbol } from '~/code/pages/Profile/components/TwoFA/components/TwoFAModal'
import { SendVerificationCode } from '~/code/components'

import translations from './translations'

export const TwoFADisableModal = observer(() => {
  const { twoFAStore, updateTwoFAUserSettings } = useInjection<TwoFAModalStoreInterface>(TwoFAModalStoreSymbol)

  const { isDisableModalOpen, setIsDisableModalOpen } = twoFAStore

  const onSubmit = async v => {
    const result = await updateTwoFAUserSettings(v.verificationCode, true)

    if (result) onCancel()
  }

  const onCancel = () => setIsDisableModalOpen(false)

  return (
    <Modal width={'600px'} title={translations().title} open={isDisableModalOpen} footer={null} onCancel={onCancel}>
      <SendVerificationCode submitButtonText={translations().disable} onSubmit={onSubmit} onCancel={onCancel} danger />
    </Modal>
  )
})
