import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    merchant: string
    applicantName: string
    email: string
    phoneNumber: string
    startDate: string
    endDate: string
    currentStatus: string
    acquisitionChannel: string
    mid: string
    assignedGroup: string
    assignedUser: string
    initiatorEmail: string
    approvalType: string
    productType: string
    timeAtCurrentStatus: string
    terminatedProcessesSuccessfully: string
    terminatedProcessesError: string
    terminalIssueType: string
    segmentCategory: string
    segmentSubCategory: string
    opportunityOwner: string
    opportunityId: string
}>()

