import React from 'react'
import { Input, Form } from 'antd'
import translations from './translations'
import { SalesForceOppIDFieldItemPropsType } from './props'
import styles from './SalesForceOppIDFieldItem.scss'

export const SalesForceOppIDFieldItem: React.FC<SalesForceOppIDFieldItemPropsType> = props => {
  const { checkSalesforceOpportunityID, form, getOppOwnerInfo, isViewMode } = props
  return (
    <>
      <Form.Item
        name='salesforceOpportunityID'
        label={translations().salesforceOpportunityIDLabel}
        rules={[
          {
            required: true,
            message: translations().salesforceOpportunityIDRequired
          },
          {
            min: 15,
            message: translations().salesforceOpportunityIDMin
          },
          {
            validator: async (rule, value) => {
              let errMessage = ''
              if (value) errMessage = await checkSalesforceOpportunityID(value)
              if (errMessage) {
                form.setFields([{ name: 'salesforceOpportunityID', errors: [errMessage] }])

                throw new Error(errMessage)
              } else {
                value && getOppOwnerInfo(value)
              }
            }
          }
        ]}
        validateTrigger='onChange'
      >
        <Input disabled={isViewMode} placeholder='0063M000003ukUuQAI' maxLength={18} />
      </Form.Item>
      <div className={styles.note}>{translations().oppIdNote}</div>
    </>
  )
}
