import React, { useCallback } from 'react'
import debounce from 'lodash.debounce'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Select, SelectProps, Spin } from 'antd'
import { TwoLine } from '~/code/components'
import { MerchantModel } from '~/code/models/handbooks'

import translations from '../../translations'
import styles from './styles.scss'

export type PortalAccessMerchantSelectProps = SelectProps & { merchants: MerchantModel[] }

export const PortalAccessMerchantSelect = observer((props: PortalAccessMerchantSelectProps) => {
  const { onSearch, className, loading, merchants, ...rest } = props

  const prepareOptions = () => {
    const options: SelectProps['options'] = merchants?.map((m, idx) => ({
      value: m.id,
      label: <TwoLine primary={m.tradeName} secondary={m.companyName} />,
      prettiedLabel: m.tradeName,
      key: `${m.id}-${idx}`
    }))

    if (loading) {
      options.push({
        value: 'loading',
        label: selectSpinner,
        disabled: true
      })
    }

    return options
  }

  const spinner = (
    <div className={styles.spinContainer}>
      <Spin />
    </div>
  )

  const selectSpinner = <Select.Option value='loading'>{spinner}</Select.Option>

  const delayedQuery = useCallback<(value: string) => void>(
    debounce((v: string) => onSearch(v), 500),
    [onSearch]
  )

  return (
    <Select
      className={classNames(styles.select, className)}
      showSearch
      allowClear
      filterOption={false}
      loading={loading}
      placeholder={translations().merchantSubLabel}
      onSearch={delayedQuery}
      notFoundContent={loading ? spinner : translations().noMerchants}
      {...rest}
      optionLabelProp={'prettiedLabel'}
      options={prepareOptions()}
    />
  )
})
