import { Popover, Tabs } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { TabKey } from '../../models'
import { MainFormProps } from './props'
import translations from './translations'
import styles from './MainForm.scss'
import { CloseCircleOutlined } from '@ant-design/icons'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import { CustomModal } from '~/code/components'
import { generateMainFormTabItems } from './services'

export const MainForm = observer(({ store }: MainFormProps) => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (
      store.dataStore.applicationId &&
      store.dataStore.sentToDocusign &&
      !store.dataStore.isDocusignSignedSuccessfully
    ) {
      store.supportingDocumentsStore.checkDocusignStatus()
    }
  }, [store.dataStore.applicationId, store.dataStore.isApplicationValidWithoutDocuments])

  return (
    <>
      {store.dataStore.applicationId && (
        <div className={styles.procHeader}>
          <b>
            {translations().companyName} {store.dataStore.application.companyProfile?.companyName}
          </b>
          <div>
            <Popover placement='leftTop' content={translations().closeProcess}>
              <CloseCircleOutlined className={styles.closeIcon} onClick={() => setShowModal(true)} />
            </Popover>
          </div>
        </div>
      )}
      <Tabs
        className={styles.styledTab}
        tabPosition='right'
        size='large'
        activeKey={store.activeTabKey}
        onChange={(activeKey: TabKey) => store.setActiveTabKey(activeKey)}
        items={generateMainFormTabItems(store)}
        onTabClick={(key: TabKey) => {
          key === 'stores' && store.bankAccountStore.reset()
        }}
      />

      <div className={styles.watermark}>
        <p>{translations().beta}</p>
        <p>{translations().contactEmail}</p>
      </div>

      <CustomModal
        title={translations().closeProcess}
        open={showModal}
        onOk={() => {
          goToRoute(Routes.APPLICATIONS_START_NEW_PROCESS, null, true)
          setShowModal(false)
        }}
        onCancel={() => setShowModal(false)}
      >
        {translations().closeProcessDescription}
      </CustomModal>
    </>
  )
})
