import { Breadcrumb } from 'antd'
import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { PageHeaderRawProps } from './props'
import styles from './PageHeaderRaw.scss'

export const PageHeaderRaw: React.FC<PropsWithChildren<PageHeaderRawProps>> = props => {
  const { breadcrumb, hasFooter, className, children } = props
  const hasBreadcrumb = Boolean(breadcrumb) && breadcrumb.routes?.length > 0

  return (
    <div
      className={classNames(styles.PageHeaderRaw, className, {
        [styles.hasFooter]: hasFooter,
        [styles.hasBreadcrumb]: hasBreadcrumb
      })}
    >
      {hasBreadcrumb && <Breadcrumb {...breadcrumb} />}
      {children}
    </div>
  )
}
