import { action, makeObservable, observable } from 'mobx'
import { RiskLimitsModel } from './models/merchant-dossier-v1/RiskLimitsModel'
import { CurrentRiskLimitsModel } from './models/merchant-dossier-v1/CurrentRiskLimitsModel'
import {
    convertLimitFields,
    getDefaultLimitFields
} from '~/code/pages/MerchantsManagement/components/RiskLimits/components/ChangeRiskLimitsModal/services'
import { injectable } from 'inversify'


@injectable()
export class ChangeRiskLimitsStore {
    public isLoading: boolean
    public isShowModal: boolean
    public initialValues: RiskLimitsModel
    public currentValues: CurrentRiskLimitsModel
    public comment: string

    constructor() {
        this.isLoading = false
        this.isShowModal = false
        this.initialValues = null
        this.currentValues = null
        this.comment = ''

        makeObservable(this, {
            isShowModal: observable,
            isLoading: observable,
            initialValues: observable,
            currentValues: observable,
            comment: observable,
            showModal: action.bound,
            reset: action.bound
        })
    }

    public reset() {
        this.isLoading = false
        this.isShowModal = false
        this.initialValues = null
        this.currentValues = null
        this.comment = ''
    }

    public startLoading() {
        this.isLoading = true
    }

    public stopLoading() {
        this.isLoading = false
    }

    public showModal(initialValues) {
        this.currentValues = getDefaultLimitFields(convertLimitFields(initialValues))
        this.initialValues = getDefaultLimitFields(convertLimitFields(initialValues))
        this.comment = ''
        this.isShowModal = true
    }

    public closeModal() {
        this.reset()
    }

    public onChangeCurrentValues(values) {
        this.comment = values.comment
        this.currentValues = convertLimitFields(values)
    }
}
