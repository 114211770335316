import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    headlineRate: string
    transactionType: string
    backText: string
    blended: string
    icPlus: string
    cardScheme: string
    serviceArea: string
    corporateOrConsumer: string
    cardType: string
    baseFee: string
    percentFee: string
    skip: string
    warningTitle: string
    ensureMSA: string
    wrongFee: string
    doubleCheck: string
}>()

