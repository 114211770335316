import translations from './translations'

translations.add('en', {
    fields: 'Fields',
    web: 'Web',
    mobile: 'Mobile',
    notificationDetails: 'Notification Details',
    templateName: 'Template Name',
    system: 'System',
    displayType: 'Display Type',
    level: 'Level',
    contentType: 'Content Type',
    templateTitle: 'Template Title',
    templateDescription: 'Template Description',
    buttonUrl: 'Button Url',
    buttonName: 'Button Name',
    back: 'Back',
    save: 'Save',
    preview: 'Preview',
    type: 'Type',
    name: 'Name',
    description: 'Description',
    title: 'Title',
    contentArea: 'Content Area',
})
