import { Badge, Button, Col, Collapse, Input, List, Modal, Row, Space, Typography } from 'antd'
import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { getBpmStatusColor } from '~/code/services/utils'
import { ProcessesStore } from '../../ProcessesStore'
import translations from './translations'
import styles from './TerminateProcessModal.scss'

const { Panel } = Collapse

interface Props {
    store: ProcessesStore
}


export const TerminateProcessModal = observer((props: Props) => {
    const { store } = props
    const isOneProcess = store.selectedProcesses.length === 1

    const [terminateReason, setTerminateReason] = React.useState<string>('')
    const [isCollapseOpen, setCollapseOpen] = React.useState(false)

    useEffect(() => setCollapseOpen(isOneProcess), [store.selectedProcesses])

    const onConfirm = () => {
        store.terminateProcesses(terminateReason)
        setTerminateReason('')
    }

    const onCancel = () => {
        store.setTerminateModalVisible(false)
    }

    return <Modal
        open={store.isTerminateModalVisible}
        onOk={onConfirm}
        onCancel={onCancel}
        footer={[
            <Button key='No' onClick={onCancel}>
                {translations().no}
            </Button>,
            <Button
                key='Terminate'
                onClick={onConfirm}
                danger
                type='primary'
                loading={store.isTerminatingProcesses}
                disabled={!terminateReason?.trim()}
            >
                {translations().yesTerminateProcess}
            </Button>
        ]}
    >
        <p>{isOneProcess ? translations().areYouSureTerminateProcess : translations().areYouSureTerminateProcesses}</p>
        <Collapse activeKey={ isCollapseOpen ? '1' : '' } onChange={() => setCollapseOpen(!isCollapseOpen)}>
            <Panel 
                key='1'
                className={styles.collapsePanel}
                header={isOneProcess ? translations().seeProcess : translations().seeProcesses}
            >
                <List
                    itemLayout='vertical'
                    dataSource={store.selectedProcesses}
                    size='small'
                    renderItem={item => (
                        <List.Item>
                            <Row>
                                <Col xs={24} className={styles.statusContainer}>
                                    <Badge status={getBpmStatusColor(item.status)} text={item.processStatus} />
                                </Col>
                                <Col xs={24} sm={10}>
                                    <Space direction='vertical'>
                                        <Typography.Text type='secondary'>{item.companyName}</Typography.Text>
                                        <Typography.Text type='secondary'>{item.applicantName}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col xs={24} sm={14}>
                                    <Space direction='vertical'>
                                        <Typography.Text type='secondary'>{item.email}</Typography.Text>
                                        <Typography.Text type='secondary'>{item.phoneNumber}</Typography.Text>
                                    </Space>
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </Panel>
        </Collapse>
        <p className={styles.reasonForTerminating}>{translations().reasonForTerminating}</p>
        <Input.TextArea
            rows={4}
            value={terminateReason}
            onChange={(e) => {
                setTerminateReason(e.target.value)
            }}
        />
    </Modal>
})
