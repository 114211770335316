import React from 'react'
import { Button, Card, Modal, Row, Table, Typography } from 'antd'
import { chargebackDetailsColumns, chargebackDetailsOriginalTransactionsColumns } from '~/code/pages/Chargebacks/constants/chargebacks-constants'
import { ChargebacksDetailModalProps } from '~/code/pages/Chargebacks/components'
import translations from './translations'
import styles from './styles.scss'

export const ChargebacksDetailsModal = (props: ChargebacksDetailModalProps) => {

    const {
        id,
        details,
        open,
        onOk,
        title,
        isLoading,
    } = props

    const dataSource = details.map((item, index) => ({
        key: index,
        ...item
    })).filter((item) => item.category !== 'original-transaction')

    const originalTransactionDataSource = details.map((item, index) => ({
        key: index,
        ...item
    })).filter((item) => item.category === 'original-transaction')

    return (
        <Modal
            width={1100}
            title={title}
            open={open}
            onCancel={() => onOk(false)}
            footer={[
                <Button type={'primary'} onClick={() => onOk(false)}>Ok</Button>
            ]}
        >
            <Typography.Title level={5}>{`${translations().chargebackId} ${id}`}</Typography.Title>
            {originalTransactionDataSource && originalTransactionDataSource.length ? (
                <Row>
                    <Table
                        className={styles.detailsTable}
                        title={() => (<Typography.Title level={5}>{translations().originalTransactionDetails}</Typography.Title>)}
                        size={'small'}
                        loading={isLoading}
                        columns={chargebackDetailsOriginalTransactionsColumns}
                        dataSource={originalTransactionDataSource}
                        pagination={false}
                        scroll={{ x: 1000, y: 1000 }}
                    />
                </Row>
            ): null}
            <Row>
                <Table
                    className={styles.detailsTable}
                    title={() => (<Typography.Title level={5}>{translations().chargebackHistory}</Typography.Title>)}
                    size={'small'}
                    loading={isLoading}
                    columns={chargebackDetailsColumns}
                    dataSource={dataSource}
                    pagination={false}
                    scroll={{ x: 1000, y: 1000 }}
                />
            </Row>
        </Modal>
    )
}
