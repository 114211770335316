import translations from './translations'

translations.add('en', {
    title: 'Title',
    system: 'System',
    type: 'Type',
    actions: 'Actions',
    companyName: 'Company Name',
    descriptor: 'Descriptor',
    publish: 'Publish',
    email: 'Email',
    firstName: 'First Name',
    lastName: 'Last Name',
    view: 'View',
    select: 'Select',
    selectTemplate: 'Select Template',
    selectRecipient: 'Select Recipient(s) & Publish',
    preview: 'Preview',
    description: 'Description',
    displayType: 'Display Type'
})
