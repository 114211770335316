import translations from './translations'

translations.add('en', {
    posProductType: 'POS',
    ecomProductType: 'ECOM',
    settlementPeriod: 'Settlement Period (business days): ',
    documents: 'Documents',
    bankStatement: 'Bank Statement',
    processingStatement: 'Processing statement',
    other: 'Other',
    upload: 'Upload',
    changeOfSettlementPeriod: 'Change of Settlement Period',
    currentSettlementBusinessDays: 'Current Settlement Period (business days)',
    newSettlementBusinessDays: 'New Settlement Period (business days)',
    comment: 'Comment',
    submit: 'Submit',
    required: 'Required',
    days: 'Days',
    documentType: 'Document type',
    noPos: 'No POS Terminals Available',
    noEcom: 'No ECOM Terminals Available',
    documentsRequired: 'Documents Required',
    provideInfo: 'Provide Info',
    provideAdditionalInfo: 'Provide Additional Info',
    change: 'Change',
    businessDays: 'Settlement Period (business days):'
})