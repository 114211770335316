import translations from './translations'

translations.add('en', {
  edit: 'Edit User',
  cancel: 'Cancel',
  submit: 'Save',
  formFields: {
    email: 'Email',
    firstName: 'First name',
    lastName: 'Last name',

  },
  formErrors: {
    emailRequired: 'email is required',
    enterValidEmail: 'please enter valid email',
    firstNameRequired: 'first name is required',
    lastNameRequired: 'last name is required',
  }
})
