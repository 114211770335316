import translations from './translations'

translations.add('en', {
  courierServiceUsed: 'Courier Service Used',
  deliveryTime: 'Delivery Time',
  isTrue: 'Is True',
  quarter1: 'Quarter 1',
  quarter2: 'Quarter 2',
  quarter3: 'Quarter 3',
  quarter4: 'Quarter 4',
  face2face: 'Face 2 Face',
  seasonalBusiness: 'Seasonal Business',
  yes: 'Yes',
  no: 'No'
})
