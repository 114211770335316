import { Table } from 'antd'
import React from 'react'
import { ProductsPropsType } from './props'
import translations from './translations'

export const Products = ({ data }: ProductsPropsType) => {
  const dt = data.map((prd, indx) => ({
    key: indx,
    name: prd.name,
    model: prd.model,
    count: prd.count,
    ws: prd.website
  }))

  const columns = [
    {
      title: translations().productName,
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: translations().model,
      key: 'model',
      dataIndex: 'model'
    },
    {
      title: translations().website,
      key: 'ws',
      dataIndex: 'ws'
    },
    {
      title: translations().count,
      key: 'count',
      dataIndex: 'count'
    }
  ]
  return (
    <>
      {dt.length > 0 && (
        <>
          <Table columns={columns} dataSource={dt} />
        </>
      )}
    </>
  )
}
