import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  commonInfo: string
  companyName: string
  commentMinMsg: string
  commentRequired: string
  commentLabel: string
  complete: string
}>()
