import { Badge, Button, Card, Pagination, Table, Tooltip } from 'antd'
import { TablePaginationConfig } from 'antd/lib/table'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'
import { formatAmount } from '~/code/services/utils'
import { ReloadButton, ResizableColumnTitle } from '~/code/components'
import translations from './translations'
import { TransactionsStatementProps } from './props'
import styles from './TransactionsStatement.scss'
import { TransactionModel } from '~/code/models'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'
import { DownloadOutlined } from '@ant-design/icons'
import { dodgerBlue } from '~/code/constants/colors'


export const TransactionsStatement = observer((props: TransactionsStatementProps) => {
  const { store } = props
  const { tableStore, totalAmount } = store

  const filteredColumns = store.columns
  const tableHeight = 980

  const paginationProps: TablePaginationConfig = generateTablePaginationProps(
      tableStore.currentPage,
      tableStore.pageSize,
      tableStore.totalCount,
      (pageNumber) => {
        tableStore.loadData({ pageNumber })
      },
      tableStore.setPageSize
  )

  return <>
    <Card>
      <div className={styles.header}>
        <div className={styles.title}>
          <Badge status='processing' color={dodgerBlue} text={
              <><Tooltip title={translations().title}>{translations().shortTitle}</Tooltip>: <strong className={styles.totalAmount}>{store.currencySymbol} {formatAmount(totalAmount)}</strong></>
          } />
        </div>
        <div className={styles.paginationContainer}>
          <Button
              className={styles.exportButton}
              icon={<DownloadOutlined />}
              type={'primary'}
              onClick={() => store.openExportModal(true)}
          >
            {translations().export}
          </Button>
          <ReloadButton
            onClick={ () => tableStore.loadData() }
            className={styles.reloadButton}
          />
          <Pagination {...paginationProps} />
        </div>
      </div>
      {
        tableStore.isLoading ?
          <Table
            size='small'
            bordered
            columns={filteredColumns}
            loading={tableStore.isLoading}
            scroll={{ x: 1000, y: tableHeight }}
            components={{ header: { cell: ResizableColumnTitle } }}
            className={classNames(styles.transactionsTable, 'dashboard-table')}
            pagination={false}
          />
          :
          <Table
            size='small'
            bordered
            virtual
            columns={filteredColumns}
            scroll={{ x: 1000, y: tableHeight }}
            components={{ header: { cell: ResizableColumnTitle } }}
            dataSource={tableStore.data ? tableStore.data.map((item, i) => {
              const ecomItem = item as TransactionModel
              return ({ ...item, key: `${ecomItem.id}-${i}` })
            }) : null }
            loading={tableStore.isLoading}
            className={classNames(styles.transactionsTable, 'dashboard-table', 'tableWithBottomPadding')}
            pagination={false}
          />

      }
      <div className={styles.paginationContainer} style={{marginTop: 15}}>
        <Pagination {...paginationProps} />
      </div>
    </Card>
  </>
})
