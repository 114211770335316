import React from 'react'
import { CustomVerificationInfoPropsType } from './props'
import { Collapse, Divider } from 'antd'
import translations from './translations'
import styles from './CustomVerificationInfo.scss'
import { groupBy } from 'lodash'

export const CustomVerificationInfo: React.FC<CustomVerificationInfoPropsType> = ({ data }) => {
  const dataGrouped = groupBy(data, item => item.ecospendAccountBankNumber)
  return (
    <>
      <h4 className={styles.title}>{translations().dnaAlgorithm}</h4>
      <Collapse defaultActiveKey={'0'}>
        {Object.entries(dataGrouped).map((gr, id) => (
          <Collapse.Panel header={`${translations().account} ${gr[0]}`} key={id}>
            {gr[1]?.map((d, idx) => (
              <div key={idx}>
                {idx === 0 && (
                  <>
                    <div className={styles.flex}>
                      <p>{translations().sortCode} </p>
                      <p
                        className={
                          d.ecospendSortCode?.replace(/\s|-/g, '') === d.sortCode?.replace(/\s|-/g, '')
                            ? styles.green
                            : styles.red
                        }
                      >
                        : {d.ecospendSortCode}
                      </p>
                    </div>

                    <div className={styles.flex}>
                      <p>{translations().accountNumber}</p>
                      <p
                        className={
                          d.ecospendAccountBankNumber?.replace(/\s/g, '') === d.bankAccountNumber?.replace(/\s/g, '')
                            ? styles.green
                            : styles.red
                        }
                      >
                        : {d.ecospendAccountBankNumber}
                      </p>
                    </div>
                  </>
                )}

                {d.ecospendAccountBankNumber?.replace(/\s/g, '') === d.bankAccountNumber?.replace(/\s/g, '') && (
                  <div className={styles.flex}>
                    <p>{translations().parties}</p>
                    <p>: {d.parties}</p>
                  </div>
                )}

                <div className={styles.flex}>
                  <p>{d.label || translations().accountName}</p>
                  <p>: {d.nameForCompare}</p>
                </div>
                <div className={styles.flex}>
                  <p>{translations().overallScore}</p>
                  <p className={d.overallScore > 65 ? styles.green : styles.red}>: {d.overallScore}</p>
                </div>
                <Divider />
              </div>
            ))}
          </Collapse.Panel>
        ))}
      </Collapse>
    </>
  )
}
