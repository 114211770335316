import translations from './translations'

translations.add('en', {
    firstName: 'First Name',
    surname: 'Surname',
    merchant: 'Merchant',
    companyNumber: 'Company Number',
    phoneNumber: 'Phone Number',
    name: 'Name',
    email: 'Email',
    comment: 'Comment',
    companyInfo: 'Company Info',
    initiatorInfo: 'Initiator Info',
    reviewerInfo: 'Reviewer Info'
})
