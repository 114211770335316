import { Card, Form, Select, Table } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { DefaultPagination, PaginationContainer, ReloadButton } from '~/code/components'
import translations from './translations'
import styles from './Exports.scss'
import { ExportsStoreType } from './ExportsStoreType'
import { columns } from './constants'

export const ExportsStoreSymbol = Symbol('ExportsStore')

export const Exports = observer(() => {
    const { jobsTableStore, jobName, setJobName, jobNameOptions } = useInjection<ExportsStoreType>(ExportsStoreSymbol)

    useEffect(() => {
        if (!jobsTableStore.isLoading) {
            jobsTableStore.loadData({ pageNumber: 1 })
        }
    }, [])

    return <div>
        <Card className={styles.filters}>
            <Form.Item label={translations().jobNameLabel}>
                <Select
                    className={styles.select}
                    value={jobName}
                    onChange={setJobName}
                    options={jobNameOptions}
                />
            </Form.Item>
        </Card>
        <Card loading={jobsTableStore.isLoading}>
            <div>{translations().tableTitle}</div>
            <PaginationContainer position='top'>
                <ReloadButton onClick={() => jobsTableStore.loadData()} />
                <DefaultPagination store={jobsTableStore} />
            </PaginationContainer>
            <Table
                columns={columns}
                dataSource={jobsTableStore.viewData.map((job, idx) => ({ ...job, key: `${job.id}-${idx}` }))}
                pagination={false}
                scroll={{ x: 1000, y: 1000 }}
            />
            <PaginationContainer position='bottom'>
                <DefaultPagination store={jobsTableStore} />
            </PaginationContainer>
        </Card>
    </div>
})