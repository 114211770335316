import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    terminalTypes: {
        all: string
        ecom: string
        pos: string
    }
    terminalType: string
    store: string
    terminal: string
    all: string
    mid: string
}>()

