import React from 'react'
import moment from 'moment'
import { Badge } from 'antd'
import { Nowrap } from '~/code/components'
import { TIDS_PATTERN } from '~/code/constants/Patterns'
import { SubmittedRequestsStatus } from '~/code/stores/SubmittedRequestsStore/models'
import translations from '../translations'

const renderStatus = (status: SubmittedRequestsStatus) => {
    switch (status) {
        case SubmittedRequestsStatus.APPROVED:
            return <Badge
                status={ 'success' }
                text={ status }
            />
        case SubmittedRequestsStatus.REJECTED:
            return <Badge
                status={ 'error' }
                text={ status }
            />
        default:
            return (
                <Badge
                    text={ status }
                    color={ 'yellow' }
                />
            )
    }
}

export const columns = [
    {
        title: translations().startDate,
        dataIndex: 'startTime',
        key: 'startTime',
        render: (date) => date ? moment(date).format('DD.MM.YYYY HH:mm:ss') : ''
    },
    {
        title: translations().merchant,
        dataIndex: 'companyName',
        key: 'companyName'
    },
    {
        title: translations().mid,
        dataIndex: 'existingMerchantID',
        key: 'existingMerchantID'
    },
    {
        title: translations().posModel,
        dataIndex: 'POSModel',
        key: 'POSModel'
    },
    {
        title: translations().quantity,
        dataIndex: 'newTerminalQnt',
        key: 'newTerminalQnt'
    },
    {
        title: translations().issuedTid,
        dataIndex: 'midsAndTids',
        key: 'midsAndTids',
        render: (midsAndTids: string) => {
            return midsAndTids?.match(TIDS_PATTERN).map((match) => match.replace(/tid:\s*(\d+);/, '$1')).join('\n')
        }
    },
    {
        title: translations().initiator,
        dataIndex: 'processInitiator',
        key: 'processInitiator'
    },
    {
        title: translations().status,
        dataIndex: 'processStatus',
        key: 'processStatus',
        render: (status: SubmittedRequestsStatus) => status ?
            <Nowrap width={94} title={status}>
                { renderStatus(status) }
            </Nowrap>
            : ''
    },
    {
        title: translations().endDate,
        dataIndex: 'endTime',
        key: 'endTime'
    }
]
