import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  personalName: string
  email: string
  telephoneNumber: string
  acquisitionChannel: string
  opportunityId: string
  initiatorEmail: string
}>()
