import { Table } from 'antd'
import React from 'react'
import { TerminalOrdersPropsType } from './props'
import translations from './translations'

export const TerminalOrders = ({ data }: TerminalOrdersPropsType) => {
  const dt = data.map((prd, indx) => ({
    key: indx,
    storeName: prd.storeName,
    paymentSolution: prd.paymentSolution,
    count: prd.count,
    moto: prd.motoRequested ? translations().yes : translations().no
  }))

  const columns = [
    {
      title: translations().storeName,
      dataIndex: 'storeName',
      key: 'storeName'
    },
    {
      title: translations().paymentSolution,
      key: 'paymentSolution',
      dataIndex: 'paymentSolution'
    },
    {
      title: translations().motoRequested,
      key: 'moto',
      dataIndex: 'moto'
    },
    {
      title: translations().count,
      key: 'count',
      dataIndex: 'count'
    }
  ]
  return (
    <>
      {dt.length > 0 && (
        <>
          <Table columns={columns} dataSource={dt} />
        </>
      )}
    </>
  )
}
