import { injectable } from 'inversify'
import { IAnalyticsOverviewStore } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview'
import { IAcquiringFilterStore } from '~/code/pages/Acquiring/components/AcquiringFilter'
import { AnalyticsPosOverviewStore } from './AnalyticsPosOverviewStore'
import { IAnalyticsDetailedViewStore } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsDetailedView/models/IAnalyticsDetailedViewStore'
import { AnalyticsPosDetailedViewStore } from './AnalyticsPosDetailedViewStore'
import { GlobalConfigStore } from '../GlobalConfigStore'
import { IAnalyticsStore } from '~/code/pages/Acquiring/pages/Analytics'

@injectable()
export class AnalyticsPosStore implements IAnalyticsStore {
  isInitiated = false

  constructor(filterStore: IAcquiringFilterStore, globalConfigStore: GlobalConfigStore) {
    this.analyticsOverviewStore = new AnalyticsPosOverviewStore(filterStore, globalConfigStore)
    this.analyticsDetailedViewStore = new AnalyticsPosDetailedViewStore(filterStore, globalConfigStore)
  }

  // child stores
  analyticsOverviewStore: IAnalyticsOverviewStore
  analyticsDetailedViewStore: IAnalyticsDetailedViewStore

  public init = () => {
    this.isInitiated = true
    ;(this.analyticsOverviewStore as AnalyticsPosOverviewStore).init()
    ;(this.analyticsDetailedViewStore as AnalyticsPosDetailedViewStore).init()
  }
}
