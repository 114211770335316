import React from 'react'
import translations from './translations'

translations.add('en', {
    yesterday: 'Yesterday',
    week: 'This Week',
    lastWeek: 'Last Week',
    month: 'This Month',
    lastMonth: 'Last Month',
    merchant: 'Merchant',
    noMerchant: 'No merchants',
    selectMerchant: 'Select a merchant',
    selectMerchantOrAcquisitionChannelFirst: 'Please select a merchant or an acquisition channel first',
    selectDatesFirst: 'Please select dates first',
    selectMerchantFirst: 'Please select a merchant first',
    willBeAvailableAfter6AM: 'Will be available today after 6:00 AM UK Time',
    willBeAvailableOnMondayAfter6AM: 'Will be available on Monday after 6:00 AM UK Time',
    all: 'ALL',
    errLoadingAccountNumbers: 'Error loading account numbers',
    accountNumber: 'Account number',
    viewStatement: 'View',
    warnText: <>
        To view statement in the table below press the <strong>"View"</strong> button. <br/>
        To download/export statement press the <strong>"Download"</strong> button. <br/><br/>
        <strong>"View"</strong> and <strong>"Download"</strong> functions work independently, you don't need to press view button to download statement.
    </>
})
