import { Card, Empty, Pagination, Table, TablePaginationConfig } from 'antd'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import classNames from 'classnames'
import { ReloadButton, ResizableColumnTitle } from '~/code/components'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'
import translations from './translations'
import styles from './SettlementsTable.scss'
import { SettlementsTableProps } from './SettlementsTableProps'

export const SettlementsTable = observer((props: SettlementsTableProps) => {
    const { columns, tableStore, tableWidth, emptyText: emptyTextProp } = props

    const tableHeight = 980

    const paginationProps: TablePaginationConfig = generateTablePaginationProps(
        tableStore.currentPage,
        tableStore.pageSize,
        tableStore.totalCount,
        (pageNumber) => {
            tableStore.loadData({ pageNumber })
        },
        tableStore.setPageSize
    )

    const isPaginationDisabled = useMemo(() => !tableStore.data || tableStore.data.length === 0, [tableStore.data])

    const tableEmptyContent = () => {
        const emptyText = emptyTextProp || translations().noData
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyText}/>
    }

    return <Card>
        <div className={classNames(styles.paginationContainer, styles.paginationContainer__top)} style={{ marginBottom: 15 }}>
            <ReloadButton
                onClick={ () => tableStore.loadData() }
                className={styles.reloadButton}
                disabled={isPaginationDisabled}
            />
            <Pagination {...paginationProps} disabled={isPaginationDisabled}/>
        </div>
        {
            tableStore.isLoading ?
                <Table
                    size='small'
                    bordered
                    scroll={{ x: tableWidth, y: tableHeight }}
                    columns={columns}
                    loading={tableStore.isLoading}
                    pagination={false}
                />
            :
                <Table
                    size='small'
                    bordered
                    virtual
                    locale={{ emptyText: tableEmptyContent() }}
                    columns={columns}
                    scroll={{ x: tableWidth, y: tableHeight }}
                    components={{ header: { cell: ResizableColumnTitle } }}
                    dataSource={tableStore.data ? tableStore.data.map((item, i) => ({ ...item, key: `${i}` })) : null }
                    loading={tableStore.isLoading}
                    pagination={false}
                    className={classNames('dashboard-table', 'tableWithBottomPadding')}
                />
        }
        <div className={classNames(styles.paginationContainer, styles.paginationContainer__bottom)}>
            <Pagination {...paginationProps} disabled={isPaginationDisabled}/>
        </div>
    </Card>
})
