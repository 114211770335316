import { inject, injectable } from 'inversify'
import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx'
import { message } from 'antd'
import { log } from 'dna-common'
import { UploadFile } from 'antd/lib/upload/interface'
import { RangePickerStoreInjectable } from '~/code/stores/RangePickerStoreInjectable'
import { PeriodType } from '~/code/models'
import { PAGE_SIZE_10 } from '~/code/constants/Configurations'
import commonTranslations from '~/code/translations/translations'
import { SubmittedChangeBankDetailsApplicationReport } from '~/code/pages/BPMProcesses/Requests/models'
import { getProcessStatuses } from '~/code/pages/BPMProcesses/Requests/services'
import {
  ChangeBankDetailsDossierV2MerchantSelectStoreSymbol,
  ChangeBankDetailsDossierV2RangePickerStoreSymbol
} from '~/code/pages/BPMProcesses/Requests/components/ChangeBankDetailsDossierV2'
import { getFullData } from '../SubmittedRequestsStore/services'
import { MerchantSelectStore } from '../MerchantsManagementStore/MerchantSelectStore'
import {
  IChangeBankDetailsDossierV2Store,
  FileKey
} from '~/code/pages/BPMProcesses/Requests/components/ChangeBankDetailsDossierV2/IChangeBankDetailsDossierV2Store'
import { CheckedDocumentType } from 'startapp/models/DocumentsRequiredModel'
import { UploadDocumentRes } from 'startapp/components/SupportingDocuments/models'
import { deleteDocument, uploadDocument } from '~/code/pages/StartApplication/components/SupportingDocuments/services'
import { camelToKebabCase } from '~/code/pages/StartApplication/services'
import { sanitizeString, sanitizeFileName } from '../MerchantsManagementStore/services/utils'
import { getSubmittedApplicationsChangeBankDetails } from '~/code/pages/BPMProcesses/Requests/services'
import { ChangeBankDetailsProcessDetailsType, OpenBankingResultsType } from './models'
import { completeApplication, terminateApplication, getProcessDetails, openBankingResultsCheck } from './services'
import translations from './translations'

@injectable()
export class ChangeBankDetailsDossierV2Store implements IChangeBankDetailsDossierV2Store {
  dateStore: RangePickerStoreInjectable
  merchantSelectStore: MerchantSelectStore
  isProcessesLoading: boolean
  isStatusesLoading: boolean
  total: number
  processes: SubmittedChangeBankDetailsApplicationReport[]
  statuses: { label: string; value: string }[]
  status: string | null = null
  currentPage: number
  pageSize: number
  isProcessDetailsLoading: boolean
  hasProcessDetailsLoadingError: boolean
  selectedProcessId: string
  selectedProcessStatus: string
  selectedEmail: string
  processDetails: ChangeBankDetailsProcessDetailsType
  isShowInfoDrawer: boolean
  openBankingResults: string
  openBankingResultsFull: OpenBankingResultsType
  isDocumentLoading: boolean
  documentTypes: FileKey[]
  documentsMap: Record<FileKey, UploadFile[]>
  documentsData: UploadDocumentRes[]
  isCompleteApplicationLoading: boolean
  isTerminateProcessLoading: boolean
  defaultPageNumber: number
  constructor(
    @inject(ChangeBankDetailsDossierV2RangePickerStoreSymbol) rangePickerStoreInjectable: RangePickerStoreInjectable,
    @inject(ChangeBankDetailsDossierV2MerchantSelectStoreSymbol) merchantSelectStore: MerchantSelectStore
  ) {
    this.dateStore = rangePickerStoreInjectable
    this.merchantSelectStore = merchantSelectStore
    this.isProcessesLoading = false
    this.isStatusesLoading = false
    this.isProcessDetailsLoading = false
    this.hasProcessDetailsLoadingError = false
    this.selectedProcessId = null
    this.selectedProcessStatus = null
    this.processDetails = null
    this.isShowInfoDrawer = false
    this.openBankingResults = 'SENT'
    this.openBankingResultsFull = null
    this.isDocumentLoading = false
    this.isCompleteApplicationLoading = false
    this.isTerminateProcessLoading = false
    this.selectedEmail = ''
    this.documentTypes = []
    this.documentsMap = {
      bankStatement: [],
      processingStatement: [],
      other: []
    }
    this.documentsData = []
    this.processes = []
    this.statuses = []
    this.currentPage = 1
    this.pageSize = 10
    this.total = 0
    this.defaultPageNumber = 1

    makeObservable(this, {
      isProcessesLoading: observable,
      isStatusesLoading: observable,
      processes: observable,
      statuses: observable,
      status: observable,
      currentPage: observable,
      pageSize: observable,
      total: observable,
      isProcessDetailsLoading: observable,
      hasProcessDetailsLoadingError: observable,
      selectedProcessId: observable,
      selectedProcessStatus: observable,
      processDetails: observable,
      isShowInfoDrawer: observable,
      openBankingResults: observable,
      openBankingResultsFull: observable,
      isDocumentLoading: observable,
      documentTypes: observable,
      documentsMap: observable,
      documentsData: observable,
      isCompleteApplicationLoading: observable,
      isTerminateProcessLoading: observable,
      selectedEmail: observable,

      merchant: computed,
      responseOpenbankingDetails: computed,
      overallScore: computed,
      responseOpenbankingCustomVerification: computed,

      init: action.bound,
      loadProcesses: action.bound,
      loadStatuses: action.bound,
      setStatus: action.bound,
      loadProcessDetails: action.bound,
      terminateApplication: action.bound
    })

    this.init()

    autorun(() => {
      if (this.dateStore.startDate && this.dateStore.endDate && this.merchant) {
        this.loadProcesses(this.defaultPageNumber)
      }
    })
  }

  get merchant() {
    return this.merchantSelectStore.selectedMerchant?.companyName
  }

  get responseOpenbankingDetails() {
    return this.openBankingResultsFull?.result?.[0]?.responseData?.accounts?.find(
      a => a.subType?.toUpperCase() === 'CURRENTACCOUNT' && a.accountFormat?.toUpperCase() === 'SORTCODE'
    )
  }

  get responseOpenbankingCustomVerification() {
    return this.openBankingResultsFull?.result?.[0]?.responseData?.customVerification?.results || []
  }

  get overallScore() {
    return this.openBankingResultsFull?.result?.[0]?.responseData?.verification?.data[0]?.overallScore
  }

  async init() {
    if (this.dateStore.period === null) {
      this.dateStore.setPeriod(PeriodType.Day)
    } else {
      this.loadProcesses(this.defaultPageNumber)
    }
    this.loadStatuses()
  }

  setStatus(status: string | null) {
    this.status = status
    this.loadProcesses(this.defaultPageNumber)
  }

  async loadProcesses(page?: number, pageSize?: number) {
    this.pageSize = pageSize || PAGE_SIZE_10

    if (page) {
      this.currentPage = page
    }

    this.isProcessesLoading = true

    const { status, result, error } = await getSubmittedApplicationsChangeBankDetails({
      from: this.dateStore.startDate.clone().utc().format(),
      to: this.dateStore.endDate.clone().utc().format(),
      status: this.status || undefined,
      companyName: this.merchant === 'All' ? undefined : this.merchant,
      processName: 'dossierbank',
      page: this.currentPage,
      size: this.pageSize
    })
    if (status === 200) {
      this.isProcessesLoading = false
      this.processes = result.report
      this.total = result.totalCount
      return
    }
    this.isProcessesLoading = false
    message.error(error?.message || commonTranslations().defaultErrorMessage)
  }

  async loadStatuses() {
    this.isStatusesLoading = true
    const { status, result, error } = await getProcessStatuses('dossierbank')
    if (status === 200) {
      this.isStatusesLoading = false
      this.statuses = getFullData(result.map(item => ({ label: item.description, value: item.name })))
      return
    }
    this.isStatusesLoading = false
    message.error(error?.message || commonTranslations().defaultErrorMessage)
  }

  async loadProcessDetails(processId: string, processStatus: string, initiatorEmail: string) {
    this.isProcessDetailsLoading = true
    this.hasProcessDetailsLoadingError = false
    this.selectedProcessId = processId
    this.selectedProcessStatus = processStatus
    this.selectedEmail = initiatorEmail

    const { status, result, error } = await getProcessDetails(processId)

    if (status === 200) {
      runInAction(() => {
        this.processDetails = result
        this.isProcessDetailsLoading = false
      })
      if (result?.needRestrictionChecks) await this.fetchOpenBankingResults()
    } else {
      message.error(error?.message || commonTranslations().defaultErrorMessage)
      runInAction(() => {
        this.hasProcessDetailsLoadingError = true
        this.isProcessDetailsLoading = false
      })
    }
  }

  public showInfoDrawer = () => {
    this.isShowInfoDrawer = true
  }

  public closeInfoDrawer = () => {
    this.clearDocuments()
    this.processDetails = null
    this.isShowInfoDrawer = false
    this.openBankingResults = 'SENT'
    this.openBankingResultsFull = null
    this.selectedProcessId = null
    this.selectedProcessStatus = null
    this.selectedEmail = ''
  }

  parseOpenBankingRequest(result: OpenBankingResultsType): string {
    let res = ''
    if (
      result?.matchResult?.toLocaleUpperCase() === 'NOMATCH' &&
      (result?.result === null || result?.result === undefined)
    ) {
      res = 'SENT'
    } else {
      res = result.matchResult?.toLocaleUpperCase()
    }
    return res
  }

  async fetchOpenBankingResults() {
    try {
      runInAction(() => {
        this.isProcessDetailsLoading = true
      })
      const { bankAccountNumber, sortCode } = this.processDetails?.accountsInfoForUpdate?.accountDetails || {}
      const { status, result } = await openBankingResultsCheck(
        this.selectedProcessId,
        bankAccountNumber?.replace(/\s/g, ''),
        sortCode?.replace(/\s/g, '')
      )

      if (status !== 200) {
        message.error(translations().ecoSpendError)
      }

      this.openBankingResultsFull = result

      this.openBankingResults = this.parseOpenBankingRequest(result)?.toLocaleUpperCase()
    } finally {
      runInAction(() => {
        this.isProcessDetailsLoading = false
      })
    }
  }

  async deleteFile(documentType: FileKey | CheckedDocumentType, fileName: string): Promise<boolean> {
    let isDeleted: boolean
    try {
      const path = this.documentsData.find(d => d?.internalFileName === fileName && d?.type === documentType)?.path
      if (path) {
        const { status, error } = await deleteDocument(path)
        if (status !== 200 || error) {
          message.error(error.message || translations().errorDeletingDocument)
          isDeleted = false
        } else {
          this.documentsData = this.documentsData.filter(d => d?.path !== path)
          isDeleted = true
        }
      }
    } catch (error) {
      message.error(translations().errorDeletingDocument)
      isDeleted = false
    }
    return isDeleted
  }

  public removeDocumentType(value: FileKey) {
    this.documentTypes = this.documentTypes.filter(doc => doc !== value)
  }

  public setDocumentsMap(fileKey: FileKey, fileList: UploadFile[]) {
    this.documentsMap[fileKey] = fileList
  }

  public removeDocument(fileKey: FileKey, fileList: UploadFile[]) {
    this.setDocumentsMap(fileKey, fileList)
    if (fileList.length === 0) this.removeDocumentType(fileKey)
  }

  async uploadFile(documentType: FileKey | CheckedDocumentType, file: File): Promise<boolean> {
    if (!file) return false

    let isUploaded: boolean

    runInAction(() => {
      this.isDocumentLoading = true
    })

    try {
      const { companyName, companyNumber } = this.processDetails || {}

      const { status, error, result } = await uploadDocument({
        companyName,
        companyNumber,
        acquisitionChannel: null,
        documentType: camelToKebabCase(documentType),
        file
      })
      if (status !== 200 || error || !result || result.length <= 0) {
        message.error(error.message || translations().errorUploadingDocument)
        isUploaded = false
      } else {
        runInAction(() => {
          const docItem = { ...result[0], internalFileName: file.name, type: documentType }
          this.documentsData = [...this.documentsData, docItem]
        })
        isUploaded = true
      }
    } catch (error) {
      message.error(translations().errorUploadingDocument)
      isUploaded = false
    } finally {
      runInAction(() => {
        this.isDocumentLoading = false
      })
    }
    return isUploaded
  }

  public addDocumentTypesArray(value: FileKey) {
    if (this.documentTypes.includes(value)) return
    this.documentTypes.push(value)
  }

  addDocumentType(fileKey: FileKey, fileList: UploadFile[]) {
    this.setDocumentsMap(fileKey, [...this.documentsMap[fileKey], ...fileList])
    this.addDocumentTypesArray(fileKey)
  }

  async uploadDocument(fileList: UploadFile[], documentType: FileKey): Promise<void> {
    const file = fileList[0]
    if (!file || !file.name || !file.originFileObj || !file.originFileObj.name) return
    try {
      const clonnedFile = { ...file }
      clonnedFile.name = sanitizeString(clonnedFile.name)
      const modifiedFile = sanitizeFileName(clonnedFile.originFileObj)
      const isUploaded = await this.uploadFile(documentType, modifiedFile)
      if (isUploaded) {
        this.addDocumentType(documentType, [clonnedFile])
      }
    } catch (error) {
      message.error(error)
    }
  }

  clearDocuments() {
    this.documentsData = []
    this.documentTypes = []
    this.documentsMap = {
      bankStatement: [],
      processingStatement: [],
      other: []
    }
  }

  prepareDocuments() {
    if (this.documentsData.length > 0) {
      return this.documentsData.map(data => {
        return {
          type: data.type,
          name: data.fileName,
          path: data.path
        }
      })
    }
    return []
  }

  async completeApplication(): Promise<void> {
    try {
      runInAction(() => {
        this.isCompleteApplicationLoading = true
      })

      const documentsList = { documents: this.prepareDocuments() }
      const { status, error } = await completeApplication(documentsList, this.selectedProcessId)
      if (status !== 200 || error) {
        message.error(error.message || translations().errCompletingApplication)
      } else {
        message.success(translations().applicationSuccessful)
        this.closeInfoDrawer()
      }
    } catch (error) {
      message.error(error?.message || translations().errCompletingApplication)
      log(error)
    } finally {
      runInAction(() => {
        this.isCompleteApplicationLoading = false
      })
    }
  }

  async terminateApplication(): Promise<void> {
    try {
      runInAction(() => {
        this.isTerminateProcessLoading = true
      })

      const { status, error } = await terminateApplication({
        deleteReason: null,
        processIds: [this.selectedProcessId]
      })
      if (status !== 200 || error) {
        message.error(error.message || translations().errTerminatingApplication)
      } else {
        message.success(translations().applicationTerminationSuccess)
        this.closeInfoDrawer()
      }
    } catch (error) {
      message.error(error?.message || translations().errTerminatingApplication)
      log(error)
    } finally {
      runInAction(() => {
        this.isTerminateProcessLoading = false
      })
    }
  }
}
