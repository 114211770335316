import classNames from 'classnames'
import { Layout, Select, Typography } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { observer } from 'mobx-react'
import React from 'react'

import { STORE_TYPES } from '~/code/constants/StoreTypes'
import { getCurrentTimeZone } from '~/code/services'
import { getHomePageRoute } from '~/code/services/auth'
import { GlobalConfigStore } from '~/code/stores/GlobalConfigStore'
import { SvgLogo } from '~/assets/icons'

import { HeaderAvatar } from '../HeaderAvatar'
import { HeaderProps } from './props'
import translations from './translations'
import styles from './styles.scss'

const { Option } = Select
const { Link } = Typography

export const Header = observer((props: HeaderProps) => {
  const { isMobile, className, currentRoute, children } = props

  const globalConfigStore = useInjection<GlobalConfigStore>(STORE_TYPES.GlobalConfigStore)
  const homeRoute = getHomePageRoute()

  return (
    <Layout.Header className={classNames(styles.header, className)}>
      <Link href={homeRoute || '/'} className={styles.logo}>
        <SvgLogo />
        {!isMobile && <h1>{translations().portalName}</h1>}
      </Link>
      {children}
      <div className='grow'></div>
      {globalConfigStore.showCurrencySelect(currentRoute) && (
        <>
          {!isMobile && <span>{translations().currency}:&nbsp;</span>}
          <Select
            className={styles.select}
            value={globalConfigStore.currency.type}
            onChange={globalConfigStore.setCurrency}
          >
            {globalConfigStore.currencies.map(c => (
              <Option value={c.type} key={c.type}>{`${c.type} (${c.symbol})`}</Option>
            ))}
          </Select>
        </>
      )}
      {!isMobile && <span>{translations().currentTimezone}:&nbsp;</span>}
      <span className={styles.value}>{getCurrentTimeZone()}</span>
      <HeaderAvatar />
    </Layout.Header>
  )
})
