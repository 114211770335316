import { Moment } from 'moment-timezone'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    cardTypesCardPaymentsOnly: string
    cardTypes: string
    issuingBanksCardPaymentsOnly: string
    issuingBanks: string
    acquisitionChannels: string
    all: string
    successful: string
    success: string
    failed: string
    other: string
    percentageChangeTooltipText: (from: Moment, to: Moment) => string
    ecomErrorCodes: {
        status: {
            responseCodes: string
            cscErrors: string
            threeDsecureErrors: string
        }
        sumTitle: (status, currencySymbol?) => string | JSX.Element
        countTitle: (status, currencySymbol?) => string | JSX.Element
    }
}>()
