import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  applicationDeclaration: string
  averageTransactionValue: string
  bankAccountDetails: string
  businessType: string
  companyAddress: string
  companyNumber: string
  descriptor: string
  dnaAcquirer: string
  emailAddress: string
  expectedAnnualCardTurnover: string
  expectedAnnualTurnover: string
  expectedMonthlyTransactionValues: string
  formerAcquirer: string
  fullLegalName: string
  highestTransactionValue: string
  incorporationDate: string
  merchantCategoryCode: string
  privacyPolicy: string
  resellerName: string
  storeDetails: string
  taxInfo: string
  telephoneNumber: string
  tradingAddress: string
  tradingName: string
  website: string

  deviceId: string
  isFastTrack: string
  isMsaSigned: string
  motoRequested: string
  needUploadKycDocuments: string
  parentCompanies: string
  partnerMerchantId: string
  productCount: string
  productType: string
  acquisitionChannel: string
  yes: string
  no: string
}>()
