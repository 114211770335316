import { ReactNode } from 'react'
import {TranslationBuilder} from '~/code/components/Translation'

export default TranslationBuilder.create<{
    back: string
    title: string
    submit: string
    recoveryCodePlaceholder: string
    recoveryCodeRequired: string
    recoveryCodeInvalid: string
    dontHaveRecoveryCode: string
    text: (n: number) => ReactNode
}>()
