import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  startDate: string
  initiator: string
  merchant: string
  mid: string
  sfCaseId: string
  status: string
  assignedGroup: string
  assignedUser: string
  reviewTime: string
  endDate: string
  adjustmentType: string
}>()
