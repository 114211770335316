import React from 'react'
import moment from 'moment'
import { Button, Tooltip } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { FetchNetSuiteInvoicesResponseType } from '~/code/stores/NetSuiteInvoicesStore/models'

import translations from '../translations'

export const getColumns = (callback: (object: FetchNetSuiteInvoicesResponseType) => void) => [
  {
    key: 'subsidiary',
    dataIndex: 'subsidiary',
    title: translations().subsidiary,
    width: '40%'
  },
  {
    key: 'period',
    dataIndex: 'period',
    width: '20%',
    title: translations().period,
    render: date => moment(date, 'YYYY-MM').format('MMMM, YYYY')
  },
  {
    key: 'status',
    dataIndex: 'status',
    title: translations().download,
    width: '30%',
    render: (_, object) => {
      return (
        <Tooltip title={translations().downloadToolip}>
          <Button icon={<DownloadOutlined />} download onClick={() => callback(object)} />
        </Tooltip>
      )
    }
  }
]
