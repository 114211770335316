import { Form, Select } from 'antd'
import React, { useEffect } from 'react'
import { Input } from '~/code/components'
import { getAcquisitionChannel, isPartner } from '~/code/services/auth'
import { NextButton, PhoneNumberFormItem, SubLabel } from '..'
import { ContactInfoProps } from './props'
import translations from './translations'
import styles from './ContactInfoForm.scss'

export const ContactInfoForm: React.FC<ContactInfoProps> = (props) => {
    const {
        initialValues,
        isAcquisitionChannelsLoading,
        acquisitionChannels,
        isMoreFormFieldsVisible,
        isNextButtonLoading,
        isApplicationWaitingForSubmission,
        onEmailChange,
        onFinish,
        onContinue,
        checkSalesforceOpportunityID
    } = props

    const [form] = Form.useForm()

    useEffect(() => {
        form.resetFields(['firstName', 'surname', 'telephoneNumber', 'acquisitionChannel', 'salesforceOpportunityID'])
    }, [isMoreFormFieldsVisible])

    const isSalesforceOpportunityIDVisible = (acquisitionChannel: string) => (
        acquisitionChannels.find(a => a.name === acquisitionChannel)?.salesforceOpportunityRequired
    )

    const handleFinish = async (values) => {
        if (isMoreFormFieldsVisible && isSalesforceOpportunityIDVisible(form.getFieldValue('acquisitionChannel'))) {
            const errMessage = await checkSalesforceOpportunityID(form.getFieldValue('salesforceOpportunityID'))
            if (errMessage) {
                form.setFields([{ name: 'salesforceOpportunityID', errors: [errMessage] }])
                return
            }
        }

        values.email = values.email.toLocaleLowerCase()
        onFinish(values)
    }

    return <Form
        form={form}
        layout='vertical'
        onFinish={handleFinish}
        initialValues={{
            ...initialValues,
            acquisitionChannel: isPartner() ? getAcquisitionChannel() : null
        }}
    >
        <Form.Item
            name='email'
            label={<div>
                {translations().emailLabel} <SubLabel>{translations().emailSubLabel}</SubLabel>
            </div>}
            rules={[
                { type: 'email', message: translations().emailFormatIncorrect },
                { required: true, message: translations().emailRequired }
            ]}
            validateTrigger='onBlur'
        >
            <Input 
                placeholder={'me@company.co.uk'} 
                textTransform={'lowercase'}
                onBlur={e => onEmailChange(e.target.value)}
            />
        </Form.Item>
        {isApplicationWaitingForSubmission && (
            <span className={styles.applicationAlreadyStarted}>{translations().applicationAlreadyStarted}</span>
        )}
        {isMoreFormFieldsVisible && (
            <>
                <Form.Item
                    name='firstName'
                    label={<div>
                        {translations().firstName} <SubLabel>{translations().firstNameSubLabel}</SubLabel>
                    </div>}
                    rules={[{ required: true, message: translations().firstNameRequired }]}
                    validateTrigger='onBlur'
                >
                    <Input placeholder={'John'}/>
                </Form.Item>

                <Form.Item
                    name='surname'
                    label={translations().surname}
                    rules={[{ required: true, message: translations().surnameRequired }]}
                    validateTrigger='onBlur'
                >
                    <Input placeholder={'Doe'}/>
                </Form.Item>

                <PhoneNumberFormItem />

                <Form.Item
                    hidden={isPartner()}
                    name='acquisitionChannel'
                    label={translations().applicationSourceLabel}
                    rules={[{ required: true, message: translations().applicationSourceRequired }]}
                    validateTrigger='onBlur'
                >
                    <Select loading={isAcquisitionChannelsLoading}>
                        {acquisitionChannels.map(option => (
                            <Select.Option key={option.name} value={option.name}>
                                {option.description}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                    {() => {
                        if (isSalesforceOpportunityIDVisible(form.getFieldValue('acquisitionChannel'))) {
                            return <Form.Item
                                name='salesforceOpportunityID'
                                label={translations().salesforceOpportunityIDLabel}
                                rules={[
                                    { required: true, message: translations().salesforceOpportunityIDRequired },
                                    { min: 15, message: translations().salesforceOpportunityIDMin }
                                ]}
                                validateTrigger='onChange'
                            >
                                <Input
                                    placeholder='0063M000003ukUuQAI'
                                    maxLength={18}
                                />
                            </Form.Item>
                        }
                        return <></>
                    }}
                </Form.Item>
            </>
        )}
        {isApplicationWaitingForSubmission ? (
            <div className={styles.continueButtonContainer}>
                <NextButton
                    loading={isNextButtonLoading}
                    onClick={onContinue}
                >
                    {translations().continue}
                </NextButton>
            </div>
        ) : (
            <NextButton loading={isNextButtonLoading} />
        )}
    </Form>
}