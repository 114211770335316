import Table, { ColumnsType } from 'antd/lib/table'
import React from 'react'
import { BpmAnalyticsItemModel } from '../../../../models'
import { getTime, round } from '../../services/utils'
import translations from './translations'
import styles from './SubStatuses.scss'

interface Props {
    list: BpmAnalyticsItemModel[]
}

const columns: ColumnsType<BpmAnalyticsItemModel> = [
    {
      title: translations().status,
      dataIndex: 'status'
    },
    {
      title: translations().avgReviewTime,
      dataIndex: 'avgReviewTime',
      render: (val) => {
        const { value, unit } = getTime(val)
        return <span className='nowrap'>{round(value)} <span className={styles.unit}>{unit}</span></span>
      }
    },
    {
      title: translations().count,
      dataIndex: 'count',
      render: (val) => <span className='nowrap'>{val} <span className={styles.unit}>{translations().app}</span></span>
    }  
]
  
export const SubStatuses: React.FC<Props> = ({ list }) => {
    return <Table
        size='small'
        bordered={false}
        pagination={false}
        columns={columns}
        className={styles.SubStatuses}
        dataSource={list}
        rowKey='status'
    />
}