import React from 'react'
import translations from './translations'

translations.add('en', {
    changeRiskLimit: 'Change Risk Limits',
    sureToChangeFields: 'Are you sure you want to change?',
    confirm: 'Confirm',
    cancel: 'Cancel',
    change: 'Change'
})
