import translations from './translations'

translations.add('en', {
  addLogin: '+ Add Login',
  addMerchant: '+ Add Merchant',
  emailAddress: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  sendWelcomeEmail: 'Send Welcome Email?',
  submit: 'Add',
  merchant: 'Merchant',
  merchantSubLabel: 'Search by trade name, company name or MID',
  noMerchants: 'No Merchants',
  roles: 'Roles',
  createdAt: 'Created Date',
  searchByMerchant: 'Search by merchant',
  searchByEmail: 'Search by email',
  actions: 'Actions',
  emailSearchPlaceholder: 'Search by email',
  lastLoginDate: 'Last Login',
  status: 'Status',
  authMethod: 'Auth Method',

  formErrors: {
    wrongEmailFormat: 'wrong email format'
  },

  menu: {
    editAccess: 'Edit access',
    editUser: 'Edit user',
    editSecurity: 'Edit security settings',
    delete: 'Delete',
  },

  deleteAlert: {
    login: {
      title: 'Delete Login',
      text: 'Are you sure you want to delete this login? This action cannot be undone.'
    },

    merchant: {
      title: 'Delete Merchant',
      text: 'Are you sure you want to delete this merchant? This action cannot be undone.'
    },

    okText: 'Confirm'
  },
  totalPagination: (range: number[], total: number) => `${range[0]}-${range[1]} of ${total}`
})
