import { Badge, Button, Card, Pagination, Table, Tooltip } from 'antd'
import { TablePaginationConfig } from 'antd/lib/table'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { formatAmount } from '~/code/services/utils'
import { ReloadButton, ResizableColumnTitle } from '~/code/components'
import { PosTransactionModel } from '~/code/models/PosTransactionModel'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { MonitoringPosTransactionsProps } from './props'
import { StatusEnum } from '~/code/pages/Acquiring/pages/MonitoringPos/constants/statusEnum'
import translations from './translations'
import styles from './MonitoringPosTransactions.scss'
import { dodgerBlue } from '~/code/constants/colors'


export const MonitoringPosAmexTransactions = observer((props: MonitoringPosTransactionsProps) => {
  const { store } = props
  const { tableStore, totalAmount } = store

  const filteredColumns = store.columns
  const tableHeight = 980

  const paginationProps: TablePaginationConfig = generateTablePaginationProps(
      tableStore.currentPage,
      tableStore.pageSize,
      tableStore.totalCount,
      (pageNumber) => {
        tableStore.loadData({ pageNumber })
      },
      tableStore.setPageSize
  )

  return <>
    <Card>
      <div className={styles.header}>
        <div className={styles.title}>
          <Badge status='processing' color={dodgerBlue} text={
              <><Tooltip title={translations().title}>{translations().shortTitle}</Tooltip>: <strong className={styles.totalAmount}>{store.currencySymbol} {formatAmount(totalAmount)}</strong></>
          } />
        </div>
        <div className={styles.paginationContainer}>
          {hasPermissions([PermissionMap.transactions.dna_pos_statement.v2.read]) ? (
              <Button
                  className={styles.exportButton}
                  icon={<DownloadOutlined />}
                  onClick={() => store.openExportModal(true)} type={'primary'}>
                {translations().export}
              </Button>
          ): null}
          <ReloadButton
            onClick={ () => tableStore.loadData() }
            className={styles.reloadButton}
          />
          <Pagination {...paginationProps} />
        </div>
      </div>
      {
        tableStore.isLoading ?
          <Table
            size='small'
            bordered
            columns={filteredColumns}
            scroll={{ x: 1000, y: tableHeight }}
            loading={tableStore.isLoading}
            components={{ header: { cell: ResizableColumnTitle } }}
            className={classNames(styles.transactionsTable, 'dashboard-table')}
            pagination={false}
          />
          :
          <Table
            size='small'
            bordered
            virtual
            columns={filteredColumns}
            scroll={{ x: 1000, y: tableHeight }}
            components={{ header: { cell: ResizableColumnTitle } }}
            dataSource={tableStore.data ? tableStore.data.map((item, i) => {
              const posItem = item as PosTransactionModel
              return ({ ...item, key: `${posItem.transactionId}-${i}`, status: StatusEnum[posItem.status] })
            }) : null }
            loading={tableStore.isLoading}
            className={classNames(styles.transactionsTable, 'dashboard-table', 'tableWithBottomPadding')}
            pagination={false}
          />

      }
      <div className={styles.paginationContainer} style={{marginTop: 15}}>
        <Pagination {...paginationProps} />
      </div>
    </Card>
  </>
})
