import translations from './translations'

translations.add('en', {
  motoCnp: 'MOTO Payments',
  zashEpos: 'Zash ePOS',
  contractType: 'Contract type',
  required: 'Required',
  subscriptionServicePeriod: 'Subscription Service Period (in months)',
  freeSubscriptionPeriod: 'Free Subscription Period (in months)',
  byDNA: 'Chargeable by DNA',
  byPaytek: 'Chargeable by Paytek',
  posModel: 'POS Model',
  posModelRequired: 'pos model is required',
  posBundles: 'Bundles',
  byOptomany: 'Chargeable by Optomany',

  title: 'Assign products',
  pos: 'POS',
  webSiteRequired: 'Store WebSite required',
  posDeliveryInstructionsRequired: 'Pos Delivery Instructions Required',
  posDeliveryAddressRequired: 'Pos Delivery Address Required',
  productPlaceholder: 'Select product',
  deviceAmountPlaceholder: 'Enter amount of devices',
  posQuantity: 'Quantity',
  posDeliveryAddress: 'Pos Delivery Address',
  posDeliveryAddressPlaceholder: 'Enter Pos Delivery Address',
  manual: 'Manual',
  sameAsCompany: 'Same As Company Address',
  sameAsStore: 'Same As Store Address',
  deliveryInstructions: 'Delivery Instructions',
  deliveryInstructionsPlaceholder: 'Enter pos delivery instructions',
  deliveryContactNumber: 'Contact number for delivery',
  validateBankAccount: 'Please validate bank account',
  addProduct: 'Add this product for the store',
  cityLabel: 'Town/City',
  cityRequired: 'town/city is required',
  cityInvalid: 'only alphanumeric characters are allowed',
  countryLabel: 'Country',
  countryRequired: 'country is required',
  regionLabel: 'County',
  postalCodeLabel: 'Postcode',
  postalCodeRequired: 'postcode is required',
  postalCodeInvalid: 'invalid postcode',
  addressLine1Label: 'Address line 1',
  addressLine1Required: 'address line 1 is required',
  addressLine2Label: 'Address line 2 ',
  addressLine2Required: 'address line 2 is required',
  zashDigital: 'Zash Digital Ordering',
  posCountError: 'Pos terminals quantity must be in 1 - 100 range',
  posCountRequired: 'pos count is required',
  feeSubscriptionPerMonth: 'Subscription Fee Per Month',
  terminalCost: 'Terminal Cost - per terminal',
  notZero: 'Value cannot be negative or zero',
  chargeType: 'Chargeable',
  aliPay: 'AliPay (Coming soon)',
  weChatPay: 'WeChat Pay',
  minValue: (fee: number) => `Minimim value is ${fee}`,
  monthlyFeeChargedTerminal:
    'Monthly fee charged for terminal support, enabling the Merchant to accept Transactions. Excluding VAT, VAT will be applied automatically on top of the rate.',
    selectDeviceType: 'Select device type',
    paymentMethods: 'Payment methods',
    cards: 'Cards (Visa, Mastercard, UnionPay, Diners & Discover)',
    posOpenBanking: ' Pos Open Banking (Coming soon)',
    configurePos: 'Configure POS product',
    infoNote: 'Informational Notes',
    terminalConstNoteInfo: 'One off fee, to be charged in the card processing system upon the TID issuance'
})
