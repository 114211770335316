export const filterCompanyByCompanyNumber = (companies: any[], companyNumber: string) => {
  return companies.find(company => company.params.companyNumber === companyNumber)
}

export const getEmptyAddress = () => {
  return {
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    locality: '',
    houseName: '',
    houseNumber: '',
    country: undefined,
    region: ''
  }
}

export const getScrollBehaviorOptions = () => {
  return {
    behavior: actions => {
      actions.forEach(({ el, top }) => {
        el.scrollTop = top - 40
      })
    }
  }
}
