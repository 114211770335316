import { isInput } from 'dna-common'
import moment from 'moment'
import { traverseObject } from '~/code/services'


export function generateRandomString() {
    return Math.random().toString(36).substring(7)
}

export function setAutoCompleteForInput(el: Element, text?: string) {
    if (!el || typeof el === 'string') return
    if (isInput(el)) {
        el.setAttribute('autocomplete', text || generateRandomString())
        el.setAttribute('name', generateRandomString())
    }
    const inputs = el.querySelectorAll('input')
    inputs.forEach((input) => {
        input.setAttribute('autocomplete', text || generateRandomString())
        input.setAttribute('name', generateRandomString())
    })
}

export function getAge(birthDate: moment.Moment) {
    return moment().diff(birthDate, 'years')
}

export function addCommasForNumbersInput(e: React.FormEvent<HTMLInputElement>) {
    // remove commas and any letters or symbols
    const value = e.currentTarget.value?.replace(/[, ]+|\D/g, '')

    // add commas
    return value?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
}

function isValidQuarterField(value: string) {
    if (value === '0') return true
    return !!value
}

export function wereQuarterFieldsFilledIn(q1: string, q2: string, q3: string, q4: string) {
    return isValidQuarterField(q1) && isValidQuarterField(q2) && isValidQuarterField(q3) && isValidQuarterField(q4)
}

export function quarterTotal(q1: string, q2: string, q3: string, q4: string) {
    return Number(q1) + Number(q2) + Number(q3) + Number(q4)
}

export function isQuarterTotalNot100(q1: string, q2: string, q3: string, q4: string) {
    return quarterTotal(q1, q2, q3, q4) !== 100
}

export function editQuarterInput(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value

    if (value.length > 1) {
        value = value?.replace(/^0+/g, '')
    }

    return value?.replace(/-|\+|\D/, '')
}

export function convertStringValuesToNumber(obj: object, excludeKeys: string[] = []) {
    traverseObject(obj, (value, key, subObj) => {
        if (!excludeKeys.includes(key)) {
            subObj[key] = typeof value === undefined ? value : Number(value)
        }
    })
}

export function isEquivalent(a: object, b: object) {
    try {
        // Create arrays of property names
        const aProps = Object.getOwnPropertyNames(a)
        const bProps = Object.getOwnPropertyNames(b)

        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length !== bProps.length) {
            return false
        }

        for (const propName of aProps) {

            // If values are objects, run the function recursively
            if (typeof a[propName] === 'object' && typeof b[propName] === 'object') {
                const equivalent = isEquivalent(a[propName], b[propName])
                if (equivalent) continue
            }

            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false
            }

        }

        // If we made it this far, objects
        // are considered equivalent
        return true
    } catch (error) {
        return false
    }
}

export const camelToKebabCase = (str: string) => str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)