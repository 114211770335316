import React from 'react'
import { SumCountCard } from '../../chart-related'
import { LineChart } from '../../chart-related/LineChart'
import { TransactionsLineChartProps } from './props'
import numeral from 'numeral'
import translations from './translations'
import { IntervalSelect } from '../../chart-related/SumCountCard/components'
import { formatXAxis, formatXAxisSecond, getTooltipTitle } from './services'
import { Skeleton } from 'antd'
import { observer } from 'mobx-react'
import { Moment } from 'moment-timezone'

export const TransactionsLineChart = observer(({
    data,
    onSumStatusChange,
    onCountStatusChange,
    interval,
    intervalOptions,
    onIntervalChange,
    onTabChange,
    currentStatus,
    currentTab,
    currencySymbol,
    legendPosition,
    statusTabs
}: TransactionsLineChartProps) => {

    const renderContent = (dataType, _loading) => {
        if (_loading) {
            return <Skeleton />
        }

        return (
            <div>
                <LineChart
                    data={data?.[currentTab]?.[currentStatus]?.[interval]}
                    yAxisNumberFormat={dataType === 'count' ? '0,00' : '0.00a'}
                    renderTooltipTitle={(value: Moment) => {
                        return getTooltipTitle(value, interval)
                    }}
                    renderTooltipLabel={(value) => {
                        const currency = dataType === 'count' ? '' : (currencySymbol || '£')
                        const format = dataType === 'count' ? '0,00' : '0,0.00'
                        return currency + numeral(value).format(format)
                    }}
                    renderXAxisLabel={(value: string, values: string[], index: number) => {
                        return formatXAxis(value, interval, values, index)
                    }}
                    renderXAxisLabelSecond={(value: string, values: string[]) => {
                        return formatXAxisSecond(value, interval, values)
                    }}
                    rerenderChartDependencyList={[interval, currentTab]}
                    legendPosition={legendPosition}
                />
            </div>
        )
    }

    const renderSumContent = () => {
        return renderContent('amount', data.amount.isLoading)
    }

    const renderCountContent = () => {
        return renderContent('count', data.count.isLoading)
    }

    return (
        <SumCountCard
            loadingSum={data.amount.isLoading}
            loadingCount={data.count.isLoading}
            title={translations().transactions}
            renderSumContent={renderSumContent}
            renderCountContent={renderCountContent}
            onSumStatusChange={onSumStatusChange}
            onCountStatusChange={onCountStatusChange}
            onTabChange={onTabChange}
            currencySymbol={currencySymbol}
            headerExtraContent={
                <IntervalSelect 
                    interval={interval}
                    intervalOptions={intervalOptions}
                    handleIntervalChange={onIntervalChange}
                />
            }
            statusTabs={statusTabs}
        />
    )
})
