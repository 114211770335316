import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Button, Col, Form, Input, Radio, Row, Select, Typography, Modal, Empty } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { SupportingDocument, Upload } from 'startapp/components/SupportingDocuments/components'
import { FormSubgroupTitle } from 'startapp/components'
import { availableDocumentTypes } from '~/code/pages/MerchantsManagement/components/ChangeOfSettlementPeriod/constants'
import { DocumentsType } from '~/code/pages/MerchantsManagement/components/ChangeOfSettlementPeriod/models/DocumentType'
import translations from './translations'
import { ChangeOfSettlementPeriodStoreType } from './ChangeOfSettlementPeriodStoreType'

const { TextArea } = Input
const { Title } = Typography

export const ChangeOfSettlementPeriodStoreSymbol = Symbol('ChangeOfSettlementPeriodStore')

export const ChangeOfSettlementPeriod = observer(() => {
  const store = useInjection<ChangeOfSettlementPeriodStoreType>(ChangeOfSettlementPeriodStoreSymbol)
  const [form] = Form.useForm()
  const [documentType, setDocumentType] = useState<keyof DocumentsType>('' as keyof DocumentsType)
  const [showModal, setShowModal] = useState<boolean>(false)
  const { setProductType, posTerminal, ecomTerminal } = store

  React.useEffect(() => {
    form.resetFields()
  }, [])

  const renderAdditionalDocumentUpload = (_documentType: string) => {
    return (
      <Upload
        disabled={!documentType}
        loading={store.isDocumentLoading}
        fileList={[]}
        onChange={async fileList => {
          const file = fileList[0]
          if (!file) return
          const isUploaded = await store.uploadFile(documentType, file?.originFileObj as File)
          if (!isUploaded) return
          store.addDocumentType(documentType, fileList)
          setDocumentType('' as keyof DocumentsType)
        }}
      >
        <Button icon={<UploadOutlined />}>{translations().upload}</Button>
      </Upload>
    )
  }

  const handleDocumentOptionChange = (option: keyof DocumentsType) => {
    setDocumentType(option)
  }

  const handleCancel = () => {
    setShowModal(false)
    form.resetFields()
    store.clearData()
  }

  const renderContent = () => {
    switch (store.productType) {
      case 'pos':
        return posTerminal.length > 0 ? (
          <Row gutter={10} style={{ alignItems: 'center', marginTop: 20 }}>
            <Col>
              <Typography.Text>{translations().businessDays}</Typography.Text>
            </Col>
            <Col xs={2}>
              <Typography.Text>{store.posSettlementPeriod || ''}</Typography.Text>
            </Col>
            <Col>
              <Button type={'primary'} onClick={() => setShowModal(true)} disabled={!store.isMerchantActive}>
                {translations().change}
              </Button>
            </Col>
          </Row>
        ) : (
          <Empty description={translations().noPos} />
        )
      case 'ecom':
        return ecomTerminal.length > 0 ? (
          <Row gutter={10} style={{ alignItems: 'center', marginTop: 20 }}>
            <Col>
              <Typography.Text>{translations().businessDays}</Typography.Text>
            </Col>
            <Col xs={2}>
              <Typography.Text>{store.ecomSettlementPeriod || ''}</Typography.Text>
            </Col>
            <Col>
              <Button type={'primary'} onClick={() => setShowModal(true)} disabled={!store.isMerchantActive}>
                {translations().change}
              </Button>
            </Col>
          </Row>
        ) : (
          <Empty description={translations().noEcom} />
        )
      default:
        return null
    }
  }

  return (
    <>
      <Title level={3}>{translations().changeOfSettlementPeriod}</Title>
      <Row>
        <Radio.Group
          defaultValue='pos'
          size='large'
          buttonStyle='solid'
          onChange={event => setProductType(event.target.value)}
        >
          <Radio.Button value='pos'>{translations().posProductType}</Radio.Button>
          <Radio.Button value='ecom'>{translations().ecomProductType}</Radio.Button>
        </Radio.Group>
      </Row>

      {renderContent()}

      <Modal
        title={translations().changeOfSettlementPeriod}
        open={showModal}
        onCancel={handleCancel}
        okText={translations().submit}
        onOk={() => form.submit()}
      >
        <Form form={form} labelAlign={'left'} layout={'horizontal'} onFinish={store.onFormFinish}>
          <Form.Item
            wrapperCol={{ span: 8 }}
            labelCol={{ span: 16 }}
            label={translations().currentSettlementBusinessDays}
            key={'oldSettlementPeriod'}
          >
            <Input
              disabled={true}
              value={store.productType === 'ecom' ? store.ecomSettlementPeriod : store.posSettlementPeriod}
            />
          </Form.Item>
          <Form.Item
            wrapperCol={{ span: 8 }}
            labelCol={{ span: 16 }}
            label={translations().newSettlementBusinessDays}
            name={'newSettlementPeriod'}
            key={'newSettlementPeriod'}
            rules={[{ required: true, message: translations().required }]}
          >
            <Input placeholder={translations().days} />
          </Form.Item>
          <FormSubgroupTitle>{translations().documents}</FormSubgroupTitle>
          {store.documentTypes.map(d => (
            <SupportingDocument
              key={d}
              title={translations()[d]}
              fileList={store.documentsMap[d]}
              onChange={async (fileList, file) => {
                const isDeleted = await store.deleteFile(d, file?.name)
                if (!isDeleted) return
                store.removeDocument(d, fileList)
              }}
              loading={store.isDocumentLoading}
            />
          ))}
          <Row justify={'space-between'}>
            <Col xs={18}>
              <Form.Item name={'documentType'} key={'documentType'} label={translations().documents}>
                <Select
                  options={availableDocumentTypes}
                  onChange={handleDocumentOptionChange}
                  value={documentType}
                  placeholder={translations().documentType}
                />
              </Form.Item>
            </Col>
            <Col>{renderAdditionalDocumentUpload(documentType)}</Col>
          </Row>
          {store.isNoDocumentsError ? (
            <Row>
              <Typography.Text type={'danger'}>{translations().documentsRequired}</Typography.Text>
            </Row>
          ) : null}
          <Form.Item key={'comment'} name={'comment'}>
            <TextArea placeholder={translations().comment} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
})
