import { Button, Col, Divider, Result, Row, Typography } from 'antd'
import { isEmpty } from 'dna-common'
import { observer } from 'mobx-react'
import React from 'react'
import { goToRoute } from '~/code/startup/Router/utils'
import { CustomButton } from '~/code/components'
import { SectionHeader } from './components'
import { StartAppResultProps } from './props'
import styles from './StartAppResult.scss'
import translations from './translations'

const { Link } = Typography

export const StartAppResult = observer((props: StartAppResultProps) => {
  const { store } = props
  return (
    <div>
      {store.resultPage === 'check' && <CheckData {...props} />}
      {store.resultPage === 'success' && <Success {...props} />}
      {store.resultPage === 'error' && <Failure {...props} />}
      {store.resultPage === 'timeout' && <Timeout {...props} />}
    </div>
  )
})

const CheckData = observer(({ store, setActiveTab }: StartAppResultProps) => {
  const { isSubmitting, submit } = store

  const { isApplicationValid } = store.dataStore

  return (
    <>
      <div className={styles.wrapper}>
        <p className={styles.header}>{translations().reviewAndConfirm}</p>
        <p>{translations().carefullyReview}</p>

        <SectionHeader title={translations().companyInfo} />
        {translations().companyDetails(
          store.dataStore.application?.companyProfile?.companyName,
          store.dataStore.application?.companyProfile?.emailAddress,
          store.dataStore.contactInfoData?.email
        )}

        {!isEmpty(store.dataStore.bankAccounts) && (
          <SectionHeader
            title={translations().bankInfo}
            onClick={() => setActiveTab('bankInfo')}
            isDocusignSuccessfully={store.dataStore.isDocusignSignedSuccessfully}
          />
        )}
        {store.dataStore.bankAccounts?.map((b, idx) => {
          return <div key={idx}>{translations().bankDetails(b.bankName, b.bankAccountNumber, b.sortCode)}</div>
        })}

        <SectionHeader
          title={translations().individualInfo}
          onClick={() => setActiveTab('individualDetails')}
          isDocusignSuccessfully={store.dataStore.isDocusignSignedSuccessfully}
        />
        {store.dataStore.application?.companyOfficerList?.map((b, idx) => {
          return (
            <div key={idx}>
              {translations().individualDetails(
                `${b.nameElements?.title} ${b.nameElements?.surname} ${b.nameElements?.forename} ${b.nameElements?.middleName}`,
                `${b.dateOfBirth?.day}. ${b.dateOfBirth?.month}. ${b.dateOfBirth?.year}`,
                b.address?.addressLine1
              )}
            </div>
          )
        })}

        <SectionHeader
          title={translations().storesInfo}
          onClick={() => setActiveTab('stores')}
          isDocusignSuccessfully={store.dataStore.isDocusignSignedSuccessfully}
        />
        {store.dataStore.stores.map((s, idx) => {
          return (
            <div key={idx}>
              {translations().storesDetails(
                s.storeName,
                s.storeAddress?.addressLine1,
                s.bankInfo?.bankAccountNumber,
                s.directDebitBankInfo?.bankAccountNumber,
                s?.products?.map(p => p.name).toString()
              )}
            </div>
          )
        })}

        <SectionHeader
          title={translations().financialInfo}
          onClick={() => setActiveTab('financialInfo')}
          isDocusignSuccessfully={store.dataStore.isDocusignSignedSuccessfully}
        />
        {store.dataStore?.stores.map((s, idx) => {
          return (
            <div key={idx}>
              {translations().financialDetails(
                s.storeName,
                s.financialInfo?.expectedAnnualCardTurnover,
                s.financialInfo?.averageTransactionValue,
                s.financialInfo?.highestTransactionValue
              )}
            </div>
          )
        })}

        <SectionHeader
          title={translations().pricingInfo}
          onClick={() => setActiveTab('pricing')}
          isDocusignSuccessfully={store.dataStore.isDocusignSignedSuccessfully}
        />
        {store.dataStore?.tariffs.map((t, idx) => {
          return <div key={idx}>{translations().pricingDetails(t.type, t.tariffs.tariffType)}</div>
        })}

        {store.supportingDocumentsStore.showDocusign && (
          <>
            <SectionHeader title={translations().docuSignInfo} onClick={() => setActiveTab('contract')} />
            {translations().currentStatus(store.dataStore.docuSignStatus)}
          </>
        )}
      </div>

      <Divider />
      <CustomButton key='submit' type='primary' disabled={!isApplicationValid} onClick={submit} loading={isSubmitting}>
        {translations().submit}
      </CustomButton>
    </>
  )
})

const Failure: React.FC<StartAppResultProps> = ({ onBack, store }) => (
  <Result
    status='error'
    title={translations().submissionFailed}
    subTitle={
      store.errorSubmitApplication.message
        ? store.errorSubmitApplication.message
        : translations().pleaseCheckBeforeSubmit
    }
    extra={[
      <Button key='back' onClick={onBack}>
        {translations().backToForm}
      </Button>
    ]}
  ></Result>
)

const Success: React.FC<StartAppResultProps> = () => {
  return (
    <div>
      <p className={styles.header}>{translations().submitted}</p>
      <p>{translations().submittedForReview}</p>
      <p>{translations().statusWillBeUpdated}</p>

      <div className={styles.links}>
        <Link>{translations().salesForceLink}</Link>
      </div>
      <div className={styles.links}>
        <Link>{translations().dashboardReportsLink}</Link>
      </div>
      <p>{translations().closeTheWindow}</p>
      <Divider />
      <Row gutter={10}>
        <Col>
          <CustomButton key='submit' type='primary' onClick={() => goToRoute('/')}>
            {translations().close}
          </CustomButton>
        </Col>
      </Row>
    </div>
  )
}
const Timeout: React.FC<StartAppResultProps> = ({ onBackToStart }) => (
  <Result
    status='warning'
    title={translations().warning}
    subTitle={
      <>
        {translations().onceYouClickSubmit} <br />
        {translations().pleaseWait2Mins}
      </>
    }
    extra={[
      <Button type='primary' key='back' onClick={onBackToStart}>
        {translations().newApplication}
      </Button>
    ]}
  ></Result>
)
