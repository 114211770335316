import translations from './translations'

translations.add('en', {
  storeName: 'Store Name',
  paymentSolution: 'Payment Solution',
  motoRequested: 'Moto Requested',
  count: 'Count',
  locationAddress: 'Location Address',
  yes: 'Yes',
  no: 'No'
})
