import React from 'react'
import { observer } from 'mobx-react'
import { Col, Row, Typography } from 'antd'
import { Info } from './../'
import translations from './translations'
import { MerchantInfoProps } from './props'
import styles from './styles.scss'

const { Title } = Typography

export const MerchantInfo = observer((props: MerchantInfoProps) => {
    const { companyFullDossier } = props

    const {
        login,
        firstName,
        surname,
        phoneNumber,
        businessStructure,
        acquisitionChannel,
        application
    } = companyFullDossier

    const { paymentSolution } = application

    return (
        <>
            <Row>
                <Col xs={24}>
                    <Title level={5} className={styles.sectionTitle}>{translations().applicant}</Title>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Info label={translations().firstName} value={firstName}/>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Info label={translations().surname} value={surname}/>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Info label={translations().email} value={login}/>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Info label={translations().phoneNumber} value={phoneNumber}/>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Info label={translations().acquisitionChannel} value={acquisitionChannel}/>
                </Col>
            </Row>

            <Row className={styles.sectionContainer}>
                <Col xs={24}>
                    <Title level={5} className={styles.sectionTitle}>{translations().productCompany}</Title>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Info label={translations().paymentSolution.label} value={translations().paymentSolution.value(paymentSolution)}/>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Info label={translations().businessStructure} value={businessStructure === 'company' ? 'Limited Company' : 'Sole Trader'}/>
                </Col>
            </Row>
        </>
    )
})
