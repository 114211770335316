import React from 'react'
import { observer } from 'mobx-react-lite'
import { Dropdown } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { PartnerAccessMenuDropdownProps } from './props'

import translations from './translations'
import styles from './styles.scss'

export const PartnerAccessMenuDropdown = observer(({ onSelect }: PartnerAccessMenuDropdownProps) => {
  const menu = {
    items: [
      {
        key: 'edit',
        label: translations().menu.editUser
      },
      {
        key: 'edit-security',
        label: translations().menu.editSecurity
      }
    ],
    onClick: ({ key }) => onSelect(key)
  }

  return (
    <Dropdown menu={menu} className={styles.root}>
      <SettingOutlined />
    </Dropdown>
  )
})
