import { ONLY_DIGITS_PATTERN, START_ZERO } from '~/code/models/Patterns'
import { isEmpty } from 'dna-common'

export const allowOnlyNumber = (formInstance, fieldName, value, max) => {
    if ((!ONLY_DIGITS_PATTERN.test(value) && !isEmpty(value)) || (value && value.length > max)) {
        return formInstance.getFieldValue(fieldName)
    } else if (START_ZERO.test(value)) {
        return parseInt(value, 10)
    }
    return Number(value)
}

export const cleanValue = (value: any): string => value?.toString().replace(/[^\+\d]/g, '') || ''

export const removePrefix = (value: any): string => value?.toString().replace(/^0|^\+44/g, '') || ''