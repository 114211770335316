import translations from './translations'

translations.add('en', {
  contractNumber: 'Contract Number',
  bankAccountName: 'Bank Account Name',
  bankAccountNumber: 'Bank Account Number',
  sortCode: 'Sort Code',
  change: 'Change',
  changeSettlement: 'Change Settlement',
  mid: 'MID initially issued with',
  payneticsMid: 'Paynetics MID',
  settlemetTeam: (teamName: string) => `Settlement is held by: ${teamName}`,
  closedContract: 'contract is closed',
  constractStatus: 'Contract Status',
  dnaMid: 'DNA MID',
  editTariffs: 'Edit/View Tariffs',
  tipJar: 'TipJar'
})
