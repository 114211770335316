import { Tabs } from 'antd'
import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { PageHeaderRaw } from '../PageHeaderRaw'
import { PageHeaderProps } from './props'
import styles from './PageHeader.scss'

export const PageHeader: React.FC<PropsWithChildren<PageHeaderProps>> = props => {
  const { className, breadcrumb, tabActiveKey, tabList, tabBarExtraContent, onTabChange, showItems = true } = props

  return (
    <PageHeaderRaw className={classNames(styles.PageHeader, className)} hasFooter={true} breadcrumb={breadcrumb}>
      <Tabs
        activeKey={tabActiveKey}
        onChange={key => {
          if (onTabChange) {
            onTabChange(key)
          }
        }}
        tabBarExtraContent={tabBarExtraContent}
        items={
          showItems &&
          tabList?.map((item, index) => ({
            ...item,
            key: item.key || String(index)
          }))
        }
      />
    </PageHeaderRaw>
  )
}
