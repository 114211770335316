import translations from './translations'

translations.add('en', {
    currentRiskLimits: 'Current Risk Limits',
    change: 'Change',
    dailyAmount: 'Daily Amount',
    dailyMaxRefundAmount: 'Daily Max Refund Amount',
    dailyCount: 'Daily Count',
    monthlyAmount: 'Monthly Amount',
    monthlyCount: 'Monthly Count',
    perRefund: 'Per Refund',
    perTransaction: 'Per Transaction',
    perDevice: 'Per Device',
    terminals: 'Terminals',
    terminalsRequired: 'Terminals are required',
    noActiveTerminals: 'No active terminals'
})
