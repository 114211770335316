import React from 'react'
import translations from './translations'

translations.add('en', {
  partner: 'Partner',
  editPartner: 'Edit partner',
  editUser: 'Edit user',
  warningText: (
    <>
      <p>Partner information hasn't been fully keyed in.</p>
      <p>Please use the 'Sync Partner' button to ensure full synchronisation across all systems.</p>
      <p>
        <strong>Note:</strong> Access to the DNA Dashboard will not be available until the partner is fully
        synchronised.
      </p>
    </>
  )
})
