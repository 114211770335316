import { Table, Form, FormInstance } from 'antd'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import merge from 'lodash/merge'
import { PricingStore } from '~/code/pages/StartApplication/stores/PricingStore'
import { getTariffsInitialValues } from '../../services'
import translations from '../../translations'
import { NumberFormItem } from '../NumberFormItem'
import styles from './styles.scss'

type BlendedTableProps = {
    store: PricingStore
    form: FormInstance
    initialValues: any
}

export const BlendedTable = observer((props: BlendedTableProps) => {
    const { store, form, initialValues } = props

    const mergedInitialValues = useMemo(() => {
        return merge({}, getTariffsInitialValues(store.filteredtariffsList, (el) => ({ base: 0})), initialValues)
    }, [initialValues, store.filteredtariffsList])

    return  <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={mergedInitialValues}
    >
        <Table
            loading={store.isTariffsLoading}
            columns={columns}
            dataSource={store.filteredtariffsList?.map((p, idx) => ({ key: idx, ...p }))}
            pagination={false}
            size='small'
            bordered
            className={styles.blendedTable}
        />
    </Form>
})

const columns = [
    {
        title: translations().transactionType,
        children: [
            {
                key: 'cardScheme',
                dataIndex: 'cardScheme',
                title: translations().cardScheme
            },
            {
                key: 'serviceArea',
                dataIndex: 'serviceArea',
                title: translations().serviceArea
            },
            {
                key: 'corporateOrConsumer',
                dataIndex: 'corporateOrConsumer',
                title: translations().corporateOrConsumer
            },
            {
                key: 'cardType',
                dataIndex: 'cardType',
                title: translations().cardType
            }
        ]
    },
    {
        title: translations().baseFee,
        render: (_, row) => <NumberFormItem
            name={[row.interchangeFee.key, 'base']}
        />
    },
    {
        title: translations().percentFee,
        render: (_, row) => <NumberFormItem
            name={[row.interchangeFee.key, 'percent']}
        />
    }
]