import { action, makeObservable, observable } from 'mobx'
import { Moment } from 'moment-timezone'
import { injectable } from 'inversify'
import { PeriodType } from '~/code/models'
import { getDates, getPeriod } from '~/code/services/utils'
import { IRangePickerContainerStore } from '~/code/pages/settlements/SettlementsPayments/components/RangePickerContainer/IRangePickerContainerStore'

@injectable()
export class RangePickerStoreInjectable implements IRangePickerContainerStore {
  period: PeriodType = null
  startDate: Moment = null
  endDate: Moment = null
  disabled?: boolean = false
  disabledTooltipTitle?: string = null

  constructor() {
    makeObservable(this, {
      period: observable,
      startDate: observable,
      endDate: observable,
      disabled: observable,
      disabledTooltipTitle: observable,
      setDates: action.bound,
      setPeriod: action.bound,
      setMonths: action.bound,
      clear: action
    })
  }

  setDates(startDate: Moment, endDate: Moment) {
    this.startDate = startDate
    this.endDate = endDate
    this.period = getPeriod(startDate, endDate)
  }

  setMonths(values: [Moment, Moment]) {
    if (!values) {
      this.clear()
    }

    const [startDate, endDate] = values

    const start = startDate.startOf('month')
    const end = endDate.endOf('month')
    this.setDates(start, end)
  }

  setPeriod(period: PeriodType) {
    const { startDate, endDate } = getDates(period)
    this.startDate = startDate
    this.endDate = endDate
    this.period = period
  }

  clear = () => {
    this.period = null
    this.startDate = null
    this.endDate = null
    this.disabled = false
    this.disabledTooltipTitle = null
  }
}
