import React from 'react'
import { observer } from 'mobx-react'
import translations from './translations'
import { ListPage } from '../ListPage'
import { PricingProductsListProps } from './props'

export const PricingProductsList = observer((props: PricingProductsListProps) => {
  const { products, selectProduct, isSaveLoading, onSave, isContinueLoading, isValid } = props

  return (
    <ListPage
      title={translations().title}
      text={translations().details}
      list={
        products &&
        products.map(d => ({
          name: translations().prodName(d),
          onClick: () => selectProduct(d),
          isCompleted: isValid(d)
        }))
      }
      isContinueLoading={isContinueLoading}
      isSaveLoading={isSaveLoading}
      onSave={onSave}
      addTitle={translations().addTitle}
      sorting={false}
    />
  )
})
