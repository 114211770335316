import translations from './translations'

translations.add('en', {
    status: 'Status',
    transactionChannel: 'Transaction Channel',
    paymentMethod: 'Payment Method',
    issuerCountry: 'Issuer Country',
    ipCountry: 'IP Country',
    nonUK: 'Non-UK',
    payerIp: 'Payer IP',
    payerEmail: 'Payer Email',
    cardMask: 'Card Mask',
    terminal: 'Terminal',
    reference: 'RRN',
    mid: 'MID',
    merchantReference: 'Merchant Reference',
    threeDSVersion: '3DS Version',
    payerAuthenticationResult: 'Payer Auth',
    validationMinLength: 'Enter at least 3 characters',
    transactionId: 'Transaction ID',
    processingType: 'Processing Type',
    cardScheme: 'Card Scheme',
    signifydDecision: 'Signifyd Decision',
    any: 'Any',
    amountFrom: 'From',
    amountTo: 'To',
    amount: 'Amount'
})
