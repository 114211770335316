import React from 'react'
import { observer } from 'mobx-react-lite'
import { Card, Col, Form, Row, Select } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { RangePickerContainer } from '~/code/pages/settlements/SettlementsPayments/components/RangePickerContainer/RangePickerContainer'
import translations from '../../../SubmittedRequests/translations'
import { IBPMProcessesStoreDossierV2 } from '../../../../models'
import { MerchantSelect } from '../../MerchantSelect'
import { BPMProcessFilterDossierV2Props } from './props'
import styles from './BPMProcessFilterDossierV2.scss'

export const BPMProcessFilterDossierV2 = observer((props: BPMProcessFilterDossierV2Props) => {
  const { BPMProcessesStoreSymbol, BPMProcessesRangePickerStoreSymbol, BPMProcessesMerchantSelectStoreSymbol } = props

  const store = useInjection<IBPMProcessesStoreDossierV2>(BPMProcessesStoreSymbol)

  return (
    <>
      <Card>
        <Row justify={'space-between'}>
          <Col xs={24} sm={16}>
            <Form wrapperCol={{ span: 12 }} labelAlign='left' layout='vertical'>
              <MerchantSelect merchantSelectStoreSymbol={BPMProcessesMerchantSelectStoreSymbol} />
              <Form.Item label={translations().status}>
                <Select
                  className={styles.merchantSelect}
                  placeholder={translations().selectStatus}
                  options={store.statuses}
                  disabled={store.statuses.length <= 1}
                  loading={store.isStatusesLoading}
                  value={store.status}
                  onChange={v => store.setStatus(v)}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24} sm={8}>
            <RangePickerContainer injectableStoreIdentifier={BPMProcessesRangePickerStoreSymbol} />
          </Col>
        </Row>
      </Card>
    </>
  )
})
