import translations from './translations'

translations.add('en', {
  companyName: 'Company Name',
  storeName: 'Store Name',
  address: 'Address',
  changeBankDetails: 'Change Bank Details',
  changeSortCodeAccountNumber: 'Please change sort code or bank account number',
  grossSettlementChange: 'Change Settlement',
  changeGrossSettlementConfirmation: 'Set Monthly Net settlement?',
  changeSettlement: 'Change Settlement Type',
  dailyNet: 'Daily Net',
  monthlyNet: 'Monthly Net',
  grossDD: 'Gross Direct Debit',
  dailyGross: 'Daily Gross',
  errorDefiningSettlementType: 'Settlement type is not defined. Please, contact process-team',
  settlementType: 'Current settlement type: ',
  changeDirectDebitConfirmation: 'Set Gross settlement?',
  chargeMerchant: 'To charge the merchant by:',
  mandate: 'Mandate',
  settlementsStatus: 'Hold/Release Settlements',
  tipJar: 'TipJar',
  directDebitAlias: 'DD',
  settlementAlias: 'ST',
  edit: 'Edit',
  viewAttributes: 'View store attributes',
  viewAttributesBtnLabel: 'View attributes',
  closeStore: 'Close Store',
  accountName: 'Account Name',
  sortCode: 'Sort Code',
  storeStatus: 'Store Status',
  closedStore: 'store is closed'
})
