import React from 'react'
import { observer } from 'mobx-react'
import { Button } from 'antd'
import { useTimer } from 'dna-common'
import { OTP_CODE_RESEND_TIME_SEC } from '~/code/constants/AuthConstants'

import translations from './translations'
import styles from './styles.scss'

export type Props = {
  onResend: () => void
}

export const ResendButton: React.FC<Props> = observer(({ onResend }) => {
  const { start, count, isRunning } = useTimer(OTP_CODE_RESEND_TIME_SEC)

  const onClick = async () => {
    await onResend()

    start()
  }

  return (
    <>
      <Button type='link' className={styles.button} onClick={onClick} disabled={isRunning}>
        {translations().text}
      </Button>
      {translations().seconds(count)}.
    </>
  )
})