import React from 'react'
import translations from './translations'
import styles from '../ChangeBankDetailsInfo.scss'

translations.add('en', {
  obRequestWaiting: (
    <>
      We`ve sent the Open Banking authorisation request to the Merchant and are waiting for them to authorise the
      request
      <br />
      <br />
      Once authorised, this page will show results of the verification
    </>
  ),
  note: 'Please note: sometimes, it takes 5-10 minutes for the email to reach the recipient`s inbox and another 5-10 minutes for the status to update.',
  note2:
    '(!) Please proceed with the rest of the application, you will get an email notification update when the status changes.',
  note3:
    'You can now inform your merchant that they need to open the email and follow instructions to complete the Open Banking Verification',
  verifResult: (status: string) => (
    <>
      <div className={styles.flex}>
        <p>
          <b>{`Verification results -  `}</b>
        </p>
        {status?.toUpperCase() === 'MATCH' && (
          <p className={styles.statusWithMargin}>
            <b className={styles.green}>AUTHORISED, PASS</b>
          </p>
        )}
        {status?.toUpperCase() === 'SENT' && (
          <p className={styles.statusWithMargin}>
            <b>SENT, Waiting for the authorisation</b>
          </p>
        )}
        {status?.toUpperCase() === 'NOMATCH' && (
          <p className={styles.notVerified}>
            <b>FAIL</b>
          </p>
        )}
        {status?.toUpperCase() === 'FAILED' && <p className={styles.notVerified}>FAILED</p>}
        {status?.toUpperCase() === 'VERIFIED' && <p className={styles.verified}>VERIFIED</p>}
      </div>
    </>
  ),
  ifLinkNotOpened:
    'Please note: If the link is not opened, it will remain valid for 30 days. However, if a person opens the link, grants consent, and exits (without completing the verification), the link will remain valid for 24 hours.',
  refreshStatus: 'Refresh status',
  obRequestStatus: 'OPEN BANKING REQUEST STATUS',
  obrRequestDetailsInfo: (sortCode: string, accNum: string, accName: string, overallScore: number) => (
    <>
      <div>{`Sort code: ${sortCode}`}</div>
      <div>{`Account number: ${accNum}`}</div>
      <div>{`Account name: ${accName}`}</div>
      <div className={styles.flex}>
        <p>Overall score</p>
        <p className={overallScore > 65 ? styles.green : styles.red}>: {overallScore}</p>
      </div>
    </>
  ),
  obrRequestAuthorised:
    'Merchant has authorised the request and the ownership was verified. You wont need to upload the bank statement.',
  responseData: 'Open Banking Initial Verification',
  obrProceedWithDocs: 'If you want to proceed with original bank details, you will need to upload the bank statements',
  commonInfo: 'General Information',
  companyName: 'Company name',
  companyNumber: 'Company number',
  documentType: 'Document type',
  upload: 'Upload Document',
  complete: 'Complete',
  customVerification: 'DNA algorithm',
  account: 'Account ',
  obrMatchText:
    'We couldn’t automatically verify this account belongs to the company. You can continue to proceed with the Open Banking verification results below.',
  uploadBankStatement: 'I will upload a bank statement',
  terminate: 'Terminate process',
  comment: 'Comment',
  terminateConfirmation: 'Are you sure you want to terminate this process?',
  comments: 'Comments'
})
