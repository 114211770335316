import { Tabs, Grid, message } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { isEmpty } from 'dna-common'

import { TabType } from '~/code/models'
import { BackButton } from '~/code/components'
import { OfficerProfile, CompleteAppTabKey, PCSProfile, LoadingPropertyType, PaymentSolution } from 'startapp/models'
import { isPCSCorporate } from 'startapp/services'
import { CorporateDetailsForm, DetailsForm, GeneralInformationForm, FinancialInformationForm } from '..'
import { Shareholders, Directors, Signatories } from './components'
import { CompleteApplicationProps } from './props'
import styles from './CompleteApplication.scss'
import translations from './translations'

const { useBreakpoint } = Grid

const CommonTab: React.FC<{ title: string; subTitle: string }> = ({ title, subTitle }) => (
  <div className={styles.tabText}>
    <div>{title}</div>
    <div className={styles.subLabel}>{subTitle}</div>
  </div>
)

const Tab: React.FC<{ title: string; isCompleted: boolean }> = ({ title, isCompleted }) => (
  <CommonTab title={title} subTitle={isCompleted ? translations().completed : translations().notCompleted} />
)

export const CompleteApplication = observer((props: CompleteApplicationProps) => {
  const { store } = props
  const screen = useBreakpoint()

  const formProps = {
    cachedPhoneNumbers: [...store.dataStore.cachedPhoneNumbers],
    cachedEmails: [...store.dataStore.cachedEmails]
  }

  const submitOfficer = async (
    data: OfficerProfile,
    loadingProperty: LoadingPropertyType,
    showOverview: boolean = true
  ) => {
    store.dataStore.savePhoneNumberAndEmail(data.telephoneNumber, data.emailAddress)
    const { officerRole, id } = store.selectedOfficer as OfficerProfile
    if (store.isCreateMode) {
      const newOfficer = store.dataStore.createOfficer({ ...data, officerRole, deletionAllowed: true })
      store.selectOfficer(newOfficer)
    } else {
      store.dataStore.updateOfficer({
        ...data,
        officerRole,
        id,
        deletionAllowed: store.selectedOfficer.deletionAllowed
      })
    }

    const isOk = await store.dataStore.saveApplication(loadingProperty)

    if (showOverview && isOk) {
      store.selectOfficer(null)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const submitShareholder = async (
    data: PCSProfile,
    loadingProperty: LoadingPropertyType,
    showOverview: boolean = true
  ) => {
    store.dataStore.savePhoneNumberAndEmail(data.telephoneNumber, data.emailAddress)
    const { id } = store.selectedOfficer as PCSProfile
    store.dataStore.updateShareholder({
      ...data,
      id
    })

    const isOk = await store.dataStore.saveApplication(loadingProperty)

    if (showOverview && isOk) {
      store.selectOfficer(null)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const handleSaveOfficer = (data: OfficerProfile) => submitOfficer(data, 'isSaveLoading', false)
  const handleSubmitOfficer = (data: OfficerProfile) => submitOfficer(data, 'isNextLoading')
  const handleSaveShareholder = (data: OfficerProfile) => submitShareholder(data, 'isSaveLoading', false)
  const handleSubmitShareholder = (data: OfficerProfile) => submitShareholder(data, 'isNextLoading')
  const handleSubmitBusinessOwner = (data: OfficerProfile) => submitOfficer(data, 'isNextLoading', false)

  const detailsFormProps = {
    isSaveLoading: store.isSaveLoading,
    isNextLoading: store.isNextLoading
  }

  const overviewFormProps = {
    isSaveLoading: store.isSaveLoading,
    onSave: store.saveOnOfficer
  }

  const Back = () => <BackButton onClick={store.goToBack}>{translations().backText}</BackButton>

  useEffect(() => {
    return store.onClose
  }, [])

  const openSupportingDocumentsPage = () => {
    if (!store.dataStore.isApplicationValidWithoutDocuments) {
      message.error(translations().pleaseCompleteApplication)
      return
    }

    store.openSupportingDocumentsPage()
  }

  const tabItems: TabType[] = [
    {
      key: 'generalInformation',
      label: <Tab title={translations().generalInformation} isCompleted={store.dataStore.isCompanyProfileValid} />,
      children: (
        <>
          <Back />
          <GeneralInformationForm
            companyType={store.dataStore.companyType}
            data={store.dataStore.application.companyProfile}
            onSubmit={store.submitCompanyProfile}
            isNextLoading={store.isNextLoading}
            isVerifyBankLoading={store.isVerifyBankLoading}
            verifyBankAccountResult={store.verifyBankAccountResult}
            setVerifyBankAccountResult={store.setVerifyBankAccountResult}
            verifyBankAccountDetails={store.verifyBankAccountDetails}
            isSaveLoading={store.isSaveLoading}
            onSave={store.saveOnGeneralInformation}
            isCompanyWebsiteRequired={
              store.dataStore.generalInformationData?.paymentSolution === PaymentSolution.WEBSITE_PAYMENTS
            }
            {...formProps}
          />
        </>
      )
    },
    {
      key: 'financialInformation',
      label: <Tab title={translations().financialInformation} isCompleted={store.dataStore.isFinancialInfoValid} />,
      children: (
        <>
          <Back />
          <FinancialInformationForm
            companyType={store.dataStore.companyType}
            data={store.dataStore.application.companyProfile}
            onSubmit={store.submitCompanyProfile}
            isNextLoading={store.isNextLoading}
            isVerifyBankLoading={store.isVerifyBankLoading}
            verifyBankAccountResult={store.verifyBankAccountResult}
            setVerifyBankAccountResult={store.setVerifyBankAccountResult}
            verifyBankAccountDetails={store.verifyBankAccountDetails}
            isSaveLoading={store.isSaveLoading}
            onSave={store.saveOnGeneralInformation}
            isCompanyWebsiteRequired={
              store.dataStore.generalInformationData?.paymentSolution === PaymentSolution.WEBSITE_PAYMENTS
            }
          />
        </>
      )
    }
  ]

  if (store.dataStore.companyType === 'company') {
    tabItems.push(
      {
        key: 'shareholders',
        label: <Tab title={translations().shareholders} isCompleted={store.dataStore.isShareholdersValid} />,
        children: store.selectedOfficer ? (
          <>
            <BackButton onClick={() => store.selectOfficer(null)}>{translations().shareholders}</BackButton>
            {isPCSCorporate(store.selectedOfficer) ? (
              <CorporateDetailsForm
                data={store.selectedOfficer}
                onSubmit={handleSubmitShareholder}
                isSaveLoading={store.isSaveLoading}
                onSave={handleSaveShareholder}
              />
            ) : (
              <DetailsForm
                data={store.selectedOfficer}
                onSubmit={handleSubmitShareholder}
                {...formProps}
                {...detailsFormProps}
                onSave={handleSaveShareholder}
              />
            )}
          </>
        ) : (
          <>
            <Back />
            <Shareholders
              shareholders={store.dataStore.shareholders}
              deleteShareholder={store.dataStore.deleteShareholder}
              selectShareholder={store.selectShareholder}
              onContinue={() => store.selectTab('directors')}
              {...overviewFormProps}
            />
          </>
        )
      },
      {
        key: 'directors',
        label: <Tab title={translations().directors} isCompleted={store.dataStore.isDirectorsValid} />,
        children: store.selectedOfficer ? (
          <>
            <BackButton onClick={() => store.selectOfficer(null)}>{translations().directors}</BackButton>
            <DetailsForm
              data={store.selectedOfficer}
              onSubmit={handleSubmitOfficer}
              title={store.isCreateMode && translations().newDirector}
              {...formProps}
              {...detailsFormProps}
              onSave={handleSaveOfficer}
            />
          </>
        ) : (
          <>
            <Back />
            <Directors
              directors={store.dataStore.directors}
              deleteOfficer={store.dataStore.deleteOfficer}
              selectOfficer={store.selectOfficer}
              onContinue={() => store.selectTab('authorisedSignatories')}
              {...overviewFormProps}
            />
          </>
        )
      },
      {
        key: 'authorisedSignatories',
        label: (
          <CommonTab
            title={translations().signatories}
            subTitle={
              isEmpty(store.dataStore.signatories)
                ? translations().optional
                : store.dataStore.isAuthorisedSignatoriesValid
                ? translations().completed
                : translations().notCompleted
            }
          />
        ),
        children: store.selectedOfficer ? (
          <>
            <BackButton onClick={() => store.selectOfficer(null)}>{translations().signatories}</BackButton>
            <DetailsForm
              data={store.selectedOfficer}
              onSubmit={handleSubmitOfficer}
              title={store.isCreateMode && translations().newSignatory}
              {...formProps}
              {...detailsFormProps}
              onSave={handleSaveOfficer}
            />
          </>
        ) : (
          <>
            <Back />
            <Signatories
              signatories={store.dataStore.signatories}
              deleteOfficer={store.dataStore.deleteOfficer}
              selectOfficer={store.selectOfficer}
              isContinueLoading={store.isCheckDocumentsRequiredLoading}
              onContinue={() => openSupportingDocumentsPage()}
              {...overviewFormProps}
            />
          </>
        )
      }
    )
  } else {
    tabItems.push({
      key: 'businessOwner',
      label: <Tab title={translations().businessOwner} isCompleted={store.dataStore.isBusinessOwnerValid} />,
      children: store.selectedOfficer && (
        <>
          <Back />
          <DetailsForm
            data={store.dataStore.owner}
            onSubmit={async data => {
              await handleSubmitBusinessOwner(data)
              openSupportingDocumentsPage()
            }}
            title={translations().businessOwner}
            {...formProps}
            {...detailsFormProps}
            onSave={handleSaveOfficer}
            isNextLoading={store.isCheckDocumentsRequiredLoading}
          />
        </>
      )
    })
  }

  return (
    <div className={styles.container}>
      <Tabs
        tabPosition={screen.sm ? 'right' : 'top'}
        size={screen.sm ? 'large' : 'small'}
        activeKey={store.currentTab}
        onChange={(activeKey: CompleteAppTabKey) => store.selectTab(activeKey)}
        items={tabItems}
      />
    </div>
  )
})
