import translations from './translations'

translations.add('en', {
  home: 'Home',
  dashboard: 'Dashboard',
  transactions: 'Transactions',
  dnaAcquiring: 'DNA Acquiring',
  optomanyCheckoutV3: 'Optomany Checkout V3',
  analytics: 'ECOM Analytics',
  analyticsTotal: 'Total Analytics',
  analyticsPos: 'POS Analytics',
  ecomMonitoring: 'ECOM Monitoring',
  monitoringPos: 'POS Monitoring',
  monitoringPosTest: 'POS Monitoring (Test)',
  monitoringPosV2: 'POS Monitoring V2',
  monitoringPosAmex: 'POS American Express',
  bmpReports: 'BPM Reports',
  onboarding: 'Onboarding Processes',
  partnerOnboarding: 'Onboarding',
  applications: 'Applications',
  newApplication: 'New Application',
  incompleteApplications: 'Incomplete Applications',
  statistics: 'Statistics',
  submittedRequests: 'Submitted Requests',
  bpmProcesses: 'BPM Processes',
  merchantManagement: 'Merchants',
  billing: 'Billing',
  invoices: 'Invoices',
  netSuiteInvoices: 'NetSuite Invoices',
  profile: 'Profile',
  settlements: 'Settlements',
  statement: 'Statement',
  statementTest: 'Statement (Test)',
  payments: 'Payments',
  transactionChannels: 'Transaction Channels',
  riskLimits: 'Change of Risk Limits',
  settlementPeriod: 'Change of Settlement Period',
  terminalIssue: 'Additional Terminal Issue',
  motoVt: 'MOTO/VT Request',
  changeBankDetailsDossierV2: 'Change of Bank Details',
  closureOfMerchant: 'Closure of Merchant',
  closureOfTerminal: 'Closure of Terminal',
  newProcess: 'New Application',
  portalAccess: 'Merchant Portal Access',
  partnerDashboardAccess: 'Partner Dashboard Access',
  chargebacks: 'Chargebacks',
  settlementType: 'Change of Settlement Type',
  teammates: 'Team Management',
  exports: 'Exports',
  additionalStoreProcesses: 'Additional Store Processes',
  settlementStatus: 'Hold/Release Settlements Requests',
  taskList: 'Tasks',
  adjustmentRequests: 'Adjustment Requests',
  editTariffsRequests: 'Change of fees',
  partnerCommissions: 'Partner Commissions (Test)',
  notificationsManagement: 'Notifications Management',
  manageTemplates: 'Manage Templates',
  manageNotifications: 'Manage Notifications'
})
