import translations from './translations'

translations.add('en', {
    notificationSaved: 'notification saved',
    notificationDeactivated: 'Notification was successfully deactivated',
    all: 'All',
    notificationCreated: 'Notification successfully created',
    unexpectedError: 'An unexpected error occurred'

})
