import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    totalApp: string
    avgReviewTime: string
    target: string
    performance: string
    acquisitionChannel: string
}>()

