import translations from './translations'

translations.add('en', {
  edit: 'Edit Partner',
  add: 'Create Partner',
  synced: 'Sync Partner',
  cancel: 'Cancel',
  submit: 'Save',

})
