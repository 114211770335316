import { action, makeObservable, observable } from 'mobx'
import moment from 'moment-timezone'
import { GroupableItem, PeriodType, TableData, TableFetchParams } from '~/code/models'
import { clearObject, createCheckColumnDate } from '~/code/services/utils'
import { RangePickerStore } from '~/code/stores/RangePickerStore'
import { TableFilterStore } from '~/code/stores/TableFilterStore'
import { fetchProcesses } from '../../../services/fetchers'
import { BPMApplicationModel } from '../../../models'
import { ProcessesFilterModel } from '../models'

export class ProcessesDataStore {
  filter = new ProcessesFilterModel()
  tableStore: TableFilterStore<GroupableItem<BPMApplicationModel>>
  dateStore: RangePickerStore
  dateColumn = {}
  additionalStore: boolean = false

  constructor(rangePickerStore: RangePickerStore) {
    this.tableStore = new TableFilterStore(this.fetchData)
    this.dateStore = rangePickerStore

    makeObservable(this, {
      filter: observable,
      additionalStore: observable,
      setFilter: action,
      setAdditionalStore: action
    })
  }

  setAdditionalStore(value: boolean) {
    this.additionalStore = value
  }

  setFilter(filter: Partial<ProcessesFilterModel>, shouldLoad: boolean = true) {
    this.filter = {
      ...this.filter,
      ...filter
    }
    if (shouldLoad) {
      this.loadData(1)
    }
  }

  resetFilter() {
    this.filter = new ProcessesFilterModel()
    this.dateStore.setPeriod(PeriodType.Yesterday)
  }

  loadData(pageNumber?: number) {
    this.tableStore.loadData({ pageNumber })
  }

  checkColumnDate = createCheckColumnDate(this.dateColumn)

  fetchData = async (
    params: TableFetchParams
  ): Promise<TableData<GroupableItem<BPMApplicationModel>, number>> => {
    const filledfilters = Object.entries(this.filter)
      .filter(([, value]) => Boolean(value))
      .reduce(
        (prev, [key, value]) => ({
          ...prev,
          [key]: typeof value === 'string' ? value.trim() : value
        }),
        {}
      )

    const { error, result } = await fetchProcesses(
      {
        from: this.dateStore.startDate.clone().utc().format(),
        to: this.dateStore.endDate.clone().utc().format(),
        page: params.currentPage,
        size: params.pageSize,
        ...filledfilters
      },
      this.additionalStore
    )

    clearObject(this.dateColumn)

    if (error || !result.report) {
      return {
        data: [],
        total: 0
      }
    }

    const data: GroupableItem<BPMApplicationModel & { key: string }>[] = []
    result.report.forEach((item, index) => {
      const date = moment(item.startDate).format('DD.MM.YYYY')
      if (this.checkColumnDate(date)) {
        data.push({ groupTitle: date, key: date, rowId: `${index} ${date}` })
      }
      data.push({ ...item, key: item.id })
    })

    return {
      data,
      total: result.totalCount || 0
    }
  }
}
