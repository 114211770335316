import React from 'react'
import translations from './../translations'
import { FieldType } from './../models/FieldType'

export function getChangedValue(oldValue: any, newValue: any) { // TODO: add interface
    return Object.keys(oldValue).map((key) => {
        return {
            oldValue: oldValue[key],
            newValue: newValue[key],
            key,
            type: key === 'dailyCount' || key === 'monthlyCount' ? FieldType.COUNT : FieldType.AMOUNT
        }
    }).filter((item) => item.newValue !== item.oldValue)
}


export function convertLimitFieldsToStartProc(values, limits) {
    const limitValues = {}
        Object.keys(values).forEach((limit) => {
            if (limits.hasOwnProperty(limit)) limitValues[limit] = values[limit]
        })
    return limitValues
 }

export function convertLimitFields(values) {
    return {
        dailyAmount: values.dailyAmount ? parseInt(values.dailyAmount, 10) : 0,
        dailyCount: values.dailyCount ? parseInt(values.dailyCount, 10) : 0,
        dailyMaxRefundAmount: values.dailyMaxRefundAmount ? parseInt(values.dailyMaxRefundAmount, 10) : 0,
        monthlyAmount: values.monthlyAmount ? parseInt(values.monthlyAmount, 10) : 0,
        monthlyCount: values.monthlyCount ? parseInt(values.monthlyCount, 10) : 0,
        perTransaction: values.perTransaction ? parseInt(values.perTransaction, 10) : 0,
        perRefund: values.perRefund ? parseInt(values.perRefund, 10) : 0,
        perDevice: values.perDevice ? parseInt(values.perDevice, 10) : 0
    }
}

export function getDefaultLimitFields(values) {
    return {
        dailyAmount: values.dailyAmount || 0,
        dailyCount: values.dailyCount || 0,
        dailyMaxRefundAmount: values.dailyMaxRefundAmount || 0,
        monthlyAmount: values.monthlyAmount || 0,
        monthlyCount: values.monthlyCount || 0,
        perTransaction: values.perTransaction || 0,
        perRefund: values.perRefund || 0,
        perDevice: values.perDevice || 0
    }
}

export function getChangedItemsList(initialValues, currentValues) {
    const changedList = getChangedValue(initialValues, currentValues)
    if (changedList.length === 0) return null
    return (
        <div>
            {
                changedList.map((el) => (
                    <div key={ el.key }>
                        { translations().changeItem(el) }
                    </div>
                ))
            }
        </div>
    )
}
