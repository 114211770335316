import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  contractNumber: string
  bankAccountName: string
  bankAccountNumber: string
  sortCode: string
  change: string
  changeSettlement: string
  mid: string
  payneticsMid: string
  settlemetTeam: (teamName: string) => string
  closedContract: string
  constractStatus: string
  dnaMid: string
  editTariffs: string
  tipJar: string
}>()
