import translations from './translations'

translations.add('en', {
  contractNumber: 'Contract Number',
  name: 'Name',
  status: 'Status',
  mid: 'MID',
  currentBalance: 'Current Balance',
  currency: 'Currency',
  contractOpenDate: 'Contract Open Date',
  showMore: 'Show more...',
  showLess: 'Show less...',
  adjust: 'Adjust',
  adjustment: 'Adjustment process start form',
  adjustmentType: 'Adjustment Type',
  amount: 'Amount',
  sfCaseId: 'SF Case Id',
  search: 'Search',
  required: 'Required',
  midsAreNotEqual: 'MIDs do not match.',
  midsAreNotEqualInstruction: 'Please check if the correct Merchant/SF case were selected',
  noSFDataFound: 'No data found.',
  noSFDataFoundInstruction: 'Please check if the correct Merchant/SF case were selected',
  description: 'Comment',
  submit: 'Submit'
})
