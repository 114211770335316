import translations from './translations'

translations.add('en', {
    no: 'No',
    yesTerminateProcess: 'Yes, terminate',
    areYouSureTerminateProcess: 'Are you sure you want to terminate the process?',
    areYouSureTerminateProcesses: 'Are you sure you want to terminate the processes?',
    reasonForTerminating: 'Reason for terminating:',
    seeProcess: 'See Process',
    seeProcesses: 'See Processes'
})
