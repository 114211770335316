import { inject, injectable } from 'inversify'
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import { message } from 'antd'
import {
  fetchPosModels,
  startTerminalIssueProcess,
  fetchPosBundlesById
} from '~/code/stores/MerchantsManagementStore/services/fetchers'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import { MerchantsManagementStoreSymbol } from '~/code/pages'
import { MerchantsManagementStore } from '~/code/stores/MerchantsManagementStore/MerchantsManagementStore'
import { MidsResType } from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v1/MidsResType'
import translations from './translations'
import { ITerminalIssueStore } from '~/code/pages/MerchantsManagement/components/TerminalIssue/ITerminalIssueStore'
import { isCountryUK, validatePostalCodeOfUK } from './services/utils'
import { jsonType, stringType, integerType, booleanType, posProductPrefixes, terminalActiveStatus } from './constants'

export interface SelectItem {
  label: string
  value: string
  id?: number
}

@injectable()
export class TerminalIssueStore implements ITerminalIssueStore {
  merchantsManagementStore: MerchantsManagementStore
  isLoading: boolean
  isPOSModelsLoading: boolean
  isMIDsLoading: boolean
  posModels: SelectItem[]
  mids: MidsResType[]
  selectedMid: MidsResType
  isMoto: boolean
  isPosBundlesLoading: boolean
  bundleOptions: SelectItem[]
  contractType: string
  selectedDeliveryAddress: string

  constructor(@inject(MerchantsManagementStoreSymbol) merchantsManagementStore: MerchantsManagementStore) {
    this.merchantsManagementStore = merchantsManagementStore
    this.isLoading = false
    this.isPOSModelsLoading = false
    this.isMIDsLoading = false
    this.posModels = []
    this.mids = []
    this.selectedMid = null
    this.isMoto = false
    this.isPosBundlesLoading = false
    this.bundleOptions = []
    this.contractType = ''
    this.selectedDeliveryAddress = ''

    makeObservable(this, {
      isLoading: observable,
      posModels: observable,
      isPOSModelsLoading: observable,
      mids: observable,
      isMIDsLoading: observable,
      selectedMid: observable,
      isMoto: observable,
      isPosBundlesLoading: observable,
      bundleOptions: observable,
      contractType: observable,
      selectedDeliveryAddress: observable,
      selectedMerchant: computed,
      isMerchantActive: computed,
      setMid: action.bound,
      setContractType: action.bound,
      getMerchantAddress: action.bound
    })

    this.init()

    reaction(
      () => this.merchantsManagementStore.companyFullDossierV2,
      () => {
        this.init()
      }
    )
  }

  init() {
    this.loadPOSModels()
    this.loadMIDs()
  }

  setMid = (value: string) => {
    this.selectedMid = this.mids.find(midItem => midItem.mid === value)
    this.setIsMoto(this.selectedMid.isMoto)
  }

  setIsMoto = (value: boolean) => {
    this.isMoto = value
  }

  setContractType = (contractType: string) => {
    this.contractType = contractType
  }

  get selectedMerchant() {
    return this.merchantsManagementStore.selectedMerchant
  }

  get isMerchantActive() {
    return this.merchantsManagementStore.isMerchantActive
  }

  async loadPOSModels() {
    try {
      runInAction(() => {
        this.isPOSModelsLoading = true
      })
      const { status, error, result } = await fetchPosModels()
      if (status !== 200 || error) {
        message.error(error.message || translations().fetchingPOSModelsError)
      } else {
        runInAction(() => {
          this.posModels = result.map(model => ({ value: model.code, label: model.value, id: model.id })) || []
        })
      }
    } catch (error) {
      message.error(translations().fetchingPOSModelsError)
    } finally {
      runInAction(() => {
        this.isPOSModelsLoading = false
      })
    }
  }

  loadMIDs() {
    const uniqueMerchantIds = []
    const stores = this.merchantsManagementStore.companyFullDossierV2?.processing?.stores || []

    stores.forEach(store => {
      store.contracts.forEach(contract => {
        const posTerminals = contract.terminals.filter(
          ({ terminal }) =>
            posProductPrefixes.includes(terminal.terminalId.substring(0, 2)) &&
            terminal.terminalStatus === terminalActiveStatus
        )
        const hasMotoTerminal = contract.terminals.some(({ terminal }) => terminal.isMoto)

        posTerminals.forEach(({ terminal }) => {
          const isMerchantIdUnique = !uniqueMerchantIds.some(({ mid }) => mid === terminal.merchantId)
          if (isMerchantIdUnique) {
            const contractInfo = store.contracts.find(({ contract }) => contract.id === terminal.contractId)
            uniqueMerchantIds.push({
              mid: terminal.merchantId,
              descriptor: terminal.descriptor,
              address: store.storeAddress.addressLine1,
              contractNumber: contractInfo.contract.contractNumber,
              id: contractInfo.contract.id,
              isMoto: hasMotoTerminal
            })
          }
        })
      })
    })

    this.mids = uniqueMerchantIds
  }

  prepareValue(value: string | number | undefined) {
    if (value === undefined) return null
    return value
  }

  async getBundlesForSelectedPos(posCode: string) {
    const id = this.posModels.find(item => item.value === posCode).id

    runInAction(() => {
      this.isPosBundlesLoading = true
    })

    const { status, result, error } = await fetchPosBundlesById(id)
    if (status !== 200 || error) {
      message.error('error')
    }

    const items = result && !error ? result.map(item => ({ value: item.code, label: item.value })) : []
    this.bundleOptions = items

    runInAction(() => {
      this.isPosBundlesLoading = false
    })
  }

  getPhone() {
    const phone = this.merchantsManagementStore.companyFullDossierV2?.phone?.[0]?.fullPhoneNum
    return phone || ''
  }

  getEmail() {
    const email = this.merchantsManagementStore.companyFullDossierV2?.email?.[0]?.email
    return email || ''
  }

  public onFormFinish = async values => {
    const { newTerminalQnt, needNewMID, ...rest } = values
    const { companyNumber = '', companyName = '' } = this.merchantsManagementStore.companyFullDossierV2?.mainInfo ?? {}
    const { acquisitionChannelCode, segmentSubCategoryCode, segmentCategoryCode } =
      this.merchantsManagementStore.getAcquisitionChannel()
    const data = {
      variables: {
        id: {
          value: this.selectedMid.id,
          type: stringType
        },
        dossierId: {
          value: this.merchantsManagementStore.companyFullDossierV2?.mainInfo?.dsrId?.toString(),
          type: stringType
        },
        companyName: {
          value: companyName,
          type: stringType
        },
        companyNumber: {
          value: companyNumber,
          type: stringType
        },
        procType: {
          value: 'additionalTerminals',
          type: stringType
        },
        needNewMID: {
          value: !values.needNewMID,
          type: booleanType
        },
        newTerminalQnt: {
          value: Number(values.newTerminalQnt),
          type: integerType
        },
        needNewTarrifs: {
          value: false,
          type: booleanType
        },
        needMoto: {
          value: this.isMoto,
          type: booleanType
        },
        contractType: {
          value: JSON.stringify({
            subscriptionServicePeriod: values.subscriptionServicePeriod || null,
            freeSubscriptionPeriod: this.prepareValue(values.freeSubscriptionPeriod),
            terminalType: values.contractType,
            chargeType: values.chargeType || ''
          }),
          type: jsonType
        },
        productTariffs: {
          value: JSON.stringify({
            terminalCost: this.prepareValue(values.terminalCost),
            setUpConfigurationDeliveryFee: this.prepareValue(values.setUpConfigurationDeliveryFee),
            paymentTerminalSupportAndTransactionFee: this.prepareValue(values.feeSubscriptionPerMonth)
          }),
          type: jsonType
        },
        paymentSolution: {
          value: values.paymentSolution || '',
          type: stringType
        },
        POSModel: {
          value: values.POSModel,
          type: stringType
        },
        existingMerchantID: {
          value: values.existingMerchantID,
          type: stringType
        },
        deliveryType: {
          value: values.posDeliveryAddressType,
          type: stringType
        },
        posBundles: {
          value: JSON.stringify(values.posBundles),
          type: jsonType
        },
        deliveryDetails: {
          value: JSON.stringify({
            address: values.address,
            deliveryInstructions: values.posDeliveryInstructions,
            posDeliveryNumber: values.posDeliveryNumber,
            deliveryType: values.posDeliveryAddressType,
            contact: {
              jobTitle: '',
              firstName: '',
              surname: '',
              telephoneNumber: this.getPhone(),
              mobileNumber: this.getPhone(),
              email: this.getEmail()
            }
          }),
          type: jsonType
        },
        acquisitionChannel: {
          value: acquisitionChannelCode,
          type: stringType
        },
        segmentCategory: {
          value: segmentCategoryCode,
          type: stringType
        },
        segmentSubCategory: {
          value: segmentSubCategoryCode,
          type: stringType
        }
      }
    }

    try {
      runInAction(() => {
        this.isLoading = true
      })
      const { status, error, result } = await startTerminalIssueProcess(data)

      if (status === 200) {
        message.success(result.message || translations().requestSuccess)
        return goToRoute(Routes.BPM_PROCESSES_TERMINAL_ISSUE)
      } else {
        throw new Error(error.message || translations().requestFail)
      }
    } catch (error) {
      message.error(error.message || translations().requestFail)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  getMerchantAddress = (): string => {
    const merchanrAddress = this.merchantsManagementStore.companyFullDossierV2?.address?.[0]
    if (merchanrAddress) {
      return `${merchanrAddress.townOrCity} ${merchanrAddress.addressLine1} ${merchanrAddress.addressLine2} ${merchanrAddress.postalCode}`
    }
    return ''
  }

  setDeliveryAddress = (address: string) => {
    if (address === 'sameAsCompanyAddress') {
      this.selectedDeliveryAddress = this.selectedMid?.address || ''
      return
    }
    if (address === 'sameAsStoreAddress') {
      this.selectedDeliveryAddress = this.getMerchantAddress() || ''
      return
    }
    this.selectedDeliveryAddress = ''
  }

  isCountryUK = (country: string) => {
    return isCountryUK(country)
  }

  validatePostalCodeOfUK = (postalCode: string) => {
    return validatePostalCodeOfUK(postalCode)
  }
}
