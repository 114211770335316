import { Col, Form, Popover, Row, Switch } from 'antd'
import React from 'react'
import { NumberFormItem } from '~/code/components/NumberFormItem'
import translations from '../translations'
import styles from '../styles.scss'
import { IcTariffType } from '../../../models'

const subBaseProps = {
  label: translations().baseLabel,
  labelCol: { span: 24 },
  className: styles.subLabel
}
const subPercentProps = {
  label: translations().percentLabel,
  labelCol: { span: 24 },
  className: styles.subLabel,
  placeholder: translations().percentPlaceholder
}

export const childrenColumns = [
  {
    title: '',
    dataIndex: 'disable',
    key: 'disable',
    width: '5%',
    align: 'center' as 'center'
  },
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    width: 120
  },
  {
    title: '',
    dataIndex: 'value',
    key: 'value',
    width: 150,
    align: 'center' as 'center'
  },

  {
    title: '',
    dataIndex: 'description',
    key: 'description',
    width: 150
  }
]

export const childrenColumnsWithoutDisable = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    width: 150
  },
  {
    title: '',
    dataIndex: 'value',
    key: 'value',
    width: 140,
    align: 'center' as 'center'
  },

  {
    title: '',
    dataIndex: 'description',
    key: 'description',
    width: 140
  }
]

const zashChildrenColumns = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    width: 160
  },
  {
    title: '',
    dataIndex: 'value',
    key: 'value',
    width: 150,
    onCell: (_, index) => ({
      colSpan: 1
    })
  },

  {
    title: '',
    dataIndex: 'description',
    key: 'description',
    width: 150,
    onCell: (_, index) => ({
      colSpan: 1
    })
  }
]

export const feesColumns = [
  {
    title: translations().nonTransactionFees,
    children: childrenColumns
  }
]

export const zashColumns = [
  {
    title: <b>{translations().zashTitle}</b>,
    children: zashChildrenColumns
  }
]

const ffChildrenColumns = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    width: 160
  },
  {
    title: '',
    dataIndex: 'value',
    key: 'value',
    width: 150,
    onCell: (_, index) => ({
      colSpan: 1
    })
  },

  {
    title: '',
    dataIndex: 'description',
    key: 'description',
    width: 150,
    onCell: (_, index) => ({
      colSpan: 1
    })
  }
]

export const ffColumns = [
  {
    title: translations().settlementFF,
    children: [
      {
        title: <i>{translations().settlementFFNote}</i>,
        children: ffChildrenColumns,
        align: 'left' as 'left'
      }
    ]
  }
]

export const settlementFeesColumns = [
  {
    title: translations().settlements,
    children: [
      {
        title: <i>{translations().settlementNote}</i>,
        children: childrenColumnsWithoutDisable,
        align: 'left' as 'left'
      }
    ]
  }
]

export const nonTransationFeesColumns = [
  {
    title: translations().nonTransactionFees,
    children: childrenColumnsWithoutDisable
  }
]

export const feesColumnsCommon = [
  {
    title: <b>{translations().transactionFees}</b>,
    children: childrenColumns
  }
]

export const authColumns = [
  {
    title: translations().authFee,
    children: childrenColumnsWithoutDisable
  }
]

export const nonTransationFeesData = (tariffsList: IcTariffType[], withoutLimits: boolean) => {
  return [
    {
      key: '1',
      name: translations().refundFee,
      value: (
        <NumberFormItem
          min={
            !withoutLimits &&
            tariffsList.find(item => item.interchangeFee?.key === 'refundFee')?.interchangeFee?.minBaseFee
          }
          max={
            !withoutLimits &&
            tariffsList.find(item => item.interchangeFee?.key === 'refundFee')?.interchangeFee?.maxBaseFee
          }
          name='refundFee'
          placeholder={translations().zeroPlaceholder}
        />
      ),
      description: translations().refundFeeDescription
    },
    {
      key: '2',
      name: translations().chargebackFee,
      value: <NumberFormItem name='chargebackFee' placeholder={translations().zeroPlaceholder} />,
      description: translations().chargeBackFeeDescription
    },
    {
      key: '3',
      name: translations().pciFee,
      value: <NumberFormItem name='pciFee' placeholder={translations().zeroPlaceholder} />,
      description: translations().pciFeeDescription
    }
  ]
}

export const settlementFeesData = (prd: string, tariffsList: IcTariffType[], withoutLimits: boolean) => {
  return [
    {
      key: '1',
      name: translations().settlementPeriod,
      value: (
        <NumberFormItem
          disabled={prd === 'ecom'}
          min={1}
          max={3}
          name='settlementPeriod'
          placeholder={translations().zeroPlaceholder}
        />
      ),
      description: '',
      type: 'ecom'
    },
    {
      key: '2',
      name: translations().settlementFee,
      value: (
        <NumberFormItem
          min={
            !withoutLimits &&
            tariffsList?.find(item => item?.interchangeFee?.key === 'settlementFee')?.interchangeFee?.minBaseFee
          }
          max={
            !withoutLimits &&
            tariffsList?.find(item => item?.interchangeFee?.key === 'settlementFee')?.interchangeFee?.maxBaseFee
          }
          name='settlementFee'
          placeholder={translations().zeroPlaceholder}
        />
      ),
      description: translations().settlementNote
    }
  ]
}

export const ffData = (tariffsList: IcTariffType[], withoutLimits: boolean) => {
  return [
    {
      key: '1',
      name: translations().fasterFundingFee,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <NumberFormItem
                validate
                name='fasterFundingFee'
                min={
                  !withoutLimits &&
                  tariffsList?.find(item => item?.interchangeFee?.key === 'fasterFundingFee')?.interchangeFee
                    ?.minBaseFee
                }
                max={
                  !withoutLimits &&
                  tariffsList?.find(item => item?.interchangeFee?.key === 'fasterFundingFee')?.interchangeFee
                    ?.maxBaseFee
                }
                placeholder={translations().zeroPlaceholder}
              />
            )
          }}
        </Form.Item>
      ),
      description: translations().fasterFundingFeeDescription
    },
    {
      key: '2',
      name: translations().fasterFundingMonthly,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <NumberFormItem
                validate
                name='fasterFundingMonthly'
                min={
                  !withoutLimits &&
                  tariffsList?.find(item => item?.interchangeFee?.key === 'fasterFundingMonthly')?.interchangeFee
                    ?.minBaseFee
                }
                max={
                  !withoutLimits &&
                  tariffsList?.find(item => item?.interchangeFee?.key === 'fasterFundingMonthly')?.interchangeFee
                    ?.maxBaseFee
                }
                placeholder={translations().zeroPlaceholder}
              />
            )
          }}
        </Form.Item>
      ),
      description: translations().fasterFundingMonthlyDescription
    }
  ]
}

export const zashData = (tariffsList: IcTariffType[], withoutLimits: boolean) => {
  return [
    {
      key: '10',
      name: translations().zashApp,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <NumberFormItem
                name='zashApp'
                placeholder={translations().zeroPlaceholder}
                min={
                  !withoutLimits &&
                  tariffsList?.find(item => item?.interchangeFee?.key === 'zashApp')?.interchangeFee?.minBaseFee
                }
                max={
                  !withoutLimits &&
                  tariffsList?.find(item => item?.interchangeFee?.key === 'zashApp')?.interchangeFee?.maxBaseFee
                }
              />
            )
          }}
        </Form.Item>
      ),
      description: translations().zashAppFeeDescription,
      type: 'pos'
    }
  ]
}

export const feesData = (prd: string) => [
  {
    key: '1',
    name: translations().refundFee,
    value: <NumberFormItem name='refundFee' placeholder={translations().zeroPlaceholder} />,
    description: translations().refundFeeDescription
  },
  {
    key: '2',
    name: translations().chargebackFee,
    value: <NumberFormItem min={0} name='chargebackFee' placeholder={translations().zeroPlaceholder} />,
    description: translations().chargeBackFeeDescription
  },
  {
    key: '3',
    name: translations().pciFee,
    value: <NumberFormItem name='pciFee' placeholder={translations().zeroPlaceholder} />,
    description: translations().pciFeeDescription
  },
  {
    key: '4',
    name: translations().settlementPeriod,
    value: (
      <NumberFormItem
        disabled={prd === 'ecom'}
        min={1}
        max={3}
        name='settlementPeriod'
        placeholder={translations().zeroPlaceholder}
      />
    ),
    description: translations().settlementFeeDescription,
    type: 'ecom'
  },
  {
    key: '5',
    name: translations().settlementFee,
    value: <NumberFormItem name='settlementFee' placeholder={translations().zeroPlaceholder} />,
    description: translations().settlementFeeDescription
  },
  {
    key: '6',
    name: translations().fasterFundingFee,
    disable: (
      <Form.Item shouldUpdate>
        {t => {
          return (
            <Popover content={translations().disableFee(t.getFieldValue('fasterFundingFeeEnabled'))}>
              <Form.Item name={'fasterFundingFeeEnabled'} valuePropName={'checked'}>
                <Switch />
              </Form.Item>
            </Popover>
          )
        }}
      </Form.Item>
    ),
    value: (
      <Form.Item shouldUpdate>
        {t => {
          return (
            <NumberFormItem
              validate={t.getFieldValue('fasterFundingFeeEnabled')}
              disabled={!t.getFieldValue('fasterFundingFeeEnabled')}
              name='fasterFundingFee'
              dependencies={['fasterFundingFeeEnabled']}
              placeholder={translations().zeroPlaceholder}
            />
          )
        }}
      </Form.Item>
    ),
    description: translations().fasterFundingFeeDescription
  },
  {
    key: '7',
    name: translations().fasterFundingMonthly,
    disable: (
      <Form.Item shouldUpdate>
        {t => {
          return (
            <Popover content={translations().disableFee(t.getFieldValue('fasterFundingMonthlyEnabled'))}>
              <Form.Item name={'fasterFundingMonthlyEnabled'} valuePropName={'checked'}>
                <Switch />
              </Form.Item>
            </Popover>
          )
        }}
      </Form.Item>
    ),
    value: (
      <Form.Item shouldUpdate>
        {t => {
          return (
            <NumberFormItem
              validate={t.getFieldValue('fasterFundingMonthlyEnabled')}
              disabled={!t.getFieldValue('fasterFundingMonthlyEnabled')}
              name='fasterFundingMonthly'
              dependencies={['fasterFundingMonthlyEnabled']}
              placeholder={translations().zeroPlaceholder}
            />
          )
        }}
      </Form.Item>
    ),
    description: translations().fasterFundingMonthlyDescription
  },
  {
    key: '8',
    name: translations().zashApp,
    disable: (
      <Form.Item shouldUpdate>
        {t => {
          return (
            <Popover content={translations().disableFee(t.getFieldValue('zashAppEnabled'))}>
              <Form.Item name={'zashAppEnabled'} valuePropName={'checked'}>
                <Switch />
              </Form.Item>
            </Popover>
          )
        }}
      </Form.Item>
    ),
    value: (
      <Form.Item shouldUpdate>
        {t => {
          return (
            <NumberFormItem
              validate={t.getFieldValue('zashAppEnabled')}
              disabled={!t.getFieldValue('zashAppEnabled')}
              name='zashApp'
              dependencies={['zashAppEnabled']}
              placeholder={translations().zeroPlaceholder}
            />
          )
        }}
      </Form.Item>
    ),
    description: translations().zashAppFeeDescription,
    type: 'pos'
  },
  {
    key: '9',
    name: translations().zashSolution,
    value: (
      <Form.Item name={'zashSolution'} valuePropName={'checked'}>
        <Switch />
      </Form.Item>
    ),
    description: '',
    type: 'pos'
  },
  {
    key: '10',
    name: translations().zAndPayByBankApp,
    value: (
      <Form.Item name={'zAndPayBankApp'} valuePropName={'checked'}>
        <Switch />
      </Form.Item>
    ),
    description: '',
    type: 'pos'
  }
]

export const feesColumnsPayments = [
  {
    title: translations().paymentFacilitatingServices,
    children: childrenColumnsWithoutDisable
  }
]

export const terminalPurchaseColumns = [
  {
    title: translations().terminalPurchase,
    children: childrenColumnsWithoutDisable
  }
]

export const feesDataPayment = (tariffsList: IcTariffType[], withoutLimits: boolean) => {
  return [
    {
      key: '1',
      name: translations().setUpConfigurationDelivery,
      disable: null,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <NumberFormItem
                validate
                name='setUpConfigurationDeliveryFee'
                min={
                  !withoutLimits &&
                  tariffsList?.find(item => item?.interchangeFee?.key === 'setUpConfigurationDeliveryFee')
                    ?.interchangeFee?.minBaseFee
                }
                max={
                  !withoutLimits &&
                  tariffsList?.find(item => item?.interchangeFee?.key === 'setUpConfigurationDeliveryFee')
                    ?.interchangeFee?.maxBaseFee
                }
                dependencies={['setUpConfigurationDeliveryFeeEnabled']}
                placeholder={translations().zeroPlaceholder}
              />
            )
          }}
        </Form.Item>
      ),
      description: translations().chargedAtOnce
    }
  ]
}

export const terminalPurchaseData = [
  {
    key: '1',
    name: translations().terminalCost,
    disable: null,
    value: (
      <Form.Item shouldUpdate>
        {t => {
          return <NumberFormItem name='terminalCost' placeholder={translations().zeroPlaceholder} validate />
        }}
      </Form.Item>
    ),
    description: translations().terminalCostDescription
  }
]

export const feesColumnsRegular = [
  {
    title: translations().regularCharges,
    children: childrenColumns
  }
]

export const feesDataRegular = (tariffsList: IcTariffType[], withoutLimits: boolean) => {
  return [
    {
      key: '1',
      name: translations().paymentTerminalSupportAndTransaction,
      disable: null,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <NumberFormItem
                validate
                name='paymentTerminalSupportAndTransactionFee'
                dependencies={['paymentTerminalSupportAndTransactionFeeEnabled']}
                placeholder={translations().zeroPlaceholder}
                min={
                  !withoutLimits &&
                  tariffsList.find(item => item.interchangeFee?.key === 'paymentTerminalSupportAndTransactionFee')
                    ?.interchangeFee?.minBaseFee
                }
                max={
                  !withoutLimits &&
                  tariffsList.find(item => item.interchangeFee?.key === 'paymentTerminalSupportAndTransactionFee')
                    ?.interchangeFee?.maxBaseFee
                }
              />
            )
          }}
        </Form.Item>
      ),
      description: translations().monthlyFeeChargedTerminal
    },

    {
      key: '2',
      name: translations().airtimeService,
      disable: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Popover content={translations().disableFee(t.getFieldValue('airtimeServiceEnabled'))}>
                <Form.Item name={'airtimeServiceEnabled'} valuePropName={'checked'}>
                  <Switch />
                </Form.Item>
              </Popover>
            )
          }}
        </Form.Item>
      ),
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <NumberFormItem
                validate={t.getFieldValue('airtimeServiceEnabled')}
                disabled={!t.getFieldValue('airtimeServiceEnabled')}
                dependencies={['airtimeServiceEnabled']}
                name='airtimeService'
                min={
                  !withoutLimits &&
                  tariffsList.find(item => item.interchangeFee?.key === 'airtimeService')?.interchangeFee?.minBaseFee
                }
                max={
                  !withoutLimits &&
                  tariffsList.find(item => item.interchangeFee?.key === 'airtimeService')?.interchangeFee?.maxBaseFee
                }
                placeholder={translations().zeroPlaceholder}
              />
            )
          }}
        </Form.Item>
      ),
      description: translations().monthlyFeeChargedAirtime
    }
  ]
}

export const feesDataRegularV2 = (tariffsList: IcTariffType[], withoutLimits: boolean) => {
  return [
    {
      key: '2',
      name: translations().airtimeService,
      disable: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Popover content={translations().disableFee(t.getFieldValue('airtimeServiceEnabled'))}>
                <Form.Item name={'airtimeServiceEnabled'} valuePropName={'checked'}>
                  <Switch />
                </Form.Item>
              </Popover>
            )
          }}
        </Form.Item>
      ),
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <NumberFormItem
                validate={t.getFieldValue('airtimeServiceEnabled')}
                disabled={!t.getFieldValue('airtimeServiceEnabled')}
                dependencies={['airtimeServiceEnabled']}
                name='airtimeService'
                min={
                  !withoutLimits &&
                  tariffsList.find(item => item.interchangeFee?.key === 'airtimeService')?.interchangeFee?.minBaseFee
                }
                max={
                  !withoutLimits &&
                  tariffsList.find(item => item.interchangeFee?.key === 'airtimeService')?.interchangeFee?.maxBaseFee
                }
                placeholder={translations().zeroPlaceholder}
              />
            )
          }}
        </Form.Item>
      ),
      description: translations().monthlyFeeChargedAirtime
    }
  ]
}

export const feesColumnsGateway = [
  {
    title: translations().gatewayServices,
    children: childrenColumnsWithoutDisable
  }
]

export const feesDataGateway = (tariffsList: IcTariffType[], withoutLimits: boolean) => {
  return [
    {
      key: '1',
      name: translations().gatewaySetUpFee,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <NumberFormItem
                name='gatewaySetUpFee'
                placeholder={translations().zeroPlaceholder}
                validate
                min={
                  !withoutLimits &&
                  tariffsList.find(item => item.interchangeFee?.key === 'gatewaySetUpFee')?.interchangeFee?.minBaseFee
                }
                max={
                  !withoutLimits &&
                  tariffsList.find(item => item.interchangeFee?.key === 'gatewaySetUpFee')?.interchangeFee?.maxBaseFee
                }
              />
            )
          }}
        </Form.Item>
      ),
      description: translations().chargedAtOnce
    },

    {
      key: '2',
      name: translations().gatewaySetUpFeePerTransaction,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <NumberFormItem
                name='gatewayFee'
                placeholder={translations().zeroPlaceholder}
                validate
                min={
                  !withoutLimits &&
                  tariffsList.find(item => item.interchangeFee?.key === 'gatewayFee')?.interchangeFee?.minBaseFee
                }
                max={
                  !withoutLimits &&
                  tariffsList.find(item => item.interchangeFee?.key === 'gatewayFee')?.interchangeFee?.maxBaseFee
                }
              />
            )
          }}
        </Form.Item>
      ),
      description: translations().feeForEachTransaction
    },
    {
      key: '3',
      name: translations().monthlyGatewayFee,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <NumberFormItem
                name='monthlyGatewayFee'
                placeholder={translations().zeroPlaceholder}
                validate
                min={
                  !withoutLimits &&
                  tariffsList.find(item => item.interchangeFee?.key === 'monthlyGatewayFee')?.interchangeFee?.minBaseFee
                }
                max={
                  !withoutLimits &&
                  tariffsList.find(item => item.interchangeFee?.key === 'monthlyGatewayFee')?.interchangeFee?.maxBaseFee
                }
              />
            )
          }}
        </Form.Item>
      ),
      description: translations().monthlyGatewayFeeDescription
    }
  ]
}

export const feesColumnsOther = [
  {
    title: translations().other,
    children: childrenColumnsWithoutDisable
  }
]

export const feesDataOther = (tariffsList: IcTariffType[], withoutLimits: boolean) => {
  return [
    {
      key: '1',
      name: translations().minMonthlyServiceCharge,
      disable: null,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <NumberFormItem
                name='minMonthlyServiceCharge'
                min={
                  !withoutLimits &&
                  tariffsList.find(item => item.interchangeFee?.key === 'minMonthlyServiceCharge')?.interchangeFee
                    ?.minBaseFee
                }
                max={
                  !withoutLimits &&
                  tariffsList.find(item => item.interchangeFee?.key === 'minMonthlyServiceCharge')?.interchangeFee
                    ?.maxBaseFee
                }
                placeholder={translations().zeroPlaceholder}
                validate
              />
            )
          }}
        </Form.Item>
      ),
      description: translations().minMonthlyServiceChargeDescription,
      type: 'pos'
    }
  ]
}

export const commonFeesData = (tariffsList: IcTariffType[], withoutLimits: boolean) => {
  return [
    {
      key: '1',
      name: translations().discoverGlobalNetwork,
      disable: null,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Row gutter={[16, 0]}>
                <Col xs={12}>
                  <NumberFormItem
                    disabled
                    name={['diners', 'base']}
                    {...subBaseProps}
                    dependencies={['dinersEnabled']}
                  />
                </Col>
                <Col xs={12}>
                  <NumberFormItem
                    disabled
                    name={['diners', 'percent']}
                    {...subPercentProps}
                    dependencies={['dinersEnabled']}
                  />
                </Col>
              </Row>
            )
          }}
        </Form.Item>
      ),
      description: translations().discoverGlobalDescription
    },
    {
      key: '2',
      name: translations().amexCardsFee,
      disable: null,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Row gutter={[16, 0]}>
                <Col xs={12}>
                  <NumberFormItem disabled name={['amex', 'base']} {...subBaseProps} dependencies={['amexEnabled']} />
                </Col>
                <Col xs={12}>
                  <NumberFormItem
                    disabled
                    name={['amex', 'percent']}
                    {...subPercentProps}
                    dependencies={['amexEnabled']}
                  />
                </Col>
              </Row>
            )
          }}
        </Form.Item>
      ),
      description: translations().amexDescription
    },
    {
      key: '3',
      name: translations().unionPayCardsFee,
      disable: null,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Row gutter={[16, 0]}>
                <Col xs={12}>
                  <NumberFormItem
                    disabled
                    name={['chinaUnionPay', 'base']}
                    {...subBaseProps}
                    dependencies={['unionPayEnabled']}
                  />
                </Col>
                <Col xs={12}>
                  <NumberFormItem
                    disabled
                    name={['chinaUnionPay', 'percent']}
                    {...subPercentProps}
                    dependencies={['unionPayEnabled']}
                  />
                </Col>
              </Row>
            )
          }}
        </Form.Item>
      ),
      description: translations().unionPayDescription
    },
    {
      key: '4',
      name: translations().openBankingFee,
      disable: null,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Row gutter={[16, 0]}>
                <Col xs={12}>
                  <NumberFormItem
                    validate
                    name={['openBanking', 'base']}
                    {...subBaseProps}
                    dependencies={['openBankingEnabled']}
                    min={
                      !withoutLimits &&
                      tariffsList.find(item => item.interchangeFee?.key === 'openBanking')?.interchangeFee?.minBaseFee
                    }
                    max={
                      !withoutLimits &&
                      tariffsList.find(item => item.interchangeFee?.key === 'openBanking')?.interchangeFee?.maxBaseFee
                    }
                  />
                </Col>
                <Col xs={12}>
                  <NumberFormItem
                    validate
                    name={['openBanking', 'percent']}
                    {...subPercentProps}
                    dependencies={['openBankingEnabled']}
                    min={
                      !withoutLimits &&
                      tariffsList.find(item => item.interchangeFee?.key === 'openBanking')?.interchangeFee
                        ?.minPercentFee
                    }
                    max={
                      !withoutLimits &&
                      tariffsList.find(item => item.interchangeFee?.key === 'openBanking')?.interchangeFee
                        ?.maxPercentFee
                    }
                  />
                </Col>
              </Row>
            )
          }}
        </Form.Item>
      ),
      description: translations().openBankingDescription
    },
    {
      key: '5',
      name: translations().payByBankFee,
      disable: null,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Row gutter={[16, 0]}>
                <Col xs={12}>
                  <NumberFormItem
                    validate
                    name={['payByBank', 'base']}
                    {...subBaseProps}
                    dependencies={['payByBankEnabled']}
                    min={
                      !withoutLimits &&
                      tariffsList.find(item => item.interchangeFee?.key === 'payByBank')?.interchangeFee?.minBaseFee
                    }
                    max={
                      !withoutLimits &&
                      tariffsList.find(item => item.interchangeFee?.key === 'payByBank')?.interchangeFee?.maxBaseFee
                    }
                  />
                </Col>
                <Col xs={12}>
                  <NumberFormItem
                    validate
                    name={['payByBank', 'percent']}
                    {...subPercentProps}
                    dependencies={['payByBankEnabled']}
                    min={
                      !withoutLimits &&
                      tariffsList.find(item => item.interchangeFee?.key === 'payByBank')?.interchangeFee?.minPercentFee
                    }
                    max={
                      !withoutLimits &&
                      tariffsList.find(item => item.interchangeFee?.key === 'payByBank')?.interchangeFee?.maxPercentFee
                    }
                  />
                </Col>
              </Row>
            )
          }}
        </Form.Item>
      ),
      description: translations().payByBankDescription,
      type: 'ecom'
    },
    {
      key: 'aliPay',
      name: translations().aliPayFee,
      disable: null,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Row gutter={[16, 0]}>
                <Col xs={12}>
                  <NumberFormItem
                    validate
                    name={['aliPay', 'base']}
                    {...subBaseProps}
                    min={
                      !withoutLimits &&
                      tariffsList.find(item => item.interchangeFee?.key === 'aliPay')?.interchangeFee?.minBaseFee
                    }
                    max={
                      !withoutLimits &&
                      tariffsList.find(item => item.interchangeFee?.key === 'aliPay')?.interchangeFee?.maxBaseFee
                    }
                  />
                </Col>
                <Col xs={12}>
                  <NumberFormItem
                    validate
                    name={['aliPay', 'percent']}
                    {...subPercentProps}
                    min={
                      !withoutLimits &&
                      tariffsList.find(item => item.interchangeFee?.key === 'aliPay')?.interchangeFee?.minPercentFee
                    }
                    max={
                      !withoutLimits &&
                      tariffsList.find(item => item.interchangeFee?.key === 'aliPay')?.interchangeFee?.maxPercentFee
                    }
                  />
                </Col>
              </Row>
            )
          }}
        </Form.Item>
      ),
      description: translations().aliPayDescription
    },
    {
      key: 'weChatPay',
      name: translations().weChatPayFee,
      disable: null,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Row gutter={[16, 0]}>
                <Col xs={12}>
                  <NumberFormItem
                    validate
                    name={['weChatPay', 'base']}
                    {...subBaseProps}
                    min={
                      !withoutLimits &&
                      tariffsList.find(item => item.interchangeFee?.key === 'weChatPay')?.interchangeFee?.minBaseFee
                    }
                    max={
                      !withoutLimits &&
                      tariffsList.find(item => item.interchangeFee?.key === 'weChatPay')?.interchangeFee?.maxBaseFee
                    }
                  />
                </Col>
                <Col xs={12}>
                  <NumberFormItem
                    validate
                    name={['weChatPay', 'percent']}
                    {...subPercentProps}
                    min={
                      !withoutLimits &&
                      tariffsList.find(item => item.interchangeFee?.key === 'weChatPay')?.interchangeFee?.minPercentFee
                    }
                    max={
                      !withoutLimits &&
                      tariffsList.find(item => item.interchangeFee?.key === 'weChatPay')?.interchangeFee?.maxPercentFee
                    }
                  />
                </Col>
              </Row>
            )
          }}
        </Form.Item>
      ),
      description: null
    }
  ]
}

export const authFeesData = (tariffsList: IcTariffType[], withoutLimits: boolean) => {
  return [
    {
      key: '1',
      name: translations().authorisationFee,
      value: (
        <NumberFormItem
          name='authorisationOkFee'
          min={
            !withoutLimits &&
            tariffsList.find(item => item.interchangeFee?.key === 'authorisationOkFee')?.interchangeFee?.minBaseFee
          }
          max={
            !withoutLimits &&
            tariffsList.find(item => item.interchangeFee?.key === 'authorisationOkFee')?.interchangeFee?.maxBaseFee
          }
          placeholder={translations().zeroPlaceholder}
        />
      ),
      description: translations().authorizationFeeDescription
    }
  ]
}

export const authFeesDataV2 = (tariffsList: IcTariffType[], type: string, withoutLimits: boolean) => {
  return [
    {
      key: '1',
      name: translations().authorisationFee,
      value: (
        <NumberFormItem
          name='authorisationOkFee'
          min={
            !withoutLimits &&
            tariffsList.find(item => item.interchangeFee?.key === 'authorisationOkFee')?.interchangeFee?.minBaseFee
          }
          max={
            !withoutLimits &&
            tariffsList.find(item => item.interchangeFee?.key === 'authorisationOkFee')?.interchangeFee?.maxBaseFee
          }
          placeholder={translations().zeroPlaceholder}
        />
      ),
      description: translations().authorizationFeeDescription
    },
    {
      key: '2',
      name: translations().cardNotPresentFee,
      value: (
        <NumberFormItem
          name='cardNotPresentFee'
          min={
            !withoutLimits &&
            tariffsList.find(item => item.interchangeFee?.key === 'cardNotPresentFee')?.interchangeFee?.minBaseFee
          }
          max={
            !withoutLimits &&
            tariffsList.find(item => item.interchangeFee?.key === 'cardNotPresentFee')?.interchangeFee?.maxBaseFee
          }
          placeholder={translations().zeroPlaceholder}
          validate={type == 'blended'}
        />
      ),
      description: translations().motoDescription
    }
  ]
}

export const feesDataFiltered = (product: string) => {
  if (product === 'pos') {
    return feesData(product)
  } else {
    return feesData(product).filter(t => t.type !== 'pos')
  }
}

export const settlementFeesDataFiltered = (product: string, tariffsList: IcTariffType[], withoutLimits: boolean) => {
  if (product === 'pos') {
    return settlementFeesData(product, tariffsList, withoutLimits)
  } else {
    return settlementFeesData(product, tariffsList, withoutLimits).filter(t => t.type !== 'pos')
  }
}

export const commonFeesDataFiltered = (
  product: string,
  tariffsList: IcTariffType[],
  isAliPay: boolean,
  isWeChatPay: boolean,
  withoutLimits
) => {
  let fees = commonFeesData(tariffsList, withoutLimits)
  if (product === 'pos') {
    fees = fees?.filter(t => t.type !== 'ecom')
  }

  if (!isAliPay) {
    fees = fees?.filter(f => f.key !== 'aliPay')
  }

  if (!isWeChatPay) {
    fees = fees?.filter(f => f.key !== 'weChatPay')
  }

  return fees
}
