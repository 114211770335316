import translations from './translations'

translations.add('en', {
    day: 'Today',
    yesterday: 'Yesterday',
    month: 'This Month',
    week: 'This Week',
    year: 'This Year',
    last7Days: 'Last 7 Days',
    last30Days: 'Last 30 Days',
    last60Days: 'Last 60 Days',
    last90Days: 'Last 90 Days',
    last12Months: 'Last 12 Months',
    custom: 'Custom'
})
