import translations from './translations'

translations.add('en', {
    title: 'Add Two-Factor Authentication',
    updateTitle: 'Update Two-Factor Authentication',
    recoveryCodes: 'Recovery code',
    selectFrequency: 'Select frequency',
    configureApp: 'Configure authenticator app',
    cancel: 'Cancel',
    back: 'Back',
    next: 'Next',
    update: 'Update',
    submit: 'Submit'
})
