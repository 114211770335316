import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    title: string
    text: string
    tooltip: string
    optional: string
    verified: string
    nonVerified: string
}>()

