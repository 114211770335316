import React from 'react'
import moment from 'moment-timezone'
import { Dropdown, MenuProps } from 'antd'
import { SettingOutlined, DownOutlined } from '@ant-design/icons'
import { ColumnType } from 'antd/lib/table'
import { getTeammateStatusTag } from '~/code/pages/Teammates/services/ui-utils'
import { DATETIME_FORMAT } from '~/code/pages/PortalAccess/constants/formats'
import { TeammateStatus } from '~/code/stores/TeammatesStore/constants/TeammateStatus'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { TeammateMenuType, TeammateViewType } from '~/code/pages/Teammates/models'

import translations from '~/code/pages/Teammates/translations'
import styles from '../styles.scss'

const getColumn = (key: string, width?: number, fixed?: boolean): ColumnType<any> => ({
  title: translations().labels[key],
  dataIndex: key,
  key,
  width,
  fixed,
  render: (val: string) => val
})

export const getColumns = (isInvited: boolean, onSelect: (key: TeammateMenuType, user: TeammateViewType) => void) => {
  const columns = [
    getColumn('email', 325, true),
    getColumn('firstName'),
    getColumn('lastName'),
    getColumn('roleTitlesStr'),
    ...(isInvited
      ? []
      : [
          {
            key: 'status',
            title: translations().labels.status,
            render: ({ status }) => getTeammateStatusTag(status as TeammateStatus)
          },
          getColumn('authMethod')
        ]),
    {
      title: isInvited ? translations().labels.invitationDate : translations().labels.lastLoginDate,
      key: 'lastLoginDate',
      render: v => moment(v.lastLoginDate).format(DATETIME_FORMAT) || ''
    }
  ]

  if (hasPermissions([PermissionMap.teammates.update, PermissionMap.teammates.delete])) {
    columns.push({
      title: translations().actions,
      key: 'actions',
      width: 90,
      fixed: 'right',
      render: (_, user) => renderActions(isInvited, key => onSelect(key, user))
    })
  }

  return columns
}

const renderActions = (isInvited: boolean, onSelect: (key: TeammateMenuType) => void) => {
  const menuItems = []

  if (hasPermissions([PermissionMap.teammates.update])) {
    if (isInvited) {
      menuItems.push('resend')
    } else {
      menuItems.push( 'editUser', 'editSecurity')

    }
  }

  if (hasPermissions([PermissionMap.teammates.delete])) {
    menuItems.push('delete')
  }

  const items: MenuProps['items'] = menuItems.map(key => ({
    key,
    label: translations().menu[key]
  }))

  return (
    <Dropdown menu={{ items, onClick: item => onSelect(item.key as TeammateMenuType) }}>
      <div className={styles.actions}>
        <SettingOutlined />
        <DownOutlined />
      </div>
    </Dropdown>
  )
}
