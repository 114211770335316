import React from 'react'
import { BPMProcessDossierV2 } from '../shared'
import { columns } from './constants'

export const EditTariffsRequestsStoreSymbol = Symbol('EditTariffsRequestsStore')
export const EditTariffsRequestsRangePickerStoreSymbol = Symbol('EditTariffsRequestsRangePickerStore')
export const EditTariffsRequestsMerchantSelectStoreSymbol = Symbol('EditTariffsRequestsMerchantSelectStore')

export const EditTariffsRequests = () => (
  <BPMProcessDossierV2
    BPMProcessesStoreSymbol={EditTariffsRequestsStoreSymbol}
    BPMProcessesRangePickerStoreSymbol={EditTariffsRequestsRangePickerStoreSymbol}
    BPMProcessesMerchantSelectStoreSymbol={EditTariffsRequestsMerchantSelectStoreSymbol}
    columns={columns}
  />
)
