import React, { forwardRef } from 'react'
import { Input as AntInput, InputRef } from 'antd'
import InputMask from 'react-input-mask'
import { InputProps } from 'antd/lib/input'
import classNames from 'classnames'
import styles from './Input.scss'

interface AdditionalInputProps extends InputProps {
  mask?: string
  textTransform?: 'uppercase' | 'lowercase'
}

const Input = forwardRef<InputRef, AdditionalInputProps>((props = {}, forwardedRef) => {
  const { textTransform, onChange, onBlur, ...rest } = props

  const onCustomChange: React.ChangeEventHandler<HTMLInputElement> = event => onChange && onChange(event)

  const onCustomBlur: React.FocusEventHandler<HTMLInputElement> = event => {
    const value = event.target.value
    const trimmedValue = value?.trim()
    if (value !== trimmedValue) {
      event.target.value = trimmedValue
      onChange && onChange(event)
    }

    onBlur && onBlur(event)
  }

  if (props.type === 'password') {
    return <AntInput.Password {...rest} onChange={onCustomChange} onBlur={onCustomBlur} ref={forwardedRef} />
  } else if (props.type === 'masked') {
    return (
      // @ts-ignore
      <InputMask
        {...rest}
        className={styles.inputMask}
        onChange={onCustomChange}
        onBlur={onCustomBlur}
        inputRef={forwardedRef as any}
      />
    )
  } else {
    return (
      <AntInput
        {...rest}
        className={classNames({
          lowercase: textTransform === 'lowercase',
          uppercase: textTransform === 'uppercase'
        })}
        onChange={onCustomChange}
        onBlur={onCustomBlur}
        ref={forwardedRef}
      />
    )
  }
})

export { Input }
