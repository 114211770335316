import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  title: string
  productName: string
  model: string
  count: string
  action: string
  delete: string
}>()
