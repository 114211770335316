import translations from '../translations'

export const getExportColumns = () => ({
    createdDate: {value: 'createdDate', label: translations().createdDate},
    merchantName: {value: 'merchantName', label: translations().merchantName},
    amount: {value: 'amount', label: translations().amount},
    currency: {value: 'currency', label: translations().currency},
    status: {value: 'status', label: translations().status},
    message: {value: 'message', label: translations().message},
    signifydDecision: {value: 'signifydDecision', label: translations().signifydDecision},
    signifydScore: {value: 'signifydScore', label: translations().signifydScore},
    payoutDate: {value: 'payoutDate', label: translations().payoutDate},
    payoutAmount: {value: 'payoutAmount', label: translations().payoutAmount},
    transactionChannel: {value: 'transactionChannel', label: translations().transactionChannel},
    transactionType: {value: 'transactionType', label: translations().transactionType},
    paymentMethod: {value: 'paymentMethod', label: translations().paymentMethod},
    processingType: {value: 'processingType', label: translations().processingType},
    mid: {value: 'mid', label: translations().mid},
    terminal: {value: 'terminal', label: translations().terminal},
    merchantReference: {value: 'merchantReference', label: translations().merchantReference},
    reference: {value: 'reference', label: translations().reference},
    sourceClient: {value: 'sourceClient', label: translations().sourceClient},
    threeDSVersion: {value: 'threeDSVersion', label: translations().threeDSVersion},
    avsHouseNumberResult: {value: 'avsHouseNumberResult', label: translations().avsHouseNumberResult},
    avsPostcodeResult: {value: 'avsPostcodeResult', label: translations().avsPostcodeResult},
    cscResult: {value: 'cscResult', label: translations().cscResult},
    payerAuthenticationResult: {value: 'payerAuthenticationResult', label: translations().payerAuthenticationResult},
    responseCode: {value: 'responseCode', label: translations().responseCode},
    cardScheme: {value: 'cardScheme', label: translations().cardScheme},
    cardMask: {value: 'cardMask', label: translations().cardMask},
    issuer: {value: 'issuer', label: translations().issuer},
    issuerCountry: {value: 'issuerCountry', label: translations().issuerCountry},
    payerName: {value: 'payerName', label: translations().payerName},
    payerEmail: {value: 'payerEmail', label: translations().payerEmail},
    payerIp: {value: 'payerIp', label: translations().payerIp},
    ipCountry: {value: 'ipCountry', label: translations().ipCountry},
    ipCity: {value: 'ipCity', label: translations().ipCity},
    id: {value: 'id', label: translations().id}
})
