import translations from './translations'
import React from 'react'

translations.add('en', {
  backText: 'Back to Stores',
  generalInformation: 'General Information',
  financialInformation: 'Financial Information',
  shareholders: 'Shareholders',
  directors: 'Directors',
  newDirector: 'New Director',
  signatories: 'Authorised Signatories',
  newSignatory: 'New Signatory',
  businessOwner: 'Business Owner',
  optional: 'Optional',
  completed: 'Completed',
  notCompleted: 'Not Completed',
  pleaseCompleteApplication: 'Please complete application',
  canNotProceed: 'Can not proceed with this owner!',
  mustBe: (count: number, companyMode: boolean) => `Must be ${count} ${companyMode ? 'directors' : 'business owners'}!`,
  docusignSignedText: (
    <>
      <p>Editing of the data is not allowed because a contract has already been signed with this data in DocuSign. </p>
      <p>If you still need to change the data, go to the Contract page and cancel the signed contract,</p>
      <p> after which you will be able to edit the data in the application.</p>
    </>
  )
})
