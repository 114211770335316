import React from 'react'
import translations from './translations'
import styles from '../ClosureOfMerchantConfirmationModal.scss'

translations.add('en', {
  submit: 'Submit',
  confirmClosure: 'Confirm closure',
  closureOfMerchant: 'ATTENTION! Merchant closure',
  areYouSureCloseMerchant: (merchant: string) => (
    <div className={styles.warningFont}>
      <p>
        Once submitted, merchant{' '}
        <b>
          <span className={styles.warning}>{merchant} will stop accepting payments immediately</span>
        </b>{' '}
        across all of their MIDs and TIDs
      </p>
      <p>Double-check if you're closing the correct merchant.</p>
    </div>
  ),
  ensureTerminationFee: (fee: number) => (
    <>
      <p className={styles.warningFont}>
        Please verify the accuracy of the <b>£ {fee}</b> termination fee{' '}
      </p>
    </>
  )
})
