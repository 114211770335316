import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Modal, Button, Spin, Empty } from 'antd'
import { ViewAttributesModalProps } from './props'
import { IViewAttributesModalStore } from './IViewAttributesModal'
import { attributesTable } from './services/utils'
import translations from './translations'
import styles from './ViewAttributesModal.scss'

export const ViewAttributesModalStoreSymbol = Symbol('ViewAttributesModalStore')
export const ViewAttributesModal = observer((props: ViewAttributesModalProps) => {
  const store = useInjection<IViewAttributesModalStore>(ViewAttributesModalStoreSymbol)
  const { storeId } = props

  useEffect(() => {
    store.loadStoreAttributes(storeId)
  }, [storeId])

  const contractItems = attributesTable(store.storeAttributes?.contractAttributes, 'contractNumber')
  const midItems = attributesTable(store.storeAttributes?.midAttributes, 'mid')
  const terminalItems = attributesTable(store.storeAttributes?.teminalAttributes, 'terminalId')

  return (
    <>
      <Modal
        width={'50%'}
        open={props.open}
        onCancel={() => store.closeModal()}
        footer={[
          <Button key='cancel' onClick={() => store.closeModal()}>
            {translations().close}
          </Button>
        ]}
      >
        <div className={styles.modalBody}>
          {store.isStoreAttributesLoading ? (
            <Spin spinning={store.isStoreAttributesLoading} />
          ) : (
            <>
              {store.areAllAttributesNull() ? (
                <Empty />
              ) : (
                <div className={styles.attributesContainer}>
                  {contractItems}
                  {midItems}
                  {terminalItems}
                </div>
              )}
            </>
          )}
        </div>
      </Modal>
    </>
  )
})
