import React from 'react'
import { Typography } from 'antd'
import { MerchantHighlightProps } from './props'
import translations from './translations'

const { Title } = Typography
export const MerchantHighlight = (props: { data: MerchantHighlightProps }) => {
  const { companyName, tradingName, isActive, uberId } = props.data

  return (
    <>
      <Title level={5}>
        {companyName}; {tradingName}; {translations().active(isActive)}; {translations().uberId} {uberId}
      </Title>
    </>
  )
}
