import translations from './translations'

translations.add('en', {
    dailyAmount: 'Daily Amount',
    dailyCount: 'Daily Count',
    dailyMaxRefundAmount: 'Daily Max Refund Amount',
    monthlyAmount: 'Monthly Amount',
    monthlyCount: 'Monthly Count',
    perRefund: 'Per Refund',
    perTransaction: 'Per Transaction'
})
