import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    acquisitionChannelLabel: string
    applicantName: string
    initiatorEmail: string
    email: string
    phoneNumber: string
    processStatus: string
    startDate: string
    action: string
    continue: string
    delete: string
    errorLoadingApplications: string
    errorLoadingTheApplication: string
    errorDeletingTheApplication: string
    deletedApplicationSuccessfully: string
    companyName: string
}>()

