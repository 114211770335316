import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  title: string
  firstName: string
  middleName: string
  surname: string
  dateOfBirth: string
  gender: string
  placeOfBirth: string
  nationality: string
  countryOfResidence: string
  occupation: string
  emailAddress: string
  telephoneNumber: string
  residentialAddress: string
  addressLine1: string
  addressLine2: string
  countryOrState: string
  country: string
  postCode: string
  dsrId: (dsrId: number | string) => string
  vulnerableStatus: string
  visualDisability: string
  audioDisability: string
  lifeEvent: string
  resilience: string
  capability: string
  vulnerabilityInfo: string
  true: string
}>()
