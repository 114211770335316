import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  loginSuccessfulDelete: string
  loginDeleteFailure: string
  deleteAlert: {
    logins: {
      title: string
      text: string
    }

    partners: {
      title: string
      text: string
    }

    okText: string
  }
}>()
