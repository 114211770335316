import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Table, Form, Modal, Select, Input, Button } from 'antd'
import { ISettlementsStatusTableStore } from './ISettlementsStatusTableStore'
import { columnsSettlementsStatusTable, AllContractActionEnum, AllContractCommonStatusEnum, UpdateContractTypeEnum } from './constants'
import { HoldTableType } from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v2'
import translations from './translations'
import styles from './SettlementsStatusTable.scss'

export const SettlementsStatusTableStoreSymbol = Symbol('SettlementsStatusTableStore')
export const SettlementsStatusTable = observer((props: {tableData: HoldTableType[]}) => {
    const store = useInjection<ISettlementsStatusTableStore>(SettlementsStatusTableStoreSymbol)
    const [form] = Form.useForm()
    const [formHoldTable] = Form.useForm()
    
    useEffect(() => {
        store.defineCommonSettlementStatusForAllContracts()
    }, [props.tableData])

    const onFinish = ({ settlementsReason, holdReason }) => {
        const reason = store.allContractsAction === AllContractActionEnum.allOn ? settlementsReason : holdReason
        store.changeHoldStatus(reason, form, UpdateContractTypeEnum.allContracts, store.allContractsAction)
    }

    return (
        <>  
            <div className={styles.flexBtns}>
                <div className={styles.settlementsBtns}>
                    <Button
                        disabled={store.allContractsCommonStatus === AllContractCommonStatusEnum.allOn}
                        onClick={() => store.setShowHoldReasonsModalForAllContracts(AllContractActionEnum.allOn)}
                    >
                        {translations().allSettlementsOn}
                    </Button>
                </div>
                <div>
                    <Button
                        disabled={store.allContractsCommonStatus === AllContractCommonStatusEnum.allOff}
                        onClick={() => store.setShowHoldReasonsModalForAllContracts(AllContractActionEnum.allOff)}
                    >
                        {translations().allSettlementsOff}
                    </Button>
                </div>
            </div>
            <Form
                form={formHoldTable}
            >
                <Table
                    pagination={false}
                    scroll={{ x: 900, y: 400 }}
                    dataSource={props.tableData}
                    columns={columnsSettlementsStatusTable}
                />
            </Form>
            <Modal    
                title={translations().reason}
                open={store.showHoldReasonsModalForAllContracts}
                okButtonProps={{disabled: form.getFieldValue('holdReason')}}
                onCancel={() => store.setHideHoldReasonsModalForAllContracts(form)}
                onOk={() => form.submit()}
            >
                <Form
                    form={form}
                    onFinish={(values) => onFinish(values)}
                >
                    {store.allContractsAction === AllContractActionEnum.allOff &&
                        <Form.Item
                            name='holdReason'
                            rules={[{ required: true, message: translations().required }]}
                            validateTrigger={['onChange', 'onBlur']}
                        >
                            <Select options={store.settlementsReasonsDict} />
                        </Form.Item>
                    }
                    {store.allContractsAction === AllContractActionEnum.allOn &&
                        <Form.Item
                            name='settlementsReason'
                            rules={[{ required: true, message: translations().required }]}
                        >
                            <Input.TextArea placeholder={translations().commentPlaceHolder} />
                        </Form.Item>
                    }
                </Form>
            </Modal>
        </>
    )
})