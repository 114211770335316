import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    requiredMessage: string
    positiveMessage: string
    betweenMessage: (min: number, max: number) => string
    lessMessage: (max: number) => string
    greaterMessage: (min: number) => string
    noZeroValue: string
}>()

