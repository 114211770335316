import { TranslationBuilder } from '~/code/components/Translation'
import { TeammateStatus } from '~/code/stores/TeammatesStore/constants/TeammateStatus'
import { AuthMethods } from '~/code/stores/TeammatesStore/constants/AuthMethods'

export default TranslationBuilder.create<{
  all: string
  messages: {
    invite: string
    update: string
    resend: string
    delete: string
  }
  errors: {
    resend: string
    delete: string
  }
  statuses: {
    [key in keyof typeof TeammateStatus]: string
  }
  authMethods: {
    [key in keyof typeof AuthMethods]: string
  }
}>()
