import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  motoCnp: string
  zashEpos: string
  contractType: string
  required: string

  subscriptionServicePeriod: string
  freeSubscriptionPeriod: string
  byDNA: string
  byPaytek: string
  posModel: string
  posModelRequired: string
  posBundles: string
  byOptomany: string

  title: string
  pos: string
  webSiteRequired: string
  posDeliveryInstructionsRequired: string
  posDeliveryAddressRequired: string
  productPlaceholder: string
  deviceAmountPlaceholder: string
  posQuantity: string
  posDeliveryAddress: string
  posDeliveryAddressPlaceholder: string
  manual: string
  sameAsStore: string
  sameAsCompany: string
  deliveryInstructions: string
  deliveryInstructionsPlaceholder: string
  deliveryContactNumber: string
  validateBankAccount: string
  addProduct: string
  cityLabel: string
  cityRequired: string
  cityInvalid: string
  countryLabel: string
  countryRequired: string
  regionLabel: string
  postalCodeLabel: string
  postalCodeRequired: string
  postalCodeInvalid: string
  addressLine1Label: string
  addressLine1Required: string
  addressLine2Label: string
  addressLine2Required: string
  posCountError: string
  zashDigital: string
  posCountRequired: string
  feeSubscriptionPerMonth: string
  terminalCost: string
  notZero: string
  chargeType: string
  aliPay: string
  weChatPay: string
  minValue: (fee: number)=> string
  monthlyFeeChargedTerminal: string
  selectDeviceType: string
  paymentMethods: string
  cards: string
  posOpenBanking: string
  configurePos: string
  infoNote: string
  terminalConstNoteInfo: string
}>()
