import { Space } from 'antd'
import React from 'react'
import { BelowActionsWithSaveProps } from './props'
import styles from './BelowActionsWithSave.scss'
import translations from './translations'
import classNames from 'classnames'
import { CustomButton } from '~/code/components'

export const BelowActionsWithSave = (props: BelowActionsWithSaveProps) => {
  const { children, large = false, isLoading, onSave, title } = props

  return (
    <Space className={styles.container}>
      <CustomButton
        type='primary'
        loading={isLoading}
        onClick={onSave}
        className={classNames({ [styles.saveButton__large]: large })}
      >
        {title || translations().save}
      </CustomButton>
      {children}
    </Space>
  )
}
