import { object, boolean, string } from 'yup'

const requireWhenTrue = () => string().nullable().when('isTrue', {
    is: true, then: string().required()
})

export const BusinessModelScheme = object({
    seasonalBusiness: object({
        isTrue: boolean(),
        quarter1: requireWhenTrue(),
        quarter2: requireWhenTrue(),
        quarter3: requireWhenTrue(),
        quarter4: requireWhenTrue()
    }).nullable(),
    face2face: object({
        isTrue: boolean().nullable(),
        deliveryTime: requireWhenTrue(),
        courierServiceUsed: requireWhenTrue()
    })
})
