import React from 'react'
import { Skeleton } from 'antd'
import classNames from 'classnames'
import { InfoProps } from './props'
import styles from './Info.scss'

export const Info = (props: InfoProps) => {
  const { label, value, isLoading, loaderStyle = { width: '100px' } } = props

  const renderValue = () => {
    if (!value) return <></>

    if (Array.isArray(value)) {
      return (value as string[]).map(val => (
        <div key={val} className={styles.propValue}>
          {val}
        </div>
      ))
    }

    return <div className={classNames(styles.propValue, props.textColor)}>{value}</div>
  }

  return (
    <div className={styles.prop}>
      {label && <div className={styles.propLabel}>{label}</div>}
      {isLoading ? <Skeleton.Button style={loaderStyle} size={'small'} active={true} /> : renderValue()}
    </div>
  )
}
