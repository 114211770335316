import translations from './translations'

translations.add('en', {
  financialInfo: 'Financial Info',
  expectedAnnualTurnover: 'Expected AnnualTurnover',
  expectedAnnualTurnoverCustomValue: 'Expected Annual Turnover CustomValue',
  expectedMonthlyTransactionValues: 'Expected Monthly Transaction Values',
  averageTransactionValue: 'Average Transaction Value',
  tradeOutsideEea: 'Trade Outside Eea',
  tradingCountriesOutsideEea: 'Trading Countries Outside Eea',
  businessModelQuestions: 'Business Model Questions',
  expectedAnnualCardTurnover: 'Expected Annual Card Turnover',
  highestTransactionValue: 'Highest Transaction Value',
  yes: 'Yes',
  no: 'No'
})
