import translations from './translations'

translations.add('en', {
    review: 'Review submitted request',
    approve: 'Approve',
    cancel: 'Cancel',
    reject: 'Reject',
    completeRiskReviewSuccess: 'Completed Risk Review Successfully',
    completeRiskReviewFailure: 'Complete Risk Review Failed',
    sureToApproveRequest: 'Are you sure you want to approve?',
    sureToRejectRequest: 'Are you sure you want to reject?'
})
