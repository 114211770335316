import { inject, injectable } from 'inversify'
import { action, makeObservable, observable } from 'mobx'
import moment from 'moment-timezone'
import { notification } from 'antd'
import { IRangePickerContainerStore } from '~/code/pages/settlements/SettlementsPayments/components/RangePickerContainer/IRangePickerContainerStore'
import { INetSuiteInvoicesFilterStore } from '~/code/pages/Billing/pages/NetSuiteInvoices/components/NetSuiteInvoicesFilter/INetSuiteInvoicesFilterStore'
import { NetSuiteInvoiceSubsidiarySystemType } from '~/code/stores/NetSuiteInvoicesStore/models'
import { RangePickerNetSuiteInvoicesStoreSymbol } from '~/code/pages/Billing/pages/NetSuiteInvoices/components'
import { StatusItem } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsDetailedView/models/StatusItem'
import { fetchSubsidiaries } from './services/fetchers'
import { capitalizeFirstLetter } from '~/code/services'
import { PAGE_SIZE_20, PAGE_SIZE_100 } from '~/code/constants/Configurations'

import translations from './translations'

@injectable()
export class NetSuiteInvoicesFilterStore implements INetSuiteInvoicesFilterStore {
  isLoading: boolean = false
  size: number = PAGE_SIZE_20
  total: number = PAGE_SIZE_100
  currentPage: number = 1
  subsidiaries: StatusItem[] = []
  rangePickerStore: IRangePickerContainerStore
  selectedSubsidiary: NetSuiteInvoiceSubsidiarySystemType = 'active-payments'

  constructor(@inject(RangePickerNetSuiteInvoicesStoreSymbol) rangePickerStore: IRangePickerContainerStore) {
    this.rangePickerStore = rangePickerStore

    makeObservable(this, {
      isLoading: observable,
      size: observable,
      total: observable,
      currentPage: observable,
      subsidiaries: observable,
      selectedSubsidiary: observable,
      resetFilter: action.bound,
      setPagination: action.bound,
      selectSubsidiary: action.bound,
      fetchSubsidiaries: action.bound
    })

    this.fetchSubsidiaries()

    if (!this.rangePickerStore.period) {
      this.rangePickerStore.setDates(moment().startOf('month'), moment().endOf('month'))
    }
  }

  public setPagination(page: number, pageSize: number) {
    this.currentPage = page
    this.size = pageSize
  }

  public resetFilter() {
    this.rangePickerStore.setDates(moment().startOf('month'), moment().endOf('month'))
    this.selectSubsidiary('active-payments')
    this.setPagination(1, 20)
  }

  public selectSubsidiary(subsidiary: NetSuiteInvoiceSubsidiarySystemType) {
    this.selectedSubsidiary = subsidiary
  }

  fetchSubsidiaries = async () => {
    this.isLoading = true

    try {
      const { result, error } = await fetchSubsidiaries()

      if (error) {
        notification.error({ message: error.message })
        this.isLoading = false
        return
      }

      this.subsidiaries = result.map(({ name: label, system: value }) => ({
        value,
        label
      }))
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }
}
