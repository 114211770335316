import translations from './translations'
import React from 'react'

translations.add('en', {
  baseLabel: 'Base fee (p.)',
  percentLabel: 'Percent fee (%)',

  settlementFee: 'Settlement fee (per settlement) (£)',
  fasterFundingFee: 'Faster Funding Fee (per settlement) (£)',

  authorisationFee: 'Authorisation fee (per transaction) (£)',
  gatewayFee: 'Gateway fee (per transaction) (£)',
  refundFee: 'Refund fee (per instance) (£)',
  chargebackFee: 'Chargeback fee (per instance) (£)',

  dinersCardsFee: 'Diners/Discover Cards fee',
  unionPayCardsFee: 'UnionPay Cards fee',
  amexCardsFee: 'American Express Cards fee',

  openBankingFee: 'Open Banking',
  payByBankFee: 'Pay By Bank',
  klarnaFee: 'Klarna fee',

  settlementPeriod: 'Settlement Period (business days)',

  monthlyRental: 'Monthly rental per TID GBP',

  definitions: (
    <>
      * As per Definitions <br />
      ** Fee applied in sending an authorisation request to a Payment Scheme. This fee applies regardless of the next
      action (void or charge) <br />
      *** Fee applied on successful authorisation request to a Payment Scheme. <br />
      **** Fee applied for processing of each Settlement <br />
      ***** Fee applied for processing of each instance of the Refund request <br />
      ****** Fee applied as soon as the Chargeback process has been initiated (does not apply to reported fraud) <br />
      ******* Fee applied monthly for ongoing PCI compliance certification <br />
    </>
  ),
  zashApp: 'Zash App (monthly) (£)',
  airtimeService: 'Airtime Service (£) (per terminal)',
  gatewaySetUpFee: 'Gateway Set Up Fee',
  minMonthlyServiceCharge: 'Minimum Monthly Service Charge (£)',
  nonTransactionFees: 'Non-transactional fees',
  pciFee: 'PCI Fee (£)',
  settlementFeeDescription: 'Fee applied for processing of each Settlement',
  refundFeeDescription: 'Fee applied for processing of each instance of the Refund request',
  chargeBackFeeDescription:
    'Fee applied as soon as the Chargeback process has been initiated (does not apply to reported fraud).',
  pciFeeDescription: 'Fee applied monthly for ongoing PCI compliance certification.',
  fasterFundingFeeDescription: 'Fee applied for reducing the Remittance Timescale to 1 Business Day',
  zashAppFeeDescription: 'Fee applied monthly for using the Zash App',
  paymentFacilitatingServices: 'Payment Facilitating Services',
  setUpConfigurationDelivery: 'Set-up, Configuration and Delivery (£) (per terminal)',
  engineerInstalationFee: 'Engineer Installation Fee (per Payment Terminal) (£)',

  regularCharges: 'Regular Charges (charged monthly)',
  chargedAtOnce: 'A fee charged once',
  paymentTerminalSupportAndTransaction: 'Subscription Fee Per Month (per terminal)', // 'Payment Terminal Support and Transaction Processing (£)',
  monthlyFeeChargedTerminal:
    'Monthly fee charged for terminal support, enabling the Merchant to accept Transactions. Excluding VAT, VAT will be applied automatically on top of the rate.',
  monthlyFeeChargedAirtime: 'Monthly fee charged for Airtime (GPRS / 3G / 4G)',
  gatewayServices: 'Gateway Services',
  gatewaySetUpFeePerTransaction: 'Gateway Fees (per Transaction) (£)',
  feeForEachTransaction: 'The fee charged for each Transaction that is submitted via the Gateway Services',
  other: 'Other Charges',
  managementFee: 'Management fee (per hour) (£)',
  managementFeeDescription:
    'Fees to take steps to manage Merchant’s account to include daily monitoring as a result of fraudulent Card Transactions; failure by the Merchant to follow standard operating procedures in the Procedure Guidelines; implementing a Chargeback reduction plan; managing any repayment programme; collecting any outstanding amounts owed by the Merchant; carrying out a review following change of the Merchant’s trading terms or operating procedures; and managing or enforcing any security that DNAP may hold in respect of Merchant’s Liabilities or in recovery of amounts due to DNAP.',
  minMonthlyServiceChargeDescription:
    'The minimum value the Merchant will be charged when credit and debit card Transaction Charges total less than per month.',
  discoverGlobalNetwork: 'Discover Global Network',
  discoverGlobalDescription: 'Charge per Transaction applies for all Discover Global Network cards accepted',
  openBankingDescription: 'Charge per Transaction for using Open Banking',
  payByBankDescription: 'Charge per Transaction for using Pay By Bank',
  authorizationFeeDescription: 'Fee applied in sending an authorisation request to a Payment Scheme.',
  disableFee: (disabled: boolean) => `Tap to ${disabled ? 'disable' : 'enable'} fee`,
  amexDescription: 'Charge per Transaction applies for all American Express cards accepted',
  unionPayDescription: 'Charge per Transaction applies for all UnionPay cards accepted',
  transactionFees: 'Other Payment Schemes Fees',
  settlementPeriodDescription: 'Remittance Timescale',
  fasterFundingMonthly: 'Faster Funding (Monthly) (£)',
  fasterFundingMonthlyDescription: 'Fee applied for reducing the Remittance Timescale to 1 Business Day',
  zashSolution: 'Zash Solution',
  zAndPayByBankApp: 'Zapp and the Pay by Bank app',
  payByTek: (
    <>
      Billing for Authorised Pos Accounts will be done by <b>PayTek</b>
    </>
  ),
  monthlyGatewayFee: 'Monthly Gateway Fee',
  monthlyGatewayFeeDescription: 'A fee charged on the monthly basis',
  terminalPurchase: 'Terminal Purchase',
  terminalCost: 'Terminal Cost - per terminal',
  authFee: 'Other Transactional Fees',
  settlements: 'Settlements',
  settlementNote:
    'Note: these are the standard settlement fees, which will be applied if Fast Funding is not enabled AND/OR is not approved',
  settlementFF: 'Settlements. Fast Funding',
  settlementFFNote: 'Fast funding fees are only applied if Fast Funding is requested AND approved',
  zashTitle: 'Zash EPOS',
  ffRequested: 'Fast funding requested',
  required: 'Required',
  percentPlaceholder: '0.00%',
  zeroPlaceholder: '0.00',
  terminalPurchaseNote:
    'The cost to purchase a terminal outright, as a one off payment, with no monthly subscription costs',
  terminalCostDescription: 'Excluding VAT, VAT will be applied automatically on top of the rate.',
  cardNotPresentFee: 'MOTO Fee (per transaction) (%)',
  aliPayFee: 'AliPay Fee',
  weChatPayFee: 'WeChat Pay Fee',
  aliPayDescription: 'Charge per Transaction for using Alipay',
  motoDescription: 'Charge per Transaction for using MOTO'
})
