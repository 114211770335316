export enum StatusEnum {
    authorised = 'Authorised',
    charged = 'Charged',
    declined ='Declined',
    refunded = 'Refunded',
    cancelled = 'Cancelled',
    chargeback = 'Chargeback',
    'chargeback-reversal' = 'Chargeback Reversal',
    'second-chargeback-lost' = 'Second Chargeback - Lost'
}
