import React from 'react'
import { useInjection } from 'dna-react-ioc'
import { observer } from 'mobx-react-lite'
import { Badge, Card, Drawer, Grid, Table } from 'antd'
import moment from 'moment'
import { Nowrap } from '~/code/components'
import translations from '~/code/pages/BPMProcesses/Requests/components/SubmittedRequests/translations/translations'
import { reviewTime } from '~/code/pages/BPMProcesses/Requests/utils'
import { ChangeOfSettlementPeriodRequestStoreSymbol } from '~/code/stores/ChangeOfSettlementPeriodRequestStore/ChangeOfSettlementPeriodRequestStore'
import {
  SettlementPeriodProcessDetails,
  SettlementPeriodRequestsFilter
} from '~/code/pages/BPMProcesses/Requests/components/ChangeOfSettlementPeriodRequests/components'
import { SettlementPeriodDrawerTitle } from '~/code/pages/BPMProcesses/Requests/components/ChangeOfSettlementPeriodRequests/components/SettlementPeriodDrawer'
import { IChangeOfSettlementPeriodRequestStore } from '~/code/pages/BPMProcesses/Requests/components/ChangeOfSettlementPeriodRequests/models/IChangeOfSettlementPeriodRequestStore'
import { SettlementPeriodRequestsStatus } from '~/code/pages/BPMProcesses/Requests/components/ChangeOfSettlementPeriodRequests/models/SettlementPeriodRequestsStatus'
import { DATE_TIME_FORMAT } from '~/code/constants/date-time'
import styles from './ChangeOfSettlementsPeriodRequests.scss'

export const ChangeOfSettlementsPeriodRequests = observer(() => {
  const screens = Grid.useBreakpoint()
  const store = useInjection<IChangeOfSettlementPeriodRequestStore>(ChangeOfSettlementPeriodRequestStoreSymbol)

  const getStatus = (status: SettlementPeriodRequestsStatus) => {
    switch (status) {
      case SettlementPeriodRequestsStatus.APPROVED:
        return 'success'
      case SettlementPeriodRequestsStatus.REJECTED:
        return 'error'
      case SettlementPeriodRequestsStatus.NEED_INFO:
        return 'warning'
      case SettlementPeriodRequestsStatus.STARTED:
        return 'processing'
      default:
        return ''
    }
  }

  const renderStatus = (status: SettlementPeriodRequestsStatus) => {
    const statusText = getStatus(status)
    return <Badge {...(statusText ? { status: statusText } : { color: 'yellow' })} text={status} />
  }

  const columns = [
    {
      title: translations().startDate,
      dataIndex: 'startDate',
      key: 'startDate',
      render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : '')
    },
    {
      title: translations().merchant,
      dataIndex: 'companyName',
      key: 'companyName'
    },
    {
      title: translations().status,
      dataIndex: 'processStatus',
      key: 'processStatus',
      render: (status: SettlementPeriodRequestsStatus) =>
        status ? (
          <Nowrap width={94} title={status}>
            {renderStatus(status)}
          </Nowrap>
        ) : (
          ''
        )
    },
    {
      title: translations().reviewTime,
      dataIndex: 'reviewTime',
      key: 'reviewTime',
      render: (reviewMinutes: number) => reviewTime(reviewMinutes)
    },
    {
      title: translations().endDate,
      dataIndex: 'endDate',
      key: 'endDate',
      render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : '')
    }
  ]

  const dataSource = store.processes && store.processes.map((application, idx) => ({ key: idx, ...application }))

  React.useEffect(() => {
    store.init()
  }, [])

  return (
    <>
      <SettlementPeriodRequestsFilter store={store} />
      <Card className={styles.tableCard}>
        <Table
          loading={store.isProcessesLoading}
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: true }}
          rowClassName={styles.row}
          onRow={record => {
            return {
              onClick: () => {
                store.loadProcessDetails(record.id)
                store.showInfoDrawer()
              }
            }
          }}
          pagination={{
            total: store.total,
            pageSize: store.pageSize,
            current: store.currentPage,
            onChange: (page, pageSize) => store.loadProcesses(page, pageSize),
            size: 'small'
          }}
        />
      </Card>
      <Drawer
        title={!store.hasProcessDetailsLoadingError ? <SettlementPeriodDrawerTitle /> : null}
        placement='right'
        closable={false}
        onClose={() => store.closeInfoDrawer()}
        open={store.isShowInfoDrawer}
        width={screens.xs ? 256 : 512}
      >
        <SettlementPeriodProcessDetails />
      </Drawer>
    </>
  )
})
