import { parseTextResult } from 'back-connector'
import { message } from 'antd'
import { saveAs } from 'file-saver'
import { getWithAuth } from '~/code/services'
import {
  FetchNetSuiteInvoicesParamsType,
  FetchNetSuiteInvoicesResponseType,
  NetSuiteInvoiceSubsidiaryType,
  FetchNetSuiteInvoiceParamsType
} from '~/code/stores/NetSuiteInvoicesStore/models'

export const fetchInvoices = (params: FetchNetSuiteInvoicesParamsType) =>
  getWithAuth<{ data: FetchNetSuiteInvoicesResponseType[]; totalCount: number }>(
    '/api/v1/billing/netsuite-invoices',
    params
  )

export const fetchInvoice = async (params: FetchNetSuiteInvoiceParamsType) => {
  const response = await getWithAuth(`/api/v1/billing/netsuite-invoice/report`, params, [], {
    parseResponse: async res => {
      if (res.status !== 200) {
        const error = await parseTextResult(res)
        throw new Error(error.message)
      }
      const blobFile = await res.blob()
      return blobFile
    }
  })

  if (response.error) {
    message.error(response.error?.message)
    return
  }

  saveAs.saveAs(await response.result, params.filename)
}

export const fetchSubsidiaries = async () =>
  getWithAuth<NetSuiteInvoiceSubsidiaryType[]>(`/api/v1/billing/subsidiaries`, {})
