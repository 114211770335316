import { Select } from 'antd'
import React from 'react'
import { IntervalSelectProps } from './props'
import styles from './IntervalSelect.scss'
import translations from './translations'

const { Option } = Select

export const IntervalSelect = ({ interval, intervalOptions, handleIntervalChange }: IntervalSelectProps) => {

    return <Select value={interval} onChange={handleIntervalChange} className={styles.intervalSelect}>
        {intervalOptions.map(intervalOption => (
            <Option key={intervalOption} value={intervalOption}>
                {translations()[intervalOption]}
            </Option>
        ))}
    </Select>
}