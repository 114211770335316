import React from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  closureOfTerminal: string
  terminals: string
  terminalsRequired: string
  reasonLabel: string
  reasonRequired: string
  reasonMinMsg: string
  submit: string
  areYouSureCloseTerminals: (terminals: string[], merchant: string) => React.ReactNode
  closeAllterminalsNotification: string
  terminationFee: string
  required: string
  valueRestriction: string
}>()
