import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    firstName: string
    surname: string
    merchant: string
    companyNumber: string
    phoneNumber: string
    name: string
    email: string
    comment: string
    companyInfo: string
    initiatorInfo: string
    reviewerInfo: string
}>()

