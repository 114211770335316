import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    review: string
    processName: string
    merchant: string
    assignedUser: string
    assignedGroup: string
    startDate: string
    endDate: string
    status: string
    reviewTime: string
    lessThanOneMinute: string
    selectMerchant: string
    selectStatus: string
    holdRelease: string
    hold: string
    release: string
}>()

