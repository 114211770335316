import { get, set } from 'store-connector'
import objectPath from 'object-path'

const userHash = 'dashboard'
const options = { useUserHash: true, userHash }

const storage = {
    get: (key: string) => {
        return get(key, options)
    },
    set: (key: string, value: string) => {
        set(key, value, options)
    },
    isEqual: (storageKey: string, key: string) => `${userHash}_${key}` === storageKey,
    isEmpty: (value: any) => {
        return !value || !JSON.parse(value)
    }
}

export const getStorageUserData = (path: string): any => {
    const key = `${storage.get('email')}_data`
    const obj = storage.get(key) || {}
    return objectPath.get(obj, path, {})
}

export const setStorageUserData = (path: string, newData: object) => {
    const key = `${storage.get('email')}_data`
    const obj = storage.get(key) || {}
    objectPath.set(obj, path, newData)
    storage.set(key, obj)
}

export default storage