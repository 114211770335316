import React from 'react'
import translations from './translations'

translations.add('en', {
  comment: 'Comment',
  commentRequired: 'Comment is required',
  reasonRequired: 'Reason is required',
  reasonMinMsg: 'Comment must be at least 2 characters',
  reason: 'Reason',
  warningMessage: (
    <>
      <p>
        <b>Attention!</b>
      </p>
      <p>
        {' '}
        Dear User, please double-check and ensure this Merchant is not transacting and is informed of the closure
        decision.
      </p>
      <p>
        Once the process is submitted, Merchant's MIDs and TIDs will be disabled immediately and Merchant won't be able
        to process transactions.
      </p>
    </>
  ),
  required: 'Required',
  valueRestriction: 'Value should be between 0 and 5000',
  terminationFee: 'Termination Fee (£)',
  legalName: 'Legal Name: ',
  closureOfMerchant: 'ATTENTION! Merchant closure'
})
