import React from 'react'
import { RowElementProps } from './props'
import styles from './RowElement.scss'

export const RowElement = (props: RowElementProps) => {
    return (
        <div className={styles.flexRow} >
            <b>
                <div className={styles.rowLabel}>
                    {props.label}:
                </div>
            </b>
            <div>{props?.value}</div>
        </div>
    )
}