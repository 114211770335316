import translations from './translations'

translations.add('en', {
  merchant: 'Merchant',
  applicantName: 'Customer Name',
  email: 'Email',
  phoneNumber: 'Phone',
  startDate: 'Start Date',
  endDate: 'End Date',
  currentStatus: 'Current status',
  acquisitionChannel: 'Partner',
  mid: 'MID',
  assignedGroup: 'Assigned Group',
  assignedUser: 'Assigned User',
  initiatorEmail: 'Initiator Email',
  approvalType: 'Approval Type',
  productType: 'Product Type',
  timeAtCurrentStatus: 'Time at current status',
  terminatedProcessesSuccessfully: 'Terminated Process Successfully',
  terminatedProcessesError: 'Terminate Processes Failed',
  terminalIssueType: 'Terminal Issue Time',
  segmentCategory: 'Channel',
  segmentSubCategory: 'Sub-channel',
  opportunityOwner: 'Opportunity Owner',
  opportunityId: 'Opportunity ID'
})
