import merge from 'lodash/merge'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { capitalize, cloneObject, isEmpty } from 'dna-common'
import { hasPermissions } from '~/code/services/auth'
import { Application, CompanyProfile, OfficerProfile, CompanyType, GeneralInformationType, OfficerAddress, OfficerRole, OnboardingRequest, CheckedDocumentVerification, PaymentSolution, PaymentSolutionView } from 'startapp/models'
import { isPCSCorporate } from './validations'
import { OnboardingRequestBase } from '../models/onboarding/OnboardingRequestBase'
import { OnboardingAddress } from '../models/onboarding/OnboardingAddress'
import { OnboardingOfficer } from '../models/onboarding/OnboardingOfficer'
import { OnboardingSoleTraderCompanyProfile } from '../models/onboarding/OnboardingSoleTraderCompanyProfile'
import { getEmptyAddress } from '.'
import { CheckedDocumentModel } from '../models/DocumentsRequiredModel'
import { convertDateObjectToString } from './converters'
import { UploadFile } from 'antd/lib/upload/interface'
import { AdvancePaymentsFormStateModel, advancePaymentsInitialValues, ProcessingMethodsFormStateModel, processingMethodsInitialValues } from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v1'
import translations from '../translations'

const processingMethodsData = [
    {
        key: 'pos',
        description: translations().pos
    },
    {
        key: 'moto',
        description: translations().moto
    },
    {
        key: 'ecom',
        description: translations().ecom
    },
    {
        key: 'payByLink',
        description: translations().payByLink
    },
    {
        key: 'payByApp',
        description: translations().payByApp
    },
    {
        key: 'virtualTerminal',
        description: translations().virtualTerminal
    }
]

const advancePaymentsData = [
    {
        key: 'nbsp',
        description: translations().nbsp
    },
    {
        key: 'deposits',
        description: translations().deposits
    },
    {
        key: 'balances',
        description: translations().balances
    },
    {
        key: 'fullPayment',
        description: translations().fullPayment
    },
    {
        key: 'other',
        hasInput: true
    }
]

export const convertAdvancePayments = (advancePayments: AdvancePaymentsFormStateModel) => {
    return advancePaymentsData.map((payment) => (
        payment.hasInput ?  {
            name: payment.key,
            description: advancePayments[payment.key].description,
            percent: Number(advancePayments[payment.key].percent),
            days: Number(advancePayments[payment.key].days)
        } : {
            name: payment.key,
            description: payment.description,
            percent: Number(advancePayments[payment.key].percent),
            days: Number(advancePayments[payment.key].days)
        }
    ))
}

export const convertProcessingMethods = (processingMethods: ProcessingMethodsFormStateModel) => {
    return processingMethodsData.map((method) => ({
        name: method.key,
        description: method.description,
        percent: Number(processingMethods[method.key].percent)
    }))
}


export const getPaymentSolutionView = (paymentSolution: PaymentSolution, terminalOrders: any): PaymentSolutionView => {
    const motoRequested = terminalOrders && terminalOrders[0]?.motoRequested
    switch (paymentSolution) {
        case PaymentSolution.POS:
            return motoRequested ? PaymentSolutionView.POS_MOTO : PaymentSolutionView.POS
        case PaymentSolution.PAY_BY_LINK:
            return motoRequested ? PaymentSolutionView.PAY_BY_LINK_MOTO : PaymentSolutionView.PAY_BY_LINK
        case PaymentSolution.WEBSITE_PAYMENTS:
            return motoRequested ? PaymentSolutionView.WEBSITE_PAYMENTS_MOTO : PaymentSolutionView.WEBSITE_PAYMENTS
    }
}

export const getPaymentSolution = (paymentSolutionView: PaymentSolutionView): PaymentSolution => {
    switch (paymentSolutionView) {
        case PaymentSolutionView.POS:
        case PaymentSolutionView.POS_MOTO:
            return PaymentSolution.POS
        case PaymentSolutionView.PAY_BY_LINK:
        case PaymentSolutionView.PAY_BY_LINK_MOTO:
            return  PaymentSolution.PAY_BY_LINK
        case PaymentSolutionView.WEBSITE_PAYMENTS:
        case PaymentSolutionView.WEBSITE_PAYMENTS_MOTO:
            return PaymentSolution.WEBSITE_PAYMENTS
    }
}

export const getTerminalOrders = (paymentSolutionView: PaymentSolutionView, locationAddress) => {
    return [
        {
            count: 1,
            locationAddress,
            motoRequested: [PaymentSolutionView.PAY_BY_LINK_MOTO, PaymentSolutionView.POS_MOTO, PaymentSolutionView.WEBSITE_PAYMENTS_MOTO].includes(paymentSolutionView),
            paymentSolution: getPaymentSolution(paymentSolutionView),
            storeName: ''
        }
     ]
}


export function parseApplicationWithEmptyValues(application: Application, companyType: CompanyType = 'sole-trader'): Application {
    const { companyProfile, companyOfficerList, companyPCSList } = cloneObject(application)
    const parsedApplication = {
        companyProfile: companyProfile ? merge(new CompanyProfile({}, companyType), companyProfile) : new CompanyProfile(),
        companyOfficerList: companyOfficerList && companyOfficerList.map(({ id, ...item }) => merge(new OfficerProfile(), item)),
        companyPCSList: companyPCSList && companyPCSList.map(({ id, ...item }) => merge(new OfficerProfile(), item))
    }
    return parsedApplication
}

export function parseApplicationCompanyInformation(application: any): Application {
    const { companyOfficerList, companyPCSList, companyProfile } = application
    // from Company House tradingAddress & tradingCountriesOutsideEea will not come

    return {
        companyProfile: companyProfile && {
            companyName: companyProfile.companyName,
            companyNumber: companyProfile.companyNumber,
            dateOfCreation: companyProfile.dateOfCreation && moment(companyProfile.dateOfCreation, 'YYYY-MM-DD').format('DD-MM-YYYY'),
            registeredOfficeAddress: companyProfile.registeredOfficeAddress && {
                ...companyProfile.registeredOfficeAddress,
                country: mapSameCountries(companyProfile.registeredOfficeAddress.country)
            },
            natureOfBusiness: companyProfile.natureOfBusiness,
            natureOfBusinessDetails: companyProfile.natureOfBusinessDetails,
            companyWebsite: companyProfile.companyWebsite,
            employeesNumber: companyProfile.employeesNumber,
            expectedAnnualTurnover: companyProfile.expectedAnnualTurnover,
            expectedMonthlyTransactionValues: companyProfile.expectedMonthlyTransactionValues,
            averageTransactionValue: companyProfile.averageTransactionValue,
            tradeOutsideEea: companyProfile.tradeOutsideEea,
            tradingCountriesOutsideEea: companyProfile.tradingCountriesOutsideEea,
            emailAddress: companyProfile.emailAddress,
            telephoneNumber: companyProfile.telephoneNumber,
            bankAccountNumber: companyProfile.bankAccountNumber,
            sortCode: companyProfile.sortCode,
            bankName: companyProfile.bankName,
            accountName: companyProfile.accountName,
            tradingAddress: companyProfile.tradingAddress,
            businessModelQuestions: companyProfile.businessModelQuestions || {
                seasonalBusiness: {
                    isTrue: false
                },
                face2face: {
                    isTrue: false
                }
            },
            expectedAnnualCardTurnover: companyProfile.expectedAnnualCardTurnover,
            processingMethods: companyProfile.processingMethods && companyProfile.processingMethods.length ? companyProfile.processingMethods : processingMethodsInitialValues,
            advancePayments: companyProfile.advancePayments && companyProfile.advancePayments.length ? companyProfile.advancePayments : advancePaymentsInitialValues
        },
        companyOfficerList: companyOfficerList ? companyOfficerList.map((item) => ({
            id: uuid(),
            name: item.name,
            nameElements: parseCompanyOfficerName(item.name),
            gender: item.gender,
            birthplace: item.birthplace,
            nationality: mapSameNationalities(item.nationality),
            dateOfBirth: item.dateOfBirth,
            officerRole: item.officerRole,
            occupation: item.occupation,
            emailAddress: item.emailAddress,
            telephoneNumber: item.telephoneNumber,
            deletionAllowed: false
        })) : [],
        companyPCSList: companyPCSList ? companyPCSList
            .filter((item) => isEmpty(item.ceasedOn))
            .map((item) => ({
                id: uuid(),
                name: item.name,
                nameElements: item.nameElements,
                gender: item.gender,
                birthplace: item.birthplace,
                occupation: item.occupation,
                nationality: mapSameNationalities(item.nationality),
                dateOfBirth: item.dateOfBirth,
                kind: item.kind,
                identification: item.identification,
                address: isPCSCorporate(item) ? item.address : {},
                emailAddress: item.emailAddress,
                telephoneNumber: item.telephoneNumber,
                deletionAllowed: false
            })) : []
    }
}

export function parseCompanyOfficerName(name: string) {
    name = name ? name.trim() : ''
    const commaIndex = name.indexOf(',')
    let surname = ''
    let forename = ''
    let names = []

    if (commaIndex > 0) {
        surname = name.slice(0, commaIndex);
        ([forename = '', ...names] = name.slice(commaIndex + 1).trim().split(/\s+/))
    } else {
        ([surname = '', forename = '', ...names] = name.split(/\s+/))
    }

    return {
        surname: capitalize(surname.trim()),
        forename: forename.trim(),
        middleName: names.map((n) => n.trim()).join(' ')
    }
}

export function mapSameCountries(country: string) {
    switch (country.toLocaleLowerCase()) {
        case 'england':
        case 'great britain':
        case 'uk':
        case 'scotland':
        case 'wales':
        case 'northern ireland':
            return 'United Kingdom'
    }
    return country
}

export function mapSameNationalities(nationality: string) {
    switch (nationality.toLocaleLowerCase()) {
        case 'english':
        case 'northern irish':
        case 'scottish':
        case 'welsh':
        case 'british':
            return 'British'
    }
    return nationality
}

export const parseOnboardingRequestData = (
    application: Application,
    generalInformation: GeneralInformationType,
    tariffs: any = undefined
) : OnboardingRequest => {

    const {
        companyType,
        paymentSolution,
        posModel,
        isFastTrack,
        isMsaSigned
    } = generalInformation

    let { companyProfile } = application
    const { companyOfficerList, companyPCSList, terminalOrders } = application

    if (!companyProfile) {
        companyProfile = new CompanyProfile()
    }

    const {
        relationshipType,
        companyName,
        companyTradeName,
        storeName,
        companyNumber,
        dateOfCreation,
        employeesNumber,
        companyWebsite,
        tradingCountriesOutsideEea,
        tradeOutsideEea,
        emailAddress,
        telephoneNumber,
        accountName,
        bankAccountType,
        bankAccountNumber,
        sortCode,
        bankName,
        averageTransactionValue,
        expectedAnnualTurnover,
        expectedAnnualTurnoverCustomValue,
        expectedMonthlyTransactionValues,
        natureOfBusiness,
        natureOfBusinessDetails,
        tradingAddress: companyTradingAddress,
        registeredOfficeAddress,
        businessModelQuestions,
        advancePayments,
        processingMethods,
        expectedAnnualCardTurnover,
        highestTransactionValue
    } = companyProfile

    const base: OnboardingRequestBase = {
        businessStructure: companyType,
        paymentSolution,
        terminalOrders,
        isFastTrack: isFastTrack ? true : false,
        isMsaSigned: isMsaSigned ? true : false
    }

    const getBusinessModelQuestions = () => {
        const { face2face, seasonalBusiness: { isTrue, quarter1, quarter2, quarter3, quarter4 } } = businessModelQuestions || { seasonalBusiness: {} }
        return {
            face2face,
            seasonalBusiness: {
                isTrue,
                quarter1: Number(quarter1),
                quarter2: Number(quarter2),
                quarter3: Number(quarter3),
                quarter4: Number(quarter4)
            }
        }
    }

    const tariffsData = tariffs
        ? { tariffs: { ...tariffs, posModel } }
        : hasPermissions(['onboarding.processes.fast_track.create'])
        && isFastTrack && !isMsaSigned
        ? { tariffs: { posModel} }
        : {}

    const soleTraderCompanyProfile: OnboardingSoleTraderCompanyProfile = {
        relationshipType,
        companyName,
        companyTradeName,
        storeName,
        dateOfCreation : dateOfCreation ? moment(dateOfCreation, 'DD / MM / YYYY').format('DD-MM-YYYY') : '',
        merchantCategoryCode: '',
        numberOfEmployees: employeesNumber,
        companyWebsite,
        tradingCountriesOutsideEea,
        tradeOutsideEea,
        emailAddress,
        telephoneNumber,
        bankAccountType,
        bankAccountName: accountName,
        bankAccountNumber,
        sortCode,
        bankName,
        averageTransactionValue,
        expectedAnnualTurnover: expectedAnnualTurnoverCustomValue ?? expectedAnnualTurnover,
        expectedMonthlyTransactionValues,
        natureOfBusiness,
        natureOfBusinessDetails,
        tradingAddress: parseAddress(companyTradingAddress),
        businessModelQuestions: getBusinessModelQuestions(),
        advancePayments: advancePayments && (!!Object.keys(advancePayments).length) ? convertAdvancePayments(companyProfile.advancePayments) : [],
        processingMethods: processingMethods && (!!Object.keys(processingMethods).length) ? convertProcessingMethods(companyProfile.processingMethods) : [],
        expectedAnnualCardTurnover,
        highestTransactionValue
    }

    if (companyType === 'company') {
        const corporateEntityShareholders = companyPCSList
            .filter((s) => isPCSCorporate(s))
            .map((s) => ({ companyName: s.name, companyNumber: s.identification?.registrationNumber || '' }))
        
        const individualShareholders = companyPCSList
            .filter((s) => !isPCSCorporate(s))
            .map((s) => parseOfficerProfile(s))

        const companyDirectors = companyOfficerList
            .filter((o) => o.officerRole === OfficerRole.director)
            .map((o) => parseOfficerProfile(o))

        const authorisedSignatories = companyOfficerList
            .filter((o) => o.officerRole === OfficerRole.authorisedSignatory)
            .map((o) => parseOfficerProfile(o))

        return {
            ...base,
            companyProfile: {
                ...soleTraderCompanyProfile,
                companyType,
                companyNumber,
                companyAddress: parseAddress(registeredOfficeAddress)
            },
            companyDirectors,
            corporateEntityShareholders,
            individualShareholders,
            authorisedSignatories,
            ...tariffsData
        }
    }

    const owner = companyOfficerList?.find((o) => o.officerRole === OfficerRole.businessOwner)

    return {
        ...base,
        companyProfile: soleTraderCompanyProfile,
        businessOwner: owner && parseOfficerProfile(owner),
        ...tariffsData
    }
}

export const parseAddress = (address: OfficerAddress): OnboardingAddress => {
    address = address ? address : getEmptyAddress()

    const {
        addressLine1,
        addressLine2,
        country,
        locality,
        postalCode,
        region,
        county
    } = address

    return {
        addressLine1,
        addressLine2,
        country,
        postalCode,
        county,
        countyOrState: region,
        townOrCity: locality
    }
}

export const parseOfficerProfile = (officer: OfficerProfile): OnboardingOfficer => {
    officer = new OfficerProfile(officer)

    const {
        nameElements: {
            title,
            forename,
            middleName,
            surname
        },
        dateOfBirth,
        gender,
        birthplace,
        nationality,
        occupation,
        emailAddress,
        telephoneNumber,
        address,
        deletionAllowed
    } = officer

    const { houseName, houseNumber, ...restAddress } = address

    return {
        title,
        firstName: forename,
        middleName,
        surname,
        dateOfBirth,
        gender,
        placeOfBirth: birthplace,
        nationality,
        occupation,
        emailAddress,
        telephoneNumber,
        residentialAddress: {
            ...parseAddress(restAddress),
            houseName,
            houseNumber
        },
        deletionAllowed
    }
}

export const parseCheckedDocumentVerification = (officer: CheckedDocumentVerification, fileList: UploadFile[]): CheckedDocumentModel => {
    return {
        isRequired: !officer.verified,
        officer: `${officer.firstName} ${officer.surname}, ${convertDateObjectToString(officer.dateOfBirth, 'DD.MM.YYYY')}, ${officer.residentialAddress.postalCode}, ${officer.residentialAddress.country}`,
        fileList: fileList && fileList.length > 0 ? fileList : [],
        isLoading: false
    }
}
