import { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  submit: string
  confirmClosure: string
  closureOfMerchant: string
  areYouSureCloseMerchant: (merchant: string) => ReactNode
  ensureTerminationFee: (fee: number) => ReactNode
}>()
