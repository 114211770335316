import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  sortCode: string
  bankAccount: string
  accountName: string
  bankName: string
  overallScore: string
  result: string
  id: string
  reportDate: string
  emailAddress: string
}>()
