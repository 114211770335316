import { object, string } from 'yup'

const isEmptyOrMin3Len = (value: string) => !value || value.length >= 3

export const TransactionStatementFilterScheme = object({
    payerIp: string().test('isPayerIpValid', '', isEmptyOrMin3Len),
    payerEmail: string().test('isPayerEmailValid', '', isEmptyOrMin3Len),
    cardMask: string().test('isCardMaskValid', '', isEmptyOrMin3Len),
    terminal: string().test('isTerminalValid', '', isEmptyOrMin3Len),
    reference: string().test('isReferenceValid', '', isEmptyOrMin3Len),
    mid: string().test('isMIDValid', '', isEmptyOrMin3Len),
    merchantReference: string().test('isMerchantReferenceValid', '', isEmptyOrMin3Len)
})
