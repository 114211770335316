import { Form, Row, Select, Col } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { StatisticsFiltersStoreSymbol } from '~/code/pages'
import { StatisticsFiltersStore } from '~/code/stores/StatisticsStore'
import translations from './translations'

export const StatisticsFilters = observer(() => {
  const store = useInjection<StatisticsFiltersStore>(StatisticsFiltersStoreSymbol)

  useEffect(() => {
    store.init()
  }, [])

  return (
    <Form>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8} xl={8}>
          <Form.Item label={translations().segmentCategory}>
            <Select
              value={store.filters.segmentCategory}
              onChange={v => {
                store.setFilter('segmentCategory', v)
                store.setFilter('segmentSubCategory', null)
                store.setFilter('acquisitionChannel', null)
              }}
            >
              {store.handbooksStore.segmentCategories.length > 0 &&
                store.handbooksStore.businessCategoriesWithAll.map(option => (
                  <Select.Option value={option.code}>{option.value}</Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={8}>
          <Form.Item label={translations().segmentSubCategory}>
            <Select
              disabled={!store.filters.segmentCategory}
              value={store.filters.segmentSubCategory}
              onChange={v => store.setFilter('segmentSubCategory', v)}
            >
              {store.handbooksStore.segmentSubCategories.length > 0 &&
                store.filters.segmentCategory &&
                store.handbooksStore
                  .sortedSubCategoriesByCategory(store.filters.segmentCategory)
                  .map(option => <Select.Option value={option.code}>{option.value}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} md={12} lg={8} xl={8}>
          <Form.Item label={translations().acquisitionChannel}>
            <Select
              disabled={!store.filters.segmentSubCategory}
              value={store.filters.acquisitionChannel}
              onChange={v => store.setFilter('acquisitionChannel', v)}
            >
              {store.handbooksStore.startProcessAcquisitionChannels.length > 0 &&
                store.filters.segmentSubCategory &&
                store.handbooksStore
                  .sortedAcqChannelsBySubCategory(store.filters.segmentSubCategory)
                  .map(option => <Select.Option value={option.code}>{option.value}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={8}>
          <Form.Item label={translations().productType}>
            <Select value={store.filters.productType} onChange={v => store.setFilter('productType', v)}>
              {store.handbooksStore.productTypes.map(option => (
                <Select.Option value={option.value}>{option.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8} xl={8}>
          <Form.Item label={translations().approvalType}>
            <Select value={store.filters.approvalType} onChange={v => store.setFilter('approvalType', v)}>
              {store.handbooksStore.approvalTypes.map(option => (
                <Select.Option value={option.value}>{option.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
})
