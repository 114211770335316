import React from 'react'
import { TabsProps } from 'antd/lib/tabs'
import {
  WebNotificationPreview
} from '~/code/pages/Notifications/ManageNotificationTemplates/components/WebNotificationPreview'
import { NotificationSetupFormType } from '~/code/stores/NotificationManagementStore'
import {
  MobileNotificationPreview
} from '~/code/pages/Notifications/ManageNotificationTemplates/components/MobileNotificationPreview'
import translations from '../translations'

export const getPreviewComponents: (
  isDashboard: boolean,
  previewTemplate: string,
  notificationSetupForm: NotificationSetupFormType
) => TabsProps['items'] = (isDashboard,previewTemplate, notificationSetupForm) => {
  return [
    {
      key: '1',
      label: translations().web,
      children: <WebNotificationPreview isDashboard={isDashboard} previewTemplate={previewTemplate} notificationSetupForm={notificationSetupForm}/>,
    },
    {
      key: '2',
      label: translations().mobile,
      children: <MobileNotificationPreview isDashboard={isDashboard} previewTemplate={previewTemplate} notificationSetupForm={notificationSetupForm}/>,
    },
  ]
}

export const formLayout = {
  labelCol: {
    md: { span: 8 },
    sm: { span: 8 },
  },
}