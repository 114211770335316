import React from 'react'
import classNames from 'classnames'
import { Card } from 'antd'
import { ChartCardProps } from './props'
import styles from './ChartCard.scss'

export const ChartCard: React.FC<ChartCardProps> = (props) => {
    const {
        loading = false,
        contentHeight,
        title,
        avatar,
        label,
        total,
        footer,
        children,
        ...rest
    } = props

    type totalType = () => React.ReactNode

    const renderTotal = (_total?: number | totalType | React.ReactNode) => {
        if (!_total && _total !== 0) {
            return null
        }
        let totalDom
        switch (typeof _total) {
            case 'undefined':
                totalDom = null
                break
            case 'function':
                totalDom = <div className={styles.total}>{_total()}</div>
                break
            default:
                totalDom = <div className={styles.total}>{_total}</div>
        }
        return totalDom
    }

    const renderContent = () => {
        if (loading) {
            return false
        }
        return (
            <div className={styles.chartCard}>
                <div
                    className={classNames(styles.chartTop, {
                        [styles.chartTopMargin]: !children && !footer
                    })}
                >
                    <div className={styles.avatar}>{avatar}</div>
                    <div className={styles.metaWrap}>
                        <div className={styles.meta}>
                            <span className={styles.title}>{title}</span>
                            <span className={styles.label}>{label}</span>
                        </div>
                        {renderTotal(total)}
                    </div>
                </div>
                {children && (
                    <div className={styles.content} style={{ height: contentHeight || 'auto' }}>
                        <div className={contentHeight && styles.contentFixed}>{children}</div>
                    </div>
                )}
                {footer && (
                    <div
                        className={classNames(styles.footer, {
                            [styles.footerMargin]: !children
                        })}
                    >
                        {footer}
                    </div>
                )}
            </div>
        )
    }


    return (
        <Card loading={loading} bodyStyle={{ padding: loading ? '20px 24px 20px 24px' : '20px 24px 8px 24px' }} {...rest}>
            {renderContent()}
        </Card>
    )
}
