import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  upload: string
  provideInfo: string
  provideAdditionalInfo: string
  comment: string
  submit: string
  documents: string
  documentType: string
  documentsRequired: string
}>()
