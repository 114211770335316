import { message } from 'antd'
import { action, autorun, makeObservable, observable, reaction } from 'mobx'
import { TableData, TableFetchParams } from '~/code/models'
import { HandbooksStore } from '~/code/stores/HandbooksStore'
import { TableFilterStore } from '~/code/stores/TableFilterStore'
import { deleteProcesses } from '../BPM/Onboarding/services/fetchers'
import { SavedApplicationsFiltersStore } from './components/SavedApplicationsFilters/SavedApplicationsFiltersStore'
import { StartedApplication } from './models'
import { fetchStartedApplications } from './services'
import translations from './translations'

export class SavedApplicationsStore {
    isInitiated: boolean = false
    selectedApplication: StartedApplication = null

    filtersStore: SavedApplicationsFiltersStore
    tableStore: TableFilterStore<StartedApplication>

    constructor(public handbookStore: HandbooksStore) {
        this.filtersStore = new SavedApplicationsFiltersStore(this)
        this.tableStore = new TableFilterStore(this.loadApplications.bind(this))

        makeObservable(this, {
            selectedApplication: observable,
            setSelectedApplication: action.bound,
            deleteApplication: action.bound,
            loadData: action.bound
        })
        
        autorun(() => {
            const { dateStore: { startDate, endDate } } = this.filtersStore
            if (startDate && endDate) {
                this.loadData()
            }
        })
    }

    init() {
        this.filtersStore.init()
        this.handbookStore.loadStartApplicationAcquisitionChannels()
        this.isInitiated = true
    }

    setSelectedApplication(application: StartedApplication) {
        this.selectedApplication = application
    }

    getAcquisitionChannelDescription(name: string): string {
        return this.handbookStore.startProcessAcquisitionChannels.find(a => a.code  === name)?.value
    }

    async loadApplications(params: TableFetchParams): Promise<TableData<StartedApplication, number>> {
        try {
            const { dateStore: { startDate, endDate }, email, acquisitionChannel } = this.filtersStore
            const { error, status, result } = await fetchStartedApplications({
                from: startDate.clone().utc().format(),
                to: endDate.clone().utc().format(),
                page: params.currentPage,
                size: params.pageSize,
                email: email || undefined,
                acquisitionChannel: acquisitionChannel || undefined
            })

            if (error || status !== 200) {
                message.error(error.message || translations().errorLoadingApplications)
                return {
                    data: [],
                    total: 0
                }
            }

            return {
                data: result.report,
                total: result.totalCount
            }
        } catch (error) {
            message.error(translations().errorLoadingApplications)
            return {
                data: [],
                total: 0
            }
        }
    }

    async deleteApplication(deleteReason: string, applicationId: string): Promise<void> {
        try {
            const { error, status } = await deleteProcesses({ processIds: [applicationId], deleteReason })

            if (error || status !== 200) {
                message.error(error.message || translations().errorDeletingTheApplication)
                return
            }

            message.success(translations().deletedApplicationSuccessfully)
        } catch (error) {
            message.error(translations().errorDeletingTheApplication)
        }
    }

    loadData() {
        this.tableStore.loadData({ pageNumber: 1 })
    }
}
