import { DownOutlined } from '@ant-design/icons'
import { Button, Col, Form, Row, Space, Typography } from 'antd'
import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import { FormSearchProps } from './props'
import translations from './translations'
import styles from './FormSearch.scss'
import { areValuesEmpty } from './services'

const { Link } = Typography

export const FormSearch: React.FC<FormSearchProps> = props => {
  const { className, form, items, onReset, isCollapsible = true } = props
  const [isCollapsed, setCollapsed] = useState(true)

  const toggleCollapse = (e: any) => {
    e.preventDefault()
    setCollapsed(!isCollapsed)
  }

  useEffect(() => {
    const collapsedValues = items.filter(item => !item.collapsedColProps).map(item => item.value)
    if (!areValuesEmpty(collapsedValues)) {
      setCollapsed(false)
    }
    }, [])

  return (
    <div className={classNames(styles.FormSearch, { 'form-search-collapsed': isCollapsed }, className)}>
      <Form
        colon={false}
        validateTrigger={'onBlur'}
        labelCol={{ xs: 7, xxl: 9 }}
        wrapperCol={{ xs: 17, xxl: 15 }}
        labelAlign={'left'}
        form={form}
        preserve={false}
      >
        <Row gutter={24}>
          {items &&
            items
              .filter(item => !isCollapsible || (isCollapsed ? item.showInCollapsed || item.collapsedColProps : true))
              .map(item => {
                const colProps = (isCollapsed && item.collapsedColProps) || item.colProps

                return (
                  <Col key={item.key} {...colProps}>
                    {item.component}
                  </Col>
                )
              })}
          {isCollapsible && (
            <Col flex={'1'} className={styles.actions}>
              <Space>
                <Button onClick={() => onReset()}>{translations().reset}</Button>
                <Link href='#' className='nowrap' onClick={toggleCollapse}>
                  <Collapse isCollapsed={isCollapsed} />
                </Link>
              </Space>
            </Col>
          )}
        </Row>
      </Form>
    </div>
  )
}

const Collapse: React.FC<{ isCollapsed: boolean }> = ({ isCollapsed }) => {
  if (isCollapsed) {
    return (
      <>
        {translations().expand}
        <DownOutlined
          style={{
            marginLeft: '0.5em',
            transition: '0.3s all',
            transform: `rotate(${isCollapsed ? 0 : 0.5}turn)`
          }}
        />
      </>
    )
  }
  return (
    <>
      {translations().collapse}
      <DownOutlined
        style={{
          marginLeft: '0.5em',
          transition: '0.3s all',
          transform: `rotate(${isCollapsed ? 0 : 0.5}turn)`
        }}
      />
    </>
  )
}
