import React from 'react'
import classNames from 'classnames'
import { SectionBlockWebProps } from '~/code/pages/Notifications/ManageNotificationTemplates/components/WebNotificationPreview/components/SectionBlockWeb/props'
import styles from './styles.scss'

export const SectionBlockWeb: React.FC<SectionBlockWebProps> = ({ className, notificationSetupForm }) => {
  const { title, description } = notificationSetupForm

  const renderMenuItems = (count = 10) => (
    Array.from({ length: count }, (_, index) => (
      <div className={styles.menuItem} key={index}>
        <div className={classNames(styles.menuItemCircle, styles.mrFive)}></div>
        <div className={styles.menuItemBar}></div>
      </div>
    ))
  )

  return (
    <div className={styles.previewWrapper}>
      <div className={classNames(styles.header, styles[className])}>
        <div className={styles.logo}>
          <div className={classNames(styles.circle, styles.yellow, styles.mrFive)}></div>
          <div className={styles.bar}></div>
        </div>
        <div className={styles.userInfo}>
          <div className={styles.bar}></div>
          <div className={classNames(styles.circle, styles.grey, styles.mlFive)}></div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={classNames(styles.sider, styles[className])}>
          {renderMenuItems()}
        </div>
        <div className={styles.tableContainer}>
          <div className={styles.contentWrapper}>
            <div className={styles.notificationWrapper}>
              <div>
                <span className={styles.title}>{title}</span>
              </div>
              <div className={styles.description}>{description}</div>
            </div>
            <div className={styles.previewImg}></div>
          </div>
        </div>
      </div>
    </div>
  )
}