import { form } from 'back-connector'
import { post } from './requests'
import { LoginModel, TokenModel, UpdateTokenRequestModel, AuthType, GrantTypeType } from '~/code/models/auth'
import { deleteWithAuth, putWithAuth } from './authorised-requests'
import { AUTH_SCOPES } from '~/code/constants/AuthConstants'

export const login = (params: LoginModel) =>
  post<TokenModel>(
    '/auth/client/oauth2/token',
    form({
      grant_type: 'password' as GrantTypeType,
      scope: AUTH_SCOPES,
      username: params.email,
      password: params.password
    })
  )

export const logout = () => deleteWithAuth('/auth/connect/token')

export const requestRefreshToken = (refreshToken: string) =>
  post<TokenModel>(
    '/auth/client/oauth2/token',
    form({
      grant_type: 'refresh_token' as GrantTypeType,
      scope: AUTH_SCOPES,
      refresh_token: refreshToken
    })
  )

export const resetPassword = (email: string) =>
  post('/api/client/v2/password-reset', {
    email
  })

export const confirmEmailForReset = (email: string, confirmCode: string) =>
  post<{ Id: string }>('/api/client/v1/validation', {
    email,
    key: confirmCode
  })

export const setNewPassword = (
  email: string,
  verificationId: string,
  newPassword: string,
  code?: number,
  authType?: AuthType
) =>
  post(
    '/api/client/v2/password',
    {
      email,
      verificationId,
      password: newPassword
    },
    getSetNewPasswordHeaders(code, authType)
  )

export const updateToken = ({
  grant_type,
  merchantId,
  twoFAcode,
  twoFAFrequencyId,
  twoFAStatusId,
  twoFATypeId
}: UpdateTokenRequestModel) => {
  const params = {
    grant_type,
    merchant_id: merchantId,
    '2fa_code': twoFAcode,
    '2fa_type_id': twoFATypeId,
    '2fa_status_id': twoFAStatusId,
    '2fa_frequency_id': twoFAFrequencyId
  }

  const filteredParams = Object.fromEntries(Object.entries(params).filter(([_, value]) => value !== undefined))

  return putWithAuth<TokenModel>('/auth/oauth2/token', form(filteredParams))
}

const getSetNewPasswordHeaders = (code?: number, authType?: AuthType): [string, string][] | undefined => {
  const headers: [string, string][] = []

  if (code) {
    headers.push(['code', `${code}`])
  }

  if (authType) {
    headers.push(['authType', authType])
  }

  return headers.length > 0 ? headers : undefined
}
