import React from 'react'
import classNames from 'classnames'
import { LoginBannerMobileProps } from './props'
import styles from './styles.scss'

export const LoginBannerMobile: React.FC<LoginBannerMobileProps> = ({ className, notificationSetupForm }) => {
  const { notificationLevelId, title, description } = notificationSetupForm

  const renderNotification = () => {
    if (!notificationLevelId) return null

    return (
      <div className={classNames(styles.notificationContainer, styles[notificationLevelId])}>
        <span className={styles.title}>{title}</span>
        <div className={styles.description}>{description}</div>
      </div>
    )
  }

  const renderBar = () => (
    <div className={classNames(styles.bar, styles[className])}></div>
  )

  return (
    <div className={styles.previewWrapper}>
      <div className={styles.header}>
        {renderBar()}
        <div className={classNames(styles.circle, styles.yellow)}></div>
      </div>
      <div className={styles.loginFormWrapper}>
        {renderBar()}
        <div className={styles.input}></div>
        {renderBar()}
        <div className={styles.input}></div>
        <div className={styles.loginBtnWrapper}>
          {renderBar()}
          <div className={classNames(styles.input, styles.yellow)}></div>
        </div>
        {renderNotification()}
      </div>
    </div>
  )
}