import { Descriptions } from 'antd'
import React from 'react'
import { CompanyInfoPropsType } from './props'
import translations from './translations'

export const CompanyInfo = ({
  data,
  deviceId,
  isFastTrack,
  isMsaSigned,
  motoRequested,
  needUploadKycDocuments,
  parentCompanies,
  partnerMerchantId,
  productCount,
  productType,
  acquisitionChannel
}: CompanyInfoPropsType) => {
  return (
    <Descriptions bordered column={1}>
      <Descriptions.Item label={<b>{translations().businessType}</b>}>{data.businessType}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().applicationDeclaration}</b>}>
        {data.applicationDeclaration}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().averageTransactionValue}</b>}>
        {data.averageTransactionValue}
      </Descriptions.Item>

      <Descriptions.Item label={<b>{translations().companyNumber}</b>}>{data.companyNumber}</Descriptions.Item>
      <Descriptions.Item
        label={<b>{translations().companyAddress}</b>}
      >{`${data?.companyAddress?.postalCode} ${data?.companyAddress?.addressLine1} ${data?.companyAddress?.townOrCity}`}</Descriptions.Item>
      <Descriptions.Item
        label={<b>{translations().tradingAddress}</b>}
      >{`${data?.tradingAddress?.postalCode} ${data?.tradingAddress?.addressLine1} ${data?.tradingAddress?.townOrCity}`}</Descriptions.Item>

      <Descriptions.Item label={<b>{translations().descriptor}</b>}>{data.descriptor}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().emailAddress}</b>}>{data.emailAddress}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().expectedAnnualCardTurnover}</b>}>
        {data.expectedAnnualCardTurnover}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().expectedAnnualTurnover}</b>}>
        {data.expectedAnnualTurnover}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().expectedMonthlyTransactionValues}</b>}>
        {data.expectedMonthlyTransactionValues}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().formerAcquirer}</b>}>{data.formerAcquirer}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().fullLegalName}</b>}>{data.fullLegalName}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().highestTransactionValue}</b>}>
        {data.highestTransactionValue}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().incorporationDate}</b>}>{data.incorporationDate}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().merchantCategoryCode}</b>}>
        {data.merchantCategoryCode}
      </Descriptions.Item>

      <Descriptions.Item label={<b>{translations().privacyPolicy}</b>}>{data.privacyPolicy}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().resellerName}</b>}>{data.resellerName}</Descriptions.Item>

      <Descriptions.Item label={<b>{translations().storeDetails}</b>}>{`${data?.storeDetails?.postalCode} ${data?.storeDetails?.addressLine1} ${data?.storeDetails?.townOrCity}`}</Descriptions.Item>

      <Descriptions.Item
        label={<b>{translations().taxInfo}</b>}
      >{`${data.taxInfo?.vatOrUtrNumber} ${data.taxInfo?.missingReason}`}</Descriptions.Item>

      <Descriptions.Item label={<b>{translations().acquisitionChannel}</b>}>{acquisitionChannel}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().deviceId}</b>}>{deviceId}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().isFastTrack}</b>}>
        {isFastTrack ? translations().yes : translations().no}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().isFastTrack}</b>}>
        {isMsaSigned ? translations().yes : translations().no}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().motoRequested}</b>}>
        {motoRequested ? translations().yes : translations().no}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().needUploadKycDocuments}</b>}>
        {needUploadKycDocuments ? translations().yes : translations().no}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().parentCompanies}</b>}>{parentCompanies}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().partnerMerchantId}</b>}>{partnerMerchantId}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().productCount}</b>}>{productCount}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().productType}</b>}>{productType}</Descriptions.Item>
    </Descriptions>
  )
}
