import translations from './translations'

translations.add('en', {
    terminalTypes: {
        all: 'ALL',
        ecom: 'ECOM',
        pos: 'POS'
    },
    terminalType: 'Terminal Type',
    store: 'Store',
    terminal: 'Terminal',
    all: 'ALL',
    mid: 'MID'
})
