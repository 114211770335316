import translations from './translations'

translations.add('en', {
    tabTitles: {
        totalSummary: 'Applications Count',
        teamSummary: 'Underwriting Team Statistics',
        terminalIssueReport: 'Terminal Issue Report'
    },
    ecom: 'ECOM',
    pos: 'POS',
    errorLoading: {
        totalSummary: (terminalType) => `Error loading ${terminalType} total summary`,
        teamSummary: (terminalType) => `Error loading ${terminalType} team summary`,
        terminalIssueReport: 'Error loading terminal issue report'
    },
    total: 'Total',
    columnTitles: {
        start: 'At the Beginning',
        new: 'New',
        submitted: 'Submitted',
        approved: 'Approved',
        addInfoProvided: 'Add Info Provided',
        referred: 'Referred',
        declined: 'Declined',
        end: 'At the End',
        terminalType: 'Terminal Type',
        user: 'User',
        acquisitionChannelName: 'Partner',
        productType: 'Product Type',
        approvalType: 'Approval Type',
        terminalQuantity: 'Number of TIDs',
        merchantQuantity: 'Number of MIDs',
        segmentCategory: 'Segment Category',
        segmentSubCategory: 'Segment subcategory'
    }
})
