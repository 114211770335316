import { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    title: string
    columns: string
    cancel: string
    ok: string
    all: (number) => string,
    custom: string
    errDownloadingStatement: string
    successDownloadingStatement: ReactNode
    error: string
}>()
