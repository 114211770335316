import React from 'react'
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import numeral from 'numeral'
import { IAnalyticsDetailedViewStore } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsDetailedView/models/IAnalyticsDetailedViewStore'
import { TableFilterStore } from '~/code/stores/TableFilterStore'
import { DetailsType } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsDetailedView/models/DetailsType'
import { TabData } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsDetailedView/models/TabData'
import { AcquiringType } from '~/code/pages/Acquiring'
import { IAcquiringFilterStore } from '~/code/pages/Acquiring/components/AcquiringFilter'
import { AnalyticsDetailedSummaryRequest } from '~/code/models/analytics/AnalyticsDetailedSummaryRequest'
import {
  fetchAcquisitionChannelsSummary,
  fetchCardTypesSummary,
  fetchIssuingBanksSummary,
  fetchMccSummary,
  fetchMerchantsSummary,
  fetchPaymentMethodsSummary
} from '~/code/services/fetchers'
import { error as errorLog } from 'dna-common'
import { AnalyticsDetailedSummaryResponse, EntitySummary } from '~/code/models/analytics/AnalyticsSummaryResponse'
import {
  capitalize,
  detailsDataToTabData,
  generateAmountTableColumns,
  generateCountTableColumns,
  generateDetailsObject,
  generateMCCAmountTableColumns,
  generateMCCCountTableColumns,
  getRequestUniqueFieldName,
  resetCurrentPageInDetailsData,
  resetDataHasBeenLoadedFlags
} from '~/code/stores/AnalyticsStore/services/utils'
import translations from './translations'
import { PaymentMethods } from '~/code/stores/AnalyticsStore/constants/PaymentMethods'
import { isPartner } from '~/code/services/auth'
import { GlobalConfigStore } from '../GlobalConfigStore'
import { Routes } from '~/code/startup/Router/Routes'
import storage from '~/code/services/storage'

export class AnalyticsDetailedViewStore implements IAnalyticsDetailedViewStore {
  tableFilterStore: TableFilterStore<any> = null
  filterStore: IAcquiringFilterStore = null
  configStore: GlobalConfigStore = null
  acquiringType: AcquiringType = null

  constructor(filterStore: IAcquiringFilterStore, configStore: GlobalConfigStore, acquiringType: AcquiringType) {
    this.filterStore = filterStore
    this.configStore = configStore
    this.acquiringType = acquiringType
    this.tableFilterStore = new TableFilterStore(null) // TODO add fetch function

    makeObservable(this, {
      currentTab: observable,

      currentMerchantsCATab: observable,
      currentMccCATab: observable,
      currentIssuingBanksCATab: observable,
      currentCardTypesCATab: observable,
      currentPaymentMethodsCATab: observable,
      currentAcquisitionChannelsCATab: observable,
      currentAcquisitionChannelsStatusTab: observable,
      forceRefresh: observable,

      tabsData: computed,
      amountTableColumns: computed,
      countTableColumns: computed,
      acquisitionChannelId: computed,
      currency: computed,
      isSolidGate: computed,

      merchantsDetails: observable,
      mccDetails: observable,
      issuingBanksDetails: observable,
      cardTypesDetails: observable,
      paymentMethodsDetails: observable,
      acquisitionChannelsDetails: observable,

      refreshTable: action.bound,

      loadData: action.bound,
      loadBreakdown: action,
      setShouldLoadData: action,
      onTabChange: action,
      onCountAmountTabChange: action,
      onStatusTabChange: action,
      onPageSizeChange: action,
      setDetailsLoadingField: action,
      setDetailsDataField: action
    })
  }

  merchantsDetails = generateDetailsObject()
  mccDetails = generateDetailsObject()
  issuingBanksDetails = generateDetailsObject()
  cardTypesDetails = generateDetailsObject()
  paymentMethodsDetails = generateDetailsObject()
  acquisitionChannelsDetails = generateDetailsObject()

  shouldLoadData: boolean = false
  forceRefresh: boolean = false
  currentTab: DetailsType = 'merchants'

  currentMerchantsCATab: 'amount' | 'count' = 'amount'
  currentMerchantsStatusTab: 'all' | 'successful' | 'failed' | 'other' = 'all'

  currentMccCATab: 'amount' | 'count' = 'amount'
  currentMccStatusTab: 'all' | 'successful' | 'failed' | 'other' = 'all'

  currentIssuingBanksCATab: 'amount' | 'count' = 'amount'
  currentIssuingBanksStatusTab: 'all' | 'successful' | 'failed' | 'other' = 'all'

  currentCardTypesCATab: 'amount' | 'count' = 'amount'
  currentCardTypesStatusTab: 'all' | 'successful' | 'failed' | 'other' = 'all'

  currentPaymentMethodsCATab: 'amount' | 'count' = 'amount'
  currentPaymentMethodsStatusTab: 'all' | 'successful' | 'failed' | 'other' = 'all'

  currentAcquisitionChannelsCATab: 'amount' | 'count' = 'amount'
  currentAcquisitionChannelsStatusTab: 'all' | 'successful' | 'failed' | 'other' = 'all'

  get amountTableColumns() {
    switch (this.currentTab) {
      case 'mcc':
        return generateMCCAmountTableColumns()
      default:
        return generateAmountTableColumns()
    }
  }
  get countTableColumns() {
    switch (this.currentTab) {
      case 'mcc':
        return generateMCCCountTableColumns()
      default:
        return generateCountTableColumns()
    }
  }

  public init() {
    reaction(
      () => {
        const {
          dateStore: { startDate, endDate },
          merchantTradeName,
          acquisitionChannelId
        } = this.filterStore
        return {
          startDate,
          endDate,
          merchantTradeName,
          acquisitionChannelId,
          currency: this.currency
        }
      },
      ({ startDate, endDate, merchantTradeName, acquisitionChannelId, currency }) => {
        // if user is not on current page, do not make requests to backend on currency change
        if (
          window.location.pathname !==
          Routes[
            this.acquiringType === 'dna'
              ? 'TRANSACTIONS_DNA_ACQUIRING_ANALYTICS'
              : 'TRANSACTIONS_OPTOMANY_CHECKOUT_ANALYTICS'
          ]
        )
          return
        if (startDate && endDate && merchantTradeName && acquisitionChannelId && this.shouldLoadData && currency) {
          // reset data has been loaded flags
          this.merchantsDetails = resetDataHasBeenLoadedFlags(this.merchantsDetails)
          this.mccDetails = resetDataHasBeenLoadedFlags(this.mccDetails)
          this.issuingBanksDetails = resetDataHasBeenLoadedFlags(this.issuingBanksDetails)
          this.cardTypesDetails = resetDataHasBeenLoadedFlags(this.cardTypesDetails)
          this.paymentMethodsDetails = resetDataHasBeenLoadedFlags(this.paymentMethodsDetails)
          this.acquisitionChannelsDetails = resetDataHasBeenLoadedFlags(this.acquisitionChannelsDetails)

          // reset all table current page props
          this.merchantsDetails = resetCurrentPageInDetailsData(this.merchantsDetails)
          this.mccDetails = resetCurrentPageInDetailsData(this.mccDetails)
          this.issuingBanksDetails = resetCurrentPageInDetailsData(this.issuingBanksDetails)
          this.cardTypesDetails = resetCurrentPageInDetailsData(this.cardTypesDetails)
          this.paymentMethodsDetails = resetCurrentPageInDetailsData(this.paymentMethodsDetails)
          this.acquisitionChannelsDetails = resetCurrentPageInDetailsData(this.acquisitionChannelsDetails)

          this.loadData()
        }
      },
      { delay: 5, fireImmediately: true }
    )
  }

  public refreshTable() {
    this.forceRefresh = true
    this.setShouldLoadData(true)
  }

  // TODO find an elegant solution for loading data on radio button change or RENAME
  setShouldLoadData = (value: boolean) => {
    this.shouldLoadData = value
    if (value) {
      this.loadData()
    }
  }

  get currency() {
    return this.configStore.currency.type
  }

  get isSolidGate() {
    return isPartner() && storage.get('acquisition_channel') === 'solidgate'
  }

  get summaryRequestParams(): AnalyticsDetailedSummaryRequest {
    const {
      dateStore: { startDate, endDate },
      merchantTradeName
    } = this.filterStore
    return {
      from: startDate.format(),
      to: endDate.format(),
      merchant: merchantTradeName,
      acquirer: this.isSolidGate ? 'paynetics_eu' : this.acquiringType,
      currency: this.currency,
      acquisitionChannel: this.acquisitionChannelId
    }
  }

  get acquisitionChannelId() {
    if (isPartner() || this.filterStore.acquisitionChannelId === 'all') return undefined
    return this.filterStore.acquisitionChannelId
  }

  get fetchDataRequest() {
    switch (this.currentTab) {
      case 'merchants': {
        return fetchMerchantsSummary
      }
      case 'mcc': {
        return fetchMccSummary
      }
      case 'issuingBanks': {
        return fetchIssuingBanksSummary
      }
      case 'cardTypes': {
        return fetchCardTypesSummary
      }
      case 'paymentMethods': {
        return fetchPaymentMethodsSummary
      }
      case 'acquisitionChannels': {
        return fetchAcquisitionChannelsSummary
      }
    }
  }

  /**
   * The main data fetcher function. The function first checks what tab is currently active, then it checks which
   * tab of the amount/count tab pane is active. Then if the new data has not been fetched for the given
   * tabs combination, the function fetches the data
   */
  loadData = async () => {
    if (!this.filterStore.merchantTradeName) return
    const detailsType = this.currentTab
    const upperCaseDetailsType = capitalize(detailsType)
    const orderBy = this['current' + upperCaseDetailsType + 'CATab']
    const orderByUpperCase = capitalize(orderBy)
    const status = this['current' + upperCaseDetailsType + 'StatusTab']
    const statusUpperCase = capitalize(status)
    const dataFieldName = orderBy + statusUpperCase + 'Data'
    const totalFieldName = orderBy + statusUpperCase + 'Total'
    const loadingFieldName = 'isLoading' + orderByUpperCase + statusUpperCase + 'Data'
    const page = this[this.currentTab + 'Details'][orderBy + statusUpperCase + 'CurrentPage']
    const size = this[this.currentTab + 'Details'][orderBy + statusUpperCase + 'PageSize']
    const requestUniqueFieldName = getRequestUniqueFieldName(this.currentTab)
    const alternativeUniqueFieldName = this.currentTab

    const hasLoadedData = this[detailsType + 'Details']['hasLoaded' + orderByUpperCase + statusUpperCase + 'Data']
    const setDataFunction = detailsType === 'paymentMethods' ? this.setPaymentMethodsData : this.setDetailsDataField

    if (this.forceRefresh || !hasLoadedData) {
      this.loadBreakdown(
        { ...this.summaryRequestParams, orderBy, page, size, status },
        requestUniqueFieldName,
        data => {
          setDataFunction(data, detailsType, dataFieldName, totalFieldName)
        },
        loading => {
          this.setDetailsLoadingField(loading, detailsType, loadingFieldName)
        },
        this.fetchDataRequest,
        alternativeUniqueFieldName
      )
    }

    return
  }

  ////////// COMMON LOAD BREAKDOWN DATA ////////////
  loadBreakdown = async (
    params: AnalyticsDetailedSummaryRequest,
    uniqueFieldName,
    setData,
    setLoadingData,
    fetchFunction,
    alternativeUniqueName?
  ) => {
    try {
      setLoadingData(true)
      const { err, result } = await fetchFunction(params)
      if (err) {
        errorLog(`Error loading ${uniqueFieldName} ${params.orderBy} data:`, err)
        runInAction(() => {
          setLoadingData(false)
        })
        return
      }

      runInAction(() => {
        const data = this.processDataMethod(
          uniqueFieldName === 'acquisitionChannel' ? this.modifyResult(result) : result,
          uniqueFieldName,
          params.orderBy,
          params.page,
          params.size,
          alternativeUniqueName
        )

        setData(data)
        setLoadingData(false)
      })
    } catch (err) {
      errorLog(`Error loading ${uniqueFieldName} ${params.orderBy} data`, err)
      runInAction(() => {
        setLoadingData(false)
      })
      return
    } finally {
      runInAction(() => (this.forceRefresh = false))
    }
  }

  onTabChange = tab => {
    this.currentTab = tab
    this.loadData()
  }

  /**
   * A callback that is triggered when the amount/count tab is changed
   * We need to fetch data if any of the filter items is changed
   * @param tab - amount/count tab
   */
  onCountAmountTabChange = (tab: 'amount' | 'count') => {
    switch (this.currentTab) {
      case 'merchants': {
        this.currentMerchantsCATab = tab
        break
      }
      case 'mcc': {
        this.currentMccCATab = tab
        break
      }
      case 'cardTypes': {
        this.currentCardTypesCATab = tab
        break
      }
      case 'issuingBanks': {
        this.currentIssuingBanksCATab = tab
        break
      }
      case 'paymentMethods': {
        this.currentPaymentMethodsCATab = tab
        break
      }
      case 'acquisitionChannels': {
        this.currentAcquisitionChannelsCATab = tab
        break
      }
    }
    this.loadData()
  }

  /**
   * A callback that is triggered when the status tab is changed
   * We need to fetch data if any of the filter items is changed
   * @param tab - selected status tab
   */
  onStatusTabChange = (tab: 'all' | 'successful' | 'failed' | 'other') => {
    const detailsTypeUpperCase = capitalize(this.currentTab)

    this['current' + detailsTypeUpperCase + 'StatusTab'] = tab
    this.loadData()
  }

  onPageSizeChange = (page: number, size: number) => {
    const detailsTypeUpperCase = capitalize(this.currentTab)
    const orderBy = this['current' + detailsTypeUpperCase + 'CATab']
    const orderByUpperCase = capitalize(orderBy)
    const status = this['current' + detailsTypeUpperCase + 'StatusTab']
    const statusUpperCase = capitalize(status)

    const previousPageSize = this[this.currentTab + 'Details'][orderBy + statusUpperCase + 'PageSize']
    let _page = page
    if (previousPageSize !== size) {
      _page = page
    }
    this[this.currentTab + 'Details'] = {
      ...this[this.currentTab + 'Details'],
      [orderBy + statusUpperCase + 'CurrentPage']: _page,
      [orderBy + statusUpperCase + 'PageSize']: size,
      ['hasLoaded' + orderByUpperCase + statusUpperCase + 'Data']: false
    }
    this.loadData()
  }

  setDetailsLoadingField = (loading, detailsType: DetailsType, fieldName) => {
    this[detailsType + 'Details'] = {
      ...this[detailsType + 'Details'], // ex: merchantsDetails
      [fieldName]: loading // ex: isLoadingAmountAllData
    }
  }

  setDetailsDataField = (data, detailsType: DetailsType, dataFieldName, totalFieldName) => {
    this[detailsType + 'Details'] = {
      ...this[detailsType + 'Details'], // ex: merchantsDetails
      [dataFieldName]: data.data, // ex: amountAllData
      [totalFieldName]: data.total, // ex: amountAllTotal
      ['hasLoaded' + capitalize(dataFieldName)]: true // ex: hasLoadedAmountAllData
    }
  }

  setPaymentMethodsData = (data, detailsType, dataFieldName, totalFieldName) => {
    const _data = {
      ...data,
      data: data.data.map(item => {
        return {
          ...item,
          name: PaymentMethods[item.name]
        }
      })
    }
    this.setDetailsDataField(_data, detailsType, dataFieldName, totalFieldName)
  }

  processData = (
    result: AnalyticsDetailedSummaryResponse,
    uniqueFieldName,
    countAmount: string,
    currentPage?: number,
    pageSize?: number,
    alternativeUniqueName?
  ) => {
    return {
      data: result.data.map((item, index) => {
        return {
          order: index + 1 + (currentPage - 1) * pageSize,
          key: index,
          average: item.count ? numeral(item.amount / item.count).format('0,0.00') : 0,
          name:
            item[uniqueFieldName] || (alternativeUniqueName && item[alternativeUniqueName]) || translations().noData,
          [countAmount]: countAmount === 'amount' ? numeral(item[countAmount]).format('0,0.00') : item[countAmount]
        }
      }),
      total: result.totalCount
    }
  }

  processMCCData = (
    result: AnalyticsDetailedSummaryResponse,
    uniqueFieldName,
    countAmount: string,
    currentPage?: number,
    pageSize?: number,
    alternativeUniqueName?
  ) => {
    return {
      data: result.data.map((item, index) => {
        return {
          order: index + 1 + (currentPage - 1) * pageSize,
          key: index,
          average: item.count ? numeral(item.amount / item.count).format('0,0.00') : 0,
          mcc: item.mcc,
          name:
            item[uniqueFieldName] || (alternativeUniqueName && item[alternativeUniqueName]) || translations().noData,
          [countAmount]: countAmount === 'amount' ? numeral(item[countAmount]).format('0,0.00') : item[countAmount]
        }
      }),
      total: result.totalCount
    }
  }

  get processDataMethod() {
    switch (this.currentTab) {
      case 'mcc':
        return this.processMCCData
      default:
        return this.processData
    }
  }

  modifyResult(result: AnalyticsDetailedSummaryResponse): AnalyticsDetailedSummaryResponse {
    return {
      totalCount: result.totalCount,
      data: result.data.map(item => this.processItem(item))
    }
  }

  processItem(item: EntitySummary) {
    const acquisitionChannelId = item['acquisitionChannel']
    const acquisitionChannel = this.filterStore.acquisitionChannels.find(a => a.id === acquisitionChannelId)
    if (acquisitionChannel) {
      return {
        ...item,
        acquisitionChannel: acquisitionChannel.name
      }
    }

    return item
  }

  generateTabsData = () => {
    const tabsData = [
      detailsDataToTabData('merchants', this.merchantsDetails),
      detailsDataToTabData('mcc', this.mccDetails),
      detailsDataToTabData('issuingBanks', this.issuingBanksDetails),
      detailsDataToTabData('cardTypes', this.cardTypesDetails),
      detailsDataToTabData('paymentMethods', this.paymentMethodsDetails)
    ]
    if (!isPartner()) tabsData.push(detailsDataToTabData('acquisitionChannels', this.acquisitionChannelsDetails))
    return tabsData
  }

  get tabsData(): TabData[] {
    return this.generateTabsData()
  }
}
