import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    title: string,
    titleRequired: string,
    dateBirth: string,
    dateBirthRequired: string,
    dateBirthInvalid: string,
    dateBirthAgeLimit: string,
    gender: string,
    genderRequired: string,
    name: string,
    nameRequired: string,
    middleName: string,
    middleNameRequired: string,
    surname: string,
    surnameRequired: string,
    houseName: string,
    houseNumber: string
    addressTitle: string,
    street: string,
    streetRequired: string,
    street2: string,
    city: string,
    cityRequired: string,
    cityInvalid: string,
    region: string,
    country: string,
    countryRequired: string,
    countryResidence: string,
    countryResidenceRequired: string,
    postCode: string,
    postCodeRequired: string,
    postCodeInvalid: string,
    nationality: string,
    nationalityRequired: string,
    emailAddress: string,
    emailAddressRequired: string,
    emailFormatIncorrect: string,
    telephoneNumber: string,
    telephoneNumberRequired: string,
    telephoneNumberWrongFormat: string,
    occupation: string,
    occupationRequired: string,
    placeBirth: string,
    placeBirthRequired: string,
    personalInfo: string
    contactInfo: string
    submit: string
    back: string
}>()

