import { Form, InputProps } from 'antd'
import React from 'react'
import { Input } from '~/code/components/Input'
import { NumberFormItemProps } from './props'
import { isNull } from '~/code/services'
import translations from './translations'

export const NumberFormItem: React.FC<NumberFormItemProps> = (props) => {
    const { isPositive = true, min, max, noZeroValue, ...rest } = props

    return <Form.Item
        {...rest}
        rules={[
            {
                validator: async (_, rawValue) => {
                    const value = Number(rawValue)
                    if (noZeroValue && String(rawValue) === '0') {
                        throw new Error(translations().noZeroValue)
                    }

                    if (!rawValue && String(rawValue) !== '0') {
                        throw new Error(translations().requiredMessage)
                    }
                    if (isPositive && value < 0) {
                        throw new Error(translations().positiveMessage)
                    }
                    if (!isNull(min) && !isNull(max) && (value < min || value > max)) {
                        throw new Error(translations().betweenMessage(min, max))
                    }
                    if (isNull(min) && !isNull(max) && value > max) {
                        throw new Error(translations().lessMessage(max))
                    }
                    if (!isNull(min) && isNull(max) && value < min) {
                        throw new Error(translations().greaterMessage(min))
                    }
                }
            }
        ]}
        validateTrigger={'onBlur'}
        getValueFromEvent={(v) => {
            const str = String(v || '')
            const dotIndex = str.indexOf('.')
            const cleanedStr = dotIndex < 0 ? str.replace(/\D/g, '') :
                str.substring(0, dotIndex).replace(/\D/g, '') + '.' + str.substring(dotIndex + 1).replace(/\D/g, '') 

            return cleanedStr
        }}
    >
        <NumberInput />
    </Form.Item>
}

const NumberInput: React.FC<InputProps & { onChange?(v: string): void }> = ({ onChange, onBlur, ...rest }) => <Input
    {...rest}
    onChange={(e) => {
        onChange && onChange(e.target.value)
    }}
    onBlur={(e) => {
        const v = e.target.value || ''
        const n = v && String(Number(v))
        if (v !== n) {
            onChange && onChange(n)
        }
        onBlur && onBlur(e)
    }}
/>

