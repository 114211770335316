import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Button, Checkbox, Col, Form, Modal, Row, Select } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useInjection } from 'dna-react-ioc'
import { StatementExportsModalPropsType } from '~/code/pages/Acquiring/pages/Statement/components/StatementExportsModal/StatementExportsModalPropsType'
import { StatementExportsModalStoreType } from '~/code/pages/Acquiring/pages/Statement/components/StatementExportsModal/StatementExportsModalStoreType'
import translations from './translations'
import styles from './StatementExportsModal.scss'

export const StatementExportsModalStoreSymbol = Symbol('StatementExportsModalStore')

export const StatementExportsModal = observer(({ transactionsStatementStore }: StatementExportsModalPropsType) => {
  const [form] = useForm()

  const {
    init,
    handleCancel,
    isUploading,
    options,
    exportTypeOptions,
    selectExportType,
    selectedExportType,
    selectOption,
    fields,
    isButtonDisabled,
    onExportsFormSubmit
  } = useInjection<StatementExportsModalStoreType>(StatementExportsModalStoreSymbol)

  useEffect(() => {
    init(transactionsStatementStore)
  }, [])

  const onCancel = () => {
    form.resetFields()
    handleCancel()
  }

  const selectType = (value: string) => {
    form.setFieldsValue({ fields: [] })
    selectExportType(value)
  }

  const onFinish = async () => {
    await onExportsFormSubmit()
    await onCancel()
  }

  const renderCheckboxGroup = () => {
    return (
      <Checkbox.Group onChange={selectOption}>
        <Row>
          {Object.values(options).map((option: { value: string; label: string }) => {
            return (
              <Col span={8} key={option.value}>
                <Checkbox value={option.value}>{option.label}</Checkbox>
              </Col>
            )
          })}
        </Row>
      </Checkbox.Group>
    )
  }

  return (
    <Modal
      open={transactionsStatementStore.isExportModalOpen}
      onCancel={onCancel}
      title={translations().title}
      footer={
        <div>
          <Button className={styles.cancel} onClick={onCancel}>
            {translations().cancel}
          </Button>
          <Button disabled={isButtonDisabled} loading={isUploading} type={'primary'} onClick={form.submit}>
            {translations().ok}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        initialValues={{
          columnsOption: 'all',
          fields: Object.keys(options)
        }}
        onFinish={onFinish}
      >
        <Form.Item label={translations().columns} name={'columnsOption'} shouldUpdate>
          <Select value={selectedExportType} options={exportTypeOptions} onChange={selectType} />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            return selectedExportType === 'custom' ? (
              <Form.Item name={'fields'}>{renderCheckboxGroup()}</Form.Item>
            ) : null
          }}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            const labels = (fields && fields.map(field => options[field].label)) || []
            return <div>{labels && labels.length ? labels.join(', ') : ''}</div>
          }}
        </Form.Item>
      </Form>
    </Modal>
  )
})
