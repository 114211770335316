import translations from './translations'

translations.add('en', {
  commonInfo: 'Common Info',
  companyName: 'Company Name',
  commentMinMsg: 'Comment must be at least 2 characters',
  commentRequired: 'Reason is required',
  commentLabel: 'Reason',
  complete: 'Complete'
})
