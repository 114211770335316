import { inject, injectable } from 'inversify'
import { makeObservable, action, observable, runInAction } from 'mobx'
import { message } from 'antd'
import { log } from 'dna-common'
import { AppStore } from '../AppStore'
import { IStoresDossierV2Store } from '~/code/pages/MerchantsManagement/components/Stores/models'
import { StoresDossierV2StoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores'
import { ISettlementsStatusModalStore } from '~/code/pages/MerchantsManagement/components/Stores/components/SettlementsStatusModal/ISettlementsStatusModalStore'
import {
  DescriptorType,
  UpdatedContractType,
  UniqueContractDataType
} from '~/code/pages/MerchantsManagement/components/Stores/components/SettlementsStatusModal/models'
import { PhysicalStoreAttributesType, HoldTableType, HoldContractDossierV2ReqType } from './models/merchant-dossier-v2'
import { startProcessHoldContractDossierV2 } from './services/fetchers'
import { stringType, jsonType, processStartStatus } from './constants'
import translations from './translations'

@injectable()
export class SettlementsStatusModalStore implements ISettlementsStatusModalStore {
  storesDossierV2Store: IStoresDossierV2Store
  holdTableData: HoldTableType[] = []
  isSubmitButtonLoading: boolean = false
  constructor(@inject(StoresDossierV2StoreSymbol) storesDossierV2Store: IStoresDossierV2Store) {
    this.storesDossierV2Store = storesDossierV2Store
    makeObservable(this, {
      holdTableData: observable,
      isSubmitButtonLoading: observable,
      closeSettlementsStatusModal: action.bound,
      updateHoldStatus: action.bound,
      clearSettlementsStatusTableData: action.bound,
      updateSettlementsStatusTableData: action.bound,
      setInitialSettlementsStatusTableData: action.bound
    })
  }

  closeSettlementsStatusModal() {
    this.holdTableData = []
    this.storesDossierV2Store.closeSettlementsStatusModal()
  }

  getEmptyDescriptor(): DescriptorType {
    return {
      mid: '',
      descriptor: ''
    }
  }

  defineMidDescriptor = (contract: PhysicalStoreAttributesType): DescriptorType => {
    if (!contract || !contract.terminals || contract.terminals.length === 0) {
      return this.getEmptyDescriptor()
    }
    const filteredTerminals = contract.terminals.find(terminal => terminal.terminal.contractId === contract.contract.id)
    if (filteredTerminals) {
      return {
        mid: filteredTerminals.terminal.merchantId || '',
        descriptor: filteredTerminals.terminal.descriptor || ''
      }
    }
    return this.getEmptyDescriptor()
  }

  clearSettlementsStatusTableData(): void {
    this.holdTableData = []
  }

  updateSettlementsStatusTableData(data: HoldTableType[]) {
    this.holdTableData = data
  }

  getInitialSettlementsStatusTableData(): HoldTableType[] {
    const uniqueContractData: UniqueContractDataType = {}
    const tableData: HoldTableType[] = []
    const uniqueMIDs = new Set<string>()
    const { stores } = this.storesDossierV2Store.companyFullDossierV2?.processing
    stores.forEach(storeInfo => {
      storeInfo.contracts.forEach(contract => {
        const { mid, descriptor } = this.defineMidDescriptor(contract)
        const { contractNumber, isActive } = contract.contract || {}
        const contractData = uniqueContractData[contractNumber]
        if (contractData && !uniqueMIDs.has(mid)) {
          contractData.mid.push(mid)
          contractData.descriptor.push(`${mid}(${descriptor})`)
          contractData.isActive = isActive
          contractData.comment = ''
          uniqueMIDs.add(mid)
        } else if (!contractData) {
          uniqueContractData[contractNumber] = {
            mid: [mid],
            contractNumber,
            descriptor: [`${mid}(${descriptor})`],
            isActive,
            comment: ''
          }
          uniqueMIDs.add(mid)
        }
      })
    })

    for (const [key, value] of Object.entries(uniqueContractData)) {
      const { enabled, reason, balance, holdInfo, comment, team } = this.storesDossierV2Store.isHoldEnabled(key)
      tableData.push({
        key,
        contract: value.contractNumber,
        mids: value.descriptor.join(', '),
        status: enabled,
        team: enabled ? '' : team,
        reason: reason
          ? this.storesDossierV2Store.settlementsReasonDictionarty.find(reasonItem => String(reasonItem.id) === reason)
              ?.value
          : null,
        balance,
        holdInfo,
        comment
      })
    }

    return tableData
  }

  setInitialSettlementsStatusTableData(): void {
    const tableData = this.getInitialSettlementsStatusTableData()
    this.storesDossierV2Store.setHoldTableData(tableData)
    this.holdTableData = tableData
  }

  getUpdatedContracts(): UpdatedContractType[] {
    const changedContractList = []
    const initialContractsData = this.getInitialSettlementsStatusTableData()
    const updatedContractsData = this.holdTableData
    updatedContractsData.forEach(updatedContract => {
      const initialContract = initialContractsData.find(
        initialContract => initialContract.contract === updatedContract.contract
      )
      if (initialContract && initialContract.status !== updatedContract.status) {
        const { contract, status, mids, balance, holdInfo, comment } = updatedContract
        changedContractList.push({
          contractNumber: contract,
          active: status,
          mid: mids,
          balance,
          holdInfo,
          comment
        })
      }
    })
    return changedContractList
  }

  createUpdateHoldStatus(
    dsrId: number,
    companyNumber: string,
    companyName: string,
    updatedContracts: UpdatedContractType[]
  ): HoldContractDossierV2ReqType {
    return {
      variables: {
        dossierId: {
          value: dsrId.toString(),
          type: stringType
        },
        companyNumber: {
          value: companyNumber,
          type: stringType
        },
        companyName: {
          value: companyName,
          type: stringType
        },
        contracts: {
          value: JSON.stringify(updatedContracts),
          type: jsonType
        },
        initiator: {
          value: AppStore.authStore.email,
          type: stringType
        },
        processStatus: {
          value: processStartStatus,
          type: stringType
        }
      }
    }
  }

  async updateHoldStatus(): Promise<void> {
    const updatedContracts = this.getUpdatedContracts()
    if (updatedContracts.length === 0) {
      message.error(translations().errorNoChangesSettlementStatus)
      return
    }
    try {
      runInAction(() => {
        this.isSubmitButtonLoading = true
      })
      const { dsrId, companyNumber, companyName } = this.storesDossierV2Store.companyFullDossierV2?.mainInfo
      if (!dsrId) {
        message.error(translations().dsrIdIsEmpty)
        return
      }

      const updateHoldStatusRequestData = this.createUpdateHoldStatus(
        dsrId,
        companyNumber,
        companyName,
        updatedContracts
      )

      const { status, error, result } = await startProcessHoldContractDossierV2(updateHoldStatusRequestData)

      if (status !== 200 || error) {
        message.error(error.message || translations().errStartingHoldProcess)
      } else {
        message.success(result.message || translations().applicationSuccessful)
        this.storesDossierV2Store.closeSettlementsStatusModal()
      }
    } catch (error) {
      log(error)
      message.error(error.message || translations().errStartingHoldProcess)
    } finally {
      runInAction(() => {
        this.isSubmitButtonLoading = false
      })
    }
  }
}
