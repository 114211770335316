import { DocumentItem } from '../../models'
import translations from './translations'

export const availableDocumentTypes: DocumentItem[] = [
  {
    value: 'bankStatement',
    label: translations().bankStatement
  },
  {
    value: 'processingStatement',
    label: translations().processingStatement
  },
  {
    value: 'other',
    label: translations().other
  }
]
