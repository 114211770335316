import React from 'react'
import { BPMProcessDossierV2 } from '../shared'
import { columns } from './constants'

export const TerminalIssueRequestsStoreSymbol = Symbol('TerminalIssueRequestsStore')
export const TerminalIssueRequestsRangePickerStoreSymbol = Symbol('TerminalIssueRequestsRangePickerStore')
export const TerminalIssueRequestsMerchantSelectStoreSymbol = Symbol('TerminalIssueRequestsMerchantSelectStore')

export const TerminalIssueRequests = () => (
    <BPMProcessDossierV2
        BPMProcessesStoreSymbol={TerminalIssueRequestsStoreSymbol}
        BPMProcessesRangePickerStoreSymbol={TerminalIssueRequestsRangePickerStoreSymbol}
        BPMProcessesMerchantSelectStoreSymbol={TerminalIssueRequestsMerchantSelectStoreSymbol}
        columns={columns}
    />
)
