import { deleteWithAuth, getWithAuth, postWithAuth } from '~/code/services'
import {
  ChangeBankDetailsProcessDetailsType,
  OpenBankingResultsType,
  DocumentType,
  TerminateApplicationRequestType,
  TerminateApplicationResponseType,
  AdditionalInfoSubmitType
} from '../models'

export const getProcessDetails = (processId: string) =>
  getWithAuth<ChangeBankDetailsProcessDetailsType>(`/bpm/change-bank-details/${processId}/details`)

export const openBankingResultsCheck = (id: string, accNum: string, sortCode: string) =>
  getWithAuth<OpenBankingResultsType>(
    `/api/processes-api/change-bank-details/${id}/verifications/ecospend?bankAccount=${accNum}&sortCode=${sortCode}`
  )

export const completeApplication = (data: { documents: DocumentType[] }, id: string) =>
  postWithAuth<any>(`/api/processes-api/change-bank-details/${id}/action/complete`, data)

export const terminateApplication = (data: TerminateApplicationRequestType) =>
  deleteWithAuth<TerminateApplicationResponseType>('/api/processes-api/change-bank-details', data)

export const uploadAdditionalDoc = (processId: string, params: AdditionalInfoSubmitType) =>
  postWithAuth<any>(`/api/processes-api/change-bank-details/${processId}/action/additionalInfo`, params)
