import React from 'react'
import translations from './translations'
import { FileType } from '~/code/models/FileType'

translations.add('en', {
  download: 'Download',
  cancel: 'Cancel',
  title: 'PDF Statement Download',
  text: (
    <>
      Please select the desired Settlement Period and click Download. <br /> <br />
      <strong>Disclaimer:</strong> Statements can be downloaded for a completed month from the 1st day of the new month.
      From August 2023 onwards, they'll be in the latest style and format, and prior to August 2023, they'll remain in
      the old design.
    </>
  )
})
