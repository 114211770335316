import translations from './translations'

translations.add('en', {
  motoCnp: 'MOTO/CNP',
  zashEpos: 'ZashEPOS',
  contractType: 'Contract type',
  required: 'Required',
  subscriptionServicePeriod: 'Subscription Service Period (in months)',
  freeSubscriptionPeriod: 'Free Subscription Period (in months)',
  byDNA: 'Chargeable by DNA',
  byPaytek: 'Chargeable by Paytek',
  posModel: 'POS Model',
  posModelRequired: 'pos model is required',
  posBundles: 'Bundles',
  byOptomany: 'Chargeable by Optomany',

  title: 'Assign products',
  pos: 'POS',
  webSiteRequired: 'Store WebSite required',
  posDeliveryInstructionsRequired: 'Pos Delivery Instructions Required',
  posDeliveryAddressRequired: 'Pos Delivery Address Required',
  productPlaceholder: 'Select product',
  deviceAmountPlaceholder: 'Enter amount of devices',
  posQuantity: 'Pos quantity',
  posDeliveryAddress: 'Pos Delivery Address',
  posDeliveryAddressPlaceholder: 'Enter Pos Delivery Address',
  manual: 'Manual',
  sameAsCompany: 'Same As Company Address',
  sameAsStore: 'Same As Store Address',
  deliveryInstructions: 'Delivery Instructions',
  deliveryInstructionsPlaceholder: 'Enter pos delivery instructions',
  deliveryContactNumber: 'Contact number for delivery',
  validateBankAccount: 'Please validate bank account',
  addProduct: 'Add this product for the store',
  cityLabel: 'Town/City',
  cityRequired: 'town/city is required',
  cityInvalid: 'only alphanumeric characters are allowed',
  countryLabel: 'Country',
  countryRequired: 'country is required',
  regionLabel: 'County',
  postalCodeLabel: 'Postcode',
  postalCodeRequired: 'postcode is required',
  postalCodeInvalid: 'invalid postcode',
  addressLine1Label: 'Address line 1',
  addressLine1Required: 'address line 1 is required',
  addressLine2Label: 'Address line 2 ',
  addressLine2Required: 'address line 2 is required',
  zashDigital: 'Zash Digital Ordering',
  posCountError: 'Pos terminals quantity must be in 1 - 100 range',
  posCountRequired: 'pos count is required',
  feeSubscriptionPerMonth: 'Subscription Fee Per Month',
  terminalCost: 'Terminal Cost - per terminal',
  notZero: 'Value cannot be negative or zero',
  chargeType: 'Charge type',
  contactPerson: 'Contact person',
  personTitle: 'Title',
  titleRequired: 'Title required',
  name: 'Name',
  nameRequired: 'Name required',
  middleName: 'Middle name',
  surname: 'Surname',
  surnameRequired: 'Surname required',
  emailAddress: 'Email address',
  emailAddressRequired: 'email address is required',
  emailFormatIncorrect: 'invalid email',
  telNumber: 'Telephone number'
})
