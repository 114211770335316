import { inject, injectable } from 'inversify'
import { action, makeObservable, observable } from 'mobx'
import { STORE_TYPES } from '~/code/constants/StoreTypes'
import { PeriodType } from '~/code/models'
import { StatisticsRangePickerStoreSymbol } from '~/code/pages'
import { HandbooksStoreInjectable } from '../HandbooksStoreInjectable'
import { RangePickerStoreInjectable } from '../RangePickerStoreInjectable'

type FilterType = 'acquisitionChannel' | 'productType' | 'approvalType' | 'segmentCategory' | 'segmentSubCategory'

@injectable()
export class StatisticsFiltersStore {
  dateStore: RangePickerStoreInjectable
  handbooksStore: HandbooksStoreInjectable

  filters: Record<FilterType, string | null> = {
    acquisitionChannel: null,
    productType: null,
    approvalType: null,
    segmentCategory: null,
    segmentSubCategory: null
  }

  constructor(
    @inject(StatisticsRangePickerStoreSymbol) rangePickerStoreInjectable: RangePickerStoreInjectable,
    @inject(STORE_TYPES.HandbooksStore) handbooksStoreInjectable: HandbooksStoreInjectable
  ) {
    this.dateStore = rangePickerStoreInjectable
    this.dateStore.setPeriod(PeriodType.Yesterday)
    this.handbooksStore = handbooksStoreInjectable

    makeObservable(this, {
      filters: observable,
      setFilter: action
    })
  }

  init = () => {
    this.handbooksStore.loadProductTypes()
    this.handbooksStore.loadApprovalTypes()
    this.handbooksStore.loadStartApplicationAcquisitionChannels()
    this.handbooksStore.loadBusinessCategories()
    this.handbooksStore.loadBusinessSubCategories()
  }

  setFilter = (key: FilterType, value: string) => {
    this.filters[key] = value
  }
}
