import { TranslationBuilder } from '~/code/components/Translation'
import { FileType } from '~/code/models/FileType'

export default TranslationBuilder.create<{
    download: string
    selectMerchantOrAcquisitionChannel: string
    fileTypes: Record<FileType, string>,
    selectSpecificMerchantFirst: string
}>()

