import React from 'react'
import styles from './SectionHeader.scss'
import { Button, Popover } from 'antd'
import { EditOutlinedBlue } from '~/assets/icons'
import { SectionHeaderPropsType } from './props'
import translations from './translations'

export const SectionHeader = ({ title, onClick, isDocusignSuccessfully }: SectionHeaderPropsType) => {
  return (
    <div className={styles.sectionHeader}>
      {title}
      {onClick && (
        <Popover content={isDocusignSuccessfully ? translations().docusignSignedText : null}>
          <div className={styles.editIcon}>
            <Button disabled={isDocusignSuccessfully} onClick={onClick} type='text' icon={<EditOutlinedBlue />} />
          </div>
        </Popover>
      )}
    </div>
  )
}
