import React from 'react'
import { Button } from 'antd'
import { BalanceTableMidCell } from '../components/BalanceTableMidCell'
import { BalancesType } from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v2'
import translations from '../translations'
import styles from '../Balances.scss'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'

export const columnsBalances = (adjustmentFn: (status: boolean, mid: string) => void, isSubsidiary?: boolean) => {
  const columns = [
    {
      title: translations().contractNumber,
      dataIndex: 'ContractNumber',
      key: 'ContractNumber'
    },
    {
      title: translations().name,
      dataIndex: 'ContractName',
      key: 'ContractName'
    },
    {
      title: translations().status,
      dataIndex: 'StatusName',
      key: 'StatusName'
    },
    {
      title: translations().mid,
      dataIndex: 'Mid',
      key: 'Mid',
      render: (value: string) => <BalanceTableMidCell mids={value} />
    },
    {
      title: translations().currentBalance,
      dataIndex: 'CurrentBalance',
      key: 'CurrentBalance',
      render: (val: number) => <span className={val > 0 ? styles.positiveBalance : styles.negativeBalance}>{val}</span>
    },
    {
      title: translations().currency,
      dataIndex: 'Currency',
      key: 'Currency'
    },
    {
      title: translations().contractOpenDate,
      dataIndex: 'DateOpen',
      key: 'DateOpen'
    },
    {
      title: translations().adjust,
      dataIndex: 'Adjust',
      key: 'Adjust',
      width: 130,
      render: (value: undefined, row: BalancesType) => {
        return (
          <Button disabled={isSubsidiary} type='primary' onClick={() => adjustmentFn(true, row.Mid)}>
            {translations().adjust}
          </Button>
        )
      }
    }
  ]

  if (!hasPermissions([PermissionMap.merchants.processes.adjustment.read])) {
    return columns.filter(column => column.key !== 'Adjust')
  }

  return columns
}
