import { CopyOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd'
import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import { OfficerProfile, PCSProfile } from 'startapp/models'
import { isCompanyOfficerValid, isCompanyPCSValid, isPCSCorporate } from 'startapp/services'
import { StartApplicationStoreContext } from 'startapp/StartApplicationStore'
import { OfficersPopoverProps } from './props'
import styles from './OfficersPopover.scss'
import translations from './translations'

export const OfficersPopover = observer((props: OfficersPopoverProps) => {
    const { onSelect, className } = props
    const store = useContext(StartApplicationStoreContext)
    const { directors, shareholders, signatories, companyType } = store.dataStore
    const [ isVisible, setVisible ] = useState(false)

    const validDirectors = directors.filter((item) => isCompanyOfficerValid(item))
    const validShareholders = shareholders.filter((item) => isCompanyPCSValid(item) && !isPCSCorporate(item))
    const validSignatories = signatories.filter((item) => isCompanyOfficerValid(item))

    const select = (officer: OfficerType) => {
        setVisible(false)
        onSelect(officer)
    }

    if (companyType !== 'company' || (validDirectors.length === 0 && validShareholders.length === 0 && validSignatories.length === 0)) {
        return null
    }

    const Content = () => {
        return <div>
            <List title={translations().shareholders} officers={validShareholders} onSelect={select} />
            <List title={translations().directors} officers={validDirectors} onSelect={select} />
            <List title={translations().authorisedSignatories} officers={validSignatories} onSelect={select} />
        </div>
    }

    return <Popover
        content={<Content />}
        open={isVisible}
        className={className}
        trigger={['click']}
        onOpenChange={setVisible}
    >
        <Button icon={<CopyOutlined />}>{translations().fillInDataFrom}</Button>
    </Popover>
})


type OfficerType = OfficerProfile | PCSProfile
type ListProps = { title: string, officers: (OfficerType)[], onSelect(officer: OfficerType): void }
const List = (props: ListProps) => {
    const { officers, title, onSelect } = props

    if (officers.length === 0) {
        return null
    }

    return <>
        <h4>{title}</h4>
        <ul className={styles.list}>
            {officers.map((item) => {
                const { nameElements: { forename, surname } } = item
                return <li
                    key={item.id}
                    className={styles.listItem}
                    onClick={() => onSelect(item)}
                >{`${forename} ${surname}`}</li>
            })}
        </ul>
    </>
}
