export interface TransactionsBreakdown {
    labels: string[]
    datasets: { data: number[] }[]
    hasLoaded?: boolean
}

export const generateTransactionsBreakdown = (): TransactionsBreakdown => {
    return {
        labels: [],
        datasets: [],
        hasLoaded: false
    }
}

export interface TransactionsInterval {
    hour: TransactionsBreakdown
    day: TransactionsBreakdown
    week: TransactionsBreakdown
    month: TransactionsBreakdown
    year: TransactionsBreakdown
}

export const generateTransactionsInterval = (): TransactionsInterval => {
    return {
        hour: generateTransactionsBreakdown(),
        day: generateTransactionsBreakdown(),
        week: generateTransactionsBreakdown(),
        month: generateTransactionsBreakdown(),
        year: generateTransactionsBreakdown()
    }
}

export interface TransactionsStatus {
    all: TransactionsInterval
    successful: TransactionsInterval
    failed: TransactionsInterval
    other: TransactionsInterval
    isLoading: boolean
}

export const generateTransactionsStatus = (): TransactionsStatus => {
    return {
        all: generateTransactionsInterval(),
        successful: generateTransactionsInterval(),
        failed: generateTransactionsInterval(),
        other: generateTransactionsInterval(),
        isLoading: false
    }
}

export interface TransactionsLineChartData {
    amount: TransactionsStatus
    count: TransactionsStatus
}

export const generateTransactionsLineChartData = (): TransactionsLineChartData => {
    return {
        amount: generateTransactionsStatus(),
        count: generateTransactionsStatus()
    }
}