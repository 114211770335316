import React from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { Card } from 'antd'
import { useInjection } from 'dna-react-ioc'

import { Page } from '~/code/pages'
import { MerchantSelect } from '~/code/components'
import { AnalyticsStoreSymbol, MerchantSelectStoreSymbol } from '~/code/stores/RouterStore'
import { YearsComparison } from '../Analytics/components'
import { ITotalAnalyticsStore } from './ITotalAnalyticsStore'
import translations from './translations'
import styles from './styles.scss'

export const TotalAnalytics = observer(() => {
    const store = useInjection<ITotalAnalyticsStore>(AnalyticsStoreSymbol.dna.total)

    return <Page>
        <Card className={styles.marginBottom}>
            <MerchantSelect storeSymbol={MerchantSelectStoreSymbol.analytics.dnaTotal} />
        </Card>

        <YearsComparison
            title={translations().title}
            loading={store.isLoadingYearsComparison}
            data={ store.yearsComparisonData && toJS(store.yearsComparisonData) }
            merchant={store.merchantName}
            year1={store.yearsComparisonYear1}
            year2={store.yearsComparisonYear2}
            onYearsChange={(year1, year2) => {
                store.changeYearsComparisonYears(year1, year2)
            }}
            currencySymbol={store.configStore.currency.symbol}
        />
    </Page>
})
