import { CountryInfoType, OfficerAddress } from '.'
import { BankInfo } from './BankInfo'
import { CompanyType } from './company/CompanyType'
import { AccountType } from './complete/AccountType'
import { BankDetailsType } from './verification'

export class CompanyProfile {
  relationshipType?: string = ''
  companyName?: string = ''
  companyTradeName?: string = ''
  companyNumber?: string = ''
  dateOfCreation?: string = ''
  companyAddress?: OfficerAddress
  registeredOfficeAddress?: OfficerAddress
  companyWebsite?: string = ''
  employeesNumber?: string = undefined
  emailAddress?: string = ''
  telephoneNumber?: string = ''
  bankAccountType?: AccountType
  bankAccountNumber?: string = ''
  sortCode?: string = ''
  accountName?: string = ''
  bankName?: string = ''
  companyType?: CompanyType | undefined
  directorsPartnersCount?: number | undefined
  charityNumber?: number | undefined
  charityComission?: number | undefined
  merchantCategoryCode?: string
  merchantCategoryCodeDescription?: string
  directDebitAccount?: BankInfo
  directDebitBankDetails?: BankDetailsType
  subscriptionServicePeriod?: string
  freeSubscriptionPeriod?: string
  terminalType?: string
  chargeType?: string
  onboardingCountryInfo?: CountryInfoType
  comments?: string
  otherDescription?: string
  countryOfInitialRegistration?: string
  townOrCity?: string
  initialBoardingDate?: string
}
