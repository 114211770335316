import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  edit: string
  cancel: string
  submit: string
  formFields: {
    email: string
    firstName: string
    lastName: string
  }
  formErrors: {
    emailRequired: string
    enterValidEmail: string
    firstNameRequired: string
    lastNameRequired: string
  }
}>()
