import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  merchantsUploadError: string
  loginsUploadError: string
  loginSuccessfulAdded: string
  loginSuccessfulUpdated: string
  securitySettingsSuccessfulUpdated: string
  securitySettingsUpdateFailure: string
  loginAddFailure: string
  loginSuccessfulDelete: string
  loginDeleteFailure: string
  loginUpdateFailure: string
  merchantSuccessfulDelete: string
  specificPermissions: {
    'virtual_terminal.refunds': string
    'pos_payments.refunds': string
    'card_issuing.read': string
    'card_issuing.full': string
    'pos_amex_payments.read': string
    'pos_amex_payments.full': string
  }
}>()
