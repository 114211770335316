import translations from './translations'

translations.add('en', {
    checkTheData: 'Check the data',
    pleaseCheckBeforeSubmit: 'Please check and modify the information before submitting.',
    backToForm: 'Back to the form',
    submit: 'Submit',
    applicationHasFollowingErrors: 'The application has the following errors',
    generalInformationNotCompleted: 'General information is NOT completed',
    shareholdersNotCompleted: 'Shareholders is NOT completed',
    directorsNotCompleted: 'Directors is NOT completed',
    authorizedSignatoriesNotCompleted: 'Authorized Signatories is NOT completed',
    businessOwnerNotCompleted: 'Business owner is NOT completed',
    supportingDocumentsNotCompleted: 'Supporting Documents is NOT completed',
    submissionFailed: 'Submission Failed',
    applicationSubmittedSuccessfully: 'Application has been submitted successfully',
    applicationID: 'Application ID',
    inOrderToTrackApplication: 'In order to track the status of the application, please go to BPM Reports -> Onboarding Processes -> Processes',
    newApplication: 'New Application',
    onceYouClickSubmit: 'Once you click on "Submit", system will start data upload to launch the process.',
    pleaseWait2Mins: 'Please wait for about 2 minutes and close this browser tab.'
})
