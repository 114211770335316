import React from 'react'
import moment from 'moment'
import { Nowrap } from '~/code/components'
import { DATE_TIME_FORMAT } from '~/code/constants/date-time'
import { reviewTime } from '~/code/pages/BPMProcesses/Requests/utils'
import { renderStatus } from '../../../services'
import { SubmittedRequestsStatus } from '~/code/pages/BPMProcesses/Requests/components/SubmittedRequests/models'
import translations from '~/code/pages/BPMProcesses/Requests/components/SubmittedRequests/translations/translations'

export const columns = [
  {
    title: translations().startDate,
    dataIndex: 'startDate',
    key: 'startDate',
    render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : '')
  },
  {
    title: translations().merchant,
    dataIndex: 'companyName',
    key: 'companyName'
  },
  {
    title: translations().status,
    dataIndex: 'processStatus',
    key: 'processStatus',
    render: (status: SubmittedRequestsStatus) =>
      status ? (
        <Nowrap width={94} title={status}>
          {renderStatus(status)}
        </Nowrap>
      ) : (
        ''
      )
  },
  {
    title: translations().assignedGroup,
    dataIndex: 'assignedGroup',
    key: 'assignedGroup'
  },
  {
    title: translations().assignedUser,
    dataIndex: 'assignedUser',
    key: 'assignedUser'
  },
  {
    title: translations().reviewTime,
    dataIndex: 'reviewTime',
    key: 'reviewTime',
    render: (reviewMinutes: number) => reviewTime(reviewMinutes)
  },
  {
    title: translations().endDate,
    dataIndex: 'endDate',
    key: 'endDate',
    render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : '')
  },
  {
    title: translations().holdRelease,
    dataIndex: 'isContractHoldActivation',
    key: 'isContractHoldActivation',
    render: val => (val === 0 ? translations().hold : translations().release)
  }
]
