import { object, string } from 'yup'

const isEmptyOrMin3Len = (value: string) => !value || value.length >= 3
const isEmptyOrMin6Len = (value: string) => !value || value.length >= 6

export const MonitoringPosTransactionFilterScheme = object({
    terminalId: string().test('isTerminalIdValid', '', isEmptyOrMin6Len),
    transactionId: string().test('isTransactionIdValid', '', isEmptyOrMin3Len),
    cardMask: string().test('isCardMaskValid', '', isEmptyOrMin3Len),
    mid: string().test('isMIDValid', '', isEmptyOrMin3Len)
})
