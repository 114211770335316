import React from 'react'
import { FieldProps } from './props'
import styles from './Field.scss'

export const Field: React.FC<FieldProps> = ({ label, value, ...rest }) => (
  <div className={styles.Field} {...rest} role='text' aria-label={`${label} ${value}`}>
    <span className={styles.label}>{label}</span>
    <span className={styles.number}>{value}</span>
  </div>
)
