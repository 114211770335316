import { Button, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useInjection } from 'dna-react-ioc'

import { PermissionMap } from '~/code/constants/PermissionMap'
import { hasPermissions } from '~/code/services/auth'
import { PageHeader } from '~/code/components'

import { TTeammatesStore, TeammatesStoreSymbol } from './store'
import { TeammateMenuType, TeammateViewType } from './models'
import { TeammatesFilter } from './components/TeammatesFilter'
import { TeammatesTable } from './components/TeammatesTable'
import { TeammatesDrawer } from './components/TeammatesDrawer'
import { TeammatesSecurityDrawer } from './components/TeammatesSecurityDrawer'

import translations from './translations'
import styles from './styles.scss'

export * from './models'
export * from './store'

export const Teammates = observer(props => {
  const store = useInjection<TTeammatesStore>(TeammatesStoreSymbol)

  const tabList = [{ key: 'all', label: translations().all }]
  if (store.usersInvitedStore.viewData.length > 0) {
    tabList.push({ key: 'invited', label: translations().invited })
  }

  const onSelect = (key: TeammateMenuType, user: TeammateViewType) => {
    switch (key) {
      case 'delete':
        store.deleteUser(user)
        break
      case 'editUser':
        store.setSelectedUser(user)
        store.setIsUserDrawerOpen(true)
        break
      case 'editSecurity':
        store.setSelectedUser(user)
        store.setIsSecurityDrawerOpen(true)
        break
      case 'resend':
        store.resend(user.id)
        break
    }
  }

  const renderAdd = () => (
    <Button
      type='primary'
      onClick={() => {
        store.setIsUserDrawerOpen(true)
      }}
    >
      {translations().buttons.add}
    </Button>
  )

  return (
    <Spin spinning={store.isLoading}>
      <PageHeader
        tabList={tabList}
        tabActiveKey={store.activeTab}
        tabBarExtraContent={hasPermissions([PermissionMap.teammates.create]) && renderAdd()}
        onTabChange={key => store.setActiveTab(key as any)}
      />

      {store.isInvited ? (
        <div className={styles.content}>
          <TeammatesTable
            isInvited={true}
            users={store.usersInvitedStore.viewData}
            onSelectMenu={onSelect}
            pagination={{
              pageSize: store.usersStore.pageSize,
              current: store.usersInvitedStore.currentPage,
              total: store.usersInvitedStore.totalCount,
              showTotal: (total, range) => translations().totalPagination(range, total),
              onChange: (pageNumber, pageSize) => {
                store.usersInvitedStore.setPageSize(pageNumber, pageSize)
                store.usersInvitedStore.loadData({ pageNumber })
              },
              size: 'small'
            }}
          />
        </div>
      ) : (
        <>
          <TeammatesFilter
            roles={store.roles}
            statuses={store.statuses}
            authMethods={store.authMethods}
            values={store.filterValues}
            onChange={filterValues => store.setFilterValues(filterValues)}
          />

          <div className={styles.content}>
            <TeammatesTable
              isInvited={false}
              users={store.usersStore.viewData}
              onSelectMenu={onSelect}
              pagination={{
                pageSize: store.usersStore.pageSize,
                current: store.usersStore.currentPage,
                total: store.usersStore.totalCount,
                showTotal: (total, range) => translations().totalPagination(range, total),
                onChange: (pageNumber, pageSize) => {
                  store.usersStore.setPageSize(pageNumber, pageSize)
                  store.usersStore.loadData({ pageNumber })
                },
                size: 'small'
              }}
            />
          </div>
        </>
      )}

      <TeammatesDrawer
        isVisible={store.isUserDrawerOpen}
        teammate={store.selectedUser}
        roles={store.roles}
        onSubmit={async values => {
          await store.inviteOrUpdateUser(values)
          if (!values.id) {
            store.setActiveTab('invited')
          }
        }}
        onClose={() => store.onUserDrawerClose()}
      />

      <TeammatesSecurityDrawer
        open={store.isSecurityDrawerOpen}
        teammate={store.selectedUser}
        onSubmit={async values => {
          await store.updateUserSecurity(values)
        }}
        onClose={() => store.onSecurityDrawerClose()}
      />
    </Spin>
  )
})
