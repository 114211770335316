import React from 'react'
import { Form, Select } from 'antd'
import translations from './translations'
import { SMEFormFieldsPropsType } from './props'
import { SalesForceOppIDFieldItem } from '../SalesForceOppIDFieldItem'
import { AcqChannelFieldItem } from '../AcqChannelFieldItem'

export const SMEFormFields: React.FC<SMEFormFieldsPropsType> = props => {
  const {
    checkSalesforceOpportunityID,
    form,
    getOppOwnerInfo,
    isViewMode,
    isSegmentCategoryLoading,
    onSelectCategory,
    onSelectPartner,
    country,
    segmentCategories,
    isSegmentSubCategoryLoading,
    onSelectSubCategory,
    subCategoryOpt,
    selectedSubCategoryID,
    acquisitionChannels,
    isAcquisitionChannelsLoading,
    isPartnerRequired
  } = props
  return (
    <>
      <Form.Item noStyle shouldUpdate>
        {() => {
          return (
            <SalesForceOppIDFieldItem
              checkSalesforceOpportunityID={checkSalesforceOpportunityID}
              getOppOwnerInfo={getOppOwnerInfo}
              form={form}
              isViewMode={isViewMode}
            />
          )
        }}
      </Form.Item>

      <Form.Item
        name='segmentCategory'
        label={translations().segmentCategory}
        rules={[
          {
            required: true,
            message: translations().requiredField
          }
        ]}
        validateTrigger='onBlur'
      >
        <Select
          disabled
          loading={isSegmentCategoryLoading}
          onSelect={onSelectCategory}
          onChange={() => {
            form.resetFields(['segmentSubCategory', 'acquisitionChannel', 'salesforceOpportunityID'])
            onSelectPartner('', country)
          }}
        >
          {segmentCategories.map(option => (
            <Select.Option key={option.code} value={option.code}>
              {option.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name='segmentSubCategory'
        label={translations().segmentSubCategory}
        rules={[
          {
            required: true,
            message: translations().requiredField
          }
        ]}
        validateTrigger='onBlur'
      >
        <Select
          disabled
          loading={isSegmentSubCategoryLoading}
          onSelect={onSelectSubCategory}
          onChange={() => {
            form.resetFields(['acquisitionChannel'])
            onSelectPartner('', country)
          }}
        >
          {subCategoryOpt?.map(option => (
            <Select.Option key={option.code} value={option.code}>
              {option.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <AcqChannelFieldItem
        onSelectPartner={onSelectPartner}
        selectedSubCategoryID={selectedSubCategoryID}
        acquisitionChannels={acquisitionChannels}
        isAcquisitionChannelsLoading={isAcquisitionChannelsLoading}
        isPartnerRequired={isPartnerRequired}
      />
    </>
  )
}
