import { inject, injectable } from 'inversify'
import { makeObservable, action, observable, computed } from 'mobx'
import { FormInstance } from 'antd/es/form/Form'
import { SettlementsStatusTableStoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores/components/SettlementsStatusModal/components/SettlementsStatusTable'
import { ISettlementsStatusSwitchStore } from '~/code/pages/MerchantsManagement/components/Stores/components/SettlementsStatusModal/components/SettlementsStatusTable/components/SettlementsStatusSwitch/ISettlementsStatusSwitchStore'
import { ISettlementsStatusTableStore } from '~/code/pages/MerchantsManagement/components/Stores/components/SettlementsStatusModal/components/SettlementsStatusTable/ISettlementsStatusTableStore'
import { UpdateContractTypeEnum, AllContractActionEnum } from '~/code/pages/MerchantsManagement/components/Stores/components/SettlementsStatusModal/components/SettlementsStatusTable/constants'

@injectable()
export class SettlementsStatusSwitchStore implements ISettlementsStatusSwitchStore {
    settlementsStatusTableStore : ISettlementsStatusTableStore
    switchStatus: boolean = false
    constructor(@inject(SettlementsStatusTableStoreSymbol) settlementsStatusTableStore: ISettlementsStatusTableStore) {
        this.settlementsStatusTableStore = settlementsStatusTableStore
        makeObservable(this, {
            switchStatus: observable,
            changeSwitchStatus: action.bound,
            settlementsReasonsDict: computed
        })
    }
    
    get settlementsReasonsDict() {
        return this.settlementsStatusTableStore.settlementsReasonsDict
    }

    changeSwitchStatus(contract: string, status: boolean) {
       this.switchStatus = status
       this.settlementsStatusTableStore.setShowHoldReasonsModalShow(contract, status)
    }
    
    changeHoldStatus(reason: string, form: FormInstance) {
        return this.settlementsStatusTableStore.changeHoldStatus(reason, form, UpdateContractTypeEnum.singleContract, this.switchStatus ? AllContractActionEnum.allOn : AllContractActionEnum.allOff)
    }
}