import page from 'page'

const replaceRouteParams = (route: string, routeParams?) => {
  let routeToGo = route

  if (routeParams) {
    Object.keys(routeParams).forEach(param => {
      routeToGo = routeToGo.replace(`:${param}?`, `:${param}`)
      routeToGo = routeToGo.replace(`:${param}`, routeParams[param])
    })
  } else {
    routeToGo = removeOptionalRouteParams(route)
  }

  return routeToGo
}

export const goToRoute = (route: string, routeParams?, refresh?: boolean) => {
  page(replaceRouteParams(route, routeParams))
  refresh && location.reload()
}

const removeOptionalRouteParams = route => {
  return route.replaceAll(/\/:(.)+\??/g, '')
}

export const redirectRoute = (route: string, routeParams?) => {
  page.redirect(replaceRouteParams(route, routeParams))
}

/**
 * Replaces current route with the given route
 * @param route - a route to be used for replacement of the current route
 * @param routeParams - an object with route params
 */
export const replaceRoute = (route: string, routeParams?: {}) => {
  page.replace(replaceRouteParams(route, routeParams))
}
