import { useInjection } from 'dna-react-ioc'
import { observer } from 'mobx-react-lite'
import React, { useCallback } from 'react'
import debounce from 'lodash.debounce'
import { Select, Spin, Form, Typography } from 'antd'
import { MerchantSelectStore } from '~/code/stores/MerchantsManagementStore/MerchantSelectStore'
import { SubLabel } from '~/code/pages/StartApplication/components'
import { TwoLine } from '~/code/components'
import styles from './MerchantSelect.scss'
import translations from './translations'

const { Option } = Select
const { Text } = Typography

export const MerchantSelect = observer((props: { merchantSelectStoreSymbol }) => {
  const merchantSelectStore = useInjection<MerchantSelectStore>(props.merchantSelectStoreSymbol)

  const onScroll = event => {
    const target = event.target
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight * 0.7) {
      merchantSelectStore.handleScroll()
    }
  }

  const selectSpinner = (
    <Option value='loading'>
      <Spin />
    </Option>
  )

  const delayedQuery = useCallback<(value: string) => void>(
    debounce((v: string) => {
      merchantSelectStore.setInputValue(v)
      merchantSelectStore.handleInputValueChange()
    }, 500),
    []
  )

  return (
    <Form.Item
      label={
        <div>
          {translations().merchant} <SubLabel>{translations().merchantSubLabel}</SubLabel>
        </div>
      }
    >
      <Select
        showSearch
        allowClear
        className={styles.merchantSelect}
        filterOption={false}
        loading={merchantSelectStore.merchantLoadingStatus === 'loading'}
        onSelect={v => merchantSelectStore.setMerchant(v, 'companyName')}
        placeholder={translations().selectMerchant}
        value={merchantSelectStore.selectedMerchant?.companyName || undefined}
        optionLabelProp={'prettiedLabel'}
        onPopupScroll={onScroll}
        onSearch={delayedQuery}
        onClear={merchantSelectStore.handleClear}
        options={[
          ...(merchantSelectStore.merchants?.map((m, idx) => ({
            key: idx,
            value: m.companyName,
            label: <TwoLine primary={m.tradeName} secondary={m.companyName !== 'All' && m.companyName} />,
            prettiedLabel: m.tradeName
          })) || []),
          ...(merchantSelectStore.merchantLoadingStatus === 'loading'
            ? [{ value: 'loading', label: selectSpinner }]
            : [])
        ]}
      />
    </Form.Item>
  )
})
