import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  note1: string
  note2: string
  required: string
  yes: string
  no: string
  documentCopyRequiresReview: string
  confirmThatHaveChoices: string
  minSymbols: string
  whySkip: string
  alreadyHaveProof: string
  doesntTrustTool: string
  couldntOpenLink: string
  couldntUseApp: string
  bankNotSupported: string
  other: string
}>()
