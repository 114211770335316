import React from 'react'
import translations from './translations'

translations.add('en', {
    back: 'Back to Log In',
    title: 'Do you have recovery code?',
    text: n => <>Enter your recovery code to disable two-factor authentication. You recovery code is the {n} character code you received when you activated two-factor authentication</>,
    submit: 'Continue',
    recoveryCodePlaceholder: 'Recovery code',
    recoveryCodeRequired: 'Recovery code is required',
    recoveryCodeInvalid: 'Recovery code is not valid',
    dontHaveRecoveryCode: 'I don`t have a recovery code'
})
