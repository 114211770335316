import React from 'react'
import translations from '../translations'
import moment from 'moment'

export const chargebacksColumns = [
    {
        title: translations().chargebackDate,
        key: 'chargebackDate',
        dataIndex: 'chargebackDate',
        render: date => moment(date).format('DD.MM.YYYY'),
        width: 150
    },
    {
        title: translations().transactionDate,
        key: 'transactionDate',
        dataIndex: 'transactionDate',
        render: date => moment(date).format('DD.MM.YYYY HH:mm:ss'),
        width: 200
    },
    {
        title: translations().merchantName,
        key: 'merchantName',
        dataIndex: 'merchantName',
        width: 250
    },
    {
        title: translations().amount,
        key: 'amount',
        dataIndex: 'amount',
        width: 80
    },
    {
        title: translations().currency,
        key: 'currency',
        dataIndex: 'currency',
        width: 80
    },
    {
        title: translations().reasonCode,
        key: 'reasonCode',
        dataIndex: 'reasonCode',
        width: 120
    },
    {
        title: translations().arn,
        key: 'arn',
        dataIndex: 'arn',
        width: 260
    },
    {
        title: translations().captureMethod,
        key: 'captureMethod',
        dataIndex: 'captureMethod',
        width: 150,
        render: captureMethod => captureMethod.toUpperCase()
    },
    {
        title: translations().transactionType,
        key: 'transactionType',
        dataIndex: 'transactionType',
        width: 150
    },
    {
        title: translations().cardScheme,
        key: 'cardScheme',
        dataIndex: 'cardScheme',
        width: 150,
        render: value => translations()[value] || ''
    },
    {
        title: translations().cardMask,
        key: 'cardMask',
        dataIndex: 'cardMask',
        width: 150
    },
    {
        title: translations().id,
        key: 'id',
        dataIndex: 'id',
        width: 300
    },
    {
        title: translations().documentId,
        key: 'documentId',
        dataIndex: 'documentId',
        width: 120
    },
    {
        title: translations().terminalId,
        key: 'terminalId',
        dataIndex: 'terminalId',
        width: 120
    },
    {
        title: translations().transactionId,
        key: 'transactionId',
        dataIndex: 'transactionId',
        width: 350
    },
]

export const chargebackDetailsColumns = [
    {
        title: translations().date,
        key: 'date',
        dataIndex: 'date',
        render: date => moment(date).format('DD.MM.YYYY'),
        width: 150
    },
    {
        title: translations().amount,
        key: 'amount',
        dataIndex: 'amount',
        width: 80
    },
    {
        title: translations().currency,
        key: 'currency',
        dataIndex: 'currency',
        width: 80
    },
    {
        title: translations().status,
        key: 'status',
        dataIndex: 'status',
        width: 100
    },
    {
        title: translations().documentId,
        key: 'documentId',
        dataIndex: 'documentId',
        width: 120
    },
    {
        title: translations().previousDocumentId,
        key: 'previousDocumentId',
        dataIndex: 'previousDocumentId',
        width: 180
    },
    {
        title: translations().operation,
        key: 'operation',
        dataIndex: 'operation',
        width: 100
    },
    {
        title: translations().transactionType,
        key: 'transactionType',
        dataIndex: 'transactionType',
        width: 150
    }
]

export const chargebackDetailsOriginalTransactionsColumns = [
    {
        title: translations().bankingDate,
        key: 'date',
        dataIndex: 'date',
        render: date => moment(date).format('DD.MM.YYYY'),
        width: 150
    },
    {
        title: translations().amount,
        key: 'amount',
        dataIndex: 'amount',
        width: 80
    },
    {
        title: translations().currency,
        key: 'currency',
        dataIndex: 'currency',
        width: 80
    },
    {
        title: translations().status,
        key: 'status',
        dataIndex: 'status',
        width: 100
    },
    {
        title: translations().documentId,
        key: 'documentId',
        dataIndex: 'documentId',
        width: 120
    },
    {
        title: translations().operation,
        key: 'operation',
        dataIndex: 'operation',
        width: 100
    },
    {
        title: translations().transactionType,
        key: 'transactionType',
        dataIndex: 'transactionType',
        width: 150
    },
]
