import translations from './translations'

translations.add('en', {
    all: 'All',
    messages: {
        invite: 'Invitation sent successfully.',
        update: 'Changes saved successfully.',
        resend: 'Invitation resent successfully.',
        delete: 'Teammate successfully removed.'
    },
    errors: {
        resend: 'An error occurred while resending the verification code.',
        delete: 'An error occurred while removing.'
    },
    statuses: {
        ok: 'Active',
        invited: 'Invited',
        validateEmail: 'Validate email',
        changePassword: 'Change password',
        block: 'Blocked'
    },
    authMethods: {
        password: 'Password',
        twoFAApp: '2FA (Auth App)',
        twoFAEnforced: '2FA (Enforced)',
    }
})
