import React, { ReactNode } from 'react'
import { LockOutlined, CloseOutlined, ClockCircleOutlined, CheckOutlined } from '@ant-design/icons'
import { Tag } from '~/code/components'
import { StatusProps, StatusIconType } from './props'

const icons: Record<StatusIconType, ReactNode> = {
  close: <CloseOutlined />,
  lock: <LockOutlined />,
  clock: <ClockCircleOutlined />,
  tick: <CheckOutlined />
}

export const Status: React.FC<StatusProps> = ({ status, type, iconType, className }: StatusProps) => {
  return (
    <Tag type={type} className={className}>
      {icons[iconType]}&nbsp;
      {status}
    </Tag>
  )
}
