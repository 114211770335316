import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { Typography, List, Tooltip } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { TwoFAModalStoreInterface } from '~/code/stores/Profile/TwoFAModalStore/TwoFAModalStoreInterface'
import { TwoFAModalStoreSymbol } from '~/code/pages/Profile/components/TwoFA/components/TwoFAModal'

import translations from './translations'
import styles from './styles.scss'

export const TwoFARecoveryCode: React.FC<{}> = observer(() => {
  const { recoveryCodes, setIsModalCloseble } = useInjection<TwoFAModalStoreInterface>(TwoFAModalStoreSymbol)
  const [isCopied, setIsCopied] = useState<boolean>(false)

  const recoveryCodesToCopy = recoveryCodes.map(({ code }) => `${code}`).join('\r\n')

  const onCopyClick = () => {
    setIsCopied(true)
    navigator.clipboard.writeText(recoveryCodesToCopy)
    setIsModalCloseble(true)
    setTimeout(() => setIsCopied(false), 300)
  }

  return (
    <div>
      <Typography.Text>{translations().title}</Typography.Text>

      <div className={styles.listHolder}>
        <List
          className={styles.codes}
          size={'small'}
          bordered
          dataSource={recoveryCodes}
          renderItem={({ code }) => <List.Item>{code}</List.Item>}
        />

        <div className={styles.buttonHolder}>
          <Tooltip title={translations().copied} placement={'top'} open={isCopied}>
            <CopyOutlined className={styles.icon} onClick={onCopyClick} />
          </Tooltip>
        </div>
      </div>

      <div className={styles.footer}>
        <Typography.Text>{translations().copyText}</Typography.Text>

        <ul>
          <li>{translations().warning.line1}</li>
          <li>{translations().warning.line2}</li>
          <li>{translations().warning.line3}</li>
        </ul>
      </div>
    </div>
  )
})
