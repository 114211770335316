import { getWithAuth, patchWithAuth } from '~/code/services'
import { EcomTransactionChannelType, UpdateTransactionChannelParams,
    PosTransactionChannelType, PosTransactionChannelRequestType } from '../models'
import { PosConnectionDetailsType } from '~/code/pages/TransactionChannels/models/PosConnectionDetailsType'

export const fetchEcomTransactionChannels = () =>
    getWithAuth<EcomTransactionChannelType[]>('/api/v1/transaction-channels')

export const updateEcomTransactionChannel = (id: string, params: UpdateTransactionChannelParams) => {
    return patchWithAuth<EcomTransactionChannelType>(`/api/v1/transaction-channels/${id}/settings`, params)
}

export const fetchPosConnectionDetails = () =>
    getWithAuth<PosConnectionDetailsType[]>('/api/v1/pos/transaction-channels/connection-statuses')

export const fetchPosTransactionChannels = () =>
    getWithAuth<PosTransactionChannelType[]>(`/api/v1/pos/transaction-channels`)

export const updatePosTransactionChannel = ({channel, ...rest}: PosTransactionChannelRequestType) =>
    patchWithAuth<any>(`/api/v1/pos/transaction-channels/${channel}/settings`, rest)
