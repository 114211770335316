import React, { useState } from 'react'
import { Form } from 'antd'
import moment, { Moment } from 'moment-timezone'
import DatePicker from '~/code/components/DatePicker'
import { DateItemProps } from './props'
import translations from './translations'

export const DateItem: React.FC<DateItemProps> = props => {
  const { name, initialValue, showValueChangedMsg = false, disabled } = props

  const [showWarning, setShowWarning] = useState(false)

  const handleChange = (date: Moment) => {
    if (moment(date).format('YYYY-MM-DD') !== moment(initialValue).format('YYYY-MM-DD')) {
      setShowWarning(true)
    } else {
      setShowWarning(false)
    }
  }

  return (
    <>
      <Form.Item name={name} key={name}>
        <DatePicker
          placement={'bottomRight'}
          disabledDate={(date: moment.Moment) => date && date.isBefore(moment().startOf('day'))}
          onChange={handleChange}
          disabled={disabled}
        />
      </Form.Item>
      {showValueChangedMsg && showWarning && (
        <div>
          {translations().changedFrom}{' '}
          {moment(initialValue).isValid() ? moment(initialValue).format('YYYY-MM-DD') : null}
        </div>
      )}
    </>
  )
}
