import React from 'react'
import classNames from 'classnames'
import { Button } from 'antd'
import { UploadOutlined, CheckCircleFilled } from '@ant-design/icons'
import { Upload } from '..'
import translations from '../../translations'
import styles from './styles.scss'
import { SupportingDocumentProps } from './props'

export const SupportingDocument: React.FC<SupportingDocumentProps> = (props) => {
    const { title, fileList, onChange, loading = false, notRequiredText, icon, lessMarginBottom } = props

    return  <div className={classNames(styles.block, { [styles.block__lessMarginBottom]: lessMarginBottom })}>
        <div className={styles.title}>
            {icon}
            {title}
        </div>
        {notRequiredText ? (
            <div className={styles.notRequiredText}>
                <CheckCircleFilled className={styles.checkIcon} />
                {notRequiredText}
            </div>
        ) : (
            <Upload
                fileList={fileList}
                onChange={onChange}
                loading={loading}
            >
                {fileList.length === 0 && <Button icon={<UploadOutlined />}>{translations().upload}</Button>}
            </Upload>
        )}
    </div>
}
