import React from 'react'
import { observer } from 'mobx-react'
import { Tabs } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { TabType } from '~/code/models'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { MerchantsManagementStore } from '~/code/stores/MerchantsManagementStore/MerchantsManagementStore'
import { MerchantsManagementStoreSymbol } from '../..'
import { Profile, Officers, RoutingTable } from './components'
import { Stores } from '../Stores'
import { Balances } from '../Balances'
import { FinancialInformation } from '../FinancialInformation'
import translations from './translations'

export const MerchantApplication = observer(() => {
  const store = useInjection<MerchantsManagementStore>(MerchantsManagementStoreSymbol)

  if (!store.isCompanyFullDossierV2Exists) return <></>

  const isCompany = store.companyFullDossierV2?.mainInfo?.businessStructure === '1'
  const isSoleTrader = store.companyFullDossierV2?.mainInfo?.businessStructure === '2'

  const tabItems: TabType[] = [
    {
      key: 'companyProfile',
      label: translations().companyProfile,
      children: <Profile profile={store.profileEntity} />
    }
  ]

  if (isCompany) {
    if (store.shareholders) {
      tabItems.push({
        key: 'individualShareholders',
        label: translations().shareholders,
        children: <Officers officersList={store.shareholders} officersType='individualShareholders' />
      })
    }

    if (store.directors) {
      tabItems.push({
        key: 'companyDirectors',
        label: translations().directors,
        children: <Officers officersList={store.directors} officersType='companyDirectors' />
      })
    }

    if (store.authorizedPersons) {
      tabItems.push({
        key: 'authorisedSignatories',
        label: translations().authorisedSignatories,
        children: <Officers officersList={store.authorizedPersons} officersType='authorisedSignatories' />
      })
    }
  } else if (isSoleTrader && store.shareholders) {
    tabItems.push({
      key: 'businessOwner',
      label: translations().businessOwner,
      children: <Officers officersList={store.shareholders} officersType='businessOwner' />
    })
  }

  if (store.isCompanyFullDossierV2Exists) {
    tabItems.push({
      key: 'stores',
      label: translations().stores,
      children: <Stores storesInfo={store.companyFullDossierV2} />
    })
    tabItems.push({
      key: 'financialInformation',
      label: translations().financialInformation,
      children: <FinancialInformation stores={store.stores} />
    })
    tabItems.push({
      key: 'balances',
      label: translations().balances,
      children: <Balances />
    })
    if (hasPermissions([PermissionMap.merchants.section.terminal_routings.read])) {
      tabItems.push({
        key: 'routing',
        label: translations().routing,
        children: <RoutingTable />
      })
    }
  }

  return <Tabs defaultActiveKey='info' items={tabItems} />
})
