import { Collapse, Descriptions } from 'antd'
import React from 'react'
import { StoresPropsType } from './props'
import translations from './translations'
import styles from './Stores.scss'
import { Products } from '../Products'
import { ProcessingMethods } from '../ProcessingMethods'
import { AdvancedPayments } from '../AdvancedPayments'
import { FinancialInfo } from '../FinancialInfo/FinancialInfo'

export const Stores = ({ data }: StoresPropsType) => {
  return (
    <Collapse ghost>
      {data?.map((item, id) => (
        <Collapse.Panel header={item.storeName} key={id}>
          <Descriptions bordered column={1}>
            <Descriptions.Item label={<b>{translations().storeName}</b>}>{item.storeName}</Descriptions.Item>
            <Descriptions.Item label={<b>{translations().systemDescriptor}</b>}>
              {item?.systemDescriptor}
            </Descriptions.Item>
            <Descriptions.Item label={<b>{translations().natureOfBusiness}</b>}>
              {item?.natureOfBusiness}
            </Descriptions.Item>
            <Descriptions.Item label={<b>{translations().mcc}</b>}>{item?.mcc}</Descriptions.Item>
            <Descriptions.Item label={<b>{translations().mccDescription}</b>}>{item?.mccDescription}</Descriptions.Item>
            <Descriptions.Item label={<b>{translations().industry}</b>}>{item?.industry}</Descriptions.Item>
          </Descriptions>

          <Descriptions
            title={<div className={styles.sectionHeader}>{translations().storeAddress}</div>}
            bordered
            column={1}
          >
            <Descriptions.Item label={<b>{translations().postalCode}</b>}>
              {item?.storeAddress?.postalCode}
            </Descriptions.Item>
            <Descriptions.Item label={<b>{translations().country}</b>}>{item?.storeAddress?.country}</Descriptions.Item>
            <Descriptions.Item label={<b>{translations().addressLine1}</b>}>
              {item?.storeAddress?.addressLine1}
            </Descriptions.Item>
            <Descriptions.Item label={<b>{translations().townOrCity}</b>}>
              {item?.storeAddress?.townOrCity}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            title={<div className={styles.sectionHeader}>{translations().systemStoreAddress}</div>}
            bordered
            column={1}
          >
            <Descriptions.Item label={<b>{translations().postalCode}</b>}>
              {item?.systemStoreAddress?.postalCode}
            </Descriptions.Item>
            <Descriptions.Item label={<b>{translations().country}</b>}>
              {item?.systemStoreAddress?.country}
            </Descriptions.Item>
            <Descriptions.Item label={<b>{translations().addressLine1}</b>}>
              {item?.systemStoreAddress?.addressLine1}
            </Descriptions.Item>
            <Descriptions.Item label={<b>{translations().townOrCity}</b>}>
              {item?.systemStoreAddress?.townOrCity}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            title={<div className={styles.sectionHeader}>{translations().delivery}</div>}
            bordered
            column={1}
          >
            <Descriptions.Item label={<b>{translations().deliveryAddress}</b>}>
              {item?.deliveryAddress}
            </Descriptions.Item>
            <Descriptions.Item label={<b>{translations().deliveryPhoneNumber}</b>}>
              {item?.deliveryPhoneNumber}
            </Descriptions.Item>
            <Descriptions.Item label={<b>{translations().deliveryInstructions}</b>}>
              {item?.deliveryInstructions}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            title={<div className={styles.sectionHeader}>{translations().bankInfo}</div>}
            bordered
            column={1}
          >
            <Descriptions.Item label={<b>{translations().bankName}</b>}>{item?.bankInfo?.bankName}</Descriptions.Item>
            <Descriptions.Item label={<b>{translations().bankAccountNumber}</b>}>
              {item?.bankInfo?.bankAccountNumber}
            </Descriptions.Item>
            <Descriptions.Item label={<b>{translations().bankAccountType}</b>}>
              {item?.bankInfo?.bankAccountType}
            </Descriptions.Item>
            <Descriptions.Item label={<b>{translations().sortCode}</b>}>{item?.bankInfo?.sortCode}</Descriptions.Item>
          </Descriptions>

          <FinancialInfo data={item.financialInfo} />

          <div className={styles.sectionHeader}>
            <b>{translations().products}</b>
          </div>
          <Products data={item.products} />

          <div className={styles.sectionHeader}>
            <b>{translations().processingMethods}</b>
          </div>
          <ProcessingMethods data={item.processingMethods} />

          <div className={styles.sectionHeader}>
            <b>{translations().advancedPayments}</b>
          </div>
          <AdvancedPayments data={item.advancePayments} />
        </Collapse.Panel>
      ))}
    </Collapse>
  )
}
