import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  title: string
  text: string
  email: string
  emailRequired: string
  emailInvalid: string
  send: string
  cancel: string
}>()

