import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    title: string
    shortTitle: string
    merchant: string
    createdDate : string
    createdTime : string
    amount: string
    currency: string
    status: string
    message: string
    signifydResult: string
    confirmationDate: string
    confirmedAmount: string
    transactionChannel: string
    transactionType: string
    paymentMethod: string
    mid: string
    terminal: string
    merchantReference: string
    rrn: string
    sourceClient: string
    threeDSVersion: string
    avsHouseNumberResult: string
    avsPostcodeResult: string
    cscResult: string
    payerAuthenticationResult: string
    responseCode: string
    cardType: string
    cardMask: string
    issuer: string
    issuerBankCountry: string
    payerName: string
    payerEmail: string
    payerIp: string
    ipCountry: string
    ipCity: string
    processingType: string
    id: string
    reason: string
    export: string
}>()
