import translations from './translations'

translations.add('en', {
  emailAddress: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  sendWelcomeEmail: 'Send Welcome Email?',
  submit: 'Add',
  partner: 'Partner',
  partnerPlaceholder: 'Search by partner name',
  noPartners: 'No Partners',
  roles: 'Roles',
  createdAt: 'Created Date',
  searchByPartner: 'Search by partner',
  searchByEmail: 'Search by email',
  actions: 'Actions',
  emailSearchPlaceholder: 'Search by email',
  lastLoginDate: 'Last Login',
  status: 'Status',
  authMethod: 'Auth Method',

  formErrors: {
    wrongEmailFormat: 'wrong email format'
  },

  menu: {
    edit: 'Edit user',
    editSecurity: 'Edit security settings',
    delete: 'Delete'
  },

  deleteAlert: {
    logins: {
      title: 'Delete Partner',
      text: 'Are you sure you want to delete this partner? This action cannot be undone.'
    },

    partners: {
      title: 'Delete Login',
      text: 'Are you sure you want to delete this login? This action cannot be undone.'
    },

    okText: 'Confirm'
  },
  totalPagination: (range: number[], total: number) => `${range[0]}-${range[1]} of ${total}`
})
