import { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  title: string
  firstName: string
  surname: string
  firstNameRequired: string
  surnameRequired: string
  emailLabel: string
  emailRequired: string
  emailFormatIncorrect: string
  telephoneNumberLabel: string
  telephoneNumberRequired: string
  telephoneNumberWrongFormat: string
  acquisitionChannel: string
  acquisitionChannelRequired: string
  referrer: string
  referrerRequired: string
  referrerPlaceholder: string
  firstNameSubLabel: string
  emailSubLabel: string
  applicationSourceLabel: string
  applicationSourceRequired: string
  applicationAlreadyStarted: string
  continue: string
  salesforceOpportunityIDLabel: string
  salesforceOpportunityIDRequired: string
  salesforceOpportunityIDMin: string
  sellerEmail: string
  fastTrack: string
  msaSigned: string
  applicantInfo: string
  firstNamePlaceholder: string
  surnamePlaceholder: string
  emailAlreadyExists: ReactNode
  segmentCategory: string
  requiredField: string
  segmentSubCategory: string
  obrCountry: string
  applicationDetails: string
  start: string
  cancel: string
  opportunityIdOwnerName: string
  oppIdNote: string
  save: string
  errorWithEmail: string
  whatToDo: string
  whatToDo1: string
  whatToDo2: string
}>()
