import translations from './translations'

translations.add('en', {
    title: 'Total sum of successful transactions',
    shortTitle: 'Total sum',
    merchant: 'Merchant',
    createdDate : 'Created Date',
    createdTime : 'Created Time',
    amount: 'Amount',
    currency: 'Currency',
    status: 'Status',
    message: 'Message',
    signifydResult: 'Signifyd Result',
    confirmationDate: 'Confirmation Date',
    confirmedAmount: 'Confirmed Amount',
    transactionChannel: 'Transaction Channel',
    transactionType: 'Transaction Type',
    paymentMethod: 'Payment Method',
    mid: 'MID',
    terminal: 'Terminal',
    merchantReference: 'Merchant Reference',
    rrn: 'RRN',
    sourceClient: 'Source Client (Origin of Transaction)',
    threeDSVersion: '3DS Version',
    avsHouseNumberResult: 'AVS House Number Result',
    avsPostcodeResult: 'AVS Post Code Result',
    cscResult: 'CSC Result',
    payerAuthenticationResult: 'Payer Auth Result (Enrollment/Payer Authentication)',
    responseCode: 'Response Code',
    cardType: 'Card Scheme',
    cardMask: 'Card Mask',
    issuer: 'Issuer',
    issuerBankCountry: 'Issuer Country',
    payerName: 'Payer Name',
    payerEmail: 'Payer Email',
    payerIp: 'Payer IP',
    ipCountry: 'IP Country',
    ipCity: 'IP City',
    processingType: 'Processing Type',
    id: 'ID',
    reason: 'Reason',
    export: 'Export'
})
