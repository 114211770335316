import React from 'react'
import { Checkbox, Table } from 'antd'

import { PAGE_SIZE_MAX } from '~/code/constants/Configurations'
import { getPermissionKeys, mergeAndConvertPermissions } from '../../services'
import { RoleItemModel } from '../../models'
import translations from '../../translations'
import styles from './styles.scss'

type Props = {
  roles: RoleItemModel[]
  selectedRoles: string[]
}

export const PermissionsTable: React.FC<Props> = ({ roles, selectedRoles }) => {
  const permissionKeys = getPermissionKeys()

  const { permissionsData, specificPermissionsData } = mergeAndConvertPermissions(
    roles
      .filter(r => selectedRoles.includes(r.value))
      .map(r => r.permissions)
      .reduce((prev, pps) => prev.concat(pps), []),
    permissionKeys.map(p => p.value)
  )

  if (permissionsData.length === 0 && specificPermissionsData.length === 0) {
    return null
  }

  return (
    <div className={styles.root}>
      {permissionsData.length > 0 && (
        <Table
          pagination={{
            pageSize: PAGE_SIZE_MAX,
            hideOnSinglePage: true,
            size: 'small'
          }}
          columns={[
            { dataIndex: 'title' },
            ...permissionKeys.map(p => ({
              title: p.label,
              key: p.value,
              dataIndex: p.value,
              className: styles.check,
              render: v => <Checkbox checked={Boolean(v)} />
            }))
          ]}
          dataSource={permissionsData}
        />
      )}

      {specificPermissionsData.length > 0 && (
        <Table
          className={styles.specific}
          pagination={{
            pageSize: PAGE_SIZE_MAX,
            hideOnSinglePage: true,
            size: 'small'
          }}
          columns={[
            { dataIndex: 'title', title: translations().specificPermissions },
            { render: () => <Checkbox checked={true} />, className: styles.check }
          ]}
          dataSource={specificPermissionsData}
        />
      )}
    </div>
  )
}
