import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { HeaderDropdown, RangePicker } from '~/code/components'
import { MobileRangePickerProps } from './props'
import styles from './MobileRangePicker.scss'
import { DropdownProps, MenuProps } from 'antd'

export const MobileRangePicker = observer((props: MobileRangePickerProps) => {
  const { store } = props
  const [isMobilePickerVisible, setMobilePickerVisible] = useState(false)
  const triggers = useRef({ isStartDateChanged: false, isEndDateChanged: false })

  const handleOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
    if (info.source === 'trigger' || nextOpen) {
      setMobilePickerVisible(nextOpen)
    }
  }

  const items: MenuProps['items'] = [
    {
      key: 'date-picker',
      label: (
        <RangePicker
          startDate={store.startDate}
          endDate={store.endDate}
          period={store.period}
          onSetDates={(startDate, endDate, triggeredDate) => {
            if (triggeredDate === 'start') {
              triggers.current.isStartDateChanged = true
            }
            if (triggeredDate === 'end') {
              triggers.current.isEndDateChanged = true
            }
            if (triggers.current.isStartDateChanged && triggers.current.isEndDateChanged) {
              triggers.current.isStartDateChanged = false
              triggers.current.isEndDateChanged = false
              setMobilePickerVisible(false)
            }
            store.setDates(startDate, endDate)
          }}
          onSetPeriod={period => {
            setMobilePickerVisible(false)
            store.setPeriod(period)
          }}
        />
      )
    }
  ]

  return (
    <HeaderDropdown
      open={isMobilePickerVisible}
      onOpenChange={handleOpenChange}
      menu={{ items }}
      overlayClassName={styles.headerDropdownOverlay}
    />
  )
})
