import { TranslationBuilder } from '~/code/components/Translation'
import { PaymentSolutionView } from '../../../models'

interface PaymentSolutionTranslate {
  title: string
  comment: string
}

export default TranslationBuilder.create<{
  title: string
  required: string
  posModel: string
  posModelRequired: string
  posCountRequired: string
  paymentSolutions: Record<PaymentSolutionView, PaymentSolutionTranslate>
  ecom: string
  pos: string
  webSiteRequired: string
  posDeliveryInstructionsRequired: string
  posDeliveryAddressRequired: string
  productPlaceholder: string
  deviceAmountPlaceholder: string
  posQuantity: string
  posDeliveryAddress: string
  posDeliveryAddressPlaceholder: string
  manual: string
  sameAsStore: string
  sameAsCompany: string
  deliveryInstructions: string
  deliveryInstructionsPlaceholder: string
  deliveryContactNumber: string
  validateBankAccount: string
  addProduct: string
  posBundles: string

  cityLabel: string
  cityRequired: string
  cityInvalid: string
  countryLabel: string
  countryRequired: string
  regionLabel: string
  postalCodeLabel: string
  postalCodeRequired: string
  postalCodeInvalid: string
  addressLine1Label: string
  addressLine1Required: string
  addressLine2Label: string
  addressLine2Required: string
  storeWebSite: string

  subscriptionServicePeriod: string
  freeSubscriptionPeriod: string
  contractType: string
  byDNA: string
  byPaytek: string
  posCountError: string
  zashEpos: string
  zashDigital: string
  byOptomany: string
  confirm: string
  byOptomanyDetails: string
  attention: string
}>()
