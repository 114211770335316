import React from 'react'
import { BPMProcessDossierV2 } from '../shared'
import { columns } from './constants'

export const ClosureOfTerminalStoreSymbol = Symbol('ClosureOfTerminalStore')
export const ClosureOfTerminalRangePickerStoreSymbol = Symbol('ClosureOfTerminalRangePickerStore')
export const ClosureOfTerminalMerchantSelectStoreSymbol = Symbol('ClosureOfTerminalMerchantSelectStore')

export const ClosureOfTerminal = () => (
  <BPMProcessDossierV2
    BPMProcessesStoreSymbol={ClosureOfTerminalStoreSymbol}
    BPMProcessesRangePickerStoreSymbol={ClosureOfTerminalRangePickerStoreSymbol}
    BPMProcessesMerchantSelectStoreSymbol={ClosureOfTerminalMerchantSelectStoreSymbol}
    columns={columns}
  />
)
