import { Descriptions, Divider, Table } from 'antd'
import React from 'react'
import { clearMobx, isObject } from '~/code/services'
import { TarriffsTablePropsType } from './props'
import translations from './translations'

export const TarriffsTable = ({ data }: TarriffsTablePropsType) => {
  const dtWithObjects = []
  const dtWithoutObjects = []
  let settlementPeriod = ''
  Object.entries(data).forEach((entry, idx) => {
    const [key, value] = entry
    if (isObject(value)) {
      const val = value as any
      dtWithObjects.push({ key: idx, name: key, base: val?.base, percent: val?.percent })
    } else {
      dtWithoutObjects.push({ key: idx, name: key, val: value })
    }

    if (key === 'settlementPeriod') {
      settlementPeriod = value
    }
  })

  const columns = [
    {
      title: translations().name,
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: translations().value,
      key: 'val',
      dataIndex: 'val'
    }
  ]
  const columns2 = [
    {
      title: translations().name,
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: translations().value,
      key: 'base',
      dataIndex: 'base'
    },
    {
      title: translations().percent,
      key: 'percent',
      dataIndex: 'percent'
    }
  ]
  return (
    <>
      <Descriptions bordered column={1}>
        <Descriptions.Item label={<b>{translations().settlementPeriod}</b>}>{settlementPeriod}</Descriptions.Item>
      </Descriptions>
      <Divider />
      {dtWithoutObjects.length > 0 && <Table columns={columns} dataSource={dtWithoutObjects} />}
      {dtWithObjects.length > 0 && <Table columns={columns2} dataSource={dtWithObjects} />}
    </>
  )
}
