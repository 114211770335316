import translations from './translations'

translations.add('en', {
  upload: 'Upload',
  provideInfo: 'Provide Info',
  provideAdditionalInfo: 'Provide Additional Info',
  comment: 'Comment',
  submit: 'Submit',
  documents: 'Documents',
  documentType: 'Document type',
  documentsRequired: 'Documents Required'
})
