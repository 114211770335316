import { Card, Grid, Form, Input } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { withAppStore } from '~/code/hocs/withAppStore'
import { MobileRangePicker, DesktopRangePicker, PageHeaderRaw } from '~/code/components'
import { ProcessesTable } from '../Processes/components'
import translations from './translations'
import { PartnerProcessesProps } from './props'
import styles from './PartnerProcesses.scss'

const { useBreakpoint } = Grid

const PartnerProcessesView = observer((props: PartnerProcessesProps) => {
    const { store } = props
    const [ form ] = useForm()
    const screens = useBreakpoint()

    useEffect(() => {
        store.init()
        form.setFieldsValue({ merchant: store.filter.companyName })
    }, [])

    return <div>
        <PageHeaderRaw className={styles.header}>
            <div className={styles.headerContent}>
                <Form
                    className={styles.form}
                    form={form}
                    onFinish={(values) => store.setFilter(values)}
                >
                    <Form.Item
                        name='companyName'
                        label={translations().merchant}
                        rules={[{ type: 'string', min: 3, message: translations().validationMinLength }]}
                        validateTrigger={['onBlur']}
                    >
                        <Input onBlur={() => form.submit()} />
                    </Form.Item>
                </Form>
                <div className={styles.extra}>{screens.xs ? <MobileRangePicker store={store.dateStore} /> : <DesktopRangePicker store={store.dateStore} />}</div>
            </div>
        </PageHeaderRaw>

        <Card>
            <ProcessesTable
                store={store}
                excludedColumns={['email', 'phoneNumber', 'acquisitionChannel', 'assignedGroup', 'assignedUser', 'reviewTime']}
            />
        </Card>
    </div>
})

export const PartnerProcesses = withAppStore(({ appStore }) => <PartnerProcessesView
  store={appStore.onboardingPartnerProcessesStore}
/>)
