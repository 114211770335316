import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    merchantButtonLabel: (isLoading: boolean, isThereMerchant: boolean) => string
    merchant: string
    merchantSubLabel: string
    noMerchants: string
    acquisitionChannel: string
    noAcquisitionChannels: string
    all: string
    modalTitle: (hasAcquisitionChannels: boolean) => string
}>()

