import { TranslationBuilder } from '~/code/components/Translation'
import { TransactionChannelType, PaymentMethodType, PosTransactionChannelType } from '~/code/models'

export default TranslationBuilder.create<{
  all: string
  home: string
  dashboard: string
  loginPageTitle: string
  resetPasswordPageTitle: string
  resetPasswordFailurePageTitle: string
  confirmCodePageTitle: string
  setNewPasswordPageTitle: string
  dnaAcquiringAnalyticsPageTitle: string
  dnaAcquiringAnalyticsPosPageTitle: string
  dnaAcquiringStatementPageTitle: string
  dnaAcquiringMonitoringPosPageTitle: string
  transactionChannelsGeneralSettingsPageTitle: string
  transactionChannelsMerchantSettingsPageTitle: string
  optomanyCheckoutV3AnalyticsPageTitle: string
  optomanyCheckoutV3StatementPageTitle: string
  onboardingPageTitle: string
  onboardingStatisticsPageTitle: string
  settlementsStatementPageTitle: string
  settlementsPaymentsPageTitle: string
  portalAccessPageTitle: string
  partnerAccessPageTitle: string
  twoFA: string
  enforceTwoFA: string
  recoveryCode: string
  chargebacksPageTitle: string
  teammatesPageTitle: string
  exportsPageTitle: string
  onboardingProcessesPageTitle: string
  onboardingProcessPageTitle: string
  onboardingProcessesAnalytics: string
  optomanyCheckoutV3PageTitle: string
  merchantsManagementProfile: string
  invoicesPageTitle: string
  netSuiteInvoicesPageTitle: string
  motoVtRequestPageTitle: string
  changeBankDetailsPageTitle: string
  closureOfMerchantPageTitle: string
  closureOfTerminalPageTitle: string
  changeofSettlementPeriodTitle: string
  terminalIssueTitle: string
  riskLimitsPageTitle: string
  notFoundPageTitle: string
  noPermissionPageTitle: string
  unknown: string
  permissionErrorText: string
  nonUK: string
  defaultErrorMessage: string
  changeOfSettlementType: string
  changeOfSettlementStatus: string
  adjustmentRequestsTitle: string
  editTariffsRequestsTitle: string
  partnerCommissionsTitle: string

  months: {
    january: string
    february: string
    march: string
    april: string
    may: string
    june: string
    july: string
    august: string
    september: string
    october: string
    november: string
    december: string
  }

  transactionChannels: Record<TransactionChannelType, string>

  posTransactionChannels: Record<PosTransactionChannelType, string>

  paymentMethods: Record<PaymentMethodType, string>

  messages: {
    successfullSetPassword: string
  }

  errors: {
    loadData: string
    saveData: string
  }

  back: string
  next: string

  datePlaceholder: string
  total: string
  average: string

  processingTypes: {
    mailOrder: string
    telephoneOrder: string
    initialRecurring: string
    recurringMit: string
    initialUcof: string
    ucofMit: string
    initialCof: string
    cofCit: string
    mailOrderInitialCof: string
    telephoneOrderInitialCof: string
    motoRecurringMit: string
  }

  cardTypes: {
    visa: string
    mastercard: string
    amex: string
    upi: string
    diners: string
    other: string
  }

  signifydDecisions: {
    accept: string
    reject: string
    hold: string
  },

  notifications: {
    manageTemplates: string
    manageNotifications: string
    publishNotifications: string
  }
}>()
