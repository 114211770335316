import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { Card } from 'antd'

import { TabType } from '~/code/models'
import { Page } from '~/code/pages'
import { PageHeader, ReloadButton } from '~/code/components'
import { AcquiringFilter } from '../../components'
import { getStatuses } from './components/AnalyticsOverview/services/getters'
import { StoreAndTerminalFilter } from '~/code/components/StoreAndTerminalFilter'
import { IAcquiringFilterStore } from '../../components/AcquiringFilter/models'
import { AnalyticsOverview, AnalyticsDetailedView } from './components'
import translations from './translations'
import styles from './Analytics.scss'
import { IAnalyticsStore } from './models'

type AnalyticsType = 'overview' | 'detailedView'

const tabList: TabType[] = [
    {
        key: 'overview',
        label: translations().overview
    },
    {
        key: 'detailedView',
        label: translations().detailedView
    }
]

export const Analytics = observer((props: {
    analyticsStoreSymbol: symbol,
    acquiringFilterStoreSymbol: symbol,
    merchantSelectStoreSymbol: symbol
}) => {
    const { analyticsStoreSymbol, acquiringFilterStoreSymbol, merchantSelectStoreSymbol }  = props
    const analyticsStore = useInjection<IAnalyticsStore>(analyticsStoreSymbol)
    const acquiringFilterStore = useInjection<IAcquiringFilterStore>(acquiringFilterStoreSymbol)

    const { storeAndTerminalFilterStore } = acquiringFilterStore
    const merchantProductType = acquiringFilterStore.acquiringEcomPosType
    const [selectedTab, setSelectedTab] = useState<AnalyticsType>('overview')

    useEffect(() => {
        if (!analyticsStore.isInitiated) analyticsStore.init()
        analyticsStore.analyticsOverviewStore.setShouldLoadData(true) // in order to trigger the initial fetch requests
    }, [])

    const refresh = () => {
        if (selectedTab === 'overview') {
            analyticsStore.analyticsOverviewStore.refreshTable()

        } else {
            analyticsStore.analyticsDetailedViewStore.refreshTable()
        }
    }

    const onTabChange = (tab) => {
        setSelectedTab(tab)
        analyticsStore.analyticsOverviewStore.setShouldLoadData(tab === 'overview')
        analyticsStore.analyticsDetailedViewStore.setShouldLoadData(tab === 'detailedView')
    }

    const renderSelectedTabContent = () => {
        if (selectedTab === 'detailedView') {
            return <AnalyticsDetailedView store={analyticsStore.analyticsDetailedViewStore} statuses={getStatuses(merchantProductType)}/>
        }

        return <AnalyticsOverview merchantProductType={merchantProductType} store={analyticsStore.analyticsOverviewStore}/>
    }

    return <Page>
        <PageHeader
            tabActiveKey={selectedTab}
            tabList={tabList}
            onTabChange={onTabChange}
        />
        <Card className={styles.Analytics}>
            <AcquiringFilter
                storeSymbol={acquiringFilterStoreSymbol}
                merchantSelectStoreSymbol={merchantSelectStoreSymbol}
                extra={
                <ReloadButton
                    onClick={refresh}
                />
            }
            />
            {merchantProductType === 'pos' && <StoreAndTerminalFilter store={storeAndTerminalFilterStore} className={styles.StoreAndTerminalFilter}/>}
        </Card>
        { renderSelectedTabContent() }
    </Page>
})
