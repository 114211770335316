import { PortalAccessTabType, PortalAccessTabKeyType } from '~/code/pages/PortalAccess/types/PortalAccessTabType'

import translations from '../translations'

export const tabs: PortalAccessTabType[] = [
  {
    key: PortalAccessTabKeyType.merchants,
    label: translations().searchByMerchant
  },
  {
    key: PortalAccessTabKeyType.logins,
    label: translations().searchByEmail
  }
]
