import translations from './translations'

translations.add('en', {
  shareholders: 'Shareholders',
  directors: 'Directors',
  authorisedSignatories: 'Authorised Signatories',
  businessOwner: 'Business Owner',
  corporateEntityShareholders: 'Corporate Entity Shareholders',
  companyName: 'Company name',
  companyNumber: 'Company number',
  companyProfile: 'Company Profile',
  info: 'Info',
  stores: 'Stores',
  balances: 'Balances',
  routing: 'Acquirers',
  financialInformation: 'Financial Information'
})
