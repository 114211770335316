import translations from './translations'

translations.add('en', {
  loginSuccessfulDelete: 'Login successfully removed',
  loginDeleteFailure: 'Login delete error',
  deleteAlert: {
    logins: {
      title: 'Delete Partner',
      text: 'Are you sure you want to delete this partner? This action cannot be undone.'
    },

    partners: {
      title: 'Delete Login',
      text: 'Are you sure you want to delete this login? This action cannot be undone.'
    },

    okText: 'Confirm'
  }
})
