import translations from './translations'

translations.add('en', {
  title: 'Reset Password',
  text: 'Enter your email address and we\'ll send you a verification code to it',
  email: 'email',
  emailRequired: 'email is required',
  emailInvalid: 'email is invalid',
  send: 'Send',
  cancel: 'Cancel'
})
