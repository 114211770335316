import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  companyType: string
  companyName: string
  relationshipType: string
  dateOfCreation: string
  numberOfEmployees: string
  companyWebsite: string
  emailAddress: string
  telephoneNumber: string
  bankAccountName: string
  bankAccountNumber: string
  bankAccountType: string
  sortCode: string
  bankName: string
  companyNumber: string
  directorsPartnersCount: string
  charityNumber: string
  charityComission: string
  merchantCategoryCode: string
  merchantCategoryCodeDescription: string
  companyAddress: string
  advancedPayments: string
  processingMethods: string
  financialInfo: string
  companyTradeName: string
}>()
