import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Modal, Form, Button, Select, Input } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { SalesForceSearch } from '../../../SalesForceSearch'
import { ICloseStoreModalStore } from './ICloseStoreModalStore'
import { CloseStoreModalProps } from './props'
import translations from './translations'

const { TextArea } = Input
export const CloseStoreModalStoreSymbol = Symbol('CloseStoreModalStore')
export const CloseStoreModal = observer((props: CloseStoreModalProps) => {
  const store = useInjection<ICloseStoreModalStore>(CloseStoreModalStoreSymbol)
  const [form] = Form.useForm()

  useEffect(() => {
    store.loadClosureReasons()
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      comment: '',
      reason: store.salesForceData?.closureReason
    })
  }, [store.salesForceData])

  const onFinish = async () => store.closeStore(form, props.storeId)

  return (
    <>
      <Modal
        width={'50%'}
        open={props.open}
        title={translations().closeStore}
        onCancel={() => store.closeModal(form)}
        footer={[
          <Button key='cancel' onClick={() => store.closeModal(form)}>
            {translations().cancel}
          </Button>,
          <Button
            key='submit'
            type='primary'
            disabled={!store.salesForceData?.id}
            loading={store.isCloseStoreLoading}
            onClick={() => form.submit()}
          >
            {translations().submit}
          </Button>
        ]}
      >
        <SalesForceSearch store={store.salesForceSearchStore} midsList={props.midsList} />

        <Form form={form} onFinish={onFinish}>
          {store.salesForceDataFound && (
            <>
              <Form.Item
                name='reason'
                label={translations().reason}
                rules={[{ required: true, message: translations().reasonRequired }]}
              >
                <Select
                  disabled
                  loading={store.isClosureReasonsLoading}
                  options={store.closureReasonsList}
                  onChange={() => form.resetFields(['comment'])}
                />
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {t =>
                  t.getFieldValue('reason') === 'other' && (
                    <Form.Item
                      name='comment'
                      label={translations().comment}
                      rules={[
                        {
                          required: true,
                          message: translations().commentRequired
                        },
                        {
                          min: 2,
                          message: translations().reasonMinMsg
                        }
                      ]}
                    >
                      <TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount maxLength={150} minLength={2} />
                    </Form.Item>
                  )
                }
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </>
  )
})
