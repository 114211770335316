import translations from './translations'

translations.add('en', {
  pos: 'POS (face-to-face)',
  moto: 'MOTO (phone payments)',
  ecom: 'ECOM (Website payments)',
  payByLink: 'ECOM Pay by link only',
  payByApp: 'ECOM Pay by Apps',
  virtualTerminal: 'ECOM Virtual terminal',
  nbsp: 'Same day sales –  product is taken away from stock',
  deposits: 'Deposits',
  balances: 'Balances',
  fullPayment: 'Full payment in advance',
  other: 'Other (please detail)',
  totalSum: 'total sum must be 100%!',
  required: 'required!',
  digitsOnly: 'digits only!',
  range: '0 - 100 range!',
  enterDetails: 'please enter details!',
  onlyPositive: 'only positive digits!',
  selectTerminal: 'Select Terminal',
  annualTurnover: 'Expected annual business turnover',
  annualCardTurnover: 'Expected annual card turnover',
  monthTransactions: 'Number of transactions per month',
  highestTransaction: 'Highest transaction value',
  averageTransaction: 'Average transaction value',
  turnoverMethod: 'Card Turnover depending on the processing method',
  processingMethod: 'Method of processing',
  cartTurnoverPercent: '% Card Turnover* (100% in total)',
  ifMerchantNew: '*Expected card turnover if the Merchant is new to cards',
  howFar: 'How far in advance payments are taken, before goods/products are received or services are complete',
  cardTurnover: '% Card Turnover',
  days: 'Days',
  haveComments: 'Have comments?',
  comment: 'Please add comment',
  requestMoto: 'Request MOTO',
  requestVt: 'Request Virtual Terminal',
  notAvailable: 'No terminals available for configuration',
  clear: 'Clear Form',
  submit: 'Submit Form',
  posLabel: 'POS',
  ecomLabel: 'ECOM',
  isRequired: 'is required!',
  description: 'Description',
  financialInformation: 'Financial information'
})
