import {
  CompanyProfile,
  ContactInfoType,
  GeneralInformationType,
  OfficerProfile,
  OfficerRole,
  OnboardingRequest,
  PCSProfile
} from '~/code/pages/StartApplication/models'
import { OfficerProfile as OfficerProfileV2 } from '~/code/pages/StartProcess/models'
import { OnboardingCompany } from '~/code/pages/StartApplication/models/onboarding/OnboardingCompany'
import { OnboardingSoleTrader } from '~/code/pages/StartApplication/models/onboarding/OnboardingSoleTrader'
import { OnboardingSoleTrader as OnboardingSoleTraderV2 } from '~/code/pages/StartProcess/models/onboarding/OnboardingSoleTrader'
import { FetchApplicationByIdRes, FetchApplicationByIdResV2 } from '../../../models'
import { getOfficerProfile } from './getters'
import {
  advancePaymentsInitialValues,
  processingMethodsInitialValues
} from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v1'
import {
  CompanyProfile as CompanyProfileV2,
  OnboardingRequest as OnboardingRequestV2,
  PCSProfile as PCSProfileV2
} from '~/code/pages/StartProcess/models'
import { OnboardingCompany as OnboardingCompanyV2 } from '~/code/pages/StartProcess/models/onboarding/OnboardingCompany'
import { ContactInfoType as ContactInfoTypeV2 } from '~/code/pages/StartProcess/models'
import { v4 as uuid } from 'uuid'

export const parseContactInfoData = (result: FetchApplicationByIdRes): ContactInfoType => {
  return {
    acquisitionChannel: result.acquisitionChannel,
    firstName: result.applicantInfo?.firstName,
    surname: result.applicantInfo?.surname,
    email: result.applicantInfo?.emailAddress,
    telephoneNumber: result.applicantInfo?.telephoneNumber
  }
}

export const parseGeneralInformationData = (result: OnboardingRequest): GeneralInformationType => {
  return {
    paymentSolution: result.paymentSolution,
    companyType: result.businessStructure,
    companyNumber: (result as OnboardingCompany).companyProfile?.companyNumber
      ? (result as OnboardingCompany).companyProfile.companyNumber
      : undefined,
    posModel: result.tariffs?.posModel,
    isFastTrack: result.isFastTrack,
    isMsaSigned: result.isMsaSigned
  }
}

export const parseCompanyProfile = (result: OnboardingRequest): CompanyProfile => {
  const { companyProfile } = result

  const data: CompanyProfile = {
    ...companyProfile,
    advancePayments: companyProfile.advancePayments
      ? companyProfile?.advancePayments.reduce((acc, item) => {
          const { name } = item
          return { ...acc, [name]: item }
        }, {})
      : advancePaymentsInitialValues,
    processingMethods: companyProfile.processingMethods
      ? companyProfile.processingMethods.reduce((acc, item) => {
          const { name } = item
          return { ...acc, [name]: item }
        }, {})
      : processingMethodsInitialValues,
    employeesNumber: companyProfile?.numberOfEmployees,
    dateOfCreation: companyProfile?.dateOfCreation,
    accountName: companyProfile?.bankAccountName,
    tradingAddress: {
      ...companyProfile?.tradingAddress,
      locality: companyProfile?.tradingAddress?.townOrCity,
      region: companyProfile?.tradingAddress?.countyOrState
    }
  }

  if (result.businessStructure === 'company') {
    result = result as OnboardingCompany
    data.registeredOfficeAddress = {
      ...result.companyProfile?.companyAddress,
      locality: result.companyProfile?.companyAddress?.townOrCity,
      region: result.companyProfile?.companyAddress?.countyOrState
    }
  }

  return data
}

export const parseOfficerList = (result: OnboardingRequest): OfficerProfile[] => {
  if (result.businessStructure === 'sole-trader') {
    result = result as OnboardingSoleTrader
    if (!result.businessOwner) return []
    return [getOfficerProfile((result as OnboardingSoleTrader).businessOwner, OfficerRole.businessOwner)]
  }

  result = result as OnboardingCompany
  const officerList: OfficerProfile[] = []

  if (result.companyDirectors) {
    result.companyDirectors.forEach(officer => officerList.push(getOfficerProfile(officer, OfficerRole.director)))
  }

  if (result.authorisedSignatories) {
    result.authorisedSignatories.forEach(officer =>
      officerList.push(getOfficerProfile(officer, OfficerRole.authorisedSignatory))
    )
  }

  return officerList
}

export const parseOfficerListV2 = (result: OnboardingRequestV2): OfficerProfileV2[] => {
  const officerList: OfficerProfileV2[] = []
  if (result.businessStructure !== 'company' && result.businessStructure !== 'llp') {
    result = result as OnboardingSoleTraderV2
    if (!result.businessOwners) return []

    result.businessOwners.forEach(officer => officerList.push(getOfficerProfile(officer, OfficerRole.businessOwner)))

    return officerList
  }

  result = result as OnboardingCompanyV2

  if (result.companyDirectors) {
    result.companyDirectors.forEach(officer => officerList.push(getOfficerProfile(officer, OfficerRole.director)))
  }

  if (result.authorisedSignatories) {
    result.authorisedSignatories.forEach(officer =>
      officerList.push(getOfficerProfile(officer, OfficerRole.authorisedSignatory))
    )
  }

  return officerList
}

export const parseCompanyPCSList = (result: OnboardingRequest): PCSProfile[] => {
  if (result.businessStructure === 'sole-trader') return []

  result = result as OnboardingCompany
  const shareholders: PCSProfile[] = []

  if (result.individualShareholders) {
    result.individualShareholders.forEach(shareholder => shareholders.push(getOfficerProfile(shareholder, null)))
  }

  if (result.corporateEntityShareholders) {
    result.corporateEntityShareholders.forEach(shareholder => {
      shareholders.push({
        name: shareholder.companyName,
        identification: { registrationNumber: shareholder.companyNumber },
        kind: 'corporate'
      })
    })
  }

  return shareholders
}

export const parseCompanyPCSListV2 = (result: OnboardingRequestV2): PCSProfileV2[] => {
  if (result.businessStructure === 'sole-trader') return []

  result = result as OnboardingCompanyV2
  const shareholders: PCSProfileV2[] = []

  if (result.individualShareholders) {
    result.individualShareholders.forEach(shareholder => shareholders.push(getOfficerProfile(shareholder, null)))
  }

  if (result.corporateEntityShareholders) {
    result.corporateEntityShareholders.forEach(shareholder => {
      shareholders.push({
        id: uuid(),
        name: shareholder.companyName,
        identification: { registrationNumber: shareholder.companyNumber },
        kind: 'corporate',
        ownershipPercentage: shareholder.ownershipPercentage
      })
    })
  }

  return shareholders
}

export const parseContactInfoDataV2 = (result: FetchApplicationByIdResV2): ContactInfoTypeV2 => {
  return {
    acquisitionChannel: result.acquisitionChannel,
    firstName: result.applicantInfo?.firstName,
    surname: result.applicantInfo?.surname,
    email: result?.sellerEmail,
    phoneNumber: result.applicantInfo?.telephoneNumber,
    salesforceOpportunityID: result.applicantInfo.opportunityId,
    segmentCategory: result.applicantInfo.segmentCategory,
    segmentSubCategory: result.applicantInfo.segmentSubCategory
  }
}

export const parseCompanyProfileV2 = (result: OnboardingRequestV2): CompanyProfileV2 => {
  const { companyProfile } = result

  const data: CompanyProfileV2 = {
    ...companyProfile,
    companyAddress: {
      ...result.companyProfile?.companyAddress,
      region: result.companyProfile?.companyAddress?.countyOrState
    }
  }
  return data
}
