const HEALTHY_MAX_CHART_BORDER_15 = 15
const HEALTHY_MAX_CHART_BORDER_3 = 3
const MIDDLE_MAX_CHART_BORDER_30 = 30
const MIDDLE_MAX_CHART_BORDER_5 = 5

export const BpmStages = [
  { name: 'application-initiated', gaugeData: [HEALTHY_MAX_CHART_BORDER_15, MIDDLE_MAX_CHART_BORDER_30] },
  { name: 'application-submitted', gaugeData: [HEALTHY_MAX_CHART_BORDER_3, MIDDLE_MAX_CHART_BORDER_5] },
  { name: 'waiting-kyc-documents', gaugeData: [HEALTHY_MAX_CHART_BORDER_15, MIDDLE_MAX_CHART_BORDER_30] },
  { name: 'additional-info-required', gaugeData: [HEALTHY_MAX_CHART_BORDER_15, MIDDLE_MAX_CHART_BORDER_30] },
  { name: 'compliance-check', gaugeData: [HEALTHY_MAX_CHART_BORDER_15, MIDDLE_MAX_CHART_BORDER_30] },
  { name: 'creditrisk-check', gaugeData: [HEALTHY_MAX_CHART_BORDER_15, MIDDLE_MAX_CHART_BORDER_30] },
  { name: 'waiting-merchant-agreement', gaugeData: [HEALTHY_MAX_CHART_BORDER_15, MIDDLE_MAX_CHART_BORDER_30] },
  { name: 'terminal-creation', gaugeData: [HEALTHY_MAX_CHART_BORDER_3, MIDDLE_MAX_CHART_BORDER_5] },
  { name: 'order-validation', gaugeData: [HEALTHY_MAX_CHART_BORDER_3, MIDDLE_MAX_CHART_BORDER_5] }
]
