import { Form, Modal, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { DeleteModalProps } from './props'
import translations from './translations'

export const DeleteModal: React.FC<DeleteModalProps> = (props) => {
    const { isVisible, handleDelete, handleCancel, title } = props
    const [ isLoading, setIsLoading ] = useState(false)
    const [ form ] = Form.useForm()

    const submit = async () => {
        await form.validateFields()
        setIsLoading(true)
        await handleDelete(form.getFieldValue('deleteReason'))
        setIsLoading(false)
    }

    useEffect(() => {
        form.resetFields()
    }, [isVisible])

    return <Modal
        open={isVisible}
        onCancel={handleCancel}
        onOk={submit}
        okText={translations().deleteApplication}
        okButtonProps={{ danger: true, loading: isLoading }}
        title={title}
        forceRender={true}
    >
        <Form
            form={form}
            layout={'vertical'}
        >
            <Form.Item
                name='deleteReason'
                label={translations().reasonLabel}
                rules={[
                    {
                        required: true,
                        message: translations().reasonRequired
                    },
                    {
                        min: 2,
                        message: translations().reason2Chars
                    }
                ]}
            >
                <Input.TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    showCount
                    maxLength={150}
                    minLength={2}
                />
            </Form.Item>
        </Form>
    </Modal>
}