import { UploadDocumentReq } from '../models'

export const getUploadDocFormData = (data: UploadDocumentReq): FormData => {
    const formData = new FormData()

    for (const [key, value] of Object.entries(data)) {
        formData.append(key, value)
    }

    return formData
}
