import React from 'react'
import translations from './translations'

translations.add('en', {
  text: 'Resend',
  seconds: count =>
    count ? (
      <>
        {' '}
        in <b>{count} seconds</b>
      </>
    ) : (
      ''
    )
})