import React from 'react'
import translations from './translations'
import styles from './StyledTab.scss'
import { ActiveBlue, CompleteGreen, Progress } from './assets'
import { StyledTabPropsType } from './props'
import { Popover } from 'antd'

export const StyledTab: React.FC<StyledTabPropsType> = ({
  title,
  isCompleted,
  subTitle,
  disabled = false,
  active = false,
  isDocusignSigned
}) => {
  return (
    <Popover placement='left' content={isDocusignSigned ? translations().docusignSignedText : ''}>
      <div className={styles.tabText}>
        <div className={styles.icon}>
          {isCompleted ? (
            <CompleteGreen />
          ) : (
            <>
              <ActiveBlue stroke={disabled && '#7F8286'} />
            </>
          )}
        </div>
        <div>
          <div className={styles.title}>{title}</div>
          <div className={styles.subTitle}>
            {subTitle ? subTitle : isCompleted ? translations().completed : translations().notCompleted}
          </div>
        </div>
      </div>
    </Popover>
  )
}
