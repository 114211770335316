import React, { useEffect } from 'react'
import { Form, Typography, Input, Select, Alert, InputNumber } from 'antd'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { SalesForceSearch } from '../SalesForceSearch'
import { ClosureOfMerchantConfirmationModal } from './components'
import { ICloseMerchantStore } from './ICloseMerchantStore'
import translations from './translations'
import styles from './ClosureOfMerchant.scss'

const { TextArea } = Input

export const CloseMerchantStoreSymbol = Symbol('CloseMerchantTerminalStore')

export const ClosureOfMerchant = observer(() => {
  const store = useInjection<ICloseMerchantStore>(CloseMerchantStoreSymbol)
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
  }, [store.selectedMerchant])

  useEffect(() => {
    form.setFieldsValue({
      comment: '',
      reason: store.salesForceData?.closureReason
    })
  }, [store.salesForceData])

  const openModal = async () => {
    try {
      await form.validateFields()
    } catch (error) {
      return
    }
    store.handleModalStatus(true)
  }
  const closeModal = () => store.handleModalStatus(false)
  const onOk = () => form.submit()
  const handleFinish = async ({ reason, comment, terminationFee }) => {
    await store.closeMerchant(reason, comment, terminationFee)
    form.resetFields()
  }

  return (
    <div className={styles.formContainer}>
      <Typography.Title level={3}>{translations().closureOfMerchant}</Typography.Title>
      <div className={styles.alertContainer}>
        <Alert description={translations().warningMessage} type='warning' showIcon />
      </div>
      <SalesForceSearch store={store.salesForceSearchStore} midsList={store.merchantMids} />
      <Form form={form} onFinish={handleFinish} labelCol={{ sm: 12, xl: 8 }} labelAlign={'left'}>
        {store.salesForceDataFound && (
          <>
            {store.isSubsidiaryForFee && (
              <>
                <Typography.Title level={5}>
                  {translations().legalName} {store.legalName}
                </Typography.Title>
                <Form.Item
                  label={translations().terminationFee}
                  name='terminationFee'
                  rules={[
                    { required: true, message: translations().required },
                    { type: 'number', min: 0, max: 5000, message: translations().valueRestriction }
                  ]}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <InputNumber min={0} />
                </Form.Item>
              </>
            )}
            <Form.Item
              name='reason'
              label={translations().reason}
              rules={[{ required: true, message: translations().reasonRequired }]}
            >
              <Select
                disabled
                loading={store.isClosureReasonsLoading}
                options={store.closureReasonsList}
                onChange={() => form.resetFields(['comment'])}
              />
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {t =>
                t.getFieldValue('reason') === 'other' && (
                  <Form.Item
                    name='comment'
                    label={translations().comment}
                    rules={[
                      {
                        required: true,
                        message: translations().commentRequired
                      },
                      {
                        min: 2,
                        message: translations().reasonMinMsg
                      }
                    ]}
                  >
                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount maxLength={150} minLength={2} />
                  </Form.Item>
                )
              }
            </Form.Item>
            <ClosureOfMerchantConfirmationModal
              open={store.isModalOpen}
              isCloseMerchantLoading={store.isCloseMerchantLoading}
              isMerchantActive={store.isMerchantActive}
              openModal={openModal}
              onCancel={closeModal}
              onOk={onOk}
              companyName={store.selectedMerchant?.companyName}
              isSubsidiaryForFee={store.isSubsidiaryForFee}
              terminationFee={form.getFieldValue('terminationFee')}
            />
          </>
        )}
      </Form>
    </div>
  )
})
