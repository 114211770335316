import React from 'react'
import { observer } from 'mobx-react'
import { Card, Col, Row, Form, Input, Button, FormInstance } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { hasPermissions } from '~/code/services/auth'
import { IClosureOfMerchantStore } from '../../IClosureOfMerchantStore'
import { ClosureOfMerchantStoreSymbol } from '../../ClosureOfMerchant'
import translations from './translations'
import styles from './ClosureOfMerchantProcessDetails.scss'

const { TextArea } = Input

export const ClosureOfMerchantProcessDetails = observer((props: { form: FormInstance }) => {
  const store = useInjection<IClosureOfMerchantStore>(ClosureOfMerchantStoreSymbol)
  const { form } = props

  const renderSubmitBtn = () => {
    if (!hasPermissions([PermissionMap.merchants.processes.merchant_close.read])) {
      return null
    }
    return (
      <div className={styles.completeBtnsBlock}>
        <Button type='primary' loading={store.isSubmitLoading} onClick={() => form.submit()}>
          {translations().complete}
        </Button>
      </div>
    )
  }

  return (
    <Card>
      <div className={styles.blockTitle}>{translations().commonInfo}</div>
      <Form form={form} onFinish={() => store.onFormFinish(form)}>
        <Row gutter={[0, 12]}>
          <Col xs={12} className={styles.propLabel}>
            {translations().companyName}:{' '}
          </Col>
          <Col xs={12}>{store.selectedProcess?.companyName}</Col>
        </Row>
        <div className={styles.commentMargin}>
          <Form.Item
            name='comment'
            label={translations().commentLabel}
            rules={[
              {
                required: true,
                message: translations().commentRequired
              },
              {
                min: 2,
                message: translations().commentMinMsg
              }
            ]}
          >
            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount maxLength={150} minLength={2} />
          </Form.Item>
          {renderSubmitBtn()}
        </div>
      </Form>
    </Card>
  )
})
