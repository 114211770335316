import React from 'react'
import classNames from 'classnames'
import {Tag as AntTag} from 'antd'
import {TagType} from '~/code/models'
import {TagProps} from './props'
import styles from './styles.scss'

export const Tag: React.FC<TagProps> = ({onClick, children, className, type}: TagProps) => {
    return <AntTag
        className={classNames(
            styles.tag,
            { [styles.default]: type === TagType.Default },
            { [styles.active]: type === TagType.Active },
            { [styles.processing]: type === TagType.Processing },
            { [styles.success]: type === TagType.Success },
            { [styles.warn]: type === TagType.Warn },
            { [styles.error]: type === TagType.Error },
            { [styles.disabled]: type === TagType.Disabled },
            className
        )}
        onClick={onClick}
    >
        {children}
    </AntTag>
}
