import React from 'react'
import { Space, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { observer } from 'mobx-react-lite'
import { FormSubgroupTitle } from '../shared/FormSubgroupTitle'
import translations from './translations'
import { ProductsTableProps } from './props'

const { Link } = Typography

export const ProductsTable = observer(({ products, store }: ProductsTableProps) => {
  const dt = products.map(prd => ({
    key: prd.id,
    name: prd.name,
    model: prd.model,
    count: prd.count,
    removable: prd.removable
  }))

  const columns: ColumnsType<any> = [
    {
      title: translations().productName,
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: translations().model,
      key: 'model',
      dataIndex: 'model'
    },
    {
      title: translations().count,
      key: 'count',
      dataIndex: 'count'
    }
  ]

  if (store) {
    columns.push({
      title: translations().action,
      key: 'action',
      dataIndex: 'product',
      render: (_, product) => (
        <Space size='middle'>
          {product.removable && (
            <Link onClick={() => store.productStore.removeProduct(product.key)}>{translations().delete}</Link>
          )}
        </Space>
      )
    })
  }
  return (
    <>
      {dt.length > 0 && (
        <>
          <FormSubgroupTitle>{translations().title}</FormSubgroupTitle>
          <Table pagination={false} columns={columns} dataSource={dt} />
        </>
      )}
    </>
  )
})
