import React from 'react'
import { Button } from 'antd'
import styles from './CustomButton.scss'
import { ButtonProps } from 'antd/es/button'
import classNames from 'classnames'

export const CustomButton = (props: ButtonProps) => {
  const { children, className, ...rest } = props
  return (
    <Button className={classNames(styles.styledButton, className)} {...rest}>
      {children}
    </Button>
  )
}
