import translations from '../translations'

export const subscriptionServicePeriodOptionsDNA = [
  {
    key: '12',
    value: '12'
  },
  {
    key: '18',
    value: '18'
  },
  {
    key: '24',
    value: '24'
  },
  {
    key: '36',
    value: '36'
  },
  {
    key: '48',
    value: '48'
  }
]

export const subscriptionServicePeriodOptionsPaytek = [
  {
    key: '36',
    value: '36'
  },
  {
    key: '48',
    value: '48'
  }
]

export const terminalTypes = [
  {
    key: 'subscription',
    value: 'subscription',
    label: 'Subscription'
  },
  {
    key: 'terminalPurchase',
    label: 'Terminal purchase (Pay upfront)',
    value: 'terminalPurchase'
  },
  {
    key: 'ownTerminal',
    label: 'Own terminal',
    value: 'ownTerminal'
  }
]

export const chargeTypes = [
  {
    key: 'byDNA',
    value: 'byDNA',
    label: translations().byDNA
  },
  {
    key: 'byOptomany',
    label: translations().byOptomany,
    value: 'byOptomany'
  }
]
