import { Typography, Form, Input, Button, Modal, Checkbox, InputNumber } from 'antd'
import { log } from 'dna-common'
import { useInjection } from 'dna-react-ioc'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import translations from './translations'
import { IClosureOfTerminalDossierV2Store } from './ClosureOfTerminalDossierV2StoreType'
import styles from './ClosureOfTerminal.scss'

const { TextArea } = Input
export const ClosureOfTerminalDossierV2StoreSymbol = Symbol('ClosureOfTerminalDossierV2Store')
export const ClosureOfTerminal = observer(() => {
  const store = useInjection<IClosureOfTerminalDossierV2Store>(ClosureOfTerminalDossierV2StoreSymbol)

  const [form] = Form.useForm()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = () => {
    form
      .validateFields()
      .then(() => setIsModalOpen(true))
      .catch(err => log(err))
  }
  const closeModal = () => setIsModalOpen(false)
  const onOk = () => {
    form.submit()
  }

  const handleFinish = async (values: { reason: string; terminationFee: number }) => {
    const { reason, terminationFee } = values
    await store.closeTerminal(reason, terminationFee)
    setIsModalOpen(false)
    form.resetFields()
  }

  return (
    <>
      <Typography.Title level={3}>{translations().closureOfTerminal}</Typography.Title>
      <Form className={styles.form} form={form} onFinish={handleFinish} labelCol={{ sm: 6 }} labelAlign='left'>
        <Form.Item
          name='terminalIds'
          label={translations().terminals}
          rules={[
            {
              required: true,
              message: translations().terminalsRequired
            }
          ]}
        >
          <Checkbox.Group
            options={store.allTerminalIds.map(t => ({ label: t, value: t }))}
            value={store.terminalIds}
            onChange={(value: string[]) => store.setTerminalIds(value)}
          ></Checkbox.Group>
        </Form.Item>
        {store.allTerminalsSelected && (
          <div className={styles.errMsgForTerminalsChoose}>{translations().closeAllterminalsNotification}</div>
        )}
        {store.isSubsidiaryForFee && (
          <Form.Item
            label={translations().terminationFee}
            name='terminationFee'
            rules={[
              { required: true, message: translations().required },
              { type: 'number', min: 0, max: 1000, message: translations().valueRestriction }
            ]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <InputNumber min={0} />
          </Form.Item>
        )}
        <Form.Item
          name='reason'
          label={translations().reasonLabel}
          rules={[
            {
              required: true,
              message: translations().reasonRequired
            },
            {
              min: 2,
              message: translations().reasonMinMsg
            }
          ]}
        >
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount maxLength={150} minLength={2} />
        </Form.Item>
        <div className={styles.buttonContainer}>
          <Button type='primary' disabled={store.allTerminalsSelected || !store.isMerchantActive} onClick={openModal}>
            {translations().submit}
          </Button>
        </div>
        <Modal
          open={isModalOpen}
          onOk={onOk}
          okButtonProps={{ loading: store.isCloseMerchantTerminalLoading }}
          onCancel={closeModal}
          title={translations().closureOfTerminal}
        >
          {translations().areYouSureCloseTerminals(store.terminalIds, store.selectedMerchant?.companyName)}
        </Modal>
      </Form>
    </>
  )
})
