import React from 'react'
import { Table, TableProps, Pagination, TablePaginationConfig, Row, Col, Modal } from 'antd'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'
import { TableFilterStore } from '~/code/stores/TableFilterStore'
import { getColumns, renderActions } from '~/code/pages/PortalAccess/utils'
import { PortalAccessTabKeyType } from '~/code/pages/PortalAccess/types/PortalAccessTabType'
import translations from '../../translations'
import styles from './styles.scss'

export type Props = TableProps<any> & {
  store: TableFilterStore<any>
  type: PortalAccessTabKeyType
  onSelectMenu(key, item): void
}

export const PortalAccessTable: React.FC<Props> = props => {
  const { store, type, pagination, onSelectMenu, ...rest } = props
  const { currentPage, pageSize, totalCount, data, setPageSize } = store

  const isPaginationDisabled = !data || data.length === 0

  const paginationProps: TablePaginationConfig = generateTablePaginationProps(
    currentPage,
    pageSize,
    totalCount,
    pageNumber => store.loadData({ pageNumber }),
    setPageSize
  )

  const onSelect = (key, item) => {
    if (key === 'delete') {
      const _type = type === PortalAccessTabKeyType.merchants ? 'login' : 'merchant'

      Modal.confirm({
        title: translations().deleteAlert[_type].title,
        content: translations().deleteAlert[_type].text,
        okText: translations().deleteAlert.okText,
        onOk: () => onSelectMenu(key, item)
      })
    } else {
      onSelectMenu(key, item)
    }
  }

  return (
    <>
      <Table
        rowKey={({ rowKey }) => rowKey}
        columns={getColumns(type, renderActions, onSelect)}
        pagination={false}
        scroll={{ x: type === PortalAccessTabKeyType.merchants ? 1500 : 1000 }} // To ensure that the column headers fit without wrapping
        {...rest}
      />

      <Row className={styles.pagination}>
        <Col>
          <Pagination {...paginationProps} disabled={isPaginationDisabled} />
        </Col>
      </Row>
    </>
  )
}
