import translations from './translations'

translations.add('en', {
  title: 'Set New Password',
  password: 'new password',
  passwordRequired: 'new password is required',
  passwordWrongFormat: 'password does not meet the requirements',
  confirmPassword: 'confirm password',
  confirmPasswordRequired: 'confirm password is required',
  passwordsDoNotMatch: 'passwords do not match',
  set: 'Set Password',
  cancel: 'Cancel'
})
