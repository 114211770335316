import moment from 'moment-timezone'
import React from 'react'
import translations from './translations'

const title = (type: 'sum' | 'count', merchant: string, status: string, currency: string) => {
    if (merchant && merchant.trim().toLowerCase() === 'all') {
        return <>Total {type} of {status} transactions for all merchants in {currency || '£'}</>
    }
    return <>Total {type} of {status} transactions for {merchant} in {currency || '£'}</>
}

translations.add('en', {
    all: 'All',
    successful: 'Successful',
    failed: 'Failed',
    sumTitle: (merchant: string, status: string, currency: string) => {
        return title('sum', merchant, status, currency)
    },
    countTitle: (merchant: string, status: string, currency: string) => {
        return title('count', merchant, status, currency)
    },
    yearsComparison: 'Years Comparison',
    asOfYesterday: `as of ${moment().subtract(1, 'day').format('DD.MM.YYYY')} GMT time`
})
