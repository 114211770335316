import { OfficerAddress } from '.'
import { CompanyType } from './company/CompanyType'
import { AccountType } from './complete/AccountType'
import { OnboardingBusinessModelQuestions } from './onboarding/OnboardingBusinessModelQuestions'
import { AdvancePaymentsFormStateModel, ProcessingMethodsFormStateModel } from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v1'

export class CompanyProfile {
    relationshipType?: string = ''
    companyName?: string = ''
    companyTradeName?: string = ''
    storeName?: string = ''
    companyNumber?: string = ''
    dateOfCreation?: string = ''
    registeredOfficeAddress?: OfficerAddress
    natureOfBusiness?: string = undefined
    natureOfBusinessDetails?: string = ''
    companyWebsite?: string = ''
    employeesNumber?: string = undefined
    expectedAnnualTurnover?: string = undefined
    expectedAnnualTurnoverCustomValue?: string = undefined
    expectedMonthlyTransactionValues?: string = ''
    averageTransactionValue: string = ''
    tradeOutsideEea?: boolean = false
    tradingCountriesOutsideEea?: string[] = []
    emailAddress?: string = ''
    telephoneNumber?: string = ''
    bankAccountType?: AccountType
    bankAccountNumber?: string = ''
    sortCode?: string = ''
    accountName?: string = ''
    bankName?: string = ''
    tradingAddress?: OfficerAddress
    businessModelQuestions?: OnboardingBusinessModelQuestions
    expectedAnnualCardTurnover?: string = undefined
    highestTransactionValue?: string = undefined
    processingMethods: ProcessingMethodsFormStateModel
    advancePayments: AdvancePaymentsFormStateModel

    constructor (initialValue: Partial<CompanyProfile> = {}, companyType: CompanyType = 'sole-trader') {
        Object.assign(this, {
            registeredOfficeAddress: companyType === 'company' ? {
                addressLine1: '',
                addressLine2: '',
                country: undefined,
                locality: '',
                postalCode: '',
                region: '',
                premises: ''
            } : undefined,
            tradingAddress: {
                addressLine1: '',
                addressLine2: '',
                country: undefined,
                locality: '',
                postalCode: '',
                region: '',
                premises: ''
            },
            businessModelQuestions: {
                seasonalBusiness: {
                    isTrue: false,
                    quarter1: '',
                    quarter2: '',
                    quarter3: '',
                    quarter4: ''
                },
                face2face: {
                    isTrue: false,
                    deliveryTime: '',
                    courierServiceUsed: false
                }
            },
            processingMethods: null,
            advancePayments: null
        }, initialValue)
    }
}