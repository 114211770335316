import { ColumnType } from 'antd/lib/table'
import React from 'react'
import { JobType, JobStatusType } from '~/code/stores/ExportsStore/models'
import translations from '../translations'
import { DownloadFile } from '../components'

export const columns: ColumnType<JobType>[] = [
    {
        key: 'id',
        dataIndex: 'id',
        title: translations().id
    },
    {
        key: 'fileName',
        dataIndex: 'fileName',
        title: translations().fileName,
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: translations().jobName,
        render: value => translations().jobNameTypes[value] || value
    },
    {
        key: 'createdDate',
        dataIndex: 'createdDate',
        title: translations().createdDate
    },
    {
        key: 'lastUpdateDate',
        dataIndex: 'lastUpdateDate',
        title: translations().lastUpdateDate
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: translations().status,
        render: (value: JobStatusType, record) => value !== 'FINISHED' ? value : <DownloadFile value={value} record={record} />
    }
]
