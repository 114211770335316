import { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    backText: string
    generalInformation: string
    financialInformation: string
    shareholders: string
    directors: string
    newDirector: string
    signatories: string
    newSignatory: string
    businessOwner: string
    optional: string
    completed: string
    notCompleted: string
    pleaseCompleteApplication: string
    canNotProceed: string
    mustBe: (count: number, companyMode: boolean) => string
    docusignSignedText: ReactNode
}>()
