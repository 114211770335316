import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Form, Modal, Select, Checkbox, Button, Row, Col } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { TMonitoringPosExportsModalStore } from './TMonitoringPosExportsModalStore'
import translations from './translations'

const { useForm } = Form

export const MonitoringPosTestExportsModalStoreSymbol = Symbol('MonitoringPosTestExportsModalStore')

export const MonitoringPosTestExportsModal = observer(() => {
    const [form] = useForm()

    const store = useInjection<TMonitoringPosExportsModalStore>(MonitoringPosTestExportsModalStoreSymbol)

    const {
        monitoringPosTransactionStore,
        isUploading,
        options,
        exportTypeOptions,
        selectExportType,
        selectedExportType,
        selectOption,
        handleCancel,
        fields,
        isButtonDisabled,
        onExportsFormSubmit
    } = store

    useEffect(() => {
        form.setFieldsValue({columnsOption: selectedExportType})
    }, [selectedExportType])

    const onCancel = () => {
        form.resetFields()
        handleCancel()
    }

    const selectType = (value: string) => {
        form.setFieldsValue({fields: []})
        selectExportType(value)
    }

    const renderCheckboxGroup = () => {
        return (
            <Checkbox.Group onChange={selectOption}>
                <Row>
                    {Object.values(options).map((option: {value: string, label: string}) => {
                        return (
                            <Col span={8} key={option.value}>
                                <Checkbox
                                    value={option.value}
                                >
                                    {option.label}
                                </Checkbox>
                            </Col>
                        )
                    })}
                </Row>
            </Checkbox.Group>
        )
    }

    return (
        <Modal
            open={monitoringPosTransactionStore.isExportModalOpen}
            onCancel={onCancel}
            title={translations().title}
            footer={
                <Row gutter={8} justify={'end'}>
                    <Col>
                        <Button onClick={onCancel}>{translations().cancel}</Button>
                    </Col>
                    <Col>
                        <Button
                          disabled={isButtonDisabled}
                          loading={isUploading}
                          type={'primary'}
                          onClick={form.submit}
                        >
                            {translations().ok}
                        </Button>
                    </Col>
                </Row>
            }
        >
            <Form
                form={form}
                initialValues={{
                    columnsOption: 'all',
                    fields: Object.keys(options)
                }}
                onFinish={onExportsFormSubmit}
            >
                <Form.Item
                    label={translations().columns}
                    name={'columnsOption'}
                    shouldUpdate
                >
                    <Select
                        value={selectedExportType}
                        options={exportTypeOptions}
                        onChange={selectType}
                    />
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                    {() => {
                        return selectedExportType ===
                        'custom' ? (
                            <Form.Item name={'fields'}>
                                {renderCheckboxGroup()}
                            </Form.Item>
                        ) : null
                    }}
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                    {() => {
                        const labels = fields.map((field) => options[field].label)
                        return (<div>
                            {labels.length
                                ? labels.join(', ')
                                : ''}
                        </div>)
                    }}
                </Form.Item>
            </Form>
        </Modal>
    )
})
