import React from 'react'
import { Select, Form } from 'antd'
import translations from './translations'
import { AcqChannelFieldItemPropsType } from './props'

export const AcqChannelFieldItem: React.FC<AcqChannelFieldItemPropsType> = props => {
  const {
    onSelectPartner,
    country,
    selectedSubCategoryID,
    acquisitionChannels,
    isAcquisitionChannelsLoading,
    isPartnerForm,
    isPartnerRequired
  } = props
  const channels = acquisitionChannels

  if (isPartnerForm && selectedSubCategoryID) {
    channels.filter(acq => acq.segmentSubCategory === selectedSubCategoryID?.toString())
  }

  return (
    <Form.Item
      name='acquisitionChannel'
      label={translations().applicationSourceLabel}
      rules={[
        {
          required: true,
          message: translations().applicationSourceRequired
        },
        {
          validator: async (_, value) => {
            if (isPartnerRequired && value === 'not_applicable') {
              throw new Error(translations().chooseValidPartner)
            }
          }
        }
      ]}
      validateTrigger={['onBlur', 'onChange']}
    >
      <Select loading={isAcquisitionChannelsLoading} onSelect={val => onSelectPartner(val, country)}>
        {acquisitionChannels.map(option => (
          <Select.Option key={option.code} value={option.code}>
            {option.value}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
