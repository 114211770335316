import React from 'react'
import numeral from 'numeral'
import { ChartCard, Dot, Field, StatusDetails } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/chart-related'
import { Trend } from './components'
import { SummaryCardProps } from './props'
import styles from './SummaryCard.scss'

export const SummaryCard = ({ loading, data, percentageChange, percentageChangeTooltipText, headerString, footerString, includeStatusOther = true }: SummaryCardProps) => {
    const { total, successful, successfulPercent, failed, failedPercent, other, otherPercent, average } = data || {}

    const getFlagIcon = () => {
        if (percentageChange > 0) return 'up'
        if (percentageChange < 0) return 'down'
        return 'none'
    }

    return <ChartCard
        bordered={false}
        title={headerString}
        label={<Trend
            flag={getFlagIcon()}
            text={`${numeral(Math.abs(percentageChange)).format('0.00')}%`}
            tooltipText={percentageChangeTooltipText}
        />}
        loading={loading}
        total={() => total}
        footer={
            <Field
                label={footerString}
                value={<>
                    <span className={styles.average}><Dot status={'successful'} />{average?.successful}</span>
                    <span className={styles.average}><Dot status={'failed'} /> {average?.failed} </span>
                    {includeStatusOther && (
                        <span className={styles.average}><Dot status={'other'} /> {average?.other} </span>
                    )}
                </>}
            />
        }
    >
        <StatusDetails status='successful' percent={successfulPercent} value={successful}/>
        <StatusDetails status='failed' percent={failedPercent} value={failed}/>
        {includeStatusOther && (
            <StatusDetails status='other' percent={otherPercent} value={other}/>
        )}
    </ChartCard>
}
