import translations from '../translations'

export const productTypeOptions = [
  {
    label: 'POS',
    value: 'POS'
  }
]

export const contractType = [
  {
    key: 'subscription',
    label: translations().subscription,
    value: 'subscription'
  },
  {
    key: 'terminalPurchase',
    label: translations().terminalPurchase,
    value: 'terminalPurchase'
  },
  {
    key: 'ownTerminal',
    label: translations().ownTerminal,
    value: 'ownTerminal'
  }
]
export const subscriptionServicePeriodOptions = [
  {
    key: '12',
    value: '12'
  },
  {
    key: '18',
    value: '18'
  },
  {
    key: '24',
    value: '24'
  },
  {
    key: '36',
    value: '36'
  },
  {
    key: '48',
    value: '48'
}
]

export const maxPhoneLength = 10

export const UKPhonePrefix = '+44'

export const phonePlaceHolder = '1122334455'