import translations from './translations'

translations.add('en', {
  newApplication: 'New Application',
  locationAddress: 'Location address',
  addressError: 'Total length of Address and Town/City must not exceed 40 characters',
  address: 'Address',
  postalCode: 'Postal Code',
  country: 'Country',
  townOrCity: 'Town or City',
  incompleteData: 'Incomplete data. Please enter manually',

  storeInfo: 'Outlet Info',
  storeAddress: {
    title: 'Trading Address',
    titleSystem: 'System Trading Address',
    cityLabel: 'Town/City',
    cityRequired: 'town/city is required',
    cityInvalid: 'only alphanumeric characters are allowed',
    countryLabel: 'Country',
    countryRequired: 'country is required',
    regionLabel: 'County',
    postalCodeLabel: 'Postcode',
    postalCodeRequired: 'postcode is required',
    postalCodeInvalid: 'invalid postcode',
    addressLine1Label: 'Address line 1',
    addressLine1Required: 'address line 1 is required',
    addressLine2Label: 'Address line 2 ',
    addressLine2Required: 'address line 2 is required',
    countryTooltip: 'We are currently only able to work with UK registered businesses'
  },
  addNewStore: 'Add New Store',
  addAnotherStore: 'Add Another Store',
  natureOfBusinessLabel: 'What are you taking card payments for?',
  natureOfBusinessRequired: 'This field is required',
  natureOfBusinessPlaceholder: 'Enter the nature of business, e.g.: retail, food',
  tradingName: 'Trading name',
  systemDescriptor: 'System descriptor',
  addProductMessage: 'You need to add at least one product',
  storeNamePlaceholder: 'Enter store name',
  systemDescriptorPlaceholder: 'Enter system descriptor',
  industry: 'Industry',
  industryPlaceholder: 'Select industry',
  mcc: 'MCC',
  mccPlaceholder: 'Select MCC',
  required: 'Required',
  productAlreadyExists: 'This product already exists',
  settlementAccount: 'Select Bank Account for settlements',
  assingAccount: 'Assign bank account',
  titleDirectDebit: 'Assign DIRECT DEBIT Bank Account',
  titleSettlements: 'Assign SETTLEMENTS Bank Account',
  directDebitAccount: 'Select Bank Account for direct debit',
  systemStoreAddressDescription:
    '(!) System store address (will be reflected on Cardholders statements and in banking apps)',
  systemDescriptorDescription:
    '(!) System descriptor (will be reflected on Cardholders statements and in banking apps)',
  industryAndMCC: 'Industry and MCC',
  maxLength24: 'Max length 24 symbols',
  outletInformation: 'Outlet Information',
  assignBankDetails: 'Assign Bank Details'
})
