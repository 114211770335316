import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Card, Table, Drawer, Grid } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { BPMProcessFilterDossierV2 } from '../shared/BPMProcessDossierV2/components'
import { MotoVtRequestsDrawerTitle } from './components/MotoVtRequestsDrawerTitle'
import { MotoVtProcessDetails } from './components/MotoVtProcessDetails/MotoVtProcessDetails'
import { IMotoVtRequestsStore } from './IMotoVtRequestsStore'
import { columns } from './constants/constants'
import styles from './MotoVtRequests.scss'

export const MotoVtRequestsStoreSymbol = Symbol('MotoVtRequestsStore')
export const MotoVtRequestsRangePickerStoreSymbol = Symbol('MotoVtRequestsRangePickerStore')
export const MotoVtRequestsMerchantSelectStoreSymbol = Symbol('MotoVtRequestsMerchantSelectStore')

export const MotoVtRequests = observer(() => {
  const screens = Grid.useBreakpoint()
  const store = useInjection<IMotoVtRequestsStore>(MotoVtRequestsStoreSymbol)
  const dataSource = store.processes && store.processes.map((application, idx) => ({ key: idx, ...application }))

  useEffect(() => {
    store.init()
  }, [])

  return (
    <>
      <BPMProcessFilterDossierV2
        BPMProcessesStoreSymbol={MotoVtRequestsStoreSymbol}
        BPMProcessesRangePickerStoreSymbol={MotoVtRequestsRangePickerStoreSymbol}
        BPMProcessesMerchantSelectStoreSymbol={MotoVtRequestsMerchantSelectStoreSymbol}
      />
      <Card className={styles.tableCard}>
        <Table
          loading={store.isProcessesLoading}
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: 200 }}
          rowClassName={styles.row}
          onRow={record => {
            return {
              onClick: () => {
                store.loadProcessDetails(record.id)
                store.showInfoDrawer()
              }
            }
          }}
          pagination={{
            total: store.total,
            pageSize: store.pageSize,
            current: store.currentPage,
            onChange: (page, pageSize) => store.loadProcesses({ page, pageSize }),
            size: 'small'
          }}
        />
      </Card>
      <Drawer
        title={store.dataLoadingStatus === 'finished' ? <MotoVtRequestsDrawerTitle /> : null}
        placement='right'
        closable={false}
        onClose={() => store.closeInfoDrawer()}
        open={store.isShowInfoDrawer}
        width={screens.xs ? 256 : 512}
      >
        <MotoVtProcessDetails />
      </Drawer>
    </>
  )
})
