import { Tooltip } from 'antd'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { NowrapProps } from './props'

export const Nowrap: React.FC<PropsWithChildren<NowrapProps>> = props => {
  const { width, title, isBlock, children } = props
  const ref = useRef<HTMLSpanElement>()
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const display = isBlock ? 'block' : 'inline-block'
  const style: React.CSSProperties = {
    width,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }

  useEffect(() => {
    if (ref.current?.getBoundingClientRect().width > width) {
      setIsTooltipVisible(true)
    }
  }, [])

  return isTooltipVisible ? (
    <Tooltip title={title || children}>
      {React.isValidElement(children) ? (
        React.cloneElement<any>(children, { style })
      ) : (
        <span style={{ ...style, display }}>{children}</span>
      )}
    </Tooltip>
  ) : (
    <span ref={ref} style={{ whiteSpace: 'nowrap' }}>
      {children}
    </span>
  )
}
