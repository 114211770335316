import React from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { RangePicker } from '~/code/components'
import { IRangePickerContainerStore } from './IRangePickerContainerStore'
import { RangePickerContainerProps } from '~/code/pages/settlements/SettlementsPayments/components/RangePickerContainer/RangePickerContainerProps'

export const RangePickerContainerStoreSymbol = Symbol('RangePickerContainerStore')

export const RangePickerContainer = observer(
  ({
    injectableStoreIdentifier = RangePickerContainerStoreSymbol,
    includeHoursAndMins = false
  }: RangePickerContainerProps) => {
    const store = useInjection<IRangePickerContainerStore>(injectableStoreIdentifier)

    // TODO refactor create RangePicker wrapper DAS-203 https://dnapayments.atlassian.net/browse/DAS-203

    return (
      <RangePicker
        startDate={store.startDate}
        endDate={store.endDate}
        period={store.period}
        onSetPeriod={store.setPeriod}
        onSetDates={store.setDates}
        disabledDate={store.disabledDate}
        disabled={store.disabled}
        tooltipTitle={store.disabledTooltipTitle}
        includeHoursAndMins={includeHoursAndMins}
      />
    )
  }
)
