import { getWithAuth, postWithAuth, patchWithAuth, deleteWithAuth, putWithAuth } from '~/code/services'
import {
  CreateMerchantLoginResponseType,
  FetchLoginsParamsType,
  CreateMerchantLoginParamsType,
  FetchMerchantsParamsType,
  FetchMerchantsResponseType,
  FetchMerchantsByLoginResponseType,
  StoreType,
  LoginsResponseType,
  UpdateLoginParamsType,
  UpdateLoginResponseType,
} from '~/code/stores/PortalAccessStore/types'
import {  EnforceTwoFAStatusParamsType } from '~/code/stores/Profile/models'

const BASE_URL = '/api/v1/dashboard'

export const fetchLogins = (params: FetchLoginsParamsType) => {
  const { merchantId, ...rest } = params
  return getWithAuth<LoginsResponseType>(`${BASE_URL}/merchants/${merchantId}/logins`, { ...rest })
}

export const fetchMerchantsByLogin = (params: FetchMerchantsParamsType) => {
  const { value, ...rest } = params
  return getWithAuth<FetchMerchantsByLoginResponseType>(`${BASE_URL}/portal-logins/${value}/merchants`, {
    ...rest
  })
}

export const fetchStoresByMerchant = (merchantId: string) => {
  return getWithAuth<StoreType[]>(`/api/v1/merchants/stores`, { merchantId })
}

export const fetchMerchants = (params: FetchMerchantsParamsType) => {
  return getWithAuth<FetchMerchantsResponseType>(`${BASE_URL}/merchants`, { ...params })
}

export const createLogin = (params: CreateMerchantLoginParamsType) => {
  const { merchantId, ...data } = params
  return postWithAuth<CreateMerchantLoginResponseType>(`${BASE_URL}/merchants/${merchantId}/logins`, { ...data })
}

export const updateLogin = (id: string, params: UpdateLoginParamsType) =>
  patchWithAuth<UpdateLoginResponseType>(`${BASE_URL}/portal-logins/${id}`, params)

export const deleteLogin = (publicId: string, merchantId: string) =>
  deleteWithAuth<any>(`${BASE_URL}/portal-logins/${publicId}?merchantId=${merchantId}`)

export const updateTwoFASettings = (id: string, params: EnforceTwoFAStatusParamsType) =>
  putWithAuth(`${BASE_URL}/portal-logins/${id}/2fa/settings`, params)
