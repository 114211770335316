import translations from './translations'

translations.add('en', {
    selectTemplate: 'Select Template',
    selectRecipient: 'Select Recipient',
    publishNotification: 'Publish Notification',
    preview: 'Preview',
    web: 'Web',
    mobile: 'Mobile',
    recipientsPreview: 'Recipients Preview',
    notificationsPreview: 'Notification Preview',
    startDate: 'Start Date',
    endDate: 'End Date',
    publishToAll: 'Publish To All',
    publishToSelected: 'Publish To Selected',
    acquisitionChannel: 'Acquisition Channel',
    merchantName: 'Merchant Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    userRole: 'User Role',
    templateDetails: 'Template Details',
    maintenanceIncident: 'Maintenance/Incident',
    push: 'Push',
    marketing: 'Marketing',
    publish: 'Publish',
    next: 'Next',
    back: 'Back'
})
