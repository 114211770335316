import { injectable } from 'inversify'
import { action, makeObservable, observable, computed } from 'mobx'
import { IPartnerAccessStore } from '~/code/pages/PartnerAccess'
import { PartnerAccessTabKeyType } from '~/code/pages/PartnerAccess/types'
import { PartnerLoginType, PartnerType } from '~/code/stores/PartnerAccessStore/types'

@injectable()
export class PartnerAccessStore implements IPartnerAccessStore {
  activeTab: PartnerAccessTabKeyType = PartnerAccessTabKeyType.partners
  selectedPartner: PartnerType = null
  selectedEmail: string = null
  selectedTableItem: PartnerLoginType = null
  isLoginDrawerOpen: boolean = false
  isPartnerDrawerOpen: boolean = false
  isSecurityDrawerOpen: boolean = false
  isPartnerDrawerEdit: boolean = false

  constructor() {
    makeObservable(this, {
      activeTab: observable,
      selectedEmail: observable,
      selectedPartner: observable,
      selectedTableItem: observable,
      isLoginDrawerOpen: observable,
      isPartnerDrawerOpen: observable,
      isSecurityDrawerOpen: observable,
      isPartnerDrawerEdit: observable,

      setActiveTab: action.bound,
      onLoginDrawerOpen: action.bound,
      setSelectedPartner: action.bound,
      onLoginDrawerClose: action.bound,
      onPartnerDrawerOpen: action.bound,
      setSelectedTableItem: action.bound,
      onPartnerDrawerClose: action.bound,
      onSecurityDrawerClose: action.bound,
      onSecurityDrawerOpen: action.bound,

      isEditMode: computed,
      isPartnerTabActive: computed
    })
  }

  get isEditMode(): boolean {
    return !!this.selectedTableItem
  }

  get isPartnerTabActive(): boolean {
    return this.activeTab === PartnerAccessTabKeyType.partners
  }

  setActiveTab = (tab: PartnerAccessTabKeyType) => {
    this.activeTab = tab
  }

  setSelectedPartner = (partner: PartnerType) => {
    this.selectedPartner = partner
  }

  setSelectedEmail = (email: string) => {
    this.selectedEmail = email
  }

  setSelectedTableItem = (item: PartnerLoginType) => {
    this.selectedTableItem = item
  }

  onLoginDrawerOpen = () => {
    this.isLoginDrawerOpen = true
  }

  onLoginDrawerClose = () => {
    this.isLoginDrawerOpen = false
    this.setSelectedTableItem(null)
  }

  onPartnerDrawerOpen = (isEdit: boolean) => {
    this.isPartnerDrawerEdit = isEdit
    this.isPartnerDrawerOpen = true
  }

  onPartnerDrawerClose = () => {
    this.isPartnerDrawerEdit = false
    this.isPartnerDrawerOpen = false
  }

  onSecurityDrawerClose = () => {
    this.isSecurityDrawerOpen = false
  }

  onSecurityDrawerOpen = () => {
    this.isSecurityDrawerOpen = true
  }

  resetTabs = () => {
    this.setSelectedPartner(null)
    this.setSelectedEmail(null)
    this.setSelectedTableItem(null)
  }
}
