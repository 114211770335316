import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    newApplication: string
    locationAddress: string
    addressError: string
    address: string
    postalCode: string
    country: string
    townOrCity: string
    incompleteData: string
    fastTrack: string
    msaSigned: string
}>()

