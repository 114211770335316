import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  applicantInfo: string
  companyProfile: string
  stores: string
  tarriffs: string
  businessOwners: string
  companyDirectors: string
  individualShareholders: string
  corporateShareholders: string
  companyOwners: string
  view: string
  json: string
  copied: string
  company: string
  companyAuthorisedSignatories: string
  terminalOrders: string
}>()
