import { SelectItem } from '~/code/models'
import { Terminal } from '../models'
import translations from '../translations'

export const terminalsToOptions = (terminals: Terminal[]): SelectItem[] => {
    return terminals?.map(terminal => ({
        value: terminal.number,
        label: terminal.number === 'all' ? translations().all : terminal.number
    }))
}

export const midsToOptions = (mids: string[]): SelectItem[] => {
    return mids?.map(mid => ({
        value: mid,
        label: mid === 'all' ? translations().all : mid
    }))
}
