import React from 'react'
import { BPMProcessDossierV2 } from '../shared'
import { columns } from './constants'

export const AdjustmentRequestsStoreSymbol = Symbol('AdjustmentRequestsStore')
export const AdjustmentRequestsRangePickerStoreSymbol = Symbol('AdjustmentRequestsRangePickerStore')
export const AdjustmentRequestsMerchantSelectStoreSymbol = Symbol('AdjustmentRequestsMerchantSelectStore')

export const AdjustmentRequests = () => (
  <BPMProcessDossierV2
    BPMProcessesStoreSymbol={AdjustmentRequestsStoreSymbol}
    BPMProcessesRangePickerStoreSymbol={AdjustmentRequestsRangePickerStoreSymbol}
    BPMProcessesMerchantSelectStoreSymbol={AdjustmentRequestsMerchantSelectStoreSymbol}
    columns={columns}
  />
)
