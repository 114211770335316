import { Card, Col, Row, Select } from 'antd'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import { BpmAnalyticsStore } from '../../BpmAnalyticsStore'
import { round, timeToString } from '../../services/utils'
import translations from './translations'
import styles from './BpmAnalyticsHeader.scss'
import { HandbooksStore } from '~/code/stores/HandbooksStore'

export const BpmAnalyticsHeader = observer((props: { store: BpmAnalyticsStore, handbooksStore: HandbooksStore }) => {
    const { store, handbooksStore } = props

    const sum = useMemo(() => store.data.reduce((prev, item) => {
        return !item.avgReviewTime ? prev : {
            avgReviewTime: prev.avgReviewTime + item.avgReviewTime,
            target: prev.target + item.target
        }
    }, { target: 0, avgReviewTime: 0}), [store.data])

    const totalPercentStr = useMemo(() => {
        if (!sum.avgReviewTime) {
            return '-'
        }
        if (sum.target > 60 || sum.avgReviewTime > 60) {
            return `${round(round(sum.target / 60) / round(sum.avgReviewTime / 60) * 100)} %`
        } else {
            return `${round(sum.target * 100 / sum.avgReviewTime)} %`
        }
    }, [sum.avgReviewTime, sum.target])

    return <Card bordered={false} className={styles.BpmAnalyticsHeader}>
        <Row>
            <Col lg={4} sm={12} xs={24}>
                <Info title={translations().totalApp} value={store.totalApplications || '-'} bordered />
            </Col>
            <Col lg={5} sm={12} xs={24}>
                <Info title={translations().avgReviewTime} value={sum.avgReviewTime ? timeToString(sum.avgReviewTime) : '-'} bordered />
            </Col>
            <Col lg={5} sm={12} xs={24}>
                <Info title={translations().target} value={sum.target ? timeToString(sum.target) : '-'} bordered />
            </Col>
            <Col lg={5} sm={12} xs={24}>
                <Info title={translations().performance} value={ totalPercentStr } bordered />
            </Col>
            <Col lg={5} sm={12} xs={24}>
                <AcquisitionChannel title={translations().acquisitionChannel} store={store} handbooksStore={handbooksStore} />
            </Col>
        </Row>
    </Card>
})

const Info: React.FC<{ title: React.ReactNode, value: React.ReactNode, bordered?: boolean }> = (props) => {
    const { title, value, bordered } = props
    return <div className={styles.Info}>
        <span>{title}</span>
        <p>{value}</p>
        {bordered && <em />}
    </div>
}

const AcquisitionChannel: React.FC<{ title: React.ReactNode, store: BpmAnalyticsStore, handbooksStore: HandbooksStore }> = observer((props) => {
    const { title, store, handbooksStore } = props
    return <div className={styles.AcquisitionChannel}>
        <span>{title}</span>
        <Select
            className={styles.acquisitionChannelSelect}
            value={store.acquisitionChannel}
            onChange={(v) => store.setAcquisitionChannel(v)}
            loading={handbooksStore.isAcquisitionChannelsLoading}
            options={handbooksStore.acquisitionChannelsWithAll.map((m) => ({ value: m.name, label: m.description }))}
        />
    </div>
})
