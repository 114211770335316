import translations from './translations'
import React from 'react'

translations.add('en', {
  closureOfTerminal: 'Closure of Terminal',
  terminals: 'Terminals',
  terminalsRequired: 'Terminals are required',
  reasonLabel: 'Reason',
  reasonRequired: 'Reason is required',
  reasonMinMsg: 'Comment must be at least 2 characters',
  submit: 'Submit',
  areYouSureCloseTerminals: (terminals, merchant) => (
    <>
      Are you sure you want to close terminals:
      <ul>
        {terminals.map(t => (
          <li key={t}>{t}</li>
        ))}
      </ul>
      of merchant {merchant}?
    </>
  ),
  closeAllterminalsNotification: 'If you want to close all terminals, you should close Merchant',
  terminationFee: 'Termination Fee (£)',
  required: 'Required',
  valueRestriction: 'Value should be between 0 and 1000'
})
