import { inject, injectable } from 'inversify'
import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx'
import { message, FormInstance } from 'antd'
import { log } from 'dna-common'
import { PeriodType } from '~/code/models'
import { PAGE_SIZE_10 } from '~/code/constants/Configurations'
import commonTranslations from '~/code/translations'
import { RangePickerStoreInjectable } from '~/code/stores/RangePickerStoreInjectable'
import { MerchantClosureApplicationType } from '~/code/pages/BPMProcesses/Requests/models'
import {
  getProcessStatuses,
  getSubmittedApplicationsClosureOfMerchant,
  cancelMerchantClosure
} from '~/code/pages/BPMProcesses/Requests/services'
import {
  ClosureOfMerchantMerchantSelectStoreSymbol,
  ClosureOfMerchantRangePickerStoreSymbol
} from '~/code/pages/BPMProcesses/Requests/components/ClosureOfMerchant'
import { getFullData } from '~/code/stores/MotoVtRequestsStore/services/getters'
import { IClosureOfMerchantStore } from '~/code/pages/BPMProcesses/Requests/components/ClosureOfMerchant/IClosureOfMerchantStore'
import { CancelMerchantClosureType } from '~/code/pages/BPMProcesses/Requests/models'
import { MerchantSelectStore } from '../MerchantsManagementStore/MerchantSelectStore'
import { stringType } from '../MerchantsManagementStore/constants'
import translations from './translations'

@injectable()
export class ClosureOfMerchantStore implements IClosureOfMerchantStore {
  dateStore: RangePickerStoreInjectable
  merchantSelectStore: MerchantSelectStore
  isProcessesLoading: boolean
  isStatusesLoading: boolean
  total: number
  processes: MerchantClosureApplicationType[]
  statuses: { label: string; value: string }[]
  status: string | null = null
  currentPage: number
  pageSize: number
  isShowInfoDrawer: boolean
  selectedProcess: MerchantClosureApplicationType
  isSubmitLoading: boolean

  constructor(
    @inject(ClosureOfMerchantRangePickerStoreSymbol) rangePickerStoreInjectable: RangePickerStoreInjectable,
    @inject(ClosureOfMerchantMerchantSelectStoreSymbol) merchantSelectStore: MerchantSelectStore
  ) {
    this.dateStore = rangePickerStoreInjectable
    this.merchantSelectStore = merchantSelectStore
    this.isProcessesLoading = false
    this.isStatusesLoading = false
    this.isShowInfoDrawer = false
    this.selectedProcess = null
    this.isSubmitLoading = false
    this.processes = []
    this.statuses = []
    this.currentPage = 1
    this.pageSize = PAGE_SIZE_10
    this.total = 0

    makeObservable(this, {
      isProcessesLoading: observable,
      isStatusesLoading: observable,
      processes: observable,
      statuses: observable,
      status: observable,
      currentPage: observable,
      pageSize: observable,
      total: observable,
      isShowInfoDrawer: observable,
      selectedProcess: observable,
      isSubmitLoading: observable,

      merchant: computed,
      dataSource: computed,

      init: action.bound,
      loadProcesses: action.bound,
      loadStatuses: action.bound,
      setStatus: action.bound,
      showInfoDrawer: action.bound,
      closeInfoDrawer: action.bound,
      setSelectedProcess: action.bound
    })

    autorun(() => {
      if (this.dateStore.startDate && this.dateStore.endDate && this.merchant) {
        this.loadProcesses({ page: 1 })
      }
    })
  }

  get merchant() {
    return this.merchantSelectStore.selectedMerchant?.companyName
  }

  showInfoDrawer = (value: MerchantClosureApplicationType) => {
    this.setSelectedProcess(value)
    this.isShowInfoDrawer = true
  }

  closeInfoDrawer = () => {
    this.setSelectedProcess(null)
    this.isShowInfoDrawer = false
  }

  setSelectedProcess = (value: MerchantClosureApplicationType | null) => {
    this.selectedProcess = value
  }

  async init() {
    if (this.dateStore.period === null) {
      this.dateStore.setPeriod(PeriodType.Day)
    } else {
      this.loadProcesses({ page: 1 })
    }
    this.loadStatuses()
  }

  setStatus(status: string | null) {
    this.status = status
    this.loadProcesses({ page: 1 })
  }

  async loadProcesses({ page, pageSize }: { page?: number; pageSize?: number }) {
    if (page) {
      this.currentPage = page
    }
    if (pageSize) {
      this.pageSize = pageSize
    } else this.pageSize = PAGE_SIZE_10

    this.isProcessesLoading = true

    const { status, result, error } = await getSubmittedApplicationsClosureOfMerchant({
      from: this.dateStore.startDate.clone().utc().format(),
      to: this.dateStore.endDate.clone().utc().format(),
      status: this.status || undefined,
      companyName: this.merchant === 'All' ? undefined : this.merchant,
      processName: 'dossierclose',
      page: this.currentPage,
      size: this.pageSize
    })
    if (status === 200) {
      this.isProcessesLoading = false
      this.processes = result.report
      this.total = result.totalCount
      return
    }
    this.isProcessesLoading = false
    message.error(error?.message || commonTranslations().defaultErrorMessage)
  }

  async loadStatuses() {
    this.isStatusesLoading = true
    const { status, result, error } = await getProcessStatuses('merchant-closure')
    if (status === 200) {
      this.isStatusesLoading = false
      this.statuses = getFullData(result.map(item => ({ label: item.description, value: item.name })))
      return
    }
    this.isStatusesLoading = false
    message.error(error?.message || commonTranslations().defaultErrorMessage)
  }

  get dataSource() {
    return this.processes && this.processes.map((application, idx) => ({ key: idx, ...application }))
  }

  createCancelMerchantClosureData(comment: string): CancelMerchantClosureType {
    const { id, initiator } = this.selectedProcess
    return {
      variables: {
        closureProcessId: {
          value: id,
          type: stringType
        },
        initiatorEmail: {
          value: initiator,
          type: stringType
        },
        comment: {
          value: comment,
          type: stringType
        }
      }
    }
  }

  onFormFinish = async (form: FormInstance) => {
    try {
      runInAction(() => {
        this.isSubmitLoading = true
      })

      const { comment } = form.getFieldsValue()
      const { id, initiator } = this.selectedProcess

      if (!id) {
        message.error(translations().processIdIsEmpty)
        return
      }

      if (!initiator) {
        message.error(translations().initiatorEmailIsEmpty)
        return
      }

      const cancelCloureReq = this.createCancelMerchantClosureData(comment)
      const { status, error, result } = await cancelMerchantClosure(cancelCloureReq)

      if (status !== 200 || error) {
        message.error(error?.message || translations().errCancelMerchantClosure)
      } else {
        message.success(result?.message || translations().applicationSuccessful)
        form.resetFields()
        this.closeInfoDrawer()
        this.loadProcesses({ page: 1 })
      }
    } catch (error) {
      message.error(error?.message || translations().requestFail)
      log(error)
    } finally {
      runInAction(() => {
        this.isSubmitLoading = false
      })
    }
  }
}
