import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    status: string
    transactionChannel: string
    paymentMethod: string
    issuerCountry: string
    ipCountry: string
    nonUK: string
    payerIp: string
    payerEmail: string
    cardMask: string
    terminal: string
    reference: string
    mid: string
    merchantReference: string
    threeDSVersion: string
    payerAuthenticationResult: string
    validationMinLength: string
    transactionId: string
    processingType: string
    cardScheme: string
    signifydDecision: string
    any: string
    amountFrom: string
    amountTo: string
    amount: string
}>()
