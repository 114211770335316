import React from 'react'
import { Col, Row } from 'antd'
import classNames from 'classnames'
import translations from './translations'
import { StatusDetailsProps } from './porps'
import styles from './StatusDetails.scss'
import { Dot } from '../Dot'

export const StatusDetails: React.FC<StatusDetailsProps> = ({
                                                status,
                                                title,
                                                percent,
                                                value,
                                                className,
                                                ...rest
                                            }) => {
    return (
        <div className={classNames(styles.StatusDetails, className)}>
            <Row gutter={16}>
                <Col xs={1}>
                    {status && <Dot status={status} />}
                </Col>
                <Col xs={8} style={{ paddingRight: '0' }}>
                    <span className={styles.status}>{title || translations()[status]}</span>
                </Col>
                <Col xs={6}>
                    <span className={styles.percent}>
                        {percent}
                    </span>
                </Col>
                <Col xs={8}>
                    <span className={styles.value}>{value}</span>
                </Col>
            </Row>
        </div>
    )
}
