import translations from './translations'

translations.add('en', {
  formFields: { 
    partnerName: 'Partner name',
    parentPartner: 'Parent partner',
    financialInstitution: 'Financial institution',
    grantedPermissions: 'Permissions settings'
  },
  formErrors: {
    partnerNameRequired: 'partner name is required',
    financialInstitutionRequired: 'financial institution is required'
  }
})
