import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    dailyAmount: string
    dailyCount: string
    dailyMaxRefundAmount: string
    monthlyAmount: string
    monthlyCount: string
    perRefund: string
    perTransaction: string
}>()

