import { ColumnsType } from 'antd/lib/table'
import { IcTariffType } from '../../../models/IcTariffType'
import translations from '../../../translations'
import React from 'react'
import { renderCell } from '../services/ui-utils'
import { getMarkup } from '../../../services'
import { Form } from 'antd'
import translations2 from '../translations'

export const ICPlusTableColumns: ColumnsType<IcTariffType> = [
  {
    title: translations().transactionType,
    children: [
      {
        key: 'cardScheme',
        dataIndex: 'cardScheme',
        title: translations().cardScheme
      },
      {
        key: 'serviceArea',
        dataIndex: 'serviceArea',
        title: translations().serviceArea,
        width: 200
      },
      {
        key: 'cardType',
        dataIndex: 'cardTypeFull',
        title: translations().cardType,
        width: 130
      }
    ]
  },
  {
    title: translations2().interchangeFee,
    children: [
      {
        key: 'interchangeFee',
        dataIndex: 'interchangeFee',
        title: translations().percentFee,
        render: v => renderCell([v.key, 'percent'])
      }
    ]
  },
  {
    title: translations2().schemeFee,
    children: [
      {
        key: 'schemeBaseFee',
        dataIndex: 'schemeFee',
        title: translations().baseFee,
        render: v => renderCell([v.key, 'base'])
      },
      {
        key: 'schemePercentFee',
        dataIndex: 'schemeFee',
        title: translations().percentFee,
        render: v => renderCell([v.key, 'percent'])
      }
    ]
  },
  {
    title: () => <div>{translations2().dnaStandardFee}</div>,
    children: [
      {
        title: translations2().headlineRate,
        render: (_, row) => renderCell([getMarkup(row), 'percent'])
      }
    ]
  },
  {
    title: translations2().totalFee,
    children: [
      {
        key: 'totalBaseFee',
        dataIndex: 'totalBaseFee',
        title: translations().baseFee,
        render: (_, row) => <strong>{row.schemeFee.baseFee}</strong>
      },
      {
        key: 'totalPercentFee',
        dataIndex: 'totalPercentFee',
        title: translations().percentFee,
        render: (_, row) => {
          return (
            <Form.Item shouldUpdate noStyle>
              {form => {
                const interchangeFee = Number(form.getFieldValue([row.interchangeFee.key, 'percent']) || 0)
                const schemeFee = Number(form.getFieldValue([row.schemeFee.key, 'percent']) || 0)
                const dnaFee = Number(form.getFieldValue([getMarkup(row), 'percent']) || 0)

                return <strong>{(interchangeFee + schemeFee + dnaFee).toFixed(3)}</strong>
              }}
            </Form.Item>
          )
        }
      }
    ]
  }
]
