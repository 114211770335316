import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Form, Drawer, DrawerProps, Grid, Button } from 'antd'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { PortalAccessStoreType } from '~/code/pages/PortalAccess/types/PortalAccessStoreType'
import { PortalAccessStoreSymbol } from '~/code/pages/PortalAccess/'
import { PortalAccessAddEditLogin } from './PortalAccessAddEditLogin'

import translations from './translations'
import styles from './styles.scss'

const { useBreakpoint } = Grid

export type PortalAccessDrawerProps = DrawerProps

export const PortalAccessDrawer = observer((props: PortalAccessDrawerProps) => {
  const { open, ...rest } = props
  const [form] = Form.useForm()
  const screens = useBreakpoint()

  const store = useInjection<PortalAccessStoreType>(PortalAccessStoreSymbol)
  const {
    selectedMerchant,
    searchLoginValue,
    selectedTableItem,
    isMerchantsTabActive,
    isLoginsSending,
    closeUserAccessDrawer
  } = store

  const isEditMode = Boolean(selectedTableItem?.merchantId)

  useEffect(() => {
    if (!isMerchantsTabActive && !open) {
      store.resetStores()
    }

    form.resetFields()
  }, [open])

  const getTitle = () => {
    if (isEditMode) return translations().edit

    return isMerchantsTabActive ? selectedMerchant?.companyName : searchLoginValue
  }

  const renderFooter = () => (
    <div className={styles.footer}>
      <Button onClick={closeUserAccessDrawer}>{translations().cancel}</Button>
      <Button
        htmlType='button'
        type='primary'
        loading={isLoginsSending}
        disabled={!hasPermissions([PermissionMap.portalAccess.create])}
        onClick={() => form.submit()}
      >
        {translations().submit}
      </Button>
    </div>
  )

  return (
    <Drawer
      title={getTitle()}
      open={open}
      width={screens.xs ? window.innerWidth : 512}
      footer={renderFooter()}
      {...rest}
    >
      <PortalAccessAddEditLogin form={form} open={open} />
    </Drawer>
  )
})
