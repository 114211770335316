import { action, computed, IComputedValue, makeObservable, observable } from 'mobx'
import { Language, Translation } from '~/code/components/Translation/Translation'

// import storage from '~/services/storage' // TODO connect with storage

class TranslationBuilderSingleton {

    constructor() {
        this._lang = /*storage.get('lang') ||*/ 'en'

        makeObservable(this, {
            _lang: observable,
            lang: computed,
            setLang: action
        })
    }

    _lang: Language

    public get lang() {
        return this._lang
    }

    public setLang(value) {
        // storage.set('lang', value) TODO connect with storage
        this._lang = value
    }

    public create<T>(): Translation<T> {
        const map = new Map<string, T>()
        const currentLanguageMap: IComputedValue<T> = computed(() => map.get(this._lang) || map.get('en'))

        const translation: Translation<T> = () => currentLanguageMap.get()
        translation.add = (lang, value) => {
            map.set(lang, value)
            return translation
        }

        return translation
    }
}

const translationBuilder = new TranslationBuilderSingleton()

export { translationBuilder as TranslationBuilder }
