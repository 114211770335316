import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Row, Select, Radio, Button, Typography, Divider, Input as AntInput, CheckboxRef } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import { Store } from 'antd/lib/form/interface'
import merge from 'lodash/merge'
import { convertBooleanToString, isEmpty, convertStringToBoolean } from 'dna-common'
import { Input, MultiSelect } from '~/code/components'
import { getEEACountries } from '~/code/constants/dictionaries'
import { scrollToFirstError } from '~/code/services/helpers'
import { AccountType, CompanyProfile } from 'startapp/models'
import {
  addCommasForNumbersInput,
  wereQuarterFieldsFilledIn,
  isQuarterTotalNot100,
  editQuarterInput,
  quarterTotal
} from 'startapp/services'
import {
  FormTitle,
  SubLabel,
  FormSubgroupTitle,
  StartAppContainer,
  NextButton,
  BelowActionsWithSave
} from 'startapp/components'
import translations from './translations'
import { FinancialInformationFormProps } from './props'
import styles from './FinancialInformationForm.scss'

const processingMethodsData = [
  {
    key: 'pos',
    description: translations().pos
  },
  {
    key: 'moto',
    description: translations().moto
  },
  {
    key: 'ecom',
    description: translations().ecom
  },
  {
    key: 'payByLink',
    description: translations().payByLink
  },
  {
    key: 'payByApp',
    description: translations().payByApp
  },
  {
    key: 'virtualTerminal',
    description: translations().virtualTerminal
  }
]

const advancePaymentsData = [
  {
    key: 'nbsp',
    description: translations().nbsp
  },
  {
    key: 'deposits',
    description: translations().deposits
  },
  {
    key: 'balances',
    description: translations().balances
  },
  {
    key: 'fullPayment',
    description: translations().fullPayment
  },
  {
    key: 'other',
    description: translations().other,
    hasInput: true
  }
]

export const FinancialInformationForm: React.FC<FinancialInformationFormProps> = props => {
  const {
    companyType,
    data,
    onSubmit,
    isVerifyBankLoading,
    verifyBankAccountResult,
    isSaveLoading,
    isNextLoading,
    setVerifyBankAccountResult,
    verifyBankAccountDetails,
    onSave
  } = props

  const getInitialValues = () =>
    convertDataToFormData(
      merge(new CompanyProfile({}, companyType), data, {
        storeName: data?.companyTradeName?.substring(0, 24),
        registeredOfficeAddress: { country: 'United Kingdom' },
        tradingAddress: { country: 'United Kingdom' }
      })
    )

  useEffect(() => {
    form.setFieldsValue(getInitialValues())
    companyProfileDataRef.current = data
    if (data.bankName) {
      setVerifyBankAccountResult({ type: 'valid', message: data.bankName })
    } else {
      setVerifyBankAccountResult({ type: 'invalid', message: '' })
    }
  }, [data])

  const companyProfileDataRef = useRef<CompanyProfile>(data)

  const [showTradingAddressBlock] = useState(true)
  const [form] = Form.useForm()
  const { getFieldsValue, getFieldValue, setFieldsValue, setFields, resetFields } = form

  useEffect(() => {
    setAdvancePaymentsPercentSum(getSum('advancePayments'))
    setProcessingMethodsPercentSum(getSum('processingMethods'))
    return () => {
      onSave(companyProfileDataRef.current)
    }
  }, [])

  const getSum = (field: string) => {
    const fieldValues = getFieldValue(field)
    if (fieldValues) {
      switch (field) {
        case 'processingMethods':
          return processingMethodsData.reduce((sum, method) => {
            return fieldValues[method.key] && fieldValues[method.key]?.percent
              ? sum + +fieldValues[method.key]?.percent
              : 0
          }, 0)
        case 'advancePayments':
          return advancePaymentsData.reduce((sum, payment) => {
            return fieldValues[payment.key] && fieldValues[payment.key]?.percent
              ? sum + +fieldValues[payment.key]?.percent
              : 0
          }, 0)
        default:
          return 0
      }
    } else return 0
  }

  const [advancePaymentsPercentSum, setAdvancePaymentsPercentSum] = useState<number>(0)
  const [processingMethodsPercentSum, setProcessingMethodsPercentSum] = useState<number>(0)

  const convertDataToFormData = (initialData: CompanyProfile) => {
    return {
      ...initialData,
      tradeOutsideEea: convertBooleanToString(initialData?.tradeOutsideEea) || 'false',
      tradingCountriesOutsideEea: initialData?.tradingCountriesOutsideEea?.slice() || [],
      bankAccountNumber: initialData?.bankAccountNumber?.replace(/(\d{4})(\d{4})/, '$1 $2'),
      sortCode: initialData?.sortCode?.replace(/-/g, ' - '),
      businessModelQuestions: {
        face2face: {
          ...initialData?.businessModelQuestions?.face2face,
          isTrue: convertBooleanToString(initialData?.businessModelQuestions?.face2face?.isTrue),
          courierServiceUsed: convertBooleanToString(initialData?.businessModelQuestions?.face2face?.courierServiceUsed)
        },
        seasonalBusiness: {
          ...initialData?.businessModelQuestions?.seasonalBusiness,
          isTrue: convertBooleanToString(initialData?.businessModelQuestions?.seasonalBusiness?.isTrue)
        }
      }
    }
  }

  // convert form data to company profile data
  const getFormData = () => {
    const values = getFieldsValue()

    const {
      businessModelQuestions,
      bankAccountNumber,
      accountName,
      tradeOutsideEea,
      tradingCountriesOutsideEea,
      sortCode,
      bankName,
      expectedAnnualTurnover,
      expectedAnnualTurnoverCustomValue,
      expectedMonthlyTransactionValues,
      expectedAnnualCardTurnover,
      highestTransactionValue,
      averageTransactionValue,
      advancePayments,
      processingMethods
    } = values

    const tradingAddressSameAsCompanyAddress = !showTradingAddressBlock
    const formData: Store = {
      tradeOutsideEea: convertStringToBoolean(tradeOutsideEea),
      bankAccountNumber: bankAccountNumber?.replace(/\s/g, ''),
      sortCode: sortCode?.replace(/\s/g, ''),
      bankAccountType: companyType === 'company' ? AccountType.Business : values?.bankAccountType,
      processingMethods,
      advancePayments,
      bankName,
      expectedAnnualTurnover,
      expectedAnnualTurnoverCustomValue,
      expectedMonthlyTransactionValues,
      expectedAnnualCardTurnover,
      highestTransactionValue,
      averageTransactionValue,
      tradingCountriesOutsideEea,
      accountName
    }

    if (businessModelQuestions) {
      const { face2face = {}, seasonalBusiness = {} } = businessModelQuestions
      formData.businessModelQuestions = {
        face2face: {
          ...face2face,
          isTrue: convertStringToBoolean(face2face.isTrue),
          courierServiceUsed: convertStringToBoolean(face2face.courierServiceUsed)
        },
        seasonalBusiness: {
          ...seasonalBusiness,
          isTrue: convertStringToBoolean(seasonalBusiness?.isTrue)
        }
      }
    }

    if (tradingAddressSameAsCompanyAddress) {
      formData.tradingAddress = {
        ...formData.registeredOfficeAddress
      }
    }

    return formData as CompanyProfile
  }

  const handleSubmit = () => {
    if (verifyBankAccountResult.type === 'invalid') {
      if (verifyBankAccountResult.message.length <= 0) {
        setVerifyBankAccountResult({ type: 'invalid', message: [translations().validateBankAccount] })
      }
      return
    }
    onSubmit(getFormData())
    resetFields()
  }

  useEffect(() => {
    if (verifyBankAccountResult) {
      const { type, message } = verifyBankAccountResult
      if (type === 'valid') {
        setFieldsValue({ bankName: message as string })
        companyProfileDataRef.current.bankName = message as string
      } else {
        setFieldsValue({ bankName: '' })
        companyProfileDataRef.current.bankName = ''
      }
    }
  }, [verifyBankAccountResult])

  const verifyBankAccount = async () => {
    try {
      await form.validateFields(['bankAccountNumber', 'sortCode'])
    } catch (error) {
      return
    }

    await verifyBankAccountDetails({
      accountNumber: getFieldValue('bankAccountNumber')?.replace(/\s/g, ''),
      sortCode: getFieldValue('sortCode')?.replace(/\s|-/g, '')
    })
  }

  const onBankAccountChange = () => {
    verifyBankAccountResult.type !== 'invalid' && setVerifyBankAccountResult({ type: 'invalid', message: '' })
  }

  const seasonalBusinessIsTrueRef = useRef<CheckboxRef>(null)
  const tradeOutsideEeaRef = useRef<CheckboxRef>(null)
  const face2faceIsTrueRef = useRef<CheckboxRef>(null)
  const face2faceCourierServiceUsedRef = useRef<CheckboxRef>(null)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>, htmlElement: CheckboxRef) => {
    if (e.key === 'Tab') {
      e.preventDefault()
      htmlElement.focus()
    }
  }

  const setProcessingMethodsError = () => {
    setFields([
      {
        name: ['processingMethods', 'pos', 'percent'],
        errors: [translations().totalSum],
        validating: false,
        touched: true,
        value: getFieldValue(['processingMethods', 'pos', 'percent'])
      },
      {
        name: ['processingMethods', 'moto', 'percent'],
        errors: [translations().totalSum],
        validating: false,
        touched: true,
        value: getFieldValue(['processingMethods', 'moto', 'percent'])
      },
      {
        name: ['processingMethods', 'ecom', 'percent'],
        errors: [translations().totalSum],
        validating: false,
        touched: true,
        value: getFieldValue(['processingMethods', 'ecom', 'percent'])
      },
      {
        name: ['processingMethods', 'payByLink', 'percent'],
        errors: [translations().totalSum],
        validating: false,
        touched: true,
        value: getFieldValue(['processingMethods', 'payByLink', 'percent'])
      },
      {
        name: ['processingMethods', 'payByApp', 'percent'],
        errors: [translations().totalSum],
        validating: false,
        touched: true,
        value: getFieldValue(['processingMethods', 'payByApp', 'percent'])
      },
      {
        name: ['processingMethods', 'virtualTerminal', 'percent'],
        errors: [translations().totalSum],
        validating: false,
        touched: true,
        value: getFieldValue(['processingMethods', 'virtualTerminal', 'percent'])
      }
    ])
  }

  const resetProcessingMethodsError = () => {
    setFields([
      {
        name: ['processingMethods', 'pos', 'percent'],
        errors: [],
        value: getFieldValue(['processingMethods', 'pos', 'percent'])
      },
      {
        name: ['processingMethods', 'moto', 'percent'],
        errors: [],
        value: getFieldValue(['processingMethods', 'moto', 'percent'])
      },
      {
        name: ['processingMethods', 'ecom', 'percent'],
        errors: [],
        value: getFieldValue(['processingMethods', 'ecom', 'percent'])
      },
      {
        name: ['processingMethods', 'payByLink', 'percent'],
        errors: [],
        value: getFieldValue(['processingMethods', 'payByLink', 'percent'])
      },
      {
        name: ['processingMethods', 'payByApp', 'percent'],
        errors: [],
        value: getFieldValue(['processingMethods', 'payByApp', 'percent'])
      },
      {
        name: ['processingMethods', 'virtualTerminal', 'percent'],
        errors: [],
        value: getFieldValue(['processingMethods', 'virtualTerminal', 'percent'])
      }
    ])
  }

  const setAdvancePaymentsError = () => {
    setFields([
      {
        name: ['advancePayments', 'nbsp', 'percent'],
        errors: [translations().totalSum],
        validating: false,
        touched: true,
        value: getFieldValue(['advancePayments', 'nbsp', 'percent'])
      },
      {
        name: ['advancePayments', 'deposits', 'percent'],
        errors: [translations().totalSum],
        validating: false,
        touched: true,
        value: getFieldValue(['advancePayments', 'deposits', 'percent'])
      },
      {
        name: ['advancePayments', 'balances', 'percent'],
        errors: [translations().totalSum],
        validating: false,
        touched: true,
        value: getFieldValue(['advancePayments', 'balances', 'percent'])
      },
      {
        name: ['advancePayments', 'fullPayment', 'percent'],
        errors: [translations().totalSum],
        validating: false,
        touched: true,
        value: getFieldValue(['advancePayments', 'fullPayment', 'percent'])
      },
      {
        name: ['advancePayments', 'other', 'percent'],
        errors: [translations().totalSum],
        validating: false,
        touched: true,
        value: getFieldValue(['advancePayments', 'other', 'percent'])
      }
    ])
  }

  const resetAdvancePaymentsError = () => {
    setFields([
      {
        name: ['advancePayments', 'nbsp', 'percent'],
        errors: [],
        value: getFieldValue(['advancePayments', 'nbsp', 'percent'])
      },
      {
        name: ['advancePayments', 'deposits', 'percent'],
        errors: [],
        value: getFieldValue(['advancePayments', 'deposits', 'percent'])
      },
      {
        name: ['advancePayments', 'balances', 'percent'],
        errors: [],
        value: getFieldValue(['advancePayments', 'balances', 'percent'])
      },
      {
        name: ['advancePayments', 'fullPayment', 'percent'],
        errors: [],
        value: getFieldValue(['advancePayments', 'fullPayment', 'percent'])
      },
      {
        name: ['advancePayments', 'other', 'percent'],
        errors: [],
        value: getFieldValue(['advancePayments', 'other', 'percent'])
      }
    ])
  }

  const getTotal = field => {
    const fieldValues = getFieldValue(field)
    switch (field) {
      case 'processingMethods':
        setProcessingMethodsPercentSum(
          processingMethodsData.reduce((sum: number, method) => {
            return fieldValues[method.key]?.percent && !isNaN(fieldValues[method.key]?.percent)
              ? sum + +fieldValues[method.key]?.percent
              : sum + 0
          }, 0)
        )
        return 0
      case 'advancePayments':
        setAdvancePaymentsPercentSum(
          advancePaymentsData.reduce((sum: number, payment) => {
            return fieldValues[payment.key]?.percent && !isNaN(fieldValues[payment.key]?.percent)
              ? sum + +fieldValues[payment.key]?.percent
              : sum + 0
          }, 0)
        )
        return 0
      default:
        return 0
    }
  }

  const checkValues = (field: string) => {
    const fieldValues = getFieldValue(field)

    switch (field) {
      case 'processingMethods':
        return processingMethodsData.every(method => typeof fieldValues[method.key]?.percent !== 'undefined')
      case 'advancePayments':
        return advancePaymentsData.every(payment => typeof fieldValues[payment.key]?.percent !== 'undefined')
      default:
        return null
    }
  }

  const setMonthTransactionNumber = () => {
    const expectedAnnualCardTurnover = getFieldValue('expectedAnnualCardTurnover').split(',').join('')
    const averageTransactionValue = getFieldValue('averageTransactionValue').split(',').join('')

    if (expectedAnnualCardTurnover.length && averageTransactionValue.length) {
      const result = +expectedAnnualCardTurnover / +averageTransactionValue / 12
      setFieldsValue({
        expectedMonthlyTransactionValues: Math.floor(result).toString()
      })
    }
    form.validateFields(['expectedMonthlyTransactionValues'])
  }

  const renderProcessingMethodBlock = () => {
    return processingMethodsData.map(method => (
      <Form.Item
        key={method.key}
        name={[`processingMethods`, method.key, 'percent']}
        label={method.description}
        required={true}
        rules={[
          {
            validator: async (_, rawValue) => {
              if (rawValue < 0 || rawValue > 100) {
                return Promise.reject(new Error(translations().range))
              } else if (!/^\d+$/.test(rawValue)) {
                return Promise.reject(new Error(translations().digitsOnly))
              } else if (checkValues('processingMethods') && getSum('processingMethods') !== 100) {
                setProcessingMethodsError()
              } else {
                resetProcessingMethodsError()
                return Promise.resolve()
              }
            }
          }
        ]}
      >
        <AntInput
          placeholder={translations().cardTurnoverPercent}
          addonAfter='%'
          onChange={() => getTotal('processingMethods')}
        />
      </Form.Item>
    ))
  }

  const renderAdvancePaymentsBlock = () => {
    const renderDescriptionField = (payment, isRequired) => (
      <Form.Item
        name={['advancePayments', payment.key, 'description']}
        rules={[{ required: isRequired, message: translations().enterDetails }]}
      >
        <AntInput placeholder={translations().enterDetails} />
      </Form.Item>
    )

    return advancePaymentsData.map(payment => (
      <Form.Item label={payment.description} required={payment.key !== 'other'}>
        <AntInput.Group>
          <Row gutter={10}>
            <Col xs={12} sm={12}>
              <Form.Item
                key={payment.description}
                name={['advancePayments', payment.key, 'percent']}
                rules={[
                  { required: true, message: translations().required },
                  {
                    validator: async (_, rawValue) => {
                      if (rawValue < 0 || rawValue > 100) {
                        return Promise.reject(new Error(translations().range))
                      } else if (!/^\d+$/.test(rawValue)) {
                        return Promise.reject(new Error(translations().digitsOnly))
                      } else if (checkValues('advancePayments') && getSum('advancePayments') !== 100) {
                        setAdvancePaymentsError()
                      } else {
                        resetAdvancePaymentsError()
                        return Promise.resolve()
                      }
                    }
                  }
                ]}
              >
                <AntInput
                  placeholder={translations().cardTurnover}
                  addonAfter='%'
                  onChange={() => getTotal('advancePayments')}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12}>
              <Form.Item
                name={['advancePayments', payment.key, 'days']}
                key={`${payment.key}Days`}
                rules={[
                  { required: true, message: translations().required },
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message: translations().onlyPositive
                  }
                ]}
              >
                <AntInput placeholder={translations().days} />
              </Form.Item>
            </Col>
          </Row>
        </AntInput.Group>

        {payment.hasInput && (
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return prevValues.advancePayments?.other?.percent !== currentValues.advancePayments?.other?.percent
            }}
          >
            {() => {
              return Number(getFieldValue(['advancePayments', payment.key, 'percent'])) !== 0
                ? renderDescriptionField(payment, true)
                : renderDescriptionField(payment, false)
            }}
          </Form.Item>
        )}
      </Form.Item>
    ))
  }

  return (
    <StartAppContainer>
      <Form
        form={form}
        onFinish={handleSubmit}
        autoComplete='off'
        layout='vertical'
        requiredMark={'optional'}
        className={styles.form}
        scrollToFirstError={scrollToFirstError}
        initialValues={getInitialValues()}
        onValuesChange={() => (companyProfileDataRef.current = getFormData())}
      >
        <FormTitle>{translations().title}</FormTitle>

        <Row gutter={[64, 0]}>
          <Col xs={24}>
            <FormSubgroupTitle>{'Payments'}</FormSubgroupTitle>
            <Form.Item
              name={['businessModelQuestions', 'seasonalBusiness', 'isTrue']}
              label={translations().seasonalBusiness.isTrueLabel}
              rules={[{ required: true, message: translations().seasonalBusiness.isTrueRequired }]}
              validateTrigger={['onChange', 'onBlur']}
            >
              <Radio.Group>
                <Radio value='true'>{translations().seasonalBusiness.yes}</Radio>
                <Radio value='false' ref={seasonalBusinessIsTrueRef}>
                  {translations().seasonalBusiness.no}
                </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {() => {
                if (getFieldValue(['businessModelQuestions', 'seasonalBusiness', 'isTrue']) !== 'true') return null
                const q1 = getFieldValue(['businessModelQuestions', 'seasonalBusiness', 'quarter1'])
                const q2 = getFieldValue(['businessModelQuestions', 'seasonalBusiness', 'quarter2'])
                const q3 = getFieldValue(['businessModelQuestions', 'seasonalBusiness', 'quarter3'])
                const q4 = getFieldValue(['businessModelQuestions', 'seasonalBusiness', 'quarter4'])

                const wereFieldsFilledIn = wereQuarterFieldsFilledIn(q1, q2, q3, q4)

                const isTotalNot100 = wereFieldsFilledIn && isQuarterTotalNot100(q1, q2, q3, q4)

                return (
                  <Form.Item
                    name={['businessModelQuestions', 'seasonalBusiness', 'quarter']}
                    label={translations().seasonalBusiness.qLabel}
                    required
                    rules={[
                      {
                        validator: async () => {
                          if (isTotalNot100) {
                            throw new Error(translations().seasonalBusiness.totalShouldBe100)
                          }
                        }
                      }
                    ]}
                    trigger='onBlur'
                  >
                    <Row gutter={16} className={styles.quarterFieldsContainer}>
                      <Col xs={24} md={5}>
                        <Form.Item
                          name={['businessModelQuestions', 'seasonalBusiness', 'quarter1']}
                          label={translations().seasonalBusiness.q1Label}
                          rules={[{ required: true, message: translations().seasonalBusiness.q1Required }]}
                          validateTrigger={['onChange', 'onBlur']}
                          getValueFromEvent={editQuarterInput}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={5}>
                        <Form.Item
                          name={['businessModelQuestions', 'seasonalBusiness', 'quarter2']}
                          label={translations().seasonalBusiness.q2Label}
                          rules={[{ required: true, message: translations().seasonalBusiness.q2Required }]}
                          validateTrigger={['onChange', 'onBlur']}
                          getValueFromEvent={editQuarterInput}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={5}>
                        <Form.Item
                          name={['businessModelQuestions', 'seasonalBusiness', 'quarter3']}
                          label={translations().seasonalBusiness.q3Label}
                          rules={[{ required: true, message: translations().seasonalBusiness.q3Required }]}
                          validateTrigger={['onChange', 'onBlur']}
                          getValueFromEvent={editQuarterInput}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={5}>
                        <Form.Item
                          name={['businessModelQuestions', 'seasonalBusiness', 'quarter4']}
                          label={translations().seasonalBusiness.q4Label}
                          rules={[{ required: true, message: translations().seasonalBusiness.q4Required }]}
                          validateTrigger={['onChange', 'onBlur']}
                          getValueFromEvent={editQuarterInput}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={4}>
                        <Form.Item label=' ' required>
                          = {quarterTotal(q1, q2, q3, q4)}%
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                )
              }}
            </Form.Item>

            <Form.Item
              name={'expectedAnnualTurnover'}
              key={'expectedAnnualTurnover'}
              label={<>{translations().expectedAnnualTurnoverLabel}</>}
              rules={[{ required: true, message: translations().expectedAnnualTurnoverRequired }]}
              validateTrigger='onBlur'
              getValueFromEvent={addCommasForNumbersInput}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={'expectedAnnualCardTurnover'}
              label={translations().annualCardTurnover}
              rules={[{ required: true, message: translations().required }]}
              getValueFromEvent={addCommasForNumbersInput}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name='averageTransactionValue'
              label={<>{translations().averageTransactionValueLabel}</>}
              rules={[{ required: true, message: translations().averageTransactionValueRequired }]}
              validateTrigger='onBlur'
              getValueFromEvent={addCommasForNumbersInput}
            >
              <Input onChange={() => setMonthTransactionNumber()} />
            </Form.Item>

            <Form.Item
              name='expectedMonthlyTransactionValues'
              label={<>{translations().expectedMonthlyTransactionValuesLabel}</>}
              dependencies={['expectedAnnualTurnover']}
              rules={[
                { required: true, message: translations().expectedMonthlyTransactionValuesRequired },
                {
                  validator: async (rule, value) => {
                    if (isNaN(+value)) {
                      return Promise.reject(new Error(translations().incorrectValue))
                    } else if (+value <= 0) {
                      return Promise.reject(new Error(translations().greaterThanZero))
                    } else {
                      return Promise.resolve()
                    }
                  }
                }
              ]}
              validateTrigger='onBlur'
              getValueFromEvent={addCommasForNumbersInput}
            >
              <Input disabled={true} />
            </Form.Item>

            <Form.Item
              name={'highestTransactionValue'}
              label={translations().highestTransaction}
              rules={[{ required: true, message: translations().required }]}
              validateTrigger='onBlur'
              getValueFromEvent={addCommasForNumbersInput}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name='tradeOutsideEea'
              label={translations().tradeOutsideEeaLabel}
              rules={[{ required: true, message: translations().tradeOutsideEeaRequired }]}
              validateTrigger={['onChange', 'onBlur']}
              tooltip={translations().tradeOutsideEeaTooltip}
            >
              <Radio.Group
                onChange={value =>
                  setTimeout(() => {
                    setFields([{ name: ['tradingCountriesOutsideEea'], value: undefined, touched: true }])
                  }, 0)
                }
              >
                <Radio value='true'>{translations().tradeOutsideEeaOptions.yes}</Radio>
                <Radio
                  value='false'
                  ref={tradeOutsideEeaRef}
                  onKeyDown={e => handleKeyDown(e, face2faceIsTrueRef.current)}
                >
                  {translations().tradeOutsideEeaOptions.no}
                </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => prevValues.tradeOutsideEea !== curValues.tradeOutsideEea}
            >
              {() => (
                <Form.Item
                  name='tradingCountriesOutsideEea'
                  label={translations().tradingCountriesOutsideEeaLabel}
                  tooltip={translations().tradingCountriesOutsideEeaTooltip}
                  rules={[
                    {
                      validator: async (rule, value) => {
                        if (getFieldValue('tradeOutsideEea') === 'true' && isEmpty(value)) {
                          throw new Error(translations().tradingCountriesOutsideEeaRequired)
                        }
                      }
                    }
                  ]}
                >
                  <MultiSelect
                    placeholder={translations().tradingCountriesOutsideEeaLabel}
                    disabled={getFieldValue('tradeOutsideEea') !== 'true'}
                    isSearchable={true}
                    options={getEEACountries().map(item => ({ value: item, label: item }))}
                    onKeyDown={e => handleKeyDown(e, face2faceIsTrueRef.current)}
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item
              name={['businessModelQuestions', 'face2face', 'isTrue']}
              label={translations().businessModelQuestions.isTrueLabel}
            >
              <Radio.Group>
                <Radio value='true'>{translations().businessModelQuestions.yes}</Radio>
                <Radio value='false' ref={face2faceIsTrueRef}>
                  {translations().businessModelQuestions.no}
                </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {() => {
                if (getFieldValue(['businessModelQuestions', 'face2face', 'isTrue']) !== 'true') return null

                return (
                  <>
                    <Form.Item
                      name={['businessModelQuestions', 'face2face', 'deliveryTime']}
                      label={translations().businessModelQuestions.deliveryTimeLabel}
                      rules={[{ required: true, message: translations().businessModelQuestions.deliveryTimeRequired }]}
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <Select onKeyDown={e => handleKeyDown(e, face2faceCourierServiceUsedRef.current)}>
                        {Object.entries(translations().face2faceSelectOptions).map(([key, label]) => (
                          <Select.Option key={key} value={label}>
                            {label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={['businessModelQuestions', 'face2face', 'courierServiceUsed']}
                      label={translations().businessModelQuestions.courierServiceUsedLabel}
                    >
                      <Radio.Group>
                        <Radio value='true'>{translations().businessModelQuestions.yes}</Radio>
                        <Radio value='false' ref={face2faceCourierServiceUsedRef}>
                          {translations().businessModelQuestions.no}
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </>
                )
              }}
            </Form.Item>

            <FormSubgroupTitle>{translations().accountBlockTitle}</FormSubgroupTitle>

            {companyType !== 'company' && (
              <Form.Item
                name='bankAccountType'
                className={styles.radioGroupFormItem}
                label={translations().accountType}
                rules={[{ required: true, message: translations().accountTypeRequired }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Radio.Group>
                  <Radio value={AccountType.Business}>{translations().accountTypes.business}</Radio>
                  <Radio value={AccountType.Personal}>{translations().accountTypes.personal}</Radio>
                </Radio.Group>
              </Form.Item>
            )}

            <Row gutter={16}>
              <Col xs={24} md={10}>
                <Form.Item
                  name='bankAccountNumber'
                  label={translations().bankAccountNumberLabel}
                  rules={[
                    { required: true, message: translations().bankAccountNumberRequired },
                    { pattern: /^(\d){4}(\s)(\d){4}$/, message: translations().bankAccountNumberInvalid }
                  ]}
                  validateTrigger='onBlur'
                  wrapperCol={{ xs: 24 }}
                >
                  <Input type={'masked'} mask={'9999 9999'} placeholder={'1234 5678'} onChange={onBankAccountChange} />
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item
                  name='sortCode'
                  label={translations().sortCodeLabel}
                  rules={[
                    { required: true, message: translations().sortCodeRequired },
                    { pattern: /^(\d){2}(\s)-(\s)(\d){2}(\s)-(\s)(\d){2}$/, message: translations().sortCodeInvalid }
                  ]}
                  validateTrigger='onBlur'
                  wrapperCol={{ xs: 24 }}
                >
                  <Input
                    type={'masked'}
                    mask={'99 - 99 - 99'}
                    placeholder={'12 - 34 - 56'}
                    onChange={onBankAccountChange}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Form.Item label={' '}>
                  <Button
                    className={styles.verifyButton}
                    loading={isVerifyBankLoading}
                    onClick={verifyBankAccount}
                    icon={
                      verifyBankAccountResult.type === 'valid' ? (
                        <CheckCircleFilled style={{ color: '#52c41a' }} />
                      ) : null
                    }
                  >
                    {translations().validate}
                  </Button>
                </Form.Item>
              </Col>

              {verifyBankAccountResult.type !== 'valid' && verifyBankAccountResult.message.length > 0 && (
                <Col xs={24}>
                  {(verifyBankAccountResult.message as string[]).map(errMsg => (
                    <div key={errMsg} className={styles.errMsgVerifyBankAccount}>
                      {errMsg}
                    </div>
                  ))}
                </Col>
              )}
            </Row>

            {verifyBankAccountResult.type === 'valid' && (
              <Form.Item name='bankName' label={translations().bankNameLabel} required>
                <Input disabled />
              </Form.Item>
            )}

            <Form.Item
              name='accountName'
              label={translations().accountNameLabel}
              tooltip={translations().accountNameTooltip}
              validateTrigger='onBlur'
            >
              <Input placeholder={translations().accountNamePlaceholder} />
            </Form.Item>

            <FormSubgroupTitle>{translations().questionnaire}</FormSubgroupTitle>

            <Typography.Title level={5}>{translations().turnoverMethod}</Typography.Title>

            {renderProcessingMethodBlock()}

            <Row justify={'end'} className={styles.processingMethodsSumCounter}>
              <Col>{`${processingMethodsPercentSum} / 100`}</Col>
            </Row>

            <Typography.Text style={{ fontStyle: 'italic' }}>{translations().ifMerchantNew}</Typography.Text>
            <Divider />
            <Typography.Title level={5}>{translations().howFar}</Typography.Title>

            {renderAdvancePaymentsBlock()}

            <Row justify={'end'} className={styles.sumCounter}>
              <Col>{`${advancePaymentsPercentSum} / 100`}</Col>
            </Row>

            <Typography.Text style={{ fontStyle: 'italic' }}>{translations().ifMerchantNew}</Typography.Text>
          </Col>
        </Row>
        <BelowActionsWithSave large isLoading={isSaveLoading} onSave={() => onSave(getFormData(), true)}>
          <NextButton loading={isNextLoading} />
        </BelowActionsWithSave>
      </Form>
    </StartAppContainer>
  )
}
