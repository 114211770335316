import React from 'react'
import moment from 'moment'
import { Popover } from 'antd'
import { SubmittedRequestsStatus } from '../../SubmittedRequests/models'
import { reviewTime } from '~/code/pages/BPMProcesses/Requests/utils'
import { Nowrap } from '~/code/components'
import { DATE_TIME_FORMAT } from '~/code/constants/date-time'
import { renderStatus } from '../../../services'
import translations from '../translations'
import styles from '../ClosureOfMerchant.scss'

const maxtTextLength = 20

const render = (value: string) => (
  <div className={styles.fixedWidth}>
    <span className='nowrap'>
      {value?.length > maxtTextLength ? <Popover content={value}>{value}</Popover> : value}
    </span>
  </div>
)

export const columns = [
  {
    title: translations().startDate,
    dataIndex: 'startDate',
    key: 'startDate',
    render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : '')
  },
  {
    title: translations().initiator,
    dataIndex: 'initiator',
    key: 'initiator',
    render
  },
  {
    title: translations().merchant,
    dataIndex: 'companyName',
    key: 'companyName',
    render
  },
  {
    title: translations().status,
    dataIndex: 'processStatus',
    key: 'processStatus',
    render: (status: SubmittedRequestsStatus) =>
      status ? (
        <Nowrap width={94} title={status}>
          {renderStatus(status)}
        </Nowrap>
      ) : (
        ''
      )
  },
  {
    title: translations().assignedGroup,
    dataIndex: 'assignedGroup',
    key: 'assignedGroup',
    render
  },
  {
    title: translations().assignedUser,
    dataIndex: 'assignedUser',
    key: 'assignedUser',
    render
  },
  {
    title: translations().reviewTime,
    dataIndex: 'reviewTime',
    key: 'reviewTime',
    render: (reviewMinutes: number) => reviewTime(reviewMinutes)
  },
  {
    title: translations().endDate,
    dataIndex: 'endDate',
    key: 'endDate',
    render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : '')
  },
  {
    title: translations().processType,
    dataIndex: 'processType',
    key: 'processType',
    render
  },
  {
    title: translations().mids,
    dataIndex: 'mids',
    key: 'mids',
    render
  },
  {
    title: translations().contracts,
    dataIndex: 'contracts',
    key: 'contracts',
    render
  },
  {
    title: translations().terminals,
    dataIndex: 'terminals',
    key: 'terminals',
    render
  }
]

export const closureType = {
  storeClose: 'storeClose',
  merchantClose: 'merchantClose'
}
