import React from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    baseLabel: string
    percentLabel: string

    settlementFee: string	
        
    authorisationFee: string
    gatewayFee: string	
    refundFee: string
    chargebackFee: string	
        
    dinersCardsFee: string	
    unionPayCardsFee: string	
    amexCardsFee: string	
        
    openBankingFee: string	
    payByBankFee: string
    klarnaFee: string	
        
    settlementPeriod: string
        
    monthlyRental: string

    definitions: React.ReactNode
}>()

