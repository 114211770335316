import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from '~/code/services'
import storage from '~/code/services/storage'
import {
  CreatePreviewRequestType,
  CreatePreviewResponseType,
  UpdateDocumentRequestType,
  UploadDocumentReq,
  UploadDocumentRes
} from '../models'
import { SendToSignRequestType } from '../models/SendToSignRequestType'
import { getUploadDocFormData } from './utils'

export const uploadDocument = async (params: UploadDocumentReq) => {
  const formData = getUploadDocFormData(params)
  const accessToken = storage.get('accessToken')

  try {
    const res = await fetch('/api/v1/sales-onboarding/files', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      body: formData
    })

    const data = await res.json()

    if (res.status === 200) {
      return {
        status: 200,
        result: data as UploadDocumentRes[]
      }
    }

    return {
      error: data as { code: number; message: string }
    }
  } catch (error) {
    return { error }
  }
}

export const deleteDocument = (path: string) => deleteWithAuth(`/api/v1/sales-onboarding/files?path=${path}`)

export const createDocuSignPreview = (id: string, data: CreatePreviewRequestType) =>
  postWithAuth<CreatePreviewResponseType>(`/api/processes-api/envelope/${id}/template`, data)

export const getDocuSignDocumentById = (envelopeID: string, documentID: string) =>
  getWithAuth<string>(`/api/processes-api/envelope/draft?envelopeID=${envelopeID}&documentID=${documentID}`)

export const sendToSignMSA = (id: string, data: SendToSignRequestType) =>
  postWithAuth<any>(`/api/processes-api/v2.1/sales-onboarding/applications/${id}/sign-msa`, data)

export const updateDocuSignPreview = (processId: string, data: UpdateDocumentRequestType) =>
  putWithAuth<CreatePreviewResponseType>(`/api/processes-api/envelope/${processId}/template`, data)

export const docusignStatus = (id: string) =>
  getWithAuth<{ status: string }>(`/api/processes-api/v2.1/sales-onboarding/applications/${id}/sign-msa`)

export const docusignCancel = (taskID: string, envelopeID: string) =>
  deleteWithAuth<{ status: string }>(
    `/api/processes-api/v2.1/sales-onboarding/applications/${taskID}/sign-msa?envelopeId=${envelopeID}`
  )

export const resendDocusignEmail = (processId: string) =>
  postWithAuth(`/api/processes-api/v2.1/sales-onboarding/applications/${processId}/notifications/docusign/applicant`)
