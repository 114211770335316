import { action, autorun, makeObservable, observable } from 'mobx'
import { message } from 'antd'
import { inject, injectable } from 'inversify'
import { PeriodType } from '~/code/models'
import commonTranslations from '~/code/translations'
import { RiskLimitsMerchantSelectStoreSymbol, RiskLimitsRangePickerStoreSymbol } from '~/code/pages/BPMProcesses/Requests/components/SubmittedRequests/components'
import { createProcessDetailsModel, ProcessDetailsModel, SubmittedApplicationReport } from '~/code/pages/BPMProcesses/Requests/models'
import { getProcessDetails, getProcessStatuses, getSubmittedApplications } from '~/code/pages/BPMProcesses/Requests/services'
import { DrawerTitleParentStore } from '~/code/pages/BPMProcesses/Requests/components/SubmittedRequests/components/DrawerTitle/models/DrawerTitleParentStore'
import { RangePickerStoreInjectable } from '../RangePickerStoreInjectable'
import { getFullData } from './services'
import { MerchantSelectStore } from '../MerchantsManagementStore/MerchantSelectStore'

@injectable()
export class SubmittedRequestsStore implements DrawerTitleParentStore {
    isInitiated: boolean = false
    isShowInfoDrawer: boolean = false
    hasProcessDetailsLoadingError: boolean = false
    isRiskLimitsApplicationsLoading: boolean = false

    dateStore: RangePickerStoreInjectable
    merchantSelectStore: MerchantSelectStore

    isChangeRiskLimitStatusesLoading: boolean = false
    riskLimitApplications: SubmittedApplicationReport[] = []
    statuses: { label: string, value: string }[]
    total: number
    pageSize: number
    currentPage: number

    status: string = null

    isProcessDetailsLoading: boolean = false
    processDetails: ProcessDetailsModel = createProcessDetailsModel()
    selectedProcessId: string = ''

    constructor(
        @inject(RiskLimitsRangePickerStoreSymbol) rangePickerStoreInjectable : RangePickerStoreInjectable,
        @inject(RiskLimitsMerchantSelectStoreSymbol) merchantSelectStore: MerchantSelectStore
    ) {

        this.dateStore = rangePickerStoreInjectable
        this.merchantSelectStore = merchantSelectStore
        this.statuses = []
        this.total = 0
        this.pageSize = 10
        this.currentPage = 1

        makeObservable(this, {
            isInitiated: observable,
            isShowInfoDrawer: observable,
            hasProcessDetailsLoadingError: observable,
            isChangeRiskLimitStatusesLoading: observable,
            isRiskLimitsApplicationsLoading: observable,
            statuses: observable,
            riskLimitApplications: observable,
            status: observable,
            isProcessDetailsLoading: observable,
            processDetails: observable,
            selectedProcessId: observable,
            total: observable,
            pageSize: observable,
            currentPage: observable,

            init: action,
            loadChangeRiskLimitStatuses: action,
            loadRiskLimitsApplications: action,
            setChangeRiskLimitStatus: action,
            loadProcessDetails: action
        })

        autorun(() => {
            if (this.dateStore.startDate && this.dateStore.endDate && this.merchant) {
                this.loadRiskLimitsApplications(1)
            }
        })
    }

    get merchant() {
        return this.merchantSelectStore.selectedMerchant?.companyName
    }

    async init() {
        this.isInitiated = true
        this.isShowInfoDrawer = false
        if (this.dateStore.period === null) {
            this.dateStore.setPeriod(PeriodType.Yesterday)
        } else {
            this.loadRiskLimitsApplications(1)
        }
        this.loadChangeRiskLimitStatuses()
    }

    showInfoDrawer = () => {
        this.isShowInfoDrawer = true
    }

    closeInfoDrawer = () => {
        this.isShowInfoDrawer = false
    }

    async loadChangeRiskLimitStatuses() {
        this.isChangeRiskLimitStatusesLoading = true
        const { status, result, error } = await getProcessStatuses('change-risk-limits')
        if (status === 200) {
            this.statuses = getFullData(result.map((item) => (
                {value: item.description, label: item.name}
            )))
            this.isChangeRiskLimitStatusesLoading = false
            return
        }
        message.error(error?.message || commonTranslations().defaultErrorMessage)
    }

    async loadRiskLimitsApplications(page?: number, pageSize?: number) {
        if (page) {
            this.currentPage = page
        }
        if (pageSize) {
            this.pageSize = pageSize
        } else this.pageSize = 10
        this.isRiskLimitsApplicationsLoading = true
        const { status, result, error } = await getSubmittedApplications({
            from: this.dateStore.startDate.clone().utc().format(),
            to: this.dateStore.endDate.clone().utc().format(),
            status: this.status || undefined,
            companyName: this.merchant === 'All' ? undefined : this.merchant,
            processName: 'dossierrisk',
            page: this.currentPage,
            size: this.pageSize
        })
        this.isRiskLimitsApplicationsLoading = false
        if (status === 200) {
            this.riskLimitApplications = result.report
            this.total = result.totalCount
            return
        }

        message.error(error?.message || commonTranslations().defaultErrorMessage)
    }

    setChangeRiskLimitStatus(status: string | null) {
        this.status = status
        this.loadRiskLimitsApplications(1)
    }

    async loadProcessDetails(processId: string) {
        this.isProcessDetailsLoading = true
        this.hasProcessDetailsLoadingError = false
        this.selectedProcessId = processId

        const { status, result, error } = await getProcessDetails(processId)
        if (status === 200) {
            this.isProcessDetailsLoading = false
            this.processDetails = result
            return
        }
        this.isProcessDetailsLoading = false
        this.hasProcessDetailsLoadingError = true
        message.error(error?.message || commonTranslations().defaultErrorMessage)
    }
}
