import { message } from 'antd'
import { apiCheck, noThrow } from 'back-connector'
import { action, makeObservable, observable } from 'mobx'
import { SelectItem } from '~/code/models'
import { CompanyResponseItem, ICompanyNameFormStore } from '../models'
import { fetchCompaniesByName, convertResponseToCompanies, filterCompanyByCompanyNumber } from '../services'
import translations from '../translations'

export class CompanyNameFormStore implements ICompanyNameFormStore {

    public company: CompanyResponseItem = undefined
    public companies: SelectItem[] = []

    constructor () {
        makeObservable(this, {
            company: observable,
            companies: observable,
            reset: action,
            selectComapny: action,
            searchCompanies: action
        })
    }

    public async selectComapny(companyNumber: string) {
        if (!companyNumber) {
            this.companies = []
            this.company = null
            return
        }
        const company = filterCompanyByCompanyNumber(this.companies.slice(), companyNumber)
        this.company = company && company.params ? company.params : null
    }

    public async searchCompanies(value: string) {
        if (!!value && value.length && value.length >= 3) {
            const result = await noThrow(apiCheck(fetchCompaniesByName(value)))
            if (!result.error) {
                this.companies = convertResponseToCompanies(result.value)
                return
            } else {
                this.companies = []
                message.error(result.error ? result.error.message : translations().couldNotLoadCompanies)
            }
        } else {
            this.companies = []
        }
    }

    public reset() {
        this.company = null
        this.companies = []
    }
}