import React from 'react'
import debounce from 'lodash.debounce'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Form, Input } from 'antd'
import { PortalAccessStoreType } from '~/code/pages/PortalAccess/types/PortalAccessStoreType'
import { PortalAccessStoreSymbol } from '~/code/pages/PortalAccess/'

import translations from '../../translations'
import styles from './styles.scss'

export const PortalAccessEmailInput = observer(() => {
  const store = useInjection<PortalAccessStoreType>(PortalAccessStoreSymbol)
  const { merchantsTableStore, searchLoginValue } = store

  const [form] = Form.useForm()

  return (
    <Form form={form} requiredMark={false} layout='vertical'>
      <Form.Item
        label={`${translations().emailAddress}:`}
        name='email'
        initialValue={searchLoginValue}
        className={styles.formItem}
        rules={[{ type: 'email', message: translations().formErrors.wrongEmailFormat }]}
      >
        <Input
          allowClear
          placeholder={translations().searchByEmail}
          onChange={debounce(e => {
            if (form.getFieldError('email').length) return

            store.setSearchLoginValue(e.target.value.trim())
            merchantsTableStore.loadData({ pageNumber: 1 })
          }, 500)}
        />
      </Form.Item>
    </Form>
  )
})
