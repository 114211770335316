import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Popover } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { BackButton } from '~/code/components'
import { StoreType } from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v2'
import { EntityItemList } from '../EntityItemList'
import { FinancialInformationForm } from './components/FinancialInformationForm'
import { IFinancialInformationStore } from './IFinancialInformationStore'
import { maxLengthForMid } from './constants'
import translations from './translations'

export const FinancialInformationStoreSymbol = Symbol('FinancialInformationStore')
export const FinancialInformation = observer((props: { stores: StoreType[] }) => {
  const store = useInjection<IFinancialInformationStore>(FinancialInformationStoreSymbol)
  const { stores } = props

  useEffect(() => {
    store.setSelectedStore(null)
  }, [])

  const generateAdditionalInfo = (storeItem: StoreType): JSX.Element => {
    const contract = storeItem?.contracts?.[0] || null
    const mids = store.getMids(contract)
    const product = store.getProduct(contract)
    return (
      <>
        <span style={{ cursor: 'pointer' }}>
          {translations().mid}
          {mids?.length > maxLengthForMid ? (
            <Popover trigger='hover' content={mids}>
              {mids}
            </Popover>
          ) : (
            mids
          )}
        </span>
        <div>
          {translations().product}
          {product}
        </div>
      </>
    )
  }

  return store.selectedStore ? (
    <>
      <BackButton onClick={() => store.setSelectedStore(null)}>{translations().back}</BackButton>
      <FinancialInformationForm store={store.selectedStore} />
    </>
  ) : (
    <EntityItemList
      title={translations().title}
      text={translations().details}
      list={stores.map(storeItem => ({
        name: storeItem?.storeInfo?.storeName,
        onClick: () => store.setSelectedStore(storeItem),
        additionalInfo: generateAdditionalInfo(storeItem)
      }))}
    />
  )
})
