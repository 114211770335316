import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  storeName: string
  paymentSolution: string
  motoRequested: string
  count: string
  locationAddress: string
  yes: string
  no: string
}>()
