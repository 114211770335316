import translations from './translations'

translations.add('en', {
  submit: 'Submit',
  cancel: 'Cancel',
  reason: 'Reason',
  reasonRequired: 'Reason is required',
  commentRequired: 'Comment is required',
  reasonMinMsg: 'Comment must be at least 2 characters',
  comment: 'Comment',
  closeStore: 'Close Store'
})
