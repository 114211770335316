import React from 'react'
import translations from './translations'

translations.add('en', {
  back: 'Back to Log In',
  title: (
    <>
      Two-factor authentication <br />
      has been enforced
    </>
  ),
  text: 'Two-factor authentication (2FA) adds an extra layer of security to your account by requiring both your password and a verification code from an authenticator app during login.',
  submit: 'Enable 2FA'
})
