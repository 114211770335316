import translations from './translations'

translations.add('en', {
    amount: 'Amount',
    chargebackDate: 'Chargeback Date',
    bankingDate: 'Banking Date',
    cardMask: 'Card Mask',
    captureMethod: 'Capture Method',
    date: 'Date',
    currency: 'Currency',
    documentId: 'Document ID',
    id: 'Chargeback ID',
    key: 'Key',
    merchantName: 'Merchant Name',
    terminalId: 'Terminal ID',
    transactionDate: 'Transaction Date',
    transactionType: 'Transaction Type',
    transactionId: 'Transaction ID',
    createdDate: 'Created Date',
    status: 'Status',
    previousDocumentId: 'Previous Document ID',
    operation: 'Operation',
    reasonCode: 'Reason Code',
    arn: 'ARN',
    cardScheme: 'Card Scheme',
    visa: 'VISA',
    mastercard: 'MasterCard',
    viewDetails: 'View Details'
})


