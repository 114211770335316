import React, { useEffect, useState } from 'react'
import {
  Alert,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  Switch,
  message,
  Typography,
  Checkbox,
  Input as AntInput
} from 'antd'
import { isEmpty } from 'dna-common'
import globalTranslations from '~/code/translations'
import { CITY_TYPING_PATTERN, HOUSE_NAME_NUMBER_TYPING_PATTERN } from '~/code/constants/Patterns'
import { PostalCode, Input, ReloadButton, DNALoader, CustomModal, CustomButton } from '~/code/components'
import { scrollToFirstError } from '~/code/services/helpers'
import { convertDateStringToObject, isCountryUK, validatePostalCodeOfUK, validateDateOfBirth } from 'startapp/services'
import {
  SortedNationalitySelect,
  SortedCountrySelect,
  FormSubgroupTitle,
  FormTitle,
  PhoneNumberFormItem,
  OfficersPopover
} from '../../components'
import { getScrollBehaviorOptions, isEquivalent } from '../../services'
import { SENT_STATUS } from '../../services/constants'
import { convertDataToFormValue } from './services/utils'
import { genderGenerator, titleGenerator } from './services/generator'
import translations from './translations'
import { DetailsFormProps } from './props'
import styles from './DetailsForm.scss'

const { Link } = Typography

export const DetailsForm: React.FC<DetailsFormProps> = (props: DetailsFormProps) => {
  const {
    title,
    data,
    onSubmit,
    isNextLoading,
    isSaveLoading,
    onSave,
    shareholder = false,
    onVerify,
    isVerifyOfficerLoading,
    settings,
    officerVerificationResult,
    showVerificationModal,
    setShowVerificationModal,
    setOfficerVerificationResult,
    onCancel,
    handleAgreementSignatoryChange,
    sendJumioRequest,
    jumioRequestSent,
    setShowJumioVerificationModal,
    showJumioVerificationModal,
    jumioRequestLoading,
    setUploadDocManually,
    jumioResults,
    resetChecks,
    selectedOfficer,
    checkJumioResults,
    setJumioRequestSent,
    checkForDuplicates,
    isJumioResultsLoading,
    setAgreementSignatoryByDefault,
    setJumioResults,
    uploadDocsManually,
    resendEmail,
    resendLoading,
    isResendTimeoutDone,
    isEuroClient
  } = props

  const [completeDisabled, setCompleteDisabled] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [editableDataChanged, setEditableDataChanged] = useState(false)
  const [showDocModal, setShowDocModal] = useState(false)
  const [savedData, setSavedData] = useState(data)

  const withoutChecks = settings?.addressAndIDCheck === 'none'

  const showVerifyBtn =
    isEmpty(officerVerificationResult) ||
    (officerVerificationResult.addressVerifications[0].verified &&
      officerVerificationResult.idVerifications[0].verified)

  const requestStatus =
    !jumioResults || (jumioResults.addressVerified === null && jumioResults.identityVerified === null)
      ? 'SENT'
      : jumioResults?.addressVerified && jumioResults.identityVerified
      ? 'VERIFIED'
      : 'COMPLETED'

  useEffect(() => {
    isEmpty(data) &&
      form.setFieldsValue({
        vulnerability: {
          enabled: false,
          visualDisability: false,
          audioDisability: false,
          lifeEvent: false,
          capability: false,
          resilience: false
        }
      })
  }, [])

  useEffect(() => {
    data && setSavedData(data)

    if (data.agreementSignatory) {
      setAgreementSigner(data.agreementSignatory)
    }
  }, [data])

  useEffect(() => {
    if (savedData.uploadDocManually) {
      setUploadDocManually(true)
    }
    if (savedData.officerVerificationResult) {
      setOfficerVerificationResult(savedData.officerVerificationResult)
    }

    if (!savedData.uploadDocManually && savedData.jumioRequestSent) {
      setJumioRequestSent(true)
    }

    if (!savedData.uploadDocManually && savedData.jumioCheckResults) {
      setJumioResults(data.jumioCheckResults)
    }
  }, [savedData])

  useEffect(() => {
    if (officerVerificationResult) {
      setCompleteDisabled(false)
    }
    if (!officerVerificationResult && !withoutChecks) {
      setCompleteDisabled(true)
    }
  }, [officerVerificationResult])

  const [agreementSigner, setAgreementSigner] = useState(data.agreementSignatory)

  const [form] = Form.useForm()
  const { validateFields, getFieldValue, setFieldsValue, getFieldsValue } = form

  const forename = data?.nameElements?.forename || ''
  const surname = data?.nameElements?.surname || ''
  const formTitle = forename + (surname ? ' ' + surname : '')

  const getFormData = async () => {
    const values = getFieldsValue()
    const dateOfBirth = convertDateStringToObject(values.dateOfBirth)
    const emailAddress = (values.emailAddress?.toLocaleLowerCase() as string) || ''

    return {
      ...values,
      dateOfBirth,
      emailAddress
    }
  }

  return (
    <div>
      {isJumioResultsLoading && <DNALoader />}
      <Form
        form={form}
        autoComplete='off'
        layout='vertical'
        requiredMark='optional'
        scrollToFirstError={scrollToFirstError}
        initialValues={convertDataToFormValue(
          savedData,
          [],
          data.officerRole === 'business-owner' && setAgreementSignatoryByDefault
        )}
        onValuesChange={() => {
          if (
            selectedOfficer?.id &&
            (form.getFieldValue('emailAddress') !== selectedOfficer?.emailAddress ||
              form.getFieldValue('telephoneNumber') !== selectedOfficer?.telephoneNumber)
          ) {
            setEditableDataChanged(true)
          }
        }}
      >
        <div className={styles.header}>
          <FormTitle>{title || formTitle}</FormTitle>
          <OfficersPopover
            onSelect={officer => {
              if (
                officer?.nameElements?.forename?.toLocaleUpperCase() === forename?.toLocaleUpperCase() &&
                officer?.nameElements?.surname?.toLocaleUpperCase() === surname?.toLocaleUpperCase()
              ) {
                form.setFieldsValue(convertDataToFormValue(officer, shareholder ? ['ownershipPercentage'] : []))
                setSavedData(officer)
              } else {
                message.error(translations().chooseAnother)
              }
            }}
          />
        </div>

        <Row gutter={[64, 0]}>
          <Col xs={24}>
            <FormSubgroupTitle>{translations().personalInfo}</FormSubgroupTitle>

            <Row gutter={16}>
              <Col xs={24} md={6}>
                <Form.Item
                  name={['nameElements', 'title']}
                  label={translations().title}
                  rules={[{ required: true, message: translations().titleRequired }]}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Select disabled={!isEmpty(officerVerificationResult)} allowClear>
                    {titleGenerator()}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={18}>
                <Form.Item
                  name={['nameElements', 'forename']}
                  label={translations().name}
                  rules={[{ required: true, message: translations().nameRequired }]}
                  validateTrigger='onBlur'
                >
                  <Input
                    disabled={!isEmpty(officerVerificationResult)}
                    readOnly={(data?.nameElements?.forename && data?.fromApi) || false}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name={['nameElements', 'middleName']} label={<div>{translations().middleName}</div>}>
              <Input
                disabled={!isEmpty(officerVerificationResult)}
                readOnly={(data?.nameElements?.middleName && data?.fromApi) || false}
              />
            </Form.Item>

            <Form.Item
              name={['nameElements', 'surname']}
              label={translations().surname}
              rules={[{ required: true, message: translations().surnameRequired }]}
              validateTrigger='onBlur'
            >
              <Input
                disabled={!isEmpty(officerVerificationResult)}
                readOnly={(data?.nameElements?.surname && data?.fromApi) || false}
              />
            </Form.Item>

            {shareholder && (
              <Form.Item
                name={'ownershipPercentage'}
                label={translations().ownershipPercentage}
                rules={[{ required: true, message: translations().ownershipPercentageRequired }]}
                validateTrigger='onBlur'
              >
                <InputNumber
                  disabled={!isEmpty(officerVerificationResult)}
                  className={styles.styledNumberInput}
                  min={25}
                />
              </Form.Item>
            )}

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name='dateOfBirth'
                  label={translations().dateBirth}
                  rules={[
                    {
                      required: true,
                      message: translations().dateBirthRequired
                    },
                    {
                      validator: async (rule, value) => {
                        const message = validateDateOfBirth(value)
                        if (message) {
                          throw new Error(message)
                        }
                      }
                    }
                  ]}
                  validateTrigger='onBlur'
                >
                  <Input
                    disabled={!isEmpty(officerVerificationResult)}
                    type={'masked'}
                    mask={'99-99-9999'}
                    placeholder={globalTranslations().datePlaceholder}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='gender'
                  label={translations().gender}
                  rules={[{ required: true, message: translations().genderRequired }]}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Select className={styles.fullWidth} allowClear disabled={!isEmpty(officerVerificationResult)}>
                    {genderGenerator()}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name='birthplace'
              label={translations().placeBirth}
              rules={[{ required: true, message: translations().placeBirthRequired }]}
              validateTrigger={['onChange', 'onBlur']}
            >
              <SortedCountrySelect className={styles.fullWidth} disabled={!isEmpty(officerVerificationResult)} />
            </Form.Item>

            <Form.Item
              name='nationality'
              label={translations().nationality}
              rules={[{ required: true, message: translations().nationalityRequired }]}
              validateTrigger={['onChange', 'onBlur']}
            >
              <SortedNationalitySelect className={styles.fullWidth} disabled={!isEmpty(officerVerificationResult)} />
            </Form.Item>

            <FormSubgroupTitle>{translations().contactInfo}</FormSubgroupTitle>

            <Form.Item
              name='emailAddress'
              label={translations().emailAddress}
              rules={[
                { type: 'email', message: translations().emailFormatIncorrect },
                { required: true, message: translations().emailAddressRequired }
              ]}
              validateTrigger='onBlur'
            >
              <Input placeholder={'me@company.co.uk'} textTransform={'lowercase'} autoComplete='off' />
            </Form.Item>

            <PhoneNumberFormItem
              label={translations().telephoneNumber}
              requiredErrorMessage={translations().telephoneNumberRequired}
              invalidErrorMessage={translations().telephoneNumberWrongFormat}
              pattern={settings?.phonePattern}
              prefix={settings?.phoneCode}
            />
          </Col>

          <Col xs={24}>
            <FormSubgroupTitle>{translations().addressTitle}</FormSubgroupTitle>

            <Form.Item
              name={['address', 'country']}
              label={translations().country}
              rules={[{ required: true, message: translations().countryRequired }]}
              validateTrigger={['onChange', 'onBlur']}
            >
              <SortedCountrySelect
                className={styles.fullWidth}
                onChange={() => {
                  if (getFieldValue(['address', 'postalCode'])) {
                    validateFields([['address', 'postalCode']])
                  }
                }}
                disabled={!isEmpty(officerVerificationResult)}
              />
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => prevValues.address?.country !== curValues.address?.country}
            >
              {() => (
                <Form.Item
                  name={['address', 'postalCode']}
                  label={translations().postCode}
                  getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                    return e.currentTarget.value?.toLocaleUpperCase()
                  }}
                  rules={[
                    {
                      required: true,
                      message: translations().postCodeRequired
                    },
                    {
                      validator: async (rule, value) => {
                        const isUK = isCountryUK(getFieldValue(['address', 'country']))
                        if (value && isUK && !validatePostalCodeOfUK(value)) {
                          throw new Error(translations().postCodeInvalid)
                        }
                      }
                    }
                  ]}
                  validateTrigger='onBlur'
                >
                  <PostalCode
                    disabled={!isEmpty(officerVerificationResult)}
                    isUK={isCountryUK(getFieldValue(['address', 'country']))}
                    onAddressSelect={a => {
                      setFieldsValue({
                        address: {
                          houseName: a.building_name || a.sub_building_name,
                          houseNumber: a.building_number || a.sub_building_number,
                          addressLine1: a.thoroughfare,
                          locality: a.town_or_city,
                          region: a.county
                        }
                      })
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item
              name={['address', 'houseName']}
              label={translations().houseName}
              getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                const value = e.currentTarget.value
                if (value && !HOUSE_NAME_NUMBER_TYPING_PATTERN.test(value)) {
                  return getFieldValue(['address', 'houseName']) || ''
                }
                return value
              }}
              validateTrigger='onBlur'
            >
              <Input disabled={!isEmpty(officerVerificationResult)} />
            </Form.Item>

            <Form.Item
              name={['address', 'houseNumber']}
              label={translations().houseNumber}
              getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                const value = e.currentTarget.value
                if (value && !HOUSE_NAME_NUMBER_TYPING_PATTERN.test(value)) {
                  return getFieldValue(['address', 'houseNumber']) || ''
                }
                return value
              }}
              validateTrigger='onBlur'
            >
              <Input disabled={!isEmpty(officerVerificationResult)} />
            </Form.Item>

            <Form.Item
              name={['address', 'addressLine1']}
              label={translations().street}
              rules={[{ required: true, message: translations().streetRequired }]}
              validateTrigger='onBlur'
            >
              <Input disabled={!isEmpty(officerVerificationResult)} />
            </Form.Item>

            <Form.Item name={['address', 'addressLine2']} label={<div>{translations().street2}</div>}>
              <Input disabled={!isEmpty(officerVerificationResult)} />
            </Form.Item>

            <Form.Item
              name={['address', 'locality']}
              label={translations().city}
              rules={[
                { required: true, message: translations().cityRequired },
                {
                  pattern: CITY_TYPING_PATTERN,
                  message: translations().cityInvalid
                }
              ]}
              validateTrigger='onBlur'
            >
              <Input disabled={!isEmpty(officerVerificationResult)} />
            </Form.Item>

            <Form.Item name={['address', 'region']} label={<div>{translations().region}</div>}>
              <Input disabled={!isEmpty(officerVerificationResult)} />
            </Form.Item>

            <Divider />

            <Form.Item
              valuePropName={'checked'}
              name={['vulnerability', 'enabled']}
              label={<div>{translations().vulnerableStatus}</div>}
              required
            >
              <Switch disabled={!isEmpty(officerVerificationResult)} />
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {t =>
                t.getFieldValue(['vulnerability', 'enabled']) === true && (
                  <>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          className={styles.styledItem}
                          name={['vulnerability', 'visualDisability']}
                          valuePropName={'checked'}
                        >
                          <Checkbox disabled={!isEmpty(officerVerificationResult)}>
                            {translations().visualDisability}{' '}
                          </Checkbox>
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item
                          className={styles.styledItem}
                          name={['vulnerability', 'audioDisability']}
                          valuePropName={'checked'}
                        >
                          <Checkbox disabled={!isEmpty(officerVerificationResult)}>
                            {' '}
                            {translations().audioDisability}{' '}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          className={styles.styledItem}
                          name={['vulnerability', 'lifeEvent']}
                          valuePropName={'checked'}
                        >
                          <Checkbox disabled={!isEmpty(officerVerificationResult)}>
                            {translations().lifeEvent}{' '}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          className={styles.styledItem}
                          name={['vulnerability', 'capability']}
                          valuePropName={'checked'}
                        >
                          <Checkbox disabled={!isEmpty(officerVerificationResult)}>
                            {' '}
                            {translations().capability}{' '}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          className={styles.styledItem}
                          name={['vulnerability', 'resilience']}
                          valuePropName={'checked'}
                        >
                          <Checkbox disabled={!isEmpty(officerVerificationResult)}>
                            {' '}
                            {translations().resilience}{' '}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className={styles.marginTop}>
                      <Col span={24}>
                        <Form.Item
                          name={['vulnerability', 'vulnerabilityInfo']}
                          label={translations().vulnerabilityInfo}
                        >
                          <AntInput.TextArea
                            disabled={!isEmpty(officerVerificationResult)}
                            rows={5}
                            placeholder={translations().addDetails}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )
              }
            </Form.Item>
            <Divider />

            {isEuroClient && (
              <>
                <Form.Item
                  name='idDocumentNumber'
                  label={<div>{translations().idNumber}</div>}
                  rules={[{ required: true, message: translations().streetRequired }]}
                  validateTrigger='onBlur'
                >
                  <Input disabled={!isEmpty(officerVerificationResult)} />
                </Form.Item>

                <Form.Item
                  name='idDocumentExpirationDate'
                  label={<div>{translations().idExpDate}</div>}
                  rules={[{ required: true, message: translations().streetRequired }]}
                  validateTrigger='onBlur'
                >
                  <Input
                    disabled={!isEmpty(officerVerificationResult)}
                    type={'masked'}
                    mask={'99-99-9999'}
                    placeholder={globalTranslations().datePlaceholder}
                  />
                </Form.Item>
              </>
            )}
          </Col>
        </Row>

        {!data.id && !shareholder && !withoutChecks && (
          <>
            <Form.Item
              name={'agreementSignatory'}
              valuePropName={'checked'}
              label={<div>{translations().agreementSignatory}</div>}
              required
            >
              <Switch disabled={!isEmpty(officerVerificationResult)} onChange={e => setAgreementSigner(e)} />
            </Form.Item>
            {agreementSigner && (
              <>
                <Row>
                  <p>{translations().agreementSignatoryInfo}</p>
                </Row>
                {!officerVerificationResult && (
                  <Row>
                    <Alert
                      className={styles.fullWidth}
                      message={<>{translations().verificationWarn()}</>}
                      type='warning'
                    />
                  </Row>
                )}
              </>
            )}
          </>
        )}
      </Form>

      {data.id && !shareholder && !withoutChecks && (
        <>
          <Row>
            <p>{translations().agreementSignatory}</p>
          </Row>
          <Row>
            <div className={styles.styledSwitch}>
              <Switch
                checked={agreementSigner}
                onChange={e => {
                  setAgreementSigner(e)
                  handleAgreementSignatoryChange(e, data.id, shareholder)
                }}
              />
            </div>
          </Row>
          {agreementSigner && (
            <Row>
              <p>{translations().agreementSignatoryInfo}</p>
            </Row>
          )}
        </>
      )}

      {officerVerificationResult && (
        <>
          <b>{translations().automatedIdentityVerification}</b>
          <Alert
            className={styles.resultsHeader}
            message={
              <>
                {translations().idVerifResult1(officerVerificationResult.idVerifications?.[0].verified)}
                {translations().addressVerifResult1(officerVerificationResult.addressVerifications?.[0].verified)}
              </>
            }
            type='warning'
          />

          {!jumioRequestSent &&
            (!officerVerificationResult.idVerifications?.[0].verified ||
              !officerVerificationResult.addressVerifications?.[0].verified) && (
              <div className={styles.verifDetails}>
                <p>
                  <b>{translations().whatsNext}</b>
                </p>
                {translations().uploadUtilityBill}
              </div>
            )}
        </>
      )}

      {jumioRequestSent == true && (
        <>
          <div className={styles.refresHeader}>
            <b className={styles.resultsHeader}>{translations().jumioStatus}</b>
            <div className={styles.refreshSection}>
              <Link
                onClick={() =>
                  checkJumioResults(
                    form.getFieldValue(['nameElements', 'forename']),
                    form.getFieldValue(['nameElements', 'surname'])
                  )
                }
              >
                {translations().refreshStatus}
              </Link>
              <ReloadButton
                onClick={() =>
                  checkJumioResults(
                    form.getFieldValue(['nameElements', 'forename']),
                    form.getFieldValue(['nameElements', 'surname'])
                  )
                }
              />
            </div>
          </div>
          <Alert
            className={styles.resultsHeader}
            message={
              <>
                {translations().verifResult(requestStatus)}
                {translations().hasComplete(form.getFieldValue('emailAddress'))}
                {requestStatus === 'VERIFIED' && translations().docsNotRequired}

                {requestStatus === 'COMPLETED' &&
                  translations().jumioVerifResult(jumioResults?.identityVerified, jumioResults?.addressVerified)}
              </>
            }
            type='warning'
          />
          {requestStatus === SENT_STATUS && !uploadDocsManually && (
            <div className={styles.resultsHeader}>
              <Alert
                message={
                  <Row gutter={10}>
                    <Col span={18}>{translations().resendText}</Col>
                    <Col span={6}>
                      <CustomButton
                        disabled={!isResendTimeoutDone}
                        loading={resendLoading}
                        onClick={() =>
                          resendEmail(
                            form.getFieldValue(['nameElements', 'forename']),
                            form.getFieldValue(['nameElements', 'surname'])
                          )
                        }
                      >
                        {translations().resend}
                      </CustomButton>
                    </Col>
                  </Row>
                }
              />
            </div>
          )}
        </>
      )}

      <Divider />

      {uploadDocsManually && (
        <>
          <Alert type='warning' message={translations().uploadDocsChoosen} />
          <Divider />
        </>
      )}

      {showVerifyBtn && (
        <Row gutter={10}>
          <Col>
            {!withoutChecks && onVerify && (
              <CustomButton
                disabled={!isEmpty(officerVerificationResult)}
                type='primary'
                className={styles.verifyButton}
                onClick={async () => {
                  form.setFields([
                    {
                      name: ['vulnerability', 'resilience'],
                      errors: ['']
                    }
                  ])
                  await form.validateFields()
                  const vulnerabiliets = form.getFieldValue('vulnerability')
                  if (vulnerabiliets?.enabled) {
                    const vulnerabilityChoosen = Object.entries(vulnerabiliets).filter(
                      v => v[0] !== 'enabled' && v[1] === true
                    )

                    if (isEmpty(vulnerabilityChoosen)) {
                      form.setFields([
                        {
                          name: ['vulnerability', 'resilience'],
                          errors: [translations().vulnerabilityError]
                        }
                      ])
                      form.scrollToField(['vulnerability', 'visualDisability'], getScrollBehaviorOptions())
                      return
                    }
                  }

                  setShowVerificationModal(true)
                }}
              >
                {translations().verify}
              </CustomButton>
            )}
          </Col>
          <Col>
            <CustomButton
              className={styles.verifyButton}
              onClick={async () => {
                if (
                  !data?.id &&
                  checkForDuplicates(
                    form.getFieldValue(['nameElements', 'surname']),
                    form.getFieldValue(['nameElements', 'forename']),
                    shareholder
                  )
                ) {
                  message.error(translations().userExists, 10)
                  return
                }
                onSave(await getFormData())
              }}
              loading={isSaveLoading}
              disabled={isSaveLoading || !isEmpty(officerVerificationResult)}
            >
              {translations().save}
            </CustomButton>
          </Col>
          <Col>
            <CustomButton
              className={styles.verifyButton}
              onClick={async () => {
                if (
                  data.id &&
                  !isEmpty(data.officerVerificationResult) &&
                  data.officerVerificationResult?.addressVerifications?.[0].verified === false &&
                  data.officerVerificationResult?.idVerifications?.[0].verified === false &&
                  agreementSigner === data.agreementSignatory &&
                  !editableDataChanged
                ) {
                  onCancel()
                  return
                }

                if (
                  !data?.id &&
                  checkForDuplicates(
                    form.getFieldValue(['nameElements', 'surname']),
                    form.getFieldValue(['nameElements', 'forename']),
                    shareholder
                  )
                ) {
                  message.error(translations().userExists, 10)
                  return
                }
                onSubmit(await getFormData())
              }}
              loading={isNextLoading}
              disabled={completeDisabled}
            >
              {data.id &&
              !isEmpty(data.officerVerificationResult) &&
              data.officerVerificationResult?.addressVerifications?.[0].verified === false &&
              data.officerVerificationResult?.idVerifications?.[0].verified === false &&
              agreementSigner === data.agreementSignatory &&
              !editableDataChanged
                ? translations().cancel
                : translations().complete}
            </CustomButton>
          </Col>
        </Row>
      )}

      {!isEmpty(officerVerificationResult) &&
        (!officerVerificationResult?.addressVerifications?.[0]?.verified ||
          !officerVerificationResult?.idVerifications?.[0]?.verified) && (
          <div className={styles.marginTop}>
            <Row gutter={10}>
              <Col>
                <CustomButton
                  loading={isSaveLoading}
                  disabled={
                    (requestStatus === 'COMPLETED' &&
                      jumioResults?.addressVerified === true &&
                      jumioResults?.identityVerified === true) ||
                    uploadDocsManually
                  }
                  type='primary'
                  onClick={async () => {
                    if (jumioRequestSent && requestStatus === 'SENT') {
                      setShowDocModal(true)
                      return
                    }
                    setUploadDocManually(true)

                    if (
                      !data?.id &&
                      checkForDuplicates(
                        form.getFieldValue(['nameElements', 'surname']),
                        form.getFieldValue(['nameElements', 'forename']),
                        shareholder
                      )
                    ) {
                      message.error(translations().userExists, 10)
                      return
                    }
                    onSave(await getFormData())
                  }}
                >
                  {translations().uploadDoc}
                </CustomButton>
              </Col>
              {isEmpty(jumioResults) && (
                <Col>
                  <CustomButton onClick={() => setShowEditModal(true)}>{translations().detailsEdit}</CustomButton>
                </Col>
              )}
              <Col>
                <CustomButton
                  loading={isSaveLoading}
                  onClick={async () => {
                    data.id &&
                    isEquivalent(selectedOfficer?.jumioCheckResults || {}, jumioResults || {}) &&
                    savedData?.jumioRequestSent &&
                    jumioRequestSent &&
                    savedData?.agreementSignatory &&
                    agreementSigner &&
                    !editableDataChanged
                      ? onCancel()
                      : !data?.id &&
                        checkForDuplicates(
                          form.getFieldValue(['nameElements', 'surname']),
                          form.getFieldValue(['nameElements', 'forename']),
                          shareholder
                        )
                      ? message.error(translations().userExists, 10)
                      : onSave(await getFormData())
                  }}
                >
                  {selectedOfficer?.id &&
                  isEquivalent(selectedOfficer?.jumioCheckResults || {}, jumioResults || {}) &&
                  savedData?.jumioRequestSent &&
                  jumioRequestSent &&
                  savedData?.agreementSignatory &&
                  agreementSigner &&
                  !editableDataChanged
                    ? translations().cancel
                    : (!selectedOfficer?.jumioCheckResults?.addressVerified &&
                        !selectedOfficer?.jumioCheckResults?.identityVerified &&
                        jumioResults?.addressVerified &&
                        jumioResults?.identityVerified) ||
                      uploadDocsManually
                    ? translations().complete
                    : translations().saveForLater}
                </CustomButton>
              </Col>
            </Row>
          </div>
        )}

      <CustomModal
        title={officerVerificationResult ? <h3>{translations().verificationResult}</h3> : translations().modalTitle}
        open={showVerificationModal}
        onOk={() => async () => onVerify(await getFormData())}
        onCancel={() => setShowVerificationModal(false)}
        footer={
          <Row gutter={10}>
            {isEmpty(officerVerificationResult) && (
              <Col>
                <CustomButton
                  type='primary'
                  onClick={async () => {
                    onVerify(await getFormData())
                  }}
                  loading={isVerifyOfficerLoading}
                >
                  {translations().detailAreCorrect}
                </CustomButton>
              </Col>
            )}
            <Col>
              <CustomButton
                type={officerVerificationResult ? 'primary' : 'default'}
                onClick={() => setShowVerificationModal(false)}
              >
                {translations().backToForm}
              </CustomButton>
            </Col>
          </Row>
        }
      >
        {isEmpty(officerVerificationResult) && (
          <>
            <div className={styles.modalInfoText}>
              {translations().welcomeText1} <br />
              {translations().welcomeText2}
            </div>
            <p className={styles.boldTxt}>{translations().pleaseDoubleCheck}</p>
          </>
        )}
        <div className={styles.styledBody}>
          {translations().detailsInfo(
            form.getFieldValue(['nameElements', 'forename']),
            form.getFieldValue(['nameElements', 'surname']),
            form.getFieldValue('dateOfBirth'),
            form.getFieldValue(['address', 'addressLine1']),
            form.getFieldValue(['address', 'postalCode'])
          )}
        </div>
        {!isEmpty(officerVerificationResult) && (
          <>
            {translations().idVerifResult(officerVerificationResult.idVerifications?.[0].verified)}
            {translations().addressVerifResult(officerVerificationResult.addressVerifications?.[0].verified)}
          </>
        )}

        {isVerifyOfficerLoading && <DNALoader />}
      </CustomModal>

      <CustomModal
        title={translations().jumioRequest}
        open={showJumioVerificationModal}
        onCancel={() => setShowJumioVerificationModal(false)}
        footer={
          <>
            <Row gutter={10}>
              {!jumioRequestSent && (
                <Col>
                  <CustomButton
                    type='primary'
                    loading={jumioRequestLoading}
                    onClick={async () => sendJumioRequest(await getFormData())}
                  >
                    {translations().sendJumioRequest}
                  </CustomButton>
                </Col>
              )}
              <Col>
                <CustomButton onClick={() => setShowJumioVerificationModal(false)}>
                  {jumioRequestSent ? translations().close : translations().editDetails}
                </CustomButton>
              </Col>
            </Row>
          </>
        }
      >
        {!jumioRequestSent && (
          <>
            <p>{translations().startJumioRequestInfo}</p>
            <p>{translations().startJumioRequestInfo2}</p>
            <p>{translations().recepientEmail(form.getFieldValue('emailAddress'))}</p>

            <div className={styles.styledBody}></div>
          </>
        )}

        {jumioRequestSent && <p>{translations().jumioVerificationSent}</p>}

        {jumioRequestLoading && <DNALoader />}
      </CustomModal>

      <CustomModal
        title={translations().editDetails}
        open={showEditModal}
        onOk={() => {
          resetChecks()
          setShowEditModal(false)
        }}
        onCancel={() => setShowEditModal(false)}
      >
        {translations().resetChecks}
      </CustomModal>

      <CustomModal
        title={translations().areYouSure}
        open={showDocModal}
        onOk={async () => {
          setUploadDocManually(true)
          if (
            !data?.id &&
            checkForDuplicates(
              form.getFieldValue(['nameElements', 'surname']),
              form.getFieldValue(['nameElements', 'forename']),
              shareholder
            )
          ) {
            message.error(translations().userExists, 10)
            return
          }
          onSave(await getFormData())
          setShowDocModal(false)
        }}
        onCancel={() => setShowDocModal(false)}
      >
        {translations().docModalText}
      </CustomModal>
    </div>
  )
}
