import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import debounce from 'lodash/debounce'
import _ from 'lodash'
import { useInjection } from 'dna-react-ioc'
import { isEmpty } from 'dna-common'
import {
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Divider,
  Radio,
  Select,
  FormInstance,
  Typography,
  Tooltip,
  Grid,
  Table
} from 'antd'
import { renderSectionPermissionsFormItem } from '~/code/pages/PortalAccess/utils'
import { Text } from '~/code/components/Text'
import { Role } from '~/code/pages/PortalAccess/types/Role'
import { MultiSelect } from '~/code/components'
import { PortalAccessStoreSymbol } from '~/code/pages/PortalAccess/'
import { SpecificPermissionsEnum } from '~/code/pages/PortalAccess/types/SpecificPermissionsEnum'
import { PortalUserAccessType } from '~/code/pages/PortalAccess/types/PortalUserAccessType'
import { PortalAccessStoreType } from '~/code/pages/PortalAccess/types/PortalAccessStoreType'
import { AddUpdateLoginFormModel } from '~/code/pages/PortalAccess/types/AddUpdateLoginFormModel'
import { PortalAccessPermissionsType } from '~/code/pages/PortalAccess/types/PortalAccessPermissionsType'
import { PortalAccessSpecificPermissionsType } from '~/code/pages/PortalAccess/types/PortalAccessSpecificPermissionsType'
import { PortalAccessMerchantSelect } from '~/code/pages/PortalAccess/components/PortalAccessMerchantSelect'
import { UserSecurity } from '~/code/components'
import { PAGE_SIZE_MAX } from '~/code/constants/Configurations'

import translations from './translations'
import styles from './styles.scss'

const { useBreakpoint } = Grid

const customPermissions: PortalAccessPermissionsType = {
  overview: 'no',
  online_payments: 'no',
  pos_payments: 'no',
  pos_amex_payments: 'no',
  payment_links: 'no',
  virtual_terminal: 'no',
  settlements: 'no',
  reports: 'no',
  payment_methods: 'no',
  teammates: 'no',
  invoices: 'no',
  chargebacks: 'no'
}

const customSpecificPermissions: PortalAccessSpecificPermissionsType = {
  card_issuing: 'no'
}

export type AddEditLoginProps = {
  open: boolean
  form: FormInstance<AddUpdateLoginFormModel>
}

export const PortalAccessAddEditLogin = observer(({ form, open }: AddEditLoginProps) => {
  const screens = useBreakpoint()
  const store = useInjection<PortalAccessStoreType>(PortalAccessStoreSymbol)

  const {
    stores,
    merchants,
    permissions,
    permissionsTableData,
    specificPermissionsTableData,
    mostSpecificPermissionsTableData,
    searchLoginValue,
    isLoginsSending,
    selectedTableItem,
    isMerchantsTabActive,
    isMerchantsLoading,
    onSubmit,
    setSearchMerchantValue,
    loadMerchants,
    setMostSpecificPermissions,
    fetchFullNameByLogin,
    closeUserAccessDrawer
  } = store

  const isEditMode = Boolean(selectedTableItem?.merchantId)
  const storeList = useMemo(() => stores.map(({ id, name }) => ({ value: id, label: name })), [stores])

  const financePermissions = permissions?.finance

  /**
   *  UI states
   */
  const [inviteAs, setInviteAs] = useState<PortalUserAccessType>(PortalUserAccessType.admin)
  const [selectedRole, setSelectedRole] = useState<Role>(selectedTableItem?.role || Role.finance)
  const [selectedTheMostSpecificPermissions, selectTheMostSpecificPermissions] = useState([])
  const [selectedPermissions, setSelectedPermissions] = useState<PortalAccessPermissionsType>(
    selectedTableItem?.permissions || financePermissions
  )
  const [selectedSpecificPermissions, setSelectedSpecificPermissions] = useState<PortalAccessSpecificPermissionsType>(
    selectedTableItem?.specificPermissions || customSpecificPermissions
  )
  const [selectedCustomPermissions, setSelectedCustomPermissions] = useState<PortalAccessPermissionsType>(
    selectedTableItem?.permissions || customPermissions
  )
  const [isPermissionsValid, setIsPermissionsValid] = useState<boolean>(true)
  const [isUserPresent, setUserPresent] = useState<boolean>()

  useEffect(() => setUserPresent(undefined), [open])

  useEffect(() => {
    setMostSpecificPermissions(selectedTheMostSpecificPermissions)
  }, [selectedTheMostSpecificPermissions])

  useEffect(() => {
    form.resetFields([
      ...Object.keys({ ...selectedPermissions, ...selectedSpecificPermissions }).map(key => ['permissions', key] as any)
    ])
  }, [selectedPermissions, selectedSpecificPermissions])

  useEffect(() => form.resetFields(['role']), [selectedRole])

  useEffect(() => {
    form.resetFields(['inviteAs'])

    setIsPermissionsValid(
      inviteAs === PortalUserAccessType.admin ? true : !_.isEqual(selectedPermissions, customPermissions)
    )
  }, [inviteAs])

  useEffect(() => {
    setInviteAs(selectedTableItem?.inviteAs || PortalUserAccessType.admin)
    setSelectedRole(selectedTableItem?.role || Role.finance)
    setSelectedPermissions(selectedTableItem?.permissions || financePermissions)
    setSelectedCustomPermissions(selectedTableItem?.permissions || customPermissions)
    selectTheMostSpecificPermissions(selectedTableItem?.mostSpecificPermissions || [])
    setSelectedSpecificPermissions(selectedTableItem?.specificPermissions || customSpecificPermissions)
  }, [selectedTableItem])

  const onPermissionChange = (sectionCode, permission) => {
    if (sectionCode === 'virtual_terminal' && permission === 'no') {
      setMostSpecificPermissions([
        ...selectedTheMostSpecificPermissions.filter(
          permission => permission !== SpecificPermissionsEnum.VIRTUAL_TERMINAL_REFUNDS
        )
      ])
    }

    const _selectedPermissions = { ...selectedPermissions, [sectionCode]: permission }
    setSelectedPermissions(_selectedPermissions)
    setIsPermissionsValid(!_.isEqual(_selectedPermissions, customPermissions))

    if (_.isEqual(_selectedPermissions, financePermissions)) {
      setSelectedRole(Role.finance)
    } else {
      setSelectedRole(Role.custom)
      setSelectedCustomPermissions(_selectedPermissions)
    }
  }

  const onSpecificPermissionChange = (sectionCode, permission) => {
    const _selectedSpecificPermissions = { ...selectedSpecificPermissions, [sectionCode]: permission }
    setSelectedSpecificPermissions(_selectedSpecificPermissions)
  }

  const selectMostSpecificPermission = permission => {
    if (selectedTheMostSpecificPermissions.includes(permission)) {
      selectTheMostSpecificPermissions([...selectedTheMostSpecificPermissions.filter(item => item !== permission)])
      return
    }
    selectTheMostSpecificPermissions([...selectedTheMostSpecificPermissions, permission])
  }

  const onRoleChange = role => {
    if (role === Role.finance) {
      setSelectedPermissions(financePermissions)
    } else {
      setSelectedPermissions(selectedCustomPermissions)
    }
    selectTheMostSpecificPermissions([])
    setSelectedSpecificPermissions(customSpecificPermissions)
    setIsPermissionsValid(role === Role.finance ? true : !_.isEqual(selectedCustomPermissions, customPermissions))
    setSelectedSpecificPermissions(customSpecificPermissions)
    setMostSpecificPermissions([])
    setSelectedRole(role)
  }

  const onInviteAs = value => {
    if (value !== selectedTableItem?.inviteAs) {
      selectTheMostSpecificPermissions([])
      setSelectedSpecificPermissions(customSpecificPermissions)
    } else {
      selectTheMostSpecificPermissions(selectedTableItem?.mostSpecificPermissions || [])
      setSelectedSpecificPermissions(selectedTableItem?.specificPermissions || customSpecificPermissions)
    }
    setInviteAs(value)
  }

  const permissionNames = Object.keys(permissionsTableData) || []
  const specificPermissionsNames = Object.keys(specificPermissionsTableData) || []

  const renderSectionPermissionsFormItems = () => {
    return (
      <>
        {permissionNames.map(item => {
          return (
            <div key={item}>
              {renderSectionPermissionsFormItem(
                translations().formFields.sections[item] || item,
                item,
                permissionsTableData[item] as any,
                onPermissionChange,
                selectedPermissions[item]
              )}
            </div>
          )
        })}
      </>
    )
  }

  const renderSectionSpecificPermissionsFormItems = () => {
    return (
      <>
        {specificPermissionsNames.map(item => {
          return (
            <div key={item}>
              {renderSectionPermissionsFormItem(
                translations().formFields.sections[item] || item,
                item,
                specificPermissionsTableData[item] as any,
                onSpecificPermissionChange,
                selectedSpecificPermissions[item]
              )}
            </div>
          )
        })}
      </>
    )
  }

  const renderDesktopPermissions = (
    specific: boolean = false,
    title: string,
    renderCallBack: () => React.ReactNode
  ) => {
    return (
      <div className={styles.permissions}>
        <Row>
          <Col sm={10} xs={8}>
            <Text>{title}</Text>
          </Col>
          <Col sm={14} xs={16}>
            <Row>
              <Col sm={8} xs={8}>
                <Text type='secondary'>{translations().formFields.noAccess}</Text>
              </Col>
              <Col sm={8} xs={8}>
                <Text type='secondary'>{translations().formFields.readAccess}</Text>
              </Col>
              <Col sm={8} xs={8}>
                <Text type='secondary'>{translations().formFields.fullAccess}</Text>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider />

        {renderCallBack()}

        {!specific && !isPermissionsValid && (
          <div className={styles.error}>{translations().formErrors.permissionRequired}</div>
        )}
      </div>
    )
  }

  const renderSpecificPermission = (key: string) => {
    switch (key) {
      case SpecificPermissionsEnum.VIRTUAL_TERMINAL_REFUNDS:
        return (
          <Form.Item shouldUpdate={true} noStyle={true}>
            {() => (
              <Form.Item key={key} name={key} className={styles.permissionCheckbox}>
                <Tooltip title={translations().virtualTerminalRefundsHint}>
                  <Checkbox
                    checked={selectedTheMostSpecificPermissions?.includes(key) || false}
                    onChange={() => selectMostSpecificPermission(key)}
                  />
                </Tooltip>
              </Form.Item>
            )}
          </Form.Item>
        )
      default:
        return (
          <Form.Item key={key} name={key} className={styles.permissionCheckbox}>
            <Checkbox
              checked={selectedTheMostSpecificPermissions?.includes(key) || false}
              onChange={() => selectMostSpecificPermission(key)}
            />
          </Form.Item>
        )
    }
  }

  const debounceAPICallback = debounce(async value => {
    const { firstName, lastName } = await fetchFullNameByLogin(value)

    if (!firstName || !lastName) {
      form.setFieldsValue({ isDefault: true, sendWelcomeEmail: true, firstName: '', lastName: '' })
      setUserPresent(false)
      return
    }

    form.setFieldsValue({ firstName, lastName, isDefault: false, sendWelcomeEmail: true })
    setUserPresent(true)
  }, 500)

  const handleEmailSearch = value => {
    if (!Boolean(value)) {
      form.setFieldsValue({ isDefault: false, sendWelcomeEmail: true, firstName: '', lastName: '' })
      setUserPresent(undefined)

      return
    }

    if (form.getFieldError('email').length) {
      return
    }

    debounceAPICallback(value)
  }

  return (
    <Form
      form={form}
      initialValues={
        !isEditMode
          ? {
              sendWelcomeEmail: true,
              isDefault: false
            }
          : {}
      }
      requiredMark={false}
      labelAlign='left'
      labelCol={{ span: 6 }}
      onFinish={v => {
        isPermissionsValid && onSubmit(v)
      }}
    >
      <Form.Item
        label={translations().formFields.email}
        name='email'
        initialValue={selectedTableItem?.email || (isMerchantsTabActive ? '' : searchLoginValue)}
        rules={[
          { type: 'email', message: translations().formErrors.enterValidEmail },
          { required: true, message: translations().formErrors.emailRequired }
        ]}
      >
        <Input
          disabled={isEditMode || (!isMerchantsTabActive && !isEditMode)}
          onChange={e => handleEmailSearch(e.target.value)}
        />
      </Form.Item>

      {!isMerchantsTabActive && !isEditMode && (
        <Form.Item
          label={translations().formFields.merchant}
          name='merchantId'
          initialValue={selectedTableItem?.merchantId}
          rules={[{ required: true, message: translations().formErrors.merchantRequired }]}
        >
          <PortalAccessMerchantSelect
            loading={isMerchantsLoading}
            merchants={merchants}
            onClear={() => store.resetSelect()}
            onSearch={v => {
              setSearchMerchantValue(v)
              loadMerchants()
            }}
            onSelect={v => {
              store.loadStoresByMerchant(v)
              form.resetFields(['shops'])
            }}
          />
        </Form.Item>
      )}

      {(isMerchantsTabActive || isEditMode) && (
        <Form.Item
          label={translations().formFields.firstName}
          name='firstName'
          initialValue={selectedTableItem?.firstName}
        >
          <Input disabled={isEditMode || isUserPresent} />
        </Form.Item>
      )}

      {(isMerchantsTabActive || isEditMode) && (
        <Form.Item
          label={translations().formFields.lastName}
          name='lastName'
          initialValue={selectedTableItem?.lastName}
        >
          <Input disabled={isEditMode || isUserPresent} />
        </Form.Item>
      )}

      <Form.Item
        label={translations().formFields.store}
        name='shops'
        initialValue={selectedTableItem?.shops}
        required
        rules={[
          {
            validator: async (_, value) => {
              if (isEmpty(value)) {
                throw new Error(translations().formErrors.shopRequired)
              }
            }
          }
        ]}
      >
        <MultiSelect isSearchable={true} options={storeList} placeholder={translations().shopPlaceholder} />
      </Form.Item>

      {!isEditMode && (
        <Form.Item name='sendWelcomeEmail' valuePropName='checked'>
          <Checkbox>{translations().formFields.sendWelcomeEmail}</Checkbox>
        </Form.Item>
      )}

      {!isEditMode && (
        <Tooltip placement={screens.xs ? 'top' : 'left'} title={translations().isDefaultTooltip}>
          <Form.Item name='isDefault' valuePropName='checked' className={styles.isDefaultFormItem}>
            <Checkbox>{translations().formFields.isDefault}</Checkbox>
          </Form.Item>
        </Tooltip>
      )}

      {!isEditMode && isMerchantsTabActive && <UserSecurity form={form} />}

      <Typography.Title level={5}>{translations().formFields.teammatePermissions}</Typography.Title>

      <Form.Item name={'inviteAs'} initialValue={inviteAs} rules={[{ required: true, message: '' }]}>
        <Radio.Group onChange={event => onInviteAs(event?.target?.value)}>
          <Radio value={PortalUserAccessType.admin}>{translations().formFields.inviteAsAdmin}</Radio>
          <Radio value={PortalUserAccessType.restricted}>{translations().formFields.inviteAsRestrictedAccess}</Radio>
        </Radio.Group>
      </Form.Item>

      {inviteAs === PortalUserAccessType.restricted && (
        <>
          <Form.Item name={'role'} initialValue={selectedRole}>
            <Select
              options={[
                {
                  label: translations().formFields.accountantAccess,
                  value: 'finance'
                },
                {
                  label: translations().formFields.customAccess,
                  value: 'custom'
                }
              ]}
              onChange={onRoleChange}
            />
          </Form.Item>

          <Typography.Title level={5}>{translations().formFields.grantedPermissions}</Typography.Title>

          {renderDesktopPermissions(false, translations().formFields.sectionName, renderSectionPermissionsFormItems)}
        </>
      )}

      <Typography.Title level={5} className={styles.specificPermissions}>
        {translations().formFields.grantedSpecificPermissions}
      </Typography.Title>
      {renderDesktopPermissions(true, translations().formFields.sectionName, renderSectionSpecificPermissionsFormItems)}

      {mostSpecificPermissionsTableData.length ? (
        <div className={styles.root}>
          <Table
            pagination={{
              pageSize: PAGE_SIZE_MAX,
              hideOnSinglePage: true,
              size: 'small'
            }}
            columns={[
              { dataIndex: 'title', title: translations().specificPermissions },
              { render: ({ key }) => renderSpecificPermission(key) }
            ]}
            dataSource={mostSpecificPermissionsTableData}
          />
        </div>
      ) : null}
    </Form>
  )
})
