import React from 'react'
import { TranslationBuilder } from '~/code/components/Translation'
import { FormFields } from './../models/FormFields'

export default TranslationBuilder.create<{
    confirm: string,
    cancel: string,
    change: string,
    fields: Record<keyof FormFields, any>
    errors: Record<keyof FormFields, any>
    changeItem: (el) => React.ReactNode
}>()

