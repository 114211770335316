import {injectable} from 'inversify'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { message } from 'antd'
import moment from 'moment-timezone'
import { ITransactionStatementStore } from '~/code/pages/Acquiring/pages/Statement/models'
import { StatementExportsModalStoreType } from '~/code/pages/Acquiring/pages/Statement/components/StatementExportsModal/StatementExportsModalStoreType'
import { isPartner } from '~/code/services/auth'
import { postEcomExports } from '~/code/services/fetchers'
import { getExportColumns } from '~/code/pages/Acquiring/pages/Statement/components/StatementExportsModal/services/constants'
import { EcomTransactionType } from '~/code/stores/TransactionStatementStore/models'
import { TransactionsParams } from '~/code/models'
import translations from './translations'

export type ExportOptionsType = Record<keyof EcomTransactionType, {value: string, label: string}>

const columnsOnlyForFullAccess = [
    'transactionChannel', 'sourceClient'
]

@injectable()
export class StatementExportModalStore implements StatementExportsModalStoreType {
    transactionsStatementStore: ITransactionStatementStore = null
    selectedOptions: any[] = []
    filteredColumns: string[] = []
    fields: string[] = []
    isUploading = false
    exportTypeOptions = []
    selectedExportType: 'all' | 'custom' = 'all'
    constructor() {
        makeObservable(this, {
            fields: observable,
            isUploading: observable,
            selectedExportType: observable,
            selectedOptions: observable,
            filteredColumns: observable,
            options: computed,
            selectExportType: action.bound,
            selectOption: action.bound,
            handleCancel: action.bound,
            init: action.bound,
        })
    }

    public init(transactionsStatementStore) {
        this.transactionsStatementStore = transactionsStatementStore
        this.filteredColumns = Object.keys(getExportColumns()).filter((c) => columnsOnlyForFullAccess.indexOf(c as string) < 0 || this.hasFullAccess)
        this.fields = [...this.filteredColumns]
        this.exportTypeOptions = [
            {value: 'all', label: translations().all(this.fields.length)},
            {value: 'custom', label: translations().custom}
        ]
    }

    get isButtonDisabled() {
        return this.isUploading || !this.fields.length
    }

    get hasFullAccess() {
        return !isPartner()
    }

    get options() {
        return this.filteredColumns.reduce((acc: any, curr, index) => {
            return {
                ...acc,
                [this.filteredColumns[index]]: getExportColumns()[this.filteredColumns[index]]
            }
        }, {})
    }

    public selectExportType = (value: 'all' | 'custom') => {
        this.selectedExportType = value
        if(value === 'all') this.fields = Object.keys(this.options)
        else this.fields = []
    }

    public selectOption = (options: (keyof ExportOptionsType)[]) => {
        this.fields = [...options]
    }

    public handleCancel = () => {
        this.transactionsStatementStore.openExportModal(false)
        this.fields = Object.keys(this.options)
        this.selectExportType('all')
    }

    public onExportsFormSubmit = async () => {
        const {
            startDate: from,
            endDate: to,
            acquirer,
            acquisitionChannel,
            currency,
            status: statuses,
            transactionChannel: transactionChannels,
            paymentMethod: paymentMethods,
            issuerCountry: issuerCountries,
            ipCountry: ipCountries,
            payerIp,
            payerEmail,
            cardMask,
            terminal,
            reference,
            mid,
            merchantReference,
            threeDSVersion,
            payerAuthenticationResult: payerAuthenticationResults,
            transactionId,
            processingType: processingTypes,
            cardScheme: cardSchemes,
            signifydDecision: signifydDecisions,
            limit,
            offset,
            amountFrom,
            amountTo
        } = this.transactionsStatementStore.request as TransactionsParams
        const {merchant} = this.transactionsStatementStore.acquiringFilterStore.merchantSelectStore
        const {tradeName} = merchant

        const fileName = `${tradeName} ${moment(from).format('DD MMM YYYY')} - ${moment(to).format('DD MMM YYYY')}.csv`

        try {
            runInAction(() => this.isUploading = true)

            const {result, error} = await  postEcomExports({
                from,
                to,
                format: 'csv',
                fields: this.fields,
                fileName,
                acquirer,
                acquisitionChannel,
                merchantId: this.transactionsStatementStore.acquiringFilterStore.merchantId,
                currency,
                statuses,
                transactionChannels,
                paymentMethods,
                issuerCountries,
                ipCountries,
                payerIp,
                payerEmail,
                cardMask,
                terminal,
                reference,
                mid,
                merchantReference,
                threeDSVersion: threeDSVersion === undefined ? threeDSVersion :  threeDSVersion.toString(),
                payerAuthenticationResults,
                transactionId: transactionId.length ? transactionId : undefined,
                processingTypes,
                cardSchemes,
                signifydDecisions,
                limit,
                offset,
                amountFrom,
                amountTo
            })

            if (error) throw new Error(error.message)
            else message.success(translations().successDownloadingStatement, 10)
        } catch(error) {
            message.error(translations().errDownloadingStatement || error.message, 10)
        } finally {
            runInAction(() => {
                this.isUploading = false
                this.handleCancel()
            })
        }
    }
}

