import React from 'react'
import { Button, List, Modal, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

import { SvgCheckBlack } from '~/assets/icons'
import { isCorporate } from '~/code/pages/StartApplication/services'
import translations from './translations'
import { OfficerListItemProps } from './props'
import styles from './OfficerListItem.scss'

const { confirm } = Modal

export const OfficerListItem = (props: OfficerListItemProps) => {
    const { isCompleted, isRemoveDisabled, onClick, onRemove } = props

    let Component = () => <div />
    let deleteTitle = ''

    if (isCorporate(props)) {
        const { corporateName } = props
        deleteTitle = corporateName
        Component = () => <div className={styles.name}>{corporateName}</div>
    } else {
        const { name, surname } = props
        const alias = (name ? name.charAt(0) : '') + (surname ? surname.charAt(0) : '')

        deleteTitle = `${(name || '')} ${surname || ''}`
        Component = () => <>
            <div className={styles.alias}>{alias}</div>
            <div className={styles.name}>{name} {surname}</div>
        </>
    }

    const onDeleteClicked = () => {
        confirm({
            title: translations().deleteFormTitle(deleteTitle),
            content: translations().deleteFormContent,
            async onOk() {
                onRemove()
            },
            onCancel() {
                return
            }
        })
    }

    return <List.Item
        actions={[
            <Tooltip title={translations().edit}>
                <Button 
                    type='text'
                    icon={<EditOutlined />}
                    disabled={!onClick}
                    onClick={onClick}
                />
            </Tooltip>,
            <Tooltip title={translations().delete}>
                <Button 
                    type='text'
                    icon={<DeleteOutlined />}
                    disabled={isRemoveDisabled || !onRemove}
                    onClick={ (e) => {
                        e.stopPropagation()
                        onDeleteClicked()
                    } }
                />
            </Tooltip>
        ]}
    >
        <div className={styles.contentContainer}>
            <Component />
            <div className={styles.checkBoxContainer}>
                {isCompleted ? <SvgCheckBlack /> : <div className={styles.status}>{translations().notCompleted}</div>}
            </div>
        </div>
    </List.Item>
}