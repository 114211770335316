import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  mainInfo: string
  title: string
  firstName: string
  middleName: string
  surname: string
  dateOfBirth: string
  gender: string
  placeOfBirth: string
  nationality: string
  occupation: string
  emailAddress: string
  telephoneNumber: string
  residentialAddress: string
  ownershipPercentage: string

  country: string
  postalCode: string
  addressLine1: string
  addressLine2: string
  townOrCity: string
  countyOrState: string
  county: string
  houseName: string
  houseNumber: string
}>()
