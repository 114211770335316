import { message } from 'antd'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { PaymentSolution } from '../models'
import { StartApplicationStore } from '../StartApplicationStore'
import { TariffType } from '../components/Pricing/models/TariffType'
import { fetchTariffs } from '../components/Pricing/services'
import { IcTariffType } from '../components/Pricing/models/IcTariffType'

export class PricingStore {
    isTariffsLoading: boolean = false
    tariffsList: IcTariffType[] = []

    constructor(private parentStore: StartApplicationStore) {
        makeObservable(this, {
            isTariffsLoading: observable,
            tariffsList: observable,

            tariffType: computed,
            productType: computed,
            filteredtariffsList: computed,

            reset: action,
            loadTariffs: action
        })
    }

    get shouldBeSkipped() {
        const { dataStore } = this.parentStore

        return !dataStore.generalInformationData.isMsaSigned &&
            dataStore.generalInformationData.isFastTrack &&
            dataStore.contactInfoData.acquisitionChannel === 'fpms'
    }

    get tariffType() {
        return this.dataStore.tariffs?.tariffType
    }

    get productType() {
        return this.parentStore.dataStore.generalInformationData.paymentSolution === PaymentSolution.POS ? 'pos' : 'ecom'
    }

    get filteredtariffsList(): IcTariffType[] {
        return this.tariffsList.filter(p => p.product === this.productType)
    }

    get dataStore() {
        return this.parentStore.dataStore
    }

    reset() {
        this.isTariffsLoading = false
        this.dataStore.setTariffs(null)
    }

    goBack() {
        this.parentStore.openGeneralInformationPage()
    }

    goNext(values: any) {
        this.parentStore.onFinishPricing(values, 'isNextLoading')
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    setTariffType(tariffType: TariffType) {
        this.dataStore.setTariffs({
            ...(this.dataStore.tariffs || {}),
            tariffType
        })
    }

    async loadTariffs() {
        try {
            runInAction(() => {
                this.isTariffsLoading = true
            })
            const { status, error, result } = await fetchTariffs()
            if (status !== 200 || error) {
                message.error(error.message || 'Error fetching tariffs')
            } else {
                runInAction(() => {
                    this.tariffsList = result
                })
            }
        } catch (error) {
            message.error('Error fetching tariffs')
        } finally {
            runInAction(() => {
                this.isTariffsLoading = false
            })
        }
    }
}