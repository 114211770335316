import translations from './translations'

translations.add('en', {
    copied: 'Copied',
    applicationData: 'Application Data',
    attachedDocuments: 'Attached Documents',
    checkingResults: 'Checking Results',
    notes: 'Notes',
    complianceTeam: 'Compliance Team',
    riskTeam: 'Risk Team',
    salesTeam: 'Sales Team',
    noComments: 'No comments',
    backToProcesses: 'Back to processes',
    lexisNexis: 'LexisNexis',
    creditSafe: 'Creditsafe',
    complianceAssist: 'Compliance Assist',
    companiesHouse: 'Companies House',
    errLoadingProcessInfo: 'Error loading process info',
    errLoadingApplicationData: 'Error loading application data',
    errLoadingNotes: 'Error loading notes',
    errLoadingCheckingResults: 'Error loading checking results',
    ecospend: 'Ecospend results',
    jumio: 'Jumio results',
    errorLoadingDocs: 'Error while loading document'
})
