import React from 'react'
import { Col, Row } from 'antd'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { isPartner } from '~/code/services/auth'
import { PaymentMethodsBreakdown } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/breakdown/PaymentMethodsBreakdown/PaymentMethodsBreakdown'
import { EcomErrorCodesStatusType } from '~/code/stores/AnalyticsStore/models/StatusType'
import { ecomErrorCodesStatusTabs, ecomStatusTabs, posStatusTabs } from '../../constants'
import { AnalyticsOverviewProps } from './props'
import translations from './translations'
import { SummaryRow, RatesSummaryRow, MCCBreakdown, YearsComparison, IBBreakdown, TransactionsLineChart, MerchantsBreakdown, PCSBreakdown, ErrorCodesBreakdown, AcquisitionChannelsBreakdown } from './components'
import styles from './AnalyticsOverview.scss'

export const AnalyticsOverview = observer((props: AnalyticsOverviewProps) => {
    const { store, merchantProductType } = props

    return <div>
        {!isPartner() && (
            <RatesSummaryRow
                data={store.approvalRateData}
                isLoading={store.isLoadingApprovalRate}
                className={styles.marginBottom}
            />
        )}
        <SummaryRow
            loading={store.isLoadingSummary}
            data={store.summaryRowData}
            percentageChange={store.statusSummaryPercentageChange}
            percentageChangeTooltipText={translations().percentageChangeTooltipText(store.prevDates?.prevStartDate, store.prevDates?.prevEndDate)}
            className={styles.marginBottom}
            includeStatusOther={merchantProductType === 'ecom'}
        />
        {merchantProductType === 'pos' && (
            <>
                <Row gutter={[24, 24]} className={styles.marginBottom}>
                    <Col xs={24}>
                        <TransactionsLineChart
                            data={ store.transactionsLineChartData && toJS(store.transactionsLineChartData) }
                            currencySymbol={store.currencySymbol}
                            onSumStatusChange={store.setTransactionsSumStatus}
                            onCountStatusChange={store.setTransactionsCountStatus}
                            interval={store.transactionsInterval}
                            intervalOptions={store.transactionsIntervalOptions}
                            onIntervalChange={store.setTransactionsInterval}
                            onTabChange={store.setTransactionsCurrentTab}
                            currentTab={store.transactionsCurrentTab}
                            currentStatus={store.transactionsCurrentStatus}
                            legendPosition={store.legendPosition}
                            statusTabs={posStatusTabs}
                        />
                    </Col>
                    <Col xs={24}>
                        <MerchantsBreakdown
                            loadingSum={store.isLoadingMerchantsAmountBreakdown}
                            loadingCount={store.isLoadingMerchantsCountBreakdown}
                            data={ store.merchantsBreakdownData && toJS(store.merchantsBreakdownData) }
                            currencySymbol={store.currencySymbol}
                            statusTabs={posStatusTabs}
                        />
                    </Col>
                </Row>
                <Row gutter={[24, 24]} className={styles.marginBottom}>
                    <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                        <ErrorCodesBreakdown
                            loadingSum={store.isLoadingErrorCodesAmountBreakdown}
                            loadingCount={store.isLoadingErrorCodesCountBreakdown}
                            data={ store.errorCodesBreakdownData && toJS(store.errorCodesBreakdownData) }
                            currencySymbol={store.currencySymbol}
                        />
                    </Col>
                    <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                        <MCCBreakdown
                            loadingSum={store.isLoadingMCCAmountBreakdown}
                            loadingCount={store.isLoadingMCCCountBreakdown}
                            data={ store.mccBreakdownData && toJS(store.mccBreakdownData) }
                            currencySymbol={store.currencySymbol}
                            statusTabs={posStatusTabs}
                        />
                    </Col>
                </Row>
                <Row gutter={[24, 24]} className={styles.marginBottom}>
                    <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                        <PCSBreakdown
                            loadingSum={store.isLoadingPCSAmountBreakdown}
                            loadingCount={store.isLoadingPCSCountBreakdown}
                            data={ store.pcsBreakdownData && toJS(store.pcsBreakdownData) }
                            currencySymbol={store.currencySymbol}
                            title={translations().cardTypes}
                            statusTabs={posStatusTabs}
                        />
                    </Col>
                    <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                        <IBBreakdown
                            loadingSum={store.isLoadingIssuingBanksAmountBreakdown}
                            loadingCount={store.isLoadingIssuingBanksCountBreakdown}
                            currencySymbol={store.currencySymbol}
                            data={ store.issuingBanksBreakdownData && toJS(store.issuingBanksBreakdownData) }
                            title={translations().issuingBanks}
                            statusTabs={posStatusTabs}
                        />
                    </Col>
                </Row>
                {!isPartner() && (
                    <Row gutter={[24, 24]} className={styles.marginBottom}>
                        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                            <AcquisitionChannelsBreakdown
                                loadingSum={store.isLoadingAcquisitionChannelsAmountBreakdown}
                                loadingCount={store.isLoadingAcquisitionChannelsCountBreakdown}
                                data={ store.acquisitionChannelsBreakdownData && toJS(store.acquisitionChannelsBreakdownData) }
                                currencySymbol={store.currencySymbol}
                                title={translations().acquisitionChannels}
                                statusTabs={posStatusTabs}
                            />
                        </Col>
                    </Row>
                )}
                <Row gutter={[24, 24]}>
                    <Col xs={24}>
                        <YearsComparison
                            loading={store.isLoadingYearsComparison}
                            data={ store.yearsComparisonData && toJS(store.yearsComparisonData) }
                            merchant={store.merchantName}
                            year1={store.yearsComparisonYear1}
                            year2={store.yearsComparisonYear2}
                            onYearsChange={(year1, year2) => {
                                store.changeYearsComparisonYears(year1, year2)
                            }}
                            currencySymbol={store.configStore.currency.symbol}
                        />
                    </Col>
                </Row>
            </>
        )}
        {merchantProductType === 'ecom' && (
            <>
                <Row gutter={[24, 24]} className={styles.marginBottom}>
                    <Col xs={24}>
                        <TransactionsLineChart
                            data={ store.transactionsLineChartData && toJS(store.transactionsLineChartData) }
                            currencySymbol={store.currencySymbol}
                            onSumStatusChange={store.setTransactionsSumStatus}
                            onCountStatusChange={store.setTransactionsCountStatus}
                            interval={store.transactionsInterval}
                            intervalOptions={store.transactionsIntervalOptions}
                            onIntervalChange={store.setTransactionsInterval}
                            onTabChange={store.setTransactionsCurrentTab}
                            currentTab={store.transactionsCurrentTab}
                            currentStatus={store.transactionsCurrentStatus}
                            legendPosition={store.legendPosition}
                            statusTabs={ecomStatusTabs}
                        />
                    </Col>
                    <Col xs={24}>
                        <MerchantsBreakdown
                            loadingSum={store.isLoadingMerchantsAmountBreakdown}
                            loadingCount={store.isLoadingMerchantsCountBreakdown}
                            data={ store.merchantsBreakdownData && toJS(store.merchantsBreakdownData) }
                            currencySymbol={store.currencySymbol}
                            statusTabs={ecomStatusTabs}
                        />
                    </Col>
                </Row>
                <Row gutter={[24, 24]} className={styles.marginBottom}>
                    <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                        <PaymentMethodsBreakdown
                            loadingSum={store.isLoadingPaymentMethodsAmountBreakdown}
                            loadingCount={store.isLoadingPaymentMethodsCountBreakdown}
                            data={ store.paymentMethodsBreakdownData && toJS(store.paymentMethodsBreakdownData) }
                            currencySymbol={store.currencySymbol}
                            statusTabs={ecomStatusTabs}
                        />
                    </Col>
                    {!isPartner() && (
                        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                            <ErrorCodesBreakdown
                                loadingSum={store.isLoadingErrorCodesAmountBreakdown}
                                loadingCount={store.isLoadingErrorCodesCountBreakdown}
                                data={ store.errorCodesBreakdownData && toJS(store.errorCodesBreakdownData) }
                                currencySymbol={store.currencySymbol}
                                statusTabs={ecomErrorCodesStatusTabs}
                                renderSumTitle={(status: EcomErrorCodesStatusType) => translations().ecomErrorCodes.sumTitle(translations().ecomErrorCodes.status[status], store.currencySymbol)}
                                renderCountTitle={(status: EcomErrorCodesStatusType) => translations().ecomErrorCodes.countTitle(translations().ecomErrorCodes.status[status], store.currencySymbol)}
                            />
                        </Col>
                    )}
                    <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                        <PCSBreakdown
                            loadingSum={store.isLoadingPCSAmountBreakdown}
                            loadingCount={store.isLoadingPCSCountBreakdown}
                            data={ store.pcsBreakdownData && toJS(store.pcsBreakdownData) }
                            currencySymbol={store.currencySymbol}
                            title={translations().cardTypesCardPaymentsOnly}
                            statusTabs={ecomStatusTabs}
                        />
                    </Col>
                    <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                        <IBBreakdown
                            loadingSum={store.isLoadingIssuingBanksAmountBreakdown}
                            loadingCount={store.isLoadingIssuingBanksCountBreakdown}
                            currencySymbol={store.currencySymbol}
                            data={ store.issuingBanksBreakdownData && toJS(store.issuingBanksBreakdownData) }
                            title={translations().issuingBanksCardPaymentsOnly}
                            statusTabs={ecomStatusTabs}
                        />
                    </Col>
                    {!isPartner() && (
                        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                            <AcquisitionChannelsBreakdown
                                loadingSum={store.isLoadingAcquisitionChannelsAmountBreakdown}
                                loadingCount={store.isLoadingAcquisitionChannelsCountBreakdown}
                                data={ store.acquisitionChannelsBreakdownData && toJS(store.acquisitionChannelsBreakdownData) }
                                currencySymbol={store.currencySymbol}
                                title={translations().acquisitionChannels}
                                statusTabs={ecomStatusTabs}
                            />
                        </Col>
                    )}
                    <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                        <MCCBreakdown
                            loadingSum={store.isLoadingMCCAmountBreakdown}
                            loadingCount={store.isLoadingMCCCountBreakdown}
                            data={ store.mccBreakdownData && toJS(store.mccBreakdownData) }
                            currencySymbol={store.currencySymbol}
                            statusTabs={ecomStatusTabs}
                        />
                    </Col>
                </Row>
                <Row gutter={[24, 24]} className={styles.marginBottom}>
                    <Col xs={24}>
                        <YearsComparison
                            loading={store.isLoadingYearsComparison}
                            data={ store.yearsComparisonData && toJS(store.yearsComparisonData) }
                            merchant={store.merchantName}
                            year1={store.yearsComparisonYear1}
                            year2={store.yearsComparisonYear2}
                            onYearsChange={(year1, year2) => {
                                store.changeYearsComparisonYears(year1, year2)
                            }}
                            currencySymbol={store.currencySymbol}
                        />
                    </Col>
                </Row>
            </>
        )}
    </div>
})
