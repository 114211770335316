import moment from 'moment'
import { cloneObject, isEmpty } from 'dna-common'
import { DATE_PATTERN } from '~/code/constants/Patterns'
import detailsFormTranslations from 'startapp/components/DetailsForm/translations'
import { CompanyProfile, CompanyProfileScheme, CompanyType, OfficerProfile, OfficerProfileScheme, PaymentSolution, PCSProfile } from 'startapp/models'
import { getAge } from './utils'
import { CorpOfficerListItemProps, OfficerListItemProps } from '../components/CompleteApplication/components/OfficerListItem/props'
import { TariffRequestType } from '../components/Pricing/models/TariffRequestType'
import { FinancialInfoScheme } from 'startapp/models/schemes/FinancialInfoScheme'

export function isCountryUK(country: string) {
    return country === 'United Kingdom'
}

export const isPCSCorporate = (pcsProfile: PCSProfile) => {
    if (pcsProfile && pcsProfile.kind && pcsProfile.kind.toLowerCase().includes('corporate')) {
        return true
    }
    return false
}

export const isCompanyProfileValid = (companyProfile: CompanyProfile, companyType: CompanyType = 'company', paymentSolution: PaymentSolution) => {
    return CompanyProfileScheme.isValidSync(cloneObject({
        ...companyProfile,
        companyType,
        paymentSolution
    }))
}

export const isFinancialInfoValid = (companyProfile: CompanyProfile, companyType: CompanyType = 'company', paymentSolution: PaymentSolution) => {
    return FinancialInfoScheme.isValidSync(cloneObject({
        ...companyProfile,
        companyType,
        paymentSolution
    }))
}

export const isCompanyOfficerValid = (officerProfile: OfficerProfile) => {
    return OfficerProfileScheme.isValidSync(cloneObject(officerProfile))
}

export const isCompanyPCSValid = (pcsProfile: PCSProfile) => {
    if (isPCSCorporate(pcsProfile)) {
        return Boolean(pcsProfile?.identification?.registrationNumber)
    }
    return OfficerProfileScheme.isValidSync(cloneObject(pcsProfile))
}

export const isDirectorsValid = (directors: OfficerProfile[]) => {
    return !isEmpty(directors) && directors.every(officerProfile => isCompanyOfficerValid(officerProfile))
}

export const isCompanyPCSListValid = (shareholders: PCSProfile[]) => {
    return isEmpty(shareholders.slice()) || shareholders.every(pcsProfile => isCompanyPCSValid(pcsProfile))
}

export const isAuthorisedSignatoriesValid = (signatories: OfficerProfile[]) => {
    return isEmpty(signatories) || signatories.every(item => isCompanyOfficerValid(item))
}

export function isFormValid(fieldsError: { errors: string[] }[]) {
    return !fieldsError || fieldsError.filter((f) => f.errors.length > 0).length === 0
}

export function validatePostalCodeOfUK(postalCode: string) {
    const UK_POSTCODE_PATTERN = /^[A-Z]{1,2}[0-9][A-Z0-9]?\s?[0-9][A-Z]{2}$/i
    return (
        postalCode &&
        UK_POSTCODE_PATTERN.test(postalCode) &&
        !/[QVX]/i.test(postalCode[0]) &&
        !/[IJZ]/i.test(postalCode[1]) &&
        (!/[A-Z]/i.test(postalCode[2]) || /[ABCDEFGHJKSTUW]/i.test(postalCode[2])) &&
        !/[CIKMOV]/i.test(postalCode[postalCode.length - 2])
    )
}

export function isDateOfCreationValid(value: string) {
    const m = moment(value, 'YYYY-MM-DD')
    const oneYearLaterFromNow = moment().add(1, 'years')

    return !value || ( m.isValid() && m.isBefore(oneYearLaterFromNow))
}

export function isDateOfBirthValid(value: string) {
    return !validateDateOfBirth(value)
}

export function validateDateOfBirth(value: string) {
    if (!value) {
        return ''
    }

    const m = moment(value, 'DD-MM-YYYY')
    if (!DATE_PATTERN.test(value) || !m.isValid() || !m.isBefore()) {
        return detailsFormTranslations().dateBirthInvalid
    }

    const age = getAge(m)
    if (age < 18 || age > 100) {
        return detailsFormTranslations().dateBirthAgeLimit
    }

    return ''
}

export const isCorporate = (props: OfficerListItemProps): props is CorpOfficerListItemProps => {
    return (props as CorpOfficerListItemProps).corporateName !== undefined
}

export const isTariffsValid = (tariffs: TariffRequestType) => {
    return tariffs && tariffs.tariffType
}