import React from 'react'
import { Card, Radio } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import styles from './styles.scss'

type Props = {
    title: React.ReactNode
    content: React.ReactNode
    isActive?: boolean
    icon?: React.ReactNode
    onClick(): void
}

export const PermissionCard: React.FC<Props> = (props) => {
    const { isActive, title, content, icon, onClick } = props

    return <Card
        className={classNames(styles.root, isActive && styles.active)}
        onClick={onClick}
    >
        <Radio checked={isActive} />
        <div className={styles.icon}>
            {icon || <LockOutlined />}
        </div>
        <div>
            <div className={styles.title}>{title}</div>
            <div>{content}</div>
        </div>
    </Card>
}