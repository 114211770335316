import { CopyOutlined } from '@ant-design/icons'
import { Collapse, message } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ApplicantInfo } from '../ApplicantInfo'
import { CompanyProfile } from '../CompanyProfile'
import { OfficerInfo } from '../OfficerInfo/OfficerInfo'
import { Stores } from '../Stores'
import { Tarriffs } from '../Tarriffs'
import { TarriffsTable } from '../TarriffsTable'
import { ApplicationDataProps } from './props'
import translations from './translations'
import styles from './ApplicationData.scss'
import { CompanyInfo } from '../CompanyInfo'
import { TerminalOrders } from '../TerminalOrders'
import { ViewModeType } from '../../types/ViewModeType'
import { isEmpty } from 'dna-common'

export const ApplicationData = observer(({ data }: ApplicationDataProps) => {
  const [viewMode, setViewMode] = useState<ViewModeType>(ViewModeType.View)

  useEffect(() => {
    if (isEmpty(data?.stores) && !data.company && !data.terminalOrders) {
      setViewMode(ViewModeType.Json)
    }
  }, [data])

  const copyApplicationData = () => {
    navigator.clipboard.writeText(JSON.stringify(data, null, 2))
    message.success(translations().copied)
  }
  return (
    <>
      {viewMode === 'view' && (
        <Collapse>
          {data?.applicantInfo && (
            <Collapse.Panel header={translations().applicantInfo} key='1'>
              <ApplicantInfo data={data.applicantInfo} />
            </Collapse.Panel>
          )}
          {data?.company && (
            <Collapse.Panel header={translations().company} key='1'>
              <CompanyInfo
                data={data.company}
                acquisitionChannel={data?.acquisitionChannel}
                deviceId={data?.deviceId}
                isFastTrack={data?.isFastTrack}
                isMsaSigned={data?.isMsaSigned}
                motoRequested={data?.motoRequested}
                needUploadKycDocuments={data?.needUploadKycDocuments}
                parentCompanies={data?.parentCompanies}
                partnerMerchantId={data?.partnerMerchantId}
                productCount={data?.productCount}
                productType={data?.productType}
              />
            </Collapse.Panel>
          )}
          {data?.companyProfile && (
            <Collapse.Panel header={translations().companyProfile} key='2'>
              <CompanyProfile data={data?.companyProfile} businessStructure={data?.businessStructure} />
            </Collapse.Panel>
          )}
          {data?.stores && (
            <Collapse.Panel header={translations().stores} key='3'>
              <Stores data={data?.stores} />
            </Collapse.Panel>
          )}
          {data?.terminalOrders && (
            <Collapse.Panel header={translations().terminalOrders} key='3'>
              <TerminalOrders data={data?.terminalOrders} />
            </Collapse.Panel>
          )}
          {data?.productTariffs && (
            <Collapse.Panel header={translations().tarriffs} key='4'>
              <Tarriffs data={data?.productTariffs} />
            </Collapse.Panel>
          )}
          {data?.tariffs && (
            <Collapse.Panel header={translations().tarriffs} key='4'>
              <TarriffsTable data={data.tariffs} />
            </Collapse.Panel>
          )}

          {data?.businessOwners?.length > 0 && (
            <Collapse.Panel header={translations().businessOwners} key='5'>
              <Collapse ghost>
                {data.businessOwners.map((item, id) => (
                  <Collapse.Panel header={`${item.firstName} ${item.middleName} ${item.surname}`} key={id}>
                    <OfficerInfo data={item} />
                  </Collapse.Panel>
                ))}
              </Collapse>
            </Collapse.Panel>
          )}

          {data?.businessOwner && (!data.businessOwners || data?.businessOwners?.length === 0) && (
            <Collapse.Panel header={translations().businessOwners} key='5'>
              <Collapse ghost>
                <Collapse.Panel
                  header={`${data.businessOwner?.firstName} ${data.businessOwner?.middleName} ${data.businessOwner?.surname}`}
                  key={1}
                >
                  <OfficerInfo data={data.businessOwner} />
                </Collapse.Panel>
              </Collapse>
            </Collapse.Panel>
          )}

          {data?.companyOwners?.length > 0 && (
            <Collapse.Panel header={translations().companyOwners} key='6'>
              <Collapse ghost>
                {data.companyOwners.map((item, id) => (
                  <Collapse.Panel header={`${item.firstName} ${item.middleName} ${item.surname}`} key={id}>
                    <OfficerInfo data={item} />
                  </Collapse.Panel>
                ))}
              </Collapse>
            </Collapse.Panel>
          )}
          {data?.companyDirectors?.length > 0 && (
            <Collapse.Panel header={translations().companyDirectors} key='7'>
              <Collapse ghost>
                {data.companyDirectors.map((item, id) => (
                  <Collapse.Panel header={`${item.firstName} ${item.middleName} ${item.surname}`} key={id}>
                    <OfficerInfo data={item} />
                  </Collapse.Panel>
                ))}
              </Collapse>
            </Collapse.Panel>
          )}
          {data?.individualShareholders?.length > 0 && (
            <Collapse.Panel header={translations().individualShareholders} key='8'>
              <Collapse ghost>
                {data.individualShareholders.map((item, id) => (
                  <Collapse.Panel header={`${item.firstName} ${item.middleName} ${item.surname}`} key={id}>
                    <OfficerInfo data={item} />
                  </Collapse.Panel>
                ))}
              </Collapse>
            </Collapse.Panel>
          )}

          {data?.companyAuthorisedSignatories?.length > 0 && (
            <Collapse.Panel header={translations().companyAuthorisedSignatories} key='9'>
              <Collapse ghost>
                {data.companyAuthorisedSignatories.map((item, id) => (
                  <Collapse.Panel header={`${item.firstName} ${item.middleName} ${item.surname}`} key={id}>
                    <OfficerInfo data={item} />
                  </Collapse.Panel>
                ))}
              </Collapse>
            </Collapse.Panel>
          )}
        </Collapse>
      )}

      {viewMode === ViewModeType.Json && (
        <div>
          <div className={styles.copyIconContainer}>
            <CopyOutlined onClick={copyApplicationData} style={{ fontSize: 20 }} />
          </div>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}
    </>
  )
})
