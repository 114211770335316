import { getWithAuth } from '~/code/services'
import { IcTariffType } from '../models/IcTariffType'
import { TariffSettingsResponseType } from '../models'

export const fetchTariffs = () => getWithAuth<IcTariffType[]>('/api/processes-api/v1/dictionaries/ic-tariffs')

export const fetchProductTariffs = () => getWithAuth<IcTariffType[]>('/bpm/product-tariffer/tariff-settings')

export const fetchTariffSettings = () =>
  getWithAuth<TariffSettingsResponseType[]>('/bpm/product-tariffer/pricing-settings?key=Onboarding')
