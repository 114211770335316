import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    copied: string
    applicationData: string
    attachedDocuments: string
    checkingResults: string
    notes: string
    complianceTeam: string
    riskTeam: string
    salesTeam: string
    noComments: string
    backToProcesses: string
    lexisNexis: string
    creditSafe: string
    complianceAssist: string
    companiesHouse: string
    errLoadingProcessInfo: string
    errLoadingApplicationData: string
    errLoadingNotes: string
    errLoadingCheckingResults: string
    ecospend: string
    jumio: string
    errorLoadingDocs: string
}>()

