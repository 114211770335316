import React, { useState } from 'react'
import { Card, Radio, Table, Tabs, Grid } from 'antd'
import { observer } from 'mobx-react'

import { TabType } from '~/code/models'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'
import translations from './translations'
import { AnalyticsDetailedViewProps } from './props'
import { PaymentStatus } from './models/PaymentStatus'
import styles from './AnalyticsDetailedView.scss'

export const AnalyticsDetailedView = observer(({ store, statuses }: AnalyticsDetailedViewProps) => {
    const screens = Grid.useBreakpoint()
    const isMobile = !screens.md

    const {
        amountTableColumns, countTableColumns, tabsData
    } = store

    const onTabChange = (tabKey) => {
        store.onTabChange(tabKey)
    }

    const onCountAmountTabChange = (tabKey: 'amount' | 'count') => {
        store.onCountAmountTabChange(tabKey)
    }

    const getTabs = (): TabType[] => {
        return tabsData.map(tabData => {
            const [status, setStatus] = useState<PaymentStatus>('all')

            const handleStatusChange = (e) => {
                setStatus(e.target.value)
                store.onStatusTabChange && store.onStatusTabChange(e.target.value)
            }

            return {
                key: tabData.key,
                label: translations()[tabData.key],
                children: <>
                    <div className={styles.statusWrapper}>
                        <Radio.Group value={status} onChange={handleStatusChange}>
                            {statuses.map(s => (
                                <Radio.Button value={s.value} key={s.value}>
                                    {s.label}
                                </Radio.Button>
                            ))}
                        </Radio.Group>
                    </div>
                    <Tabs
                        className={styles.tabs}
                        tabPosition={isMobile ? 'top' : 'right'}
                        defaultActiveKey='amount'
                        onChange={onCountAmountTabChange}
                        items={[
                            { key: 'amount', label: translations().amount, children: renderTabContent(amountTableColumns, tabData.amountTableData, store.onPageSizeChange, status) },
                            { key: 'count', label: translations().count, children: renderTabContent(countTableColumns, tabData.countTableData, store.onPageSizeChange, status) }
                        ]}
                    />
                </>
            }
        })
    }
    
    return <Card>
        <Tabs
            defaultActiveKey={store.currentTab}
            onChange={onTabChange}
            items={getTabs()}
        />
    </Card>
})

const renderTabContent = (columns, tableData, onPageSizeChange, status: PaymentStatus) => {

    const handlePageSizeChange = (page, size) => {
        onPageSizeChange && onPageSizeChange(page, size)
    }

    return <Table size='small' columns={columns} dataSource={tableData[status]?.data || []} loading={tableData[status]?.loading || false}
        pagination={
            generateTablePaginationProps(
                tableData[status]?.currentPage,
                tableData[status]?.pageSize,
                tableData[status]?.total,
                handlePageSizeChange
            )
        }
    />
}
