import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  formFields: {
    partnerName: string
    parentPartner: string
    financialInstitution: string
    grantedPermissions: string
  }
  formErrors: {
    partnerNameRequired: string
    financialInstitutionRequired: string
  }
}>()
