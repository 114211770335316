import translations from '../translations'

export function getFullData(data) {
    return [
        {
            value: null,
            label: translations().all
        },
        ...data
    ]
}
