import React from 'react'
import { Card } from 'antd'
import { RiskLimitsModel } from '~/code/pages/BPMProcesses/Requests/models'
import translations from './translations'
import styles from './RiskLimits.scss'
import { isNumber } from './services'

interface RiskLimitsProps {
    riskLimits: RiskLimitsModel
    title: string
    insideDrawer?: boolean
}

export const RiskLimits = (props: RiskLimitsProps) => {
    const { riskLimits, title, insideDrawer } = props

    const renderAmountValue = (val) => isNumber(val) && val.toLocaleString('en-US', { style: 'currency', currency: 'GBP' })

    const {
        dailyAmount,
        dailyCount,
        dailyMaxRefundAmount,
        monthlyAmount,
        monthlyCount,
        perRefund,
        perTransaction
    } = riskLimits

    return <div className={insideDrawer ? styles.insideDrawer : styles.container}>
        <Card title={title} bordered={ false }>
            <div className={styles.propContainer}>
                <div className={styles.propLabel}>{ translations().dailyAmount }</div>
                <div className={styles.propValue}>{ renderAmountValue(dailyAmount) }</div>
            </div>
            <div className={styles.propContainer}>
                <div className={styles.propLabel}>{translations().dailyCount}</div>
                <div className={styles.propValue}>{ dailyCount }</div>
            </div>
            <div className={styles.propContainer}>
                <div className={styles.propLabel}>{translations().dailyMaxRefundAmount}</div>
                <div className={styles.propValue}>{ renderAmountValue(dailyMaxRefundAmount) }</div>
            </div>
            <div className={styles.propContainer}>
                <div className={styles.propLabel}>{ translations().monthlyAmount }</div>
                <div className={styles.propValue}>{ renderAmountValue(monthlyAmount) } </div>
            </div>
            <div className={styles.propContainer}>
                <div className={styles.propLabel}>{ translations().monthlyCount }</div>
                <div className={styles.propValue}>{ monthlyCount }</div>
            </div>
            <div className={styles.propContainer}>
                <div className={styles.propLabel}>{ translations().perRefund }</div>
                <div className={styles.propValue}>{ renderAmountValue(perRefund) }</div>
            </div>
            <div className={styles.propContainer}>
                <div className={styles.propLabel}>{ translations().perTransaction }</div>
                <div className={styles.propValue}>{ renderAmountValue(perTransaction) }</div>
            </div>
        </Card>
    </div>
}
