import { inject, injectable } from 'inversify'

import { SETTLEMENT_STATEMENTS_STORE_TYPES } from '~/code/pages/settlements/SettlementsStatement/symbols'
import { columns } from '~/code/pages/settlements/SettlementsStatement/components/SettlementsTable/columns'
import { SettlementParams } from '~/code/pages/settlements/SettlementsStatement/components/SettlementsTable/models'
import { fetchSettlement } from '~/code/pages/settlements/SettlementsStatement/services'
import { BaseSettlementsFiltersStore } from '~/code/stores/SettlementsStatementStore/BaseSettlementsFiltersStore'

import { BaseSettlementsTableStore } from './BaseSettlementsTableStore'

// @ts-ignore
@injectable()
export class SettlementsTableStore extends BaseSettlementsTableStore {
  columns = columns

  constructor(@inject(SETTLEMENT_STATEMENTS_STORE_TYPES.filter) settlementsFilterStore: BaseSettlementsFiltersStore) {
    super(settlementsFilterStore as BaseSettlementsFiltersStore)
  }

  protected async fetchSettlement(data: SettlementParams) {
    return await fetchSettlement(data)
  }
}
