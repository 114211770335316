import React from 'react'
import translations from './translations'

translations.add('en', {
    title: <>The recovery code is generated for your account. <br /> This 12 character code can be used one time to recover your account if you are unable to sign in with two-factor authentication (2FA).</>,
    copy: 'Copy',
    copyText: 'Copy the code to proceed.',
    copied: 'Copied',
    warning: {
        line1: 'The code can be used only once',
        line2: 'For security reasons this code will not be displayed again',
        line3: 'Do not share this code with anyone'
    }
})
