import { Form, Select } from 'antd'
import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { FilterSelectProps } from './props'
import styles from './FilterSelect.scss'

export const FilterSelect: React.FC<PropsWithChildren<FilterSelectProps>> = props => {
  const { formItemProps, selectProps, children } = props
  return (
    <Form.Item {...formItemProps} className={classNames(styles.formItem, formItemProps.className)}>
      <Select {...selectProps} className={classNames(styles.select, selectProps.className)} />
    </Form.Item>
  )
}
