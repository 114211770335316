import React, { useCallback } from 'react'
import { Card, Form, Row, Col, Select, Grid } from 'antd'
import classNames from 'classnames'
import debounce from 'lodash.debounce'
import { Input } from '~/code/components'
import { TeammateFilterType } from '../../models'
import { TeammatesFilterProps } from './props'

import translations from '../../translations'
import styles from './styles.scss'

const { useBreakpoint } = Grid

export const TeammatesFilter: React.FC<TeammatesFilterProps> = props => {
  const [form] = Form.useForm()
  const { roles, values, statuses, authMethods, onChange } = props
  const screens = useBreakpoint()
  const delayedOnChange = useCallback<(values: Partial<TeammateFilterType>) => void>(debounce(onChange, 500), [])

  return (
    <Card className={classNames(styles.root)}>
      <Form
        form={form}
        colon={false}
        validateTrigger={'onBlur'}
        layout={screens.xl ? 'horizontal' : 'vertical'}
        labelCol={{ xs: 24, md: 8, lg: 24, xl: 8 }}
        wrapperCol={{ xs: 24, md: 17, lg: 24, xl: 17 }}
        labelAlign={'left'}
        initialValues={values}
        onValuesChange={(changedValues, newValues) => {
          if (Object.keys(changedValues).includes('role')) {
            onChange(newValues)
          } else {
            delayedOnChange(newValues)
          }
        }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={12} lg={6}>
            <Form.Item name='firstName' label={translations().labels.firstName}>
              <Input onChange={e => form.setFieldsValue({ firstName: e.target.value.trim() })} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} lg={7}>
            <Form.Item name='lastName' label={translations().labels.lastName}>
              <Input onChange={e => form.setFieldsValue({ lastName: e.target.value.trim() })} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <Form.Item name='email' label={translations().labels.email}>
              <Input onChange={e => form.setFieldsValue({ email: e.target.value.trim() })} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} lg={5}>
            <Form.Item name='role' label={translations().labels.role}>
              <Select options={[{ value: null, label: translations().all }, ...roles]} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24} sm={12} lg={6}>
            <Form.Item name='status' label={translations().labels.status}>
              <Select options={statuses} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} lg={7}>
            <Form.Item name='authMethod' label={translations().labels.authMethod}>
              <Select options={authMethods} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}