import { object, string } from 'yup'
import { isCountryUK, validatePostalCodeOfUK } from 'startapp/services'
import { CITY_TYPING_PATTERN } from '~/code/constants/Patterns'

export const AddressScheme = object({
    country: string().required(),
    postalCode: string()
        .required()
        .test('postal-code', 'invalid ${path}', function (value) {
            const isUK = isCountryUK(this.parent.country)
            return !isUK || validatePostalCodeOfUK(value)
        }),
    addressLine1: string().required(),
    addressLine2: string().nullable(),
    locality: string().required().matches(CITY_TYPING_PATTERN),
    region: string().nullable()
}).nullable().default(null)
