import translations from './translations'

translations.add('en', {
  store: 'Store',
  storeName: 'Store Name',
  systemDescriptor: 'System Descriptor',
  storeAddress: 'Store Address',
  country: 'Country',
  postalCode: 'Postal Code',
  addressLine1: 'Address Line 1',
  townOrCity: 'Town Or City',
  systemStoreAddress: 'System Store Address',
  mcc: 'MCC',
  mccDescription: 'MCC Description',
  industry: 'Industry',
  natureOfBusiness: 'Nature Of Business',
  deliveryAddress: 'Delivery Address',
  deliveryInstructions: 'Delivery Instructions',
  deliveryPhoneNumber: 'Delivery PhoneNumber',
  delivery: 'Delivery',
  products: 'Products',
  bankInfo: 'Bank Info',
  bankAccountNumber: 'Bank Account Number',
  sortCode: 'Sort Code',
  accountName: 'Account Name',
  bankName: 'Bank Name',
  bankAccountType: 'Bank Account Type',
  financialInfo: 'Financial Info',
  expectedAnnualTurnover: 'Expected AnnualTurnover',
  expectedAnnualTurnoverCustomValue: 'Expected Annual Turnover CustomValue',
  expectedMonthlyTransactionValues: 'Expected Monthly Transaction Values',
  averageTransactionValue: 'Average Transaction Value',
  tradeOutsideEea: 'Trade Outside Eea',
  tradingCountriesOutsideEea: 'Trading Countries Outside Eea',
  businessModelQuestions: 'Business Model Questions',
  expectedAnnualCardTurnover: 'Expected Annual Card Turnover',
  highestTransactionValue: 'Highest Transaction Value',
  yes: 'Yes',
  no: 'No',
  processingMethods: 'Processing Methods',
  advancedPayments: 'Advanced Payments'
})
