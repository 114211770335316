export const SENT_STATUS = 'SENT'
export const SUCCESS_STATUS = 'success'
export enum PRODUCT_VERSIONS {
  REDESIGNED = '2'
}
export enum PROCESS_VERSIONS {
  ONBOARDING = '2.1',
  ADDITIONAL_STORE_ISSUE = '2.2'
}

export const ECOSPEND_STATUSES = {
  SENT: 'SENT',
  VERIFIED: 'VERIFIED',
  MATCH: 'MATCH',
  NOMATCH: 'NOMATCH',
  CANCELED: 'CANCELEDBYLINK'
}

export const DNA_DOMAINS = ['@dnapayments.com', '@dnapaymentsgroup.com', '@optomany.com', '@123send.com']
