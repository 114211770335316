import { Checkbox, Form } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import {
  BelowActionsWithSave,
  CompanyNameForm,
  FormSubgroupTitle,
  NextButton,
  SelectPaymentSolutionForm,
  StartAppContainer
} from '..'
import { GeneralInformationProps } from './props'
import { isCountryUK, validatePostalCodeOfUK } from 'startapp/services'
import { BackButton, Input, PostalCode } from '~/code/components'
import translations from './translations'
import styles from './GeneralInformation.scss'
import { PaymentSolutionView } from 'startapp/models'

export const GeneralInformation = observer((props: GeneralInformationProps) => {
  const { form, store } = props
  const { validateFields, getFieldValue, setFieldsValue, setFields } = form

  useEffect(() => {
    form.resetFields()

    // When moving between different incomplete applications -> reset company name form
    if (store.dataStore.generalInformationData?.companyNumber !== store.companyNameFormStore.company?.companyNumber) {
      store.companyNameFormStore.reset()
    }
  }, [])

  const SubmitButton = observer(() => {
    return <NextButton loading={store.dataStore.isNextLoading} />
  })

  const setError = (error: string) => {
    setFields([
      {
        name: ['locationAddress', 'addressLine1'],
        errors: [error],
        validating: false,
        touched: true,
        value: form.getFieldValue(['locationAddress', 'addressLine1'])
      },
      {
        name: ['locationAddress', 'townOrCity'],
        errors: [error],
        validating: false,
        touched: true,
        value: form.getFieldValue(['locationAddress', 'townOrCity'])
      }
    ])
  }

  const resetError = () => {
    setFields([
      {
        name: ['locationAddress', 'addressLine1'],
        errors: [],
        validating: false,
        touched: true,
        value: form.getFieldValue(['locationAddress', 'addressLine1'])
      },
      {
        name: ['locationAddress', 'townOrCity'],
        errors: [],
        validating: false,
        touched: true,
        value: form.getFieldValue(['locationAddress', 'townOrCity'])
      }
    ])
  }

  return (
    <>
      <BackButton onClick={store.reset}>{translations().newApplication}</BackButton>
      <StartAppContainer>
        <Form
          form={form}
          hideRequiredMark={true}
          layout='vertical'
          onFinish={values => store.onFinishGeneralInformation(values, 'isNextLoading')}
          className={styles.generalInformationForm}
          initialValues={store.dataStore.generalInformationViewData}
        >
          <SelectPaymentSolutionForm store={store} />

          <Form.Item shouldUpdate noStyle>
            {() => {
              const paymentSolution = getFieldValue('paymentSolutionView')
              return paymentSolution && paymentSolution.length ? (
                <>
                  <FormSubgroupTitle>{translations().locationAddress}</FormSubgroupTitle>

                  <Form.Item
                    name={['locationAddress', 'country']}
                    label={'Country'}
                    tooltip={'Country'}
                    rules={[{ required: true, message: 'Required' }]}
                    validateTrigger={['onChange', 'onBlur']}
                  >
                    <Input disabled />
                  </Form.Item>

                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => {
                      return prevValues.locationAddress?.country !== curValues.locationAddress?.country
                    }}
                  >
                    {() => (
                      <Form.Item
                        name={['locationAddress', 'postalCode']}
                        label={translations().postalCode}
                        getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                          return e.currentTarget.value?.toLocaleUpperCase()
                        }}
                        rules={[
                          { required: true, message: 'required' },
                          {
                            validator: async (rule, value) => {
                              const isUK = isCountryUK(getFieldValue(['locationAddress', 'country']))
                              if (value && isUK && !validatePostalCodeOfUK(value)) {
                                throw new Error('error')
                              }
                            }
                          }
                        ]}
                        validateTrigger='onBlur'
                      >
                        <PostalCode
                          isUK={isCountryUK(getFieldValue(['locationAddress', 'country']))}
                          onAddressSelect={a => {
                            if (a.building_number.length && a.thoroughfare.length) {
                              setFieldsValue({
                                locationAddress: {
                                  addressLine1: a.building_number + ' ' + a.thoroughfare,
                                  townOrCity: a.town_or_city
                                }
                              })
                              validateFields([
                                ['locationAddress', 'townOrCity'],
                                ['locationAddress', 'addressLine1']
                              ])
                            } else {
                              setFieldsValue({
                                locationAddress: {
                                  addressLine1: '',
                                  townOrCity: ''
                                }
                              })
                              setError(translations().incompleteData)
                            }
                          }}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item
                    name={['locationAddress', 'addressLine1']}
                    label={translations().address}
                    validateTrigger={'onChange'}
                    rules={[
                      { required: true, message: 'required' },
                      {
                        validator: async (rule, value) => {
                          const { addressLine1, townOrCity } = getFieldValue('locationAddress')
                          if (addressLine1?.concat(' ', townOrCity).length > 40) {
                            setError(translations().addressError)
                          } else if (addressLine1 && townOrCity) {
                            resetError()
                            return Promise.resolve()
                          }
                        }
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['locationAddress', 'townOrCity']}
                    label={translations().townOrCity}
                    validateTrigger={'onChange'}
                    rules={[
                      { required: true, message: 'required' },
                      {
                        validator: async (rule, value) => {
                          const { addressLine1, townOrCity } = getFieldValue('locationAddress')
                          if (addressLine1?.concat(' ', townOrCity).length > 40) {
                            setError(translations().addressError)
                          } else if (addressLine1 && townOrCity) {
                            resetError()
                            return Promise.resolve()
                          }
                        }
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </>
              ) : null
            }}
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {() => {
              if (
                (getFieldValue('paymentSolutionView') === PaymentSolutionView.POS ||
                  getFieldValue('paymentSolutionView') === PaymentSolutionView.POS_MOTO) &&
                store.isFastTrackAvailable
              ) {
                return (
                  <Form.Item name={'isFastTrack'} valuePropName={'checked'}>
                    <Checkbox>{translations().fastTrack}</Checkbox>
                  </Form.Item>
                )
              } else return <></>
            }}
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {() => {
              if (
                (getFieldValue('paymentSolutionView') === PaymentSolutionView.POS ||
                  getFieldValue('paymentSolutionView') === PaymentSolutionView.POS_MOTO) &&
                getFieldValue('isFastTrack')
              ) {
                return (
                  <Form.Item name={'isMsaSigned'} valuePropName={'checked'}>
                    <Checkbox>{translations().msaSigned}</Checkbox>
                  </Form.Item>
                )
              } else return <></>
            }}
          </Form.Item>

          <CompanyNameForm store={store.companyNameFormStore} form={form} />

          <BelowActionsWithSave
            large
            isLoading={store.dataStore.isSaveLoading}
            onSave={() => store.onFinishGeneralInformation(form.getFieldsValue(), 'isSaveLoading')}
          >
            <SubmitButton />
          </BelowActionsWithSave>
        </Form>
      </StartAppContainer>
    </>
  )
})
