import React from 'react'
import translations from './translations'

translations.add('en', {
    id: 'ID',
    createdDate: 'Date Requested',
    lastUpdateDate: 'Last Updated',
    fileName: 'File Name',
    jobName: 'Document Type',
    status: 'Status',
    jobNameTypes: {
        'all': 'All',
        'merchant-statement-excel': 'Merchant Statement (Excel)',
        'merchant-statement-csv': 'Merchant Statement (CSV)',
        'merchant-statement-excel-test': 'Merchant Statement (Excel) Test',
        'merchant-statement-csv-test': 'Merchant Statement (CSV) Test',
        'pos-transactions-csv': 'POS Transactions (CSV)',
        'pos-transactions-csv-test': 'POS Transactions (CSV) Test',
        'pos-transactionsv2-csv': 'POS Transactions (CSV)',
        'ecom-transactions-csv': 'ECOM Transactions (CSV)',
        'pos-amex-transactions-csv': 'POS Amex Transactions (CSV)'
    },
    jobNameLabel: 'Document Type',
    download: 'Download',
    tableTitle: <>Your download requests for the <b>last 24 hours</b></>,
    errorDownloadingFile: 'Error downloading file'
})
