import { CloseCircleOutlined, IdcardTwoTone } from '@ant-design/icons'
import { Button, Result, Typography } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import { CompleteApplicationStore } from 'startapp/stores/CompleteApplicationStore'
import styles from './StartAppResult.scss'
import translations from './translations'

const { Paragraph, Text } = Typography

type Props = { store: CompleteApplicationStore, onBack(): void, onBackToStart(): void }

export const StartAppResult = observer((props: Props) => {
    const { store } = props
    return <div>
        {store.resultPage === 'check' && <CheckData {...props} />}
        {store.resultPage === 'success' && <Success {...props} />}
        {store.resultPage === 'error' && <Failure {...props} />}
        {store.resultPage === 'timeout' && <Timeout {...props} />}
    </div>
})

const CheckData = observer(({ store, onBack }: Props) => {
    const {
        isSubmitting,
        submit
    } = store

    const {
        companyType,
        isApplicationValid, 
        isCompanyProfileValid, 
        isShareholdersValid, 
        isDirectorsValid,
        isAuthorisedSignatoriesValid,
        isBusinessOwnerValid
    } = store.dataStore
    const { isSupportingDocumentsValid } = store.supportingDocumentsStore

    return <Result
        icon={<IdcardTwoTone className={styles.resultIcon} twoToneColor={'#40a9ff'} />}
        title={translations().checkTheData}
        subTitle={translations().pleaseCheckBeforeSubmit}
        extra={[
            <Button
                key='back'
                onClick={onBack}
                className={styles.backButton}
            >{translations().backToForm}</Button>,
            <Button
                key='submit'
                type='primary'
                disabled={!isApplicationValid}
                onClick={submit}
                loading={isSubmitting}
            >{translations().submit}</Button>
        ]}
    >
        {
            !isApplicationValid && <div className='desc'>
                <Paragraph>
                    <Text
                        strong
                        style={{
                            fontSize: 16
                        }}
                    >
                        {translations().applicationHasFollowingErrors}:
                    </Text>
                </Paragraph>

                {!isCompanyProfileValid && <Paragraph>
                    <ErrorIcon /> {translations().generalInformationNotCompleted}
                </Paragraph>}
                
                {companyType === 'company' ? <>
                    {!isShareholdersValid && <Paragraph>
                        <ErrorIcon /> {translations().shareholdersNotCompleted}
                    </Paragraph>}
                    
                    {!isDirectorsValid && <Paragraph>
                        <ErrorIcon /> {translations().directorsNotCompleted}
                    </Paragraph>}
                    
                    {!isAuthorisedSignatoriesValid && <Paragraph>
                        <ErrorIcon /> {translations().authorizedSignatoriesNotCompleted}
                    </Paragraph>}
                </> : <>
                    {!isBusinessOwnerValid && <Paragraph>
                        <ErrorIcon /> {translations().businessOwnerNotCompleted}
                    </Paragraph>}
                </>}    

                {!isSupportingDocumentsValid && <Paragraph>
                    <ErrorIcon /> {translations().supportingDocumentsNotCompleted}
                </Paragraph>}            
            </div>
        }
    </Result>
})

const ErrorIcon = () => <CloseCircleOutlined className={styles.errorIcon} />

const Failure: React.FC<Props> = ({ onBack, store }) => (
    <Result
        status='error'
        title={translations().submissionFailed}
        subTitle={store.errorSubmitApplication.message ? store.errorSubmitApplication.message : translations().pleaseCheckBeforeSubmit}
        extra={[
            <Button key='back' onClick={onBack}>{translations().backToForm}</Button>
        ]}
    ></Result>
)

const Success: React.FC<Props> = ({ onBackToStart, store }) => (
    <Result
        status='success'
        title={translations().applicationSubmittedSuccessfully}
        subTitle={<>
            {translations().applicationID}: <strong>{store.resultApplicationId}</strong><br />
            {translations().inOrderToTrackApplication}
        </>}
        extra={[
            <Button type='primary' key='back' onClick={onBackToStart}>{translations().newApplication}</Button>
        ]}
    ></Result>
)

const Timeout: React.FC<Props> = ({ onBackToStart }) => (
    <Result
        status='warning'
        title='Warning'
        subTitle={<>
            {translations().onceYouClickSubmit} <br />
            {translations().pleaseWait2Mins}
        </>}
        extra={[
            <Button type='primary' key='back' onClick={onBackToStart}>{translations().newApplication}</Button>
        ]}
    ></Result>
)