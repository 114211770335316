import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    processStatus: string
    dailyAmount: string
    dailyCount: string
    monthlyAmount: string
    monthlyCount: string
    perRefund: string
    perTransaction: string
    currency: string
    currentLimits: string
    requestedLimits: string
    approvedLimits: string
    comment: string
}>()

