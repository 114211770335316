import translations from './translations'

translations.add('en', {
    all: 'All',
    type: 'Type',
    maintenanceIncident: 'Maintenance/Incident',
    push: 'Push',
    marketing: 'Marketing',
    description: 'Description',
    merchantPortal: 'Merchant Portal',
    dashboard: 'Dashboard',
    bpm: 'BPM',
    system: 'System',
    title: 'Title',
    status: 'Status',
    active: 'Active',
    inactive: 'Inactive',
    scheduled: 'Scheduled',
    banner: 'Banner',
    popup: 'Popup',
    block: 'Full block',
    displayType: 'Display Type'
})
