import translations from './translations'

translations.add('en', {
    message: {
        updateSuccess: 'Successfully updated Two-Factor Authentication settings',
        disableSuccess: 'Successfully disabled Two-Factor Authentication',
        enableSuccess: 'Successfully enabled Two-Factor Authentication',
        alreadyEnabled: 'Two-Factor Authentication is already enabled'
    }
})
