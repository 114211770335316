import { Modal, Table, Button, Form, Select } from 'antd'
import React, { useEffect } from 'react'
import { useInjection } from 'dna-react-ioc'
import { observer } from 'mobx-react'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { IRoutingDataStore } from './IRoutingDataStore'
import { columns, paymentMethods } from './constants'
import translations from './translations'
import styles from './RoutingTable.scss'

export const RoutingDataStoreSymbol = Symbol('RoutingDataStore')
export const RoutingTable = observer(() => {
  const store = useInjection<IRoutingDataStore>(RoutingDataStoreSymbol)
  const [form] = Form.useForm()
  useEffect(() => {
    store.reset()
    store.loadRoutingData()
  }, [store.companyFullDossierV2])

  const onFinish = async (values: { transactionChannel: string }) => {
    await store.updateRoutingData(values.transactionChannel)
    form.resetFields()
  }

  const renderChangeRoutingButton = () => {
    if (!hasPermissions([PermissionMap.merchants.processes.tariff.read])) {
      return null
    }
    return (
      <div className={styles.changeButtonContainer}>
        <Button type='primary' disabled={store.selectedTIDs.length === 0} onClick={store.openModal}>
          {translations().change}
        </Button>
      </div>
    )
  }

  return (
    <>
      <Table
        scroll={{ x: 200 }}
        rowSelection={{
          type: 'checkbox',
          onSelect: store.setSelectedRows,
          onSelectAll: store.setSelectAllRows,
          getCheckboxProps: record => ({
            disabled: !record.isPosProduct || !hasPermissions([PermissionMap.merchants.processes.tariff.read])
          })
        }}
        loading={store.isRoutingDataLoading}
        pagination={false}
        dataSource={store.routingData}
        columns={columns}
      />
      <Modal
        open={store.isModalOpen}
        okButtonProps={{
          loading: store.isSubmitButtonLoading
        }}
        onCancel={() => {
          form.resetFields()
          store.closeModal()
        }}
        onOk={() => form.submit()}
      >
        <div>{store.selectedTIDs.join(', ')}</div>
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            label={translations().selectTransactionChannel}
            name='transactionChannel'
            rules={[{ required: true, message: translations().required }]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <Select options={paymentMethods} />
          </Form.Item>
        </Form>
      </Modal>
      {store.routingData.length > 0 ? renderChangeRoutingButton() : null}
    </>
  )
})
