import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  companyName: string
  storeName: string
  address: string
  changeBankDetails: string
  changeSortCodeAccountNumber: string
  grossSettlementChange: string
  changeGrossSettlementConfirmation: string
  changeSettlement: string
  dailyNet: string
  monthlyNet: string
  grossDD: string
  dailyGross: string
  errorDefiningSettlementType: string
  settlementType: string
  changeDirectDebitConfirmation: string
  chargeMerchant: string
  mandate: string
  settlementsStatus: string
  tipJar: string
  directDebitAlias: string
  settlementAlias: string
  edit: string
  viewAttributes: string
  viewAttributesBtnLabel: string
  closeStore: string
  accountName: string
  sortCode: string
  storeStatus: string
  closedStore: string
}>()
