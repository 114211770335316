import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    title: string
    system: string
    type: string
    actions: string
    companyName: string
    descriptor: string
    publish: string
    email: string
    firstName: string
    lastName: string
    view: string
    select: string
    selectTemplate: string
    selectRecipient: string
    preview: string
    description: string
    displayType: string
}>()

