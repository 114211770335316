import React, { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  baseLabel: string
  percentLabel: string

  settlementFee: string
  fasterFundingFee: string

  authorisationFee: string
  gatewayFee: string
  refundFee: string
  chargebackFee: string

  dinersCardsFee: string
  unionPayCardsFee: string
  amexCardsFee: string

  openBankingFee: string
  payByBankFee: string
  klarnaFee: string

  settlementPeriod: string

  monthlyRental: string

  definitions: React.ReactNode
  zashApp: string
  airtimeService: string
  gatewaySetUpFee: string
  minMonthlyServiceCharge: string
  nonTransactionFees: string
  pciFee: string
  settlementFeeDescription: string
  refundFeeDescription: string
  chargeBackFeeDescription: string
  pciFeeDescription: string
  fasterFundingFeeDescription: string
  zashAppFeeDescription: string

  paymentFacilitatingServices: string
  setUpConfigurationDelivery: string
  engineerInstalationFee: string

  regularCharges: string
  chargedAtOnce: string
  paymentTerminalSupportAndTransaction: string
  monthlyFeeChargedTerminal: string
  monthlyFeeChargedAirtime: string
  gatewayServices: string
  gatewaySetUpFeePerTransaction: string
  feeForEachTransaction: string
  other: string
  managementFee: string
  managementFeeDescription: string
  minMonthlyServiceChargeDescription: string
  discoverGlobalNetwork: string
  discoverGlobalDescription: string
  openBankingDescription: string
  payByBankDescription: string
  authorizationFeeDescription: string
  disableFee: (disabled: boolean) => string
  amexDescription: string
  unionPayDescription: string
  transactionFees: string
  settlementPeriodDescription: string
  fasterFundingMonthly: string
  fasterFundingMonthlyDescription: string
  zashSolution: string
  zAndPayByBankApp: string
  payByTek: ReactNode
  monthlyGatewayFee: string
  monthlyGatewayFeeDescription: string
  terminalPurchase: string
  terminalCost: string
  authFee: string
  settlements: string
  settlementNote: string
  settlementFF: string
  settlementFFNote: string
  zashTitle: string
  ffRequested: string
  required: string
  percentPlaceholder: string
  zeroPlaceholder: string
  terminalPurchaseNote: string
  terminalCostDescription: string
  cardNotPresentFee: string

  aliPayFee: string
  weChatPayFee: string
  aliPayDescription: string
  motoDescription: string
}>()
