import React, { useEffect, useState } from 'react'
import numeral from 'numeral'
import { PieChart, SumCountCard } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/chart-related'
import translations from './translations'
import { PieChartBreakdownProps } from './props'

export const PieChartBreakdown = ({loadingSum, loadingCount, data, title, currencySymbol = '£', statusTabs, renderSumTitle, renderCountTitle}: PieChartBreakdownProps) => {
    const [isLoadingSum, setLoadingSum] = useState(loadingSum)
    const [isLoadingCount, setLoadingCount] = useState(loadingCount)

    useEffect(() => {
        setLoadingSum(loadingSum)
    }, [data, loadingSum])

    useEffect(() => {
        setLoadingCount(loadingCount)
    }, [data, loadingCount])

    const renderContent = (dataType, status, _isLoading) => {
        if (_isLoading || !data) {
            return false
        }

        const getData = () => {
            if (!statusTabs) return data?.[dataType]

            return data?.[dataType]?.[status]
        }

        return (
            <div>
                <PieChart
                    data={ getData() }
                    currencySymbol={dataType === 'count' ? '' : currencySymbol}
                    valueNumberFormat={dataType === 'count' ? '0,00' : '0.00a'}
                    valueTitle={dataType === 'count' ? translations().count : translations().volume}
                    renderTooltipLabel={(label, value) => {
                        const currency = dataType === 'count' ? '' : (currencySymbol || '£')
                        const format = dataType === 'count' ? '0,00' : '0,0.00'
                        return  label + ': ' + currency + numeral(value).format(format)
                    }}
                />
            </div>
        )
    }

    const renderSumContent = (status) => {
        return renderContent('amount', status, isLoadingSum)
    }

    const renderCountContent = (status) => {
        return renderContent('count', status, isLoadingCount)
    }

    return (
        <SumCountCard
            loadingSum={loadingSum}
            loadingCount={loadingCount}
            title={title}
            renderSumContent={renderSumContent}
            renderCountContent={renderCountContent}
            statusTabs={statusTabs}
            renderSumTitle={renderSumTitle}
            renderCountTitle={renderCountTitle}
            currencySymbol={currencySymbol} 
        />
    )
}
