import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    message: {
        updateSuccess: string
        disableSuccess: string
        enableSuccess: string
        alreadyEnabled: string
    }
}>()
