import React from 'react'
import { Col, Form, Row } from 'antd'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { isEmpty } from 'dna-common'
import { CustomButton, CustomModal } from '~/code/components'
import { FormSubgroupTitle } from '../shared/FormSubgroupTitle'
import { PosFieldsForm } from './components/PosFieldsForm'
import { EcomFieldsForm } from './components'
import { SelectPaymentSolutionFormProps } from './props'
import translations from './translations'
import styles from './ProductSelectionForm.scss'

export const ProductSelectionForm = observer(
  ({ store, form, storeForm, isBundlesLoading }: SelectPaymentSolutionFormProps) => {
    return (
      <div className={styles.container}>
        <FormSubgroupTitle>{translations().addNewProduct}</FormSubgroupTitle>

        <Form.Item shouldUpdate noStyle>
          {t => (
            <>
              <Row gutter={24}>
                <Col span={8} />
                <Col span={8}>
                  <div className={classNames(styles.centered, styles.mt20, styles.mb20)}>
                    <div
                      className={
                        t.getFieldValue('productType') === 'pos'
                          ? classNames(styles.styledBtn, styles.btnSelected)
                          : styles.styledBtn
                      }
                      onClick={() => t.setFieldsValue({ productType: 'pos' })}
                    >
                      <div>
                        <div className={classNames(styles.centered, styles.mb10)}>+</div>
                        <div className={styles.centered}>{translations().addPos}</div>
                      </div>
                    </div>

                    <div
                      className={
                        t.getFieldValue('productType') === 'ecom'
                          ? classNames(styles.styledBtn, styles.ml20, styles.btnSelected)
                          : classNames(styles.styledBtn, styles.ml20)
                      }
                      onClick={() => t.setFieldsValue({ productType: 'ecom' })}
                    >
                      <div>
                        <div className={classNames(styles.centered, styles.mb10)}>+</div>
                        <div className={styles.centered}>{translations().addEcom}</div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col span={8} />
              </Row>

              {t.getFieldValue('productType') === 'pos' && (
                <>
                  <PosFieldsForm
                    form={form}
                    isPaytek={store.productStore.isPaytek}
                    posModels={store.posModels}
                    isPosModelsLoading={store.isPosModelsLoading}
                    getBundlesForSelectedPos={store.getBundlesForSelectedPos}
                    bundleOptions={store.bundleOptions}
                    isBundlesLoading={isBundlesLoading}
                    handleChargeTypeChange={store.productStore.handleChargeTypeChange}
                    storeForm={storeForm}
                    companyProfile={store.dataStore.application?.companyProfile}
                    onboardingSettings={store.onboardingSettings}
                    posDeliveryAddress={store.productStore.productDeliveryAddress}
                    posDeliveryAddressType={store.productStore.posDeliveryType}
                    showDeliveryInfo={isEmpty(store.productStore.productDeliveryAddress)}
                    posDeliveryInstructions={store.productStore.productDeliveryInstructions}
                    productItem={store.productStore.products?.find(p => p.type === 'pos')}
                    subscriptionFee={store.subscriptionPriceFeeForSelectedPos}
                    aliPayEnabled={store.aliPayEnabled}
                    isSME={store.isSME}
                    isISO={store.isISO}
                  />
                </>
              )}

              {t.getFieldValue('productType') === 'ecom' && (
                <EcomFieldsForm form={form} website={store.dataStore.application?.companyProfile?.companyWebsite} />
              )}
            </>
          )}
        </Form.Item>

        <Form.Item shouldUpdate>
          {t =>
            t.getFieldValue('productType') && (
              <div className={styles.addTerminalBtn}>
                <CustomButton
                  disabled={!t.getFieldValue('productType')}
                  type='primary'
                  onClick={async () => {
                    await form.validateFields()
                    store.productStore.addProductV2(
                      form,
                      store.bundlesForSelectedPos,
                      form.getFieldValue('productType')
                    )
                  }}
                >
                  {translations().addProduct}
                </CustomButton>
              </div>
            )
          }
        </Form.Item>

        <CustomModal
          title={translations().attention}
          open={store.productStore.showPopup}
          onOk={() => {
            store.productStore.setShowPopup(false)
          }}
          footer={
            <CustomButton onClick={() => store.productStore.setShowPopup(false)}>{translations().confirm}</CustomButton>
          }
        >
          {translations().byOptomanyDetails}
        </CustomModal>
      </div>
    )
  }
)
