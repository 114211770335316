import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { Card, Empty, Spin, Alert, Typography, Row, Col, Button, Form, Select, Collapse, Modal } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { Comment } from '@ant-design/compatible'
import moment from 'moment-timezone'
import { isEmpty } from 'dna-common'
import { useInjection } from 'dna-react-ioc'
import { AppStore } from '~/code/stores/AppStore'
import { IChangeBankDetailsDossierV2Store } from '../../IChangeBankDetailsDossierV2Store'
import { ChangeBankDetailsDossierV2StoreSymbol } from '../../ChangeBankDetailsDossierV2'
import { SupportingDocument, Upload } from '~/code/pages/StartApplication/components/SupportingDocuments/components'
import { DocumentsType } from '../../models/DocumentType'
import { ReloadButton } from '~/code/components'
import { parseSortCode } from '~/code/stores/ChangeBankDetailsDossierV2Store/services'
import { FormSubgroupTitle } from '~/code/pages/StartApplication/components'
import { CustomVerificationInfo } from '~/code/components'
import { availableDocumentTypes, openBankingStatus } from '../../constants'
import { openBankingResult } from './constants'
import styles from './ChangeBankDetailsInfo.scss'
import translations from './translations'

const { Link } = Typography
export const ChangeBankDetailsInfo = observer((props: { open: boolean; initiatorEmail: string }) => {
  const store = useInjection<IChangeBankDetailsDossierV2Store>(ChangeBankDetailsDossierV2StoreSymbol)
  const [form] = Form.useForm()
  const [documentType, setDocumentType] = useState<keyof DocumentsType>('' as keyof DocumentsType)
  const [isUploadBtnClicked, setIsUploadBtnClicked] = useState(false)

  useEffect(() => {
    setIsUploadBtnClicked(false)
  }, [props.open])

  const handleDocumentOptionChange = (option: keyof DocumentsType) => {
    setDocumentType(option)
  }

  if (store.hasProcessDetailsLoadingError) {
    return (
      <div className={styles.empty}>
        <Empty />
      </div>
    )
  }

  if (store.isProcessDetailsLoading) {
    return (
      <div className={styles.loader}>
        <Spin size='large' />
      </div>
    )
  }

  const renderAdditionalDocumentUpload = (_documentType: string) => {
    return (
      <Upload
        disabled={!documentType}
        loading={store.isDocumentLoading}
        fileList={[]}
        onChange={async fileList => {
          await store.uploadDocument(fileList, documentType)
        }}
      >
        <Button icon={<UploadOutlined />}>{translations().upload}</Button>
      </Upload>
    )
  }

  const isCompleteDisabled = (): boolean => {
    if (store.openBankingResults === openBankingResult.match) return false
    return store.openBankingResults !== openBankingResult.match && store.documentsData.length === 0
  }

  const isTerminateDisabled = (): boolean => {
    return (
      ![openBankingStatus.completed, openBankingStatus.started].includes(store.selectedProcessStatus?.toUpperCase()) ||
      AppStore.authStore.email !== props?.initiatorEmail
    )
  }

  const isNeedShowOpenBanking =
    store.processDetails?.needRestrictionChecks &&
    store.processDetails?.accountsInfoForUpdate &&
    [openBankingStatus.completed, openBankingStatus.started].includes(
      store.processDetails?.processStatus?.toUpperCase()
    )

  const confirm = () => {
    Modal.confirm({
      content: translations().terminateConfirmation,
      onOk: () => {
        store.terminateApplication()
        store.loadProcesses()
      }
    })
  }

  return (
    <Card>
      {isNeedShowOpenBanking ? (
        <Form form={form} autoComplete='off' layout='vertical' requiredMark={'optional'}>
          <div className={styles.refresHeader}>
            <b className={styles.resultsHeader}>{translations().obRequestStatus}</b>
            {store.openBankingResults?.toUpperCase() !== openBankingResult.verified &&
              store.openBankingResults?.toUpperCase() !== openBankingResult.match && (
                <div className={styles.refreshSection}>
                  <Link onClick={() => store.fetchOpenBankingResults()}>{translations().refreshStatus}</Link>
                  <ReloadButton onClick={() => store.fetchOpenBankingResults()} />
                </div>
              )}
          </div>
          <Alert
            message={
              <>
                {translations().verifResult(store.openBankingResults)}
                {store.openBankingResults === openBankingResult.sent && (
                  <>
                    <p>{translations().obRequestWaiting}</p>
                    <p>{translations().note}</p>
                    <p>{translations().note2}</p>
                    <p>{translations().note3}</p>
                    <p className={styles.red}>{translations().ifLinkNotOpened}</p>
                  </>
                )}

                {store.openBankingResults === openBankingResult.match && (
                  <>
                    <p>{translations().obrRequestAuthorised}</p>
                    <p>{translations().obrMatchText}</p>
                    <br />
                    {!isEmpty(store.responseOpenbankingDetails) && (
                      <>
                        <FormSubgroupTitle>{translations().responseData}</FormSubgroupTitle>
                        <Collapse defaultActiveKey={1}>
                          <Collapse.Panel
                            header={`${
                              translations().account
                            } ${store.responseOpenbankingDetails?.accountIdentification?.substring(6)}`}
                            key={1}
                          >
                            {translations().obrRequestDetailsInfo(
                              parseSortCode(store.responseOpenbankingDetails?.accountIdentification?.substring(0, 6)),
                              store.responseOpenbankingDetails?.accountIdentification?.substring(6),
                              store.responseOpenbankingDetails?.calculatedOwnerName,
                              store.overallScore
                            )}
                          </Collapse.Panel>
                        </Collapse>
                        <br />
                        {!isEmpty(store.responseOpenbankingCustomVerification) && (
                          <CustomVerificationInfo data={store.responseOpenbankingCustomVerification} />
                        )}
                      </>
                    )}
                  </>
                )}
                {store.openBankingResults === openBankingResult.noMatch && (
                  <>
                    {!isEmpty(store.responseOpenbankingDetails) && (
                      <>
                        <FormSubgroupTitle>{translations().responseData}</FormSubgroupTitle>
                        <Collapse defaultActiveKey={1}>
                          <Collapse.Panel
                            header={`${
                              translations().account
                            } ${store.responseOpenbankingDetails?.accountIdentification?.substring(6)}`}
                            key={1}
                          >
                            {translations().obrRequestDetailsInfo(
                              parseSortCode(store.responseOpenbankingDetails?.accountIdentification?.substring(0, 6)),
                              store.responseOpenbankingDetails?.accountIdentification?.substring(6),
                              store.responseOpenbankingDetails?.calculatedOwnerName,
                              store.overallScore
                            )}
                          </Collapse.Panel>
                        </Collapse>
                        <br />
                        <br />
                      </>
                    )}
                    <p>{translations().obrProceedWithDocs}</p>
                    {!isEmpty(store.responseOpenbankingCustomVerification) && (
                      <CustomVerificationInfo data={store.responseOpenbankingCustomVerification} />
                    )}
                  </>
                )}
              </>
            }
            type='warning'
          />
          {store.openBankingResults !== openBankingResult.match && (
            <>
              <div className={styles.uploadBtn}>
                <Button
                  type='primary'
                  onClick={() => {
                    setIsUploadBtnClicked(true)
                  }}
                >
                  {translations().uploadBankStatement}
                </Button>
              </div>
              {isUploadBtnClicked && (
                <div className={styles.documentsBlock}>
                  {store.documentTypes.map(d => (
                    <SupportingDocument
                      key={d}
                      title={translations()[d]}
                      fileList={store.documentsMap[d]}
                      onChange={async (fileList, file) => {
                        const isDeleted = await store.deleteFile(d, file?.name)
                        if (!isDeleted) return
                        store.removeDocument(d, fileList)
                      }}
                      loading={store.isDocumentLoading}
                    />
                  ))}
                  <Row justify={'space-between'}>
                    <Col xs={18}>
                      <Form.Item name={'documentType'} key={'documentType'}>
                        <Select
                          options={availableDocumentTypes}
                          onChange={handleDocumentOptionChange}
                          value={documentType}
                          placeholder={translations().documentType}
                        />
                      </Form.Item>
                    </Col>
                    <Col>{renderAdditionalDocumentUpload(documentType)}</Col>
                  </Row>
                </div>
              )}
            </>
          )}
          {
            <div className={styles.completeBtnsBlock}>
              <Button
                className={styles.terminateBtn}
                type='primary'
                loading={store.isTerminateProcessLoading}
                disabled={isTerminateDisabled()}
                onClick={() => {
                  confirm()
                }}
              >
                {translations().terminate}
              </Button>
              <Button
                type='primary'
                loading={store.isCompleteApplicationLoading}
                disabled={isCompleteDisabled()}
                onClick={() => {
                  store.completeApplication()
                  store.loadProcesses()
                }}
              >
                {translations().complete}
              </Button>
            </div>
          }
        </Form>
      ) : (
        <>
          <div className={styles.blockTitle}>{translations().commonInfo}</div>
          {store.selectedProcessStatus}
          <Row gutter={[0, 12]}>
            <Col xs={12} className={styles.propLabel}>
              {translations().companyName}:
            </Col>
            <Col xs={12}>{store.processDetails?.companyName}</Col>
          </Row>
          <Row gutter={[0, 12]}>
            <Col xs={12} className={styles.propLabel}>
              {translations().companyNumber}:
            </Col>
            <Col xs={12}>{store.processDetails?.companyNumber}</Col>
          </Row>
          {store.processDetails?.comments?.length ? (
            <>
              <div className={styles.blockTitle}>{translations().comments}</div>
              {store.processDetails.comments.map((comment, index) => (
                <Comment
                  key={index}
                  content={comment.comment}
                  author={comment.author}
                  datetime={moment(comment.date).format('YYYY-MM-DD HH:mm:ss')}
                />
              ))}
            </>
          ) : null}
        </>
      )}
    </Card>
  )
})
