import { observer } from 'mobx-react'
import React from 'react'
import { ProcessDetailsModel } from '~/code/pages/BPMProcesses/Requests/models'
import { Card } from 'antd'
import styles from './Details.scss'
import translations from './translations'

interface DetailsProps {
    processDetails: ProcessDetailsModel
}

export const Details = observer((props: DetailsProps) => {
    const {
        firstName,
        surname,
        companyName,
        merchantEmail,
        phoneNumber,
        initiatorName,
        initiatorEmail,
        initiatorComment,
        reviewerName,
        reviewerEmail,
        reviewerComment
    } = props.processDetails

    return <>
        <div className={styles.container}>
            <Card title={translations().companyInfo} bordered={ false }>
                <div className={styles.propContainer}>
                    <div className={styles.propLabel}>{translations().merchant}</div>
                    <div className={styles.propValue}>{companyName}</div>
                </div>
                <div className={styles.propContainer}>
                    <div className={styles.propLabel}>{translations().firstName}</div>
                    <div className={styles.propValue}>{firstName}</div>
                </div>
                <div className={styles.propContainer}>
                    <div className={styles.propLabel}>{translations().surname}</div>
                    <div className={styles.propValue}>{surname}</div>
                </div>
                <div className={styles.propContainer}>
                    <div className={styles.propLabel}>{translations().email}</div>
                    <div className={styles.propValue}>{merchantEmail}</div>
                </div>
                <div className={styles.propContainer}>
                    <div className={styles.propLabel}>{translations().phoneNumber}</div>
                    <div className={styles.propValue}>{phoneNumber}</div>
                </div>
            </Card>
        </div>
        <div className={styles.container}>
            <Card title={translations().initiatorInfo} bordered={ false }>
                {
                    initiatorName && (
                        <div className={styles.propContainer}>
                            <div className={styles.propLabel}>{translations().name}</div>
                            <div className={styles.propValue}>{initiatorName}</div>
                        </div>
                    )
                }
                <div className={styles.propContainer}>
                    <div className={styles.propLabel}>{translations().email}</div>
                    <div className={styles.propValue}>{initiatorEmail}</div>
                </div>
                <div className={styles.propContainer}>
                    <div className={styles.propLabel}>{translations().comment}</div>
                    <div className={styles.propValue}>{initiatorComment}</div>
                </div>
            </Card>
        </div>
        {reviewerEmail && (
            <div className={styles.container}>
                <Card title={translations().reviewerInfo} bordered={ false }>
                    {
                        reviewerName && (
                            <div className={styles.propContainer}>
                                <div className={styles.propLabel}>{translations().name}</div>
                                <div className={styles.propValue}>{reviewerName}</div>
                            </div>
                        )
                    }
                    <div className={styles.propContainer}>
                        <div className={styles.propLabel}>{translations().email}</div>
                        <div className={styles.propValue}>{reviewerEmail}</div>
                    </div>
                    <div className={styles.propContainer}>
                        <div className={styles.propLabel}>{translations().comment}</div>
                        <div className={styles.propValue}>{reviewerComment}</div>
                    </div>
                </Card>
            </div>
        )}
    </>
})
