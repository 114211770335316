export const notCompositeTariffsList = [
  'terminalCost',
  'monthlyGatewayFee',
  'fasterFundingMonthly',
  'managementFee',
  'gatewaySetUpFeePerTransaction',
  'paymentTerminalSupportAndTransactionFee',
  'engineerInstallationFee',
  'setUpConfigurationDeliveryFee',
  'fasterFundingFee',
  'minMonthlyServiceCharge',
  'gatewaySetUpFee',
  'airtimeService',
  'zashApp',
  'posRentalMonthlyFee',
  'monthlyFee',
  'pciFee',
  'chargebackFee',
  'refundFee',
  'settlementFee',
  'gatewayFee',
  'authorisationOkFee',
  'authorisationFee',
  'settlementPeriod'
]

export const descriptionPostfix = 'Description'

export const notEditableTariffs = ['diners', 'amex', 'chinaUnionPay']
