import translations from './translations'
import { routeTypeEnum } from '../constants'

translations.add('en', {
    contractNumber: 'Contract Number',
    merchantId: 'Merchant Id',
    routeToMasterCard: 'MasterCard',
    routeToVisa: 'Visa',
    terminalId: 'Terminal Id',
    change: 'Change',
    required: 'Required',
    selectTransactionChannel: 'Select transaction channel',
    product: 'Product',
    dna: 'DNA',
    paynetics: 'Paynetics',
    getRouteLabel: (value: string) => routeTypeEnum[value]
})
