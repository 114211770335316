import React from 'react'
import { observer } from 'mobx-react'
import { Form, Modal } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { StartChangeRiskLimitsStore } from '~/code/stores/MerchantsManagementStore/StartChangeRiskLimitsStore'
import { ChangeRiskLimits } from './../ChangeRiskLimitsModal/ChangeRiskLimitsModal'
import { getChangedItemsList } from '../ChangeRiskLimitsModal/services'
import translations from './translations'

const { confirm } = Modal
export const StartChangeRiskLimitsStoreSymbol = Symbol('StartChangeRiskLimitsStore')
export const StartChangeRiskLimits = observer(() => {
    const [form] = Form.useForm()
    const { 
        changeRiskLimitsStore, 
        isActiveConfirm, 
        riskLimits,
        onFormSubmit
    } = useInjection<StartChangeRiskLimitsStore>(StartChangeRiskLimitsStoreSymbol)

    const onFinish = values => {
        confirm({
            title: translations().sureToChangeFields,
            content: getChangedItemsList(changeRiskLimitsStore.initialValues, changeRiskLimitsStore.currentValues),
            okType: 'danger',
            okText: translations().confirm,
            cancelText: translations().cancel,
            okButtonProps: {
                type: 'primary',
                danger: false
            },
            onOk: () => onFormSubmit(values)
        })
    }

    return <ChangeRiskLimits
        form={ form }
        onFinish={ onFinish }
        title={ translations().changeRiskLimit }
        okButtonText={ translations().change }
        okButtonProps={ {
            disabled: !isActiveConfirm
        }}
        riskLimits={riskLimits}
    />
})
