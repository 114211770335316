import { RcFile } from 'antd/lib/upload'

export const convertCamelCaseToUserFacingText = (value: string) => {
  const arr = value.split('')
  let output = ''

  arr.forEach((val, idx) => {
    if (idx === 0) {
      output += val.toLocaleUpperCase()
      return
    }

    if (typeof val === 'number' || val === val.toLocaleUpperCase()) {
      output += ` ${val.toLocaleLowerCase()}`
      return
    }

    output += val
  })

  return output
}

export function isCountryUK(country: string) {
  return country.replace(/\s/g, '').toUpperCase() === 'UNITEDKINGDOM'
}

export function validatePostalCodeOfUK(postalCode: string) {
  const UK_POSTCODE_PATTERN = /^[A-Z]{1,2}[0-9][A-Z0-9]?\s?[0-9][A-Z]{2}$/i
  return (
    postalCode &&
    UK_POSTCODE_PATTERN.test(postalCode) &&
    !/[QVX]/i.test(postalCode[0]) &&
    !/[IJZ]/i.test(postalCode[1]) &&
    (!/[A-Z]/i.test(postalCode[2]) || /[ABCDEFGHJKSTUW]/i.test(postalCode[2])) &&
    !/[CIKMOV]/i.test(postalCode[postalCode.length - 2])
  )
}

export function sanitizeString(name: string): string {
  return name.replaceAll('&', '')
}

export function sanitizeFileName(file: RcFile): File {
  const modifiedFileName = sanitizeString(file.name)
  const modifiedFile = new File([file], modifiedFileName, {
    type: file.type,
    lastModified: file.lastModified
  })
  return modifiedFile
}

export function chunkArrayInGroups(arr: any[], size: number = 3) {
  const result = []

  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size))
  }
  return result
}
