import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    errLoadingAcquisitionChannels: string
    errLoadingAccountNumbers: string
    selectMerchant: string
    selectMerchantOrChannelFirst: string
    selectMerchantFirst: string
    selectDatesFirst: string
    noData: string
    all: string
}>()
