import { isEmpty } from 'dna-common'

export const isObjectValueEmpty = (object: object) => {
    if (Object.values(object).length) {
        return Object.values(object).some((value) => isEmpty(value))
    }
    return true
}

export const areValuesEmpty = (values: any[]) => {
    for (const value of values) {
        if (value === 'all') {
            continue
        }

        if (!isEmpty(value)) {
            if (typeof value === 'object' && value !== null) return isObjectValueEmpty(value)
            return false
        }
    }
    return true
}
