import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    overview: string
    detailedView: string

    statusTabs: {
        all: string
        successful: string
        failed: string
        other: string

        responseCodes: string
        cscErrors: string
        threeDsecureErrors: string
    }
}>()

