import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  yes: string
  no: string
  isSeasonal: string
  qLabel: string
  q1Label: string
  q2Label: string
  q3Label: string
  q4Label: string
  annualCardTurnover: string
  averageTransaction: string
  numberTransactionPerMonth: string
  highestTransaction: string
  isSeasonalBusiness: string
}>()
