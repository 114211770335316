import { Modal } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { MerchantsManagementStoreSymbol } from '../../MerchantsManagement'
import translations from './translations'
import { IMerchantManagementStore } from '~/code/models'

export const AdditionalStoreIssue = observer(() => {
  const store = useInjection<IMerchantManagementStore>(MerchantsManagementStoreSymbol)
  return (
    <Modal
      title={translations().additionalStoreIssueConfirm}
      open={store.showAdditionalStoreIssueModal}
      onOk={() => store.goToShowAdditionalProcess()}
      onCancel={() => store.setShowAdditionalStoreIssueModal(false)}
      okButtonProps={{
        loading: store.isFullDossierLoading,
        disabled: !store.isMerchantActive
      }}
    >
      <p>{translations().confirmText}</p>
      <p>{translations().confirmText2}</p>
    </Modal>
  )
})
