import { ColumnsType } from 'antd/es/table'
import translations from '../translations'

export const transactionColumns: ColumnsType<any> = [
  {
    title: translations().order,
    dataIndex: 'order',
    key: 'order'
  },
  {
    title: translations().partner,
    key: 'acquisitionChannel',
    dataIndex: 'acquisitionChannel'
  },
  {
    title: translations().trVol,
    key: 'salesVolume',
    dataIndex: 'salesVolume'
  },
  {
    title: translations().comVol,
    key: 'salesFeeVolume',
    dataIndex: 'salesFeeVolume'
  }
]
