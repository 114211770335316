import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    yesterday: string
    week: string
    lastWeek: string
    month: string
    lastMonth: string
    merchant: string
    noMerchant: string
    selectMerchant: string
    selectMerchantOrAcquisitionChannelFirst: string
    selectDatesFirst: string
    selectMerchantFirst: string
    willBeAvailableAfter6AM: string
    willBeAvailableOnMondayAfter6AM: string
    all: string
    errLoadingAccountNumbers: string
    accountNumber: string
    viewStatement: string
    warnText: any
}>()

