import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    avgReviewTime: string
    status: string
    count: string
    min: string
    app: string
}>()

