import { message } from 'antd'
import { inject, injectable } from 'inversify'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { DataLoadingStatus } from '../../models/DataLoadingStatus'
import { StatisticsFiltersStoreSymbol } from '../../pages'
import { IStatisticsStore, TeamSummaryType, TerminalIssueReportType, TotalSummaryType, GroupedByTerminalType, TerminalType } from '../../pages/BPM/Statistics/models'
import { fetchTeamSummary, fetchTerminalIssueReport, fetchTotalSummary } from '../../pages/BPM/Statistics/services'
import translations from '../../pages/BPM/Statistics/translations'
import { StatisticsFiltersStore } from './StatisticsFiltersStore'

@injectable()
export class StatisticsStore implements IStatisticsStore {
    filtersStore: StatisticsFiltersStore

    totalSummaryLoadingStatus: GroupedByTerminalType<DataLoadingStatus> = { ecom: 'idle', pos: 'idle' }
    teamSummaryLoadingStatus: GroupedByTerminalType<DataLoadingStatus> = { ecom: 'idle', pos: 'idle' }
    terminalIssueReportLoadingStatus: DataLoadingStatus = 'idle'

    _totalSummary: GroupedByTerminalType<TotalSummaryType> = { ecom: null, pos: null }
    _teamSummary: GroupedByTerminalType<TeamSummaryType[]> = { ecom: [], pos: [] }
    _terminalIssueReport: TerminalIssueReportType[] = []

    constructor(@inject(StatisticsFiltersStoreSymbol) filtersStore : StatisticsFiltersStore) {
        this.filtersStore = filtersStore

        makeObservable(this, {
            totalSummaryLoadingStatus: observable,
            teamSummaryLoadingStatus: observable,
            terminalIssueReportLoadingStatus: observable,

            _totalSummary: observable,
            _teamSummary: observable,
            _terminalIssueReport: observable,

            ecomTotalSummary: computed,
            posTotalSummary: computed,
            ecomTeamSummary: computed,
            posTeamSummary: computed,
            totalSummary: computed,
            terminalIssueReport: computed,
            totalTerminalIssueReport: computed,

            loadTotalSummary: action,
            loadTeamSummary: action,
            loadTerminalIssueReport: action,
            clear: action
        })

        reaction(() => {
            const { dateStore: { startDate, endDate } } = this.filtersStore
            return { startDate, endDate }
        }, this.clear)

        reaction(() => {
            const { filters: { acquisitionChannel, productType, approvalType, segmentCategory, segmentSubCategory } } = this.filtersStore
            return { acquisitionChannel, productType, approvalType, segmentCategory, segmentSubCategory }
        }, () => this.terminalIssueReportLoadingStatus = 'idle')
    }

    get ecomTotalSummary() {
        if (this.totalSummaryLoadingStatus.ecom === 'idle') {
            this.loadTotalSummary('ecom')
        }

        return this._totalSummary.ecom
    }

    get posTotalSummary() {
        if (this.totalSummaryLoadingStatus.pos === 'idle') {
            this.loadTotalSummary('pos')
        }

        return this._totalSummary.pos
    }

    get totalSummary() {
        if (!this.ecomTotalSummary || !this.posTotalSummary) return []
        const totalSummaryArr = [this.ecomTotalSummary, this.posTotalSummary]
        return [...totalSummaryArr, this.calculateTotalRowSummary(totalSummaryArr)]
    }

    get ecomTeamSummary() {
        if (this.teamSummaryLoadingStatus.ecom === 'idle') {
            this.loadTeamSummary('ecom')
        }

        if (this._teamSummary.ecom.length === 0) return []

        return [...this._teamSummary.ecom, this.calculateTotalRowSummary(this._teamSummary.ecom)]
    }

    get posTeamSummary() {
        if (this.teamSummaryLoadingStatus.pos === 'idle') {
            this.loadTeamSummary('pos')
        }

        if (this._teamSummary.pos.length === 0) return []

        return [...this._teamSummary.pos, this.calculateTotalRowSummary(this._teamSummary.pos)]
    }

    get terminalIssueReport() {
        if (this.terminalIssueReportLoadingStatus === 'idle') {
            this.loadTerminalIssueReport()
        }

        if (this._terminalIssueReport.length === 0) return []

        return this._terminalIssueReport
    }

    get totalTerminalIssueReport() {
        return {
            terminalQuantity: {
                ecom: this.terminalIssueReport.reduce((prev, curr) => prev + (curr.productType !== 'pos' ? curr.terminalQuantity : 0), 0),
                pos: this.terminalIssueReport.reduce((prev, curr) => prev + (curr.productType === 'pos' ? curr.terminalQuantity : 0), 0)
            },
            merchantQuantity: {
                ecom: this.terminalIssueReport.reduce((prev, curr) => prev + (curr.productType !== 'pos' ? curr.merchantQuantity : 0), 0),
                pos: this.terminalIssueReport.reduce((prev, curr) => prev + (curr.productType === 'pos' ? curr.merchantQuantity : 0), 0)
            }
        }
    }

    loadTotalSummary = async (terminalType: TerminalType) => {
        this.totalSummaryLoadingStatus[terminalType] = 'loading'
        const { dateStore: { startDate, endDate } } = this.filtersStore
        const { status, error, result } = await fetchTotalSummary({
            from: startDate.clone().utc().format(),
            to: endDate.clone().utc().format(),
            terminalType
        })

        if (status !== 200 || error) {
            message.error(error?.message || translations().errorLoading.totalSummary(terminalType))
            this.totalSummaryLoadingStatus[terminalType] = 'failed'
            return
        }

        this._totalSummary[terminalType] = { terminalType: translations()[terminalType], ...result}
        this.totalSummaryLoadingStatus[terminalType] = 'finished'
    }

    loadTeamSummary = async (terminalType: TerminalType) => {
        this.teamSummaryLoadingStatus[terminalType] = 'loading'
        const { dateStore: { startDate, endDate } } = this.filtersStore
        const { status, error, result } = await fetchTeamSummary({
            from: startDate.clone().utc().format(),
            to: endDate.clone().utc().format(),
            terminalType
        })

        if (status !== 200 || error) {
            message.error(error?.message || translations().errorLoading.teamSummary(terminalType))
            this.teamSummaryLoadingStatus[terminalType] = 'failed'
            return
        }

        this._teamSummary[terminalType] = result ? result : []
        this.teamSummaryLoadingStatus[terminalType] = 'finished'
    }

    loadTerminalIssueReport = async () => {
        this.terminalIssueReportLoadingStatus = 'loading'
        const { dateStore: { startDate, endDate }, filters: { acquisitionChannel, productType, approvalType, segmentCategory, segmentSubCategory } } = this.filtersStore
        const { status, error, result } = await fetchTerminalIssueReport({
            from: startDate.clone().utc().format(),
            to: endDate.clone().utc().format(),
            acquisitionChannel: acquisitionChannel ? acquisitionChannel : undefined,
            productType: productType ? productType : undefined,
            approvalType: approvalType ? approvalType : undefined,
            segmentCategory: segmentCategory ? segmentCategory : undefined,
            segmentSubCategory: segmentSubCategory ? segmentSubCategory : undefined,
        })

        if (status !== 200 || error) {
            message.error(error?.message || translations().errorLoading.terminalIssueReport)
            this.terminalIssueReportLoadingStatus = 'failed'
            return
        }

        this._terminalIssueReport = result ? result : []
        this.terminalIssueReportLoadingStatus = 'finished'
    }

    clear = () => {
        this.totalSummaryLoadingStatus = { ecom: 'idle', pos: 'idle' }
        this.teamSummaryLoadingStatus = { ecom: 'idle', pos: 'idle' }
        this.terminalIssueReportLoadingStatus = 'idle'
    }

    private calculateTotalRowSummary = (summary) => ({
        terminalType: translations().total,
        user: translations().total,
        login: translations().total,
        isAssigned: false,
        start: summary.reduce((prev, curr) => prev + curr.start, 0),
        submitted: {
            new: summary.reduce((prev, curr) => prev + curr.submitted.new, 0),
            addInfoProvided: summary.reduce((prev, curr) => prev + curr.submitted.addInfoProvided, 0)
        },
        approved: {
            new: summary.reduce((prev, curr) => prev + curr.approved.new, 0),
            addInfoProvided: summary.reduce((prev, curr) => prev + curr.approved.addInfoProvided, 0)
        },
        referred: summary.reduce((prev, curr) => prev + curr.referred, 0),
        declined: summary.reduce((prev, curr) => prev + curr.declined, 0),
        end: summary.reduce((prev, curr) => prev + curr.end, 0)
    })
}