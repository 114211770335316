import React from 'react'
import { observer } from 'mobx-react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { OfficerRole } from 'startapp/models'
import { isAuthorisedSignatoriesValid, isCompanyOfficerValid } from 'startapp/services'
import { SubLabel } from 'startapp/components'
import { OfficerListPage } from '../OfficerListPage'
import translations from './translations'
import { SignatoriesProps } from './props'
import styles from './Signatories.scss'

export const Signatories = observer( (props: SignatoriesProps) => {
    const { signatories, selectOfficer, deleteOfficer, onContinue, isSaveLoading, onSave, isContinueLoading } = props

    return <OfficerListPage
        title={<>
            {translations().title}
            <SubLabel className={styles.subLabel}>{translations().optional}</SubLabel>
            <Tooltip title={translations().tooltip} className={styles.tooltip}>
                <QuestionCircleOutlined />
            </Tooltip>
        </>}
        text={translations().text}
        list={signatories && signatories.map((d) => ({
            name: d.nameElements?.forename,
            surname: d.nameElements?.surname,
            isCompleted: isCompanyOfficerValid(d),
            onClick: () => selectOfficer(d),
            onRemove: () => deleteOfficer(d),
            isRemoveDisabled: !d.deletionAllowed
        }))}
        isValid={isAuthorisedSignatoriesValid(signatories)}
        onAdd={() => selectOfficer({ officerRole: OfficerRole.authorisedSignatory })}
        isContinueLoading={isContinueLoading}
        onContinue={onContinue}
        isSaveLoading={isSaveLoading}
        onSave={onSave}
    />
})
