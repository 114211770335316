import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { IPartnerAccessStore } from '~/code/pages/PartnerAccess'
import {
  PartnerAccessFilter,
  PartnerAccessTable,
  PartnerAccessLoginDrawer,
  PartnerAccessDrawer,
  PartnerAccessSecurityDrawer,
  PartnerAccessHead
} from '~/code/pages/PartnerAccess/components'

export const PartnerAccessStoreSymbol = Symbol('PartnerAccessStore')

export const PartnerAccess = observer(() => {
  const store = useInjection<IPartnerAccessStore>(PartnerAccessStoreSymbol)

  const { activeTab, isLoginDrawerOpen, isPartnerDrawerOpen, isSecurityDrawerOpen, onSecurityDrawerClose, resetTabs } =
    store

  useEffect(() => () => resetTabs(), [])

  useEffect(() => resetTabs(), [activeTab])

  return (
    <>
      <PartnerAccessHead store={store} />
      <PartnerAccessFilter />
      <PartnerAccessTable />

      {/* Drawers */}
      <PartnerAccessLoginDrawer open={isLoginDrawerOpen} />
      <PartnerAccessDrawer open={isPartnerDrawerOpen} />
      <PartnerAccessSecurityDrawer open={isSecurityDrawerOpen} />
    </>
  )
})
