import React from 'react'
import { List, Row, Col } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import sortBy from 'lodash/sortBy'
import { isCorporate } from '~/code/pages/StartProcess/services'
import { StoresListItem } from '../StoresListItem'
import translations from './translations'
import { StoresListPageProps } from './props'
import styles from './StoresListPage.scss'
import { CustomButton } from '~/code/components'
import { BelowActionsWithSave, FormTitle } from '../../../shared'

export const StoresListPage: React.FC<StoresListPageProps> = props => {
  const { title, text, list, onAdd, isSaveLoading, onSave, addTitle, sorting = true } = props

  return (
    <>
      <FormTitle>{title}</FormTitle>
      <p className={styles.text}>{text}</p>
      <Row className={styles.styledRow}>
        <Col xs={24}>
          <List
            locale={{ emptyText: translations().empty }}
            className={styles.listStyle}
            split={false}
            itemLayout='horizontal'
            dataSource={
              sorting
                ? sortBy(list, item => {
                    if (isCorporate(item)) {
                      return item.corporateName?.toLocaleLowerCase()
                    }
                    return item.name?.toLocaleLowerCase()
                  })
                : list
            }
            renderItem={item => (
              <>
                <StoresListItem {...item} />
              </>
            )}
          />
          {onAdd && (
            <div className={styles.addButtonContainer}>
              <CustomButton icon={<PlusOutlined />} onClick={onAdd}>
                {addTitle || translations().add}
              </CustomButton>
            </div>
          )}
          {onSave && <BelowActionsWithSave large isLoading={isSaveLoading} onSave={onSave} />}
        </Col>
      </Row>
    </>
  )
}
