import { Spin, Table } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment-timezone'

import { DATE_FORMAT } from '~/code/constants/date-time'
import { CLASSNAME_ANT_ROW, CLASSNAME_ROW_HEADER, CLASSNAME_ROW_STICKY } from '~/code/constants/ui'
import { ResizableColumnTitle } from '~/code/components'

import { TableWithInfiniteScrollProps } from './props'
import styles from './styles.scss'

export const TableWithInfiniteScroll: React.FC<TableWithInfiniteScrollProps> = (props) => {
  const { className, isLoadingMore, loading, dataSource, fixedDateProp, nonTableBodyHeight, onLoadMoreData, ...rest } = props
  const [tableBodyHeight, setTableBodyHeight] = useState(680)
  const refStickyDate = useRef<string>('')
  const refStickyRow = useRef<HTMLElement>(null)
  const refTableBody = useRef<HTMLElement>(null)

  const handleScroll = (tableBody: HTMLElement) => {
    if (!tableBody) {
      return
    }

    if (fixedDateProp) {
      if (!refStickyRow.current || refTableBody.current !== tableBody) {
        const div = document.createElement('div')
        div.classList.add(CLASSNAME_ROW_STICKY)
        tableBody.appendChild(div)
  
        refStickyDate.current = ''
        refStickyRow.current = div
        refTableBody.current = tableBody
      }
  
      const firstRow = tableBody.querySelector('.' + CLASSNAME_ANT_ROW) as HTMLElement
      const date = firstRow?.dataset['date']
      if (date && date !== refStickyDate.current) {
        refStickyDate.current = date
        refStickyRow.current.textContent = date
      }
    }

    const { scrollTop, scrollHeight, clientHeight } = tableBody
    const deltaHeight = 300
    const isAtEnd = scrollTop + clientHeight + deltaHeight >= scrollHeight
    if (isAtEnd && onLoadMoreData) {
      onLoadMoreData()
    }
  }

  useEffect(() => {
    const h = window.innerHeight - nonTableBodyHeight
    if (nonTableBodyHeight && h > tableBodyHeight) {
      setTableBodyHeight(h)
    }
  }, [setTableBodyHeight, dataSource, nonTableBodyHeight])

  return (
    <Table
      size='small'
      bordered
      virtual
      scroll={{ x: 1000, y: tableBodyHeight }}
      components={{ header: { cell: ResizableColumnTitle } }}
      onRow={(item) => fixedDateProp ? (
        item.groupTitle ?
          ({ 'className': CLASSNAME_ROW_HEADER, 'data-date': item.groupTitle })
          : ({ 'data-date':  moment(item[fixedDateProp]).format(DATE_FORMAT) })
      ) : undefined}
      dataSource={!loading ? dataSource : null }
      loading={loading}
      className={classNames(styles.root, className, 'dashboard-table', 'tableWithBottomPadding')}
      footer={() => isLoadingMore && <div style={{ textAlign: 'center'}}><Spin /></div>}
      onScroll={(e) => handleScroll(e.target as HTMLElement)}
      pagination={false}
      {...rest}
    />
  )
}
