import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  newApplication: string
  locationAddress: string
  addressError: string
  address: string
  postalCode: string
  country: string
  townOrCity: string
  incompleteData: string
  storeInfo: string
  storeAddress: {
    title: string
    titleSystem: string
    cityLabel: string
    cityRequired: string
    cityInvalid: string
    countryLabel: string
    countryRequired: string
    regionLabel: string
    postalCodeLabel: string
    postalCodeRequired: string
    postalCodeInvalid: string
    addressLine1Label: string
    addressLine1Required: string
    addressLine2Label: string
    addressLine2Required: string
    countryTooltip: string
  }
  addNewStore: string
  addAnotherStore: string
  natureOfBusinessLabel: string
  natureOfBusinessRequired: string
  natureOfBusinessPlaceholder: string
  tradingName: string
  systemDescriptor: string
  addProductMessage: string
  storeNamePlaceholder: string
  systemDescriptorPlaceholder: string
  industry: string
  industryPlaceholder: string
  mcc: string
  mccPlaceholder: string
  required: string
  productAlreadyExists: string
  settlementAccount: string
  assingAccount: string
  titleDirectDebit: string
  titleSettlements: string
  directDebitAccount: string
  systemStoreAddressDescription: string
  systemDescriptorDescription: string
  industryAndMCC: string
  maxLength24: string
  outletInformation: string
  assignBankDetails: string
}>()
