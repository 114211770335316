export enum NotificationBehaviourEnum {
  DASHBOARD_LOGIN_BANNER = 'dashboard_login_banner',
  DASHBOARD_SECTION_BANNER = 'dashboard_section_banner',
  MP_SECTION_BANNER = 'mp_section_banner',
  MP_LOGIN_BANNER = 'mp_login_banner',
  DASHBOARD_POPUP = 'dashboard_popup',
  MP_POPUP = 'mp_popup',
  DASHBOARD_BLOCK = 'dashboard_block',
  MP_BLOCK = 'mp_block',
  DASHBOARD_SECTION_BLOCK = 'dashboard_section_block',
  MP_SECTION_BLOCK = 'mp_section_block'
}


