import React from 'react'
import { observer } from 'mobx-react'
import { OfficerRole } from 'startapp/models'
import { isCompanyOfficerValid, isDirectorsValid } from 'startapp/services'
import { OfficerListPage } from '../OfficerListPage'
import translations from './translations'
import { DirectorsProps } from './props'

export const Directors = observer( (props: DirectorsProps) => {
    const { directors, selectOfficer, deleteOfficer, onContinue, isSaveLoading, onSave } = props

    return <OfficerListPage
        title={translations().title}
        text={translations().text}
        list={directors && directors.map((d) => ({
            name: d.nameElements?.forename,
            surname: d.nameElements?.surname,
            isCompleted: isCompanyOfficerValid(d),
            onClick: () => selectOfficer(d),
            onRemove: () => deleteOfficer(d),
            isRemoveDisabled: directors.length === 1 || !d.deletionAllowed
        }))}
        isValid={isDirectorsValid(directors)}
        onAdd={() => selectOfficer({ officerRole: OfficerRole.director })}
        onContinue={onContinue}
        isSaveLoading={isSaveLoading}
        onSave={onSave}
    />
})
