import { ItemModel } from '~/code/models/handbooks'
import translations from '../translations'

export const getPermissionKeys = (): ItemModel[] => {
    return Object.entries(translations().permissionKeys)
        .map(([value, label]) => ({ value, label }))
}

export const mergeAndConvertPermissions = (permissions: string[], permissionKeys: string[]) => {
    const map = {}
    const specificPermissions = []

    for (const p of permissions) {
        const [role, key] = p.split(/\.([^.]+)$/) // split by last dot

        if (!permissionKeys.includes(key)) {
            if (!specificPermissions.includes(p)) {
                specificPermissions.push(p)
            }
            continue
        }

        if (!map[role]) map[role] = {}

        map[role][key] = true
    }

    const permissionsData = Object.entries(map)
        .map(([permission, accessKeys]) => ({
            key: permission,
            title: translations().permissions[permission] || permission,
            ...(accessKeys as any)
        }))
        .sort((a, b) => a.title.localeCompare(b.title))

    const specificPermissionsData = specificPermissions
        .map((permission) => ({
            key: permission,
            title: translations().permissions[permission] || permission
        }))
        .sort((a, b) => a.title.localeCompare(b.title))

    return {
        permissionsData,
        specificPermissionsData
    }
}
