import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    title: string
    soleTrader: string,
    company: string,
    companyNameFormLabel: string
    companyNameFormRequired: string
    companyNameFormSubLabel: string
    companyIsNotActive: (status: string) => string
    companyNameFormDescription: string
    typeSomethingToSearch: string
    next: string
    changePaymentSolution: string
    couldNotLoadCompanies: string
    companyTypeRequired: string
}>()

