import translations from './translations'

translations.add('en', {
  salesForceCaseId: 'SF Case Id',
  search: 'Search',
  required: 'Required',
  midsAreNotEqual: 'MIDs do not match.',
  midsAreNotEqualInstruction: 'Please check if the correct Merchant/SF case were selected',
  noSalesForceDataFound: 'No data found.',
  noSalesForceDataFoundInstruction: 'Please check if the correct Merchant/SF case were selected',
  salesForceDataFound: 'Data found.',
  salesForceDataFoundInstruction: 'Salesforce data is successfully found'
})
