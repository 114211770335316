import { loginTableColumns, partnerTableColumns } from '../constants'
import { PartnerAccessTabKeyType } from '~/code/pages/PartnerAccess/types'
import { getActionItems } from '.'

import translations from '../translations'

const columns: Record<PartnerAccessTabKeyType, any> = {
  [PartnerAccessTabKeyType.partners]: loginTableColumns,
  [PartnerAccessTabKeyType.logins]: partnerTableColumns
}

export const getColumns = (tab: PartnerAccessTabKeyType, renderActions, onSelect) => [
  ...columns[tab],
  ...(Boolean(getActionItems(tab))
    ? [
        {
          title: translations().actions,
          key: 'actions',
          width: 90,
          fixed: 'right',
          render: (_, item) => renderActions(tab, key => onSelect(key, item))
        }
      ]
    : [])
]
