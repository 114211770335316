import translations from './translations'
import React from 'react'

translations.add('en', {
    sum: 'Sum',
    count: 'Count',
    sumTitle: (status, currencySymbol) => {
        return  <>Total sum of {status} transactions in {currencySymbol || '£'}</>
    },
    countTitle: (status, currencySymbol) => {
        return  <>Total count of {status} transactions in {currencySymbol || '£'}</>
    }
})
