import { CHUNKSIZE } from '~/code/stores/SettlementsPaymentsStore/services/constants'


const identification = __CLEARBANK_IDENTIFICATION__

export const generateApprovePaymentsRequest = (payments) => {

    const paymentInstructions = [];

    for (let i = 0; i < payments.length; i += CHUNKSIZE) {

        const chunk = payments.slice(i, i + CHUNKSIZE)

        if (chunk) {
            paymentInstructions.push({
                paymentInstructionIdentification: String(chunk[0].documentId),
                debtorAccount: {
                    identification: {
                        other: {
                            identification,
                            schemeName: {
                                proprietary: 'PRTY_COUNTRY_SPECIFIC'
                            }
                        }
                    }
                },
                creditTransfers: chunk.map((payment) => ({
                    paymentIdentification: {
                        instructionIdentification: String(payment.documentId),
                        endToEndIdentification: String(payment.documentId)
                    },
                    amount: {
                        instructedAmount: payment.amountToMerchant,
                        currency: 'GBP'
                    },
                    creditor: {
                        name: payment.merchantName
                    },
                    creditorAccount: {
                        identification: {
                            other: {
                                identification: 'GBR' + payment?.sortCode?.replaceAll('-', '') + payment.accountNumber,
                                schemeName: {
                                    proprietary: 'PRTY_COUNTRY_SPECIFIC'
                                }
                            }
                        }
                    },
                    remittanceInformation: {
                        structured: {
                            creditorReferenceInformation: {
                                reference: payment.details
                            }
                        }
                    }
                }))
            })
        }
    }
    return {paymentInstructions}
}
