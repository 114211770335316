import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Layout, Grid, Drawer, Button } from 'antd'

import { Footer } from '~/code/components/layout/Footer'
import { Header } from '~/code/components/layout/Header'
import { Sider } from '~/code/components/layout/Sider'
import { SiderMenu } from '~/code/components/layout/SiderMenu';

import { MainLayoutProps } from './props'
import styles from './styles.scss'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'

const { useBreakpoint } = Grid

export const MainLayout = observer((props: MainLayoutProps) =>  {
    const { store, currentRoute, children } = props
    const [ isCollapsed, setCollapsed ] = useState(false)
    const screens = useBreakpoint()
    const isMobile = screens.xs || !screens.md

    useEffect(() => {
        if (screens.xs || screens.lg === false) {
            setCollapsed(true)
        }
    }, [screens])

  return (
    <Layout hasSider className={styles.root}>
      <Sider isMobile={isMobile} className={styles.sider} collapsed={isCollapsed} onCollapse={c => setCollapsed(c)}>
        <SiderMenu
          menuData={store.menuData}
          onSelect={key => {
            store.onMenuItemClick(key)
            if (isMobile) {
              setCollapsed(true)
            }
          }}
        />
      </Sider>
      <div className={styles.container}>
        <Header currentRoute={currentRoute} className={styles.header} isMobile={isMobile}>
          {isMobile && (
            <div className={styles.siderTrigger} onClick={() => setCollapsed(!isCollapsed)}>
              {isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </div>
          )}
        </Header>
        <Layout.Content className={styles.content}>
          {/*<Outlet />*/}
          {children}
        </Layout.Content>
        <Footer />
      </div>
    </Layout>
  )
})
