import { Alert, Form, InputNumber } from 'antd'
import React from 'react'
import translations from '../translations'
import styles from '../PosFieldsForm.scss'

export const feesDataRegularForProducts = (
  subscriptionFee: number,
  editMode: boolean,
  editEnabled: boolean,
  isSME: boolean
) => {
  return [
    {
      key: '1',
      name: translations().feeSubscriptionPerMonth,
      disable: null,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Form.Item
                name='feeSubscriptionPerMonth'
                rules={
                  isSME
                    ? [
                        { required: true, message: translations().required },
                        {
                          type: 'number',
                          min: subscriptionFee ? Number(subscriptionFee) : 0,
                          message: translations().minValue(subscriptionFee ? Number(subscriptionFee) : 0)
                        }
                      ]
                    : []
                }
                validateTrigger={['onChange', 'onBlur']}
              >
                <InputNumber disabled={editMode ? !editEnabled : false} />
              </Form.Item>
            )
          }}
        </Form.Item>
      ),
      description: (
        <Alert
          className={styles.styledAlert}
          type='info'
          showIcon
          message={translations().infoNote}
          description={translations().monthlyFeeChargedTerminal}
        />
      )
    }
  ]
}

export const productColumns = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    width: 150
  },
  {
    title: '',
    dataIndex: 'value',
    key: 'value',
    width: 140,
    align: 'center' as 'center'
  },

  {
    title: '',
    dataIndex: 'description',
    key: 'description',
    width: 140
  }
]

export const terminalPurchaseData = (isChargeAbleByDNA: boolean) => {
  return [
    {
      key: '1',
      name: translations().terminalCost,
      disable: null,
      value: (
        <Form.Item
          label=''
          name='terminalCost'
          rules={[
            { required: true, message: translations().required },
            { type: 'number', min: 1, message: translations().notZero }
          ]}
          validateTrigger={['onChange', 'onBlur']}
        >
          <InputNumber className={styles.styledNumberInput} />
        </Form.Item>
      ),
      description: isChargeAbleByDNA ? (
        <Alert
          className={styles.styledAlert}
          type='info'
          showIcon
          message={translations().infoNote}
          description={translations().terminalConstNoteInfo}
        />
      ) : (
        ''
      )
    }
  ]
}
