import { Button, Card } from 'antd'
import { TableRowSelection } from 'antd/lib/table/interface'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { GroupableItem } from '~/code/models'
import { withAppStore } from '~/code/hocs/withAppStore'
import { hasPermissions, isPartner } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { BPMApplicationModel, OnboardingTabEnum } from '../../models'
import { Onboarding } from '../../Onboarding'
import { ProcessesTable, ProcessesFilter, TerminateProcessModal } from './components'
import translations from './translations'
import { ProcessesProps } from './props'
import styles from './Processes.scss'

const ProcessesView = observer((props: ProcessesProps) => {
  const { store, additionalStoreProcess = false } = props
  const { handbooksStore } = store

  const isTerminateAvailable = hasPermissions([PermissionMap.onboarding.processes.update])
  const isTerminateButtonVisible = store.selectedProcesses && store.selectedProcesses.length > 0 && isTerminateAvailable

  const isFilterVisible = !isPartner()

  useEffect(() => {
    if (!store.isInitiated) {
      store.init()
    }

    if (store.dataStore.additionalStore !== additionalStoreProcess) {
      store.resetFilter()
    }

    store.dataStore.setAdditionalStore(additionalStoreProcess)
    store.loadData(1)
  }, [])

  const rowSelection: TableRowSelection<GroupableItem<BPMApplicationModel>> = {
    hideSelectAll: true,
    selectedRowKeys: store.selectedProcesses.map(p => p.rowId),
    onChange: (selectedRowKeys: React.Key[], selectedRows: BPMApplicationModel[]) =>
      store.setSelectedProcesses(selectedRows),
    renderCell: (v, record, i, originNode) => !record.groupTitle && originNode
  }

  return (
    <Onboarding activeTab={OnboardingTabEnum.Processes} store={store} showItems={!additionalStoreProcess}>
      {isFilterVisible && (
        <div className={styles.wrapperWithTopMargin}>
          <Card>
            <ProcessesFilter store={store} handbooksStore={handbooksStore} className={styles.processesHeader} />
          </Card>
        </div>
      )}

      <div className={styles.wrapperWithTopMargin}>
        <Card>
          <ProcessesTable
            store={store}
            rowSelection={isTerminateAvailable ? rowSelection : null}
            actions={
              isTerminateButtonVisible && (
                <Button
                  danger
                  type='primary'
                  className={styles.terminateButton}
                  onClick={() => store.setTerminateModalVisible(true)}
                >
                  {translations().terminate}
                </Button>
              )
            }
            canGoToDetailsPage
            additionalStore={additionalStoreProcess}
          />
          <TerminateProcessModal store={store} />
        </Card>
      </div>
    </Onboarding>
  )
})

export const Processes = withAppStore(({ appStore: appStore }) => (
  <ProcessesView store={appStore.onboardingProcessesStore} handbooksStore={appStore.handbooksStore} />
))

export const ProcessesAdditionalStore = withAppStore(({ appStore: appStore }) => (
  <ProcessesView
    store={appStore.onboardingProcessesStore}
    handbooksStore={appStore.handbooksStore}
    additionalStoreProcess
  />
))
