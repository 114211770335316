import React from 'react'
import { Button } from 'antd'
import page from 'page'
import { Routes } from '~/code/startup/Router/Routes'
import translations from './translations'
import styles from '~/code/pages/settlements/SettlementsStatement/components/SettlementsFilters/SettlementsFilters.scss'

translations.add('en', {
  errLoadingAcquisitionChannels: 'Error loading acquisition channels',
  errLoadingAccountNumbers: 'Error loading account numbers',
  errDownloadingStatement: 'Error downloading statement',
  successDownloadingStatement: (
    <span className={styles.downloadAsyncMsg}>
      Your download request has been queued for processing. Once the document is ready, it can be downloaded from the{' '}
      <Button type='link' onClick={() => page(Routes.EXPORTS)}>
        Exports
      </Button>{' '}
      section.
    </span>
  ),
  selectMerchant: 'Select merchant',
  selectMerchantOrChannelFirst: 'Please select an acquisition channel or a merchant first',
  selectDatesFirst: 'Please select dates first',
  selectMerchantFirst: 'Please select a merchant first',
  noData: 'No Data',
  all: 'ALL'
})
