import { inject, injectable } from 'inversify'
import { v4 as uuid } from 'uuid'
import { message } from 'antd'
import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { isPartner, getAcquisitionChannel } from '~/code/services/auth'
import { InvoicesFilterStoreSymbol } from '~/code/pages/Billing/pages/Invoices/components'
import { IInvoicesFilterStore } from '~/code/pages/Billing/pages/Invoices/components/InvoicesFilter'
import { IInvoicesStore } from '~/code/pages/Billing/pages/Invoices/IInvoicesStore'
import { fetchInvoices } from '~/code/stores/invoicesStore/services/fetchers'
import { InvoicesDataSourceType } from '~/code/stores/invoicesStore/models'

@injectable()
export class InvoicesStore implements IInvoicesStore {
  invoiceFilterStore: IInvoicesFilterStore
  isInvoicesLoading: boolean = false
  invoicesDataSource: InvoicesDataSourceType[] = []

  constructor(@inject(InvoicesFilterStoreSymbol) invoiceFilterStore: IInvoicesFilterStore) {
    this.invoiceFilterStore = invoiceFilterStore
    makeObservable(this, {
      isInvoicesLoading: observable,
      invoicesDataSource: observable,
      refresh: action.bound
    })

    reaction(
      () => {
        const { rangePickerStore, merchantSelectStore, selectedStatus, mid, currentPage, size } =
          this.invoiceFilterStore

        return {
          startDate: rangePickerStore.startDate,
          endDate: rangePickerStore.endDate,
          status: selectedStatus,
          merchant: merchantSelectStore.merchant,
          acquisitionChannel: merchantSelectStore.acquisitionChannel,
          currentPage,
          size,
          mid
        }
      },
      () => this.loadInvoices()
    )
  }

  public refresh() {
    this.loadInvoices()
  }

  loadInvoices = async () => {
    runInAction(() => (this.isInvoicesLoading = true))

    const { startDate, endDate } = this.invoiceFilterStore.rangePickerStore
    const { merchant, acquisitionChannel } = this.invoiceFilterStore.merchantSelectStore

    const params = {
      from: startDate ? startDate.format('YYYY-MM-DD') : undefined,
      to: endDate ? endDate.format('YYYY-MM-DD') : undefined,
      page: this.invoiceFilterStore.currentPage,
      size: this.invoiceFilterStore.size,
      status: this.invoiceFilterStore.selectedStatus === 'all' ? undefined : this.invoiceFilterStore.selectedStatus,
      merchantId: merchant && merchant.id ? merchant.id : undefined,
      acquisitionChannel: isPartner() ? getAcquisitionChannel() : acquisitionChannel.id,
      mid: this.invoiceFilterStore.mid?.length ? this.invoiceFilterStore.mid : undefined
    }

    try {
      const { error, result } = await fetchInvoices(params)

      if (error) throw new Error(error.message)

      runInAction(() => {
        this.invoicesDataSource = result.data.map(invoice => ({ ...invoice, rowKey: uuid() }))
        this.invoiceFilterStore.total = result.totalCount
      })
    } catch (err) {
      message.error(err.message)
    } finally {
      runInAction(() => (this.isInvoicesLoading = false))
    }
  }
}
