import React from 'react'
import translations from './translations'
import { FieldType } from './../models/FieldType'

translations.add('en', {
    confirm: 'Confirm',
    cancel: 'Cancel',
    change: 'Change',
    fields: {
        dailyAmount: 'Daily Amount',
        dailyCount: 'Daily Count',
        dailyMaxRefundAmount: 'Daily Max Refund Amount',
        monthlyAmount: 'Monthly Amount',
        monthlyCount: 'Monthly Count',
        perRefund: 'Per Refund',
        perTransaction: 'Per Transaction',
        comment: 'Comment',
        perDevice: 'Per Device'
    },
    errors: {
        dailyAmount: (minAmount) => `Daily Amount must be at least ${ minAmount }`,
        dailyCount: (minCount) => `Daily Count must be at least ${ minCount }`,
        dailyMaxRefundAmount: (minAmount) => `Daily Max Refund Amount must be at least ${ minAmount }`,
        monthlyAmount: (minAmount) => `Monthly Amount must be at least ${ minAmount }`,
        monthlyCount: (minCount) => `Monthly Count must be at least ${ minCount }`,
        perRefund: (minAmount) => `Per Refund must be at least ${ minAmount }`,
        perTransaction: (minAmount) => `Per Transaction must be at least ${ minAmount }`,
        comment: (length) => `Comment must be at least ${ length } characters`,
        perDevice: (length) => `Per Device could be no more than ${length}`
    },
    changeItem: (el) => <div>- <b>{ translations().fields[el.key] }</b> from <b>{ el.type === FieldType.AMOUNT ? '£' : '' }{ el.oldValue }</b> to <b>{ el.type === FieldType.AMOUNT ? '£' : '' }{ el.newValue }</b></div>
})
