import { Collapse, Descriptions, Divider, Table } from 'antd'
import React from 'react'
import translations from './translations'
import { EcoSpendCheckResultsProps } from './props'
import { ecospenResultColumns } from './constants'

export const EcoSpendCheckResults = ({ data }: EcoSpendCheckResultsProps) => {
  return (
    <Collapse>
      {data?.map((item, indx) => (
        <Collapse.Panel header={`${translations().id}: ${item.processId}`} key={indx}>
          <Descriptions bordered column={1}>
            <Descriptions.Item label={translations().id}>{item.processId}</Descriptions.Item>
            <Descriptions.Item label={translations().reportDate}>
              {item.responseData?.verification?.reportCreationDate}
            </Descriptions.Item>
            <Descriptions.Item label={translations().emailAddress}>{item?.requestData?.emailAddress}</Descriptions.Item>
          </Descriptions>
          <Divider />

          {item.responseData?.verification?.data?.map((ver, v) => (
            <>
              <Table
                pagination={false}
                key={v}
                columns={ecospenResultColumns}
                dataSource={ver.results?.map((res, r) => ({
                  key: r,
                  sortCode: res?.identification?.substring(0, 6),
                  bankAccount: res?.identification?.substring(6),
                  accountName: res?.value,
                  bankName: res?.bankName,
                  overallScore: res?.percentage,
                  result: res?.result
                }))}
              />
            </>
          ))}
        </Collapse.Panel>
      ))}
    </Collapse>
  )
}
