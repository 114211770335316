import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import styles from './styles.scss'
import { PaginationContainerProps } from './props'

export const PaginationContainer: React.FC<PropsWithChildren<PaginationContainerProps>> = ({ position, children }) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.top]: position === 'top',
        [styles.bottom]: position === 'bottom'
      })}
    >
      {children}
    </div>
  )
}
