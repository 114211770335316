import React from 'react'
import { Col, Row, Typography } from 'antd'
import classNames from 'classnames'
import { LinkButtonGroupProps } from './props'
import styles from './LinkButtonGroup.scss'
import { withTooltip } from '~/code/hocs/withTooltip'

const { Link } = Typography

export const LinkButtonGroup: React.FC<LinkButtonGroupProps> = props => {
  const { links, selectedValue, onSelect, className } = props

  return (
    links && (
      <div className={classNames(styles.LinkButtonGroup, className)}>
        <Row>
          {links.map(link => {
            const { value, text, isDisabled, tooltipTitle } = link

            return withTooltip(
              <Col xs={12} sm={8} key={value}>
                <Link
                  key={value}
                  href='#'
                  className={classNames(
                    { [styles.active]: value === selectedValue },
                    { [styles.disabled]: isDisabled }
                  )}
                  onClick={e => {
                    if (isDisabled) return
                    e.preventDefault()
                    onSelect && onSelect(link)
                  }}
                >
                  {text}
                </Link>
              </Col>,
              tooltipTitle,
              value
            )
          })}
        </Row>
      </div>
    )
  )
}
