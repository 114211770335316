import { v4 as uuid } from 'uuid'
import { OfficerProfile, OfficerRole } from '~/code/pages/StartProcess/models'
import { OnboardingOfficer } from '~/code/pages/StartProcess/models/onboarding/OnboardingOfficer'

export const getOfficerProfile = (officer: OnboardingOfficer, officerRole: OfficerRole): OfficerProfile => {
  const {
    firstName,
    surname,
    middleName,
    title,
    dateOfBirth,
    placeOfBirth,
    gender,
    nationality,
    occupation,
    emailAddress,
    telephoneNumber,
    residentialAddress,
    deletionAllowed,
    fromApi,
    officerVerificationResult,
    agreementSignatory,
    jumioCheckResults,
    jumioRequestSent,
    uploadDocManually,
    idDocumentNumber,
    idDocumentExpirationDate,
    vulnerability
  } = officer

  const { houseName, houseNumber, addressLine1, addressLine2, country, postalCode, townOrCity, countyOrState } =
    residentialAddress

  const res: OfficerProfile = {
    id: uuid(),
    deletionAllowed,
    nameElements: {
      surname,
      forename: firstName,
      middleName,
      title
    },
    gender,
    dateOfBirth,
    birthplace: placeOfBirth,
    nationality,
    address: {
      addressLine1,
      addressLine2,
      postalCode,
      locality: townOrCity,
      houseName,
      houseNumber,
      country,
      region: countyOrState
    },
    officerRole,
    occupation,
    emailAddress,
    telephoneNumber,
    fromApi: fromApi || false,
    officerVerificationResult,
    agreementSignatory,
    jumioCheckResults,
    jumioRequestSent,
    uploadDocManually,
    idDocumentNumber,
    idDocumentExpirationDate,
    vulnerability
  }

  if (officer?.ownershipPercentage > 0) {
    res.ownershipPercentage = officer.ownershipPercentage
  }

  if (officerRole === null) {
    delete res.officerRole
  }

  return res
}
