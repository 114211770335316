import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  ecoSpendError: string
  errorUploadingDocument: string
  errorDeletingDocument: string
  errCompletingApplication: string
  applicationSuccessful: string
  errTerminatingApplication: string
  applicationTerminationSuccess: string
  requestFail: string
  requestSuccess: string
}>()
