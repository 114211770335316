import { message } from 'antd'
import { inject, injectable } from 'inversify'
import { action, computed, makeObservable, observable, runInAction, reaction } from 'mobx'
import { log } from 'dna-common'
import { MerchantsManagementStoreSymbol } from '~/code/pages'
import { ItemModel } from '~/code/models/handbooks'
import { Routes } from '~/code/startup/Router/Routes'
import { goToRoute } from '~/code/startup/Router/utils'
import { AppStore } from '~/code/stores/AppStore'
import { ICloseMerchantStore } from '~/code/pages/MerchantsManagement/components/ClosureOfMerchant/ICloseMerchantStore'
import { ISalesForceSearchStore } from '~/code/pages/MerchantsManagement/components/SalesForceSearch/ISalesForceSearchStore'
import { startProcessCloseMerchantDossierV2, getMerchantClosureReasons } from './services/fetchers'
import {
  CloseMerchantDossierV2ReqType,
  ClosureMerchantDataType,
  SalesForceDataResType
} from './models/merchant-dossier-v2'
import { MerchantsManagementStore } from './MerchantsManagementStore'
import { SalesForceSearchStore } from './SalesForceSearchStore'
import { jsonType, stringType, doubleType, booleanType, closeMerchantOrStoreType } from './constants'
import translations from './translations'

@injectable()
export class CloseMerchantStore implements ICloseMerchantStore {
  merchantsManagementStore: MerchantsManagementStore
  isCloseMerchantLoading: boolean = false
  isModalOpen: boolean = false
  isClosureReasonsLoading: boolean = false
  closureReasonsList: ItemModel[] = []
  salesForceSearchStore: ISalesForceSearchStore
  salesForceDataFound: boolean = false
  salesForceData: SalesForceDataResType = null

  constructor(@inject(MerchantsManagementStoreSymbol) merchantsManagementStore: MerchantsManagementStore) {
    this.merchantsManagementStore = merchantsManagementStore
    this.salesForceSearchStore = new SalesForceSearchStore()
    makeObservable(this, {
      isCloseMerchantLoading: observable,
      isModalOpen: observable,
      isClosureReasonsLoading: observable,
      closureReasonsList: observable,
      salesForceDataFound: observable,
      salesForceData: observable,
      selectedMerchant: computed,
      isMerchantActive: computed,
      isSubsidiaryForFee: computed,
      isSubsidiaryForProcess: computed,
      legalName: computed,
      merchantMids: computed,
      closeMerchant: action,
      handleModalStatus: action,
      setSalesForceData: action,
      loadClosureReasons: action
    })
    this.init()

    reaction(
      () => this.salesForceSearchStore.salesForceDataResult,
      salesForceDataResult => {
        this.salesForceSearchStore.salesForceSuccessfulValidation
          ? this.setSalesForceData(true, salesForceDataResult)
          : this.setSalesForceData(false, null)
      }
    )
  }

  init() {
    this.loadClosureReasons()
  }

  setSalesForceData(isFound: boolean, salesForceData: SalesForceDataResType) {
    this.salesForceDataFound = isFound
    this.salesForceData = salesForceData
  }

  get selectedMerchant() {
    return this.merchantsManagementStore.selectedMerchant
  }

  get isMerchantActive() {
    return this.merchantsManagementStore.isMerchantActive
  }

  get tenantCode() {
    return this.merchantsManagementStore.companyFullDossierV2?.mainInfo?.tenantCode
  }

  get financialInstitute() {
    return this.merchantsManagementStore.companyFullDossierV2?.mainInfo?.financialInstitute
  }

  get isSubsidiaryForFee() {
    return this.merchantsManagementStore.isSubsidiaryForFee
  }

  get isSubsidiaryForProcess() {
    return this.merchantsManagementStore.isSubsidiaryForProcess
  }

  get legalName() {
    const { companyLegalName, companyName } = this.merchantsManagementStore.companyFullDossierV2?.mainInfo || {}
    return companyLegalName || companyName
  }

  get merchantMids() {
    return this.merchantsManagementStore.merchantMids
  }

  handleModalStatus(status: boolean): void {
    this.isModalOpen = status
  }

  async loadClosureReasons(): Promise<void> {
    try {
      runInAction(() => {
        this.isClosureReasonsLoading = true
      })
      const { status, error, result } = await getMerchantClosureReasons()
      if (status !== 200 || error) {
        message.error(error.message || translations().errorLoadingMerchantClosureReasons)
      } else {
        this.closureReasonsList = result.map(reason => {
          return { value: reason.key, label: reason.value }
        })
      }
    } catch (error) {
      message.error(translations().errorLoadingMerchantClosureReasons)
    } finally {
      runInAction(() => {
        this.isClosureReasonsLoading = false
      })
    }
  }

  createClosureMerchantData(data: ClosureMerchantDataType): CloseMerchantDossierV2ReqType {
    const { reason, comment, terminationFee, topLevelSubjectId, fullDossier, dsrId } = data
    const { email, phone, mainInfo, processing } = fullDossier
    return {
      variables: {
        legalEntity: {
          value: JSON.stringify({ email, phone, mainInfo, processing }),
          type: jsonType
        },
        reason: {
          value: reason,
          type: stringType
        },
        comment: {
          value: comment,
          type: stringType
        },
        initiatorEmail: {
          value: AppStore.authStore.email,
          type: stringType
        },
        topLevelSubjectId: {
          value: topLevelSubjectId.toString(),
          type: stringType
        },
        dossierId: {
          value: dsrId.toString(),
          type: stringType
        },
        terminationFee: {
          value: terminationFee,
          type: doubleType
        },
        subsidiariesMerchant: {
          value: this.isSubsidiaryForProcess,
          type: booleanType
        },
        caseIdSalesForce: {
          value: this.salesForceData?.id || '',
          type: stringType
        },
        processType: {
          value: closeMerchantOrStoreType.closeMerchant,
          type: stringType
        }
      }
    }
  }

  async closeMerchant(reason: string, comment: string, terminationFee: number): Promise<void> {
    try {
      runInAction(() => {
        this.isCloseMerchantLoading = true
      })
      const { topLevelSubjectId, dsrId } = this.merchantsManagementStore.companyFullDossierV2?.mainInfo

      if (!topLevelSubjectId) {
        message.error(translations().topLevelSubjectIdIsEmpty)
        return
      }

      if (!dsrId) {
        message.error(translations().dsrIdIsEmpty)
        return
      }

      const closeMerchantDossierV2Req = this.createClosureMerchantData({
        fullDossier: this.merchantsManagementStore.companyFullDossierV2,
        topLevelSubjectId,
        reason,
        comment,
        terminationFee,
        dsrId
      })
      const { status, error, result } = await startProcessCloseMerchantDossierV2(closeMerchantDossierV2Req)
      if (status !== 200 || error) {
        message.error(error.message || translations().errClosingMerchant)
      } else {
        message.success(result.message || translations().applicationSuccessful)
        this.handleModalStatus(false)
        goToRoute(Routes.BPM_PROCESSES_CLOSURE_OF_MERCHANT)
      }
    } catch (error) {
      message.error(error?.message || translations().errClosingMerchant)
      log(error)
    } finally {
      runInAction(() => {
        this.isCloseMerchantLoading = false
      })
    }
  }
}
