import React from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {Tag} from 'antd'

import translations from './translations'
import styles from './styles.scss'

export type TwoFALabelProps = {
    isEnabled: boolean
    isLoading: boolean
}

export const TwoFALabel: React.FC<TwoFALabelProps> = observer(({isEnabled, isLoading}) => {
    const color = isEnabled ? 'green' : 'grey'
    const text = isEnabled ? translations().activated : translations().disabled

    return (
        <Tag color={color} className={classNames(styles.root, {[styles.visible]: !isLoading})}>
            {text}
        </Tag>
    )
})