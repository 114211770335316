import { Form } from 'antd'
import React from 'react'
import { UKPrefix } from '~/code/constants/dictionaries'
import { PHONE_NUMBER_PATTERN } from '~/code/constants/Patterns'
import { PhoneNumberInput } from '../PhoneNumberInput'
import { PhoneNumberFormItemProps } from './props'
import translations from './translations'

export const PhoneNumberFormItem: React.FC<PhoneNumberFormItemProps> = props => {
  const {
    name = 'telephoneNumber',
    label = translations().phoneNumberLabel,
    requiredErrorMessage = translations().phoneNumberRequired,
    invalidErrorMessage = translations().phoneNumberWrongFormat,
    shouldCheckByData8,
    options,
    pattern,
    prefix,
    ...rest
  } = props



  const phonePattern = pattern ? new RegExp(pattern) : PHONE_NUMBER_PATTERN

  return (
    <Form.Item
      {...rest}
      name={name}
      label={label}
      rules={[
        { required: true, message: requiredErrorMessage },
        {
          validator: async (_, value) => {
            if (value) {
              if (!phonePattern.test(value)) {
                throw new Error(invalidErrorMessage)
              }
            }
          }
        }
      ]}
      validateTrigger={'onBlur'}
    >
      <PhoneNumberInput options={options} prefix={prefix || UKPrefix} />
    </Form.Item>
  )
}
