import React from 'react'
import classNames from 'classnames'
import { SectionBannerMobileProps } from './props'
import styles from './styles.scss'

export const SectionBannerMobile: React.FC<SectionBannerMobileProps> = ({ className, notificationSetupForm }) => {
  const { notificationLevelId, title, description } = notificationSetupForm

  const renderNotification = () => {
    if (!notificationLevelId) return null

    return (
      <div className={classNames(styles.notificationContainer, styles[notificationLevelId])}>
        <span className={styles.title}>{title}</span>
        <div className={styles.description}>{description}</div>
      </div>
    )
  }

  const renderTableItems = (rows: number) => (
    Array.from({ length: rows }, (_, index) => (
      <div className={styles.tableRow} key={index}>
        <div className={styles.tableItem}></div>
        <div className={classNames(styles.tableItem, styles.long)}></div>
        <div className={styles.tableItem}></div>
        <div className={styles.tableItem}></div>
      </div>
    ))
  )

  return (
    <div className={styles.previewWrapper}>
      <div className={classNames(styles.header, styles[className])}>
        <div className={styles.logo}>
          <div className={classNames(styles.circle, styles.yellow, styles.mrFive)}></div>
          <div className={styles.bar}></div>
        </div>
        <div className={styles.userInfo}>
          <div className={styles.bar}></div>
          <div className={classNames(styles.circle, styles.white, styles.mlFive)}></div>
        </div>
      </div>
      <div className={styles.notificationWrapper}>
        {renderNotification()}
      </div>
      <div className={styles.content}>
        <div className={styles.tableContainer}>
          <div className={styles.table}>
            <div className={styles.tableHead}>
              <div className={styles.headItem}></div>
            </div>
            {renderTableItems(5)}
            <div className={styles.tableHead}>
              <div className={styles.headItem}></div>
            </div>
            {renderTableItems(7)}
          </div>
        </div>
      </div>
    </div>
  )
}