import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    currentRiskLimits: string
    change: string
    dailyAmount: string
    dailyCount: string
    dailyMaxRefundAmount: string
    monthlyAmount: string
    monthlyCount: string
    perRefund: string
    perTransaction: string
    perDevice: string
    terminals: string
    terminalsRequired: string
    noActiveTerminals: string
}>()

