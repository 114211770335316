import translations from './translations'

translations.add('en', {
  startDate: 'Start Date',
  initiator: 'Initiator',
  merchant: 'Merchant',
  mid: 'Mid',
  sfCaseId: 'SF case ID',
  status: 'Status',
  assignedGroup: 'Assigned Group',
  assignedUser: 'Assigned User',
  reviewTime: 'Review Time',
  endDate: 'End Date',
  adjustmentType: 'Adjustment Type'
})
