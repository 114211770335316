import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  addLogin: string
  addMerchant: string
  emailAddress: string
  firstName: string
  lastName: string
  sendWelcomeEmail: string
  submit: string
  merchant: string
  merchantSubLabel: string
  noMerchants: string
  roles: string
  createdAt: string
  searchByMerchant: string
  searchByEmail: string
  actions: string
  emailSearchPlaceholder: string
  lastLoginDate: string
  status: string
  authMethod: string

  formErrors: {
    wrongEmailFormat: string
  }

  menu: {
    editAccess: string
    editUser: string
    editSecurity: string
    delete: string
  }
  deleteAlert: {
    login: {
      title: string
      text: string
    }

    merchant: {
      title: string
      text: string
    }

    okText: string
  }
  totalPagination: (range: number[], total: number) => string
}>()
