import { PortalAccessTabType, PartnerAccessTabKeyType } from '../types/PartnerAccessTabType'

import translations from '../translations'

export const tabs: PortalAccessTabType[] = [
  {
    key: PartnerAccessTabKeyType.partners,
    label: translations().searchByPartner
  },
  {
    key: PartnerAccessTabKeyType.logins,
    label: translations().searchByEmail
  }
]
