import { inject, injectable } from 'inversify'

import { SETTLEMENT_STATEMENTS_TEST_STORE_TYPES } from '~/code/pages/settlements/SettlementsStatement/symbols'
import { columnsTest } from '~/code/pages/settlements/SettlementsStatement/components/SettlementsTable/columns'
import { SettlementParams } from '~/code/pages/settlements/SettlementsStatement/components/SettlementsTable/models'
import { fetchSettlementTest } from '~/code/pages/settlements/SettlementsStatement/services'
import { BaseSettlementsFiltersStore } from '~/code/stores/SettlementsStatementStore/BaseSettlementsFiltersStore'

import { BaseSettlementsTableStore } from './BaseSettlementsTableStore'

// @ts-ignore
@injectable()
export class SettlementsTestTableStore extends BaseSettlementsTableStore {
  columns = columnsTest

  constructor(
    @inject(SETTLEMENT_STATEMENTS_TEST_STORE_TYPES.filter) settlementsFilterStore: BaseSettlementsFiltersStore
  ) {
    super(settlementsFilterStore)
  }

  protected async fetchSettlement(data: SettlementParams) {
    return await fetchSettlementTest(data)
  }
}
