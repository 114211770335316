import { Descriptions, Typography } from 'antd'
import React from 'react'
import { LexisNexisInfoProps } from './props'
import translations from './translations'

const { Link } = Typography

export const LexisNexisInfo = ({ data, store }: LexisNexisInfoProps) => {
  return (
    <>
      {data?.map((item, indx) => (
        <Descriptions bordered column={1}>
          <Descriptions.Item label={<b>{translations().firstName}</b>}>{item.firstName}</Descriptions.Item>
          <Descriptions.Item label={<b>{translations().surname}</b>}>{item.surname}</Descriptions.Item>
          <Descriptions.Item label={<b>{translations().result}</b>}>{item.result}</Descriptions.Item>
          <Descriptions.Item label={<b>{translations().score}</b>}>{item.score}</Descriptions.Item>
          <Descriptions.Item label={<b>{translations().reportPdf}</b>}>
            <Link
              onClick={() =>
                store.loadDocumentsData(
                  item.reportPdfUrl,
                  item.reportPdfUrl?.split('/')?.pop() || `${item.firstName}-${item.surname}.pdf`,
                  'lexisNexis'
                )
              }
            >
              {item.reportPdfUrl?.split('/')?.pop() || `${item.firstName}-${item.surname}.pdf`}
            </Link>
          </Descriptions.Item>
        </Descriptions>
      ))}
    </>
  )
}
