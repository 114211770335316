import { Descriptions } from 'antd'
import React from 'react'
import { ApplicantInfoPropsType } from './props'
import translations from './translations'

export const ApplicantInfo = ({ data }: ApplicantInfoPropsType) => {
  return (
    <Descriptions bordered column={1}>
      <Descriptions.Item
        label={<b>{translations().personalName}</b>}
      >{`${data.firstName} ${data.surname} ${data.middleName}`}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().email}</b>}>{data.emailAddress}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().telephoneNumber}</b>}>{data.telephoneNumber}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().acquisitionChannel}</b>}>
        {data.acquisitionChannel}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().opportunityId}</b>}>{data.opportunityId}</Descriptions.Item>
      <Descriptions.Item label={<b>{translations().initiatorEmail}</b>}>{data.initiatorEmail}</Descriptions.Item>
    </Descriptions>
  )
}
