import translations from './translations'

translations.add('en', {
    title: 'Business structure',
    soleTrader: 'Sole Trader',
    company: 'Limited Company',
    companyNameFormLabel: 'Your company',
    companyNameFormRequired: 'Please select a company',
    companyNameFormSubLabel: 'Select from the list or start typing in a search',
    companyIsNotActive: (status: string) => `You cannot select this company. The status of the company is '${status}'`,
    companyNameFormDescription: 'Service provided by Companies House UK',
    typeSomethingToSearch: 'Find company',
    next: 'Next',
    changePaymentSolution: 'Change payment solution',
    couldNotLoadCompanies: 'Could not load companies',
    companyTypeRequired: 'company type is required'
})
