import React from 'react'
import { observer } from 'mobx-react'
import { UserOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { AuthForm } from '~/code/components/auth/AuthForm'
import { AuthFormSubmitButton } from '~/code/components/auth/AuthFormSubmitButton'
import translations from './translations'
import { ResetPasswordProps } from './props'
import styles from './ResetPassword.scss'

export const ResetPassword = observer((props: ResetPasswordProps) => {
  const { store } = props
  return <AuthForm
    title={translations().title}
  >
    <p className={styles.text}>{translations().text}</p>

    <Form
      validateTrigger={[]}
      onFinish={({ email }) => {
        store.resetPassword(email?.toLocaleLowerCase())
      }}
    >
      <Form.Item
        name='email'
        rules={[
          { required: true, message: translations().emailRequired },
          { type: 'email', message: translations().emailInvalid }
        ]}
      >
        <Input
          size='large'
          prefix={<UserOutlined />}
          placeholder={translations().email}
          className='lowercase'
        />
      </Form.Item>

      <AuthFormSubmitButton loading={store.isLoading}>
        {translations().send}
      </AuthFormSubmitButton>

      <Button size='large' className={styles.cancel} onClick={store.cancelResetPassword}>
        {translations().cancel}
      </Button>
    </Form>
  </AuthForm>
})
