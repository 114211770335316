import { Space, Typography } from 'antd'
import React from 'react'
import translations from '../translations'

const { Link } = Typography

export const jumioDocsColumns = openDoc => [
  {
    title: translations().name,
    dataIndex: 'fileName',
    key: 'fileName'
  },
  {
    title: translations().type,
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: translations().link,
    dataIndex: 'filePath',
    key: 'filePath',
    render: (_, doc) => (
      <Space size='middle'>
        {<Link onClick={() => openDoc(doc.filePath, doc.fileName, 'jumio')}>{translations().download}</Link>}
      </Space>
    )
  }
]
