import translations from './translations'

translations.add('en', {
    overview: 'Overview',
    detailedView: 'Detailed View',

    statusTabs: {
        all: 'All',
        successful: 'Successful',
        failed: 'Failed',
        other: 'Other',

        responseCodes: 'Response Codes',
        cscErrors: 'CSC Errors',
        threeDsecureErrors: '3DS Errors'
    }
})
