import { Form } from 'antd'
import React from 'react'

const ReadOnly: React.FC<{ value?: string }> = props => (
  <span>{props.value}</span>
)

export const renderCell = (name: string[]) => {
  return (
    <Form.Item name={name} noStyle>
      <ReadOnly />
    </Form.Item>
  )
}
