import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  enforceTitle: string
  enforced: string
  enabled: string
  disabled: string
  cancel: string
  submit: string
  formFields: {
    email: string
    firstName: string
    lastName: string
  }
  security: string
  twoFA: string
  reasonPlaceholder: string
  formErrors: {
    reasonRequired: string
  }
  disableWarning: string
}>()
