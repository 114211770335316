import { observer } from 'mobx-react'
import React from 'react'
import { Alert, Divider } from 'antd'
import { BackButton } from '~/code/components'
import { isPCSCorporate } from '../../services'
import { CorporateDetailsForm } from '../CorporateDetailsForm'
import { DetailsForm } from '../DetailsForm'
import { BusinessOwners, Directors, Shareholders } from './components'
import { IndividualDetailsProps } from './props'
import translations from './translations'

export const IndividualDetails = observer(({ store }: IndividualDetailsProps) => {
  const { completeApplicationStore } = store

  const formProps = {
    cachedPhoneNumbers: [...store.dataStore.cachedPhoneNumbers],
    cachedEmails: [...store.dataStore.cachedEmails]
  }

  const detailsFormProps = {
    isSaveLoading: completeApplicationStore.isSaveLoading,
    isNextLoading: completeApplicationStore.isNextLoading,
    isVerifyOfficerLoading: completeApplicationStore.officerVerificationLoading,
    isCheckTimeoutDone: completeApplicationStore.isCheckTimeoutDone,
    onVerify: completeApplicationStore.verifyCompanyOfficer,
    settings: store.onboardingSettings,
    officerVerificationResult: store.completeApplicationStore.officerVerificationResult,
    showVerificationModal: completeApplicationStore.showIndivVerificationModal,
    setShowVerificationModal: completeApplicationStore.setShowIndivVerifModal,
    setOfficerVerificationResult: completeApplicationStore.setOfficerVerificationResult,
    onCancel: () => completeApplicationStore.selectOfficer(null),
    handleAgreementSignatoryChange: completeApplicationStore.handleAgreementSignatoryChange,
    sendJumioRequest: completeApplicationStore.sendJumioRequest,
    jumioRequestSent: completeApplicationStore.jumioRequestSent,
    setShowJumioVerificationModal: completeApplicationStore.setShowJumioVerificationModal,
    showJumioVerificationModal: completeApplicationStore.showJumioVerificationModal,
    jumioRequestLoading: completeApplicationStore.isJumioRequestLoading,
    setUploadDocManually: completeApplicationStore.setUploadDocManually,
    jumioResults: completeApplicationStore.jumioResults,
    resetChecks: completeApplicationStore.resetChecks,
    selectedOfficer: completeApplicationStore.selectedOfficer,
    checkJumioResults: completeApplicationStore.checkJumioResults,
    setJumioRequestSent: completeApplicationStore.setJumioRequestSent,
    checkForDuplicates: completeApplicationStore.checkForDuplicates,
    isJumioResultsLoading: completeApplicationStore.isJumioResultsLoading,
    setJumioResults: completeApplicationStore.setJumioResults,
    uploadDocsManually: completeApplicationStore.uploadDocManually,
    resendEmail: completeApplicationStore.resendJumioEmail,
    resendLoading: completeApplicationStore.isResendEmailLoading,
    isResendTimeoutDone: completeApplicationStore.isResendTimeoutDone,
    isEuroClient: completeApplicationStore.isEuroClient
  }

  const overviewFormProps = {
    isSaveLoading: completeApplicationStore.isSaveLoading,
    onSave: completeApplicationStore.saveOnOfficer,
    settings: store.onboardingSettings
  }

  if (['company', 'llp'].includes(completeApplicationStore.dataStore.companyType)) {
    return (
      <>
        {completeApplicationStore.selectedOfficer ? (
          <>
            <BackButton
              onClick={() => {
                store.completeApplicationStore.selectOfficer(null)
                completeApplicationStore.resetChecks()
              }}
            >
              {translations().back}
            </BackButton>
            {isPCSCorporate(completeApplicationStore.selectedOfficer) ? (
              <CorporateDetailsForm
                data={completeApplicationStore.selectedOfficer}
                onSubmit={completeApplicationStore.handleSubmitShareholder}
                isSaveLoading={completeApplicationStore.isSaveLoading}
                onSave={completeApplicationStore.handleSaveShareholder}
              />
            ) : (
              <DetailsForm
                title={
                  completeApplicationStore.selectedOfficer?.hasOwnProperty('officerRole')
                    ? translations().director
                    : translations().shareholder
                }
                shareholder={!completeApplicationStore.selectedOfficer?.hasOwnProperty('officerRole')}
                data={completeApplicationStore.selectedOfficer}
                onSubmit={o =>
                  completeApplicationStore.selectedOfficer?.hasOwnProperty('officerRole')
                    ? completeApplicationStore.handleSubmitOfficer(o)
                    : completeApplicationStore.handleSubmitShareholder(o)
                }
                {...formProps}
                {...detailsFormProps}
                onSave={o =>
                  completeApplicationStore.selectedOfficer?.hasOwnProperty('officerRole')
                    ? completeApplicationStore.handleSaveOfficer(o)
                    : completeApplicationStore.handleSaveShareholder(o)
                }
              />
            )}
          </>
        ) : (
          <>
            {!store.dataStore.msaSignerSelected && (
              <>
                <Alert type='warning' message={translations().signeeNotFound} />
                <Divider />
              </>
            )}
            <Shareholders
              shareholders={completeApplicationStore.dataStore.shareholders}
              deleteShareholder={completeApplicationStore.dataStore.deleteShareholder}
              selectShareholder={completeApplicationStore.selectShareholder}
              onContinue={() => completeApplicationStore.selectTab('authorisedSignatories')}
              settings={store.onboardingSettings}
            />
            <Divider />

            <Directors
              directors={completeApplicationStore.dataStore.directors}
              deleteOfficer={completeApplicationStore.dataStore.deleteOfficer}
              selectOfficer={val => {
                const check = val.id ? true : completeApplicationStore.checkCount('add')
                check && completeApplicationStore.selectOfficer(val)
              }}
              onContinue={() => {
                completeApplicationStore.checkCount('next') && completeApplicationStore.selectTab('shareholders')
              }}
              {...overviewFormProps}
            />
          </>
        )}
      </>
    )
  } else {
    return (
      <>
        {completeApplicationStore.selectedOfficer ? (
          <>
            <BackButton onClick={() => store.completeApplicationStore.selectOfficer(null)}>
              {translations().back}
            </BackButton>
            <DetailsForm
              data={completeApplicationStore.selectedOfficer}
              onSubmit={completeApplicationStore.handleSubmitBusinessOwner}
              title={translations().businessOwner}
              {...formProps}
              {...detailsFormProps}
              setAgreementSignatoryByDefault={
                store.dataStore.application?.companyOfficerList?.length === 0 && !store.dataStore?.agreementOfficer
              }
              onSave={completeApplicationStore.handleSaveBusinessOwner}
              isNextLoading={completeApplicationStore.isNextLoading}
            />
          </>
        ) : (
          <>
            {!store.dataStore.msaSignerSelected && (
              <>
                <Alert type='warning' message={translations().signeeNotFound} />
                <Divider />
              </>
            )}

            <BusinessOwners
              owners={completeApplicationStore.dataStore.businessOwners}
              deleteOfficer={completeApplicationStore.dataStore.deleteOfficer}
              selectOfficer={val => {
                const check = val.id ? true : completeApplicationStore.checkCount('add')
                check && completeApplicationStore.selectOfficer(val)
              }}
              {...overviewFormProps}
            />
          </>
        )}
      </>
    )
  }
})
