import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Form, Button, Alert, Input, Row, Col } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { SalesForceSearchProps } from './props'
import translations from './translations'
import styles from './SalesForceSearch.scss'

export const SalesForceSearchStoreSymbol = Symbol('SalesForceSearchStore')
export const SalesForceSearch = observer((props: SalesForceSearchProps) => {
  const [salesForceCaseId, setSalesForceCaseId] = useState('')
  const { midsList, store } = props
  const [form] = useForm()

  useEffect(() => {
    store.clearSalesForceData(form)
  }, [midsList])

  return (
    <Form form={form}>
      <Row gutter={16}>
        <Col xs={24} md={14}>
          <Form.Item
            name='salesForceCaseIdSearch'
            label={translations().salesForceCaseId}
            rules={[{ required: true, message: translations().required }]}
            validateTrigger={['onBlur']}
          >
            <Input value={salesForceCaseId} onChange={e => setSalesForceCaseId(e.target.value)} />
          </Form.Item>
        </Col>
        <Col xs={24} md={10}>
          <Form.Item>
            <Button
              type='primary'
              loading={store.isSalesForceDataLoading}
              onClick={() => {
                store.loadSalesForceData(form.getFieldValue('salesForceCaseIdSearch'), form, midsList)
              }}
            >
              {translations().search}
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={14}>
          <Form.Item
            name='salesForceCaseId'
            label={translations().salesForceCaseId}
            rules={[{ required: true, message: translations().required }]}
            validateTrigger={['onBlur']}
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      {!store.midsAreEqual && (
        <div className={styles.alertMarginButtom}>
          <Alert
            message={translations().midsAreNotEqual}
            description={translations().midsAreNotEqualInstruction}
            type='warning'
            showIcon
          />
        </div>
      )}
      {store.noSalesForceDataFound && (
        <div className={styles.alertMarginButtom}>
          <Alert
            message={translations().noSalesForceDataFound}
            description={translations().noSalesForceDataFoundInstruction}
            type='warning'
            showIcon
          />
        </div>
      )}
      {store.salesForceSuccessfulValidation && (
        <div className={styles.alertMarginButtom}>
          <Alert
            message={translations().salesForceDataFound}
            description={translations().salesForceDataFoundInstruction}
            type='success'
            showIcon
          />
        </div>
      )}
    </Form>
  )
})
