import { JumioShortResultsDataType, OfficerAddress, VulnerabilitesDataType } from '.'
import { getEmptyAddress } from '../services'
import { BirthDateModel } from './BirthDateModel'
import { VerifyOfficerResponseType } from './verification'

export enum OfficerRole {
  authorisedSignatory = 'authorised-signatory',
  director = 'director',
  businessOwner = 'business-owner'
}

export class OfficerProfile {
  deletionAllowed?: boolean
  id?: string
  name?: string = ''
  nameElements?: {
    surname?: string
    forename?: string
    middleName?: string
    title?: string
  }
  gender?: string = undefined
  birthplace?: string = undefined
  nationality?: string = undefined
  dateOfBirth?: BirthDateModel = null
  officerRole?: OfficerRole
  occupation?: string = ''
  address?: OfficerAddress
  emailAddress?: string = ''
  telephoneNumber?: string = ''
  ownershipPercentage?: number = 0
  fromApi?: boolean = false
  officerVerificationResult?: VerifyOfficerResponseType
  agreementSignatory?: boolean
  jumioRequestSent?: boolean
  jumioCheckResults?: JumioShortResultsDataType
  uploadDocManually?: boolean
  idDocumentNumber?: string
  idDocumentExpirationDate?: string
  vulnerability?: VulnerabilitesDataType

  constructor(initialValue: OfficerProfile = {}) {
    Object.assign(
      this,
      {
        nameElements: {
          surname: '',
          forename: '',
          middleName: '',
          title: undefined
        },
        address: getEmptyAddress()
      },
      initialValue
    )
  }
}
