import { action, computed, makeObservable } from 'mobx'
import { ChangeRiskLimitsStore } from './ChangeRiskLimitsStore'
import { getChangedValue, hasEmptyProperty } from '~/code/pages/MerchantsManagement/components/RiskLimits/components/ChangeRiskLimitsModal/services'
import { inject, injectable } from 'inversify'
import { RiskLimitsChangeDossierV2Req, RiskLimitsType } from './models/merchant-dossier-v2'
import { startRiskLimitsDossierV2ChangeProcess } from '~/code/stores/MerchantsManagementStore/services/fetchers'
import { MerchantsManagementStoreSymbol } from '~/code/pages'
import { MerchantsManagementStore } from './MerchantsManagementStore'
import { AppStore } from '~/code/stores/AppStore'
import { RiskLimitsStoreSymbol } from '~/code/pages/MerchantsManagement/components'
import { RiskLimitsStore } from './RiskLimitsStore'
import { convertLimitFieldsToStartProc } from '~/code/pages/MerchantsManagement/components/RiskLimits/components/ChangeRiskLimitsModal/services'
import { message } from 'antd'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import { ChangeRiskLimitsStoreSymbol } from '~/code/pages/MerchantsManagement/components/RiskLimits/components/ChangeRiskLimitsModal/ChangeRiskLimitsModal'
import translations from './translations'

@injectable()
export class StartChangeRiskLimitsStore {
    changeRiskLimitsStore: ChangeRiskLimitsStore
    merchantsManagementStore: MerchantsManagementStore
    riskLimitsStore: RiskLimitsStore

    constructor(
        @inject(ChangeRiskLimitsStoreSymbol) changeRiskLimitsStore : ChangeRiskLimitsStore,
        @inject(MerchantsManagementStoreSymbol) merchantsManagementStore : MerchantsManagementStore,
        @inject(RiskLimitsStoreSymbol) riskLimitsStore : RiskLimitsStore,
    ) {
        makeObservable(this, {
            isActiveConfirm: computed,
            closeChangeModal: action.bound,
            onFormSubmit: action.bound
        })
        this.changeRiskLimitsStore = changeRiskLimitsStore
        this.merchantsManagementStore = merchantsManagementStore
        this.riskLimitsStore = riskLimitsStore
    }

    public closeChangeModal() {
        this.changeRiskLimitsStore.closeModal()
    }

    public get isActiveConfirm(): boolean {
        const store = this.changeRiskLimitsStore            
        return store.initialValues &&
            store.currentValues &&
            store.comment && store.comment.length > 1 &&
            getChangedValue(store.initialValues, store.currentValues).length > 0 && !hasEmptyProperty(store.currentValues)
    }

    public get riskLimits(): RiskLimitsType {
        return this.merchantsManagementStore.riskLimits
    }
    
    changeRiskLimits = async (application: RiskLimitsChangeDossierV2Req): Promise<boolean> => {
        const { status } = await startRiskLimitsDossierV2ChangeProcess(application)
        return status === 200
    }

    public async onFormSubmit(values) {
        this.changeRiskLimitsStore.startLoading()
        const isChangedRiskLimits = await this.changeRiskLimits({
            variables: {
                application: {
                    type: 'Json',
                    value: JSON.stringify({
                        dossierId: this.merchantsManagementStore.companyFullDossierV2?.mainInfo?.topLevelSubjectId.toString(),
                        initiatorEmail: AppStore.authStore.email,
                        terminals: this.riskLimitsStore.terminalIds,
                        riskLimits: convertLimitFieldsToStartProc(values, this.riskLimits)
                    })
                },
                comment: {
                    type: 'String',
                    value: values.comment
                }
            }
        })
        this.changeRiskLimitsStore.stopLoading()
        if (isChangedRiskLimits) {
            this.closeChangeModal()
            message.success(translations().submitNewRequestSuccess)
            return goToRoute(Routes.BPM_PROCESSES_SUBMITTED_REQUESTS)
        }
        message.error(translations().submitNewRequestError)
    }
}
