import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  title: string
  text: (isDisabled: boolean, count: number, reSend: () => void) => React.ReactNode
  code: string
  codeRequired: string
  confirm: string
  back: string
  emailWasSentTo: (email: string) => JSX.Element
}>()

