import { OfficerProfile } from '../../../models'
import { convertDateObjectToString } from '../../../services'
import merge from 'lodash/merge'

export const convertDataToFormValue = (
  data: OfficerProfile,
  excludeKeys?: string[],
  setAgreementByDefault?: boolean
) => {
  const res = merge(new OfficerProfile(), data, {
    dateOfBirth: convertDateObjectToString(data?.dateOfBirth)
  })

  if (setAgreementByDefault) {
    res.agreementSignatory = true
  }

  if (excludeKeys?.length > 0) {
    excludeKeys.forEach(key => {
      delete res[key]
    })
  }
  return res
}
