import React from 'react'
import numeral from 'numeral'
import { HorizontalBar, SumCountCard } from '../../chart-related'
import translations from './translations'
import { MerchantsBreakdownProps } from './props'

export const MerchantsBreakdown = ({loadingSum, loadingCount, data, currencySymbol, statusTabs}: MerchantsBreakdownProps) => {
    const renderContent = (dataType, status, _loading) => {
        if (_loading) {
            return false
        }
        return (
            <div>
                <HorizontalBar
                    data={data?.[dataType]?.[status]}
                    valueNumberFormat={dataType === 'count' ? '0,00' : '0.00a'}
                    renderTooltipLabel={(label, value) => {
                        const currency = dataType === 'count' ? '' : (currencySymbol || '£')
                        const format = dataType === 'count' ? '0,00' : '0,0.00'
                        return  label + ': ' + currency + numeral(value).format(format)
                    }}
                />
            </div>
        )
    }

    const renderSumContent = (status) => {
        return renderContent('amount', status, loadingSum)
    }

    const renderCountContent = (status) => {
        return renderContent('count', status, loadingCount)
    }

    return (
        <SumCountCard
            loadingSum={loadingSum}
            loadingCount={loadingCount}
            title={translations().merchants}
            renderSumContent={renderSumContent}
            renderCountContent={renderCountContent}
            statusTabs={statusTabs}
            currencySymbol={currencySymbol}
        />
    )
}
