import translations from './translations'

translations.add('en', {
    all: 'All',
    selectAll: 'Select All',
    deselectAll: 'Deselect All',
    placeholder: 'Search here...',
    noData: 'No data',
    selected: (count) => `${count} Selected`
})

