import translations from './translations'
import React from 'react'

translations.add('en', {
    title: 'Contact info',
    firstName: 'First name',
    surname: 'Surname',
    firstNameRequired: 'first name is required',
    surnameRequired: 'surname is required',
    emailLabel: 'Email address',
    emailRequired: 'email is required',
    emailFormatIncorrect: 'invalid email',
    telephoneNumberLabel: 'Phone number',
    telephoneNumberRequired: 'phone number is required',
    telephoneNumberWrongFormat: 'invalid phone number',
    acquisitionChannel: 'Acquisition channel',
    acquisitionChannelRequired: 'acquisition channel is required',
    referrer: 'Sales ID',
    referrerRequired: 'sales ID is required',
    referrerPlaceholder: 'Sales ID',
    firstNameSubLabel: 'This information will be used as the Primary Contact.',
    emailSubLabel: 'This email will receive Merchant Portal access details.',
    applicationSourceLabel: 'Application source',
    applicationSourceRequired: 'application source is required',
    applicationAlreadyStarted: 'Application with this email has already started.',
    continue: 'Continue',
    salesforceOpportunityIDLabel: 'Salesforce opportunity ID',
    salesforceOpportunityIDRequired: 'salesforce opportunity ID is required',
    salesforceOpportunityIDMin: 'salesforce opportunity ID must be at least 15 characters'
})
