import { inject, injectable } from 'inversify'
import { action, makeObservable, observable, runInAction, reaction } from 'mobx'
import { notification } from 'antd'
import { getUserTrustedDevices, deleteUserTrustedDevices } from '~/code/stores/Profile/services/fetchers'
import { TwoFATrustedDeviceModel } from '~/code/stores/Profile/models'
import { TwoFADevicesStoreInterface } from './TwoFADevicesStoreInterface'
import { TwoFAModalStoreInterface } from '~/code/stores/Profile/TwoFAModalStore/TwoFAModalStoreInterface'
import { TwoFAModalStoreSymbol } from '~/code/pages/Profile/components/TwoFA/components/TwoFAModal'
import { TableFilterStore } from '../../TableFilterStore'
import { TableFetchParams } from '~/code/models'

import translations from './translations'

@injectable()
export class TwoFADevicesStore implements TwoFADevicesStoreInterface {
  private paginationSize: number = 5

  public tableStore: TableFilterStore<TwoFATrustedDeviceModel>
  public twoFAModalStore: TwoFAModalStoreInterface
  public isLoading: boolean = false
  public isVisible: boolean = false

  constructor(@inject(TwoFAModalStoreSymbol) twoFAModalStore: TwoFAModalStoreInterface) {
    this.twoFAModalStore = twoFAModalStore

    makeObservable(this, {
      isVisible: observable,
      isLoading: observable,
      setIsLoading: action.bound,
      setIsVisible: action.bound
    })

    this.tableStore = new TableFilterStore(this.fetchDevices.bind(this))
    this.tableStore.setPageSize(1, this.paginationSize)

    this.setIsVisible()

    reaction(
      () => ({
        isTrustedDeviceSelected: this.twoFAModalStore.isTrustedDeviceSelected,
        isEnabled: this.twoFAModalStore.twoFAStore.isEnabled
      }),
      async ({ isTrustedDeviceSelected, isEnabled }) =>
        runInAction(() => (this.isVisible = isTrustedDeviceSelected && isEnabled))
    )
  }

  async fetchDevices({ currentPage, pageSize }: TableFetchParams) {
    this.setIsLoading(true)

    const { result } = await getUserTrustedDevices(currentPage, pageSize)

    this.setIsLoading(false)

    return {
      data: result.data,
      total: result.totalCount
    }
  }

  deleteDevice = async (id: string) => {
    this.setIsLoading(true)

    try {
      await deleteUserTrustedDevices(id)
      await this.tableStore.loadData()

      notification.success({ message: translations().message.delete })
    } catch (error) {
      notification.error({ message: error })
    } finally {
      this.setIsLoading(false)
    }
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  setIsVisible = () => {
    this.isVisible = this.twoFAModalStore.isTrustedDeviceSelected && this.twoFAModalStore.twoFAStore.isEnabled
  }
}
