import translations from './translations'

translations.add('en', {
  title: 'Entity Type',
  soleTrader: 'Sole Trader',
  company: 'Limited Company',
  companyNameFormLabel: 'Your company',
  companyNameFormRequired: 'Please select a company',
  companyNameFormSubLabel: 'Start by typing in the Company’s name and select from the list',
  companyIsNotActive: (status: string) => `You cannot select this company. The status of the company is '${status}'`,
  companyNameFormDescription: 'Service provided by Companies House UK',
  typeSomethingToSearch: 'Find company',
  next: 'Next',
  changePaymentSolution: 'Change payment solution',
  couldNotLoadCompanies: 'Could not load companies',
  companyTypeRequired: 'company type is required',
  charityNumber: 'Registered charity number',
  directorsNumber: 'Number of Active Directors',
  partnersNumber: 'Number of Partners',
  charityComission: 'Charity Comission',
  processAlreadyStarted: (compNum: string) =>
    `Application with this Company ${compNum} was already submitted. To start a new one the previous one should be terminated. `,
  legalEntityPlaceholder: 'Choose Legal Entity',
  limitedCompany: 'Limited Company',
  llp: 'LLP',
  partnerShip: 'Partnership',
  charity: 'Charity',
  other: 'Other',
  description: 'Description',
  required: 'Required',
  otherDescription: 'If the legal form is “other”, please, provide a description',
  errorCompanyCheck: 'Error while checking company information. Please try again',
  tryAgain: 'Try again'
})
