import translations from './translations'

translations.add('en', {
    acquisitionChannelLabel: 'Application Source',
    applicantName: 'Customer Name',
    initiatorEmail: 'Initiator Email',
    email: 'Email',
    phoneNumber: 'Phone Number',
    processStatus: 'Status',
    startDate: 'Start Date',
    action: 'Action',
    continue: 'Continue',
    delete: 'Delete',
    errorLoadingApplications: 'Error loading applications',
    errorLoadingTheApplication: 'Error loading the application',
    errorDeletingTheApplication: 'Error deleting the application',
    deletedApplicationSuccessfully: 'Deleted application successfully',
    companyName: 'Company name'
})
