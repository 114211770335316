import translations from './translations'

translations.add('en', {
    review: 'Review',
    processName: 'Process Name',
    merchant: 'Merchant',
    assignedUser: 'Assigned User',
    assignedGroup: 'Assigned Group',
    startDate: 'Start Date',
    endDate: 'End Date',
    status: 'Status',
    reviewTime: 'Review Time',
    lessThanOneMinute: 'less than 1 minute',
    selectMerchant: 'Select Merchant',
    selectStatus: 'Select Status',
    holdRelease: 'Hold/Release Settlements',
    hold: 'Hold',
    release: 'Release Settlements'
})
