import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    errorLoadingEcomTransactionChannels: string
    errorLoadingPosTransactionChannels: string
    errorUpdatingTransactionChannel: string
    successUpdatingTransactionChannel: string
    errorFetchingConnectionDetails: string

}>()

