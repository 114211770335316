import { CurrencyModel } from '~/code/models/globalConfig'

export const currencies: CurrencyModel[] = [
    {
        type: 'GBP',
        symbol: '£'
    },
    {
        type: 'USD',
        symbol: '$'
    },
    {
        type: 'EUR',
        symbol: '€'
    }
]