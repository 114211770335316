import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { Form, Radio, Table, Row, Col, Button, Modal, Typography, FormInstance, Input, Skeleton } from 'antd'
import { TidsList } from '~/code/components'
import { NumberFormItem } from '~/code/components/NumberFormItem'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { hasPermissions } from '~/code/services/auth'
import { FormSubgroupTitle } from 'startapp/components'
import {
  TerminalTariffType,
  BlendedTariffEditTabTableType
} from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v2'
import { TariffGroupsType, TariffSettingsType } from '../../../Contract/models'
import { TariffTypeEnum } from '../../constants'
import { DateItem } from '../DateItem'
import { notCompositeTariffsList, descriptionPostfix, notEditableTariffs } from './constants'
import { childrenColumns, columnsBlended, confirmationTableColumns, generateInput } from './services/generator'
import { getScrollBehaviorOptions } from './services/getters'
import { IEditTariffsTableStore } from './IEditTariffsTableStore'
import { EditTariffsTableProps } from './props'
import translations from './translations'
import styles from './EditTariffsTable.scss'

const { TextArea } = Input

export const EditTariffsTableStoreSymbol = Symbol('EditTariffsTableStore')
export const EditTariffsTable = observer((props: EditTariffsTableProps) => {
  const { terminal, contractTariffs, product, terminalList, terminalGroup } = { ...props }
  const store = useInjection<IEditTariffsTableStore>(EditTariffsTableStoreSymbol)
  const [formFees] = Form.useForm()
  const [formConfirmation] = Form.useForm()
  const [state, setState] = useState({
    terminalTariffsList: [],
    tariffType: TariffTypeEnum.blended,
    fieldsValue: {},
    tabSettings: [],
    valuesList: {},
    dnaFees: [],
    isDataLoading: false
  })

  useEffect(() => {
    formFees.resetFields()
    fetchData()
  }, [contractTariffs])

  const fetchData = async () => {
    try {
      setState(prev => ({ ...prev, isDataLoading: true }))
      const tariffType = store.getTariffType(terminal)
      await store.getData(product, terminalGroup)
      const { tabSettings, fieldsList } = store.generateFieldsList(tariffType, product)
      const valuesList = store.generateValuesList(terminal, contractTariffs, fieldsList)
      const fieldsValue = store.generateFormValuesList(fieldsList, valuesList)
      const terminalTariffsList = store.getBlendedTariffs(terminal, product, fieldsValue)
      const dnaFees = store.getDNAFees(product, state.valuesList)
      setState(prev => ({
        ...prev,
        tariffType,
        fieldsValue,
        tabSettings,
        valuesList,
        terminalTariffsList,
        dnaFees,
        isDataLoading: false
      }))
      formFees.setFieldsValue(fieldsValue)
    } catch (error) {
      setState(prev => ({ ...prev, isDataLoading: false }))
    }
  }

  const tariffLabel = (settingsItem: TariffSettingsType) => {
    return notCompositeTariffsList.includes(settingsItem.interchangeFee.key)
      ? settingsItem.interchangeFee.key
      : [settingsItem.interchangeFee.key, settingsItem.interchangeFee.components]
  }

  const isDisabled = !hasPermissions([PermissionMap.merchants.processes.tariff.read])

  const confirm = (formFees: FormInstance, finalValuesList: TerminalTariffType) => {
    Modal.confirm({
      content: (
        <>
          <Form form={formConfirmation} labelAlign='left'>
            <Typography.Title level={4}>{translations().confirmationMessage}</Typography.Title>
            <br />
            <Typography.Title level={5}>{store.selectedMidStoreInfo}</Typography.Title>
            <br />
            <Table
              dataSource={Object.entries(finalValuesList).map(valueItem => {
                const tariffName = valueItem[0]
                const tariffValue = valueItem[1]
                return {
                  key: valueItem[0],
                  oldValues: generateInput(tariffName, state.fieldsValue[tariffName]),
                  newValues: generateInput(tariffName, tariffValue),
                  tariffName: translations()[tariffName]
                }
              })}
              columns={confirmationTableColumns}
            />
            <Form.Item key={'comment'} name={'comment'}>
              <TextArea placeholder={translations().comment} />
            </Form.Item>
          </Form>
        </>
      ),
      width: '60%',
      onOk: () =>
        store.startEditTerminalTariffs(
          state.fieldsValue,
          formFees,
          terminalList.join(','),
          formConfirmation,
          state.tariffType
        ),
      onCancel: () => formConfirmation.resetFields()
    })
  }

  return state.isDataLoading ? (
    <Skeleton active />
  ) : (
    <div className={styles.feesForm}>
      <Form
        form={formFees}
        labelCol={{ span: 24 }}
        initialValues={state.fieldsValue}
        labelAlign='left'
        scrollToFirstError
      >
        <Form.Item className={styles.displayGrid}>
          <Radio.Group value={state.tariffType} className={styles.radioGroup}>
            <Radio id='blended' value={TariffTypeEnum.blended} checked>
              {translations().blended}
            </Radio>
            <Radio id='icPlus' value={TariffTypeEnum.icPlus} disabled>
              {translations().icPlus}
            </Radio>
          </Radio.Group>
          {translations().tids}
          <TidsList terminalList={terminalList}></TidsList>
          {product === 'POS' && (
            <div>
              {translations().settlementPeriod}: {store.posSettlementPeriod}
            </div>
          )}
          {product === 'ECOM' && (
            <div>
              {translations().settlementPeriod}: {store.ecomSettlementPeriod}
            </div>
          )}
        </Form.Item>
        {state.tariffType === TariffTypeEnum.blended && !store.isEuroClient && (
          <Table
            pagination={false}
            dataSource={state.terminalTariffsList}
            columns={columnsBlended(isDisabled)}
            rowClassName={(row: BlendedTariffEditTabTableType) =>
              row.serviceAreaCode === 'uk' || row.serviceAreaCode === 'international'
                ? styles.internationalTariffsColor
                : null
            }
          />
        )}
        {state.tariffType === TariffTypeEnum.icPlus && (
          <div className={styles.headlineRateFormContainer}>
            <FormSubgroupTitle>{translations().headlineRate}</FormSubgroupTitle>
            {state.dnaFees.map((items, idx) => (
              <Row key={idx} gutter={16}>
                {items.map((item, colIdx) => (
                  <Col key={colIdx} xs={24} md={12} lg={6}>
                    <NumberFormItem
                      name={[item.code, item.component]}
                      label={translations()[item.code]}
                      placeholder={translations().percentPlaceholder}
                      initialValue={state.fieldsValue?.[item.code]?.[item.component]}
                      showValueChangedMsg={true}
                      max={item.component === 'base' ? item.maxBaseFee : item.maxPercentFee}
                      min={item.component === 'base' ? item.minBaseFee : item.minPercentFee}
                      disabled={isDisabled}
                    />
                  </Col>
                ))}
              </Row>
            ))}
          </div>
        )}
        {state.tabSettings.map((tariffType, idxSetting) => {
          return tariffType.groups
            ?.filter((item: TariffGroupsType) => item.group.code !== 'termianlTariffs' && item.group.code !== 'dnaFee')
            .map((groupItem: TariffGroupsType, idxTariff: number) => {
              return (
                <div key={`${idxSetting}${idxTariff}`}>
                  <div className={styles.tableHeader}>{groupItem.group.value}</div>
                  <Table
                    showHeader={false}
                    bordered
                    pagination={false}
                    columns={childrenColumns}
                    dataSource={groupItem.tariffSettings.map((settingsItem: TariffSettingsType, idx: number) => {
                      return {
                        key: idx,
                        name: `${translations()[settingsItem.interchangeFee.key]} ${
                          settingsItem.forContract ? '*' : ''
                        }`,
                        value: (
                          <>
                            {settingsItem.interchangeFee.components === 'all' ? (
                              <Row gutter={[16, 0]}>
                                <Col xs={12}>
                                  <NumberFormItem
                                    name={[settingsItem.interchangeFee.key, 'base']}
                                    placeholder={translations().zeroPlaceholder}
                                    initialValue={state.fieldsValue?.[settingsItem.interchangeFee.key]?.['base']}
                                    showValueChangedMsg={true}
                                    label={
                                      groupItem.group.code === 'otherPaymentSchemesFees' || store.isEuroClient
                                        ? translations().baseLabel
                                        : null
                                    }
                                    max={settingsItem.interchangeFee.maxBaseFee}
                                    min={settingsItem.interchangeFee.minBaseFee}
                                    disabled={
                                      notEditableTariffs.includes(settingsItem.interchangeFee.key) ||
                                      store.isEuroClient ||
                                      isDisabled
                                    }
                                  />
                                </Col>
                                <Col xs={12}>
                                  <NumberFormItem
                                    name={[settingsItem.interchangeFee.key, 'percent']}
                                    placeholder={translations().zeroPlaceholder}
                                    initialValue={state.fieldsValue?.[settingsItem.interchangeFee.key]?.['percent']}
                                    showValueChangedMsg={true}
                                    label={
                                      groupItem.group.code === 'otherPaymentSchemesFees' || store.isEuroClient
                                        ? translations().percentLabel
                                        : null
                                    }
                                    max={settingsItem.interchangeFee.maxPercentFee}
                                    min={settingsItem.interchangeFee.minPercentFee}
                                    disabled={
                                      notEditableTariffs.includes(settingsItem.interchangeFee.key) ||
                                      store.isEuroClient ||
                                      isDisabled
                                    }
                                  />
                                </Col>
                              </Row>
                            ) : (
                              <Row gutter={[16, 0]}>
                                <Col
                                  xs={tariffLabel(settingsItem) === 'paymentTerminalSupportAndTransactionFee' ? 12 : 24}
                                >
                                  <NumberFormItem
                                    label={
                                      store.isEuroClient
                                        ? settingsItem.interchangeFee.components === 'base'
                                          ? translations().baseLabel
                                          : translations().percentLabel
                                        : null
                                    }
                                    name={tariffLabel(settingsItem)}
                                    placeholder={translations().zeroPlaceholder}
                                    initialValue={
                                      notCompositeTariffsList.includes(settingsItem.interchangeFee.key)
                                        ? state.fieldsValue?.[settingsItem.interchangeFee.key]
                                        : state.fieldsValue?.[settingsItem.interchangeFee.key]?.[
                                            settingsItem.interchangeFee.components
                                          ]
                                    }
                                    showValueChangedMsg={true}
                                    max={
                                      settingsItem.interchangeFee.components === 'base'
                                        ? settingsItem.interchangeFee.maxBaseFee
                                        : settingsItem.interchangeFee.maxPercentFee
                                    }
                                    min={
                                      settingsItem.interchangeFee.components === 'base'
                                        ? settingsItem.interchangeFee.minBaseFee
                                        : settingsItem.interchangeFee.minPercentFee
                                    }
                                    disabled={store.isEuroClient || isDisabled}
                                  />
                                </Col>
                                <Col xs={12}>
                                  <Form.Item shouldUpdate noStyle>
                                    {tariffLabel(settingsItem) === 'paymentTerminalSupportAndTransactionFee' && (
                                      <DateItem
                                        name='effectiveFrom'
                                        initialValue={state.fieldsValue?.['effectiveFrom'] || null}
                                        showValueChangedMsg={true}
                                        disabled={isDisabled}
                                      />
                                    )}
                                  </Form.Item>
                                </Col>
                              </Row>
                            )}
                          </>
                        ),
                        description: translations()[`${settingsItem.interchangeFee.key}${descriptionPostfix}`]
                      }
                    })}
                  />
                </div>
              )
            })
        })}
        {!store.isEuroClient && (
          <div className={styles.submitBtn}>
            <Button
              disabled={isDisabled}
              type='primary'
              loading={store.isEditTariffsIsLoading}
              onClick={() => {
                const errorsList = formFees.getFieldsError().filter(({ errors }) => errors.length)
                if (errorsList.length > 0) {
                  formFees.scrollToField(errorsList[0]?.name, getScrollBehaviorOptions())
                } else {
                  const { isFieldsChanged, finalValuesList } = store.hasValuesBeenChanged(state.fieldsValue, formFees)
                  if (isFieldsChanged) confirm(formFees, finalValuesList)
                }
              }}
            >
              {translations().save}
            </Button>
          </div>
        )}
      </Form>
      <div>
        <b>{translations().contractRates}</b>
      </div>
    </div>
  )
})
