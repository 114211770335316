import React from 'react'
import translations from './translations'

translations.add('en', {
    selectDatesFirst: 'Please select dates first',
    noData: 'No Data',
    all: 'ALL',
    couldNotSendToApprovalTheFollowing: (payments) => {
        const paymentsText = payments?.length > 1 ? 'payments' : 'payment'
        const documentIds = payments?.map(payment => payment.documentId).join(', ') || ''
        return <>Failed to send {payments?.length} {paymentsText} for approval with the following document IDs: <br/><span style={{ fontStyle: 'italic'}} >{documentIds}</span></>
    },
    failedToSendPaymentsForApproval: 'Failed to send the payments for approval. Please try again later',
    failedToApproveThePayments: 'Failed to approve the payments. Please try again later',
    negativeOrZeroApprove: 'Payment amount must be greater than 0'
})
