import React from 'react'
import {
  NotificationBehaviourEnum
} from '~/code/stores/NotificationManagementStore'
import {
  EntireSystemBlockWeb,
  LoginBannerWeb, PopupWeb, SectionBannerWeb, SectionBlockWeb
} from '~/code/pages/Notifications/ManageNotificationTemplates/components/WebNotificationPreview/components'
import { DARKBLUE, GREY, WHITE } from '~/code/pages/Notifications/ManageNotificationTemplates/constants'

export const WebNotificationPreview = ({isDashboard, previewTemplate, notificationSetupForm}) => {

  // const isDashboard = previewTemplate === NotificationBehaviourEnum.DASHBOARD_LOGIN_BANNER ||
  //   previewTemplate === NotificationBehaviourEnum.DASHBOARD_SECTION_BANNER ||
  //   previewTemplate === NotificationBehaviourEnum.DASHBOARD_POPUP ||
  //   previewTemplate === NotificationBehaviourEnum.DASHBOARD_SECTION_BLOCK ||
  //   previewTemplate === NotificationBehaviourEnum.DASHBOARD_BLOCK

  const renderPreview = () => {
    switch (previewTemplate) {
      case NotificationBehaviourEnum.DASHBOARD_LOGIN_BANNER:
      case NotificationBehaviourEnum.MP_LOGIN_BANNER:
        return <LoginBannerWeb
          className={isDashboard ? DARKBLUE : GREY}
          notificationSetupForm={notificationSetupForm}
        />
      case NotificationBehaviourEnum.DASHBOARD_SECTION_BANNER:
      case NotificationBehaviourEnum.MP_SECTION_BANNER:
        return <SectionBannerWeb
          className={isDashboard ? DARKBLUE : WHITE}
          notificationSetupForm={notificationSetupForm}
        />
      case NotificationBehaviourEnum.DASHBOARD_POPUP:
      case NotificationBehaviourEnum.MP_POPUP:
        return <PopupWeb
          className={isDashboard ? DARKBLUE : WHITE}
          notificationSetupForm={notificationSetupForm}
        />
      case NotificationBehaviourEnum.DASHBOARD_SECTION_BLOCK:
      case NotificationBehaviourEnum.MP_SECTION_BLOCK:
        return <SectionBlockWeb
          className={isDashboard ? DARKBLUE : WHITE}
          notificationSetupForm={notificationSetupForm}
        />
      case NotificationBehaviourEnum.DASHBOARD_BLOCK:
      case NotificationBehaviourEnum.MP_BLOCK:
        return <EntireSystemBlockWeb notificationSetupForm={notificationSetupForm}/>
      default: return <></>
    }
  }

  return (
    <>
      <div>
        {renderPreview()}
      </div>
    </>
  )
}
