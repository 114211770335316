import { Button, Space } from 'antd'
import React from 'react'
import { BelowActionsWithSaveProps } from './props'
import styles from './BelowActionsWithSave.scss'
import translations from './translations'
import classNames from 'classnames'

export const BelowActionsWithSave = (props: BelowActionsWithSaveProps) => {
    const { children, large = false, isLoading, onSave } = props

    return <Space className={styles.container}>
        <Button loading={isLoading} onClick={onSave} className={classNames({ [styles.saveButton__large]: large })}>{translations().save}</Button>
        {children}
    </Space>
}