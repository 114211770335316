import React, { useState } from 'react'
import { Button, Card, Col, Divider, Form, Input, Row, Select } from 'antd'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { useForm } from 'antd/es/form/Form'
import { MerchantSelect } from '~/code/components'
import { MonthPickerContainer } from '~/code/containers'
import { IInvoicesFilterStore } from '~/code/pages/Billing/pages/Invoices/components/InvoicesFilter'
import translations from '../../translations'
import styles from './styles.scss'

export const MerchantSelectStoreInvoicesSymbol = Symbol('MerchantSelectStoreInvoices')
export const RangePickerInvoicesStoreSymbol = Symbol('RangePickerInvoicesStore')
export const InvoicesFilterStoreSymbol = Symbol('InvoicesFilterStore')

export const InvoicesFilter = observer(() => {
  const [form] = useForm()
  const [prevMid, setPrevMid] = useState('')

  const { selectedStatus, statuses, selectStatus, mid, setMid, resetFilter } =
    useInjection<IInvoicesFilterStore>(InvoicesFilterStoreSymbol)

  const onEnterPress = event => {
    const target = event.target as HTMLInputElement
    setMid(target.value)
  }

  const onFilterBlur = () => {
    const mid = form.getFieldValue('mid') || ''
    if (prevMid === mid) return
    setPrevMid(mid)
    setMid(mid)
  }

  const handleResetFilter = () => {
    resetFilter()
    form.setFieldsValue({ mid: '', status: 'all' })
  }

  return (
    <Card>
      <Row justify={'space-between'}>
        <Col>
          <MerchantSelect storeSymbol={MerchantSelectStoreInvoicesSymbol} />
        </Col>
        <Col>
          <MonthPickerContainer injectableStoreIdentifier={RangePickerInvoicesStoreSymbol} />
        </Col>
      </Row>
      <Divider />
      <Form form={form} initialValues={{ status: selectedStatus }}>
        <div className={styles.filterWrapper}>
          <Row gutter={20}>
            <Col>
              <Form.Item name={'status'} label={translations().status} className={styles.statusSelect}>
                <Select options={statuses} onChange={selectStatus} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name={'mid'} label={translations().mid} className={styles.midInput}>
                <Input
                  value={mid}
                  onPressEnter={onEnterPress}
                  onBlur={onFilterBlur}
                  onChange={e => form.setFieldsValue({ mid: e.target.value.trim() })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button className={styles.resetBtn} onClick={handleResetFilter}>
                {translations().reset}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Card>
  )
})
