import { injectable } from 'inversify'
import { action, makeObservable, observable } from 'mobx'
import moment from 'moment-timezone'
import { ExportsStoreType } from '~/code/pages/Exports/ExportsStoreType'
import { TableFetchParams } from '~/code/models'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { fetchJobs } from './services/fetchers'
import { TableFilterStore } from '../TableFilterStore'
import translations from '../../pages/Exports/translations'
import { JobNameSelectItemType, JobNameType, JobType } from './models'

@injectable()
export class ExportsStore implements ExportsStoreType {
    jobsTableStore: TableFilterStore<JobType>

    jobName: JobNameType = 'all'

    constructor() {
        this.jobsTableStore = new TableFilterStore(this.loadJobs)

        makeObservable(this, {
            jobName: observable,
            setJobName: action
        })
    }

    get jobNameOptions(): JobNameSelectItemType[] {
        const options: JobNameSelectItemType[] = [{ value: 'all', label: translations().jobNameTypes.all }]

        if (hasPermissions([PermissionMap.settlements.download])) {
            options.push({ value: 'merchant-statement-excel', label: translations().jobNameTypes['merchant-statement-excel'] })
            options.push({ value: 'merchant-statement-csv', label: translations().jobNameTypes['merchant-statement-csv'] })
        }

        if (hasPermissions([PermissionMap.settlements.test.download])) {
            options.push({ value: 'merchant-statement-excel-test', label: translations().jobNameTypes['merchant-statement-excel-test'] })
            options.push({ value: 'merchant-statement-csv-test', label: translations().jobNameTypes['merchant-statement-csv-test'] })
        }

        if (hasPermissions([PermissionMap.transactions.dna_pos_statement.v2.read])) {
            options.push({ value: 'pos-transactionsv2-csv', label: translations().jobNameTypes['pos-transactions-csv'] })
        }

        if (hasPermissions([PermissionMap.transactions.dna_ecom_statement.read, PermissionMap.transactions.optomany_ecom_statement.read])) {
            options.push({ value: 'ecom-transactions-csv', label: translations().jobNameTypes['ecom-transactions-csv'] })
        }

        if (hasPermissions([PermissionMap.transactions.dna_pos_amex_statement.read])) {
            options.push({ value: 'pos-amex-transactions-csv', label: translations().jobNameTypes['pos-amex-transactions-csv'] })
        }

        if (hasPermissions([PermissionMap.transactions.dna_pos_statement.test.read])) {
            options.push({ value: 'pos-transactions-csv-test', label: translations().jobNameTypes['pos-transactions-csv-test'] })
        }

        return options
    }

    setJobName = (value: JobNameType) => {
        this.jobName = value
        this.jobsTableStore.loadData({ pageNumber: 1 })
    }

    loadJobs = async (params: TableFetchParams) => {
        const { error, result } = await fetchJobs({
            jobName: this.jobName === 'all' ? undefined : this.jobName,
            page: params.currentPage,
            size: params.pageSize
        })
        if (error) {
            throw new Error(error.message)
        }

        return {
            data: result.data.map(d => ({
                ...d,
                createdDate: d.createdDate ? moment(d.createdDate).format('DD.MM.YYYY HH:mm') : null,
                lastUpdateDate: d.lastUpdateDate ? moment(d.lastUpdateDate).format('DD.MM.YYYY HH:mm') : null
            })),
            total: result.totalCount
        }
    }
}
