import React from 'react'
import { TabType } from '~/code/models'
import { PageHeader } from '~/code/components'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { hasPermissions } from '~/code/services/auth'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import { TransactionChannelsTabEnum } from './models/TransactionChannelsTabEnum'
import { TransactionChannelsProps } from './props'
import translations from './translations'

const tabList: (TabType & { permissions: string[] })[] = [
    {
        key: TransactionChannelsTabEnum.GlobalSettings,
        label: translations().globalSettings,
        permissions: [PermissionMap.transactions.channels.read]
    },
    {
        key: TransactionChannelsTabEnum.MerchantSettings,
        label: translations().merchantSettings,
        permissions: [PermissionMap.transactions.channels.read]
    }
]

export const TransactionChannels = (props: TransactionChannelsProps) => {
    const { activeTab, children } = props
    const allowedTabList = tabList.filter((t) => hasPermissions(t.permissions))

    return <div>
        <PageHeader
            tabActiveKey={activeTab}
            tabList={allowedTabList}
            onTabChange={(key) => goToRoute(`${Routes.TRANSACTION_CHANNELS}/${key}`)}
        />
        {children}
    </div>
}
