import React from 'react'
import { Typography } from 'antd'
import translations from './translations'

const { Link } = Typography

translations.add('en', {
  title: 'To use an authenticator app go through the following steps:',
  step1: (iOSUrl: string, androidUrl: string) => (
    <>
      Download a two-factor authenticator app like Google Authenticator for{' '}
      <Link href={androidUrl} target='_blank'>
        Android
      </Link>{' '}
      and{' '}
      <Link href={iOSUrl} target='_blank'>
        iOS
      </Link>{' '}
      or a similar authenticator app
    </>
  ),
  step1Note:
    'Note: Microsoft Authenticator doesn’t support one account across multiple systems. Consider alternatives for broader system compatibility when enabling 2FA.',
  step2: (key: string, login: string) => (
    <>
      Scan the QR code or enter login {login} and this key <code>{key}</code> into your two factor authenticator app.
      Spaces and casing do not matter
    </>
  ),
  step3:
    'Once you have scanned the QR code or input the key above, your two factor authenticator app will provide you with a unique code. Enter the code in the confirmation box below and click submit'
})
