import { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  bankAccountNumberLabel: string
  bankAccountNumberRequired: string
  bankAccountNumberInvalid: string
  sortCodeLabel: string
  sortCodeRequired: string
  sortCodeInvalid: string
  validate: string
  validateBankAccount: string
  bankNameLabel: string
  accountNameLabel: string
  accountNamePlaceholder: string
  accountNameTooltip: string
  accountNameRequired: string
  accountType: string
  accountTypes: {
    business: string
    personal: string
  }
  accountTypeRequired: string
  bankAccountInfo: string
  checkPassed: string
  checkFailed: string
  verify: string
  cancel: string
  checkBankDetails: string
  detailsCorrect: string
  detailEdit: string
  confirmBankDetails: string
  info1: string
  info2: string
  info3: string
  accountDetailsInfo: (companyName: string, accNum: string, sortCode: string) => ReactNode
  verificationResults: string
  verified: string
  verifiedDetails: string
  notVerified: string
  notVerifiedDetails: string
  backToForm: string
  close: string
  save: string
  resetChecks: string
  automatedVerification: string
  failed: string
  whatsNext: string
  twoOptions: string
  optionOne: ReactNode
  optionTwo: string
  openBankingVerification: string
  uploadBankStatement: string
  saveForLater: string
  openBankingRequest: string
  sendOBRequest: string
  editDetails: string
  startOBRequestInfo: string
  startOBRequestInfo2: string
  obVerificationSent: string
  obRequestStatus: string
  obRequestWaiting: ReactNode
  verifResult: (status: string, isCanceled?: boolean) => ReactNode
  sentWaiting: string
  accountOwnerEmail: string
  yes: string
  no: string
  refreshStatus: string
  obrRequestAuthorised: string
  obrRequestFailed: string
  obrEditAndSave: string
  obrProceedWithDocs: string
  obrRequestDetailsInfo: (sortCode: string, accNum: string, accName: string, overallScore: number) => ReactNode
  requestData: string
  authorisedData: string
  note: string
  responseData: string
  updateAndSave: string
  confirmUpdate: string
  note2: string
  areYouSure: string
  docModalText: string
  complete: string
  note3: string
  resendText: string
  resend: string
  verificationSurvey: string
  ifLinkNotOpened: string
  obtainAndUpload: string
  doesntSupportOB: string
  account: string
  successOBNote: string
  SMENote: string
  SMETooltip: string
  SMENote2: string
  nowYouHave: string
  proceedWithBankStatement: string
}>()
