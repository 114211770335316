import React from 'react'
import { observer } from 'mobx-react'
import { Result } from 'antd'
import { Page } from '..'
import translations from './translations'

const NoPermission = observer(() => {
    return <Page>
        <Result
            status='403'
            title='403'
            subTitle={translations().text}
        />
    </Page>
})

export { NoPermission }
