import { message } from 'antd'
import { inject, injectable } from 'inversify'
import { makeObservable, action, observable, computed } from 'mobx'
import { ContractType, HoldDetailsType } from './models/merchant-dossier-v2'
import { StoresDossierV2Store } from './StoresDossierV2Store'
import { StoresDossierV2StoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores'
import { IContractStore } from '~/code/pages/MerchantsManagement/components/Stores/components/Contract/IContractStore'
import { getEditTariffsInfo } from './services/fetchers'
import translations from './translations'
@injectable()
export class ContractStore implements IContractStore {
  storesDossierV2Store: StoresDossierV2Store
  selectedContract: ContractType = null
  isTariffSettingsLoading: boolean = false
  constructor(@inject(StoresDossierV2StoreSymbol) storesDossierV2Store: StoresDossierV2Store) {
    this.storesDossierV2Store = storesDossierV2Store
    makeObservable(this, {
      selectedContract: observable,
      isTariffSettingsLoading: observable,
      isSubsidiary: computed,
      openEditTariffsModal: action,
      getEditTariffsInformation: action,
      getPayneticsMid: action,
      defineTipJarVenueByMid: action
    })
  }

  get isSubsidiary() {
    return this.storesDossierV2Store.isSubsidiary
  }

  async openEditTariffsModal(contract: ContractType, midText: string) {
    this.storesDossierV2Store.openEditTariffsModal(contract, midText)
  }

  async getEditTariffsInformation(contract: ContractType, midText?: string) {
    try {
      const contractId = contract?.id
      if (!contractId) {
        message.error(translations().contractIdIsEmpty)
        return
      }
      const { status, result, error } = await getEditTariffsInfo(contractId.toString())

      if (status !== 200 || error) {
        message.error(error.message)
      } else {
        this.storesDossierV2Store.openEditTariffsModal(contract, midText)
        this.storesDossierV2Store.setEditContractTariffs(result)
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  getPayneticsMid(mid: string): string {
    let payneticsMid = ''
    const filteredMid = this.storesDossierV2Store.payneticsMidList.filter(
      midItem => midItem.paramId === 25 && midItem.paramValue === mid
    )
    const groupId = filteredMid[0]?.groupId
    if (groupId) {
      payneticsMid = this.storesDossierV2Store.payneticsMidList.filter(
        midItem => midItem.paramId === 13 && midItem.groupId === groupId
      )[0]?.paramValue
    }
    return payneticsMid || ''
  }

  isHoldEnabled(contractNumber: string): HoldDetailsType {
    return this.storesDossierV2Store.isHoldEnabled(contractNumber)
  }

  defineTipJarVenueByMid = (mid: string): string => {
    return this.storesDossierV2Store.defineTipJarVenueByMid(mid)
  }
}
