import React from 'react'
import translations from './translations'

translations.add('en', {
  title: 'Verification required',
  continue: 'Continue',
  resend: {
    question: `Didn't receive a code?`
  },
  description: (n, e) => `To continue, please enter the ${n}-digit verification code sent to ${e}`
})
