import { Button } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { observer } from 'mobx-react'
import React from 'react'
import { MerchantsManagementStoreSymbol } from '~/code/pages'
import { ChangeRiskLimitsStore } from '~/code/stores/MerchantsManagementStore/ChangeRiskLimitsStore'
import { MerchantsManagementStore } from '~/code/stores/MerchantsManagementStore/MerchantsManagementStore'
import translations from '../../translations'
import { ChangeRiskLimitsStoreSymbol } from '../ChangeRiskLimitsModal/ChangeRiskLimitsModal'

export const ChangeButton = observer(() => {

    const merchantsManagementStore = useInjection<MerchantsManagementStore>(MerchantsManagementStoreSymbol)
    const changeRiskLimitsStore = useInjection<ChangeRiskLimitsStore>(ChangeRiskLimitsStoreSymbol)

    const { companyFullDossierLoadError, riskLimits } = merchantsManagementStore
    const { showModal } = changeRiskLimitsStore

    if (companyFullDossierLoadError) return null
    return (
        <Button type='primary' onClick={ () => showModal(riskLimits) }>
            { translations().change }
        </Button>
    )
})
