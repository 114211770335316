import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Table, Radio, Form } from 'antd'
import { TariffsTableStoreType } from './TariffsTableStoreType'
import { TidsList } from '~/code/components'
import { TariffsTableProps } from './props'
import { columnsBlended, columnsIcPlus } from './constants/constants'
import translations from './translations'
import styles from './TariffsTable.scss'

export const TariffsTableStoreSymbol = Symbol('TariffsTableStore')
export const TariffsTable = observer((props: TariffsTableProps) => {
  const { terminal, contractTariffs } = { ...props }
  const store = useInjection<TariffsTableStoreType>(TariffsTableStoreSymbol)
  const [terminalTarrsiffList, setTerminalTariffsList] = useState([])
  const [generalTariffsList, setGeneralTariffsList] = useState([])
  const [tariffType, setTariffType] = useState('')

  useEffect(() => {
    const { terminalTariffs, generalTariffs, tariffType } = store.getBlendedTariffs(terminal, contractTariffs)
    setTariffType(tariffType)
    setTerminalTariffsList(terminalTariffs)
    setGeneralTariffsList(generalTariffs)
  }, [])

  return (
    <Form>
      <Form.Item className={styles.displayGrid}>
        <Radio.Group value={tariffType} className={styles.radioGroup}>
          <Radio value='blended' checked>
            {translations().blended}
          </Radio>
          <Radio value='icPlus' disabled>
            {translations().icPlus}
          </Radio>
        </Radio.Group>
        {translations().tids}
        <TidsList terminalList={terminal.terminal.terminalId}></TidsList>
      </Form.Item>
      {tariffType === 'icPlus' && (
        <>
          <Table
            pagination={false}
            scroll={{ x: 900, y: 400 }}
            dataSource={terminalTarrsiffList}
            columns={columnsIcPlus}
          />
          {generalTariffsList.length && (
            <Table
              className={styles.generalTariffsTable}
              pagination={false}
              scroll={{ x: 900, y: 400 }}
              dataSource={generalTariffsList}
              columns={columnsBlended}
            />
          )}
        </>
      )}
      {tariffType === 'blended' && (
        <Table
          pagination={false}
          scroll={{ x: 900, y: 400 }}
          dataSource={terminalTarrsiffList}
          columns={columnsBlended}
        />
      )}
      <Form.Item label={translations().settlement}>
        <div>{terminal.generalTariffs[0]?.settlementPeriod}</div>
      </Form.Item>
    </Form>
  )
})
