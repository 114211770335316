import translations from './translations'

translations.add('en', {
  applicationSubmitted: 'Application Submitted',
  contactInfoTitle: 'Client Info',
  generalInformationTitle: 'Stores',
  pricingTitle: 'Pricing',
  completeApplicationTitle: 'Processing info',
  supportingDocumentsTitle: 'Documents',
  applicationSubmissionTitle: 'Submit',
  couldNotLoadCompanies: 'Could not load companies',
  next: 'Next',
  back: 'Back',
  errorLoadingApplication: 'An error occurred while loading your application',
  errorLoadingAcquisitionChannels: 'Error loading acquisition channels',
  errorVerifyBankAccount: 'Validation is temporarily unavailable',
  errorVerifyAddress: 'Error verifying address details',
  errorStartingApplication: 'Error starting application',
  newApplication: 'New Application',
  errorCheckingStatus: 'Error checking status of the application',
  applicationAlreadySubmitted: 'Application with this company number has already been submitted',
  errorSavingData: 'Error saving data',
  savedDataSuccessfully: 'Saved data successfully',
  all: 'All',
  errorCheckingSFOpportunityID: 'Error checking Salesforce opportunity ID',
  errorCheckingDocumentRequirements: 'Error checking document requirements',
  pos: 'POS (face-to-face)',
  moto: 'MOTO (phone payments)',
  ecom: 'ECOM (Website payments)',
  payByLink: 'ECOM Pay by link only',
  payByApp: 'ECOM Pay by App',
  virtualTerminal: 'ECOM Virtual terminal',
  nbsp: 'Same day sales – product is taken away from stock',
  deposits: 'Deposits',
  balances: 'Balances',
  fullPayment: 'Full payment in advance',
  checkedBankAccountInfo: (accNum: string, sortCode: string) => `Account number: ${accNum}, sort code: ${sortCode}`,
  addProductMessage: 'You need to add at least one product',
  mustBe: (count: number, companyMode: boolean) => `Must be ${count} ${companyMode ? 'directors' : 'business owners'}!`,
  canNotProceed: 'Can not proceed with this owner!',
  errorEmailCheck: 'Error while checking Email',
  addStoreMsg: 'You need to add at least one new store',
  chooseValue: 'Choose value',
  success: 'Successfully',
  error: 'Error',
  msaSignError: 'The application can not be submitted as the MSA has not been signed yet.',
  obrSettingsError: 'Error while loading onboarding settings',
  docusignSettingsError: 'Error while loading docusign credentials',
  validateBankAccount: 'Validate bank account first',
  accExists: 'Account already exists',
  errorVerifyOfficer: 'Error while checking officer',
  ecoSpendError: 'Error while getting ecospend status',
  jumioError: 'Error while sending jumio request',
  resendError: 'Error while resend email',
  resendSuccess: 'Email successfully resent. Your next attempt can be made after 10 seconds.',
  errorFetchingTariffs: 'Error fetching tariffs',
  companyCheckErr: 'Error while checking company information. Please try again',
  tradeName: 'Trade Name',
  tariffListNull: 'Tariff list is empty, please refresh this page'
})
