import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    selectDatesFirst: string
    noData: string
    all: string
    couldNotSendToApprovalTheFollowing: (payments) => JSX.Element
    failedToSendPaymentsForApproval: string
    failedToApproveThePayments: string
    negativeOrZeroApprove: string
}>()
