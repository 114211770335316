import React from 'react'
import classNames from 'classnames'
import { Table, Modal } from 'antd'
import { TeammateMenuType, TeammateViewType } from '~/code/pages/Teammates/models'
import { getColumns } from './services/columns'
import { TeammatesTableProps } from './props'

import translations from '~/code/pages/Teammates/translations'
import styles from './styles.scss'

export const TeammatesTable: React.FC<TeammatesTableProps> = props => {
  const { className, isInvited, users, pagination, onSelectMenu } = props

  const onSelect = (key: TeammateMenuType, user: TeammateViewType) => {
    if (key === 'delete') {
      Modal.confirm({
        title: translations().deleteAlert.title,
        content: translations().deleteAlert.text,
        okText: translations().deleteAlert.okText,
        onOk: () => onSelectMenu(key, user)
      })

      return
    }

    onSelectMenu(key, user)
  }

  return (
    <Table
      rowKey='id'
      dataSource={users}
      className={classNames(styles.root, className)}
      columns={getColumns(isInvited, onSelect)}
      pagination={pagination}
      scroll={{ x: !isInvited ? 1500 : 'auto' }}
    />
  )
}
