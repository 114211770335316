import React from 'react'
import { observer } from 'mobx-react'
import { Pagination, TablePaginationConfig } from 'antd'
import { DefaultPaginationProps } from './props'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'

export const DefaultPagination = observer((props: DefaultPaginationProps) => {
    const { store } = props
    const paginationProps: TablePaginationConfig =
        generateTablePaginationProps(
            store.currentPage,
            store.pageSize,
            store.totalCount,
            (pageNumber) => {
                store.loadData({ pageNumber })
            },
            store.setPageSize
        )

    return <Pagination {...paginationProps} />
})
