import { inject, injectable } from 'inversify'

import { FileType } from '~/code/models/FileType'
import { IMerchantSelectStore } from '~/code/components/MerchantSelect/models'

import { STORE_TYPES } from '~/code/constants/StoreTypes'
import { SETTLEMENT_STATEMENTS_STORE_TYPES } from '~/code/pages'

import { downloadStatementFile, downloadStatementFileAsync, downloadStatementFileOld } from './services/fetchers'
import { HandbooksStoreInjectable } from '~/code/stores/HandbooksStoreInjectable'
import { RangePickerStoreInjectable } from '~/code/stores/RangePickerStoreInjectable'

import { BaseSettlementsFiltersStore } from './BaseSettlementsFiltersStore'

// @ts-ignore
@injectable()
export class SettlementsFiltersStore extends BaseSettlementsFiltersStore {
  constructor(
    @inject(STORE_TYPES.HandbooksStore) handbooksStore: HandbooksStoreInjectable,
    @inject(SETTLEMENT_STATEMENTS_STORE_TYPES.rangePicker) rangePickerStore: RangePickerStoreInjectable,
    @inject(SETTLEMENT_STATEMENTS_STORE_TYPES.merchantSelect) merchantSelectStore: IMerchantSelectStore
  ) {
    super(handbooksStore, rangePickerStore, merchantSelectStore)
  }

  // @ts-ignore
  protected downloadStatementFunc(format: FileType, id: string, isOld: boolean = false) {
    return format === FileType.PDF
      ? isOld
        ? downloadStatementFileOld
        : downloadStatementFile(id)
      : downloadStatementFileAsync
  }
}
