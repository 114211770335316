import { getWithAuth, postWithAuth } from '~/code/services'
import { camelCaseObject } from 'dna-common'
import { Application, CheckDocumentRequirementsRes, OnboardingRequest } from '../models'
import { VerifyBankAccountReq, VerifyBankAccountRes } from '../models/verification'
import { FetchApplicationStatusRes } from '../models/onboarding/FetchApplicationStatusRes'
import { ApplicantInfo } from '../models/onboarding/ApplicantInfo'

export const fetchCompaniesByName = async (name: string) => {
    return getWithAuth<any>(`/api/merchant`, {
        name,
        size: 20,
        page: 0
    }).then(camelCaseObject)
}

export const saveCompleteApplication = (email: string, data: Application) =>
    postWithAuth<any>(`/api/process/onboarding/key/${email}/application`, data)
        .then(camelCaseObject)

export const loadCompanyInformation = (companyNumber: string) =>
    getWithAuth<any>(`/api/merchant/${companyNumber}/full?officerRole=director`)
        .then(camelCaseObject)

export const verifyBankAccount = (data: VerifyBankAccountReq) => postWithAuth<VerifyBankAccountRes>('/api/v1/sort-codes/validation', data)

export const fetchApplicationStatus = (email: string) =>
    getWithAuth<FetchApplicationStatusRes>(`/api/v2/sales-onboarding/logins/${email}/st`)

export const startApplication = (data: ApplicantInfo) =>
    postWithAuth<{ id: string }>(`/api/v2/sales-onboarding/applications`, data)

export const saveApplication = (id: string, data: OnboardingRequest) =>
    postWithAuth<{ id: string }>(`/api/v2/sales-onboarding/applications/${id}/save`, data)
        .then(camelCaseObject)

export const completeApplication = (id: string, data: OnboardingRequest) =>
    postWithAuth(`/api/v2/sales-onboarding/applications/${id}/complete`, data)
        .then(camelCaseObject)

export const checkSalesforceOpportunityID = (id: string) =>
    getWithAuth<{ code: number, message: string }>(`/api/v2/sales-onboarding/opportunities/${id}`)

export const checkDocumentRequirements = (id: string, data: OnboardingRequest) =>
    postWithAuth<CheckDocumentRequirementsRes>(`/api/v1/sales-onboarding/applications/${id}/document-requirements`, data)
