import { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  comment: string
  commentRequired: string
  reasonRequired: string
  reasonMinMsg: string
  reason: string
  warningMessage: ReactNode
  required: string
  valueRestriction: string
  terminationFee: string
  legalName: string
  closureOfMerchant: string
}>()
