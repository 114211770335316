import { getWithAuth, patchWithAuth, putWithAuth, deleteWithAuth, postWithAuth } from '~/code/services'
import {
  Profile,
  AuthAppInfoModel,
  AuthSecretKeyModel,
  TwoFASettingsModel,
  TwoFAUserSettingsModel,
  RecoveryCodeInterface,
  TwoFAUserUpdateSettingsRequestModel,
  TwoFATrustedDeviceResponseModel,
  UserModel
} from '~/code/stores/Profile/models'

export const getProfile = () => getWithAuth<Profile>('/oppapi/profile')

export const getTwoFASettings = () => getWithAuth<TwoFASettingsModel>('/auth/oauth2/2fa/settings')

export const getTwoFAUserSettings = () => getWithAuth<TwoFAUserSettingsModel>('/auth/oauth2/user/2fa/settings')

export const getAuthAppInfo = () => getWithAuth<AuthAppInfoModel>('/auth/oauth2/2fa/auth/apps')

export const getAuthSecretKey = () => getWithAuth<AuthSecretKeyModel>('/auth/oauth2/user/2fa/auth/info')

export const getAuthQR = () => getWithAuth<string>('/auth/oauth2/user/2fa/auth/qr')

export const getRecoveryCodes = () => getWithAuth<RecoveryCodeInterface[]>('/auth/oauth2/user/2fa/recovery-codes')

export const updateTwoFAUserSettings = (settings: TwoFAUserUpdateSettingsRequestModel, code: number) =>
  putWithAuth<RecoveryCodeInterface>('/auth/oauth2/user/2fa/settings', settings, [['code', `${code}`]])

export const updateProfileTimezone = (timezone: string) => patchWithAuth('/auth/oauth2/user/settings', { timezone })

export const deleteUserTrustedDevices = (id: string) => deleteWithAuth(`/auth/oauth2/user/2fa/device/${id}`)

export const getUserTrustedDevices = (page: number, size: number) =>
  getWithAuth<TwoFATrustedDeviceResponseModel>(`/auth/oauth2/user/2fa/devices?page=${page}&size=${size}`)

export const sendEmailOtp = () => postWithAuth(`/auth/oauth2/email/otp?type=twoFAEnable`)

export const validateEmailOtp = (code: string) => postWithAuth(`/auth/oauth2/email/validation`, { code })

export const getUserInfoByUsername = (username: string) => getWithAuth<UserModel>(`/api/v1/user/${username}/info`)

export const updateUserInfo = (user: Partial<UserModel>) => patchWithAuth<UserModel>('/api/v1/user/info', user)
