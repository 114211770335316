import { TranslationBuilder } from '~/code/components/Translation'
import nationalitiesEn from '~/assets/jsons/nationalities.en.json'

const translations = TranslationBuilder.create<string[]>()

translations.add('en', nationalitiesEn)

export function getNationalities() {
    return translations()
}

const britishTranslation = TranslationBuilder.create<string>()
britishTranslation.add('en', 'British')

export function getSortedNationalitiesByBritish() {
    const british = britishTranslation()
    return [
        ...[british],
        ...translations().filter((n) => n !== british)
    ]
}
