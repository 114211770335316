import React from 'react'

const ActiveBlue = props => (
  <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 24C5.64873 24 0.5 18.8513 0.5 12.5C0.5 6.14873 5.64873 1 12 1C18.3513 1 23.5 6.14873 23.5 12.5C23.5 18.8513 18.3513 24 12 24Z'
      stroke={props.stroke || '#1890FF'}
    />
  </svg>
)

export default ActiveBlue
