import { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  customVerificationResult: string
  sortCode: string
  accountNumber: string
  accountName: string
  overallScore: string
  account: string
  dnaAlgorithm: string
  parties: string
}>()
