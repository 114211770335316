import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    all: string
    successful: string
    failed: string
    sumTitle: (merchant: string, status: string, currency: string) => JSX.Element
    countTitle: (merchant: string, status: string, currency: string) => JSX.Element
    yearsComparison: string
    asOfYesterday: string
}>()
