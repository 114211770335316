import React from 'react'
import { Col, Row } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import translations from './translations'
import { SummaryRowProps } from './props'
import { SummaryCard } from './components'

const colResponsiveProps = {
    xs: 24,
    sm: 12
}

export const SummaryRow = observer(({
    loading,
    data,
    percentageChange,
    percentageChangeTooltipText,
    className,
    includeStatusOther = true
}: SummaryRowProps) => {
    const { sum, count } = data || {}

    return (
        <Row gutter={[24, 24]} className={classNames(className)}>
            <Col {...colResponsiveProps} flex={'auto'}>
                <SummaryCard
                    loading={loading}
                    data={sum}
                    percentageChange={percentageChange?.amount}
                    percentageChangeTooltipText={percentageChangeTooltipText}
                    headerString={translations().totalTransactionsSum}
                    footerString={translations().dailyAverage}
                    includeStatusOther={includeStatusOther}
                />
            </Col>
            <Col {...colResponsiveProps}>
                <SummaryCard
                    loading={loading}
                    data={count}
                    percentageChange={percentageChange?.count}
                    percentageChangeTooltipText={percentageChangeTooltipText}
                    headerString={translations().totalTransactionsCount}
                    footerString={translations().dailyAverage}
                    includeStatusOther={includeStatusOther}
                />
            </Col>
        </Row>
    )
})
