import React from 'react'
import { Typography } from 'antd'
import classNames from 'classnames'
import translations from './translations'
import styles from '../ConfirmEmail.scss'

const { Link } = Typography

translations.add('en', {
  title: 'Confirm Your Email',
  text: (isDisabled, count, reSend) => {
    const waitingText = count ? (
      <>
        {' '}
        in <b>{count} seconds</b>
      </>
    ) : (
      ''
    )
    const link = (
      <Link className={classNames({ [styles.disabled]: isDisabled })} onClick={() => !isDisabled && reSend()}>
        re-send
      </Link>
    )
    return (
      <>
        If you didn't receive a verification code, please check "Spam" bin or try to {link}
        {waitingText}
      </>
    )
  },
  code: 'Verification code',
  codeRequired: 'verification code is required',
  confirm: 'Confirm',
  back: 'Back',
  emailWasSentTo: (email: string) => {
    return (
      <>
        If we find an account associated with <b>{email}</b>, we will send a 6-digit verification code to it.
      </>
    )
  }
})
