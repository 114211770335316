import { Typography } from 'antd'
import React from 'react'
import { TwoLineProps } from './props'
import styles from './styles.scss'

const { Text } = Typography

export const TwoLine: React.FC<TwoLineProps> = ({ primary, secondary }) => (
    <>
        <Text className={styles.primary}>{primary}</Text>
        {secondary && <Text className={styles.secondary} type='secondary'>{secondary}</Text>}       
    </>
)