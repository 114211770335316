import { inject, injectable } from 'inversify'
import { action, makeObservable, observable } from 'mobx'
import { IMerchantSelectStore } from '~/code/components/MerchantSelect/models'
import {
  MerchantSelectStoreInvoicesSymbol,
  RangePickerInvoicesStoreSymbol
} from '~/code/pages/Billing/pages/Invoices/components'
import { IInvoicesFilterStore } from '~/code/pages/Billing/pages/Invoices/components/InvoicesFilter'
import { StatusItem } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsDetailedView/models/StatusItem'
import { InvoiceStatusType } from '~/code/stores/invoicesStore/models'
import { IRangePickerContainerStore } from '~/code/pages/settlements/SettlementsPayments/components/RangePickerContainer/IRangePickerContainerStore'
import translations from './translations'
import moment from 'moment-timezone'

@injectable()
export class InvoicesFilterStore implements IInvoicesFilterStore {
  merchantSelectStore: IMerchantSelectStore
  rangePickerStore: IRangePickerContainerStore
  mid: string = ''
  statuses: StatusItem[] = [
    { value: 'all', label: translations().all },
    { value: 'issued', label: translations().issued },
    { value: 'paid', label: translations().paid }
  ]
  selectedStatus: InvoiceStatusType = 'all'
  currentPage: number = 1
  size: number = 20
  total: number = 100

  constructor(
    @inject(MerchantSelectStoreInvoicesSymbol) merchantSelectStore: IMerchantSelectStore,
    @inject(RangePickerInvoicesStoreSymbol) rangePickerStore: IRangePickerContainerStore
  ) {
    this.merchantSelectStore = merchantSelectStore
    this.rangePickerStore = rangePickerStore
    makeObservable(this, {
      selectedStatus: observable,
      mid: observable,
      currentPage: observable,
      size: observable,
      total: observable,
      resetFilter: action.bound,
      selectStatus: action.bound,
      setMid: action.bound,
      setPagination: action.bound
    })

    if (!this.rangePickerStore.period) {
      this.rangePickerStore.setDates(
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month')
      )
    }
  }

  public setMid(value) {
    this.mid = value
  }

  public setPagination(page: number, pageSize: number) {
    this.currentPage = page
    this.size = pageSize
  }

  public resetFilter() {
    this.merchantSelectStore.setMerchant('all')
    this.merchantSelectStore.setAcquisitionChannel('all')
    this.merchantSelectStore.setTempMerchant('all')
    this.merchantSelectStore.setTempAcquisitionChannel('all')
    this.rangePickerStore.setDates(moment().startOf('month'), moment().endOf('month'))
    this.setPagination(1, 20)
    this.setMid('')
    this.selectStatus('all')
  }

  public selectStatus(value: InvoiceStatusType) {
    this.selectedStatus = value
  }
}
