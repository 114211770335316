import translations from './translations'
import React, { ReactNode } from 'react'
import styles from '../BankAccountInfo.scss'

translations.add('en', {
  bankAccountNumberLabel: 'Bank account number',
  bankAccountNumberRequired: 'bank account number is required',
  bankAccountNumberInvalid: 'invalid bank account number',
  sortCodeLabel: 'Sort code',
  sortCodeRequired: 'sort code is required',
  sortCodeInvalid: 'invalid sort code',
  validate: 'Validate',
  validateBankAccount: 'Please validate bank account',
  bankNameLabel: 'Bank name',
  accountNameLabel: 'Account name',
  accountNamePlaceholder: 'Bank account name',
  accountNameTooltip: 'Name on the bank statement',
  accountNameRequired: 'account name is required',
  accountType: 'Account type',
  accountTypes: {
    business: 'Business',
    personal: 'Personal'
  },
  accountTypeRequired: 'Account type is required',
  bankAccountInfo: 'Bank Account Info',
  checkPassed:
    'Verification of bank account successfully passed, document confirming the existence of a bank account is not required',
  checkFailed:
    'Bank account verification is not passed, in the next steps you will need to attach a document confirming the existence of a bank account',
  verify: 'Verify',
  cancel: 'Cancel',
  checkBankDetails: 'Check bank details',
  detailsCorrect: 'Details are correct',
  detailEdit: 'Edit bank detials',
  confirmBankDetails: 'Confirm bank details',
  info1: `Dear user, you're about to start verification process for the banking details.`,
  info2: `If banking details are verified, you won't need to collect bank statement`,
  info3: `Make sure you've entered correct details:`,
  accountDetailsInfo: (companyName: string, accNum: string, sortCode: string) => (
    <>
      <p>{`Merchant legal name: ${companyName}`}</p>
      <p>{`Bank account number: ${accNum}`}</p>
      <p>{`Sort code: ${sortCode}`}</p>
    </>
  ),
  verificationResults: 'Verification results:',
  verified: 'VERIFIED',
  notVerified: 'NOT VERIFIED',
  verifiedDetails: `We won't request a bank statement`,
  notVerifiedDetails: `We couldn’t automatically verify this account belongs to the company. Please follow one of two options below: send the Open Banking link to the account holder OR obtain and upload a physical copy of the valid bank statement.`,
  backToForm: 'Back to form',
  close: 'Close',
  save: 'Save',
  resetChecks: 'Verification results will be lost, are you sure want to edit details?',
  automatedVerification: 'AUTOMATED BANK VERIFICATION ',
  failed: 'FAILED',
  whatsNext: 'WHATS NEXT:',
  twoOptions: 'You now have two options:',
  optionOne: (
    <>
      <p>
        Send the Open Banking authorisation request to the Merchant. They will receive an email with the Open Banking
        link.
      </p>
      <p>
        Once clicked, they will be forwarded to the Open Banking verification page, where they can select their bank,
        enter banking details and authorise the request via the mobile banking App or banking portal.
      </p>
    </>
  ),
  optionTwo:
    'Upload the bank statement. When proceeding with this option, you will have to upload a recent bank statement on the ”Documents” section',
  openBankingVerification: 'Open Banking Verification',
  uploadBankStatement: 'I will upload a bank statement',
  saveForLater: 'Save for later',
  openBankingRequest: 'Open Banking verification request',
  sendOBRequest: 'Send the Open Banking Request',
  editDetails: 'Edit details',
  startOBRequestInfo: 'Dear user, you are about to send the Open Banking verification request',
  startOBRequestInfo2:
    'Merchant will receive this request via their Banking App.Please inform them that they need to authorise the request for us to verify that the bank details provided belong to them',
  obVerificationSent:
    'The verification request is now sent via email. Please inform the merchant to follow the link from the email request and complete the Bank Account verification process. Once completed the status here will change automatically',
  obRequestStatus: 'OPEN BANKING VERIFICATION',
  obRequestWaiting: (
    <>
      We`ve sent the Open Banking authorisation request to the Merchant and are waiting for them to authorise the
      request
      <br />
      <br />
      Once authorised, this page will show results of the verification
    </>
  ),
  verifResult: (status: string, isCanceled?: boolean) => (
    <>
      <div className={styles.flex}>
        <p>
          <b>{`Verification results -  `}</b>
        </p>
        {status?.toUpperCase() === 'MATCH' && (
          <p className={styles.statusWithMargin}>
            <b className={styles.green}>AUTHORISED, PASS</b>
          </p>
        )}
        {status?.toUpperCase() === 'SENT' && (
          <p className={styles.statusWithMargin}>
            <b>SENT, Waiting for the authorisation</b>
          </p>
        )}
        {status?.toUpperCase() === 'NOMATCH' && (
          <p className={styles.notVerified}>
            <b>{isCanceled ? 'Customer refused using Open Banking': 'FAIL'}</b>
          </p>
        )}
        {status?.toUpperCase() === 'FAILED' && <p className={styles.notVerified}>FAILED</p>}
        {status?.toUpperCase() === 'VERIFIED' && <p className={styles.verified}>VERIFIED</p>}
      </div>
    </>
  ),
  sentWaiting: 'SENT, Waiting for the authorisation',
  accountOwnerEmail: 'Account owner email is: ',
  yes: 'Yes',
  no: 'No',
  refreshStatus: 'Refresh status',
  obrRequestAuthorised:
    'Merchant has authorised the request and the ownership was verified. You wont need to upload the bank statement.',
  obrRequestFailed: '',
  obrEditAndSave:
    'If you want to amend the bank data to the one we`ve got from the Open Banking press ”Update bank details and save”, updated details will be saved as ”Verified” and you won`t need to upload the Bank Statement',
  obrProceedWithDocs: 'If you want to proceed with original bank details, you will need to upload the bank statements',
  obrRequestDetailsInfo: (sortCode: string, accNum: string, accName: string, overallScore: number) => (
    <>
      <div>{`Sort code: ${sortCode}`}</div>
      <div>{`Account number: ${accNum}`}</div>
      <div>{`Account name: ${accName}`}</div>
      <div className={styles.flex}>
        <p>Overall score</p>
        <p className={overallScore > 65 ? styles.green : styles.red}>: {overallScore}</p>
      </div>
    </>
  ),
  requestData: 'Request data',
  authorisedData: 'Authorised results',
  note: 'Please note: sometimes, it takes 5-10 minutes for the email to reach the recipient`s inbox and another 5-10 minutes for the status to update.',
  responseData: 'Open Banking Initial Verification',
  updateAndSave: 'Update bank details and Save',
  confirmUpdate:
    'Are you sure want to amend the bank data to the one we`ve got from the Open Banking? Updated details will be saved as ”Verified” and you won`t need to upload the Bank Statement.',
  note2:
    '(!) Please proceed with the rest of the application, you will get an email notification update when the status changes.',
  areYouSure: 'Are you sure?',
  docModalText:
    'We`re waiting for the merchant to authorise an Open Banking request. Selecting "I will upload a bank statement" will cancel the Open Banking verification request, and you will have to upload the scan copy of a valid bank statement in the Documents section',
  complete: 'Complete',
  note3:
    'You can now inform your merchant that they need to open the email and follow instructions to complete the Open Banking Verification',
  resendText: 'If a client does not receive the email, please click the "Resend" button.',
  resend: 'Resend',
  verificationSurvey: 'Open Banking verification survey',
  ifLinkNotOpened:
    'Please note: If the link is not opened, it will remain valid for 30 days. However, if a person opens the link, grants consent, and exits (without completing the verification), the link will remain valid for 24 hours.',
  obtainAndUpload:
    'We couldn`t verify this account belongs to the company. Please obtain and upload a physical copy of the valid bank statement',
  doesntSupportOB:
    'The sort-code provided is not the list of Banks supporting Open Banking. If you think this is wrong, please contact processes@dnapayments.com',
  account: 'Account: ',
  successOBNote:
    'We couldn’t automatically verify this account belongs to the company. You can continue to proceed with the Open Banking verification results below.',
  SMENote:
    'We couldn’t automatically verify this account belongs to the company. Please proceed with the Open Banking Verification.',
  SMETooltip: 'This option becomes available after both verification methods attempted',
  SMENote2:
    'Upload the bank statement. When proceeding with this option, you will have to upload a recent bank statement on the ”Documents” section',
  nowYouHave: 'Now, you have the following option:',
  proceedWithBankStatement: 'Please proceed with the bank statement upload'
})
