import { log } from 'dna-common'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { UploadFile } from 'antd/lib/upload/interface'
import { StartApplicationStore } from '../..'
import { mandatoryDocuments } from '../components/SupportingDocuments/components/SupportingDocument/constants'
import { DocumentItem } from '../components/SupportingDocuments/components/SupportingDocument/models'
import { DocumentsRequiredModel, CheckedDocumentModel, CheckedDocumentType } from '../models/DocumentsRequiredModel'
import { camelToKebabCase, checkDocumentRequirements, parseCheckedDocumentVerification, parseOnboardingRequestData } from '../services'
import translations from '../components/SupportingDocuments/translations'
import { SupportingDocumentsType } from '../models'
import { UploadDocumentRes } from '../components/SupportingDocuments/models'
import { message } from 'antd'
import { deleteDocument, uploadDocument } from '../components/SupportingDocuments/services'

type FileKey = keyof SupportingDocumentsType

export class SupportingDocumentsStore {

    isCheckDocumentsRequiredLoading: boolean = false
    documentsRequired: DocumentsRequiredModel = {
        bankStatement: true
    }

    idDocuments: CheckedDocumentModel[] = []
    residentialUtilityBills: CheckedDocumentModel[] = []

    supportingDocumentsMap: Record<FileKey, UploadFile[]> = {
        bankStatement: [],

        idDrivingLicence: [],
        idPassport: [],
        idOther: [],
        poaPersonalBankStatement: [],
        poaCouncilTax: [],
        poaUtilityBill: [],
        poaOther: [],
        pobBankStatementBusiness: [],
        pobChequeBook: [],
        pobBankLetter: [],
        pobOther: [],
        kybLeaseAgreement: [],
        kybAlcoholLicense: [],
        kybCertificateOfAuthenticity: [],
        domainOwnership: [],
        licences: [],
        processingStatement: [],
        premisesPhotoOutside: [],
        premisesPhotoInside: [],
        signedMsa: [],
        additionalTidsRequestForm: [],
        other: []
    }

    isDocumentLoading = {
        bankStatement: false,

        idDrivingLicence: false,
        idPassport: false,
        idOther: false,
        poaPersonalBankStatement: false,
        poaCouncilTax: false,
        poaUtilityBill: false,
        poaOther: false,
        pobBankStatementBusiness: false,
        pobChequeBook: false,
        pobBankLetter: false,
        pobOther: false,
        kybLeaseAgreement: false,
        kybAlcoholLicense: false,
        kybCertificateOfAuthenticity: false,
        domainOwnership: false,
        licences: false,
        processingStatement: false,
        premisesPhotoOutside: false,
        premisesPhotoInside: false,
        signedMsa: false,
        other: false,

        uploadAdditionalDocument: false
    }

    supportingDocumentsData: UploadDocumentRes[] = []
    additionalDocumentTypes: FileKey[] = []

    constructor(
        private parentStore: StartApplicationStore
    ) {
        makeObservable(this, {
            documentsRequired: observable,
            isCheckDocumentsRequiredLoading: observable,
            supportingDocumentsMap: observable,
            isDocumentLoading: observable,
            supportingDocumentsData: observable,
            additionalDocumentTypes: observable,
            idDocuments: observable,
            residentialUtilityBills: observable,

            mandatoryDocuments: computed,
            isSupportingDocumentsValid: computed,
            isIdDocumentsValid: computed,
            isResidentialUtilityBillsValid: computed,

            checkDocumentsRequired: action,
            addAdditionalDocument: action,
            removeAdditionalDocument: action,
            setSupportingDocumentsMap: action.bound,
            setIdDocument: action,
            setResidentialUtilityBillDocument: action,
            uploadFile: action,
            deleteFile: action,
            reset: action
        })
    }

    get dataStore() {
        return this.parentStore.dataStore
    }

    get mandatoryDocuments(): (DocumentItem & { notRequiredText?: string })[] {
        return mandatoryDocuments.map(d => ({ ...d, notRequiredText: this.documentsRequired[d.key] ? null : translations().notRequired[d.key] }))
    }

    get isSupportingDocumentsValid() {
        return Boolean(
            (this.documentsRequired.bankStatement ? this.supportingDocumentsMap.bankStatement[0] : true) &&
            this.isIdDocumentsValid &&
            this.isResidentialUtilityBillsValid
        )
    }

    get isIdDocumentsValid() {
        if (this.idDocuments.length === 0) return true
        let isValid = true
        for (const doc of this.idDocuments) {
            if (doc.isRequired) {
                isValid = doc.fileList.length > 0
            }
        }
        return isValid
    }

    get isResidentialUtilityBillsValid() {
        if (this.residentialUtilityBills.length === 0) return true
        let isValid = true
        for (const bill of this.residentialUtilityBills) {
            if (bill.isRequired) {
                isValid = bill.fileList.length > 0
            }
        }
        return isValid
    }

    openCompleteApplicationPage() {
        this.parentStore.openCompleteApplicationPage()
    }

    openResultPage() {
        this.parentStore.openResultPage()
    }

    reset() {
        this.supportingDocumentsMap = {
            bankStatement: [],

            idDrivingLicence: [],
            idPassport: [],
            idOther: [],
            poaPersonalBankStatement: [],
            poaCouncilTax: [],
            poaUtilityBill: [],
            poaOther: [],
            pobBankStatementBusiness: [],
            pobChequeBook: [],
            pobBankLetter: [],
            pobOther: [],
            kybLeaseAgreement: [],
            kybAlcoholLicense: [],
            kybCertificateOfAuthenticity: [],
            domainOwnership: [],
            licences: [],
            processingStatement: [],
            premisesPhotoOutside: [],
            premisesPhotoInside: [],
            signedMsa: [],
            additionalTidsRequestForm: [],
            other: []
        }
        this.supportingDocumentsData = []
        this.additionalDocumentTypes = []
        this.idDocuments = []
        this.residentialUtilityBills = []
    }

    addAdditionalDocument(fileKey: FileKey, fileList: UploadFile[]) {
        this.setSupportingDocumentsMap(fileKey, [...this.supportingDocumentsMap[fileKey], ...fileList])
        this.addAdditionalDocumentType(fileKey)
    }

    removeAdditionalDocument(fileKey: FileKey, fileList: UploadFile[]) {
        this.setSupportingDocumentsMap(fileKey, fileList)
        if (fileList.length === 0) this.removeAdditionalDocumentType(fileKey)
    }

    addAdditionalDocumentType(value: FileKey) {
        if (this.additionalDocumentTypes.includes(value)) return
        this.additionalDocumentTypes.push(value)
    }

    removeAdditionalDocumentType(value: FileKey) {
        this.additionalDocumentTypes = this.additionalDocumentTypes.filter(doc => doc !== value)
    }

    setSupportingDocumentsMap(fileKey: FileKey, fileList: UploadFile[]) {
        this.supportingDocumentsMap[fileKey] = fileList
    }

    setIdDocument(officer: string, fileList: UploadFile[]) {
        this.idDocuments = this.idDocuments.map(b => {
            if (b.officer === officer) {
                return { ...b, fileList }
            }
            return b
        })
    }

    setResidentialUtilityBillDocument(officer: string, fileList: UploadFile[]) {
        this.residentialUtilityBills = this.residentialUtilityBills.map(b => {
            if (b.officer === officer) {
                return { ...b, fileList }
            }
            return b
        })
    }

    setCheckedDocumentLoading(checkedDocumentType: CheckedDocumentType, officer: string, isLoading: boolean) {
        if (checkedDocumentType === 'idDocument') {
            this.idDocuments = this.idDocuments.map(b => {
                if (b.officer === officer) {
                    return { ...b, isLoading }
                }
                return b
            })
        }

        if (checkedDocumentType === 'residentialUtilityBill') {
            this.residentialUtilityBills = this.residentialUtilityBills.map(b => {
                if (b.officer === officer) {
                    return { ...b, isLoading }
                }
                return b
            })
        }
    }

    async checkDocumentsRequired() {
        const { application, generalInformationData, applicationId } = this.dataStore
        const data = parseOnboardingRequestData(application, generalInformationData)
        
        try {
            runInAction(() => {
                this.isCheckDocumentsRequiredLoading = true
            })

            const { status, error, result } = await checkDocumentRequirements(applicationId, data)
            if (status === 200 && !error) {
                runInAction(() => {
                    this.documentsRequired.bankStatement = !result.bankAccountVerification.verified
                    result.idVerifications.forEach((officer, idx) => {
                        this.idDocuments[idx] = parseCheckedDocumentVerification(officer, this.idDocuments?.[idx]?.fileList)
                    })
                    result.addressVerifications.forEach((officer, idx) => {
                        this.residentialUtilityBills[idx] = parseCheckedDocumentVerification(officer, this.residentialUtilityBills?.[idx]?.fileList)
                    })
                })
            }
        } catch (error) {
            log(error)
        }

        runInAction(() => {
            this.isCheckDocumentsRequiredLoading = false
        })
    }

    async uploadFile(documentType: FileKey | CheckedDocumentType, file: File, officer?: string): Promise<boolean> {
        if (!file) return false

        let isUploaded: boolean

        try {
            runInAction(() => {
                if (officer) {
                    this.setCheckedDocumentLoading(documentType as CheckedDocumentType, officer, true)
                } else {
                    this.isDocumentLoading[documentType] = true
                }
            })
            const { status, error, result } = await uploadDocument({
                companyName: this.dataStore.application?.companyProfile?.companyName,
                companyNumber: this.dataStore.companyNumber,
                acquisitionChannel: this.dataStore.contactInfoData?.acquisitionChannel,
                documentType: camelToKebabCase(documentType),
                file
            })
            if (status !== 200 || error || !result || result.length <= 0) {
                message.error(error.message || translations().errorUploadingDocument)
                isUploaded = false
            } else {
                runInAction(() => {
                    this.supportingDocumentsData.push({ ...result[0], internalFileName: file.name, type: documentType })
                })
                isUploaded = true
            }
        } catch (error) {
            message.error(translations().errorUploadingDocument)
            isUploaded = false
        }

        runInAction(() => {
            if (officer) {
                this.setCheckedDocumentLoading(documentType as CheckedDocumentType, officer, false)
            } else {
                this.isDocumentLoading[documentType] = false
            }
        })

        return isUploaded
    }

    async deleteFile(documentType: FileKey | CheckedDocumentType, fileName: string, officer?: string): Promise<boolean> {
        let isDeleted: boolean
        try {
            runInAction(() => {
                if (officer) {
                    this.setCheckedDocumentLoading(documentType as CheckedDocumentType, officer, true)
                } else {
                    this.isDocumentLoading[documentType] = true
                }
            })
            const path = this.supportingDocumentsData.find(d => d?.internalFileName === fileName && d?.type === documentType)?.path
            if (path) {
                const { status, error } = await deleteDocument(path)
                if (status !== 200 || error) {
                    message.error(error.message || translations().errorDeletingDocument)
                    isDeleted = false
                } else {
                    isDeleted = true
                }
            }
        } catch (error) {
            message.error(translations().errorDeletingDocument)
            isDeleted = false
        }

        runInAction(() => {
            if (officer) {
                this.setCheckedDocumentLoading(documentType as CheckedDocumentType, officer, false)
            } else {
                this.isDocumentLoading[documentType] = false
            }
        })
        return isDeleted
    }
}
