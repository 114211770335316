import translations from './translations'

translations.add('en', {
  title: 'Added products list',
  productName: 'Product Name',
  model: 'Model',
  count: 'Count',
  action: 'Action',
  delete: 'Delete'
})
