import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input, Typography } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useInjection } from 'dna-react-ioc'
import logo from '~/assets/img/apple-touch-icon.png'
import { AuthForm } from '~/code/components/auth/AuthForm'
import { Routes } from '~/code/startup/Router/Routes'
import { IRangePickerContainerStore } from '~/code/pages/settlements/SettlementsPayments/components/RangePickerContainer/IRangePickerContainerStore'
import { ISettlementsPaymentsTableContainerStore } from '~/code/pages/settlements/SettlementsPayments/components/SettlementsPaymentsTableContainer/ISettlementsPaymentsTableContainerStore'
import { SettlementsPaymentsTableContainerStoreSymbol } from '~/code/pages/settlements/SettlementsPayments/components/SettlementsPaymentsTableContainer/SettlementsPaymentsTableContainer'
import { SettlementsPaymentsRangePickerStoreSymbol } from '~/code/pages/settlements/SettlementsPayments'
import translations from './translations'
import styles from './Login.scss'
import { LoginProps } from './props'
import Link from 'antd/es/typography/Link'
import {
  LOGIN_EMAIL,
  LOGIN_PASSWORD,
  LOGIN_BUTTON
} from '~/code/constants/playwright-ids'

export const Login = observer(({ store }: LoginProps) => {
  const rangePickerStore = useInjection<IRangePickerContainerStore>(SettlementsPaymentsRangePickerStoreSymbol)
  const tableStore = useInjection<ISettlementsPaymentsTableContainerStore>(SettlementsPaymentsTableContainerStoreSymbol)

  // TODO move the logic of clearing the stores to the AppStore, when the AppStore becomes injectable
  useEffect(() => {
    tableStore.clear()
    rangePickerStore.clear()
  }, [])

  const header = (
    <>
      <img src={logo} alt={'logo'} className={styles.logo} />
      <Typography.Title level={1} className={styles.title}>
        {translations().title}
      </Typography.Title>
    </>
  )

  return (
    <AuthForm header={header}>
      <Form
        validateTrigger={[]}
        onFinish={({ email, ...rest }) => {
          store.login({ ...rest, email: email?.toLocaleLowerCase() })
        }}
      >
        <Form.Item
          name='email'
          rules={[
            { required: true, message: translations().emailRequired },
            { type: 'email', message: translations().emailInvalid }
          ]}
        >
          <Input size='large' prefix={<UserOutlined />} placeholder='email' className='lowercase' data-test-id={LOGIN_EMAIL} />
        </Form.Item>
        <Form.Item name='password' rules={[{ required: true, message: translations().passwordRequired }]}>
          <Input.Password size='large' prefix={<LockOutlined />} placeholder='password' data-test-id={LOGIN_PASSWORD} />
        </Form.Item>

        <div className={styles.forgotContainer}>
          <Link href={Routes.RESET_PASSWORD}>Forgot Password?</Link>
        </div>

        <Button size='large' type='primary' htmlType='submit' data-test-id={LOGIN_BUTTON} className={styles.button} loading={store.isLoading}>
          {translations().login}
        </Button>
      </Form>
    </AuthForm>
  )
})
