import { action, computed, makeObservable, observable, reaction, runInAction } from "mobx";
import { TerminalLoadingStatus, TerminalType } from '../components/StoreAndTerminalFilter/constants'
import { IStoreAndTerminalFilterStore, Store, Terminal, IStoreAndTerminalFilterParentStore } from '../components/StoreAndTerminalFilter/models'
import { fetchMerchantTerminals } from '../services/fetchers'

const all = 'all'

export class StoreAndTerminalFilterStore implements IStoreAndTerminalFilterStore {
    private _placeholderStore: Store = {
        location: all,
        name: all,
        nameLocation: all,
        terminals: []
    }

    private _placeholderTerminal: Terminal = {
        number: all
    }

    public availableTerminalTypes: TerminalType[] = []

    public terminalType: TerminalType = 'all'

    public store: Store = this._placeholderStore

    public terminal: Terminal = this._placeholderTerminal

    public mid: string = all

    public _stores: Store[] = []

    public allTerminals: Terminal[] =  []

    public allMids: string[] = []

    public terminalLoadingStatus: TerminalLoadingStatus = 'idle'

    constructor(
        private parentStore: IStoreAndTerminalFilterParentStore,
        private initialTerminalType: TerminalType
    ) {
        this.terminalType = initialTerminalType

        makeObservable(this, {
            availableTerminalTypes: observable,
            terminalType: observable,
            store: observable,
            terminal: observable,
            mid: observable,
            _stores: observable,
            allTerminals: observable,
            allMids: observable,
            terminalLoadingStatus: observable,

            merchantId: computed,
            stores: computed,
            terminals: computed,
            mids: computed,
            terminalsForFilter: computed,

            loadMerchantTerminals: action,
            setStore: action,
            setTerminal: action,
            setMid: action,
            setTerminalType: action
        })

        reaction(
            () => this.merchantId,
            () => {
                runInAction(() => {
                    this.terminalType = this.initialTerminalType
                    this.terminal = this._placeholderTerminal
                    this.availableTerminalTypes = []
                    this.allTerminals = []
                    this._stores = []
                    this.allMids = []
                    this.terminalLoadingStatus = 'idle'
                })
            }
        )
    }

    public get merchantId() {
        return this.parentStore.merchantId
    }

    public get stores () {
        if (this.terminalLoadingStatus === 'idle' || this.terminalLoadingStatus === 'failed') {
            this.loadMerchantTerminals()
        }

        if (this._stores.length > 1) {
            return [this._placeholderStore, ...this._stores]
        }

        return this._stores
    }

    public get terminals (): Terminal[] {
        if (this.allTerminals.length <= 0) return []

        const rawTerminals = this.allTerminals
            .filter(terminal => this.terminalType === 'all' || this.terminalType === terminal.type)
            .filter((terminal) => this.mid === 'all' || this.mid === terminal.mid)

        if (this.terminalType === 'pos') {
            if (this.store?.location === all) {
                if (rawTerminals.length >= 1) {
                    return [this._placeholderTerminal, ...rawTerminals]
                }
                return rawTerminals
            }
            if (this.mid !== 'all') {
                return [this._placeholderTerminal, ...this.store.terminals.filter((terminal) => terminal.mid === this.mid)]
            } else return [this._placeholderTerminal, ...this.store.terminals]
        }

        return [this._placeholderTerminal, ...rawTerminals]
    }

    public get mids() {
        if (this.terminalType !== 'all') {
            return Array.from(new Set(
                ['all', ...this.allTerminals
                    .filter((terminal) => terminal.type === this.terminalType)
                    .map((terminal) => terminal.mid)]
            ))
        } else if (this.store?.terminals && this.store?.terminals.length) {
            return Array.from(new Set(
              ['all', ...this.allTerminals
                .map((terminal) => terminal.mid)]
            ))
        } else return Array.from(new Set(this.allMids))
    }


    public get terminalsForFilter() {

        const terminals = [...(this.terminals?.slice(1) ?? [])]

        if (this._emptyTerminalsListCases) {
            return []
        }

        if (this.terminalType !== 'all') {

            if (this.store?.terminals.length) {

                if(this.terminal.number !== 'all') {
                    return [this.terminal.number]
                }

                if (this.mid !== 'all') {
                    return terminals
                        .filter((terminal) => terminal.type === this.terminalType)
                        .filter((terminal) => terminal.mid === this.mid)
                        .map(terminal => terminal.number)
                }
                return terminals.filter((terminal) => terminal.type === this.terminalType).map(terminal => terminal.number)
            }

            if(this.terminal.number !== 'all') {
                return [this.terminal.number]
            }

            return terminals.filter((terminal) => terminal.type === this.terminalType).map(terminal => terminal.number)
        } else if (this.mid !== 'all') {
            if (this.allMids.length > 2 && this.allTerminals.length > 1) {

                if(this.terminal.number !== 'all') {
                    return [this.terminal.number]
                }

                return terminals.filter((terminal) => terminal.mid === this.mid).map(terminal => terminal.number)
            } else {
                return [this.terminal.number]
            }
        } else return [this.terminal.number]
    }

    public loadMerchantTerminals = async () => {
        if (!this.merchantId || this.merchantId === 'all') {
            return
        }

        runInAction(() => {
            this.terminalLoadingStatus = 'loading'
        })

        const {result, error} = await fetchMerchantTerminals(this.merchantId, { type: this.terminalType })

        runInAction(() => {
            this.availableTerminalTypes = []
            if (error) {
                this.allTerminals = []
                this._stores = []
                this.terminalLoadingStatus = 'failed'
                return
            }

            if (!result) {
                this.allTerminals = []
                this._stores = []
                this.terminalLoadingStatus = 'finished'
                return
            }

            this._stores = result?.reduce((aggr, current): Store[] => {
                const currentNameLocation = `${current.name?.toUpperCase()}/${current.location?.toUpperCase()}`
                const alreadyAddedStore = aggr.find(store => `${store.name.toUpperCase()}/${store.location.toUpperCase()}` === currentNameLocation)

                if (current.type?.toLocaleLowerCase() === 'pos') {
                    if (alreadyAddedStore) {
                        alreadyAddedStore.terminals.push({
                            name: current.name,
                            number: current.number,
                            type: current.type?.toLocaleLowerCase(),
                            mid: current.mid
                        })
                    } else {
                        const newStore: Store = {
                            location: current.location?.toUpperCase(),
                            name: current.name?.toUpperCase(),
                            nameLocation: currentNameLocation,
                            terminals: [{
                                name: current.name,
                                number: current.number,
                                type: current.type?.toLocaleLowerCase() as ('ecom' | 'pos'),
                                mid: current.mid
                            }]
                        }
                        aggr.push(newStore)
                    }
                }

                if (!this.availableTerminalTypes.includes(current?.type?.toLocaleLowerCase() as TerminalType)) {
                    this.availableTerminalTypes.push(current.type.toLocaleLowerCase() as TerminalType)
                }
                return aggr
            }, [])

            if (this.availableTerminalTypes.length > 1) {
                this.availableTerminalTypes = ['all', ...this.availableTerminalTypes]
            }

            this.allMids = ['all', ...Array.from(new Set(result.map((terminal) => terminal.mid)))]
            this.allTerminals = [...result] as Terminal []

            this.terminalLoadingStatus = 'finished'

            this.terminalType = this.availableTerminalTypes[0]
            this.store = this.stores[0]
            this.terminal = this.terminals[0]
            this.mid = this.mids[0]
        })
    }

    public setTerminalType = (terminalType: TerminalType) => {
        this.terminalType = terminalType
        this.store = this.stores?.length > 0 && this.stores[0]
        this.terminal = this.terminals?.length > 0 && this.terminals[0]
        this.mid = this.mids[0]
    }

    public setStore = (storeNameLocation: string) => {
        this.store = this.stores.find(item => item.nameLocation.toUpperCase() === storeNameLocation.toUpperCase())
        this.terminal = this.terminals[0]
        this.mid = this.mids[0]
    }

    public setTerminal = (terminalNumber: string) => {
        this.terminal = this.terminals.find(item => item.number === terminalNumber)
    }

    public setMid = (mid: string) => {
        this.mid = mid
        this.terminal = this.terminals[0]
    }

    get isAllTerminalTypeSelected () {
        return (
            this.terminalType === 'all' ||
            (this.availableTerminalTypes?.length === 1 && ['ecom', 'pos'].includes(this.terminalType))
        )
    }

    get isAllStoreSelected () {
        return (
            this.store?.location === 'all' ||
            (this.stores?.length === 1 && this.stores[0].location === this.store?.location) ||
            !this.stores?.length
        )
    }

    get isAllTerminalSelected () {
        return (
            this.terminal?.number === 'all' || this.allTerminals?.length === 1
        )
    }

    get isAllMidsSelected() {
        return this.mid === 'all' || this.allMids.length === 2
    }

    private get _emptyTerminalsListCases () {
        return this._whenAllSelectedForAll ||
            this._whenNoAvailableTerminal
    }

    private get _whenAllSelectedForAll () {
        return this.isAllTerminalTypeSelected && this.isAllStoreSelected && this.isAllTerminalSelected && this.isAllMidsSelected
    }

    private get _whenNoAvailableTerminal () {
        return this.terminal === null || this.terminal === undefined
    }
}
