import { OfficerAddress } from '.'
import { getEmptyAddress } from '../services'
import { BirthDateModel } from './BirthDateModel'

export enum OfficerRole {
    authorisedSignatory = 'authorised-signatory',
    director = 'director',
    businessOwner = 'business-owner'
}

export class OfficerProfile {
    deletionAllowed?: boolean
    id?: string
    name?: string = ''
    nameElements?: {
        surname?: string
        forename?: string
        middleName?: string
        title?: string
    }
    gender?: string = undefined
    birthplace?: string = undefined
    nationality?: string = undefined
    dateOfBirth?: BirthDateModel = null
    officerRole?: OfficerRole
    occupation?: string = ''
    address?: OfficerAddress
    emailAddress?: string = ''
    telephoneNumber?: string = ''

    constructor (initialValue: OfficerProfile = {}) {
        Object.assign(this, {
            nameElements: {
                surname: '',
                forename: '',
                middleName: '',
                title: undefined
            },
            address: getEmptyAddress()
        }, initialValue)
    }
}