import { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  sign: string
  docuSign: string
  docuSignInfo: ReactNode
  signError: string
  preview: string
  alreadySentToSign: string
  update: string
  updateError: string
  signContract: string
  docuSignInfo2: ReactNode
  signedSuccessfully: string
  currentStatus: (status: string) => string
  signatoryEmail: string
  review: string
  detailsInfo: (firstName: string, lastName: string, dob: string, address: string) => ReactNode
  detailsAreCorrect: string
  backToForm: string
  doubleCheck: string
  refreshStatus: string
  needToChangeEmail: string
  alreadySent: string
  alreadySentCase: string
  amendData: string
  clickTheButton: string
  detailsWillBeUpd: string
  wontBeRequire: string
  signeeNotFound: string
  note: string
  cancellation: string
  cancellationText: ReactNode
  note2: string
  updateLogic: string
  docusignStatusCheck: ReactNode
  attention: string
  back: string
  revoke: string
  save: string
  willUploadManually: string
  resend: string
  resendText: string
  changeEmailText: string
  clickCancellation: string
  clickGenerate: string
}>()
