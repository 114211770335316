import React from 'react'
import { Typography, Tabs, Card, Skeleton, Divider, Grid, message, Empty } from 'antd'
import { PageHeaderRaw } from '~/code/components'
import { ArrowLeftOutlined, CopyOutlined } from '@ant-design/icons'
import styles from './styles.scss'
import { observer } from 'mobx-react'
import { ProcessProps } from './props'
import { tabKey } from './types'
import classNames from 'classnames'
import translations from './translations'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import { AttachedDocuments, JumioCheckResults, LexisNexisInfo } from './components'
import { ApplicationData } from './components/ApplicationData'
import { EcoSpendCheckResults } from './components/EcoSpendCheckResults'
import { isEmpty } from 'dna-common'

const { Title, Text } = Typography
const { TabPane } = Tabs

export const Process = observer((props: ProcessProps) => {
  const { store, type } = props
  const screen = Grid.useBreakpoint()

  const goBack = () => {
    type === 'onboarding' && goToRoute(Routes.BPM_REPORTS_ONBOARDING_PROCESS)
    type === 'additional' && goToRoute(Routes.BPM_REPORTS_ONBOARDING_PROCESSES_ADDITIONAL_STORE)
  }

  const renderCheckingResultsSection = (data: any) => {
    if (!data) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    return JSON.stringify(data, null, 2)
  }

  return (
    <div>
      <PageHeaderRaw>
        <div className={styles.header}>
          <div onClick={goBack} className={styles.arrowLeftContainer}>
            <ArrowLeftOutlined className={styles.arrowLeft} />
            {translations().backToProcesses}
          </div>
          <Title level={4} className={styles.title}>
            {store.isProcessInfoLoading ? (
              <Skeleton.Input style={{ width: 200 }} size='small' />
            ) : (
              store.processInfo?.companyName
            )}
          </Title>
          <div></div>
        </div>
      </PageHeaderRaw>
      <Card className={classNames(styles.card, { [styles.card__bigScreen]: !screen.xs })}>
        <Tabs
          tabPosition={screen.xs ? 'top' : 'left'}
          defaultActiveKey='applicationData'
          onChange={(key: tabKey) => store.onTabChange(key)}
        >
          <TabPane key='applicationData' tab={translations().applicationData}>
            <Title level={4} className={styles.tabTitle}>
              {translations().applicationData}
            </Title>
            {store.isApplicationDataLoading ? (
              <Skeleton />
            ) : (
              store.applicationData && (
                <>
                  <ApplicationData data={store.applicationData} />
                </>
              )
            )}
          </TabPane>
          <TabPane key='attachedDocuments' tab={translations().attachedDocuments}>
            <Title level={4} className={styles.tabTitle}>
              {translations().attachedDocuments}
            </Title>
            {store.isDocumentsLoading ? (
              <Skeleton />
            ) : (
              store.applicationData?.documents && (
                <AttachedDocuments store={store} docsList={store.applicationData.documents} />
              )
            )}
          </TabPane>
          <TabPane key='checkingResults' tab={translations().checkingResults}>
            <Title level={4} className={styles.tabTitle}>
              {translations().checkingResults}
            </Title>
            {store.isCheckingResultsLoading ? (
              <Skeleton />
            ) : (
              <div>
                <div>
                  <Title level={5}>{translations().lexisNexis}</Title>
                  <LexisNexisInfo data={store.checkingResults?.lexisNexis} store={store} />
                </div>
                <Divider />
                {!isEmpty(store.checkingResults?.ecospend) && (
                  <>
                    <div>
                      <Title level={5}>{translations().ecospend}</Title>
                      <EcoSpendCheckResults data={store.checkingResults?.ecospend} />
                    </div>
                    <Divider />
                  </>
                )}

                {!isEmpty(store.checkingResults?.jumio) && (
                  <>
                    <div>
                      <Title level={5}>{translations().jumio}</Title>
                      <JumioCheckResults data={store.checkingResults?.jumio} loadDocumentsData={store.loadDocumentsData} />
                    </div>
                    <Divider />
                  </>
                )}

                <div>
                  <Title level={5}>{translations().complianceAssist}</Title>
                  <pre>{renderCheckingResultsSection(store.checkingResults?.complianceAssist)}</pre>
                </div>
                <Divider />
                <div>
                  <Title level={5}>{translations().creditSafe}</Title>
                  <pre>{renderCheckingResultsSection(store.checkingResults?.creditSafe)}</pre>
                </div>
                <Divider />
                <div>
                  <Title level={5}>{translations().companiesHouse}</Title>
                  <pre>{renderCheckingResultsSection(store.checkingResults?.companiesHouse)}</pre>
                </div>
              </div>
            )}
          </TabPane>
          <TabPane key='notes' tab={translations().notes}>
            <Title level={4} className={styles.tabTitle}>
              {translations().notes}
            </Title>
            {store.isNotesLoading ? (
              <Skeleton />
            ) : (
              <div>
                <div>
                  <Title level={5}>{translations().complianceTeam}</Title>
                  <Text type='secondary'>
                    {store.notes?.compliance ? store.notes?.compliance : translations().noComments}
                  </Text>
                </div>
                <Divider />
                <div>
                  <Title level={5}>{translations().riskTeam}</Title>
                  <Text type='secondary'>{store.notes?.risk ? store.notes?.risk : translations().noComments}</Text>
                </div>
                <Divider />
                <div>
                  <Title level={5}>{translations().salesTeam}</Title>
                  <Text type='secondary'>
                    {store.notes?.salesSupport ? store.notes?.salesSupport : translations().noComments}
                  </Text>
                </div>
              </div>
            )}
          </TabPane>
        </Tabs>
      </Card>
    </div>
  )
})
