import React from 'react'
import { Card, Col, Divider, Row, Space, Typography } from 'antd'
import {
  NotificationTemplateEnum
} from '~/code/stores/NotificationManagementStore'
import Meta from 'antd/es/card/Meta'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import MaintenanceImg from '~/assets/img/maintenance.svg'
import PushImg from '~/assets/img/push_coming_soon.svg'
import MarketingImg from '~/assets/img/marketing_coming_soon.svg'
import { IManageNotificationTemplateStore } from '~/code/pages/Notifications'
import {
  ManageNotificationTemplateStoreSymbol
} from '~/code/pages/Notifications/ManageNotificationTemplates/components'
import translations from './translations'
import styles from './styles.scss'


export const SelectTemplateStep = observer(() => {
  const store = useInjection<IManageNotificationTemplateStore>(ManageNotificationTemplateStoreSymbol)

  const {setTemplateType} = store

  return (
    <>
      <Card>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8}>
            <Card
              className={styles.templateCard}
              hoverable={true}
              onClick={() => setTemplateType(NotificationTemplateEnum.MAINTENANCE)}
              cover={
                <img
                  className={styles.cardImg}
                  alt={translations().maintenanceImage}
                  src={MaintenanceImg}
                />
              }
            >
              <Meta
                title={translations().maintenanceIncident}
                description={translations().creatingMaintenance}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card
              className={styles.templateCard}
              hoverable={true}
              cover={
                <img
                  className={styles.cardImg}
                  alt={translations().pushImage}
                  src={PushImg}
                />
              }
            >
              <Meta
                title={translations().push}
                description={translations().comingSoon}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card
              className={styles.templateCard}
              hoverable={true}
              cover={
                <img
                  className={styles.cardImg}
                  alt={translations().marketingImage}
                  src={MarketingImg}
                />
              }
            >
              <Meta
                title={translations().marketing}
                description={translations().comingSoon}
              />
            </Card>
          </Col>
        </Row>
        <Divider />
        <Space size="small" direction="vertical" style={{ width: '100%' }}>
          <Row>
            <Col span={24}>
              <Typography.Title level={5}>{translations().instructions}</Typography.Title>
            </Col>
          </Row>
          <Row gutter={[0, 0]}>
            <Col span={24}>
              <Typography.Text type="secondary">{translations().typeSelection}</Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text>{translations().selectType}</Typography.Text>
            </Col>
          </Row>
          <Row gutter={[0, 0]}>
            <Col span={24}>
              <Typography.Text type="secondary">{translations().fillingDetails}</Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text>{translations().makeSure}</Typography.Text>
            </Col>
          </Row>
          <Row gutter={[0, 0]}>
            <Col span={24}>
              <Typography.Text type="secondary">{translations().preview}</Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text>{translations().requiredPreview}</Typography.Text>
            </Col>
          </Row>
        </Space>
      </Card>
    </>
  )
})