import { CaptureMethod, CardScheme, Status } from './FilterModels'

export class MonitoringPosTransactionFilterModel {
    status: Status = 'all'
    transactionChannel: string[] = []
    transactionId: string = ''
    captureMethod: CaptureMethod = 'all'
    cardScheme: CardScheme = 'all'
    terminalId: string = ''
    cardMask: string = ''
    mid: string = 'all'
    amountFrom: string
    amountTo: string
}
