import React from 'react'
import { Modal, Button, Typography } from 'antd'
import { observer } from 'mobx-react'
import { ClosureOfMerchantConfirmationModalProps } from './props'
import translations from './translations'
import styles from './ClosureOfMerchantConfirmationModal.scss'

export const ClosureOfMerchantConfirmationModal = observer((props: ClosureOfMerchantConfirmationModalProps) => {
  const {
    open,
    isCloseMerchantLoading,
    isMerchantActive,
    openModal,
    onCancel,
    onOk,
    companyName,
    isSubsidiaryForFee,
    terminationFee
  } = props

  return (
    <div className={styles.buttonContainer}>
      <Button type='primary' disabled={!isMerchantActive} onClick={openModal}>
        {translations().submit}
      </Button>
      <Modal
        open={open}
        onOk={onOk}
        okText={translations().confirmClosure}
        okButtonProps={{ loading: isCloseMerchantLoading }}
        onCancel={onCancel}
        title={<Typography.Title level={3}>{translations().closureOfMerchant}</Typography.Title>}
      >
        {translations().areYouSureCloseMerchant(companyName)}
        {isSubsidiaryForFee ? translations().ensureTerminationFee(terminationFee) : null}
      </Modal>
    </div>
  )
})
