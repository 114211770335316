import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    startDate: string
    merchant: string
    status: string
    assignedGroup: string
    assignedUser: string
    reviewTime: string
    endDate: string
}>()