import translations from './translations'

translations.add('en', {
    title: 'Export POS transactions',
    columns: 'Columns',
    cancel: 'Cancel',
    ok: 'Export',
    all: (value) => `All (${value})`,
    custom: 'Custom'
})
