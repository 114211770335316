import { inject, injectable } from 'inversify'
import { makeObservable, observable, runInAction, reaction, action } from 'mobx'
import { message } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { log } from 'dna-common'
import { ItemModel } from '~/code/models/handbooks'
import { AppStore } from '~/code/stores/AppStore'
import { IStoresDossierV2Store } from '~/code/pages/MerchantsManagement/components/Stores/models'
import { StoresDossierV2StoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores'
import { ICloseStoreModalStore } from '~/code/pages/MerchantsManagement/components/Stores/components/CloseStoreModal/ICloseStoreModalStore'
import { ISalesForceSearchStore } from '~/code/pages/MerchantsManagement/components/SalesForceSearch/ISalesForceSearchStore'
import { SalesForceDataResType, CloseStoreReqType, ClosureStoretDataType } from './models/merchant-dossier-v2'
import { startProcessCloseStore, getMerchantClosureReasons } from './services/fetchers'
import { SalesForceSearchStore } from './SalesForceSearchStore'
import { stringType, jsonType, closeMerchantOrStoreType, booleanType } from './constants'
import translations from './translations'

@injectable()
export class CloseStoreModalStore implements ICloseStoreModalStore {
  storesDossierV2Store: IStoresDossierV2Store
  reason: string = null
  isCloseStoreLoading: boolean = false
  isClosureReasonsLoading: boolean = false
  closureReasonsList: ItemModel[] = []
  salesForceSearchStore: ISalesForceSearchStore
  salesForceDataFound: boolean = false
  salesForceData: SalesForceDataResType = null
  constructor(@inject(StoresDossierV2StoreSymbol) storesDossierV2Store: IStoresDossierV2Store) {
    this.storesDossierV2Store = storesDossierV2Store
    this.salesForceSearchStore = new SalesForceSearchStore()
    makeObservable(this, {
      reason: observable,
      isCloseStoreLoading: observable,
      salesForceDataFound: observable,
      closureReasonsList: observable,
      salesForceData: observable,
      setSalesForceData: action,
      loadClosureReasons: action,
      closeStore: action,
      closeModal: action
    })
    reaction(
      () => this.salesForceSearchStore.salesForceDataResult,
      salesForceDataResult => {
        this.salesForceSearchStore.salesForceSuccessfulValidation
          ? this.setSalesForceData(true, salesForceDataResult)
          : this.setSalesForceData(false, null)
      }
    )
  }

  setSalesForceData(isFound: boolean, salesForceData: SalesForceDataResType) {
    this.salesForceDataFound = isFound
    this.salesForceData = salesForceData
  }

  closeModal = (form: FormInstance) => {
    form.resetFields()
    this.storesDossierV2Store.closeCloseStoreModal()
  }

  async loadClosureReasons(): Promise<void> {
    try {
      runInAction(() => {
        this.isClosureReasonsLoading = true
      })
      const { status, error, result } = await getMerchantClosureReasons()
      if (status !== 200 || error) {
        message.error(error.message || translations().errorLoadingMerchantClosureReasons)
      } else {
        this.closureReasonsList = result?.map(reason => {
          return { value: reason.key, label: reason.value }
        })
      }
    } catch (error) {
      message.error(translations().errorLoadingMerchantClosureReasons)
    } finally {
      runInAction(() => {
        this.isClosureReasonsLoading = false
      })
    }
  }

  createCloseStoreData(data: ClosureStoretDataType): CloseStoreReqType {
    const { reason, comment, dsrId, storeId } = data
    return {
      variables: {
        dossierId: {
          value: dsrId.toString(),
          type: stringType
        },
        initiatorEmail: {
          value: AppStore.authStore.email,
          type: stringType
        },
        processType: {
          value: closeMerchantOrStoreType.closeStore,
          type: stringType
        },
        closedStoresOriginId: {
          value: JSON.stringify([storeId]),
          type: jsonType
        },
        comment: {
          value: comment,
          type: stringType
        },
        reason: {
          value: reason,
          type: stringType
        },
        subsidiariesMerchant: {
          value: false,
          type: booleanType
        },
        caseIdSalesForce: {
          value: this.salesForceData?.id || '',
          type: stringType
        }
      }
    }
  }

  async closeStore(form: FormInstance, storeId: string) {
    const { comment, reason } = form.getFieldsValue()
    const { dsrId } = this.storesDossierV2Store.companyFullDossierV2?.mainInfo

    if (!dsrId) {
      message.error(translations().dsrIdIsEmpty)
      return
    }

    if (!storeId) {
      message.error(translations().storeIdIsEmpty)
      return
    }

    try {
      runInAction(() => {
        this.isCloseStoreLoading = true
      })

      const closeStoreDossierV2Req = this.createCloseStoreData({
        dsrId,
        storeId,
        comment,
        reason
      })
      const { status, error } = await startProcessCloseStore(closeStoreDossierV2Req)

      if (status !== 200 || error) {
        message.error(error.message)
      } else {
        form.resetFields()
        message.success(translations().applicationSuccessful)
        this.storesDossierV2Store.closeCloseStoreModal()
      }
    } catch (error) {
      message.error(error?.message)
      log(error)
    } finally {
      runInAction(() => {
        this.isCloseStoreLoading = false
      })
    }
  }
}
