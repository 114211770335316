import { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  title: string
  details: ReactNode
  openBankStatus: (status: string) => string
  creditSafeStatus: (status: boolean) => string
  notVerified: string
}>()
