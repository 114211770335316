import React, { useMemo, useState } from 'react'
import { Form, Modal, Switch as AntSwitch, Input, Tooltip, Alert } from 'antd'
import { SwitchProps } from './props'
import { MAX_COMMENT_LENGTH, MIN_COMMENT_LENGTH } from './constants'
import translations from './translations'
import styles from './styles.scss'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import classNames from 'classnames'

export const Switch = (props: SwitchProps) => {
    const {
        isEnabled,
        enabledText = translations().on,
        disabledText = translations().off,
        toggleText,
        isLoading,
        updateTransactionChannel,
        isOnlyOneTransactionChannelEnabled,
        switchType
    } = props

    const cannotDisable = useMemo(() => {
        return isEnabled && isOnlyOneTransactionChannelEnabled
    }, [isEnabled, isOnlyOneTransactionChannelEnabled])

    const hasPermissionToUpdate = hasPermissions([PermissionMap.transactions.channels.update])

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const openModal = () => {
        setIsModalOpen(true)
    }
    const closeModal = () => setIsModalOpen(false)

    const getModalTitle = () => translations().areYouSureToggle(isEnabled, toggleText)
    const warningAlert = (
        <div className={classNames({ [styles.alertRed]: isEnabled })}>
            <Alert
                message={translations().warningText(isEnabled, toggleText)}
                description={isEnabled ? translations().onceDisabled(switchType) : null}
                type='warning'
                showIcon
            />
        </div>
    )

    const [confirmToggleValue, setConfirmToggleValue] = useState<string>('')
    const confirmComponent = (
        <div className={styles.confirmContainer}>
            <div>{translations().pleaseTypeToConfirm}:</div>
            <div className={styles.confirmToggleText}>{toggleText}</div>
            <Input value={confirmToggleValue} onChange={e => setConfirmToggleValue(e.target.value)} />
        </div>
    )
    const okButtonProps = { disabled: confirmToggleValue !== toggleText }

    const renderModalWithComment = () => {
        const [form] = Form.useForm()

        const onFinish = async (values: { comment: string }) => {
            await updateTransactionChannel(isEnabled, values.comment)
            closeModal()
            form.resetFields()
        }

        const onCancel = () => {
            closeModal()
            form.resetFields()
        }

        return <Modal
            open={isModalOpen}
            onOk={cannotDisable ? closeModal : form.submit}
            okButtonProps={okButtonProps}
            onCancel={onCancel}
            confirmLoading={isLoading}
            title={getModalTitle()}
        >
            {cannotDisable ? (
                <div>{translations().pleaseEnableOneChannel}</div>
            ) : (
                <>
                    {warningAlert}
                    <Form form={form} onFinish={onFinish} className={styles.form}>
                        <Form.Item
                            name='comment'
                            label={ translations().comment }
                            rules={[
                                {
                                    required: true,
                                    message: translations().commentRequired
                                },
                                {
                                    min: MIN_COMMENT_LENGTH,
                                    message: translations().commentMinMsg(MIN_COMMENT_LENGTH)
                                }
                            ]}
                        >
                            <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount maxLength={ MAX_COMMENT_LENGTH } minLength={ MIN_COMMENT_LENGTH }/>
                        </Form.Item>
                    </Form>
                    {confirmComponent}
                </>
            )}
        </Modal>
    }

    const renderModalWithoutComment = () => {
        const onOk = async () => {
            await updateTransactionChannel(isEnabled)
            closeModal()
        }

        return <Modal
            open={isModalOpen}
            onOk={onOk}
            okButtonProps={okButtonProps}
            onCancel={closeModal}
            confirmLoading={isLoading}
            title={getModalTitle()}
        >
            {warningAlert}
            {confirmComponent}
        </Modal>
    }

    const antSwitch = (
        hasPermissionToUpdate ? (
                <AntSwitch
                checked={isEnabled}
                checkedChildren={enabledText}
                unCheckedChildren={disabledText}
                onClick={openModal}
            />
        ) : (
            <Tooltip title={translations().youDontHavePermissionsToUpdate}>
                <AntSwitch
                    checked={isEnabled}
                    checkedChildren={enabledText}
                    unCheckedChildren={disabledText}
                    disabled
                />
            </Tooltip>
        )
    )

    return <>
        {antSwitch}
        {switchType === 'channel' ? renderModalWithComment() : renderModalWithoutComment()}
    </>
}