import React from 'react'
import translations from './translations'

translations.add('en', {
    id: 'ID',
    date: 'Issue Date',
    period: 'Period',
    merchantName: 'Merchant',
    amount: 'Amount',
    currency: 'Currency',
    status: 'Status',
    reset: 'Reset',
    mid: 'MID',
    download: 'Download'
})
