import { message } from 'antd'
import { injectable } from 'inversify'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { PAGE_SIZE_500 } from '~/code/constants/Configurations'
import { AcquiringType } from '~/code/pages/Acquiring'
import { isPartner } from '~/code/services/auth'
import { fetchMerchants, fetchTransactionsAcquisitionChannels } from '~/code/services/fetchers'
import { IMerchantSelectStore } from '../../components/MerchantSelect/models'
import { MerchantProductType, TransactionsAcquisitionChannelModel } from '../../models'
import { MerchantModel } from '../../models/handbooks'
import translations from './translations'
import storage from '~/code/services/storage'

@injectable()
export class MerchantSelectStore implements IMerchantSelectStore {
  isInitiated = false

  isAcquisitionChannelsLoading: boolean = false
  acquisitionChannel: TransactionsAcquisitionChannelModel = { id: 'all', name: 'ALL', isActive: true }
  tempAcquisitionChannel: TransactionsAcquisitionChannelModel = null
  _acquisitionChannels: TransactionsAcquisitionChannelModel[] = []

  isMerchantSelectLoading: boolean = false
  isMerchantListLoading: boolean = false
  isPagingMerchantListLoading: boolean = false
  merchant: MerchantModel = null
  tempMerchant: MerchantModel = null
  merchants: MerchantModel[] = []
  inputValue: string = ''

  pageNumberMerchants: number = 1
  merchantsTotalCount: number = 0

  acquirer: AcquiringType = undefined
  merchantProduct: MerchantProductType = undefined

  isModalOpen: boolean = false

  constructor({ acquirer, merchantProduct }: { acquirer: AcquiringType, merchantProduct?: MerchantProductType }) {
    this.acquirer = acquirer
    this.merchantProduct = merchantProduct

    makeObservable(this, {
      isAcquisitionChannelsLoading: observable,
      acquisitionChannel: observable,
      tempAcquisitionChannel: observable,
      _acquisitionChannels: observable,

      isMerchantListLoading: observable,
      isMerchantSelectLoading: observable,
      isPagingMerchantListLoading: observable,
      merchant: observable,
      tempMerchant: observable,
      merchants: observable,
      inputValue: observable,

      pageNumberMerchants: observable,
      merchantsTotalCount: observable,
      isModalOpen: observable,

      acquisitionChannels: computed,
      hasAcquisitionChannels: computed,
      isOkBtnDisabled: computed,
      isSolidGate: computed,

      setAcquisitionChannel: action,
      setTempAcquisitionChannel: action,
      setMerchant: action,
      setTempMerchant: action,
      setInputValue: action,

      openModal: action,
      closeModal: action,

      loadAcquisitionChannels: action,
      loadMerchants: action,
      handleOk: action
    })
  }

  get acquisitionChannels() {
    return this._acquisitionChannels.filter(a => a.isActive)
  }

  get hasAcquisitionChannels() {
    return this.acquisitionChannels.length > 0
  }

  get isOkBtnDisabled() {
    return !this.tempMerchant?.id || this.merchants.length === 0
  }

  get isSolidGate() {
    return isPartner() && storage.get('acquisition_channel') === 'solidgate'
  }

  public init = async () => {
    this.isInitiated = true
    this.setIsMerchantSelectLoading(true)
    if (!isPartner()) {
      await this.initAcquisitionChannels()
    }
    await this.initMerchants()

    this.setIsMerchantSelectLoading(false)
  }

  private initAcquisitionChannels = async () => {
    await this.loadAcquisitionChannels()
    if (this.acquisitionChannels.length > 0) {
      this.setAcquisitionChannel(this.acquisitionChannels[0]?.id)
      this.setTempAcquisitionChannel(this.acquisitionChannels[0]?.id, false)
    } else {
      this.setAcquisitionChannel(null)
      this.setTempAcquisitionChannel(null)
    }
  }

  private initMerchants = async () => {
    await this.loadMerchants({ type: 'normal' })
    if (this.merchants.length > 0) {
      this.setMerchant(this.merchants[0].id)
      this.setTempMerchant(this.merchants[0].id)
    } else {
      this.setMerchant('')
      this.setTempMerchant('')
    }
  }

  handleOk = () => {
      this.setAcquisitionChannel(this.tempAcquisitionChannel?.id)
      this.setMerchant(this.tempMerchant?.id)
      this.setInputValue('')
      this.closeModal()
  }

  handleScroll = () => {
    if (!this.isPagingMerchantListLoading) {
      this.loadMerchants({
        type: 'paging',
        acquisitionChannelId: this.tempAcquisitionChannel?.id,
        value: this.inputValue ? this.inputValue : undefined
      })
    }
  }

  handleInputValueChange = () => {
    if (!this.isMerchantListLoading && this.isModalOpen) {
      this.loadMerchants({ type: 'normal', acquisitionChannelId: this.tempAcquisitionChannel?.id, value: this.inputValue })
    }
  }

  handleClear = () => {
    if (!this.tempMerchant) return
    this.setTempMerchant(null)
    this.loadMerchants({ type: 'normal', acquisitionChannelId: this.tempAcquisitionChannel?.id })
  }

  handleCancel = async () => {
    this.closeModal()

    if (this.onCancelSetTempAcquisitionChannel()) {
      await this.setTempAcquisitionChannel(this.acquisitionChannel?.id)
    }

    this.setTempMerchant(this.merchant?.id)
    this.setInputValue('')
  }

  private onCancelSetTempAcquisitionChannel = (): boolean => {
    return !!this.inputValue || (!isPartner() && this.acquisitionChannel?.id !== this.tempAcquisitionChannel?.id)
  }

  setAcquisitionChannel = (id: string) => {
    this.acquisitionChannel = this.acquisitionChannels.find(a => a.id === id)
  }

  setTempAcquisitionChannel = async (id: string, loadMerchants: boolean = true) => {
    this.tempAcquisitionChannel = this.acquisitionChannels.find(a => a.id === id)
    if (loadMerchants) {
      await this.loadMerchantsAndSetDefaultTempMerchant()
    }
  }

  setMerchant = (id: string) => {
    id === 'all' ? this.merchant = {tradeName: translations().all, acquirer: null, id: 'all'} : this.merchant = this.merchants.find(m => m.id === id)
  }

  setTempMerchant = (id: string) => {
    this.tempMerchant = this.merchants.find(m => m.id === id)
  }

  setIsMerchantSelectLoading = (value: boolean) => {
    this.isMerchantSelectLoading = value
  }

  setInputValue = (value: string) => {
    this.inputValue = value
  }

  openModal = () => {
    this.isModalOpen = true
  }

  closeModal = () => {
    this.isModalOpen = false
  }

  loadAcquisitionChannels = async () => {
    try {
      runInAction(() => {
        this.isAcquisitionChannelsLoading = true
      })

      const { status, error, result } = await fetchTransactionsAcquisitionChannels()

      if (status !== 200 || error) {
      message.error(error.message || translations().errLoadingAcquisitionChannels)
    } else {
      runInAction(() => {
        this._acquisitionChannels = [{ id: 'all', isActive: true, name: 'ALL' }, ...result]
      })
    }
    } catch (error) {
      message.error(translations().errLoadingAcquisitionChannels)
    } finally {
      runInAction(() => {
        this.isAcquisitionChannelsLoading = false
      })
    }
  }

  loadMerchants = async ({ type, acquisitionChannelId, value }: { type: 'normal' | 'paging', acquisitionChannelId?: string, value?: string }) => {
    if (type === 'normal') {
      this.pageNumberMerchants = 1
      this.isMerchantListLoading = true
    } else {
      if (this.merchantsTotalCount <= this.merchants.length) return
      this.pageNumberMerchants++
      this.isPagingMerchantListLoading = true
    }

    const { error, result } = await fetchMerchants({
      product: this.merchantProduct,
      acquisitionChannel: acquisitionChannelId === 'all' ? undefined : acquisitionChannelId,
      // TODO: refactor acquirer to dynamic value depended on user_type and acquisition_channel DAS-359
      acquirer: this.isSolidGate ? 'paynetics_eu' : this.acquirer,
      value: value ? value : undefined,
      page: this.pageNumberMerchants,
      size: PAGE_SIZE_500
    })

    const sortedResult = result && result.data?.sort((a, b) => a.tradeName.toLocaleLowerCase().localeCompare(b.tradeName.toLocaleLowerCase()))

    runInAction(() => {
      if (error) {
        this.isMerchantListLoading = false
        this.merchants = []
        return
      }

      this.merchantsTotalCount = result?.totalCount

      if (type === 'normal') {
        if (!sortedResult || sortedResult.length === 0) {
          this.merchants = []
        } else if (sortedResult.length === 1) {
          this.merchants = sortedResult
        } else {
          this.merchants = value ? sortedResult : [
            {
              tradeName: translations().all,
              acquirer: null,
              id: 'all'
            },
            ...sortedResult
          ]
        }

        this.isMerchantListLoading = false
      } else {
        this.merchants = [...this.merchants, ...sortedResult]
        this.isPagingMerchantListLoading = false
      }
    })
  }

  loadMerchantsAndSetDefaultTempMerchant = async () => {
    await this.loadMerchants({ type: 'normal', acquisitionChannelId: this.tempAcquisitionChannel?.id })
    if (this.merchants?.length > 0) {
      this.setTempMerchant(this.merchants[0].id)
    } else {
      this.setTempMerchant(null)
    }
  }
}
