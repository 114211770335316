import { Table, Form, FormInstance, Row, Col } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import merge from 'lodash/merge'
import { PricingStore } from '~/code/pages/StartApplication/stores/PricingStore'
import { FormSubgroupTitle } from '../../../shared'
import { IcTariffType } from '../../models/IcTariffType'
import { getMarkup, getTariffsInitialValues } from '../../services'
import translations from '../../translations'
import { NumberFormItem } from '../NumberFormItem'
import styles from './styles.scss'

type ICPlusTableProps = {
    store: PricingStore
    form: FormInstance<any>
    initialValues: any
}

export const ICPlusTable = observer((props: ICPlusTableProps) => {
    const { store, form, initialValues } = props

    const mergedInitialValues = useMemo(() => {
        return merge({}, getTariffsInitialValues(store.filteredtariffsList), initialValues)
    }, [initialValues, store.filteredtariffsList])

    return <Form
        form={form}
        initialValues={mergedInitialValues}
    >
        <div className={styles.headlineRateFormContainer}>
            <FormSubgroupTitle>{translations().headlineRate}</FormSubgroupTitle>
            <Row gutter={16}>
                <Col xs={24} md={12} lg={6}>
                    <NumberFormItem name={['dnapConsumerDebit', 'percent']} label={'Consumer Debit'} noZeroValue={true}/>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <NumberFormItem name={['dnapConsumerCredit', 'percent']} label={'Consumer Credit'} noZeroValue={true}/>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <NumberFormItem name={['dnapCorporateDebit', 'percent']} label={'Corporate Debit'} noZeroValue={true}/>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <NumberFormItem name={['dnapCorporateCredit', 'percent']} label={'Corporate Credit'} noZeroValue={true}/>
                </Col>                
            </Row>            
        </div>

        <Table
            loading={store.isTariffsLoading}
            columns={columns}
            dataSource={store.filteredtariffsList?.map((p, idx) => ({ key: idx, ...p }))}
            pagination={false}
            size='small'
            bordered
        />
    </Form>
})

const columns: ColumnsType<IcTariffType> = [
    {
        title: translations().transactionType,
        children: [
            {
                key: 'cardScheme',
                dataIndex: 'cardScheme',
                title: translations().cardScheme
            },
            {
                key: 'serviceArea',
                dataIndex: 'serviceArea',
                title: translations().serviceArea,
                width: 200
            },
            {
                key: 'corporateOrConsumer',
                dataIndex: 'corporateOrConsumer',
                title: translations().corporateOrConsumer,
                width: 110
            },
            {
                key: 'cardType',
                dataIndex: 'cardType',
                title: translations().cardType
            }
        ]
    },
    {
        title: 'Interchange fee',
        children: [
            {
                key: 'interchangeFee',
                dataIndex: 'interchangeFee',
                title: translations().percentFee,
                render: (v) =>  renderCell([v.key, 'percent'])
            }
        ]
    },
    {
        title: 'Scheme fee',
        children: [
            {
                key: 'schemeBaseFee',
                dataIndex: 'schemeFee',
                title: translations().baseFee,
                render: (v) =>  renderCell([v.key, 'base'])
            },
            {
                key: 'schemePercentFee',
                dataIndex: 'schemeFee',
                title: translations().percentFee,
                render: (v) =>  renderCell([v.key, 'percent'])
            }
        ]
    },
    {
        title: () => <div>DNA Standard fee</div>,
        children: [
            {
                title: 'Headline rate (%)',
                render: (_, row) =>  renderCell([getMarkup(row), 'percent'])
            }
        ]
    },
    {
        title: 'Total fee',
        children: [
            {
                key: 'totalBaseFee',
                dataIndex: 'totalBaseFee',
                title: translations().baseFee,
                render: (_, row) => <strong>{row.schemeFee.baseFee}</strong>
            },
            {
                key: 'totalPercentFee',
                dataIndex: 'totalPercentFee',
                title: translations().percentFee,
                render: (_, row) => {
                    return <Form.Item shouldUpdate noStyle>
                        {(form) => {
                            const interchangeFee = Number(form.getFieldValue([row.interchangeFee.key, 'percent']) || 0)
                            const schemeFee = Number(form.getFieldValue([row.schemeFee.key, 'percent']) || 0)
                            const dnaFee = Number(form.getFieldValue([getMarkup(row), 'percent']) || 0)

                            return <strong>{(interchangeFee + schemeFee + dnaFee).toFixed(3)}</strong>
                        }}
                    </Form.Item>
                }
            }
        ]
    }
]

const renderCell = (name: string[]) => {
    return <Form.Item name={name} noStyle>
        <ReadOnly />
    </Form.Item>
}

const ReadOnly: React.FC<{ value?: string }> = (props) => <span>{props.value}</span>
