import { Card, Col, Row, Table } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import { withAppStore } from '~/code/hocs/withAppStore'
import { WithAppStoreProps } from '~/code/models'
import translations from './translations'

const columns = [
  {
    title: translations().status,
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: translations().quantity,
    dataIndex: 'quantity',
    key: 'quantity'
  },
  {
    title: translations().sum,
    dataIndex: 'sum',
    key: 'sum'
  },
  {
    title: translations().sumShare,
    dataIndex: 'sumShare',
    key: 'sumShare'
  }
]

const TransactionsView = observer((props: WithAppStoreProps) => {

  return <Card
  loading={false}
  bordered={false}
  title={ translations().title }
  style={{
    height: '100%'
  }}
>
  <Table<any>
    rowKey={(record) => record.index}
    size='small'
    columns={columns}
    dataSource={[]}
    pagination={false}
  />
</Card>
})

export const Transactions = withAppStore(TransactionsView)