import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    selectTemplate: string
    selectRecipient: string
    preview: string
    publishNotification: string
    web: string
    mobile: string
    recipientsPreview: string
    notificationsPreview: string
    startDate: string
    endDate: string
    publishToSelected: string
    publishToAll: string
    acquisitionChannel: string
    merchantName: string
    firstName: string
    lastName: string
    email: string
    userRole: string
    templateDetails: string
    maintenanceIncident: string,
    push: string,
    marketing: string,
    publish: string,
    next: string,
    back: string
}>()

