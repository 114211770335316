import { observer } from 'mobx-react'
import React, { useRef, useEffect } from 'react'
import Chart from 'chart.js'
import numeral from 'numeral'
import { isEmpty } from 'dna-common'
import styles from './LineChart.scss'
import { LineChartProps } from './props'

export const LineChart = observer((props: LineChartProps) => {
    const {
        data, yAxisNumberFormat, renderTooltipTitle, renderTooltipLabel, renderXAxisLabel,
        renderXAxisLabelSecond, rerenderChartDependencyList, legendPosition
    } = props
    const chartRef = useRef(null)

    useEffect(() => {
        createChart()
    }, rerenderChartDependencyList ? rerenderChartDependencyList : [])

    const getXAxes = () => {
        const xAxes = []

        xAxes.push({
            gridLines: { display: false, drawBorder: false },
            ticks: {
                callback(value, index, values) {
                    return renderXAxisLabel(value, values, index)
                }
            }
        })

        if (renderXAxisLabelSecond) {
            xAxes.push({
                gridLines: { drawOnChartArea: false, display: false, drawBorder: false },
                ticks: {
                    callback(value, index, values) {
                        return renderXAxisLabelSecond(value, values)
                    }
                }
            })
        }

        return xAxes
    }

    const createChart = () => {
        const ctx = chartRef.current.getContext('2d')
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: getXAxes(),
                yAxes: [{
                    gridLines: { drawBorder: false },
                    ticks: {
                        min: 0,
                        beginAtZero: true,
                        callback(value, index, values) {
                            return value === Math.round(value) ? numeral(value).format(yAxisNumberFormat || '0.00a') : null
                        }
                    }
                }]
            },
            legend: {
                display: !isEmpty(legendPosition),
                position: legendPosition
            },
            tooltips: {
                callbacks: {
                    title: (param) => {
                        return renderTooltipTitle(param[0].label)
                    },
                    label: ({ datasetIndex, index, label, value }) => {
                        return renderTooltipLabel(value)
                    }
                }
            }
        }
        
        const chart = new Chart(ctx, {
            type: 'line',
            data,
            options
        })
    }

    return <div className={styles.LineChart}>
        <canvas
            ref={chartRef}
        />
    </div>
})
