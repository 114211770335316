import React from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Card, Table } from 'antd'
import { IBPMProcessDossierV2 } from './models'
import { BPMProcessFilterDossierV2 } from './components'
import { BPMProcessDossierV2Props } from './props'
import styles from './BPMProcessDossierV2.scss'

export const BPMProcessDossierV2 = observer((props: BPMProcessDossierV2Props) => {
  const {
    BPMProcessesStoreSymbol,
    BPMProcessesRangePickerStoreSymbol,
    BPMProcessesMerchantSelectStoreSymbol,
    columns
  } = props

  const store = useInjection<IBPMProcessDossierV2>(BPMProcessesStoreSymbol)

  React.useEffect(() => {
    store.init()
  }, [])

  const dataSource = store.processes && store.processes.map((application, idx) => ({ key: idx, ...application }))

  return (
    <>
      <BPMProcessFilterDossierV2
        BPMProcessesStoreSymbol={BPMProcessesStoreSymbol}
        BPMProcessesRangePickerStoreSymbol={BPMProcessesRangePickerStoreSymbol}
        BPMProcessesMerchantSelectStoreSymbol={BPMProcessesMerchantSelectStoreSymbol}
      />
      <Card className={styles.tableCard}>
        <Table
          loading={store.isProcessesLoading}
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: 200 }}
          rowClassName={styles.row}
          pagination={{
            total: store.total,
            pageSize: store.pageSize,
            current: store.currentPage,
            onChange: (page, pageSize) => store.loadProcesses({ page, pageSize }),
            size: 'small'
          }}
        />
      </Card>
    </>
  )
})
