import translations from './translations'

translations.add('en', {
    type: 'Type',
    maintenanceIncident: 'Maintenance/Incident',
    push: 'Push',
    marketing: 'Marketing',
    description: 'Description',
    title: 'Title',
    merchantPortal: 'Merchant Portal',
    dashboard: 'Dashboard',
    bpm: 'BPM',
    name: 'Name',
    system: 'System',
    all: 'All',
    banner: 'Banner',
    popup: 'Popup',
    block: 'Full block',
    displayType: 'Display Type'
})
