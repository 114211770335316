import React from 'react'
import translations from './translations'

translations.add('en', {
  companyProfile: 'Company Profile',
  yes: 'Yes',
  no: 'No',

  companyName: 'Company name',
  companyTradeName: 'Trading name',
  dateOfCreation: 'Trading from',
  merchantCategoryCode: 'Merchant category code',
  numberOfEmployees: 'Number of employees',
  companyWebsite: 'Company website',
  natureOfAccountTransactions: 'Payment methods',
  tradeOutsideEea: 'Is trading outside EEA?',
  tradingCountriesOutsideEea: 'Trading countires outside EEA',
  emailAddress: 'Company email address',
  telephoneNumber: 'Company phone number',
  averageTransactionValue: 'Average transaction value (£)',
  expectedAnnualTurnover: 'Expected annual turnover (£)',
  expectedMonthlyTransactionValues: 'Number of transactions per month',
  natureOfBusiness: 'Industry',
  natureOfBusinessDetails: 'Product/service description',
  tradingAddress: 'Trading address',
  companyType: 'Company type',
  companyNumber: 'Company number',
  companyAddress: 'Company address',
  addressLine1: 'Address line 1',
  addressLine2: 'Address line 2',
  townCity: 'Town/City',
  countyOrState: 'Country or State',
  postCode: 'Postcode',
  country: 'Country',
  businessModelQuestions: {
    seasonalBusiness: {
      isTrue: 'Is business seasonal?',
      quarter1: 'Quarter 1',
      quarter2: 'Quarter 2',
      quarter3: 'Quarter 3',
      quarter4: 'Quarter 4'
    },
    face2face: {
      isTrue: 'Is business face to face?',
      deliveryTime: 'Delivery time',
      courierServiceUsed: 'Is courier service used?'
    }
  },
  company: (dsrId, isActive) => `Company ${isActive ? '' : 'is closed '}(UBER ID: ${dsrId})`,
  financialInformation: 'Financial information',
  partner: 'Partner',
  segmentCategory: 'Segment category',
  segmentSubCategory: 'Segment subcategory',
  acquisitionMgr: 'Acquisition manager',
  segmentInformation: 'Segment Information',
  dnaAcquiring: 'DNA Direct Acquiring',
  tenantValue: 'Entity',
  marketCode: 'Merchant - UK or EU',
  attributes: 'Attributes'
})
