import React from 'react'
import { observer } from 'mobx-react'
import { Button, Tooltip } from 'antd'
import { FileExcelOutlined, FilePdfOutlined, FileTextOutlined } from '@ant-design/icons'
import { FileType } from '~/code/models/FileType'
import { DownloadStatementButtonsProps } from './DownloadStatementButtonsProps'

import translations from './translations'
import styles from './styles.scss'

export const DownloadStatementButtons = observer((props: DownloadStatementButtonsProps) => {
  const { merchantId, downloadStatement, disabled: _disabled } = props

  const buttonsList = [
    {
      key: FileType.PDF,
      icon: <FilePdfOutlined className={styles.icon} />,
      disabled: !merchantId || merchantId === 'all', // Export PDF available only for one merchant
      disabledReason: translations().selectSpecificMerchantFirst
    },
    {
      key: FileType.EXCEL,
      icon: <FileExcelOutlined className={styles.icon} />
    },
    {
      key: FileType.CSV,
      icon: <FileTextOutlined className={styles.icon} />
    }
  ]

  return (
    <div className={styles.buttons}>
      {buttonsList.map(({ key, icon, disabled, disabledReason }) => {
        const title = translations().fileTypes[key]

        if (_disabled) return <Button key={key} type='link' {...{ icon, title, disabled: true }} />

        return (
          <Tooltip key={key} title={disabled ? disabledReason : title}>
            <Button {...{ icon, title, disabled }} type='link' onClick={() => downloadStatement(key)} />
          </Tooltip>
        )
      })}
    </div>
  )
})
