export const THEME = {
  token: {},
  components: {
    Layout: {
      headerBg: '#1d1737',
      siderBg: '#1d1737'
    },
    Menu: {
      darkItemBg: '#1d1737'
    },
    Modal: {
      marginXS: 24
    }
  }
}
