import React, { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { Form, Modal, Select, Spin } from 'antd'
import debounce from 'lodash.debounce'
import { SubLabel } from '~/code/pages/StartApplication/components'
import { TwoLine } from '~/code/components'
import { MerchantSelectProps } from './props'
import styles from './styles.scss'
import translations from './translations'
import { getButtonText } from './services'
import { IMerchantSelectStore } from './models'

export const MerchantSelect = observer((props: MerchantSelectProps) => {
  const { storeSymbol } = props
  const store = useInjection<IMerchantSelectStore>(storeSymbol)

  const {
    isInitiated,
    tempAcquisitionChannel,
    acquisitionChannels,
    acquisitionChannel,
    tempMerchant,
    merchant,
    merchants,
    isMerchantSelectLoading,
    isPagingMerchantListLoading,
    isMerchantListLoading,
    isAcquisitionChannelsLoading,
    hasAcquisitionChannels,
    isOkBtnDisabled,
    isModalOpen,
    init,
    openModal,
    handleOk,
    handleCancel,
    handleScroll,
    setTempAcquisitionChannel,
    handleClear,
    handleInputValueChange,
    setInputValue,
    setTempMerchant
  } = store

  useEffect(() => {
    if (!isInitiated) init()
  }, [])

  const onScroll = event => {
    const target = event.target
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight * 0.7) {
      handleScroll()
    }
  }

  const delayedQuery = useCallback<(value: string) => void>(
    debounce((v: string) => {
      setInputValue(v)
      handleInputValueChange()
    }, 500),
    []
  )

  const spinner = (
    <div className={styles.spinContainer}>
      <Spin />
    </div>
  )

  const selectSpinner = <Select.Option value='loading'>{spinner}</Select.Option>

  return (
    <>
      <Form.Item
        label={translations().merchantButtonLabel(isMerchantSelectLoading, !!merchant)}
        colon={!!merchant}
        className={styles.merchantFormItem}
      >
        {isMerchantSelectLoading ? (
          <Spin />
        ) : (
          <span onClick={openModal} className={styles.showModalBtn}>
            {hasAcquisitionChannels ? getButtonText(acquisitionChannel, merchant?.tradeName) : merchant?.tradeName}
          </span>
        )}
      </Form.Item>
      <Modal
        title={translations().modalTitle(hasAcquisitionChannels)}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: isOkBtnDisabled }}
      >
        <div className={styles.modalDialogContainer}>
          <Form colon={false} layout='vertical'>
            {hasAcquisitionChannels && (
              <Form.Item label={translations().acquisitionChannel}>
                <Select
                  showSearch={true}
                  filterOption={(searchText, option) =>
                    String(option.value || '')
                      .toLocaleLowerCase()
                      .indexOf(searchText?.toLocaleLowerCase()) >= 0
                  }
                  className={styles.select}
                  value={tempAcquisitionChannel?.id}
                  loading={isAcquisitionChannelsLoading}
                  onChange={setTempAcquisitionChannel}
                  notFoundContent={translations().noAcquisitionChannels}
                >
                  {acquisitionChannels.map(a => (
                    <Select.Option value={a.id} key={a.id}>
                      {a.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label={
                <div>
                  {translations().merchant} <SubLabel>{translations().merchantSubLabel}</SubLabel>
                </div>
              }
            >
              <Select
                showSearch
                allowClear
                filterOption={false}
                className={styles.select}
                value={tempMerchant?.id}
                loading={isMerchantListLoading}
                optionLabelProp={'prettiedLabel'}
                onSelect={setTempMerchant}
                onSearch={delayedQuery}
                notFoundContent={isMerchantListLoading ? spinner : translations().noMerchants}
                onPopupScroll={onScroll}
                onClear={handleClear}
                options={[
                  ...(merchants?.map(m => ({
                    value: m.id,
                    label: <TwoLine primary={m.tradeName} secondary={m.companyName} />,
                    prettiedLabel: m.tradeName
                  })) || []),
                  ...(isPagingMerchantListLoading ? [{ value: 'loading', label: selectSpinner }] : [])
                ]}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  )
})
