import React from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { getPanelInfo } from './helpers'

import styles from './styles.scss'

export const RealTimeTransactionMapPanel = observer(() => {
  return (
    <div className={styles.root}>
      {getPanelInfo().map(item => {
        return (
          <div className={classNames(styles.row, styles[item.type])} key={item.type}>
            {item.text}
          </div>
        )
      })}
    </div>
  )
})