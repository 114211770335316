import React from 'react'
import { BPMProcessDossierV2 } from '../shared'
import { columns } from './constants'

export const ChangeOfSettlementsStatusStoreSymbol = Symbol('ChangeOfSettlementsStatusStore')
export const ChangeOfSettlementsStatusRangePickerStoreSymbol = Symbol('ChangeOfSettlementsStatusRangePickerStore')
export const ChangeOfSettlementsStatusMerchantSelectStoreSymbol = Symbol('ChangeOfSettlementsStatusMerchantSelectStore')

export const ChangeOfSettlementsStatus = () => (
  <BPMProcessDossierV2
    BPMProcessesStoreSymbol={ChangeOfSettlementsStatusStoreSymbol}
    BPMProcessesRangePickerStoreSymbol={ChangeOfSettlementsStatusRangePickerStoreSymbol}
    BPMProcessesMerchantSelectStoreSymbol={ChangeOfSettlementsStatusMerchantSelectStoreSymbol}
    columns={columns}
  />
)
