import React from 'react'
import { Card, Col, Divider, Row } from 'antd'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { Page } from '~/code/pages'
import { IAcquiringFilterStore } from '../../components/AcquiringFilter/models'
import { ITransactionStatementStore } from '../Statement/models'
import { AcquiringFilter } from '~/code/pages/Acquiring'
import styles from './MonitoringPos.scss'
import {
    MonitoringPosTestExportsModal, MonitoringPosTestTransactions,
    MonitoringPosTestTransactionsFilter
} from '~/code/pages/Acquiring/pages/MonitoringPosTest/components'
import {StoreAndTerminalFilter} from '~/code/components/StoreAndTerminalFilter'

export const MonitoringPosTestStoreSymbol = Symbol('MonitoringTestStoreDnaPos')

export const MonitoringPosTest = observer((props: {
    transactionsStatementStoreSymbol: symbol,
    acquiringFilterStoreSymbol: symbol,
    merchantSelectStoreSymbol: symbol
}) => {
    const { transactionsStatementStoreSymbol, acquiringFilterStoreSymbol, merchantSelectStoreSymbol }  = props
    const transactionsStatementStore = useInjection<ITransactionStatementStore>(transactionsStatementStoreSymbol)
    const acquiringFilterStore = useInjection<IAcquiringFilterStore>(acquiringFilterStoreSymbol)
    const { storeAndTerminalFilterStore } = acquiringFilterStore

    return <Page>
        <MonitoringPosTestExportsModal />
        <Card className={styles.MonitoringPos}>
            <Row>
                <Col xs={24}>
                    <AcquiringFilter
                        storeSymbol={acquiringFilterStoreSymbol}
                        merchantSelectStoreSymbol={merchantSelectStoreSymbol}
                    />
                </Col>
                <Col xs={24}>
                    <StoreAndTerminalFilter store={storeAndTerminalFilterStore} withMidsFilter={true}/>
                </Col>
            </Row>
            <Divider/>
            <div className={styles.monitoringPosFilterWrapper}>
                <MonitoringPosTestTransactionsFilter
                    store={transactionsStatementStore}
                    handbooksStore={transactionsStatementStore.handbooksStore}
                    storeAndTerminalFilterStore={storeAndTerminalFilterStore}
                />
            </div>
        </Card>
        <MonitoringPosTestTransactions store={transactionsStatementStore} />
    </Page>
})
