import { CopyOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd'
import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import { OfficersPopoverProps } from './props'
import styles from './OfficersPopover.scss'
import translations from './translations'
import { StartProcessStoreContext } from '../../../StartProcessStore'
import { OfficerProfile, PCSProfile } from '../../../models'
import { isCompanyOfficerValid, isCompanyPCSValid, isPCSCorporate } from '../../../services'
import { PROCESS_VERSIONS } from '../../../services/constants'

export const OfficersPopover = observer((props: OfficersPopoverProps) => {
  const { onSelect, className } = props
  const store = useContext(StartProcessStoreContext)
  const { directors, shareholders, signatories, businessOwners } = store.dataStore
  const [isVisible, setVisible] = useState(false)

  const withoutChecks =
    store.onboardingSettings?.addressAndIDCheck === 'none' ||
    store.dataStore.version === PROCESS_VERSIONS.ADDITIONAL_STORE_ISSUE

  const validDirectors = withoutChecks ? directors : directors.filter(item => isCompanyOfficerValid(item))
  const validShareholders = withoutChecks
    ? shareholders
    : shareholders.filter(item => isCompanyPCSValid(item) && !isPCSCorporate(item))
  const validSignatories = withoutChecks ? signatories : signatories.filter(item => isCompanyOfficerValid(item))
  const validBusinessOwners = withoutChecks
    ? businessOwners
    : businessOwners.filter(item => isCompanyOfficerValid(item))

  const select = (officer: OfficerType) => {
    setVisible(false)
    onSelect(officer)
  }

  if (
    validDirectors.length === 0 &&
    validShareholders.length === 0 &&
    validSignatories.length === 0 &&
    validBusinessOwners.length === 0
  ) {
    return null
  }

  const Content = () => {
    return (
      <div>
        <List title={translations().shareholders} officers={validShareholders} onSelect={select} />
        <List title={translations().directors} officers={validDirectors} onSelect={select} />
        <List title={translations().authorisedSignatories} officers={validSignatories} onSelect={select} />
        <List title={translations().businessOwners} officers={validBusinessOwners} onSelect={select} />
      </div>
    )
  }

  return (
    <Popover content={<Content />} open={isVisible} className={className} trigger={['click']} onOpenChange={setVisible}>
      <Button icon={<CopyOutlined />}>{translations().fillInDataFrom}</Button>
    </Popover>
  )
})

type OfficerType = OfficerProfile | PCSProfile
type ListProps = { title: string; officers: OfficerType[]; onSelect(officer: OfficerType): void }
const List = (props: ListProps) => {
  const { officers, title, onSelect } = props

  if (officers.length === 0) {
    return null
  }

  return (
    <>
      <h4>{title}</h4>
      <ul className={styles.list}>
        {officers.map(item => {
          const {
            nameElements: { forename, surname }
          } = item
          return (
            <li key={item.id} className={styles.listItem} onClick={() => onSelect(item)}>{`${forename} ${surname}`}</li>
          )
        })}
      </ul>
    </>
  )
}
