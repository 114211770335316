import { Button, Card } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'
import React from 'react'

import { ReloadButton } from '~/code/components'
import { PosTransactionModel } from '~/code/models/PosTransactionModel'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { StatusEnum } from '~/code/pages/Acquiring/pages/MonitoringPos/constants/statusEnum'
import { TableWithInfiniteScroll } from '~/code/components/table/TableWithInfiniteScroll'
import { MonitoringPosTransactionsProps } from './props'
import translations from './translations'
import styles from './MonitoringPosTransactions.scss'


export const MonitoringPosTransactions = observer((props: MonitoringPosTransactionsProps) => {
  const { store } = props
  const { tableStore } = store

  return <>
    <Card>
      <div className={styles.header}>
        <div className={styles.paginationContainer}>
          <ReloadButton
            onClick={ () => tableStore.reload() }
          />

          {hasPermissions([PermissionMap.transactions.dna_pos_statement.v2.read]) ? (
              <Button
                  className={styles.exportButton}
                  icon={<DownloadOutlined />}
                  onClick={() => store.openExportModal(true)} type={'primary'}>
                {translations().export}
              </Button>
          ): null}
        </div>
      </div>

      <TableWithInfiniteScroll
        loading={tableStore.isLoading}
        isLoadingMore={tableStore.isLoadingMore}
        columns={store.columns}
        fixedDateProp={'transactionDate'}
        nonTableBodyHeight={500}
        dataSource={tableStore.data?.map((item, i) => {
          const posItem = item as PosTransactionModel
          return ({ ...item, key: `${posItem.transactionId}-${i}`, status: StatusEnum[posItem.status] })
        })}
        onLoadMoreData={tableStore.loadMoreData}
      />
    </Card>
  </>
})
