import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  submit: string
  cancel: string
  reason: string
  commentRequired: string
  reasonRequired: string
  reasonMinMsg: string
  comment: string
  closeStore: string
}>()
