import React from 'react'
import translations from './translations'

translations.add('en', {
    name: {
        ON_EACH_LOGIN: (
            <>
                <strong>Each login:</strong> We will ask for a verification code
            </>
        ),
        TRUSTED_DEVICE: (
            <>
                <strong>Once per device:</strong> We will only ask for a verification code once
                every 30 days on trusted device
            </>
        )
    },

    disabled: 'Disabled'
})
