import translations from './translations'

translations.add('en', {
    selectType: 'Select Type',
    fillInDetails: 'Fill in Details',
    manageTemplates: 'Manage Templates',
    createNotification: 'Create a Notification',
    createNotificationTemplate: 'Create a Notification Template',
    editNotificationTemplate: 'Edit a Notification Template',
})
