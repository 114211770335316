import { message } from 'antd'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { v4 as uuid } from 'uuid'
import { isEmpty } from 'dna-common'

import {
  OfficerRole,
  Application,
  CompanyProfile,
  OfficerProfile,
  PCSProfile,
  CompanyType,
  GeneralInformationType,
  ContactInfoType,
  LoadingPropertyType,
  CompanyResponseItem,
  StoreItem
} from '../models'
import {
  isCompanyProfileValid,
  isDirectorsValid,
  isCompanyOfficerValid,
  isAuthorisedSignatoriesValid,
  isCompanyPCSListValid,
  parseApplicationWithEmptyValues,
  parseOnboardingRequestData,
  saveApplication,
  isFinancialInfoValid,
  isBusinessOwnersValid,
  isTariffsValid,
  isAllBankAccountsValid,
  isAllStoresMainInfoValid
} from '../services'
import translations from '../translations'
import { ITarrifs } from '../models/ITarrifs'
import { StartProcessStore } from '../StartProcessStore'
import { BankInfo } from '../models/BankInfo'
import { StartTypesType } from '../models/contactInfo/StartTypesType'
import { PROCESS_VERSIONS, PRODUCT_VERSIONS, SUCCESS_STATUS } from '../services/constants'

const emptyApplication: Application = {
  companyProfile: null,
  companyPCSList: [],
  companyOfficerList: []
}

export class StartProcessDataStore {
  cachedPhoneNumbers: string[] = []
  cachedEmails: string[] = []
  company: CompanyResponseItem = undefined

  contactInfoData: ContactInfoType = null
  generalInformationData: GeneralInformationType = null
  applicationId: string = ''
  application: Application = parseApplicationWithEmptyValues(emptyApplication)
  tariffs: ITarrifs[] = []
  stores: StoreItem[] = []
  isFastTrack: boolean = false
  isMsaSigned: boolean = false

  isSaveLoading: boolean = false
  isNextLoading: boolean = false
  version: string = ''
  sentToDocusign: boolean = false
  envelopeId: string = ''
  docusignProcessStatus: string = ''
  bankAccounts: BankInfo[] = []
  docuSignStatus: string = ''
  isManualMSA: boolean = false
  startType: StartTypesType = null
  productVersion: string = ''

  constructor(
    private completeApplicationStore: {},
    private supportingDocumentsStore: {
      isSupportingDocumentsValid: boolean
      showDocusign: boolean
      reset: () => void
    },
    private parentStore: StartProcessStore
  ) {
    makeObservable(this, {
      cachedPhoneNumbers: observable,
      cachedEmails: observable,
      company: observable,
      stores: observable,
      version: observable,
      envelopeId: observable,
      contactInfoData: observable,
      generalInformationData: observable,
      applicationId: observable,
      application: observable,
      tariffs: observable,
      isFastTrack: observable,
      isMsaSigned: observable,
      isNextLoading: observable,
      isSaveLoading: observable,
      sentToDocusign: observable,
      docusignProcessStatus: observable,
      bankAccounts: observable,
      docuSignStatus: observable,
      isManualMSA: observable,
      startType: observable,
      productVersion: observable,

      companyType: computed,
      companyNumber: computed,
      isApplicationValid: computed,
      isApplicationValidWithoutDocuments: computed,
      isCompanyProfileValid: computed,
      isDirectorsValid: computed,
      isBusinessOwnerValid: computed,
      isAuthorisedSignatoriesValid: computed,
      isShareholdersValid: computed,
      directors: computed,
      owner: computed,
      signatories: computed,
      shareholders: computed,
      generalInformationViewData: computed,
      businessOwners: computed,
      agreementOfficer: computed,
      isAdditionalStoreProcess: computed,
      isDocusignSignedSuccessfully: computed,
      isDocusignSent: computed,
      removableStores: computed,
      isBankAccountsSectionDisabled: computed,
      isDocusignCompleted: computed,
      productTypes: computed,
      msaSignerSelected: computed,
      isAdditionalStoresContactInfoValid: computed,

      savePhoneNumberAndEmail: action.bound,
      setContactInfoData: action.bound,
      setGeneralInformationData: action.bound,
      setApplicationId: action.bound,
      updateCompanyProfile: action.bound,
      createOfficer: action.bound,
      updateOfficer: action.bound,
      deleteOfficer: action.bound,
      updateShareholder: action.bound,
      deleteShareholder: action.bound,
      reset: action.bound,
      resetApplication: action.bound,
      setIsNextLoading: action,
      setTariffs: action.bound,
      setTerminalOrders: action.bound,
      setCompany: action.bound,
      createShareholder: action.bound,

      saveOnPaymentSolution: action.bound,
      saveApplication: action.bound,
      setStores: action.bound,
      saveOnProducts: action.bound,
      setFastTrack: action.bound,
      setMsaSigned: action.bound,
      setVersion: action.bound,
      updateContactInfoData: action.bound,
      setSentToDocusign: action.bound,
      setEnvelopeId: action.bound,
      setDocuSignProcessStatus: action.bound,
      setBankAccounts: action.bound,
      addBankAccount: action.bound,
      deleteBankAccount: action.bound,
      saveOnBankAccounts: action.bound,
      isPricingPlanValid: action.bound,
      setDocuSignStatus: action.bound,
      setIsManualMSA: action.bound,
      setStartType: action.bound,
      setProductVersion: action.bound
    })
  }

  setProductVersion(prVersion: string) {
    this.productVersion = prVersion
  }

  setStartType(type: StartTypesType) {
    this.startType = type
  }

  setIsManualMSA(value: boolean) {
    this.isManualMSA = value
  }

  setDocuSignStatus(status: string) {
    this.docuSignStatus = status
  }

  deleteBankAccount(account: BankInfo) {
    const index = this.bankAccounts?.findIndex(o => o.bankAccountNumber === account.bankAccountNumber)
    this.bankAccounts.splice(index, 1)

    //delete from stores
    this.stores?.forEach(store => {
      if (
        store?.bankInfo?.bankAccountNumber === account.bankAccountNumber &&
        store?.bankInfo?.sortCode === account.sortCode
      ) {
        delete store.bankInfo
      }

      if (
        store?.directDebitBankInfo?.bankAccountNumber === account.bankAccountNumber &&
        store?.directDebitBankInfo?.sortCode === account.sortCode
      ) {
        delete store.directDebitBankInfo
      }
    })
  }

  addBankAccount(account: BankInfo) {
    if (
      this.bankAccounts.find(
        acc => acc.bankAccountNumber === account.bankAccountNumber && acc.sortCode === account.sortCode
      )
    ) {
      message.error(translations().accExists)
      return
    }

    this.bankAccounts.push(account)
  }

  setBankAccounts(accounts: BankInfo[]) {
    this.bankAccounts = accounts
  }

  setDocuSignProcessStatus(value: string) {
    this.docusignProcessStatus = value
  }

  setVersion(value: string) {
    this.version = value
  }

  setEnvelopeId(value: string) {
    this.envelopeId = value
  }

  setFastTrack(value: boolean) {
    this.isFastTrack = value
  }

  setMsaSigned(value: boolean) {
    this.isMsaSigned = value
  }

  setStores(values: StoreItem[]) {
    this.stores = values
  }

  setSentToDocusign(value: boolean) {
    this.sentToDocusign = value
  }

  get productTypes() {
    let hasEcom = false,
      hasPos = false

    if (this.productVersion === PRODUCT_VERSIONS.REDESIGNED) {
      this.parentStore.productStore.stores.forEach(store => {
        if (store.removable) {
          if (!hasPos) hasPos = Boolean(store.products.find(p => p.type === 'pos'))
          if (!hasEcom) hasEcom = Boolean(store.products.find(p => p.type === 'ecom'))
        }
      })
    } else {
      this.parentStore.productStore.stores.forEach(store => {
        if (store.removable) {
          store.products.forEach(p => {
            if (['pos_moto', 'pos'].includes(p.name)) {
              hasPos = true
            } else {
              hasEcom = true
            }
          })
        }
      })
    }

    const prodTypes: string[] = []

    if (hasEcom) prodTypes.push('ecom')
    if (hasPos) prodTypes.push('pos')
    prodTypes.sort((a, b) => b.localeCompare(a))

    return prodTypes
  }

  get isBankAccountsSectionDisabled() {
    return this.version === PROCESS_VERSIONS.ADDITIONAL_STORE_ISSUE
      ? !this.applicationId || this.isDocusignSent
      : !this.msaSignerSelected || !this.individualDetailsValid || !this.applicationId || this.isDocusignSent
  }

  get removableStores() {
    return this.stores?.filter(st => st.removable === true)
  }

  get isDocusignSent() {
    return this.sentToDocusign === true
  }

  get isDocusignSignedSuccessfully() {
    return this.docuSignStatus === 'success'
  }

  get isAdditionalStoreProcess() {
    return this.version === PROCESS_VERSIONS.ADDITIONAL_STORE_ISSUE
  }

  get agreementOfficer() {
    return (
      this.parentStore.onboardingSettings?.bankDetailScheme !== 'EU' &&
      this.application?.companyOfficerList?.find(o => o.agreementSignatory === true)
    )
  }

  get generalInformationViewData() {
    return {
      systemStoreAddress: {
        country: this.application?.companyProfile?.onboardingCountryInfo?.value
      },
      storeAddress: {
        country: this.application?.companyProfile?.onboardingCountryInfo?.value
      },
      storeName: this.removableStores?.length === 0 ? this.application?.companyProfile?.companyTradeName : '',
      systemDescriptor:
        this.removableStores?.length === 0 ? this.application?.companyProfile?.companyTradeName?.substring(0, 24) : ''
    }
  }

  get companyType(): CompanyType {
    return this.application.companyProfile?.companyType
  }

  get companyNumber(): string {
    return this.generalInformationData?.companyNumber
  }

  get isCompanyProfileValid(): boolean {
    const { companyProfile } = this.application
    return isCompanyProfileValid(companyProfile)
  }

  get isBankAccountsValid(): boolean {
    return isAllBankAccountsValid(this.bankAccounts, this.parentStore.onboardingSettings?.bankDetailScheme !== 'UK')
  }

  get isStoresMainInfoValid(): boolean {
    const isValid = isAllStoresMainInfoValid(this.stores.filter(st => st.removable === true))
    return this.version === PROCESS_VERSIONS.ADDITIONAL_STORE_ISSUE
      ? isValid && this.isAdditionalStoresContactInfoValid
      : isValid
  }

  get isAdditionalStoresContactInfoValid(): boolean {
    if (this.parentStore.productStore.isSalesforceOpportunityIDVisible(this.contactInfoData.acquisitionChannel)) {
      return Boolean(this.contactInfoData?.salesforceOpportunityID)
    } else return true
  }

  get isPricingValid(): boolean {
    return this.parentStore.onboardingSettings?.needPricing
      ? isTariffsValid(this.tariffs, this.parentStore.pricingStore.productTypes)
      : true
  }

  get isApplicationValid(): boolean {
    return this.isApplicationValidWithoutDocuments && this.supportingDocumentsStore.isSupportingDocumentsValid
  }

  get uploadedDocumentsValid(): boolean {
    return this.isMSAFilled && this.supportingDocumentsStore.isSupportingDocumentsValid
  }

  get isMSAFilled(): boolean {
    return this.supportingDocumentsStore.showDocusign
      ? this.sentToDocusign
        ? this.docuSignStatus === SUCCESS_STATUS
        : this.isManualMSA
      : true
  }

  get isApplicationValidWithoutDocuments(): boolean {
    return (
      this.isCompanyProfileValid &&
      this.isBankAccountsValid &&
      this.individualDetailsValid &&
      this.isStoresMainInfoValid &&
      this.isFinancialInfoValid &&
      this.isPricingValid
    )
  }

  get individualDetailsValid(): boolean {
    return this.version === PROCESS_VERSIONS.ADDITIONAL_STORE_ISSUE
      ? true
      : this.msaSignerSelected &&
          ((!['company', 'llp'].includes(this.companyType) && this.isBusinessOwnersValid) ||
            (['company', 'llp'].includes(this.companyType) && this.isDirectorsValid && this.isShareholdersValid))
  }

  get msaSignerSelected(): boolean {
    return Boolean(this.parentStore.onboardingSettings?.automatedMSA === 'none' ? true : this.agreementOfficer)
  }

  get isDocusignCompleted(): boolean {
    return this.isApplicationValidWithoutDocuments && (this.docuSignStatus === 'success' || this.isManualMSA)
  }

  get isFinancialInfoValid(): boolean {
    return isFinancialInfoValid(this.stores?.filter(s => s.removable === true))
  }

  get isDirectorsValid(): boolean {
    return isDirectorsValid(this.directors, this.parentStore?.onboardingSettings?.addressAndIDCheck === 'none')
  }

  get isBusinessOwnerValid(): boolean {
    return !isEmpty(this.owner) && isCompanyOfficerValid(this.owner)
  }

  get isBusinessOwnersValid(): boolean {
    return isBusinessOwnersValid(this.owners, this.parentStore?.onboardingSettings?.addressAndIDCheck === 'none')
  }

  get isAuthorisedSignatoriesValid(): boolean {
    return isAuthorisedSignatoriesValid(this.signatories)
  }

  get isShareholdersValid(): boolean {
    return isCompanyPCSListValid(this.shareholders, this.parentStore?.onboardingSettings?.addressAndIDCheck === 'none')
  }

  get directors(): OfficerProfile[] {
    return this.application?.companyOfficerList?.filter(item => item.officerRole === OfficerRole.director) || []
  }

  get owner(): OfficerProfile {
    return (
      this.application.companyOfficerList?.find(officer => officer.officerRole === OfficerRole.businessOwner) || {
        officerRole: OfficerRole.businessOwner
      }
    )
  }

  get owners(): OfficerProfile[] {
    return (
      this.application.companyOfficerList?.filter(officer => officer.officerRole === OfficerRole.businessOwner) || []
    )
  }

  get signatories(): OfficerProfile[] {
    return (
      this.application?.companyOfficerList?.filter(item => item.officerRole === OfficerRole.authorisedSignatory) || []
    )
  }

  get businessOwners(): OfficerProfile[] {
    return this.application?.companyOfficerList?.filter(item => item.officerRole === OfficerRole.businessOwner) || []
  }

  get shareholders(): PCSProfile[] {
    return this.application?.companyPCSList || []
  }

  isPricingPlanValid(type: string) {
    return this.tariffs?.find(t => t.type === type)?.completed ? true : false
  }

  setCompany(company: CompanyResponseItem) {
    this.company = company
  }

  setTariffs(tariffs: any) {
    this.tariffs = tariffs
  }

  savePhoneNumberAndEmail(phoneNumber: string, email: string) {
    if (phoneNumber && !this.cachedPhoneNumbers.includes(phoneNumber)) {
      this.cachedPhoneNumbers.push(phoneNumber)
    }

    if (email && !this.cachedEmails.includes(email)) {
      this.cachedEmails.push(email)
    }
  }

  setContactInfoData(data: ContactInfoType) {
    this.contactInfoData = data
  }

  updateContactInfoData(data: ContactInfoType) {
    this.contactInfoData = { ...this.contactInfoData, ...data }
  }

  setTerminalOrders(data) {
    this.application.terminalOrders = data
  }

  setGeneralInformationData(data: GeneralInformationType) {
    this.generalInformationData = data
  }

  setApplicationId(id: string) {
    this.applicationId = id
  }

  updateCompanyProfile(companyProfile: CompanyProfile) {
    this.application.companyProfile = {
      ...this.application.companyProfile,
      ...companyProfile
    }
  }

  setOfficerList(officerList: OfficerProfile[]) {
    this.application.companyOfficerList = officerList
  }

  createOfficer(officer: OfficerProfile) {
    const newOfficer = { ...officer, id: uuid() }
    this.application.companyOfficerList.push(newOfficer)
    return newOfficer
  }

  updateOfficer(officer: OfficerProfile) {
    const index = this.application.companyOfficerList.findIndex(o => o.id === officer.id)
    const oldValue = this.application.companyOfficerList[index]

    this.application.companyOfficerList.splice(index, 1, {
      ...oldValue,
      ...officer
    })
  }

  deleteOfficer(officer: OfficerProfile) {
    const index = this.application.companyOfficerList.findIndex(o => o.id === officer.id)
    this.application.companyOfficerList.splice(index, 1)
  }

  setCompanyPCSList(companyPCSList: PCSProfile[]) {
    this.application.companyPCSList = companyPCSList
  }

  createShareholder(shareholder: PCSProfile) {
    const newOfficer = { ...shareholder, id: uuid() }
    this.application.companyPCSList.push(newOfficer)
    return newOfficer
  }

  updateShareholder(shareholder: PCSProfile) {
    const index = this.application.companyPCSList.findIndex(o => o.id === shareholder.id)

    const oldValue = this.application.companyPCSList[index]

    this.application.companyPCSList.splice(index, 1, {
      ...oldValue,
      ...shareholder
    })
  }

  deleteShareholder(shareholder: PCSProfile) {
    const index = this.application.companyPCSList.findIndex(o => o.id === shareholder.id)
    this.application.companyPCSList.splice(index, 1)
  }

  setIsNextLoading(value: boolean) {
    this.isNextLoading = value
  }

  reset() {
    this.cachedEmails = []
    this.cachedPhoneNumbers = []
    this.generalInformationData = null
    this.contactInfoData = null
    this.bankAccounts = []
    this.resetApplication()
    this.tariffs = []
    this.stores = []
    this.version = ''
    this.sentToDocusign = false
    this.envelopeId = ''
    this.docuSignStatus = ''
    this.isManualMSA = false
  }

  resetApplication() {
    this.supportingDocumentsStore.reset()
    this.application = parseApplicationWithEmptyValues(emptyApplication)
  }

  saveOnPaymentSolution(values: GeneralInformationType, loadingProperty: LoadingPropertyType): Promise<boolean> {
    this.setGeneralInformationData(values)
    return this.saveApplication(loadingProperty)
  }

  saveOnProducts(values: StoreItem[], loadingProperty: LoadingPropertyType): Promise<boolean> {
    this.setStores(values)
    return this.saveApplication(loadingProperty)
  }

  saveOnBankAccounts(accounts: BankInfo[], loadingProperty: LoadingPropertyType): Promise<boolean> {
    this.setBankAccounts(accounts)
    return this.saveApplication(loadingProperty)
  }

  saveOnTariffs(values: any, loadingProperty: LoadingPropertyType): Promise<boolean> {
    this.setTariffs(values)
    return this.saveApplication(loadingProperty)
  }

  async saveApplication(loadingProperty: LoadingPropertyType) {
    const data = parseOnboardingRequestData(
      this.application,
      this.productTypes.length === 0 ? [] : this.tariffs,
      this.stores,
      this.parentStore.activeTabKey,
      this.version,
      this.contactInfoData,
      this.envelopeId,
      this.parentStore.onboardingSettings,
      this.bankAccounts,
      this.sentToDocusign,
      this.isManualMSA,
      this.productVersion
    )
    let isOk = false
    const errorMessage = translations().errorSavingData
    const successMessage = translations().savedDataSuccessfully

    try {
      runInAction(() => {
        this[loadingProperty] = true
      })

      const { error, status } = await saveApplication(this.applicationId, data)

      if (error || status !== 200) {
        message.error(error.message || errorMessage)
      } else {
        isOk = true
        message.success(successMessage)
      }
    } catch (error) {
      message.error(error.message || errorMessage)
    } finally {
      runInAction(() => {
        this[loadingProperty] = false
      })
    }

    return isOk
  }
}
