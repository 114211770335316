import { message } from 'antd'
import { injectable } from 'inversify'
import { action, computed, makeObservable, observable } from 'mobx'
import debounce from 'lodash.debounce'
import { PAGE_SIZE_500 } from '~/code/constants/Configurations'
import commonTranslations from '~/code/translations'
import { MerchantDossierModel } from './models/merchant-dossier-v1'
import { fetchMerchantDossiersV2 } from './services/fetchers'
import { getDefaultMerchantDossierModel } from './services/getters'
import { minTextLengthForSearch } from '~/code/pages/MerchantsManagement/components/MerchantSelect/constants'

type LoadingStatus = 'idle' | 'loading' | 'failed' | 'finished'

@injectable()
export class MerchantSelectStore {
  pageNumber: number = 1
  merchantsTotalCount: number = 0
  merchantLoadingStatus: LoadingStatus = 'idle'
  pagingMerchantLoadingStatus: LoadingStatus = 'idle'
  selectedMerchant: MerchantDossierModel = getDefaultMerchantDossierModel()
  _merchants: MerchantDossierModel[] = []
  inputValue: string = ''

  constructor(private isWithAll: boolean) {
    makeObservable(this, {
      pageNumber: observable,
      merchantLoadingStatus: observable,
      pagingMerchantLoadingStatus: observable,
      selectedMerchant: observable,
      _merchants: observable,
      inputValue: observable,

      merchants: computed,

      setMerchant: action,
      setInputValue: action,
      loadMerchants: action,
      loadMerchantsPaging: action
    })
  }

  delayedQuery = debounce((v: string) => {
    if (v?.length === 0) {
      this.clearMerchants()
      return
    }
    if (v?.length >= minTextLengthForSearch) {
      this.setInputValue(v)
      this.handleInputValueChange()
    }
  }, 500)

  get merchants() {
    if (this.merchantLoadingStatus === 'idle') {
      this.loadMerchants(true)
    }

    return this._merchants
  }

  handleScroll = () => {
    if (this.pagingMerchantLoadingStatus !== 'loading') {
      this.loadMerchantsPaging()
    }
  }

  handleInputValueChange = () => {
    if (this.merchantLoadingStatus !== 'loading') {
      this.loadMerchants()
    }
  }

  handleClear = () => {
    this.setInputValue('')
    this.loadMerchants()
  }

  clearMerchants = () => {
    this._merchants = []
  }

  setMerchant = (value: string, setByKey: 'id' | 'companyName' = 'id') => {
    this.selectedMerchant =
      this.merchants.find(m => m[setByKey].toString() === value.toString()) || getDefaultMerchantDossierModel()
  }

  clearSelectedMerchant() {
    this.selectedMerchant = getDefaultMerchantDossierModel()
  }

  setInputValue = (inputValue: string) => {
    this.inputValue = inputValue
  }

  loadMerchants = async (setDefaultMerchant: boolean = false) => {
    this.merchantLoadingStatus = 'loading'
    this.pageNumber = 1

    const { status, error, result } = await this.fetchMerchantDossiers()
    if (status !== 200 || error) {
      this.merchantLoadingStatus = 'failed'
      message.error(error?.message || commonTranslations().defaultErrorMessage)
      return
    }

    this.merchantLoadingStatus = 'finished'
    this.merchantsTotalCount = result.totalCount
    if (result.data) {
      if (this.isWithAll) {
        if (result.data.length > 1 && !this.inputValue) {
          this._merchants = [
            { id: 'all', tradeName: 'All', companyName: 'All', mid: null, companyNumber: null },
            ...result.data
          ]
        } else {
          this._merchants = result.data
        }
        if (setDefaultMerchant) {
          this.setMerchant(this._merchants[0].id)
        }
      } else {
        this._merchants = result.data
      }
    } else {
      this._merchants = []
    }
  }

  loadMerchantsPaging = async () => {
    if (this.merchantsTotalCount <= this.merchants.length) return

    this.pagingMerchantLoadingStatus = 'loading'
    this.pageNumber++

    const { status, error, result } = await this.fetchMerchantDossiers()
    if (status !== 200 || error) {
      this.pagingMerchantLoadingStatus = 'failed'
      message.error(error?.message || commonTranslations().defaultErrorMessage)
      return
    }

    this.pagingMerchantLoadingStatus = 'finished'
    this.merchantsTotalCount = result.totalCount
    this._merchants = [...this._merchants, ...result.data]
  }

  fetchMerchantDossiers = () =>
    fetchMerchantDossiersV2({
      page: this.pageNumber,
      size: PAGE_SIZE_500,
      value: this.inputValue ? this.inputValue : undefined
    })
}
