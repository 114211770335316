import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Button, Col, Form, Modal, Row, Select, Input, Typography } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { MotoVtRequestsStatus } from '../../constants/constants'
import translations from '~/code/pages/MerchantsManagement/components/ChangeOfSettlementPeriod/translations/translations'
import { DocumentsType } from '~/code/pages/MerchantsManagement/components/ChangeOfSettlementPeriod/models/DocumentType'
import { SupportingDocument, Upload } from 'startapp/components/SupportingDocuments/components'
import { IMotoVtRequestsDrawerTitleStore } from './IMotoVtRequestsDrawerTitleStore'
import styles from './MotoVtRequestsDrawerTitle.scss'

const { TextArea } = Input
export const MotoVtRequestsDrawerTitleStoreSymbol = Symbol('MotoVtRequestsDrawerTitleStore')
export const MotoVtRequestsDrawerTitle = observer(() => {
  const store = useInjection<IMotoVtRequestsDrawerTitleStore>(MotoVtRequestsDrawerTitleStoreSymbol)

  const [form] = Form.useForm()
  const [documentType, setDocumentType] = useState<keyof DocumentsType>('' as keyof DocumentsType)

  React.useEffect(() => {
    form.resetFields()
    store.clearData()
  }, [store.showUploadModal])

  const renderAdditionalDocumentUpload = (_documentType: string) => {
    return (
      <Upload
        disabled={!documentType}
        loading={store.isDocumentLoading}
        fileList={[]}
        onChange={async fileList => {
          const file = fileList[0]
          if (!file) return
          const isUploaded = await store.uploadFile(documentType, file?.originFileObj as File)
          if (!isUploaded) return
          store.addDocumentType(documentType, fileList)
          setDocumentType('' as keyof DocumentsType)
        }}
      >
        <Button icon={<UploadOutlined />}>{translations().upload}</Button>
      </Upload>
    )
  }

  const handleDocumentOptionChange = (option: keyof DocumentsType) => {
    setDocumentType(option)
  }

  const handleClose = () => {
    store.setUploadModal(false)
    store.clearData()
    form.resetFields()
  }

  return (
    <>
      <div className={styles.headerPadding}>
        <Row justify={'space-between'}>
          <Col>{store.motoVtStore.processDetails?.processStatus}</Col>
          {store.motoVtStore.processDetails?.processStatus === MotoVtRequestsStatus.NEED_INFO ? (
            <Col>
              <Button onClick={() => store.setUploadModal(true)} type={'primary'}>
                {translations().provideInfo}
              </Button>
            </Col>
          ) : null}
        </Row>
      </div>
      <Modal
        open={store.showUploadModal}
        title={translations().provideAdditionalInfo}
        onCancel={handleClose}
        okText={translations().submit}
        onOk={form.submit}
        getContainer={false}
      >
        <Form form={form} onFinish={store.onFormFinish} initialValues={{ comment: '' }}>
          {store.documentTypes.map(d => (
            <SupportingDocument
              key={d}
              title={translations()[d]}
              fileList={store.documentsMap[d]}
              onChange={async (fileList, file) => {
                const isDeleted = await store.deleteFile(d, file?.name)
                if (!isDeleted) return
                store.removeDocument(d, fileList)
              }}
            />
          ))}

          {store.availableDocumentTypeOptions.length ? (
            <Row justify={'space-between'}>
              <Col xs={18}>
                <Form.Item name={'documentType'} label={translations().documents} key={'documentType'}>
                  <Select
                    options={store.availableDocumentTypeOptions}
                    onChange={handleDocumentOptionChange}
                    placeholder={translations().documentType}
                    value={documentType}
                  />
                </Form.Item>
              </Col>
              <Col>{renderAdditionalDocumentUpload(documentType)}</Col>
            </Row>
          ) : null}

          {store.isNoDocumentsError ? (
            <Row>
              <Typography.Text type={'danger'}>{translations().documentsRequired}</Typography.Text>
            </Row>
          ) : null}
          <Form.Item key={'comment'} name={'comment'}>
            <TextArea placeholder={translations().comment} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
})
