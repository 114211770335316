import translations from './translations'

translations.add('en', {
  sortCode: 'Sort Code',
  bankAccount: 'Bank Account',
  accountName: 'Account Name',
  bankName: 'Bank Name',
  overallScore: 'Overall Score',
  result: 'Result',
  id: 'ID',
  reportDate: 'Report creation date',
  emailAddress: 'Email address used for sending verification link'
})
