import { inject, injectable } from 'inversify'
import { action, makeObservable, observable, runInAction, reaction, computed } from 'mobx'
import { notification } from 'antd'

import { sendEmailOtp, validateEmailOtp } from '~/code/stores/Profile/services/fetchers'
import { TwoFAStoreInterface, TwoFAStoreSymbol } from '~/code/pages/Profile/components/TwoFA'
import { TwoFAEmailConfirmationModalStoreInterface } from './TwoFAEmailConfirmationModalStoreInterface'
import { AppStore } from '~/code/stores/AppStore'

import {
  AuthSecretKeyModel,
  TwoFASettingsModel,
  AuthAppInfoModel,
  RecoveryCodeInterface,
  TwoFAUserUpdateSettingsRequestModel
} from '~/code/stores/Profile/models'

import translations from './translations'

@injectable()
export class TwoFAEmailConfirmationModalStore implements TwoFAEmailConfirmationModalStoreInterface {
  public twoFAStore: TwoFAStoreInterface
  public isLoading: boolean = false

  constructor(@inject(TwoFAStoreSymbol) twoFAStore: TwoFAStoreInterface) {
    this.twoFAStore = twoFAStore

    makeObservable(this, {
      isLoading: observable,
      email: computed
    })
  }

  get email() {
    return AppStore.authStore.email
  }

  verifyEmailOtp = async (code: string): Promise<boolean> => {
    try {
      const { error } = await validateEmailOtp(code)

      if (error) {
        notification.error({ message: error.message })

        return false
      }

      this.twoFAStore.setIsEmailConfirmationModalOpen(false)
      this.twoFAStore.setIsModalOpen(true)

      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  resendEmailOtp = async () => {
    runInAction(() => (this.isLoading = true))
    try {
      await sendEmailOtp()
    } catch (error) {
      console.error(error)
    } finally {
      runInAction(() => (this.isLoading = false))
    }
  }
}
