import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  headlineRate: string
  transactionType: string
  backText: string
  blended: string
  icPlus: string
  cardScheme: string
  serviceArea: string
  corporateOrConsumer: string
  cardType: string
  baseFee: string
  percentFee: string
  skip: string
  pos: string
  ecom: string
  warningTitle: string
  ensureMSA: string
  wrongFee: string
  doubleCheck: string
  consumerDebitLabel: string
  consumerCreditLabel: string
  corporateDebitLabel: string
  corporateCreditLabel: string
  sameAsPos: string
  prodName: (prod: string) => string
  selectFeeType: string
  note: string
  save: string
  cancel: string
  saveForLater: string
  back: string
}>()
