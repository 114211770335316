import { Form, Select, Input } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import { FormSearch } from '~/code/components'
import translations from './translations'
import { ProcessesFilterProps } from './props'

export const ProcessesFilter: React.FC<ProcessesFilterProps> = observer(props => {
  const { className, store, handbooksStore } = props

  const bpmUsers = handbooksStore.bpmUsers
    .filter(u => !store.filter.assignedGroup || !u.group || u.group === store.filter.assignedGroup)
    .map(u => ({ value: u.login, label: u.fullName }))

  return (
    <FormSearch
      className={className}
      onReset={() => store.resetFilter()}
      items={[
        {
          key: 'status',
          value: store.filter.status,
          collapsedColProps: { xs: 24, sm: 15, md: 12, lg: 12, xl: 8, xxl: 6 },
          colProps: { xs: 24, sm: 12, md: 12, lg: 12, xl: 8, xxl: 6 },
          showInCollapsed: true,
          component: (
            <Form.Item label={translations().processStatus}>
              <Select
                value={store.filter.status}
                loading={handbooksStore.isBpmStatusesLoading}
                options={handbooksStore.bpmStatuses.map(m => ({ value: m.name, label: m.description }))}
                onChange={v => store.setFilter({ status: v })}
              />
            </Form.Item>
          )
        },
        {
          key: 'productType',
          value: store.filter.productType,
          collapsedColProps: { xs: 0, md: 12, lg: 12, xl: 8, xxl: 6 },
          colProps: { xs: 24, sm: 12, md: 12, lg: 12, xl: 8, xxl: 6 },
          showInCollapsed: true,
          component: (
            <Form.Item label={translations().productType}>
              <Select
                disabled={bpmUsers.length <= 1}
                value={store.filter.productType}
                onChange={v => store.setFilter({ productType: v })}
                loading={handbooksStore.isProductTypesLoading}
                options={handbooksStore.productTypes}
              />
            </Form.Item>
          )
        },
        {
          key: 'approvalType',
          value: store.filter.approvalType,
          collapsedColProps: { xs: 0, md: 12, lg: 12, xl: 8, xxl: 6 },
          colProps: { xs: 24, sm: 12, md: 12, lg: 12, xl: 8, xxl: 6 },
          showInCollapsed: true,
          component: (
            <Form.Item label={translations().approvalType}>
              <Select
                disabled={bpmUsers.length <= 1}
                value={store.filter.approvalType}
                onChange={v => store.setFilter({ approvalType: v })}
                loading={handbooksStore.isApprovalTypesLoading}
                options={handbooksStore.approvalTypes}
              />
            </Form.Item>
          )
        },
        {
          key: 'segmentCategory',
          value: store.filter.segmentCategory,
          collapsedColProps: { xs: 0, md: 12, lg: 12, xl: 8, xxl: 6 },
          colProps: { xs: 24, sm: 12, md: 12, lg: 12, xl: 8, xxl: 6 },
          showInCollapsed: true,
          component: (
            <Form.Item label={translations().segmentCategory}>
              <Select
                value={store.filter.segmentCategory}
                onChange={v =>
                  store.setFilter({ segmentCategory: v, segmentSubCategory: null, acquisitionChannel: null })
                }
                loading={handbooksStore.isSegmentCategoriesLoading}
                options={
                  handbooksStore.segmentCategories.length > 0 &&
                  handbooksStore.businessCategoriesWithAll.map(m => ({
                    value: m.code,
                    label: m.value
                  }))
                }
              />
            </Form.Item>
          )
        },
        {
          key: 'segmentSubCategory',
          value: store.filter.segmentSubCategory,
          collapsedColProps: { xs: 0, md: 12, lg: 12, xl: 8, xxl: 6 },
          colProps: { xs: 24, sm: 12, md: 12, lg: 12, xl: 8, xxl: 6 },
          showInCollapsed: true,
          component: (
            <Form.Item label={translations().segmentSubCategory}>
              <Select
                disabled={!store.filter.segmentCategory}
                value={store.filter.segmentSubCategory}
                onChange={v => store.setFilter({ segmentSubCategory: v })}
                loading={handbooksStore.isSegmentSubCategoriesLoading}
                options={
                  handbooksStore.segmentSubCategories.length > 0 &&
                  store.filter.segmentCategory &&
                  handbooksStore.sortedSubCategoriesByCategory(store.filter.segmentCategory).map(m => ({
                    value: m.code,
                    label: m.value
                  }))
                }
              />
            </Form.Item>
          )
        },
        {
          key: 'acquisitionChannel',
          value: store.filter.acquisitionChannel,
          collapsedColProps: { xs: 0, md: 12, lg: 12, xl: 8, xxl: 6 },
          colProps: { xs: 24, sm: 12, md: 12, lg: 12, xl: 8, xxl: 6 },
          showInCollapsed: true,
          component: (
            <Form.Item label={translations().acquisitionChannel}>
              <Select
                disabled={!store.filter.segmentCategory}
                value={store.filter.acquisitionChannel}
                onChange={v => store.setFilter({ acquisitionChannel: v })}
                loading={handbooksStore.isStartProcessAcquisitionChannelsLoading}
                options={
                  handbooksStore.startProcessAcquisitionChannels.length > 0 &&
                  store.filter.segmentSubCategory &&
                  handbooksStore.sortedAcqChannelsBySubCategory(store.filter.segmentSubCategory).map(m => ({
                    value: m.code,
                    label: m.value
                  }))
                }
              />
            </Form.Item>
          )
        },
        {
          key: 'opportunityId',
          value: store.filter.opportunityId,
          colProps: { xs: 24, sm: 12, md: 12, lg: 12, xl: 8, xxl: 6 },
          showInCollapsed: true,
          component: (
            <Form.Item label={translations().opportunityId}>
              <Input
                value={store.filter.opportunityId}
                onChange={e => store.setFilter({ opportunityId: e.target.value?.trim() }, false)}
                onBlur={() => store.loadData(1)}
                onPressEnter={() => store.loadData(1)}
              />
            </Form.Item>
          )
        },
        {
          key: 'assignedGroup',
          value: store.filter.assignedGroup,
          colProps: { xs: 24, sm: 12, md: 12, lg: 12, xl: 8, xxl: 6 },
          component: (
            <Form.Item label={translations().assignedGroup}>
              <Select
                value={store.filter.assignedGroup}
                onChange={v => store.setFilter({ assignedGroup: v, assignedUser: null })}
                loading={handbooksStore.isBpmUserGroupsLoading}
                options={handbooksStore.bpmUserGroups.map(m => ({ value: m.id, label: m.name }))}
              />
            </Form.Item>
          )
        },
        {
          key: 'assignedUser',
          value: store.filter.assignedUser,
          colProps: { xs: 24, sm: 12, md: 12, lg: 12, xl: 8, xxl: 6 },
          component: (
            <Form.Item label={translations().assignedUser}>
              <Select
                disabled={bpmUsers.length <= 1}
                value={store.filter.assignedUser}
                onChange={v => store.setFilter({ assignedUser: v })}
                loading={handbooksStore.isBpmUsersLoading}
                options={bpmUsers}
              />
            </Form.Item>
          )
        },
        {
          key: 'companyName',
          value: store.filter.companyName,
          colProps: { xs: 24, sm: 12, md: 12, lg: 12, xl: 8, xxl: 6 },
          component: (
            <Form.Item label={translations().merchant}>
              <Input
                value={store.filter.companyName}
                onChange={e => store.setFilter({ companyName: e.target.value }, false)}
                onBlur={() => store.loadData(1)}
              />
            </Form.Item>
          )
        },
        {
          key: 'mid',
          value: store.filter.mid,
          colProps: { xs: 24, sm: 12, md: 12, lg: 12, xl: 8, xxl: 6 },
          component: (
            <Form.Item label={translations().mid}>
              <Input
                value={store.filter.mid}
                onChange={e => store.setFilter({ mid: e.target.value?.trim() }, false)}
                onBlur={() => store.loadData(1)}
              />
            </Form.Item>
          )
        },
        {
          key: 'email',
          value: store.filter.email,
          colProps: { xs: 24, sm: 12, md: 12, lg: 12, xl: 8, xxl: 6 },
          component: (
            <Form.Item label={translations().email}>
              <Input
                value={store.filter.email}
                onChange={e => store.setFilter({ email: e.target.value?.trim() }, false)}
                onBlur={() => store.loadData(1)}
              />
            </Form.Item>
          )
        },
        {
          key: 'phoneNumber',
          value: store.filter.phoneNumber,
          colProps: { xs: 24, sm: 12, md: 12, lg: 12, xl: 8, xxl: 6 },
          component: (
            <Form.Item label={translations().phoneNumber}>
              <Input
                value={store.filter.phoneNumber}
                onChange={e => store.setFilter({ phoneNumber: e.target.value?.trim() }, false)}
                onBlur={() => store.loadData(1)}
              />
            </Form.Item>
          )
        },
        {
          key: 'initiatorEmail',
          value: store.filter.initiatorEmail,
          colProps: { xs: 24, sm: 12, md: 12, lg: 12, xl: 8, xxl: 6 },
          component: (
            <Form.Item label={translations().initiatorEmail}>
              <Input
                value={store.filter.initiatorEmail}
                onChange={e => store.setFilter({ initiatorEmail: e.target.value?.trim() }, false)}
                onBlur={() => store.loadData(1)}
              />
            </Form.Item>
          )
        }
      ]}
    />
  )
})
