import React from 'react'
import DatePicker from '~/code/components/DatePicker'
import { MonthPickerProps } from './MonthPickerProps'
import translations from './translations'
import styles from './styles.scss'

const { RangePicker } = DatePicker

export const MonthPicker: React.FC<MonthPickerProps> = ({ startDate, endDate, onDatesChange }) => {
  return (
    <>
      <span className={styles.pickerLabel}>{translations().period}</span>
      <RangePicker
        format={'MMM YYYY'}
        allowClear={false}
        picker='month'
        value={[startDate, endDate]}
        onChange={([startDate, endDate]) => onDatesChange([startDate, endDate])}
      />
    </>
  )
}
