export class ProcessesFilterModel {
  status: string = null
  email: string = null
  phoneNumber: string = null
  initiatorEmail: string = null
  companyName: string = null
  assignedGroup: string = null
  assignedUser: string = null
  acquisitionChannel: string = null
  approvalType: string = null
  mid: string = null
  productType: string = null
  segmentCategory: string = null
  segmentSubCategory: string = null
  opportunityId: string = null
}
