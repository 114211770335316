import translations from '../translations'

export const columnsBlended = [
    {
        title: translations().tariffName,
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: translations().feeBase,
        dataIndex: 'feeBase',
        key: 'feeBase',
    },
    {
        title: translations().feePercent,
        dataIndex: 'feePercent',
        key: 'feePercent',
    }
]

export const columnsIcPlus = [
    {
        title: translations().transactionType,
        children: [
            {
                key: 'cardScheme',
                dataIndex: 'cardScheme',
                title: translations().cardScheme
            },
            {
                key: 'serviceArea',
                dataIndex: 'serviceArea',
                title: translations().serviceArea,
                width: 200
            },
            {
                key: 'corporateOrConsumer',
                dataIndex: 'corporateOrConsumer',
                title: translations().corporateOrConsumer,
                width: 110
            },
            {
                key: 'cardType',
                dataIndex: 'cardType',
                title: translations().cardType
            }
        ]
    },
    {
        title: translations().interchangeFee,
        children: [
            {
                key: 'interchangeFee',
                dataIndex: 'interchangeFee',
                title: translations().percentFee
            }
        ]
    },
    {
        title: translations().schemeFee,
        children: [
            {
                key: 'schemeBaseFee',
                dataIndex: 'schemeBaseFee',
                title: translations().baseFee
            },
            {
                key: 'schemePercentFee',
                dataIndex: 'schemePercentFee',
                title: translations().percentFee
            }
        ]
    },
    {
        title: translations().dnaStandardFee,
        children: [
            {
                key: 'standardBaseFee',
                dataIndex: 'standardBaseFee',
                title: translations().baseFee,
            },
            {
                key: 'standardPercentFee',
                dataIndex: 'standardPercentFee',
                title: translations().percentFee
                
            }
        ]
    },
    {
        title: translations().totalFee,
        children: [
            {
                key: 'totalBaseFee',
                dataIndex: 'totalBaseFee',
                title: translations().baseFee
            },
            {
                key: 'totalPercentFee',
                dataIndex: 'totalPercentFee',
                title: translations().percentFee
            }
        ]
    }
]