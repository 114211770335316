import translations from './translations'

translations.add('en', {
  terminalId: 'Terminal Id',
  tariffCode: 'Tariff Code',
  tariffName: 'Tariff Name',
  feeBase: 'Fee Base',
  feePercent: 'Fee Percent',
  settlement: 'Settlement Period',
  tids: 'TIDS',
  blended: 'Blended',
  icPlus: 'IC++',
  transactionType: 'Transaction Type (Merchant Services Charges (per Transaction) *)',
  backText: 'Back to Product & Company',
  cardScheme: 'Card Scheme',
  serviceArea: 'Service Area',
  corporateOrConsumer: 'Corporate or Consumer',
  cardType: 'Card Type',
  baseFee: 'Base fee (p.)',
  percentFee: 'Percent fee (%)',
  totalFee: 'Total fee',
  dnaStandardFee: 'DNA Standard fee',
  schemeFee: 'Scheme fee',
  interchangeFee: 'Interchange fee'
})
