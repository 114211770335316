import moment from 'moment-timezone'
import { Months } from '~/code/constants/Months'
import { PartnerCommissionsResponseType } from '../models'
import translations from '~/code/translations'

export const getChartDataNetRev = (year1: string, year2: string, datas: PartnerCommissionsResponseType[]) => {
  const months = Object.values(Months).map(value => translations().months[value])

  const res = datas?.map(t => ({
    year: moment(t.reportingMonth).year(),
    month: moment(t.reportingMonth).month(),
    ...t
  }))

  const year1Dataset = []
  for (let i = 0; i <= 11; i++) {
    year1Dataset.push(res?.filter(r => r.year === Number(year1)).find(f => f.month === i)?.netRevenue || 0)
  }

  const year2Dataset = []
  for (let i = 0; i <= 11; i++) {
    year2Dataset.push(res?.filter(r => r.year === Number(year2)).find(f => f.month === i)?.netRevenue || 0)
  }

  return {
    datasets: [
      {
        label: `${year1}`,
        data: year1Dataset,
        backgroundColor: 'rgba(24, 143, 255, 0.35)',
        borderWidth: 1
      },
      {
        label: `${year2}`,
        data: year2Dataset,
        backgroundColor: 'rgba(24, 143, 255, 0.7)',
        borderWidth: 1
      }
    ],
    labels: months
  }
}

export const getChartDataCommissions = (year1: string, year2: string, datas: PartnerCommissionsResponseType[]) => {
  const months = Object.values(Months).map(value => translations().months[value])

  const res = datas?.map(t => ({
    year: moment(t.reportingMonth).year(),
    month: moment(t.reportingMonth).month(),
    ...t,
    salesFeeVolume: Number(t.salesFeeVolume?.toString()?.replace('-', ''))
  }))

  const year1Dataset = []
  for (let i = 0; i <= 11; i++) {
    year1Dataset.push(res?.filter(r => r.year === Number(year1)).find(f => f.month === i)?.salesFeeVolume || 0)
  }

  const year2Dataset = []
  for (let i = 0; i <= 11; i++) {
    year2Dataset.push(res?.filter(r => r.year === Number(year2)).find(f => f.month === i)?.salesFeeVolume || 0)
  }

  return {
    datasets: [
      {
        label: `${year1}`,
        data: year1Dataset,
        backgroundColor: 'rgba(24, 143, 255, 0.35)',
        borderWidth: 1
      },
      {
        label: `${year2}`,
        data: year2Dataset,
        backgroundColor: 'rgba(24, 143, 255, 0.7)',
        borderWidth: 1
      }
    ],
    labels: months
  }
}

export const getChartDataTransactionVol = (year1: string, year2: string, datas: PartnerCommissionsResponseType[]) => {
  const months = Object.values(Months).map(value => translations().months[value])

  const res = datas?.map(t => ({
    year: moment(t.reportingMonth).year(),
    month: moment(t.reportingMonth).month(),
    ...t
  }))

  const year1Dataset = []
  for (let i = 0; i <= 11; i++) {
    year1Dataset.push(res?.filter(r => r.year === Number(year1)).find(f => f.month === i)?.salesVolume || 0)
  }

  const year2Dataset = []
  for (let i = 0; i <= 11; i++) {
    year2Dataset.push(res?.filter(r => r.year === Number(year2)).find(f => f.month === i)?.salesVolume || 0)
  }

  return {
    datasets: [
      {
        label: `${year1}`,
        data: year1Dataset,
        backgroundColor: 'rgba(24, 143, 255, 0.35)',
        borderWidth: 1
      },
      {
        label: `${year2}`,
        data: year2Dataset,
        backgroundColor: 'rgba(24, 143, 255, 0.7)',
        borderWidth: 1
      }
    ],
    labels: months
  }
}
