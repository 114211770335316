import { getWithAuth, postWithAuth, patchWithAuth } from '~/code/services'

import {
  PartnerType,
  PartnerLoginType,
  PartnerRequestType,
  PartnerLoginsRequestType,
  UpdatePartnerRequestType,
  PartnerLoginsResponseType,
  AddPartnerLoginsRequestType,
  PartnerPermissionResponseType
} from '~/code/stores/PartnerAccessStore/types'

export const fetchPartners = () => {
  return getWithAuth<PartnerType[]>(`/api/v1/dashboard/partners`)
}

export const addPartnerLogins = (partner: AddPartnerLoginsRequestType) => {
  return postWithAuth(`/api/v1/dashboard/partners/logins`, partner)
}

export const addPartner = (partner: PartnerRequestType) => {
  return postWithAuth(`/api/v1/dashboard/partners`, partner)
}

export const updatePartner = (partner: UpdatePartnerRequestType) => {
  return patchWithAuth(`/api/v1/dashboard/partners`, partner)
}

export const fetchPartnerLogins = ({ partnerKey, size, page }: PartnerLoginsRequestType) => {
  return getWithAuth<PartnerLoginsResponseType>(
    `/api/v1/dashboard/partners/logins?partnerKey=${partnerKey}&page=${page}&size=${size}`
  )
}

export const fetchPartnersByLogin = (login: string) => {
  return getWithAuth<PartnerLoginType>(`/api/v1/dashboard/partners/${login}`)
}

export const fetchPermissions = () => {
  return getWithAuth<PartnerPermissionResponseType>(`/api/v1/dashboard/partners/permissions`)
}

export const fetchPermissionsByPartner = (partnerKey: string) => {
  return getWithAuth<PartnerPermissionResponseType>(`/api/v1/dashboard/partners/permissions/${partnerKey}`)
}
