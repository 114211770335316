import { inject, injectable } from 'inversify'
import { message } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { action, computed, makeObservable, observable, runInAction, reaction } from 'mobx'
import { CheckedDocumentType } from 'startapp/models/DocumentsRequiredModel'
import { deleteDocument, uploadDocument } from 'startapp/components/SupportingDocuments/services'
import { UploadDocumentRes } from 'startapp/components/SupportingDocuments/models'
import { camelToKebabCase } from 'startapp/services'
import { MerchantsManagementStoreSymbol } from '~/code/pages'
import { MerchantsManagementStore } from '~/code/stores/MerchantsManagementStore/MerchantsManagementStore'
import { startProcessChangeSettlementPeriod } from '~/code/stores/MerchantsManagementStore/services/fetchers'
import {
  FileKey,
  ChangeOfSettlementPeriodStoreType
} from '~/code/pages/MerchantsManagement/components/ChangeOfSettlementPeriod/ChangeOfSettlementPeriodStoreType'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import { AppStore } from '../AppStore'
import { ChangeSettlementPeriodSubmitType } from '~/code/pages/MerchantsManagement/components/ChangeOfSettlementPeriod/models/ChangeSettlementPeriodSubmitType'
import { ChangeOfSettlementPeriodReqType } from './models/merchant-dossier-v2'
import {
  activeTerminalStatus,
  posTerminalPrefix
} from '~/code/pages/MerchantsManagement/components/ChangeOfSettlementPeriod/constants'
import { jsonType, stringType } from './constants'
import translations from './translations'

@injectable()
export class ChangeOfSettlementPeriodStore implements ChangeOfSettlementPeriodStoreType {
  merchantsManagementStore: MerchantsManagementStore
  productType: string
  isDocumentLoading: boolean
  documentsData: UploadDocumentRes[] = []
  documentTypes: FileKey[] = []
  documentsMap: Record<FileKey, UploadFile[]> = {
    bankStatement: [],
    processingStatement: [],
    other: []
  }
  isLoading: boolean
  isNoDocumentsError: boolean

  constructor(@inject(MerchantsManagementStoreSymbol) merchantsManagementStore: MerchantsManagementStore) {
    this.merchantsManagementStore = merchantsManagementStore
    this.productType = 'pos'
    this.isDocumentLoading = false
    this.documentTypes = []
    this.isLoading = false
    this.isNoDocumentsError = false

    makeObservable(this, {
      productType: observable,
      isDocumentLoading: observable,
      documentsMap: observable,
      documentTypes: observable,
      isLoading: observable,
      isNoDocumentsError: observable,
      posTerminal: computed,
      ecomTerminal: computed,
      fullDossierV2: computed,
      isMerchantActive: computed,
      ecomSettlementPeriod: computed,
      posSettlementPeriod: computed,

      setProductType: action,
      setDocumentsMap: action,
      addDocumentType: action,
      setNoDocumentsError: action,
      clearData: action
    })

    this.init()

    reaction(
      () => this.fullDossierV2,
      () => {
        this.init()
      }
    )
  }

  init() {
    this.clearData()
  }

  public setProductType = (value: string) => {
    this.productType = value
  }

  addDocumentType(fileKey: FileKey, fileList: UploadFile[]) {
    this.setDocumentsMap(fileKey, [...this.documentsMap[fileKey], ...fileList])
    this.addDocumentTypesArray(fileKey)
  }

  public addDocumentTypesArray(value: FileKey) {
    if (this.documentTypes.includes(value)) return
    this.documentTypes.push(value)
  }

  get fullDossierV2() {
    return this.merchantsManagementStore.companyFullDossierV2
  }

  get posSettlementPeriod() {
    return this.merchantsManagementStore.settlementPeriodPos?.toString() || null
  }

  get ecomSettlementPeriod() {
    return this.merchantsManagementStore.settlementPeriodEcom?.toString() || null
  }

  get posTerminal() {
    const posesList = []
    const stores = this.merchantsManagementStore.companyFullDossierV2?.processing?.stores || []

    stores.forEach(store => {
      store.contracts.forEach(contract => {
        const filteredPoses = contract.terminals
          .filter(
            terminal =>
              terminal.terminal.terminalId.startsWith(posTerminalPrefix) &&
              terminal.terminal.terminalStatus === activeTerminalStatus
          )
          .map(pos => pos.terminal)

        posesList.push(...filteredPoses)
      })
    })

    return posesList
  }

  get ecomTerminal() {
    const ecomList = []
    const stores = this.merchantsManagementStore.companyFullDossierV2?.processing?.stores || []

    stores.forEach(store => {
      store.contracts.forEach(contract => {
        const filteredEcoms = contract.terminals
          .filter(
            terminal =>
              !terminal.terminal.terminalId.startsWith(posTerminalPrefix) &&
              terminal.terminal.terminalStatus === activeTerminalStatus
          )
          .map(pos => pos.terminal)

        ecomList.push(...filteredEcoms)
      })
    })

    return ecomList
  }

  get isMerchantActive() {
    return this.merchantsManagementStore.isMerchantActive
  }

  async uploadFile(documentType: FileKey | CheckedDocumentType, file: File): Promise<boolean> {
    if (!file) return false

    let isUploaded: boolean

    runInAction(() => {
      this.isDocumentLoading = true
    })

    try {
      const { status, error, result } = await uploadDocument({
        companyName: this.merchantsManagementStore.selectedMerchant?.companyName,
        companyNumber: this.merchantsManagementStore.selectedMerchant?.companyNumber,
        acquisitionChannel:
          this.merchantsManagementStore.companyFullDossierV2?.merchantAcquisitionInfo?.[0]?.acquisitionChannelValue,
        documentType: camelToKebabCase(documentType),
        file
      })

      if (status !== 200 || error || !result || result.length <= 0) {
        message.error(error.message || translations().errorUploadingDocument)
        isUploaded = false
      } else {
        runInAction(() => {
          this.documentsData.push({ ...result[0], internalFileName: file.name, type: documentType })
        })
        isUploaded = true
        this.setNoDocumentsError(false)
      }
    } catch (error) {
      message.error(translations().errorUploadingDocument)
      isUploaded = false
    } finally {
      runInAction(() => {
        this.isDocumentLoading = false
      })
    }

    return isUploaded
  }

  public setDocumentsMap(fileKey: FileKey, fileList: UploadFile[]) {
    this.documentsMap[fileKey] = fileList
  }

  public clearData() {
    this.documentsData = []
    this.documentTypes = []
    this.documentsMap = {
      bankStatement: [],
      processingStatement: [],
      other: []
    }
  }

  public removeDocument(fileKey: FileKey, fileList: UploadFile[]) {
    this.setDocumentsMap(fileKey, fileList)
    if (fileList.length === 0) this.removeDocumentType(fileKey)
  }

  public removeDocumentType(value: FileKey) {
    this.documentTypes = this.documentTypes.filter(doc => doc !== value)
  }

  async deleteFile(documentType: FileKey | CheckedDocumentType, fileName: string): Promise<boolean> {
    let isDeleted: boolean
    try {
      const path = this.documentsData.find(d => d?.internalFileName === fileName && d?.type === documentType)?.path
      if (path) {
        const { status, error } = await deleteDocument(path)
        if (status !== 200 || error) {
          message.error(error.message || translations().errorDeletingDocument)
          isDeleted = false
        } else {
          isDeleted = true
        }
      }
    } catch (error) {
      message.error(translations().errorDeletingDocument)
      isDeleted = false
    }
    return isDeleted
  }

  public setNoDocumentsError(value: boolean) {
    this.isNoDocumentsError = value
  }

  createDocumentsData() {
    return this.documentsData.map(document => ({
      path: document.path,
      name: document.fileName,
      type: camelToKebabCase(document.type)
    }))
  }

  createClosureMerchantData(values: ChangeSettlementPeriodSubmitType, dsrId: number): ChangeOfSettlementPeriodReqType {
    const docs = this.createDocumentsData()
    return {
      variables: {
        dossierId: {
          value: dsrId.toString(),
          type: stringType
        },
        initiatorEmail: {
          value: AppStore.authStore.email,
          type: stringType
        },
        initiatorComment: {
          value: values.comment,
          type: stringType
        },
        settlementPeriod: {
          value: values.newSettlementPeriod,
          type: stringType
        },
        oldSettlementPeriod: {
          value: this.productType === 'ecom' ? this.ecomSettlementPeriod : this.posSettlementPeriod,
          type: stringType
        },
        product: {
          value: this.productType,
          type: stringType
        },
        documents: {
          value: JSON.stringify(docs),
          type: jsonType
        }
      }
    }
  }

  public onFormFinish = async (values: ChangeSettlementPeriodSubmitType) => {
    if (!this.documentsData.length) {
      this.setNoDocumentsError(true)
      return
    }

    const { dsrId } = this.merchantsManagementStore.companyFullDossierV2?.mainInfo

    if (!dsrId) {
      message.error(translations().dsrIdIsEmpty)
      return
    }

    const data = this.createClosureMerchantData(values, dsrId)

    try {
      runInAction(() => {
        this.isLoading = true
      })

      const { status, error } = await startProcessChangeSettlementPeriod(data)

      if (status === 200 && !error) {
        message.success(translations().requestSuccess)
        return goToRoute(Routes.BPM_CHANGE_OF_SETTLEMENT_PERIOD)
      } else {
        throw new Error(error.message || translations().requestFail)
      }
    } catch (error) {
      message.error(error.message || translations().requestFail)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }
}
