import React from 'react'
import classNames from 'classnames'
import { Button, Card, Col, Form, Row, Select } from 'antd'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { useForm } from 'antd/es/form/Form'
import { MonthPickerContainer } from '~/code/containers'
import { INetSuiteInvoicesFilterStore } from '~/code/pages/Billing/pages/NetSuiteInvoices/components/NetSuiteInvoicesFilter/INetSuiteInvoicesFilterStore'

import translations from '../../translations'
import styles from './styles.scss'

export const RangePickerNetSuiteInvoicesStoreSymbol = Symbol('RangePickerNetSuiteInvoicesStoreSymbol')
export const NetSuiteInvoicesFilterStoreSymbol = Symbol('NetSuiteInvoicesFilterStoreSymbol')

export const NetSuiteInvoicesFilter = observer(() => {
  const [form] = useForm()

  const { selectedSubsidiary, subsidiaries, isLoading, selectSubsidiary, resetFilter } =
    useInjection<INetSuiteInvoicesFilterStore>(NetSuiteInvoicesFilterStoreSymbol)

  const handleResetFilter = () => {
    resetFilter()
    form.setFieldsValue({ subsidiary: 'active-payments' })
  }

  return (
    <Card>
      <Row gutter={24}>
        <Col>
          <Form form={form} initialValues={{ subsidiary: selectedSubsidiary }}>
            <Form.Item name={'subsidiary'} label={translations().subsidiary} className={styles.select}>
              <Select
                loading={isLoading}
                options={subsidiaries}
                onChange={selectSubsidiary}
                className={classNames({ [styles.selectLoading]: isLoading })}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col>
          <MonthPickerContainer injectableStoreIdentifier={RangePickerNetSuiteInvoicesStoreSymbol} />
        </Col>
        <Col>
          <Button className={styles.resetBtn} onClick={handleResetFilter}>
            {translations().reset}
          </Button>
        </Col>
      </Row>
    </Card>
  )
})
