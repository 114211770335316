import translations from './translations'

translations.add('en', {
    comment: 'Comment',
    commentRequired: 'Comment is required',
    commentMinMsg: (length) => `Comment must be at least ${ length } characters`,
    areYouSureToggle: (isEnabled, toggleText) => `${isEnabled ? 'Disable' : 'Enable'} ${toggleText}. Are you ABSOLUTELY SURE?`,
    warningText: (isEnabled, toggleText) => `You are about to ${isEnabled ? 'disable' : 'enable'} ${toggleText}`,
    onceDisabled: (switchType) => `Once disabled, transactions will not be processed through ${switchType === 'channel' ? 'the' : ''} ${switchType}.`,
    youDontHavePermissionsToUpdate: 'You don\'t have permissions to update the transaction channel settings',
    pleaseEnableOneChannel: 'Please enable at least one transaction channel to disable this one',
    pleaseTypeToConfirm: 'Please type the following to confirm',
    on: 'ON',
    off: 'OFF'
})
