import { deleteWithAuth } from '~/code/services'
import storage from '~/code/services/storage'
import { UploadDocumentReq, UploadDocumentRes } from '../models'
import { getUploadDocFormData } from './utils'

export const uploadDocument = async (params: UploadDocumentReq) => {
    const formData = getUploadDocFormData(params)
    const accessToken = storage.get('accessToken')

    try {
        const res = await fetch('/api/v1/sales-onboarding/files', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            body: formData
        })
    
        const data = await res.json()
    
        if (res.status === 200) {
            return {
                status: 200,
                result: data as UploadDocumentRes[]
            }
        }
    
        return {
            error: data as { code: number, message: string }
        }
    } catch (error) {
        return { error }
    }
}

export const deleteDocument = (path: string) => deleteWithAuth(`/api/v1/sales-onboarding/files?path=${path}`)