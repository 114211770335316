import { observer } from 'mobx-react'
import React from 'react'
import { RangePicker } from '../RangePicker'
import { DesktopRangePickerProps } from './props'

export const DesktopRangePicker = observer((props: DesktopRangePickerProps) => {
    const { store } = props
    return <RangePicker
        startDate={store.startDate}
        endDate={store.endDate}
        period={store.period}
        onSetDates={store.setDates}
        onSetPeriod={store.setPeriod}
    />
})
