import { UploadOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'
import React, { useState } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { BackButton, FilterSelect } from '~/code/components'
import { SupportingDocument, Upload } from './components'
import { SupportingDocumentsType } from 'startapp/models'
import { FormTitle, NextButton } from 'startapp/components'
import { SupportingDocumentsStore } from '../../stores/SupportingDocumentsStore'
import { StartAppContainer } from '../StartAppContainer'
import { FormSubgroupTitle } from '../shared'
import { availableAdditionalDocuments } from './components/SupportingDocument/constants'
import translations from './translations'
import styles from './SupportingDocuments.scss'

type Props = { store: SupportingDocumentsStore }

export const SupportingDocuments = observer(({ store }: Props) => {
  const [documentType, setDocumentType] = useState<keyof SupportingDocumentsType>('' as keyof SupportingDocumentsType)

  const renderAdditionalDocumentUpload = (_documentType: keyof SupportingDocumentsType) => {
    const acceptedExtensions = _documentType === 'additionalTidsRequestForm' ? '.xls, .xlsx' : undefined
    return (
      <Upload
        acceptedExtensions={acceptedExtensions}
        disabled={!documentType}
        loading={store.isDocumentLoading.uploadAdditionalDocument}
        fileList={[]}
        onChange={async fileList => {
          const file = fileList[0]
          if (!file) return
          const isUploaded = await store.uploadFile(documentType, file?.originFileObj as File)
          if (!isUploaded) return
          store.addAdditionalDocument(documentType, fileList)
          setDocumentType('' as keyof SupportingDocumentsType)
        }}
      >
        <Button icon={<UploadOutlined />}>{translations().upload}</Button>
      </Upload>
    )
  }

  return (
    <div>
      <BackButton onClick={() => store.openCompleteApplicationPage()}>{translations().backToThirdStep}</BackButton>
      <StartAppContainer>
        <FormTitle>{translations().title}</FormTitle>
        <div className={styles.uploadFileText}>{translations().uploadFileText}</div>
        <FormSubgroupTitle>{translations().mandatoryDocuments}</FormSubgroupTitle>
        {store.mandatoryDocuments.map(d => (
          <SupportingDocument
            {...d}
            fileList={store.supportingDocumentsMap[d.key]}
            onChange={async (fileList, file) => {
              const fileToUpload = fileList[0]
              if (fileToUpload) {
                const isUploaded = await store.uploadFile(d.key, fileToUpload?.originFileObj as File)
                if (!isUploaded) return
                store.setSupportingDocumentsMap(d.key, fileList)
              } else {
                const isDeleted = await store.deleteFile(d.key, file.name)
                if (!isDeleted) return
                store.setSupportingDocumentsMap(d.key, fileList)
              }
            }}
            loading={store.isDocumentLoading[d.key]}
          />
        ))}
        {store.idDocuments.length > 0 && (
          <div className={styles.checkedDocument}>{translations().passportOrDriving}</div>
        )}
        {store.idDocuments.map((b, idx, arr) => (
          <SupportingDocument
            lessMarginBottom={idx !== arr.length - 1}
            key={b.officer as any}
            title={b.officer}
            loading={b.isLoading}
            notRequiredText={b.isRequired ? null : translations().notRequired.passportOrDriving}
            icon={<UserOutlined className={styles.userIcon} />}
            fileList={b.fileList}
            onChange={async (fileList, file) => {
              const fileToUpload = fileList[0]
              if (fileToUpload) {
                const isUploaded = await store.uploadFile('idDocument', fileToUpload?.originFileObj as File, b.officer)
                if (!isUploaded) return
                store.setIdDocument(b.officer, fileList)
              } else {
                const isDeleted = await store.deleteFile('idDocument', file.name, b.officer)
                if (!isDeleted) return
                store.setIdDocument(b.officer, fileList)
              }
            }}
          />
        ))}
        {store.residentialUtilityBills.length > 0 && (
          <div className={styles.checkedDocument}>{translations().residentialUtilityBill}</div>
        )}
        {store.residentialUtilityBills.map((b, idx, arr) => (
          <SupportingDocument
            lessMarginBottom={idx !== arr.length - 1}
            key={b.officer as any}
            title={b.officer}
            loading={b.isLoading}
            notRequiredText={b.isRequired ? null : translations().notRequired.residentialUtilityBill}
            icon={<UserOutlined className={styles.userIcon} />}
            fileList={b.fileList}
            onChange={async (fileList, file) => {
              const fileToUpload = fileList[0]
              if (fileToUpload) {
                const isUploaded = await store.uploadFile(
                  'residentialUtilityBill',
                  fileToUpload?.originFileObj as File,
                  b.officer
                )
                if (!isUploaded) return
                store.setResidentialUtilityBillDocument(b.officer, fileList)
              } else {
                const isDeleted = await store.deleteFile('residentialUtilityBill', file.name, b.officer)
                if (!isDeleted) return
                store.setResidentialUtilityBillDocument(b.officer, fileList)
              }
            }}
          />
        ))}
        <div className={styles.additionalDocumentsContainer}>
          <FormSubgroupTitle>{translations().additionalDocuments}</FormSubgroupTitle>
          {store.additionalDocumentTypes.map(d => (
            <SupportingDocument
              key={d}
              title={translations()[d]}
              fileList={store.supportingDocumentsMap[d]}
              onChange={async (fileList, file) => {
                const isDeleted = await store.deleteFile(d, file?.name)
                if (!isDeleted) return
                store.removeAdditionalDocument(d, fileList)
              }}
              loading={store.isDocumentLoading[d]}
            />
          ))}
          <div className={styles.additionalDocumentsActions}>
            <Form layout='vertical'>
              <FilterSelect
                formItemProps={{ label: translations().documentType }}
                selectProps={{
                  className: styles.documentTypeSelect,
                  value: documentType,
                  onChange: setDocumentType,
                  options: availableAdditionalDocuments
                }}
              />
            </Form>
            {renderAdditionalDocumentUpload(documentType)}
          </div>
          {documentType === 'additionalTidsRequestForm' && (
            <div className={classNames(styles.uploadFileText)}>{translations().onlyXlsFilesAreSupported}</div>
          )}
        </div>

        <NextButton disabled={!store.isSupportingDocumentsValid} onClick={() => store.openResultPage()} />
      </StartAppContainer>
    </div>
  )
})
