import { action, autorun, makeObservable, observable, runInAction } from 'mobx'
import { PeriodType } from '~/code/models'
import { BpmStages } from '~/code/constants/BpmStages'
import { HandbooksStore } from '~/code/stores/HandbooksStore'
import { RangePickerStore } from '~/code/stores/RangePickerStore'
import { BpmAnalyticsItemModel, BpmAnalyticsStageModel } from '../../models'
import { fetchBpmAnalytics } from '../../services/fetchers'

export class BpmAnalyticsStore {
  isInitiated: boolean = false
  isLoading: boolean = false
  totalApplications: number = 0
  dateStore: RangePickerStore
  data: BpmAnalyticsStageModel[] = []
  acquisitionChannel: string = null

  constructor(public handbooksStore: HandbooksStore, rangePickerStore: RangePickerStore) {
    this.dateStore = rangePickerStore

    makeObservable(this, {
      isLoading: observable,
      data: observable,
      acquisitionChannel: observable,
      init: action,
      reset: action,
      loadData: action,
      setAcquisitionChannel: action
    })

    autorun(() => {
      if (this.dateStore.startDate && this.dateStore.endDate) {
        this.loadData()
      }
    })
  }

  setAcquisitionChannel(value: string) {
    this.acquisitionChannel = value
    this.loadData()
  }

  async init() {
    this.isInitiated = true
    this.reset()
    if (this.dateStore.period === null) this.dateStore.setPeriod(PeriodType.Yesterday)
    this.handbooksStore.loadAcquisitionChannels()
  }

  reset() {
    this.totalApplications = 0
    this.data = this.getParseData([])
  }

  async loadData() {
    this.isLoading = true
    this.reset()
    await this.handbooksStore.loadBpmStatuses()
    const { result, error } = await fetchBpmAnalytics({
      from: this.dateStore.startDate.clone().utc().format(),
      to: this.dateStore.endDate.clone().utc().format(),
      acquisitionChannel: this.acquisitionChannel || undefined
    })

    runInAction(() => {
      if (!error) {
        const data: BpmAnalyticsItemModel[] = result.current.data.map((current, index) => {
          const { count, target, stage, status } = current
          const { avgReviewTime } = result.historical.data[index]
          return { count, target, stage, status, avgReviewTime }
        })

        this.totalApplications = result.current.totalApplications
        this.data = this.getParseData(data)
      }

      this.isLoading = false
    })
  }

  getParseData(items: BpmAnalyticsItemModel[]) {
    const stageMap: Record<string, BpmAnalyticsItemModel[]> = {}
    items.forEach(item => {
      const { stage } = item
      if (!stageMap[stage]) {
        stageMap[stage] = []
      }
      stageMap[stage].push(item)
    })

    return BpmStages.map(({ name, gaugeData }) => {
      const subStatuses = stageMap[name] || []
      const description = this.handbooksStore.bpmStatuses.find(s => s.name === name)?.description
      const { count, avgReviewTime, target } = subStatuses.reduce(
        (prev, c) => {
          return {
            count: prev.count + c.count,
            avgReviewTime: prev.avgReviewTime + c.avgReviewTime,
            target: prev.target + c.target
          }
        },
        { count: 0, avgReviewTime: 0, target: 0 }
      )

      return {
        name,
        description,
        gaugeData,
        count,
        target,
        avgReviewTime,
        subStatuses
      }
    })
  }
}
