import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Col, Row } from 'antd'
import debounce from 'lodash.debounce'
import { TumblerSprite } from '~/assets/icons'
import { OFF_POSITION, ON_POSITION } from '~/code/pages/TransactionChannels/components/SwitchTumbler/constants'
import classNames from 'classnames'
import { TumblerProps } from './props'
import styles from './styles.scss'

export const Tumbler = ({checked, onClick, isDisabled, tumblerRef}: TumblerProps) => {

    const [flag, setFlag] = useState(() => checked)

    useLayoutEffect(() => {
        tumblerRef.current.style.left = checked ? `${ON_POSITION}px` : `${OFF_POSITION}px`
    }, [])

    useEffect(() => {
        debounceSetFlag()
    }, [checked])

    const debounceSetFlag = debounce(() => {
        setFlag(checked)
    }, 300)

    return (
        <>
            <div className={styles.tumblerContainer}>
                <Row>
                    <Col>
                        <div className={styles.signal}>
                            <div className={styles.innerRound}>
                                <div className={classNames(styles.light, {[styles.red]: !flag, [styles.grey]: flag})}/>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className={styles.tumblerSprite}>
                            <div onClick={ !isDisabled ? onClick : null} ref={tumblerRef} className={styles.tumblerWrapper}>
                                <TumblerSprite/>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className={styles.signal}>
                            <div className={styles.innerRound}>
                                <div className={classNames(styles.light, {[styles.green]: flag, [styles.grey]: !flag})}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}
