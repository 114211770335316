import translations from './translations'

translations.add('en', {
    backText: 'Back to Pricing',
    generalInformation: 'General Information',
    financialInformation: 'Financial Information',
    shareholders: 'Shareholders',
    directors: 'Directors',
    newDirector: 'New Director',
    signatories: 'Authorised Signatories',
    newSignatory: 'New Signatory',
    businessOwner: 'Business Owner',
    optional: 'Optional',
    completed: 'Completed',
    notCompleted: 'Not Completed',
    pleaseCompleteApplication: 'Please complete application'
})
