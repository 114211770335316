import React from 'react'
import translations from './translations'

translations.add('en', {
  title: 'Pricing plans',
  details: (
    <>
      Please fill out the agreed pricing plan. <br />
      Note: these fees will be automatically reflected on the Merchant Service Agreement
    </>
  ),
  addTitle: 'Add store',
  productName: (prod: string) => (
    <>
      {prod === 'pos' && (
        <>
          <p>POS payments</p>
          <p>Card machines</p>
        </>
      )}

      {prod === 'ecom' && (
        <>
          <p>Online payments</p>
          <p>Website payments, VT, Payment links</p>
        </>
      )}
    </>
  ),
  prodName: (prod: string) => (prod === 'pos' ? 'POS payments' : 'Online payments')
})
