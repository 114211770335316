import React from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Drawer, DrawerProps, Form, Grid, Button } from 'antd'
import {
  IPartnerAccessFormStore,
  PartnerAccessFormStoreSymbol
} from '~/code/pages/PartnerAccess/components/PartnerAccessDrawer'
import { UserSecurity } from '~/code/components'

import translations from './translations'
import styles from './styles.scss'

const { useBreakpoint } = Grid

export const PartnerAccessSecurityDrawer = observer(({ open }: DrawerProps) => {
  const [form] = Form.useForm()
  const screens = useBreakpoint()

  const store = useInjection<IPartnerAccessFormStore>(PartnerAccessFormStoreSymbol)
  const { partnerAccessStore, isLoading } = store

  const { selectedTableItem, onSecurityDrawerClose, setSelectedTableItem } = partnerAccessStore

  const twoFAEnabled = selectedTableItem?.twoFaStatus !== 'DISABLED'
  const currentTwoFAEnabled = Form.useWatch('twoFAEnabled', form)
  const currentReason = Form.useWatch('reason', form)
  const isSubmitDisabled =
    twoFAEnabled === currentTwoFAEnabled || (twoFAEnabled && !currentTwoFAEnabled && !currentReason)

  const handleAfterOpenChange = (visible: boolean) => {
    if (!visible) {
      form.resetFields()
      setSelectedTableItem(null)
    }
  }

  const renderFooter = () => (
    <div className={styles.footer}>
      <Button onClick={onSecurityDrawerClose}>{translations().cancel}</Button>
      <Button
        htmlType='button'
        type='primary'
        loading={isLoading}
        disabled={isSubmitDisabled}
        onClick={() => form.submit()}
      >
        {translations().submit}
      </Button>
    </div>
  )

  return (
    <Drawer
      title={translations().edit}
      open={open}
      onClose={onSecurityDrawerClose}
      afterOpenChange={handleAfterOpenChange}
      width={screens.xs ? window.innerWidth : 512}
      footer={renderFooter()} 
      destroyOnClose
    >
      <Form
        form={form}
        requiredMark={false}
        labelAlign='left'
        labelCol={{ span: 6 }}
        initialValues={{
          twoFAEnabled
        }}
        onFinish={v => store.updateSecurity({ ...v, publicId: selectedTableItem?.id })}
      >
        <UserSecurity form={form} status={selectedTableItem?.twoFaStatus} />
      </Form>
    </Drawer>
  )
})
