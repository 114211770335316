import { inject, injectable } from 'inversify'
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import moment, { Moment } from 'moment-timezone'

import { IMerchantSelectStore } from '~/code/components/MerchantSelect/models'
import { STORE_TYPES } from '~/code/constants/StoreTypes'
import { DataLoadingStatus } from '~/code/models/DataLoadingStatus'
import { YearsComparisonData } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/YearsComparison/models'
import { AnalyticsYearsComparisonRequest } from '~/code/models/analytics/AnalyticsYearsComparisonRequest'

import { isPartner } from '~/code/services/auth'
import { fetchAnalyticsYearsComparison } from '~/code/services/fetchers'
import { getYearsComparisonData } from './services/utils'

import { GlobalConfigStore } from '../GlobalConfigStore'
import { MerchantSelectStoreSymbol } from '../RouterStore'
import storage from '~/code/services/storage'

@injectable()
export class TotalAnalyticsStore {
    merchantSelectStore: IMerchantSelectStore
    configStore: GlobalConfigStore

    _loadingState: DataLoadingStatus = 'idle'
    _yearsComparisonData: YearsComparisonData = null
    yearsComparisonYear1: Moment = moment().subtract(1, 'years')
    yearsComparisonYear2: Moment = moment()

    constructor(
        @inject(MerchantSelectStoreSymbol.analytics.dnaTotal) merchantSelectStore: IMerchantSelectStore,
        @inject(STORE_TYPES.GlobalConfigStore) configStore: GlobalConfigStore
    ) {
        this.merchantSelectStore = merchantSelectStore
        this.configStore = configStore

        makeObservable(this, {
            yearsComparisonYear1: observable,
            yearsComparisonYear2: observable,

            _loadingState: observable,
            _yearsComparisonData: observable,

            isLoadingYearsComparison: computed,
            merchantName: computed,
            isSolidGate: computed,
            currencySymbol: computed,

            changeYearsComparisonYears: action.bound
        })

        reaction(() => this.params, this.loadYearsComparison, { delay: 5 })
    }

    get isLoadingYearsComparison() {
        return this._loadingState === 'idle' || this._loadingState === 'loading'
    }

    get merchantName() {
        return this.merchantSelectStore.merchant?.tradeName
    }

    get acquisitionChannelId() {
        if (isPartner() || this.merchantSelectStore.acquisitionChannel?.id === 'all') return undefined
        return this.merchantSelectStore.acquisitionChannel?.id
    }

    get yearsComparisonData() {
        if (this._loadingState === 'idle') {
            this.loadYearsComparison()
        }

        return this._yearsComparisonData
    }

    get isSolidGate() {
        return isPartner() && storage.get('acquisition_channel') === 'solidgate'
    }

    get currencySymbol() {
        return this.configStore.currency.symbol
    }
    
    get params(): AnalyticsYearsComparisonRequest {

        return {
            year1: this.yearsComparisonYear1.get('year').toString(),
            year2: this.yearsComparisonYear2.get('year').toString(),
            merchantId: this.merchantSelectStore.merchant?.id,
            currency: this.configStore.currency?.type,
            acquisitionChannel: this.acquisitionChannelId
        }
    }

    changeYearsComparisonYears(year1: Moment, year2: Moment) {
        this.yearsComparisonYear1 = year1
        this.yearsComparisonYear2 = year2
    }

    // TODO DAS-359 acquirer
    loadYearsComparison = async () => {
        if (!this.merchantName || this._loadingState === 'loading') return

        runInAction(() => this._loadingState = 'loading')
        const [ecomRes, posRes] = await Promise.all([
            fetchAnalyticsYearsComparison('ecom', { ...this.params, acquirer: this.isSolidGate ? 'paynetics_eu' : 'dna' }),
            fetchAnalyticsYearsComparison('pos', this.params)
        ])

        if (ecomRes.error || posRes.error) {
            runInAction(() => {
                this._loadingState = 'failed'
                this._yearsComparisonData = null
            })
            return
        }

        runInAction(() => {
            const _year1 = this.yearsComparisonYear1.get('year')
            const _year2 = this.yearsComparisonYear2.get('year')

            this._yearsComparisonData = getYearsComparisonData(_year1, _year2, [ecomRes.result, posRes.result])
            this._loadingState = 'finished'
        })
    }
}

