import { TranslationBuilder } from '~/code/components/Translation'
import { SwitchType } from '../models'

export default TranslationBuilder.create<{
    comment: string
    commentRequired: string
    commentMinMsg: (length: number) => string
    areYouSureToggle: (isEnabled: boolean, toggleText: string) => string
    warningText: (isEnabled: boolean, toggleText: string) => string
    onceDisabled: (switchType: SwitchType) => string
    youDontHavePermissionsToUpdate: string
    pleaseEnableOneChannel: string
    pleaseTypeToConfirm: string
    on: string
    off: string
}>()

