import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    notCompleted: string
    deleteFormTitle: (name: string) => string
    deleteFormContent: string
    edit: string
    delete: string
    productsModal: string
    agreementSignatory: string
    verified: string
    notVerified: string
    manuallyDocs: string
}>()

