import React from 'react'
import { observer } from 'mobx-react'
import { Result } from 'antd'
import { Page } from '../'
import translations from './translations'

const NotFound = observer(() => {
    return <Page>
        <Result
            status='404'
            title='404'
            subTitle={translations().text}
        />
    </Page>
})

export { NotFound }
