import translations from './translations'

translations.add('en', {
    applicationSubmitted: 'Application Submitted',
    contactInfoTitle: 'Applicant',
    generalInformationTitle: 'Product & Company',
    pricingTitle: 'Pricing',
    completeApplicationTitle: 'Application',
    supportingDocumentsTitle: 'Documents',
    applicationSubmissionTitle: 'Submit',
    couldNotLoadCompanies: 'Could not load companies',
    next: 'Next',
    back: 'Back',
    errorLoadingApplication: 'An error occurred while loading your application',
    errorLoadingAcquisitionChannels: 'Error loading acquisition channels',
    errorVerifyBankAccount: 'Validation is temporarily unavailable',
    errorVerifyAddress: 'Error verifying address details',
    errorStartingApplication: 'Error starting application',
    newApplication: 'New Application',
    errorCheckingStatus: 'Error checking status of the application',
    applicationAlreadySubmitted: 'Application with this email has already been submitted',
    errorSavingData: 'Error saving data',
    savedDataSuccessfully: 'Saved data successfully',
    all: 'All',
    errorCheckingSFOpportunityID: 'Error checking Salesforce opportunity ID',
    errorCheckingDocumentRequirements: 'Error checking document requirements',
    pos: 'POS (face-to-face)',
    moto: 'MOTO (phone payments)',
    ecom: 'ECOM (Website payments)',
    payByLink: 'ECOM Pay by link only',
    payByApp: 'ECOM Pay by App',
    virtualTerminal: 'ECOM Virtual terminal',
    nbsp: 'Same day sales – product is taken away from stock',
    deposits: 'Deposits',
    balances: 'Balances',
    fullPayment: 'Full payment in advance'
})