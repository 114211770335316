import React from 'react'
import { Dropdown, Row, Col, Radio, Form, MenuProps } from 'antd'
import { SettingOutlined, DownOutlined } from '@ant-design/icons'
import { Text } from '~/code/components/Text'
import { PortalAccessTabKeyType } from '~/code/pages/PortalAccess/types/PortalAccessTabType'

import translations from '../translations'
import styles from '~/code/pages/PortalAccess/styles.scss'

export const renderActions = (type: PortalAccessTabKeyType, onSelect) => {
  let items: MenuProps['items'] = [
    {
      key: 'edit',
      label: translations().menu.editAccess
    },
    {
      key: 'delete',
      label: translations().menu.delete
    }
  ]

  if (type === PortalAccessTabKeyType.merchants) {
    items = [
      {
        key: 'edit-user',
        label: translations().menu.editUser
      },
      ...items.slice(0, 1), // 'edit'
      {
        key: 'edit-security',
        label: translations().menu.editSecurity
      },
      ...items.slice(1) // 'delete'
    ]
  }

  return (
    <Dropdown menu={{ items, onClick: item => onSelect(item.key) }}>
      <div className={styles.actions}>
        <SettingOutlined />
        <DownOutlined />
      </div>
    </Dropdown>
  )
}

export const renderSectionPermissionsFormItem = (
  sectionName: string,
  sectionCode: string,
  accessLevels: { value; disabled? }[],
  onChange?,
  initialValue?
) => {
  return (
    <Row>
      <Col sm={10} xs={10}>
        <Text>{sectionName}</Text>
      </Col>
      <Col sm={14} xs={14}>
        <Form.Item name={['permissions', sectionCode]} initialValue={initialValue || accessLevels[0].value}>
          <Radio.Group className={styles.radioGroup} onChange={e => onChange(sectionCode, e?.target?.value)}>
            <Row>
              {['no', 'read', 'full'].map(code => {
                const item = accessLevels.find(a => a.value === code)
                if (!item) return null

                return (
                  <Col key={item.value} sm={8} xs={8}>
                    <Radio value={item.value} disabled={item.disabled} />
                  </Col>
                )
              })}
            </Row>
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>
  )
}
