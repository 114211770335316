import { Dropdown } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import React from 'react'
import { HeaderDropdownProps } from './props'
import styles from './HeaderDropdown.scss'

export const HeaderDropdown: React.FC<HeaderDropdownProps> = (props) => {
    const { children, overlayClassName, ...rest } = props

    return <Dropdown
        trigger={['click']}
        overlayClassName={classNames(styles.container, overlayClassName)}
        {...rest}
    >
        { children || <EllipsisOutlined /> }
    </Dropdown>
}