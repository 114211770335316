import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  contractNumber: string
  name: string
  status: string
  mid: string
  currentBalance: string
  currency: string
  contractOpenDate: string
  showMore: string
  showLess: string
  adjust: string
  adjustment: string
  adjustmentType: string
  amount: string
  sfCaseId: string
  search: string
  required: string
  midsAreNotEqual: string
  midsAreNotEqualInstruction: string
  noSFDataFound: string
  noSFDataFoundInstruction: string
  description: string
  submit: string
}>()
