import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    title: string,
    firstName: string
    surname: string
    firstNameRequired: string
    surnameRequired: string
    emailLabel: string
    emailRequired: string
    emailFormatIncorrect: string
    telephoneNumberLabel: string
    telephoneNumberRequired: string
    telephoneNumberWrongFormat: string
    acquisitionChannel: string
    acquisitionChannelRequired: string
    referrer: string
    referrerRequired: string
    referrerPlaceholder: string
    firstNameSubLabel: string
    emailSubLabel: string
    applicationSourceLabel: string
    applicationSourceRequired: string
    applicationAlreadyStarted: string
    continue: string
    salesforceOpportunityIDLabel: string
    salesforceOpportunityIDRequired: string
    salesforceOpportunityIDMin: string
}>()

