import React from 'react'
import { observer } from 'mobx-react'
import { Select, SelectProps, Spin, Typography } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { MerchantSelectStore } from '~/code/stores/MerchantsManagementStore/MerchantSelectStore'
import { minTextLengthForSearch } from './constants'
import styles from './MerchantSelect.scss'
import translations from './translations'

const { Title, Text } = Typography
const { Option } = Select

export const MerchantsManagementSelectStoreSymbol = Symbol('MerchantsManagementSelectStore')

export const MerchantSelect = observer((props: { disabled: boolean }) => {
  const store = useInjection<MerchantSelectStore>(MerchantsManagementSelectStoreSymbol)
  const { disabled } = props
  const onScroll = event => {
    const target = event.target
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight * 0.7) {
      store.handleScroll()
    }
  }

  const selectSpinner = (
    <Option value='loading'>
      <Spin />
    </Option>
  )

  const prepareOptions = () => {
    if (store.inputValue?.length < minTextLengthForSearch) {
      return []
    }
    const options: SelectProps['options'] = store.merchants?.map((m, idx) => ({
      value: m.id,
      label: (
        <>
          <Text className={styles.primary}>{m.tradeName}</Text>
          <Text className={styles.secondary} type='secondary'>
            {m.companyName}
          </Text>
          <Text className={styles.secondary} type='secondary'>
            {m.mid}
          </Text>
        </>
      ),
      prettiedLabel: m.tradeName,
      key: `${m.id}-${idx}`
    }))

    if (store.pagingMerchantLoadingStatus === 'loading') {
      options.push({
        value: 'loading',
        label: selectSpinner,
        disabled: true
      })
    }

    return options
  }

  return (
    <>
      <div>
        <Title level={5} className={styles.label}>
          {translations().merchant}
        </Title>
        <Text className={styles.subLabel}>{translations().merchantSubLabel}</Text>
      </div>
      <Select
        showSearch
        allowClear
        className={styles.select}
        filterOption={false}
        loading={store.merchantLoadingStatus === 'loading'}
        onSelect={v => store.setMerchant(String(v))}
        placeholder={translations().selectMerchant}
        value={store.selectedMerchant?.id || undefined}
        onPopupScroll={onScroll}
        onSearch={store.delayedQuery}
        optionLabelProp={'prettiedLabel'}
        onClear={store.handleClear}
        options={prepareOptions()}
        disabled={disabled}
      />
    </>
  )
})
