import { action, makeObservable, observable } from 'mobx'
import { injectable } from 'inversify'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { RealTimeTransactionDataStoreInterface } from './RealTimeTransactionDataStoreInterface'
import { RealTimeTransactionModel } from '~/code/stores/RealTimeTransactionStore/models'

export const RealTimeTransactionDataStoreSymbol = Symbol('RealTimeTransactionDataStoreSymbol')

@injectable()
export class RealTimeTransactionDataStore implements RealTimeTransactionDataStoreInterface {
  public data: RealTimeTransactionModel = null

  constructor() {
    makeObservable(this, {
      data: observable,

      updateData: action.bound
    })
  }

  public updateData = (newData: RealTimeTransactionModel) => {
    if (
      (hasPermissions([PermissionMap.transactions.dna_ecom_realtime.read]) && newData.source === 'ecom') ||
      (hasPermissions([PermissionMap.transactions.dna_pos_realtime.read]) && newData.source === 'pos')
    ) {
      this.data = newData
    }
  }
}
