import { inject, injectable } from 'inversify'
import { makeObservable, computed, observable, action } from 'mobx'
import { IRiskLimitsStore } from '~/code/pages/MerchantsManagement/components/RiskLimits/IRiskLimitsStore'
import { MerchantsManagementStoreSymbol } from '~/code/pages'
import { MerchantsManagementStore } from './MerchantsManagementStore'

@injectable()
export class RiskLimitsStore implements IRiskLimitsStore {
    merchantsManagementStore : MerchantsManagementStore
    isChangeRiskLimitLoading: boolean = false
    terminalIds: string[] = []
    allTerminalsSelected: boolean = false

    constructor(@inject(MerchantsManagementStoreSymbol) merchantsManagementStore : MerchantsManagementStore) {
        this.merchantsManagementStore = merchantsManagementStore
        makeObservable(this, {
            isChangeRiskLimitLoading: observable,
            terminalIds: observable,
            allTerminalsSelected: observable,

            selectedMerchant: computed,
            allTerminalIds: computed,
            isCompanyFullDossierV2Exists: computed,

            setTerminalIds: action,
            setAllTerminalsSelected: action
        })
    }

    get selectedMerchant() {
        return this.merchantsManagementStore.selectedMerchant
    }

    get isCompanyFullDossierV2Exists(): boolean {
        return this.merchantsManagementStore.isCompanyFullDossierV2Exists
    }

    get allTerminalIds() {
        const terminalsList = []
        if (this.merchantsManagementStore.companyFullDossierV2?.processing?.stores?.length > 0) {
            this.merchantsManagementStore.companyFullDossierV2?.processing?.stores?.forEach((store) => {
                store.contracts.forEach((contract) => {
                    const filteredTerminals = contract.terminals.filter((terminal) =>  terminal.terminal.terminalStatus === '1')
                    filteredTerminals.forEach((terminal) => {
                        terminalsList.push(terminal.terminal.terminalId)
                    })
                })
            })
        }
        return terminalsList || []
    }

    setAllTerminalsSelected() {
        this.terminalIds = this.allTerminalIds
    }

    setTerminalIds(terminalIds: string[]) {
        this.terminalIds = terminalIds
    }

}
