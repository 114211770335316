import React from 'react'
import translations from '../translations'
import { SettlementsStatusSwitch } from '../components/SettlementsStatusSwitch'

export const columnsSettlementsStatusTable = [
    {
        title: translations().contract,
        dataIndex: 'contract',
        key: 'contract',
    },
    {
        title: translations().mids,
        dataIndex: 'mids',
        key: 'mids',
    },
    {
        title: translations().status,
        dataIndex: 'status',
        key: 'status',
        render: (v, row) => {
            return (
                <SettlementsStatusSwitch contract={row.contract} settlemetValue={v}/>
            )
        }
    },
    {
        title: translations().team,
        dataIndex: 'team',
        key: 'team',
    },
    {
        title: translations().reason,
        dataIndex: 'reason',
        key: 'reason',
    },
    {
        title: translations().balance,
        dataIndex: 'balance',
        key: 'balance',
    },
    {
        title: translations().comment,
        dataIndex: 'comment',
        key: 'comment',
    }
]