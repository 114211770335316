import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { Col, Row, Typography, Space, Button } from 'antd'
import { useScreenType } from '~/code/hooks'
import { TwoFAStoreInterface } from '~/code/pages/Profile/components'
import {
  TwoFAModal,
  TwoFADisableModal,
  TwoFADevices,
  TwoFAEmailConfirmationModal,
  TwoFALabel
} from '~/code/pages/Profile/components/TwoFA/components'

import translations from './translations'
import styles from './styles.scss'

export const TwoFAStoreSymbol = Symbol('TwoFAStoreSymbol')

export const TwoFA = observer(() => {
  const { isMobile, isTablet } = useScreenType()

  const { isEnabled, isLoading, sendEmailOtp, setIsModalOpen, setIsDisableModalOpen, getTwoFAUserSettings } =
    useInjection<TwoFAStoreInterface>(TwoFAStoreSymbol)

    useEffect(() => {
      getTwoFAUserSettings()
    }, [])

  return (
    <>
      <Row gutter={[16, 16]} align={'middle'}>
        <Col xs={24} md={24} lg={12}>
          <Typography.Title level={4}>
            <span className={styles.title}>{translations().title}</span>
            <TwoFALabel isEnabled={isEnabled} isLoading={isLoading} />
          </Typography.Title>
          <Typography.Text>{translations().text}</Typography.Text>
        </Col>

        <Col xs={24} md={24} lg={8} offset={isMobile || isTablet ? 0 : 4}>
          <Space>
            <Button
              className={styles.button}
              loading={isLoading}
              onClick={() => (isEnabled ? setIsModalOpen(true) : sendEmailOtp())}
            >
              {isEnabled ? translations().configure : translations().enable}
            </Button>

            {isEnabled && (
              <Button className={styles.button} danger onClick={() => setIsDisableModalOpen(true)}>
                {translations().disable}
              </Button>
            )}
          </Space>
        </Col>
      </Row>

      <TwoFADevices className={styles.devices} />

      <TwoFAModal />
      <TwoFADisableModal />
      <TwoFAEmailConfirmationModal />
    </>
  )
})
