import { autorun } from 'mobx'
import { PeriodType } from '~/code/models'
import { getAcquisitionChannel } from '~/code/services/auth'
import { RangePickerStore } from '~/code/stores/RangePickerStore'
import { ProcessesFilterModel } from '../Processes/models'
import { ProcessesDataStore } from '../Processes/stores/ProcessesDataStore'

export class PartnerProcessesStore {
    isInitiated: boolean = false
    dateStore = new RangePickerStore()
    dataStore: ProcessesDataStore

    constructor() {
        this.dataStore = new ProcessesDataStore(this.dateStore)

        autorun(() => {
            if (this.dateStore.startDate && this.dateStore.endDate) {
                this.tableStore.loadData({ pageNumber: 1 })
            }
        })
    }

    init() {
        if (!this.isInitiated) {
            this.dateStore.setPeriod(PeriodType.Yesterday)
        } else {
            this.tableStore.loadData({ pageNumber: 1 })
        }

        this.isInitiated = true
    }

    get tableStore() {
        return this.dataStore.tableStore
    }

    get filter() {
        return this.dataStore.filter
    }

    setFilter(filter: Partial<ProcessesFilterModel>, shouldLoad: boolean = true) {
        this.dataStore.setFilter(filter, shouldLoad)
    }

    get acquisitionChannel() {
        return this.dataStore.filter.acquisitionChannel
    }

    set acquisitionChannel(value: string) {
        this.dataStore.filter.acquisitionChannel = value
    }
}
