import { ColumnsType } from 'antd/lib/table'
import { TerminalIssueReportType } from '../models'
import translations from '../translations'
import { render } from './totalSummaryColumns'

export const terminalIssueReportColumns: ColumnsType<TerminalIssueReportType> = [
    {
        dataIndex: 'segmentCategory',
        title: translations().columnTitles.segmentCategory,
        render
    },
    {
        dataIndex: 'segmentSubCategory',
        title: translations().columnTitles.segmentSubCategory,
        render
    },
    {
        dataIndex: 'acquisitionChannelName',
        title: translations().columnTitles.acquisitionChannelName,
        render
    },
    {
        dataIndex: 'productType',
        title: translations().columnTitles.productType
    },
    {
        dataIndex: 'approvalType',
        title: translations().columnTitles.approvalType
    },
    {
        dataIndex: 'terminalQuantity',
        title: translations().columnTitles.terminalQuantity,
        render
    },
    {
        dataIndex: 'merchantQuantity',
        title: translations().columnTitles.merchantQuantity,
        render
    }
]
