import translations from '../../../translations'
import { AdditionalDocumentItem, DocumentItem } from '../models/models'

export const mandatoryDocuments: DocumentItem[] = [
    {
        key: 'bankStatement',
        title: translations().bankStatement
    }
]

export const availableAdditionalDocuments: AdditionalDocumentItem[] = [
    {
        value: 'idDrivingLicence',
        label: translations().idDrivingLicence
    },
    {
        value: 'idPassport',
        label: translations().idPassport
    },
    {
        value: 'idOther',
        label: translations().idOther
    },
    {
        value: 'poaPersonalBankStatement',
        label: translations().poaPersonalBankStatement
    },
    {
        value: 'poaCouncilTax',
        label: translations().poaCouncilTax
    },
    {
        value: 'poaUtilityBill',
        label: translations().poaUtilityBill
    },
    {
        value: 'poaOther',
        label: translations().poaOther
    },
    {
        value: 'pobBankStatementBusiness',
        label: translations().pobBankStatementBusiness
    },
    {
        value: 'pobChequeBook',
        label: translations().pobChequeBook
    },
    {
        value: 'pobBankLetter',
        label: translations().pobBankLetter
    },
    {
        value: 'pobOther',
        label: translations().pobOther
    },
    {
        value: 'kybLeaseAgreement',
        label: translations().kybLeaseAgreement
    },
    {
        value: 'kybAlcoholLicense',
        label: translations().kybAlcoholLicense
    },
    {
        value: 'kybCertificateOfAuthenticity',
        label: translations().kybCertificateOfAuthenticity
    },
    {
        value: 'domainOwnership',
        label: translations().domainOwnership
    },
    {
        value: 'licences',
        label: translations().licences
    },
    {
        value: 'processingStatement',
        label: translations().processingStatement
    },
    {
        value: 'premisesPhotoOutside',
        label: translations().premisesPhotoOutside
    },
    {
        value: 'premisesPhotoInside',
        label: translations().premisesPhotoInside
    },
    {
        value: 'additionalTidsRequestForm',
        label: translations().additionalTidsRequestForm
    },
    {
        value: 'signedMsa',
        label: translations().signedMsa
    },
    {
        value: 'other',
        label: translations().other
    }
]
