import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    contractNumber: string
    merchantId: string
    routeToMasterCard: string
    routeToVisa: string
    terminalId: string
    change: string
    required: string
    selectTransactionChannel: string
    product: string
    dna: string
    paynetics: string
    getRouteLabel: (value: string) => string
}>()
