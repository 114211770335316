import translations from './translations'

translations.add('en', {
  applicantInfo: 'Applicant Info',
  companyProfile: 'Company Profile',
  stores: 'Stores List',
  tarriffs: 'Tarriffs',
  businessOwners: 'Business Owners',
  companyDirectors: 'Company Directors',
  individualShareholders: 'Individual Shareholders',
  corporateShareholders: 'Corporate Shareholders',
  companyOwners: 'Company Owners',
  view: 'View',
  json: 'JSON',
  copied: 'Copied',
  company: 'Company',
  companyAuthorisedSignatories: 'Company Authorised Signatories',
  terminalOrders: 'Terminal Orders'
})
