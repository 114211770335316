import translations from './translations'

translations.add('en', {
  enforceTitle: 'Enforce two-factor authentication for all sign-ins',
  enforced: 'Enforced',
  enabled: 'Enabled',
  disabled: 'Disabled',
  cancel: 'Cancel',
  submit: 'Save',
  formFields: {
    email: 'Email',
    firstName: 'First name',
    lastName: 'Last name'
  },
  security: 'Security',
  twoFA: 'Two-Factor Authentication',
  reasonPlaceholder: 'Enter reason',
  formErrors: {
    reasonRequired: 'reason is required'
  },
  disableWarning: 'Disabling two-factor authentication reduces the security of your account'
})