import translations from './translations'
import React from 'react'

translations.add('en', {
    headlineRate: 'Headline rate (%)',
    transactionType: 'Transaction Type (Merchant Services Charges (per Transaction) *)',
    backText: 'Back to Product & Company',
    blended: 'Blended',
    icPlus: 'IC++',
    cardScheme: 'Card Scheme',
    serviceArea: 'Service Area',
    corporateOrConsumer: 'Corporate or Consumer',
    cardType: 'Card Type',
    baseFee: 'Base fee (p.)',
    percentFee: 'Percent fee (%)',
    skip: 'Skip',
    warningTitle: 'Please be cautious whilst filling out the fees',
    ensureMSA: 'Ensure each rate is filled according to the MSA.',
    wrongFee: 'The wrong fee will cause incorrect charges.',
    doubleCheck: 'It is critical to double-check each field before proceeding forward.'
})
