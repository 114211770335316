import React, { useState } from 'react'
import { Button, List, Modal, Space, Tooltip, Typography } from 'antd'

import { CheckGreenOutlined, DeleteRedOutlined, EditOutlinedBlue } from '~/assets/icons'
import { isCorporate } from '~/code/pages/StartProcess/services'
import translations from './translations'
import { ListItemProps } from './props'
import styles from './ListItem.scss'
import { ProductsTable } from '../ProductsTable'
import { ProductItem } from '../../models'

const { Link } = Typography
const { confirm } = Modal

export const ListItem = (props: ListItemProps) => {
  const {
    isCompleted,
    isRemoveDisabled,
    onClick,
    onRemove,
    address,
    account,
    products,
    isEditDisabled = false,
    agreementSignatory,
    accountVerificationStatus,
    manuallyDocs,
    officerVerificationStatus
  } = props

  const [showModal, setShowModal] = useState(false)
  const [choosenProducts, setChoosenProducts] = useState<ProductItem[]>([])

  const showProdModal = (prds: ProductItem[]) => {
    setChoosenProducts(prds)
    setShowModal(true)
  }

  const deleteTitle = isCorporate(props) ? props.corporateName : `${props.name || ''}`

  const renderName = () => {
    const { name, surname } = props
    const alias = (name ? name.charAt(0) : '') + (surname ? surname.charAt(0) : '')
    return (
      <>
        <div className={styles.alias}>{alias}</div>
        <div className={styles.name}>
          {name} {surname}
        </div>
      </>
    )
  }

  const onDeleteClicked = () => {
    confirm({
      title: translations().deleteFormTitle(deleteTitle),
      content: translations().deleteFormContent,
      async onOk() {
        onRemove()
      },
      onCancel() {
        return
      }
    })
  }

  return (
    <>
      <List.Item
        actions={[
          <Tooltip>
            {isCompleted && (
              <div className={styles.checkedIcon}>
                <CheckGreenOutlined />
              </div>
            )}
          </Tooltip>,
          <Tooltip title={translations().delete}>
            {onRemove && (
              <Button
                type='text'
                icon={<DeleteRedOutlined />}
                disabled={isRemoveDisabled}
                onClick={e => {
                  e.stopPropagation()
                  onDeleteClicked()
                }}
              />
            )}
          </Tooltip>,
          <Tooltip title={translations().edit}>
            <Button type='text' icon={<EditOutlinedBlue />} disabled={isEditDisabled} onClick={onClick} />
          </Tooltip>
        ]}
      >
        <div className={styles.contentContainer}>{renderName()}</div>
        {agreementSignatory && <b>{translations().agreementSignatory}</b>}
      </List.Item>

      {officerVerificationStatus && (
        <div className={styles.container}>
          {!manuallyDocs && (
            <div
              className={
                ['VERIFIED', 'MATCH'].includes(officerVerificationStatus?.toLocaleUpperCase())
                  ? styles.accountVerified
                  : styles.accountNotVerified
              }
            >
              {officerVerificationStatus}
            </div>
          )}
          {manuallyDocs && <div className={styles.accountVerified}>{translations().manuallyDocs}</div>}
        </div>
      )}

      {address?.addressLine1 && (
        <div className={styles.container}>{`${address.postalCode}, ${address.addressLine1}`}</div>
      )}

      {account?.sortCode && (
        <div className={styles.container}>
          {`${account.sortCode}, ${account.bankAccountNumber}`}
          {manuallyDocs && <div className={styles.accountVerified}>{translations().manuallyDocs}</div>}
          {accountVerificationStatus && !manuallyDocs && (
            <div
              className={
                ['VERIFIED', 'MATCH'].includes(accountVerificationStatus)
                  ? styles.accountVerified
                  : styles.accountNotVerified
              }
            >
              {accountVerificationStatus}
            </div>
          )}
        </div>
      )}

      {products?.length > 0 && (
        <div className={styles.container}>
          <Space size='middle'>
            <Link onClick={() => showProdModal(products)}>{translations().productsModal}</Link>
          </Space>
        </div>
      )}

      <Modal
        title={translations().productsModal}
        open={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
      >
        <ProductsTable products={choosenProducts} />
      </Modal>
    </>
  )
}
