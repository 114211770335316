import React from 'react'
import { observer } from 'mobx-react'
import { Divider, Form, Input, Modal } from 'antd'
const { TextArea } = Input
import { isEmpty } from 'dna-common'
import { useInjection } from 'dna-react-ioc'
import {
    ONLY_DIGITS_PATTERN,
    START_ZERO
} from '~/code/models/Patterns'
import {
    MAX_COMMENT_LENGTH,
    MAX_DAILY_AMOUNT_LENGTH,
    MAX_DAILY_COUNT_LENGTH, MAX_DAILY_MAX_REFUND_AMOUNT_LENGTH,
    MAX_MONTHLY_AMOUNT_LENGTH,
    MAX_MONTHLY_COUNT_LENGTH,
    MAX_PER_REFUND_LENGTH,
    MAX_PER_TRANSACTION_LENGTH,
    MIN_COMMENT_LENGTH,
    MIN_DAILY_AMOUNT,
    MIN_DAILY_COUNT, MIN_DAILY_MAX_REFUND_AMOUNT,
    MIN_MONTHLY_AMOUNT,
    MIN_MONTHLY_COUNT,
    MIN_PER_REFUND, MIN_PER_TRANSACTION,
    MIN_PER_DEVICE, MAX_PER_DEVICE_LENGTH
} from './models'
import { ChangeRiskLimitsStore } from '~/code/stores/MerchantsManagementStore/ChangeRiskLimitsStore'
import translations from './translations'
import { ChangeRiskLimitsProps } from './props'
import styles from './ChangeRiskLimits.scss'
import classNames from 'classnames'

export const ChangeRiskLimitsStoreSymbol = Symbol('ChangeRiskLimitsStore')
export const ChangeRiskLimits = observer((props: ChangeRiskLimitsProps) => {
    const store = useInjection<ChangeRiskLimitsStore>(ChangeRiskLimitsStoreSymbol)
    const { form, onFinish, title, okButtonText, okButtonProps = {}, isShowLimits = true, isFormLayoutVertical = false, riskLimits } = props

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name)
    }

    const allowOnlyNumber = (fieldName, value, max) => {
        if ((!ONLY_DIGITS_PATTERN.test(value) && !isEmpty(value)) || (value && value.length > max)) {
            return form.getFieldValue(fieldName)
        } else if (START_ZERO.test(value)) {
            return parseInt(value, 10)
        }
        return Number(value)
    }

    const renderLimitFields = () => {
        return (
            <>
            {riskLimits?.hasOwnProperty('dailyAmount') &&
                <Form.Item name='dailyAmount'  label={ translations().fields.dailyAmount }
                           rules={
                    [
                        {
                            type: 'number',
                            min: MIN_DAILY_AMOUNT,
                            message: translations().errors.dailyAmount(MIN_DAILY_AMOUNT)
                        }
                    ]
                }
                        getValueFromEvent={ (e: React.FormEvent<HTMLInputElement>) => allowOnlyNumber('dailyAmount', e.currentTarget.value, MAX_DAILY_AMOUNT_LENGTH) }
                >
                    <Input disabled={ store.isLoading } />
                </Form.Item>
            }
            {riskLimits?.hasOwnProperty('dailyCount') &&
                <Form.Item name='dailyCount' label={ translations().fields.dailyCount } rules={
                    [
                        {
                            type: 'number',
                            min: MIN_DAILY_COUNT,
                            message: translations().errors.dailyCount(MIN_DAILY_COUNT)
                        }
                    ]
                }
                           getValueFromEvent={ (e: React.FormEvent<HTMLInputElement>) => allowOnlyNumber('dailyCount', e.currentTarget.value, MAX_DAILY_COUNT_LENGTH) }
                >
                    <Input disabled={ store.isLoading } />
                </Form.Item>
            }
            {riskLimits?.hasOwnProperty('dailyMaxRefundAmount') &&
                <Form.Item name='dailyMaxRefundAmount' label={ translations().fields.dailyMaxRefundAmount } rules={
                    [
                        {
                            type: 'number',
                            min: MIN_DAILY_MAX_REFUND_AMOUNT,
                            message: translations().errors.dailyMaxRefundAmount(MIN_DAILY_MAX_REFUND_AMOUNT)
                        }
                    ]
                }
                           getValueFromEvent={ (e: React.FormEvent<HTMLInputElement>) => allowOnlyNumber('dailyMaxRefundAmount', e.currentTarget.value, MAX_DAILY_MAX_REFUND_AMOUNT_LENGTH) }
                >
                    <Input disabled={ store.isLoading } />
                </Form.Item>
            }
            {riskLimits?.hasOwnProperty('monthlyAmount') &&
                <Form.Item name='monthlyAmount' label={ translations().fields.monthlyAmount } rules={
                    [
                        {
                            type: 'number',
                            min: MIN_MONTHLY_AMOUNT,
                            message: translations().errors.monthlyAmount(MIN_MONTHLY_AMOUNT)
                        }
                    ]
                }
                           getValueFromEvent={ (e: React.FormEvent<HTMLInputElement>) => allowOnlyNumber('monthlyAmount', e.currentTarget.value, MAX_MONTHLY_AMOUNT_LENGTH) }
                >
                    <Input disabled={ store.isLoading } />
                </Form.Item>
            }
            {riskLimits?.hasOwnProperty('monthlyCount') &&
                <Form.Item name='monthlyCount' label={ translations().fields.monthlyCount } rules={
                    [
                        {
                            type: 'number',
                            min: MIN_MONTHLY_COUNT,
                            message: translations().errors.monthlyCount(MIN_MONTHLY_COUNT)
                        }
                    ]
                }
                           getValueFromEvent={ (e: React.FormEvent<HTMLInputElement>) => allowOnlyNumber('monthlyCount', e.currentTarget.value, MAX_MONTHLY_COUNT_LENGTH) }
                >
                    <Input disabled={ store.isLoading } />
                </Form.Item>
            }
            {riskLimits?.hasOwnProperty('perRefund') &&
                <Form.Item name='perRefund' label={ translations().fields.perRefund } rules={
                    [
                        {
                            type: 'number',
                            min: MIN_PER_REFUND,
                            message: translations().errors.perRefund(MIN_PER_REFUND)
                        }
                    ]
                }
                           getValueFromEvent={ (e: React.FormEvent<HTMLInputElement>) => allowOnlyNumber('perRefund', e.currentTarget.value, MAX_PER_REFUND_LENGTH) }
                >
                    <Input disabled={ store.isLoading } />
                </Form.Item>
            }
            {riskLimits?.hasOwnProperty('perTransaction') &&
                <Form.Item name='perTransaction' label={ translations().fields.perTransaction } rules={
                    [
                        {
                            type: 'number',
                            min: MIN_PER_TRANSACTION,
                            message: translations().errors.perTransaction(MIN_PER_TRANSACTION)
                        }
                    ]
                }
                           getValueFromEvent={ (e: React.FormEvent<HTMLInputElement>) => allowOnlyNumber('perTransaction', e.currentTarget.value, MAX_PER_TRANSACTION_LENGTH) }
                >
                    <Input disabled={ store.isLoading } />
                </Form.Item>
            }
            {riskLimits?.hasOwnProperty('perDevice') &&
                <Form.Item name='perDevice' label={ translations().fields.perDevice } rules={
                    [
                        {
                            type: 'number',
                            min: MIN_PER_DEVICE,
                            message: translations().errors.perDevice(MIN_PER_DEVICE)
                        }
                    ]
                }
                           getValueFromEvent={ (e: React.FormEvent<HTMLInputElement>) => allowOnlyNumber('perDevice', e.currentTarget.value, MAX_PER_DEVICE_LENGTH) }
                >
                    <Input disabled={ store.isLoading } />
                </Form.Item>
            }
                <Divider />
            </>
        )
    }

    return (
        <Modal
            className={ classNames(styles.ChangeRiskLimits, { [styles.ChangeRiskLimits__formItemFullWidth]: isFormLayoutVertical }) }
            title={ title }
            open={ store.isShowModal }
            confirmLoading={ store.isLoading }
            okText={ okButtonText }
            okButtonProps={ okButtonProps }
            onOk={ () => form.submit() }
            onCancel={ () => {
                store.closeModal()
                form.resetFields()
            } }
        >
            <Form
                colon={ false }
                layout={isFormLayoutVertical ? 'vertical' : 'horizontal'}
                labelCol={{span: 10}}
                form={ form }
                onFinish={ onFinish }
                onFinishFailed={ onFinishFailed }
                initialValues={ store.initialValues }
                onValuesChange={ (e) => store.onChangeCurrentValues(form.getFieldsValue()) }
            >
                {
                    isShowLimits && renderLimitFields()
                }
                <Form.Item
                    name='comment'
                    label={ translations().fields.comment }
                    rules={[
                        {
                            min: MIN_COMMENT_LENGTH,
                            message: translations().errors.comment(MIN_COMMENT_LENGTH)
                        }
                    ]}
                >
                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount maxLength={ MAX_COMMENT_LENGTH } minLength={ MIN_COMMENT_LENGTH }/>
                </Form.Item>
            </Form>
        </Modal>
    )
})
