import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Grid } from 'antd'
import { useMap } from 'react-leaflet'
import { MAP_CENTER, MAP_MOBILE_CENTER, MAP_DEFAULT_ZOOM } from '../../constants'

const { useBreakpoint } = Grid

export const RealTimeTransactionMapWrapper = observer(() => {
  const map = useMap()
  const screens = useBreakpoint()
  const isMobile = screens.xs || !screens.md

  useEffect(() => {
    map.setView(isMobile ? MAP_MOBILE_CENTER : MAP_CENTER, MAP_DEFAULT_ZOOM)
  }, [isMobile])

  return null
})