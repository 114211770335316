import React, { useEffect, useState } from 'react'
import { Col, Radio, Row, Skeleton } from 'antd'
import classNames from 'classnames'
import moment from 'moment'
import numeral from 'numeral'
import { SumCountCard } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/chart-related'
import DatePicker from '~/code/components/DatePicker'
import { BarChart } from '~/code/components'
import { yearsComparisonStatusTabs } from './constants/status-tabs'
import { YearsComparisonProps } from './props'
import translations from './translations'
import styles from './YearsComparison.scss'


export const YearsComparison: React.FC<YearsComparisonProps> = props => {
  const { title, data, loading, currencySymbol, merchant, year1, year2, onYearsChange } = props
  const [isLoading, setLoading] = useState(loading)
  const [firstYear, setFirstYear] = useState(year1)
  const [secondYear, setSecondYear] = useState(year2)

  const onFirstYearSelect = (value: moment.Moment) => {
    let secondYearValue = secondYear
    if (value.get('year') >= secondYear.get('year')) {
      secondYearValue = value.clone().add(1, 'years')
    }
    setFirstYear(value)
    setSecondYear(secondYearValue)

    onYearsChange && onYearsChange(value, secondYearValue)
  }

  const onSecondYearSelect = (value: moment.Moment) => {
    setSecondYear(value)
    onYearsChange && onYearsChange(firstYear, value)
  }

  useEffect(() => {
    setLoading(loading)
  }, [data, loading])

  const renderSubTitle = (dataType, status) => {
    if (dataType === 'sum') {
      return translations().sumTitle(merchant, status, currencySymbol)
    }

    return translations().countTitle(merchant, status, currencySymbol)
  }

  const renderHeader = (dataType, status, handleStatusChange) => {
    return (
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} lg={8}>
          <div className={styles.subTitle}>{renderSubTitle(dataType, status)}</div>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <div className={styles.yearsPickerWrapper}>
            <DatePicker
              picker={'year'}
              placement={'bottomRight'}
              disabledDate={(date: moment.Moment) => date.get('year') < 2019}
              value={firstYear}
              onChange={onFirstYearSelect}
            />
            <span className={styles.vs}>vs</span>
            <DatePicker
              picker={'year'}
              placement={'bottomRight'}
              disabledDate={(date: moment.Moment) => date.get('year') <= firstYear.get('year')}
              value={secondYear}
              onChange={onSecondYearSelect}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <div className={styles.statusWrapper}>
            <Radio.Group value={status} onChange={handleStatusChange}>
              <Radio.Button value='all'>{translations().all}</Radio.Button>
              <Radio.Button value='successful'>{translations().successful}</Radio.Button>
              <Radio.Button value='failed'>{translations().failed}</Radio.Button>
            </Radio.Group>
          </div>
        </Col>
      </Row>
    )
  }

  const renderSumHeader = (status, handleStatusChange) => {
    return renderHeader('sum', status, handleStatusChange)
  }

  const renderCountHeader = (status, handleStatusChange) => {
    return renderHeader('count', status, handleStatusChange)
  }

  const renderContent = (dataType, status) => {
    if (isLoading) {
      return <Skeleton active />
    }
    return (
      <div>
        <BarChart
          data={data?.[dataType]?.[status]}
          yAxisNumberFormat={dataType === 'count' ? '0,00' : '0.00a'}
          renderTooltipLabel={(label, value) => {
            const currency = dataType === 'count' ? '' : currencySymbol || '£'
            const format = dataType === 'count' ? '0,00' : '0,0.00'
            return label + ': ' + currency + numeral(value).format(format)
          }}
        />
      </div>
    )
  }

  const renderSumContent = status => {
    return renderContent('amount', status)
  }

  const renderCountContent = status => {
    return renderContent('count', status)
  }

  return (
    <SumCountCard
      loading={loading}
      title={
        <div>
          {title || translations().yearsComparison}{' '}
          <span className={styles.titleSecondary}>{translations().asOfYesterday}</span>
        </div>
      }
      renderSumHeader={renderSumHeader}
      renderCountHeader={renderCountHeader}
      renderSumContent={renderSumContent}
      renderCountContent={renderCountContent}
      className={classNames(styles.card, !isLoading && styles.loading)}
      statusTabs={yearsComparisonStatusTabs}
      currencySymbol={currencySymbol}
    />
  )
}
