import React from 'react'
import { Badge } from 'antd'
import { getWithAuth, putWithAuth, postWithAuth } from '~/code/services'
import { BpmFilterStatusModel } from '~/code/models/BpmFilterStatusModel'
import { SubmittedRequestsStatus } from '~/code/stores/SubmittedRequestsStore/models'
import {
  GetSubmittedApplicationsQueryParams,
  CompleteRiskReviewParams,
  GetSubmittedApplicationsResponse,
  ProcessDetailsModel,
  GetChangeBankDetailsApplicationsResponseType,
  GetTerminalIssueApplicationsResponseType,
  GetEditTariffsApplicationsResponseType,
  CancelMerchantClosureResponseType,
  CancelMerchantClosureType,
  MerchantClosureApplicationsResponseType
} from '../models'

export const getProcessStatuses = (processName: string) =>
  getWithAuth<BpmFilterStatusModel[]>('/api/bpm/processes/statuses', { processName })

export const getSubmittedApplications = (params: GetSubmittedApplicationsQueryParams) =>
  getWithAuth<GetSubmittedApplicationsResponse>('/bpm/adw/report/dashboard-processes-info', params)

export const getSubmittedApplicationsClosureOfMerchant = (params: GetSubmittedApplicationsQueryParams) =>
  getWithAuth<MerchantClosureApplicationsResponseType>('/bpm/adw/report/dashboard-processes-info', params)

export const getSubmittedApplicationsChangeBankDetails = (params: GetSubmittedApplicationsQueryParams) =>
  getWithAuth<GetChangeBankDetailsApplicationsResponseType>('/bpm/adw/report/dashboard-processes-info', params)

export const getSubmittedApplicationsTerminalIssue = (params: GetSubmittedApplicationsQueryParams) =>
  getWithAuth<GetTerminalIssueApplicationsResponseType>('/bpm/adw/report/dashboard-additional-terminals', params)

export const getSubmittedApplicationsEditTariffs = (params: GetSubmittedApplicationsQueryParams) =>
  getWithAuth<GetEditTariffsApplicationsResponseType>('/bpm/adw/report/dashboard-processes-info', params)

export const getProcessDetails = (processId: string) =>
  getWithAuth<ProcessDetailsModel>(`/api/bpm/details/processes/${processId}/risk-limits`)

export const completeRiskReview = (processId: string, params: CompleteRiskReviewParams) =>
  putWithAuth(`/api/bpm/processes/${processId}/risk-limits`, params)

export const cancelMerchantClosure = (params: CancelMerchantClosureType) =>
  postWithAuth<CancelMerchantClosureResponseType>('/bpm/engine/process-definition/key/rejectclosure/start', params)

export const renderStatus = (status: SubmittedRequestsStatus) => {
  switch (status) {
    case SubmittedRequestsStatus.APPROVED:
      return <Badge status={'success'} text={status} />
    case SubmittedRequestsStatus.REJECTED:
      return <Badge status={'error'} text={status} />
    default:
      return <Badge text={status} color={'yellow'} />
  }
}
