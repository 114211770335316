import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    day: string
    yesterday: string
    month: string
    week: string
    year: string
    last7Days: string
    last30Days: string
    last60Days: string
    last90Days: string
    last12Months: string
    custom: string
}>()

