import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    amount: string
    chargebackDate: string
    bankingDate: string,
    date: string
    cardMask: string
    currency: string
    captureMethod: string
    documentId: string
    id: string
    key: string
    merchantName: string
    terminalId: string
    transactionDate: string
    transactionType: string
    transactionId: string
    createdDate: string
    status: string
    previousDocumentId: string
    operation: string
    reasonCode: string
    arn: string
    cardScheme: string
    visa: string
    mastercard: string
    viewDetails: string
}>()

