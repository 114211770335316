import translations from './translations'

translations.add('en', {
  all: 'ALL',
  home: 'Home',
  dashboard: 'Dashboard',
  loginPageTitle: 'Login',
  resetPasswordPageTitle: 'Reset Password',
  resetPasswordFailurePageTitle: 'Reset Password Failure',
  confirmCodePageTitle: 'Confirm Your Email',
  setNewPasswordPageTitle: 'Set New Password',
  dnaAcquiringAnalyticsPageTitle: 'DNA Acquiring ECOM Analytics',
  dnaAcquiringAnalyticsPosPageTitle: 'DNA Acquiring POS Analytics',
  dnaAcquiringStatementPageTitle: 'DNA Acquiring ECOM Monitoring',
  dnaAcquiringMonitoringPosPageTitle: 'DNA Acquiring POS Monitoring',
  transactionChannelsGeneralSettingsPageTitle: 'Transaction channels - Global settings',
  transactionChannelsMerchantSettingsPageTitle: 'Transaction channels - Merchant settings',
  optomanyCheckoutV3AnalyticsPageTitle: 'Optomany Checkout V3 ECOM Analytics',
  optomanyCheckoutV3StatementPageTitle: 'Optomany Checkout V3 ECOM Monitoring',
  onboardingPageTitle: 'Onboarding',
  onboardingStatisticsPageTitle: 'Onboarding Statistics',
  settlementsStatementPageTitle: 'Settlements Statement',
  settlementsPaymentsPageTitle: 'Settlements Payments',
  portalAccessPageTitle: 'Merchant Portal Access',
  partnerAccessPageTitle: 'Partner Dashboard Access',
  twoFA: 'Two-factor authentication',
  enforceTwoFA: 'Two-factor authentication has been enforced',
  recoveryCode: 'Recovery code',
  chargebacksPageTitle: 'Chargebacks',
  teammatesPageTitle: 'Team Management',
  exportsPageTitle: 'Exports',
  onboardingProcessesPageTitle: 'Onboarding Processes',
  onboardingProcessPageTitle: 'Onboarding Process',
  onboardingProcessesAnalytics: 'Onboarding Analytics',
  optomanyCheckoutV3PageTitle: 'Optomany Checkout V3',
  merchantsManagementProfile: 'Merchants',
  invoicesPageTitle: 'Invoices',
  netSuiteInvoicesPageTitle: 'NetSuite Invoices',
  riskLimitsPageTitle: 'Change of Risk Limits',
  motoVtRequestPageTitle: 'MOTO/VT Request',
  changeBankDetailsPageTitle: 'Change of Bank Details',
  closureOfMerchantPageTitle: 'Closure of Merchant',
  closureOfTerminalPageTitle: 'Closure of Terminal',
  changeofSettlementPeriodTitle: 'Change of Settlement Period Requests',
  terminalIssueTitle: 'Terminal Issue Requests',
  notFoundPageTitle: 'Not Found',
  noPermissionPageTitle: 'No Permission',
  unknown: 'Unknown',
  permissionErrorText: 'You do not have permission to log in',
  nonUK: 'Non-UK',
  defaultErrorMessage: 'Something went wrong. Please try again later',
  changeOfSettlementType: 'Change of Settlement Type',
  changeOfSettlementStatus: 'Change of Settlement Status',
  adjustmentRequestsTitle: 'Adjustment Requests',
  editTariffsRequestsTitle: 'Change of fees requests',
  partnerCommissionsTitle: 'Partner Commissions',

  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December'
  },

  transactionChannels: {
    BAMBORA: 'Bambora',
    ECOSPEND: 'Open Banking',
    EGATEWAY: 'DNA eComm',
    OPTOMANY: 'Optomany',
    PAYBYBANKAPP: 'Pay by Bank app',
    PAYNETICS: 'Paynetics',
    PAYPAL_DNA: 'Paypal DNA',
    PAYPAL_OPTOMANY: 'Paypal Optomany',
    WIRECARD: 'WireCard',
    KLARNA: 'Klarna',
    ASTROPAY: 'AstroPay'
  },

  posTransactionChannels: {
    visa: 'VISA',
    mastercard: 'MasterCard',
    p_h2h: 'Paynetics H2H',
    upi: 'UnionPay',
    openbanking: 'Open Banking',
    alipay: 'Alipay',
    americanexpress: 'American Express'
  },

  paymentMethods: {
    card: 'Card',
    paypal: 'PayPal',
    applepay: 'Apple Pay',
    googlepay: 'Google Pay',
    paybybankapp: 'Pay by Bank app',
    ecospend: 'Open Banking',
    klarna: 'Klarna',
    astropay: 'AstroPay',
    clicktopay: 'Click to Pay',
    alipay: 'AliPay',
    wechatpay: 'WeChat Pay'
  },

  messages: {
    successfullSetPassword: 'Password has been changed successfully!'
  },

  errors: {
    loadData: 'Error while loading data',
    saveData: 'Error while saving data'
  },

  back: 'Back',
  next: 'Next',
  datePlaceholder: 'dd / mm / yyyy',
  total: 'Total',
  average: 'Average',

  processingTypes: {
    mailOrder: 'Mail Order',
    telephoneOrder: 'Telephone Order',
    initialRecurring: 'Initial Recurring',
    recurringMit: 'Recurring MIT',
    initialUcof: 'Initial UCOF',
    ucofMit: 'UCOF MIT',
    initialCof: 'Initial COF',
    cofCit: 'COF CIT',
    mailOrderInitialCof: 'Mail Order - Initial COF',
    telephoneOrderInitialCof: 'Telephone Order- Initial COF',
    motoRecurringMit: 'MOTO - Recurring MIT'
  },

  cardTypes: {
    visa: 'VISA',
    mastercard: 'MasterCard',
    amex: 'AmericanExpress',
    upi: 'UnionPay',
    diners: 'DinersClub',
    other: 'Other'
  },

  signifydDecisions: {
    accept: 'ACCEPT',
    reject: 'REJECT',
    hold: 'HOLD'
  },

  notifications: {
    manageTemplates: 'Manage Templates',
    manageNotifications: 'Manage Notifications',
    publishNotifications: 'Publish Notification'
  }
})
