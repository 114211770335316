import { ReactNode } from 'react'
import { string } from 'yup'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  checkTheData: string
  pleaseCheckBeforeSubmit: string
  backToForm: string
  submit: string
  applicationHasFollowingErrors: string
  generalInformationNotCompleted: string
  shareholdersNotCompleted: string
  directorsNotCompleted: string
  authorizedSignatoriesNotCompleted: string
  businessOwnerNotCompleted: string
  supportingDocumentsNotCompleted: string
  submissionFailed: string
  applicationSubmittedSuccessfully: string
  applicationID: string
  inOrderToTrackApplication: string
  newApplication: string
  onceYouClickSubmit: string
  pleaseWait2Mins: string
  warning: string
  reviewAndConfirm: string
  carefullyReview: string
  companyInfo: string
  companyDetails: (companyName: string, companyEmail: string, merchantEmail: string) => ReactNode
  bankInfo: string
  bankDetails: (bankName: string, accNum: string, sortCode: string) => ReactNode
  individualInfo: string
  individualDetails: (name: string, bd: string, address: string) => ReactNode
  storesInfo: string
  storesDetails: (
    name: string,
    address: string,
    settlementAcc: string,
    directDebitAcc: string,
    products: string
  ) => ReactNode
  financialInfo: string
  financialDetails: (name: string, annual: string, average: string, highest: string) => ReactNode
  pricingInfo: string
  pricingDetails: (prodName: string, tariffType: string) => ReactNode
  docuSignInfo: string
  docuSignDetails: (status: boolean) => ReactNode
  documentsInfo: string
  documentsDetails: (status: boolean) => ReactNode
  submitted: string
  submittedForReview: string
  statusWillBeUpdated: string
  closeTheWindow: string
  salesForceLink: string
  dashboardReportsLink: string
  closeAndNew: string
  close: string
  currentStatus: (status: string) => string
}>()
