import { isEmpty } from 'dna-common'
import moment from 'moment-timezone'
import { SelectItem } from '~/code/models'
import { BirthDateModel, CompanyResponseItem, PaginationResponse } from 'startapp/models'

interface FieldData {
    name: string[]
    value?: any
    touched?: boolean
    validating?: boolean
    errors?: string[]
}

export const getFieldsFromValues = (values: any) => {
    const fields: FieldData[] = []

    Object.entries(values).forEach(([name, value]) => {
        if (isEmpty(value)) return

        if (typeof value === 'object' && !Array.isArray(value)) {
            return Object.entries(value).forEach(([subName, subValue]) => {
                if (subValue) {
                    fields.push({ name: [name, subName], touched: true })
                }
            })
        }

        fields.push({ name: [name], touched: true })
    })

    return fields
}

export function convertResponseToCompanies(response: PaginationResponse<CompanyResponseItem>): SelectItem[] {
    if (response && response.items && Array.isArray(response.items)) {
        return response.items.map((item: CompanyResponseItem) => {
            return {
                label: item.title,
                value: item.companyNumber,
                params: item
            }
        })
    }
    return []
}

export function convertDateObjectToString(date: BirthDateModel, format: string = 'DD-MM-YYYY'): string {
    const { year, month, day } = date || {}

    if (!year || !month || !day) {
        return ''
    }

    return moment(`${year}-${month}-${day}`, 'YYYY-M-D').format(format)
}

export function convertDateStringToObject(date: string): BirthDateModel {
    if (date) {
        const m = moment(date, 'DD-MM-YYYY')
        return {
            year: m.year(),
            month: m.month() + 1,
            day: m.date()
        }
    }

    return { year: 0, month: 0, day: 0 }
}