import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js'
import numeral from 'numeral'
import styles from './BarChart.scss'

export const BarChart = props => {
  const { data, yAxisNumberFormat, renderTooltipLabel } = props
  const chartRef = useRef(null)

  useEffect(() => {
    createChart()
  }, [])

  const createChart = () => {
    const ctx = chartRef.current.getContext('2d')
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom'
      },
      scales: {
        xAxes: [
          {
            gridLines: { display: false, drawBorder: false }
          }
        ],
        yAxes: [
          {
            ticks: {
              min: 0,
              beginAtZero: true,
              callback(value, index, values) {
                return numeral(value).format(yAxisNumberFormat || '0.00a')
              }
            },
            gridLines: { drawBorder: false }
          }
        ]
      },
      tooltips: {
        callbacks: {
          label: ({ datasetIndex, index, label, value }, _data) => {
            return renderTooltipLabel(_data.datasets[datasetIndex].label, value)
          }
        }
      }
    }

    if (!renderTooltipLabel) {
      delete options.tooltips
    }

    const chart = new Chart(ctx, {
      type: 'bar',
      data,
      options
    })
  }

  return (
    <div className={styles.BarChart}>
      <canvas id={`bar2-${styles.BarChart}`} ref={chartRef} />
    </div>
  )
}
