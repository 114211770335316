import translations from './translations'

translations.add('en', {
  name: 'Name',
  result: 'Result',
  id: 'ID',
  personName: 'Person name',
  files: 'Files',
  type: 'Type',
  link: 'Link',
  download: 'Download',
  pass: 'PASS',
  fail: 'FAIL'
})
