import translations from './translations'

translations.add('en', {
  merchantsUploadError: 'Merchants upload Error',
  loginsUploadError: 'Logins upload Error',
  loginSuccessfulAdded: 'Login has been added',
  loginSuccessfulUpdated: 'Login has been updated',
  securitySettingsSuccessfulUpdated: 'Security settings have been updated',
  securitySettingsUpdateFailure: 'Security settings update error',
  loginAddFailure: 'Login adding error',
  loginSuccessfulDelete: 'Login successfully removed',
  loginDeleteFailure: 'Login delete error',
  loginUpdateFailure: 'Login update error',
  merchantSuccessfulDelete: 'Merchant successfully removed',
  specificPermissions: {
    'virtual_terminal.refunds': 'Ad-hoc refunds via VT',
    'pos_payments.refunds': 'Pos payments refunds',
    'card_issuing.read': 'Card issuing read',
    'card_issuing.full': 'Card issuing full',
    'pos_amex_payments.read': 'POS American Express read',
    'pos_amex_payments.full': 'POS American Express full'
  }
})
