import translations from './translations'

translations.add('en', {
    title: 'Trusted devices',
    delete: 'Delete',
    columns: {
        device: 'Device',
        addedOn: 'Added on',
        lastLogin: 'Last login'
    },
    messages: {
        confirm: 'Are you sure that you want to delete your trusted device?'
    },
    yes: 'Yes',
    no: 'No',
    tablet: 'Tablet',
    mobile: 'Mobile',
    desktop: 'Desktop',
    bot: 'Bot'
})
