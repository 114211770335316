import translations from '../translations'

export const ecomMotoPrefix = ['04']

export const processingMethodsData = [
  {
    key: 'pos',
    description: translations().pos
  },
  {
    key: 'moto',
    description: translations().moto
  },
  {
    key: 'ecom',
    description: translations().ecom
  },
  {
    key: 'payByLink',
    description: translations().payByLink
  },
  {
    key: 'payByApp',
    description: translations().payByApp
  },
  {
    key: 'virtualTerminal',
    description: translations().virtualTerminal
  }
]

export const advancePaymentsData = [
  {
    key: 'nbsp',
    description: translations().nbsp
  },
  {
    key: 'deposits',
    description: translations().deposits
  },
  {
    key: 'balances',
    description: translations().balances
  },
  {
    key: 'fullPayment',
    description: translations().fullPayment
  },
  {
    key: 'other',
    description: translations().other,
    hasInput: true
  }
]
