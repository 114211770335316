import React from 'react'
import { observer } from 'mobx-react'
import { Button, Card, Col, Drawer, Pagination, Row, Space, Table, TablePaginationConfig } from 'antd'
import {
  SelectTemplateStepTableFilter
} from '~/code/pages/Notifications/ManageNotifications/pages/PublishNotification/components'
import { PlusOutlined } from '@ant-design/icons'
import {
  getColumns,
  PublishNotificationStoreSymbol
} from '~/code/pages/Notifications/ManageNotifications/pages/PublishNotification'
import { useInjection } from 'dna-react-ioc'
import { IPublishNotificationsStore } from '~/code/pages/Notifications'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'
import { TemplateDetails } from '~/code/pages/Notifications/ManageNotificationTemplates/components/TemplateDetails'
import { NotificationSetupFormType } from '~/code/stores/NotificationManagementStore'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'
import translations from './translations'
import styles from './styles.scss'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { ReloadButton } from '~/code/components'

export const SelectTemplateStep = observer(() => {
  const store = useInjection<IPublishNotificationsStore>(PublishNotificationStoreSymbol)
  const {
    templates,
    templatesFilter,
    setTemplatesFilter,
    templatesTotal,
    isDrawerOpened,
    onDrawerClose,
    previewTemplate,
    selectedTemplate,
    notificationSetupForm,
    goToTemplatesCreate,
    isDashboard,
    isTemplatesLoading
  } = store
  const columns = getColumns(store)
  const screens = useBreakpoint()


  const paginationProps: TablePaginationConfig = generateTablePaginationProps(
    templatesFilter.page,
    templatesFilter.size,
    templatesTotal,
    (page: number, pageSize: number) => setTemplatesFilter({page, size: pageSize}, true)
  )

  const templateDetails = [
    {
      title: translations().title,
      description: selectedTemplate?.title,
    },
    {
      title: translations().system,
      description: selectedTemplate?.system,
    },
    {
      title: translations().description,
      description: (<div className={styles.templateDescription}>{selectedTemplate?.descriptionLine1}</div>),
    },
    {
      title: translations().type,
      description: selectedTemplate?.type,
    },
    {
      title: translations().displayType,
      description: selectedTemplate?.displayType,
    },
    {
      title: translations().contentArea,
      description: selectedTemplate?.contentArea,
    },
  ]

  return (
    <>
      <Space direction="vertical" size={'middle'} style={{ display: 'flex' }}>
        <Card className={styles.filterCard}>
          <SelectTemplateStepTableFilter/>
        </Card>

        <Card>
          <Space direction="vertical" size={'middle'} style={{ display: 'flex' }}>
            <Row justify={'space-between'}>
              <Col>
                {translations().cantFind}
                <Button
                  className={styles.createTemplateBtn}
                  disabled={!hasPermissions([PermissionMap.notifications.templates.create])}
                  color="primary"
                  type="link"
                  icon={<PlusOutlined />}
                  onClick={() => goToTemplatesCreate()}
                  iconPosition={'start'}
                >
                  {translations().createNew}
                </Button>
              </Col>
              <Col>
                <Row gutter={4}>
                  <Col>
                    <ReloadButton onClick={() => setTemplatesFilter(templatesFilter, true)}/>
                  </Col>
                  <Col>
                    <Pagination {...paginationProps} disabled={false} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Table
                  loading={isTemplatesLoading}
                  dataSource={templates}
                  columns={columns}
                  pagination={false}
                  rowKey={(template) =>template.id}
                  scroll={{ x: 1000, y: 1000 }}
                />
              </Col>
            </Row>
            <Row gutter={4} justify={'end'}>
              <Col>
                <ReloadButton onClick={() => setTemplatesFilter(templatesFilter, true)}/>
              </Col>
              <Col>
                <Pagination {...paginationProps} disabled={false} />
              </Col>
            </Row>
          </Space>
        </Card>
        <Drawer
          closable
          destroyOnClose
          title={translations().templateDetails}
          placement="right"
          open={isDrawerOpened}
          onClose={onDrawerClose}
          width={screens.xs ? 360 : 620}
        >
          <TemplateDetails
            data={templateDetails}
            isDashboard={isDashboard}
            previewTemplate={previewTemplate}
            notificationSetupForm={notificationSetupForm as NotificationSetupFormType}
          />
        </Drawer>
      </Space>
    </>
  )
})


