import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    commentLabel: string
    isAuthorisationDisabledLabel: string
    isTdsTwoDisabledLabel: string
    updatedAtLabel: string
    globalSettings: string
    merchantSettings: string
    onLowercased: string
    ecom: string
    pos: string
    posAll: string
    posVip: string
    connection: string
    traffic: string
    lastChangedBy: string
    lastChangedDate: string
    userComment: string
    comingSoon: string
}>()

