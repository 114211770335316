import translations from './translations'

translations.add('en', {
  mainInfo: 'Main Info',
  title: 'Title',
  firstName: 'First Name',
  middleName: 'Middle Name',
  surname: 'Surname',
  dateOfBirth: 'Date Of Birth',
  gender: 'Gender',
  placeOfBirth: 'Place Of Birth',
  nationality: 'Nationality',
  occupation: 'Occupation',
  emailAddress: 'Email Address',
  telephoneNumber: 'Telephone Number',
  residentialAddress: 'Residential Address',
  ownershipPercentage: 'Ownership Percentage',
  country: 'Country',
  postalCode: 'Postal Code',
  addressLine1: 'Address Line 1',
  addressLine2: 'Address Line 2',
  townOrCity: 'Town Or City',
  countyOrState: 'County Or State',
  county: 'County',
  houseName: 'House Name',
  houseNumber: 'House Number'
})
