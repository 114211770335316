import { Row, Col, Typography, Divider, Skeleton } from 'antd'
import React, { useEffect } from 'react'
import { useInjection } from 'dna-react-ioc'
import { observer } from 'mobx-react'
import { IOfficersStore } from './IOfficersStore'
import { OfficersProps } from './props'
import { Info } from '../../..'
import translations from './translations'
import styles from './styles.scss'

const { Title } = Typography
export const OfficersStoreSymbol = Symbol('OfficersStore')
export const Officers = observer((props: OfficersProps) => {
  const store = useInjection<IOfficersStore>(OfficersStoreSymbol)
  useEffect(() => {
    store.loadOfficersInfo({ officersList: props.officersList, officersType: props.officersType })
  }, [props.officersType, store.companyFullDossierV2])

  const renderVulnerabilityBlock = ({ vulnerability }) => {
    const { enabled, visualDisability, audioDisability, lifeEvent, capability, resilience, vulnerabilityInfo } =
      vulnerability || {}
    const enabledValue = enabled ? enabled.toString() : enabled
    return (
      enabled && (
        <Row>
          <Col xs={24} className={styles.sectionContainer}>
            <Title level={5} className={styles.sectionTitle}>
              {translations().vulnerableStatus}
            </Title>
            <Row>
              {visualDisability && (
                <Col xs={24} md={12}>
                  <Info label={translations().visualDisability} value={enabledValue} />
                </Col>
              )}
              {audioDisability && (
                <Col xs={24} md={12}>
                  <Info label={translations().audioDisability} value={enabledValue} />
                </Col>
              )}
              {lifeEvent && (
                <Col xs={24} md={12}>
                  <Info label={translations().lifeEvent} value={enabledValue} />
                </Col>
              )}
              {capability && (
                <Col xs={24} md={12}>
                  <Info label={translations().capability} value={enabledValue} />
                </Col>
              )}
              {resilience && (
                <Col xs={24} md={12}>
                  <Info label={translations().resilience} value={enabledValue} />
                </Col>
              )}
            </Row>
            {vulnerabilityInfo && (
              <Row>
                <Info label={translations().vulnerabilityInfo} value={vulnerabilityInfo} />
              </Row>
            )}
          </Col>
        </Row>
      )
    )
  }

  return (
    <>
      {!store.isIndividualsLoading ? (
        <div>
          {store[props.officersType]?.map((individual, idx) => {
            return individual ? (
              <div key={idx}>
                {idx !== 0 && <Divider />}
                <Title level={4}>
                  {`${individual.mainInfo?.englishTitleValue || ''}  ${individual.mainInfo?.name} ${
                    individual.mainInfo?.surname
                  } (${translations().dsrId(individual.mainInfo?.dsrId || '')})`}
                </Title>
                <Row>
                  <Col xs={24} md={12} lg={8}>
                    <Info label={translations().title} value={individual.mainInfo?.englishTitleValue} />
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Info label={translations().firstName} value={individual.mainInfo?.name} />
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Info label={translations().middleName} value={individual.mainInfo?.middleName} />
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Info label={translations().surname} value={individual.mainInfo?.surname} />
                  </Col>

                  <Col xs={24} md={12} lg={8}>
                    <Info label={translations().dateOfBirth} value={individual.mainInfo?.birthDate} />
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Info label={translations().gender} value={individual.mainInfo?.genderValue} />
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Info label={translations().placeOfBirth} value={individual.mainInfo?.birthPlace} />
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Info label={translations().nationality} value={individual.mainInfo?.nationalityValue} />
                  </Col>

                  <Col xs={24} md={12} lg={8}>
                    <Info label={translations().occupation} value={individual.mainInfo?.occupation} />
                  </Col>
                </Row>
                {individual.email?.map((email, idx) => {
                  return (
                    <Row key={idx}>
                      <Col>
                        <Info label={translations().emailAddress} value={email.email} />
                      </Col>
                    </Row>
                  )
                })}
                {individual.phone?.map((phone, idx) => {
                  return (
                    <Row key={idx}>
                      <Col xs={24} md={12} lg={8}>
                        <Info label={translations().telephoneNumber} value={phone.fullPhoneNum} />
                      </Col>
                    </Row>
                  )
                })}
                {individual.address?.map((address, idx) => {
                  return (
                    <Row key={idx}>
                      <Col xs={24} className={styles.sectionContainer}>
                        <Title level={5} className={styles.sectionTitle}>
                          {translations().residentialAddress}
                        </Title>
                        <Row>
                          <Col xs={24} md={12} lg={8}>
                            <Info label={translations().addressLine1} value={address.addressLine1} />
                          </Col>
                          <Col xs={24} md={12} lg={8}>
                            <Info label={translations().addressLine2} value={address.addressLine2} />
                          </Col>
                          <Col xs={24} md={12} lg={8}>
                            <Info label={translations().countryOrState} value={address.countyOrState} />
                          </Col>
                          <Col xs={24} md={12} lg={8}>
                            <Info label={translations().postCode} value={address.postalCode} />
                          </Col>
                          <Col xs={24} md={12} lg={8}>
                            <Info label={translations().country} value={address.countryValue} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )
                })}
                {renderVulnerabilityBlock(individual)}
              </div>
            ) : (
              ''
            )
          })}
        </div>
      ) : (
        <Skeleton />
      )}
    </>
  )
})
