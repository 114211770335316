import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  store: string
  storeName: string
  systemDescriptor: string
  storeAddress: string
  country: string
  postalCode: string
  addressLine1: string
  townOrCity: string
  systemStoreAddress: string
  mcc: string
  mccDescription: string
  industry: string
  natureOfBusiness: string
  deliveryAddress: string
  deliveryInstructions: string
  deliveryPhoneNumber: string
  delivery: string
  products: string
  bankInfo: string
  bankAccountNumber: string
  sortCode: string
  accountName: string
  bankName: string
  bankAccountType: string
  financialInfo: string
  expectedAnnualTurnover: string
  expectedAnnualTurnoverCustomValue: string
  expectedMonthlyTransactionValues: string
  averageTransactionValue: string
  tradeOutsideEea: string
  tradingCountriesOutsideEea: string
  businessModelQuestions: string
  expectedAnnualCardTurnover: string
  highestTransactionValue: string
  yes: string
  no: string
  processingMethods: string
  advancedPayments: string
}>()
