import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { TabType } from '~/code/models'
import { PageHeader } from '~/code/components'
import { ISettlementsFilterStore } from './components/SettlementsFilters/ISettlementsFilterStore'
import { ISettlementsTableStore } from './components/SettlementsTable/ISettlementsTableStore'
import { perBankingDateColumns } from './components/SettlementsTable/columns'
import { SettlementsFilters, SettlementsTable } from './components'
import styles from './SettlementsStatement.scss'
import { Page } from '../../Page'
import { SettlementsTableProps } from './components/SettlementsTable/SettlementsTableProps'
import { SettlementsStatementProps } from './props'
import translations from './translations'

export type TabKey = 'perTransaction' | 'perBankingDate'

const tabList: TabType[] = [
  {
    key: 'perTransaction',
    label: translations().perTransaction
  },
  {
    key: 'perBankingDate',
    label: translations().perBankingDate
  }
]

export const SettlementsStatement = observer(
  ({
    showHeader,
    canDownload,
    tableStoreSymbol,
    filtersStoreSymbol,
    merchantSelectStoreSymbol
  }: SettlementsStatementProps) => {
    // TODO move the logic of clearing the stores to the AppStore, when the AppStore becomes injectable
    const filtersStore = useInjection<ISettlementsFilterStore>(filtersStoreSymbol)
    const settlementsTableStore = useInjection<ISettlementsTableStore>(tableStoreSymbol)

    const [tabKey, setTabKey] = useState<TabKey>('perTransaction')
    const tableStore =
      tabKey === 'perTransaction' ? settlementsTableStore.tableStore : settlementsTableStore.tableStorePerBankingDate

    useEffect(() => {
      return () => {
        filtersStore.clear()
      }
    }, [])

    const handleTabChange = (key: TabKey) => {
      setTabKey(key)
    }

    const handleLoad = async () => {
      tableStore.loadData({ pageNumber: 1 })
    }

    const renderHeader = () => (
      <PageHeader
        tabActiveKey={tabKey}
        tabList={tabList}
        onTabChange={key => handleTabChange(key as TabKey)}
        className={styles.header}
      />
    )

    const settlementsTableProps: Record<TabKey, SettlementsTableProps> = {
      perTransaction: {
        columns: settlementsTableStore.columns,
        tableStore,
        tableWidth: 2000,
        emptyText: settlementsTableStore.emptyText
      },
      perBankingDate: {
        columns: perBankingDateColumns,
        tableStore,
        tableWidth: 1000
      }
    }

    return (
      <Page>
        {showHeader && renderHeader()}
        <SettlementsFilters
          isDownloadButtonVisible={canDownload && tabKey === 'perTransaction'}
          isLoading={tableStore.isLoading}
          onLoad={handleLoad}
          filtersStoreSymbol={filtersStoreSymbol}
          merchantSelectStoreSymbol={merchantSelectStoreSymbol}
        />
        {tableStore.loadingStatus !== 'idle' && <SettlementsTable {...settlementsTableProps[tabKey]} />}
      </Page>
    )
  }
)
