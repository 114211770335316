import translations from './translations'

translations.add('en', {
    processStatus: 'Process Status',
    dailyAmount: 'Daily Amount',
    dailyCount: 'Daily Count',
    monthlyAmount: 'Monthly Amount',
    monthlyCount: 'Monthly Count',
    perRefund: 'Per Refund',
    perTransaction: 'Per Transaction',
    currency: 'Currency',
    currentLimits: 'Current Limits',
    requestedLimits: 'Requested Limits',
    approvedLimits: 'Approved Limits',
    comment: 'Comment'
})
