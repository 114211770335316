import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import styles from './MiniArea.scss'
import Chart from 'chart.js'

export const MiniArea = observer((props) => {
    const { data } = props
    const chartRef = useRef(null)
    let ticksMaxVal = 100

    useEffect(() => {
        ticksMaxVal = (data?.datasets?.length && data.datasets[0]?.data) && data.datasets[0].data.reduce((previousValue, currentValue) => {
                if (currentValue > previousValue) {
                    return currentValue
                }
                return previousValue
        }, 0) + 10
        createChart()
    }, [])

    const createChart = () => {
        const ctx = chartRef.current.getContext('2d')
        const chart = new Chart(ctx, {
            type: 'line',
            data: props.data,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                style: {
                    height: '65px'
                },
                legend: {
                    display: false
                },
                elements: {
                    point: {
                        radius: 0.7
                    },
                    line: {
                        backgroundColor: 'rgba(24, 144, 255, 0.2)',
                        borderColor: '#1089ff',
                        borderWidth: 2
                    }
                },
                scales: {
                    xAxes: [{
                        ticks: {display: false},
                        gridLines: {display: false, drawBorder: false}
                    }],
                    yAxes: [{
                        ticks: {display: false, min: 0, max: ticksMaxVal},
                        gridLines: {display: false, drawBorder: false}
                    }]
                }
            }
        })
    }

    return <div className={styles.MiniArea}>
        <canvas
            id={'miniAreaCanvas'}
            ref={chartRef}
        />
    </div>
})
