import translations from './translations'

translations.add('en', {
  notCompleted: 'Not completed',
  deleteFormTitle: (name: string) => `Are you sure you want to delete ${name}?`,
  deleteFormContent: 'By clicking Ok you confirm your action',
  edit: 'Edit',
  delete: 'Delete',
  productsModal: 'Products',
  agreementSignatory: 'SIGNEE',
  verified: 'Account successfully verified',
  notVerified: 'Account not verified',
  manuallyDocs: 'Documents will be uploaded'
})
