import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  createdDate: string
  merchantName: string
  amount: string
  currency: string
  status: string
  message: string
  signifydDecision: string
  signifydScore: string
  payoutDate: string
  payoutAmount: string
  transactionChannel: string
  transactionType: string
  paymentMethod: string
  processingType: string
  mid: string
  terminal: string
  merchantReference: string
  reference: string
  sourceClient: string
  threeDSVersion: string
  avsHouseNumberResult: string
  avsPostcodeResult: string
  cscResult: string
  payerAuthenticationResult: string
  responseCode: string
  cardScheme: string
  cardMask: string
  issuer: string
  issuerCountry: string
  payerName: string
  payerEmail: string
  payerIp: string
  ipCountry: string
  ipCity: string
  id: string
  title: string
  columns: string
  cancel: string
  ok: string
  all: (number) => string
  custom: string
}>()
