import React from 'react'
import { TranslationBuilder } from '~/code/components/Translation'
import { PaymentSolutionType } from '~/code/stores/MerchantsManagementStore/models/merchant-dossier-v1/ApplicationModel'

export default TranslationBuilder.create<{
    info: string
    merchant: string
    email: string
    companyWebsite: string
    firstName: string
    surname: string
    phoneNumber: string
    businessStructure: string
    acquisitionChannel: string
    status: string
    paymentSolution: {
        label: string
        value: (paymentSolution: PaymentSolutionType) => React.ReactNode 
    }
    source: string
    applicant: string
    productCompany: string
}>()

