import translations from './translations'
import React from 'react'

translations.add('en', {
  note1: 'DNA Payments uses smart, automated tools to streamline and simplify our operations and customer experience.',
  note2:
    'Before uploading the documents manually, please answer the questions below. This will help us understand how to improve the Open Banking verification tool.',
  required: 'Required field',
  yes: 'Yes',
  no: 'No',
  documentCopyRequiresReview:
    'Do you understand that any document copy requires manual review by the Underwriting team, incurring costs and time?',
  confirmThatHaveChoices:
    'Please confirm that with this application, there were other choices but to submit a physical document copy.',
  minSymbols: 'Please elaborate, 30 symbols minimum',
  whySkip: 'Why do you want to skip Open Banking verification?',
  alreadyHaveProof: 'Already have proof of bank',
  doesntTrustTool: 'Customer doesn`t trust the tool',
  couldntOpenLink: 'Customer couldn`t open the link',
  couldntUseApp: 'Customer couldn`t use their app',
  bankNotSupported: 'Customer`s bank not supported',
  other: 'Other, please specify'
})
