import translations from './translations'

translations.add('en', {
    merchants: 'Merchants',
    mcc: 'MCC',
    cardTypes: 'Card Types',
    paymentMethods: 'Payment Methods',
    issuingBanks: 'Issuing Banks',
    acquisitionChannels: 'Acquisition Channels',
    amount: 'Amount',
    count: 'Count'
})
