import { Col, Divider, Row, Form, FormInstance } from 'antd'
import React, { useMemo } from 'react'
import merge from 'lodash/merge'
import { MerchantProductType } from '~/code/models'
import { getTariffsInitialValues } from '../../services'
import { NumberFormItem } from '../NumberFormItem'
import translations from './translations'
import styles from './styles.scss'

type FeesFormMSAProps = {
    form: FormInstance<any>
    initialValues: any
    product: MerchantProductType
}

const subBaseProps = {
    label: translations().baseLabel,
    labelCol: {span: 24},
    className: styles.subLabel
}
const subPercentProps = {
    label: translations().percentLabel,
    labelCol: {span: 24},
    className: styles.subLabel
}

export const FeesFormMSA: React.FC<FeesFormMSAProps> = (props) => {
    const { form, initialValues, product } = props

    const mergedInitialValues = useMemo(() => {
        const productValues = product !== 'ecom' ? {} : {
            openBanking: { base: 0, percent: 0.5 },
            payByBank: { base: 0, percent: 0.5 }
        }

        return merge(
            {},
            {
                diners: { base: 0, percent: 3 },
                chinaUnionPay: { base: 0, percent: 3 },
                amex: { base: 0, percent: 3 },
                settlementPeriod: 3,
                ...productValues
            },
            initialValues
        )
    }, [initialValues, product])

    return <div className={styles.feesFormMSA}>
        <Form
            form={form}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 10 }}
            initialValues={mergedInitialValues}
            labelAlign='left'
        >
            <Row gutter={[0, 16]}>
                <Col xs={24} lg={12}>
                    <Form.Item
                        className={styles.parentLabel}
                        label={translations().dinersCardsFee}
                    >
                        <Row gutter={[16, 0]}>
                            <Col xs={12}>
                                <NumberFormItem
                                    name={['diners', 'base']}
                                    {...subBaseProps}
                                />
                            </Col>
                            <Col xs={12}>
                                <NumberFormItem
                                    name={['diners', 'percent']}
                                    {...subPercentProps}
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>

                <Col xs={24} lg={12}>
                    <Form.Item
                        className={styles.parentLabel}
                        label={translations().unionPayCardsFee}
                    >
                        <Row gutter={[16, 0]}>
                            <Col xs={12}>
                                <NumberFormItem
                                    name={['chinaUnionPay', 'base']}
                                    {...subBaseProps}
                                />
                            </Col>
                            <Col xs={12}>
                                <NumberFormItem
                                    name={['chinaUnionPay', 'percent']}
                                    {...subPercentProps}
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                    
                </Col>

                <Col xs={24} lg={12}>
                    <Form.Item
                        className={styles.parentLabel}
                        label={translations().amexCardsFee}
                    >
                        <Row gutter={[16, 0]}>
                            <Col xs={12}>
                                <NumberFormItem
                                    name={['amex', 'base']}
                                    {...subBaseProps}
                                />
                            </Col>
                            <Col xs={12}>
                                <NumberFormItem
                                    name={['amex', 'percent']}
                                    {...subPercentProps}
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                    
                </Col>
            </Row>

            <Divider />

            {product === 'ecom' && <Row gutter={[0, 16]}>
                <Col xs={24} lg={12}>
                    <Form.Item
                        className={styles.parentLabel}
                        label={translations().openBankingFee}
                    >
                        <Row gutter={[16, 0]}>
                            <Col xs={12}>
                                <NumberFormItem
                                    name={['openBanking', 'base']}
                                    {...subBaseProps}
                                />
                            </Col>
                            <Col xs={12}>
                                <NumberFormItem
                                    name={['openBanking', 'percent']}
                                    {...subPercentProps}              
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>

                <Col xs={24} lg={12}>
                    <Form.Item
                        className={styles.parentLabel}
                        label={translations().payByBankFee}
                    >
                        <Row gutter={[16, 0]}>
                            <Col xs={12}>
                                <NumberFormItem
                                    name={['payByBank', 'base']}
                                    {...subBaseProps}
                                />
                            </Col>
                            <Col xs={12}>
                                <NumberFormItem
                                    name={['payByBank', 'percent']}
                                    {...subPercentProps}
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>

                {/* <Col xs={24} lg={12}>
                    <Form.Item
                        className={styles.parentLabel}
                        label={translations().klarnaFee}
                    >
                        <Row gutter={[16, 0]}>
                            <Col xs={12}>
                                <NumberFormItem
                                    name={['klarna', 'base']}
                                    {...subBaseProps}
                                />
                            </Col>
                            <Col xs={12}>
                                <NumberFormItem
                                    name={['klarna', 'percent']}
                                    {...subPercentProps}
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                </Col> */}
            </Row>}

            <Divider />
            
            <Row gutter={[0, 16]}>
                <Col xs={24} lg={12}>
                    <NumberFormItem
                        name='authorisationOkFee'
                        label={translations().authorisationFee}
                        max={0.5}
                    />
                </Col>

                <Col xs={24} lg={12}>
                    <NumberFormItem
                        name='gatewayFee'
                        label={translations().gatewayFee}
                        isPositive={false}
                        max={0.5}
                    />
                </Col>

                <Col xs={24} lg={12}>
                    <NumberFormItem
                        name='refundFee'
                        label={translations().refundFee}
                    />
                </Col>

                <Col xs={24} lg={12}>
                    <NumberFormItem
                        name='chargebackFee'
                        label={translations().chargebackFee}
                    />
                </Col>
            </Row>

            <Divider />
            
            <Row gutter={[0, 16]}>
                <Col xs={24} lg={12}>
                    <NumberFormItem
                        name='settlementFee'
                        label={translations().settlementFee}
                    />
                </Col>
            </Row>

            <Divider />

            <Row gutter={[0, 16]}>
                <Col xs={24} lg={12}>
                    <NumberFormItem
                        name='settlementPeriod'
                        label={translations().settlementPeriod}
                        min={1}
                        max={7}
                     />
                </Col>
            </Row>

            <Divider />

            <div>
                {translations().definitions}
            </div>
        </Form>
    </div>
}
