import { observer } from 'mobx-react'
import React from 'react'
import { SavedApplicationsFilters, SavedApplicationsTable } from './components'
import { SavedApplicationsStore } from './SavedApplicationsStore'
import styles from './SavedApplications.scss'

export const SavedApplications = observer((props: { store: SavedApplicationsStore }) => {
    const { store } = props

    return <>
        <SavedApplicationsFilters store={store.filtersStore} />
        <div className={styles.tableSectionContainer}>
            <SavedApplicationsTable store={store} />
        </div>
    </>
})