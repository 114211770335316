import translations from './translations'

translations.add('en', {
    commentLabel: 'Comment',
    isAuthorisationDisabledLabel: 'Authorisation',
    isTdsTwoDisabledLabel: '3DS v2.x',
    updatedAtLabel: 'Last Updated Date',
    globalSettings: 'Global Settings',
    merchantSettings: 'Merchant Settings',
    onLowercased: 'on',
    ecom: 'ECOM transactions',
    pos: 'POS',
    posAll: 'POS transactions',
    posVip: 'POS VIP clients',
    connection: 'Connection',
    traffic: 'Traffic',
    lastChangedBy: 'Last changed by:',
    lastChangedDate: 'Last changed date:',
    userComment: 'User comment:',
    comingSoon: 'COMING SOON'
})

