import { string, object } from 'yup'
import { isDateOfCreationValid } from '../../services/validations'


export const CompanyProfileScheme = object({
  companyType: string().oneOf(['company', 'sole-trader', 'charity', 'partnership', 'llp', 'other']),
  companyNumber: string().nullable().when('companyType', { is: 'company', then: string().required() }),
  companyName: string().required(),
  emailAddress: string().required().email(),
  dateOfCreation: string().required().test('date-validator', '', isDateOfCreationValid)
}).required()
