import React, { useState } from 'react'
import { defaultTerminalsLength } from './constants'
import translations from './translations'

export const TidsList = (props: { terminalList: string[] }) => {
  const { terminalList } = props
  const [showAllTids, setShowAllTids] = useState(false)

  const displayTerminals = () => {
    if (!showAllTids && terminalList?.length > defaultTerminalsLength) {
      return (
        <div>
          {`${terminalList.slice(0, defaultTerminalsLength).join(', ')} ${translations().dots}`}
          <a onClick={() => setShowAllTids(true)}>{translations().showMore}</a>
        </div>
      )
    } else {
      return <div>{terminalList.join(', ')}</div>
    }
  }

  return (
    <>
      {displayTerminals()}
      {showAllTids && <a onClick={() => setShowAllTids(false)}>{translations().showLess}</a>}
    </>
  )
}
