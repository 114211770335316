import React from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { MerchantSelect } from '~/code/components'
import { RangePicker } from '~/code/components/pickers/RangePicker'
import { IAcquiringFilterStore } from './models'
import { AcquiringFilterProps } from './props'
import styles from './AcquiringFilter.scss'

export const AcquiringFilter = observer((props: AcquiringFilterProps) => {
  const { storeSymbol, merchantSelectStoreSymbol } = props
  const store = useInjection<IAcquiringFilterStore>(storeSymbol)
  const { dateStore } = store

  return (
    <div>
      <div className={styles.AcquiringFilter}>
        <div className={styles.filtersContainer}>
          <MerchantSelect storeSymbol={merchantSelectStoreSymbol} />

          <div className={styles.extraContainer}>{props.extra}</div>

          <RangePicker
            startDate={dateStore.startDate}
            endDate={dateStore.endDate}
            period={dateStore.period}
            onSetPeriod={store.setPeriod}
            onSetDates={store.setDates}
          />
        </div>
      </div>
    </div>
  )
})
