import moment from 'moment'
import { FileType } from '~/code/models/FileType'

export function getFilename(name, from, to, format) {
    return `${ name } ${ moment(from).format('DD MMM YYYY') } - ${ moment(to).format('DD MMM YYYY') }.${ getFileExtension(format) }`
}

export function getPdfFilename(name, period, format) {
    return `${ name } ${ moment(period).format('MMM YYYY') }.${ getFileExtension(format) }`
}

export function getFileContentType(type: FileType) {
    switch (type) {
        case FileType.EXCEL:
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        case FileType.CSV:
            return 'text/csv'
        default:
            return 'application/pdf'
    }
}

export function getFileExtension(type: FileType) {
    switch (type) {
        case FileType.EXCEL:
            return 'xlsx'
        case FileType.CSV:
            return 'csv'
        default:
            return 'pdf'
    }
}
