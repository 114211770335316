import { MerchantProductType } from '~/code/models'
import { CorporateOrConsumerType } from '../models/CorporateOrConsumerType'
import { IcTariffType } from '../models/IcTariffType'
import { TariffFeeType } from '../models/TariffFeeType'
import { TariffValueType } from '../models/TariffValueType'

export const getMarkup = (options: { corporateOrConsumer: CorporateOrConsumerType, cardType: string }) => {
    const { corporateOrConsumer, cardType } = options
    if (corporateOrConsumer === 'Consumer') {
        if (cardType === 'Debit') {
            return 'dnapConsumerDebit'
        }
        if (cardType === 'Credit') {
            return 'dnapConsumerCredit'
        }
    }

    if (corporateOrConsumer === 'Corporate') {
        if (cardType === 'Debit') {
            return 'dnapCorporateDebit'
        }
        if (cardType === 'Credit') {
            return 'dnapCorporateCredit'
        }
    }

    return ''
}

export const getTariffsInitialValues = (data: IcTariffType[], func?: (el: TariffFeeType) => TariffValueType) => {
    const result: Record<string, TariffValueType> = {}
    for (const item of data) {
        [item.interchangeFee, item.schemeFee].forEach((el) => {
            result[el.key] = func ? func(el) : { base: el.baseFee, percent: el.percentFee }
        })
    }
    return result
}

export const getBlendedValues = (tariffList: IcTariffType[]) => {
    const values = {}
    tariffList.forEach((row) => {
        values[row.interchangeFee.key] = { base: 0, percent: 0 }
    })
    return values
}

export const getFeesFormMSAValues = (product: MerchantProductType) => {
    const values = {
        diners: { base: 0, percent: 0 },
        chinaUnionPay: { base: 0, percent: 0 },
        amex: { base: 0, percent: 0 },
        authorisationOkFee: 0,
        gatewayFee: 0,
        refundFee: 0,
        chargebackFee: 0,
        settlementFee: 0,
        settlementPeriod: 0
    }

    if (product === 'ecom') {
        return {
            ...values,
            openBanking: { base: 0, percent: 0 },
            payByBank: { base: 0, percent: 0 }
        }
    }

    return values
}
