import React from 'react'
import { Radio, Form, Select, Typography } from 'antd'
import { observer } from 'mobx-react-lite'
import { PaymentSolutionView } from '../../models'
import { StartApplicationStore } from '../../StartApplicationStore'
import { FormSubgroupTitle } from '../shared/FormSubgroupTitle'
import { SubLabel } from '../shared/SubLabel'
import translations from './translations'
import styles from './SelectPaymentSolutionForm.scss'

type Props = { store: StartApplicationStore }

export const SelectPaymentSolutionForm = observer(({ store }: Props) => {
    return <div className={styles.container}>
        <FormSubgroupTitle>{translations().title}</FormSubgroupTitle>
        <Form.Item name='paymentSolutionView' rules={[{ required: true, message: translations().required }]}>
            <Radio.Group className={styles.radioGroup}>
                <Typography.Title level={5}>{translations().ecom}</Typography.Title>
                <PaymentSolutionButton item={PaymentSolutionView.WEBSITE_PAYMENTS} />
                <PaymentSolutionButton item={PaymentSolutionView.WEBSITE_PAYMENTS_MOTO} />
                <PaymentSolutionButton item={PaymentSolutionView.PAY_BY_LINK} />
                <PaymentSolutionButton item={PaymentSolutionView.PAY_BY_LINK_MOTO} />
                <Typography.Title level={5}>{translations().pos}</Typography.Title>
                <PaymentSolutionButton item={PaymentSolutionView.POS} />
                <PaymentSolutionButton item={PaymentSolutionView.POS_MOTO} />
            </Radio.Group>
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
            {(form) =>
                (form.getFieldValue('paymentSolutionView') === PaymentSolutionView.POS
                    || form.getFieldValue('paymentSolutionView') === PaymentSolutionView.POS_MOTO) && (
                    <div className={styles.posModel}>
                        <span>{translations().posModel}:</span>
                        <Form.Item
                            name='posModel'
                            rules={[{ required: true, message: translations().posModelRequired }]}
                        >
                            <Select
                                loading={store.isPosModelsLoading}
                                options={store.posModels}
                            />
                        </Form.Item>
                    </div>
                )
            }
        </Form.Item>
    </div>
})

const PaymentSolutionButton: React.FC<{ item: PaymentSolutionView }> = ({ item }) => (
    <Radio value={ item }>
        <div>{ translations().paymentSolutions[item].title }</div>
        <SubLabel>({ translations().paymentSolutions[item].comment })</SubLabel>
    </Radio>
)
