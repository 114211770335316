import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  requestFail: string
  requestSuccess: string
  errClosingMerchant: string
  errClosingTerminal: string
  applicationSuccessful: string
  errorUploadingDocument: string
  errorDeletingDocument: string
  documentsRequired: string
  fetchingPOSModelsError: string
  fetchingMIDsError: string
  errChangingBankDetals: string
  errVerifyingBankAccount: string
  errBankDetailsValidation: string
  errorLoadingAppData: string
  submitNewRequestSuccess: string
  submitNewRequestError: string
  errLoadingBalances: string
  errLoadingSettlementPeriod: string
  errorLoadingSettlementsInfo: string
  dsrIdIsEmpty: string
  errorEditingTariffs: string
  errorNoChanges: string
  errorLoadingTipJarInfo: string
  errorLoadingMerchantClosureReasons: string
  errorNoChangesSettlementStatus: string
  errorChangeRoutings: string
  pos: string
  ecom: string
  topLevelSubjectIdIsEmpty: string
  errorLoadingSalesForceData: string
  errStartingAdjustment: string
  contractIdIsEmpty: string
  tariffSettingsAreEmpty: string
  openBanking: string
  openBankingDescription: string
  payByBank: string
  payByBankDescription: string
  errStartingHoldProcess: string
  errorLoadingStoreAttributes: string
  attachDocuments: string
  errorLoadingMerchantData: string
  storeIdIsEmpty: string
  notDefined: string
}>()
