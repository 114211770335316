
export enum AdvancePaymentsTypes {
    NBSP = 'nbsp',
    DEPOSITS = 'deposits',
    BALANCES = 'balances',
    FULLPAYMENT = 'fullPayment',
    OTHER = 'other'
}

export type AdvancePaymentsFormStateModel = Partial<Record<AdvancePaymentsTypes, AdvancedPaymentsModel>>

export interface AdvancedPaymentsModel {
    days?: number | string,
    description: string,
    name: string,
    percent?: number | string
}

export const advancePaymentsInitialValues: AdvancePaymentsFormStateModel = {
    nbsp: {
        days: null,
        description: '',
        name: '',
        percent: null
    },
    deposits: {
        days: null,
        description: '',
        name: '',
        percent: null
    },
    balances: {
        days: null,
        description: '',
        name: '',
        percent: null
    },
    fullPayment: {
        days: null,
        description: '',
        name: '',
        percent: null
    },
    other: {
        days: null,
        description: '',
        name: '',
        percent: null
    }
}