import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    notificationSaved: string
    notificationDeactivated: string
    all: string
    notificationCreated: string
    unexpectedError: string

}>()

