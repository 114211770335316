import { ITableFilterStore, SelectItem } from '~/code/models'
import { TeammateTabType } from '~/code/stores/TeammatesStore/models'
import { RoleItemModel, TeammateType, TeammateViewType, TeammateFilterType } from './models'

export type TTeammatesStore = {
    isLoading: boolean
    roles: RoleItemModel[]
    statuses: SelectItem[]
    authMethods: SelectItem[]
    activeTab: TeammateTabType
    selectedUser: Partial<TeammateViewType>
    filterValues: Partial<TeammateFilterType>
    isInvited: boolean
    isSecurityDrawerOpen: boolean
    isUserDrawerOpen: boolean

    usersStore: ITableFilterStore<TeammateViewType>
    usersInvitedStore: ITableFilterStore<TeammateViewType>

    inviteOrUpdateUser(user: TeammateType): Promise<void>
    updateUserSecurity(user: TeammateType): Promise<void>
    deleteUser(user: TeammateType): Promise<void>
    resend(id: string): Promise<void>
    setActiveTab(tab: TeammateTabType): void
    setSelectedUser(user: Partial<TeammateViewType>): void
    setFilterValues(filterValues: Partial<TeammateFilterType>): void
    setIsSecurityDrawerOpen: (isOpen: boolean) => void
    setIsUserDrawerOpen: (isOpen: boolean) => void
    onSecurityDrawerClose: () => void
    onUserDrawerClose: () => void
}

export const TeammatesStoreSymbol = Symbol('TeammatesStore')
