import { Descriptions } from 'antd'
import React from 'react'
import { FinancialInfoPropsType } from './props'
import translations from './translations'
import styles from './FinancialInfo.scss'

export const FinancialInfo = ({ data }: FinancialInfoPropsType) => {
  return (
    <Descriptions
      title={<div className={styles.sectionHeader}>{translations().financialInfo}</div>}
      bordered
      column={1}
    >
      <Descriptions.Item label={<b>{translations().expectedAnnualTurnover}</b>}>
        {data?.expectedAnnualTurnover}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().expectedAnnualTurnoverCustomValue}</b>}>
        {data?.expectedAnnualTurnoverCustomValue}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().expectedMonthlyTransactionValues}</b>}>
        {data?.expectedMonthlyTransactionValues}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().averageTransactionValue}</b>}>
        {data?.averageTransactionValue}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().tradeOutsideEea}</b>}>
        {data?.tradeOutsideEea ? translations().yes : translations().no}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().tradingCountriesOutsideEea}</b>}>
        {data?.tradingCountriesOutsideEea?.join()}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().expectedAnnualCardTurnover}</b>}>
        {data?.expectedAnnualCardTurnover}
      </Descriptions.Item>
      <Descriptions.Item label={<b>{translations().highestTransactionValue}</b>}>
        {data?.highestTransactionValue}
      </Descriptions.Item>
    </Descriptions>
  )
}
