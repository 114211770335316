import { Divider, Form, Input, Radio, Select } from 'antd'
import React, { useState } from 'react'
import { OpenBankingQuestionnairePropsType } from './props'
import translations from './translations'
import { YesOrNo } from '../../models'
import styles from './OpenBankingQuestionnaire.scss'
import { skipReasons } from './constants'

export const OpenBankingQuestionnaire: React.FC<OpenBankingQuestionnairePropsType> = ({ form }) => {
  return (
    <>
      <p className={styles.styledP}>{translations().note1}</p>
      <p className={styles.styledP}>{translations().note2}</p>
      <Divider />
      <Form layout='vertical' form={form}>
        <Form.Item
          name='skipReason'
          rules={[{ required: true, message: translations().required }]}
          validateTrigger={['onChange', 'onBlur']}
          label={translations().whySkip}
        >
          <Select options={skipReasons} />
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {t =>
            t.getFieldValue('skipReason') === 'other' && (
              <Form.Item
                shouldUpdate
                name='skipReasonOther'
                rules={[{ required: true }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Input.TextArea />
              </Form.Item>
            )
          }
        </Form.Item>

        <Form.Item
          name='documentCopyRequiresReview'
          label={translations().documentCopyRequiresReview}
          rules={[{ required: true, message: translations().required }]}
          validateTrigger={['onChange', 'onBlur']}
        >
          <Radio.Group>
            <Radio value={YesOrNo.Yes}>{translations().yes}</Radio>
            <Radio value={YesOrNo.No}>{translations().no}</Radio>
          </Radio.Group>
        </Form.Item>
        <Divider />

        <Form.Item
          name='confirmThatHaveChoices'
          label={translations().confirmThatHaveChoices}
          rules={[{ required: true, message: translations().required }]}
          validateTrigger={['onChange', 'onBlur']}
        >
          <Radio.Group>
            <Radio value={YesOrNo.Yes}>{translations().yes}</Radio>
            <Radio value={YesOrNo.No}>{translations().no}</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </>
  )
}
