import translations from './translations'

translations.add('en', {
  companyType: 'Company Type',
  companyName: 'Company Name',
  relationshipType: 'Relationship Type',
  dateOfCreation: 'Date Of Creation',
  numberOfEmployees: 'Number Of Employees',
  companyWebsite: 'Company Website',
  emailAddress: 'Email Address',
  telephoneNumber: 'Telephone Number',
  bankAccountName: 'Bank Account Name',
  bankAccountNumber: 'Bank Account Number',
  bankAccountType: 'Bank Account Type',
  sortCode: 'Sort Code',
  bankName: 'Bank Name',
  companyNumber: 'Company Number',
  directorsPartnersCount: 'Directors/Partners Count',
  charityNumber: 'Charity Number',
  charityComission: 'Charity Comission',
  merchantCategoryCode: 'Merchant Category Code',
  merchantCategoryCodeDescription: 'Merchant Category Code Description',
  companyAddress: 'Company Address',
  advancedPayments: 'Advanced Payments',
  processingMethods: 'Processing Methods',
  financialInfo: 'Financial Info',
  companyTradeName: 'Company Trade Name'
})
