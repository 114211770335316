import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { Typography, Button } from 'antd'
import translations from './translations'
import styles from './CollapsibleText.scss'

export const CollapsibleText = observer((props: { comment: string }) => {
  const { comment } = props
  const [expanded, setExpanded] = useState(false)
  const showExpandCollapse = comment?.length > 20

  return (
    <>
      <Typography.Paragraph
        ellipsis={
          !expanded && showExpandCollapse
            ? {
                rows: 2,
                expandable: false,
                symbol: translations().dots
              }
            : false
        }
      >
        {comment}
      </Typography.Paragraph>
      {showExpandCollapse && (
        <div className={styles.collapseBtn}>
          <Button
            type='link'
            onClick={e => {
              e.stopPropagation()
              setExpanded(!expanded)
            }}
          >
            {expanded ? translations().collapse : translations().expand}
          </Button>
        </div>
      )}
    </>
  )
})
