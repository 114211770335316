import { Table } from 'antd'
import React from 'react'
import { ProcessingMethodsPropsType } from './props'
import translations from './translations'

export const ProcessingMethods = ({ data }: ProcessingMethodsPropsType) => {
  const dt = data.map((prd, indx) => ({
    key: indx,
    name: prd.name,
    percent: prd.percent,
    description: prd.description
  }))

  const columns = [
    {
      title: translations().name,
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: translations().percent,
      key: 'percent',
      dataIndex: 'percent'
    },
    {
      title: translations().description,
      key: 'description',
      dataIndex: 'description'
    }
  ]
  return (
    <>
      {dt.length > 0 && (
        <>
          <Table columns={columns} dataSource={dt} />
        </>
      )}
    </>
  )
}
