import { DocumentItem } from '~/code/pages/MerchantsManagement/components/ChangeOfSettlementPeriod/models/DocumentItem'
import translations from '../translations'

export const availableDocumentTypes: DocumentItem[] = [
    {
        value: 'bankStatement',
        label: translations().bankStatement
    },
    {
        value: 'processingStatement',
        label: translations().processingStatement
    },
    {
        value: 'other',
        label: translations().other
    }
]

export const activeTerminalStatus = '1'
export const posTerminalPrefix = '03'