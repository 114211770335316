import { inject, injectable } from 'inversify'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { IManageNotificationsStore } from '~/code/pages/Notifications/ManageNotifications/IManageNotificationsStore'
import { message } from 'antd'
import { deactivateNotification, fetchNotifications } from '~/code/stores/NotificationManagementStore/services/fetchers'
import { NotificationType } from '~/code/stores/NotificationManagementStore/models'
import { IPublishNotificationsStore, PublishNotificationStoreSymbol } from '~/code/pages/Notifications'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import translations from './translations'
import { INITIAL_FILTER_DATA } from '~/code/stores/NotificationManagementStore/constants'

@injectable()
export class ManageNotificationsStore implements IManageNotificationsStore {
  isNotificationsLoading: boolean = false
  isDrawerOpened: boolean = false
  isDeactivatingModalOpen: boolean = false
  publishNotificationStore: IPublishNotificationsStore = null
  notifications: NotificationType[] = []
  filter = INITIAL_FILTER_DATA
  total: number = 0

  constructor(
    @inject(PublishNotificationStoreSymbol) publishNotificationStore: IPublishNotificationsStore
  ) {
    makeObservable(this, {
      notifications: observable,
      isDrawerOpened: observable,
      isNotificationsLoading: observable,
      isDeactivatingModalOpen: observable,
      filter: observable,
      isDashboard: computed,
      previewTemplate: computed,
      selectedTemplate: computed,
      notificationSetupForm: computed,
      setDrawer: action.bound,
      setDeactivatingModal: action.bound,
      resetFilter: action.bound
    })

    this.publishNotificationStore = publishNotificationStore
    this.loadNotifications()
  }

  get previewTemplate() {
    return this.publishNotificationStore.previewTemplate
  }

  get notificationSetupForm() {
    return this.publishNotificationStore.notificationSetupForm
  }

  get selectedTemplate() {
    return this.publishNotificationStore.selectedTemplate
  }

  get isDashboard() {
    return this.publishNotificationStore.isDashboard
  }

  public setFilter = (values: any, shouldLoad: boolean = false ) => {
    this.filter = {
      ...this.filter,
      ...values
    }

    if (shouldLoad) this.loadNotifications()
  }

  public resetFilter = () => {
    this.filter = INITIAL_FILTER_DATA
    this.loadNotifications()
  }

  public setDrawer = (value: boolean) => {
    this.isDrawerOpened = value
  }

  public setDeactivatingModal = (value: boolean) => {
    this.isDeactivatingModalOpen = value
  }

  public viewDetails = (id: string) => {
    this.publishNotificationStore.notificationTemplatesStore.getTemplateDetails(id)
      .then(() => this.setDrawer(true))
  }

  public onDrawerClose = () => {
    this.setDrawer(false)
    this.publishNotificationStore.notificationTemplatesStore.setNotificationForm(null)
  }

  public republishNotification = (id: string) => {

    this.publishNotificationStore.notificationTemplatesStore.getTemplateDetails(id)
      .then(() => {
        goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_PUBLISH_NOTIFICATIONS)
        this.publishNotificationStore.setStep(1)
      })
  }

  public goToPublishNotification = () => {
    this.publishNotificationStore.setStep(0)
    goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_PUBLISH_NOTIFICATIONS)
  }

  loadNotifications = async () => {
    const params = {
      ...this.filter,
      notificationTypeId: this.filter.notificationTypeId === 'all' ? undefined : this.filter.notificationTypeId,
      systemId: this.filter.systemId === 'all' ? undefined : this.filter.systemId,
      statusId: this.filter.statusId === 'all' ? undefined : this.filter.statusId,
      notificationDisplayTypeId: this.filter.notificationDisplayTypeId === 'all' ? undefined : this.filter.notificationDisplayTypeId,

    }

    runInAction(() => this.isNotificationsLoading = true)

    try {
      const { result } = await fetchNotifications(params)

      if (result) {
        runInAction(() => {
          this.notifications = result.data
          this.total = result.totalCount
        })
      }

    } catch (error) {
      message.error(error.message)
    } finally {
      runInAction(() => this.isNotificationsLoading = false)
    }
  }

  turnOffNotification = async (id: string) => {
    try {
      const { result } = await deactivateNotification(id)

      if (result) {
        message.success(translations().notificationDeactivated)
        this.setFilter({page: 1, size: 10}, true)
      }
    } catch (error) {
      message.error(error.message)
    }
  }
}
