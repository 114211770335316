import { CaretDownOutlined, CaretUpOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { TrendProps } from './props'
import styles from './Trend.scss'

export const Trend: React.FC<TrendProps> = ({ flag, text, tooltipText }) => {
    return <div className={styles.trend}>
        <span>{text}</span>
        <span className={classNames(styles.flagIcon, styles[flag])}>
            {flag === 'up' && (
                <CaretUpOutlined />
            )}
            {flag === 'down' && (
                <CaretDownOutlined />
            )}
        </span>
        <Tooltip title={tooltipText} placement='topRight'>
            <InfoCircleOutlined className={styles.infoIcon} />
        </Tooltip>
    </div>
}