import React from 'react'
import * as localForage from 'localforage'
import { action, computed, makeObservable, observable } from 'mobx'
import { create, persist } from 'mobx-persist'
import { finishedLoading } from '~/code'
import translations from '../translations'
import { RouterStore } from '~/code/stores/RouterStore'
import { IPageTitleStore } from '~/code/components/PageTitle'
import { AuthStore } from './AuthStore'
import { HandbooksStore } from './HandbooksStore'
import { ProcessesStore } from '../pages/BPM/Onboarding/components/Processes/ProcessesStore'
import { BpmAnalyticsStore } from '../pages/BPM/Onboarding/components/BpmAnalytics/BpmAnalyticsStore'
import { StartApplicationStore } from '../pages/StartApplication/StartApplicationStore'
import { RangePickerStore } from '~/code/stores/RangePickerStore'
import { PartnerProcessesStore } from '~/code/pages/BPM/Onboarding/components/PartnerProcesses'
import { SavedApplicationsStore } from '../pages/SavedApplications/SavedApplicationsStore'
import { TransactionChannelsStore } from './TransactionChannelsStore'
import { ITransactionChannelsStore } from '../pages/TransactionChannels/models'
import { OnboardingProcessStore } from './OnboardingProcessStore'
import { StartProcessStore } from '../pages/StartProcess/StartProcessStore'

const hydrate = create({
  storage: localForage
})

class AppStore implements IPageTitleStore {
  constructor() {
    makeObservable(this, {
      currentPageTitle: observable,
      currentLayout: observable,
      currentPage: observable,
      currentRoute: computed,
      setCurrentPage: action
    })

    this.init()
  }

  public authStore: AuthStore
  public routerStore = new RouterStore(this)
  public onboardingProcessesStore: ProcessesStore
  public onboardingProcessStore: OnboardingProcessStore
  public onboardingBpmAnalyticsStore: BpmAnalyticsStore
  public onboardingPartnerProcessesStore: PartnerProcessesStore
  public handbooksStore: HandbooksStore
  public rangePickerStore: RangePickerStore
  public startApplicationStore: StartApplicationStore
  public savedApplicationsStore: SavedApplicationsStore
  public transactionChannelsStore: ITransactionChannelsStore
  public startProcessStore: StartProcessStore

  public async init() {
    this.authStore = new AuthStore(this)

    if (this.authStore.isAuthenticated) {
      this.initStores()
    }

    Promise.all([hydrate('appStore', this)]).then(() => {
      translations() // need to initialize, otherwise other stores won't be able to access on load
      finishedLoading()
    })
  }

  private initStores() {
    this.handbooksStore = new HandbooksStore()
    this.rangePickerStore = new RangePickerStore()
    this.onboardingProcessesStore = new ProcessesStore(this.handbooksStore, this.rangePickerStore)
    this.onboardingProcessStore = new OnboardingProcessStore()
    this.onboardingBpmAnalyticsStore = new BpmAnalyticsStore(this.handbooksStore, this.rangePickerStore)
    this.startApplicationStore = new StartApplicationStore(this)
    this.savedApplicationsStore = new SavedApplicationsStore(this.handbooksStore)
    this.onboardingPartnerProcessesStore = new PartnerProcessesStore()
    this.transactionChannelsStore = new TransactionChannelsStore()
    this.startProcessStore = new StartProcessStore(this)
  }

  @persist
  public username: string = ''

  public currentPageTitle: string = ''

  public currentLayout: ({ children }: { children?: any }) => React.JSX.Element = () => null

  public currentPage: () => React.JSX.Element = () => null

  public setCurrentPage(
    currentPage: () => React.JSX.Element,
    currentLayout: ({ children }: { children?: any }) => React.JSX.Element,
    pageTitle: string
  ) {
    this.currentLayout = currentLayout
    this.currentPage = currentPage
    this.currentPageTitle = pageTitle
  }

  public onLogin() {
    this.initStores()
    this.routerStore.onLogin()
  }

  public onLogout() {
    this.routerStore.onLogout()
  }

  public get currentRoute() {
    return this.routerStore.currentRoute
  }
}

const AppStoreInstance = new AppStore()

export { AppStoreInstance as AppStore }
