import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    posProductType: string
    ecomProductType: string
    settlementPeriod: string
    documents: string
    bankStatement: string
    processingStatement: string
    other: string
    upload: string
    changeOfSettlementPeriod: string
    comment: string
    currentSettlementBusinessDays: string
    newSettlementBusinessDays: string
    submit: string
    required: string
    days: string
    documentType: string
    noPos: string
    noEcom: string
    documentsRequired: string
    provideInfo: string
    provideAdditionalInfo: string
    change: string
    businessDays: string
}>()