import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  title: string
  password: string
  passwordRequired: string
  passwordWrongFormat: string
  confirmPassword: string
  confirmPasswordRequired: string
  passwordsDoNotMatch: string
  set: string
  cancel: string
}>()

