import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    fields: string
    web: string
    mobile: string
    notificationDetails: string
    templateName: string
    system: string
    displayType: string
    level: string
    contentType: string
    templateTitle: string
    templateDescription: string
    buttonUrl: string
    buttonName: string
    back: string
    save: string
    preview: string
    name: string
    contentArea: string
    type: string
    description: string
    title: string
}>()

