import React from 'react'
import { observer } from 'mobx-react'
import moment from 'moment-timezone'
import { Card, Table, Drawer, Grid, Form } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { minDrawerWidth, standardDrawerWidth } from '../../constants'
import { BPMProcessFilterDossierV2 } from '../shared/BPMProcessDossierV2/components'
import { ClosureOfMerchantProcessDetails } from './components/ClosureOfMerchantProcessDetails'
import { IClosureOfMerchantStore } from './IClosureOfMerchantStore'
import { columns, closureType } from './constants'
import translations from './translations'
import styles from './ClosureOfMerchant.scss'

export const ClosureOfMerchantStoreSymbol = Symbol('ClosureOfMerchantStore')
export const ClosureOfMerchantRangePickerStoreSymbol = Symbol('ClosureOfMerchantRangePickerStore')
export const ClosureOfMerchantMerchantSelectStoreSymbol = Symbol('ClosureOfMerchantMerchantSelectStore')

export const ClosureOfMerchant = observer(() => {
  const screens = Grid.useBreakpoint()
  const store = useInjection<IClosureOfMerchantStore>(ClosureOfMerchantStoreSymbol)
  const [form] = Form.useForm()

  React.useEffect(() => {
    store.init()
  }, [])

  return (
    <>
      <BPMProcessFilterDossierV2
        BPMProcessesStoreSymbol={ClosureOfMerchantStoreSymbol}
        BPMProcessesRangePickerStoreSymbol={ClosureOfMerchantRangePickerStoreSymbol}
        BPMProcessesMerchantSelectStoreSymbol={ClosureOfMerchantMerchantSelectStoreSymbol}
      />
      <Card>
        <Table
          loading={store.isProcessesLoading}
          columns={columns}
          dataSource={store.dataSource}
          scroll={{ x: 200 }}
          rowClassName={styles.row}
          onRow={record => {
            return {
              onClick: () => {
                if (!record?.endDate && record?.startDate) {
                  const availableDateFrom = moment(__CANCEL_MERCHANT_CLOSURE_AVAILABLE_DATE__, 'YYYY-MM-DD')
                  const procStartDate = moment(record?.startDate, 'YYYY-MM-DD')
                  if (procStartDate.isValid() && procStartDate.isAfter(availableDateFrom)) store.showInfoDrawer(record)
                }
              }
            }
          }}
          pagination={{
            total: store.total,
            pageSize: store.pageSize,
            current: store.currentPage,
            onChange: (page, pageSize) => store.loadProcesses({ page, pageSize }),
            size: 'small'
          }}
        />
      </Card>
      <Drawer
        title={
          store.selectedProcess?.processTypeCode === closureType.storeClose
            ? translations().storeCloseHeaderText
            : translations().merchantCloseHeaderText
        }
        placement='right'
        closable={false}
        onClose={() => {
          form.resetFields()
          store.closeInfoDrawer()
        }}
        open={store.isShowInfoDrawer}
        width={screens.xs ? minDrawerWidth : standardDrawerWidth}
      >
        <ClosureOfMerchantProcessDetails form={form} />
      </Drawer>
    </>
  )
})
