import { getWithAuth } from '~/code/services'
import { TeamSummaryType, TerminalIssueReportType, TerminalType, TotalSummaryType } from '../models'

type PeriodParamsType = {
    from: string
    to: string
}

type SummaryParamsType = PeriodParamsType & {
    terminalType?: TerminalType
}

type TerminalIssueParamsType = PeriodParamsType & {
    acquisitionChannel?: string
    productType?: string
    approvalType?: string
    segmentCategory?: string
    segmentSubCategory?: string
}

export const fetchTotalSummary = (params: SummaryParamsType) =>
    getWithAuth<TotalSummaryType>('/api/bpm/reports/applications/total-summary', params)

export const fetchTeamSummary = (params: SummaryParamsType) =>
    getWithAuth<TeamSummaryType[]>('/api/bpm/reports/applications/team-summary', params)

export const fetchTerminalIssueReport = (params: TerminalIssueParamsType) =>
    getWithAuth<TerminalIssueReportType[]>('/api/processes-api/bpm/reports/terminals/issued-stat', params)