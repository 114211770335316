import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  salesForceCaseId: string
  search: string
  required: string
  midsAreNotEqual: string
  midsAreNotEqualInstruction: string
  noSalesForceDataFound: string
  noSalesForceDataFoundInstruction: string
  salesForceDataFound: string
  salesForceDataFoundInstruction: string
}>()
