import React from 'react'
import { observer } from 'mobx-react'
import { Form, Radio, Select } from 'antd'
import { FormSubgroupTitle } from '../shared/FormSubgroupTitle'
import { CompanyInformationForm } from './components'
import translations from './translations'
import { CompanyNameFormProps } from './props'
import styles from './CompanyNameForm.scss'

export const CompanyNameForm = observer((props: CompanyNameFormProps) => {
  const { store, form } = props
  const { company, companies } = store
  const { getFieldValue, resetFields } = form

  return (
    <div>
      <FormSubgroupTitle>{translations().title}</FormSubgroupTitle>

      <Form.Item name='companyType' rules={[{ required: true, message: translations().companyTypeRequired }]}>
        <Radio.Group
          className={styles.radioGroup}
          onChange={e => {
            store.reset()
            resetFields(['companyNumber'])
          }}
        >
          <Radio value={'sole-trader'}>{translations().soleTrader}</Radio>
          <Radio value={'company'}>{translations().company}</Radio>
        </Radio.Group>
      </Form.Item>

      <div className={styles.companyContainer}>
        <Form.Item noStyle shouldUpdate={(prevVal, curVal) => prevVal.companyType !== curVal.companyType}>
          {() =>
            getFieldValue('companyType') === 'company' && (
              <Form.Item
                name='companyNumber'
                label={
                  <>
                    {translations().companyNameFormLabel}
                    <span className={styles.subLabel}>{translations().companyNameFormSubLabel}</span>
                  </>
                }
                rules={[
                  { required: true, message: translations().companyNameFormRequired },
                  {
                    validator: async (rule, value) => {
                      if (company && company.companyStatus.toLowerCase() !== 'active') {
                        throw new Error(translations().companyIsNotActive(store.company.companyStatus))
                      }
                    }
                  }
                ]}
                validateFirst={true}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Select
                  showSearch
                  allowClear
                  suffixIcon={false}
                  filterOption={false}
                  placeholder={translations().typeSomethingToSearch}
                  onSearch={(text: string) => {
                    if (text) {
                      store.searchCompanies(text.trim())
                    }
                  }}
                  onSelect={(value: string) => store.selectComapny(value)}
                  onChange={(value: string) => {
                    if (!value) {
                      store.reset()
                    }
                  }}
                >
                  {companies &&
                    companies.map(o => (
                      <Select.Option key={o.value} value={o.value}>
                        {o.label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )
          }
        </Form.Item>

        {company && (
          <>
            <CompanyInformationForm
              companyNumber={company.companyNumber}
              address={company.addressSnippet}
              registrationDate={company.dateOfCreation.toString()}
            />

            <div className={styles.companyNameFormDescription}>{translations().companyNameFormDescription}</div>
          </>
        )}
      </div>
    </div>
  )
})
