import translations from './translations'

translations.add('en', {
  acquiringServicesFee: 'Acquiring Services Fees',
  icPlusFeeStructure: '',
  transactionFees: 'Transaction Fees',
  processingFee: 'IC++ (Processing Fee)',
  description: 'Description',
  debitConsumer: 'Visa and MasterCard debit cards (applies to all Consumer, Intra EEA, and International issued debit cards)',
  creditConsumer: 'Visa and MasterCard credit cards (applies to all Consumer, Intra EEA, and International issued credit cards)',
  debitCorporate: 'Visa and MasterCard debit cards (applies to all Corporate, Intra EEA, and International issued debit cards)',
  creditCorporate: 'Visa and MasterCard credit cards (applies to all Corporate, Intra EEA, and International issued credit cards)',
  debitCreditDescription: 'Charge per Transaction. Interchange Fees & Scheme fees apply in addition to the Processing Fees',
  interchangeFee: 'Interchange',
  schemeFee: 'Scheme',
  dnaStandardFee: 'DNA fee',
  headlineRate: 'Headline rate (%)',
  totalFee: 'Total fee'
})
