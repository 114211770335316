import translations from './translations'
import React from 'react'

translations.add('en', {
  checkTheData: 'Check the data',
  pleaseCheckBeforeSubmit: 'Please check and modify the information before submitting.',
  backToForm: 'Back to the form',
  submit: 'Submit',
  applicationHasFollowingErrors: 'The application has the following errors',
  generalInformationNotCompleted: 'General information is NOT completed',
  shareholdersNotCompleted: 'Shareholders is NOT completed',
  directorsNotCompleted: 'Directors is NOT completed',
  authorizedSignatoriesNotCompleted: 'Authorized Signatories is NOT completed',
  businessOwnerNotCompleted: 'Business owners is NOT completed',
  supportingDocumentsNotCompleted: 'Supporting Documents is NOT completed',
  submissionFailed: 'Submission Failed',
  applicationSubmittedSuccessfully: 'Application has been submitted successfully',
  applicationID: 'Application ID',
  inOrderToTrackApplication:
    'In order to track the status of the application, please go to BPM Reports -> Onboarding Processes -> Processes',
  newApplication: 'New Application',
  onceYouClickSubmit: 'Once you click on "Submit", system will start data upload to launch the process.',
  pleaseWait2Mins: 'Please wait for about 2 minutes and close this browser tab.',
  warning: 'Warning',
  reviewAndConfirm: 'Please review and confirm application details',
  carefullyReview: 'Please carefully review the full application before submitting it',
  companyInfo: 'Company info',
  companyDetails: (companyName: string, companyEmail: string, merchantEmail: string) => (
    <>
      Company name: {companyName}, <br />
      Company email: {companyEmail}, <br />
      Merchant portal login email: {merchantEmail}
    </>
  ),
  bankInfo: 'Bank details',
  bankDetails: (bankName: string, accNum: string, sortCode: string) => (
    <>
      Bank name: {bankName}, <br />
      Account number: {accNum}, <br />
      Sort code: {sortCode}
    </>
  ),
  individualInfo: 'Individual details',
  individualDetails: (name: string, bd: string, address: string) => <>{`${name}, ${bd}, ${address}`}</>,

  storesInfo: 'Stores and products',
  storesDetails: (name: string, address: string, settlementAcc: string, directDebitAcc: string, products: string) => (
    <>
      Store name: {name}, <br />
      Address: {address}, <br />
      Settlement account: {settlementAcc}, <br />
      Direct debit account: {directDebitAcc}, <br />
      Products: {products}
    </>
  ),
  financialInfo: 'Financial information',
  financialDetails: (name: string, annual: string, average: string, highest: string) => (
    <>
      {name}, <br />
      Annual CARD turnover: {annual}, <br />
      Average transaction value: {average}, <br />
      Highest transaction value: {highest}, <br />
    </>
  ),
  pricingInfo: 'Pricing',
  pricingDetails: (prodName: string, tariffType: string) => (
    <>
      {prodName === 'pos' && `POS payments: ${tariffType === 'ic+' ? 'IC ++' : 'Blended'}`}
      {prodName === 'ecom' && `ECOM payments: ${tariffType === 'ic+' ? 'IC ++' : 'Blended'}`}
    </>
  ),
  docuSignInfo: 'Merchant service agreement',
  docuSignDetails: (status: boolean) => <>Status: {status ? 'Sent to sign' : 'Unsigned'}</>,
  documentsInfo: 'Documents',
  documentsDetails: (status: boolean) => <></>,
  submitted: 'Application submitted',
  submittedForReview: 'Onboarding application is now submitted for the review.',
  statusWillBeUpdated:
    'Application statuses will be automatically updated and reflected on Salesforce and Dashboard Reports',
  closeTheWindow: 'You can close this window',
  salesForceLink: 'Salesforce Opportunity Link',
  dashboardReportsLink: 'Dashboard Reports',
  closeAndNew: 'Close and new',
  close: 'Close',
  currentStatus: (status: string) => {
    if (status === 'wait') {
      return 'Current status: Sent, pending signature'
    } else if (status === 'success') {
      return 'Current status: Signed'
    } else {
      return 'Current status: Ready for sending'
    }
  }
})
