import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  home: string
  dashboard: string
  transactions: string
  dnaAcquiring: string
  optomanyCheckoutV3: string
  analytics: string
  analyticsTotal: string
  analyticsPos: string
  ecomMonitoring: string
  monitoringPos: string
  monitoringPosTest: string
  monitoringPosV2: string
  monitoringPosAmex: string
  bmpReports: string
  submittedRequests: string
  onboarding: string
  partnerOnboarding: string
  applications: string
  newApplication: string
  incompleteApplications: string
  statistics: string
  bpmProcesses: string
  merchantManagement: string
  billing: string
  invoices: string
  netSuiteInvoices: string
  profile: string
  settlements: string
  statement: string
  statementTest: string
  payments: string
  transactionChannels: string
  riskLimits: string
  settlementPeriod: string
  terminalIssue: string
  motoVt: string
  changeBankDetailsDossierV2: string
  closureOfMerchant: string
  closureOfTerminal: string
  teammates: string
  exports: string
  newProcess: string
  portalAccess: string
  partnerDashboardAccess: string
  chargebacks: string
  settlementType: string
  additionalStoreProcesses: string
  settlementStatus: string
  taskList: string
  adjustmentRequests: string
  editTariffsRequests: string
  partnerCommissions: string
  notificationsManagement: string
  manageTemplates: string
  manageNotifications: string
}>()
