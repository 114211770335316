import translations from './translations'
import React from 'react'

translations.add('en', {
    hour: 'Hour',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    year: 'Year'
})
