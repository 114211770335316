import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Table, Button, Modal, Form, Alert, InputNumber, Input } from 'antd'
import { useInjection } from 'dna-react-ioc'
import { IBalancesStore } from './IBalancesStore'
import { columnsBalances } from './constants/constants'
import translations from './translations'
import styles from './Balances.scss'

const { TextArea } = Input
export const BalancesStoreSymbol = Symbol('BalancesStore')
export const Balances: React.FC = observer(() => {
  const store = useInjection<IBalancesStore>(BalancesStoreSymbol)
  const [form] = Form.useForm()

  useEffect(() => {
    store.reset()
    if (store.isCompanyFullDossierV2Exists) store.loadBalances()
  }, [store.companyFullDossierV2])

  const onFinish = async () => {
    await form.validateFields()
    await store.startAdjustementProcess(form)
    form.resetFields()
  }

  return (
    <>
      <Table
        scroll={{ x: 200 }}
        loading={store.isBalancesLoading}
        pagination={false}
        dataSource={store.balancesResult}
        columns={columnsBalances(store.handleAdjustmentModalStatus, store.isSubsidiary)}
      />
      <Modal
        title={translations().adjustment}
        open={store.isAdjustmentModalOpen}
        onOk={() => form.submit()}
        okText={translations().submit}
        onCancel={() => {
          form.resetFields()
          store.handleAdjustmentModalStatus(false, '')
        }}
        okButtonProps={{
          loading: store.isAdjustmentStartLoading,
          disabled: !store.midsAreEqual
        }}
      >
        <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFinish}>
          <Form.Item
            name='sfCaseId'
            label={translations().sfCaseId}
            rules={[{ required: true, message: translations().required }]}
            validateTrigger={['onBlur']}
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type='primary'
              loading={store.isSFDataLoading}
              onClick={() => store.loadSalesForceData(form.getFieldValue('sfCaseId'), form)}
            >
              {translations().search}
            </Button>
          </Form.Item>
          {!store.midsAreEqual && (
            <div className={styles.alertMarginButtom}>
              <Alert
                message={translations().midsAreNotEqual}
                description={translations().midsAreNotEqualInstruction}
                type='warning'
                showIcon
              />
            </div>
          )}
          {store.noSFDataFound && (
            <div className={styles.alertMarginButtom}>
              <Alert
                message={translations().noSFDataFound}
                description={translations().noSFDataFoundInstruction}
                type='warning'
                showIcon
              />
            </div>
          )}
          <Form.Item
            name='adjustmentType'
            label={translations().adjustmentType}
            rules={[{ required: true, message: translations().required }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label={translations().amount}
            name='amount'
            rules={[{ required: true, message: translations().required }]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <InputNumber disabled min={0} />
          </Form.Item>
          <Form.Item
            name='currency'
            label={translations().currency}
            rules={[{ required: true, message: translations().required }]}
            validateTrigger={['onBlur']}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item name='description' label={translations().description}>
            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount maxLength={150} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
})
