export interface OnboardingBusinessModelQuestions {
  seasonalBusiness: {
    isTrue: boolean
    quarter1: string | number
    quarter2: string | number
    quarter3: string | number
    quarter4: string | number
  }
  face2face: {
    isTrue: boolean
    deliveryTime: string
    courierServiceUsed: boolean
  }
}

export const onboardingBusinessModelQuestionsValues: OnboardingBusinessModelQuestions =
  {
    seasonalBusiness: {
      isTrue: false,
      quarter1: 0,
      quarter2: 0,
      quarter3: 0,
      quarter4: 0
    },
    face2face: {
      isTrue: false,
      deliveryTime: '',
      courierServiceUsed: false
    }
  }
