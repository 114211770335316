import { TranslationBuilder } from '~/code/components/Translation'
import { ReactNode } from 'react'

export default TranslationBuilder.create<{
  obRequestWaiting: ReactNode
  note: string
  note2: string
  note3: string
  verifResult: (status: string) => ReactNode
  ifLinkNotOpened: string
  refreshStatus: string
  obRequestStatus: string
  obrRequestDetailsInfo: (sortCode: string, accNum: string, accName: string, overallScore: number) => ReactNode
  obrRequestAuthorised: string
  responseData: string
  obrProceedWithDocs: string
  commonInfo: string
  companyName: string
  companyNumber: string
  documentType: string
  upload: string
  complete: string
  customVerification: string
  account: string
  obrMatchText: string
  uploadBankStatement: string
  terminate: string
  comment: string
  terminateConfirmation: string
  comments: string
}>()
