import { message } from 'antd'
import { action, computed, makeObservable, observable } from 'mobx'
import { inject, injectable } from 'inversify'
import { STORE_TYPES } from '~/code/constants/StoreTypes'
import { SubmittedRequestsStore } from './SubmittedRequestsStore'
import { AppStore } from '~/code/stores/AppStore'
import { CompleteRiskReviewParams } from '~/code/pages/BPMProcesses/Requests/models'
import { completeRiskReview } from '~/code/pages/BPMProcesses/Requests/services'
import translations from './translations'
import { ChangeRiskLimitsStore } from '~/code/stores/MerchantsManagementStore/ChangeRiskLimitsStore'
import { hasEmptyProperty } from '~/code/pages/MerchantsManagement/components/RiskLimits/components/ChangeRiskLimitsModal/services'
import { SubmittedRequestsStatus } from './models'
import { ChangeRiskLimitsStoreSymbol } from '~/code/pages/MerchantsManagement/components/RiskLimits/components/ChangeRiskLimitsModal/ChangeRiskLimitsModal'


@injectable()
export class DrawerTitleStore {
    changeRiskLimitsStore: ChangeRiskLimitsStore
    submittedRequestsStore: SubmittedRequestsStore

    public decision: SubmittedRequestsStatus = null
    constructor(
        @inject(ChangeRiskLimitsStoreSymbol) changeRiskLimitsStore: ChangeRiskLimitsStore,
        @inject(STORE_TYPES.SubmittedRequestsStore) submittedRequestsStore: SubmittedRequestsStore
    ) {
        this.changeRiskLimitsStore = changeRiskLimitsStore
        this.submittedRequestsStore = submittedRequestsStore

        makeObservable(this, {
            decision: observable,
            selectedProcessId: computed,
            isActiveConfirm: computed,
            isRejectDecision: computed,
            isApproveDecision: computed,
            finishRiskReview: action,
            setDecision: action
        })
    }

    public get isRejectDecision() {
        return this.decision === SubmittedRequestsStatus.REJECTED
    }

    public get isApproveDecision() {
        return this.decision === SubmittedRequestsStatus.APPROVED
    }

    async finishRiskReview(params: CompleteRiskReviewParams) {
        this.changeRiskLimitsStore.startLoading()
        const { status, error } = await completeRiskReview(this.selectedProcessId, {
            comment: params.comment,
            decision: params.decision,
            reviewerEmail: AppStore.authStore.email,
            ...(params.limits && { limits: params.limits })
        })
        this.changeRiskLimitsStore.stopLoading()
        if (error || status !== 200) {
            message.error(error.message)
            return false
        }
        message.success(translations().completeRiskReviewSuccess)
        return true
    }

    public get isLoading(): boolean {
        return this.submittedRequestsStore?.isProcessDetailsLoading
    }

    public get hasError(): boolean {
        return this.submittedRequestsStore?.hasProcessDetailsLoadingError
    }

    public get isActiveConfirm(): boolean {
        const store = this.changeRiskLimitsStore
        return !!store.initialValues &&
            !!store.currentValues &&
            !!store.currentValues?.comment &&
            (this.isApproveDecision ? !hasEmptyProperty(store.currentValues) : true)
    }


    public setDecision(decision: SubmittedRequestsStatus) {
        this.decision = decision
    }

    public get selectedProcessId() {
        return this.submittedRequestsStore?.selectedProcessId
    }

    public get processDetails() {
        return this.submittedRequestsStore?.processDetails
    }

    public reset() {
        this.submittedRequestsStore.init()
    }
}
