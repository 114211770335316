import { Moment } from 'moment-timezone'
import numeral from 'numeral'
import { Months } from '~/code/constants/Months'
import { AnalyticsYearsComparisonResponse } from '~/code/models/analytics/AnalyticsYearsComparisonResponse'
import { YearsComparisonData } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/YearsComparison/models'
import { cardTypeBackgroundColors, restCardTypeBackgroundColors } from '../constants/colors-icons'
import { IntervalType } from '../models/IntervalType'
import translations from '~/code/translations'
import { ApprovalRateData, ApprovalRateSummaryGroup } from '~/code/models/analytics/AnalyticsApprovalRateSummary'

export const generateBackgroundColorsForCardTypes = (labels: string[]) => {
    let restColorIndex = 0
    return labels.map(label => {
        return cardTypeBackgroundColors[label.trim().toLowerCase()] || restCardTypeBackgroundColors[restColorIndex++]
    })
}

export const sumSummaryRowData = (...args) => {
    return args.reduce((previousValue, currentValue) => {
        let result = [...previousValue]
        currentValue.forEach(item => {
            let found = previousValue.find(i => i.currency === item.currency)
            if (found) {
                found = {
                    ...found,
                    amount: found.amount + item.amount,
                    count: found.count + item.count
                }
                result = [...result.filter(i => i.currency !== item.currency), found]
            } else {
                result.push(item)
            }
        })

        return result
    }, [])
}

export const generateYearsData = () => {
    const result = []
    for ( let i = 1; i <= 12; i++) {
        result.push({month: i, amount: 0, count: 0})
    }
    return result
}

export const groupByMonth = (array: {month, amount, count}[]) => {
    return generateYearsData().map(item => {
        array.forEach(i => {
            if (item.month === i.month){
                item.amount = item.amount + i.amount
                item.count = item.count + i.count
            }
        })
        return item
    })
}

export const getYearsComparisonData = (
    year1: number,
    year2: number,
    datas: AnalyticsYearsComparisonResponse[]
) => {
    const months = Object.values(Months).map(value => translations().months[value])
    const statuses = ['all', 'successful', 'failed', 'other']

    const groupedByStatusAnYear = statuses.reduce((prev, status) => {

        const [year1Data, year2Data] = [year1, year2].map((year) => {
            return datas.reduce((prevData, data) => [
                ...prevData,
                ...(data[status]?.filter(item => item.year === year) || [])
            ], [])
        })
        
        return {
            ...prev,
            [status]: {
                [year1]: groupByMonth(year1Data),
                [year2]: groupByMonth(year2Data)
            }
        }
    }, {})

    return ['amount', 'count'].reduce((prevPropData, prop) => ({
        ...prevPropData,
        [prop]: statuses.reduce((prev, status) => {
            if (!groupedByStatusAnYear[status]) return prev
            return {
                ...prev,
                [status]: {
                    labels: months,
                    datasets: [
                        {
                            label: `${year1}`,
                            backgroundColor: 'rgba(24, 143, 255, 0.35)',
                            data: groupedByStatusAnYear[status][year1].map(item => item[prop]),
                            borderWidth : 1
                        },
                        {
                            label: `${year2}`,
                            backgroundColor: 'rgba(24, 143, 255, 0.7)',
                            data: groupedByStatusAnYear[status][year2].map(item => item[prop]),
                            borderWidth : 1
                        }
                    ]
                }
            }
        }, {})
    }), {}) as YearsComparisonData
}

export const generateDetailsObject = () => {
    return {
        amountAllData: [],
        amountSuccessfulData: [],
        amountFailedData: [],
        amountOtherData: [],
        amountAllCurrentPage: 1,
        amountAllPageSize: 20,
        amountAllTotal: 0,
        amountSuccessfulCurrentPage: 1,
        amountSuccessfulPageSize: 20,
        amountSuccessfulTotal: 0,
        amountFailedCurrentPage: 1,
        amountFailedPageSize: 20,
        amountFailedTotal: 0,
        amountOtherCurrentPage: 1,
        amountOtherPageSize: 20,
        amountOtherTotal: 0,
        isLoadingAmountAllData: false,
        hasLoadedAmountAllData: false,
        isLoadingAmountSuccessfulData: false,
        hasLoadedAmountSuccessfulData: false,
        isLoadingAmountFailedData: false,
        hasLoadedAmountFailedData: false,
        isLoadingAmountOtherData: false,
        hasLoadedAmountOtherData: false,

        countAllData: [],
        countSuccessfulData: [],
        countFailedData: [],
        countOtherData: [],
        countAllCurrentPage: 1,
        countAllPageSize: 20,
        countAllTotal: 0,
        countSuccessfulCurrentPage: 1,
        countSuccessfulPageSize: 20,
        countSuccessfulTotal: 0,
        countFailedCurrentPage: 1,
        countFailedPageSize: 20,
        countFailedTotal: 0,
        countOtherCurrentPage: 1,
        countOtherPageSize: 20,
        countOtherTotal: 0,
        isLoadingCountAllData: false,
        hasLoadedCountAllData: false,
        isLoadingCountSuccessfulData: false,
        hasLoadedCountSuccessfulData: false,
        isLoadingCountFailedData: false,
        hasLoadedCountFailedData: false,
        isLoadingCountOtherData: false,
        hasLoadedCountOtherData: false
    }
}

export const resetCurrentPageInDetailsData = (detailsData) => {
    return {
        ...detailsData,
        amountAllCurrentPage: 1,
        amountSuccessfulCurrentPage: 1,
        amountFailedCurrentPage: 1,
        amountOtherCurrentPage: 1,

        countAllCurrentPage: 1,
        countSuccessfulCurrentPage: 1,
        countFailedCurrentPage: 1,
        countOtherCurrentPage: 1
    }
}

export const resetDataHasBeenLoadedFlags = (detailsData) => {
    return {
        ...detailsData,
        hasLoadedAmountAllData: false,
        hasLoadedAmountSuccessfulData: false,
        hasLoadedAmountFailedData: false,
        hasLoadedAmountOtherData: false,

        hasLoadedCountAllData: false,
        hasLoadedCountSuccessfulData: false,
        hasLoadedCountFailedData: false,
        hasLoadedCountOtherData: false
    }
}

export const detailsDataToTabData = (key, detailsData) => {
    return {
        key,
        amountTableData: {
            all: {
                loading: detailsData.isLoadingAmountAllData,
                data: detailsData.amountAllData,
                currentPage: detailsData.amountAllCurrentPage,
                pageSize: detailsData.amountAllPageSize,
                total: detailsData.amountAllTotal
            },
            successful: {
                loading: detailsData.isLoadingAmountSuccessfulData,
                data: detailsData.amountSuccessfulData,
                currentPage: detailsData.amountSuccessfulCurrentPage,
                pageSize: detailsData.amountSuccessfulPageSize,
                total: detailsData.amountSuccessfulTotal
            },
            failed: {
                loading: detailsData.isLoadingAmountFailedData,
                data: detailsData.amountFailedData,
                currentPage: detailsData.amountFailedCurrentPage,
                pageSize: detailsData.amountFailedPageSize,
                total: detailsData.amountFailedTotal
            },
            other: {
                loading: detailsData.isLoadingAmountOtherData,
                data: detailsData.amountOtherData,
                currentPage: detailsData.amountOtherCurrentPage,
                pageSize: detailsData.amountOtherPageSize,
                total: detailsData.amountOtherTotal
            }
        },
        countTableData: {
            all: {
                loading: detailsData.isLoadingCountAllData,
                data: detailsData.countAllData,
                currentPage: detailsData.countAllCurrentPage,
                pageSize: detailsData.countAllPageSize,
                total: detailsData.countAllTotal
            },
            successful: {
                loading: detailsData.isLoadingCountSuccessfulData,
                data: detailsData.countSuccessfulData,
                currentPage: detailsData.countSuccessfulCurrentPage,
                pageSize: detailsData.countSuccessfulPageSize,
                total: detailsData.countSuccessfulTotal
            },
            failed: {
                loading: detailsData.isLoadingCountFailedData,
                data: detailsData.countFailedData,
                currentPage: detailsData.countFailedCurrentPage,
                pageSize: detailsData.countFailedPageSize,
                total: detailsData.countFailedTotal
            },
            other: {
                loading: detailsData.isLoadingCountOtherData,
                data: detailsData.countOtherData,
                currentPage: detailsData.countOtherCurrentPage,
                pageSize: detailsData.countOtherPageSize,
                total: detailsData.countOtherTotal
            }
        }
    }
}

export const generateAmountTableColumns = () => {
    return [
        {
            title: 'Order',
            dataIndex: 'order',
            key: 'name',
            width: '10%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '50%'
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: '20%'
        },
        {
            title: 'Average',
            dataIndex: 'average',
            key: 'average',
            width: '20%'
        }
    ]
}

export const generateMCCAmountTableColumns = () => {
    return [
        {
            title: 'Order',
            dataIndex: 'order',
            key: 'name',
            width: '10%'
        },
        {
            title: 'MCC',
            dataIndex: 'mcc',
            key: 'mcc',
            width: '15%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '45%'
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: '15%'
        },
        {
            title: 'Average',
            dataIndex: 'average',
            key: 'average',
            width: '15%'
        }
    ]
}

export const generateCountTableColumns = () => {
    return [
        {
            title: 'Order',
            dataIndex: 'order',
            key: 'name',
            width: '10%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '60%'
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            width: '30%'
        }
    ]
}

export const generateMCCCountTableColumns = () => {
    return [
        {
            title: 'Order',
            dataIndex: 'order',
            key: 'name',
            width: '10%'
        },
        {
            title: 'MCC',
            dataIndex: 'mcc',
            key: 'mcc',
            width: '15%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '60%'
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            width: '15%'
        }
    ]
}

export const getRequestUniqueFieldName = (detailsType: DetailsType) => {
    switch (detailsType) {
        case 'merchants' : {
            return 'merchant'
        }
        case 'mcc' : {
            return 'mccDescription'
        }
        case 'issuingBanks' : {
            return 'issuer'
        }
        case 'cardTypes' : {
            return 'cardScheme'
        }
        case 'paymentMethods' : {
            return 'paymentMethod'
        }
        case 'acquisitionChannels' : {
            return 'acquisitionChannel'
        }
    }
}

export const capitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

export const filterIntervalOptions = (startDate: Moment, endDate: Moment): IntervalType[] => {
    if (!startDate || !endDate) return []

    const diffDays = endDate.diff(startDate, 'days')
    let intervalTypes: IntervalType[]

    if (diffDays < 1) {
        intervalTypes = ['hour']
    } else if (diffDays >= 1 && diffDays < 8) {
        intervalTypes = ['day']
    } else if (diffDays >= 8 && diffDays < 32) {
        intervalTypes = ['day', 'week']
    } else if (diffDays >= 32 && diffDays < 183) {
        intervalTypes = ['week', 'month']
    } else if (diffDays >= 183 && diffDays < 365 ) {
        intervalTypes = ['month']
    } else if (diffDays >= 365 && diffDays < 730) {
        intervalTypes = ['month', 'year']
    } else {
        intervalTypes = ['year']
    }

    return intervalTypes
}

export const processApprovalRates = (approvalRates: ApprovalRateSummaryGroup, currencySymbol: string): ApprovalRateData => {
    const failedRates = {
        customer: approvalRates.failed.find(v => v.reason === 'customer'),
        issuer: approvalRates.failed.find(v => v.reason === 'issuer'),
        other: approvalRates.failed.find(v => v.reason === 'other')
    }

    return {
        amount: {
            total: `${currencySymbol}${numeral(approvalRates.total.amount).format('0,0.00')}`,
            successful: {
                value: `${currencySymbol}${numeral(approvalRates.successful.amount).format('0,0.00')}`,
                percentage: `${numeral((approvalRates.successful.amount * 100) / approvalRates.total.amount).format('0,0.00')}%`
            },
            failed: [
                {
                    reason: 'customer',
                    value: `${currencySymbol}${numeral(failedRates.customer.amount).format('0,0.00')}`,
                    percentage: `${numeral((failedRates.customer.amount * 100) / approvalRates.total.amount).format('0,0.00')}%`
                },
                {
                    reason: 'issuer',
                    value: `${currencySymbol}${numeral(failedRates.issuer.amount).format('0,0.00')}`,
                    percentage: `${numeral((failedRates.issuer.amount * 100) / approvalRates.total.amount).format('0,0.00')}%`
                },
                {
                    reason: 'other',
                    value: `${currencySymbol}${numeral(failedRates.other.amount).format('0,0.00')}`,
                    percentage: `${numeral((failedRates.other.amount * 100) / approvalRates.total.amount).format('0,0.00')}%`
                }
            ],
            other: approvalRates.other ? {
                value: `${currencySymbol}${numeral(approvalRates.other.amount).format('0,0.00')}`,
                percentage: `${numeral((approvalRates.other.amount * 100) / approvalRates.total.amount).format('0,0.00')}%`
            } : null
        },
        count: {
            total: `${numeral(approvalRates.total.count).format('0,0')}`,
            successful: {
                value: `${numeral(approvalRates.successful.count).format('0,0')}`,
                percentage: `${numeral((approvalRates.successful.count * 100) / approvalRates.total.count).format('0,0.00')}%`
            },
            failed: [
                {
                    reason: 'customer',
                    value: `${numeral(failedRates.customer.count).format('0,0')}`,
                    percentage: `${numeral((failedRates.customer.count * 100) / approvalRates.total.count).format('0,0.00')}%`
                },
                {
                    reason: 'issuer',
                    value: `${numeral(failedRates.issuer.count).format('0,0')}`,
                    percentage: `${numeral((failedRates.issuer.count * 100) / approvalRates.total.count).format('0,0.00')}%`
                },
                {
                    reason: 'other',
                    value: `${numeral(failedRates.other.count).format('0,0')}`,
                    percentage: `${numeral((failedRates.other.count * 100) / approvalRates.total.count).format('0,0.00')}%`
                }
            ],
            other: approvalRates.other ? {
                value: `${numeral(approvalRates.other.count).format('0,0')}`,
                percentage: `${numeral((approvalRates.other.count * 100) / approvalRates.total.count).format('0,0.00')}%`
            } : null
        }
    }
}