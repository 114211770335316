import { notification } from 'antd'
import { ApiResponse, ContentType, del, get, post, put, patch, Options } from 'back-connector'
import storage from '~/code/services/storage'
import {
    X_CLIENT_ID_KEY,
    VISITOR_ID_KEY
  } from '~/code/constants/AuthConstants'
import { AppStore } from '~/code/stores/AppStore'

export const getWithAuth = <T>(url: string, data?: ContentType, _headers: string[][] = [], options: Options = {}) => {
    const headers = [ ..._headers ]
    const accessToken = storage.get('accessToken')
    const visitorId = storage.get(VISITOR_ID_KEY)

    headers.push(['Authorization', `Bearer ${accessToken}`], [X_CLIENT_ID_KEY, visitorId])

    return interceptor<T>(get<T>(url, data, headers, options))
}

export const postWithAuth = <T>(url: string, data?: ContentType, _headers: string[][] = [], options: Options = {}) => {
    const headers = [ ..._headers ]
    const accessToken = storage.get('accessToken')
    const visitorId = storage.get(VISITOR_ID_KEY)

    headers.push(['Authorization', `Bearer ${accessToken}`], [X_CLIENT_ID_KEY, visitorId])

    return interceptor<T>(post<T>(url, data, headers, options))
}

export const deleteWithAuth = <T>(url: string, data?: ContentType) => {
    const headers = []
    const accessToken = storage.get('accessToken')
    const visitorId = storage.get(VISITOR_ID_KEY)

    headers.push(['Authorization', `Bearer ${accessToken}`], [X_CLIENT_ID_KEY, visitorId])

    return interceptor<T>(del<T>(url, data, headers))
}

export const putWithAuth = <T>(url: string, data?: ContentType, headers?: any[][]) => {
    const _headers = []
    const accessToken = storage.get('accessToken')
    const visitorId = storage.get(VISITOR_ID_KEY)

    _headers.push(['Authorization', `Bearer ${accessToken}`], [X_CLIENT_ID_KEY, visitorId])

    if (headers?.length) {
        _headers.push(...headers)
    }

    return interceptor<T>(put<T>(url, data, _headers))
}


export const patchWithAuth = <T>(url: string, data?: ContentType) => {
    const _headers = []
    const accessToken = storage.get('accessToken')
    const visitorId = storage.get(VISITOR_ID_KEY)

    _headers.push(['Authorization', `Bearer ${accessToken}`], [X_CLIENT_ID_KEY, visitorId])

    return interceptor<T>(patch<T>(url, data, _headers))
}

const interceptor = async <T>(promise: Promise<ApiResponse<T>>) => {
    const response = await promise
    if (response.status === 401) {
        if (AppStore.authStore.isAuthenticated) {
            notification.error({ message: 'Your session has expired. Please log in again.' })
            AppStore.authStore.logout()
        }
    }
    return response
}
