import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  pos: string
  moto: string
  ecom: string
  payByLink: string
  payByApp: string
  virtualTerminal: string
  nbsp: string
  deposits: string
  balances: string
  fullPayment: string
  other: string
  totalSum: string
  required: string
  digitsOnly: string
  range: string
  enterDetails: string
  onlyPositive: string
  selectTerminal: string
  annualTurnover: string
  annualCardTurnover: string
  monthTransactions: string
  highestTransaction: string
  averageTransaction: string
  turnoverMethod: string
  processingMethod: string
  cartTurnoverPercent: string
  ifMerchantNew: string
  howFar: string
  cardTurnover: string
  days: string
  haveComments: string
  comment: string
  requestMoto: string
  requestVt: string
  notAvailable: string
  clear: string
  submit: string
  posLabel: string
  ecomLabel: string
  isRequired: string
  description: string
  financialInformation: string
}>()
