import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Table, Form, Row, Col } from 'antd'
import { NumberFormItem } from '~/code/components/NumberFormItem'
import { ICPlusTableColumns } from './constants/constants'
import { FormSubgroupTitle } from '../../../shared'
import translationsFees from '../FeesFormMSA/translations'
import translations from '../../translations'
import { ICPlusTableProps } from './props'
import styles from './styles.scss'


export const ICPlusTable = observer((props: ICPlusTableProps) => {
  const { store, form, initialValues, type } = props

  const [mergedInitialValues, setMergedInitialValues] = useState(store.mergeInitialValuesICPlus(type, initialValues))

  useEffect(() => {
    store.onInitialValuesChangeICPlus(initialValues, type, form, setMergedInitialValues)
    setTimeout(() => form.setFieldsValue(store.mergeInitialValuesICPlus(type, initialValues)), 1000)
  }, [initialValues])

  return (
    <Form form={form} initialValues={mergedInitialValues} scrollToFirstError>
      <div className={styles.headlineRateFormContainer}>
        <FormSubgroupTitle>{translations().headlineRate}</FormSubgroupTitle>
        <Row gutter={16}>
          <Col xs={24} md={12} lg={6}>
            <NumberFormItem
              min={0}
              name={['dnapConsumerDebit', 'percent']}
              label={translations().consumerDebitLabel}
              placeholder={translationsFees().percentPlaceholder}
              decimalLength={4}
            />
          </Col>
          <Col xs={24} md={12} lg={6}>
            <NumberFormItem
              min={0}
              name={['dnapConsumerCredit', 'percent']}
              label={translations().consumerCreditLabel}
              placeholder={translationsFees().percentPlaceholder}
              decimalLength={4}
            />
          </Col>
          <Col xs={24} md={12} lg={6}>
            <NumberFormItem
              min={0}
              name={['dnapCorporateDebit', 'percent']}
              label={translations().corporateDebitLabel}
              placeholder={translationsFees().percentPlaceholder}
              decimalLength={4}
            />
          </Col>
          <Col xs={24} md={12} lg={6}>
            <NumberFormItem
              min={0}
              name={['dnapCorporateCredit', 'percent']}
              label={translations().corporateCreditLabel}
              placeholder={translationsFees().percentPlaceholder}
              decimalLength={4}
            />
          </Col>
        </Row>
      </div>

      <Table
        loading={store.isTariffsLoading}
        columns={ICPlusTableColumns}
        dataSource={store.getFilteredTariffsList(type, 'ic+')?.map((p, idx) => ({
          key: idx,
          ...p,
          cardTypeFull: `${p.cardType} ${p.corporateOrConsumer}`
        }))}
        pagination={false}
        size='small'
        bordered
      />
    </Form>
  )
})
