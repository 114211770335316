import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    review: string
    approve: string
    cancel: string
    reject: string
    completeRiskReviewSuccess: string
    completeRiskReviewFailure: string
    sureToApproveRequest: string
    sureToRejectRequest: string
}>()

