import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  financialInfo: string
  expectedAnnualTurnover: string
  expectedAnnualTurnoverCustomValue: string
  expectedMonthlyTransactionValues: string
  averageTransactionValue: string
  tradeOutsideEea: string
  tradingCountriesOutsideEea: string
  businessModelQuestions: string
  expectedAnnualCardTurnover: string
  highestTransactionValue: string
  yes: string
  no: string
}>()
