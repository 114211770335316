import moment from 'moment'
import { DATETIME_FORMAT } from './formats'
import { getTeammateStatusTag } from '~/code/pages/Teammates/services/ui-utils'

import translations from '../translations'

export const loginTableColumns = [
  {
    title: translations().emailAddress,
    key: 'email',
    dataIndex: 'email',
    width: 300,
    fixed: 'left'
  },
  {
    title: translations().firstName,
    key: 'firstName',
    dataIndex: 'firstName'
  },
  {
    title: translations().lastName,
    key: 'lastName',
    dataIndex: 'lastName'
  },

  {
    title: translations().roles,
    key: 'roles',
    render: ({ rolesStr }) => rolesStr
  },

  {
    title: translations().status,
    key: 'status',
    width: 180,
    render: ({ status }) => getTeammateStatusTag(status)
  },

  {
    title: translations().authMethod,
    key: 'authMethod',
    dataIndex: 'authMethod'
  },

  {
    title: translations().createdAt,
    key: 'createdAt',
    render: ({ createdAt }) => createdAt && moment(createdAt).format(DATETIME_FORMAT)
  },
  {
    title: translations().lastLoginDate,
    key: 'lastLoginDate',
    render: ({ lastLoginDate }) => (lastLoginDate && moment(lastLoginDate).format(DATETIME_FORMAT)) || ''
  }
]

export const merchantTableColumns = [
  {
    title: translations().merchant,
    key: 'companyName',
    dataIndex: 'companyName',
    width: 200,
    fixed: 'left'
  },
  {
    title: translations().roles,
    key: 'roles',
    render: ({ rolesStr }) => rolesStr
  },

  {
    title: translations().status,
    key: 'status',
    width: 180,
    render: ({ status }) => getTeammateStatusTag(status)
  },

  {
    title: translations().authMethod,
    key: 'authMethod',
    dataIndex: 'authMethod'
  },

  {
    title: translations().createdAt,
    key: 'createdAt',
    render: ({ createdAt }) => createdAt && moment(createdAt).format(DATETIME_FORMAT)
  },

  {
    title: translations().lastLoginDate,
    key: 'lastLoginDate',
    render: ({ lastLoginDate }) => (lastLoginDate && moment(lastLoginDate).format(DATETIME_FORMAT)) || ''
  }
]
