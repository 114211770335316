import { observer } from 'mobx-react'
import React from 'react'
import moment from 'moment'
import { useInjection } from 'dna-react-ioc'
import { Drawer, Table, Grid, Badge, Card } from 'antd'
import { Nowrap } from '~/code/components'
import { STORE_TYPES } from '~/code/constants/StoreTypes'
import { SubmittedRequestsStore } from '~/code/stores/SubmittedRequestsStore/SubmittedRequestsStore'
import { ProcessDetails } from '~/code/pages/BPMProcesses/Requests/components/SubmittedRequests/components/ProcessDetails'
import { DrawerTitle, RequestsFilter } from './components'
import { reviewTime } from '~/code/pages/BPMProcesses/Requests/utils'
import { SubmittedRequestsStatus } from './models'
import { DATE_TIME_FORMAT } from '~/code/constants/date-time'
import translations from './translations'
import styles from './SubmittedRequests.scss'

const SubmittedRequestsView = observer(() => {
  const screens = Grid.useBreakpoint()

  const store = useInjection<SubmittedRequestsStore>(STORE_TYPES.SubmittedRequestsStore)

  React.useEffect(() => {
    store.init()
  }, [])

  const renderStatus = (status: SubmittedRequestsStatus) => {
    switch (status) {
      case SubmittedRequestsStatus.APPROVED:
        return <Badge status={'success'} text={status} />
      case SubmittedRequestsStatus.REJECTED:
        return <Badge status={'error'} text={status} />
      default:
        return <Badge text={status} color={'yellow'} />
    }
  }

  const columns = [
    {
      title: translations().startDate,
      dataIndex: 'startDate',
      key: 'startDate',
      render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : '')
    },
    {
      title: translations().merchant,
      dataIndex: 'companyName',
      key: 'companyName'
    },
    {
      title: translations().status,
      dataIndex: 'processStatus',
      key: 'processStatus',
      render: (status: SubmittedRequestsStatus) =>
        status ? (
          <Nowrap width={94} title={status}>
            {renderStatus(status)}
          </Nowrap>
        ) : (
          ''
        )
    },
    {
      title: translations().assignedGroup,
      dataIndex: 'assignedGroup',
      key: 'assignedGroup'
    },
    {
      title: translations().assignedUser,
      dataIndex: 'assignedUser',
      key: 'assignedUser'
    },
    {
      title: translations().reviewTime,
      dataIndex: 'reviewTime',
      key: 'reviewTime',
      render: (reviewMinutes: number) => reviewTime(reviewMinutes)
    },
    {
      title: translations().endDate,
      dataIndex: 'endDate',
      key: 'endDate',
      render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : '')
    }
  ]

  const dataSource =
    store.riskLimitApplications && store.riskLimitApplications.map((application, idx) => ({ key: idx, ...application }))

  return (
    <>
      <RequestsFilter store={store} />
      <Card className={styles.tableCard}>
        <Table
          loading={store.isRiskLimitsApplicationsLoading}
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: true }}
          pagination={{
            total: store.total,
            pageSize: store.pageSize,
            current: store.currentPage,
            onChange: (page, pageSize) => store.loadRiskLimitsApplications(page, pageSize),
            size: 'small'
          }}
        />
      </Card>
      <Drawer
        title={
          !store.hasProcessDetailsLoadingError ? (
            <DrawerTitle key={store.selectedProcessId} closeDrawer={() => store.closeInfoDrawer()} />
          ) : null
        }
        placement='right'
        closable={false}
        onClose={() => store.closeInfoDrawer()}
        open={store.isShowInfoDrawer}
        width={screens.xs ? 256 : 512}
      >
        <ProcessDetails />
      </Drawer>
    </>
  )
})

export const SubmittedRequests = () => <SubmittedRequestsView />
