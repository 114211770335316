import { object, string, array, number } from 'yup'
import { BankAccountScheme } from './BankAccountScheme'
import { ProductScheme } from './ProductScheme'
export const StoreScheme = object({
  id: number().required(),
  storeName: string().required(),
  systemDescriptor: string().required(),
  natureOfBusiness: string().required(),
  mcc: string().required(),
  mccDescription: string().required(),
  bankInfo: BankAccountScheme,
  products: array().of(ProductScheme)
}).required()
