import React from 'react'
import { observer } from 'mobx-react'
import { Col, Row } from 'antd'
import { RealTimeTransactionMap } from '~/code/pages/RealTimeTransaction/components/RealTimeTransactionMap/RealTimeTransactionMap'

import styles from './styles.scss'

export const RealTimeTransaction = observer(() => {
  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} sm={24}>
        <RealTimeTransactionMap className={styles.transactionsMap} />
      </Col>
    </Row>
  )
})
