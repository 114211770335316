import React from 'react'
import { inject, injectable } from 'inversify'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import moment from 'moment/moment'
import {
  IPublishNotificationsStore,
  NotificationTemplatesStoreSymbol
} from '~/code/pages/Notifications'
import { message } from 'antd'
import { FetchMerchantsReq, MerchantModel } from '~/code/models/handbooks'
import { fetchMerchants, fetchTransactionsAcquisitionChannels } from '~/code/services/fetchers'
import { SelectItem } from '~/code/models'
import {
  IManageNotificationTemplatesStore
} from '~/code/pages/Notifications/ManageNotificationTemplates/INotificationTemplatesStore'
import { createNotification, fetchTeammates } from '~/code/stores/NotificationManagementStore/services/fetchers'
import { TeammateRequestType } from '~/code/stores/TeammatesStore/models'
import { TeammateViewType } from '~/code/pages/Teammates'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import translations from './translations'

@injectable()
export class PublishNotificationStore implements IPublishNotificationsStore {
  isDrawerOpened: boolean = false
  isPublishing: boolean = false
  step: number = 0
  merchants: MerchantModel [] = []
  teamMates: TeammateViewType[] = []
  acquisitionChannelsOptions: SelectItem[] = []
  notificationTemplatesStore: IManageNotificationTemplatesStore = null
  selectedMerchantsIds: React.Key[] = []
  selectedUsersIds: React.Key[] = []
  merchantsFilter = {
    acquisitionChannel: null,
    product: 'pos',
    acquirer: 'dna',
    page: 1,
    size: 10
  }
  merchantsTotal = 0
  teamMatesFilter = {
    page: 1,
    size: 10,
    userType: 'employee',
    role: null,
    email: null,
    firstName: null,
    lastName: null
  }
  teamMatesTotal = 0

  publishForm = {
    startDate: null,
    endDate: null,
    userType: 'all'
  }

  constructor(
    @inject(NotificationTemplatesStoreSymbol) notificationTemplatesStore: IManageNotificationTemplatesStore,
  ) {
    makeObservable(this, {
      isDrawerOpened: observable,
      isPublishing: observable,
      step: observable,
      teamMates: observable,
      merchants: observable,
      acquisitionChannelsOptions: observable,
      notificationTemplatesStore: observable,
      selectedMerchantsIds: observable,
      selectedUsersIds: observable,
      merchantsFilter: observable,
      teamMatesFilter: observable,
      teamMatesTotal: observable,
      merchantsTotal: observable,
      templates: computed,
      selectedTemplate: computed,
      previewTemplate: computed,
      notificationSetupForm: computed,
      templatesFilter: computed,
      templatesTotal: computed,
      isDashboard: computed,
      isTemplatesLoading: computed,
      setStep: action.bound,
      setTemplatesFilter: action.bound,
      setSelectedMerchantsIds: action.bound,
      setSelectedUsersIds: action.bound,
      setMerchantsFilter: action.bound,
      setTeammatesFilter: action.bound,
      setPublishForm: action.bound,
      setDrawer: action.bound
    })

    this.notificationTemplatesStore = notificationTemplatesStore
    this.loadAcquisitionChannels()
    this.loadMerchants()
    this.loadTeammates()
  }

  public setStep = (value: number) => {
    this.step = value
  }

  public setTemplatesFilter = (values: any, shouldLoad: boolean = false) => {
    this.notificationTemplatesStore.setFilter(values, shouldLoad)
  }

  public setMerchantsFilter = (values: any) => {
    this.merchantsFilter = {
      ...this.merchantsFilter,
      ...values
    }
  }

  public setTeammatesFilter = (values: any) => {
    this.teamMatesFilter = {
      ...this.teamMatesFilter,
      ...values
    }
  }

  public setPublishForm = (values: any) => {
    this.publishForm = {
      ...this.publishForm,
      ...values
    }
  }

  public setDrawer = (value: boolean) => {
    this.isDrawerOpened = value
  }

  public loadTemplates = async () => {
    await this.notificationTemplatesStore.loadTemplates()
  }

  public selectTemplate = (id: string) => {
    this.notificationTemplatesStore.getTemplateDetails(id).then(() => this.setStep(1))
  }

  public viewTemplate = (id: string) => {
    this.notificationTemplatesStore.getTemplateDetails(id).then(() => this.setDrawer(true))
  }

  public setSelectedMerchantsIds = (ids: React.Key[]) => {
    this.selectedMerchantsIds = ids
  }

  public setSelectedUsersIds = (ids: React.Key[]) => {
    this.selectedUsersIds = ids
  }

  public onDrawerClose = () => {
    this.notificationTemplatesStore.setNotificationForm(null)
    this.setDrawer(false)
  }

  public publishToAllMerchants = async () => {
    runInAction(() => {
      this.merchantsFilter = {
        ...this.merchantsFilter,
        page: 1,
        size: this.merchantsTotal,
      }
    })

    await this.loadMerchants().then(() => {
      this.setSelectedMerchantsIds(this.merchants.map((merchant) => merchant.id))
    })
  }

  public publishToAllTeammates = async () => {
    runInAction(() => {
      this.teamMatesFilter = {
        ...this.teamMatesFilter,
        page: 1,
        size: this.teamMatesTotal,
      }
    })
    await this.loadTeammates().then(() => {
      this.setSelectedUsersIds(this.teamMates.map((teamMate) => teamMate.id))
    })
  }

  public publishToAll = () => {
    this.selectedTemplate.systemId === 'dashboard' ?  this.publishToAllMerchants() :  this.publishToAllTeammates()
    this.setStep(2)
  }

  public goToTemplatesCreate = () => {
    goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_TEMPLATES_CREATE)
  }

  public clearSelectedTemplate = () => {
    this.notificationTemplatesStore.clearSelectedTemplate()
  }

  get templates() {
    return this.notificationTemplatesStore.templates
  }

  get selectedTemplate() {
    return this.notificationTemplatesStore.selectedTemplate
  }

  get previewTemplate() {
    return this.notificationTemplatesStore.previewTemplate
  }

  get notificationSetupForm() {
    return this.notificationTemplatesStore.notificationSetupForm
  }

  get templatesFilter() {
    return this.notificationTemplatesStore.filter
  }

  get templatesTotal() {
    return this.notificationTemplatesStore.total
  }

  get isDashboard() {
    return this.notificationTemplatesStore.isDashboard
  }

  get isTemplatesLoading() {
    return this.notificationTemplatesStore.isTemplatesLoading
  }
  loadAcquisitionChannels = async () => {
    try {
      const { result } = await fetchTransactionsAcquisitionChannels()

      if (result) {
        runInAction(() => {
          this.acquisitionChannelsOptions = [{value: 'all', label: translations().all}, ...result.map((channel) => ({
            label: channel.name,
            value: channel.name
          }))]
        })
      }

    } catch (error) {
      message.error(error.message)
    }
  }

  loadMerchants = async () => {
    try {
      const { result } = await fetchMerchants(this.merchantsFilter as FetchMerchantsReq)

      if (result) {
        runInAction(() => {
          this.merchants = result.data
          this.merchantsTotal = result.totalCount
        })
      }

    } catch (error) {
      message.error(error.message)
    }
  }

  loadTeammates = async () => {
    try {
      const { result } = await fetchTeammates(this.teamMatesFilter as TeammateRequestType)

      if (result) {
        const { teamMates, totalCount } = result

        const users: TeammateViewType[] = teamMates.map(({ login, roles, ...rest }) => ({
          ...rest,
          email: login,
          roles,
          roleTitlesStr: ''
        }))

        runInAction(() => {
          this.teamMates = users
          // this.teamMatesTotal = totalCount
          this.teamMatesTotal = 0
        })
      }

    } catch (error) {
      message.error(error.message)
    }
  }

  publishNotification = async () => {
    runInAction(() => this.isPublishing = true)

    const merchants = this.selectedTemplate.systemId === 'dashboard'
        ? [] // this.selectedMerchantsIds.length === this.merchantsTotal ? [] : this.selectedMerchantsIds
        : undefined
    const users = this.selectedTemplate.systemId === 'dashboard'
        ? undefined
        : []  // this.selectedUsersIds.length === this.teamMatesTotal ? [] : this.selectedUsersIds

    const {startDate, endDate, userType} = this.publishForm

    const data = {
      notificationTemplateId: this.notificationTemplatesStore.selectedTemplate.id,
      isActive: true,
      data: undefined,
      users: users,
      merchants: merchants,
      startDate: moment(startDate).format(),
      endDate: moment(endDate).format(),
      userType: userType === 'all' ? undefined : userType,
    }

    try {
      const { result, error } = await createNotification(data)

      if (result) {
        message.success(translations().notificationCreated)
        goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_NOTIFICATIONS)
        this.notificationTemplatesStore.setNotificationForm(null)
      }

      if (error) {
        throw new Error(error.message)
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      runInAction(() => this.isPublishing = false)
    }
  }
}