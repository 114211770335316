import { message } from 'antd'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { AcquisitionChannelModel } from '~/code/models'
import { parseCompanyPCSList, parseCompanyProfile, parseContactInfoData, parseGeneralInformationData, parseOfficerList } from '../../SavedApplications/components/SavedApplicationsTable/services'
import { fetchApplicationById } from '../../SavedApplications/services'
import { IContactInfoParentStore } from '../components/ContactInfo/models/IContactInfoParentStore'
import { IContactInfoStore } from '../components/ContactInfo/models/IContactInfoStore'
import { CompanyProfile, ContactInfoType, GeneralInformationType, OfficerProfile, OnboardingRequest, PaymentSolution, PCSProfile } from '../models'
import { ApplicationStatusType } from '../models/onboarding/FetchApplicationStatusRes'
import { checkSalesforceOpportunityID, fetchApplicationStatus, startApplication, isTariffsValid } from '../services'
import translations from '../translations'
import { log } from 'dna-common'

export class ContactInfoStore implements IContactInfoStore {
    isLoading: boolean = false
    applicationStatus: ApplicationStatusType = null

    isCheckSalesforceOpportunityIDLoading: boolean = false
    isLoadApplicationByIdLoading: boolean = false

    constructor(private parentStore: IContactInfoParentStore) {
        makeObservable(this, {
            isLoading: observable,
            applicationStatus: observable,
            isCheckSalesforceOpportunityIDLoading: observable,
            isLoadApplicationByIdLoading: observable,
            dataStore: computed,
            contactInfoData: computed,
            isAcquisitionChannelsLoading: computed,
            acquisitionChannels: computed,
            isMoreFormFieldsVisible: computed,
            isApplicationWaitingForSubmission: computed,
            reset: action.bound,
            setContactInfoData: action.bound,
            onEmailChange: action.bound,
            loadApplicationById: action.bound,
            checkSalesforceOpportunityID: action.bound
        })
    }

    reset() {
        this.applicationStatus = null
    }

    get dataStore() {
        return this.parentStore.dataStore
    }

    get contactInfoData(): ContactInfoType {
        return this.dataStore.contactInfoData
    }

    get isAcquisitionChannelsLoading(): boolean {
        return this.parentStore.isAcquisitionChannelsLoading
    }

    get acquisitionChannels(): AcquisitionChannelModel[] {
        return this.parentStore.acquisitionChannels
    }

    get isMoreFormFieldsVisible(): boolean {
        return this.applicationStatus === 'pde'
    }

    get isApplicationWaitingForSubmission(): boolean {
        return this.applicationStatus === 'wau'
    }

    onEmailChange(email: string) {
        if (!this.contactInfoData) return

        if (email !== this.contactInfoData.email) {
            this.applicationStatus = null
        }
    }

    async setContactInfoData(values: ContactInfoType): Promise<void> {
        this.dataStore.savePhoneNumberAndEmail(values.telephoneNumber, values.email)
        this.dataStore.setContactInfoData(values)
        this.isLoading = true

        if (this.applicationStatus === 'pde') {
            this.startOnboardingApplication()
        } else {
            this.getApplicationStatus(values.email)
        }
    }

    async getApplicationStatus(email: string) {
        try {
            const { error, status, result } = await fetchApplicationStatus(email)
            this.isLoading = false
            if (error || status !== 200) {
                message.error(error.message || translations().errorStartingApplication)
                return
            }

            this.dataStore.setApplicationId(result.id)
            this.applicationStatus = result.st
            if (this.applicationStatus !== 'pde' && this.applicationStatus !== 'wau') {
                message.error(translations().applicationAlreadySubmitted)
            }
        } catch (error) {
            this.isLoading = false
            message.error(error.message || translations().errorStartingApplication)
        }
    }

    async startOnboardingApplication() {
        try {
            const { email, acquisitionChannel, firstName, surname, telephoneNumber, salesforceOpportunityID } = this.dataStore.contactInfoData

            const { error, result, status } = await startApplication({
                emailAddress: email,
                acquisitionChannel,
                firstName,
                surname,
                telephoneNumber,
                middleName: '',
                opportunityId: salesforceOpportunityID
            })
            this.isLoading = false

            if (error || status !== 200) {
                message.error(error.message || translations().errorStartingApplication)
                return
            }

            this.dataStore.setApplicationId(result.id)
            this.parentStore.setActiveTabKey('generalInformation')
        } catch (error) {
            this.isLoading = false
            message.error(error.message || translations().errorStartingApplication)
        }
    }

    async loadApplicationById() {
        try {
            this.isLoadApplicationByIdLoading = true
            const { error, status, result } = await fetchApplicationById(this.dataStore.applicationId)

            if (error || status !== 200) {
                this.isLoadApplicationByIdLoading = false
                message.error(error.message || translations().errorLoadingApplication)
                return
            }

            this.parentStore.reset()

            this.dataStore.savePhoneNumberAndEmail(result?.applicantInfo?.telephoneNumber, result?.applicantInfo?.emailAddress)

            const contactInfoData: ContactInfoType = parseContactInfoData(result)
            this.dataStore.setContactInfoData(contactInfoData)

            if (result.tariffs) {
                this.dataStore.setTariffs(result.tariffs)
            }

            this.dataStore.setTerminalOrders(result.terminalOrders)

            const generalInformationData: GeneralInformationType = parseGeneralInformationData(result)
            this.dataStore.setGeneralInformationData(generalInformationData)

            if (result.companyProfile) {
                const companyProfile: CompanyProfile = parseCompanyProfile(result)
                this.dataStore.updateCompanyProfile(companyProfile)
            }

            const officerList: OfficerProfile[] = parseOfficerList(result)
            this.dataStore.setOfficerList(officerList)

            const companyPCSList: PCSProfile[] = parseCompanyPCSList(result)
            this.dataStore.setCompanyPCSList(companyPCSList)

            this.isLoadApplicationByIdLoading = false
            this.continueWithApplication(result)
        } catch (error) {
            this.isLoadApplicationByIdLoading = false
            message.error(translations().errorLoadingApplication)
        }
    }

    continueWithApplication(result: OnboardingRequest) {
        if (!result.businessStructure
            || !result.paymentSolution
            || !result.terminalOrders
            || (result.paymentSolution === PaymentSolution.POS && !result.tariffs?.posModel)) {
            log('no terminal orders')
            this.parentStore.setActiveTabKey('generalInformation')
        } else if (!isTariffsValid(result.tariffs)) {
            this.parentStore.setActiveTabKey('pricing')
        } else {
            this.parentStore.setActiveTabKey('completeApplication')
        }
    }

    async checkSalesforceOpportunityID(id: string): Promise<string> {
        runInAction(() => {
            this.isCheckSalesforceOpportunityIDLoading = true
        })
        let errorMessage = ''
        try {
            const { status, error } = await checkSalesforceOpportunityID(id)
            if (status !== 200 || error) {
                errorMessage =  error?.message || translations().errorCheckingSFOpportunityID
            }
        } catch (error) {
            errorMessage =  error?.message || translations().errorCheckingSFOpportunityID
        }
        runInAction(() => {
            this.isCheckSalesforceOpportunityIDLoading = false
        })
        return errorMessage
    }
}