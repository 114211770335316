import translations from './translations'
import React from 'react'

translations.add('en', {
  start: 'Start',
  generalInfo: 'General Information',
  bankDetailsVerif: 'Bank details',
  storesProducts: 'Stores/Products',
  financialInfo: 'Financial Information',
  merchantBankAccount: 'Merchant Bank Account - For Charges (Money Out)',
  shareholders: 'Shareholders',
  directors: 'Directors',
  newDirector: 'New Director',
  signatories: 'Authorised Signatories',
  newSignatory: 'New Signatory',
  businessOwner: 'Business Owner',
  optional: 'Optional',
  completed: 'Completed',
  notCompleted: 'Not Completed',
  pricing: 'Pricing',
  contract: 'Contract',
  sign: 'Sign',
  docuSign: 'DocuSign',
  docuSignInfo: (
    <>
      <p>Click Preview to form the draft of the contract MSA. </p>
      <p>A pdf document will be downloaded, </p>
      <p>check it and assure the data is correct,</p>
      <p> then click Sign to send it for merchant signature in DocuSign</p>
    </>
  ),
  signError: 'Something went wrong on backend side',
  preview: 'Preview',
  alreadySentToSign: 'Document already sent to sign',
  update: 'Update',
  updateError: 'Something went wrong while update',
  title: 'Documents',
  submit: 'Submit',
  back: 'Back',
  individialDetails: 'Individual details',
  director: 'Director',
  shareholder: 'Shareholder',
  signeeNotFound: 'The contract signee is not selected. Please assign the individual as Signee first'
})
