import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    manageTemplates: string
    newTemplate: string
    templateDetails: string
    edit: string
    delete: string
    title: string
    description: string
    system: string
    type: string
    actions: string
    createDate: string
    refresh: string
    deleteTemplateTitle: string
    deleteTemplateContent: string
    viewDetails: string
    displayType: string
    contentArea: string
}>()

