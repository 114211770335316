import translations from './translations'

translations.add('en', {
  startDate: 'Start Date',
  initiator: 'Initiator',
  merchant: 'Merchant',
  mids: 'MIDs',
  status: 'Status',
  assignedGroup: 'Assigned Group',
  assignedUser: 'Assigned User',
  reviewTime: 'Review Time',
  endDate: 'End Date',
  contracts: 'Contracts',
  terminals: 'TIDs',
  storeCloseHeaderText: 'Cancel store closure',
  merchantCloseHeaderText: 'Cancel merchant closure',
  processType: 'Process Type'
})
