import { RealTimeTransactionSourceType } from '~/code/stores/RealTimeTransactionStore/models'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'

import translations from './translations'

type TransactionInfoType = {
  type: RealTimeTransactionSourceType
  text: string
}

export const getPanelInfo = (): TransactionInfoType[] => {
  const panelInfo: TransactionInfoType[] = []

  if (hasPermissions([PermissionMap.transactions.dna_ecom_realtime.read])) {
    panelInfo.push({ type: 'ecom', text: translations().ecom })
  }

  if (hasPermissions([PermissionMap.transactions.dna_pos_realtime.read])) {
    panelInfo.push({ type: 'pos', text: translations().pos })
  }

  return panelInfo
}
