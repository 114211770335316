import { message } from 'antd'
import { action, computed, makeObservable, observable, runInAction, toJS } from 'mobx'
import { apiCheck, ApiResponseError, noThrow } from 'back-connector'
import { trimObject } from '~/code/services/utils'
import { parseApplicationCompanyInformation, loadCompanyInformation, completeApplication, parseOnboardingRequestData, verifyBankAccount, isEquivalent, camelToKebabCase } from 'startapp/services'
import { OfficerProfile, PCSProfile, CompleteAppTabKey, CompleteApplicationParentStore, ResultPageType, SupportingDocumentsType, CompanyProfile, VerifyBankAccountResult, GeneralInformationType } from 'startapp/models'
import translations from 'startapp/translations'
import { RESPONSE_TIMEOUT_IN_MS } from '~/code/constants/Configurations'
import { VerifyBankAccountReq } from '../models/verification'
import { availableAdditionalDocuments } from '../components/SupportingDocuments/components/SupportingDocument/constants'

export class CompleteApplicationStore {
    
    currentTab: CompleteAppTabKey = 'generalInformation'
    resultPage: ResultPageType = 'check'
    resultApplicationId: string
    selectedOfficer: OfficerProfile | PCSProfile = null
    isSubmitting: boolean = false

    isVerifyBankLoading: boolean = false
    verifyBankAccountResult: VerifyBankAccountResult = { type: 'invalid', message: '' }

    errorSubmitApplication: ApiResponseError = { code: '', message: '' }

    constructor(private parentStore: CompleteApplicationParentStore) {

        makeObservable(this, {
            currentTab: observable,
            selectedOfficer: observable,
            isSubmitting: observable,
            resultPage: observable,

            isVerifyBankLoading: observable,
            verifyBankAccountResult: observable,

            errorSubmitApplication: observable,

            isNextLoading: computed,
            isSaveLoading: computed,
            isCheckDocumentsRequiredLoading: computed,

            isCreateMode: computed,

            saveOnGeneralInformation: action.bound,
            saveOnOfficer: action.bound,

            submit: action.bound,
            selectTab: action.bound,
            selectOfficer: action.bound,
            submitCompanyProfile: action.bound,
            loadCompanyInformation: action.bound,
            clearResultPage: action.bound,
            onClose: action.bound,

            setVerifyBankAccountResult: action.bound,
            verifyBankAccountDetails: action.bound
        })
    }

    reset() {
        this.currentTab = 'generalInformation'
        this.resultPage = 'check'
        this.selectedOfficer = null
        this.resultApplicationId = ''
        this.dataStore.resetApplication()
    }

    get dataStore() {
        return this.parentStore.dataStore
    }

    get supportingDocumentsStore() {
        return this.parentStore.supportingDocumentsStore
    }

    get isCreateMode() {
        return !this.selectedOfficer?.id
    }

    get isNextLoading() {
        return this.dataStore.isNextLoading
    }

    get isSaveLoading() {
        return this.dataStore.isSaveLoading
    }

    get isCheckDocumentsRequiredLoading() {
        return this.parentStore.isCheckDocumentsRequiredLoading
    }

    updateCompanyProfile(companyProfile: CompanyProfile) {
        this.dataStore.savePhoneNumberAndEmail(companyProfile.telephoneNumber, companyProfile.emailAddress)
        this.dataStore.updateCompanyProfile(companyProfile)
    }

    async submitCompanyProfile(companyProfile: CompanyProfile) {
        if (!isEquivalent(toJS(this.dataStore.application.companyProfile), companyProfile)) {
            this.updateCompanyProfile(companyProfile)
            await this.dataStore.saveApplication('isNextLoading')
        }

        if (this.currentTab === 'generalInformation') {
            this.goToFinancialInfoTab()
        } else this.goToShareholderOrBusinessOwnerTab()
    }

    goToFinancialInfoTab() {
        this.selectTab('financialInformation')
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    goToShareholderOrBusinessOwnerTab() {
        if (this.dataStore.companyType === 'company') {
            this.selectTab('shareholders')
        } else {
            this.selectTab('businessOwner')
        }
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    selectShareholder = (shareholder: PCSProfile) => this.selectOfficer(shareholder)

    goToBack = () => {
        this.parentStore.openPricingPage()
    }

    async loadCompanyInformation(companyNumber: string) {
        const result = await noThrow(apiCheck(loadCompanyInformation(companyNumber)))
        if (result.error) {
            message.error(result.error.message)
            return false
        } else if (!result.value) {
            message.error(translations().errorLoadingApplication)
            return false
        }
        // from Company House tradingAddress & tradingCountriesOutsideEea will not come
        const companyInformation = parseApplicationCompanyInformation(result.value)
        this.dataStore.application = trimObject(companyInformation)
        return true
    }

    async init(values: GeneralInformationType) {
        const { companyType, companyNumber } = values
        const { application } = this.dataStore
        const oldCompanyNumber = application?.companyProfile?.companyNumber
        if (companyType === 'company' && oldCompanyNumber !== companyNumber) {
            return await this.loadCompanyInformation(companyNumber)
        }
        return true
    }

    selectTab(page: CompleteAppTabKey) {
        this.currentTab = page
        if (page === 'businessOwner') {
            this.selectOfficer(this.dataStore.owner)
        } else {
            this.selectOfficer(null)
        }
    }

    selectOfficer(officer: OfficerProfile | PCSProfile) {
        this.selectedOfficer = officer
    }

    openCompleteApplicationPage() {
        this.parentStore.openCompleteApplicationPage()
    }

    async openSupportingDocumentsPage() {
        await this.parentStore.checkDocumentsRequired()
        this.parentStore.openSupportingDocumentsPage()
    }

    openResultPage = () => {
        this.parentStore.openResultPage()
    }

    onClose() {
        this.currentTab = 'generalInformation'
    }

    clearResultPage() {
        this.resultPage = 'check'
    }

    async saveOnGeneralInformation(companyProfile: CompanyProfile, saveToBackend?: boolean) {
        this.updateCompanyProfile(companyProfile)
        saveToBackend && this.dataStore.saveApplication('isSaveLoading')
    }

    async saveOnOfficer() {
        this.dataStore.saveApplication('isSaveLoading')
    }

    async submit() {
        this.isSubmitting = true
        const { application, generalInformationData, applicationId, tariffs } = this.dataStore
        const { supportingDocumentsData, supportingDocumentsMap } = this.supportingDocumentsStore
        const data = parseOnboardingRequestData(application, generalInformationData, tariffs)

        // to be removed soon - START
        const obj = {} as SupportingDocumentsType
        const additionalDocuments = availableAdditionalDocuments.map(d => d.value)
        for (const [fileKey, fileList] of Object.entries(supportingDocumentsMap)) {
            const file = fileList[0]
            if (!file || additionalDocuments.includes(fileKey as any)) continue
            const base64 = await getBase64(file.originFileObj)

            obj[fileKey] = {
                fileName: file.name,
                value: base64.replace(/^data:([\w\/\+]+);(charset=[\w-]+|base64),/, '')
            }
        }
        data.supportingDocuments = obj
        // to be removed soon - END

        data.documents = supportingDocumentsData.map(d => ({
            type: camelToKebabCase(d.type),
            name: d.fileName,
            path: d.path
        }))

        let isTimeoutFired = false
        const timeoutId = setTimeout(() => {
            this.resultPage = 'timeout'
            this.isSubmitting = false
            isTimeoutFired = true
        }, RESPONSE_TIMEOUT_IN_MS)

        try {            
            const { error } = await completeApplication(
                applicationId,
                data
            )

            this.errorSubmitApplication = error

            if (isTimeoutFired) {
                return
            }

            if (error) {
                message.error(error.message)
                this.resultPage = 'error'
            } else {
                this.resultApplicationId = applicationId
                this.resultPage = 'success'
            }
        } catch (err) {
            message.error(err?.message)
        }

        clearTimeout(timeoutId)

        this.isSubmitting = false
    }

    setVerifyBankAccountResult(value: VerifyBankAccountResult) {
        this.verifyBankAccountResult = value
    }

    async verifyBankAccountDetails(data: VerifyBankAccountReq) {
        let verifyBankAccountResult: VerifyBankAccountResult

        try {
            runInAction(() => {
                this.isVerifyBankLoading = true
            })

            const { status, result, error } = await verifyBankAccount(data)
            if (status !== 200 || error) {
                verifyBankAccountResult = {
                    type: 'error',
                    message: [error.message || translations().errorVerifyBankAccount]
                }
            } else {
                if (result.errors && result.errors.length > 0) {
                    verifyBankAccountResult = {
                        type: 'error',
                        message: result.errors
                    }
                } else if (result.validations && result.validations.length > 0) {
                    verifyBankAccountResult = {
                        type: 'invalid',
                        message: result.validations
                    }
                } else if (result.bankDetails && result.bankDetails.bank) {
                    verifyBankAccountResult = {
                        type: 'valid',
                        message: result.bankDetails.bank
                    }
                }
            }
        } catch (error) {
            verifyBankAccountResult = {
                type: 'error',
                message: [translations().errorVerifyBankAccount]
            }
        } finally {
            runInAction(() => {
                this.isVerifyBankLoading = false
                this.setVerifyBankAccountResult(verifyBankAccountResult)
            })
        }
    }
}

function getBase64(file: File | Blob) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = error => reject(error)
    })
}