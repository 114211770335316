import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  name: string
  result: string
  id: string
  personName: string
  files: string
  type: string
  link: string
  download: string
  pass: string
  fail: string
}>()
