import translations from './translations'

translations.add('en', {
    web: 'Web',
    mobile: 'Mobile',
    recipientsPreview: 'Select Recipient(s)',
    notificationsPreview: (system: string) => `${system} Notification Preview`,
    publishTo: 'Publish notification to:',
    publish: 'Publish',
    fieldRequired: 'Field Required',
    partner: 'Partners',
    employee: 'Employees',
    all: 'All',
    dashboard: 'Dashboard',
    mp: 'Merchant Portal',
    confirmation: 'Publish Confirmation',
    aboutToPublish: 'You are about to publish notification to: ',
    confirmationTextDashboard: 'users on the Dashboard system',
    confirmationTextMp: 'users on the Merchant Portal system'
})
