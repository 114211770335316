import React from 'react'
import classNames from 'classnames'
import { Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { WarningIconWithTooltipProps } from './props'

import styles from './styles.scss'

export const WarningIconWithTooltip: React.FC<WarningIconWithTooltipProps> = ({ text, className }) => {
  return (
    <Tooltip title={text}>
      <ExclamationCircleOutlined className={classNames(styles.root, className)} />
    </Tooltip>
  )
}
