import { Input, Popover } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { MultiSelectContent } from './components'
import { MultiSelectAction } from './models'
import translations from './translations'
import { MultiSelectProps } from './props'
import styles from './MultiSelect.scss'
import classNames from 'classnames'

export const MultiSelect: React.FC<MultiSelectProps> = (props) => {
    const {
        value = [],
        options,
        placeholder,
        disabled,
        extraActions = [],
        isSearchable,
        isDefaultAll,
        emptyText = '',
        onChange = () => null,
        onBlur = () => null,
        onKeyDown = () => null
    } = props
    const [ isVisible, setVisible ] = useState(false)

    const isEmpty = !value || value.length === 0
    const isActionKey = (key: string) => Boolean(extraActions.find((a) => a.key === key))
    const optionsWithoutActionKeys = options.filter((opt) => !isActionKey(opt.value))

    const actions: MultiSelectAction[] = [
        { text: translations().selectAll, onClick: () => onChange(optionsWithoutActionKeys.map((opt) => opt.value))},
        { text: translations().deselectAll, onClick: () => onChange([])},
        ...extraActions
    ]

    const handleMenuClick = (key: string) => {
        if (value.indexOf(key) >= 0) {
            onChange(value.filter((v) => v !== key))
        } else {
            if (isActionKey(key)) {
                onChange([key])
            } else {
                const values = value.filter((v) => !isActionKey(v))
                onChange([...values, key])
            }
        }
    }

    const selectedText = (
        isEmpty ? (isDefaultAll ? translations().all : emptyText)
        : optionsWithoutActionKeys.length === value.length ? translations().all
        : value.length > 1 ? translations().selected(value.length)
        : options.find((opt) => opt.value === value[0])?.label || value
    )

    const content = <MultiSelectContent
        isSearchable={isSearchable}
        isVisible={isVisible}
        options={options}
        value={value}
        actions={actions}
        handleMenuClick={handleMenuClick}
    />

    const handleInputClick = () => {
        if (disabled) return
        setVisible(true)
    }

    return <Popover
        placement='bottomLeft'
        trigger={['click']}
        content={content}
        overlayClassName={styles.popover}
        open={isVisible}
        onOpenChange={(flag) => {
            setVisible(flag)
            if (!flag) {
                onBlur()
            }
        }}
    >
        <Input
            className={classNames(styles.input, { [styles.input__disabled]: disabled })}
            suffix={<DownOutlined onClick={handleInputClick} />}
            value={selectedText}
            disabled={disabled}
            placeholder={placeholder}
            onClick={handleInputClick}
            onKeyDown={onKeyDown}
        />
    </Popover>
}
