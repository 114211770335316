import { StoreItem } from '../../../models'
import merge from 'lodash/merge'
import { BankInfo } from '../../../models/BankInfo'

export const convertDataToFormValue = (data: StoreItem, excludeKeys?: string[], bankAccounts?: BankInfo[]) => {
  const res = merge(data, {
    settlementAccount:
      data?.bankInfo?.bankAccountNumber || (bankAccounts.length === 1 ? bankAccounts[0].bankAccountNumber : ''),
    directDebitAccount:
      data?.directDebitBankInfo?.bankAccountNumber ||
      (bankAccounts.length === 1 ? bankAccounts[0].bankAccountNumber : '')
  })

  if (excludeKeys?.length > 0) {
    excludeKeys.forEach(key => {
      delete res[key]
    })
  }
  return res
}
