import React from 'react'
import { observer } from 'mobx-react-lite'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { hasPermissions } from '~/code/services/auth'
import { Button } from 'antd'
import { PageHeader } from '~/code/components'
import { tabs } from '../../constants'
import { PartnerAccessTabKeyType } from '~/code/pages/PartnerAccess/types'
import { PartnerAccessHeadProps } from './props'

import translations from './translations'
import styles from './styles.scss'

export const PartnerAccessHead = observer(({ store }: PartnerAccessHeadProps) => {
  const { activeTab, selectedPartner, isPartnerTabActive, setActiveTab, onLoginDrawerOpen, onPartnerDrawerOpen } = store

  return (
    <div className={styles.root}>
      <PageHeader
        tabList={tabs}
        tabActiveKey={activeTab}
        onTabChange={(key: PartnerAccessTabKeyType) => setActiveTab(key)}
      />
      {isPartnerTabActive && (
        <div className={styles.buttons}>
          {hasPermissions([PermissionMap.partners.teammates.create]) && (
            <Button
              type='primary'
              className={styles.loginButton}
              disabled={!selectedPartner}
              onClick={onLoginDrawerOpen}
            >
              {translations().addLogin}
            </Button>
          )}

          {hasPermissions([PermissionMap.partners.create]) && (
            <Button type='primary' disabled={selectedPartner?.synced} onClick={() => onPartnerDrawerOpen(false)}>
              {!!selectedPartner && selectedPartner.synced === false
                ? translations().syncPartner
                : translations().addPartner}
            </Button>
          )}
        </div>
      )}
    </div>
  )
})
