import translations from './translations'

translations.add('en', {
    storeName: 'Store Name',
    location: 'Location',
    mid: 'Mid',
    venueId: 'Venue Id',
    save: 'Save',
    cancel: 'Cancel',
    errorMessage: 'Please, fill at least one venueId'
})