import React, { useRef } from 'react'
import { Card, Divider, Row, Col } from 'antd'
import { observer } from 'mobx-react'
import { TransactionChannels } from '~/code/pages'
import { PosTransactionChannel } from '~/code/pages/TransactionChannels/components/PosTransactionChannel'
import { PosTransactionChannelType } from '~/code/pages/TransactionChannels/models'
import { TransactionChannelsTabEnum } from '../../models/TransactionChannelsTabEnum'
import { EcomTransactionChannel } from '../../components'
import { GeneralSettingsProps } from './props'
import translations from '../../translations'
import styles from './styles.scss'

export const GlobalSettings = observer((props: GeneralSettingsProps) => {
    const { store } = props

    const rowRef = useRef(null)

    const renderPosTransactionChannels = (transactionChannels: PosTransactionChannelType[]) => {

        return (
            <Row gutter={[16, 16]} className={styles.generalSettings} ref={rowRef}>
                {transactionChannels.map((transactionChannel, index) => (
                    <Col xs={24} sm={24} lg={12} xl={8} key={index}>
                        <PosTransactionChannel
                            transactionChannel={transactionChannel}
                            isLoading={store.isUpdateTransactionChannelLoading}
                            updateTransactionChannel={(isDisabled: boolean, comment: string) =>
                                store.updatePosTransactionChannel({
                                    channel: transactionChannel.channel,
                                    comment,
                                    action: transactionChannel.value === 'YES' ? 'NO' : 'YES'
                                })
                            }
                        />
                    </Col>
                ))}
            </Row>
        )
    }

    return <TransactionChannels activeTab={TransactionChannelsTabEnum.GlobalSettings}>
        {store.isTransactionChannelsLoading ? (
            <Card loading={true} className={styles.generalSettings}></Card>
        ) : (
            <>
                <Divider>{translations().ecom}</Divider>
                <Row gutter={[16, 16]} className={styles.generalSettings}>
                    {store.ecomTransactionChannels.map(transactionChannel => (
                        <Col xs={24} sm={24} lg={12} xl={8} key={transactionChannel.transactionChannelId}>
                            <EcomTransactionChannel
                                transactionChannel={transactionChannel}
                                isLoading={store.isUpdateTransactionChannelLoading}
                                isOnlyOneTransactionChannelEnabled={store.isOnlyOneEcomTransactionChannelEnabled}
                                updateTransactionChannel={store.updateEcomTransactionChannel}
                            />
                        </Col>

                    ))}
                </Row>

                <Divider>{translations().posAll}</Divider>

                {renderPosTransactionChannels(store.posAllTransactionChannels)}
            </>
        )}
    </TransactionChannels>
})
