import translations from './translations'

translations.add('en', {
  personalName: 'Personal Name',
  email: 'Email address',
  telephoneNumber: 'Telephone Number',
  acquisitionChannel: 'Acquisition Channel',
  opportunityId: 'Opportunity ID',
  initiatorEmail: 'Initiator Email'
})
