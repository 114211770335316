import { MerchantDossierModel } from '../models/merchant-dossier-v1/MerchantDossierModel'

export const getDefaultMerchantDossierModel = (): MerchantDossierModel => {
  return {
    id: '',
    mid: '',
    companyName: '',
    tradeName: '',
    companyNumber: ''
  }
}
