import React from 'react'
import { observer } from 'mobx-react'
import { Card, Col, Form, Row, Select } from 'antd'
import { SubmittedRequestsStore } from '~/code/stores/SubmittedRequestsStore/SubmittedRequestsStore'
import { RangePickerContainer } from '~/code/pages/settlements/SettlementsPayments/components/RangePickerContainer/RangePickerContainer'
import translations from '../../translations'
import styles from './RequestsFilter.scss'
import { MerchantSelect } from '../../../shared/MerchantSelect'

interface RequestsFilterProps {
  store: SubmittedRequestsStore
}

export const RiskLimitsRangePickerStoreSymbol = Symbol('RiskLimitsRangePickerStore')
export const RiskLimitsMerchantSelectStoreSymbol = Symbol('RiskLimitsMerchantSelectStore')

export const RequestsFilter = observer((props: RequestsFilterProps) => {
  const { store } = props

  return (
    <>
      <Card>
        <Row justify={'space-between'}>
          <Col xs={24} sm={12}>
            <Form wrapperCol={{ span: 12 }} labelAlign='left' layout='vertical'>
              <MerchantSelect merchantSelectStoreSymbol={RiskLimitsMerchantSelectStoreSymbol} />
              <Form.Item label={translations().status}>
                <Select
                  className={styles.merchantSelect}
                  placeholder={translations().selectStatus}
                  options={store.statuses}
                  disabled={store.statuses.length <= 1}
                  loading={store.isChangeRiskLimitStatusesLoading}
                  value={store.status}
                  onChange={v => store.setChangeRiskLimitStatus(v)}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24} sm={12}>
            <RangePickerContainer injectableStoreIdentifier={RiskLimitsRangePickerStoreSymbol} />
          </Col>
        </Row>
      </Card>
    </>
  )
})
