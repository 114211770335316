import { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  title: string
  details: ReactNode
  addTitle: string
  productName: (prod: string) => ReactNode
  prodName: (prod: string) => string
}>()
