import { notification } from 'antd'
import { error } from 'dna-common'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { PAGE_SIZE_20, PAGE_SIZE_50 } from '~/code/constants/Configurations'
import { TableData, TableFetchParams, ITableFilterStore, DataLoadingStatus } from '~/code/models'
import rootTranslations from '~/code/translations'

type FetchDataType<T> = (params: TableFetchParams) => Promise<TableData<T, number>>
type OptionsType = { hideErrorNotification?: boolean, isInfinitePagination?: boolean }

export class TableFilterStore<T extends { id?: string | number }> implements ITableFilterStore<T> {
  hasMoreData: boolean = false
  loadingStatus: DataLoadingStatus = 'idle'
  currentPage: number = 1
  pageSize: number = PAGE_SIZE_20
  totalCount: number = 0
  data: T[] = []

  constructor(public fetchData: FetchDataType<T>, private options: OptionsType = {}) {
    if (options.isInfinitePagination) {
      this.pageSize = PAGE_SIZE_50
    }

    makeObservable(this, {
      loadingStatus: observable,
      data: observable,
      currentPage: observable,
      pageSize: observable,
      totalCount: observable,

      isLoading: computed,
      viewData: computed,

      loadData: action,
      setPageSize: action.bound
    })
  }

  get isLoading() {
    return this.loadingStatus === 'loading' && (!this.options.isInfinitePagination || this.currentPage === 1)
  }

  get isLoadingMore() {
    return this.loadingStatus === 'loading' && (this.options.isInfinitePagination && this.currentPage > 1)
  }

  get viewData() {
    if (this.loadingStatus === 'idle') {
      this.loadData()
    }
    return this.data
  }

  loadData = async (params?: { pageNumber: number }) => {
    const { pageNumber = this.currentPage } = params || {}

    runInAction(() => {
      this.loadingStatus = 'loading'
      this.currentPage = pageNumber
    })

    try {
      const { data, total, dataCount } = await this.fetchData({
        currentPage: pageNumber,
        pageSize: this.pageSize
      })
      const result = data?.map((item, index) =>
        item.id
          ? {
              rowId: `${index} ${item.id}`,
              ...item
            }
          : item
      ) || []

      runInAction(() => {
        if (this.options.isInfinitePagination && pageNumber > 1) {
          this.data = this.data.concat(result)
        } else {
          this.data = result
        }
        this.totalCount = total
        this.currentPage = pageNumber
        this.loadingStatus = 'finished'
        this.hasMoreData = this.pageSize === dataCount
      })
    } catch (err) {
      error(err)
      if (!this.options.hideErrorNotification) {
        notification.error({ message: err?.message || rootTranslations().errors.loadData })
      }

      runInAction(() => {
        this.data = []
        this.totalCount = 0
        this.currentPage = 1
        this.loadingStatus = 'failed'
        this.hasMoreData = false
      })
    }
  }

  loadMoreData = () => {
    if (this.options.isInfinitePagination && this.loadingStatus === 'finished' && this.hasMoreData) {
      this.loadData({ pageNumber: this.currentPage + 1 })
    }
  }

  setPageSize(currentPage: number, pageSize: number) {
    this.currentPage = currentPage
    this.pageSize = pageSize
  }

  reload = () => {
    if (this.options.isInfinitePagination) {
      this.loadData({ pageNumber: 1 })
    } else {
      this.loadData()
    }
  }
}
