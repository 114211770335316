import translations from './translations'

translations.add('en', {
    dailyAmount: 'Daily Amount',
    dailyCount: 'Daily Count',
    monthlyAmount: 'Monthly Amount',
    monthlyCount: 'Monthly Count',
    perRefund: 'Per Refund',
    perTransaction: 'Per Transaction',
    merchant: 'Merchant',
    selectMerchant: 'Select Merchant',
    status: 'Status',
    selectStatus: 'Select Status'
})
