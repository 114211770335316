import { inject, injectable } from 'inversify'
import { computed, makeObservable, observable, runInAction } from 'mobx'
import { MerchantsManagementStoreSymbol } from '~/code/pages'
import { MerchantsManagementStore } from './MerchantsManagementStore'
import { IOfficersStore } from '~/code/pages/MerchantsManagement/components/MerchantApplication/components/Officers/IOfficersStore'
import { CompanyFullDossierV2Type, IndividualInfoType } from './models/merchant-dossier-v2'
import { getIndividualsInfo } from './services/fetchers'
import { OfficersProps } from '~/code/pages/MerchantsManagement/components/MerchantApplication/components/Officers/props'
import { message } from 'antd'


@injectable()
export class OfficersStore implements IOfficersStore {
    merchantsManagementStore : MerchantsManagementStore
    individualShareholders: IndividualInfoType[] = []
    companyDirectors: IndividualInfoType[] = []
    authorisedSignatories: IndividualInfoType[] = []
    businessOwner: IndividualInfoType[] = []

    isIndividualsLoading: boolean = false
    constructor(@inject(MerchantsManagementStoreSymbol) merchantsManagementStore : MerchantsManagementStore) {
        this.merchantsManagementStore = merchantsManagementStore
        
        makeObservable(this, {
            individualShareholders: observable,
            companyDirectors: observable,
            authorisedSignatories: observable,
            businessOwner: observable,
            isIndividualsLoading: observable,
            companyFullDossierV2: computed
        })
    }

    get companyFullDossierV2(): CompanyFullDossierV2Type {
        return this.merchantsManagementStore.companyFullDossierV2
    }

    async loadOfficersInfo(officers: OfficersProps) {
        try {
            runInAction(() => {
                this.isIndividualsLoading = true
            })
            const individualsResult = await Promise.all(officers.officersList.map((officer) => getIndividualsInfo(officer)))
            const filteredIndividualsResult = individualsResult.filter((result) => (!result.error && result.status === 200))
            const inviduals =  filteredIndividualsResult.map((invidual) => invidual.result)

            switch (officers.officersType) {
                case 'individualShareholders':
                    this.individualShareholders = inviduals
                    break
                case 'companyDirectors':
                    this.companyDirectors = inviduals
                    break
                case 'authorisedSignatories':
                    this.authorisedSignatories = inviduals
                    break
                case 'businessOwner':
                    this.businessOwner = inviduals
                    break
            }
        } catch (error) {
            message.error(error)
        } finally {
            runInAction(() => {
                this.isIndividualsLoading = false
            })
        }
    }
}
