import React from 'react'
import { observer } from 'mobx-react'
import { Modal, Typography, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { DownloadStatementLoadingModalProps } from './DownloadStatementLoadingModalProps'

import translations from './translations'
import styles from './styles.scss'

export const DownloadStatementLoadingModal = observer((props: DownloadStatementLoadingModalProps) => {
  const { isOpen, onCancel } = props

  return (
    <Modal width={'408px'} open={isOpen} footer={null} onCancel={onCancel} closable={false}>
        <div className={styles.spinHolder}>
          <Spin indicator={<LoadingOutlined className={styles.loadingIcon} spin />} />
        </div>

        <Typography.Title level={5} className={styles.title}>{translations().title}</Typography.Title>
        <Typography.Text className={styles.text}>{translations().text}</Typography.Text>
    </Modal>
  )
})
