import React from 'react'
import { createRoot } from 'react-dom/client'
import { ConfigProvider, notification } from 'antd'
import enGB from 'antd/es/locale/en_GB'
import 'reflect-metadata'
import Chart from 'chart.js'
import 'chartjs-plugin-labels'
import moment from 'moment-timezone'
import * as Sentry from "@sentry/react";

import { DNAIoCProvider } from 'dna-react-ioc'
import { iocInit } from '~/code/startup/ioc-init'
import storage from '~/code/services/storage'
import { DEFAULT_TIMEZONE } from '~/code/constants/date-time'
import { THEME } from '~/code/constants/Theme'
import { AppStore } from './stores/AppStore'
import './startup/Router'
import { App } from './App'

if (__VERSION__ !== storage.get('version')) {
  localStorage.clear()
  storage.set('version', __VERSION__)
}

Chart.defaults.global.plugins = {
  labels: false
}

moment.tz.setDefault(DEFAULT_TIMEZONE)

iocInit()

if (!__IS_PROD__ && window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: "https://ab449777a9eb7986e6efef0f253f4196@test-sentry.dnapayments.com/6",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['https:test-dashboard.dnapayments.com'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

notification.config({
  duration: 3
})

export const finishedLoading = () => {
  const container = document.getElementById('dashboard-content')
  const root = createRoot(container)

  root.render(
    <DNAIoCProvider>
      <ConfigProvider theme={THEME} locale={enGB}>
        <App store={AppStore} />
      </ConfigProvider>
    </DNAIoCProvider>
  )
}
