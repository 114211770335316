import React from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { Modal, Typography } from 'antd'
import { TwoFAEmailConfirmationModalStoreInterface } from '~/code/stores/Profile/TwoFAEmailConfirmationModalStore/TwoFAEmailConfirmationModalStoreInterface'
import { SendVerificationCode, ResendButton } from '~/code/components'
import { OTP_CODE_LENGTH } from '~/code/constants/AuthConstants'

import translations from './translations'
import styles from './styles.scss'

export const TwoFAEmailConfirmationModalStoreSymbol = Symbol('TwoFAEmailConfirmationModalStoreSymbol')

export const TwoFAEmailConfirmationModal = observer(() => {
  const { twoFAStore, email, verifyEmailOtp, resendEmailOtp } = useInjection<TwoFAEmailConfirmationModalStoreInterface>(
    TwoFAEmailConfirmationModalStoreSymbol
  )

  const { isEmailConfirmationModalOpen, setIsEmailConfirmationModalOpen } = twoFAStore

  const onSubmit = async v => await verifyEmailOtp(v.verificationCode)
  const onCancel = async () => setIsEmailConfirmationModalOpen(false)

  return (
    <Modal
      width={'600px'}
      title={translations().title}
      open={isEmailConfirmationModalOpen}
      footer={null}
      onCancel={onCancel}
    >
      <Typography.Text>{translations().description(OTP_CODE_LENGTH, email)}</Typography.Text>

      <div className={styles.resend}>
        {translations().resend.question} <ResendButton onResend={resendEmailOtp} />
      </div>

      <SendVerificationCode
        description={''}
        submitButtonText={translations().continue}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </Modal>
  )
})
