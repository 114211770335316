import {isEmpty} from 'dna-common'
import {inject, injectable} from 'inversify'
import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {Profile} from '~/code/stores/Profile/models'
import {ProfileStoreInterface} from '~/code/pages/Profile'

@injectable()
export class ProfileStore implements ProfileStoreInterface {
    _profileData: Partial<Profile> = {}

    constructor() {
        makeAutoObservable(this)
    }
}
