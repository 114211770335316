import translations from './translations'

translations.add('en', {
  title: 'Title',
  firstName: 'First name',
  middleName: 'Middle name',
  surname: 'Surname',
  dateOfBirth: 'Date of birth',
  gender: 'Gender',
  placeOfBirth: 'Place of birth',
  nationality: 'Nationality',
  countryOfResidence: 'Country of residence',
  occupation: 'Occupation',
  emailAddress: 'Email address',
  telephoneNumber: 'Phone number',
  residentialAddress: 'Residential address',
  addressLine1: 'Address line 1',
  addressLine2: 'Address line 2',
  countryOrState: 'County or State',
  postCode: 'Postcode',
  country: 'Country',
  dsrId: dsrId => `dossierId: ${dsrId}`,
  vulnerableStatus: 'Vulnerable Status',
  visualDisability: 'Visual disability',
  audioDisability: 'Audio disability',
  lifeEvent: 'Life event (bereavement, relationship breakdown, job loss)',
  resilience: 'Resilience (low confidence / capability in managing financial affairs)',
  capability: 'Capability (low income / unmanaged debt)',
  vulnerabilityInfo: 'Vulnerability info:',
  true: 'true'
})
