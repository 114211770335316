import { DownloadOutlined } from '@ant-design/icons'
import { Tooltip, Button } from 'antd'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { downloadFile } from '~/code/stores/ExportsStore/services/fetchers'
import styles from './DownloadFile.scss'
import translations from './translations'
import { DownloadFileProps } from './props'

export const DownloadFile = observer((props: DownloadFileProps) => {
    const { value, record } = props

    const [isLoading, setIsLoading] = useState(false)

    const handleClick = async () => {
        setIsLoading(true)
        await downloadFile(record)
        setIsLoading(false)
    }

    return <div>
        {value}
        <Tooltip title={translations().download}>
            <Button
                className={styles.downloadBtn}
                icon={<DownloadOutlined />}
                onClick={handleClick}
                loading={isLoading}
            />
        </Tooltip>
    </div>
})
