import { parseTextResult } from 'back-connector'
import { message } from 'antd'
import { saveAs } from 'file-saver'
import { getWithAuth } from '~/code/services'
import { FetchInvoicesParamsType } from '~/code/stores/invoicesStore/models/FetchInvoicesParamsType'
import { FetchInvoicesResponseType } from '~/code/stores/invoicesStore/models/FetchInvoicesResponseType'

export const fetchInvoices = (params: FetchInvoicesParamsType) =>
  getWithAuth<{ data: FetchInvoicesResponseType[]; totalCount: number }>('/api/v1/merchants/invoices', params)

export const fetchInvoice = async (id, filename) => {
  const response = await getWithAuth(`/api/v1/invoices/${id}`, {}, [], {
    parseResponse: async res => {
      if (res.status !== 200) {
        const error = await parseTextResult(res)
        throw new Error(error.message)
      }
      const blobFile = await res.blob()
      return blobFile
    }
  })
  if (response.error) {
    // TODO: need to test invoices
    message.error(response.error?.message)
  }
  saveAs.saveAs(await response.result, filename)
}
