import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { notification } from 'antd'
import { subscribe } from 'dna-react-ioc'
import { createSaveAndPlanRefreshAuthCredentials } from 'dna-common'
import storage from '~/code/services/storage'
import {
  REFRESH_ACCESS_TOKEN_BEFORE,
  RETRY_REFRESH_ACCESS_TOKEN_COUNT,
  RETRY_REFRESH_ACCESS_TOKEN_TIMEOUT
} from '~/code/constants/AuthConstants'
import {
  login,
  logout,
  requestRefreshToken,
  resetPassword,
  confirmEmailForReset,
  setNewPassword,
  updateToken
} from '~/code/services/auth-requests'
import {
  LoginModel,
  AuthParentStore,
  TokenModel,
  UserType,
  GrantTypeType,
  UpdateTokenRequestModel,
  AuthType,
  AuthMethodType
} from '~/code/models/auth'
import { generatePermissions, getPermissions, savePermissions } from '~/code/services/auth'
import translations from '~/code/translations'
import { goToRoute, redirectRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import { TwoFAModalStoreSymbol } from '~/code/pages/Profile/components/TwoFA/components/TwoFAModal'
import { TwoFAModalStoreInterface } from '~/code/stores/Profile/TwoFAModalStore'

const ACCESS_TOKEN_KEY = 'accessToken'
const REFRESH_TOKEN_KEY = 'refreshToken'

export class AuthStore {
  twoFAModalStore: TwoFAModalStoreInterface 
  isLoading: boolean = false
  requireAuth = false
  savedEmail: string = storage.get('savedEmail') || ''
  email: string = storage.get('email') || ''
  verificationId: string = storage.get('verificationId') || ''
  permissions: string[] = []
  userType: UserType = null
  password: string = ''
  savedUpdatedTokenResult: TokenModel = null

  constructor(private parentStore: AuthParentStore) {
    makeObservable(this, {
      isLoading: observable,
      requireAuth: observable,
      email: observable,
      userType: observable,
      permissions: observable,
      isPartner: computed,
      login: action,
      clearStorage: action,
      clearStore: action,
      updateTokenTwoFA: action.bound,
      confirmEmail: action.bound,
      confirmTwoFA: action.bound,
      resetPassword: action.bound,
      setPassword: action.bound,
      cancelResetPassword: action.bound,
      sendCodeToEmailForResetPassword: action.bound,
      saveUserData: action.bound,
      clearResetPasswordData: action.bound,
      enforceTokenTwoFA: action.bound,
      handleTwoFAAuth: action.bound,
      handleSuccessfulLogin: action.bound
    })

    window.addEventListener('storage', this.storageListener)

    const { planRefreshAuthCredentials, saveAndPlanRefreshAuthCredentials } =
      createDashboardSaveAndPlanRefreshAuthCredentials(this.logout)

    this.saveAndPlanRefreshAuthCredentials = tokenData => {
      saveAndPlanRefreshAuthCredentials(mapTokens(tokenData))
    }

    if (this.isAuthenticated) {
      planRefreshAuthCredentials()
      runInAction(() => {
        this.userType = storage.get('user_type')
        this.permissions = generatePermissions(getPermissions() || [])
      })
    }
  }

  get isAuthenticated() {
    return Boolean(this.email && storage.get(ACCESS_TOKEN_KEY) && !this.requireAuth)
  }

  get isPartner() {
    return this.userType === 'partner'
  }

  hasPermissions = (permissions: string[]) => {
    if (!permissions || permissions.length === 0) return true
    return permissions.some(p => this.permissions.indexOf(p) >= 0)
  }

  handlePermissionError() {
    notification.error({ message: translations().permissionErrorText });
  }  

  handleTwoFAAuth(result: TokenModel, email: string, authMethod: AuthMethodType) {
    this.saveAndPlanRefreshAuthCredentials(result)
    this.requireAuth = true
    this.savedEmail = email
    storage.set('savedEmail', email)

    if (authMethod === '2fa') {
      redirectRoute(Routes.TWO_FA)
    } else {
      redirectRoute(Routes.TWO_FA_ENFORCED)
    }
  }

  handleSuccessfulLogin(email: string, result: TokenModel) {
    this.saveUserData(email, result)
    this.requireAuth = false
    this.parentStore.onLogin()
  }

  async login(data: LoginModel) {
    try {
      this.isLoading = true

      const { error, result } = await login(data)

      this.clearResetPasswordData()

      if (error) {
        notification.error({ message: error.message })
        return
      }

      const { user_type: userType, acquisition_channel: acquisitionChannel, permissions, auth_method } = result

      const email = data.email.toLocaleLowerCase()
      const hasAcquisitionChannel = Boolean(acquisitionChannel)
      const isAllowed = permissions?.length > 0 && (userType !== 'partner' || hasAcquisitionChannel)

      if (auth_method === '2fa' || auth_method === '2fa_enforced') {
        this.handleTwoFAAuth(result, email, auth_method)
      } else if (!isAllowed) {
        this.handlePermissionError()
      } else {
        this.handleSuccessfulLogin(email, result)
      }
    } finally {
      this.isLoading = false
    }
  }

  async updateTokenTwoFA(twoFAcode: string, grant_type: GrantTypeType): Promise<void> {
    try {
      this.isLoading = true

      const request: UpdateTokenRequestModel = {
        twoFAcode: twoFAcode,
        grant_type: grant_type
      }

      const { result, error } = await updateToken(request)

      if (error) {
        notification.error({ message: error.message })
        return
      }

      const isAllowed = result?.permissions?.length > 0

      if (!isAllowed) {
        this.handlePermissionError()
        return
      }

      this.handleSuccessfulLogin(this.savedEmail, result)
    } catch (error) {
      notification.error({ message: error.message })
    } finally {
      this.isLoading = false
    }
  }

  async enforceTokenTwoFA(code: string): Promise<boolean> {
    const { settings, selectedFrequency, setRecoveryCodes } = this.twoFAModalStore
    const { statuses, types, frequencies } = settings

    const statusId = statuses.find(({ value }) => value === 'ENABLED')?.id
    const typeId = types.find(({ value }) => value === 'AUTHENTICATOR')?.id

    const request: UpdateTokenRequestModel = {
      grant_type: 'authorize_code',
      twoFAcode: code,
      twoFAStatusId: statusId,
      twoFATypeId: typeId,
      twoFAFrequencyId: selectedFrequency
    }

    try {
      const { result, error } = await updateToken(request)

      if (error) {
        notification.error({ message: error.message })
        return false
      }

      this.savedUpdatedTokenResult = result

      if (result.recovery_code?.code) {
        setRecoveryCodes([{ code: result.recovery_code.code }])
      }

      const updatedSettings = {
        frequency: frequencies.find(({ id }) => id === request.twoFAFrequencyId),
        type: types.find(({ id }) => id === request.twoFATypeId),
        status: statuses.find(({ id }) => id === request.twoFAStatusId)
      }

      this.twoFAModalStore.twoFAStore.setUserSettings(updatedSettings)

      return true
    } catch (error) {
      notification.error({ message: error.message })
      return false
    }
  }

  onTwoFAModalClose = () => {
    if (!this.savedUpdatedTokenResult) {
      return
    }
    
    this.handleSuccessfulLogin(this.savedEmail, this.savedUpdatedTokenResult)
  }

  onTwoFAModalOpen = () => {
    this.twoFAModalStore.twoFAStore.setIsModalOpen(true)
  }

  initTwoFAModalStore = () => {
    this.twoFAModalStore = subscribe<TwoFAModalStoreInterface>(TwoFAModalStoreSymbol)
  }

  saveUserData = (email: string, result: TokenModel) => {
    const { user_type, acquisition_channel, permissions } = result

    this.userType = user_type
    this.permissions = generatePermissions(permissions)
    this.email = email

    savePermissions(permissions)

    storage.set('email', this.email)
    storage.set('user_type', this.userType)
    storage.set('acquisition_channel', acquisition_channel)

    this.saveAndPlanRefreshAuthCredentials(result)
  }

  saveAndPlanRefreshAuthCredentials(tokenData: TokenModel) {
    // will be overriden
  }

  storageListener = (e: StorageEvent) => {
    if (storage.isEqual(e.key, ACCESS_TOKEN_KEY) && storage.isEmpty(e.newValue)) {
      this.logout()
    }
  }

  async resetPassword(email: string) {
    this.email = email.toLocaleLowerCase()

    if (await this.sendCodeToEmailForResetPassword()) {
      goToRoute(Routes.CONFIRM_EMAIL)
    }
  }

  async confirmEmail(code: string) {
    this.isLoading = true
    const { error, result } = await confirmEmailForReset(this.email, code)

    if (error) {
      notification.error({ message: error.message })
    } else {
      this.verificationId = result.Id
      goToRoute(Routes.SET_PASSWORD)
    }

    this.isLoading = false
  }

  async confirmTwoFA(code: number, authType: AuthType) {
    this.isLoading = true

    const { error } = await setNewPassword(this.email, this.verificationId, this.password, code, authType)

    if (error) {
      notification.error({ message: error.message })
    } else {
      notification.success({ message: translations().messages.successfullSetPassword })

      this.clearResetPasswordData()

      goToRoute(Routes.LOGIN)
    }

    this.isLoading = false
  }

  async setPassword(password: string) {
    this.isLoading = true
    const { error, status } = await setNewPassword(this.email, this.verificationId, password)

    if (status === 401) {
      this.password = password
      storage.set('email', this.email)
      storage.set('verificationId', this.verificationId)

      this.isLoading = false
      redirectRoute(Routes.TWO_FA_CONFIRM)
      return
    }

    if (error) {
      notification.error({ message: error.message })
    } else {
      notification.success({ message: translations().messages.successfullSetPassword })
      goToRoute(Routes.LOGIN)
    }

    this.isLoading = false
  }

  cancelResetPassword() {
    this.clearResetPasswordData()

    goToRoute(Routes.LOGIN)
  }

  async sendCodeToEmailForResetPassword() {
    this.isLoading = true
    const { error } = await resetPassword(this.email)

    if (error) {
      notification.error({ message: error.message })
    }

    this.isLoading = false
    return !error
  }

  logout = async () => {
    setTimeout(async () => {
      try {
        await logout()
      } catch (error) {
      } finally {
        this.clearStorage()
        this.clearStore()

        this.parentStore.onLogout()
      }
    }, 500)
  }

  backToLogin = async () => {
    try {
      await logout()
    } catch (e) {
    } finally {
      this.clearStore()
      this.clearStorage()

      goToRoute(Routes.LOGIN)
    }
  }

  clearStorage = () => {
    storage.set(ACCESS_TOKEN_KEY, '')
    storage.set(REFRESH_TOKEN_KEY, '')
    storage.set('email', '')
    storage.set('savedEmail', '')
    storage.set('permissions', '')
    storage.set('roles', '')
  }

  clearResetPasswordData = () => {
    this.email = ''
    this.password = ''
    this.verificationId = ''
    storage.set('email', '')
    storage.set('verificationId', '')
  }

  clearStore = () => {
    this.email = ''
    this.savedEmail = ''
    this.isLoading = false
    this.requireAuth = false
    this.savedUpdatedTokenResult = null
  }

  destroy() {
    window.removeEventListener('storage', this.storageListener)
  }
}

const mapTokens = (result: TokenModel) =>
  result && {
    accessToken: result.access_token,
    refreshToken: result.refresh_token,
    expiresIn: result.expires_in
  }

const createDashboardSaveAndPlanRefreshAuthCredentials = (logOut: () => Promise<void>) =>
  createSaveAndPlanRefreshAuthCredentials({
    refreshAccessToken: async () => {
      const _refreshToken = storage.get(REFRESH_TOKEN_KEY)
      if (!_refreshToken) {
        return null
      }

      const email = storage.get('email')
      const { result, error, status } = await requestRefreshToken(_refreshToken)

      if (error) {
        return { error: { type: status === 401 ? 'UNAUTHORISED' : 'UNKNOWN' } }
      }

      return { response: mapTokens(result) }
    },
    logOut,
    refreshAccessTokenBefore: REFRESH_ACCESS_TOKEN_BEFORE,
    retryRefreshAccessTokenCount: RETRY_REFRESH_ACCESS_TOKEN_COUNT,
    retryRefreshAccessTokenTimeout: RETRY_REFRESH_ACCESS_TOKEN_TIMEOUT,
    saveTokens: ({ accessToken, refreshToken }) => {
      storage.set(ACCESS_TOKEN_KEY, accessToken)
      storage.set(REFRESH_TOKEN_KEY, refreshToken)
    }
  })
